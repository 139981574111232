import { East } from '@mui/icons-material'
import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { nodeAxios } from '../../../../../../../utils/axiosConfig';
import { EMAIL_VERIFICATION_CONSTANTS } from '../../../../../../../utils/helpers';

const EmailVerification = ({ onClose, fetchVenueSettings, venueSettings, closePreferencesModal }) => {

    const [email, setEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(null);


    const handleVerify = (e) => {
        e.preventDefault();

        if (!email) {
            toast.error('Please enter an email address');
            return;
        }
        if (email.includes('@esplanda.com')) {
            toast.error('Email address is not allowed');
            return;
        }
        if (email.includes('@mykidreports.com')) {
            toast.error('Email address is not allowed');
            return;
        }
        if (email == venueSettings?.custom_from_email_address && venueSettings?.is_from_email_verified == EMAIL_VERIFICATION_CONSTANTS.VERIFIED) {
            toast.error("Email is already verified");
            return;
        }
        if (email == venueSettings?.custom_from_email_address && venueSettings?.is_from_email_verified == EMAIL_VERIFICATION_CONSTANTS.PENDING) {
            toast.error("Email is already in verification process");
            return;
        }

        const payload = {
            email: email,
            template: process.env.NODE_ENV !== "production" ? 'Esplanda-Template-Staging' :
                    "Esplanda-Email-Verification-Template-2"
        }


        setIsLoading(true);
        nodeAxios
            .post('/email-settings/request-verification', payload).then((response) => {
                toast.success('An email has been sent to your email address. Please verify your email address by clicking the link in the email we sent you.');
                fetchVenueSettings();
                onClose();
                closePreferencesModal();
            }).catch((error) => {
                console.log('🚀OUTPUT --> handleVerify error:', error);
                // toast.error(error?.response?.data?.message || 'An error occurred');
            }).finally(() => {
                setIsLoading(false);
            })

    }


  return (
    <form onSubmit={handleVerify}>
    <DialogTitle className='!tw-font-semibold'>
        Add Email
    </DialogTitle>
    <DialogContent sx={{ pt: '5px !important', width: { md: 450 } }}>
        {/* <p className='tw-text-xs tw-text-[#868686]'>
            Please verify your email address by clicking the link in the email we sent you.
        </p> */}

        <TextField 
            name='email'
            type='email'
            label='Email'
            variant='outlined'
            fullWidth
            size='small'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
        />
        

    </DialogContent>
    <DialogActions>
        <button 
            className='tw-dui-btn tw-h-auto tw-min-h-0 tw-py-2 !tw-rounded-md hover:tw-bg-whiteSmoke '
            type='button'
            onClick={onClose}
        >Cancel
        </button>
        <button 
            className='tw-dui-btn tw-h-auto tw-min-h-0 tw-py-2 tw-bg-blue tw-text-white !tw-rounded-md hover:tw-bg-darkBlue '
            type='submit'
            disabled={isLoading}
        >
            Verify email {isLoading ? <span className='tw-dui-loading tw-dui-loading-spinner tw-dui-loading-xs'></span> : <East sx={{ fontSize: '20px' }} />}
        </button>
    </DialogActions>
    </form>
  )
}

export default EmailVerification