import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import addnewproductsimg from "../../../assets/add-new-products.png";
import addnewproductsdesistoreimg from "../../../assets/add-new-products-desistore.png";
import routePaths from '../../../config/route-paths';
const AddNewProduct = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Product & Inventory               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.addNewProduct} className={cn('selected')}>How to Add New Products</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.createNewTags}>Create New Tags</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.assigntagstoproduct}>Assign Tag to Product</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.uploadInventory}>Upload Inventory</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.searchProductbyCategory}>Search Product by Category</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.editProductAttributes}>Edit Product Attributes</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.changingItemVisiblity}>Changing Item Visiblity</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
            How to Add New Products         
         </Typography>
         <span className={cn('addnewproductsimg')}>         
         <img src={Const.isOurDesiStore ? addnewproductsdesistoreimg : addnewproductsimg}/>
         </span>
         <p>
         <ol>
            <li>Click ‘<strong>Add New Product</strong>’ button on the top-left of the page, below ‘<strong>Category</strong>’.</li>
            <li>Choose your Product Category from the dropdown.</li>
            <li>Enter the name of your product in ‘<strong>Product Name</strong>’.</li>
            <li>Enter the name of the Brand or Producer of the product. This would be the brewery or winery, for example.</li>
            <li>Select the format of your product from the ‘<strong>Format</strong>’ dropdown.</li>
            <li>Enter the SKU. This can be found on the barcode. For items without a SKU or barcode, create a unique one and enter that.</li>
            <li>Enter the Price & Quantity of the product.</li>
            <li>Click the ‘<strong>Upload Image</strong>’ icon to upload your own image of the product. You may skip this step if you would like, as we will automatically
               update the product image with our own.
            </li>
            <li>To finish, click the ‘<strong>Add</strong>’ button on the right side.</li>
         </ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default AddNewProduct