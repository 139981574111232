import { Alert, Button } from '@mui/material'
import React from 'react'

const ErrorFallbackBoundary = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 15,
                alignItems: 'center',
                padding: 10
            }}
        >
            <Alert variant="filled" severity="error">Something went wrong</Alert>
            <Button
                variant="contained"
                onClick={() => {
                    window.location.reload()
                }}
            >
                Refresh
            </Button>
        </div>
    )
}

export default ErrorFallbackBoundary