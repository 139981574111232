import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Collapse,
} from "@mui/material";
import StatesList from './StatesList';
import {
    countryApi
} from "../../../../redux/actions/searchActions";
import LightTooltip from "../../../../util_comps/LightTooltip";

const Country = (props) => {
    const countryList = useSelector((store) => store.search.country);
    const dispatch = useDispatch((data) => data);
    let [openedCountry, setOpenedCountry] = useState("none");
    useEffect(() => {
        dispatch(countryApi());
    }, []);
    const handleCountryOpen = (country) => {
        if (openedCountry === country) {
            setOpenedCountry("none");
        } else {
            setOpenedCountry(country);
        }
    };
    const setCountry = (country) => {
        let countries = [...props.filters.countries];
        countries.push(country);
        return countries;
    };
    const removeCountry = (country) => {
        let prevCountries = props.filters.countries;
        let newCountries = prevCountries.filter((val) => val !== country);
        return newCountries;
    };
    const setCountryState = (data, multi) => {
        let states = [...props.filters.states];
        states.push(data);
        return multi ? data : states;
    };
    const removeCountryState = (data, multi) => {
        let states = [...props.filters.states];
        /*         if(multi){
                    for(let state of props.filters.states){
                        let stateIndex = data.indexOf(state);
                        console.log(stateIndex)
                        if(stateIndex !== -1){
                            states.splice(states.indexOf(state),1);
                        }
                    }
                }else{ */
        states = states.filter((val) => val !== data);
/*         }
 */        return states;
    };
    const setCity = (data, multi) => {
        let cities = [...props.filters.cities];
        cities.push(data);
        return cities;
/*         return multi ? data : cities;
 */    };
    const removeCity = (data, multi) => {
        let cities = [...props.filters.cities];
        /*         console.log(cities,data);
                if(multi){
                    for(let city of props.filters.cities){
                        let cityIndex = data.indexOf(city);
                        console.log(cityIndex)
                        if(cityIndex !== -1){
                            cities.splice(cities.indexOf(city),1);
                            console.log(cities)
                        }
                    }
                }else{ */
        cities = cities.filter((val) => val !== data);
/*         }
 */        return cities;
    };
    const handleCountrySet = (val, index) => {
/*         let selectedStates = [...countryList[index].CountryStates].map((val) => val.State);
        let cities = [...countryList[index].CountryStates].map((val) => val.citydata);
        console.log(selectedStates,cities)
        let citiesMeshed = [];
        for(let val of cities){
            for(let cityObj of val){
                citiesMeshed.push(cityObj.City)
            }
        }; */
/*         console.log(citiesMeshed)
 */        let countries = setCountry(val.Country);
        /*         let states = setCountryState(selectedStates, true); */
        props.setFilters({
            ...props.filters,
            countries: countries,
            //states: [...props.filters.states, ...states],
            //cities: [...props.filters.cities, ...citiesMeshed]
        });
    }
    const handleRemoveCountry = (val, index) => {
        /*         let selectedStates = [...countryList[index].CountryStates].map((val) => val.State);
                let cities = [...countryList[index].CountryStates].map((val) => val.citydata);
                let citiesMeshed = [];
                for(let val of cities){
                    for(let cityObj of val){
                        citiesMeshed.push(cityObj.City)
                    }
                }; */
        let newCountries = removeCountry(val.Country);
        /*         let remainingCities = removeCity(citiesMeshed,true);
                let remainingStates = removeCountryState(selectedStates,true) */
        props.setFilters({
            ...props.filters,
            countries: newCountries,
            /*             states: remainingStates,
                        cities: remainingCities */
        });
    };
    const handleRemoveState = (val, citydata) => {
        let newStates = removeCountryState(val);
        /*         let cities = [...props.filters.cities];
                let citiesMeshed = citydata;
                console.log(citiesMeshed)
                for(let val of citiesMeshed){
                    cities.splice(cities.indexOf(val.City),1)
                }; */
        props.setFilters({
            ...props.filters,
            states: newStates,
/*             cities
 */        });
    }
    const handleSetState = (val, citydata, country) => {
        let newStates = setCountryState(val, false);
        /*         let cities = citydata;
                console.log(cities)
                let citiesMeshed = [];
                for(let val of cities){
                    citiesMeshed.push(val.City)
                };
                let countries = props.filters.countries;
                if(countries.indexOf(country) === -1){
                    countries.push(country);
                } */
        props.setFilters({
            ...props.filters,
            states: newStates,
            /*             cities: [...props.filters.cities, ...citiesMeshed],
                        countries */
        });
    };
    const handleRemoveCity = (val) => {
        let newCities = removeCity(val);
        props.setFilters({
            ...props.filters,
            cities: newCities
        });
    }
    const handleSetCity = (val, state) => {
        let newCities = setCity(val, false);
        /*         let states = props.filters.states;
                if(states.indexOf(state) === -1){
                    states.push(state);
                } */
        props.setFilters({
            ...props.filters,
            cities: newCities,
/*             states: states
 */        });
    };
    let { countries: initCountries, states: initStates, cities: initCities } = props.initState.current.filters;
    let { countries: newCountries, states: newStates, cities: newCities } = props.filters;
    let tColor = (JSON.stringify(initCountries) !== JSON.stringify(newCountries) || JSON.stringify(initStates) !== JSON.stringify(newStates) || JSON.stringify(initCities) !== JSON.stringify(newCities)) && props.activePanel !== "country" ? 'red' : 'black'
    return (
        <React.Fragment>
            <Accordion
                TransitionProps={{ unmountOnExit: true }}
                square
                onChange={() => props.handlePanelOpen("country")}
                expanded={props.activePanel === "country"}
            >
                <AccordionSummary
                    expandIcon={
                        props.activePanel === "country" ? (
                            <IndeterminateCheckBoxIcon style={{ fill: "black" }} />
                        ) : (
                            <AddBoxIcon style={{ fill: "black" }} />
                        )
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                >
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Only show products that are from a certain country or region</span>} arrow placement="top">
                        <Typography style={{ fontSize: '16px', color: tColor }} variant="h6">Country</Typography>
                    </LightTooltip>
                </AccordionSummary>
                <AccordionDetails>
                    <List style={{ overflow: "hidden" }}>
                        {countryList.map((value, index) => {
                            const labelId = `checkbox-list-label-${value.Country}`;
                            return (
                                <React.Fragment>
                                    <ListItem
                                        key={value}
                                        role={undefined}
                                        button
                                        style={{ width: '100%' }}
                                        onClick={(e) =>
                                            handleCountryOpen(value.Country)
                                        }
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                onChange={e => e.target.checked ? handleCountrySet(value, index) : handleRemoveCountry(value, index)}
                                                edge="start"
                                                checked={props.filters.countries.indexOf(value.Country) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                inputProps={{
                                                    "aria-labelledby": labelId,
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={labelId}
                                            primary={value.Country}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end">
                                                {openedCountry === value.Country ? <ExpandMoreIcon onClick={e => handleCountryOpen(value.Country)} /> : <ExpandLessIcon onClick={e => handleCountryOpen(value.Country)} />}
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Collapse
                                        in={openedCountry === value.Country}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <StatesList setCity={handleSetCity} removeCity={handleRemoveCity} removeCountryState={handleRemoveState} setCountryState={handleSetState} filters={props.filters} country={value} />
                                    </Collapse>
                                </React.Fragment>
                            );
                        })}
                    </List>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

export default Country;
