import React, { useState } from 'react'
import TabsSection from '../../Library/V2/TabsSection/TabsSection';
import styles from './Store.module.scss';
import StoreInfo from './StoreInfo/StoreInfo';
import { useMediaQuery } from '@mui/material';
import BusinessInfo from './BusinessInfo/BusinessInfo';

const tabs = [
    {
      value: 'restaurant_info',
      label: 'Store Info',
    },
    {
      value: 'business_info',
      label: 'Business Info',
    },
  ];


function Store() {
    const [selectedTab, setSelectedTab] = useState('restaurant_info');
    const isSmall = useMediaQuery('(max-width: 1000px)');

  return (
    <div className="page-wrapper tw-my-2 tw-px-4 sm:tw-px-11">
        <TabsSection
            tabs={tabs}
            tabsbg='var(--bg-3)'
            tabsPadding='15px'
            tabValue={selectedTab}
            tabs_container={styles.tabs_container}
            onChange={( _, newValue) => {
                setSelectedTab(newValue)
            }}
            boxStyles={{
            p: '0 !important',
            borderBottom: '1px solid #D3D3D3',
            }}
        />

        {selectedTab === 'restaurant_info' ? (
            <StoreInfo {...{isSmall}} />
        ) : selectedTab === 'business_info' ? (
          <BusinessInfo {...{isSmall}} />
      ) : null }
        
    </div>
  )
}

export default Store;