import React, { useState } from 'react';

const ReadMore = ({ text, maxLength, hideReadMore, readMoreClassName }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const resultString = isTruncated ? (text?.slice(0, maxLength) || '') : text;

  const toggleIsTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div className={readMoreClassName && readMoreClassName}
    style={{
      display: isTruncated ? 'flex' : 'block',
      alignItems: 'center',
      gap: 5
    }}
    >
      <p style={{margin: 0}}>{resultString} 
      {text?.length > maxLength && (
        <button
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          outline: 'none',
          color: '#0071C3'
        }}
        onClick={toggleIsTruncated}
        >
          {hideReadMore ? '...' : isTruncated ? 'Read more' : 'Read less'}
        </button>
      )}
      </p>
      
    </div>
  );
};

export default ReadMore;
