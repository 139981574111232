
const initialState = {
    items: []
};
function groupList(state = initialState, action) {
    switch (action.type) {
        case "SET_GROUP_LIST": {
            return {...state, items: action.payload};
        };
        case "REORDER_GROUP_LIST": {
            return {...state, items: action.payload};
        };
        default:
            return state;
    }
};
export default groupList;  