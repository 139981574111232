import { buttonBaseClasses, inputBaseClasses, outlinedInputClasses, Radio, radioClasses, Select, selectClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

const GenericSelect = styled((props) => (
    <Select
        {...props}
    />
))(({ theme, padding, minHeight, bgColor }) => ({
    padding: 0,
    backgroundColor: bgColor || 'white',
    [`& .${outlinedInputClasses.notchedOutline}`]:{
        minHeight: minHeight || 50,
        border: '1px solid #D3D3D3 !important',
        // boxShadow: '0px 1px 3px #00000033'
    },
    [`& .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input}.${selectClasses.select}`]:{
        padding: padding || '12px 32px 12px 14px'
    }
}))

export default GenericSelect