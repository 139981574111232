import React, { useEffect, useState } from "react";
import styles from './TimeSlotPicker.module.scss';
import clsx from "clsx";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as DatePicker2 } from '@mui/x-date-pickers/DatePicker';
import { Alert, Divider, Drawer, IconButton, TextField, inputBaseClasses, outlinedInputClasses, useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import { Close, Edit, KeyboardArrowDown } from "@mui/icons-material";
import useTimeSlotPicker from "../../../../../hooks/useTimeSlotPicker";
import { useSelector } from "react-redux";
import { getUTCDateObj } from "../../../../../utils/helpers";

const TimeSlotPicker = ({ address, timeSlotData, cart = {}, onTimeslotChange, delivery, onDeliveryChange, type, isCheckout, isMenu, isSmall, isCollapseIcon }) => {
const isPickup = (type == 'pickup')
  const isTablet = useMediaQuery('(max-width:1100px)');
  const dateTime = delivery
  const user = useSelector((store) => store.user);

  const {
    openCal,
    setOpenCal,
    slots,
    setSlots,
    isLoading
  } = useTimeSlotPicker({ venueId: user?.data?.venue_id_integer, onTimeslotChange, cart, type, dateTime, onDeliveryChange, timeSlotData })
console.log({type})
  return (
    <div
      className={clsx(styles.time_slot_picker, address && styles.is_address, isSmall && styles.time_slot_picker_small)}
    >
      {(!timeSlotData?.isAvailable && !isLoading) ? (
        <Alert
          classes={{
            root: styles.alert_root,
            message: styles.alert_message
          }}
          variant="filled"
          elevation={6}
          severity="error"
        >
          Store has not setup {type || "delivery"} times yet.
        </Alert>
      ) : (
        <div
          className={clsx("tw-flex tw-flex-col tw-gap-1")}
        >
          <div
            className={clsx(styles.date_container, isCheckout && (styles.ischeckout_date_container))}
          >
            <div
              className={styles.delivery_date_text}
            >
              {isPickup ? "Pickup on:" : "Deliver on:"}
            </div>

            <div
              className={styles.calender_container}
            >
              {dateTime?.date && (
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker2
                      disableOpenPicker={isTablet}
                      open={openCal}
                      onOpen={() => setOpenCal(true)}
                      onClose={() => setOpenCal(false)}
                      slotProps={{
                        popper: {
                          className: styles.popper_root
                        },
                        textField: {
                          onBeforeInput: (e) => {
                            if (e?.preventDefault) {
                              e?.preventDefault();
                              e?.stopPropagation();
                            }
                          },
                          InputProps: {
                            style: {
                              WebkitTextFillColor: 'black'
                            },
                            onClick: () => setOpenCal(true),
                            onKeyDown: (e) => {
                              e.preventDefault();
                            },
                            onInput: (e) => {
                              e.preventDefault();
                            }
                          },
                          inputProps: {
                            onClick: () => setOpenCal(true),
                            onKeyDown: (e) => {
                              e.preventDefault();
                            },
                            onInput: (e) => {
                              e.preventDefault();
                            }
                          }
                        }
                      }}
                      className={styles.date_picker}
                      value={getUTCDateObj(dateTime?.date)}
                      onChange={(date) => {
                        let newDateTime = dateTime;
                        let time = slots?.find((data) => data.date == format(new Date(date), 'yyy-MM-dd'))?.intervals[0]
                        console.log(date, format(new Date(date), 'yyy-MM-dd'))
                        newDateTime['date'] = format(new Date(date), 'yyy-MM-dd');
                        newDateTime['time'] = time;
                        console.log('newdatetime',newDateTime)
                        onDeliveryChange(newDateTime);
                      }}
                      sx={{
                        [`& .${inputBaseClasses.root}`]: {
                          paddingRight: 0
                        },
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                          border: 'none',
                          outline: 'none',
                        },
                        [`& .${outlinedInputClasses.input}`]: {
                          padding: '2px'
                        },
                      }}
                      // minDate={new Date()}
                      // maxDate={new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000)}
                      shouldDisableDate={(date) => {
                        return !Boolean(slots?.filter((data) => data.date == format(date, 'yyy-MM-dd'))?.length)
                      }}
                    />
                  </LocalizationProvider>
                </>
              )}
            </div>
          </div>

          <div
            className={clsx(styles.time_container, isCheckout && (styles.ischeckout_time_container))}
          >
            <div
              className={styles.delivery_date_text}
            >
              {isPickup ? "Pickup time" : "Deliver time"}
            </div>

            {dateTime.date && (
              <div
                className={styles.slot_container}
              >
                <select
                  className={styles.time_slot_select}
                  onChange={(e) => {

                    let newDateTime = dateTime
                    newDateTime['time'] = e.target.value
                    console.log('newdatetime',newDateTime)
                    onDeliveryChange(newDateTime)
                  }}
                  value={dateTime?.time}
                >
                  {/* {slots?.find((data) => data.date == format((dateTime?.date instanceof Date) ? new Date(dateTime?.date) : new Date(dateTime?.date?.replace(/-/g, '\/').replace(/T.+/, '')), 'yyy-MM-dd'))?.intervals?.map((slot) => { */}
                  {slots?.find((data) => data.date == format(getUTCDateObj(dateTime?.date), 'yyy-MM-dd'))?.intervals?.map((slot) => {
                    return (
                      <option
                        value={slot}
                      >
                        {slot}
                      </option>
                    )
                  })}
                </select>
                {/* <QueryBuilderIcon
                      className={styles.date_icons}
                    /> */}
              </div>
            )}

          </div>
        </div>
      )}

    </div >
  );
};

export default TimeSlotPicker;
