import clsx from 'clsx'
import React from 'react'
import GenericTextField from '../GenericTextField/GenericTextField'
import GenericTextFieldUndeline from '../GenericTextField/GenericTextFieldUndeline'
import styles from './GenericTextFieldWithLabel.module.scss'

const GenericTextFieldWithLabel = ({ label, containerStyle, containerClassname, isSmallLabel, isXSmallLabel, ...rest }) => {
    return (
        <div
            style={containerStyle}
            className={clsx(styles.container, isSmallLabel && styles.small_label, containerClassname)}
        >
            <div
                className={clsx(styles.label_text, isSmallLabel && styles.small_label, isXSmallLabel && styles.xsmall_label)}
            >
                {label}
                {rest.required && (
                    <span
                        className={clsx(styles.required_asterisk, isSmallLabel && styles.small_icon)}
                    >
                        *
                    </span>
                )}
            </div>
            {label == '' ? <GenericTextFieldUndeline
                {...rest}
            /> : <GenericTextField
                {...rest}
            />}

        </div>
    )
}

export default GenericTextFieldWithLabel