import { FormControl, InputLabel } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import GenericSelect from '../GenericSelect/GenericSelect'
import styles from './GenericSelectWithLabel.module.scss'

const GenericSelectWithLabel = ({ label, placeholder, isSmallLabel, containerClassname, containerStyle, isXSmallLabel, ...rest }) => {
    return (
        <div
            style={containerStyle}
            className={clsx(styles.container, containerClassname)}
        >
            <div
                className={clsx(styles.label_text, isSmallLabel && styles.is_small, isXSmallLabel && styles.is_xsmall, rest.labelClassName)}
            >
                {label}
            </div>

            <div>
                <FormControl
                    className={styles.form_control}
                >
                    <InputLabel
                        classes={{
                            shrink: styles.input_label_shrink
                        }}
                        style={{
                            color: 'gray'
                        }}
                        sx={{
                            transform: 'translate(14px, 13px) scale(1)',
                        }}
                    >
                        {placeholder}
                    </InputLabel>
                    <GenericSelect
                        {...rest}
                    >
                        {rest.children}
                    </GenericSelect>
                </FormControl>
            </div>
        </div>
    )
}

export default GenericSelectWithLabel