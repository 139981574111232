export const getSubstring = (name) => {
  let endIndex = 0;
  let startIndex = 0;
  for (let i = 0; i < name.length; i++) {
    if (name[i] == "-") {
      endIndex = i;
      break;
    }
  }
  return name.substring(startIndex, endIndex);
};

export const getImageUrlDefault = (value) => {
  const name = value ? value?.trim().toString().toLowerCase() : "default";
  if (name.startsWith("beer")) {
    return "images/beer-default.png";
  } else if (name.startsWith("wine")) {
    return "images/wine-default.png";
  } else if (name.startsWith("liquor")) {
    return "images/liquor-default.png";
  } else if (name.startsWith("cider")) {
    return "images/cider-default.png";
  } else if (name.startsWith("mead")) {
    return "images/mead-default.png";
  } else if (name.startsWith("misc")) {
    return "images/misc-default.png";
  } else if (name.startsWith("venue")) {
    return "images/venue-default.png";
  } else if (name.startsWith("default")) {
    return "images/beer-default.png";
  } else {
    return "images/beer-default.png";
  }
};

export const isImageExists = (url) => {
  var image = new Image();

  image.src = url;

  if (!image.complete) {
    return false;
  } else if (image.height === 0) {
    return false;
  }

  return true;
};
