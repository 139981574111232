import { Box, Tab } from '@mui/material'
import { ContentState, EditorState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect } from 'react'
import styles from './OrderEmailSetting.module.scss'
import { TreeView } from '@mui/x-tree-view';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import StyledTreeItem from '../../Library/StyledTreeItem/StyledTreeItem';
import axios from '../../../utils/axiosConfig'

// const tabs = [
//   {
//     id: 'pickup',
//     label: 'Pickup',
//     child: [
//       {
//         id: 'pickup_order_cancel',
//         label: 'Order Cancel'
//       },
//       {
//         id: 'pickup_order_placed',
//         label: 'Order Placed'
//       },
//       {
//         id: 'pickup_order_ready',
//         label: 'Order Ready'
//       }
//     ]
//   },
//   {
//     id: 'delivery',
//     label: 'Delivery',
//     child: [
//       {
//         id: 'delivery_order_cancel',
//         label: 'Order Cancel'
//       },
//       {
//         id: 'delivery_order_placed',
//         label: 'Order Placed'
//       },
//       {
//         id: 'delivery_order_ready',
//         label: 'Order Ready'
//       }
//     ]
//   },
// ]

const OrderEmailSetting = (props) => {

  const handleChange = (event, newValue) => {
    let emailObj = props.emailTypes.find((data) => data.id === newValue)
    let val;
    if (emailObj?.child?.length) {
      val = emailObj.child[0].id
      props.setValue(val)
    } else {
      val = newValue
      props.setValue(newValue);
    }
  };

  const fetchEditorState = (val) => {
    axios.get(`/venue-email-config/get-by-type/${val}`)
      .then((response) => {
        const blocksFromHtml = htmlToDraft(response.data.data.result.text);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        props.setEditorState(editorState);
      })
  }

  useEffect(() => {
    if (props.value) {
      fetchEditorState(props.value)
    }
  }, [props.value])


  const onChange = (editorState) => {
    props.setEditorState(editorState);
  };

  return (
    <div
      className={styles.container}
    >

      <Box>

        <div
          className={styles.email_list_text}
        >
          Email Lists
        </div>

        <div
          className={styles.tree_container}
        >
          <TreeView
            aria-label="customized"
            defaultCollapseIcon={<KeyboardArrowDown />}
            defaultExpandIcon={<KeyboardArrowRight />}
            defaultEndIcon={<></>}
            selected={props.value}
            onNodeSelect={handleChange}
            sx={{ flexGrow: 1 }}
          >
            {props.emailTypes?.map((data) => {
              return (
                <StyledTreeItem
                  nodeId={data.id}
                  classes={{
                    selected: styles.tree_item_selected,
                    group: styles.tree_item_group,
                    iconContainer: styles.tree_item_icon_container,
                    content: styles.tree_item_content
                  }}
                  label={
                    <Tab
                      value={data.id}
                      key={data?.label}
                      classes={{
                        root: styles.tabs_root,
                        selected: styles.tab_selected
                      }}
                      label={data?.label}
                      onClick={data?.onClick}
                    />
                  }
                // endIcon={<IconButton onClick={() => deleteActivity(data.id)} ><DeleteForever /></IconButton>}
                // ContentProps={{
                //   contentEditable: isEditMode,
                //   onBlur: (e) => {
                //     if (e.currentTarget.textContent && e.currentTarget.textContent !== data.title) {
                //       handleEdit({ text: e.currentTarget.textContent, id: data.id });
                //     }
                //   }
                // }}
                >
                  {data.child?.length > 0 && data.child?.map((child1) => {
                    return (
                      <StyledTreeItem
                        nodeId={child1.id}
                        classes={{
                          selected: styles.tree_item_selected
                        }}
                        label={
                          <Tab
                            value={data.id}
                            key={child1?.label}
                            classes={{
                              root: styles.tabs_root,
                              selected: styles.tab_selected
                            }}
                            label={child1?.label}
                            onClick={child1?.onClick}
                          />
                        }
                      // endIcon={<IconButton onClick={() => deleteActivity(child1.id)} ><DeleteForever /></IconButton>}
                      // ContentProps={{
                      //   contentEditable: isEditMode,
                      //   onBlur: (e) => {
                      //     if (e.currentTarget.textContent && e.currentTarget.textContent !== child1.title) {
                      //       handleEdit({ text: e.currentTarget.textContent, id: child1.id });
                      //     }
                      //   }
                      // }}
                      >
                        {/* {child1.child.length > 0 && child1.child.map((child2) => {
                        return (
                          <StyledTreeItem
                            nodeId={child2.id}
                            label={<TreeTitleContainer id={child2.id} handleEdit={handleEdit} isEditMode={isEditMode} image={child2.image} title={child2.title} noIcon={true} />}
                            endIcon={<IconButton onClick={() => deleteActivity(child2.id)} ><DeleteForever /></IconButton>}
                            ContentProps={{
                              contentEditable: isEditMode,
                              onBlur: (e) => {
                                if (e.currentTarget.textContent && e.currentTarget.textContent !== child2.title) {
                                  handleEdit({ text: e.currentTarget.textContent, id: child2.id });
                                }
                              }
                            }}
                          />
                        )
                      })} */}
                      </StyledTreeItem>
                    )
                  })}
                </StyledTreeItem>
              )
            })}
          </TreeView>
        </div>




        {/* <Tabs
          style={{
            minHeight: 'unset'
          }}
          classes={{
            indicator: styles.tab_indicator,
            flexContainer: styles.tab_flex
          }}
          orientation="vertical"
          value={value}
          onChange={handleChange}
        >
          {tabs.map((data) => (
            <Tab
              key={data?.label}
              classes={{
                root: styles.tabs_root,
                selected: styles.tab_selected
              }}
              label={data?.label}
              onClick={data?.onClick}
            />
          ))}
        </Tabs> */}
      </Box>

      <div>
        <div
          className={styles.edit_in_text}
        >
          Edit in {props.value.replaceAll('_', ' ')}
        </div>

        <Editor
          // wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={onChange}
          editorState={props.editorState}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "embedded",
              "image",
              "remove",
              "history",
            ],
          }}
        />

      </div>

    </div>
  )
}

export default OrderEmailSetting