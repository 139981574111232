import React from 'react'
import { styled } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Button, Tooltip, CircularProgress, Dialog, ListItemIcon, Menu, MenuItem, Pagination, Radio, Select, Typography, tooltipClasses, useMediaQuery, Box } from '@mui/material';
import { Add, ChevronRight, Description, DragIndicator, ExpandLess, ExpandMore, HelpOutline, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, Link, LocalOfferOutlined, Search, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from '../../../../CustomPageBuilder/EditSection/Util/helpers';
import { ReactComponent as EmptyPlates } from '../../../../../assets/svgs/empty_plates.svg';
import GenericSwitch from '../../../../Library/V2/GenericSwitch/GenericSwitch';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { Divider } from '@mui/material'




const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        //   disableFocusListener
        //   disableHoverListener
        //   disableTouchListener
        // placement="left-end"
        PopperProps={{
            disablePortal: true,
        }}
        arrow
    //   leaveDelay={5000}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 260,
        fontSize: 14,
        border: 'none',
        borderRadius: 10,
        padding: '10px 15px',
        //   textAlign: 'center',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000  ',
    },
}));



const columns = [
    {
        id: 'category_name',
        label: 'Category',
    },
    {
        id: 'ProductName',
        sortid: 'product_name',
        label: 'Item name',
    },
    {
        id: 'Price',
        sortid: 'price',
        label: 'Price',
        // format: (value) => `$${parseFloat(value)?.toFixed(2)}`
    },
    {
        id: 'stockqty',
        sortid: 'quantity',
        label: 'Quantity',
        format: (value, isTrack) => isTrack ? value : ' '
    }
];



const MobileItems = ({
    products,
    options,
    sort,
    setSort,
    handleEdit2,
    handleDelete,
    handleChangeVisibility,
    getTreeItem,
    venueCategoryMenu,
    catId,
    setCatId,
    selectedCatName,
    setSelectedCatName,
    handleClick2,
    handleClose2,
    anchorEl2,
    open2,
    onDragEnd,
    getItemStyle
}) => {

    function getPrice(row) {

        let value = `$${parseFloat(row.Price)?.toFixed(2)}`;
        const min = row?.min_price;
        const max = row?.max_price;
        const formatted_value = min === max ? `$${parseFloat(min)?.toFixed(2)}` : `$${parseFloat(min)?.toFixed(2)} - $${parseFloat(max)?.toFixed(2)}`;
        value = formatted_value || `$${parseFloat(row.Price)?.toFixed(2)}`;
        // console.log('🚀OUTPUT --> formatted_value:', formatted_value);

        return value;
    }



    return (
        <Box>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        // style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {products.map((row, index) => (
                                <Draggable
                                    key={row.id}
                                    draggableId={"q-" + row.id}
                                    index={index}
                                    isDragDisabled={!Boolean(catId)}
                                >
                                    {(provided, snapshot) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            // onClick={() => handleEdit2(row.id)}
                                            className='tw-border tw-border-solid tw-border-gray tw-flex tw-items-center tw-gap-2 tw-mb-2 tw-rounded-lg'
                                        >
                                            <div className='tw-w-full'>
                                                <div className='tw-px-[10px] tw-py-[4px]  tw-flex tw-justify-between tw-items-center tw-text-sm tw-text-black tw-mb-0'>
                                                    <span className='tw-font-semibold tw-max-w-[60%] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.offer_name}</span>
                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            handleEdit2(row.id)
                                                        }}
                                                        sx={{
                                                            height: 30,

                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                                <Divider />
                                                <div className='tw-p-[10px]'>
                                                    <div className='tw-w-full tw-flex tw-flex-row tw-justify-between tw-mb-2'>
                                                        <div className='tw-flex tw-flex-col tw-w-1/2'>
                                                            <span className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-[#7C7C7C] tw-text-sm'>Offer valid from</span>
                                                            <span className='tw-font-bold tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.valid_for} days</span>
                                                        </div>
                                                        <div className='tw-flex tw-flex-col tw-w-1/2'>
                                                            <span className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-[#7C7C7C] tw-text-sm'>Fullfillment type</span>
                                                            {row.coupon_valid_for && (<span className='tw-font-bold tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.coupon_valid_for}</span>)}

                                                        </div>
                                                    </div>
                                                    <div className='tw-w-full tw-flex tw-flex-row tw-justify-between tw-mb-2'>
                                                        <div className='tw-flex tw-flex-col tw-w-1/2'>
                                                            <span className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-[#7C7C7C] tw-text-sm'>Discount</span>
                                                            {row.coupon_limit && (<span className='tw-font-bold tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>${parseFloat(row.coupon_limit)?.toFixed(2)}</span>)}

                                                        </div>
                                                        <div className='tw-flex tw-flex-col tw-w-1/2'>
                                                            <span className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-[#7C7C7C] tw-text-sm'>Offer status</span>
                                                            <div className='tw-w-28'>
                                                                <GenericSwitch
                                                                    background="#0AB221"
                                                                    width={35}
                                                                    height={18}
                                                                    translateX={16}
                                                                    thumb={13}
                                                                    isStatus={true}
                                                                    checked={row.is_active}
                                                                    // onText="'On'"
                                                                    // offText="'Off'"
                                                                    onChange={(event) => {
                                                                        handleChangeVisibility(index)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='tw-w-full tw-flex tw-flex-row tw-justify-between tw-mb-2'>
                                                        <div className='tw-flex tw-flex-col tw-w-1/2'>
                                                            <span className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-[#7C7C7C] tw-text-sm'>Audience</span>
                                                            <span className='tw-font-bold tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>-</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {Boolean(catId) &&
                                                <div className='tw-w-[25px]'>
                                                    <DragIndicator />
                                                </div>}
                                        </Box>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    )
}

export default MobileItems;