import React from 'react'
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'

const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  legend: { display: true, position: "right" },
  datalabels: {
    display: true,
    color: "white",
  },
  tooltips: {
    backgroundColor: "#5a6e7f",
  },
  plugins: {
    datalabels: {
      color: '#36A2EB'
    }
  },
  responsive: true,
}

const PieChart = (props) => {

  return (
    <div>
      <Pie
        data={props.data ? props.data : data}
        options={options}
        plugins={props.showLabels ? [ChartDataLabels] : []}
      />
    </div>
  )
}

export default PieChart