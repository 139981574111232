import { Box, CircularProgress, Tab, Tabs } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import GenericSwitch from '../Library/GenericSwitch/GenericSwitch';
import TabPanel from '../Library/TabPanel/TabPanel';
import FacebookButton from '../MarketingConnections/FacebookButton/FacebookButton';
import TwitterButton from '../MarketingConnections/TwitterButton/TwitterButton';
import styles from './SocialMarketing.module.scss'
import Analytics from './Analytics/Analytics';
import CreatePost from './CreatePost/CreatePost';
import TabsSection from '../Library/TabsSection/TabsSection';
import routePaths from '../../config/route-paths';
import MarketingContext from '../Marketing2/Util/marketingContext';

// const tabs = [
//     {
//         label: 'Analytics'
//     },
//     {
//         label: 'Social Post'
//     }
// ]

const tabs = [
    {
        value: '/marketing/dashboard',
        label: 'Dashboard'
    },
    {
        value: '/marketing/posts',
        label: 'Posts'
    },
    {
        value: '/marketing/planner',
        label: 'Planner'
    }
]

const SocialMarketing = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const facebookBtnRef = useRef()
    const twitterBtnRef = useRef()

    const [init, setInit] = useState(false)
    const [fbLoading, setFbLoading] = useState(false)
    const [instaLoading, setInstaLoading] = useState(false)
    const [twitterLoading, setTwitterLoading] = useState(false)
    const [value, setValue] = useState(location.pathname)

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(newValue)
    };


    const { pages, names, handleFBDisconnect, handleTwitterDisconnect, isOneConnected, externalWebsite, subDomain, ...rest } = useContext(MarketingContext)

    const stopFbLoading = () => {
        setFbLoading(false)
        setInstaLoading(false)
    }

    const stopTwitterLoading = () => {
        setTwitterLoading(false)
    }

    const connections = useMemo(() => [
        {
            title: 'Facebook',
            background: '#3A5999',
            checked: !(pages.length === 0),
            isLoading: fbLoading,
            onChange: () => {
                if (pages.length === 0) {
                    let btn = document.getElementsByClassName(styles.facebook_btn)
                    btn[0].click()
                } else {
                    setFbLoading(true)
                    handleFBDisconnect(stopFbLoading)
                }
            }
        },
        {
            title: 'Twitter',
            background: '#55ACEF',
            checked: !(names.filter((data) => data.isTwitter).length === 0),
            isLoading: twitterLoading,
            onChange: (event) => {
                if (names.filter((data) => data.isTwitter).length === 0) {
                    twitterBtnRef.current.onButtonClick(event)
                } else {
                    setTwitterLoading(true)
                    handleTwitterDisconnect(stopTwitterLoading)
                }
            }
        },
        {
            title: 'Instagram',
            background: 'transparent linear-gradient(180deg, #EF8355 0%, #E4635B 35%, #7659B4 100%) 0% 0% no-repeat padding-box',
            checked: !(pages.filter((data) => data.isInstagram).length === 0),
            isLoading: instaLoading,
            onChange: () => {
                if (pages.filter((data) => data.isInstagram).length === 0) {
                    let btn = document.getElementsByClassName(styles.facebook_btn)
                    // console.log('test', facebookBtnRef.current, btn[0])
                    btn[0].click()
                } else {
                    setInstaLoading(true)
                    handleFBDisconnect(stopFbLoading)
                }
            }
        },
    ], [pages, names, fbLoading, twitterLoading, instaLoading]);

    useEffect(() => {
        // if (!isOneConnected && init) {
        //     navigate(routePaths.socialConnect)
        // }
        // setTimeout(() => {
        //     setInit(true)
        // }, 6000);
    }, [isOneConnected, init])

    return (
        <div
            className={styles.container}
        >
            <Box>
                <Tabs
                    style={{
                        minHeight: 'unset'
                    }}
                    classes={{
                        indicator: styles.tab_indicator
                    }}
                    value={value}
                    onChange={handleChange}
                >
                    {tabs.map((data, index) => (
                        <Tab
                            value={data.value}
                            key={data?.label + index}
                            classes={{
                                root: styles.tabs_root,
                                selected: styles.tab_selected
                            }}
                            label={data?.label}
                        />
                    ))}
                </Tabs>
            </Box>

            <Outlet
                context={{
                    pages,
                    names,
                    handleFBDisconnect,
                    handleTwitterDisconnect,
                    isOneConnected,
                    externalWebsite,
                    subDomain,
                    ...rest
                }}
            />

            {/* <FacebookButton
                ref={facebookBtnRef}
                facebookBtnClass={styles.facebook_btn}
            />

            <TwitterButton
                ref={twitterBtnRef}
                twitterBtnClass={styles.twitter_btn}
            />

            <div
                className={styles.title_container}
            >
                <div
                    className={styles.title}
                >
                    Social Media Marketing
                </div>

                <div
                    className={styles.swicth_section}
                >
                    {connections.map((data) => (
                        <div
                            className={styles.switch_container}
                            key={data.title}
                        >
                            <div
                                className={styles.switch_label}
                            >
                                {data.title}
                            </div>
                            <GenericSwitch
                                background={data.background}
                                width={108}
                                height={24}
                                thumb={19}
                                isStatus={true}
                                checked={data.checked}
                                onChange={data.onChange}
                            />

                            {data.isLoading && (
                                <CircularProgress style={{ marginLeft: 6 }} size={15} />
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <TabsSection
                tabs={tabs}
                tabsBody={[
                    {
                        component: <Analytics />
                    },
                    {
                        component: (
                            <CreatePost
                                pages={pages}
                                names={names}
                                externalWebsite={externalWebsite}
                                subDomain={subDomain}
                            />
                        )
                    }
                ]}
            /> */}
        </div>
    )
}

export default SocialMarketing