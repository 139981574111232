import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../utils/axiosConfig";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import styles from './RecoverPassword.module.scss'
import { TextField } from "@mui/material";
import GenericLoadingButton from "../Library/GenericLoadingButton/GenericLoadingButton";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password too short!")
    .required("Password is required!"),
  password_confirmation: Yup.string()
    .test("is-same", "Passwords don't match!", function (value) {
      return value === this.parent.password;
    })
    .required("Password is required!"),
});


const RecoverPassword = (props) => {
  const history = useNavigate();
  const { token } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      setIsLoading(true)
      const url = `/auth/recover-password?token=${token}`;
      axios
        .post(url, { ...values })
        .then((response) => {
          history("/");
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        })
        .finally(() => {
          setIsLoading(false)
        });
    },
  });

  return (
    <div className={styles.reset_password}>
      <div className={styles.reset_text_container}>
        <div className={styles.reset_text}>Reset Password</div>
      </div>

      <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
        <div className={styles.input_field_container}>
          <label className={styles.input_label} htmlFor='password'>
            Password
            <span className={styles.required_asterisk}>*</span>
          </label>
          <TextField
            InputProps={{
              classes: {
                notchedOutline: styles.input_box_style,
                root: styles.input_root,
                input: styles.input_element_style,
              },
            }}
            variant='outlined'
            id='password'
            name='password'
            placeholder='Password'
            type='text'
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            required
          />
        </div>

        <div className={styles.input_field_container}>
          <label className={styles.input_label} htmlFor='password_confirmation'>
            Confirm Password
            <span className={styles.required_asterisk}>*</span>
          </label>
          <TextField
            InputProps={{
              classes: {
                notchedOutline: styles.input_box_style,
                root: styles.input_root,
                input: styles.input_element_style,
              },
            }}
            variant='outlined'
            id='password_confirmation'
            name='password_confirmation'
            placeholder='Confirm Password'
            type='text'
            fullWidth
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
            required
          />
        </div>

        <div className={styles.submit_btn_container}>
          <GenericLoadingButton
            type='submit'
            classes={{
              root: styles.submit_btn,
            }}
            size='large'
            endIcon={<></>}
            loading={isLoading}
            loadingPosition='end'
            variant='contained'
          >
            Submit
          </GenericLoadingButton>
        </div>
      </form>
    </div>
  );
};

export default RecoverPassword;
