import React from 'react'
import styles from './LayersDrag.module.scss'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { DeleteForever, DragIndicator, EditOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import HTMLRenderer from './HTMLRenderer';

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '4px 0',
    margin: `0 0 8px 0`,
    borderRadius: 4,
    // border: '1px solid #D3D3D3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const LayersDrag = (props) => {

    return (
        <div>
            <DragDropContext onDragEnd={props.onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.scroll}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {props.items.map((item, index) => {
                                return (
                                    <Draggable
                                        key={index.toString()}
                                        draggableId={index.toString()}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() => {

                                                }}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                )}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        position: 'relative',
                                                        // width: 170,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    <HTMLRenderer
                                                        html={item?.toHTML()}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {/* {props.items.map((item, index) => {
                return (
                    <HTMLRenderer
                        html={item?.toHTML()}
                    />
                )
            })} */}
        </div>
    )
}

export default LayersDrag