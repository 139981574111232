import React from 'react'
import { useSelector } from 'react-redux';
import Menu from '../pages/Menu/Menu'

const MenuRoute = () => {
  const user = useSelector((state) => state.user);

  return (
    <Menu isActionsDisabled={user?.data?.is_claim_business === 1} />
  )
}

export default MenuRoute