import React, { useRef } from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Typography } from "@mui/material";

const AddProductToTagTagItem = (props) => {
  const typographyRef = useRef(null);
  return (
    <div
      onClick={(e) => {props.setActiveTag(props.val); props.getTagProducts(props.val.id)}}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 10px",
        cursor: "pointer",
        borderLeft:
          props.val.id === props.activeTag.id ? "5px solid red" : "none",
      }}
    >
      <Typography
        ref={typographyRef}
        onBlur={(e) => {
          props.editTag(props.val.id, props.val.title,e.currentTarget.textContent);
        }}
        {...{tabIndex: 1}}
        suppressContentEditableWarning={true}
        contentEditable={props.activeEditable === props.val.id}
        variant="h6"
        style={{
          fontSize: "1rem",
          fontWeight: 400,
        }}
      >
        {props.val.title}
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CreateOutlinedIcon
          onClick={(e) => {props.setActiveEditable(props.val.id);typographyRef.current.focus()}}
          style={{ color: "red", marginRight: "3px" }}
        />
        <DeleteOutlineOutlinedIcon
          onClick={(e) => {e.stopPropagation();props.deleteTag(props.val.id, props.val.title)}}
          style={{ color: "#868990" }}
        />
      </div>
    </div>
  );
};

export default AddProductToTagTagItem;
