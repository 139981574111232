import { useFormik } from 'formik'
import React, { useState } from 'react'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import HeartlandIcon from '../../../assets/images/heartland.png'
import styles from './HeartlandForm.module.scss'
import GenericButton from '../../Library/GenericButton/GenericButton'
import { Divider } from '@mui/material'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'

const HeartlandForm = (props) => {
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            hl_public_key: props.hl_public_key || '',
            hl_secret_api_key: props.hl_secret_api_key || ''
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setIsLoading(true)

            axios.post('/venue-payment/store', values)
                .then((response) => {
                    props.fetchVenuePayment()
                    toast.success('Heartland Connected')
                })
                .catch(() => {
                    toast.error('Something went wrong')
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    })

    const handleRequestHeartland = () => {
        axios.post('/venue-payment/heartland-request')
            .then(() => {
                toast.success('Heartland account requested')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    return (
        <div
            className={styles.stripe_form}
        >
            <div
                className={styles.logo_container}
            >
                <img className={styles.logo} src={HeartlandIcon} />
            </div>

            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <GenericTextFieldWithLabel
                    label="Heartland public key"
                    id="hl_public_key"
                    name="hl_public_key"
                    value={formik.values.hl_public_key}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    disabled={props.isHlSaveDisabled}
                />

                <GenericTextFieldWithLabel
                    label="Heartland secret api key"
                    id="hl_secret_api_key"
                    name="hl_secret_api_key"
                    value={formik.values.hl_secret_api_key}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    disabled={props.isHlSaveDisabled}
                />

                {!props.isHlSaveDisabled && (
                    <div
                        style={{
                            display: 'flex',
                            gap: 10
                        }}
                    >
                        <GenericLoadingButton
                            variant="contained"
                            loading={isLoading}
                            loadingPosition="end"
                            type="submit"
                            fullWidth={true}
                        >
                            Save
                        </GenericLoadingButton>

                        <GenericButton
                            variant="contained"
                            btnColor="secondary"
                            fullWidth={true}
                            onClick={props.onClose}
                        >
                            Cancel
                        </GenericButton>
                    </div>
                )}

                {!props.isHlSaveDisabled && (
                    <div
                        className={styles.down_section}
                    >
                        <div
                            className={styles.or_container}
                        >
                            <Divider />
                            <div className={styles.or_circle}>or</div>
                        </div>

                        <div>
                            Don't have a Heartland account?
                        </div>

                        <GenericButton
                            variant="contained"
                            fullWidth={true}
                            onClick={handleRequestHeartland}
                        >
                            Request Heartland account
                        </GenericButton>

                        <div>
                            Your Heartland account is requested For more details, please contact
                        </div>

                        <div>
                            <div>
                                James Hauer
                            </div>
                            <a
                                href="mailto:james.hauer@e-hps.com"
                            >
                                james.hauer@e-hps.com
                            </a>
                        </div>
                    </div>
                )}
            </form>


        </div>
    )
}

export default HeartlandForm