import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import styles from './AddUsersDialog.module.scss'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { Autocomplete, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import { DeleteForever, Edit } from '@mui/icons-material'
import UploadImage from '../../Library/UploadImage/UploadImage'
import * as yup from 'yup';
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'

const validationSchema = yup.object({
    password: yup
        .string()
    ,
    password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Password must match')
});

const AddUsersDialog = (props) => {

    const imageRef = useRef()
    const [isLoading, setIsLoading] = useState(false)
    const [scopes, setScopes] = useState({
        admin: [],
        manager: [],
        driver: []
    })

    const formik = useFormik({
        initialValues: {
            contact_no: props.editId?.contact_no || '',
            email: props.editId?.email || '',
            name: props.editId?.name || '',
            code: props.editId?.code || '',
            image_file: null,
            password: '',
            password_confirmation: '',
            role: props.editId?.biz_users_role || '1',
            scopes: props.editId?.scopes || []
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsLoading(true)
            let url
            let fd = new FormData()
            switch (props.page) {
                case 'Add':
                    url = '/users/create'
                    break;
                case 'Edit':
                    url = `/users/edit/${props.editId?.id}`
                    break;
                case 'Change':
                    url = `/auth/change-password-by-user/${props.editId?.id}`
            }

            for (const [key, value] of Object.entries(values)) {
                if (key == 'scopes') {
                    fd.append(key, value?.join(','))
                } else {
                    if (value) {
                        fd.append(key, value)
                    }
                }
            }

            if (!values.image_file && !props.editId?.image) {
                fd.append('image_file_delete', true)
            }

            let changePassParams = {
                password: values.password,
                password_confirmation: values.password_confirmation
            }

            if (props.page === 'Change') {

                axios.post(url, changePassParams)
                    .then(() => {
                        toast.success('Saved Successfully')
                        props.onClose()
                    })
                    .catch((err) => {
                        Object.entries(err.response?.data).map(([key, value]) => {
                            console.log(key, value)
                            formik.setFieldError(key, value && value[0])
                        })

                        toast.error(err.response?.data?.message)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })

            } else {
                nodeAxios.post(url, fd)
                    .then(() => {
                        toast.success('Saved Successfully')
                        props.onClose()
                    })
                    .catch((err) => {
                        Object.entries(err.response?.data).map(([key, value]) => {
                            console.log(key, value)
                            formik.setFieldError(key, value && value[0])
                        })

                        toast.error(err.response?.data?.message)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        }
    })

    const fetchScopes = () => {
        nodeAxios.get('/auth/get-scopes')
            .then((res) => {
                setScopes(res.data?.data)
            })
    }

    useEffect(() => {
        fetchScopes()
    }, [])

    return (
        <>
            <DialogTitle>
                {{
                    'Add': 'Add User',
                    'Edit': `Edit ${props.editId?.name}`,
                    'Change': `Update Password ${props.editId?.name}`
                }[props.page]}
            </DialogTitle>

            <DialogContent>
                <form
                    className={styles.form}
                    onSubmit={formik.handleSubmit}
                >
                    {(props.page === 'Edit' || props.page === 'Add') && (
                        <div>
                            {(props.editId?.image || formik.values.image_file) && (
                                <div
                                    className={styles.image_edit_delete_container}
                                >
                                    <IconButton
                                        onClick={() => {
                                            imageRef.current?.click()
                                        }}
                                    >
                                        <Edit style={{ fontSize: 18, color: 'black' }} />
                                    </IconButton>

                                    <IconButton
                                        onClick={() => {
                                            props.removeImage()
                                        }}
                                    >
                                        <DeleteForever style={{ fontSize: 18, color: 'black' }} />
                                    </IconButton>
                                </div>
                            )}

                            <div
                                className={styles.upload_image_container}
                            >
                                <UploadImage
                                    uploadText="Upload Image"
                                    isBig={true}
                                    ref={imageRef}
                                    image={formik.values.image_file}
                                    imageLink={props.editId?.image}
                                    onChange={(event) => {
                                        formik.setFieldValue('image_file', event.target.files[0])
                                        event.target.value = ''
                                    }}
                                    onImageClose={(event) => {
                                        event.preventDefault()
                                        formik.setFieldValue('image_file', null)
                                    }}
                                />
                            </div>
                        </div>

                    )}

                    {(props.page === 'Edit' || props.page === 'Add') && (
                        <>
                            <div
                                className={styles.type_number_container}
                            >
                                <GenericTextFieldWithLabel
                                    label="Name"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    fullWidth={true}
                                    required={true}
                                />

                                <GenericTextFieldWithLabel
                                    label="Phone Number"
                                    id="contact_no"
                                    name="contact_no"
                                    value={formik.values.contact_no}
                                    onChange={formik.handleChange}
                                    error={formik.touched.contact_no && Boolean(formik.errors.contact_no)}
                                    helperText={formik.touched.contact_no && formik.errors.contact_no}
                                    fullWidth={true}
                                    required={true}
                                />
                            </div>

                            <GenericTextFieldWithLabel
                                label="Email"
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="new-password"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                fullWidth={true}
                                required={true}
                                disabled={props.page == 'Edit'}
                            />
                            <GenericTextFieldWithLabel
                                label="Checkin code"
                                id="code"
                                name="code"
                                type="code"
                                autoComplete="new-password"
                                inputProps={{ maxLength: 6 }}
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                                fullWidth={true}
                            // required={true}
                            />

                            <GenericSelectWithLabel
                                name="role"
                                label="Role"
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                required={true}
                            >
                                <MenuItem onClick={() => formik.setFieldValue('scopes', [])} value="1">Admin</MenuItem>
                                <MenuItem value="2">Manager</MenuItem>
                                <MenuItem onClick={() => formik.setFieldValue('scopes', [])} value="3">Driver</MenuItem>
                            </GenericSelectWithLabel>

                            {(formik.values.role == 2) && (
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={scopes.admin}
                                    getOptionLabel={(option) => option}
                                    value={formik.values.scopes}
                                    onChange={(event, newValue) => {
                                        console.log(newValue)
                                        formik.setFieldValue('scopes', newValue)
                                    }}
                                    renderInput={(params) => (
                                        <GenericTextFieldWithLabel
                                            {...params}
                                            placeholder="Scope"
                                        />
                                    )}
                                />
                            )}
                        </>
                    )}

                    {(props.page === 'Change' || props.page === 'Add') && (
                        <>
                            <GenericTextFieldWithLabel
                                label="Password"
                                id="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                fullWidth={true}
                                required={true}
                            />

                            <GenericTextFieldWithLabel
                                label="Confirm Password"
                                id="password_confirmation"
                                name="password_confirmation"
                                value={formik.values.password_confirmation}
                                onChange={formik.handleChange}
                                error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                fullWidth={true}
                                required={true}
                            />
                        </>
                    )}

                    <DialogActions>
                        <GenericLoadingButton
                            variant="contained"
                            loading={isLoading}
                            loadingPosition="end"
                            type="submit"
                        >
                            Save
                        </GenericLoadingButton>

                        <GenericButton
                            variant="contained"
                            btnColor="secondary"
                            type="button"
                            onClick={props.onClose}
                        >
                            Cancel
                        </GenericButton>
                    </DialogActions>
                </form>
            </DialogContent>
        </>
    )
}

export default AddUsersDialog