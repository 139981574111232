import React, { useState, useRef, useEffect, useCallback } from "react";
import {
    TableRow,
    TableCell,
    Typography,
    TextField,
    Select,
    Autocomplete,
    IconButton,
    Dialog,
    DialogContent,
    Button
} from "@mui/material";
import { Add, Create, Remove, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
    getSuggestions,
    getProductFormats,
} from "../../../../../redux/actions/orderDetailsActions";
import { useClickOutside } from "../../../../../hooks/useClickOutside";
import StyledTableRow from "../../../../Library/StyledTableRow/StyledTableRow";
import StyledTableCell from "../../../../Library/StyledTableCell/StyledTableCell";
import EditOrderProductDialog from "../../../../Dialogs/EditOrderProductDialog/EditOrderProductDialog";
import { debounce } from "lodash";
import styles from './Item.module.scss'
import { useConfirm } from 'material-ui-confirm';
import GenericTextField from "../../../../Library/GenericTextField/GenericTextField";
import { getImage } from "../../../../CustomPageBuilder/EditSection/Util/helpers";
import ModifierDialog from "./ModifierDialog/ModifierDialog";
import { convertToId } from "../../../../../utils/helpers";

const useStyles = makeStyles(() => ({
    textInput: {
        borderRadius: "0",
        width: "100%",
        padding: "0 !important",
        "& > input": {
            padding: "7px 9px !important",
        },
    },
    autocompleteInput: {
        borderRadius: "0",
        width: "100%",
        padding: "0 !important",
        "& > input": {
            padding: "9px 67px 9px 9px !important",
        },
    },
    selectField: {
        backgroundColor: "white",
        borderRadius: 0,
        border: "1px solid lightgrey",
        transition: "none",
        width: "100%",
        "& > select": {
            padding: "9px 32px 9px 10px !important",
            fontSize: ".93rem",
            "&:focus": {
                backgroundColor: "white",
            },
        },
        "&:hover": {
            backgroundColor: "white",
        },
    },
    selectFieldOutlined: {
        border: "none !important",
    },
}));

const OrderItem = ({ data, setSaveable, handleSave, isEditMode, setOrderDetails, index, ...props }) => {

    const confirm = useConfirm();
    const classes = useStyles();
    const dispatch = useDispatch((data) => data);

    const [isCustomize, setIsCustomize] = useState(false)
    const [customizeObj, setCustomizeObj] = useState({})
    const [quantityById, setQuantityById] = useState({})

    const {
        suggestions,
        formats,
        result: { order },
        isLoading
    } = useSelector((state) => state.orderDetailsNew);
    const [subsitute, setSubsitute] = useState(false);

    const itemRef = useRef(null);
    const [editableName, setEditableName] = useState(data.name);
    const [editableFormat, setEditableFormat] = useState(data.productformat);
    const [editableQuantity, setEditableQuantity] = useState(data.quantity);
    const initData = useRef({
        name: `${data.name}`,
        format: `${data.productformat}`,
        quantity: `${data.quantity}`
    })
    useEffect(() => {
        if (subsitute) {
            dispatch(getProductFormats({ productID: data.product_id }));
        }
    }, [subsitute]);

    const handleFetchSuggestion = (value) => {
        dispatch(
            getSuggestions({
                name: value,
            })
        );
    }

    const debounceSearch = useCallback(debounce(handleFetchSuggestion, 1000), [])
    const handleProductNameChange = (event, value, manual) => {
        try {
            if (manual) {
                debounceSearch(value)

                setEditableName(value);
                if (initData.current.format !== value) {
                    setSaveable(true);
                }
            } else {
                props.updateOrderItem({
                    prevId: data.product_id,
                    value,
                })
                dispatch(getProductFormats({ productID: value.ProductId }));
                setEditableName(value.ProductName);
                if (initData.current.format !== value.ProductName) {
                    setSaveable(true);
                }
            }
        } catch (err) {
            console.log(err.message);
        }
    };
    const handleFormatChange = (event) => {
        try {
            let format = formats.find((e) => e.format === event.target.value);
            if (format) {
                props.updateOrderItemFormat({
                    product_id: data.product_id,
                    format: format,
                })
                setEditableFormat(event.target.value);
                if (initData.current.format !== event.target.value) {
                    setSaveable(true);
                }
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const handlePriceChange = (event) => {
        props.updateOrderItemPrice({
            product_id: data.product_id,
            price: event.target.value || 0
        })

        if (initData.current.format !== event.target.value) {
            setSaveable(true);
        }
    };

    const handleQuantityChange = (value, action) => {
        try {
            props.updateOrderItemQuantity({
                product_id: data.product_id,
                quantity: value,
                action
            })

            setEditableQuantity(value);
            if (initData.current.quantity !== value) {
                setSaveable(true);
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const handleDelete = () => {
        setSaveable(true)

        confirm({
            title: 'Do you wish to confirm to delete this product from order',
        })
            .then(() => {
                props.deleteOrderItem({
                    id: data.product_id,
                })
            })
            .catch(() => {

            });
    };

    return (
        <StyledTableRow
            style={{
                backgroundColor: 'white'
            }}
            ref={itemRef}
        >
            <StyledTableCell size="small">
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            minWidth: "70px",
                            maxWidth: "100px",
                            height: "74px",
                        }}
                    >
                        <img
                            style={{
                                width: "90%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                            src={data.product_image}
                            alt={data.ProductName}
                            onError={({ currentTarget, target }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = getImage('no_image.png-1694435803.png')
                            }}
                        />
                    </div>
                </div>
            </StyledTableCell>

            <StyledTableCell>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: 'column',
                        minWidth: 150
                    }}
                >
                    <div
                        className={styles.table_text}
                    >
                        {data.name}
                    </div>

                    {Boolean(data?.selected_modifiers?.length) && (
                        <div
                            style={{
                                fontStyle: 'italic'
                            }}
                        >
                            Customization:
                        </div>
                    )}
                    <div>
                        {data?.modifiers_list?.map((mdata) => {
                            const opt = data.modifiers[mdata?.modifier_id]

                            return (
                                <div
                                    style={{
                                        marginLeft: 8
                                    }}
                                >
                                    {Boolean(opt) && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <b
                                                className={styles.text_wrap}
                                                style={{
                                                    fontWeight: 500,
                                                    fontStyle: 'italic'
                                                }}
                                            >
                                                {mdata?.modifier_title}
                                            </b>

                                            {/* {Boolean(parseFloat(mdata.modifier_price)) && (
                                                <div
                                                    style={{
                                                        fontStyle: 'italic'
                                                    }}
                                                >
                                                    ${parseFloat(mdata?.modifier_price)?.toFixed(2)}
                                                </div>
                                            )} */}
                                        </div>
                                    )}

                                    {mdata?.modifier_option?.map((mdata1) => {
                                        if (!opt?.includes(mdata1?.id)) {
                                            return
                                        }
                                        return (
                                            <div
                                                style={{
                                                    marginLeft: 8
                                                }}
                                                className={styles.modifier_data}
                                            >
                                                <div
                                                    className={styles.text_wrap}
                                                    style={{
                                                        fontStyle: 'italic'
                                                    }}
                                                >
                                                    {mdata1.title}
                                                </div>

                                                {/* {Boolean(parseFloat(mdata1.price)) && (
                                                    <div>
                                                        ${parseFloat(mdata1.price || 0).toFixed(2)}
                                                    </div>
                                                )} */}

                                                {/* {isEditMode && (
                                            <Button
                                                style={{
                                                    color: 'red'
                                                }}
                                                variant="text"
                                                onClick={() => {
                                                    setModifierIdsToRemove((prevState) => {
                                                        let newState = { ...prevState }
                                                        if (newState[mdata?.id]) {
                                                            newState = {
                                                                ...newState,
                                                                [mdata?.id]: [...newState[mdata?.id], mdata1.modifier_option_id]
                                                            }
                                                        } else {
                                                            newState = {
                                                                ...newState,
                                                                [mdata?.id]: [mdata1.modifier_option_id]
                                                            }
                                                        }

                                                        return newState

                                                    })
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        )} */}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>

                    {Boolean(data.brewery_name) && (
                        <small>
                            {data.brewery_name}
                        </small>
                    )}

                    {Boolean(data.SKU) && (
                        <small>
                            SKU: {data.SKU}
                        </small>
                    )}

                    {isEditMode && (
                        <Button
                            startIcon={<Add />}
                            style={{
                                textTransform: 'none'
                            }}
                            variant="text"
                            onClick={() => {
                                setIsCustomize(true)
                                // setCustomizeObj({
                                //     ...data,
                                //     modifiers: convertToId(data?.selected_modifiers)
                                // })
                                setCustomizeObj(data)
                                setQuantityById({ [data.order_itemId]: data.quantity })
                            }}
                        >
                            Edit Customization
                        </Button>
                    )}
                    {data.note && (
                        <div
                            style={{
                                display: 'flex',
                                color: 'red',
                                gap: 4
                            }}
                        >
                            <div>
                                Note:
                            </div>

                            <div>
                                {data.note}
                            </div>
                        </div>
                    )}
                </div>
            </StyledTableCell>

            <StyledTableCell
                style={{
                    verticalAlign: 'baseline'
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div
                        className={styles.table_text_price}
                    >
                        {(data?.productformat != 'Esplanda') ? data?.productformat : '-'}
                    </div>
                </div>
            </StyledTableCell>

            <StyledTableCell
                style={{
                    verticalAlign: 'baseline'
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: 'column'
                    }}
                >
                    {isEditMode ? (
                        <div
                            style={{
                                width: 80
                            }}
                        >
                            <GenericTextField
                                style={{
                                    backgroundColor: 'white'
                                }}
                                onChange={handlePriceChange}
                                value={data.unit_price}
                                type="number"
                            />
                        </div>
                    ) : (
                        <div
                            className={styles.table_text_price}
                        >
                            ${parseFloat(data.unit_price)?.toFixed(2)}
                        </div>
                    )}

                    <div
                        style={{
                            height: 20
                        }}
                    />

                    {data?.modifiers_list?.map((mdata) => {
                        console.log(mdata)
                        const opt = data.modifiers[mdata?.modifier_id]

                        return (
                            <div
                                style={{
                                    marginLeft: 8
                                }}
                            >
                                {Boolean(opt) && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <b
                                            style={{
                                                height: 20
                                            }}
                                        >
                                            {/* {mdata?.modifier_title} */}
                                        </b>

                                        {Boolean(parseFloat(mdata.modifier_price)) && (
                                            <div
                                                style={{
                                                    fontStyle: 'italic'
                                                }}
                                            >
                                                ${parseFloat(mdata?.modifier_price)?.toFixed(2)}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {mdata?.modifier_option?.map((mdata1) => {
                                    if (!opt?.includes(mdata1?.id)) {
                                        return
                                    }
                                    return (
                                        <div
                                            style={{
                                                marginLeft: 8
                                            }}
                                            className={styles.modifier_data}
                                        >
                                            <div
                                                style={{
                                                    height: 20
                                                }}
                                            >
                                                {/* {mdata1.title} */}
                                            </div>

                                            {Boolean(parseFloat(mdata1.price)) && (
                                                <div
                                                    style={{
                                                        fontStyle: 'italic'
                                                    }}
                                                >
                                                    ${parseFloat(mdata1.price || 0).toFixed(2)}
                                                </div>
                                            )}

                                            {/* {isEditMode && (
                                            <Button
                                                style={{
                                                    color: 'red'
                                                }}
                                                variant="text"
                                                onClick={() => {
                                                    setModifierIdsToRemove((prevState) => {
                                                        let newState = { ...prevState }
                                                        if (newState[mdata?.id]) {
                                                            newState = {
                                                                ...newState,
                                                                [mdata?.id]: [...newState[mdata?.id], mdata1.modifier_option_id]
                                                            }
                                                        } else {
                                                            newState = {
                                                                ...newState,
                                                                [mdata?.id]: [mdata1.modifier_option_id]
                                                            }
                                                        }

                                                        return newState

                                                    })
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        )} */}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </StyledTableCell>

            <StyledTableCell
                style={{
                    verticalAlign: 'baseline'
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {isEditMode ? (
                        <div
                            style={{
                                display: 'flex',
                                boxShadow: '0px 1px 4px #0000001a'
                            }}
                        >
                            <div
                                onClick={e => {
                                    if ((editableQuantity - 1) > 0) {
                                        handleQuantityChange(editableQuantity - 1, 'reduce')
                                    }
                                }}
                                style={{
                                    backgroundColor: "rgb(223,219,220)",
                                    color: "#767273",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <Remove
                                    color="inherit"
                                    style={{ transform: "scale(.75)" }}
                                />
                            </div>
                            <div
                                style={{
                                    margin: '5px 14px'
                                }}
                                className={styles.table_text}
                            >
                                {data.quantity}
                            </div>
                            <div
                                onClick={e => handleQuantityChange(editableQuantity + 1, 'increment')}
                                style={{
                                    backgroundColor: "rgb(223,219,220)",
                                    color: "#767273",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <Add
                                    color="inherit"
                                    style={{ transform: "scale(.75)" }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            className={styles.table_text}
                        >
                            {data.quantity}
                        </div>
                    )}
                </div>
            </StyledTableCell>
            <StyledTableCell
                style={{
                    verticalAlign: 'baseline'
                }}
            >
                <div>
                    <div
                        className={styles.table_text_price}
                    >
                        ${(data.unit_price * data.quantity).toFixed(2)}
                    </div>

                    <div
                        style={{
                            height: 20
                        }}
                    />

                    {data?.modifiers_list?.map((mdata) => {
                        console.log(mdata)
                        const opt = data.modifiers[mdata?.modifier_id]

                        return (
                            <div
                                style={{
                                    marginLeft: 8
                                }}
                            >
                                {Boolean(opt) && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <b
                                            style={{
                                                height: 20
                                            }}
                                        >
                                            {/* {mdata?.modifier_title} */}
                                        </b>

                                        {Boolean(parseFloat(mdata.modifier_price)) && (
                                            <div
                                                style={{
                                                    fontStyle: 'italic'
                                                }}
                                            >
                                                ${parseFloat(mdata?.modifier_price * data.quantity)?.toFixed(2)}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {mdata?.modifier_option?.map((mdata1) => {
                                    if (!opt?.includes(mdata1?.id)) {
                                        return
                                    }
                                    return (
                                        <div
                                            style={{
                                                marginLeft: 8
                                            }}
                                            className={styles.modifier_data}
                                        >
                                            <div
                                                style={{
                                                    height: 20
                                                }}
                                            >
                                                {/* {mdata1.title} */}
                                            </div>

                                            {Boolean(parseFloat(mdata1.price)) && (
                                                <div
                                                    style={{
                                                        fontStyle: 'italic'
                                                    }}
                                                >
                                                    ${parseFloat((mdata1.price || 0) * data.quantity).toFixed(2)}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </StyledTableCell>

            {order?.status === "o" ? (
                <StyledTableCell>
                    <div style={{ display: "flex", gap: 4 }}>
                        {isEditMode && (
                            <Button
                                variant="text"
                                style={{
                                    color: '#E40000'
                                }}
                                onClick={handleDelete}
                            >
                                Remove
                            </Button>
                        )}
                    </div>

                    <Dialog
                        open={subsitute}
                        onClose={() => setSubsitute(false)}
                    >
                        <EditOrderProductDialog
                            suggestions={suggestions}
                            editableName={editableName}
                            editableFormat={editableFormat}
                            editableQuantity={editableQuantity}
                            quantity={data.quantity}
                            isLoading={isLoading}
                            formats={formats}
                            handleSave={handleSave}
                            onClose={() => setSubsitute(false)}
                            handleProductNameChange={handleProductNameChange}
                            handleFormatChange={handleFormatChange}
                            handleQuantityChange={handleQuantityChange}
                        />
                    </Dialog>
                </StyledTableCell>
            ) : null}

            <Dialog
                open={isCustomize}
                onClose={() => {
                    setIsCustomize(false)
                    setCustomizeObj({})
                }}
            >
                <ModifierDialog
                    customizeObj={customizeObj}
                    isEdit={true}
                    quantityById={quantityById}
                    setQuantityById={setQuantityById}
                    // setSelectedFormat={setSelectedFormat}
                    // selectedFormat={selectedFormat}
                    validateCartValue={(data) => {

                        setOrderDetails((prevState) => {
                            let newState = { ...prevState }
                            if (newState?.order_details) {
                                newState.order_details[index] = data
                            }
                            return newState
                        })
                    }}
                    onClose={() => {
                        setIsCustomize(false)
                        setCustomizeObj({})
                    }}
                />
            </Dialog>
        </StyledTableRow>
    );
};

export default OrderItem;
