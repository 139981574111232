import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import axios from '../../../utils/axiosConfig'
import InputField from '../../Library/InputField/InputField'
import styles from './ShowPopupFEDialog.module.scss'

const ShowPopupFEDialog = (props) => {
    const [logo, setLogo] = useState('')
    const [title, setTitle] = useState('')
    const [showAddButton, setShowAddButton] = useState(false)
    const [buttonText, setButtonText] = useState('')
    const [buttonUrl, setButtonUrl] = useState('')
    const [popUpId, setPopUpId] = useState()

    const fetchPopup = () => {
        axios.get('/venue-popup/list')
            .then((response) => {

                if(response.data?.data?.result?.id){
                    setPopUpId(response.data?.data?.result?.id)
                    setTitle(response.data?.data?.result?.title)
                    setButtonText(response.data?.data?.result?.button?.text)
                    setButtonUrl(response.data?.data?.result?.button?.url)
                }
            })
    }

    useEffect(() => {
        if (props.open) {
            axios.get('/venue/steps/branding')
                .then((response) => {
                    setLogo(response.data[0]?.original?.data?.result?.logo)
                })

            fetchPopup()
        }
    }, [props])

    const onSubmit = () => {
        axios.post(popUpId ? `/venue-popup/edit/${popUpId}` : '/venue-popup/create', {
            title: title,
            button: {
                text: buttonText,
                url: buttonUrl
            }
        })
            .then(() => {
                toast.success('Popup Saved')
                props.onClose()
            })
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
        >
            <DialogTitle
                align="center"
            >
                Your popup look like this.
            </DialogTitle>
            <DialogContent>
                <div
                    className={styles.content_container}
                >
                    <img
                        src={logo}
                        height={50}
                    />

                    <InputField
                        placeholder="Add title"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                        showIconButton={false}
                    />

                    <div
                        style={{
                            marginTop: 15
                        }}
                    >
                        {buttonText ? (
                            <Button
                                style={{
                                    backgroundColor: '#EF2D2C',
                                    color: 'white'
                                }}
                                variant="contained"
                                onClick={() => setShowAddButton(true)}
                            >
                                {buttonText}
                            </Button>
                        ) : (
                            <Button
                                style={{
                                    backgroundColor: '#676767',
                                    color: 'white'
                                }}
                                variant="contained"
                                onClick={() => setShowAddButton(true)}
                            >
                                Add Button
                            </Button>
                        )}
                    </div>

                </div>

                <Dialog
                    open={showAddButton}
                    onClose={() => setShowAddButton(false)}
                >
                    <DialogContent
                        style={{
                            padding: 25
                        }}
                    >
                        <div>
                            <div>
                                Button Text
                            </div>
                            <InputField
                                placeholder=""
                                value={buttonText}
                                onChange={(e) => {
                                    setButtonText(e.target.value)
                                }}
                                showIconButton={false}
                            />
                        </div>

                        <div
                            style={{
                                marginTop: '20px'
                            }}
                        >
                            <div>
                                Button Url
                            </div>
                            <InputField
                                placeholder=""
                                value={buttonUrl}
                                onChange={(e) => {
                                    setButtonUrl(e.target.value)
                                }}
                                showIconButton={false}
                            />
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            style={{
                                backgroundColor: 'black',
                                color: 'white'
                            }}
                            variant="contained"
                            onClick={() => setShowAddButton(false)}
                        >
                            Save
                        </Button>

                        <Button
                            style={{
                                backgroundColor: '#DDDDDD',
                                color: 'black'
                            }}
                            variant="contained"
                            onClick={() => setShowAddButton(false)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContent>
            <DialogActions>
                <Button
                    style={{
                        backgroundColor: 'black',
                        color: 'white'
                    }}
                    variant="contained"
                    onClick={onSubmit}
                >
                    Submit
                </Button>

                <Button
                    style={{
                        backgroundColor: '#dddddd',
                        color: 'black'
                    }}
                    variant="contained"
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ShowPopupFEDialog