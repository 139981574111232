import { bizBaseurl } from '../../env';
import axios from '../../utils/axiosConfig';

export const getOrderDetails = (params) => {
    try {
        return async (dispatch) => {
            //should it be changed to baseurl?
            let { data } = await axios.get(`/driver/get-order-detail/${params.id}`);
            dispatch({
                type: "SET_ORDER_DETAILS",
                payload: data
            })
        }
    } catch (err) {
        throw err;
    }
};

export const getSuggestions = (params) => {
    try {
        return async (dispatch) => {
            dispatch({
                type: "SET_ORDER_SUGGESTIONS_REQUEST",
            });

            let { data } = await axios.get('/product/my-list', {
                params: {
                    page: 1,
                    limit: 10,
                    q: params.name,
                    category_id: params.category_id,
                    sort_by: params.sort_by,
                    sort_by_order: params.sort_by_order
                }
            })
            let inventories = data.data.result;
            // let pagination = data.data.pagination;

            dispatch({
                type: "SET_ORDER_SUGGESTIONS",
                payload: inventories
            });
        }
    } catch (err) {
        throw err;
    }
};

export const getProductFormats = (params) => {
    try {
        return async (dispatch) => {
            let { data } = await axios.get(`/venue/get-product-format/${params.productID}`);
            let { result } = data.data;
            dispatch({
                type: "SET_PRODUCT_FORMATS",
                payload: result
            });
        }
    } catch (err) {
        throw err;
    }
};