const initialState = {
    orders: [],
    pagination: [],
    totals: [{}],
    isLoading: true,
};
function orders(state = initialState, action) {
    switch (action.type) {
        case "SET_ORDERS_REQUEST":
            return {
                ...state,
                isLoading: true
            };
        case "SET_ORDERS":
            return {
                ...state,
                isLoading: false,
                ...action.payload
            };
        case "CHANGE_ORDER_STATUS":
            let {id,status,currentStatus,update} = action.payload;
            let index = state.orders.findIndex(i => i.Id === id);
            let newOrders = [...state.orders];
            if(update){
                newOrders[index] = {
                    ...newOrders[index],
                    status: status
                };
            }else{
                newOrders.splice(index,1);
            };
            let statusNames = {
                'o': 'open',
                'clo': 'closed',
                'a': 'accept',
                'r': 'ready',
                'can': 'cancel'
            };
            let newTotals = {
                ...state.totals[0],
                [statusNames[status]]: `${parseInt(state.totals[0][statusNames[status]]) + 1}`,
                [statusNames[currentStatus]]: `${parseInt(state.totals[0][statusNames[currentStatus]]) - 1}`
            }
            return {...state, orders: newOrders, totals: [{...newTotals}]}
        default:
            return state;
    }
};

export default orders;