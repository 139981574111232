import swal from 'sweetalert2';

const Alert = (title,text,type,otherProps) => {
    let setter = {}
    if(otherProps){
        setter = {
            title: title, 
            text: text, 
            icon: type,
            ...otherProps
        }
    }else{
        setter = {
            title: title, 
            text: text, 
            icon: type
        }
    }
    let promise = swal.fire(setter);
    return promise;
};

export default Alert;