import { format } from "date-fns"
import imageBaseUrl from "../components/CustomPageBuilder/EditSection/Util/imageBaseUrl"

export const formatWithComma = (number) => parseFloat(number)?.toFixed(2)?.toLocaleString(undefined, { maximumFractionDigits: 2 })

export const handleInsideAttrChange = ({ model, key, value, tag }) => {
    if (model?.find(tag)?.length) {
        const newmodel = model?.find(tag)[0]
        const attr = newmodel.getAttributes()
        newmodel.setAttributes({ ...attr, [key]: value })
    } else {
        const attr = model.getAttributes()
        model.setAttributes({ ...attr, [key]: value })
    }
}

export const getImage = (name) => `https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/${name}`
export const getImage2 = (name) => `https://d36musakzcdau7.cloudfront.net/esplanda/PageComponent/${name}`
export const getImage3 = (name) => `${imageBaseUrl}/ProductProperty/${name}`

export const convertToId = (data) => {
    // Extract the top-level id
    let result = {}

    data?.forEach(element => {
        // Map each option's id to the top-level id
        result = {
            ...result,
            [element.modifier_id]: element.options?.map(option => option.modifier_option_id)
        };
    });

    return result;
}

export const formatDate = (date, dateFormat) => {
    try {
        return format(new Date(date), dateFormat)
    } catch (error) {
        return ''
    }
}


export const cutExtraChars = (string, length) => {
    return String(string).length > (length || 70)
        ? String(string).substring(0, (length || 70)) + '...'
        : string;
};

export const convertTo24HourFormatWithoutSpace = (timeString) => {
    // Extract the hours, minutes, and modifier (AM/PM) using a regular expression
    const match = timeString.match(/(\d+):(\d+)(AM|PM)/i);
    if (!match) {
        throw new Error('Invalid time format');
    }

    let [_, hours, minutes, modifier] = match;

    // Convert the hours part to a number for comparison and adjustment
    hours = parseInt(hours, 10);

    // If the time is PM and not 12 PM, add 12 to the hours to convert to 24-hour format
    if (modifier.toUpperCase() === 'PM' && hours !== 12) {
        hours += 12;
    }
    // If the time is AM and exactly 12 AM, adjust hours to 00
    else if (modifier.toUpperCase() === 'AM' && hours === 12) {
        hours = 0;
    }

    // Ensure hours are formatted as a two-digit number
    if (hours < 10) {
        hours = '0' + hours;
    }

    // Return the formatted time string in 24-hour format
    return `${hours}:${minutes}`;
}


export const getInitialsAvatar = (name) => {
    return `https://ui-avatars.com/api/?background=random&length=1&name=${name}`;
};

export const removeNull = (string) => {
    if (string) {
      return string?.replace(/null/g, '');
    } else {
      return '';
    }
  };
  

export const downloadCSV = (csvString, filename) => {
    const blob = new Blob([csvString], { type: 'text/csv' });
    const link = document.createElement('a');
    link.style = 'display: none;';
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body?.appendChild(link);
    link.click();
};

export const getUTCDateObj = (date) => {
    const now = new Date(date);
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth();
    const day = now.getUTCDate();
    const hour = now.getUTCHours();
    const min = now.getUTCMinutes()

    const tomorrowUTC = new Date();
    tomorrowUTC.setDate(day);
    tomorrowUTC.setYear(year);
    tomorrowUTC.setMonth(month);
    tomorrowUTC.setHours(hour);
    tomorrowUTC.setMinutes(min);

    return tomorrowUTC
}

export const generateDeliveryPickupSlot = (deliveryDate, timeSlot) => {
    const date = new Date(deliveryDate).getUTCDate();
    const month = new Date(deliveryDate).getUTCMonth();
    const year = new Date(deliveryDate).getUTCFullYear();

    const dayName = getUTCDateObj(deliveryDate)?.toLocaleDateString("en-US", {
        weekday: "long",
    });

    return `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${date < 10 ? `0${date}` : date
        } ${timeSlot} ${dayName}`;
};



export const  EMAIL_VERIFICATION_CONSTANTS = {
    UNINITIATED: 0,
    VERIFIED: 1,
    PENDING: 2,
    FAILED: 3
}


export const getName = (user) => {
    return `${user?.first_name} ${removeNull(user?.last_name)}`;
  };