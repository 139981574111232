import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import UploadInventoryimg from "../../../assets/Upload-Inventory.png";
import routePaths from '../../../config/route-paths';
const UploadInventory = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Product & Inventory               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.addNewProduct}>How to Add New Products</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.createNewTags} >Create New Tags</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.assigntagstoproduct}>Assign Tag to Product</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.uploadInventory} className={cn('selected')}>Upload Inventory</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.searchProductbyCategory}>Search Product by Category</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.editProductAttributes}>Edit Product Attributes</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.changingItemVisiblity}>Changing Item Visiblity</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Upload Inventory      
         </Typography>
         <p><strong>Adding all of your products individually can take quite some time. You can upload an inventory file from Microsoft Excel or Google Sheets
to upload all your inventory at once.</strong></p>

<span className={cn('addnewproductsimg')}>
    <img src={UploadInventoryimg} />
    </span>
         
         <p>
            
         <ol>
            <li>In the ‘<strong>My Inventory</strong>’ page, click the ‘<strong>Download Template</strong>’ button on the right side of the page. It will download an example CSV file
which can be opened with Microsoft Excel or Google Sheets.</li>
   

    <li>Make sure your existing inventory file for your store matches the template you just downloaded. The number of columns needs to
match and the names of these columns need to match as well.</li>

<li>Back on the ‘<strong>My Inventory</strong>’ page, click the ‘<strong>Upload Inventory File</strong>’ button next to the ‘<strong>Download Template</strong>’ button. You can now select
your inventory file from your computer and upload it.</li>

         </ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default UploadInventory