import React, { useEffect, useState } from 'react'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'
import styles from './BusinessInfo.module.scss'
import LiquorLicense from './LiquorLicense/LiquorLicense'
import axios from '../../utils/axiosConfig'
import { useOutletContext } from 'react-router-dom'
import MicroWebsite from './MicroWebsite/MicroWebsite'
import SocialMedia from './SocialMedia/SocialMedia'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import AppLinks from './AppLinks/AppLinks'

const BusinessInfo = () => {

  const { license, removeLicenceImage, venueDetails, fetchStoreInfo, fetchLiquorLicense, isActionsDisabled } = useOutletContext()

  const [isLoading, setIsLoading] = useState(false)
  const [licenseFile, setLicenseFile] = useState()
  const [licenseNumber, setLicenseNumber] = useState(license?.licence_no)
  const [externalWebsite, setExternalWebsite] = useState('')

  useEffect(() => {
    setLicenseNumber(license?.licence_no)
  }, [license])

  const formik = useFormik({
    initialValues: {
      ...venueDetails
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsLoading(true)
      axios.post('/venue/setting', values)
        .then(() => {
          toast.success('Saved Successfully')
          // setIsEditMode(false)
        })
        .finally(() => {
          setIsLoading(false)
          fetchStoreInfo()
        })

      if (licenseNumber) {
        let formData = new FormData();
        if (licenseFile) {
          formData.append('file', licenseFile);
        }
        if (licenseNumber) {
          formData.append('licence_no', licenseNumber);
        }

        axios.post('/venue/steps/licence-info', formData)
          .then(() => {
            fetchLiquorLicense()
          })
      } else if (!licenseNumber && licenseFile) {
        toast.error('Please enter license number')
      }

      if (externalWebsite) {
        axios.post('/venue/external-url', {
          external_url: externalWebsite
        })
          .then(() => {

          })
      }
    }
  })

  return (
    <div
      className={styles.container}
    >
      <div
        className={styles.box_containers}
      >
        <div
          className={styles.liquor_license_container}
        >
          <LiquorLicense
            license={license}
            removeLicenceImage={removeLicenceImage}
            licenseFile={licenseFile}
            licenseNumber={licenseNumber}
            setLicenseFile={setLicenseFile}
            setLicenseNumber={setLicenseNumber}
          />
        </div>

        <div
          className={styles.micro_website_container}
        >
          <MicroWebsite
            venueDetails={venueDetails}
            externalWebsite={externalWebsite}
            setExternalWebsite={setExternalWebsite}
          />
          <AppLinks
            formik={formik}
          />
        </div>

        <div
          className={styles.social_media_container}
        >
          <SocialMedia
            formik={formik}
          />
        </div>
      </div>

      <div
        className={styles.save_btn_container}
      >
        <GenericLoadingButton
          variant="contained"
          type="button"
          onClick={formik.handleSubmit}
          size="large"
          loading={isLoading}
          loadingPosition="end"
          disabled={isActionsDisabled}
        >
          Save
        </GenericLoadingButton>
      </div>
    </div>
  )
}

export default BusinessInfo