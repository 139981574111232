import { Box } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from '../../utils/axiosConfig'
import Loader from '../Library/Loader'

const MarketingRoutes = () => {

    const confirm = useConfirm()
    const user = useSelector((state) => state.user);
    const [pages, setPages] = useState([])
    const [names, setNames] = useState([])
    const [isFBLoading, setisFBLoading] = useState(true)
    const [isSocialListLoading, setisSocialListLoading] = useState(true)
    const [isEmailConnected, setIsEmailConnected] = useState(false)
    const [externalWebsite, setExternalWebsite] = useState('')
    const [subDomain, setSubDomain] = useState('')

    const fetchNewSocialData = () => {
        return axios.post('/venue-social-page-insights/update')
    }

    const fetchNewMailChimpData = () => {
        return axios.post('/mailchimp-campaign/update-analytics')
    }

    const fetchFbList = () => {
        axios.get('/venue-setting/fb-page-list')
            .then((response) => {
                setPages(response.data.data.data.map((data) => ({ ...data, isFacebook: true })))

                let instaDetails = response.data.data.data.filter((data) => data.instagram_business_account)
                if (instaDetails.length) {
                    instaDetails.map((data) => {
                        setPages((prevState) => [...prevState, { name: data.instagram_business_account?.name, isInstagram: true, id: data.instagram_business_account?.id }])
                    })
                }
            })
            .catch(() => {
                setPages([])
            })
            .finally(() => {
                setisFBLoading(false)
            })

    }

    const fetchSocialList = (cb) => {
        axios.get('/venue-setting/social-list')
            .then((response) => {
                setExternalWebsite(response?.data?.data?.result?.external_website)
                setSubDomain(response?.data?.data?.result?.sub_domain)

                if (response?.data?.data?.result?.mail_chimp_api_key) {
                    setIsEmailConnected(true)
                } else {
                    setIsEmailConnected(false)
                }

                if (response?.data?.data?.result?.tw_oauth_token) {
                    console.log('twiter', response?.data?.data?.result?.tw_oauth_token)
                    setNames((prevState) => [...prevState, { id: response?.data?.data?.result?.tw_oauth_token, name: response?.data?.data?.result?.tw_screen_name, isTwitter: true }])
                    console.log('twiter', response?.data?.data?.result?.tw_oauth_token)
                } else {
                    setNames([])
                }

                cb()
            })
            .catch(() => {
                // setNames([])
            })
            .finally(() => {
                setisSocialListLoading(false)
            })
    }

    const handleFBDisconnect = (cb) => {
        confirm({
            title: 'Do you wish to disconnect Facebook/Instagram',
        })
            .then(() => {
                axios.post('/venue-setting/disconnect-fb')
                    .then(() => {
                        fetchFbList()
                        toast.success('Facebook Disconnected')
                        cb()
                    }).catch(() => {
                        toast.error('Something went wrong')
                    })
            })
            .catch(() => {

            });
    }

    const handleTwitterDisconnect = (cb) => {

        confirm({
            title: 'Do you wish to disconnect Twitter',
        })
            .then(() => {
                axios.post('/venue-setting/disconnect-twitter')
                    .then(() => {
                        fetchSocialList()
                        toast.success('Twitter Disconnected')
                        cb()
                    })
                    .catch(() => {
                        toast.error('Something went wrong')
                    })
            })
            .catch(() => {

            });
    }

    const handleMailChimpDisconnect = (cb, setIsMailChimpLoading) => {

        confirm({
            title: 'Do you wish to disconnect Mailchimp',
        })
            .then(() => {
                axios.post('/mailchimp-campaign/disconnect')
                    .then(() => {
                        fetchSocialList(cb)
                        toast.success('Mailchimp Disconnected')
                    })
                    .catch(() => {
                        toast.error('Something went wrong')
                    })
            })
            .catch(() => {
                setIsMailChimpLoading(false)
            });
    }

    const fetchAll = () => {
        fetchNewSocialData()
            .then(() => {
                fetchFbList()
                fetchNewMailChimpData()
                fetchSocialList()
            })
            .catch(() => {
                fetchFbList()
                fetchNewMailChimpData()
                fetchSocialList()
            })
    }

    // useEffect(() => {
    //     fetchAll()
    // }, [])

    return (
        // (isFBLoading || isSocialListLoading) ? (
        //     <Loader />
        // ) : (
            <Box
                sx={{
                    padding: {
                        xs: '0px 5px',
                        sm: '0px 20px'
                    }
                }}
            >
                <Outlet
                    // context={{
                    //     isActionsDisabled: user?.data?.is_claim_business === 1,
                    //     isOneConnected: !(names.length === 0) || !(pages.length === 0),
                    //     pages,
                    //     names,
                    //     isEmailConnected,
                    //     externalWebsite,
                    //     subDomain,
                    //     fetchFbList,
                    //     fetchSocialList,
                    //     handleFBDisconnect,
                    //     handleTwitterDisconnect,
                    //     handleMailChimpDisconnect,
                    //     fetchNewSocialData,
                    //     fetchNewMailChimpData,
                    //     fetchAll
                    // }}
                />
            </Box>
        )
    // )
}

export default MarketingRoutes