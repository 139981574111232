import React, { useContext, createContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const AlertContext = createContext(null);

export const AlertContextProvider = ({ children }) => {
  const [modal, setModal] = useState({ message: null, isOpen: false, severity:''});

  const { message, isOpen,severity } = modal;

  const handleClose = () => {
    setModal({ ...modal, isOpen: false, message: null });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <AlertContext.Provider value={{ modal, setModal }}>
        {children}
      </AlertContext.Provider>
    </>
  );
};

export const useAlert = () => {
  const { modal, setModal } = useContext(AlertContext);
  return { modal, setModal };
};
