import { buttonBaseClasses, typographyClasses } from '@mui/material';
import { styled } from '@mui/material/styles'
import { dateCalendarClasses, dayPickerClasses, pickersDayClasses, pickersSlideTransitionClasses, StaticDatePicker } from '@mui/x-date-pickers';
// import { calendarOrClockPickerClasses, pickerStaticWrapperClasses } from '@mui/x-date-pickers/internals';

export const StyledStaticDatePicker = styled(StaticDatePicker)({
    [`& .${dateCalendarClasses.root}`]: {
        width: 500,
        maxHeight: 'unset',
        margin: 0
    },
    // [`& .${pickerStaticWrapperClasses.content}`]: {
    //     width: 500,
    // },
    // [`& .${calendarOrClockPickerClasses.root}`]: {
    //     width: 500,
    // },
    // [`& .${calendarOrClockPickerClasses.root} > div`]: {
    //     width: 500,
    //     maxHeight: 'unset',
    //     margin: 0
    // },
    [`& .${typographyClasses.root}.${dayPickerClasses.weekDayLabel}`]: {
        width: 60,
        height: 60,
        fontSize: 20
    },
    [`& .${pickersSlideTransitionClasses.root}.${dayPickerClasses.slideTransition}`]: {
        minHeight: 380
    },
    [`& .${buttonBaseClasses.root}.${pickersDayClasses.root}`]: {
        width: 60,
        height: 60,
        fontSize: 20
    },
});