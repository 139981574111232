import React, { useRef, useState } from 'react'
import { Cancel, Close, Delete, Edit } from '@mui/icons-material'
import { Button, DialogContent, DialogTitle, FormControlLabel, FormHelperText, IconButton, InputAdornment, Tooltip, outlinedInputClasses } from '@mui/material'
import { useFormik } from 'formik'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import styles from './AddDietaryAttributesDialog.module.scss'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import axios, { nodeAxios } from '../../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { getImage2, getImage3 } from '../../../../../utils/helpers'


const AddDietaryAttributesDialog = (props) => {

    const imageRef = useRef()

    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: props.isEdit ? props?.editObj : {
            title: '',
            image_file: '',
            image_link: '',
        },
        // enableReinitialize: true,
        onSubmit: (values) => {
            setIsLoading(true)

            const fd = new FormData()
            Object.entries(values).forEach(([key, value]) => {
                fd.append(key, value)
            })

            axios.post(props.isEdit ? `/product-property/edit/${props?.editObj?.id}` : '/product-property/create', fd)
                .then((res) => {
                    nodeAxios.post('/change-log/create', { what: `${values.title} is ${props.isEdit ? 'edited' : 'added'}` })
                        .then(() => {

                        })
                        .catch((error) => {
                            console.log('🚀OUTPUT --> error:', error);
                        });
                    if (props.onSuccess) {
                        props.onSuccess()
                    }
                    props.onClose()
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    })

    console.log(formik)
    return (
        <>
            <DialogTitle
                align='left'
                fontWeight={600}
                fontSize={14}
                borderBottom="1px solid #D3D3D3"
                className='tw-flex tw-items-center tw-justify-between !tw-text-base !tw-py-1 !tw-px-4 sm:!tw-px-5 !tw-mb-4 !tw-mt-[5px] sm:!tw-mt-0'
            >
                {props.isEdit ? "Edit Attribute" : "Add Attribute"}
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{ color: '#000' }}
                >
                    <Cancel />
                </IconButton>
            </DialogTitle>

            <DialogContent className='!tw-px-4 sm:!tw-px-5'>
                <input
                    style={{
                        display: 'none'
                    }}
                    ref={imageRef}
                    type="file"
                    onChange={(event) => {
                        formik.setFieldValue('image_file', event.target.files[0])
                        formik.setFieldValue('image_link', URL.createObjectURL(event.target.files[0]))
                        formik.setFieldValue('image_file_remove', 0)
                        event.target.value = ''
                    }}
                />
                <div
                    className={styles.form_container}
                >
                    <div
                        className={styles.title_container}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                                position: 'relative'
                            }}
                            onClick={() => {
                                imageRef.current?.click()
                            }}
                        >
                            <img
                                style={{
                                    minWidth: 150,
                                    minHeight: 150,
                                    maxWidth: 150,
                                    maxHeight: 150,
                                    objectFit: 'cover'
                                }}
                                src={formik.values.image_link || (formik.values.image && getImage3(formik.values.image)) || getImage2('addimage.png-1699973235.png')}
                                height={100}
                            />

                            {(Boolean(formik.values.image_file) || Boolean(formik.values.image)) && (
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        padding: 2
                                    }}
                                    onClick={(event) => {
                                        formik.setFieldValue('image_file_remove', 1)
                                        formik.setFieldValue('image_file', '')
                                        formik.setFieldValue('image_link', '')
                                        formik.setFieldValue('image', '')
                                        event.stopPropagation()
                                    }}
                                >
                                    <Delete
                                        style={{
                                            position: 'absolute',
                                            backgroundColor: 'white',
                                            padding: 2,
                                            left: 0,
                                            bottom: 3
                                        }}
                                    />
                                </div>
                            )}

                            <Edit
                                style={{
                                    position: 'absolute',
                                    backgroundColor: 'white',
                                    padding: 2,
                                    right: 0,
                                    bottom: 3
                                }}
                            />
                        </div>

                        {/* <div className={styles.title}>
                            {props.isEdit ? "Edit Proterties" : "Add Properties"}
                        </div> */}
                    </div>

                    <GenericTextFieldWithLabel
                        id="title"
                        name="title"
                        label="Attribute Name"
                        placeholder="Enter attribute name"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        fullWidth={true}
                        required={true}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />
                </div>
            </DialogContent>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: 10,
                    gap: 4
                }}
            >

                <Button
                    style={{
                        backgroundColor: '#d3d3d3',
                        color: 'black'
                    }}
                    variant="text"
                    className="!tw-w-full lg:!tw-w-auto !tw-rounded-3xl"
                    disableElevation
                    onClick={() => {
                        props.onClose()
                    }}
                >
                    Cancel
                </Button>

                <GenericLoadingButton
                    variant="contained"
                    loading={isLoading}
                    loadingPosition="end"
                    type="submit"
                    className="!tw-w-full lg:!tw-w-auto !tw-rounded-3xl"
                    onClick={formik.handleSubmit}
                >
                    Save
                </GenericLoadingButton>
            </div>
        </>
    )
}

export default AddDietaryAttributesDialog