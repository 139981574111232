import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import storelist from "../../../assets/store-list.png";
import listicon from "../../../assets/list-text.png";
import fulfillmentmethodsimg from "../../../assets/Fulfillment-Methods.png";
import routePaths from '../../../config/route-paths';
const FulfillmentMethods = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={storelist} />
            </div>
            Store Details             
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.StoreDetails}>Store Details</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.FulfillmentMethods} className={cn('selected')}>Fulfillment Methods</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.BankInformation}>Bank Information</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.LiquorLicense}>Business License</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.SubmitLogo}>Submit Logo</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.POSIntegration}>POS Integration</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AccessYourWebsite}>Access Your Website</Link></li>
        </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Fulfillment Methods
         </Typography>
       
         <p>
            
         <ol>
            <li>Go to the ‘<strong>My Store Settings</strong>’ section of the admin website.</li>

           

            <li>Click on ‘<strong>Fulfillment Methods</strong>’ on the left, below ‘<strong>Store Details</strong>’. Here you will see all the options for customizing your pickup,
delivery, and shipping methods.</li>

<span className={cn('addnewproductsimg')}>
    <img src={fulfillmentmethodsimg} />
    </span>

                  

    <li>The page defaults to delivery methods first. Checking the ‘<strong>Add Platform Service Charge</strong>’ box will pass on your service
fees to your customer.</li>
  

    <li>Next, you will be able to configure your delivery zones and fees. The Deliver From and Deliver To parameters will indicate the delivery
zone, and for each zone, you can set different fees. You can choose to set a fixed dollar amount or a percentage of each order to be the
delivery fee, as well as the minimum delivery amount.</li>

<li>To add new delivery zones, you can click ‘<strong>Add New Zone</strong>’. To remove a delivery zone, click the black trash can icon to the right
of the zone you wish to remove.</li>

<li>Below, you will be able to set your hours for delivery. Toggle which days you are available for delivery, and choose the hours by
clicking on the small clock icons next to the times.</li>

<li>Finally, you can restrict your deliveries to certain states. This function is useful for stores that border other states. If you do not
hold the necessary licenses to sell alcohol in other states, you can limit orders to only your home state.</li>

<li>To configure pickup methods, you can click on ‘Pickup’ at the top, next to ‘Delivery’. Many of the same options can be customized,
including adding a pickup fee if you would like. To configure shipping methods, click ‘Shipping’.</li>

<li>When you are finished configuring your fulfillment methods, click ‘<strong>Save</strong>’ in the bottom-right corner.</li>

       
   

</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default FulfillmentMethods