import axios from '../utils/axiosConfig';
import { bizBaseurl } from '../env';

export const uploadCsv = async (file) => {
    try {
        let formData = new FormData();
        formData.append("import_file", file);
        let { data } = await axios.post(`/inventory/upload`, formData);
        return data.message;
    } catch (err) {
        throw err;
    };
};

export const deleteInventory = async (id) => {
    try {
        await axios.get(`/product/delete-my-product/${id}`);
        return;
    } catch (err) {
        throw err;
    };
};

export const addInventory = async (body) => {
    try {
        let fd = new FormData()
        for (const [key, value] of Object.entries(body)) {
            fd.append(key, value)
        }
        await axios.post(`/product/create-my-product`, fd);
        return;
    } catch (err) {
        throw err;
    };
};