import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import {
  ProductListCategoryList,
  CategoryListCategoryList,
} from "./CategoryList";
import LightTooltip from "../../../../util_comps/LightTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    height: 310,
  },
  content: {
    margin: 0,
  },
  expanded: {
    margin: '0 !important',
  },
  hideBoxShadow: {
    boxShadow: "none",
  },
  formControl: {
    marginRight: `0 !important`,
    marginBottom: `0 !important`,
    marginLeft: `0 !important`,
  },
  tagListAccordion: {
    "&:before": {
      display: "none",
    },
  },
});

export const ProductListCategories = (props) => {

  const [activePanel, setActivePanel] = useState("none");
  const classes = useStyles();
  const handlePanelOpen = (panelName) => {
    if (panelName === activePanel) {
      setActivePanel("none");
    } else {
      setActivePanel(panelName);
    }
  };
  const handleSelect = (e, val) => {
    if (e.target.checked) {
      props.setCategories([]);
      props.setMainCategory(val.MainCatId);
    } else {
      props.setMainCategory(1);
    }
  };
  return (
    <div
      style={{ border: "1px solid lightgrey" }}
      className={classes.root}
    >
      {props.mainCategories.map((val, i) => {
        let active = false;
        let getChild = (val) => {
          if (val.child) {
            for (let cat of val.child) {
              if (
                props.selectedCategories.findIndex(
                  (i) => i.category_id === cat.MainCatId
                ) !== -1
              ) {
                active = true;
                break;
              } else {
                getChild(cat);
              }
            }
          }
        };
        getChild(val);
        return (
          <Accordion
            className={classes.hideBoxShadow}
            onChange={() => handlePanelOpen(val.MainCatName)}
            expanded={activePanel === val.MainCatName}
            style={{ margin: "0 !important" }}
          >
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Radio
                    checked={props.mainCategory === val.MainCatId}
                    onChange={(e) => handleSelect(e, val)}
                    style={{ color: "#6C757D" }}
                  />
                }
                label={
                  <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Choose a category to display its products.</span>} arrow placement="top">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "16px",
                        color: active ? "red" : "black",
                      }}
                    >
                      {val.MainCatName}
                    </Typography>
                  </LightTooltip>
                }
              />
            </AccordionSummary>

            <AccordionDetails
              style={{ padding: '0 8px 0px 16px' }}
            >
              <ProductListCategoryList
                mainCategory={props.mainCategory}
                rootCategory={val.MainCatId}
                selectedCategories={props.selectedCategories}
                handleCategorySelection={props.handleCategorySelection}
                handleCategoryRemove={props.handleCategoryRemove}
                categories={val.child}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export const CategoryListCategories = (props) => {
  const [activePanel, setActivePanel] = useState("none");
  const classes = useStyles();
  const handlePanelOpen = (panelName) => {
    if (panelName === activePanel) {
      setActivePanel("none");
    } else {
      setActivePanel(panelName);
    }
  };
  const handleSelect = (e, val, child) => {
    if (
      props.selectedCategories.findIndex(
        (i) => i.category_id === val.category_id
      ) !== -1
    ) {
      props.handleCategoryRemove(val, child);
    } else {
      props.handleCategorySelection(val, child);
    }
  };
  return (
    <div style={{ border: "1px solid lightgrey" }} className={classes.root}>
      <div style={{ padding: "10px" }}>
        {props.mainCategories.map((val, i) => {
          let active = false;
          let getChild = (val) => {
            if (val.child) {
              for (let cat of val.child) {
                if (
                  props.selectedCategories.findIndex(
                    (i) => i.category_id === cat.MainCatId
                  ) !== -1
                ) {
                  active = true;
                  break;
                } else {
                  getChild(cat);
                }
              }
            }
          };
          getChild(val);
          let toggleOn = false;
          let checkboxOn =
            props.selectedCategories.findIndex(
              (i) => i.category_id === val.MainCatId
            ) !== -1;
          if (val.child && val.child.length > 0 && !checkboxOn) {
            for (let child of val.child) {
              if (
                props.selectedCategories.findIndex(
                  (i) => i.category_id === child.MainCatId
                ) !== -1
              ) {
                toggleOn = true;
                break;
              }
            }
          }
          return (
            <Accordion
              className={classes.hideBoxShadow}
              onChange={() => handlePanelOpen(val.MainCatName)}
              expanded={activePanel === val.MainCatName}
              style={{ margin: "0 !important" }}
            >
              <AccordionSummary
                classes={{
                  content: classes.content,
                  expanded: classes.expanded
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      checked={checkboxOn}
                      onChange={(e) => {
                        handleSelect(
                          e,
                          {
                            categoryName: val.MainCatName,
                            category_id: val.MainCatId,
                            category_image: val.image,
                          },
                          val.child
                        );
                      }}
                    />
                  }
                  label={
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Select a category or click on the arrow on right to choose sub category</span>} arrow placement="top">
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: "14px",
                          color: active ? "red" : "black",
                        }}
                      >
                        {val.MainCatName}
                      </Typography>
                    </LightTooltip>
                  }
                />
                {val.child ? (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "8px",
                      marginLeft: "auto",
                    }}
                  >
                    <LightTooltip
                      title={
                        <span
                          style={{ display: "inline-block", padding: "0 15px" }}
                        >
                          Show Sub Categories Only
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <div
                        style={{
                          marginRight: 9,
                          alignItems: "center",
                          color: "grey",
                          fontSize: "1.3rem",
                          display:
                            i === 0 &&
                              ("ontouchstart" in window ||
                                navigator.msMaxTouchPoints)
                              ? "initial"
                              : "none",
                        }}
                      >
                        <InfoOutlinedIcon fontSize="inherit" color="inherit" />
                      </div>
                    </LightTooltip>
                    <LightTooltip
                      title={
                        <span
                          style={{ display: "inline-block", padding: "0 15px" }}
                        >
                          Show Sub-Categories Only
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <FormControlLabel
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        checked={toggleOn}
                        className={classes.formControl}
                        control={
                          <Switch
                            size="small"
                            /* checked={props.selectedCategories.findIndex(i => i.category_id === val.MainCatId) === -1} */ onChange={(
                              e
                            ) => props.handleToggle(e, val, val.child)}
                          />
                        }
                      />
                    </LightTooltip>
                  </div>
                ) : null}
              </AccordionSummary>
              <AccordionDetails style={{ padding: `0 8px 0px 16px` }}>
                <CategoryListCategoryList
                  handleToggle={props.handleToggle}
                  parentId={val.MainCatId}
                  selectedCategories={props.selectedCategories}
                  handleCategorySelection={props.handleCategorySelection}
                  handleCategoryRemove={props.handleCategoryRemove}
                  categories={val.child}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export const TagList = (props) => {
  const classes = useStyles();

  const handleSelect = (e, val) => {
    // props.setGroupName(val.title)
    if (e.target.checked) {
      props.setSelectedTag(val);
    } else {
      props.setSelectedTag({ id: null });
    }
  };

  return (
    <div style={{ border: "1px solid lightgrey" }} className={classes.root}>
      <div style={{ padding: "10px" }}>

        {props.tags.map((val, i) => {
          let active = false;
          if (props.selectedTag.id === val.id) {
            props.setSelectedTag(val)
          }

          return (
            <Accordion
              className={`${classes.hideBoxShadow}${classes.tagListAccordion}`}
              style={{ margin: "0 !important" }}
            >
              <AccordionSummary
                classes={{
                  content: classes.content,
                  expanded: classes.expanded
                }}
                expandIcon={null}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Radio
                      checked={props.selectedTag.id === val.id}
                      onChange={(e) => handleSelect(e, val)}
                      style={{ color: "#6C757D" }}
                    />
                  }
                  label={
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Select a tag and click on preview to see the products.</span>} arrow placement="top">
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: "16px",
                          color: active ? "red" : "black",
                        }}
                      >
                        {val.title}
                      </Typography>
                    </LightTooltip>
                  }
                />
              </AccordionSummary>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};
