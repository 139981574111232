import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import Customize from "../../../assets/feature.png";
import listicon from "../../../assets/list-text.png";
import addnewimg from "../../../assets/add-new.png";
import custompageimg from "../../../assets/custom-page.png";
import routePaths from '../../../config/route-paths';
const CreateCustomStorePage = (props) => {
   const cn = classname('add-new-product')
   return (
      <div
         className={cns(
            cn(),
            props.className,
         )}>
         <div className={cn('help-page-title')}>
            <div className={cn('title-text')}>
               <Typography variant="h1">
                  Help & Support
               </Typography>
            </div>
            <div className={cn('help-page-title-search')}>
               Search
            </div>
         </div>
         <div className={cn('help-page-body')}>
            <div className={cn('helpdesk-grid')}>
               <div className={cn('left-list')}>
                  <Typography variant="h2">
                     <div className={cn('iconProduct')}>
                        <img src={Customize} />
                     </div>
                     Customize Your Website & App
                  </Typography>
                  <ul className={cn('helpdeskinnerlist')}>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AddNewProductGroups}>How to Add New Product Groups</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropProductGroupInterface}>Drag & Drop Product Group Interface</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingProductGroup}>Edit Existing Product Group</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropBanners}>Drag & Drop Banners</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateNewBannerGroup}>Create New Banner Group</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingBannerGroup}>Edit Existing Banner Group</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateCustomStorePage} className={cn('selected')}>Create Custom Store Page</Link></li>
                  </ul>
               </div>
               <div className={cn('right-body-content')}>
                  <Typography variant="h3">
                     Create Custom Store Page
                  </Typography>

                  <p>

                     <ol>
                        <li>Go to the ‘<strong>Custom Page</strong>’ section of the admin website.</li>

                        <span className={cn('addnewproductsimg2')}>
                           <img src={addnewimg} />
                        </span>

                        <li>Click on the ‘<strong>Add New</strong>’ button on the right side of the page. A pop-up will appear, and you can give your custom page a name. Click ‘<strong>Save</strong>’</li>

                        <span className={cn('addnewproductsimg')}>
                           <img src={custompageimg} />
                        </span>


                        <li>Now you will see a blank page, along with a host of editing tools. Here, you can add <strong>text, images, navigation, forms</strong>, and other content.
                           You can import code as well if you already have a custom website design in <strong>HTML and CSS</strong>. You can drag and drop sections from the
                           right side toolbar onto the blank canvas to create your own layout.</li>


                        <li>The Custom Store Page allows you to create a page for events and promotions, or any other content you wish to display for everyone
                           to see. A link to this page will appear at the footer of your store’s home page, under ‘<strong>Information</strong>’.</li>




                     </ol>
                  </p>
               </div>
            </div>
         </div>
         <div className={cn('answersection')}>
            <Typography variant="h4">
               Still can’t find an answer?
            </Typography>
            <p>Send us a ticket and we will get back to you.</p>
            <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
         </div>
      </div>
   )
}
export default CreateCustomStorePage