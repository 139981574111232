import axios from '../../utils/axiosConfig'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { siteBaseUrl } from '../../utils/baseUrl'
import ClaimMyBusiness from '../ClaimMyBusiness/ClaimMyBusiness'
import Loader from '../Library/Loader'

const ClaimMyBusinessRoute = () => {

    const params = useParams()
    const [details, setDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const fetchStore = () => {
        setIsLoading(true)
        axios.get(`/v2/venue-listing/get/${params.id}`)
            .then((response) => {
                setDetails(response.data.data.result)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (params.id) {
            fetchStore()
        }
    }, [params])

    return (
        isLoading ? <Loader /> : <ClaimMyBusiness details={details} id={params.id} />
    )
}

export default ClaimMyBusinessRoute