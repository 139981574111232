import React, { useState } from 'react';
import styles from './PagesActionMenu.module.scss';
import { Button, Menu, MenuItem } from '@mui/material';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
// import { useNavigate } from 'react-router-dom';
// import axios from '../../../config/axiosConfig';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios, { nodeAxios } from '../../../utils/axiosConfig'

function PagesActionMenu({
  data,
  onDuplicatePage,
  onRemovePage,
  onEditPageDetail,
  fetchTemplate,
  ...props
}) {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [showListMenuEl, setShowListMenuEl] = useState(null);
  const showListMenu = Boolean(showListMenuEl);

  const handleOpenListMenu = (event) => {
    event.stopPropagation();
    setShowListMenuEl(event.currentTarget);
  };
  const handleCloseListMenu = (event) => {
    event.preventDefault();
    setShowListMenuEl(null);
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const handlemakeHomePage = (id) => {
    nodeAxios.post(`/web-builder/web-page/make-homepage/${id}`)
      .then(() => {

      })
      .finally(() => {
        fetchTemplate()
      })
  }

  return (
    <>
      <Button
        id={`list-menu-button-${data.id}`}
        aria-controls={showListMenu ? `list-menu-${data.id}` : undefined}
        aria-haspopup='true'
        aria-expanded={showListMenu ? 'true' : undefined}
        onClick={handleOpenListMenu}
        classes={{ root: styles.btn_root }}
      >
        <MoreVertRoundedIcon />
      </Button>

      <Menu
        id={`list-menu-${data.id}`}
        anchorEl={showListMenuEl}
        open={showListMenu}
        onClose={handleCloseListMenu}
        MenuListProps={{ 'aria-labelledby': `list-menu-button-${data.id}` }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem onClick={onEditPageDetail}>Edit Page Detail</MenuItem>
        {!Boolean(data?.is_homepage) && (
          <MenuItem onClick={() => handlemakeHomePage(data.id)}>Make Home Page</MenuItem>
        )}
        <MenuItem onClick={onDuplicatePage}>Duplicate Page</MenuItem>
        {data.uri != 'index' && (
          <MenuItem onClick={onRemovePage} sx={{ color: '#FF0C0C' }}>
            Remove
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default PagesActionMenu;
