import React from 'react';
import { OpenWith, DeleteOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ProductCardSliderVenue from '../../ProductCardSliderVenue/ProductCardSliderVenue';
import ProductSquareChipSlider from '../../ProductSquareChipSlider/ProductSquareChipSlider';

const CustomizePreview = (props) => {
    const dispatch = useDispatch(data => data);
    const { groupList } = useSelector((store) => store);
    const handleDelete = () => {
        let newSelectedItems = groupList.items.filter((val, i) => (i !== props.index));
        dispatch({
            type: "REORDER_GROUP_LIST",
            payload: newSelectedItems
        });
    };

    return (
        <div
            ref={props.provided.innerRef}
            {...props.provided.draggableProps}
            {...props.provided.dragHandleProps}
            style={{
                userSelect: "none",
                margin: "0 0 8px 0",
                minHeight: "50px",
                ...props.provided.draggableProps.style
            }}
        >
            <div style={{ width: '95%', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {!props.isLaptopView && props.item.showTitle ?
                    <Typography variant="h6" style={{ fontSize: '10px' }}>{props.item.productGroupName.length > 10 ? `${props.item.productGroupName.substring(0, 8)}...` : props.item.productGroupName}</Typography>
                    : null}
                <div style={{ border: '1px solid #DFDADA', marginLeft: 'auto' }}>
                    <OpenWith style={{ color: "black", margin: '2px 4px', fontSize: '15px' }} />
                    <DeleteOutlined onClick={e => handleDelete()} style={{ color: 'black', margin: '2px 4px', cursor: 'pointer', fontSize: '15px' }} />
                </div>
            </div>
            <div style={{ width: "100%", margin: 0, padding: '2px 6px', border: '1px dashed lightgray' }}>
                {props.isLaptopView ? (
                    <div
                        style={{ display: 'flex' }}
                    >
                        {props.item.showTitle ? (
                            <Typography
                                variant="h6"
                                style={{ fontSize: '15px' }}
                            >
                                {props.item.productGroupName.length > 10 ? `${props.item.productGroupName.substring(0, 20)}...` : props.item.productGroupName}
                            </Typography>
                        ) : null}
                    </div>
                ) : null}

                {props.item.productGroupType === "categoryList" ? (
                    <ProductSquareChipSlider
                        products={props.item.data}
                    />
                ) : props.item.productGroupType === "NewsLetter" ? (

                    <div
                        style={{
                            textAlign: 'center',
                            fontWeight: 600,
                            border: '1px solid #dddddd',
                            padding: 15
                        }}
                    >
                        {props.item.productGroupName}
                    </div>

                ) : (
                    <ProductCardSliderVenue
                        isEvent={props.item.productGroupType == 'Event'}
                        products={props.item.data.result}
                        baseImageLink={props.item.data.product_image}
                        isBaseUrl={Boolean(props.item.data.product_image)}
                    />
                )}
            </div>
        </div>
    );
}
export default CustomizePreview;