import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FormFields.module.scss';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios, { nodeAxios } from '../../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import {
  Add as AddIcon,
  Person as PersonIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  ArrowDropDown as ArrowDropDownIcon,
  RadioButtonUncheckedOutlined as RadioButtonUncheckedOutlinedIcon,
  DragIndicator,
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  SwapHoriz,
  Edit,
  DeleteOutline,
} from '@mui/icons-material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useConfirm } from 'material-ui-confirm';
import GenericSelect from '../../../Library/GenericSelect/GenericSelect';
import LinkSelector from '../../../CustomPageBuilder/Library/LinkSelector/LinkSelector';
import ButtonStyleEdit from '../../../Library/ButtonStyleEdit/ButtonStyleEdit';

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'transparent',
  padding: '4px 0',
  // maxHeight: 400,
  overflowY: 'auto',
  width: '100%',
  // maxWidth: 700,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: 'none',
  // padding: '4px 0',
  margin: `0 0 12px 0`,
  // borderRadius: 4,
  // border: '1px solid #D3D3D3',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // gap: 5,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'var(--bg-10)',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function FormFields({ form, ...props }) {
  console.log('🚀OUTPUT --> form:', form);
  // const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [showListMenuEl, setShowListMenuEl] = useState(null);
  const [editField, setEditField] = useState(false);
  const [isEditBtnDialogOpen, setIsEditBtnDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isSavedDialogOpen, setIsSavedDialogOpen] = useState(false);

  const fetchTemplate = () => {
    axios.get('/web-builder/web-page').then((response) => {
      let pageArr = response.data.data.results
        .filter((data) => data.type_id === 3)
        .map((data) => ({ ...data, id: data.id?.toString() }));
      setPages(pageArr);
    });
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  const handleSaveForm = (values) => {
    setIsLoading(true);
    nodeAxios
      .post(`/web-builder/web-page-form/edit/${form?.data?.id}`, values)
      .then((response) => {
        console.log('🚀OUTPUT --> response:', response);

        setIsLoading(false);
        toast.success('Form updated successfully!');
        // props.fetchForm();
        setIsSavedDialogOpen(true);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      buttons: form?.data?.buttons || [
        { title: 'Submit', action: 'save', link_to: 'index' },
      ],
      fields: form?.data?.fields || [],
    },
    // validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log('🚀OUTPUT --> values:', values);
      if (values.fields.length < 1) {
        toast.error('Please add at least one field');
        return;
      }

      handleSaveForm(values);
    },
  });

  const onDragEnd = (result) => {
    console.log('🚀OUTPUT --> result:', result);
    if (!result.destination) {
      return;
    }

    const newitems = reorder(
      formik.values.fields,
      result.source.index,
      result.destination.index
    );

    formik.setFieldValue('fields', newitems);
    // setSocial(newitems);
  };

  console.log('🚀OUTPUT --> formik.values:', formik.values);

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.form_body_container}
      >
        {!Boolean(formik.values.fields.length) ? (
          <div className={styles.add_field_container}>
            <FileIcon />
            <b>Your form doesn’t have any field yet.</b>
            <p style={{ marginBottom: '10px' }}>
              Your form needs at least one field before you can save
            </p>
            <AddFieldMenu
              {...{ showListMenuEl, setShowListMenuEl, setEditField, formik }}
            />
          </div>
        ) : (
          <>
            <div className={styles.child_body_container}>
              <div className={styles.field_wrapper}>
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onDragStart={(e) => console.log('🚀OUTPUT --> e:', e)}
                >
                  <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {formik.values.fields.map((fields, index) => (
                          Array.isArray(fields) ? (
                          <Draggable
                            key={fields[0].id}
                            draggableId={fields[0].id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => {}}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div className='border tw-border-gray tw-rounded-lg tw-flex tw-items-center tw-w-full tw-p-2 tw-gap-2'>
                                  <div className={styles.drag_indicator}>
                                    <IconButton>
                                      <DragIndicator />
                                    </IconButton>
                                  </div>
                                  {/* {fields?.map((field, rowIndex) => (
                                    <FieldItem
                                      {...{
                                        // key: field.id,
                                        field,
                                        index,
                                        rowIndex,
                                        editField,
                                        setEditField,
                                        formik,
                                      }}
                                    />
                                    ))} */}
                                    <FieldRowItem
                                      {...{
                                        // key: field.id,
                                        field : fields[0],
                                        index,
                                        rowIndex : 0,
                                        editField,
                                        setEditField,
                                        formik,
                                      }}
                                    />
                                    {fields.length > 1 && (
                                      <>
                                      <IconButton onClick={() => {
                                        const newFields = [...formik.values.fields];
                                        newFields[index] = [newFields[index][1], newFields[index][0]];
                                        formik.setFieldValue('fields', newFields);
                                      }}>
                                        <SwapHoriz />
                                      </IconButton>
                                        
                                        <FieldRowItem
                                          {...{
                                            // key: field.id,
                                            field : fields[1],
                                            index,
                                            rowIndex : 1,
                                            editField,
                                            setEditField,
                                            formik,
                                          }}
                                        />
                                      </>
                                    )}
                                </div>
                              </div>
                            )}
                          </Draggable>) : (
                          <Draggable
                            key={fields.id}
                            draggableId={fields.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => {}}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div className='border tw-border-gray tw-rounded-lg tw-flex tw-items-center tw-w-full tw-p-2 tw-gap-2'>
                                  <div className={styles.drag_indicator}>
                                    <IconButton>
                                      <DragIndicator />
                                    </IconButton>
                                  </div>
                                    <FieldItem
                                      {...{
                                        key: fields.id,
                                        field : fields,
                                        index,
                                        editField,
                                        setEditField,
                                        formik,
                                      }}
                                    />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div style={{ width: '100%' }}>
                  <AddFieldMenu
                    {...{
                      variant: 'text',
                      showListMenuEl,
                      setShowListMenuEl,
                      setEditField,
                      formik,
                    }}
                  />
                </div>
                <div className='tw-flex tw-items-center tw-justify-end tw-w-full tw-gap-4'>
                  {formik.values.buttons?.length < 2 && (
                      <Button
                        variant='text'
                        color='primary'
                        onClick={() => {
                          formik.setFieldValue('buttons', [
                            { title: 'Clear', action: 'clear'  },
                            ...formik.values.buttons,
                          ]);
                        }}
                      >
                        <AddIcon sx={{ mr: 1 }} /> Add another button
                      </Button>
                  )}
                  {formik.values.buttons?.map((button, index) => (
                    <div className={styles.submitBtnRow}>
                      <Button
                        variant='contained'
                        color='success'
                        onClick={() => setEditField(false)}
                        sx={{
                            textTransform: 'none',
                            whiteSpace: 'nowrap',
                            // backgroundColor: 'black',
                            border: button?.buttonStyles?.button_style === 'outline' ? '2px solid' : 'none',
                            borderColor: button?.buttonStyles?.button_outline_color || 'black',
                            backgroundColor: button?.buttonStyles?.button_background_color || '#00AB63',
                            color: button?.buttonStyles?.button_text_color || 'white',
                            borderRadius: button?.buttonStyles?.button_border_radius ? `${button?.buttonStyles?.button_border_radius}px` : '5px',
                            fontSize: button?.buttonStyles?.button_font_size ? `${button?.buttonStyles?.button_font_size}px` : '14px',
                            px: button?.buttonStyles?.button_horizontal_padding ? `${button?.buttonStyles?.button_horizontal_padding}px` : '16px',
                            py: button?.buttonStyles?.button_vertical_padding ? `${button?.buttonStyles?.button_vertical_padding}px` : '6px',
                            fontFamily: button?.buttonStyles?.button_font_family || 'Roboto',
                            "&:hover": {
                              backgroundColor: button?.buttonStyles?.button_background_color || '#00AB63',
                            }
                        }}
                      >
                        {button?.title || 'Submit'}
                      </Button>
                      <Button
                        variant='text'
                        color='primary'
                        sx={{ ml: 1, px: 0, minWidth: 'auto' }}
                        onClick={() => setIsEditBtnDialogOpen(index + 1)}
                      >
                        <Edit />
                        {/* Edit button text */}
                      </Button>
                      {formik.values.buttons?.length > 1 && (
                        <Button
                          variant='text'
                          color='error'
                          // sx={{ py: 0, px: 1, minWidth: 'auto' }}
                          sx={{ ml: 1, px: 0, minWidth: 'auto' }}
                          onClick={() => {
                            const newButtons = [...formik.values.buttons];
                            newButtons.splice(index, 1);
                            formik.setFieldValue('buttons', newButtons);
                          }}
                        >
                          <DeleteOutline />
                          {/* Remove */}
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        <div className={styles.form_action_footer}>
          <div>
            <Button
              variant='outlined'
              color='inherit'
              sx={{ px: 3.5 }}
              onClick={() => setIsCancelDialogOpen(true)}
            >
              Cancel
            </Button>
          </div>
          <div>
            <LoadingButton
              variant='contained'
              color='primary'
              sx={{ px: 3.5 }}
              // onClick={handleOpenListMenu}
              type='submit'
              loading={isLoading}
              loadingPosition='end'
              size='medium'
              endIcon={<div style={{ width: isLoading ? 20 : 0 }} />}
            >
              Save form
            </LoadingButton>
          </div>
        </div>
      </form>
      <Dialog
        open={isEditBtnDialogOpen}
        onClose={() => setIsEditBtnDialogOpen(false)}
        classes={{
          container: styles.dialog_container,
          paper: styles.dialog_paper,
        }}
      >
        <DialogContent classes={{ root: styles.dialog_content }}>
          <div className={styles.edit_button_dialog}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              Edit button
              <IconButton onClick={() => setIsEditBtnDialogOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <TextField
              variant='outlined'
              color='primary'
              name={`buttons[${isEditBtnDialogOpen - 1}].title`}
              label='Enter button text'
              placeholder='Enter button text'
              fullWidth
              size='small'
              sx={{ mt: 2 }}
              value={formik.values.buttons?.[isEditBtnDialogOpen - 1]?.title}
              onChange={formik.handleChange}
              required
              onBlur={formik.handleBlur}
              error={
                formik.touched.buttons?.[isEditBtnDialogOpen - 1]?.title &&
                !formik.values.buttons?.[isEditBtnDialogOpen - 1]?.title
              }
            />
            <div style={{ fontWeight: 600 }}>Button action</div>
            <EditButtonDropdown {...{ formik, isEditBtnDialogOpen }} />

            {formik.values.buttons?.[isEditBtnDialogOpen - 1]?.action ===
              'navigate' && (
              <LinkSelector
                variant='outlined'
                label='Link To'
                placeholder='Paste website address here'
                value={
                  formik.values.buttons?.[isEditBtnDialogOpen - 1]?.link_to
                }
                onChange={(event) => {
                  formik.setFieldValue(
                    `buttons[${isEditBtnDialogOpen - 1}].link_to`,
                    event.target.value
                  );
                }}
                fullWidth={true}
                required={true}
                pages={pages}
              />
            )}

            <div className={styles.editBtnRow} style={{ marginTop: '1rem' }}>
              <Button
                variant='text'
                color='inherit'
                // sx={{ py: 0, px: 1, minWidth: 'auto' }}
                onClick={() => setIsEditBtnDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                // onClick={handleUpdateButtonText}
                onClick={() => setIsEditBtnDialogOpen(false)}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        classes={{
          container: styles.dialog_container,
          paper: styles.dialog_paper,
        }}
      >
        <DialogContent classes={{ root: styles.dialog_content }}>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '18px',
              }}
            >
              <b>Save changes?</b>
              <IconButton onClick={() => setIsCancelDialogOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            Your unsaved changes will be lost. Save changes before cancel?
            <div className={styles.editBtnRow} style={{ marginTop: '1rem' }}>
              <Button
                variant='text'
                color='inherit'
                sx={{ px: 3, mr: 2 }}
                onClick={() => navigate('/forms')}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                sx={{ px: 3 }}
                onClick={() => handleSaveForm(formik.values)}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isSavedDialogOpen}
        onClose={() => {
          props.fetchForm();
          setIsSavedDialogOpen(false);
        }}
        classes={{
          container: styles.dialog_container,
          paper: styles.dialog_paper,
        }}
      >
        <DialogContent classes={{ root: styles.dialog_content }}>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                fontSize: '18px',
                marginBottom: '1rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <CheckIcon style={{ marginRight: '1rem' }} />
                <b>
                  {form?.data?.title} from is saved and can now be used in
                  website builder.
                </b>
              </div>
              <IconButton
                onClick={() => {
                  props.fetchForm();
                  setIsSavedDialogOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {form?.data?.title} form is now added to Section ‘Forms’ in website
            builder.
            <div className={styles.editBtnRow} style={{ marginTop: '1rem' }}>
              <Button
                variant='contained'
                color='primary'
                sx={{ px: 3 }}
                onClick={() => navigate('/forms')}
              >
                Got it
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FormFields;

const FieldRowItem = ({ field, index, rowIndex, editField, setEditField, formik }) => {
  const [showListMenuEl, setShowListMenuEl] = useState(null);
  const handleRemoveField = (index, rowIndex) => {
    const newFields = [...formik.values.fields];
    // newFields.splice(index, 1);
    if (newFields[index].length === 1) {
      newFields.splice(index, 1);
    } else {
      newFields[index].splice(rowIndex, 1);
    }
    
    formik.setFieldValue('fields', newFields);
  };

  const getTitle = () => {
    return editField !== `field-${field?.id}` && field?.title
      ? field?.title
      : {
          [NAME_FIELD_ID]: 'Name',
          [EMAIL_FIELD_ID]: 'Email address',
          [PHONE_FIELD_ID]: 'Phone number',
          [DROPDOWN_FIELD_ID]: 'Dropdown',
          [RADIO_FIELD_ID]: 'Radio button',
          [SHORT_ANSWER_FIELD_ID]: 'Short answer',
          [PARAGRAPH_FIELD_ID]: 'Paragraph',
          [DATE_FIELD_ID]: 'Date',
          [READ_ONLY_FIELD_ID]: 'Read only',
          [PASSWORD_FIELD_ID]: 'Password',
          [ADDRESS_FIELD_ID]: 'Address',
        }[field?.field_id]
  }

  return (
    <div className={styles.field_container}>
      {/* {rowIndex === 0 && (
        <div className={styles.drag_indicator}>
          <IconButton>
            <DragIndicator />
          </IconButton>
        </div>
      )} */}
      <div className={styles.field_body}>
        <div className={styles.field_header}>
          <div className={styles.field_title} title={getTitle()}>
            {editField !== `field-${field?.id}` && field?.title
              ? field?.title
              : {
                  [NAME_FIELD_ID]: 'Name',
                  [EMAIL_FIELD_ID]: 'Email address',
                  [PHONE_FIELD_ID]: 'Phone number',
                  [DROPDOWN_FIELD_ID]: 'Dropdown',
                  [RADIO_FIELD_ID]: 'Radio button',
                  [SHORT_ANSWER_FIELD_ID]: 'Short answer',
                  [PARAGRAPH_FIELD_ID]: 'Paragraph',
                  [DATE_FIELD_ID]: 'Date',
                  [READ_ONLY_FIELD_ID]: 'Read only',
                  [PASSWORD_FIELD_ID]: 'Password',
                  [ADDRESS_FIELD_ID]: 'Address',
                }[field?.field_id]}
          </div>
          
          {field?.isEdit !== false && (
            <div className={styles.editBtnRow}>
              {editField !== `field-${field?.id}` && (
                <Button
                  variant='text'
                  color='primary'
                  onClick={() => {
                    setEditField(`field-${field?.id}`);
                  }}
                  sx={{ whiteSpace:'nowrap' }}
                >
                  Edit field
                </Button>
              )}
              <Button
                variant='text'
                color='error'
                onClick={() => handleRemoveField(index, rowIndex)}
              >
                Remove
              </Button>
            </div>
          )}
        </div>
        {editField === `field-${field?.id}` && (
          <>
            <TextField
              variant='outlined'
              color='primary'
              name={`fields[${index}][${rowIndex}].title`}
              // label='Enter Field name'
              label={
                formik.values.fields[index][rowIndex].placeholder || 'Enter Field name'
              }
              placeholder={
                formik.values.fields[index][rowIndex].placeholder || 'Enter Field name'
              }
              fullWidth
              size='small'
              sx={{ mt: 2 }}
              value={formik.values.fields[index][rowIndex].title}
              onChange={formik.handleChange}
              required
              onBlur={formik.handleBlur}
              error={
                formik.touched.fields?.[index]?.[rowIndex]?.title &&
                !formik.values.fields[index]?.[rowIndex].title
              }
              helperText={formik.values.fields[index][rowIndex].title ? '' : 'Required'}
            />

            {(field?.field_id === DROPDOWN_FIELD_ID ||
              field?.field_id === RADIO_FIELD_ID) && (
              <div className={styles.dropdown_options}>
                {field?.options?.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className={styles.dropdown_option_container}
                  >
                    <TextField
                      variant='outlined'
                      color='primary'
                      name={`fields[${index}][${rowIndex}].options[${optionIndex}].label`}
                      label='Option'
                      placeholder='Option'
                      fullWidth
                      size='small'
                      value={
                        formik.values.fields[index][rowIndex].options[optionIndex].label
                      }
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.setFieldValue(
                          `fields[${index}][${rowIndex}].options[${optionIndex}].value`,
                          String(event.target.value)
                            .trim()
                            .replace(/\s+/g, '-')
                            .toLowerCase()
                        );
                      }}
                      required
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.fields?.[index]?.[rowIndex]?.options?.[optionIndex]
                          ?.label &&
                        !formik.values.fields[index]?.[rowIndex].options[optionIndex].label
                      }
                      helperText={
                        formik.values.fields[index]?.[rowIndex].options[optionIndex].label
                          ? ''
                          : 'Required'
                      }
                    />
                    {field?.options?.length > 1 && (
                      <Button
                        variant='text'
                        color='error'
                        onClick={() => {
                          const newOptions = [
                            ...formik.values.fields[index][rowIndex].options,
                          ];
                          newOptions.splice(optionIndex, 1);
                          formik.setFieldValue(
                            `fields[${index}][${rowIndex}].options`,
                            newOptions
                          );
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                ))}
                <Button
                  variant='text'
                  color='primary'
                  onClick={() => {
                    const newOptions = [...formik.values.fields[index][rowIndex].options];
                    newOptions.push({ label: '' });
                    formik.setFieldValue(
                      `fields[${index}][${rowIndex}].options`,
                      newOptions
                    );
                  }}
                >
                  Add option
                </Button>
              </div>
            )}
            {field?.field_id !== READ_ONLY_FIELD_ID && (
              <FormControlLabel
                control={
                  <Checkbox
                    id='required'
                    name={`fields[${index}][${rowIndex}].required`}
                    checked={formik.values.fields[index][rowIndex].required}
                    onChange={formik.handleChange}
                    size='small'
                  />
                }
                label='This field is required'
              />
            )}
            <div className={styles.editBtnRow}>
              <Button
                variant='text'
                color='inherit'
                // sx={{ py: 0, px: 1, minWidth: 'auto' }}
                onClick={() => {
                  if (field.title === '') {
                    handleRemoveField(index, rowIndex);
                  }
                  setEditField(false);
                }}
              >
                cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                onClick={() => {
                  if (field.title === '') {
                    // handleRemoveField(index);
                    toast.error('Please enter field name');
                    return;
                  }
                  setEditField(false);
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
      {formik?.values?.fields[index]?.length === 1 && (
        <AddSideFieldMenu 
          {...{ variant : 'text', showListMenuEl, index, setShowListMenuEl, setEditField, formik }}
        />
      )}
    </div>
  );
};
const FieldItem = ({ field, index, editField, setEditField, formik }) => {
  const [showListMenuEl, setShowListMenuEl] = useState(null);
  const handleRemoveField = (index) => {
    const newFields = [...formik.values.fields];
    newFields.splice(index, 1);
    formik.setFieldValue('fields', newFields);
  };

  const getTitle = () => {
    return editField !== `field-${field?.id}` && field?.title
      ? field?.title
      : {
          [NAME_FIELD_ID]: 'Name',
          [EMAIL_FIELD_ID]: 'Email address',
          [PHONE_FIELD_ID]: 'Phone number',
          [DROPDOWN_FIELD_ID]: 'Dropdown',
          [RADIO_FIELD_ID]: 'Radio button',
          [SHORT_ANSWER_FIELD_ID]: 'Short answer',
          [PARAGRAPH_FIELD_ID]: 'Paragraph',
          [DATE_FIELD_ID]: 'Date',
          [READ_ONLY_FIELD_ID]: 'Read only',
          [PASSWORD_FIELD_ID]: 'Password',
          [ADDRESS_FIELD_ID]: 'Address',
        }[field?.field_id]
  }

  return (
    <div className={styles.field_container}>
      <div className={styles.field_body}>
        <div className={styles.field_header}>
          <div className={styles.field_title} title={getTitle()}>
            {editField !== `field-${field?.id}` && field?.title
              ? field?.title
              : {
                  [NAME_FIELD_ID]: 'Name',
                  [EMAIL_FIELD_ID]: 'Email address',
                  [PHONE_FIELD_ID]: 'Phone number',
                  [DROPDOWN_FIELD_ID]: 'Dropdown',
                  [RADIO_FIELD_ID]: 'Radio button',
                  [SHORT_ANSWER_FIELD_ID]: 'Short answer',
                  [PARAGRAPH_FIELD_ID]: 'Paragraph',
                  [DATE_FIELD_ID]: 'Date',
                  [READ_ONLY_FIELD_ID]: 'Read only',
                  [PASSWORD_FIELD_ID]: 'Password',
                  [ADDRESS_FIELD_ID]: 'Address',
                }[field?.field_id]}
          </div>

          <div className={styles.editBtnRow}>
            {editField !== `field-${field?.id}` && (
              <Button
                variant='text'
                color='primary'
                onClick={() => {
                  setEditField(`field-${field?.id}`);
                }}
                sx={{ whiteSpace:'nowrap' }}
              >
                Edit field
              </Button>
            )}
            <Button
              variant='text'
              color='error'
              onClick={() => handleRemoveField(index)}
            >
              Remove
            </Button>
          </div>
        </div>
        {editField === `field-${field?.id}` && (
          <>
            <TextField
              variant='outlined'
              color='primary'
              name={`fields[${index}].title`}
              // label='Enter Field name'
              label={
                formik.values.fields[index].placeholder || 'Enter Field name'
              }
              placeholder={
                formik.values.fields[index].placeholder || 'Enter Field name'
              }
              fullWidth
              size='small'
              sx={{ mt: 2 }}
              value={formik.values.fields[index].title}
              onChange={formik.handleChange}
              required
              onBlur={formik.handleBlur}
              error={
                formik.touched.fields?.[index]?.title &&
                !formik.values.fields[index].title
              }
              helperText={formik.values.fields[index].title ? '' : 'Required'}
            />

            {(field?.field_id === DROPDOWN_FIELD_ID ||
              field?.field_id === RADIO_FIELD_ID) && (
              <div className={styles.dropdown_options}>
                {field?.options?.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className={styles.dropdown_option_container}
                  >
                    <TextField
                      variant='outlined'
                      color='primary'
                      name={`fields[${index}].options[${optionIndex}].label`}
                      label='Option'
                      placeholder='Option'
                      fullWidth
                      size='small'
                      value={
                        formik.values.fields[index].options[optionIndex].label
                      }
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.setFieldValue(
                          `fields[${index}].options[${optionIndex}].value`,
                          String(event.target.value)
                            .trim()
                            .replace(/\s+/g, '-')
                            .toLowerCase()
                        );
                      }}
                      required
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.fields?.[index]?.options?.[optionIndex]
                          ?.label &&
                        !formik.values.fields[index].options[optionIndex].label
                      }
                      helperText={
                        formik.values.fields[index].options[optionIndex].label
                          ? ''
                          : 'Required'
                      }
                    />
                    {field?.options?.length > 1 && (
                      <Button
                        variant='text'
                        color='error'
                        onClick={() => {
                          const newOptions = [
                            ...formik.values.fields[index].options,
                          ];
                          newOptions.splice(optionIndex, 1);
                          formik.setFieldValue(
                            `fields[${index}].options`,
                            newOptions
                          );
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                ))}
                <Button
                  variant='text'
                  color='primary'
                  onClick={() => {
                    const newOptions = [...formik.values.fields[index].options];
                    newOptions.push({ label: '' });
                    formik.setFieldValue(
                      `fields[${index}].options`,
                      newOptions
                    );
                  }}
                >
                  Add option
                </Button>
              </div>
            )}
            {field?.field_id !== READ_ONLY_FIELD_ID && (
              <FormControlLabel
                control={
                  <Checkbox
                    id='required'
                    name={`fields[${index}].required`}
                    checked={formik.values.fields[index].required}
                    onChange={formik.handleChange}
                    size='small'
                  />
                }
                label='This field is required'
              />
            )}
            <div className={styles.editBtnRow}>
              <Button
                variant='text'
                color='inherit'
                // sx={{ py: 0, px: 1, minWidth: 'auto' }}
                onClick={() => {
                  if (field.title === '') {
                    handleRemoveField(index);
                  }
                  setEditField(false);
                }}
              >
                cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                onClick={() => {
                  if (field.title === '') {
                    // handleRemoveField(index);
                    toast.error('Please enter field name');
                    return;
                  }
                  setEditField(false);
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
      {formik?.values?.fields[index]?.length === 1 && (
        <AddSideFieldMenu 
          {...{ variant : 'text', showListMenuEl, index, setShowListMenuEl, setEditField, formik }}
        />
      )}
    </div>
  );
};

const AddFieldMenu = ({
  variant,
  showListMenuEl,
  setShowListMenuEl,
  setEditField,
  formik,
}) => {
  const showListMenu = Boolean(showListMenuEl);
  const handleOpenListMenu = (event) => {
    event.stopPropagation();
    setShowListMenuEl(event.currentTarget);
  };
  const handleCloseListMenu = (event) => {
    event.preventDefault();
    setShowListMenuEl(null);
  };

  return (
    <>
      <Button
        variant={variant || 'contained'}
        color='primary'
        // sx={{ mt: 2 }}
        // onClick={() => setIsAddUserDialogOpen(true)}
        id='add-field-button'
        aria-controls={showListMenu ? 'add-field' : undefined}
        aria-haspopup='true'
        aria-expanded={showListMenu ? 'true' : undefined}
        onClick={handleOpenListMenu}
      >
        <AddIcon sx={{ mr: 0.5 }} />
        Add field
      </Button>
      <Menu
        id='add-field'
        anchorEl={showListMenuEl}
        open={showListMenu}
        onClose={handleCloseListMenu}
        classes={{ paper: styles.menu_container }}
        MenuListProps={{
          'aria-labelledby': 'add-field-button',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      >
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(NAME_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <PersonIcon sx={{ mr: 1 }} />
          Name
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(EMAIL_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <EmailIcon sx={{ mr: 1 }} />
          Email address
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(PHONE_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <PhoneIcon sx={{ mr: 1 }} />
          Phone number
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(DROPDOWN_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <ArrowDropDownIcon sx={{ mr: 0.6, width: '28px', height: '28px' }} />
          Dropdown
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(RADIO_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <RadioButtonUncheckedOutlinedIcon
            sx={{ mr: 1, strokeWidth: '3px', stroke: '#000' }}
          />
          Radio button
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(SHORT_ANSWER_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <ShortAnswerIcon style={{ marginRight: '8px' }} />
          Short answer
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(PARAGRAPH_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <ParagraphIcon style={{ marginRight: '8px' }} />
          Paragraph
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(DATE_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <DateIcon style={{ marginRight: '8px' }} />
          Date
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(READ_ONLY_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <VisibilityIcon style={{ marginRight: '8px' }} />
          Read only
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            const newField = getFieldObject(PASSWORD_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <VisibilityIcon style={{ marginRight: '8px' }} />
          Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(ADDRESS_FIELD_ID);
            formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <VisibilityIcon style={{ marginRight: '8px' }} />
          Address
        </MenuItem> */}
      </Menu>
    </>
  );
};
const AddSideFieldMenu = ({
  variant,
  index,
  showListMenuEl,
  setShowListMenuEl,
  setEditField,
  formik,
}) => {
  const showListMenu = Boolean(showListMenuEl);
  const handleOpenListMenu = (event) => {
    event.stopPropagation();
    setShowListMenuEl(event.currentTarget);
  };
  const handleCloseListMenu = (event) => {
    event.preventDefault();
    setShowListMenuEl(null);
  };

  return (
    <>
      <Button
        variant={variant || 'contained'}
        color='primary'
        className='!tw-border-l !tw-border-gray !tw-border-solid !tw-rounded-none tw-whitespace-nowrap'
        // sx={{ mt: 2 }}
        // onClick={() => setIsAddUserDialogOpen(true)}
        id='add-side-field-button'
        aria-controls={showListMenu ? 'add-side-field' : undefined}
        aria-haspopup='true'
        aria-expanded={showListMenu ? 'true' : undefined}
        onClick={handleOpenListMenu}
      >
        <AddIcon sx={{ mr: 0.5 }} />
        Add field
      </Button>
      <Menu
        id='add-side-field'
        anchorEl={showListMenuEl}
        open={showListMenu}
        onClose={handleCloseListMenu}
        classes={{ paper: styles.menu_container }}
        MenuListProps={{
          'aria-labelledby': 'add-side-field-button',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      >
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(NAME_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <PersonIcon sx={{ mr: 1 }} />
          Name
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(EMAIL_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <EmailIcon sx={{ mr: 1 }} />
          Email address
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(PHONE_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <PhoneIcon sx={{ mr: 1 }} />
          Phone number
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(DROPDOWN_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <ArrowDropDownIcon sx={{ mr: 0.6, width: '28px', height: '28px' }} />
          Dropdown
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(RADIO_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <RadioButtonUncheckedOutlinedIcon
            sx={{ mr: 1, strokeWidth: '3px', stroke: '#000' }}
          />
          Radio button
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(SHORT_ANSWER_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <ShortAnswerIcon style={{ marginRight: '8px' }} />
          Short answer
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(PARAGRAPH_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <ParagraphIcon style={{ marginRight: '8px' }} />
          Paragraph
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(DATE_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <DateIcon style={{ marginRight: '8px' }} />
          Date
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newField = getFieldObject(READ_ONLY_FIELD_ID);
            // formik.setFieldValue('fields', [...formik.values.fields, [newField]]);
            const updatedFields = formik.values.fields.map((fields, i) => (
              i === index ? [...fields, newField] : fields
            ))
            formik.setFieldValue('fields', updatedFields);
            setEditField(`field-${newField?.id}`);
            setShowListMenuEl(null);
          }}
        >
          <VisibilityIcon style={{ marginRight: '8px' }} />
          Read only
        </MenuItem>
      </Menu>
    </>
  );
};

const FileIcon = () => {
  return (
    <svg
      width='57'
      height='57'
      viewBox='0 0 57 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M46.9675 24.5811H31.6043C30.2073 24.5811 28.8675 24.0262 27.8797 23.0384C26.8918 22.0505 26.3369 20.7107 26.3369 19.3137V3.95055C26.3369 3.83413 26.2906 3.72248 26.2083 3.64016C26.126 3.55784 26.0143 3.5116 25.8979 3.5116H15.8021C13.9394 3.5116 12.1531 4.25154 10.836 5.56864C9.51887 6.88574 8.77893 8.67211 8.77893 10.5348V45.6507C8.77893 47.5133 9.51887 49.2997 10.836 50.6168C12.1531 51.9339 13.9394 52.6738 15.8021 52.6738H40.3832C42.2459 52.6738 44.0323 51.9339 45.3494 50.6168C46.6665 49.2997 47.4064 47.5133 47.4064 45.6507V25.0201C47.4064 24.9037 47.3602 24.792 47.2778 24.7097C47.1955 24.6274 47.0839 24.5811 46.9675 24.5811Z'
        fill='#C2C2C2'
      />
      <path
        d='M46.004 20.6953L30.2227 4.91403C30.192 4.88352 30.153 4.86276 30.1105 4.85437C30.0681 4.84598 30.0241 4.85032 29.9841 4.86686C29.9441 4.88339 29.9099 4.91138 29.8858 4.94731C29.8617 4.98323 29.8487 5.02548 29.8485 5.06876V19.3137C29.8485 19.7794 30.0335 20.226 30.3628 20.5553C30.692 20.8846 31.1386 21.0695 31.6043 21.0695H45.8493C45.8926 21.0694 45.9348 21.0564 45.9707 21.0323C46.0067 21.0081 46.0347 20.9739 46.0512 20.9339C46.0677 20.8939 46.0721 20.8499 46.0637 20.8075C46.0553 20.765 46.0345 20.726 46.004 20.6953Z'
        fill='#C2C2C2'
      />
    </svg>
  );
};

const ShortAnswerIcon = (props) => {
  return (
    <svg
      {...props}
      width='25'
      height='12'
      viewBox='0 0 18 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='18' height='9' rx='3' fill='black' />
      <rect x='4' y='4' width='10' height='1.5' rx='0.75' fill='white' />
    </svg>
  );
};
const ParagraphIcon = (props) => {
  return (
    <svg
      {...props}
      width='28'
      height='28'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.75 3C5.75544 3 4.80161 3.39509 4.09835 4.09835C3.39509 4.80161 3 5.75544 3 6.75V17.25C3 18.2446 3.39509 19.1984 4.09835 19.9016C4.80161 20.6049 5.75544 21 6.75 21H17.25C18.2446 21 19.1984 20.6049 19.9016 19.9016C20.6049 19.1984 21 18.2446 21 17.25V6.75C21 5.75544 20.6049 4.80161 19.9016 4.09835C19.1984 3.39509 18.2446 3 17.25 3H6.75ZM8.25 7.5H15.75C15.9489 7.5 16.1397 7.57902 16.2803 7.71967C16.421 7.86032 16.5 8.05109 16.5 8.25C16.5 8.44891 16.421 8.63968 16.2803 8.78033C16.1397 8.92098 15.9489 9 15.75 9H8.25C8.05109 9 7.86032 8.92098 7.71967 8.78033C7.57902 8.63968 7.5 8.44891 7.5 8.25C7.5 8.05109 7.57902 7.86032 7.71967 7.71967C7.86032 7.57902 8.05109 7.5 8.25 7.5ZM8.25 11.25H12.75C12.9489 11.25 13.1397 11.329 13.2803 11.4697C13.421 11.6103 13.5 11.8011 13.5 12C13.5 12.1989 13.421 12.3897 13.2803 12.5303C13.1397 12.671 12.9489 12.75 12.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25ZM8.25 15H15.75C15.9489 15 16.1397 15.079 16.2803 15.2197C16.421 15.3603 16.5 15.5511 16.5 15.75C16.5 15.9489 16.421 16.1397 16.2803 16.2803C16.1397 16.421 15.9489 16.5 15.75 16.5H8.25C8.05109 16.5 7.86032 16.421 7.71967 16.2803C7.57902 16.1397 7.5 15.9489 7.5 15.75C7.5 15.5511 7.57902 15.3603 7.71967 15.2197C7.86032 15.079 8.05109 15 8.25 15Z'
        fill='black'
      />
    </svg>
  );
};
const DateIcon = (props) => {
  return (
    <svg
      {...props}
      width='28'
      height='28'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 8.5V17.75C21 18.612 20.6576 19.4386 20.0481 20.0481C19.4386 20.6576 18.612 21 17.75 21H6.25C5.38805 21 4.5614 20.6576 3.9519 20.0481C3.34241 19.4386 3 18.612 3 17.75V8.5H21ZM13.995 10.695C13.331 10.695 12.804 10.873 12.413 11.227C12.023 11.582 11.827 12.062 11.827 12.668C11.827 13.016 11.916 13.326 12.093 13.598C12.271 13.87 12.513 14.084 12.818 14.24C12.4734 14.386 12.1782 14.6284 11.968 14.938C11.7661 15.2417 11.6608 15.5994 11.666 15.964C11.666 16.586 11.878 17.08 12.303 17.448C12.728 17.816 13.293 18 14 18C14.703 18 15.266 17.817 15.69 17.45C16.113 17.084 16.324 16.589 16.324 15.964C16.324 15.586 16.224 15.248 16.024 14.948C15.8098 14.6369 15.513 14.3917 15.167 14.24C15.477 14.084 15.72 13.87 15.899 13.598C16.079 13.326 16.168 13.016 16.168 12.668C16.168 12.063 15.973 11.582 15.582 11.228C15.192 10.873 14.662 10.695 13.995 10.695ZM10.22 10.769H10.068L7.295 11.789V12.79L9.038 12.194V17.902H10.22V10.77V10.769ZM13.99 14.729C14.335 14.729 14.613 14.837 14.823 15.053C15.033 15.27 15.138 15.553 15.138 15.905C15.138 16.267 15.038 16.548 14.838 16.75C14.638 16.952 14.358 17.053 14 17.053C13.642 17.053 13.361 16.949 13.158 16.74C12.954 16.532 12.852 16.254 12.852 15.905C12.852 15.551 12.956 15.265 13.162 15.051C13.369 14.836 13.645 14.729 13.99 14.729ZM13.995 11.648C14.298 11.648 14.539 11.748 14.718 11.948C14.897 12.148 14.986 12.403 14.986 12.712C14.986 13.037 14.898 13.296 14.72 13.488C14.543 13.68 14.303 13.776 14 13.776C13.697 13.776 13.457 13.68 13.28 13.488C13.102 13.296 13.014 13.038 13.014 12.712C13.014 12.383 13.102 12.124 13.277 11.933C13.453 11.743 13.692 11.648 13.995 11.648ZM17.75 3C18.612 3 19.4386 3.34241 20.0481 3.9519C20.6576 4.5614 21 5.38805 21 6.25V7H3V6.25C3 5.38805 3.34241 4.5614 3.9519 3.9519C4.5614 3.34241 5.38805 3 6.25 3H17.75Z'
        fill='black'
      />
    </svg>
  );
};
const CheckIcon = (props) => {
  return (
    <svg
      {...props}
      width='46'
      height='46'
      viewBox='0 0 46 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.25 23.5L21.0357 30.8214L32.75 16.1786M22.5 44C19.8079 44 17.1422 43.4698 14.655 42.4395C12.1678 41.4093 9.90791 39.8993 8.00431 37.9957C6.10071 36.0921 4.59069 33.8322 3.56047 31.345C2.53025 28.8578 2 26.1921 2 23.5C2 20.8079 2.53025 18.1422 3.56047 15.655C4.59069 13.1678 6.10071 10.9079 8.00431 9.00431C9.90791 7.10071 12.1678 5.59069 14.655 4.56047C17.1422 3.53025 19.8079 3 22.5 3C27.9369 3 33.1512 5.15982 36.9957 9.00431C40.8402 12.8488 43 18.0631 43 23.5C43 28.9369 40.8402 34.1512 36.9957 37.9957C33.1512 41.8402 27.9369 44 22.5 44Z'
        stroke='#036EE4'
        stroke-width='4'
      />
    </svg>
  );
};

const NAME_FIELD_ID = 0;
const EMAIL_FIELD_ID = 1;
const PHONE_FIELD_ID = 2;
const DROPDOWN_FIELD_ID = 3;
const RADIO_FIELD_ID = 4;
const SHORT_ANSWER_FIELD_ID = 5;
const PARAGRAPH_FIELD_ID = 6;
const DATE_FIELD_ID = 7;
const READ_ONLY_FIELD_ID = 8;
const PASSWORD_FIELD_ID = 9;
const ADDRESS_FIELD_ID = 10;

function getFieldObject(field_id) {
  return {
    [NAME_FIELD_ID]: {
      id: `name-${dayjs().unix()}`,
      field_id: NAME_FIELD_ID,
      title: 'Name',
      required: false,
      type: 'text',
      placeholder: '',
    },
    [EMAIL_FIELD_ID]: {
      id: `email-${dayjs().unix()}`,
      field_id: EMAIL_FIELD_ID,
      title: 'Email',
      required: false,
      placeholder: '',
      type: 'email',
    },
    [PHONE_FIELD_ID]: {
      id: `phone-${dayjs().unix()}`,
      field_id: PHONE_FIELD_ID,
      title: 'Phone',
      required: false,
      placeholder: '',
      type: 'number',
    },
    [DROPDOWN_FIELD_ID]: {
      id: `dropdown-${dayjs().unix()}`,
      field_id: DROPDOWN_FIELD_ID,
      title: 'Dropdown',
      placeholder: '',
      required: false,
      options: [
        { label: 'Option 1', value: 'option-1' },
        { label: 'Option 2', value: 'option-2' },
        { label: 'Option 3', value: 'option-3' },
      ],
    },
    [RADIO_FIELD_ID]: {
      id: `radio-${dayjs().unix()}`,
      field_id: RADIO_FIELD_ID,
      title: 'Radio',
      required: false,
      placeholder: '',
      options: [
        { label: 'Option 1', value: 'option-1' },
        { label: 'Option 2', value: 'option-2' },
        { label: 'Option 3', value: 'option-3' },
      ],
    },
    [SHORT_ANSWER_FIELD_ID]: {
      id: `short-answer-${dayjs().unix()}`,
      field_id: SHORT_ANSWER_FIELD_ID,
      title: 'Short Answer',
      required: false,
      placeholder: '',
      type: 'text',
    },
    [PARAGRAPH_FIELD_ID]: {
      id: `paragraph-${dayjs().unix()}`,
      field_id: PARAGRAPH_FIELD_ID,
      title: 'Paragraph',
      placeholder: '',
      required: false,
      type: 'textfield',
    },
    [DATE_FIELD_ID]: {
      id: `date-${dayjs().unix()}`,
      field_id: DATE_FIELD_ID,
      title: 'Date',
      placeholder: '',
      required: false,
      type: 'date',
    },
    [READ_ONLY_FIELD_ID]: {
      id: `read-only-${dayjs().unix()}`,
      field_id: READ_ONLY_FIELD_ID,
      title: 'Read Only',
      placeholder: '',
      required: false,
      type: 'readonly',
    },
    [PASSWORD_FIELD_ID]: {
      id: `password-${dayjs().unix()}`,
      field_id: PASSWORD_FIELD_ID,
      title: 'Password',
      placeholder: '',
      required: false,
      type: 'password',
    },
    [ADDRESS_FIELD_ID]: {
      id: `address-${dayjs().unix()}`,
      field_id: ADDRESS_FIELD_ID,
      title: 'Address',
      placeholder: '',
      required: false,
      type: 'text',
    },
  }[field_id];
}

const EditButtonDropdown = ({ formik, isEditBtnDialogOpen, ...props }) => {
  console.log('🚀OUTPUT --> props.rooms:', props.rooms);

  const [buttonStyles, setButtonStyles] = useState(formik.values.buttons?.[isEditBtnDialogOpen - 1]?.buttonStyles);

  useEffect(() => {
    if(buttonStyles) {
      formik.setFieldValue(`buttons[${isEditBtnDialogOpen - 1}].buttonStyles`, buttonStyles);
    }
  }, [buttonStyles]);

  return (
    <FormControl
      sx={{
        maxWidth: '100% !important',
        width: '100% !important',
      }}
      size='small'
    >
      <InputLabel focused={false}>Button Action</InputLabel>
      <GenericSelect
        value={formik.values.buttons?.[isEditBtnDialogOpen - 1]?.action}
        label='Button Action'
        onChange={(e) => {
          formik.setFieldValue(
            `buttons[${isEditBtnDialogOpen - 1}].action`,
            e.target.value
          );
          if (e.target.value !== 'navigate') {
            formik.setFieldValue(
              `buttons[${isEditBtnDialogOpen - 1}].link_to`,
              null
            );
          } else {
            formik.setFieldValue(
              `buttons[${isEditBtnDialogOpen - 1}].link_to`,
              'index'
            );
          }
        }}
      >
        <MenuItem value='save'>Save form (Default) </MenuItem>
        <MenuItem value='navigate'>Navigate to page </MenuItem>
        <MenuItem value='clear'>Clear form </MenuItem>
      </GenericSelect>

      <ButtonStyleEdit {...{ title: 'Button styles', buttonStyles, setButtonStyles, hidePosition: true }}  />

    </FormControl>
  );
};
