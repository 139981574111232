import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios, { axios3, nodeAxios } from '../../utils/axiosConfig'
import { toast } from 'react-toastify';
import { Button, FormControlLabel, Pagination, Switch } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const SyncWebsite = () => {

    const confirm = useConfirm()
    const [venues, setVenues] = useState([])
    const [pagination, setPagination] = useState({})

    const fetchWebsites = (page) => {
        nodeAxios.get('/venue/website-list', {
            params: {
                page: page || 1
            }
        })
            .then((res) => {
                setVenues(res.data.data)
                setPagination(res.data.pagination)
            })
    }

    useEffect(() => {
        fetchWebsites()
    }, [])


    const handleSync = (id, venueId) => {
        confirm({
            title: 'Do you wish to confirm to sync site as per template',
            description: 'This process will add any missing pages from template to site'
        })
            .then(() => {
                axios3
                    .post(`/web-builder/web-page/setup/${id}`, {
                        venue_id: venueId,
                        update: 1
                    })
                    .then(() => {
                        toast.success('Success')
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message);
                    })
                    .finally(() => {
                        fetchWebsites()
                    });

            })
            .catch(() => {

            });
    };

    const handleChunks = (val, venueId) => {
        confirm({
            title: 'Do you wish to change chunks status',
            description: 'This process might reduce site performance'
        })
            .then(() => {
                axios3
                    .post(`/venue-setting/handle-chunk-bundle`, {
                        venue_id: venueId,
                        is_chunks_bundle: val
                    })
                    .then(() => {
                        toast.success('Success')
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message);
                    })
                    .finally(() => {

                    });

            })
            .catch(() => {

            });
    };

    const handleMiddlewareChange = (recordId, venueId) => {
        nodeAxios
            .post(`/venue/change-middleware`, {
                venueId: venueId,
                recordId: recordId
            })
            .then(() => {
                toast.success('Success')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {

            });
    }

    return (
        <div>
            <TableContainer
                style={{
                    maxWidth: 600
                }}
                component={Paper}
            >
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Venue name</TableCell>
                            <TableCell>Subdomain</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>Node middleware</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {venues.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>
                                    {row.subdomain}
                                </TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        control={(
                                            <Switch
                                                checked={row.isNodeMidddleware}
                                                disabled={row.isNodeMidddleware}
                                                onChange={(event) => handleMiddlewareChange(row.recordId, row.venueId)}
                                            />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            <Pagination
                page={pagination.current_page}
                count={pagination.last_page}
                onChange={(e, page) => fetchWebsites(page)}
            />
        </div>
    );
}

export default SyncWebsite