
const initialState = {
  isLoading: false,
  params: [],
  categoryMenu: [],
  data: {
    result: [],
    last_page: "",
    pagination: {},
    count: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    },
    countVenue: "",
  },
  country: [],
  brewery: [],
  categoryListLoading: false,
  categoryList: {
    parent: [],
    category: {},
    child: [],
  },
  filters: {},
  locationData: {
    category: 0,
    sortby: "",
    q: "",
    category_id: 0,
    country: [],
    state: [],
    city: [],
    abvvalue: null,
    brewery_id: [],
    venue_id: [],
    rating: "",
    min: 0,
    max: 500,
    searchActivate: false,
  },
};

export default function search(state = initialState, action = null) {
  switch (action.type) {
    case "SET_SEARCH_LOADING":
      return { ...state, isLoading: true };
    case "SET_SEARCH":
      return { ...state, data: action.payload, isLoading: false };
    case "SET_SEARCH_ERROR":
      return initialState;
    case "SET_COUNTRY":
      return { ...state, country: action.payload };
    case "SET_BREWERY":
      return { ...state, brewery: action.payload };
    case "SET_CATEGORY_MENU": 
      return {...state, categoryMenu: action.payload};
    case "SET_CATEGORY_LIST_LOADING":
      return { ...state, categoryListLoading: true };
    case "SET_CATEGORY_CHILD_LIST":
      return {
        ...state,
        categoryList: action.payload,
        categoryListLoading: false,
      };
    case "SET_CATEGORY_CHILD_LIST_ERROR":
      return {
        ...state,
        categoryListLoading: false,
      };
    case "SET_LOCATION_DATA":
      return {
        ...state,
        locationData: { ...initialState.locationData, ...action.payload },
      };
    case "SET_FILTER_DATA":
      return {
        ...state,
        locationData: { ...state.locationData, ...action.payload },
      };
    default:
      return state;
  }
}
