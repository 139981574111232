import axios from '../../utils/axiosConfig';
import { nanoid } from 'nanoid';

export const getBanners = (params) => {
        return (dispatch) => {
            axios.get('/banner-group/get-banner', {
                params: {
                    page: params.page,
                    venue_id: localStorage.getItem('venue_id'),
                    q: params.query,
                    sort_by: params.sortby ? params.sortby : 'nameza'
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                }
            })
            .then((response)=>{
                let banners = response.data.data.result.map((val) => ({ data: { ...val, bannerUuid: nanoid(15) }, type: 'banner' }));
                let pagination = response.data.data.pagination;
                
                dispatch({
                    type: "SET_BANNERS",
                    payload: {
                        banners,
                        pagination
                    }
                })
            })
        }
};