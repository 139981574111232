import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import TabsSection from '../../Library/V2/TabsSection/TabsSection';
import styles from './OrderSettings.module.scss';
import OrderProperties from './OrderInfo/OrderProperties';
import TaxProperties from './OrderInfo/TaxProperties';
import { useMediaQuery } from '@mui/material';
//import BusinessInfo from './BusinessInfo/BusinessInfo';
import { useSearchParams } from 'react-router-dom'
import Notifications from './Notifications/Notifications';
import Loader from '../../Library/Loader';

const tabs = [
  {
    value: 'order_properties',
    label: 'Order Properties',
  },
  {
    value: 'notifications',
    label: 'Notifications',
  },
  {
    value: 'taxes',
    label: 'Taxes',
  },
];


function OrderSettings() {
  const { isLoading } = useOutletContext()

  const [searchParams] = useSearchParams();
  const section = searchParams.get('section');

  const [selectedTab, setSelectedTab] = useState(section || 'order_properties');
  const isSmall = useMediaQuery('(max-width: 1000px)');

  return isLoading ? <Loader /> : (
    <div className="page-wrapper tw-my-2 tw-px-4 sm:tw-px-11">
      <TabsSection
        tabs={tabs}
        tabsbg='var(--bg-3)'
        tabsPadding='15px'
        tabValue={selectedTab}
        tabs_container={styles.tabs_container}
        onChange={(_, newValue) => {
          setSelectedTab(newValue)
        }}
        boxStyles={{
          p: '0 !important',
          borderBottom: '1px solid #D3D3D3',
        }}
      />

      {selectedTab === 'order_properties' ? (
        <OrderProperties {...{ isSmall }} />
      ) : selectedTab === 'notifications' ? (
        <Notifications {...{ isSmall }} />
      ) : selectedTab === 'taxes' ? (
        <TaxProperties {...{ isSmall }} />
      ) : null}

    </div>
  )
}

export default OrderSettings;