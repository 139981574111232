import { Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import TabsSection from '../Library/TabsSection/TabsSection'
import DeliverySetting from './DeliverySetting/DeliverySetting'
import OrderEmailSetting from './OrderEmailSetting/OrderEmailSetting'
import PickupSetting from './PickupSetting/PickupSetting'
import ShippingSetting from './ShippingSetting/ShippingSetting'
import axios from '../../utils/axiosConfig'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'
import { toast } from 'react-toastify'
import styles from './OrderSettings.module.scss'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import Coupons from '../Coupons/Coupons'
import GoogleIds from './GoogleIds/GoogleIds'
import OrderStatus from './OrderStatus/OrderStatus'
import VenueTax from './VenueTax/VenueTax'
import * as Yup from 'yup';

const validationSchemaTax = Yup.object().shape({
  venue_tax: Yup.number()
    .required('This field is required')
    .min(0, 'Number must be at least 0')
    .max(100, 'Number must be at most 100')
    .test(
      'is-decimal',
      'Number must not have more than 2 decimal points',
      value => /^\d+(\.\d{1,2})?$/.test(value)
    ),
});


const OrderSettings = ({ isFromClover,isColverSaved }) => {

  const {
    service,
    deliveryVenueHours,
    deliveryZone,
    venueDetails,
    pickupVenueHours,
    setServiceChargeToCustomer,
    fetchStoreInfo,
    isActionsDisabled,
    venueStates
  } = useOutletContext()
  const [isLoading, setIsLoading] = useState(false)
  const [emailTypes, setEmailTypes] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [value, setValue] = useState('');
  const [tabValue, setTabValue] = useState(0)
  const [isClover, setIsClover] = useState(false)

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabs = useMemo(() => [
    {
      label: 'Pickup Setting',
      value: 0
    },
    {
      label: 'Delivery Setting',
      value: 1
    },
    {
      label: 'Shipping Setting',
      value: 2
    },
    {
      label: 'Order Email Setting',
      value: 3
    },
    {
      label: 'Coupons',
      value: 4
    },
    {
      label: "Google Id's",
      value: 5
    },
    {
      label: "Order Status",
      value: 6
    },
    {
      label: "Tax",
      value: 7
    },
  ], []);

  const fetchEmailTypes = () => {
    axios.get('/venue-email-config/type-list')
      .then((response) => {
        let result = response.data.data.result
        var arr = []
        setValue(result[0].key)
        result.forEach(element => {
          let key = element.key

          let value = element.value

          let firstText = key.split('_')[0]
          if (firstText === 'pickup') {
            if (arr.filter((data) => data.id === 'pickup').length) {
              let index = arr.findIndex((data) => data.id === 'pickup')
              arr[index].child.push({
                id: key,
                label: value
              })
              return
            } else {
              arr.push({
                id: 'pickup',
                label: 'Pickup',
                child: [
                  {
                    id: key,
                    label: value
                  }
                ]
              })
              return
            }
          }
          if (firstText === 'delivery') {
            if (arr.filter((data) => data.id === 'delivery').length) {
              let index = arr.findIndex((data) => data.id === 'delivery')
              arr[index].child.push({
                id: key,
                label: value
              })

              return
            } else {
              arr.push({
                id: 'delivery',
                label: 'Delivery',
                child: [
                  {
                    id: key,
                    label: value
                  }
                ]
              })

              return
            }
          }
          if (firstText === 'shipping') {
            if (arr.filter((data) => data.id === 'shipping').length) {
              let index = arr.findIndex((data) => data.id === 'shipping')
              arr[index].child.push({
                id: key,
                label: value
              })

              return
            } else {
              arr.push({
                id: 'shipping',
                label: 'Shipping',
                child: [
                  {
                    id: key,
                    label: value
                  }
                ]
              })

              return
            }
          }

          if (firstText === 'other') {
            if (arr.filter((data) => data.id === 'other').length) {
              let index = arr.findIndex((data) => data.id === 'other')
              arr[index].child.push({
                id: key,
                label: value
              })

              return
            } else {
              arr.push({
                id: 'other',
                label: 'Other',
                child: [
                  {
                    id: key,
                    label: value
                  }
                ]
              })

              return
            }
          } else {
            arr.push({
              id: key,
              label: value,
              child: []
            })

            return
          }

        });
        setEmailTypes(arr)
      })
  }

  const handleSubmit = (values) => {

    if (tabValue === 3) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftToHtml(rawContentState);

      axios.post(`/venue-email-config/update/${value}`, {
        text: markup
      })
        .then(() => {
          toast.success('Email Configuration Updated Succesfully')
        })
    } else {

      setIsLoading(true)

      axios.post('/venue/setting', values)
        .then(() => {
          if (tabValue === 7) {
            toast.success('Please make sure to change the tax rate on each category individually')
          } else {
            toast.success('Saved Successfully')
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message)
        })
        .finally(() => {
          setIsLoading(false)
          fetchStoreInfo()
        })
    }
  }

  useEffect(() => {
    fetchEmailTypes()
  }, [])

  const deliveryHr = Boolean(deliveryVenueHours?.length) ? deliveryVenueHours : [{
    venuetimings: 'delivery',
    dayname: 'Sunday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'delivery',
    dayname: 'Monday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'delivery',
    dayname: 'Tuesday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'delivery',
    dayname: 'Wednesday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'delivery',
    dayname: 'Thursday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'delivery',
    dayname: 'Friday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'delivery',
    dayname: 'Saturday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  }]

  const pickupHr = Boolean(pickupVenueHours.length) ? pickupVenueHours : [{
    venuetimings: 'pickup',
    dayname: 'Sunday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'pickup',
    dayname: 'Monday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'pickup',
    dayname: 'Tuesday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'pickup',
    dayname: 'Wednesday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'pickup',
    dayname: 'Thursday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'pickup',
    dayname: 'Friday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  },
  {
    venuetimings: 'pickup',
    dayname: 'Saturday',
    opentimehours: 12,
    opentimemins: 0,
    closedtimehours: 18,
    closedtimemins: 0,
    isclosed: '1',
    time_interval: 60
  }]

  useEffect(() => {
    if (isFromClover) {
      setTabValue(6)
    }
  }, [isFromClover])

  return (
    <Formik
      initialValues={{
        ...venueDetails,
        venue_delivery_zone: deliveryZone,
        venue_hours: [...deliveryHr, ...pickupHr]
      }}
      validationSchema={(tabValue === 7) && validationSchemaTax}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {formik => (
        <div>
          <TabsSection
            scrollButtons="auto"
            variant="scrollable"
            tabPanelMinWidth="fit-content"
            tabs={tabs}
            tabValue={tabValue}
            onChange={handleChange}
            tabsBody={[
              {
                component: (
                  <PickupSetting
                    formik={formik}
                  />
                )
              },
              {
                component: (
                  <DeliverySetting
                    formik={formik}
                    venueStates={venueStates}
                  />
                )
              },
              {
                component: (
                  <ShippingSetting
                    formik={formik}
                    venueStates={venueStates}
                  />
                )
              },
              {
                component: (
                  <OrderEmailSetting
                    emailTypes={emailTypes}
                    value={value}
                    setValue={setValue}
                    editorState={editorState}
                    setEditorState={setEditorState}
                  />
                )
              },
              {
                component: (
                  <Coupons
                    isActionsDisabled={isActionsDisabled}
                  />
                )
              },
              {
                component: (
                  <GoogleIds
                    isActionsDisabled={isActionsDisabled}
                  />
                )
              },
              {
                component: (
                  <OrderStatus
                    formik={formik}
                    isActionsDisabled={isActionsDisabled}
                    isClover={isClover}
                    setIsClover={setIsClover}
                    isColverSaved={isColverSaved}
                  />
                )
              },
              {
                component: (
                  <VenueTax
                    formik={formik}
                    isActionsDisabled={isActionsDisabled}
                  />
                )
              },
            ]}
          />

          {((tabValue !== 4) && (tabValue !== 5) && !isClover) && (
            <div
              className={styles.save_btn_container}
            >
              <GenericLoadingButton
                variant="contained"
                type="button"
                onClick={formik.handleSubmit}
                size="large"
                loading={isLoading}
                loadingPosition="end"
                disabled={isActionsDisabled}
              >
                Save
              </GenericLoadingButton>
            </div>
          )}
        </div>
      )}
    </Formik>
  )
}

export default OrderSettings