const routePaths = {
  index: '/',
  login: '/login',
  signup: '/Signup',
  order: '/order',
  dashboard: '/dashboard',
  orderDetails: '/OrderDetails',
  orderDashboard: '/OrderDashboard',
  venueOrderListing: '/VenueOrderListing',
  emailSettings: '/EmailSettings',
  createVenue: '/CreateVenue',
  productMaster: '/ProductMaster',
  categoryList: '/CategoryList',
  bannerListing: '/BannerListing',
  stripeThanks: '/stripe-thanks',
  venueDetails: '/VenueDetails',
  venueCreate: '/VenueCreate',
  venueSettingDetails: '/VenueSettingDetails',
  venueSettingCreate: '/VenueSettingCreate',
  commingSoon: '/ComingSoon',
  myUsers: '/MyUsers',
  myStoreInfo: '/MyStoreInfo',
  recoverPassword: '/recover-password/:token',
  myStoreSetting: '/MyStoreSettings',
  paymentAccount: '/payment-account',
  coupons: '/coupons',
  resetPassword: '/resetpassword',
  products: '/products',
  menu: '/menu',
  offers: '/offers',
  store: '/store',
  customization: '/customization',
  modifiers: '/modifiers',
  payments: '/Payments',
  giftCardListing: '/GiftCardListing',
  giftCardCreate: '/GiftCardCreate',
  giftCardEdit: '/GiftCardEdit',
  giftCardHistory: '/GiftCardHistory',
  productGroupListing: '/product-group-listing',
  productGroup: '/product-group',
  productGroupEdit: '/product-group-edit',
  customizeHomepage: '/customize-homepage',
  createPage: '/create-page',
  customPage: '/custom-page/:name',
  customPageEdit: '/custom-page/edit/:name/:id',
  orderDetailsNew: '/order-details',
  pricing: '/Pricing',
  whyOurLiquorStore: '/why-us',
  whyOurDesiStore: '/why-us',
  howItWorks: '/HowItWorks',
  integrations: '/integrations',
  ourFeatures: '/our-features',
  helpPage: '/help-support',
  addNewProduct: '/pages/add-new-product',
  createNewTags: '/pages/create-new-tags',
  assigntagstoproduct: '/pages/assign-tags-to-product',
  uploadInventory: '/pages/upload-inventory',
  searchProductbyCategory: '/pages/search-product-by-category',
  editProductAttributes: '/pages/edit-product-attributes',
  changingItemVisiblity: '/pages/changing-item-visiblity',
  findOrders: '/pages/find-orders',
  transactionStatusReview: '/pages/transaction-status-review',
  ordersDetails: '/pages/orders-details',
  OrdersStatus: '/pages/orders-status',
  AddNewProductGroups: '/pages/add-new-product-groups',
  DragandDropProductGroupInterface:
    '/pages/drag-and-drop-product-group-interface',
  EditExistingProductGroup: '/pages/edit-existing-product-group',
  DragandDropBanners: '/pages/drag-and-drop-banners',
  CreateNewBannerGroup: '/pages/create-new-banner-group',
  EditExistingBannerGroup: '/pages/edit-existing-banner-group',
  CreateCustomStorePage: '/pages/create-custom-store-page',
  StoreDetails: '/pages/store-details',
  FulfillmentMethods: '/pages/fulfillment-methods',
  BankInformation: '/pages/bank-information',
  LiquorLicense: '/pages/liquor-license',
  SubmitLogo: '/pages/submit-logo',
  POSIntegration: '/pages/POS-integration',
  AccessYourWebsite: '/pages/access-your-Website',
  privacyPolicy: '/privacy-policy',
  marketing: '/marketing',
  socialConnect: '/social/connect',
  emailConnect: '/email/connect',
  marketingSocial: '/marketing/social',
  marketingEmail: '/marketing/email',
  webPerformance: '/web-performance',
  claimMyBusiness: '/claim-my-business/:id',
  manageListing: '/manage-listing',
  myStoreSettingNew: '/mystore-settings-new',
  thankYou: '/thank-you',
  editMenu: '/edit-menu',
  marketingDashboard: '/marketing/dashboard',
  marketingPosts: '/marketing/posts',
  marketingPostDetails: '/marketing/post-details/:id',
  marketingCreatePost: '/marketing/create-post/:id',
  marketingPlanner: '/marketing/planner',
  websiteBuilder: '/website-builder/template/:id/:name/*',
  manageSite: '/website-builder/template/:id',
  manageSite2: '/website-builder/manage-site',
  websiteTemplates: '/website-builder/template',
  websiteSettings: '/website-builder/settings',
  websitePageSettings: '/website-builder/page/settings/:id',
  websitegetStarted: '/website-builder/get-started',
  blog: '/website-builder/blogs',
  createBlog: '/website-builder/blogs/create',
  forms: '/forms',
  formEdit: '/forms/edit/:id',
  formResponses: '/forms/:id',
  syncWebsite: '/sync-website',
  categoryMapping: '/category-mapping',
  properties: '/properties',
  categories: '/categories',
  authorizeUnsplash: '/authorize_unsplash',
  gettingStarted: '/getting-started',
  restaurantGettingStarted: '/restaurant/getting-started',
  home: '/home',
  adminTemplate: '/admin/template',
  changeLog: '/change-log',
  orderSettings: '/order-settings',
  emailVerifySuccess: '/email-verify/success',
  emailVerifyFailure: '/email-verify/failure'

};

export default routePaths;
