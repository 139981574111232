import axios from 'axios';
import ApiUrl from './baseUrl';

const instance = axios.create({
    baseURL: ApiUrl,
});

const instance2 = axios.create({
    baseURL: ApiUrl,
});

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

instance2.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken2');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

export default instance;
export const axios2 = instance2;