import styled, { css } from "styled-components";

const textStyles = css`
  color: #171717;
  font-family: "Poppins", sans-serif;
`;

export const SubTitle = styled.h2`
  ${textStyles}
  font-size: 30px;
  font-weight: bold;
`;

export const Title = styled.h1`
  ${textStyles}
  font-size: 50px;
  font-weight: bold;
`;

export const Paragraph = styled.p`
  ${textStyles}
  font-size:15px;
`;

export const LabelText = styled.span`
  font-size: 15px;
  color: #171717;
  font-weight: bold;
  font-family: "Poppins";
  margin-bottom: 10px;
  display: inline-block;
`;

export const ErrorText = styled.p`
  font-size: 15px;
  color: #171717;
  font-family: "Poppins";
  margin-top: 10px;
  color: red;
`;

export const TextLink = styled.h3`
  ${textStyles};
  text-decoration: underline;
  font-size: 22px;
  font-weight: 400;
`;

export const Column = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.repeat}, 1fr)`};
  gap: 14px;
  margin-top: 12px;
  align-items:center;

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonFooterEnd = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
