import Const from "../components/helpers/const";

export const isImageExists = (url) => {
  var image = new Image();

  image.src = url;

  if (!image.complete) {
    return false;
  } else if (image.height === 0) {
    return false;
  }

  return true;
};

export const getImageUrlDefault = (value) => {
  const name = value ? value?.trim().toString().toLowerCase() : 'default';

  if(Const.isOurDesiStore){
    return "/assets/images/desi-store-default.png"
  }else{
    return "/assets/images/default-liquor-image.png"
  }

  // if (name.startsWith('beer')) {
  //   return '/assets/images/beer-default.png';
  // } else if (name.startsWith('wine')) {
  //   return '/assets/images/wine-default.png';
  // } else if (name.startsWith('liquor')) {
  //   return '/assets/images/liquor-default.png';
  // } else if (name.startsWith('cider')) {
  //   return '/assets/images/cider-default.png';
  // } else if (name.startsWith('mead')) {
  //   return '/assets/images/mead-default.png';
  // } else if (name.startsWith('misc')) {
  //   return '/assets/images/misc-default.png';
  // } else if (name.startsWith('venue')) {
  //   return '/assets/images/venue-default.png';
  // } else if (name.startsWith('default')) {
  //   return '/assets/images/beer-default.png';
  // } else {
  //   return '/assets/images/beer-default.png';
  // }
};
