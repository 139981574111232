import { alpha, styled } from '@mui/material/styles';
import { treeItemClasses, TreeItem } from '@mui/x-tree-view';
import TransitionComponent from '../TransitionComponent/TransitionComponent';

const StyledTreeItem = styled((props) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    [`& .${treeItemClasses.content}`]: {
        flexDirection: 'row-reverse'
    },
}));

export default StyledTreeItem