import React from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import LightTooltip from "../../../../util_comps/LightTooltip";

const ABV = (props) => {
    const abvs = [
        {
            key: "<1.0%",
            value: "0-1",
        },
        {
            key: "1.0% - 4.9%",
            value: "1-4.9",
        },
        {
            key: "5.0% - 6.9%",
            value: "5-6.9",
        },
        {
            key: "7.0% - 9.9%",
            value: "7-9.9",
        },
        {
            key: "10.0% - 11.9%",
            value: "10-11.9",
        },
        {
            key: "12.0% - 14.9%",
            value: "12-14.9",
        },
        {
            key: "15.0% - 39.9%",
            value: "15-39.9",
        },
        {
            key: "40.0% - 49.9%",
            value: "40-49.9",
        },
    ];
    return (
        <React.Fragment>
            <Accordion
                TransitionProps={{ unmountOnExit: true }}
                square
                onChange={() => props.handlePanelOpen("abv")}
                expanded={props.activePanel === "abv"}
            >
                <AccordionSummary
                    expandIcon={
                        props.activePanel === "abv" ? (
                            <IndeterminateCheckBoxIcon style={{ fill: "black" }} />
                        ) : (
                            <AddBoxIcon style={{ fill: "black" }} />
                        )
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Only show products that fall within a range of alcohol content</span>} arrow placement="top">
                            <Typography style={{ fontSize: '16px', color: props.initState.current.filters.abv !== props.filters.abv && props.activePanel !== "abv" ? 'red' : 'black' }} variant="h6">ABV</Typography>
                        </LightTooltip>
                        {props.filters.abv !== 'none' && <Typography color="secondary" onClick={e => { e.stopPropagation(); props.setFilters({ ...props.filters, abv: 'none' }) }} style={{ paddingLeft: '10%' }} variant="body1">(Reset)</Typography>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={props.filters.abv}
                            onChange={e => props.setFilters({ ...props.filters, abv: e.target.value })}
                        >
                            {abvs.map((val) => {
                                return (
                                    <FormControlLabel
                                        value={val.value}
                                        control={<Radio />}
                                        label={val.key}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

export default ABV;
