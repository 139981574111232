import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import Customize from "../../../assets/feature.png";
import listicon from "../../../assets/list-text.png";
import viewproductgroupimg from "../../../assets/view-product-group.png";
import Productgroupnamedesistoreimg from "../../../assets/Product-group-name-desistore.png";
import searchproductgroupimg from "../../../assets/search-product-group.png";
import Productgroupnameimg from "../../../assets/Product-group-name.png";
import routePaths from '../../../config/route-paths';
const AddNewProductGroups = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={Customize} />
            </div>
            Customize Your Website & App              
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AddNewProductGroups} className={cn('selected')}>How to Add New Product Groups</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropProductGroupInterface}>Drag & Drop Product Group Interface</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingProductGroup}>Edit Existing Product Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropBanners}>Drag & Drop Banners</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateNewBannerGroup}>Create New Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingBannerGroup}>Edit Existing Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateCustomStorePage}>Create Custom Store Page</Link></li>
</ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         How to Add New Product Groups
         </Typography>
         <p><strong>Product groups allow you to display featured products on your store’s home page. These can be categories, such as certain styles of
beer, wine, and spirits, or they can be custom groups of tagged items.</strong></p>
         <p>
            
         <ol>
            <li>To add a new product group, go to the ‘<strong>Customize Homepage</strong>’ page of the admin website.</li>

            <li>On the left hand side, you will see Product Groups and Banners, and on the right side, you can see a live preview of your store’s homepage.</li>
            
<span className={cn('addnewproductsimg1')}>
    <img src={Const.isOurDesiStore ? Productgroupnamedesistoreimg : viewproductgroupimg}/>
    </span>


    <li>There will be a search bar on the left side that is filled with the text ‘<strong>Search Product Group</strong>’. Under this search bar you will see
underlined blue text that reads ‘<strong>View Product Groups</strong>’. Click this text.</li>


    <span className={cn('addnewproductsimg1')}>
    <img src={searchproductgroupimg} />
    </span>


    <li>You will be taken to a page that shows all of your product groups. To add a new one, click on the ‘<strong>Add New</strong>’ button in the
top-right corner of the page.</li>


    <span className={cn('addnewproductsimg')}>
    <img src={Productgroupnameimg} />
    </span>
    
   
<li>Here, you can give your <strong>Product Group a name</strong>. Afterwards, you will select which products will go in this group in Choose List Type.</li>

<li>Category List will allow you to group products by category. You can further refine by subcategory if you wish. If you do not want to
display all the subcategories, you can remove them by clicking the ‘X’ next to each one under ‘<strong>Selected Categories</strong>’.</li>


<li>Product List will allow you to group products together, rather than entire categories and subcategories. Customers will see specific
products and not categories and subcategories.</li>

<li>Choosing the Tags list type will let you select certain product tags to group together, if the products you want to display are not
necessarily all the same style or category. You can add a new tag, but keep in mind that you will leave the Product Groups
page and be redirected to ‘<strong>My Inventory</strong>’.</li>

<li>When you are finished creating a new product group, click the ‘<strong>Apply</strong>’ button in the bottom left. You will be returned to the Product
Groups list, and here you will see the new group you just created. You can edit or delete the group from this page.</li>

</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default AddNewProductGroups