import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash';
import MyLibraryFilters from './MyLibraryFilters/MyLibraryFilters'
import axios from '../../../../Util/axiosConfig';
import styles from './MyLibrary.module.scss'
import Loader from '../../Loader/Loader';
import { Button } from '@mui/material';
import imageBaseUrl from '../../../../Util/imageBaseUrl';
// import { Masonry } from '../../../../Util/useMarcy';
import { InView } from 'react-intersection-observer';

function MyLibrary(props) {
    
  const [sortBy, setSortBy] = useState('created_at');
  const [searchQuery, setSearchQuery] = useState('');
  const [images, setImages] = useState(null);


  ///web-builder/web-page-gallery
  const fetchImages = (params) => {
    if (!params?.page) {
      setImages(null)
    }
    axios.get(`/web-builder/web-page-gallery`, { 
      params: {
        'sort-by-order': 'DESC',
        'sort-by': 'created_at',
        ...params,
      }
     }).then((response) => {
      setImages({
        data: response.data.data.results,
        pagination: response.data.data.pagination,
        base_image_url: response.data.data.base_image_url,
      })
    }).catch((error) => {
      console.log('🚀OUTPUT --> error:', error);
    });
  }


  useEffect(() => {
    fetchImages()
  }, [])
  
  const handleChangeSort = (event) => {
    setSortBy(event.target.value);
    let params = { page: props.listings?.pagination?.current_page };
    if (event.target.value === 'created_at') {
      // params['sort-by-order'] = event.target.value;
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = event.target.value;
    } else if (event.target.value) {
      params['sort-by-order'] = event.target.value;
    }
    if (searchQuery) {
      params.q = searchQuery;
    }
    

    // props.fetchListing(params);
  };

  const handleSearchChange = (event) => {
    let params = { page: props.listings?.pagination?.current_page };
    if (event.target.value) {
      params.q = event.target.value;
    }


    // params['sort-by-order'] = 'DESC';
    // params['sort-by'] = 'created_at';
    fetchImages(params)
    // props.fetchListing(params);
  };
  
  const handleClearAllFilters = (event) => {
    setSortBy('created_at');
    setSearchQuery('');
    let params = {
      page: props.listings?.pagination?.current_page,
      'sort-by-order': 'DESC',
      'sort-by': 'created_at',
    };

    // props.fetchListing(params);
  };

  const debounceSearch = useCallback(debounce(handleSearchChange, 1000), [ sortBy, searchQuery ]);
    
  return (
    <>
        <MyLibraryFilters {...{ sortBy, searchQuery, setSearchQuery, handleChangeSort, debounceSearch, handleClearAllFilters }} />
        {!images ? <Loader /> : images?.data?.length === 0 ? (
          <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[200px]">
            No Images available
          </div>
        ) : (
              <div className={styles.image_wrapper}>
                {/* <Masonry> */}
                  {images?.data?.map((image) =>  (
                    <ImageItem key={image.id} image={image} {...props} />
                  ))}
                {/* </Masonry> */}
                
                <InView onChange={(inView, entry) => {
                  if (images && images?.length > 0 && inView) {
                    let params = {
                      page: images?.pagination?.current_page + 1,
                    }
                    if(searchQuery){
                      params.query = searchQuery
                    }
                    fetchImages(params)
                  }
                }
                }>
                  {images && images?.length > 0 && (
                    <Loader />
                  )}
                  {/* <div> Loading... </div>   */}
                </InView>
              </div>
              )}
    </>
  )
}

export default MyLibrary;


const ImageItem = ({ image, ...props }) => {

  function shortenText(text) {
    return text.length > 10 ? text.substring(0, 10) + "..." : text;
  }

  return (
    <div className={styles.image}>
      <img src={`${imageBaseUrl}/WebPage/${image.image}`} alt={image.title} />
      <div className={styles.image_text}> 
        {image?.title && <div title={image?.title}>{shortenText(image?.title)}</div>}
        <Button variant='contained'
        sx={{ 
          background:'#fff', 
          color:'#000', 
          borderRadius:'20px',
          "&:hover": {
            color: "#fff"
          } 
        }} 
        onClick={() => {
          console.log(image); 
          props.handleImageGalleryUpload({
            image: `${imageBaseUrl}/WebPage/${image.image}`,
          })
        }}
        >Use Image</Button>
      </div>
    </div>
  );
};