import React, { memo } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import styles from './ProductSquareChipSlider.module.scss';
import { useId } from 'react';
import Loader from '../Library/Loader';
import { getImageUrlDefault } from '../../utils/isImageExists';
import clsx from 'clsx';

const CatSquareChipBreakpoint = {
    2700: {
        slidesPerView: 8.9,
    },
    2500: {
        slidesPerView: 8.2,
    },
    2300: {
        slidesPerView: 7.7,
    },
    2100: {
        slidesPerView: 7.2,
    },
    1900: {
        slidesPerView: 6.5,
    },
    1600: {
        slidesPerView: 5.5,
    },
    1440: {
        slidesPerView: 5,
    },
    1360: {
        slidesPerView: 7.8,
    },
    1250: {
        slidesPerView: 7,
    },
    1100: {
        slidesPerView: 6.5,
    },
    992: {
        slidesPerView: 6,
    },
    920: {
        slidesPerView: 5.8,
    },
    840: {
        slidesPerView: 5.5,
    },
    769: {
        slidesPerView: 5,
    },
    700: {
        slidesPerView: 6.1,
    },
    650: {
        slidesPerView: 5.5,
    },
    600: {
        slidesPerView: 5.2,
    },
    570: {
        slidesPerView: 4.9,
    },
    520: {
        slidesPerView: 4.6,
    },
    470: {
        slidesPerView: 4.2,
    },
    430: {
        slidesPerView: 3.8,
    },
    380: {
        slidesPerView: 3.5,
    },
    320: {
        slidesPerView: 3.1,
    }
}

const CatSquareChipBreakpointSmall = {
    320: {
        slidesPerView: 4
    },
}

const ProductSquareChipSlider = (props) => {

    const id = useId()

    return (
        <div
            className={styles.product_square_chip_slider}
        >
            <Swiper
                className={clsx(styles.category_square_chip_swiper, props.isBigPreview && styles.category_square_chip_swiper2)}
                navigation={true}
                loop={false}
                lazy={true}
                pagination={false}
                observeParents={true}
                observer={true}
                breakpoints={props.isBigPreview ? CatSquareChipBreakpoint : CatSquareChipBreakpointSmall}
                slidesPerGroup={1}
            >
                {props.isLoading ? (
                    <Loader />
                ) : props.products?.map((childData, index) => {
                    return (
                        <SwiperSlide
                            key={`${id}-${index}`}
                            style={{
                                padding: '4px'
                            }}
                        >
                            <div
                                className={clsx(styles.category_square_chip, props.isBigPreview && styles.category_square_chip2)}
                            >
                                <div>
                                    <img
                                        className={clsx(styles.category_square_chips_image, props.isBigPreview && styles.category_square_chips_image2)}
                                        src={props.isCategoryList ? childData.category_image : childData.image_file}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = getImageUrlDefault();
                                        }}
                                        loading="lazy"
                                    />
                                    <div
                                        className={clsx(styles.square_chip_title, props.isBigPreview && styles.square_chip_title2)}
                                    >
                                        {childData.MainCatName ? childData.MainCatName : childData.categoryName}
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}

            </Swiper>
        </div>
    )
}

export default memo(ProductSquareChipSlider)