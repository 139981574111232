import { useNavigate, useParams } from 'react-router'

import { Button, MenuItem, Pagination, TextField, inputBaseClasses } from '@mui/material';
import {
  LocalizationProvider,
  PickersDay,
  StaticDatePicker,
  dateCalendarClasses,
  pickersLayoutClasses,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import Comments from '../Comments/Comments';
import styles from './MarketingPlanner.module.scss';
import { toast } from 'react-toastify';
import { StyledStaticDatePicker } from '../Library/StaticDatePicker/StaticDatePicker';
import GenericSelect from '../Library/GenericSelect/GenericSelect';
import { format } from 'date-fns';
import MarketingContext from '../Util/marketingContext';
import axios from '../Util/axiosConfig';

const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0;
};

const PinkDay = (props) => {
  return (
    <PickersDay
      classes={{
        root: styles.mydatepicker_pinkday,
      }}
      {...props}
    />
  );
};
const RedDay = (props) => {
  return (
    <PickersDay
      classes={{
        root: styles.mydatepicker_redday,
      }}
      {...props}
    />
  );
};

const GreenDay = (props) => {
  return (
    <PickersDay
      classes={{
        root: styles.mydatepicker_greendot,
      }}
      {...props}
    />
  );
};

const GrayDay = (props) => {
  return (
    <PickersDay
      classes={{
        root: styles.mydatepicker_graydot,
      }}
      {...props}
    />
  );
};

const MarketingPlanner = () => {
  const { pages, names } = useContext(MarketingContext);
  const navigate = useNavigate();
  const [value, setValue] = useState(new Date());
  const [accounts, setAccounts] = useState('all');
  const [schduleAccounts, setScheduleAccounts] = useState('all');
  const [posts, setPosts] = useState([]);
  const [schedulePosts, setSchedulePosts] = useState([]);
  const [schedulePagination, setSchedulePagination] = useState({});
  const [since, setSince] = useState('');
  const [until, setUntil] = useState('');

  const fetchPosts = (pageid, firstDay, lastDay) => {
    axios
      .get('/marketing/social-post/list', {
        params: {
          page_id: pageid != 'all' ? pageid : undefined,
          since: firstDay || since,
          until: lastDay || until,
        },
      })
      .then((response) => {
        setPosts(response.data.data.results);
        setSchedulePagination(response.data.data.pagination);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const fetchScheduled = (pageid, pageNo) => {
    axios
      .get('/marketing/social-post/schedule', {
        params: {
          page_id: pageid != 'all' ? pageid : undefined,
          page: pageNo,
          limit: 5,
        },
      })
      .then((response) => {
        setSchedulePosts(response.data.data.results);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const renderDay = ({ selectedDay, day, ...pickersDayProps }) => {
    if (isWeekend(day['$d'])) {
      return <RedDay day={day} {...pickersDayProps} />;
    }

    for (let index = 0; index < posts.length; index++) {
      let dateObj = new Date(posts[index].post_create_at);
      if (
        new Date(day['$d']?.toDateString()).getTime() ===
          new Date(dateObj.toDateString()).getTime() &&
        new Date(dateObj.toDateString()).getTime() > new Date()
      ) {
        return <GrayDay day={day} {...pickersDayProps} />;
      }
    }

    for (let index = 0; index < posts.length; index++) {
      let dateObj = new Date(posts[index].post_create_at);
      if (
        posts[index].post_status == 0 &&
        new Date(day['$d']?.toDateString()).getTime() ===
          new Date(dateObj.toDateString()).getTime()
      ) {
        return <PinkDay day={day} {...pickersDayProps} />;
      }
    }

    for (let index = 0; index < posts.length; index++) {
      let dateObj = new Date(posts[index].post_create_at);
      if (
        new Date(day['$d']?.toDateString()).getTime() ===
        new Date(dateObj.toDateString()).getTime()
      ) {
        return <GreenDay day={day} {...pickersDayProps} />;
      }
    }

    return (
      <PickersDay
        classes={{
          root: styles.mydatepicker_day,
        }}
        day={day}
        {...pickersDayProps}
      />
    );
  };

  useEffect(() => {
    fetchPosts();
    fetchScheduled();
  }, []);

  useEffect(() => {
    var date = value,
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = format(new Date(y, m, 1), 'yyyy-MM-dd');
    var lastDay = format(new Date(y, m + 1, 0), 'yyyy-MM-dd');
    setSince(firstDay);
    setUntil(lastDay);
    fetchPosts(accounts, firstDay, lastDay);
    fetchScheduled(schduleAccounts);
  }, [value, accounts, schduleAccounts]);

  const handlePaginationChange = (event, value) => {
    fetchScheduled(undefined, value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title_container}>
        <div className={styles.dashboard_text}>Posts</div>

        <div>
          <Button
            style={{
              backgroundColor: '#0B9DE4',
              borderRadius: '30px',
              fontWeight: 600,
            }}
            variant='contained'
            type='button'
            onClick={() => {
              axios
                .post('/marketing/social-post-group/initiate')
                .then((groupResponse) => {
                  navigate(
                    `/marketing/create-post/${groupResponse.data?.data?.result?.id}`
                  );
                })
                .catch((err) => {
                  toast.error(err?.response?.data?.message);
                });
            }}
          >
            Create Post
          </Button>
        </div>
      </div>

      <div className={styles.date_post_container}>
        <div className={styles.calendar_container}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledStaticDatePicker
              className={styles.calendar}
              InputProps={{
                className: styles.calendar_input,
              }}
              sx={{
                [`& .${dateCalendarClasses.root}`]: {
                  height: 'auto',
                  width: 'auto',
                  maxHeight: '440px'
                },
              }}
              // renderDay={renderDay}
              slots={{ day: renderDay }}
              slotProps={{
                day: { selectedDay: value },
              }}
              displayStaticWrapperAs='desktop'
              openTo='day'
              value={dayjs(value)}
              onChange={(newValue) => {
                console.log(newValue['$d']);
                setValue(newValue['$d']);
              }}
              // renderInput={(params) => <TextField {...params} />}
              onMonthChange={(newValue) => {
                setValue(newValue['$d']);
              }}
            />
          </LocalizationProvider>
        </div>

        <div className={styles.post_container}>
          <GenericSelect
            style={{
              backgroundColor: 'white',
            }}
            fullWidth={true}
            value={accounts}
            onChange={(event) => {
              setAccounts(event.target.value);
              // fetchPosts(event.target.value)
            }}
          >
            <MenuItem value='all'>All Accounts</MenuItem>
            {pages.concat(names).map((val) => {
              return <MenuItem value={val.page_id}>{val.name}</MenuItem>;
            })}
          </GenericSelect>
          {Boolean(posts.length) ? (
            <div className={styles.comments_container}>
              {posts.map((data) => (
                <Comments
                  onClick={() => {
                    navigate(
                      `/marketing/post-details/${data.post_group_id}`
                    );
                  }}
                  title={
                    new Date(data.post_create_at).getTime() > new Date()
                      ? 'Scheduled'
                      : data.post_status == 1
                      ? 'Published'
                      : 'Post need attention'
                  }
                  time={format(new Date(data.post_create_at), 'hh:mm a')}
                  isFacebook={data.posted_on?.includes('0')}
                  isInstagram={data.posted_on?.includes('1')}
                  isTwitter={data.posted_on?.includes('2')}
                  description={data.content}
                  images={data.images}
                  groupId={data.post_group_id}
                />
              ))}
            </div>
          ) : (
            <div
              style={{
                fontWeight: 600,
                fontSize: 18,
                textAlign: 'center',
                paddingTop: 20,
              }}
            >
              No Post Available
            </div>
          )}
        </div>
      </div>

      <div className={styles.future_post_container}>
        <div>
          <div className={styles.dashboard_text}>Future Scheduled Posts</div>

          <GenericSelect
            style={{
              backgroundColor: 'white',
            }}
            fullWidth={true}
            value={schduleAccounts}
            onChange={(event) => {
              setScheduleAccounts(event.target.value);
              // fetchScheduled(event.target.value)
            }}
          >
            <MenuItem value='all'>All Accounts</MenuItem>
            {pages.concat(names).map((val) => {
              return <MenuItem value={val.page_id}>{val.name}</MenuItem>;
            })}
          </GenericSelect>
        </div>

        {Boolean(schedulePosts.length) ? (
          <div className={styles.comments_future_container}>
            {schedulePosts.map((data) => (
              <Comments
                onClick={() => {
                  navigate(`/marketing/post-details/${data.post_group_id}`);
                }}
                title={
                  new Date(data.post_create_at).getTime() > new Date()
                    ? 'Scheduled'
                    : data.post_status == 1
                    ? 'Published'
                    : 'Post need attention'
                }
                time={format(new Date(data.post_create_at), 'hh:mm a')}
                isFacebook={data.posted_on?.includes('0')}
                isInstagram={data.posted_on?.includes('1')}
                isTwitter={data.posted_on?.includes('2')}
                description={data.content}
                images={data.images}
                groupId={data.post_group_id}
              />
            ))}
          </div>
        ) : (
          <div
            style={{
              fontWeight: 600,
              fontSize: 18,
              textAlign: 'center',
              paddingTop: 20,
            }}
          >
            No Post Available
          </div>
        )}

        {Boolean(schedulePosts.length) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 15,
            }}
          >
            <Pagination
              count={schedulePagination.last_page}
              page={schedulePagination.current_page}
              onChange={handlePaginationChange}
              variant='outlined'
              shape='rounded'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketingPlanner;
