import React, { useEffect, useState, useRef } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Paper,
} from "@mui/material";
import Item from "./Item";
import { useSelector, useDispatch } from "react-redux";
import AddForm from './AddForm';
import StyledTableCell from "../../../../Library/StyledTableCell/StyledTableCell";
import styles from './List.module.scss'

const List = (props) => {
    const { order_details, order } = props.orderDetails;

    return (
        <TableContainer
            component={Paper}
            style={{
                boxShadow: 'none',
                borderBottom: '1px solid #d3d3d3'
            }}
        >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">
                            {/* <div
                                    className={styles.head_title}
                                >
                                    test
                                </div> */}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                            <div
                                className={styles.head_title}
                            >
                                Product Name
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                            <div
                                className={styles.head_title}
                            >
                                Format
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align="right">
                            <div
                                className={styles.head_title}
                            >
                                Each
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                            <div
                                className={styles.head_title}
                            >
                                Quantity
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align="right">
                            <div
                                className={styles.head_title}
                            >
                                Total
                            </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">

                        </StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.open ? (
                        <AddForm
                            setSaveable={props.setSaveable}
                            open={props.open}
                            setOpen={props.setOpen}
                            updateOrderItem={props.updateOrderItem}
                            updateOrderItemFormat={props.updateOrderItemFormat}
                            updateOrderItemPrice={props.updateOrderItemPrice}
                            updateOrderItemSubFees={props.updateOrderItemSubFees}
                            updateOrderItemQuantity={props.updateOrderItemQuantity}
                            addOrderItem={props.addOrderItem}
                            deleteOrderItem={props.deleteOrderItem}
                        />
                    ) : null}
                    {order_details?.map((val, i) => {
                        return <Item
                            index={i}
                            orderDetails={order_details}
                            setOrderDetails={props.setOrderDetails}
                            isEditMode={props.isEditMode}
                            setSaveable={props.setSaveable}
                            data={val}
                            handleSave={props.handleSave}
                            updateOrderItem={props.updateOrderItem}
                            updateOrderItemFormat={props.updateOrderItemFormat}
                            updateOrderItemPrice={props.updateOrderItemPrice}
                            updateOrderItemSubFees={props.updateOrderItemSubFees}
                            updateOrderItemQuantity={props.updateOrderItemQuantity}
                            addOrderItem={props.addOrderItem}
                            deleteOrderItem={props.deleteOrderItem}
                        />;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default List;
