import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ClaimMyBusinessForm from '../ClaimMyBusiness/ClaimMyBusinessForm/ClaimMyBusinessForm'
import Loader from '../Library/Loader';
import ManageListing from '../ManageListing/ManageListing';
import axios from '../../utils/axiosConfig'

const ManageListingRoute = () => {

    const user = useSelector((store) => store.user);

    const [details, setDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const fetchStore = () => {
        setIsLoading(true)
        axios.get(`/venue-listing/get/${user?.data?.venueid}`)
            .then((response) => {
                setDetails(response.data.data.result)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (user?.data?.venueid) {
            fetchStore()
        }
    }, [user?.data?.venueid])


    return (
        isLoading ? (
            <Loader />
        ) : (
            <ManageListing details={details} id={user?.data?.venueid} />
        )
    )
}

export default ManageListingRoute