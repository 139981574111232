import React, { useEffect, useState, useRef } from "react";
import { Typography, Breadcrumbs, Button, FormControlLabel, IconButton, Dialog, useMediaQuery, Collapse, Menu, MenuItem } from "@mui/material";
import Details from "./Parts/Details/Details";
import Detail from "./Parts/Details/Parts/Detail";
import List from "./Parts/Products/List";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useAuth } from "../../../hooks/useAuth";
import { usePrompt } from "../../../hooks/usePageLeave";
import { Add, ArrowBack, ChevronRight, KeyboardArrowUp, Remove } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveOrders, cancelOrder, changeStatus } from '../../../servise/orderAPI';
import { changeOrderStatus as changeStatusAction } from '../../../redux/actions/ordersActions';
import './style.css';
import { toast } from "react-toastify";
import styles from './OrderDetails.module.scss'
import GenericButton from "../../Library/GenericButton/GenericButton";
import GenericSwitch from "../../Library/GenericSwitch/GenericSwitch";
import { useConfirm } from "material-ui-confirm";
import RefundOrderDialog from "../../Dialogs/RefundOrderDialog/RefundOrderDialog";
import GenericLoadingButton from "../../Library/GenericLoadingButton/GenericLoadingButton";
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import { format } from "date-fns";
import PaymentInfo from "./Parts/Details/Parts/PaymentInfo";
import { getImage } from '../../CustomPageBuilder/EditSection/Util/helpers';
import GenericTextField from '../../Library/GenericTextField/GenericTextField';
import PageLoader from "../../Library/PageLoader/PageLoader";
import AssignDriverDialog from "../../Orders/AssignDriverDialog/AssignDriverDialog";
import AddForm from './Parts/Products/AddForm'
import generatePDF from "react-to-pdf";
import { convertToId } from "../../../utils/helpers";
import ModifierDialog from './Parts/Products/ModifierDialog/ModifierDialog';
import DeliveryAcceptButton from "../../DeliveryAcceptButton/DeliveryAcceptButton";
import Detail2 from "./Parts/Details/Parts/Detail2";


const statusColor = {
    'o': '#FED813',
    'a': '#0060EF',
    'r': '#00C437',
    'can': '#E90000',
    'clo': '#00C437'
}

const options = {
    filename: "orders.pdf",
    page: {
        margin: 10
    }
};


const OrderDetails = (props) => {

    const targetRef = useRef()
    const dispatch = useDispatch((data) => data);
    const { id } = queryString.parse(window.location.search);

    const history = useNavigate();

    const isMobileScreen = useMediaQuery('(max-width:768px)');
    const user = useSelector((store) => store.user);

    const [showAssignDriver, setShowAssignDriver] = useState(false)
    const [orderDetails, setOrderDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [addForm, setAddForm] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false)
    const [showRefundPopup, setShowRefundPopup] = useState(false)
    const [isCancelRefundLoading, setIsCancelRefundLoading] = useState(false)
    const [isStatusLoading, setIsStatusLoading] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isPrinterSaved, setIsPrinterSaved] = useState(false)
    const [settings, setSettings] = useState({})
    const confirm = useConfirm()

    const [isDirty, setIsDirty] = useState(false)
    // usePrompt("Are you sure want to discard changes?", isDirty);
    const [saveable, setSaveable] = useState(false);

    const [deliveryPartners, setDeliveryPartners] = useState([])
    const [isDeliveryLoading, setIsDeliveryLoading] = useState(true)
    const [isShippingLoading, setIsShippingLoading] = useState(true)
    const [shippingPartners, setShippingPartners] = useState([])

    const [showCustomerInfo, setShowCustomerInfo] = useState((user?.data?.biz_users_role === 1 || user?.data?.biz_users_role === 2) ? false : true)
    const [showItems, setShowItems] = useState((user?.data?.biz_users_role === 1 || user?.data?.biz_users_role === 2) ? true : false)
    const [isDoorDashEnabled, setIsDoorDashEnabled] = useState(false)

    const checkDoorDash = () => {
        nodeAxios.get('/doordash/check-doordash')
            .then((res) => {
                setIsDoorDashEnabled(res.data.data)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    useEffect(() => {
        checkDoorDash()
    }, [])


    useEffect(() => {
        if (saveable) {
            setIsDirty(true)
        } else {
            setIsDirty(false)
        };
    }, [saveable]);

    const handleClose = async () => {

        confirm({
            title: 'Do you wish to confirm to delete this order',
        })
            .then(async () => {
                try {
                    setIsCancelRefundLoading(true)
                    await cancelOrder(id);
                    closeOrder()
                    toast.success('Successfully cancelled the order')
                } catch (err) {
                    toast.error(err.response?.data?.message)
                } finally {
                    setIsCancelRefundLoading(false)
                    fetchOrderDetails()
                }
            })
            .catch(() => {

            });
    };

    const handleSave = async () => {

        if (parseFloat(orderDetails.order_venue?.venueproducttotal) > parseFloat(orderDetails.order_venue?.venuegrandtotal)) {
            toast.error('Edited order amount can not be greater than previous order amount')
            return
        }
        try {
            setIsSaveLoading(true)
            nodeAxios.post(`/orders/substitute-order/${id}`, {
                order_items: orderDetails?.order_details?.map((data) => {

                    let amount = 0

                    Object.entries(data?.modifiers || {})?.forEach(([key, value]) => {
                        console.log(key, value)
                        let obj = data.modifiers_list?.find((fdata) => fdata?.modifier_id == key)
                        console.log(obj)
                        obj?.modifier_option?.forEach((fdata2) => {
                            console.log(fdata2, value?.includes(fdata2?.id))
                            if (value?.includes(fdata2?.id)) {
                                amount = amount + parseFloat(fdata2?.price || 0)
                            }
                            console.log(amount)
                        })
                    })


                    return ({
                        ...data,
                        modifier_amount: amount
                    })
                }),
                ...orderDetails.order_venue
            })
                .then(() => {
                    toast.success('Successfully saved the order')
                })
                .catch((err) => {
                    toast.error(err.response?.data?.message)
                })
                .finally(() => {
                    fetchOrderDetails()
                })
            setSaveable(false);
            setIsEditMode(false)
        } catch (err) {
            if (err.response) {
                toast.error(err.response?.data?.message)
            }
        } finally {
            setIsSaveLoading(false)
        }
    };

    const handleStatusChange = async (data, isDoorDash) => {

        const id = data.Id
        const status = data.status

        let nextStatus
        switch (status) {
            case 'o':
                nextStatus = 'a'
                break;
            case 'a':
                nextStatus = 'r'
                break;
            case 'r':
                nextStatus = 'clo'
                break;

            default:
                break;
        }
        setIsStatusLoading(true)
        nodeAxios.post('/orders/change-status', {
            orderId: id,
            nextStatus: nextStatus,
            actionTakenFrom: isMobileScreen ? 'App' : 'Web',
            isDoordash: isDoorDash
        })
            .then(() => {
                toast.success('Order status updated')
                fetchOrderDetails()
                dispatch(changeStatusAction({ id, status: nextStatus, currentStatus, update: false }));
            })
            .catch((err) => {
                toast.error(err.response?.data?.message)
            })
            .finally(() => {
                setIsStatusLoading(false)
            })
    }

    const fetchOrderDetails = () => {
        axios.get(`/driver/get-order-detail/${id}`)
            .then((res) => {
                setOrderDetails({
                    ...res.data?.data?.results,
                    order_details: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchOrderDetails()
    }, [id])

    const updateOrderItem = (payload) => {
        setOrderDetails((prevState) => {
            let prevDetail_1 = prevState.order_details.find(e => e.product_id === payload.prevId);
            let updatedDetails = prevState.order_details.map((val) => {
                if (val.product_id === payload.prevId) {
                    return {
                        product_id: payload.value.ProductId,
                        name: payload.value.ProductName,
                        productformat: payload.value.format,
                        product_image: payload.value.image,
                        quantity: 1,
                        price: payload.value.Price,
                        vpid: payload.value.id,
                        SKU: payload.value.sku,
                        brewery_name: payload.value.ProducerName,
                        product_category: payload.value.category_name
                    }
                } else {
                    return val;
                };
            });
            return {
                ...prevState,
                order: {
                    ...prevState.order,
                    cc_amount: (parseFloat(prevState.order.cc_amount) - parseFloat(prevDetail_1.price)) + parseFloat(payload.value.Price),
                    grand_total: (parseFloat(prevState.order.grand_total) - parseFloat(prevDetail_1.price)) + parseFloat(payload.value.Price)
                },
                order_venue: {
                    ...prevState.order_venue,
                    venueproducttotal: (parseFloat(prevState.order_venue.venueproducttotal) - parseFloat(prevDetail_1.price)) + parseFloat(payload.value.Price)
                },
                order_details: updatedDetails
            }
        })
    }


    const updateOrderItemFormat = (payload) => {
        setOrderDetails((prevState) => {
            let prevDetail_3 = prevState.order_details.find(e => e.product_id === payload.product_id);
            let changedFormatDetails = prevState.order_details.map((val) => {
                if (val.product_id === payload.product_id) {
                    return {
                        ...val,
                        productformat: payload.format.format,
                        unit_price: payload.format.Price,
                        product_id: payload.format.ProductId,
                        vpid: payload.format.id
                    }
                } else {
                    return val;
                }
            });

            let venueProductTotal = changedFormatDetails.reduce((total, data) => parseFloat(total) + (parseFloat(data.unit_price) * parseInt(data.quantity)), 0)
            return {
                ...prevState,
                order: {
                    ...prevState.order,
                    // cc_amount: (parseFloat(prevState.order.cc_amount) - parseFloat(prevDetail_3.price)) + parseFloat(payload.format.Price),
                    // grand_total: (parseFloat(prevState.order.grand_total) - parseFloat(prevDetail_3.price)) + parseFloat(payload.format.Price)
                },
                order_venue: {
                    ...prevState.order_venue,
                    venueproducttotal: venueProductTotal
                },
                order_details: changedFormatDetails
            }
        })
    }
    const updateOrderItemPrice = (payload) => {
        setOrderDetails((prevState) => {
            let prevDetail_5 = prevState.order_details.find(e => e.product_id === payload.product_id);
            let changedPriceDetails = prevState.order_details.map((val) => {
                if (val.product_id === payload.product_id) {
                    return {
                        ...val,
                        unit_price: payload.price,
                        price: parseFloat(val.quantity) * parseFloat(payload.price)
                    }
                } else {
                    return val;
                }
            });

            let venueProductTotal = changedPriceDetails.reduce((total, data) => parseFloat(total) + (parseFloat(data.unit_price) * data.quantity), 0)
            return {
                ...prevState,
                order_venue: {
                    ...prevState.order_venue,
                    venueproducttotal: venueProductTotal
                },
                order_details: changedPriceDetails
            }
        })
    }
    const updateOrderItemSubFees = (payload) => {
        setOrderDetails((prevState) => {
            return {
                ...prevState,
                order_venue: {
                    ...prevState.order_venue,
                    [payload.key]: payload.value
                },
            }
        })
    }

    const updateOrderItemQuantity = (payload) => {
        setOrderDetails((prevState) => {
            let prevDetail_4 = prevState.order_details.find(e => e.product_id === payload.product_id);
            console.log(prevDetail_4)
            let changedQuantityDetails = prevState.order_details?.map((val) => {
                if (val.product_id === payload.product_id) {
                    return {
                        ...val,
                        quantity: payload.quantity
                    }
                } else {
                    return val;
                }
            });

            let venueProductTotal = changedQuantityDetails.reduce((total, data) => parseFloat(total) + (parseFloat(data.unit_price) * parseInt(data.quantity)), 0)

            return {
                ...prevState,
                order: {
                    ...prevState.order,
                    // cc_amount: parseFloat(prevState.order.cc_amount) + (parseFloat(prevDetail_4.unit_price) * parseFloat(prevDetail_4.quantity)),
                    // grand_total: parseFloat(prevState.order.grand_total) + (parseFloat(prevDetail_4.unit_price) * parseFloat(prevDetail_4.quantity))
                },
                order_venue: {
                    ...prevState.order_venue,
                    venueproducttotal: venueProductTotal

                },
                order_details: changedQuantityDetails
            }

            if (payload.action === 'increment') {
                return {
                    ...prevState,
                    order: {
                        ...prevState.order,
                        cc_amount: parseFloat(prevState.order.cc_amount) + (parseFloat(prevDetail_4.unit_price) * parseFloat(prevDetail_4.quantity)),
                        grand_total: parseFloat(prevState.order.grand_total) + (parseFloat(prevDetail_4.unit_price) * parseFloat(prevDetail_4.quantity))
                    },
                    order_venue: {
                        ...prevState.order_venue,
                        venueproducttotal: parseFloat(prevState.order_venue.venueproducttotal) + (parseFloat(prevDetail_4.unit_price) * parseFloat(prevDetail_4.quantity))

                    },
                    order_details: changedQuantityDetails
                }
            } else {
                return {
                    ...prevState,
                    order: {
                        ...prevState.order,
                        cc_amount: parseFloat(prevState.order.cc_amount) - parseFloat(prevDetail_4.unit_price),
                        grand_total: parseFloat(prevState.order.grand_total) - parseFloat(prevDetail_4.unit_price)
                    },
                    order_venue: {
                        ...prevState.order_venue,
                        venueproducttotal: parseFloat(prevState.order_venue.venueproducttotal) - parseFloat(prevDetail_4.unit_price)

                    },
                    order_details: changedQuantityDetails
                }
            }
        })
    }

    const closeOrder = () => {
        setOrderDetails((prevState) => {
            return {
                ...prevState,
                order: {
                    ...prevState.order,
                    status: 'can'
                }
            }
        })
    }

    const addOrderItem = (payload) => {
        setOrderDetails((prevstate) => {
            return {
                ...prevstate,
                order: {
                    ...prevstate.order,
                    // cc_amount: parseFloat(prevstate.order.cc_amount) + (parseFloat(payload.unit_price) * parseInt(payload.quantity)),
                    // grand_total: parseFloat(prevstate.order.grand_total) + (parseFloat(payload.unit_price) * parseInt(payload.quantity))
                },
                order_venue: {
                    ...prevstate.order_venue,
                    venueproducttotal: parseFloat(prevstate.order_venue.venueproducttotal) + (parseFloat(payload.unit_price) * parseInt(payload.quantity))
                },
                order_details: [payload, ...prevstate.order_details]
            }
        })
    }

    const deleteOrderItem = (payload) => {
        setOrderDetails((prevState) => {
            let prevDetail_2 = prevState.order_details.find(e => e.product_id === payload.id);
            let newDetails = prevState.order_details.filter(e => e.product_id !== payload.id)
            return {
                ...prevState,
                order: {
                    ...prevState.order,
                    // cc_amount: parseFloat(prevState.order.cc_amount) - parseFloat(prevDetail_2.price),
                    // grand_total: parseFloat(prevState.order.grand_total) - parseFloat(prevDetail_2.price)
                },
                order_venue: {
                    ...prevState.order_venue,
                    venueproducttotal: parseFloat(prevState.order_venue.venueproducttotal) - (parseFloat(prevDetail_2.unit_price) * parseInt(prevDetail_2.quantity))
                },
                order_details: newDetails
            }
        })
    }

    const fetchSetting = () => {
        axios.get('/venue/setting')
            .then((res) => {
                setSettings(res.data?.data)
            })
    }

    useEffect(() => {
        fetchSetting()
    }, [])


    useEffect(() => {
        axios.get(`/venue-printer-setting/my`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((response) => {
                //setBannersInGallery(response.data.data.result)
                if (response.data.data.result?.identifier != undefined && response.data.data.result?.identifier != '') {
                    setIsPrinterSaved(false);
                } else {
                    setIsPrinterSaved(true);
                }
                // console.log('printerSettinf', response.data.data.result.identifier)
            })
    }, [])

    return (
        <div>

            {(isLoading || isStatusLoading) && (
                <PageLoader />
            )}
            <div>
                <div
                    className={styles.container}
                >
                    <div className={styles.container_header} >
                        <div>
                            <Button
                                sx={{
                                    color: 'black',
                                    '@media (max-width: 768px)': {
                                        fontSize: 20
                                    }
                                }}
                                startIcon={<ArrowBack />}
                                onClick={e => history('/VenueOrderListing')}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <div>
                                        Order #{id}
                                    </div>
                                    {!isMobileScreen && (
                                        <div
                                            style={{
                                                fontSize: 10
                                            }}
                                        >
                                            Order created on {orderDetails?.order?.orderdate && format(new Date(orderDetails?.order?.orderdate), 'MMM-dd-yyyy, HH:mm a')}
                                        </div>
                                    )}
                                </div>
                            </Button>
                        </div>

                        <div className={styles.set_to_packed_btn}>
                            {window?.ReactNativeWebView && isPrinterSaved && (
                                <Button
                                    variant="contained"
                                    className="tw-whitespace-nowrap"
                                    onClick={() => {
                                        window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: orderDetails, userinfo: user }));
                                    }}
                                >
                                    Send to kitchen
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                sx={{ width: (!window?.ReactNativeWebView || !isPrinterSaved) && isMobileScreen && '48%' }}
                                onClick={() => {
                                    generatePDF(targetRef, {
                                        filename: `Order_${id}_${settings?.venue?.Name}`,
                                        page: {
                                            margin: 10
                                        }
                                    })
                                }}
                            >
                                Print
                            </Button>

                            {orderDetails?.order_details?.length && ((orderDetails?.order_details[0]?.otype == 'delivery') && (orderDetails?.order?.status == 'o')) ? (
                                <div
                                    className="tw-cursor-pointer"
                                    onClick={(event) => {
                                        event?.stopPropagation()
                                    }}
                                >
                                    <DeliveryAcceptButton
                                        isDoorDashEnabled={isDoorDashEnabled}
                                        setRow={() => { }}
                                        row={{
                                            ...orderDetails?.order,
                                            venuedrivertip: orderDetails?.order_venue?.venuedrivertip
                                        }}
                                        user={user}
                                        isPrinterSaved={isPrinterSaved}
                                        handleStatusChange={handleStatusChange}
                                    />
                                </div>
                            ) : (
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: statusColor[orderDetails?.order?.status] || '#00C437',
                                        color: (orderDetails?.order?.status === 'o') ? 'black' : 'white',
                                        width: (!window?.ReactNativeWebView || !isPrinterSaved) && isMobileScreen && '48%'
                                    }}
                                    disabled={((orderDetails?.order?.status == 'can') || (orderDetails?.order?.status == 'clo') || (orderDetails?.order?.status == 'success'))}
                                    onClick={() => {
                                        handleStatusChange(orderDetails?.order)

                                        if (orderDetails?.order?.status === 'o' && window?.ReactNativeWebView && isPrinterSaved) {
                                            axios.get(`/driver/get-order-detail/${orderDetails?.order.Id}`)
                                                .then((res) => {

                                                    const resultsData = res.data?.data?.results;
                                                    resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                    window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                })
                                        }
                                    }}
                                >
                                    {{
                                        'o': 'Accept Order',
                                        'a': 'Set to Ready',
                                        'r': 'Close Order',
                                        'can': 'Cancelled',
                                        'clo': 'Closed'
                                    }[orderDetails?.order?.status]}
                                </Button>
                            )}
                        </div>
                    </div>

                    <div
                        ref={targetRef}
                        className={styles.list_order_detail_container}
                    >

                        <div className={styles.list_order_detail_section}>
                            <div
                                className={styles.customer_information}
                            >
                                <div
                                    onClick={() => {
                                        if (isMobileScreen) {
                                            setShowCustomerInfo(!showCustomerInfo)
                                        }
                                    }}
                                    style={{
                                        backgroundColor: '#F0F0F0',
                                        padding: 10,
                                        fontWeight: 600,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        transition: 'all .3s ease-in-out'
                                    }}
                                >
                                    Customer information

                                    {isMobileScreen && (
                                        showCustomerInfo ? <KeyboardArrowUp /> : <ChevronRight />
                                    )}
                                </div>
                                {isMobileScreen ? (
                                    <Collapse in={showCustomerInfo}>
                                        <CustomerInfoBody orderDetails={orderDetails} isMobileScreen={isMobileScreen} />
                                    </Collapse>
                                ) : (
                                    <CustomerInfoBody orderDetails={orderDetails} isMobileScreen={isMobileScreen} />
                                )}
                            </div>

                            <div
                                className={styles.customer_information}
                            >
                                <div
                                    onClick={() => {
                                        if (isMobileScreen) {
                                            setShowItems(!showItems)
                                        }
                                    }}
                                    style={{
                                        backgroundColor: '#F0F0F0',
                                        padding: 10,
                                        fontWeight: 600,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        transition: 'all .3s ease-in-out'
                                    }}
                                >
                                    Items ({orderDetails?.order_details?.length})

                                    {isMobileScreen && (
                                        showItems ? <KeyboardArrowUp /> : <ChevronRight />
                                    )}
                                </div>
                                <Collapse in={showItems}> </Collapse>
                                {isMobileScreen ? (
                                    <Collapse in={showItems}>
                                        <ProductItems {...{ isMobileScreen, orderDetails, isEditMode, saveable, setSaveable, isSaveLoading, setAddForm, addForm, handleSave, updateOrderItem, updateOrderItemFormat, updateOrderItemPrice, updateOrderItemSubFees, updateOrderItemQuantity, addOrderItem, deleteOrderItem, setIsEditMode, setOrderDetails }} />
                                    </Collapse>
                                ) : (
                                    <ProductItems {...{ isMobileScreen, orderDetails, isEditMode, saveable, setSaveable, isSaveLoading, setAddForm, addForm, handleSave, updateOrderItem, updateOrderItemFormat, updateOrderItemPrice, updateOrderItemSubFees, updateOrderItemQuantity, addOrderItem, deleteOrderItem, setIsEditMode, setOrderDetails }} />
                                )}

                            </div>
                            {isMobileScreen ? (
                                <div className={styles.set_to_packed_btn}>
                                    {orderDetails?.order_details?.length && ((orderDetails?.order_details[0]?.otype == 'delivery') && (orderDetails?.order?.status == 'o')) ? (
                                        <div
                                            className="tw-cursor-pointer"
                                            onClick={(event) => {
                                                event?.stopPropagation()
                                            }}
                                        >
                                            <DeliveryAcceptButton
                                                isDoorDashEnabled={isDoorDashEnabled}
                                                setRow={() => { }}
                                                row={{
                                                    ...orderDetails?.order,
                                                    venuedrivertip: orderDetails?.order_venue?.venuedrivertip
                                                }}
                                                user={user}
                                                isPrinterSaved={isPrinterSaved}
                                                handleStatusChange={handleStatusChange}
                                            />
                                        </div>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            style={{
                                                backgroundColor: statusColor[orderDetails?.order?.status] || '#00C437',
                                                color: (orderDetails?.order?.status === 'o') ? 'black' : 'white'
                                            }}
                                            disabled={((orderDetails?.order?.status == 'can') || (orderDetails?.order?.status == 'clo') || (orderDetails?.order?.status == 'success'))}
                                            onClick={() => {
                                                handleStatusChange(orderDetails?.order)
                                                if (orderDetails?.order?.status === 'o' && window?.ReactNativeWebView && isPrinterSaved) {
                                                    axios.get(`/driver/get-order-detail/${orderDetails?.order.Id}`)
                                                        .then((res) => {
                                                            const resultsData = res.data?.data?.results;
                                                            resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                            window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                        })
                                                }
                                            }}
                                        >
                                            {{
                                                'o': 'Accept Order',
                                                'a': 'Set to Ready',
                                                'r': 'Close Order',
                                                'can': 'Cancelled',
                                                'clo': 'Closed'
                                            }[orderDetails?.order?.status]}
                                        </Button>
                                    )}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: 20
                                    }}
                                >
                                    <GenericLoadingButton
                                        style={{
                                            color: '#E40000',
                                            borderColor: '#E40000',
                                            backgroundColor: 'white',
                                            display: (orderDetails?.order?.status === "o" || orderDetails?.order?.status === "a" || orderDetails?.order?.status === "r") ? "inline-flex" : "none",
                                        }}
                                        variant="outlined"
                                        loading={isCancelRefundLoading}
                                        loadingPosition="end"
                                        onClick={() => {
                                            if (orderDetails?.order?.status === "a" || orderDetails?.order?.status === "r") {
                                                setShowRefundPopup(true)
                                            } else {
                                                handleClose()
                                            }
                                        }}
                                    >
                                        {(orderDetails?.order?.status === "a" || orderDetails?.order?.status === "r") ? "Refund Order" : "Cancel Order & Refund Charges"}
                                    </GenericLoadingButton>
                                </div>
                            )}
                        </div>

                        <div className={styles.list_order_detail_side_section}>
                            <Details
                                doordashFee={orderDetails?.order_venue?.doordash_estimate_amount}
                                isEditMode={isEditMode}
                                setSaveable={setSaveable}
                                orderDetails={orderDetails}
                                fetchOrderDetails={fetchOrderDetails}
                                isDoorDashEnabled={isDoorDashEnabled}
                            />
                            <div className={styles.detail_container}>
                                <Detail2
                                    isTracking={true}
                                    tracking={orderDetails.order_tracking}
                                    _1value={{
                                        val:
                                            orderDetails.order_tracking &&
                                                orderDetails.order_tracking[0]
                                                ? orderDetails.order_tracking[0]
                                                    .created_at
                                                : "",
                                        style: {},
                                    }}
                                    _1header={{ val: "Order Created", style: {} }}
                                    _2value={{
                                        val:
                                            orderDetails.order_tracking &&
                                                orderDetails.order_tracking[1]
                                                ? (<><Typography style={{ fontWeight: '700', fontSize: '.85rem' }} component="span">{orderDetails.order_tracking[1]?.created_at}</Typography> <Typography style={{ fontWeight: '400', fontSize: '.85rem' }} component="span">{orderDetails?.order_tracking[1]?.event_details}</Typography></>)
                                                : "",
                                        style: {},
                                    }}
                                    _2header={{ val: "Order Accepted", style: {} }}
                                    _3value={{
                                        val:
                                            orderDetails.order_tracking &&
                                                orderDetails.order_tracking[2]
                                                ? (<><Typography style={{ fontWeight: '700', fontSize: '.85rem' }} component="span">{orderDetails.order_tracking[2]?.created_at}</Typography> <Typography style={{ fontWeight: '400', fontSize: '.85rem' }} component="span">{orderDetails?.order_tracking[2]?.event_details}</Typography></>)
                                                : "",
                                        style: {},
                                    }}
                                    _3header={{ val: "Order Ready", style: {} }}
                                    _4header={{ val: "Order Closed", style: {} }}
                                    _4value={{
                                        val:
                                            orderDetails.order_tracking &&
                                                orderDetails.order_tracking[3]
                                                ? (<><Typography style={{ fontWeight: '700', fontSize: '.85rem' }} component="span">{orderDetails.order_tracking[3]?.created_at}</Typography> <Typography style={{ fontWeight: '400', fontSize: '.85rem' }} component="span">{orderDetails?.order_tracking[3]?.event_details}</Typography></>)
                                                : "",
                                        style: {},
                                    }}
                                    _5header={{ val: "Order Closed", style: {} }}
                                    _5value={{
                                        val:
                                            orderDetails.order_tracking &&
                                                orderDetails.order_tracking[3]
                                                ? (<><Typography style={{ fontWeight: '700', fontSize: '.85rem' }} component="span">{orderDetails.order_tracking[3]?.created_at}</Typography> <Typography style={{ fontWeight: '400', fontSize: '.85rem' }} component="span">{orderDetails?.order_tracking[3]?.event_details}</Typography></>)
                                                : "",
                                        style: {},
                                    }}
                                    _6header={{ val: "Order Cancelled", style: {} }}
                                    _6value={{
                                        val:
                                            orderDetails.order_tracking &&
                                                orderDetails.order_tracking[3]
                                                ? (<><Typography style={{ fontWeight: '700', fontSize: '.85rem' }} component="span">{orderDetails.order_tracking[3]?.created_at}</Typography> <Typography style={{ fontWeight: '400', fontSize: '.85rem' }} component="span">{orderDetails?.order_tracking[3]?.event_details}</Typography></>)
                                                : "",
                                        style: {},
                                    }}
                                    title="Order Tracking"
                                />
                            </div>
                            <div className={styles.payment_container}>
                                <PaymentInfo
                                    orderDetails={orderDetails}
                                    productTotal={orderDetails?.order_venue?.venueproducttotal}
                                    isEditMode={isEditMode}
                                    setSaveable={setSaveable}
                                    subFees={[
                                        {
                                            header: "Delivery Fee",
                                            value: orderDetails?.order_venue?.venuedeliverfee,
                                            key: 'venuedeliverfee'
                                        },
                                        {
                                            header: "Pickup Fee",
                                            value: orderDetails?.order_venue?.pickupfees,
                                            key: 'pickupfees'
                                        },
                                        {
                                            header: "Service Charge",
                                            value: Boolean(parseInt(orderDetails?.order_venue?.service_charge_from_customer)) ? orderDetails?.order_venue?.esplanda_fee : 0,
                                            key: 'esplanda_fee'
                                        },
                                        // {
                                        //     header: "Stripe Fee",
                                        //     value: orderDetails?.order_venue?.strip_fee
                                        // },
                                        {
                                            header: "Tip",
                                            value: orderDetails?.order_venue?.venuedrivertip,
                                            key: 'venuedrivertip'
                                        },
                                        {
                                            header: "Shipping Fee",
                                            value: orderDetails?.order_venue?.venueshippingfee,
                                            key: 'venueshippingfee'
                                        },
                                        {
                                            header: "Tax",
                                            value: orderDetails?.order_venue?.tax_amount,
                                            key: 'tax_amount'
                                        },
                                        {
                                            header: "Coupon Amount",
                                            value: orderDetails?.order_venue?.coupon_amount,
                                            key: 'coupon_amount',
                                            isNegative: true
                                        },
                                        {
                                            header: "Refund Amount",
                                            value: orderDetails?.order?.refund_amount,
                                            key: 'refund_amount',
                                            isNegative: true
                                        }
                                    ]}
                                    doordashFee={orderDetails?.order_venue?.doordash_estimate_amount}
                                    cc={orderDetails?.order?.cc_amount}
                                    gc={orderDetails?.order?.gift_card_amount}
                                    lastdigit={orderDetails?.payment_transaction?.str_cust_card_no}
                                    total={orderDetails?.order?.grand_total}
                                    isApplePay={(orderDetails?.payment_transaction?.last_payment_method == 1)}
                                    isGooglePay={(orderDetails?.payment_transaction?.last_payment_method == 2)}
                                    flex
                                    title="Payment Information"
                                />
                            </div>
                            {isMobileScreen && (
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: 20,
                                        width: '100%'
                                    }}
                                >
                                    <GenericLoadingButton
                                        style={{
                                            width: '100%',
                                            color: '#E40000',
                                            borderColor: '#E40000',
                                            backgroundColor: 'white',
                                            display: (orderDetails?.order?.status === "o" || orderDetails?.order?.status === "a" || orderDetails?.order?.status === "r") ? "inline-flex" : "none",
                                        }}
                                        variant="outlined"
                                        loading={isCancelRefundLoading}
                                        loadingPosition="end"
                                        onClick={() => {
                                            if (orderDetails?.order?.status === "a" || orderDetails?.order?.status === "r") {
                                                setShowRefundPopup(true)
                                            } else {
                                                handleClose()
                                            }
                                        }}
                                    >
                                        {(orderDetails?.order?.status === "a" || orderDetails?.order?.status === "r") ? "Refund Order" : "Cancel Order & Refund Charges"}
                                    </GenericLoadingButton>
                                </div>
                            )}
                        </div>
                        {/* <div style={{ marginTop: 40, marginBottom: 20, display: saveable ? "flex" : 'none', justifyContent: 'flex-end' }}>
                            <Button
                                className="orderdetails-grid-list-container-btn"
                                onClick={e => handleSave()}
                                style={{
                                    border: "1px solid rgb(218,218,218)",
                                    padding: "8px 25px",
                                    borderRadius: "0",
                                    color: 'white',
                                    backgroundColor: '#EA2B19'
                                }}
                            >
                                Save Order
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>
            <Dialog
                open={showRefundPopup}
                onClose={() => {
                    setShowRefundPopup(false)
                }}
            >
                <RefundOrderDialog
                    cc_amount={orderDetails.order?.cc_amount}
                    gift_card_amount={orderDetails.order?.gift_card_amount > 0 ? orderDetails.order?.gift_card_amount : null}
                    id={id}
                    onClose={() => {
                        fetchOrderDetails()
                        setShowRefundPopup(false)
                    }}
                />
            </Dialog>

            {/* <Dialog
                open={showAssignDriver}
                onClose={() => {
                    setShowAssignDriver(false)
                }}
            >
                <AssignDriverDialog
                    handleStatusChange={handleStatusChange}
                    row={orderDetails?.order}
                    deliveryPartners={deliveryPartners}
                    shippingPartners={shippingPartners}
                    onClose={() => {
                        setShowAssignDriver(false)
                    }}
                />
            </Dialog> */}
        </div>
    );
};

export default OrderDetails;



const MobileOrderItem = ({ index, orderDetail, isEditMode, setSaveable, updateOrderItemPrice, updateOrderItemQuantity, orderDetails, deleteOrderItem, setOrderDetails }) => {
    console.log('🚀OUTPUT --> orderDetail:', orderDetail);
    const confirm = useConfirm()
    const [editableQuantity, setEditableQuantity] = useState(orderDetail.quantity);
    const [isCustomize, setIsCustomize] = useState(false)
    const [customizeObj, setCustomizeObj] = useState({})
    const [quantityById, setQuantityById] = useState({})
    const initData = useRef({
        name: `${orderDetail.name}`,
        format: `${orderDetail.productformat}`,
        quantity: `${orderDetail.quantity}`
    })
    const handlePriceChange = (event) => {
        updateOrderItemPrice({
            product_id: orderDetail.product_id,
            price: parseFloat(event.target.value) || 0
        })

        if (initData.current.format !== event.target.value) {
            setSaveable(true);
        }
    };

    const handleQuantityChange = (value, action) => {
        try {
            updateOrderItemQuantity({
                product_id: orderDetail.product_id,
                quantity: value,
                action
            })

            setEditableQuantity(value);
            if (initData.current.quantity !== value) {
                setSaveable(true);
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    function getProductTitle(full) {
        let name = orderDetail.name;
        let brewery_name = orderDetail.brewery_name;
        let product_category = orderDetail.product_category;
        let title = `${name} ${Boolean(brewery_name) ? ` | ${brewery_name}` : ''}${Boolean(product_category) ? ` | ${product_category}` : ''}`;
        if (!full && title.length > 70) {
            return title.slice(0, 70) + '...'
        } else {
            return title;
        }
    }
    const handleDelete = () => {
        setSaveable(true)

        confirm({
            title: 'Do you wish to confirm to delete this product from order',
            titleProps: {
                style: {
                    fontSize: '13px',
                    fontWeight: '700'
                }
            }
        }).then(() => {
            deleteOrderItem({ id: orderDetail.product_id })
        }).catch(() => { });
    };

    return (
        <div className={styles.mobile_order_item_container}>
            <div title={getProductTitle('full')}>{getProductTitle()}</div>
            <div className={styles.mobile_order_item}>
                <div className={styles.mobile_order_img}>
                    <div
                        style={{
                            minWidth: "70px",
                            maxWidth: "100px",
                            // height: "74px",
                        }}
                    >
                        <img
                            style={{
                                width: "90%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                            src={orderDetail.product_image}
                            alt={orderDetail.ProductName}
                            onError={({ currentTarget, target }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = getImage('no_image.png-1694435803.png')
                            }}
                        />
                    </div>
                </div>
                <div className={styles.mobile_order_body}>
                    {orderDetail.SKU &&
                        <Typography fontSize={12} variant="h6">
                            SKU: <span style={{ color: '#B12704' }}>{orderDetail.SKU}</span>
                        </Typography>}
                    {orderDetail?.productformat && orderDetail?.productformat != 'Esplanda' &&
                        <Typography fontSize={12} variant="h6">
                            Format: <span style={{ color: '#B12704' }}>
                                {(orderDetail?.productformat != 'Esplanda') ? orderDetail?.productformat : '-'}
                            </span>
                        </Typography>}
                    <Typography fontSize={12} variant="h6" display={'flex'} gap={'10px'} >
                        Quantity:
                        {
                            isEditMode ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        boxShadow: '0px 1px 4px #0000001a'
                                    }}
                                >
                                    <div
                                        onClick={e => {
                                            if ((editableQuantity - 1) > 0) {
                                                handleQuantityChange(editableQuantity - 1, 'reduce')
                                            }
                                        }}
                                        style={{
                                            backgroundColor: "rgb(223,219,220)",
                                            color: "#767273",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Remove
                                            color="inherit"
                                            style={{ transform: "scale(.75)" }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            margin: '5px 14px'
                                        }}
                                        className={styles.table_text}
                                    >
                                        {orderDetail.quantity}
                                    </div>
                                    <div
                                        onClick={e => handleQuantityChange(editableQuantity + 1, 'increment')}
                                        style={{
                                            backgroundColor: "rgb(223,219,220)",
                                            color: "#767273",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Add
                                            color="inherit"
                                            style={{ transform: "scale(.75)" }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <span style={{ color: '#B12704' }}>{orderDetail.quantity}</span>
                            )
                        }

                    </Typography>
                    <Typography fontSize={12} variant="h6" display={'flex'} gap={'10px'} >
                        Price: {isEditMode ? (
                            <span style={{ width: 80 }}>
                                <GenericTextField
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    onChange={handlePriceChange}
                                    value={orderDetail.unit_price}
                                />
                            </span>
                        ) : (
                            <span style={{ color: '#B12704' }}>${Number(orderDetail.unit_price).toFixed(2)}</span>
                        )}

                    </Typography>
                    <Typography fontSize={12} variant="h6">
                        Total: <span style={{ color: '#B12704' }}>${(orderDetail.unit_price * orderDetail.quantity).toFixed(2)}</span>


                    </Typography>
                </div>
            </div>
            {Boolean(orderDetail?.modifiers_list?.length) && (
                <ProductModifiers orderDetail={orderDetail} />
            )}

            {isEditMode && (
                <Button
                    startIcon={<Add />}
                    style={{
                        textTransform: 'none'
                    }}
                    variant="text"
                    onClick={() => {
                        setIsCustomize(true)
                        // setCustomizeObj({
                        //     ...data,
                        //     modifiers: convertToId(data?.selected_modifiers)
                        // })
                        setCustomizeObj(orderDetail)
                        setQuantityById({ [orderDetail.order_itemId]: orderDetail.quantity })
                    }}
                >
                    Edit Modifier
                </Button>
            )}
            {orderDetail.note && (
                <div
                    style={{
                        display: 'flex',
                        color: 'red',
                        gap: 4
                    }}
                >
                    <div>
                        Note:
                    </div>

                    <div>
                        {orderDetail.note}
                    </div>
                </div>
            )}
            {orderDetails.order?.status == 'o' && (
                <div style={{ display: "flex", gap: 4, justifyContent: 'flex-end' }}>
                    <Button
                        variant="text"
                        style={{
                            color: '#E40000'
                        }}
                        onClick={handleDelete}
                    >
                        Remove
                    </Button>
                </div>
            )}

            <Dialog
                open={isCustomize}
                onClose={() => {
                    setIsCustomize(false)
                    setCustomizeObj({})
                }}
            >
                <ModifierDialog
                    customizeObj={customizeObj}
                    isEdit={true}
                    quantityById={quantityById}
                    setQuantityById={setQuantityById}
                    // setSelectedFormat={setSelectedFormat}
                    // selectedFormat={selectedFormat}
                    validateCartValue={(data) => {

                        setOrderDetails((prevState) => {
                            let newState = { ...prevState }
                            if (newState?.order_details) {
                                newState.order_details[index] = data
                            }
                            return newState
                        })
                    }}
                    onClose={() => {
                        setIsCustomize(false)
                        setCustomizeObj({})
                    }}
                />
            </Dialog>
        </div>

    )
}

const ProductModifiers = ({ orderDetail }) => {

    return (
        <div>
            {Boolean(orderDetail?.selected_modifiers?.length) && (
                <>
                    <div style={{ fontStyle: 'italic' }}>
                        Customization:
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 600,
                            marginLeft: 8
                        }}
                    >
                        <b className={styles.modifier_title}></b>
                        <div className={styles.modifier_price}>Each</div>
                        <div className={styles.modifier_price}>Total</div>
                    </div>
                </>
            )}
            {orderDetail?.modifiers_list?.map((mdata) => {
                const opt = orderDetail.modifiers[mdata?.modifier_id]

                return (
                    <div
                        style={{
                            marginLeft: 8
                        }}
                    >
                        {Boolean(opt) && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <b className={styles.modifier_title}>
                                    {mdata?.modifier_title}
                                </b>

                                {Boolean(parseFloat(mdata.modifier_price)) && (
                                    <div className={styles.modifier_price}>
                                        ${parseFloat(mdata?.modifier_price)?.toFixed(2)}
                                    </div>
                                )}

                                {Boolean(parseFloat(mdata.modifier_price)) && (
                                    <div className={styles.modifier_price}>
                                        ${parseFloat(mdata?.modifier_price * orderDetail.quantity)?.toFixed(2)}
                                    </div>
                                )}
                            </div>
                        )}

                        {mdata?.modifier_option?.map((mdata1) => {
                            if (!opt?.includes(mdata1?.id)) {
                                return
                            }
                            return (
                                <div
                                    style={{
                                        marginLeft: 8,
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                    className={styles.modifier_data}
                                >
                                    <div className={styles.modifier_title}>
                                        {mdata1.title}
                                    </div>

                                    {Boolean(parseFloat(mdata1.price)) && (
                                        <div className={styles.modifier_price}>
                                            ${parseFloat(mdata1.price || 0).toFixed(2)}
                                        </div>
                                    )}
                                    {Boolean(parseFloat(mdata1.price)) && (
                                        <div className={styles.modifier_price}>
                                            ${parseFloat((mdata1.price || 0) * orderDetail.quantity).toFixed(2)}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}

const CustomerInfoBody = ({ orderDetails, isMobileScreen }) => {
    const [showMoreAddress, setShowMoreAddress] = useState(false)
    const [showMoreEmail, setShowMoreEmail] = useState(false)

    const address = orderDetails?.order?.orderaddress
    // const address = orderDetails?.order?.orderaddress || orderDetails?.users?.Address1;
    const phone = orderDetails?.order?.order_phone_number || orderDetails?.users?.phonenumber;
    const email = orderDetails?.users?.email;



    return (
        <div className={styles.customer_details}>
            <div className={styles.customer_item}>
                <div className={styles.customer_item_title}>
                    Name
                </div>
                <div className={styles.customer_item_value}>
                    {orderDetails?.users?.name}
                </div>
            </div>
            <div className={styles.customer_item}>
                <div className={styles.customer_item_title}>
                    {isMobileScreen ? 'Phone' : 'Phone number'}
                </div>
                <a className={styles.customer_item_value} href={`tel:${phone}`} >
                    {phone}
                </a>
            </div>

            <div className={styles.customer_item}>
                <div className={styles.customer_item_title}>
                    Email
                </div>
                <a className={styles.customer_item_value} href={`mailto:${email}`} >
                    {email?.length > 26 ? (
                        <>
                            {showMoreEmail ? email : email.slice(0, 26) + '...'}
                            <Button
                                variant="text"
                                color='primary'
                                sx={{ p: 0, pl: 0.6, minWidth: 'auto' }}
                                onClick={() => setShowMoreEmail(!showMoreEmail)}
                            >
                                {showMoreEmail ? 'hide' : 'view'}
                            </Button>
                        </>
                    ) : (
                        email
                    )}
                </a>
            </div>

            {Boolean(address?.length) && (
                <div className={styles.customer_item}>
                    {Boolean(orderDetails?.order_details?.length) && (
                        <div className={styles.customer_item_title}>
                            {(orderDetails?.order_details[0]?.otype == 'pickup') ? "Pickup From" : 'Address'}
                        </div>
                    )}

                    <div className={styles.customer_item_value}>
                        {address?.length > 40 ? (
                            <>
                                {showMoreAddress ? address : address.slice(0, 40) + '...'}
                                <Button
                                    variant="text"
                                    color='primary'
                                    sx={{ p: 0, pl: 0.6, minWidth: 'auto' }}
                                    onClick={() => setShowMoreAddress(!showMoreAddress)}
                                >
                                    {showMoreAddress ? 'hide' : 'view'}
                                </Button>
                            </>
                        ) : (
                            address
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

const ProductItems = ({ isMobileScreen, orderDetails, isEditMode, saveable, setSaveable, isSaveLoading, setAddForm, addForm, handleSave, updateOrderItem, updateOrderItemFormat, updateOrderItemPrice, updateOrderItemSubFees, updateOrderItemQuantity, addOrderItem, deleteOrderItem, setIsEditMode, setOrderDetails }) => {
    return (
        <>
            {isMobileScreen && orderDetails?.order_details && orderDetails?.order_details?.length > 0 ? (
                <div className={styles.mobile_order_container}>
                    {orderDetails?.order_details?.map((orderDetail, index) => {
                        return (
                            <MobileOrderItem key={index} {...{ index, orderDetail, isEditMode, setSaveable, updateOrderItemPrice, updateOrderItemQuantity, orderDetails, deleteOrderItem, setOrderDetails }} />
                        )
                    })}
                    {addForm ? (
                        <AddForm
                            setSaveable={setSaveable}
                            open={addForm}
                            setOpen={setAddForm}
                            updateOrderItem={updateOrderItem}
                            updateOrderItemFormat={updateOrderItemFormat}
                            updateOrderItemPrice={updateOrderItemPrice}
                            updateOrderItemSubFees={updateOrderItemSubFees}
                            updateOrderItemQuantity={updateOrderItemQuantity}
                            addOrderItem={addOrderItem}
                            deleteOrderItem={deleteOrderItem}
                        />
                    ) : null}
                </div>
            ) : (
                <List
                    orderDetails={orderDetails}
                    setOrderDetails={setOrderDetails}
                    isEditMode={isEditMode}
                    setSaveable={setSaveable}
                    setOpen={setAddForm}
                    open={addForm}
                    handleSave={handleSave}
                    updateOrderItem={updateOrderItem}
                    updateOrderItemFormat={updateOrderItemFormat}
                    updateOrderItemPrice={updateOrderItemPrice}
                    updateOrderItemSubFees={updateOrderItemSubFees}
                    updateOrderItemQuantity={updateOrderItemQuantity}
                    addOrderItem={addOrderItem}
                    deleteOrderItem={deleteOrderItem}
                />
            )}
            <div
                className={styles.add_cancel_container}
            >
                {orderDetails.order?.status == 'o' && !isEditMode && (
                    <Button
                        variant="text"
                        onClick={() => {
                            setIsEditMode(true)
                        }}
                    >
                        Edit
                    </Button>
                )}

                {isEditMode && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={e => setAddForm(true)}
                            size="small"
                            startIcon={<Add />}
                        >
                            Add Product
                        </Button>
                    </div>
                )}
            </div>
            <div
                className={styles.save_cancel_container}
                style={{
                    // display: saveable ? 'flex' : 'none',
                    display: isEditMode ? 'flex' : 'none',
                    justifyContent: 'flex-end',
                    paddingRight: 20,
                    paddingTop: 10,
                    borderTop: '1px solid #d3d3d3',
                    gap: 10
                }}
            >
                <Button
                    variant="text"
                    style={{
                        color: 'black'
                    }}
                    onClick={() => {
                        window.location.reload()
                    }}
                >
                    Cancel
                </Button>

                <GenericLoadingButton
                    variant="contained"
                    onClick={handleSave}
                    loading={isSaveLoading}
                    loadingPosition="end"
                >
                    Save
                </GenericLoadingButton>
            </div>
        </>
    )
}