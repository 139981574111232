import React from 'react'
import styles from './AppLinks.module.scss'
import GenericTextFieldWithLabel from '../../../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'

const AppLinks = ({formik}) => {
    return (
        <div
            className={styles.box_container}
        >
            <div className={styles.social}>Social Media</div>

            <div className={styles.social_list}>
                <GenericTextFieldWithLabel
                    containerStyle={{
                        width: '100%'
                    }}
                    label="IOS app link"
                    id="ios_app_link"
                    name="ios_app_link"
                    value={formik.values.ios_app_link}
                    onChange={formik.handleChange}
                    fullWidth={true}
                />
            </div>

            <div className={styles.social_list}>
                <GenericTextFieldWithLabel
                    containerStyle={{
                        width: '100%'
                    }}
                    label="Android app link"
                    id="android_app_link"
                    name="android_app_link"
                    value={formik.values.android_app_link}
                    onChange={formik.handleChange}
                    fullWidth={true}
                />
            </div>
        </div>
    )
}

export default AppLinks