import React, { useState } from "react";
import Detail from "./Parts/Detail";
import PaymentInfo from './Parts/PaymentInfo';
import { useSelector } from 'react-redux';
import day from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from './Details.module.scss'
import { Button, Dialog, Drawer, IconButton, useMediaQuery } from "@mui/material";
import { getImage2 } from "../../../../../utils/helpers";
import { Close } from "@mui/icons-material";
import axios, { nodeAxios } from '../../../../../utils/axiosConfig'
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";

day.extend(localizedFormat)

const statusColor = {
    'o': '#FED813',
    'a': '#0060EF',
    'r': '#00C437',
    'can': '#E90000',
    'clo': '#00C437'
}


const Details = (props) => {

    const confirm = useConfirm()
    const isMobileScreen = useMediaQuery('(max-width:1000px)');
    const { order, order_details, order_venue } = props.orderDetails

    const [showDoorDashTracking, setShowDoorDashTracking] = useState(false)
    const [trackingUrl, setTrackingUrl] = useState('')

    return (
        <div
            className={styles.container}
        >
            {order_details?.length && (
                <Detail
                    title="Order Details"
                    doordashFee={props.doordashFee}
                    fetchOrderDetails={props.fetchOrderDetails}
                    externalDeliveryId={order.external_delivery_id}
                    order={order}
                    orderDetails={order_details}
                    orderVenue={order_venue}
                    orderStatus={order?.status}
                    _1value={{ val: order?.Id, style: {} }}
                    _1header={{ val: "Order Id", style: {} }}
                    _2value={{
                        val: (
                            <div
                                className={styles.order_status}
                                style={{
                                    textAlign: 'center',
                                    backgroundColor: statusColor[order?.status] || '#00C437',
                                    color: (order?.status === 'o') ? 'black' : 'white'
                                }}
                            >
                                {{
                                    'o': 'Open',
                                    'a': 'Accepted',
                                    'r': 'Ready',
                                    'can': 'Cancelled',
                                    'clo': 'Closed'
                                }[order?.status]}
                            </div>
                        ), style: {}
                    }}
                    _2header={{ val: "Order Status", style: {} }}
                    _3value={{ val: day(order?.orderdate).format('LLL'), style: {} }}
                    _3header={{ val: "Order Date", style: {} }}
                    _6value={{ val: ((order_details[0]?.otype === "delivery") || (order_details[0]?.otype === "pickup")) && order?.delivery_pickup_slot ? (order?.delivery_pickup_slot?.includes('undefined') ? order?.delivery_pickup_slot?.replace(', undefined', '') : order?.delivery_pickup_slot) : '', style: {} }}
                    _6header={{ val: ((order_details[0]?.otype === "delivery") || (order_details[0]?.otype === "pickup")) ? ((order_details[0]?.otype === "delivery") ? "Delivery Time" : "Pickup Time") : '', style: {} }}
                    _5value={{ val: order?.customer_comment, style: {} }}
                    _5header={{ val: "Customer Comment", style: {} }}
                    _4value={{
                        val: (
                            <div
                                className="tw-flex tw-gap-2"
                            >
                                <div>
                                    {order_details[0]?.otype?.charAt(0)?.toUpperCase() + order_details[0]?.otype?.slice(1)?.toLowerCase()}
                                </div>

                                {order_venue?.doordash_track_url && (
                                    <div
                                        className="tw-text-[#0076E3] tw-underline tw-cursor-pointer"
                                        onClick={(event) => {
                                            setShowDoorDashTracking(true)
                                            setTrackingUrl(order_venue?.doordash_track_url)
                                        }}
                                    >
                                        Check doordash status
                                    </div>
                                )}
                            </div>
                        ), style: {}
                    }}
                    _4header={{ val: "Fulfillment type", style: {} }}
                    _7value={{ val: order?.gift_message, style: {} }}
                    _7header={{ val: "Gift Message", style: {} }}
                    flex
                    otype={order_details[0]?.otype}
                />
            )}

            {isMobileScreen ? (
                <Drawer
                    PaperProps={{
                        style: {
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15
                        }
                    }}
                    anchor="bottom"
                    open={showDoorDashTracking}
                    onClose={() => {
                        setShowDoorDashTracking(false)
                        setTrackingUrl('')
                    }}
                >
                    <div
                        className="tw-pt-4 tw-pr-4 tw-pl-4"
                    >
                        <div
                            className="tw-flex tw-justify-between"
                        >
                            <img
                                className="tw-h-6"
                                src={getImage2('doordash_red.png-1709566784.png')}
                            />

                            <IconButton
                                onClick={() => {
                                    setShowDoorDashTracking(false)
                                    setTrackingUrl('')
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>

                        {((order?.status == 'o') || (order?.status == 'a')) && (
                            <div
                                className="tw-mt-2"
                            >
                                <Button
                                    style={{
                                        color: '#E40000'
                                    }}
                                    variant="text"
                                    onClick={() => {

                                        confirm({
                                            title: 'Are you sure you want to cancel DoorDash delivery?',
                                            description: 'Once cancelled you cannot call DoorDash for this order again.'
                                        })
                                            .then(() => {

                                                nodeAxios.post(`/doordash/cancel-delivery`, {
                                                    orderId: order.Id,
                                                    deliveryId: order.external_delivery_id
                                                })
                                                    .then((res) => {
                                                        toast.success('Doordash delivery cancelled')
                                                    })
                                                    .finally(() => {

                                                    })
                                            })
                                            .catch(() => {

                                            })
                                            .finally(() => {
                                            })
                                    }}
                                >
                                    Cancel DoorDash Delivery
                                </Button>
                            </div>
                        )}
                    </div>

                    <iframe
                        className="tw-h-[75vh]"
                        src={trackingUrl}
                    />
                </Drawer>
            ) : (
                <Dialog
                    open={showDoorDashTracking}
                    onClose={() => {
                        setShowDoorDashTracking(false)
                        setTrackingUrl('')
                    }}
                    maxWidth="xl"
                    PaperProps={{
                        style: {
                            width: '100%'
                        }
                    }}
                >
                    <div
                        className="tw-pt-4 tw-pr-4 tw-pl-4"
                    >
                        <div
                            className="tw-flex tw-justify-between"
                        >
                            <img
                                className="tw-h-6"
                                src={getImage2('doordash_red.png-1709566784.png')}
                            />

                            <IconButton
                                onClick={() => {
                                    setShowDoorDashTracking(false)
                                    setTrackingUrl('')
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>

                        {((order?.status == 'o') || (order?.status == 'a')) && (
                            <div
                                className="tw-mt-2"
                            >
                                <Button
                                    style={{
                                        color: '#E40000'
                                    }}
                                    variant="text"
                                    onClick={() => {

                                        confirm({
                                            title: 'Are you sure you want to cancel DoorDash delivery?',
                                            description: 'Once cancelled you cannot call DoorDash for this order again.'
                                        })
                                            .then(() => {

                                                nodeAxios.post(`/doordash/cancel-delivery`, {
                                                    orderId: order.Id,
                                                    deliveryId: order.external_delivery_id
                                                })
                                                    .then((res) => {
                                                        toast.success('Doordash delivery cancelled')
                                                    })
                                                    .finally(() => {

                                                    })
                                            })
                                            .catch(() => {

                                            })
                                            .finally(() => {
                                            })
                                    }}
                                >
                                    Cancel DoorDash Delivery
                                </Button>
                            </div>
                        )}
                    </div>

                    <iframe
                        className="tw-h-screen"
                        src={trackingUrl}
                    />
                </Dialog>
            )}
        </div>
    );
};

export default Details;