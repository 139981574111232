import { ArrowRightAlt } from '@mui/icons-material'
import React from 'react'
import styles from './DragHereChip.module.scss'

const DragHereChip = () => {
    return (
        <div
            className={styles.drag_here_container}
        >
            <div
            className={styles.icon}
            >
                <ArrowRightAlt style={{fontSize: '58px'}} />
            </div>
            <div
            className={styles.drag_here_text}
            >
                Drag Here
            </div>
        </div>
    )
}

export default DragHereChip