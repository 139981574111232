import {
    POST_UPDATE_PRODUCT_CUSTOMIZATION_REQUEST,
    POST_UPDATE_PRODUCT_CUSTOMIZATION_SUCCESS,
    POST_UPDATE_PRODUCT_CUSTOMIZATION_FAILURE
} from '../types/productMasterListTypes';

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    productList: {},
    productListError: ""
};

export const productList = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PRODUCT_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_PRODUCT_LIST_LOADED":
            return { ...initialState, isLoading: false, productList: action.payload };
        case "SET_PRODUCT_LIST_ERROR":
            return { ...initialState, productListError: action.payload };
        default:
            return state;
    }
};

