import clsx from 'clsx'
import React from 'react'

const Button = ({ type, onClick, className, children }) => {
    return (
        <button
            type={type || "button"}
            className={clsx("tw-outline-none tw-px-5 tw-py-2.5 tw-text-center tw-items-center tw-text-sm", className)}
            onClick={onClick}
        >
            {children}
        </button>

    )
}

export default Button