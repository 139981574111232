import React from 'react'
import styles from './CloudBasedIntegration.module.scss'
import cloudbaselogo1 from "../../../assets/cloud-base-logo-1.jpg";
import cloudbaselogo2 from "../../../assets/cloud-base-logo-2.jpg";
import cloudbaselogo3 from "../../../assets/cloud-base-logo-3.jpg";
import cloudbaselogo4 from "../../../assets/cloud-base-logo-4.jpg";
import cloudbaselogo5 from "../../../assets/cloud-base-logo-5.jpg";
import cloudbaselogo6 from "../../../assets/cloud-base-logo-6.jpg";
import cloudbaselogo7 from "../../../assets/cloud-base-logo-7.jpg";
import cloudbaselogo8 from "../../../assets/cloud-base-logo-8.jpg";
import cloudbaselogo9 from "../../../assets/cloud-base-logo-9.jpg";
import cloudbaselogo10 from "../../../assets/cloud-base-logo-10.jpg";
import cloudbaselogo11 from "../../../assets/cloud-base-logo-11.jpg";
import cloudbaselogo12 from "../../../assets/cloud-base-logo-12.jpg";


const CloudBasedIntegration = (props) => {
    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.container}
            >
                <div
                    className={styles.para}
                >
                    If you're using based service, this is the way to go. You're minute away from completing the integration.
                    Click on your POS from the list below to give us access to the inventory data. Rest assure - we are only reading the
                    data from POS, and not writing to it. Once we get your permission(by click on the POS button), we'll start fetching the inventory
                    immediately. It should take <span>about a minute</span> until you'll see your products under the inventory.
                </div>

            </div>


            <div
                className={styles.logo_container}
            >
                <div className={styles.logo_section_tabs_container}>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Lightspeed retial')
                        }}
                    >
                        <img src={cloudbaselogo1} />
                    </div>

                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Clover')
                        }}
                    >
                        <img src={cloudbaselogo2} />
                    </div>

                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Shopify')
                        }}
                    >
                        <img src={cloudbaselogo3} />
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Square')
                        }}
                    >
                        <img src={cloudbaselogo4} />
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Bindo POS')
                        }}
                    >
                        <a href="https://bindolabs.com/" target='_blank'><img src={cloudbaselogo5} /></a>
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('BigCommerce')
                        }}
                    >
                        <img src={cloudbaselogo6} />
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Shopkeep')
                        }}
                    >
                        <img src={cloudbaselogo7} />
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Vend')
                        }}
                    >
                        <img src={cloudbaselogo8} />
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('eHopper')
                        }}
                    >
                        <img src={cloudbaselogo9} />
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Talech')
                        }}
                    >
                        <img src={cloudbaselogo10} />
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('NCR')
                        }}
                    >
                        <img src={cloudbaselogo11} />
                    </div>
                    <div
                        className={styles.logo_image}
                        onClick={() => {
                            props.showIntegrations()
                            props.setPlatformName('Alberta POS')
                        }}
                    >
                        <img src={cloudbaselogo12} />
                    </div>


                </div>
            </div>

            <div
                className={styles.cant_find}
                onClick={() => {
                    props.showIntegrations()
                    props.setPlatformName()
                }}
            >
                Still can't find your POS system?
                click here to schedule a meeting with our integration team.
            </div>
        </div>

    )
}

export default CloudBasedIntegration