import React, { useState, useEffect } from 'react';
import Fee from '../Inputs/Fee';
import ValueInput from '../Inputs/Value';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Config from '../Config/Config';
import LightTooltip from '../../../../../../../util_comps/LightTooltip';

const useStyles = makeStyles(() => ({
    fieldDescription: {
        color: 'blue',
        fontSize: '1rem',
        marginBottom: '5px',
        width: 'fit-content'
    },
    inputsContainer: {
        marginRight: '24%',
        "@media only screen and (max-width: 1024px)": {
            flexDirection: 'column',
            marginBottom: 30,
            marginRight: '0 !important'
        }
    },
    input: {
        flex: 3,
        marginRight: '3.5%',
        "@media only screen and (max-width: 1024px)": {
            marginRight: '0 !important',
            marginBottom: 15
        }
    }
}));

const Pickup = (props) => {
    const classes = useStyles();
    const [priceOption, setPriceOption] = useState('dollar');
    const { storeInfo } = useSelector(state => state.venueInfo);
    const { venue } = storeInfo;
    const dispatch = useDispatch(data => data);
    useEffect(() => {
        if (venue.pickupfeestype) {
            setPriceOption(venue.pickupfeestype);
        };
    }, [venue]);
    const handleInputChange = (pairs) => {
        let setter = {
            ...storeInfo,
            venue: {
                ...venue
            }
        }
        let isDirty = false;
        pairs.map((val) => {
            if (val.val !== '') {
                isDirty = true;
            };
            setter.venue = {
                ...setter.venue,
                [val.key]: val.val
            }
        });
        if (isDirty === '') {
            props.setPristine()
        } else {
            props.setDirty()
        }
        dispatch({
            type: "SET_STORE_INFO",
            payload: setter
        })
    }
    let venueHours = storeInfo.venue_hours?.filter((val) => val.venuetimings === 'pickup') || [];
    return (
        <div style={{ marginTop: 20 }}>
            <div className={classes.inputsContainer} style={{ display: 'flex' }}>
                <div className={classes.input}>
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>If you charge flat fees, choose "Dollar". Choose "Percent", if you want us  to calculate the fees based on order value.</span>} arrow placement="top">
                        <Typography className={classes.fieldDescription}>Pickup Fee Type</Typography>
                    </LightTooltip>
                    <Fee onChange={(val) => handleInputChange([{ key: "pickupfeestype", val }, { key: "pickupfees", val: venue.pickupfeestype === "dollar" ? venue.pickupfees || "0" : venue.pickupfees || "0.00" }])} value={venue.pickupfeestype} />
                </div>
                <div className={classes.input}>
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Enter Pickup fees here</span>} arrow placement="top">
                        <Typography className={classes.fieldDescription}>Pickup Fees</Typography>
                    </LightTooltip>
                    <ValueInput onChange={(val) => handleInputChange([{ key: "pickupfees", val }])} value={venue.pickupfees} type={priceOption} />
                </div>
                <div className={classes.input}>
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Only orders with value greater than this amount will be allowed to process. We recommend to have a low value here to get more pickup orders.</span>} arrow placement="top">
                        <Typography className={classes.fieldDescription}>Min. Pickup Amount</Typography>
                    </LightTooltip>
                    <ValueInput onChange={(val) => handleInputChange([{ key: "minpickupamount", val }])} value={venue.minpickupamount} type="dollar" />
                </div>
            </div>
            <div>
                <Config setDirty={props.setDirty} configs={venueHours} />
            </div>
        </div>
    );
};

export default Pickup;