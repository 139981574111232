import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import axios, { nodeAxios } from '../../../../utils/axiosConfig'
import Loader from '../../../Library/Loader'
import ValueTextField from '../../../Library/ValueTextField/ValueTextField'
import GenericLoadingButton from '../../../Library/GenericLoadingButton/GenericLoadingButton'
const TaxProperties = (props) => {

    const { venueDetails, pickupVenueHours, openVenueHours, fetchStoreInfo, removeImage, removeFavicon, isLoading, isActionsDisabled, deliveryZone, deliveryVenueHours } = useOutletContext()
    console.log('🚀OUTPUT --> venueDetails:', venueDetails);
    const [isEditMode, setIsEditMode] = useState(false)
    const [showAddHoliday, setShowAddHoliday] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imageFile, setImageFile] = useState()
    const [faviconFile, setFaviconFile] = useState()
    const [noLogo, setNoLogo] = useState(!Boolean(venueDetails.image))
    const [holidays, setHolidays] = useState([])
    const [venueSubTypes, setVenueSubTypes] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editObj, setEditObj] = useState({})



    useEffect(() => {
        setNoLogo(!Boolean(venueDetails.image))

    }, [venueDetails])






    const formik = useFormik({
        initialValues: {
            ...venueDetails,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("Updated values", values);
            setLoading(true)


            axios.post('/venue/setting', values)
                .then(() => {
                    // if (tabValue === 7) {
                    //     toast.success('Please make sure to change the tax rate on each category individually')
                    // } else {
                    toast.success('Saved Successfully')
                    //}
                })
                .catch((err) => {
                    toast.error(err.response?.data?.message)
                })
                .finally(() => {
                    setLoading(false)
                    fetchStoreInfo()
                })

        }
    })

    const handleChange = (e) => {
        const newValue = e.target.value;

        // Regular expression to match a valid number with up to two decimal places
        const regex = /^\d*\.?\d{0,2}$/;

        if (regex.test(newValue) && (newValue === '' || parseFloat(newValue) <= 100)) {
            formik.setFieldValue('venue_tax', newValue)
        }
    };



    return isLoading ? (<Loader />) : (
        <div className=' tw-flex tw-flex-col tw-gap-3 tw-text-sm tw-h-full tw-justify-center'>

            <div className='tw-flex tw-justify-between tw-flex-col lg:tw-flex-row lg:tw-items-center'>
                <div className='tw-font-bold tw-text-xl tw-w-full'>
                    <div className='tw-flex  tw-w-full tw-mt-4 tw-flex-col tw-gap-2'>
                        <div >
                            Taxes
                        </div>
                        <div className='!tw-text-xs'>
                            Choose your  percentage for orders
                        </div>

                    </div>
                </div>


            </div>
            <form

                onSubmit={formik.handleSubmit}
            >
                <div className='tw-flex tw-flex-row tw-gap-8'>


                    <ValueTextField

                        id={'venue_tax'}
                        name={'venue_tax'}
                        type={'percentage'}
                        texttype="number"
                        value={formik.values.venue_tax}
                        onChange={handleChange}
                        error={formik.touched.venue_tax && Boolean(formik.errors.venue_tax)}
                        helperText={formik.touched.venue_tax && formik.errors.venue_tax}
                    />

                </div>
                <div className='tw-flex tw-mt-4'>
                    <GenericLoadingButton
                        variant="contained"
                        type="button"
                        size='small'
                        onClick={() => {
                            formik.handleSubmit()

                        }}
                        size="large"
                        //loading={props.loading}
                        loadingPosition="end"
                    // disabled={props.isActionsDisabled}
                    >
                        Save tax percentage
                    </GenericLoadingButton>
                </div>
            </form>





        </div>
    )
}

export default TaxProperties