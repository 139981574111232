import React, { useEffect } from "react";
import { Typography, TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import day from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import GenericSwitch from "../../../../../../Library/GenericSwitch/GenericSwitch";
import styles from './Config.module.scss'

const useStyles = makeStyles((theme) => ({
    textField: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        width: 100,
    },
    container: {
        margin: "25px 100px 30px 0px",
        "@media only screen and (max-width: 1024px)": {
            margin: '0 0 40px 0 !important'
        }
    },
    item: {
        "@media only screen and (max-width: 1024px)": {
            flexDirection: 'column'
        }
    },
    itemDate: {
        "@media only screen and (max-width: 1024px)": {
            margin: "0 0 15px 0 !important"
        }
    },
    arrowIcon: {
        "@media only screen and (max-width: 1024px)": {
            margin: "0 20px !important"
        }
    },
    inputPickers: {
        "@media only screen and (max-width: 1024px)": {
            padding: '4px 5px 4px 10px !important',
        }
    }
}));

const Config = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch(data => data);
    const { storeInfo } = useSelector(state => state.venueInfo);
    const handleInputChange = (time, id, hrkey, minkey) => {
        let [hour, minute] = time.split(':');
        hour = parseInt(hour) > 10 ? parseInt(hour) : parseInt(hour[1]);
        minute = parseInt(minute) > 10 ? parseInt(minute) : parseInt(minute[1]);
        let newVenueHours = storeInfo?.venue_hours?.map((val) => {
            if (val.Id === id) {
                return {
                    ...val,
                    [hrkey]: hour,
                    [minkey]: minute,
                    isclosed: '0'
                }
            } else {
                return val;
            }
        });
        props.setDirty()
        dispatch({
            type: "SET_STORE_INFO",
            payload: {
                ...storeInfo,
                venue_hours: newVenueHours
            }
        });
    };
    const handleSwitch = (id) => {

        let openhour = "10"
        let openminute = "00"

        let closehour = "21"
        let closeminute = "00"
        openhour = parseInt(openhour) > 10 ? parseInt(openhour) : parseInt(openhour[1]);
        openminute = parseInt(openminute) > 10 ? parseInt(openminute) : parseInt(openminute[1]);

        closehour = parseInt(closehour) > 10 ? parseInt(closehour) : parseInt(closehour[1]);
        closeminute = parseInt(closeminute) > 10 ? parseInt(closeminute) : parseInt(closeminute[1]);

        let newVenueHours = storeInfo?.venue_hours?.map((val) => {
            if (val.Id === id) {
                return {
                    ...val,
                    opentimehours: openhour,
                    opentimemins: openminute,
                    closedtimehours: closehour,
                    closedtimemins: closeminute,
                    isclosed: val.isclosed === "0" ? "1" : "0"
                }
            } else {
                return val;
            }
        });
        dispatch({
            type: "SET_STORE_INFO",
            payload: {
                ...storeInfo,
                venue_hours: newVenueHours
            }
        });
    }
    return (
        <div className={classes.container}>
            {props.configs.map((val, i) => {
                return (
                    <div className={classes.item} style={{ margin: "17px 0", display: "flex" }}>
                        <div
                            className={classes.itemDate}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flex: 6,
                                marginRight: "10%",
                            }}
                        >
                            <Typography style={{ color: "black", marginRight: 20 }}>
                                {val.dayname}
                            </Typography>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <GenericSwitch
                                    checked={val.isclosed === "0"}
                                    onChange={e => {
                                        handleSwitch(val.Id)
                                    }}
                                />
                                <Typography
                                    style={{ color: "black", marginLeft: 20 }}
                                >
                                    {val.isclosed === "0" ? "Open" : "Closed"}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ flex: 7 }}>
                            {val.isclosed === "0" && (
                                <div
                                    className={classes.inputPickers}
                                    style={{
                                        border: "1px solid #DADADA",
                                        display: "flex",
                                        alignItems: 'center',
                                        padding: '4px 10px 4px 20px',
                                        borderRadius: 3
                                    }}
                                >
                                    <TextField
                                        type="time"
                                        defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                        className={classes.textField}
                                        onChange={(e) => {
                                            handleInputChange(e.target.value, val.Id, 'opentimehours', 'opentimemins')}
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                            endAdornment: null
                                        }}
                                        InputProps={{
                                            endAdornment: null,
                                            disableUnderline: true,
                                            classes: {
                                                notchedOutline: styles.input_box_style,
                                                input: styles.input_box
                                            }
                                        }}
                                    />
                                    <ArrowForwardIcon className={classes.arrowIcon} style={{ transform: 'scale(.8)', color: 'lightgrey', margin: '0 30px' }} />
                                    <TextField
                                        type="time"
                                        defaultValue={`${val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:${val.closedtimemins < 10 ? `0${val.closedtimemins}` : val.closedtimemins}`}
                                        onChange={(e) => handleInputChange(e.target.value, val.Id, 'closedtimehours', 'closedtimemins')}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        InputProps={{
                                            endAdornment: null,
                                            disableUnderline: true,
                                            classes: {
                                                notchedOutline: styles.input_box_style,
                                                input: styles.input_box
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Config;
