import { Autorenew } from '@mui/icons-material'
import { IconButton, MenuItem } from '@mui/material'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useId } from 'react'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'
import GenericSelectWithLabel from '../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import GenericSwitch from '../Library/GenericSwitch/GenericSwitch'
import GenericTextFieldWithLabel from '../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import styles from './MarketingFilters.module.scss'

const period = [
  {
    value: 7,
    label: 'Last 7 days'
  },
  {
    value: 15,
    label: 'Last 15 days'
  },
  {
    value: 30,
    label: 'Last 30 days'
  }
]

const MarketingFilters = (props) => {

  const id = useId()

  return (
    <div
      className={styles.container}
    >
      <div
        className={styles.start_section}
      >
        <div
          style={{
            minWidth: 150
          }}
          className={styles.item}
        >
          <GenericSelectWithLabel
            label="Period"
            placeholder="Select a social account"
            variant="outlined"
            value={props.periodState}
            onChange={(event) => props.setPeriodState(event.target.value)}
          >
            {period.map((data) => (
              <MenuItem
                key={id + data.value}
                value={data.value}
              >
                {data.label}
              </MenuItem>
            ))}
          </GenericSelectWithLabel>
        </div>

        <div
          className={styles.item}
        >
          <GenericTextFieldWithLabel
            className={styles.textfield_item}
            label="Current Start Date"
            id="date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={props.startDate}
            onChange={(event) => props.setStartDate(event.target.value)}
          />
        </div>

        <div
          className={styles.item}
        >
          <GenericTextFieldWithLabel
            className={styles.textfield_item}
            label="Current End Date"
            id="date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={props.endDate}
            onChange={(event) => props.setEndDate(event.target.value)}
          />
        </div>
      </div>

      <div
        className={styles.item}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 20
          }}
        >
          <div
            className={styles.label}
          >
            Compare
          </div>
          <GenericSwitch
            checked={props.isCompare}
            onChange={(event) => props.setIsCompare(event.target.checked)}
          />
        </div>
      </div>

      {props.isCompare && (
        <div
          className={styles.start_section}
        >
          <div
            className={styles.item}
          >
            <GenericTextFieldWithLabel
              label="Previous Start Date"
              id="date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={props.pstartDate}
              onChange={(event) => props.setpStartDate(event.target.value)}
            />
          </div>

          <div
            className={styles.item}
          >
            <GenericTextFieldWithLabel
              label="Previous End Date"
              id="date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={props.pendDate}
              onChange={(event) => props.setpEndDate(event.target.value)}
            />
          </div>
        </div>
      )}

      <div
        className={styles.btn_container}
      >
        <GenericLoadingButton
          type="submit"
          loading={props.isLoading}
          loadingPosition="end"
          variant="contained"
          onClick={props.onShowClick}
          size="large"
        >
          Show
        </GenericLoadingButton>
      </div>

      {props.isRefresh && (
        <div
          className={styles.item}
        >
          <IconButton
            style={{
              height: 'fit-content'
            }}
            onClick={props.refresh}
          >
            <Autorenew />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default MarketingFilters