import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInventories } from '../../../../redux/actions/inventoryActions';
import { getCategoryByID } from '../../../../servise/categoryAPI';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';

const CategorySelection = (props) => {
    const dispatch = useDispatch(data => data);
    useEffect(() => {
        (async () => {
            let categories = await getCategoryByID("0");
            categories.result && categories.result.sort(function (a, b) {
                let nameA = a.MainCatName.toLowerCase();
                let nameB = b.MainCatName.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            });
            props.setSelected([
                {
                    value: categories.result,
                    index: 0,
                    selectedValue: "none"
                }
            ]);
        })();
    }, []);

    const handleSelectChange = async (categoryStr, index) => {
        if (categoryStr === 'none') {
            let catid = props.selected[index].value[0].parent_id;
            if (catid === 0) {
                catid = ""
            }
            dispatch(getInventories({ page: 1, searchStr: props.searchStr, category_id: catid }));
            let newSelected = props.selected.filter((val, i) => i <= index);
            newSelected[index] = {
                ...newSelected[index],
                selectedValue: 'none'
            }
            props.setSelected(newSelected);
            return;
        }
        let categoryObj = props.selected[index].value.find(element => element.MainCatId === parseInt(categoryStr))
        dispatch(getInventories({ page: 1, searchStr: props.searchStr, category_id: categoryObj.MainCatId }));
        let newCategory = await getCategoryByID(categoryObj.MainCatId);
        newCategory.result.sort(function (a, b) {
            let nameA = a.MainCatName.toLowerCase();
            let nameB = b.MainCatName.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });
        if (index < props.selected[props.selected.length - 1].index) {
            let newSelected = props.selected.filter((val, i) => i <= index);
            newSelected[index] = {
                ...newSelected[index],
                selectedValue: categoryObj
            }
            props.setSelected([
                ...newSelected,
                {
                    index: newSelected[newSelected.length - 1].index + 1,
                    value: newCategory.result
                }
            ])
        } else {
            let newSelected = [...props.selected];
            newSelected[index] = {
                ...newSelected[index],
                selectedValue: categoryObj
            }
            props.setSelected([
                ...newSelected,
                {
                    index: props.selected[props.selected.length - 1].index + 1,
                    value: newCategory.result
                }
            ])
        };
    }
    return (
        <div
            style={{ display: 'flex', flexWrap: 'wrap' }}
        >
            {[0, 1, 2, 3].map((currentVal, currentIndex) => {

                let elementFocusable = currentVal <= props.selected[props.selected.length - 1].index;

                if (!elementFocusable || !Boolean(props.selected[currentVal].value?.length)) {
                    return
                }
                return (
                    <FormControl
                        size="small"
                        sx={{
                            marginRight: '20px',
                            minWidth: '100px'
                        }}
                    >
                        <Typography
                            variant="h6"
                            style={{ fontSize: '.96rem', marginBottom: 5 }}
                        >
                            {currentVal === 0 ? 'Category' : 'Subcategory'}
                        </Typography>
                            <Select
                                value={props.selected[currentVal]?.selectedValue?.MainCatId || 'none'}
                                onChange={e => handleSelectChange(e.target.value, currentVal)}
                            >
                                <MenuItem value="none">
                                    <em>Select</em>
                                </MenuItem>

                                {props.selected[currentVal].value?.map((val, i) => {
                                    return (
                                        <MenuItem value={val.MainCatId}>{val.MainCatName}</MenuItem>
                                    )
                                })}
                            </Select>
                        {/* <Select
                            className={classes.root}
                            disableUnderline
                            native
                            inputProps={{
                                disabled: !elementFocusable
                            }}
                            value={props.selected[currentVal]?.selectedValue?.MainCatId || 'none'}
                            onChange={e => handleSelectChange(e.target.value, currentVal)}
                        >

                            {elementFocusable && (
                                <React.Fragment>
                                    <option value="none">Selected</option>
                                    {props.selected[currentVal].value?.map((val, i) => {
                                        return (
                                            <option value={val.MainCatId}>{val.MainCatName}</option>
                                        )
                                    })}
                                </React.Fragment>
                            )}
                        </Select> */}
                    </FormControl>
                )
            })}
        </div>
    );
};

export default CategorySelection;