import { Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'

const RefundOrderDialog = (props) => {
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            is_cancel: true,
            cc_refund_amount: props.cc_amount || 0,
            gift_card_refund_amount: props.gift_card_amount || null,
        },
        onSubmit: (values) => {
            if (parseFloat(values.cc_refund_amount) > parseFloat(props.cc_amount)) {
                toast.error('CC Refund amount can not be greater than Order CC Amount')
            } else if (parseFloat(values.gift_card_refund_amount) > parseFloat(props.gift_card_amount)) {
                toast.error('Gift card amount can not be greater than Order Gift Card Amount')
            }
            else {
                setIsLoading(true)
                nodeAxios.post(`/orders/refund/${props.id}`, {
                    is_cancel: values.is_cancel,
                    cc_refund_amount: values.cc_refund_amount || 0,
                    gift_card_refund_amount: values.gift_card_refund_amount || null,
                    action_taken_from: (window.innerWidth < 1000) ? 'App' : 'Web'
                })
                    .then((res) => {
                        toast.success(res.data.message)
                        props.onClose()
                    })
                    .catch((err) => {
                        toast.error(err.response?.data?.message)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        }
    })

    return (
        <>
            <DialogTitle
                align="center"
                fontSize={20}
            >
                Refund Order
            </DialogTitle>

            <DialogContent
                style={{
                    width: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15
                }}
            >

                <FormControlLabel
                    control={<Checkbox checked={formik.values.is_cancel} onChange={(event) => formik.setFieldValue('is_cancel', event.target.checked)} />}
                    label="Cancel Order"
                />

                <GenericTextFieldWithLabel
                    id="cc_refund_amount"
                    name="cc_refund_amount"
                    label="CC Refund Amount"
                    type="number"
                    value={formik.values.cc_refund_amount}
                    onChange={formik.handleChange}
                />

                {
                    props.gift_card_amount &&
                    <GenericTextFieldWithLabel
                        id="gift_card_refund_amount"
                        name="gift_card_refund_amount"
                        label="Gift Card Refund Amount"
                        type="number"
                        value={formik.values.gift_card_refund_amount}
                        onChange={formik.handleChange}
                    />
                }

            </DialogContent>

            <DialogActions>
                <GenericLoadingButton
                    variant="contained"
                    onClick={formik.handleSubmit}
                    loading={isLoading}
                    loadingPosition="end"
                >
                    Refund
                </GenericLoadingButton>

                <GenericButton
                    variant="contained"
                    btnColor="secondary"
                    onClick={props.onClose}
                >
                    Cancel
                </GenericButton>
            </DialogActions>
        </>
    )
}

export default RefundOrderDialog