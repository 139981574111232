import React, { useState } from 'react'
import Blogs from '../CustomPageBuilder/EditSection/Blogs/WebsiteBuilderBlogs/Blogs'
import axios, { nodeAxios } from '../../utils/axiosConfig';
import { useEffect } from 'react';

const BlogsPage = () => {

  const [blogCategories, setBlogCategories] = useState([]);

  const fetchBlogCategories = () => {
    nodeAxios
      .get(`/web-builder/public/blog-category/v2?identifier=${localStorage.getItem("venue_id")}`) // /web-builder/blog-category
      .then((response) => {
        setBlogCategories({
          data: response.data?.data,
          pagination: response.data?.pagination,
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // dispatch(getChildrenAction({ page: 'Children', params: { page: 1 } }));
    fetchBlogCategories();
  }, []);
  return (
    <Blogs page='Blogs' {...{ blogCategories }} />
  )
}

export default BlogsPage