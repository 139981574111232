import React, { useState, useEffect } from 'react';
  import { useParams } from 'react-router-dom';
import Loader from '../../Library/Loader';
import { nodeAxios } from '../../../utils/axiosConfig';
import FormResponses from '../../Forms/FormResponses/FormResponses';
import { downloadCSV } from '../../../utils/helpers';
import { toast } from 'react-toastify';
 
const FormResponsesPage = () => {
  const param = useParams(); 
  const [formResponses, setFormResponses] = useState(null);
  const [isCSVDownloadLoading, setIsCSVDownloadLoading] = useState(null);
  const [form, setForm] = useState(null);

  const fetchForm = (params) => {
    setForm(null);
    nodeAxios
      .get(`/web-builder/web-page-form/get/${param?.id}`, { params })
      .then((response) => {
        setForm({
          data: response.data?.data,
        });
      })
      .catch((error) => {
        setForm({
          data: null,
        });
        console.log('🚀 ~ error', error);
      });
  };

  const fetchFormResponses = (params) => {
    setFormResponses(null);
    nodeAxios
      .get(`/web-builder/web-page-form/response/${param?.id}`, { params })
      .then((response) => {
        setFormResponses({
          data: response.data?.data,
          pagination: response.data?.pagination,
        });
      })
      .catch((error) => {
        setFormResponses({
          data: null,
        });
        console.log('🚀 ~ error', error);
      });
  };
  
  const downloadReport = (params, name) => {
    // setReport(null);
    //export: true

    const fileName = `${name}_Responses`;


    setIsCSVDownloadLoading(true);
    // axios.get(`/web-builder/web-page-form/response/${router.query.id}?export=true`, { params })
    nodeAxios.get(`/web-builder/web-page-form/response/${param?.id}?export=true`, { params })
      .then((response) => {
        const fileString = response?.data; // Replace with your actual CSV string
        setIsCSVDownloadLoading(false);
        downloadCSV(fileString, fileName);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e?.response?.data?.message);
        setIsCSVDownloadLoading(false);
      });
  };


  useEffect(() => {
    const params = {
      'sort-by-order': 'DESC',
      'sort-by': 'created_at',
    };
    if (param?.id) {
      fetchForm();
      fetchFormResponses(params); 
    }
  }, [param?.id]);

  return (
    <FormResponses
      edit={true}
      listings={formResponses}
      form={form}
      childId={param?.id}
      fetchListing={fetchFormResponses}
      page='Forms'
      downloadReport={downloadReport}
      setIsCSVDownloadLoading={setIsCSVDownloadLoading}
      isCSVDownloadLoading={isCSVDownloadLoading}
    />
  );
};


export default FormResponsesPage;
