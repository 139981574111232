import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import orderdetailsimg from "../../../assets/order-details.png";
import routePaths from '../../../config/route-paths';
const OrdersDetails = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Orders & Payments               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.findOrders}>Find Orders</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.transactionStatusReview}>Transaction Status Review</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.ordersDetails} className={cn('selected')}>Order Details</Link></li>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.OrdersStatus}>Order Status</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Orders Details
         </Typography>
         
         <p>
            
         <ol>
            <li>To find a detailed overview of any orders placed through your website or app, open the ‘<strong>Orders</strong>’ page.</li>
            
            <span className={cn('addnewproductsimg')}>
    <img src={orderdetailsimg} />
    </span>

            <li>At the top-right corner of the page, you will see ‘<strong>My Payments</strong>’ in underlined blue text. This is to the left of the Order ID search bar.
Click on ‘My Payments’.</li>

    
   
    <li>Search for an order by the <strong>Order ID</strong> or sort by date. When you find the order you are looking for, click on the ID number.
The text color will be blue and underlined. You can also click on the <strong>icon to the right of the Total Price</strong> of each order.</li>

<li>Now you can view all the details of a specific order, including time and date placed, order status, order tracking, payment methods,
and customer information.</li>

<li>When you are done viewing this order, click the back arrow at the top-right of the screen.</li>

<li>Now you can view your completed payments, and see the <strong>order date, customer information, credit card fees,</strong> Esplanda fees, and your net total.</li>


</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default OrdersDetails