import { Dialog } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { getInsideModel, handleActuallComponentsAdd, handleInsideAttrChange } from '../../Util/helpers'
import WebsiteEditorContext from '../../../../utils/context/websiteEditorContext'
import SelectFormDialog from '../../CustomPageBuilder/SelectFormDialog/SelectFormDialog'

const SelectFormDialogContainer = () => {

    const { editor } = useContext(WebsiteEditorContext)

    const [showFormDialog, setShowFormDialog] = useState(false)
    const [formBlockId, setFormBlockId] = useState()

    const handleFormAdd = (block) => {
        const parsedBlock = JSON.parse(JSON.stringify(block))
        try {
            const blockId = block?.getId();
            if (parsedBlock?.type?.includes('form') && !parsedBlock?.type?.includes('enrollment') && !parsedBlock?.type?.includes('signup')) {
                setFormBlockId(blockId);
                setShowFormDialog(true);
            }
        } catch (error) { }
    }

    const handleComponentSelect = (model, editor) => {

        const parsedModel = JSON.parse(JSON.stringify(model))
        handleActuallComponentsAdd(model, parsedModel)

        // if (!Boolean(formsRef.current?.filter((data) => data.form_id == model.ccid).length)) {
        if (!Boolean(parsedModel?.components?.[0]?.attributes?.group_id)) {
            handleFormAdd(model)
        }
    }

    useEffect(() => {
        if (editor) {
            editor.on('component:selected', (model) => handleComponentSelect(model, editor))
        }
    }, [editor])


    return (
        <Dialog open={showFormDialog}>
            <SelectFormDialog
                id={formBlockId}
                editor={editor}
                onUseForm={(formId) => {
                    const sModel = editor?.getSelected()
                    handleInsideAttrChange({ model: getInsideModel(sModel), key: 'form_id', value: formBlockId, isOutside: true })
                    handleInsideAttrChange({ model: getInsideModel(sModel), key: 'group_id', value: formId, isOutside: true })
                    setShowFormDialog(false);
                    setFormBlockId();
                }}
                onSuccess={(formBlockId) => {
                    const sModel = editor?.getSelected()
                    handleInsideAttrChange({ model: getInsideModel(sModel), key: 'form_id', value: formBlockId,isOutside:true })
                    setShowFormDialog(false);
                    setFormBlockId();
                }}
                onCancel={() => {
                    editor?.runCommand('core:component-delete');
                    setShowFormDialog(false);
                    setFormBlockId();
                }}
            />
        </Dialog>
    )
}

export default SelectFormDialogContainer