import { ArrowForward, Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, List, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch';
import GenericTextField from '../../Library/GenericTextField/GenericTextField';
import styles from './BusinessHours.module.scss'
import PreparationTime from '../../OrderSettings/PreparationTime/PreparationTime';

const hours = Array.from({ length: 48 }, (_, i) => {
    const hours = Math.floor(i / 2).toString().padStart(2, '0');
    const minutes = (i % 2 === 0) ? '00' : '30';
    return `${hours}:${minutes}`;
});

const findDivisors = (num) => {
    // Check if the number is less than 1
    if (num < 1) {
        return 'Input must be a positive integer greater than 0.';
    }

    // Initialize an array to hold the divisors
    let divisors = [];

    // Check every number from 1 to the target number
    // If 'num' is divisible by this number, then it's a divisor
    for (let i = 1; i <= num; i++) {
        if (num % i === 0) {
            divisors.push(i);
        }
    }

    return divisors;
}

const BusinessHours = (props) => {

    const [showPrep, setShowPrep] = useState(false)

    const handleInputChange = (time, index, hrkey, minkey) => {
        let [hour, minute] = time.split(':');
        console.log(hour, minute)
        hour = parseInt(hour) >= 10 ? parseInt(hour) : parseInt(hour[1]);
        minute = parseInt(minute) >= 10 ? parseInt(minute) : parseInt(minute[1]);
        console.log(hour, minute)
        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    [hrkey]: hour,
                    [minkey]: minute,
                    time_interval: 15
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    };

    const handleSlotChange = (time_interval, index, key) => {

        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    [key]: time_interval,
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    };


    const handleSwitch = (index) => {

        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    isclosed: Boolean(parseInt(val.isclosed)) ? '0' : '1'
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    }

    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.title}
            >
                Pickup Hours

                <Button
                    variant="text"
                    onClick={() => {
                        setShowPrep(true)
                    }}
                >
                    Add preparation times
                </Button>
            </div>

            <List disablePadding>
                {props.formik.values.venue_hours.map((val, index) => {
                    if (val?.venuetimings == 'delivery') {
                        return
                    }
                    const hourDiff = val.opentimehours - val.closedtimehours
                    const slotsOption = findDivisors(Math.abs(hourDiff))
                    return (
                        <div
                            className={styles.hour_item_container}
                        >
                            <Typography
                                style={{ color: "black", marginRight: 10, width: 85 }}
                            >
                                {val.dayname}
                            </Typography>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <GenericSwitch
                                    checked={val.isclosed == '0'}
                                    onChange={e => {
                                        handleSwitch(index)
                                    }}
                                />

                                <div
                                    style={{
                                        marginLeft: 5
                                    }}
                                >
                                    {val.isclosed == '0' ? "Open" : "Closed"}
                                </div>
                            </div>

                            <div
                                className={styles.hours_container}
                            >
                                {val.isclosed == '0' && (
                                    <div
                                        className={styles.time_container}
                                    >
                                        {/* <GenericTextField
                                            type="time"
                                            defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                            onChange={(e) => {
                                                console.log(e.target.value)
                                                handleInputChange(e.target.value, index, 'opentimehours', 'opentimemins')
                                            }}
                                        /> */}

                                        <GenericTextField
                                            select
                                            // defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                            value={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins || '00'}`}
                                            onChange={(e) => {
                                                handleInputChange(e.target.value, index, 'opentimehours', 'opentimemins')
                                            }}
                                            variant="outlined"
                                        >
                                            {hours.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </GenericTextField>

                                        <ArrowForward
                                            className={styles.arrow}
                                        />

                                        <GenericTextField
                                            select
                                            // defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                            value={`${val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:${val.closedtimemins || '00'}`}
                                            onChange={(e) => {
                                                handleInputChange(e.target.value, index, 'closedtimehours', 'closedtimemins')
                                            }}
                                            variant="outlined"
                                        >
                                            {hours.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </GenericTextField>

                                        {/* <GenericTextField
                                            type="time"
                                            defaultValue={`${val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:${val.closedtimemins < 10 ? `0${val.closedtimemins}` : val.closedtimemins}`}
                                            onChange={(e) => handleInputChange(e.target.value, index, 'closedtimehours', 'closedtimemins')}
                                        /> */}

                                        <GenericTextField
                                            style={{
                                                marginLeft: '5px'
                                            }}
                                            select
                                            // defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                            value={val.time_interval || 60}
                                            onChange={(e) => handleSlotChange(e.target.value, index, 'time_interval')}
                                            variant="outlined"
                                        >
                                            <MenuItem
                                                value={15}
                                            >
                                                15 min
                                            </MenuItem>
                                            <MenuItem
                                                value={30}
                                            >
                                                30 min
                                            </MenuItem>
                                            {slotsOption?.map((option) => (
                                                <MenuItem key={option} value={parseFloat(option) * 60}>
                                                    {option} hour
                                                </MenuItem>
                                            ))}
                                        </GenericTextField>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </List>


            <Dialog
                open={showPrep}
                onClose={() => {
                    setShowPrep(false)
                }}
                maxWidth="lg"
            >
                <DialogTitle>
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onClick={() => {
                            setShowPrep(false)
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <PreparationTime
                        type="pickup"
                        onClose={() => {
                            setShowPrep(false)
                        }}
                    />
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default BusinessHours