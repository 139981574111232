import clsx from 'clsx'
import React, { forwardRef } from 'react'
import ReactFacebookLogin from 'react-facebook-login'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import routePaths from '../../../config/route-paths'
import axios from '../../../utils/axiosConfig'
import Const from '../../helpers/const'
import styles from './FacebookButton.module.scss'

const FacebookButton = forwardRef((props, ref) => {

    const navigate = useNavigate()

    const { fetchFbList, fetchAll } = useOutletContext()

    return (
        <ReactFacebookLogin
            ref={ref}
            cssClass={clsx(styles.btn, props.facebookBtnClass)}
            appId={Const.isOurDesiStore ? "1372600669893266" : "446667517180549"}
            version="14.0"
            fields="name,email,picture"
            scope="pages_show_list,pages_read_engagement,pages_manage_posts,instagram_basic,instagram_content_publish,instagram_manage_insights,read_insights"
            textButton={props.textButton || "Connect"}
            callback={(res) => {
                let data = {
                    token: res.accessToken,
                    user_id: res.userID,
                }

                axios.post('/venue-setting/connect-fb', data)
                    .then(() => {
                        fetchAll()
                        toast.success('Facebook Connected')
                        navigate(routePaths.marketingDashboard)
                    }).catch(() => {
                        toast.error('Something went wrong')
                    })
            }}
            icon={props.icon}
        />
    )
})

export default FacebookButton