import { Close } from '@mui/icons-material'
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React, { useState } from 'react'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import TabsSection from '../../Library/TabsSection/TabsSection'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import Categories from './Categories/Categories'
import Pages from './Pages/Pages'
import WebAddress from './WebAddress/WebAddress'

const menuTypes = [
    {
        value: 0,
        label: 'Categories'
    },
    {
        value: 1,
        label: 'Pages'
    },
    {
        value: 2,
        label: 'Web Address'
    }
]


const EditMenuDialog = (props) => {

    const [webAddress, setWebAddress] = useState('')

    return (
        <>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <div>
                        Edit
                    </div>

                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onClick={props.onClose}
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <div
                        style={{
                            marginBottom: 20
                        }}
                    >
                        <GenericTextFieldWithLabel
                            label="Name"
                        />
                    </div>

                    <TabsSection
                        tabs={menuTypes}
                        panelHeight="unset"
                        scrollButtons="auto"
                        variant="scrollable"
                        tabsBody={[
                            {
                                component: <Categories />
                            },
                            {
                                component: <Pages />
                            },
                            {
                                component: (
                                    <WebAddress
                                        webAddress={webAddress}
                                        setWebAddress={setWebAddress}
                                    />
                                )
                            }
                        ]}
                    />
                </div>
            </DialogContent>

            <DialogActions>
                <GenericLoadingButton
                    variant="contained"
                >
                    Save
                </GenericLoadingButton>

                <GenericButton
                    variant="contained"
                    btnColor="secondary"
                    onClick={props.onClose}
                >
                    Cancel
                </GenericButton>
            </DialogActions>
        </>
    )
}

export default EditMenuDialog