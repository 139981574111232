import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React, { useState, useRef, useEffect, useCallback, Fragment } from 'react'
import InputField from '../../Library/InputField/InputField'
import SortBySelect from '../../Library/SortBySelect/SortBySelect'
import styles from './AddCollectionDialog.module.scss'

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import queryString from "query-string";
import "react-input-range/lib/css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesMenu } from "../../../redux/actions/searchActions";
import { getTagList } from "../../../redux/actions/tagListActions";
import { previewCategoryList, previewProductList } from "../../../servise/previewAPI";
import {
    addProductGroup,
    updateProductGroup,
} from "../../../servise/productGroupAPI";
import { constructSearchString } from "../../helpers/constructSearchString";
import {
    CategoryListCategories,
    ProductListCategories,
    TagList,
} from "../../pages/ProductGroup/Parts/Categories";
import Filter from "../../pages/ProductGroup/Parts/Filter";
import Preview from "../../pages/ProductGroup/Parts/Preview";
import Search from "../../pages/ProductGroup/Parts/Search";
import { DeleteForever, Search as SearchIcon } from '@mui/icons-material'
import SelectedCategories from "../../pages/ProductGroup/Parts/SelectedCategories";
import SelectedTags from "../../pages/ProductGroup/Parts/SelectedTags";
import { usePrompt } from "../../../hooks/usePageLeave";
import { useNavigate } from "react-router-dom";
import LightTooltip from "../../../util_comps/LightTooltip";
import ProductSquareChipSlider from '../../ProductSquareChipSlider/ProductSquareChipSlider'
import ProductCardSliderVenue from '../../ProductCardSliderVenue/ProductCardSliderVenue'
import { toast } from 'react-toastify'
import ProductListForCollection from '../../ProductListForCollection/ProductListForCollection'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
// import uuid from "uuid/v4";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getProductGroups } from '../../../redux/actions/productGroupActions'
import { debounce } from 'lodash'
import DragHereChip from '../../Library/DragHereChip/DragHereChip'
import GenericButton from '../../Library/GenericButton/GenericButton'
import Loader from '../../Library/Loader'


const AddCollectionDialog = (props) => {

    const dispatch = useDispatch((data) => data);

    const mainCategories = useSelector((state) => state.search.categoryMenu);
    const tags = useSelector((state) => state.tagList.tags);


    let [groupName, setGroupName] = useState("");
    let [listType, setListType] = useState(null);
    const [isSystem, setIsSystem] = useState(false)
    const [mainCategory, setMainCategory] = useState(0);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedTag, setSelectedTag] = useState({ id: null });
    const [productImage, setProductImage] = useState("");
    const [columns, setColumns] = useState({});
    const [existingColums, setexistingColums] = useState([])
    const [apiExistingColums, setApiExistingColums] = useState([])
    const [subCollection, setSubCollection] = useState('')
    const [productSearchQuery, setProductSearchQuery] = useState('')
    const [sortBy, setSortBy] = useState('newproduct')
    const [isLoading, setIsLoading] = useState(true)

    const [filters, setFilters] = useState({
        ratings: 0,
        maxPrice: 500,
        abv: "none",
        brands: [],
        countries: [],
        states: [],
        breweries: [],
        cities: [],
        sortby: "none",
    });

    const initState = useRef({
        filters: {
            ratings: 0,
            maxPrice: 500,
            abv: "none",
            brands: [],
            countries: [],
            states: [],
            breweries: [],
            cities: [],
            sortby: "none",
        },
        groupName: "",
        selectedCategories: [],
        mainCategory: 1,
    });

    const [buttonFunction, setButtonFunction] = useState("Preview");
    const [previewItems, setPreviewItems] = useState([]);

    const [isDirty, setIsDirty] = useState(false)
    // usePrompt("Are you sure want to discard changes?", isDirty);

    const isInit = useRef(true);

    const fetchMasterList = (query, sortby, existingColums) => {
        nodeAxios.get('/products/list', {
            params: {
                limit: 20,
                q: query,
                sortby: sortby
            }
        })
            .then((response) => {

                let newResult = response.data.data.map((data) => ({
                    ProductId: data.ProductId,
                    Name: data.ProductName,
                    maincatid: '',
                    catid: '',
                    category_name: data.category_name,
                    sub_category_name: ".",
                    Region: "",
                    ABV: data.ABV,
                    Description: data.description,
                    ibu: null,
                    SKU: data.sku,
                    ProductLogo: "",
                    image_file: data.image,
                    vPrice: data.Price,
                    format: ""
                }))

                setColumns((prevState) => {
                    let newObject = { ...prevState }
                    return ({
                        [0]: {
                            name: "From",
                            label: "Add Products to collection",
                            items: inBoth(newResult, existingColums || [])
                        },
                        [1]: {
                            name: "To",
                            label: "Existing Products in collection",
                            items: existingColums || []
                        }
                    })
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const debounceSearchProduct = useCallback(debounce(fetchMasterList, 1000), [])

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };

    useEffect(() => {
        if (columns) {
            setexistingColums(Object.values(columns).filter((data) => data.name == 'To')[0]?.items?.map((data) => data))
        }
    }, [columns])


    useEffect(() => {
        dispatch(getCategoriesMenu());
        dispatch(getTagList());
        fetchMasterList()
    }, [props.open]);

    useEffect(() => {
        if (props.subCollection == 'TagProductList' || props.subCollection == 0) {
            setListType("tagList")
        }
        if (props.subCollection == 'ProductList') {
            setListType("productList")
        }
        if (props.subCollection == 'CategoryList') {
            setListType("categoryList")
        }
    }, [props.subCollection])
    console.log(listType, props.subCollection)
    useEffect(() => {
        setSelectedCategories([]);

        if (listType == 'tagList') {
            setSubCollection("TagProductList")
        }
        if (listType == 'productList') {
            setSubCollection("ProductList")
        }
        if (listType == 'categoryList') {
            setSubCollection("CategoryList")
        }
    }, [listType]);

    const deleteChildren = (categories, children) => {

        for (let child of children) {
            let index = categories.findIndex(
                (i) => i.category_id === child.MainCatId
            );
            if (categories[index]) {
                categories.splice(index, 1);
            }
        }

        return categories;
    };

    const handleCategorySelection = (category, child) => {
        let categories = [...selectedCategories];
        if (selectedCategories.findIndex((i) => i.category_id === category.parent_id) !== -1) {
            return;
        }
        if (child) {
            deleteChildren(categories, child);
        }
        setSelectedCategories([...categories, category]);
    };

    const handleCategoryRemove = (category, children) => {
        let categories = [...selectedCategories];
        categories = categories.filter(
            (val) => val.category_id !== category.category_id
        );
        setSelectedCategories(categories);
    };

    const handleToggle = (e, category, child, parentId) => {
        let categories = [...selectedCategories];
        const getChildren = (children, parentId) => {
            if (children) {
                for (let child of children) {
                    let catObj = {
                        category_id: child.MainCatId,
                        categoryName: child.MainCatName,
                        category_image: child.image,
                        parent_id: parentId,
                    };
                    categories.push(catObj);
                }
                return categories;
            }
        };

        if (e.target.checked) {
            if (child) {
                deleteChildren(categories, child);
                getChildren(child, category.MainCatId);
            }
            categories = categories.filter(
                (val) => val.category_id !== category.MainCatId
            );
            setSelectedCategories(categories);
        } else {
            if (child) {
                deleteChildren(categories, child);
            }
            categories.push({
                category_id: category.MainCatId,
                categoryName: category.MainCatName,
                category_image: category.image,
                parent_id: parentId,
            });
            setSelectedCategories(categories);
        }
    };

    const operation = (list1, list2, isUnion = false) => list1?.filter((set => a => isUnion === set.has(a.ProductId ? a.ProductId : a.product_id))(new Set(list2?.map(b => b.ProductId ? b.ProductId : b.product_id))));
    const inBoth = (list1, list2) => operation(list1, list2, false)

    const preview = async (props) => {

        if (listType === "productList" || listType === "tagList") {
            const searchString = constructSearchString({
                category: props?.mainCategory || mainCategory,
                selectedCategories: props?.selectedCategories || selectedCategories,
                filters: props?.filters || filters,
            });

            const data = await previewProductList(
                listType === "tagList" ?
                    `/v2/product/tagged-products/${props?.selectedTag?.id ? props.selectedTag.id : selectedTag.id}` : `/v2/product/filter`,
                listType === "tagList" ?
                    `?venue_id=${localStorage.getItem("venue_id")}` : searchString,
                true
            );
            setPreviewItems(data.result);
            setexistingColums(data.result)
            setApiExistingColums(data.result)

            setColumns((prevState) => {
                let newObject = { ...prevState }
                return ({
                    [0]: {
                        name: "From",
                        label: "Add Products to collection",
                        items: inBoth(Object.values(columns)[0]?.items, data.result)
                    },
                    [1]: {
                        name: "To",
                        label: "Existing Products in collection",
                        items: data.result
                    }
                })
            })
            setProductImage(data.product_image);
        } else {
            setPreviewItems(selectedCategories);
        }
    };

    const handleExistingDelete = (id) => {
        // if (apiExistingColums.filter((data) => {
        //     if (data.ProductId) {
        //         return data.ProductId = id
        //     } else {
        //         return data.product_id = id
        //     }
        // }).length) {
        axios.delete(`/tag/remove-tag-from-tag-product/${selectedTag.id}/${id}`)
            .then((response) => {
                toast.success('Removed Succefully')
            })
        // }
    }

    const apply = async () => {

        if (listType == 'tagList') {
            await axios.post('/tag/create-tag-with-products', {
                tag_id: selectedTag.id,
                title: groupName,
                product_ids: existingColums.map((data) => data.ProductId ? data.ProductId : data.product_id)
            })

            toast.success('Collection created successfully')
            props.onClose()
        } else {
            try {
                setIsDirty(false);
                let parentIds = [];
                let getParentIds = (categories, parentId) => {
                    for (let category of categories) {
                        let parentIdToPush = parentId || category.MainCatId;
                        if (
                            selectedCategories.findIndex(
                                (i) => i.category_id === category.MainCatId
                            ) !== -1
                        ) {
                            parentIds.push(parentIdToPush);
                        }
                        if (category.child) {
                            getParentIds(category.child, parentIdToPush);
                        }
                    }
                };
                if (listType === "categoryList") {
                    getParentIds(mainCategories, null);
                }
                let requestData = {
                    ProductGroupName: groupName,
                    ParentCategoryIds:
                        listType === "categoryList" ? parentIds.join(",") : null,

                    ProductGroupType:
                        listType === "categoryList"
                            ? "CategoryList"
                            : listType === "productList"
                                ? "ProductList"
                                : "TagProductList",

                    CategoryIds: selectedCategories.map((val) => val.category_id).join(","),
                    ProductListURL:
                        listType === "categoryList"
                            ? null
                            : listType === "productList"
                                ? constructSearchString({
                                    category: mainCategory,
                                    selectedCategories,
                                    filters,
                                })
                                : `?venue_id=${localStorage.getItem("venue_id")}`,
                };

                if (listType === "tagList") {
                    requestData["tag_id"] = selectedTag.id;
                }
                if (props.isProductGroupEdit) {
                    let parsedQstr = queryString.parse(window.location.search);
                    await updateProductGroup(requestData, parsedQstr);
                    toast.success('Product Group Updated Successfully')

                    setGroupName('')
                    setSelectedCategories([])
                    dispatch(getProductGroups({ type: subCollection, page: 1 }))
                    props.onClose()
                } else {
                    await addProductGroup(requestData);
                    toast.success('Product Group Added Successfully')

                    setGroupName('')
                    setSelectedCategories([])
                    dispatch(getProductGroups({ type: subCollection, page: 1 }))
                    props.onClose()
                }
            } catch (err) {
                if (err) {
                    toast.error(listType === 'tagList' ? "Select any one tag from the list" : err.response.data[Object.keys(err.response.data)[0]])
                }
            }
        }
    };

    const handlePreviewApplyButton = async () => {
        if (listType == 'tagList') {
            if (!groupName) {
                toast.error('Please enter collection name')
                return
            }

            await apply();
            setIsDirty(false);
        } else {
            if (buttonFunction === "Preview") {
                await preview();
                setButtonFunction("Apply");
            } else {
                if (!groupName) {
                    toast.error('Please enter collection name')
                    return
                }

                await apply();
                setIsDirty(false);
            }
        }
    };

    useEffect(() => {
        if (listType === "categoryList" || listType === "tagList") {
            setButtonFunction("Apply");
            setSelectedTag({ id: null });
        } else {
            setButtonFunction("Preview");
        }
    }, [listType]);

    useEffect(() => {
        if (!isInit.current) {
            if (listType === "categoryList" || listType === "tagList") {
                setPreviewItems(selectedCategories);
            } else {
                setButtonFunction("Preview");
            }
        }
        isInit.current = false;
    }, [selectedCategories, filters]);

    useEffect(() => {
        if (props.isProductGroupEdit) {
            let parsedQstr = queryString.parse(window.location.search);
            let categoryIds = parsedQstr.categoryIds?.split(",");
            let {
                productGroupName,
                productGroupType,
                productListURL,
                productGroupId,
                selectedTagId,
                isSystem,
            } = parsedQstr;
            productListURL = decodeURIComponent(productListURL);
            productGroupName = decodeURIComponent(productGroupName);
            let parsedProductListURL = queryString.parse(productListURL);
            setIsSystem(Boolean(parseInt(isSystem)))

            let {
                abvvalue,
                q,
                sortby,
                rating,
                min,
                max,
                'country[]': countries,
                'state[]': states,
                'city[]': cities,
                brewery_id: breweries,
                category: plistMainCat,
            } = parsedProductListURL;
            if (!categoryIds || !productGroupName) {
                toast.error('Error occured due to not enough data')
                return;
            }
            let categories = [];

            previewCategoryList(categoryIds.join(','))
                .then((response) => {

                    setSelectedCategories(response.map((data) => ({
                        category_id: data.MainCatId,
                        categoryName: data.MainCatName,
                        category_image: data.image_file,
                        parent_id: data.parent_id || null,
                    })))
                })

            // let mainCat = { val: 0 };
            // const listCats = (children, parentId, rootId) => {
            //     for (let child of children) {
            //         for (let selectedCat of categoryIds) {
            //             if (child.MainCatId === parseInt(selectedCat)) {
            //                 mainCat.val = rootId;
            //                 categories.push({
            //                     category_id: child.MainCatId,
            //                     categoryName: child.MainCatName,
            //                     category_image: child.image,
            //                     parent_id: parentId || null,
            //                 });
            //             }
            //         }
            //         if (child.child) {
            //             let rootIdToPass = rootId || child.MainCatId;
            //             listCats(child.child, child.MainCatId, rootIdToPass);
            //         }
            //     }
            // };

            // console.log(pcat, categories)

            // listCats(mainCategories, null, null);
            preview()
            if (productGroupType === "TagProductList") {
                setListType("tagList");
                setSelectedTag({ id: parseInt(selectedTagId) });
                setGroupName(productGroupName);
                preview({
                    selectedTag: { id: parseInt(selectedTagId) },
                });
                setButtonFunction("Apply");
                return;
            }
            if (productGroupType === "ProductList") {
                let setter = {};
                if (breweries) {
                    let breweryArr = [];
                    for (let brewery of breweries) {
                        breweryArr.push({ VenueId: brewery });
                    }
                    setter["breweries"] = breweries;
                }
                if (abvvalue) {
                    setter["abv"] = abvvalue;
                }

                if (sortby) {
                    setter["sortby"] = sortby;
                }
                if (rating) {
                    setter["ratings"] = parseInt(rating);
                }
                if (max) {
                    setter["maxPrice"] = parseInt(max);
                }
                if (min) {
                    setter["minPrice"] = parseInt(min);
                }
                if (countries) {
                    setter["countries"] = Array.isArray(countries) ? countries : [countries];
                }
                if (states) {
                    setter["states"] = Array.isArray(states) ? states : [states];
                }
                if (cities) {
                    setter["cities"] = Array.isArray(cities) ? cities : [cities];
                }
                setFilters({
                    ...filters,
                    ...setter,
                });
                setListType("productList");
                setMainCategory(parseInt(plistMainCat));
                preview({
                    filters: {
                        ...filters,
                        setter,
                    },
                    listType: "productList",
                    mainCategory: parseInt(plistMainCat),
                    selectedCategories: [],
                });
                setButtonFunction("Apply");
                //categories.splice(mainCategoryIndex, 1);
            }

            if (productGroupType == 'CategoryList') {
                setListType('categoryList')
            }
            setSelectedCategories(categories);
            setGroupName(productGroupName);
            isInit.current = true;
        }
    }, [mainCategories, props]);

    const handleListTypeChange = (val) => {
        setListType(val);
        setMainCategory(1);
        setSelectedCategories([]);
    };

    let Categories =
        (listType === "productList")
            ? ProductListCategories
            : (listType === "tagList")
                ? ProductListForCollection
                : CategoryListCategories;

    return (
        <>
            <DialogTitle>
                {props.isProductGroupEdit ? "Edit Collection" : "Add Collection"}
            </DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div
                        className={styles.container}
                    >
                        <div
                            className={styles.group_name_sortby_container}
                        >
                            <div
                                style={{
                                    width: 220
                                }}
                            >
                                <div>
                                    Collection Name
                                </div>
                                <InputField
                                    // placeholder="Collection Name"
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                    disabled={isSystem}
                                    // value={collectionName}
                                    // onChange={(e) => {
                                    //     setCollectionName(e.target.value)
                                    // }}
                                    showIconButton={false}
                                />
                            </div>

                            <div
                                style={{
                                    width: '125px'
                                }}
                            >
                                <div>
                                    Collection Type
                                </div>
                                <SortBySelect
                                    value={listType}
                                    onChange={(e) => handleListTypeChange(e.target.value)}
                                    disabled={isSystem}
                                    menuItems={[
                                        // {
                                        //     value: 0,
                                        //     name: "All"
                                        // },
                                        {
                                            value: "tagList",
                                            name: "My Collection"
                                        },
                                        {
                                            value: "productList",
                                            name: "Products"
                                        },
                                        {
                                            value: "categoryList",
                                            name: "Categories"
                                        }
                                    ]}
                                />
                            </div>
                        </div>

                        <div
                            style={{
                                margin: '10px 0'
                            }}
                        >
                            <div
                                className={styles.details_container}
                            >

                                {listType === 'tagList' && (
                                    <DragDropContext
                                        onDragEnd={result => onDragEnd(result, columns, setColumns)}
                                    >
                                        {Object.entries(columns).map(([columnId, column], index) => {
                                            return (
                                                <Fragment
                                                    key={columnId}
                                                >
                                                    {index === 1 && (
                                                        <DragHereChip />
                                                    )}
                                                    <div
                                                        className={styles.dragging_columns}
                                                        key={columnId}
                                                    >
                                                        <div style={{ margin: 8, width: '100%' }}>
                                                            <Droppable droppableId={columnId} key={columnId}>
                                                                {(provided, snapshot) => {
                                                                    return (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={{
                                                                                border: '1px solid #dddddd',
                                                                                padding: 10,
                                                                                minHeight: 370
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                {column.label}
                                                                            </div>
                                                                            {column.name == 'From' && (
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex'
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            marginRight: 6
                                                                                        }}
                                                                                    >
                                                                                        <InputField
                                                                                            placeholder="Search Product"
                                                                                            value={productSearchQuery}
                                                                                            onChange={(e) => {
                                                                                                setProductSearchQuery(e.target.value)
                                                                                                debounceSearchProduct(e.target.value, '', existingColums)
                                                                                            }}
                                                                                            onIconButtonClick={e => debounceSearchProduct(productSearchQuery)}
                                                                                            showIconButton={true}
                                                                                            endIcon={(
                                                                                                <SearchIcon
                                                                                                    style={{
                                                                                                        color: '#ef2d2c'
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </div>

                                                                                    <div>
                                                                                        <SortBySelect
                                                                                            value={sortBy}
                                                                                            onChange={(event) => {
                                                                                                setSortBy(event.target.value)
                                                                                                fetchMasterList(productSearchQuery, event.target.value)
                                                                                            }}
                                                                                            menuItems={[
                                                                                                {
                                                                                                    value: "newproduct",
                                                                                                    name: "Newly Added"
                                                                                                },
                                                                                                {
                                                                                                    value: "nameaz",
                                                                                                    name: "Name (A-Z)"
                                                                                                },
                                                                                                {
                                                                                                    value: "nameza",
                                                                                                    name: "Name (Z-A)"
                                                                                                }
                                                                                            ]}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            <div
                                                                                style={{
                                                                                    minHeight: 270,
                                                                                    maxHeight: 280,
                                                                                    overflow: 'auto'
                                                                                }}
                                                                            >
                                                                                {column.items?.map((item, index) => {
                                                                                    return (
                                                                                        <Draggable
                                                                                            key={item.ProductId ? item.ProductId : item.product_id}
                                                                                            draggableId={item.ProductId ? item.ProductId : item.product_id}
                                                                                            index={index}
                                                                                        >
                                                                                            {(provided, snapshot) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        ref={provided.innerRef}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        style={{
                                                                                                            userSelect: "none",
                                                                                                            padding: 4,
                                                                                                            margin: "0 0 8px 0",
                                                                                                            minHeight: "30px",
                                                                                                            padding: 4,
                                                                                                            margin: '0px 0px 8px',
                                                                                                            minHeight: 30,
                                                                                                            display: 'flex',
                                                                                                            boxShadow: '0px 1px 4px #0000001a',
                                                                                                            alignItems: 'center',
                                                                                                            justifyContent: 'space-between',
                                                                                                            ...provided.draggableProps.style
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: 'flex'
                                                                                                            }}
                                                                                                        >
                                                                                                            <img
                                                                                                                src={item.image_file ? item.image_file : `${productImage}${item.product_id ? item.product_id : item.ProductId}/${item.logo ? item.logo : item.ProductLogo}`}
                                                                                                                height={30}
                                                                                                            />
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    width: 250,
                                                                                                                    whiteSpace: 'nowrap',
                                                                                                                    overflow: 'hidden',
                                                                                                                    textOverflow: 'ellipsis',
                                                                                                                    whiteSpace: 'nowrap',
                                                                                                                    overflow: 'hidden',
                                                                                                                    textOverflow: 'ellipsis',
                                                                                                                    padding: 10,
                                                                                                                    borderRadius: '4px'
                                                                                                                }}
                                                                                                            >
                                                                                                                {item.Name ? item.Name : item.name}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {column.name == 'To' && (
                                                                                                            <IconButton
                                                                                                                onClick={() => {
                                                                                                                    setColumns((prevState) => {
                                                                                                                        let newObject = { ...prevState }

                                                                                                                        let toData = inBoth(Object.values(newObject)[1]?.items, [item])
                                                                                                                        setexistingColums(toData)

                                                                                                                        if (item.ProductId) {
                                                                                                                            handleExistingDelete(item.ProductId)
                                                                                                                        } else {
                                                                                                                            handleExistingDelete(item.product_id)
                                                                                                                        }
                                                                                                                        return ({
                                                                                                                            [0]: {
                                                                                                                                name: "From",
                                                                                                                                label: "Add Products to collection",
                                                                                                                                items: Object.values(newObject)[0]?.items
                                                                                                                            },
                                                                                                                            [1]: {
                                                                                                                                name: "To",
                                                                                                                                label: "Existing Products in collection",
                                                                                                                                items: toData
                                                                                                                            }
                                                                                                                        })
                                                                                                                    })
                                                                                                                }}
                                                                                                            >
                                                                                                                <DeleteForever />
                                                                                                            </IconButton>
                                                                                                        )}

                                                                                                    </div>
                                                                                                );
                                                                                            }}
                                                                                        </Draggable>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Droppable>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            );
                                        })}
                                    </DragDropContext>
                                )}

                                {listType !== 'tagList' && (
                                    <>
                                        <div
                                            className={styles.list_type_leftsection}
                                        >

                                            <div
                                                style={{
                                                    marginBottom: '8px',
                                                    fontWeight: 600,
                                                    display: ((listType === "categoryList") || (listType === "tagList")) ?
                                                        "none" : "flex",
                                                }}
                                            >
                                                Categories
                                            </div>


                                            {listType === "categoryList" && (
                                                <Typography
                                                    style={{ fontSize: 18, marginBottom: "10px" }}
                                                    variant="h6"
                                                >
                                                    All Categories
                                                </Typography>
                                            )}
                                            {(
                                                <Categories
                                                    handleToggle={handleToggle}
                                                    mainCategories={mainCategories}
                                                    setCategories={setSelectedCategories}
                                                    mainCategory={mainCategory}
                                                    setMainCategory={setMainCategory}
                                                    handleCategoryRemove={handleCategoryRemove}
                                                    handleCategorySelection={handleCategorySelection}
                                                    selectedCategories={selectedCategories}
                                                    tags={tags}
                                                    selectedTag={selectedTag}
                                                    setSelectedTag={setSelectedTag}
                                                    setGroupName={setGroupName}
                                                />
                                            )}
                                        </div>

                                        <div
                                            className={styles.list_type_leftsection}
                                        >
                                            {listType === "productList" ? (
                                                <Filter
                                                    mainCategory={mainCategory}
                                                    initState={initState}
                                                    filters={filters}
                                                    setFilters={setFilters}
                                                />
                                            ) : listType === "tagList" ? (
                                                <></>
                                            ) : (
                                                <SelectedCategories
                                                    handleCategoryRemove={handleCategoryRemove}
                                                    setCategories={setSelectedCategories}
                                                    categories={selectedCategories}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="grouplist-preview-container">
                                <Typography
                                    style={{ fontSize: 18, marginBottom: "10px" }}
                                    variant="h6"
                                >
                                    Preview
                                </Typography>
                                <div
                                    style={{
                                        padding: "0 10px",
                                        alignSelf: "center",
                                        border: "1px solid lightgrey",
                                        position: "relative",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    {Boolean(groupName.length) && (
                                        <Typography
                                            style={{
                                                fontSize: 20,
                                                margin: "10px",
                                                position: "relative",
                                                top: "-9%",
                                            }}
                                            variant="h6"
                                        >
                                            {groupName.length > 20
                                                ? `${groupName.substring(0, 20)}...`
                                                : groupName}
                                        </Typography>
                                    )}

                                    {listType === "categoryList" ? (
                                        <ProductSquareChipSlider
                                            products={previewItems}
                                            slide={8}
                                            // baseImageLink={productImage}
                                            // isBaseUrl={true}
                                            isCategoryList={true}
                                        />
                                    ) : (
                                        <ProductCardSliderVenue
                                            products={listType == "tagList" ? existingColums : previewItems}
                                            baseImageLink={productImage}
                                            slide={8}
                                            isBaseUrl={listType != "tagList"}
                                        />
                                    )}

                                </div>
                            </div>
                        </div>

                    </div>
                )}
                <DialogActions>
                    <GenericButton
                        onClick={(e) => {
                            handlePreviewApplyButton()
                        }}
                        variant="contained"
                        disabled={isSystem && (buttonFunction === 'Apply')}
                    >
                        {buttonFunction}
                    </GenericButton>

                    <Button
                        onClick={(e) => {
                            props.onClose()
                        }}
                        style={{
                            backgroundColor: "#DDDDDD",
                            color: "#171717",
                        }}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </DialogContent>
        </>
    )
}

export default AddCollectionDialog