import React from 'react'
import { useSelector } from 'react-redux';
import CustomPageInfo from '../CustomPageInfo/CustomPageInfo'

const CustomPageInfoRoute = () => {
    const user = useSelector((state) => state.user);
  return (
    <CustomPageInfo isActionsDisabled={user?.data?.is_claim_business === 1} />
  )
}

export default CustomPageInfoRoute