import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import banner from "./banner";
import categoryByID from "./categorByID";
import category from "./category";
import dashboard from "./dashboard";
import giftCard from "./giftCard";
import groupList from "./groupList";
import inventory from "./inventory";
import { cancelOrder, changeOrderStatus, order } from "./order";
import OrderCount from "./orderCount";
import orderDetails from "./orderDetails";
import orders from "./orders";
import orderDetailsNew from "./orderDetailsNew";
import {
  categoryUpdate,
  categoryDetailsUpdate,
  addNewCategoey,
  changeCategoryLevel,
} from "./updateCategory";
import {
  setting,
  settingCreate,
  settingDetailsUpdate,
  settingDelete,
  bannerCategory,
} from "./setting";
import {
  venueSetting,
  editVenueSetting,
  venueStates,
  updateVenueURL,
} from "./venuSetting";
import payments from "./payments";
import productGroup from "./productGroup";
import productDetails from "./productMaster";
import { productList as productMasterList } from "./productMasterList";
import productStoreMaster from "./productStore";
import productStoreList from "./productStoreList";
import search from "./search";
import user from "./user";
import { venue } from "./venue";
import venueStore from "./venueStore";
import reviews from "./reviews";
import venueInfo from "./venueInfo";
import tagList from "./tagList";
import emailSetting from "./emailSetting";
import {
  getCouponsReducer,
  postNewCouponReducer,
  postUpdateCouponReducer,
} from "./couponsReducer";
import { getCustomPageReducer, getCustomPageDataReducer } from "./customPageReducer";

const rootReducer = combineReducers({
  user,
  order,
  cancelOrder,
  venueInfo,
  changeOrderStatus,
  orderDetails,
  OrderCount,
  venue,
  search,
  venueStore,
  productDetails,
  productMasterList,
  category,
  categoryByID,
  categoryUpdate,
  categoryDetailsUpdate,
  addNewCategoey,
  changeCategoryLevel,
  setting,
  settingCreate,
  settingDetailsUpdate,
  settingDelete,
  venueSetting,
  editVenueSetting,
  venueStates,
  updateVenueURL,
  productStoreMaster,
  productStoreList,
  payments,
  dashboard,
  giftCard,
  banner,
  productGroup,
  inventory,
  orders,
  groupList,
  orderDetailsNew,
  reviews,
  bannerCategory,
  tagList,
  emailSetting,
  getCouponsReducer,
  postNewCouponReducer,
  postUpdateCouponReducer,
  getCustomPageReducer,
  getCustomPageDataReducer
});

const middlewares = [];

const persistConfig = {
  key: "1.0.10",
  storage,
  blacklist: [
    "orderDetailsNew",
    "productGroup",
    "venueInfo",
    "user",
    "tagList",
    "emailSetting",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// if (process.env.NODE_ENV === "development") {
//   const { logger } = require("redux-logger");
//   middlewares.push(logger);
// }

const store = createStore(
  persistedReducer,
  applyMiddleware(thunk, ...middlewares)
);

export const persistor = persistStore(store);

export default store;
