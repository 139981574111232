import { useNavigate, useParams } from 'react-router'

import React, { useEffect, useState } from 'react'
import styles from './PostDetails.module.scss'
import axios from '../Util/axiosConfig'
import { Box, Button, MenuItem, outlinedInputClasses, Select, Tab, Tabs } from '@mui/material'
import { format } from 'date-fns'
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs'
import { FaFacebook, FaTwitter } from 'react-icons/fa'
import { Swiper, SwiperSlide } from "swiper/react";
import imageBaseUrl from '../Util/imageBaseUrl'
import LineGraph from '../Library/Charts/LineGraph'
import Loader from '../Library/Loader/Loader'
import { getImage } from '../Util/helpers'

const period = [
    {
        value: 7,
        label: 'Last 7 days'
    },
    {
        value: 15,
        label: 'Last 15 days'
    },
    {
        value: 30,
        label: 'Last 30 days'
    }
]

const PostDetails = (props) => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [readMore, setReadMore] = useState(false)
    const [postDetails, setPostDetails] = useState([])
    const [selectedDetails, setSelectedDetails] = useState()
    const [since, setSince] = useState(format((new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
    const [until, setUntil] = useState(format((new Date()), "yyyy-MM-dd"))
    const [dateValue, setDateValue] = useState(30)
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true)

    const fetchPostDetails = () => {
        setIsLoading(true)
        axios.get(`/marketing/social-post/detail/${id}`, {
            params: {
                since: since,
                until: until
            }
        })
            .then((response) => {
                setPostDetails(response.data.data.result)
                setSelectedDetails(response.data.data.result[0])
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedDetails(postDetails[newValue])
    };

    const handleChange2 = (event) => {
        setDateValue(event.target.value)
        setSince(format((new Date(Date.now() - event.target.value * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
    };


    useEffect(() => {
        fetchPostDetails()
    }, [since])

    return (
        <div
            className={styles.container}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div
                        className={styles.title_container}
                    >
                        <div
                            className={styles.post_details_text}
                        >
                            Post Details
                        </div>
                        {/* 
                <div>
                    <Button
                        style={{
                            backgroundColor: '#0B9DE4',
                            borderRadius: '30px',
                            fontWeight: 600
                        }}
                        variant="contained"
                        type="button"
                    >
                        Share
                    </Button>

                </div> */}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <div
                                className={styles.description_text}
                            >
                                Description
                            </div>

                            <p style={{ opacity: 0.6 }}>
                                {postDetails[0]?.content && (
                                    <>
                                        {readMore ? postDetails[0]?.content : `${postDetails[0]?.content?.substring(0, 75)}...`}
                                        {(postDetails[0]?.content?.length > 75) && (
                                            <button onClick={() => setReadMore(!readMore)} style={{ border: "none", backgroundColor: "inherit", cursor: 'pointer', display: 'inline-block' }}>
                                                {readMore ? 'Show less' : 'Read more...'}
                                            </button>
                                        )}
                                    </>
                                )}
                            </p>
                            <div style={{ opacity: 0.6 }}>Posted on</div>
                            {postDetails[0]?.post_create_at && (
                                <div style={{ opacity: 0.8 }}>{format(new Date(postDetails[0]?.post_create_at), 'LLL dd, yyyy hh:mm a')}</div>
                            )}
                        </div>

                        <div className={styles.image_container}>

                            <Swiper
                                style={{
                                    width: '100%'
                                }}
                                navigation={true}
                                // loop={true}
                                centeredSlides={true}
                                observeParents={true}
                                observer={true}
                                spaceBetween={5}
                            >
                                {selectedDetails?.images?.map((data) => (
                                    <SwiperSlide
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'relative'
                                            }}
                                            className={styles.upload_photo}
                                            variant="contained"
                                        >
                                            {(data.type_id == 1) ? (
                                                <video
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        borderRadius: '10px',
                                                        objectFit: 'contain'
                                                    }}
                                                    src={data.url}
                                                    controls={true}
                                                />
                                            ) : (
                                                <img
                                                    style={{
                                                        borderRadius: 10,
                                                        height: '150px',
                                                        maxWidth: '250px',
                                                        objectFit: 'contain'
                                                    }}
                                                    src={`${imageBaseUrl}/SocialPostImage/${selectedDetails?.post_group_id}/${data?.image}`}
                                                />
                                            )}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>

                    <div
                        style={{
                            fontWeight: 600
                        }}
                    >
                        Analytics
                    </div>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                        <Tabs value={value} onChange={handleChange}>
                            {postDetails.map((data) => (
                                <Tab
                                    label={(
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 6,
                                                color: (data.state_id == 2) ? 'red' : '#0B9DE4'
                                            }}
                                        >
                                            <div>
                                                {(data.type_id == 0) && (
                                                    <FaFacebook
                                                        color={"#4267B2"}
                                                        fontSize={20}
                                                    />
                                                )}

                                                {(data.type_id == 1) && (
                                                    <img
                                                    src={getImage('instagram.png-1699456353.png')}
                                                        height={20}
                                                        width={20}
                                                    />
                                                )}

                                                {(data.type_id == 2) && (
                                                    <FaTwitter
                                                        color={"#4267B2"}
                                                        fontSize={20}
                                                    />
                                                )}
                                            </div>

                                            <div>
                                                {data.page_name}
                                            </div>
                                        </div>
                                    )}
                                />
                            ))}
                        </Tabs>

                        <div>
                            <Select
                                value={dateValue}
                                onChange={handleChange2}
                                size="small"
                                sx={{
                                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                                        border: 'none'
                                    }
                                }}
                            >
                                {period.map((data) => (
                                    <MenuItem
                                        value={data.value}
                                    >
                                        {data.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Box>

                    {(new Date(selectedDetails.post_create_at).getTime() > new Date().getTime()) ? (
                        <div
                            style={{
                                padding: 20,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                style={{
                                    backgroundColor: '#0B9DE4',
                                    borderRadius: '30px',
                                    fontWeight: 600
                                }}
                                variant="contained"
                                type="button"
                                onClick={() => {
                                    navigate(`/marketing/create-post/${selectedDetails.post_group_id}`)
                                }}
                            >
                                Re-Schedule
                            </Button>
                        </div>
                    ) : (
                        <>
                            {Boolean(selectedDetails?.insights?.length) ? (
                                <div
                                    className={styles.line_graph_container}
                                >
                                    <div
                                        className={styles.line_graph_item}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 600
                                            }}
                                        >
                                            Views
                                        </div>

                                        {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.post_view && (
                                            <div
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: 18
                                                }}
                                            >
                                                {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.post_view}
                                            </div>
                                        )}

                                        {(Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.post_view - selectedDetails?.insights[0]?.post_view)) && (
                                            <div
                                                style={{
                                                    color: ((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.post_view - selectedDetails?.insights[0]?.post_view) > 0) ? '#107517' : '#E75C5C',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 4
                                                }}
                                            >
                                                {Math.abs(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.post_view - selectedDetails?.insights[0]?.post_view)}

                                                {((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.post_view - selectedDetails?.insights[0]?.post_view) > 0) && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 4
                                                        }}
                                                    >
                                                        <div>
                                                            increase
                                                        </div>

                                                        <BsFillArrowUpCircleFill />
                                                    </div>
                                                )}

                                                {((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.post_view - selectedDetails?.insights[0]?.post_view) < 0) && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 4
                                                        }}
                                                    >
                                                        <div>
                                                            decrease
                                                        </div>

                                                        <BsFillArrowDownCircleFill />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <LineGraph
                                            data={{
                                                labels: selectedDetails?.insights.map((data, index) => {
                                                    if (index == 0) {
                                                        if (data.job_run_at) {
                                                            return format(new Date(data.job_run_at), 'LLL dd')
                                                        } else {
                                                            return ''
                                                        }
                                                    } else if (index == (selectedDetails?.insights?.length - 1)) {
                                                        if (data.job_run_at) {
                                                            return format(new Date(data.job_run_at), 'LLL dd')
                                                        } else {
                                                            return ''
                                                        }
                                                    } else {
                                                        return ''
                                                    }
                                                }),
                                                datasets: [
                                                    {
                                                        data: selectedDetails?.insights.map((data) => data.post_view),
                                                        borderColor: '#0B9DE4',
                                                        borderWidth: 2
                                                        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                                    },
                                                ],
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={styles.line_graph_item}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 18
                                            }}
                                        >
                                            Impressions
                                        </div>

                                        {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression && (
                                            <div
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: 18
                                                }}
                                            >
                                                {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression}
                                            </div>
                                        )}

                                        {(Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression - selectedDetails?.insights[0]?.daily_impression)) && (
                                            <div
                                                style={{
                                                    color: ((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression - selectedDetails?.insights[0]?.daily_impression) > 0) ? '#107517' : '#E75C5C',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 4
                                                }}
                                            >
                                                {Math.abs(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression - selectedDetails?.insights[0]?.daily_impression)}

                                                {((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression - selectedDetails?.insights[0]?.daily_impression) > 0) && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 4
                                                        }}
                                                    >
                                                        <div>
                                                            increase
                                                        </div>

                                                        <BsFillArrowUpCircleFill />
                                                    </div>
                                                )}

                                                {((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression - selectedDetails?.insights[0]?.daily_impression) < 0) && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 4
                                                        }}
                                                    >
                                                        <div>
                                                            decrease
                                                        </div>

                                                        <BsFillArrowDownCircleFill />
                                                    </div>
                                                )}
                                            </div>
                                        )}


                                        <LineGraph
                                            data={{
                                                labels: selectedDetails?.insights.map((data, index) => {
                                                    if (index == 0) {
                                                        if (data.job_run_at) {
                                                            return format(new Date(data.job_run_at), 'LLL dd')
                                                        } else {
                                                            return ''
                                                        }
                                                    } else if (index == (selectedDetails?.insights?.length - 1)) {
                                                        if (data.job_run_at) {
                                                            return format(new Date(data.job_run_at), 'LLL dd')
                                                        } else {
                                                            return ''
                                                        }
                                                    } else {
                                                        return ''
                                                    }
                                                }),
                                                datasets: [
                                                    {
                                                        data: selectedDetails?.insights.map((data) => data.daily_impression),
                                                        borderColor: '#0B9DE4',
                                                        borderWidth: 2
                                                        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                                    },
                                                ],
                                            }}
                                        />
                                    </div>


                                    <div
                                        className={styles.line_graph_item}
                                    >
                                        <div>
                                            Likes
                                        </div>

                                        {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like && (
                                            <div
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: 18
                                                }}
                                            >
                                                {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like}
                                            </div>
                                        )}

                                        {(Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like - selectedDetails?.insights[0]?.daily_like)) && (
                                            <div
                                                style={{
                                                    color: ((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like - selectedDetails?.insights[0]?.daily_like) > 0) ? '#107517' : '#E75C5C',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 4
                                                }}
                                            >
                                                {Math.abs(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like - selectedDetails?.insights[0]?.daily_like)}

                                                {((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like - selectedDetails?.insights[0]?.daily_like) > 0) && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 4
                                                        }}
                                                    >
                                                        <div>
                                                            increase
                                                        </div>

                                                        <BsFillArrowUpCircleFill />
                                                    </div>
                                                )}

                                                {((selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like - selectedDetails?.insights[0]?.daily_like) < 0) && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 4
                                                        }}
                                                    >
                                                        <div>
                                                            decrease
                                                        </div>

                                                        <BsFillArrowDownCircleFill />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <LineGraph
                                            data={{
                                                labels: selectedDetails?.insights.map((data, index) => {
                                                    if (index == 0) {
                                                        if (data.job_run_at) {
                                                            return format(new Date(data.job_run_at), 'LLL dd')
                                                        } else {
                                                            return ''
                                                        }
                                                    } else if (index == (selectedDetails?.insights?.length - 1)) {
                                                        if (data.job_run_at) {
                                                            return format(new Date(data.job_run_at), 'LLL dd')
                                                        } else {
                                                            return ''
                                                        }
                                                    } else {
                                                        return ''
                                                    }
                                                }),
                                                datasets: [
                                                    {
                                                        data: selectedDetails?.insights.map((data) => data.daily_like),
                                                        borderColor: '#0B9DE4',
                                                        borderWidth: 2
                                                        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                                    },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (selectedDetails.state_id == 2) ? (
                                <div
                                    style={{
                                        padding: 20,
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: '#0B9DE4',
                                            borderRadius: '30px',
                                            fontWeight: 600
                                        }}
                                        variant="contained"
                                        type="button"
                                        onClick={() => {
                                            navigate(`/marketing/create-post/${selectedDetails.post_group_id}`)
                                        }}
                                    >
                                        Retry
                                    </Button>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 20,
                                        padding: 20,
                                        textAlign: 'center'
                                    }}
                                >
                                    No Insights Available
                                </div>
                            )}
                        </>
                    )}


                    <div
                        className={styles.insight_container}
                    >
                        {Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_post_view) && (
                            <div
                                className={styles.insight_info}
                            >
                                <div>
                                    Views
                                </div>

                                <div
                                    className={styles.insight_number}
                                >
                                    {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_post_view}
                                </div>
                            </div>
                        )}

                        {Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression) && (
                            <div
                                className={styles.insight_info}
                            >
                                <div>
                                    Impressions
                                </div>

                                <div
                                    className={styles.insight_number}
                                >
                                    {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_impression}
                                </div>
                            </div>
                        )}


                        {Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like) && (
                            <div
                                className={styles.insight_info}
                            >
                                <div>
                                    Likes
                                </div>

                                <div
                                    className={styles.insight_number}
                                >
                                    {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_like}
                                </div>
                            </div>
                        )}

                        {Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_reply) && (
                            <div
                                className={styles.insight_info}
                            >
                                <div>
                                    Replies
                                </div>

                                <div
                                    className={styles.insight_number}
                                >
                                    {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_reply}
                                </div>
                            </div>
                        )}

                        {Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_retweet) && (
                            <div
                                className={styles.insight_info}
                            >
                                <div>
                                    Retweet
                                </div>

                                <div
                                    className={styles.insight_number}
                                >
                                    {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_retweet}
                                </div>
                            </div>
                        )}

                        {Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_link_clicks) && (
                            <div
                                className={styles.insight_info}
                            >
                                <div>
                                    Clicks
                                </div>

                                <div
                                    className={styles.insight_number}
                                >
                                    {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_link_clicks}
                                </div>
                            </div>
                        )}


                        {Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_comments) && (
                            <div
                                className={styles.insight_info}
                            >
                                <div>
                                    Comments
                                </div>

                                <div
                                    className={styles.insight_number}
                                >
                                    {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_comments}
                                </div>
                            </div>
                        )}

                        {Boolean(selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_shares) && (
                            <div
                                className={styles.insight_info}
                            >
                                <div>
                                    Shares
                                </div>

                                <div
                                    className={styles.insight_number}
                                >
                                    {selectedDetails?.insights[selectedDetails?.insights?.length - 1]?.daily_shares}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default PostDetails