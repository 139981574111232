import React from 'react';
import styled from 'styled-components';

const RangeSliderContainer = styled.div`
    .input-range__track--active,
    .input-range__slider {
        background: ${(props) => props.color};
        border-color: ${(props) => props.color};
    }
    width: 100%;
`;

export default RangeSliderContainer;