import { bizBaseurl } from "../../env";
import axios from "../../utils/axiosConfig";
import { nanoid } from "nanoid";

export const getEmailTypes = (params) => {
  try {
    return async (dispatch) => {
      //should it be changed to baseurl?
      let { data } = await axios.get(`/venue-email-config/type-list`);
      let emailTypes = data.data.result;
      dispatch({
        type: "SET_EMAIL_TYPES",
        payload: emailTypes
      });
    };
  } catch (err) {
    throw err;
  }
};
