import React, { useRef, useState } from 'react'
import UploadImage from '../../../../Library/V2/UploadImage/UploadImage'
import styles from './BusinessLogo.module.scss'
import { Dialog, IconButton } from '@mui/material'
import { DeleteForever, Edit } from '@mui/icons-material'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
// import Const from '../../helpers/const'

const BusinessLogo = (props) => {

  const imageRef = useRef();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className='tw-flex tw-justify-between tw-items-center tw-mb-2'>
        <div className='tw-font-semibold tw-text-sm'>Business logo</div>
      </div>

      <div className='tw-w-full tw-mb-2'>
        <UploadImage
          uploadText="Upload Business Logo"
          isBig={true}
          ref={imageRef}
          image={props.imageFile}
          imageLink={props.imageLink}
          viewImageLabel="View Logo"
          removeLabel='Remove Logo'
          onViewImage={() => setOpen(true)}
          onRemoveImage={() => {
            props.removeImage()
            props.setImageFile()
            props.setNoLogo(true)
          }}
          onChange={(event) => {
            props.setImageFile(event.target.files[0])
            props.setNoLogo(false)
            event.target.value = ''
          }}
          onImageClose={(event) => {
            event.preventDefault()
            props.setImageFile()
          }}
        />
      </div>

      
      <Dialog
          open={open}
          onClose={() => setOpen(false)}
      >
          <div className={styles.uploaded_image}>
              <img
                  className='tw-max-w-full sm:tw-max-w-80 tw-max-h-full'
                  src={props.imageFile ? URL.createObjectURL(props.imageFile) : props.imageLink}
              />
          </div>
      </Dialog>
    </div>
  )
}

export default BusinessLogo