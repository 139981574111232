import { Button } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import styles from './ConnectionItems.module.scss'

const ConnectionItems = (props) => {
    return (
        <div
            className={clsx(styles.container, props.containerClassName)}
        >
            {props.icon}

            <div
                className={styles.title}
            >
                {props.title}
            </div>
            {props.button}
        </div>
    )
}

export default ConnectionItems