import React from 'react'
import { Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Add, CloseOutlined, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import InputField from '../../../../Library/InputField/InputField'
import GenericSearchField from '../../../../Library/V2/GenericSearchField/GenericSearchField'


function ItemsHeader({ sort, setSort, searchStr, setSearchStr, debounceSearch, isSearchLoading, setShowAddNewProduct, isMobile }) {
    return (
        <div>
            <div className='tw-flex tw-justify-between'>
                <h1 className="tw-text-2xl tw-font-semibold tw-mb-4 tw-text-black">Coupons</h1>

                {isMobile ? (
                    <div className='tw-fixed tw-bottom-10 tw-right-9 tw-z-10'>
                        <IconButton className='!tw-bg-black !tw-rounded-3xl !tw-text-white' onClick={() => setShowAddNewProduct(true)}>
                            <Add />
                        </IconButton>
                    </div>
                ) : (
                    <div className='tw-flex tw-items-center tw-gap-3'>
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            disableElevation
                            style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: 20
                            }}
                            onClick={() => {
                                setShowAddNewProduct(true)
                            }}
                        >
                            New Coupon
                        </Button>
                    </div>
                )}
            </div>
            <div className="tw-flex tw-justify-between">
                <div className="tw-flex tw-gap-4 tw-whitespace-normal tw-w-full lg:tw-w-auto">
                    Attract new customers, encourage repeat business, and differentiate yourself in a competitive market, ultimately driving overall profitability.
                </div>


            </div>
        </div>
    )
}

export default ItemsHeader;