import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { switchClasses } from '@mui/material/Switch';

const GenericSwitch = styled(({ isStatus, ...props }) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme, background, width, height, thumb, isStatus, translateX, onText, offText, textOffSet }) => ({
  width: width ? width : 32,
  height: height ? height : 16,
  overflow: 'visible',
  padding: 0,
  [`& .${switchClasses.switchBase}`]: {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',

    [`& .${switchClasses.input}`]: {
      ...(isStatus && {
        width: 108,
        left: 0,
      }),
    },

    '&.Mui-checked': {
      transform: `translateX(${translateX || (width ? width / 2 + 31 : 16)}px)`,
      color: '#fff',
      [`& .${switchClasses.input}`]: {
        ...(isStatus && {
          left: -88,
        }),
      },
      [`& + .${switchClasses.track}`]: {
        background: background ? background : '#000000',
        opacity: 1,
        border: 0,
        position: 'relative',
        ...(isStatus && {
          '&:after': {
            content: onText || "'VISIBLE'",
            color: background ? background : 'white',
            left: '42px',
            position: 'absolute',
            top: textOffSet || '3px',
            fontSize: 12,
            fontWeight: 600
          },
        }),
      },
      [`&.Mui-disabled + .${switchClasses.track}`]: {
        opacity: 0.5,
      },
    },
    [`&.Mui-focusVisible .${switchClasses.thumb}`]: {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    [`&.Mui-disabled .${switchClasses.thumb}`]: {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    [`&.Mui-disabled .${switchClasses.track}`]: {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  [`& .${switchClasses.thumb}`]: {
    boxSizing: 'border-box',
    width: thumb ? thumb : 12,
    height: thumb ? thumb : 12,
  },
  [`& .${switchClasses.track}`]: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#DDDDDD' : '#DDDDDD',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    position: 'relative',
    ...(isStatus && {
      '&:after': {
        content: offText || "'HIDDEN'",
        color: '#D4D4D4',
        left: '42px',
        position: 'absolute',
        top: textOffSet || '3px',
        fontSize: 12,
        fontWeight: 600
      },
    }),
  },
}));

export default GenericSwitch;
