import axios from "../../utils/axiosConfig";
import Const from "../../components/helpers/const";

export function getVenueSetting(params) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url =
        params !== ""
          ? `/venue/setting?${params}`
          : "/venue/setting";
      dispatch({ type: "SET_VENUE_SETTING_LIST_LOADING" });

      return axios
        .get(url)
        .then((response) => {
          dispatch({
            type: "SET_VENUE_SETTING_LIST_LOADED",
            payload: response.data,
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: "SET_VENUE_SETTING_LIST_ERROR",
            payload: error.response,
          });
          return Promise.resolve();
        });
    }
  };
}
export function updateVenueDetails(params) {

  return (dispatch) => {
    if (localStorage.userToken) {
      let url = `/venue/setting`;
      
      dispatch({ type: "SET_EDIT_VENUE_SETTING_LIST_LOADING" });
      return axios
        .post(url, params)
        .then((response) => {
          dispatch({
            type: "SET_EDIT_VENUE_SETTING_LIST_LOADED",
            payload: response.data,
          });

          return Promise.resolve(response.data);
        })

        .catch((error) =>
          dispatch({
            error: true,
            type: "SET_EDIT_VENUE_SETTING_LIST_LOADED",
            message: error.response,
          })
        );
    }
  };
}
export function getVenueStates(params) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url =
        params !== ""
          ? `/venue/states?${params}`
          : "/venue/states";
      dispatch({ type: "SET_VENUE_STATES_LIST_LOADING" });
      return axios
        .get(url)
        .then((response) => {
          dispatch({
            type: "SET_VENUE_STATES_LIST_LOADED",
            payload: response.data,
          });
          
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: "SET_VENUE_STATES_LIST_ERROR",
            payload: error.response,
          });
          return Promise.resolve();
        });
    }
  };
}
export function updateVenueURL(params) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url = `/venue/create-url`;
      
      dispatch({ type: "SET_VENUE_URL_LOADING" });
      return axios
        .post(url, params)
        .then((response) => {
          dispatch({
            type: "SET_VENUE_URL_LOADED",
            payload: response.data,
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            error: true,
            type: "SET_VENUE_URL_ERROR",
            message: error.response.data,
          });
          return Promise.resolve(error.response.data);
        });
    }
  };
}
