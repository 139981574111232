import React, { useEffect, useState } from 'react'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import styles from './OrderStatus.module.scss'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch'
import Clover from '../Clover/Clover'
import { useConfirm } from 'material-ui-confirm'
import { toast } from 'react-toastify'

const OrderStatus = ({ formik, isClover, setIsClover, isColverSaved }) => {

    const confirm = useConfirm()
    const [cloverCred, setCloverCred] = useState()

    const fetchCloverSettings = () => {
        nodeAxios.get('/clover/connection-credentials')
            .then((res) => {
                setCloverCred(res?.data?.data)
                setIsClover(true)
            })
            .catch(() => {
                setIsClover(false)
            })
    }

    useEffect(() => {
        fetchCloverSettings()
    }, [isColverSaved])

    const handleDisconnect = () => {
        confirm({
            title: 'Are you sure you want to disconnect clover',
        })
            .then(() => {
                axios.delete(`/venue-clover-setting/remove`)
                    .then((res) => {
                        toast.success('Clover disconnected')
                        setIsClover(false)
                    })
                    .finally(() => {
                    })
            })
            .catch(() => {

            })
            .finally(() => {
                fetchCloverSettings()
            })
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    gap: 5
                }}
            >
                <div
                    style={{
                        fontWeight: 600
                    }}
                >
                    Clover
                </div>
                <GenericSwitch
                    background="#00C57E"
                    width={60}
                    height={24}
                    translateX={38}
                    thumb={19}
                    isStatus={true}
                    checked={isClover}
                    onText="'On'"
                    offText="'Off'"
                    onChange={(event) => {
                        if (event.target.checked) {
                            setIsClover(event.target.checked)
                        } else {
                            handleDisconnect()
                        }
                    }}
                />
            </div>

            {isClover ? (
                <Clover
                    cloverCred={cloverCred}
                    handleDisconnect={handleDisconnect}
                />
            ) : (
                <>
                    <div
                        style={{
                            fontWeight: 700,
                            fontSize: 18,
                            marginBottom: 10,
                            marginTop: 15
                        }}
                    >
                        When an order is accepted, Set the Order Status to:
                    </div>

                    <div
                        className={styles.container}
                    >
                        <div
                            style={{
                                border: '1px solid #d3d3d3',
                                padding: '10px',
                                borderRadius: '10px'
                            }}
                        >
                            <FormControl>
                                <FormLabel>
                                    Pickup Order Status
                                </FormLabel>
                                <RadioGroup
                                    value={formik.values?.pickup_order_status}
                                    onChange={(event) => {
                                        formik.setFieldValue('pickup_order_status', parseInt(event.target.value))
                                    }}
                                >
                                    <FormControlLabel
                                        value={0}
                                        control={<Radio />}
                                        label="Accept"
                                    />
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio />}
                                        label="Ready"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio />}
                                        label="Closed"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div
                            style={{
                                border: '1px solid #d3d3d3',
                                padding: '10px',
                                borderRadius: '10px'
                            }}
                        >
                            <FormControl>
                                <FormLabel>
                                    Delivery Order Status
                                </FormLabel>
                                <RadioGroup
                                    value={formik.values?.delivery_order_status}
                                    onChange={(event) => {
                                        formik.setFieldValue('delivery_order_status', parseInt(event.target.value))
                                    }}
                                >
                                    <FormControlLabel
                                        value={0}
                                        control={<Radio />}
                                        label="Accept"
                                    />
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio />}
                                        label="Ready"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio />}
                                        label="Closed"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {/* <div>
                <FormControl>
                    <FormLabel>
                        Shipping Order Status
                    </FormLabel>
                    <RadioGroup
                        value={formik.values?.shipping_order_status}
                        onChange={(event) => {
                            formik.setFieldValue('shipping_order_status', parseInt(event.target.value))
                        }}
                    >
                        <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Accept"
                        />
                        <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Ready"
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Closed"
                        />
                    </RadioGroup>
                </FormControl>
            </div> */}
                    </div>
                </>
            )}
        </div>
    )
}

export default OrderStatus