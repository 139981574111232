import { Button, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import ClaimMyBusinessForm from "./ClaimMyBusinessForm/ClaimMyBusinessForm"
import React, { useEffect } from "react";
import cart from "../../assets/cart.png";
import man from "../../assets/man.jpg";
import ourdesistoreman from "../../assets/brand-story-desistore.jpg";
import ourdesistorecustomize from "../../assets/ourdesi-store-customize.png";
import ourdesistorenewcustomers from "../../assets/new-customers.jpg";
import customerreview1 from "../../assets/customer-review-1.jpg";
import customerreview2 from "../../assets/customer-review-2.jpg";
import payment from '../../assets/payment.png';
import customers from '../../assets/customers.png';
import customize from "../../assets/customize.png";
import women from "../../assets/women.jpg";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReviews } from '../../redux/actions/reviewActions.js';
import Const from "../helpers/const";
import styles from './ClaimMyBusiness.module.scss'
import { Check } from "@mui/icons-material";

const ClaimMyBusiness = (props) => {

  const history = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch(data => data);
  const { result: reviews } = useSelector(state => state.reviews);
  const handleGetStarted = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  const handleOurFeatures = () => {
    history('/our-features');
  }
  useEffect(() => {
    dispatch(getReviews());
  }, []);

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
      <div
        className="signup-form-container"
        style={{
          height: "65vh",
          backgroundColor: "rgb(55,53,54)",
          width: "100%",
          marginBottom: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: 'relative'
        }}
      >
        <div className="signup-header" style={{ width: '44%', marginRight: '30%' }}>
          <Typography
            variant="h3"
            style={{ color: "white", fontWeight: "700", fontSize: "3.5rem", }}
          >
            Claim your business, to manage your business details.
          </Typography>
          <Typography style={{ color: "white", marginTop: '20px', fontSize: "1.2rem" }}>
            Get found online whenever new customers are searching for liquor stores like yours, and bring more customers for your business.
          </Typography>
        </div>
        <div
          className={styles.form_container}
        >
          <ClaimMyBusinessForm id={props.id} details={props.details} />
        </div>
      </div>
      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            marginBottom: "30px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "2.2rem",
              textAlign: "center",
              color: "black",
            }}
          >
            Whatever you need, we have resources.
          </Typography>

          <div
            style={{
              textAlign: 'center'
            }}
          >
            Create a free business profile with us and drive more customers, sales & revenue!!
          </div>
        </div>
        <div
          className="signup-benefits"
          style={{
            gridTemplateColumns: "33% 33% 33%",
            display: "grid",
            margin: "0 5vw",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={cart} />
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "1.25rem",
                color: "black",
                marginBottom: "5px",
              }}
            >
              Increase Your Brand Awareness
            </Typography>
            <Typography
              style={{
                fontSize: ".9rem",
                padding: "0 75px",
                color: "black",
              }}
            >
              Add your business, where thousands of customers are already searching for liquor stores, to increase your brand awareness.
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={payment} />
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "1.25rem",
                color: "black",
                marginBottom: "5px",
              }}
            >
              Reach Potential Customers Faster
            </Typography>
            <Typography
              style={{
                fontSize: ".9rem",
                padding: "0 75px",
                color: "black",
              }}
            >
              Make your business profile engaging & attractive and reach more customers easily and faster.
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={customers} />
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "1.25rem",
                color: "black",
                marginBottom: "5px",
              }}
            >
              Increased Web Traffic & Sales
            </Typography>
            <Typography
              style={{
                fontSize: ".9rem",
                padding: "0 75px",
                color: "black",
              }}
            >
              Attract new customers to your business profile and turn visitors into customers.
            </Typography>
          </div>
        </div>
      </div>
      <div
        className='signup-brandstory'
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "50px",
          background: "#f8f8f8",
        }}
      >
        <img src={Const.isOurDesiStore ? ourdesistoreman : man} style={{ marginRight: "6%", width: '45%' }} />
        <div className='signup-brandstory-texts' style={{ width: "45%" }}>
          <Typography
            variant="h3"
            style={{
              color: "black",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Your Brand's Story Starts with your Web Address
          </Typography>
          <Typography style={{ color: "black", fontSize: "1.2rem" }}>
            Extend your Brick & Mortar store to the web. We act  as online extension to your existing store by seamlessly bring your inventory online.
            You'll receive guided support and a toolset of products to tell
            your brand story & track performance. You can easily upload new
            innovative products and get quick result for your products.
          </Typography>
        </div>
      </div>
      <div
        className="signup-customize"
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "2%",
          marginBottom: '50px'
        }}
      >
        <div>
          <div>
            <Typography
              variant="h3"
              style={{
                color: "black",
                fontWeight: "bold",
                marginBottom: "20px",
                fontSize: "2.5rem"
              }}
            >
              Easily connect with customers
            </Typography>
            <div>
              Add your Business Profile with latest photos, offers, reviews and ratings. It helps to provide customers complete information about your business.
            </div>
          </div>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            {[
              'Make your fully optimized business profile to stand out in search results ',
              'Respond to reviews & messages to build engagement with customers',
              'Add Latest Photos, Offers, Location and Contact details to easily connect with',
              'Make it easier for customers to directly connect with your business via website'
            ].map((data) => (
              <ListItemButton>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary={data} />
              </ListItemButton>
            ))}
          </List>
        </div>
        <img
          style={{
            width: '50%'
          }}
          src={Const.isOurDesiStore ? ourdesistorecustomize : customize}
        // style={{ marginLeft: "5%" }}
        />
      </div>


      <div
        className="signup-reach-new-customers"
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "50px",
          background: "#f8f8f8",
        }}
      >
        <div className="signup-reach-new-customers-texts" style={{ width: "45%", marginLeft: '2%' }}>
          <Typography
            variant="h3"
            style={{
              color: "black",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Reach new customers at a lower cost
          </Typography>
          <Typography style={{ color: "black", fontSize: "1.2rem" }}>
            Get your store online for free no monthly Fees or Contracts.
          </Typography>
        </div>
        <img src={Const.isOurDesiStore ? ourdesistorenewcustomers : women} style={{ marginLeft: "6%", width: '47%', height: '450px', objectFit: 'cover' }} />
      </div>

      <div
        className="signup-reach-new-customers"
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "50px",
        }}
      >
        {/* <img src={Const.isOurDesiStore ? ourdesistorenewcustomers : women} style={{ marginLeft: "6%", width: '47%', height: '450px', objectFit: 'cover' }} /> */}
        <div className="signup-reach-new-customers-texts" style={{ marginLeft: '2%' }}>
          <Typography
            variant="h3"
            style={{
              color: "black",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Know your Business Profile Insights
          </Typography>
          <Typography style={{ color: "black", fontSize: "1.2rem" }}>
            Add your business profile and get detailed insights about how customers are finding you, on what keywords you're ranking on top and best way to connect with customers. These insights help you to attract your potential customers, increase engagement and easily drive more traffic on your website.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            marginBottom: "30px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "2.2rem",
              textAlign: "center",
              color: "black",
            }}
          >
            Add your Business Profile in 3 Simple Steps
          </Typography>
        </div>
        <div
          className="signup-benefits"
          style={{
            gridTemplateColumns: "33% 33% 33%",
            display: "grid",
            margin: "0 5vw",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {/* <img src={cart} /> */}
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "1.25rem",
                color: "black",
                marginBottom: "5px",
              }}
            >
              Claim your Listing
            </Typography>
            <Typography
              style={{
                fontSize: ".9rem",
                padding: "0 75px",
                color: "black",
              }}
            >
              Create your business profile and list your business for multiple locations to get appear in search results.
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            {/* <img src={payment} /> */}
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "1.25rem",
                color: "black",
                marginBottom: "5px",
              }}
            >
              Personalized your Business Profile
            </Typography>
            <Typography
              style={{
                fontSize: ".9rem",
                padding: "0 75px",
                color: "black",
              }}
            >
              Personalize your business profile, add business hours, latest photos, locations, products and contact details to easily connect with customers.
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            {/* <img src={customers} /> */}
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "1.25rem",
                color: "black",
                marginBottom: "5px",
              }}
            >
              Easily Manage
            </Typography>
            <Typography
              style={{
                fontSize: ".9rem",
                padding: "0 75px",
                color: "black",
              }}
            >
              Manage your business profile from one-place, respond to customers and build engagement to drive more traffic on your website.
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography className="signup-reviews-header" variant="h4" style={{ textAlign: 'center', color: 'black', fontWeight: 'bold', marginBottom: '30px' }}>
          See What Store Owner Say About Us
        </Typography>
        <div
          className="signup-reviews-grid"
          style={{ gridTemplateColumns: "50% 50%", display: "grid", margin: '0 11vw' }}
        >
          <div className="signup-reviews-grid-first" style={{ marginRight: '20px' }}>
            <div style={{ width: '100%', height: '240px' }}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Const.isOurDesiStore ? customerreview1 : reviews[0]?.image_file} />
            </div>
            <Typography style={{ fontSize: "1.2rem", color: 'black', fontWeight: '600' }}>
              {reviews[0]?.name}
            </Typography>
            <Typography style={{ color: "rgb(185,66,60)", fontWeight: 'bold' }}>
              Store Name: {reviews[0]?.store_name}
            </Typography>
            <Typography style={{ color: 'black', fontSize: '.85rem' }}>{reviews[0]?.description}</Typography>
          </div>
          <div className="signup-reviews-grid-second" style={{ marginRight: '20px' }}>
            <div style={{ width: '100%', height: '240px' }}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Const.isOurDesiStore ? customerreview2 : reviews[1]?.image_file} />
            </div>
            <Typography style={{ fontSize: "1.2rem", color: 'black', fontWeight: '600' }}>
              {reviews[1]?.name}
            </Typography>
            <Typography style={{ color: "rgb(185,66,60)", fontWeight: 'bold' }}>
              Store Name: {reviews[1]?.store_name}
            </Typography>
            <Typography style={{ color: 'black', fontSize: '.85rem' }}>{reviews[1]?.description}</Typography>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Typography className="signup-ols-purpose" style={{ fontSize: "2.5rem", color: 'black', width: '60%', margin: 'auto', textAlign: 'center' }}>
          <Typography style={{ fontWeight: "bold", fontSize: '2.5rem', color: 'black', display: 'inline-block', marginRight: '9px' }} component="span">
            Esplanda
          </Typography>
          Platform is Built to Help You Make More Money
        </Typography>
        <div style={{ display: "flex", justifyContent: 'center', marginTop: '15px' }}>
          <Button onClick={handleGetStarted} small style={{ marginRight: "10px" }} />
          <Button onClick={handleOurFeatures} small />
        </div>
      </div>
    </div>
  )
}

export default ClaimMyBusiness