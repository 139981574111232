import axios from '../utils/axiosConfig';
import {baseurl} from '../env';

export const getCategoryByID = async (params) => {
    try{
        let {data: {data}} = await axios.get(`/category/list/${params}`);
        return data;
    }catch(err){
        throw err;
    };
};