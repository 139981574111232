import axios from "../../utils/axiosConfig";
import Const from "../../components/helpers/const";

export function getDashboardList(params) {
    return (dispatch) => {
        if (localStorage.userToken) {
            //let url=Const.BASE_URL + `/dashboard/index?`;
            let url=params!=="" ? `/dashboard/index?${params}` : '/dashboard/index';
            dispatch({ type: "SET_DASHBOARD_LIST_LOADING" });
            return axios
                .get(url)
                .then((response) => {
                    dispatch({
                        type: "SET_DASHBOARD_LIST_LOADED",
                        payload: response.data
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({
                        type: "SET_DASHBOARD_LIST_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    }
}
