import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import storelist from "../../../assets/store-list.png";
import listicon from "../../../assets/list-text.png";
import brandingimg from "../../../assets/branding-logo.png";
import socialurlimg from "../../../assets/social-url.png";
import routePaths from '../../../config/route-paths';
const SubmitLogo = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={storelist} />
            </div>
            Store Details             
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.StoreDetails}>Store Details</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.FulfillmentMethods}>Fulfillment Methods</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.BankInformation}>Bank Information</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.LiquorLicense}>Business License</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.SubmitLogo} className={cn('selected')}>Submit Logo</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.POSIntegration}>POS Integration</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AccessYourWebsite}>Access Your Website</Link></li>
        </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Submit Logo
         </Typography>
       
         <p>
            
         <ol>
            <li>Go to the ‘<strong>My Store Settings</strong>’ section of the admin website.</li>

            <span className={cn('addnewproductsimg')}>
    <img src={brandingimg} />
    </span>

    <li>Click on the ‘<strong>Branding</strong>’ tab on the left.</li>
           

<li>Here you can upload an image of your store’s logo. This will be displayed at the top of your website, across all pages. If you do not
have a logo, you can simply check the box at the bottom of the page, and we will use a placeholder instead.
</li>

<span className={cn('addnewproductsimg')}>
    <img src={socialurlimg} />
    </span>
                  

    <li>If you have social media accounts associated with your store, click on ‘<strong>My Social Links</strong>’ on the right side of the page. Here you can link
up those accounts so when customers click on the social media links on your store website, they will be redirected to your
store’s respective social media accounts.</li>

<li>When you are finished, click ‘<strong>Save</strong>’ in the bottom-right corner.</li>
  
</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default SubmitLogo