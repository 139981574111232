import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { getVenuesByType } from "../../../../redux/actions/searchActions";
import LightTooltip from "../../../../util_comps/LightTooltip";

export default function Brewery(props) {
    //const [breweries,setBreweries] = useState([]);
    const dispatch = useDispatch((data) => data);
    const breweries = useSelector((state) => state.search.brewery);
    let mainCategory = "";
    switch (props.mainCategory) {
        case 1:
            mainCategory = "Brewery";
            break;
        case 2:
            mainCategory = "Winery";
            break;
        case 3:
            mainCategory = "Distillery";
            break;
        case 4:
            mainCategory = "Brewery";
            break;
        case 5:
            mainCategory = "Brewery";
            break;
        case 6:
            mainCategory = 'Brewery';
            break;
        default: mainCategory = 'Brewery'
    }
    useEffect(() => {
        dispatch(getVenuesByType(mainCategory, 1, ""));
    }, []);
    const searchBreweries = async (e) => {
        setTimeout(() => {
            dispatch(getVenuesByType(props.type, 1, e.target.value));
        }, 2000);
    };
    const handleRemoveBrewery = (value) => {
        let breweries = props.filters.breweries;
        let filteredBreweries = breweries.filter(
            (val) => val.VenueId !== value.VenueId
        );
        props.setFilters({ ...props.filters, breweries: filteredBreweries });
    };
    const handleSetBrewery = (value) => {
        let breweries = props.filters.breweries;
        breweries.push(value);
        props.setFilters({ ...props.filters, breweries: breweries });
    };
    return (
        <Accordion
            onChange={() => props.handlePanelOpen("brewery")}
            expanded={props.activePanel === "brewery"}
        >
            <AccordionSummary
                expandIcon={
                    props.activePanel === "brewery" ? (
                        <IndeterminateCheckBoxIcon style={{ fill: "black" }} />
                    ) : (
                        <AddBoxIcon style={{ fill: "black" }} />
                    )
                }
                aria-label="Expand"
                aria-controls="additional-actions3-content"
                id="additional-actions3-header"
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Only show products that are produced by a given brand(s).</span>} arrow placement="top">
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "16px",
                                color:
                                    JSON.stringify(
                                        props.initState.current.filters.breweries
                                    ) !== JSON.stringify(props.filters.breweries) &&
                                        props.activePanel !== "brewery"
                                        ? "red"
                                        : "black",
                            }}
                        >
                            Brand
                        </Typography>
                    </LightTooltip>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <SearchIcon />
                        <TextField
                            style={{ height: "40px" }}
                            inputProps={{ disableUnderline: true }}
                            onChange={searchBreweries}
                            id="outlined-search"
                            type="search"
                            variant="filled"
                        />
                    </div>
                    {breweries.map((value) => {
                        const labelId = `checkbox-list-label-${value.Name}`;
                        return (
                            <ListItem key={value.Name} button>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        //checked={}
                                        onChange={(e) =>
                                            e.target.checked
                                                ? handleSetBrewery(value)
                                                : handleRemoveBrewery(value)
                                        }
                                        inputProps={{
                                            "aria-labelledby": labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    style={{ lineHeight: 0 }}
                                    id={labelId}
                                    primary={value.Name}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </AccordionDetails>
        </Accordion>
    );
}
