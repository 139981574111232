import { Autocomplete, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { classname } from '../../../utils/bem'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import ValueTextField from '../../Library/ValueTextField/ValueTextField'
import './CouponsDialog.scss'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import _ from 'lodash'

const modalAutoCompleteOptions = [
    {
        title: 'Delivery'
    },
    {
        title: 'Pickup'
    },
    {
        title: 'Shipping'
    }
]


const CouponsDialog = ({
    modalForm,
    onClose,
    isEdit,
}) => {

    const cn = classname('coupons')
    const formik = useFormik({
        initialValues: {
            code: modalForm.couponCode || '',
            type: modalForm.discountType || '',
            value: modalForm.discountAmount || '',
            valid_from: modalForm.validFrom || '',
            valid_to: modalForm.validTo || '',
            limit: modalForm.orderLimit || '',
            min_purchase: modalForm.validAbove || '',
            valid_for: modalForm.couponFor ? (Array.isArray(modalForm.couponFor) ? modalForm.couponFor : modalForm.couponFor.split(',').map((data) => _.startCase(_.toLower(data)))) : [],
            first_purchase: modalForm.firstPurchase || false
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            let postValues = { ...values }
            postValues.valid_for = values.valid_for.map((option) => option?.title).toString().toLowerCase()
            axios.post(isEdit ? `/coupon/update/${modalForm.id}` : '/coupon/create', postValues)
                .then(() => {
                    toast.success(isEdit ? 'Coupon Updated' : 'Coupon Added')
                    onClose()
                })
                .catch((err) => {
                    if(err?.response?.data?.code?.length){
                        toast.error(err?.response?.data?.code[0])
                    }else{
                        toast.error(err?.response?.data?.message)
                    }
                })
        }
    })

    return (
        <>
            <DialogTitle
                align="center"
            >
                {isEdit ? `Edit ${modalForm.couponCode}` : "Add New Coupon"}
            </DialogTitle>
            <DialogContent>
                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 15,
                        width: 690
                    }}
                >
                    <div
                        className={cn('modal-form-section')}
                    >
                        <div
                            className={cn('modal-form-autocomplete-item-container')}
                        >
                            <Autocomplete
                                multiple
                                limitTags={3}
                                id={cn('autocomplete-input')}
                                options={modalAutoCompleteOptions}
                                getOptionLabel={(option) => option.title}
                                isOptionEqualToValue={(option, value) => option.title === value.title}
                                value={formik.values.valid_for}
                                onChange={(event, newValue) => {
                                    let newArray = []
                                    newArray.push(...newValue)
                                    const arrayUniqueByKey = [...new Map(newArray.map(item =>
                                        [item['title'], item])).values()];
                                    formik.setFieldValue('valid_for', arrayUniqueByKey)
                                }}
                                renderInput={(params) => {
                                    return (
                                        <GenericTextFieldWithLabel
                                            label="Coupon valid for"
                                            {...params}
                                            multiline={false}
                                            required={formik.values.valid_for?.length === 0}
                                            fullWidth={true}
                                            isSmallLabel={true}
                                        />
                                    )
                                }}
                                ChipProps={{
                                    className: cn('autocomplete-chip')
                                }}
                            />
                        </div>

                        <div
                            className={cn('modal-form-item-container')}
                        >
                            <GenericTextFieldWithLabel
                                id="code"
                                name="code"
                                label="Coupon Code"
                                placeholder="Type your coupon code"
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                required={true}
                                fullWidth={true}
                                isSmallLabel={true}
                            />
                        </div>
                    </div>

                    <div
                        className={cn('modal-form-section')}
                    >
                        <div
                            className={cn('modal-form-item-container')}
                        >
                            <GenericTextFieldWithLabel
                                label="Valid from"
                                id="valid_from"
                                name="valid_from"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formik.values.valid_from}
                                onChange={formik.handleChange}
                                required={true}
                                fullWidth={true}
                                isSmallLabel={true}
                            />

                        </div>

                        <div
                            className={cn('modal-form-item-container')}
                        >

                            <GenericTextFieldWithLabel
                                label="Valid to"
                                id="valid_to"
                                name="valid_to"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formik.values.valid_to}
                                onChange={formik.handleChange}
                                required={true}
                                fullWidth={true}
                                isSmallLabel={true}
                            />

                        </div>
                    </div>

                    <div
                        className={cn('modal-form-section')}
                    >
                        <div
                            className={cn('modal-form-item-container')}
                        >

                            <GenericSelectWithLabel
                                id="type"
                                name="type"
                                label="Discount Type"
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                isSmallLabel={true}
                            >
                                <MenuItem value="percentage">Percentage</MenuItem>
                                <MenuItem value="dollar">Dollar</MenuItem>
                            </GenericSelectWithLabel>
                        </div>

                        <div
                            className={cn('modal-form-item-container')}
                        >
                            <ValueTextField
                                id="value"
                                name="value"
                                label="Discount Amount"
                                type={formik.values.type}
                                value={formik.values.value}
                                onChange={formik.handleChange}
                                texttype="number"
                                required={true}
                                isSmallLabel={true}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            alignItems: 'flex-end'
                        }}
                        className={cn('modal-form-section')}
                    >
                        <div
                            className={cn('modal-form-item-container')}
                        >
                            <GenericTextFieldWithLabel
                                id="limit"
                                name="limit"
                                label={(
                                    <div>
                                        <div>
                                            Number of Uses
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 12
                                            }}
                                        >
                                            (leave empty for unlimited)
                                        </div>
                                    </div>
                                )}
                                value={formik.values.limit}
                                onChange={formik.handleChange}
                                type="number"
                                isSmallLabel={true}
                            />
                        </div>
                        <div
                            className={cn('modal-form-item-container')}
                        >
                            <ValueTextField
                                id="min_purchase"
                                name="min_purchase"
                                label={(
                                    <div>
                                        <div>
                                            Coupon only valid above
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 12
                                            }}
                                        >
                                            (if empty, then Assume 0, it is valid on every amount)
                                        </div>
                                    </div>
                                )}
                                value={formik.values.min_purchase}
                                onChange={formik.handleChange}
                                type="dollar"
                                texttype="number"
                                isSmallLabel={true}
                            />
                        </div>
                    </div>

                    <div
                        className={cn('modal-form-checkbox-section')}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.first_purchase}
                                    onChange={(event) => {
                                        formik.setFieldValue('first_purchase', event.target.checked)
                                    }}
                                    size="small"
                                />
                            }
                            label="Applicable for 1st purchase only"
                        />
                    </div>

                    <DialogActions>
                        <GenericButton
                            variant="contained"
                            type="submit"
                        >
                            {isEdit ? "Update" : "Add"}
                        </GenericButton>

                        <GenericButton
                            variant="contained"
                            btnColor="secondary"
                            type="button"
                            onClick={onClose}
                        >
                            Cancel
                        </GenericButton>
                    </DialogActions>

                </form>
            </DialogContent>
        </>
    )
}

export default CouponsDialog