import axios from "../../utils/axiosConfig";
import Const from "../../components/helpers/const";


export function getGiftCardList(params) {
    return (dispatch) => {
        if (localStorage.userToken) {
            let url = params !== "" ? `/cards/list?${params}` : '/cards/list';
            dispatch({ type: "SET_GIFT_CARD_LIST_LOADING" });
            return axios
                .get(url)
                .then((response) => {
                    dispatch({
                        type: "SET_GIFT_CARD_LIST_LOADED",
                        payload: response.data
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {
                    dispatch({
                        type: "SET_GIFT_CARD_LIST_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    }
};
export function updateGiftCardDetails(params, id) {
    return (dispatch) => {
        if (localStorage.userToken) {
            let url = `/cards/edit/${id}`;
            dispatch({ type: "SET_GIFT_CARD_DETAILS_LOADING" });
            return axios
                .post(url, params)
                .then((response) => {
                    dispatch({
                        type: "SET_GIFT_CARD_DETAILS_LOADED",
                        payload: response.data
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({
                        type: "SET_GIFT_CARD_DETAILS_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    }
}
export function addGiftCardDetail(params) {
    return (dispatch) => {
        if (localStorage.userToken) {
            let url = `/cards/create`;
            dispatch({ type: "ADD_GIFT_CARD_LOADING" });
            return axios
                .post(url, params)
                .then((response) => {
                    dispatch({
                        type: "ADD_GIFT_CARD_LOADED",
                        payload: response.data
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {

                    dispatch({
                        type: "ADD_GIFT_CARD_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    }
}
export function deleteGiftCardDetail(params) {
    return (dispatch) => {
        if (localStorage.userToken) {
            let url = `/cards/delete/${params}`;
            dispatch({ type: "DELETE_GIFT_CARD_LOADING" });
            return axios
                .delete(url)
                .then((response) => {
                    dispatch({
                        type: "DELETE_GIFT_CARD_LOADED",
                        payload: response.data
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({
                        type: "DELETE_GIFT_CARD_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    }
}
export function getGiftCardHistory(params) {
    return (dispatch) => {
        if (localStorage.userToken) {
            let url = params !== "" ? `/cards/gift-order?${params}` : '/cards/gift-order';
            dispatch({ type: "SET_GIFT_CARD_HISTORY_LOADING" });
            return axios
                .get(url)
                .then((response) => {
                    dispatch({
                        type: "SET_GIFT_CARD_HISTORY_LOADED",
                        payload: response.data
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({
                        type: "SET_GIFT_CARD_HISTORY_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    }
};
