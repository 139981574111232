import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import WebsiteEditorContext from '../../../../utils/context/websiteEditorContext'
import GenericSelectWithLabel from '../../CustomPageBuilder/Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { Collapse, MenuItem } from '@mui/material'
import TabSelector from '../../CustomPageBuilder/Library/TabSelector/TabSelector'
import ColorInputWithLabel from '../../CustomPageBuilder/Library/ColorInputWithLabel/ColorInputWithLabel'
import ImageUpload from '../../CustomPageBuilder/Library/ImageUpload/ImageUpload'
import SliderWithLabel from '../../CustomPageBuilder/Library/SliderWithLabel/SliderWithLabel'
import { debounce } from 'lodash'
import { resolveHeight } from '../../Util/helpers'
import { getInsideModel, handleInsideAttrChange } from '../../Util/helpers'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import GenericSwitchWithLabel from '../../CustomPageBuilder/Library/GenericSwitchWithLabel/GenericSwitchWithLabel'
import GenericTextFieldWithLabel from '../../CustomPageBuilder/Library/GenericTextFieldWithLabel/GenericTextFieldWithLabel'

const SpacingOptions = [
  {
    value: '0',
    label: 'None'
  },
  {
    value: '15',
    label: 'Small'
  },
  {
    value: '25',
    label: 'Medium'
  },
  {
    value: '35',
    label: 'Large'
  },
  {
    value: '100',
    label: 'X Large'
  }
]

const StyleResolver = () => {

  const { selectedModel, pages } = useContext(WebsiteEditorContext)
  const resolveHeightTimer = useRef()
  const imageRef = useRef()

  const [model, setModel] = useState()
  const [traits, setTraits] = useState([])
  const [attributes, setAttributes] = useState({})
  const [showStates, setShowStates] = useState({})
  const [styles, setStyles] = useState({})
  const [isReact, setIsReact] = useState(false)

  const [openRte, setOpenRte] = useState(false)
  const [editorState, setEditorState] = useState('');
  const [convertedContent, setConvertedContent] = useState(null);
  const [rteInput, setRteInput] = useState('')

  useEffect(() => {
    if (selectedModel) {
      console.log({ selectedModel })

      const attr = selectedModel?.getAttributes()
      const style = selectedModel?.getStyle()
      setStyles(style)

      let insideModel
      let isReactComp
      let insideAttr
      try {
        insideModel = getInsideModel(selectedModel)
        insideAttr = insideModel?.getAttributes()
        console.log({ insideAttr })
        isReactComp = insideAttr?.is_react
        setIsReact(isReactComp)
      } catch (error) {
        console.log(error)
      }

      if (isReactComp) {
        const insideTraits = JSON.parse(JSON.stringify(insideModel?.getTraits() || []))
        console.log({ insideAttr })
        setAttributes(insideAttr)
        setTraits(insideTraits)
        setModel(insideModel)
        resolveHeight(selectedModel, resolveHeightTimer)
      } else {
        const mainTraits = JSON.parse(JSON.stringify(selectedModel?.getTraits() || []))
        setAttributes(attr)
        setTraits(mainTraits)
        setModel(selectedModel)
      }

      console.log({ attr })
    }
  }, [selectedModel])

  useEffect(() => {
    if (model && attributes) {
      resolveHeight(model, resolveHeightTimer)
    }
  }, [attributes, model])

  const updateSelectedStyle = (key, value) => {
    const attr = selectedModel?.getStyle()
    console.log({ attr, key, value })
    selectedModel.setStyle({
      ...attr,
      [key]: value
    })
  }

  const handleSliderUpdate = (key, newValue) => {
    updateSelectedStyle(key, newValue)
    setAttributes((prevState) => ({
      ...prevState,
      [key]: newValue
    }))
  }
  const debounceSliderUpdate = useCallback(debounce(handleSliderUpdate, 1000), [])

  return (
    <div
      className="tw-flex tw-flex-col tw-gap-2"
    >

      {traits?.map?.((trait) => {
        switch (trait.name) {
          case 'sticky':
            return (
              <GenericSwitchWithLabel
                label="Sticky Header"
                // description="Display menu links on your website"
                height={18}
                width={36}
                thumb={13}
                translateX={18}
                checked={attributes?.is_sticky}
                onChange={(event) => {
                  setAttributes((prevState) => ({
                    ...prevState,
                    is_sticky: Boolean(event.target.checked)
                  }))
                  handleInsideAttrChange({ model, key: 'is_sticky', value: Boolean(event.target.checked) ? 'true' : '', isOutside: isReact })
                  const modelStyle = model?.getStyle()
                  let newModelStyle = getComputedStyle(model.getEl())
                  if (Boolean(event.target.checked)) {
                    model.setStyle({
                      'position': 'fixed',
                      'width': '100%',
                      'z-index': '1000',
                      'background-color': 'inherit'
                    })

                    selectedModel.setStyle({
                      ...modelStyle,
                      height: newModelStyle.height,
                    })
                  } else {
                    model.setStyle({
                      'display': 'block',
                    })

                    selectedModel.setStyle({
                      ...modelStyle,
                      height: newModelStyle.height,
                    })
                  }
                }}
              />
            )
          case 'bg_color':
            return (
              <ColorInputWithLabel
                title="Background color"
                value={attributes?.bg_color}
                onChange={(newValue) => {
                  setAttributes((prevState) => ({
                    ...prevState,
                    bg_color: newValue
                  }))
                  handleInsideAttrChange({ model, key: 'bg_color', value: newValue, isOutside: isReact })
                }}
              />
            )
          case 'color':
            return (
              <ColorInputWithLabel
                title="Color"
                value={attributes?.color}
                onChange={(newValue) => {
                  setAttributes((prevState) => ({
                    ...prevState,
                    color: newValue
                  }))
                  handleInsideAttrChange({ model, key: 'color', value: newValue, isOutside: isReact })
                }}
              />
            )
          case 'max_width':
            return (
              <GenericTextFieldWithLabel
                variant="outlined"
                label="Max Width"
                placeholder="100%"
                value={attributes?.max_width}
                onChange={(event) => {
                  setAttributes((prevState) => ({
                    ...prevState,
                    max_width: event.target.value
                  }))
                  handleInsideAttrChange({ model, key: 'max_width', value: event.target.value, isOutside: isReact })
                }}
                fullWidth={true}
              />
            )
          case 'spacing':
            return (
              <div
                className="tw-border-b tw-border-b-[#D3D3D3]"
                style={{
                  paddingBottom: showStates['spacing'] ? 10 : 0
                }}
              >
                <div
                  className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
                  onClick={() => {
                    setShowStates((prevState) => ({
                      ...prevState,
                      'spacing': !prevState['spacing']
                    }))
                  }}
                >
                  <div
                    className="tw-font-semibold tw-text-base"
                  >
                    Spacing
                  </div>

                  {showStates['spacing'] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>
                <Collapse
                  in={showStates['spacing']}
                  unmountOnExit
                >
                  <div
                    className="tw-flex tw-flex-col tw-gap-4"
                  >
                    <GenericSelectWithLabel
                      label="Top"
                      colorType="secondary"
                      value={attributes?.spacing_top}
                      onChange={(event) => {

                        setAttributes((prevState) => ({
                          ...prevState,
                          spacing_top: event.target.value
                        }))
                        handleInsideAttrChange({ model, key: 'spacing_top', value: event.target.value, isOutside: isReact })

                      }}
                      fullWidth={true}
                    >
                      {SpacingOptions?.map((option) => {
                        return (
                          <MenuItem
                            key={`${option.label}-spacing_top`}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        )
                      })}
                    </GenericSelectWithLabel>

                    <GenericSelectWithLabel
                      label="Bottom"
                      colorType="secondary"
                      value={attributes?.spacing_bottom}
                      onChange={(event) => {
                        setAttributes((prevState) => ({
                          ...prevState,
                          spacing_bottom: event.target.value
                        }))
                        handleInsideAttrChange({ model, key: 'spacing_bottom', value: event.target.value, isOutside: isReact })
                      }}
                      fullWidth={true}
                    >
                      {SpacingOptions?.map((option) => {
                        return (
                          <MenuItem
                            key={`${option.label}-spacing_bottom`}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        )
                      })}
                    </GenericSelectWithLabel>
                  </div>
                </Collapse>
              </div>
            )
          case 'layout':
            return (
              <div
                className="tw-border-b tw-border-b-[#D3D3D3]"
                style={{
                  paddingBottom: showStates['layout'] ? 10 : 0
                }}
              >
                <div
                  className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
                  onClick={() => {
                    setShowStates((prevState) => ({
                      ...prevState,
                      'layout': !prevState['layout']
                    }))
                  }}
                >
                  <div
                    className="tw-font-semibold tw-text-base"
                  >
                    Navigation Layout
                  </div>

                  {showStates['layout'] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>
                <Collapse
                  in={showStates['layout']}
                  unmountOnExit
                >
                  <div
                    className="tw-flex tw-flex-col tw-gap-4"
                  >
                    <GenericSelectWithLabel
                      label="Logo style"
                      value={attributes?.logo_style}
                      colorType="secondary"
                      onChange={(event) => {
                        setAttributes((prevState) => ({
                          ...prevState,
                          logo_style: event.target.value
                        }))
                        handleInsideAttrChange({ model: model, key: 'logo_style', value: event.target.value, isOutside: isReact })
                      }}
                      fullWidth={true}
                    >
                      <MenuItem
                        value="logoleftnavleft"
                      >
                        Logo Left + Nav Left
                      </MenuItem>
                      <MenuItem
                        value="logoleftnavright"
                      >
                        Logo Left + Nav Right
                      </MenuItem>
                      <MenuItem
                        value="logoleftnavcenter"
                      >
                        Logo Left + Nav Center
                      </MenuItem>
                      <MenuItem
                        value="logocenternavleft"
                      >
                        Logo center + Nav Left
                      </MenuItem>
                    </GenericSelectWithLabel>
                  </div>
                </Collapse>
              </div>
            )
          case 'width':
            return (
              <div
                className="tw-border-b tw-border-b-[#D3D3D3]"
                style={{
                  paddingBottom: showStates['width'] ? 10 : 0
                }}
              >
                <div
                  className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
                  onClick={() => {
                    setShowStates((prevState) => ({
                      ...prevState,
                      'width': !prevState['width']
                    }))
                  }}
                >
                  <div
                    className="tw-font-semibold tw-text-base"
                  >
                    Width
                  </div>

                  {showStates['width'] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>
                <Collapse
                  in={showStates['width']}
                  unmountOnExit
                >
                  <div
                    className="tw-flex tw-flex-col tw-gap-4"
                  >
                    <TabSelector
                      onChange={(value) => {
                        setAttributes((prevState) => ({
                          ...prevState,
                          width: value
                        }))
                        handleInsideAttrChange({ model: selectedModel, key: 'width', value: value, isOutside: isReact })
                      }}
                      value={attributes?.width}
                      tabs={[
                        {
                          label: 'Full',
                          value: 'full'
                        },
                        {
                          label: 'Inset',
                          value: 'inset'
                        }
                      ]}
                    />
                  </div>
                </Collapse>
              </div>
            )
          case 'social':
            return (
              <div
                className="tw-border-b tw-border-b-[#D3D3D3]"
                style={{
                  paddingBottom: showStates['social_icons'] ? 10 : 0
                }}
              >
                <div
                  className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
                  onClick={() => {
                    setShowStates((prevState) => ({
                      ...prevState,
                      'social_icons': !prevState['social_icons']
                    }))
                  }}
                >
                  <div
                    className="tw-font-semibold tw-text-base"
                  >
                    Social Icons
                  </div>

                  {showStates['social_icons'] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>
                <Collapse
                  in={showStates['social_icons']}
                  unmountOnExit
                >
                  <div
                    className="tw-flex tw-flex-col tw-gap-4"
                  >
                    <TabSelector
                      title="Icon size"
                      onChange={(value) => {
                        setAttributes((prevState) => ({
                          ...prevState,
                          social_icon_size: value
                        }))
                        handleInsideAttrChange({ model: model, key: 'social_icon_size', value: value, isOutside: isReact })
                      }}
                      value={attributes?.social_icon_size}
                      tabs={[
                        {
                          label: 'Small',
                          value: '20'
                        },
                        {
                          label: 'Medium',
                          value: '25'
                        },
                        {
                          label: 'Large',
                          value: '30'
                        }
                      ]}
                    />

                    <ColorInputWithLabel
                      title="Social icon color"
                      value={attributes?.social_icon_color}
                      onChange={(newValue) => {
                        setAttributes((prevState) => ({
                          ...prevState,
                          social_icon_color: value
                        }))
                        handleInsideAttrChange({ model: model, key: 'social_icon_color', value: newValue, isOutside: isReact })

                      }}
                    />
                  </div>
                </Collapse>
              </div>
            )
          case 'background':
            return (
              <div
                className="tw-border-b tw-border-b-[#D3D3D3]"
                style={{
                  paddingBottom: showStates['background'] ? 10 : 0
                }}
              >
                <div
                  className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
                  onClick={() => {
                    setShowStates((prevState) => ({
                      ...prevState,
                      'background': !prevState['background']
                    }))
                  }}
                >
                  <div
                    className="tw-font-semibold tw-text-base"
                  >
                    Background
                  </div>

                  {showStates['background'] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>

                <Collapse
                  in={showStates['background']}
                  unmountOnExit
                >
                  <div
                    className="tw-flex tw-flex-col tw-gap-4"
                  >
                    <TabSelector
                      title="Image fit"
                      onChange={(value) => {
                        if (value === 'color') {
                          updateSelectedStyle('background-image', '')
                          imageRef.current?.imageDelete()
                        } else {
                          updateSelectedStyle('background-color', '')
                          setAttributes((prevState) => ({
                            ...prevState,
                            background_color: ''
                          }))
                        }

                        setAttributes((prevState) => ({
                          ...prevState,
                          background_type: value
                        }))
                      }}
                      value={attributes?.background_type}
                      tabs={[
                        {
                          label: 'Image',
                          value: 'image'
                        },
                        {
                          label: 'Color',
                          value: 'color'
                        }
                      ]}
                    />

                    {attributes?.background_type === 'image' ? (
                      <ImageUpload
                        title="Background Image"
                        imageSrc={attributes?.background_image}
                        setImageSrc={(val) => {
                          setAttributes((prevState) => ({
                            ...prevState,
                            background_image: val
                          }))
                        }}
                        onUpload={(link) => {
                          updateSelectedStyle('background-image', `url(${link})`)
                          resolveHeight(model, resolveHeightTimer)
                        }}
                        onDelete={() => {
                          updateSelectedStyle('background-image', '')
                          resolveHeight(model, resolveHeightTimer)
                        }}
                      />
                    ) : (
                      <ColorInputWithLabel
                        title="Background color"
                        value={attributes?.background_color}
                        onChange={(newValue) => {
                          updateSelectedStyle('background-color', newValue)
                          setAttributes((prevState) => ({
                            ...prevState,
                            background_color: newValue
                          }))
                        }}
                      />
                    )}

                  </div>
                </Collapse>
              </div>
            )
          case 'color':
            return (
              <div
                className="tw-border-b tw-border-b-[#D3D3D3]"
                style={{
                  paddingBottom: showStates['color'] ? 10 : 0
                }}
              >
                <div
                  className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
                  onClick={() => {
                    setShowStates((prevState) => ({
                      ...prevState,
                      'color': !prevState['color']
                    }))
                  }}
                >
                  <div
                    className="tw-font-semibold tw-text-base"
                  >
                    Color
                  </div>

                  {showStates['color'] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>

                <Collapse
                  in={showStates['color']}
                  unmountOnExit
                >
                  <div
                    className="tw-flex tw-flex-col tw-gap-4"
                  >
                    <ColorInputWithLabel
                      title="color"
                      value={attributes?.color}
                      onChange={(newValue) => {
                        updateSelectedStyle('color', newValue)
                        setAttributes((prevState) => ({
                          ...prevState,
                          color: newValue
                        }))
                      }}
                    />

                  </div>
                </Collapse>
              </div>
            )
          case 'textbox':
            return (
              <div
                className="tw-border-b tw-border-b-[#D3D3D3]"
                style={{
                  paddingBottom: showStates['textbox'] ? 10 : 0
                }}
              >
                <div
                  className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
                  onClick={() => {
                    setShowStates((prevState) => ({
                      ...prevState,
                      'textbox': !prevState['textbox']
                    }))
                  }}
                >
                  <div
                    className="tw-font-semibold tw-text-base"
                  >
                    Text box
                  </div>

                  {showStates['textbox'] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>
                <Collapse
                  in={showTextBox}
                  unmountOnExit
                >
                  <div
                    className="tw-flex tw-flex-col tw-gap-4"
                  >

                    <SliderWithLabel
                      title="Text box round corners"
                      colorType="secondary"
                      value={attributes?.textboxRound}
                      handleChange={(event, newValue) => debounceSliderUpdate('textbox_round', newValue)}
                    />

                    <ColorInputWithLabel
                      title="Text box background color"
                      value={attributes?.textbox_background_color}
                      onChange={(newValue) => {
                        debounceSliderUpdate('textbox_background_color', newValue)
                      }}
                    />

                    <ColorInputWithLabel
                      title="Text color"
                      value={attributes?.textbox_color}
                      onChange={(newValue) => {
                        debounceSliderUpdate('textbox_color', newValue)
                      }}
                    />
                  </div>
                </Collapse>
              </div>
            )
          // case 'logo':
          //   return (
          //     <div
          //       className="tw-border-b tw-border-b-[#D3D3D3]"
          //       style={{
          //         paddingBottom: showImage ? 10 : 0
          //       }}
          //     >
          //       <div
          //         className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
          //         onClick={() => {
          //           setShowImage((prevState) => !prevState)
          //         }}
          //       >
          //         <div
          //           className="tw-font-semibold tw-text-base"
          //         >
          //           Image
          //         </div>

          //         {showImage ? (
          //           <KeyboardArrowUp />
          //         ) : (
          //           <KeyboardArrowDown />
          //         )}
          //       </div>
          //       <Collapse
          //         in={showImage}
          //         unmountOnExit
          //       >
          //         <div
          //           className="tw-flex tw-flex-col tw-gap-4"
          //         >
          //           <TabSelector
          //             title="Image fit"
          //             onChange={(value) => {
          //               handleInsideAttrChange({ model: model, key: 'image_fit', value: value })
          //               setImageFit(value)
          //             }}
          //             value={imageFit}
          //             tabs={[
          //               {
          //                 label: 'Contain',
          //                 value: 'contain'
          //               },
          //               {
          //                 label: 'Cover',
          //                 value: 'cover'
          //               }
          //             ]}
          //           />

          //           <SliderWithLabel
          //             title="Round corners"
          //             colorType="secondary"
          //             value={imageRound}
          //             handleChange={handleRoundCornersChange}
          //           />
          //         </div>
          //       </Collapse>
          //     </div>
          //   )
          // case 'image':
          //   return (
          //     <div
          //       className="tw-border-b tw-border-b-[#D3D3D3]"
          //       style={{
          //         paddingBottom: showImage ? 10 : 0
          //       }}
          //     >
          //       <div
          //         className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
          //         onClick={() => {
          //           setShowImage((prevState) => !prevState)
          //         }}
          //       >
          //         <div
          //           className="tw-font-semibold tw-text-base"
          //         >
          //           Image
          //         </div>

          //         {showImage ? (
          //           <KeyboardArrowUp />
          //         ) : (
          //           <KeyboardArrowDown />
          //         )}
          //       </div>
          //       <Collapse
          //         in={showImage}
          //         unmountOnExit
          //       >
          //         <div
          //           className="tw-flex tw-flex-col tw-gap-4"
          //         >
          //           <TabSelector
          //             title="Image fit"
          //             onChange={(value) => {
          //               handleInsideAttrChange({ model: model, key: 'image_fit', value: value })
          //               setImageFit(value)
          //             }}
          //             value={imageFit}
          //             tabs={[
          //               {
          //                 label: 'Contain',
          //                 value: 'contain'
          //               },
          //               {
          //                 label: 'Cover',
          //                 value: 'cover'
          //               }
          //             ]}
          //           />

          //           <SliderWithLabel
          //             title="Round corners"
          //             colorType="secondary"
          //             value={imageRound}
          //             handleChange={handleRoundCornersChange}
          //           />

          //           <SliderWithLabel
          //             title="Item round corners"
          //             colorType="secondary"
          //             value={itemRound}
          //             handleChange={handleItemRoundCornersChange}
          //           />


          //           <ColorInputWithLabel
          //             title="Item background color"
          //             value={itemBackground}
          //             onChange={(newValue) => {
          //               setItemBackground(newValue)
          //               handleInsideAttrChange({ model: model, key: 'item_background_color', value: newValue })
          //             }}
          //           />
          //         </div>
          //       </Collapse>
          //     </div>
          //   )
          // case 'items':
          //   return (
          //     <>
          //       {
          //         trait.hidestyles !== undefined && trait.hidestyles === true ? <></> : <div
          //           className="tw-border-b tw-border-b-[#D3D3D3]"
          //           style={{
          //             paddingBottom: showGalleryItem ? 10 : 0
          //           }}
          //         >
          //           <div
          //             className="tw-flex tw-justify-between tw-items-center tw-py-[10px] tw-cursor-pointer"
          //             onClick={() => {
          //               setShowGalleryItem((prevState) => !prevState)
          //             }}
          //           >
          //             <div
          //               className="tw-font-semibold tw-text-base"
          //             >
          //               {trait.label}
          //             </div>

          //             {showGalleryItem ? (
          //               <KeyboardArrowUp />
          //             ) : (
          //               <KeyboardArrowDown />
          //             )}
          //           </div>
          //           <Collapse
          //             in={showGalleryItem}
          //             unmountOnExit
          //           >
          //             <div
          //               className="tw-flex tw-flex-col tw-gap-4"
          //             >
          //               <TabSelector
          //                 title="Image fit"
          //                 onChange={(value) => {
          //                   handleInsideAttrChange({ model: model, key: 'image_fit', value: value })
          //                   setImageFit(value)
          //                 }}
          //                 value={imageFit}
          //                 tabs={[
          //                   {
          //                     label: 'Contain',
          //                     value: 'contain'
          //                   },
          //                   {
          //                     label: 'Cover',
          //                     value: 'cover'
          //                   }
          //                 ]}
          //               />

          //               <SliderWithLabel
          //                 title="Image Round corners"
          //                 colorType="secondary"
          //                 value={imageRound}
          //                 handleChange={handleRoundCornersChange}
          //               />

          //               <SliderWithLabel
          //                 title="Item round corners"
          //                 colorType="secondary"
          //                 value={itemRound}
          //                 handleChange={handleItemRoundCornersChange}
          //               />


          //               <ColorInputWithLabel
          //                 title="Item background color"
          //                 value={itemBackground}
          //                 onChange={(newValue) => {
          //                   setItemBackground(newValue)
          //                   handleInsideAttrChange({ model: model, key: 'item_background_color', value: newValue })
          //                 }}
          //               />
          //             </div>
          //           </Collapse>
          //         </div>
          //       }
          //     </>
          //   )
          // case 'button':
          //   return (
          //     <ButtonStyleEdit {...{ buttonStylesKey: 'button_styles', buttonStyles, setButtonStyles, resolveHeightTimer, model }} />
          //   )
          // case 'button2':
          //   return (
          //     <ButtonStyleEdit {...{ title: 'Button 2', buttonStylesKey: 'button_styles2', buttonStyles: buttonStyles2, setButtonStyles: setButtonStyles2, resolveHeightTimer, model }} />
          //   )
          // case 'esplanda-signup-button':
          //   return (
          //     <ButtonStyleEdit {...{ buttonStylesKey: 'button_styles', hidePosition: true, buttonStyles, setButtonStyles, resolveHeightTimer, model }} />
          //   )
          // case 'item-button':
          //   return items?.map((item, index) => item.itemIsButton && (
          //     <ButtonStyleItem {...{ item, setItems, index, items, resolveHeightTimer, model }} />
          //   ))
        }
      })}

    </div>
  )
}

export default StyleResolver