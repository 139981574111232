import React from 'react';
import { classname, cns } from '../../../utils/bem';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import './Loader.scss'

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.number
}

const defaultProps = {
    className: '',
    size: 40
}

function Loader(props) {

    const cn = classname('loader')

    return (
        <div
            className={cns(
                cn(),
                props.className,
            )}
        >
            <CircularProgress
                size={props.size}
            />
        </div>
    )
}

Loader.defaultProps = defaultProps;
Loader.propTypes = propTypes;

export default Loader
