import React, { useEffect } from "react";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import FreshChatWidget from "./components/FreshChatWidget/FreshChatWidget";
import SwiperCore, { Navigation, Autoplay, Lazy, Pagination } from "swiper";

import "./App.scss";
import "./grapesjssystem.css";
import "./grapesjs.css";
import "reactjs-freshchat/dist/index.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import 'https://wb.esplanda.com/grapeseditor.scss';

import { ToastContainer, toast } from "react-toastify";
import RoutesArr from "./config/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js';
import { ConfirmProvider } from "material-ui-confirm";
import Const from "./components/helpers/const";
import { AppProvider } from "./utils/context/appContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);

SwiperCore.use([Navigation, Autoplay, Lazy, Pagination]);

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}



const Fingerprint2 = require("fingerprintjs2");

Fingerprint2.get(function (components) {
  var values = components.map(function (component) {
    return component.value;
  });
  var device_token = Fingerprint2.x64hash128(values.join(""), 31);
  localStorage.setItem("device_token", device_token);
});

const App = () => {
  console.log(process.env)
  const user = useSelector((store) => store.user);

  useEffect(() => {
    document.head.setAttribute('biz_base_url', process.env.REACT_APP_API_STORE_URL)
    document.head.setAttribute('fe_base_url', process.env.REACT_APP_API_FE_STORE_URL)
    document.body.setAttribute('is_builder', true)
    document.head.setAttribute('stripe_key', process.env.REACT_APP_STRIPE_KEY)
    document.head.setAttribute('biz_base_url', process.env.REACT_APP_API_STORE_URL)
    document.head.setAttribute('stripe_key', process.env.REACT_APP_STRIPE_KEY)
  }, [])

  useEffect(() => {



    if (user.isLoggedIn) {
      // console.log('M here app js page 01', localStorage.getItem('userToken'));
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ userToken: localStorage.getItem('userToken') }));
    }


  }, [user.isLoggedIn])

  useEffect(() => {

    window.addEventListener('message', (event) => {
      // toast.success('app ver recived 01', event.data?.appVersion);
      if (event.data?.appVersion !== undefined) {
        //toast.success('app ver recived', event.data?.appVersion);
        localStorage.setItem("app_ver_for_app", event.data?.appVersion);
      }

    });
    window?.ReactNativeWebView?.postMessage(JSON.stringify({ getDeviceType: 'getDeviceType' }));

  }, []);

  return (
    <div>
      <AppProvider
        value={{
          user: user
        }}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <ConfirmProvider
          defaultOptions={{
            confirmationText: "OK",
            confirmationButtonProps: {
              variant: 'contained',
              style: {
                backgroundColor: 'black',
                color: 'white'
              }
            },
            cancellationButtonProps: {
              variant: 'contained',
              style: {
                backgroundColor: '#dddddd',
                color: 'black'
              }
            }
          }}
        >
          <RouterProvider router={RoutesArr()} />
          {/* <RoutesArr /> */}
        </ConfirmProvider>
      </AppProvider>
    </div>
  );
};
export default App;
