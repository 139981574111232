import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import TagsInput from '../../Library/TagsInput/TagsInput'
import styles from './SendTestEmailDialog.module.scss'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'

const SendTestEmailDialog = (props) => {

    const [emails, setEmails] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSelecetedTags = (items) => {
        setEmails(items);
    }

    const handleSave = () => {

        let emailParam = []
        if (emails.length) {
            emailParam = emails
        } else {
            if (!(/\S+@\S+\.\S+/.test(inputvalue?.trim()))) {
                toast.error('Please enter a valid email')
                return
            }else{
                emailParam = [inputvalue?.trim()]
            }
        }

        setIsLoading(true)
        axios.post('/mailchimp-campaign/send-test-email', {
            campaign_id: props.id,
            email: emailParam
        })
            .then(() => {
                toast.success('Test email sent')
                props.onClose()
            })
            .catch(()=>{
                toast.error('Somethiing went wrong')
            })
            .finally(()=>{
                setIsLoading(false)
            })
    }

    return (
        <>
            <DialogTitle>
                Send Test Email
            </DialogTitle>

            <DialogContent
            style={{
                minWidth: 450
            }}
            >
                <TagsInput
                    selectedTags={handleSelecetedTags}
                    fullWidth={true}
                    placeholder="Enter Emails"
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            </DialogContent>

            <DialogActions>
                <GenericButton
                    variant="contained"
                    btnColor="secondary"
                    onClick={props.onClose}
                >
                    Cancel
                </GenericButton>

                <GenericLoadingButton
                    variant="contained"
                    onClick={handleSave}
                    loading={isLoading}
                    loadingPosition="end"
                >
                    Save
                </GenericLoadingButton>
            </DialogActions>
        </>
    )
}

export default SendTestEmailDialog