import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './ImageTool.module.scss';

import Option from './Option';
import Spinner from './Spinner';
import { Button } from '@mui/material';
import GenericTextFieldWithLabel from '../../../../../Library/GenericTextFieldWithLabel/GenericTextFieldWithLabel';
import LinkSelector from '../../../../../Library/LinkSelector/LinkSelector';
import { AtomicBlockUtils, EditorState, SelectionState } from 'draft-js';

class ImageTool extends Component {

    state = {
        imgSrc: '',
        href: '',
        dragEnter: false,
        uploadHighlighted:
            this.props.config.uploadEnabled && !!this.props.config.uploadCallback,
        showImageLoading: false,
        height: 'auto',
        width: 'auto',
        alt: '',
    };

    componentDidUpdate(prevProps) {
        const { config } = this.props;
        if (prevProps.expanded && !this.props.expanded) {
            this.setState({
                imgSrc: '',
                href: '',
                dragEnter: false,
                uploadHighlighted: config.uploadEnabled && !!config.uploadCallback,
                showImageLoading: false,
                height: 'auto',
                width: 'auto',
                alt: '',
            });
        } else if (
            config.uploadCallback !== prevProps.config.uploadCallback ||
            config.uploadEnabled !== prevProps.config.uploadEnabled
        ) {
            this.setState({
                uploadHighlighted: config.uploadEnabled && !!config.uploadCallback,
            });
        }
    }

    onDragEnter = event => {
        this.stopPropagation(event);
        this.setState({
            dragEnter: true,
        });
    };

    onImageDrop = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            dragEnter: false,
        });

        // Check if property name is files or items
        // IE uses 'files' instead of 'items'
        let data;
        let dataIsItems;
        if (event.dataTransfer.items) {
            data = event.dataTransfer.items;
            dataIsItems = true;
        } else {
            data = event.dataTransfer.files;
            dataIsItems = false;
        }
        for (let i = 0; i < data.length; i += 1) {
            if (
                (!dataIsItems || data[i].kind === 'file') &&
                data[i].type.match('^image/')
            ) {
                const file = dataIsItems ? data[i].getAsFile() : data[i];
                this.uploadImage(file);
            }
        }
    };

    showImageUploadOption = () => {
        this.setState({
            uploadHighlighted: true,
        });
    };

    addImageFromState = () => {
        console.log(this.props)
        const {
            config: {
                alt: {
                    present: getEditorState,
                    mandatory: setEditorState,
                }
            },
        } = this.props;

        const { imgSrc, alt, href } = this.state;
        let { height, width } = this.state;
        const { onChange } = this.props;
        if (!isNaN(height)) {
            height += 'px';
        }
        if (!isNaN(width)) {
            width += 'px';
        }

        onChange(imgSrc, height, width, alt);

        const entityData = { src: imgSrc, height, width, alt, href };
        console.log(entityData)
        // if (config.alt.present) {
        //     entityData.alt = alt;
        // }
        const editorState = getEditorState()




        const content = editorState.getCurrentContent();
        const blockMap = content.getBlockMap();
        const key = blockMap.last().getKey();
        const length = blockMap.last().getLength();

        // Create a new selection state positioned at the very end of the editor content
        // const selection = new SelectionState({
        //     anchorKey: key,
        //     anchorOffset: length,
        //     focusKey: key,
        //     focusOffset: length,
        // });

        const selection = editorState.getSelection();

        // Move the selection to the specified character offset
        const newSelection = selection.merge({
            anchorOffset: 1,
            focusOffset: 1
        });

        // Force the editor to focus on this new selection
        const editorStateAtEnd = EditorState.forceSelection(editorState, newSelection);



        const contentState = editorStateAtEnd.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'MUTABLE',
            entityData
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorStateAtEnd, { currentContent: contentStateWithEntity });

        const newEditorState2 = AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            ' '
        );

        setEditorState(newEditorState2)
    };

    showImageURLOption = () => {
        this.setState({
            uploadHighlighted: false,
        });
    };

    toggleShowImageLoading = () => {
        const showImageLoading = !this.state.showImageLoading;
        this.setState({
            showImageLoading,
        });
    };

    updateValue = event => {
        this.setState({
            [`${event.target.name}`]: event.target.value,
        });
    };

    selectImage = event => {
        if (event.target.files && event.target.files.length > 0) {
            this.uploadImage(event.target.files[0]);
        }
    };

    uploadImage = file => {
        this.toggleShowImageLoading();
        const { uploadCallback } = this.props.config;
        uploadCallback(file)
            .then(({ data }) => {
                this.setState({
                    showImageLoading: false,
                    dragEnter: false,
                    imgSrc: data.link || data.url,
                });
                this.fileUpload = false;
            })
            .catch(() => {
                this.setState({
                    showImageLoading: false,
                    dragEnter: false,
                });
            });
    };

    fileUploadClick = event => {
        this.fileUpload = true;
        event.stopPropagation();
    };

    stopPropagation = event => {
        if (!this.fileUpload) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            this.fileUpload = false;
        }
    };

    renderAddImageModal() {
        const {
            imgSrc,
            href,
            uploadHighlighted,
            showImageLoading,
            dragEnter,
            height,
            width,
            alt,
        } = this.state;
        const {
            config: {
                popupClassName,
                className, // evternalLinkOnly
                uploadCallback,
                uploadEnabled,
                urlEnabled,
                previewImage,
                inputAccept,
                defaultSize: {
                    height: pages
                },
            },
            doCollapse,
            translations,
        } = this.props;
        return (
            <div
                className={classNames('rdw-image-modal', popupClassName)}
                onClick={this.stopPropagation}
            >
                <div className="rdw-image-modal-header">
                    {uploadEnabled && uploadCallback && (
                        <span
                            onClick={this.showImageUploadOption}
                            className="rdw-image-modal-header-option"
                        >
                            {translations['components.controls.image.fileUpload']}
                            <span
                                style={{
                                    width: '100%'
                                }}
                                className={classNames('rdw-image-modal-header-label', {
                                    'rdw-image-modal-header-label-highlighted': uploadHighlighted,
                                })}
                            />
                        </span>
                    )}
                    {urlEnabled && (
                        <span
                            onClick={this.showImageURLOption}
                            className="rdw-image-modal-header-option"
                        >
                            {translations['components.controls.image.byURL']}
                            <span
                                style={{
                                    width: '100%'
                                }}
                                className={classNames('rdw-image-modal-header-label', {
                                    'rdw-image-modal-header-label-highlighted': !uploadHighlighted,
                                })}
                            />
                        </span>
                    )}
                </div>
                {uploadHighlighted ? (
                    <div onClick={this.fileUploadClick}>
                        <div
                            style={{
                                backgroundColor: 'white',
                                margin: 0
                            }}
                            onDragEnter={this.onDragEnter}
                            onDragOver={this.stopPropagation}
                            onDrop={this.onImageDrop}
                            className={classNames('rdw-image-modal-upload-option', {
                                'rdw-image-modal-upload-option-highlighted': dragEnter,
                            })}
                        >
                            <label
                                htmlFor="file"
                                className="rdw-image-modal-upload-option-label"
                            >
                                {previewImage && imgSrc ? (
                                    <img
                                        src={imgSrc}
                                        alt={imgSrc}
                                        className="rdw-image-modal-upload-option-image-preview"
                                    />
                                ) : (
                                    imgSrc || (
                                        <div
                                            className={styles.add_image_container}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <img
                                                    src="https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/addimage.png-1689616360.png"
                                                    height={30}
                                                    width={30}
                                                />
                                                <div
                                                    style={{
                                                        fontWeight: 600,
                                                        fontSize: 14,
                                                        marginTop: 5,
                                                        color: '#0D7AEA'
                                                    }}
                                                >
                                                    Upload Image
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: 14,
                                                        color: '#8c8c8c'
                                                    }}
                                                >
                                                    PNG, JPG or WPEG
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </label>
                        </div>
                        <input
                            type="file"
                            id="file"
                            accept={inputAccept}
                            onChange={this.selectImage}
                            className="rdw-image-modal-upload-option-input"
                        />
                    </div>
                ) : (
                    <div className="rdw-image-modal-url-section">
                        <GenericTextFieldWithLabel
                            containerStyle={{
                                width: '100%'
                            }}
                            variant="outlined"
                            label=""
                            placeholder="Paste image url here"
                            name="imgSrc"
                            onChange={this.updateValue}
                            onBlur={this.updateValue}
                            value={imgSrc}
                            fullWidth={true}
                        />
                    </div>
                )}
                {/* {altConf.present && (
                    <div className="rdw-image-modal-size">
                        <span className="rdw-image-modal-alt-lbl">Alt Text</span>
                        <input
                            onChange={this.updateValue}
                            onBlur={this.updateValue}
                            value={alt}
                            name="alt"
                            className="rdw-image-modal-alt-input"
                            placeholder="alt"
                        />
                        <span className="rdw-image-mandatory-sign">
                            {altConf.mandatory && '*'}
                        </span>
                    </div>
                )} */}
                <div className="rdw-image-modal-size">
                    {/* <input
                        onChange={this.updateValue}
                        onBlur={this.updateValue}
                        value={height}
                        name="height"
                        className="rdw-image-modal-size-input"
                        placeholder="Height"
                    />
                    <span className="rdw-image-mandatory-sign">*</span>
                    <input
                        onChange={this.updateValue}
                        onBlur={this.updateValue}
                        value={width}
                        name="width"
                        className="rdw-image-modal-size-input"
                        placeholder="Width"
                    />
                    <span className="rdw-image-mandatory-sign">*</span> */}
                    <LinkSelector
                        variant="outlined"
                        label="Link To"
                        colorType="secondary"
                        value={href}
                        name="href"
                        onChange={this.updateValue}
                        fullWidth={true}
                        pages={pages}
                        externalLinkOnly={className}
                        MenuProps={{
                            disablePortal: true
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        gap: 10
                    }}
                >
                    <GenericTextFieldWithLabel
                        onChange={this.updateValue}
                        onBlur={this.updateValue}
                        value={height}
                        name="height"
                        // className="rdw-image-modal-size-input"
                        placeholder="Height"
                        label="Height"
                    />

                    <GenericTextFieldWithLabel
                        onChange={this.updateValue}
                        onBlur={this.updateValue}
                        value={width}
                        name="width"
                        // className="rdw-image-modal-size-input"
                        placeholder="Width"
                        label="Width"
                    />

                </div>

                <span
                    className={styles.btn_container}
                >
                    <Button
                        variant="contained"
                        className={styles.add_btn}
                        onClick={this.addImageFromState}
                        disabled={
                            !imgSrc || !height || !width
                        }
                    >
                        {translations['generic.add']}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: '#EEEEEE',
                            color: 'black',
                            boxShadow: 'none',
                            border: '1px solid #d3d3d3',
                            width: '100px'
                        }}
                        variant="contained"
                        onClick={doCollapse}
                    >
                        {translations['generic.cancel']}
                    </Button>
                </span>
                {showImageLoading ? (
                    <div className="rdw-image-modal-spinner">
                        <Spinner />
                    </div>
                ) : (
                    undefined
                )}
            </div>
        );
    }

    render() {
        const {
            config: { icon, className, title },
            expanded,
            onExpandEvent,
            translations,
        } = this.props;
        return (
            <div
                className="rdw-image-wrapper"
                aria-haspopup="true"
                aria-expanded={expanded}
                aria-label="rdw-image-control"
            >
                <Option
                    className={classNames(className)}
                    value="unordered-list-item"
                    onClick={onExpandEvent}
                    title={title || translations['components.controls.image.image']}
                >
                    <img src={icon} alt="" />
                </Option>
                {expanded ? this.renderAddImageModal() : undefined}
            </div>
        );
    }
}

export default ImageTool;
