import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'
import StyledTableCell from '../../Library/StyledTableCell/StyledTableCell'
import StyledTableRow from '../../Library/StyledTableRow/StyledTableRow'
import styles from './IntegratedPos.module.scss'

const headings = [
    {
        label: 'File Name'
    },
    {
        label: 'Status'
    },
    {
        label: 'Time'
    }
]

const IntegratedPos = (props) => {
    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.pos_container}
            >
                <div
                    className={styles.title}
                >
                    Your POS System is integrated with us
                </div>
                <img
                style={{
                    height: 120,
                    width: 200,
                    objectFit: 'contain'
                }}
                    src={props.posProvider.image_file}
                />
                <div
                    className={styles.title}
                >
                    {props.posProvider.title}
                </div>

            </div>

            <div
                className={styles.table_container}
            >
                <div
                    className={styles.title}
                >
                    List of last Import File
                </div>
                <TableContainer
                    component={Paper}
                >
                    <Table
                        sx={{
                            minWidth: 700
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {headings.map((data) => (
                                    <StyledTableCell
                                        style={{
                                            color: 'black'
                                        }}
                                    >
                                        {data.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.tableData.map((row) => (
                                <StyledTableRow key={row.filename}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.filename}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.state}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {format(new Date(row.created_at), 'yyyy-MM-dd hh:mm a')}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default IntegratedPos