import React, { useState, useEffect } from "react";
import { Typography, Checkbox, FormControlLabel } from "@mui/material";
import Delivery from "./Parts/Delivery/Delivery";
import Shipping from "./Parts/Shipping/Shipping";
import Pickup from "./Parts/Pickup/Pickup";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import LightTooltip from "../../../../../util_comps/LightTooltip";
import Const from "../../../../helpers/const";


const StoreInfo = (props) => {
  const dispatch = useDispatch((data) => data);
  const { storeInfo } = useSelector((state) => state.venueInfo);
  const storeState = useSelector(
    (store) => store.venueSetting.VenueSettingList?.data
  );
  const [fulfillments, setFulfillments] = useState({
    pickup: false,
    delivery: true,
    shipping: false,
  });

  const [checkbox, setCheckbox] = useState({
    delhivery: storeInfo.venue_esplanda_fee?.delivery_service_charge_to_customer,
    pickup: storeInfo.venue_esplanda_fee?.pickup_service_charge_to_customer,
    shipping: storeInfo.venue_esplanda_fee?.shipping_service_charge_to_customer
  })

  const venueStates = useSelector(
    (state) => state.venueStates.venueStateList?.data?.result
  );
  const handleCheckbox = (event, name) => {
    event.stopPropagation();
    dispatch({
      type: "SET_STORE_INFO",
      payload: {
        ...storeInfo,
        store_info_check: {
          ...storeInfo.store_info_check,
          [name]: !storeInfo?.store_info_check[name],
        },
      },
    });
    let setter = {};
    Object.entries(fulfillments).map(([key, val], i) => {
      if (key === name) {
        setter[key] = !fulfillments[name];
      } else {
        setter[key] = false;
      }
    });
    setFulfillments({
      ...setter,
    });
  };
  /*   useEffect(() => {
    if (fulfillmentsChanged.delivery) {
      return;
    }
    setFulfillmentsChanged({
      ...fulfillmentsChanged,
      delivery: true,
    });
  }, [
    storeInfo.venue.deliveryfeestype,
    storeInfo.venue.deliveryfees,
    storeInfo.venue.mindeliveryamount,
    storeInfo.venue_hours,
  ]); */
  const deliveryChecked =
    (
      storeInfo.venue_hours?.filter((val) => val.venuetimings === "delivery") ||
      []
    ).filter((e) => e.isclosed === "0").length !== 0;
  const pickupChecked =
    (
      storeInfo.venue_hours?.filter((val) => val.venuetimings === "pickup") ||
      []
    ).filter((e) => e.isclosed === "0").length !== 0;
  const shippingChecked = storeInfo?.venue?.shiptostates !== null;

  const handleServiceChargeCheckbox = (key, event) => {
    let setter = {
      ...storeInfo,
      venue: {
        ...storeInfo.venue,
      },
    };

    setter.venue[key] = event.target.checked === false ? 0 : 1


    dispatch({
      type: "SET_STORE_INFO",
      payload: setter,
    });
  }

  return (
    <div className="storeinfo-container" style={{ marginLeft: 20 }}>
      <div>
        <Typography
          style={{
            color: "black",
            fontSize: "1.1rem",
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          Fulfillment Methods
        </Typography>
        <Typography style={{ color: "black" }}>
          Which fulfillment method do you use ?
        </Typography>
      </div>
      <div
        className="storeinfo-option-buttons"
        style={{ display: "flex", marginTop: 15 }}
      >
        <div
          onClick={(e) => handleCheckbox(e, "delivery")}
          style={{
            border: fulfillments.delivery
              ? "none"
              : "1px solid #E6E6E6",
            backgroundColor: fulfillments.delivery ? 'black' : 'unset',
            color: fulfillments.delivery ? 'white' : 'black',
            outline: "none",
            display: "flex",
            alignItems: "center",
            padding: "7px 23px 7px 23px",
            marginRight: 12,
            borderRadius: 4,
            cursor: "pointer",
          }}
        >
          <Typography>Delivery</Typography>
          <span
            style={{
              display: deliveryChecked ? "inline-block" : "none",
              marginLeft: "5px",
            }}
          >
            ✔
          </span>
        </div>
        <div
          onClick={(e) => handleCheckbox(e, "pickup")}
          style={{
            border: fulfillments.pickup
              ? "2px solid grey"
              : "1px solid #E6E6E6",
            backgroundColor: fulfillments.pickup ? 'black' : 'unset',
            color: fulfillments.pickup ? 'white' : 'black',
            outline: "none",
            display: "flex",
            alignItems: "center",
            padding: "7px 23px 7px 23px",
            marginRight: 12,
            borderRadius: 4,
            cursor: "pointer",
          }}
        >
          <Typography>Pickup</Typography>
          <span
            style={{
              display: pickupChecked ? "inline-block" : "none",
              marginLeft: "5px",
            }}
          >
            ✔
          </span>
        </div>
        <div
          onClick={(e) => handleCheckbox(e, "shipping")}
          style={{
            border: fulfillments.shipping
              ? "2px solid grey"
              : "1px solid #E6E6E6",
            backgroundColor: fulfillments.shipping ? 'black' : 'unset',
            color: fulfillments.shipping ? 'white' : 'black',
            outline: "none",
            display: "flex",
            alignItems: "center",
            padding: "7px 23px 7px 23px",
            marginRight: 12,
            borderRadius: 4,
            cursor: "pointer",
          }}
        >
          <Typography>Shipping</Typography>
          <span
            style={{
              display: shippingChecked ? "inline-block" : "none",
              marginLeft: "5px",
            }}
          >
            ✔
          </span>
        </div>
      </div>

      <div style={{ minHeight: 300 }}>
        {Object.entries(fulfillments).map(([key, val], i) => {
          if (val) {
            switch (key) {
              case "pickup":
                return (
                  <>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={checkbox.pickup}
                          onChange={(event) => {
                            handleServiceChargeCheckbox('pickup_service_charge_to_customer', event)
                            setCheckbox({ ...checkbox, pickup: event.target.checked })
                          }}
                          color="default"
                        />
                      )}
                      label={<span><LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>If this checkbox is enabled, this fees will be added to each order to offset your Our{Const.isOurDesiStore ? 'DesiStore' : 'LiquorStore'} fees.</span>} arrow placement="top"><span className="tooltip-color" >Add platform Service</span></LightTooltip> Charge of {storeInfo.venue_esplanda_fee.pickup_fee} to customer order.</span>}
                    />
                    <Pickup
                      setPristine={props.setPristine}
                      setDirty={props.setDirty}
                    />
                  </>
                );
              case "delivery":
                return (
                  <>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={checkbox.delhivery}
                          onChange={(event) => {
                            handleServiceChargeCheckbox('delivery_service_charge_to_customer', event)
                            setCheckbox({ ...checkbox, delhivery: event.target.checked })
                          }}
                          color="default"
                        />
                      )}
                      label={<span><LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>If this checkbox is enabled, this fees will be added to each order to offset your Our{Const.isOurDesiStore ? 'DesiStore' : 'LiquorStore'} fees.</span>} arrow placement="top"><span className="tooltip-color" >Add platform Service</span></LightTooltip> Charge of {storeInfo.venue_esplanda_fee?.delivery_fee} to customer order.</span>}
                    />
                    <Delivery
                      setPristine={props.setPristine}
                      setDirty={props.setDirty}
                    />
                  </>
                );
              case "shipping":
                return (
                  <>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={checkbox.shipping}
                          onChange={(event) => {
                            handleServiceChargeCheckbox('shipping_service_charge_to_customer', event)
                            setCheckbox({ ...checkbox, shipping: event.target.checked })
                          }}
                          color="default"
                        />
                      )}
                      label={<span><LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>If this checkbox is enabled, this fees will be added to each order to offset your Our{Const.isOurDesiStore ? 'DesiStore' : 'LiquorStore'} fees.</span>} arrow placement="top"><span className="tooltip-color" >Add platform Service</span></LightTooltip> Charge of {storeInfo.venue_esplanda_fee.shipping_fee} to customer order.</span>}
                    />
                    <Shipping
                      setPristine={props.setPristine}
                      setDirty={props.setDirty}
                    />
                  </>
                );
              default:
                return null;
            }
          }
        })}
      </div>
      <Typography style={{ fontSize: ".9rem", color: "black" }}>
        If You don't have personal delivery drivers, you can still offer
        delivery through our third party delivery service integration, just{" "}
        <Typography
          style={{
            color: "inherit",
            fontSize: "inherit",
            display: "inline-block",
            borderBottom: "1px solid black",
            cursor: "pointer",
          }}
        >
          click here
        </Typography>
      </Typography>
    </div>
  );
};

export default StoreInfo;
