import { useNavigate, useParams } from 'react-router'

import { Search } from '@mui/icons-material'
import { Button, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './MarketingPosts.module.scss'
import axios from '../Util/axiosConfig'
import { debounce } from 'lodash'
import { FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa'
import { format } from 'date-fns'
import Loader from '../Library/Loader/Loader'
import { toast } from 'react-toastify'
import GenericPostTable from '../Library/GenericPostTable/GenericPostTable'
import { useContext } from 'react'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'

const headings = [
  {
    label: 'Post'
  },
  {
    label: 'Description'
  },
  {
    label: 'Accounts'
  },
  {
    label: 'Date Posted'
  },
  {
    label: 'Impressions'
  },
  {
    label: 'Views'
  },
  {
    label: 'Likes'
  },
  {
    label: 'Comments'
  }
]

const rows = [
  {
    image: 'Image',
    description: 'Description',
    accounts: 'Accounts',
    date: 'datePosted',
    impressions: 'Imp',
    likes: 'Likes',
    views: 'Views',
    comments: 'comments'
  }
]

const MarketingPosts = () => {

  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [isCreatePostLoading, setIsCreatePostLoading] = useState(false)

  const fetchPosts = (query, pageNo) => {
    setIsLoading(true)
    axios.get('/marketing/social-post/list', {
      params: {
        q: query,
        page: pageNo
      }
    })
      .then((response) => {
        setPagination(response.data.data.pagination)
        setPosts(response.data.data.results)
      })
      .catch(() => {

      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handlePaginationChange = (event, value) => {
    fetchPosts(searchQuery, page)
    setPage(value)
  }

  const debounceSearchPosts = useCallback(debounce(fetchPosts, 1000), [])

  useEffect(() => {
    fetchPosts()
  }, [])

  return (
    <div
      className={styles.container}
    >
      <div
        className={styles.title_container}
      >
        <div
          className={styles.dashboard_text}
        >
          Posts
        </div>

        <div>
          <GenericLoadingButton
            style={{
              backgroundColor: '#0B9DE4',
              borderRadius: '30px',
              fontWeight: 600
            }}
            variant="contained"
            type="button"
            loading={isCreatePostLoading}
            loadingPosition='end'
            onClick={() => {
              setIsCreatePostLoading(true)
              axios.post('/marketing/social-post-group/initiate')
                .then((groupResponse) => {
                  navigate(`/marketing/create-post/${groupResponse.data?.data?.result?.id}`)
                })
                .catch((err) => {
                  toast.error(err?.response?.data?.message);
                })
                .finally(() => {
                  setIsCreatePostLoading(false)
                })
            }}
          >
            Create Post
          </GenericLoadingButton>

        </div>
      </div>

      {/* {showHelpPage ? helpPage : (
      )} */}
        <>
          <div className={styles.searh_input_container}>
            <div
              style={{
                width: 250,
                marginBottom: 15,
                position: 'relative'
              }}
            >
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z'
                  stroke='#919191'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M18.9999 18.9999L14.6499 14.6499'
                  stroke='#919191'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
              <TextField
                InputProps={{
                  classes: {
                    notchedOutline: styles.input_box_style,
                    input: styles.input_element,
                  },
                }}
                variant='outlined'
                placeholder="Search Post"
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.target.value)
                  debounceSearchPosts(event.target.value)
                }}
              />
            </div>
          </div>

          {isLoading ? (
            <Loader />
          ) : Boolean(posts.length) ? (
            <>
              <GenericPostTable
                headings={headings}
                posts={posts}
                handleChangePage={handlePaginationChange}
                totalPages={pagination.last_page}
                pageNo={page}
              />
            </>
          ) : (
            <div
              style={{
                fontWeight: 600,
                fontSize: 18,
                textAlign: 'center',
                paddingTop: 20
              }}
            >
              No Post Available
            </div>
          )}
        </>

    </div >
  )
}

export default MarketingPosts