import React, { useState } from "react";
//import './style.css';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';
import Items from "./Items/Items";
import CouponItems from "./CouponItems/Items";
import TabsSection from '../../Library/V2/TabsSection/TabsSection';


const tabs = [
  {
    value: 'coupons',
    label: 'Coupons',
  },
  {
    value: 'automated_offers',
    label: 'Automated Offers',
  },
];

const Offers = (props) => {
  useAuth();
  const [selectedTab, setSelectedTab] = useState('coupons')


  return (
    <React.Fragment>

      <div className="page-wrapper tw-my-2 tw-px-4 sm:tw-px-11 ">
        <TabsSection
          tabs={tabs}
          tabsbg='var(--bg-3)'
          tabsPadding='15px'
          tabValue={selectedTab}

          onChange={(_, newValue) => {
            setSelectedTab(newValue)
          }}
          boxStyles={{
            p: '0 !important',
            borderBottom: '1px solid #D3D3D3',
          }}
        />
        {selectedTab === 'automated_offers' ? (
          <Items />
        ) : <CouponItems />}

      </div>
    </React.Fragment >
  );
};

export default Offers;
