import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import { TreeItem, TreeView, treeItemClasses } from '@mui/x-tree-view';
import React, { useEffect } from 'react'
import { useState } from 'react';
import styles from './SidebarItem.module.scss'
import { Link, useLocation } from 'react-router-dom';

const StyledTreeItem = styled(TreeItem, {
    shouldForwardProp: (props) => props !== 'rootNode',
})(({ rootNode, isActive }) => {
    // const borderColor = 'white';
    // const borderColor = 'white';

    return {
        position: 'relative',
        background: isActive ? 'var(--bg-6)' : '',
        color: 'var(--text-1)',
        margin: '0',
        padding: '7px 5px',
        fontFamily: "'Inter', 'sans-serif' !important",
        borderRadius: '8px',
        fontSize: '14px',
        // '&:before': {
        //   pointerEvents: 'none',
        //   content: '""',
        //   position: 'absolute',
        //   width: 16,
        //   left: -18,
        //   top: 12,
        //   borderBottom:
        //     // only display if the TreeItem is not root node
        //     !rootNode ? `1px solid ${borderColor}` : 'none',
        // },
        // [`& .${treeItemClasses.group}`]: {
        //   marginLeft: 16,
        //   paddingLeft: 18,
        //   borderLeft: `1px solid ${borderColor}`,
        // },
        [`& .${treeItemClasses.content}`]: {
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            backgroundColor: 'transparent !important',
        },
        [`& .${treeItemClasses.label}`]: {
            fontFamily: "'Inter', 'sans-serif' !important",
            color: 'var(--text-1)',
        },
    };
});

const SidebarItem = ({ open, setOpen, icon, label, isBelow1000Px, href, id, child, handleDrawerClose, isActive, onClick, onNodeToggle, nodeId }) => {

    const [expanded, setExpanded] = useState([])
    const router = useLocation()

    // useEffect if !open setExpanded()

    useEffect(() => {
        if (!open) {
            setExpanded([])
        }
    }, [open])


    const handleToggle = (event, nodeIds) => {
        console.log(nodeIds)
        if (open) {
            setExpanded(nodeIds);
        }
    };
    return (
        <ListItemButton
            style={{
                padding: '0 0 0 2px',
                backgroundColor: 'white'
            }}
            disableRipple={true}
            disableTouchRipple={true}
            onClick={() => {
                if (!open) {
                    setOpen(true);
                }
                if (onClick) {
                    onClick()
                }
            }}
        >
            <TreeView
                classes={{
                    root: styles.tree_view_root,
                }}
                expanded={expanded}
                onNodeToggle={onNodeToggle ? onNodeToggle : handleToggle}
                defaultCollapseIcon={
                    <ExpandMore
                        sx={{ width: '18px' }}
                        style={{
                            color: 'var(--text-1)',
                            width: '18px',
                        }}
                    />
                }
                defaultExpandIcon={
                    <ChevronRight
                        sx={{ opacity: open ? 1 : 0, width: '18px' }}
                        style={{
                            color: 'var(--text-1)',
                            width: '18px',
                        }}
                    />
                }
            >
                <StyledTreeItem
                    classes={{
                        root: styles.tree_view_item_root,
                        group: styles.tree_view_item_group,
                    }}
                    rootNode
                    nodeId={nodeId || id?.toString()}
                    label={
                        href ? (
                            <Link
                                to={href}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={() =>
                                    isBelow1000Px && handleDrawerClose()
                                }
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {icon}
                                </ListItemIcon>
                                <ListItemText
                                    style={{
                                        color: 'var(--text-1)',
                                        fontWeight: 'bold',
                                    }}
                                    primary={label}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </Link>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {icon}
                                </ListItemIcon>
                                <ListItemText
                                    style={{
                                        color: 'var(--text-1)',
                                        fontWeight: 'bold',
                                    }}
                                    primary={label}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </div>
                        )
                    }
                    isActive={isActive || (href ? router.pathname.includes(href) : undefined)}
                >
                    {child?.filter((data) => data)?.map((data, index) => data.onClick ? (
                        <div

                            onClick={data.onClick}
                        >
                            <div className={styles.menu_item_links}>
                                <div
                                    className="tw-px-2 tw-flex tw-gap-6 tw-py-4"
                                >
                                    <div
                                        className="tw-ml-1 tw-min-h-6 tw-min-w-6"
                                    >
                                        {/* {data.icon} */}
                                    </div>
                                    <div>
                                        {data.label}
                                    </div>
                                </div>
                                {/* <StyledTreeItem
                                   nodeId={data.nodeid || `c1-${index + 1}`}
                                   icon={data.icon}
                                   label=
                                   isActive={router.pathname === data.href}
                               /> */}
                            </div>
                        </div>
                    ) : (
                        <Link
                            to={data.href}
                            onClick={() => isBelow1000Px && handleDrawerClose()}
                        >
                            <div className={styles.menu_item_links}>
                                <div
                                    className="tw-px-2 tw-flex tw-gap-6 tw-py-2"
                                >
                                    <div
                                        className="tw-ml-1 tw-min-h-6 tw-min-w-6"
                                    >
                                        {/* {data.icon} */}
                                    </div>
                                    <div>
                                        {data.label}
                                    </div>
                                </div>
                                {/* <StyledTreeItem
                                    nodeId={data.nodeid || `c1-${index + 1}`}
                                    icon={data.icon}
                                    label=
                                    isActive={router.pathname === data.href}
                                /> */}
                            </div>
                        </Link>
                    ))}
                </StyledTreeItem>
            </TreeView>
        </ListItemButton>
    )
}

export default SidebarItem