import { outlinedInputClasses, styled, TextField } from '@mui/material';
import React from 'react';

const GenericTextField = styled((props) => <TextField {...props} />)(
  ({ theme, variant }) => ({
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: variant == 'outlined' ? '1.5px solid #A8A8A8 !important' : 'none',
      outline: 'none',
      color: '#2e2e2e',
    },
    [`& .${outlinedInputClasses.input}`]: {
      fontSize: 14,
      padding: '7.5px 14px',
    },
    [`& .${outlinedInputClasses.root}`]: {
      height: 38,
      backgroundColor: variant == 'outlined' ? 'transparent' : '#efefef',
    },
  })
);

export default GenericTextField;
