import React from 'react'

const PromotionIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="24px" height="24px" viewBox="0 0 32 32" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }} version="1.1">

            <g transform="matrix(1,0,0,1,-96,-240)">

                <g transform="matrix(0.294118,-0.509427,0.314918,0.181818,-7.8734,270.952)">

                    <path d="M111.879,249.995C108.418,251.567 105.129,252.095 102.105,251.032C101.604,250.83 101.074,251.013 100.668,251.526C100.262,252.04 100.024,252.828 100.024,253.662C100,255.359 100,257.318 100,258.785C99.999,260.094 100.569,261.222 101.361,261.482C105.813,262.874 110.497,262.861 115.406,261.49" style={{ fill: 'rgb(144,224,239)' }} />

                </g>

                <g transform="matrix(0.529412,0,0,1,62.0588,-2)">

                    <path d="M117,249.236C117,248.543 116.322,247.899 115.208,247.535C114.095,247.17 112.704,247.137 111.533,247.447C105.954,248.924 98.111,251 98.111,251L98.111,261C98.111,261 105.954,263.076 111.533,264.553C112.704,264.863 114.095,264.83 115.208,264.465C116.322,264.101 117,263.457 117,262.764C117,259.04 117,252.96 117,249.236Z" style={{ fill: 'rgb(144,224,239)' }} />

                </g>

                <g transform="matrix(0.823529,0,0,0.909091,17.6471,21.7273)">

                    <path d="M117,250L102.429,250C101.087,250 100,250.985 100,252.2C100,254.117 100,256.883 100,258.8C100,260.015 101.087,261 102.429,261C107.126,261 117,261 117,261L117,250Z" style={{ fill: 'rgb(144,224,239)' }} />

                </g>

                <path d="M113.764,248L102,248C100.343,248 99,249.343 99,251L99,257C99,258.657 100.343,260 102,260L102.556,260C102.088,261.518 101.431,262.844 100.488,263.889C100.063,264.339 99.871,264.942 99.955,265.541C100.039,266.141 100.393,266.669 100.915,266.976C101.447,267.299 102.069,267.658 102.535,267.927C103.358,268.403 104.403,268.233 105.033,267.521L105.037,267.517C106.863,265.411 108.3,262.92 109.356,260.05C109.362,260.034 109.368,260.017 109.373,260L113.723,260L120.457,264.04C121.383,264.596 122.538,264.611 123.478,264.078C124.419,263.546 125,262.548 125,261.468L125,247.236C125,246.196 124.462,245.231 123.577,244.684C122.693,244.137 121.588,244.088 120.658,244.553L113.764,248ZM104.639,260C104.07,262.075 103.211,263.866 101.944,265.261L101.948,265.263C102.469,265.58 103.078,265.932 103.535,266.196L103.535,266.196L103.535,266.196C105.067,264.428 106.295,262.359 107.234,260L104.639,260ZM115,258.434L115,249.618C115,249.618 121.553,246.342 121.553,246.342C121.863,246.187 122.231,246.203 122.526,246.385C122.821,246.568 123,246.889 123,247.236L123,261.468C123,261.828 122.806,262.16 122.493,262.338C122.179,262.515 121.794,262.51 121.486,262.325L115,258.434ZM113,250L113,258C113,258 102,258 102,258C101.448,258 101,257.552 101,257L101,251C101,250.448 101.448,250 102,250L113,250Z" style={{ fill: 'rgb(25,144,167)' }} />

            </g>

        </svg>
    )
}

export default PromotionIcon