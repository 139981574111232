import React, { useContext, useEffect, useRef, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
// import { grapesjsInit } from 'webpage-builder-dev/grapesjs/init';
import { grapesjsInit } from 'webpage-builder/grapesjs/init';
import axios, { nodeAxios } from '../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import styles from './CustomPageBuilder.module.scss'
import baseUrl from '../../../utils/baseUrl';
import { useSelector } from 'react-redux';
import { allSector, blocksList, findUniqueObject, tabbarBlocksList, footerBlocksList, headerBlocksList, styleSector } from './constants';
import clsx from 'clsx';
import PageLoader from '../../Library/PageLoader/PageLoader';
import Loader from '../../Library/Loader';
import WebsiteEditorContext from '../../../utils/context/websiteEditorContext';

const CustomPageBuilder = (props) => {

    const codeMirrorRef = useRef()
    const codeMirrorRefEditor = useRef()
    const ctx = useOutletContext()

    const { name, id, uri, setIsSaved, editor, setEditor, setCode } = useContext(WebsiteEditorContext)

    const [searchParams, setSearchParams] = useSearchParams();
    const device = searchParams.get('device')

    const venueIdRef = useRef();
    const venueNameRef = useRef();
    const isBlockerRef = useRef()

    const user = useSelector((state) => state.user);

    const [customPageData, setCustomPageData] = useState()
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [pages, setPages] = useState([])
    const [blockCategories, setBlockCategories] = useState([])
    const [showSection, setShowSection] = useState(false)
    const [isAddSectionUp, setIsAddSectionUp] = useState(false)
    const [showEditMenu, setShowEditMenu] = useState(false)
    const [editMenuCoord, setEditMenuCoord] = useState({
        top: 0,
        right: 0
    })
    const [editType, setEditType] = useState('')
    const [isSectionSelected, setIsSectionSelected] = useState(false)
    const [isMultiDevice, setIsMultiDevice] = useState(false)

    useEffect(() => {
        if (!isPageLoading) {
            setIsSaved(true)
        }
    }, [isPageLoading])

    useEffect(() => {
        venueIdRef.current = user?.data?.venue_id_integer
        venueNameRef.current = user?.data?.name
    }, [user])

    useEffect(() => {
        if (editor) {
            editor.setDevice(device.charAt(0).toUpperCase() + device.slice(1))
        }

        if (customPageData && editor) {
            localStorage.setItem(`gjsProject-${name}`, customPageData)
            load()
        } else if (editor) {
            load()
        }
    }, [customPageData, editor]);

    useEffect(() => {
        let typeId = 3

        let blocks = blocksList

        if (name === 'Header') {
            typeId = 1

            blocks = headerBlocksList
        }

        if (name === 'Footer') {
            typeId = 2
            blocks = footerBlocksList
        }

        if (name === 'MobileNavigation') {
            typeId = 4
            blocks = tabbarBlocksList
        }

        let imageApi = `${baseUrl}/page-component/create`
        nodeAxios
            .get(`/web-builder/web-page/get/${id}`, {
                params: {
                    device_type: (device == 'mobile') ? 2 : ((device == 'tablet') ? 1 : 0),
                }
            })
            .then((response) => {
                try {
                    codeMirrorRef.current = response.data.data?.rawhtml
                    setCode(response.data.data?.rawhtml || '')

                    setIsMultiDevice(response.data.data?.is_multi)
                    let responseObj = JSON.parse(response.data.data?.payload);
                    Object.keys(responseObj).forEach(function (key) {
                        if (key.includes('html')) {
                            delete responseObj[key];
                        }
                        if (key.includes('css')) {
                            delete responseObj[key];
                        }
                        if (key.includes('assets')) {
                            responseObj['assets'] = responseObj[key];
                            delete responseObj[key];
                        }
                        if (key.includes('pages')) {
                            responseObj['pages'] = responseObj[key];
                            delete responseObj[key];
                        }
                        if (key.includes('styles')) {
                            responseObj['styles'] = responseObj[key];
                            delete responseObj[key];
                        }
                    });
                    setCustomPageData(JSON.stringify(responseObj));

                } catch (e) {
                    console.log(e);
                }
            })
            .catch(() => { })
            .finally(() => {
                grapesjsInit({
                    onEditorInit,
                    ctx,
                    name,
                    blocksArr: blocks,
                    imageApi,
                    typeId,
                    pages,
                    baseUrl,
                    subDomain: '',
                    venueId: user?.data?.venueid,
                    editorType: 'biz',
                    initScript: process.env.REACT_APP_WEB_BUILDER_INIT || "https://stage-wb.esplanda.com/bundle.js",
                    initCss: process.env.REACT_APP_WEB_BUILDER_INIT_CSS || "https://stage-wb.esplanda.com/main.css",
                    // initScript: "/grapesjs/js/js2/bundle.js",
                    // initCss: "/grapesjs/js/js2/main.css"
                })
            })

        return () => {
            window.location.reload()
        }
    }, []);

    const getContentDocument = (editor) => {
        const iframe = editor.Canvas?.getFrameEl();
        if (iframe.contentDocument) {
            return iframe.contentDocument;
        } else if (iframe.contentWindow && iframe.contentWindow.document) {
            return iframe.contentWindow.document;
        } else if (iframe.document) {
            return iframe.document;
        } else {
            return null;
        }
    }

    // const handleComponentSelect = (model, editor) => {

    //     const parsedModel = JSON.parse(JSON.stringify(model))
    //     handleActuallComponentsAdd(model, parsedModel)

    //     const styleManager = editor.StyleManager;
    //     const sectors = styleManager?.getSectors();

    //     allSector.forEach((data) => {
    //         styleManager.addSector(data.id, {
    //             name: data.name,
    //             open: data.open,
    //             properties: data.properties,
    //         });
    //     })

    //     const filteredStyleSector = styleSector.find((data) => parsedModel.type?.includes(data.type))

    //     var uniqueObjects = findUniqueObject(JSON.parse(JSON.stringify(sectors)), filteredStyleSector?.array || allSector, 'id');

    //     // Loop through all the sectors
    //     uniqueObjects.forEach(sector => {
    //         styleManager.removeSector(sector.id)
    //     });

    //     console.log(parsedModel.type)
    //     if (parsedModel.type?.includes('image') && parsedModel.type?.includes('link')) {
    //         setEditType('image-link');
    //     } else if (parsedModel.type?.includes('image') && !parsedModel.type?.includes('link')) {
    //         setEditType('image');
    //     } else if (parsedModel.type?.includes('audio')) {
    //         setEditType('audio');
    //     } else if (parsedModel.type?.includes('video')) {
    //         setEditType('video');
    //     } else if (
    //         parsedModel.type?.includes('link') ||
    //         parsedModel.type?.includes('text')
    //     ) {
    //         setEditType('text');
    //     } else if (parsedModel.type?.includes('button')) {
    //         setEditType('button');
    //     } else if (parsedModel.type?.includes('navbar') && parsedModel.type?.includes('react')) {
    //         setEditType('navbar');
    //     } else if (parsedModel.type?.includes('introduction') && parsedModel.type?.includes('react')) {
    //         setEditType('introduction');
    //     } else if (parsedModel.type?.includes('about') && parsedModel.type?.includes('react')) {
    //         setEditType('about');
    //     } else if (parsedModel.type?.includes('gallery') && parsedModel.type?.includes('react')) {
    //         setEditType('gallery');
    //     } else if (parsedModel.type?.includes('logos') && parsedModel.type?.includes('react')) {
    //         setEditType('logos');
    //     } else if (parsedModel.type?.includes('people') && parsedModel.type?.includes('react')) {
    //         setEditType('people');
    //     } else if (parsedModel.type?.includes('contact') && parsedModel.type?.includes('react')) {
    //         setEditType('contact');
    //     } else if (parsedModel.type?.includes('services') && parsedModel.type?.includes('react')) {
    //         setEditType('services');
    //     } else if (parsedModel.type?.includes('portfolio') && parsedModel.type?.includes('react')) {
    //         setEditType('portfolio');
    //     } else if (parsedModel.type?.includes('quote') && parsedModel.type?.includes('react')) {
    //         setEditType('quote');
    //     } else if (parsedModel.type?.includes('footer') && parsedModel.type?.includes('react')) {
    //         setEditType('footer');
    //     } else if (parsedModel.type?.includes('esplanda-footer')) {
    //         setEditType('footer');
    //     } else if (parsedModel.type?.includes('social') && parsedModel.type?.includes('react')) {
    //         setEditType('social');
    //     } else if (parsedModel.type?.includes('faq') && parsedModel.type?.includes('react')) {
    //         setEditType('faq');
    //     } else if (parsedModel.type?.includes('pricing') && parsedModel.type?.includes('react')) {
    //         setEditType('pricing');
    //     } else if (parsedModel.type?.includes('testimonials') && parsedModel.type?.includes('react')) {
    //         setEditType('testimonials');
    //     } else if (parsedModel.type?.includes('enrollment')) {
    //         setEditType('enrollment')
    //     } else if (parsedModel.type?.includes('hero') && parsedModel.type?.includes('react')) {
    //         setEditType('hero');
    //     } else if (parsedModel.type?.includes('table') && parsedModel.type?.includes('react')) {
    //         setEditType('table');
    //     } else if (parsedModel.type?.includes('feature') && parsedModel.type?.includes('react')) {
    //         setEditType('feature');
    //     } else if (parsedModel.type?.includes('tabbar') && parsedModel.type?.includes('react')) {
    //         setEditType('tabbar');
    //     } else if (parsedModel.type?.includes('about') && parsedModel.type?.includes('react')) {
    //         setEditType('about');
    //     } else if (parsedModel.type?.includes('text') && parsedModel.type?.includes('react')) {
    //         setEditType('text');
    //     } else if (parsedModel.type?.includes('esplanda-product-card-group')) {
    //         setEditType('esplanda-product-card-group')
    //     } else if (parsedModel.type?.includes('esplanda-category-card-group')) {
    //         setEditType('esplanda-category-card-group')
    //     } else if (parsedModel.type?.includes('forms') && parsedModel.type?.includes('react')) {
    //         setEditType('forms');
    //     } else if (parsedModel.type?.includes('calltoaction') && parsedModel.type?.includes('react')) {
    //         setEditType('calltoaction');
    //     } else if (parsedModel.type?.includes('tabs') && parsedModel.type?.includes('react')) {
    //         setEditType('tabs');
    //     } else if (parsedModel.type?.includes('embed') && parsedModel.type?.includes('react')) {
    //         setEditType('embed');
    //     } else if (parsedModel.type?.includes('esplandabanner') || parsedModel.type?.includes('react')) {
    //         setEditType('esplanda-banner')
    //     } else if (parsedModel.type?.includes('esplanda-header')) {
    //         setEditType('esplanda-header')
    //     } else if (parsedModel.type?.includes('esplanda-terms')) {
    //         setEditType('esplanda-terms')
    //     } else if (parsedModel.type?.includes('esplanda-privacy')) {
    //         setEditType('esplanda-privacy')
    //     } else if (parsedModel.type?.includes('esplanda-menu-tabs')) {
    //         setEditType('esplanda-menu-tabs')
    //     } else if (parsedModel.type?.includes('esplanda-store-details')) {
    //         setEditType('esplanda-store-details')
    //     } else if (parsedModel.type?.includes('esplanda-signup')) {
    //         setEditType('esplanda-signup')
    //     } else {
    //         setEditType('')
    //     }

    //     const parsedModel2 = JSON.parse(JSON.stringify(model.parent() || {}))
    //     var contentDocument = getContentDocument(editor);

    //     if ((parsedModel2?.type == 'wrapper') && contentDocument) {
    //         setIsSectionSelected(true)
    //         let section = contentDocument?.getElementById(model.ccid)
    //         let isButton = contentDocument?.getElementById(`addsection-${model.ccid}`)

    //         if (!isButton && uri !== 'tabbar') {
    //             section.style = 'position: relative;'
    //             let coord = section.getBoundingClientRect()
    //             let coorStyle = getComputedStyle(section)
    //             // console.log(section,coord, getComputedStyle(section).height, coord.height, parseInt(coorStyle.paddingTop), parseInt(coorStyle.paddingBottom))
    //             let button = contentDocument?.createElement('button');
    //             button.style = `height: 34px; padding: 5px 20px; background-color: #0B9DE4; color: white; border: none; border-radius: 4px; cursor: pointer; position: absolute; top: ${coord.height - 20}px; left: 50%; pointer-events: all; border-radius: 20px;z-index: 99999999999999999999999999; transform: translateX(-50%);`
    //             button.innerHTML = 'Add Section';
    //             button.id = `addsection-${model.ccid}`;
    //             button.addEventListener("click", function () {
    //                 handleAddSection(false)
    //             });
    //             section.appendChild(button);

    //             const resizeObserver = new ResizeObserver(entries => {

    //                 window.requestAnimationFrame(() => {
    //                     if (!Array.isArray(entries) || !entries.length) {
    //                         return;
    //                     }

    //                     for (let entry of entries) {
    //                         const { width, height } = entry.contentRect;
    //                         const calcHeight = height + parseInt(coorStyle.paddingTop) + parseInt(coorStyle.paddingBottom)
    //                         button.style = `height: 34px; padding: 5px 20px; background-color: #0B9DE4; color: white; border: none; border-radius: 4px; cursor: pointer; position: absolute; top: ${calcHeight - 20}px; left: 50%; pointer-events: all; border-radius: 20px;z-index: 99999999999999999999999999;transform: translateX(-50%);`
    //                     }
    //                 });
    //             });

    //             // Step 3: Observe target elements
    //             resizeObserver.observe(section);
    //         }
    //         setShowSection(false)
    //     } else {
    //         setIsSectionSelected(false)
    //     }
    // }

    // const handleComponentHover = (model, editor) => {
    //     // const parsedModel = JSON.parse(JSON.stringify(model))
    //     // console.log(parsedModel)
    //     if (model.attributes.tagName == 'body') {
    //         if (document.querySelector('.gjs-rte-actionbar').classList.contains(styles.display_none)) {
    //             document.querySelector('.gjs-rte-actionbar').classList.add(styles.display_none)
    //         }
    //     }
    //     const parsedModel2 = JSON.parse(JSON.stringify(model.parent() || {}))
    //     if (parsedModel2?.type === 'wrapper') {
    //         document.querySelector('.gjs-rte-actionbar').classList.add(styles.display_none)
    //         // setShowEditMenu(false)
    //         editor.select(model)
    //     } else {
    //         document.querySelector('.gjs-rte-actionbar').classList.remove(styles.display_none)
    //     }
    // }

    const onEditorInit = (editor) => {

        // setBlockCategories(editor.BlockManager.getCategories().models.sort((a, b) => a.id > b.id ? 1 : -1).map((element, index) => {

        //     let blocks = JSON.parse(JSON.stringify(editor.BlockManager.getAll())).filter((data) => (data.category?.id === element.id))
        //     return ({
        //         id: (index + 1),
        //         title: element.id,
        //         blocks: blocks.map((data) => ({ ...data, categoryData: data.category, category: undefined }))
        //     })
        // }))

        //  setBlockCategories(editor.BlockManager.getCategories().models.sort((a, b) => a.id > b.id ? 1 : -1).map((element, index) => {

        //     let blocks = JSON.parse(JSON.stringify(editor.BlockManager.getAll())).filter((data) => (data.category?.id === element.id))
        //     return ({
        //         id: (index + 1),
        //         title: element.id,
        //         blocks: blocks.map((data) => ({ ...data, categoryData: data.category, category: undefined }))
        //     })
        // }))

        // editor.on('update', () => {
        //     setIsSaved(false)
        // })

        // editor.on('component:selected', (model) => handleComponentSelect(model, editor))

        // editor.on('component:hover', (model) => handleComponentHover(model, editor))

        // editor.on('component:add', (model) => {
        //     editor.select(model)
        // })

        setEditor(editor)
    }

    const load = async () => {
        await editor.load()

        var contentDocument = getContentDocument(editor);

        contentDocument.head?.setAttribute('venue_id', venueIdRef.current)
        contentDocument.head?.setAttribute('venue_name', venueNameRef.current)
        contentDocument.head?.setAttribute('biz_base_url', process.env.REACT_APP_API_STORE_URL)
        contentDocument.head?.setAttribute('fe_base_url', process.env.REACT_APP_API_FE_STORE_URL)

        // setTimeout(() => {
        //     contentDocument?.body?.addEventListener('click', (el) => {
        //         console.log('2', el)
        //         const selectedCoords = document?.querySelector('.gjs-frame')?.getBoundingClientRect();
        //         const frameCoords = el?.target?.getBoundingClientRect()
        //         const canvasHeight = window.innerHeight - 65
        //         const menuTop = el.clientY + 157
        //         console.log(selectedCoords, frameCoords, el.clientY)

        //         setEditMenuCoord({
        //             top: (menuTop > canvasHeight) ? (el.clientY - (menuTop - canvasHeight)) : el.clientY,
        //             right: 50,
        //         });
        //     });

        // }, 4000);

        setTimeout(() => {

            if (localStorage.getItem('pullreloadaction') == 'save') {
                editor.store();
                localStorage.removeItem('pullreloadaction')
            }

            if (localStorage.getItem('synceditsite') && (localStorage.getItem('synceditsite') != device)) {
                editor.store();
            }
        }, 1000);

        setTimeout(() => {
            setIsPageLoading(false)
        }, 4000);
    }

    const handleAddSection = (isUp) => {
        setIsAddSectionUp(isUp)
        setShowSection(true)
    }

    return (
        <div
            className={clsx(styles.custom_page_builder)}
        >
            {/* {isPageLoading && (
                <Loader />
            )}

            <BlocksPlaceholder /> */}

            <div
                // style={isPageLoading ? { height: 0, overflow: 'hidden' } : {}}
            >
                <div
                    className="tw-flex tw-justify-start tw-items-stretch tw-flex-nowrap tw-h-[calc(100vh-45px)] tw-overflow-hidden"
                >
                    <div class="editor-canvas">
                        <div
                            id="editor"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CustomPageBuilder;