import React,{useState} from "react";
import {
    Checkbox,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Collapse,
} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CitiesList from './CitiesList';

const StatesList = (props) => {
    const [openedState,setOpenedState] = useState('none');
    const handleStateOpen = (state) => {
        if(openedState === state){
            setOpenedState('none');
        }else{
            setOpenedState(state);
        };
    };
    return (
        <List component="div" disablePadding>
            {props.country.CountryStates.map((state) => {
                return (
                    <React.Fragment>
                        <ListItem
                            style={{
                                paddingRight: '80px',
                                marginLeft: "7%",
                                width: '100%'
                            }}
                            button
                            onClick={e => handleStateOpen(state.State)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    onChange={e => e.target.checked ? props.setCountryState(state.State,state.citydata,props.country.Country) : props.removeCountryState(state.State,state.citydata)}
                                    checked={props.filters.states.indexOf(state.State) !== -1}
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": state.State,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText primary={state.State} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end">
                                    {openedState === state.State ? <ExpandMoreIcon onClick={e=> handleStateOpen(state.State)} /> : <ExpandLessIcon onClick={e => handleStateOpen(state.State)} />}
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse
                            in={openedState === state.State}
                            timeout="auto"
                            unmountOnExit
                        >
                            <CitiesList setCity={props.setCity} removeCity={props.removeCity} state={state} filters={props.filters} />
                       </Collapse>
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default StatesList;
