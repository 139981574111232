import axios, { nodeAxios } from "../../utils/axiosConfig";
import Const from "../../components/helpers/const";
import { toast } from "react-toastify";


export function getOrderList(params) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url = params !== "" ? `/order/list-new?${params}` : '/order/list-new';
      dispatch({ type: "SET_ORDER_LIST_LOADING" });
      return axios
        .get(url)
        .then((response) => {
          dispatch({
            type: "SET_ORDER_LIST_LOADED",
            payload: response.data
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: "SET_ORDER_LIST_ERROR",
            payload: error.response,
          });
          return Promise.resolve();
        });
    }
  }
};

export function getOrderCount(params) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url = params !== "" ? `/driver/get-order-count?${params}` : '/driver/get-order-count';
      dispatch({ type: "SET_ORDER_COUNT_LOADING" });
      return axios
        .get(url)
        .then((response) => {
          dispatch({
            type: "SET_ORDER_COUNT_LOADED",
            payload: response.data
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: "SET_ORDER_COUNT_ERROR",
            payload: error.response,
          });
          return Promise.resolve();
        });
    }
  }
};

export function cancelOrder(params) {
  return (dispatch) => {
    dispatch({ type: "SET_CANCEL_ORDER_LOADING" });
    return axios
      .get(`/order/cancel/${params}`, {
        params: {
          action_taken_from: (window.innerWidth < 1000) ? 'App' : 'Web'
        }
      })
      .then((response) => {
        dispatch({
          type: "SET_CANCEL_ORDER_LOADED",
          payload: response,
        });
        return {
          error: false,
          status: "ok"
        };
      })
      .catch((error) => dispatch({
        error: true, type: "SET_CANCEL_ORDER_ERROR", message: error.response.data.message
      }));

  };
}
export function changeOrderStatus(id, nextStatus) {
  return (dispatch) => {
    dispatch({ type: "SET_STATUS_ORDER_LOADING" });
    return nodeAxios.post('/orders/change-status', {
      orderId: id,
      nextStatus: nextStatus,
      actionTakenFrom: (window.innerWidth < 1000) ? 'App' : 'Web',
    }).then((response) => {
      dispatch({
        type: "SET_STATUS_ORDER_LOADED",
        payload: response,
      });
      return {
        error: false,
        status: "ok"
      };
    })
      .catch((error) => {
        toast.error(error.response?.data?.message)
        dispatch({
          error: true, type: "SET_STATUS_ORDER_ERROR", errors: error.response.data.errors
        })
      });

  };
}