import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import createnewtagsimg from "../../../assets/create-new-tags.png";
import routePaths from '../../../config/route-paths';
const CreateNewTags = (props) => {
   const cn = classname('add-new-product')
   return (
      <div
         className={cns(
            cn(),
            props.className,
         )}>
         <div className={cn('help-page-title')}>
            <div className={cn('title-text')}>
               <Typography variant="h1">
                  Help & Support
               </Typography>
            </div>
            <div className={cn('help-page-title-search')}>
               Search
            </div>
         </div>
         <div className={cn('help-page-body')}>
            <div className={cn('helpdesk-grid')}>
               <div className={cn('left-list')}>
                  <Typography variant="h2">
                     <div className={cn('iconProduct')}>
                        <img src={productinventory} />
                     </div>
                     Product & Inventory
                  </Typography>
                  <ul className={cn('helpdeskinnerlist')}>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.addNewProduct}>How to Add New Products</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.createNewTags} className={cn('selected')}>Create New Tags</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.assigntagstoproduct}>Assign Tag to Product</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.uploadInventory}>Upload Inventory</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.searchProductbyCategory}>Search Product by Category</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.editProductAttributes}>Edit Product Attributes</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.changingItemVisiblity}>Changing Item Visiblity</Link></li>
                  </ul>
               </div>
               <div className={cn('right-body-content')}>
                  <Typography variant="h3">
                     Create New Tags
                  </Typography>
                  <p><strong>Item tags are useful for organizing products into your own custom categories, such as seasonal or promotional items.</strong></p>
                  <span className={cn('addnewproductsimg')}>
                     <img src={createnewtagsimg} />
                  </span>
                  <p>

                     <ol>
                        <li>Click the ‘<strong>Tags</strong>’ button on the top-right in ‘<strong>My Inventory</strong>’, just below the ‘<strong>Search Inventory</strong>’ search bar.</li>
                        <li>A pop-up window will appear. Next, click the ‘<strong>Add New Tag</strong>’ button on the right.</li>
                        <li>Another pop-up will appear. Here, choose a name for your tag, such as ‘<strong>Sale’, ‘Christmas’, or ‘Featured</strong>’. Click ‘<strong>Save</strong>’.</li>
                        <li>Your new tag will now appear in the list under ‘<strong>Select Tag</strong>’ on the left side.</li>
                     </ol>
                  </p>
               </div>
            </div>
         </div>
         <div className={cn('answersection')}>
            <Typography variant="h4">
               Still can’t find an answer?
            </Typography>
            <p>Send us a ticket and we will get back to you.</p>
            <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
         </div>
      </div>
   )
}
export default CreateNewTags