import React from 'react'

const FeatureIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" id="Layer_3" version="1.1">
            <g>
                <polygon fill="#241F20" points="30.367,21.922 16.96,8.113 21.334,22.747 17.885,26.021 31.04,39.887 26.867,25.322  " />
                <path d="M24,0C10.745,0,0,10.745,0,24s10.745,24,24,24s24-10.745,24-24S37.255,0,24,0z M24,44   c-4.756,0-9.119-1.667-12.552-4.439l7.053-7.053l-2.754-2.902l-7.149,7.149C5.727,33.293,4,28.849,4,24C4,12.954,12.954,4,24,4   c4.849,0,9.293,1.727,12.755,4.597l-6.966,6.966l2.781,2.875l6.99-6.99C42.333,14.881,44,19.244,44,24C44,35.046,35.046,44,24,44z" fill="#241F20" />
            </g>
        </svg>
    )
}

export default FeatureIcon