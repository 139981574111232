import { Button, Divider, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, inputBaseClasses, outlinedInputClasses } from '@mui/material'
import React, { useState } from 'react'
import styles from './FiltersDrawer.module.scss' 
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

function FiltersDrawer({days, setDays, daysOption, orderSattus, closeDrawer, ...props}) {

    const [_days, _setDays] = useState(days)
    const [_filters, _setFilters] = useState({
        startDate: props.filters.startDate,
        endDate: props.filters.endDate
    })
    const [_fullFillment, _setFullFillment] = useState(props.fullFillment)
    const [_orderState, _setOrderState] = useState(props.orderState)


  return (
    <div className={styles.filters_wrapper}>
        {(props.isOrderHistory || props.isReports) && !props.isRevenueByDate && (
            <>
                <Select
                    value={_days}
                    onChange={(event) => {
                        _setDays(event.target.value)
                    }}
                    size="small"
                    sx={{
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            border: '1px solid #d3d3d3'
                        },
                        "@media (max-width: 426px)": {
                            width: '100%'
                        }
                    }}
                >
                    {daysOption?.map((data) => (
                        <MenuItem value={data.value}>{data.label}</MenuItem>
                    ))}
                </Select>


                <div className={styles.toolbar_date_item}> 
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div>
                            From
                            <DatePicker
                                value={new Date(_filters.startDate)}
                                onChange={(date) => {
                                    _setFilters({ ..._filters, startDate: date })
                                }}
                                sx={{
                                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                                        border: '1px solid #d3d3d3 !important', 
                                    },
                                    [`& .${outlinedInputClasses.input}`]: {
                                        padding: '10px 14px',
                                    }, 
                                }}
                                maxDate={new Date(_filters.endDate)} 
                            />
                        </div>

                        <div>
                            To
                            <DatePicker
                                className={styles.date_picker}
                                value={new Date(_filters.endDate)}
                                onChange={(date) => {
                                    _setFilters({ ..._filters, endDate: date })
                                }}
                                sx={{
                                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                                        border: '1px solid #d3d3d3 !important', 
                                    },
                                    [`& .${outlinedInputClasses.input}`]: {
                                        padding: '10px 14px',
                                    }, 
                                }}
                                minDate={new Date(_filters.startDate)}
                                maxDate={new Date()} 
                            />
                        </div>
                    </LocalizationProvider>
                </div>
                <Divider sx={{ width: '100%' }} /> 
            </>
        )}

        <div className={styles.toolbar_item}>
                {/* <div>Fulfillment type:</div> */}
                <FormControl>
                    <FormLabel sx={{ color: '#000' }} id="fulfillment_type">Fulfillment type:</FormLabel>
                    <RadioGroup
                        aria-labelledby="fulfillment_type"
                        // defaultValue="female"
                        value={_fullFillment}
                        onChange={(event) => {
                            _setFullFillment(event.target.value)
                        }}
                        name="fulfillment_type"
                    >
                        <FormControlLabel value="none" control={<Radio />} label="All" />
                        <FormControlLabel value="pickup" control={<Radio />} label="Pickup" />
                        <FormControlLabel value="delivery" control={<Radio />} label="Delivery" />
                        <FormControlLabel value="shipping" control={<Radio />} label="Shipping" />
                    </RadioGroup>
                </FormControl>
                <Divider /> 
                <FormControl>
                    <FormLabel sx={{ color: '#000' }} id="order_status">Order status:</FormLabel>
                    <RadioGroup
                        aria-labelledby="order_status" 
                        value={_orderState}
                        onChange={(event) => {
                            _setOrderState(event.target.value)
                        }}
                        name="order_status" 
                    >
                        {orderSattus?.map((data) => (
                            <FormControlLabel value={data.value} control={<Radio />} label={data.label} />
                        ))} 
                    </RadioGroup>
                </FormControl>
        </div> 

        <div className={styles.action_container} >
            <Button
                variant="contained"
                color='primary'
                fullWidth 
                onClick={() => {
                    props.setFullFillment(_fullFillment);
                    props.setOrderState(_orderState);
                    setDays(_days);
                    props.setFilters({ 
                        startDate: _filters.startDate,
                        endDate: _filters.endDate, 
                     });
                    closeDrawer();
                }}
            >
                Apply Filter
            </Button>
            {console.log(
                (props.fullFillment !== 'none') ,
                (props.orderState !== 'none') ,
                (days !== 'thisweek') ,
                (props.filters.startDate !== new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)) ,
                (props.filters.endDate !== new Date())
            )}
        {/* {(
            (props.fullFillment !== 'none') ||
            (props.orderState !== 'none') ||
            ((props.isOrderHistory || props.isReports) && !props.isRevenueByDate  && (
                ((days !== 'thisweek') ||
                (props.filters.startDate !== new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)) ||
                (props.filters.endDate !== new Date()))
            ))
            ) && ( */}
            <Button
                variant="outlined"
                color='primary'
                fullWidth
                onClick={() => {
                    props.setFullFillment('none');
                    props.setOrderState('none');
                    setDays('thisweek');
                    props.setFilters({ 
                        startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                        endDate: new Date(), 
                     });
                    closeDrawer();
                }}
            >
                Clear Filter
            </Button>
        {/* )} */}
            
        </div>
        {/* {isMobileScreen && <Divider sx={{ width: '100%', mb: 4 }} />} */}
        
    </div>
  )
}

export default FiltersDrawer