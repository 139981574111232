import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'

const NameDialog = (props) => {
    return (
        <>
            <DialogTitle
            fontSize={25}
            >
                {props.title}
            </DialogTitle>
            <DialogContent>
                <GenericTextFieldWithLabel
                    label={props.label}
                    value={props.name}
                    onChange={props.onChange}
                    fullWidth={true}
                />
            </DialogContent>
            <DialogActions>
                <GenericLoadingButton
                    variant="contained"
                    onClick={props.onSave}
                    loading={props.isTemplateSave}
                    loadingPosition="end"
                >
                    Save
                </GenericLoadingButton>

                <Button
                    onClick={props.onClose}
                    style={{
                        backgroundColor: "#DDDDDD",
                        color: "#171717",
                    }}
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    )
}

export default NameDialog