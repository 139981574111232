import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import IconButton from "@mui/material/IconButton";
import { getSubstring } from "../../../../utils/getSubstring";
import { getImageUrlDefault } from "../../../../utils/isImageExists";

const useStyles = makeStyles({
    media: {
        width: 50,
        height: 65,
        margin: `0 auto`,
    },
    media2: {
        height: 85,
        width: "70%",
        margin: "0 auto",
        marginTop: '10%'
    },
    actions: {
        alignItems: "flex-end",
        justifyContent: "space-between",
        padding: "2px 8px",
    },
});

const ProductPreviewItem = ({ img, name, venue_name, price, rating, product_image, product_id, productBaseImage }) => {
    const classes = useStyles();
    return (
        <Card
            style={{
                height: "190px",
                border: "1px solid lightgrey",
                display: 'flex',
                fontSize: '.6em',
                margin: '0 3px',
                justifyContent: 'center'
            }}
        >
            <div style={{ display: 'flex', width: '99%', flexDirection: 'column', justifyContent: 'space-between' }}>
                <CardHeader
                    style={{ padding: 0, display: 'flex', flexDirection: 'row-reverse' }}
                    action={
                        <IconButton aria-label="settings">
                            <FavoriteIcon fontSize="small" />
                        </IconButton>
                    }
                />
                <CardMedia
                    className={classes.media}
                    component="img"
                    src={`${productBaseImage}${product_id}/${img}`}
                    onError={e => {
                        e.target.onerror = null
                        e.target.src = 'https://biz-frontend-stag.esplanda.com/images/beer-default.png'
                    }}
                />
                <CardContent style={{ padding: "3px 9px" }}>
                    <Typography
                        style={{ textAlign: "center", fontSize: '10px' }}
                        variant="subtitle"
                        color="textSecondary"
                        component="p"
                    >
                        {name}
                    </Typography>
                    <Typography
                        style={{ fontSize: '10px', textAlign: "center" }}
                        variant="caption"
                        color="textSecondary"
                        component="p"
                    >
                        {venue_name}
                    </Typography>
                </CardContent>
                <CardActions className={classes.actions} disableSpacing>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography style={{ fontWeight: 600, fontSize: 12 }} variant="body1">
                            ${price}
                        </Typography>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "11px",
                                color: "#e12c2c",
                            }}
                        >
                            {Array.from(new Array(5)).map((val, index) => {
                                let icon;
                                if (index <= rating) {
                                    icon = (
                                        <StarIcon
                                            color="inherit"
                                            fontSize="inherit"
                                        />
                                    );
                                } else {
                                    icon = (
                                        <StarBorderIcon
                                            color="inherit"
                                            fontSize="inherit"
                                        />
                                    );
                                }
                                return icon;
                            })}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <ShoppingCartIcon fontSize="small" />
                        <LocationOnIcon fontSize="small" />
                    </div>
                </CardActions>
            </div>
        </Card>
    );
};

const CategoryPreviewItem = (props) => {
    const classes = useStyles();
    return (
        <Card style={{ margin: "0 3px", height: 181, border: "1px solid lightgrey" }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-around' }}>
                <CardMedia
                    className={classes.media2}
                    component="img"
                    src={props.category_img}
                    onError={e => {
                        e.target.onerror = null
                        e.target.src = 'https://biz-frontend-stag.esplanda.com/images/beer-default.png'
                    }}
                />
                <CardContent style={{ padding: '14px 8px' }}>
                    <Typography
                        style={{ textAlign: "center", fontSize: ".8em" }}
                        variant="body1"
                        color="textSecondary"
                    >
                        {props.categoryName}
                    </Typography>
                </CardContent>
            </div>
        </Card>
    );
};

export const Items = {
    CategoryPreviewItem,
    ProductPreviewItem
};
