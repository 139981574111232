import React, { useEffect, useRef, useState } from 'react';
 
import { useNavigate } from 'react-router-dom';

import imageBaseUrl from '../../../Util/imageBaseUrl';
import axios, { nodeAxios } from '../../../Util/axiosConfig';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import styles from './CreateBlogModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Edit, Send } from '@mui/icons-material';
import Loader from '../../../../Library/Loader/Loader';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton';


const validationSchema = yup.object({
  // firstName: yup
  //     .string()
  //     .required('This field is required')
  // ,
});

function convertToURL(title) {
  // Remove leading and trailing whitespaces
  let trimmedTitle = title.trim();

  // Replace spaces with hyphens
  let url = trimmedTitle.replace(/\s+/g, '-');

  // Remove special characters using regular expression
  url = url.replace(/[^a-zA-Z0-9-]/g, '');

  // Convert to lowercase
  url = url.toLowerCase();

  return url;
}

const CreateBlogModal = ({ blog, ...props }) => {
  console.log('🚀OUTPUT --> blog:', blog);
  const navigate = useNavigate()

  console.log(convertToURL(blog?.blogTitle));

  const [addCategory, setAddCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [isAddCategoryLoading, setIsAddCategoryLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);


  const handleImageUploadClick = (event, formik) => {
    formik.setFieldValue('imageFile', event.target.files[0]);
  };

  const createCategory = () => {
    if (newCategory === '') {
      toast.error('Add category name');
      return;
    }

    setIsAddCategoryLoading(true);

    const formData = new FormData();

    formData.append('title', newCategory);

    nodeAxios
      .post(`/web-builder/blog-category/create`, formData)
      .then((response) => {
        toast.success(response?.data?.message);
        setNewCategory('');
        setAddCategory(false);
        setIsAddCategoryLoading(false);
        props?.fetchBlogCategories();
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setNewCategory('');
        setIsAddCategoryLoading(false);
        setAddCategory(false);
        console.log(err);
      });
  };

  const createBlog = (values, status) => {
    const formData = new FormData();
    const blogUrl = convertToURL(values?.blogTitle);

    formData.append('title', values?.blogTitle);
    // formData.append('blog_url', blogUrl);
    if (blog?.contentHtml) {
      formData.append('content', blog?.contentHtml);
    }

    formData.append('status', status);
    if (values?.categories?.length > 0) {
      formData.append(
        `category_ids`,
        values?.categories.filter((e) => e !== '')?.join(',')
      );
    } else if (values?.categories?.length === 0) {
      formData.append(`category_ids`, '');
    }

    formData.append('image_file', values.imageFile);
    console.log('🚀OUTPUT --> formData:', formData);

    let url = `/web-builder/blog/create/v2`;
    if (props.edit) {
      url = `/web-builder/blog/edit/v2/${blog?.id}`;
    }
    
    
          
    
    if(status > 0){
      setIsSaveLoading(true);
    } else {
      setIsDraftLoading(true);
    }
    nodeAxios
    .post(url, formData)
      .then((response) => {
        const blog_id = response?.data?.data?.id;

          // *********************************

          var tempE2 = document.createElement('div');
          const htmlString = `<body id="ihx7"><noscript></noscript><div id="google_translate_element"></div><header id="HEADER"></header><section class="p-2"><div><blog-detail blog_id="${blog_id}" school_id="${localStorage.getItem("venue_id")}"></blog-detail></div></section><footer id="FOOTER"></footer></body>`;
          
          // create a new DOMParser object
          const parser = new DOMParser();
          // parse the HTML string and create a new document object
          const doc = parser.parseFromString(htmlString, 'text/html');

          // get the document element
          const docElement = doc.documentElement;
          
          // console.log(tempE3)
          var newDiv3 = document.createElement('div');
          newDiv3.id = 'google_translate_element';

          var noScript = document.createElement('noscript');
          // var newDiv = document.createElement('header');
          // newDiv.id = 'HEADER';
  
          // var newDiv2 = document.createElement('footer');
          // newDiv2.id = 'FOOTER';

          tempE2 = docElement.querySelector('body');

          tempE2.setAttribute('fe_base_url', (process.env.REACT_APP_API_FE_STORE_URL || 'https://staging-apiv1.esplanda.com'))
          tempE2.setAttribute('venue_id', localStorage.getItem("venue_id"))
          // tempE2.setAttribute('venue_name', userSlice?.user?.school_name)

          // tempE2.insertBefore(newDiv, tempE2.firstChild);

          tempE2.insertBefore(newDiv3, tempE2.firstChild);

          tempE2.insertBefore(noScript, tempE2.firstChild);

          // tempE2.appendChild(newDiv2);
          // ***********************************
          
          console.log('🚀OUTPUT --> tempE2:', tempE2);
        // toast.success(response?.data?.message);
        // props?.fetchBlogs();
        // router.push('/website-builder/blogs/');
        if (
          response?.data?.message?.includes('created') ||
          response?.data?.message?.includes('updated')
        ) {
          let blogAssets = blogUrl + '-assets';
          let blogStyles = blogUrl + '-styles';
          let blogPages = blogUrl + '-pages';
          let blogCss = blogUrl + '-css';
          let blogHtml = blogUrl + '-html';
          const payload = {
            title: values?.blogTitle,
            payload: {
              [blogAssets]: [],
              [blogStyles]: [],
              [blogPages]: JSON.parse(
                `[{"frames":[{"component":{"components":[{"classes":["p-2"],"components":[{"components":[{"attributes":{"blog_id":"${blog_id}","school_id":"${localStorage.getItem("venue_id")}"},"tagName":"blog-detail"}]}],"type":"blogs-detail-page"}],"type":"wrapper"}}],"id":"GETGyZDOLH0hkiEG","type":"main"}]`
              ),
              [blogCss]: '* { box-sizing: border-box; } body {margin: 0;}',
              [blogHtml]: tempE2?.outerHTML,
              'head-params': {
                fe_base_url: process.env.REACT_APP_API_FE_STORE_URL || 'https://staging-apiv1.esplanda.com',
              }
            },
            type_id: '3',
            uri: response?.data?.data?.template_url,
          };
          // console.log('🚀OUTPUT --> payload:', payload);
          let scl_web_page_url = `/web-builder/web-page/create`;
          if (props.edit) {
            scl_web_page_url = `/web-builder/web-page/edit/${blog?.web_page_id}`;
          }

          nodeAxios
            .post(scl_web_page_url, payload)
            .then((resp) => {
              // axios2
              //   .post('http://nodeapi.mykidreports.com/convert-thumbnail', {
              //     web_page_id: resp?.data?.data?.result?.id,
              //   })
              //   .catch((err) => {
              //     // toast.error(err?.data?.message);
              //     console.log(err);
              //   });
              // toast.success(resp?.data?.message);
              // resp?.data?.message?.includes('Created') && 
              if (!props.edit) {
                nodeAxios
                  .post(`/web-builder/blog/edit/${blog_id}`, {
                    title: resp.data?.data?.result?.title,
                    web_page_id: resp.data?.data?.result?.id,
                  })
                  .then((resp) => {
                    toast.success('Blog created successfully.');
                    // toast.success(resp?.data?.message);
                    
                      setIsSaveLoading(false);
                      setIsDraftLoading(false);
                      navigate('/website-builder/blogs/');
                  })
                  .catch((err) => {
                    toast.error(err?.data?.message);
                    console.log(err);
                    setIsSaveLoading(false);
                    setIsDraftLoading(false);
                  });
              } else if (props.edit) {
                setIsSaveLoading(false);
                setIsDraftLoading(false);
                navigate('/website-builder/blogs/');
                // props?.fetchBlogs();
                // props?.closeDialog();
              }
            })
            .catch((err) => {
              toast.error(err?.data?.message);
              console.log(err);
              setIsSaveLoading(false);
              setIsDraftLoading(false);
            });
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        setIsSaveLoading(false);
        setIsDraftLoading(false);
      });
  };

  console.log(props.blogCategories);
  return (
    <div>
      <Formik
        initialValues={
          props.edit
            ? {
                imageFile: null,
                blogTitle: blog?.blogTitle || '',
                categories: blog.categories,
              }
            : {
                imageFile: null,
                blogTitle: blog?.blogTitle || '',
                categories: [],
              }
        }
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let isChildren = false;

          if (values.blogTitle === '') {
            toast.error('Enter blog title');
            return;
          }

          console.log(values);
          console.log(values.categories?.join(','));
          createBlog(values, 1);
        }}
      >
        {(formik) => (
          <form
            style={{ minWidth: '750px' }}
            className={clsx(
              styles.add_edit_childrens
            )}
            onSubmit={formik.handleSubmit}
          >
            <div className={styles.create_blog_form_container}>
              <div
                className={styles.profile_container}
                style={{ width: '35%' }}
              >
                <label className={styles.input_label} htmlFor='blog_cover'>
                  Blog Cover
                </label>
                <div className={styles.upload_image_input_container}>
                  <input
                    style={{
                      display: 'none',
                    }}
                    id='image-upload'
                    type='file'
                    accept='image/*'
                    onChange={(event) => handleImageUploadClick(event, formik)}
                  />

                  <label
                    htmlFor='image-upload'
                    className={
                      styles.personal_info_image_upload_label
                    }
                    style={{ width: '100%', height: 'auto' }}
                  >
                    {formik.values.imageFile || blog?.imageFile ? (
                      <>
                        <div className={styles.upload_image_preview}>
                          <img
                            // className={styles.personal_info_image}
                            src={
                              formik.values.imageFile
                                ? URL.createObjectURL(formik.values.imageFile)
                                : props.edit
                                ? `${imageBaseUrl}/SchoolWebPage/${blog?.imageFile}`
                                : ''
                            }
                            height={100}
                            width={100}
                            objectFit='cover'
                          />
                        </div>

                        <div
                          className={styles.edit_circle}
                          style={{ bottom: '12px' }}
                        >
                          <Edit
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.upload_image_label}>
                          <div className={styles.upload_image_btn}>
                            Upload Image
                          </div>
                          <div>Minimum size of 640×360</div>
                        </div>
                      </>
                    )}
                  </label>
                </div>
              </div>

              <div className={styles.blog_form_fields} style={{ width: '65%' }}>
                <div>
                  <label className={styles.input_label} htmlFor='blogTitle'>
                    Title
                  </label>
                  <TextField
                    InputProps={{
                      classes: {
                        notchedOutline: styles.input_box_style,
                        root: styles.form_input_root,
                        input: styles.form_input_element_style,
                      },
                    }}
                    placeholder='Give your project a title'
                    variant='outlined'
                    id='blogTitle'
                    name='blogTitle'
                    type='text'
                    fullWidth
                    value={formik.values.blogTitle}
                    onChange={formik.handleChange}
                    error={formik.touched.blogTitle}
                    helperText={formik.touched.blogTitle}
                    required
                  />
                </div>
                <div>
                  <label className={styles.input_label} htmlFor='categories'>
                    How would you categorize this blog?
                  </label>
                  <FieldArray
                    name='categories'
                    render={(arrayHelpers) => (
                      <div className={styles.family_members_containers}>
                        {!props?.blogCategories ? (
                          <Loader />
                        ) : (
                          props?.blogCategories &&
                          props?.blogCategories?.data?.length > 0 && (
                            <FormGroup
                              classes={{ root: styles.categories_container }}
                            >
                              {props?.blogCategories?.data?.map(
                                (category, index) => (
                                  <FormControlLabel
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        `categories`,
                                        event.target.checked
                                          ? [
                                              ...formik.values.categories,
                                              category.id,
                                            ]
                                          : formik.values.categories.filter(
                                              (e) => e !== category.id
                                            )
                                      );
                                    }}
                                    name={category.title}
                                    control={
                                      <Checkbox
                                        checked={formik.values.categories?.find(
                                          (e) => e == category.id
                                        )}
                                        size='small'
                                        sx={{ color: 'black !important' }}
                                      />
                                    }
                                    classes={{
                                      label: styles.checkbox_label,
                                      root: styles.category_item_root,
                                    }}
                                    title={category.title}
                                    label={category.title.substring(0, 15)}
                                  />
                                )
                              )}
                            </FormGroup>
                          )
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className={styles.add_category_row}>
                  {!addCategory ? (
                    <Button
                      classes={{ root: styles.add_category_btn }}
                      onClick={() => setAddCategory(true)}
                    >
                      <Add /> Add category
                    </Button>
                  ) : (
                    <>
                      <div className={styles.add_category_field}>
                        <TextField
                          InputProps={{
                            classes: {
                              notchedOutline: styles.input_box_style,
                              root: styles.form_input_root,
                              input: styles.form_input_element_style,
                            },
                          }}
                          placeholder='Category name..'
                          variant='outlined'
                          id='title'
                          name='title'
                          type='text'
                          fullWidth
                          value={newCategory}
                          onChange={(e) => setNewCategory(e.target.value)}
                          required
                        />
                      </div>
                      <GenericLoadingButton
                        classes={{ root: styles.save_category_btn }}
                        // type='submit'
                        variant='contained'
                        loading={isAddCategoryLoading}
                        onClick={createCategory}
                        loadingPosition='end'
                        size='medium'
                      >
                        Save
                      </GenericLoadingButton>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.save_cancel_btn_container}>
              <Button
                variant='outlined'
                onClick={() => props.setIsAddUserDialogOpen(false)}
                style={{
                  width: 'auto',
                  height: '35px',
                  border: 'none',
                  borderRadius: '60px',
                  color: 'black',
                }}
              >
                Cancel
              </Button>
              {props.edit ? (
                <GenericLoadingButton
                  classes={{ root: styles.primary_btn }}
                  onClick={() => createBlog(formik.values, blog?.status)}
                  variant='contained'
                  loadingPosition='end'
                  loading={isSaveLoading}
                  size='medium'
                >
                  Save
                </GenericLoadingButton>
              ) : (
                <>
                  <GenericLoadingButton
                    classes={{ root: styles.secondary_btn }}
                    // type='submit'
                    variant='contained'
                    // loading={postChildrenCreate.isLoading}
                    onClick={() => createBlog(formik.values, 0)}
                    loadingPosition='end'
                    size='medium'
                    loading={isDraftLoading}
                  >
                    Save as draft
                  </GenericLoadingButton>
                  <GenericLoadingButton
                    classes={{ root: styles.primary_btn }}
                    type='submit'
                    variant='contained'
                    loading={isSaveLoading}
                    loadingPosition='end'
                    size='medium'
                  >
                    <Send />
                    Publish
                  </GenericLoadingButton>
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateBlogModal;
