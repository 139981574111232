import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, TextField } from '@mui/material';
import React, { useState } from 'react'
import styles from './EditMenuLinkDialog.module.scss'
import { DeleteForever } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import GenericTextFieldWithLabel from '../GenericTextFieldWithLabel/GenericTextFieldWithLabel';
import LinkSelector from '../LinkSelector/LinkSelector';
import ImageUpload from '../ImageUpload/ImageUpload';

const EditMenuLinkDialog = (props) => {
    const confirm = useConfirm()
    const [isDropdown, setIsDropdown] = useState(Boolean(props.menu[props.editIndex]?.child?.length))

    const handleDropdownOff = () => {
        confirm({
            title: 'Do you wish to disable dropdown',
            description: 'You will loose your sub menu data'
        })
            .then(() => {
                props.setMenu((prevState) => {
                    let newState = [...prevState]
                    let obj = newState[props.editIndex]

                    obj.child = []

                    if (newState[props.editIndex] && obj) {
                        newState[props.editIndex] = obj
                    }
                    return newState
                })
                setIsDropdown(false)
            })
            .catch(() => {
            });
    };

    return (
        <>
            <DialogTitle
                align='left'
                fontWeight={600}
                fontSize={14}
                borderBottom="1px solid #D3D3D3"
                style={{
                    padding: 10,
                    marginBottom: 15
                }}
            >
                Edit menu link
            </DialogTitle>
            <DialogContent
                style={{
                    minWidth: 430
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={isDropdown}
                                    size="small"
                                    onClick={(event) => {
                                        if (event.target.checked) {
                                            setIsDropdown(event.target.checked)
                                            props.setMenu((prevState) => {
                                                let newState = [...prevState]
                                                let obj = newState[props.editIndex]

                                                if (!obj.child?.length) {
                                                    obj.child = []
                                                }

                                                obj.child?.push({
                                                    title: 'Label',
                                                    uri: 'Uri'
                                                })

                                                if (newState[props.editIndex] && obj) {
                                                    newState[props.editIndex] = obj
                                                }
                                                return newState
                                            })
                                        } else {
                                            handleDropdownOff()
                                        }
                                    }}
                                />
                            )}
                            label="Dropdown"
                        />
                    </FormGroup>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20
                    }}
                >

                    <GenericTextFieldWithLabel
                        variant="outlined"
                        label="Title"
                        value={props.menu[props.editIndex]?.title}
                        onChange={(event) => {
                            props.setMenu((prevState) => {
                                let newState = [...prevState]
                                let obj = newState[props.editIndex]
                                if (newState[props.editIndex] && obj) {
                                    newState[props.editIndex] = {
                                        ...obj,
                                        title: event.target.value
                                    }
                                }
                                return newState
                            })
                        }}
                        fullWidth={true}
                    />
                    {props.traits?.map((trait) => (
                        <>
                            {{
                                'menulabel': (
                                    <GenericTextFieldWithLabel
                                        variant="outlined"
                                        label="Label"
                                        value={props.menu[props.editIndex]?.label}
                                        onChange={(event) => {
                                            props.setMenu((prevState) => {
                                                let newState = [...prevState]
                                                let obj = newState[props.editIndex]
                                                if (newState[props.editIndex] && obj) {
                                                    newState[props.editIndex] = {
                                                        ...obj,
                                                        label: event.target.value
                                                    }
                                                }
                                                return newState
                                            })
                                        }}
                                        fullWidth={true}
                                    />
                                ),
                                'menudescription': (
                                    <GenericTextFieldWithLabel
                                        variant="outlined"
                                        label="Description"
                                        value={props.menu[props.editIndex]?.description}
                                        onChange={(event) => {
                                            props.setMenu((prevState) => {
                                                let newState = [...prevState]
                                                let obj = newState[props.editIndex]
                                                if (newState[props.editIndex] && obj) {
                                                    newState[props.editIndex] = {
                                                        ...obj,
                                                        description: event.target.value
                                                    }
                                                }
                                                return newState
                                            })
                                        }}
                                        fullWidth={true}
                                    />
                                ),
                            }[trait.name]}
                        </>
                    ))}

                    {isDropdown ? (

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 10,
                            }}
                        >
                            {props.menu[props.editIndex]?.child?.map((member, mindex) => (
                                <div className={styles.submenu_item}>
                                    {props.menu[props.editIndex]?.child?.length > 1 && (
                                        <div
                                            onClick={() => {
                                                props.setMenu((prevState) => {
                                                    let newState = [...prevState]
                                                    let obj = newState[props.editIndex]
                                                    obj.child?.splice(mindex, 1)

                                                    if (newState[props.editIndex] && obj) {
                                                        newState[props.editIndex] = obj
                                                    }
                                                    return newState
                                                })
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                color: 'red'
                                            }}
                                        >
                                            <DeleteForever />
                                        </div>
                                    )}
                                    <GenericTextFieldWithLabel
                                        variant="outlined"
                                        label="Sub menu label"
                                        value={member?.title}
                                        onChange={(event) => {
                                            props.setMenu((prevState) => {
                                                let newState = [...prevState]
                                                let obj = newState[props.editIndex]
                                                obj.child[mindex] = {
                                                    ...obj?.child[mindex],
                                                    title: event.target.value
                                                }

                                                if (newState[props.editIndex] && obj) {
                                                    newState[props.editIndex] = obj
                                                }
                                                return newState
                                            })
                                        }}
                                        fullWidth={true}
                                    />

                                    {props.traits?.map((trait) => (
                                        <>
                                            {{
                                                'menuchilddescription': (
                                                    <GenericTextFieldWithLabel
                                                        variant="outlined"
                                                        label="Sub menu Description"
                                                        value={member?.description}
                                                        onChange={(event) => {
                                                            props.setMenu((prevState) => {
                                                                let newState = [...prevState]
                                                                let obj = newState[props.editIndex]
                                                                obj.child[mindex] = {
                                                                    ...obj?.child[mindex],
                                                                    description: event.target.value
                                                                }

                                                                if (newState[props.editIndex] && obj) {
                                                                    newState[props.editIndex] = obj
                                                                }
                                                                return newState
                                                            })
                                                        }}
                                                        fullWidth={true}
                                                    />
                                                ),
                                                'menuchildimage': (
                                                    <ImageUpload
                                                        title="Sub menu image"
                                                        imageSrc={member?.image}
                                                        setImageSrc={() => { }}
                                                        onUpload={(link) => {
                                                            props.setMenu((prevState) => {
                                                                let newState = [...prevState]
                                                                let obj = newState[props.editIndex]
                                                                obj.child[mindex] = {
                                                                    ...obj?.child[mindex],
                                                                    image: link
                                                                }

                                                                if (newState[props.editIndex] && obj) {
                                                                    newState[props.editIndex] = obj
                                                                }
                                                                return newState
                                                            })
                                                        }}
                                                        onImageLinkChange={(event) => {

                                                        }}
                                                        onImageTargetChange={(event) => {

                                                        }}
                                                        imageLink={''}
                                                        imageTarget={''}
                                                        pages={[]}
                                                        onDelete={() => {
                                                            props.setMenu((prevState) => {
                                                                let newState = [...prevState]
                                                                let obj = newState[props.editIndex]
                                                                obj.child[mindex] = {
                                                                    ...obj?.child[mindex],
                                                                    image: ''
                                                                }

                                                                if (newState[props.editIndex] && obj) {
                                                                    newState[props.editIndex] = obj
                                                                }
                                                                return newState
                                                            })
                                                        }}
                                                    />
                                                ),
                                            }[trait.name]}
                                        </>
                                    ))}

                                    <LinkSelector
                                        variant="outlined"
                                        label="Link To"
                                        placeholder="Your store name"
                                        value={member?.uri}
                                        tab={member?.tab}
                                        onTabChange={(checked) => {
                                            props.setMenu((prevState) => {
                                                let newState = [...prevState]
                                                let obj = newState[props.editIndex]
                                                obj.child[mindex] = {
                                                    ...obj?.child[mindex],
                                                    tab: checked
                                                }

                                                if (newState[props.editIndex] && obj) {
                                                    newState[props.editIndex] = obj
                                                }
                                                return newState
                                            })
                                        }}
                                        onChange={(event) => {
                                            props.setMenu((prevState) => {
                                                let newState = [...prevState]
                                                let obj = newState[props.editIndex]
                                                obj.child[mindex] = {
                                                    ...obj?.child[mindex],
                                                    uri: event.target.value
                                                }

                                                if (newState[props.editIndex] && obj) {
                                                    newState[props.editIndex] = obj
                                                }
                                                return newState
                                            })
                                        }}
                                        fullWidth={true}
                                        pages={props.pages}
                                    />

                                </div>
                            ))}

                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: 20,
                                    justifyContent: 'flex-end',
                                    gap: 10,
                                }}
                            >
                                <Button
                                    variant='contained'
                                    onClick={() =>
                                        props.setMenu((prevState) => {
                                            let newState = [...prevState]
                                            let obj = newState[props.editIndex]

                                            if (!obj.child) {
                                                obj.child = []
                                            }

                                            obj.child?.push({
                                                title: 'Label',
                                                uri: 'Uri'
                                            })

                                            if (newState[props.editIndex] && obj) {
                                                newState[props.editIndex] = obj
                                            }
                                            return newState
                                        })
                                    }
                                >
                                    Add submenu
                                </Button>
                            </div>
                        </div>

                    ) : (
                        <LinkSelector
                            variant="outlined"
                            label="Link To"
                            placeholder="Your store name"
                            value={props.menu[props.editIndex]?.uri}
                            tab={props.menu[props.editIndex]?.tab}
                            onTabChange={(checked) => {
                                props.setMenu((prevState) => {
                                    let newState = [...prevState]
                                    let obj = newState[props.editIndex]
                                    if (newState[props.editIndex] && obj) {
                                        newState[props.editIndex] = {
                                            ...obj,
                                            tab: checked
                                        }
                                    }
                                    return newState
                                })
                            }}
                            onChange={(event) => {
                                props.setMenu((prevState) => {
                                    let newState = [...prevState]
                                    let obj = newState[props.editIndex]
                                    if (newState[props.editIndex] && obj) {
                                        newState[props.editIndex] = {
                                            ...obj,
                                            uri: event.target.value
                                        }
                                    }
                                    return newState
                                })
                            }}
                            fullWidth={true}
                            pages={props.pages}
                        />
                    )}


                </div>

            </DialogContent>
            <DialogActions>
                <Button
                    style={{
                        backgroundColor: '#EEEEEE',
                        color: 'black',
                        paddingLeft: 16,
                        paddingRight: 16
                    }}
                    variant='text'
                    onClick={props.onDelete}
                >
                    Discard
                </Button>
                <Button
                    style={{
                        paddingLeft: 25,
                        paddingRight: 25,
                        background: 'linear-gradient(180deg, #2993FF 0.83%, #036FDE 100%)'
                    }}
                    variant='contained'
                    onClick={props.onClose}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    )
}

export default EditMenuLinkDialog