import React, { useState } from 'react'
import { cns, classname } from '../../utils/bem'
import { Link } from 'react-router-dom';
import './PosIntegrations.scss'
import posintegration from "../../assets/pos-integration.jpg";
import cloudbaselogo1 from "../../assets/cloud-base-logo-1.jpg";
import cloudbaselogo2 from "../../assets/cloud-base-logo-2.jpg";
import cloudbaselogo3 from "../../assets/cloud-base-logo-3.jpg";
import cloudbaselogo4 from "../../assets/cloud-base-logo-4.jpg";
import cloudbaselogo5 from "../../assets/cloud-base-logo-5.jpg";
import cloudbaselogo6 from "../../assets/cloud-base-logo-6.jpg";
import cloudbaselogo7 from "../../assets/cloud-base-logo-7.jpg";
import cloudbaselogo8 from "../../assets/cloud-base-logo-8.jpg";
import cloudbaselogo9 from "../../assets/cloud-base-logo-9.jpg";
import cloudbaselogo10 from "../../assets/cloud-base-logo-10.jpg";
import cloudbaselogo11 from "../../assets/cloud-base-logo-11.jpg";
import cloudbaselogo12 from "../../assets/cloud-base-logo-12.jpg";
import cloudbaselogo13 from "../../assets/cloud-base-logo-13.jpg";
import cloudbaselogo14 from "../../assets/cloud-base-logo-14.jpg";
import cloudbaselogo15 from "../../assets/cloud-base-logo-15.jpg";
import cloudbaselogo16 from "../../assets/cloud-base-logo-16.jpg";
import cloudbaselogo17 from "../../assets/cloud-base-logo-17.jpg";
import cloudbaselogo18 from "../../assets/cloud-base-logo-18.jpg";
import cloudbaselogo19 from "../../assets/cloud-base-logo-19.jpg";
import cloudbaselogo20 from "../../assets/cloud-base-logo-20.jpg";
import cloudbaselogo21 from "../../assets/cloud-base-logo-21.jpg";
import cloudbaselogo22 from "../../assets/cloud-base-logo-22.jpg";
import cloudbaselogo23 from "../../assets/cloud-base-logo-23.jpg";
import cloudbaselogo24 from "../../assets/cloud-base-logo-24.jpg";
import cloudbaselogo25 from "../../assets/cloud-base-logo-25.jpg";
import cloudbaselogo26 from "../../assets/cloud-base-logo-26.jpg";
import cloudbaselogo27 from "../../assets/cloud-base-logo-27.jpg";
import cloudbaselogo28 from "../../assets/cloud-base-logo-28.jpg";
import cloudbaselogo29 from "../../assets/cloud-base-logo-29.jpg";
import cloudbaselogo30 from "../../assets/cloud-base-logo-30.jpg";
import onsitelogo2 from "../../assets/on-site-logo-2.jpg";
import onsitelogo3 from "../../assets/on-site-logo-3.jpg";
import onsitelogo4 from "../../assets/on-site-logo-4.jpg";
import onsitelogo5 from "../../assets/on-site-logo-5.jpg";
import onsitelogo6 from "../../assets/on-site-logo-6.jpg";
import onsitelogo7 from "../../assets/on-site-logo-7.jpg";
import onsitelogo8 from "../../assets/on-site-logo-8.jpg";
import onsitelogo9 from "../../assets/on-site-logo-9.jpg";
import onsitelogo10 from "../../assets/on-site-logo-10.jpg";
import onsitelogo11 from "../../assets/on-site-logo-11.jpg";
import onsitelogo12 from "../../assets/on-site-logo-12.jpg";
import onsitelogo13 from "../../assets/on-site-logo-13.jpg";
import onsitelogo14 from "../../assets/on-site-logo-14.jpg";
import onsitelogo15 from "../../assets/on-site-logo-15.jpg";
import onsitelogo16 from "../../assets/on-site-logo-16.jpg";
import onsitelogo17 from "../../assets/on-site-logo-17.jpg";
import onsitelogo18 from "../../assets/on-site-logo-18.jpg";
import onsitelogo19 from "../../assets/on-site-logo-19.jpg";
import onsitelogo20 from "../../assets/on-site-logo-20.jpg";
import onsitelogo21 from "../../assets/on-site-logo-21.jpg";
import onsitelogo22 from "../../assets/on-site-logo-22.jpg";
import onsitelogo23 from "../../assets/on-site-logo-23.jpg";
import onsitelogo24 from "../../assets/on-site-logo-24.jpg";
import onsitelogo25 from "../../assets/on-site-logo-25.jpg";
import onsitelogo26 from "../../assets/on-site-logo-26.jpg";
import onsitelogo27 from "../../assets/on-site-logo-27.jpg";
import onsitelogo28 from "../../assets/on-site-logo-28.jpg";
import onsitelogo29 from "../../assets/on-site-logo-29.jpg";
import onsitelogo30 from "../../assets/on-site-logo-30.jpg";
import onsitelogo31 from "../../assets/on-site-logo-31.jpg";
import onsitelogo32 from "../../assets/on-site-logo-32.jpg";
import onsitelogo33 from "../../assets/on-site-logo-33.jpg";
import onsitelogo34 from "../../assets/on-site-logo-34.jpg";
import onsitelogo35 from "../../assets/on-site-logo-35.jpg";
import onsitelogo36 from "../../assets/on-site-logo-36.jpg";
import onsitelogo37 from "../../assets/on-site-logo-37.jpg";
import onsitelogo38 from "../../assets/on-site-logo-38.jpg";
import onsitelogo39 from "../../assets/on-site-logo-39.jpg";
import onsitelogo40 from "../../assets/on-site-logo-40.jpg";
import onsitelogo41 from "../../assets/on-site-logo-41.jpg";
import onsitelogo42 from "../../assets/on-site-logo-42.jpg";
import onsitelogo43 from "../../assets/on-site-logo-43.jpg";
import onsitelogo44 from "../../assets/on-site-logo-44.jpg";
import onsitelogo46 from "../../assets/on-site-logo-46.jpg";
import onsitelogo47 from "../../assets/on-site-logo-47.jpg";
import onsitelogo48 from "../../assets/on-site-logo-48.jpg";
import onsitelogo49 from "../../assets/on-site-logo-49.jpg";
import onsitelogo50 from "../../assets/on-site-logo-50.jpg";
import onsitelogo51 from "../../assets/on-site-logo-51.jpg";
import onsitelogo52 from "../../assets/on-site-logo-52.jpg";
import onsitelogo53 from "../../assets/on-site-logo-53.jpg";
import onsitelogo54 from "../../assets/on-site-logo-54.jpg";
import onsitelogo55 from "../../assets/on-site-logo-55.jpg";
import onsitelogo56 from "../../assets/on-site-logo-56.jpg";
import onsitelogo57 from "../../assets/on-site-logo-57.jpg";
import GetStarted from "../../util_comps/GetStarted";
import { useNavigate } from 'react-router-dom';
import Const from '../helpers/const';



const PosIntegrations = (props) => {
    const cn = classname('pos-integrations')
    const history = useNavigate()

    const [cloudbase, setCloudbase] = useState(true);
    return (
        <div
            className={cns(
                cn(),
                props.className,
            )}
        >

            <div
                className={cn('pos-integrations-tabs-container')}
            >

                <div
                    className={cn('main-section-tabs-container')}
                >
                    <h3
                        className={cn('pos-integrations-h3-title-1')}
                    >
                        Stress-free POS Integration
                    </h3>
                    <h3
                        className={cn('pos-integrations-h3-title')}
                    >
                        If you don’t see one you want, just drop us a note and we’ll make it happen.
                    </h3>

                    <div className={cn('section-tabs-container')}>

                        <button
                            className={cn('cloud-based-button', {
                                selected: cloudbase
                            })}
                            onClick={() => setCloudbase(true)}
                        >
                            Cloud Based Integration​s
                        </button>

                        <button
                            className={cn('on-site-button', {
                                selected: !cloudbase
                            })}
                            onClick={() => setCloudbase(false)}
                        >
                            On Site Integration​s
                        </button>

                        {cloudbase ? (
                            <>
                                <div className={cn('logo-section-tabs-container')}>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.lightspeedhq.com/" target='_blank'><img src={cloudbaselogo1} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.clover.com/" target='_blank'><img src={cloudbaselogo2} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.shopify.com/" target='_blank'><img src={cloudbaselogo3} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://squareup.com/us/en/point-of-sale" target='_blank'><img src={cloudbaselogo4} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://bindolabs.com/" target='_blank'><img src={cloudbaselogo5} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.bigcommerce.com/apps/point-of-sale/" target='_blank'><img src={cloudbaselogo6} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.lightspeedhq.com/shopkeep/" target='_blank'><img src={cloudbaselogo7} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.vendhq.com/asia/" target='_blank'><img src={cloudbaselogo8} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://ehopper.com/" target='_blank'><img src={cloudbaselogo9} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.talech.com/" target='_blank'><img src={cloudbaselogo10} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.ncr.com/" target='_blank'><img src={cloudbaselogo11} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://albertapayments.com/" target='_blank'><img src={cloudbaselogo12} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.appcard.com/" target='_blank'><img src={cloudbaselogo13} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.bottlepos.com/" target='_blank'><img src={cloudbaselogo14} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.cloudretailer.com/" target='_blank'><img src={cloudbaselogo15} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://comcash.com/" target='_blank'><img src={cloudbaselogo16} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.pdicstoreessentials.com/" target='_blank'><img src={cloudbaselogo17} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.eposnow.com/us/" target='_blank'><img src={cloudbaselogo18} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://erply.com/" target='_blank'><img src={cloudbaselogo19} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://koronapos.com/" target='_blank'><img src={cloudbaselogo20} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.modisoft.com/" target='_blank'><img src={cloudbaselogo21} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.computerperfect.com/" target='_blank'><img src={cloudbaselogo22} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.spoton.com/" target='_blank'><img src={cloudbaselogo23} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.netsuite.com/portal/home.shtml" target='_blank'><img src={cloudbaselogo24} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.harbortouch.com/" target='_blank'><img src={cloudbaselogo25} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://nrsplus.com/" target='_blank'><img src={cloudbaselogo26} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://revelsystems.com/" target='_blank'><img src={cloudbaselogo27} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.pomodosoftware.com/" target='_blank'><img src={cloudbaselogo28} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.hotelkeyapp.com/" target='_blank'><img src={cloudbaselogo29} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.zoho.com/us/inventory/" target='_blank'><img src={cloudbaselogo30} /></a>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={cn('logo-section-tabs-container')}>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.lightspeedhq.com/" target='_blank'><img src={cloudbaselogo1} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.liquorpos.com/" target='_blank'><img src={onsitelogo2} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.posmatic.com/point-of-sale/pos-software/onsight-pos.php" target='_blank'><img src={onsitelogo3} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.winepos.com/" target='_blank'><img src={onsitelogo4} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://quickbooks.intuit.com/desktop/point-of-sale/" target='_blank'><img src={onsitelogo5} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://business.adobe.com/products/magento/magento-commerce.html" target='_blank'><img src={onsitelogo6} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://rioinfotech.com/wine-soft.html" target='_blank'><img src={onsitelogo7} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://cispl.com/" target='_blank'><img src={onsitelogo8} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://atlanticsystemsinc.com/product/spirits-2000/" target='_blank'><img src={onsitelogo9} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://posim.com/" target='_blank'><img src={onsitelogo10} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://thegeneralstore.com/" target='_blank'><img src={onsitelogo11} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.magstarinc.com/retail-management-solutions/point-of-sale" target='_blank'><img src={onsitelogo12} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.heartlandpaymentsystems.com/products/point-of-sale/restaurant/pcamerica" target='_blank'><img src={onsitelogo13} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://dynamics.microsoft.com/en-gb/" target='_blank'><img src={onsitelogo14} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.mpowerbeverage.com/" target='_blank'><img src={onsitelogo15} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.rdtsystems.com/products_point_of_sale.html" target='_blank'><img src={onsitelogo16} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.adventpos.com/" target='_blank'><img src={onsitelogo17} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.capretail.com/" target='_blank'><img src={onsitelogo18} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.ezsoftwaresolutions.net/EZ-Retail" target='_blank'><img src={onsitelogo19} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://jmscpos.com/" target='_blank'><img src={onsitelogo20} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.tamretail.com/" target='_blank'><img src={onsitelogo21} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://tigerpos.com/" target='_blank'><img src={onsitelogo22} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.ncr.com/point-of-sale-pos-systems" target='_blank'><img src={cloudbaselogo11} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.m988.com/index.html" target='_blank'><img src={onsitelogo23} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://certek.com/products/artisan-pos-software/" target='_blank'><img src={onsitelogo24} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.biztracker.com/biztracker-infinity-pos" target='_blank'><img src={onsitelogo25} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://cafecartel.com/" target='_blank'><img src={onsitelogo26} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://web.futurepos.com/" target='_blank'><img src={onsitelogo27} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://cornerstorepos.com/" target='_blank'><img src={onsitelogo28} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.dhpos.com/pos.htm" target='_blank'><img src={onsitelogo29} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.ecrs.com/retail-pos/" target='_blank'><img src={onsitelogo30} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.keystrokepos.com/" target='_blank'><img src={onsitelogo31} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://infotouch.com/" target='_blank'><img src={onsitelogo32} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://itretail.com/" target='_blank'><img src={onsitelogo33} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://main.korusbiz.com/retail-pos/" target='_blank'><img src={onsitelogo34} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.naucomputer.com/" target='_blank'><img src={onsitelogo35} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.logivision.com/" target='_blank'><img src={onsitelogo36} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://nrsplus.com/" target='_blank'><img src={onsitelogo56} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://microbiz.com/windows-pos-tour/" target='_blank'><img src={onsitelogo37} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.poswithlogic.com/" target='_blank'><img src={onsitelogo38} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://ospreyretail.com/products/pos" target='_blank'><img src={onsitelogo39} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.pomodosoftware.com/" target='_blank'><img src={onsitelogo40} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.pos-plus.com/" target='_blank'><img src={onsitelogo41} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.rocketpos.co.nz/pos-system-for-liquor.html" target='_blank'><img src={onsitelogo42} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.positive.software/positive-for-retail" target='_blank'><img src={onsitelogo43} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.apipos.com/" target='_blank'><img src={onsitelogo44} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.retailpro.com/" target='_blank'><img src={onsitelogo46} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.sunsmartpos.com/" target='_blank'><img src={onsitelogo47} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.dumac.com/pos-software-for-supermarkets-grocery-stores-dumac" target='_blank'><img src={onsitelogo48} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.saitecpos.com/" target='_blank'><img src={onsitelogo49} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="http://www.locsoftware.com/" target='_blank'><img src={onsitelogo50} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.windwardsoftware.com/en-US/" target='_blank'><img src={onsitelogo51} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.thegeneralstore.com/" target='_blank'><img src={onsitelogo52} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.komachine.com/en/companies/techscan-korea/" target='_blank'><img src={onsitelogo53} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.computer-rx.com/pharmacy-solutions/pos" target='_blank'><img src={onsitelogo54} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.windwardsoftware.com/en-US/" target='_blank'><img src={onsitelogo55} /></a>
                                    </div>
                                    <div className={cn('logo-image')}>
                                        <a href="https://www.storetenderonline.com/" target='_blank'><img src={onsitelogo57} /></a>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>


                <div className={cn('grey-section-tabs-container')}>
                    <h5 className={cn('h5-title')}>
                        <strong>Our Customers</strong> increase their basket size by 35% within
                     
                        one month after going live!
                    </h5>


                    <Link
                        to="/signup"
                        className={cn('Get-Started')}
                    >
                        Get Started
                    </Link>


                </div>







            </div>




        </div>
    )
}

export default PosIntegrations
