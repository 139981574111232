import { CircularProgress } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import routePaths from '../../config/route-paths'
import GenericSwitch from '../Library/GenericSwitch/GenericSwitch'
import TabsSection from '../Library/TabsSection/TabsSection'
import EmailAnalytics from './EmailAnalytics/EmailAnalytics'
import EmailCampaign from './EmailCampaign/EmailCampaign'
import styles from './EmailMarketing.module.scss'
import LaunchCampaign from './LaunchCampaign/LaunchCampaign'
import axios from '../../utils/axiosConfig'

const tabs = [
    {
        label: 'Email Analytics'
    },
    // {
    //     label: 'Launch Campaign'
    // },
    {
        label: 'Email Campaign'
    }
]

const EmailMarketing = () => {

    const navigate = useNavigate()
    const { isEmailConnected, handleMailChimpDisconnect } = useOutletContext()
    const [isMailChimpLoading, setIsMailChimpLoading] = useState(false)
    const [campaignList, setCampaignList] = useState([])

    const stopMailChimpLoading = () => {
        setIsMailChimpLoading(false)
        navigate(routePaths.emailConnect)
    }
    const connections = useMemo(() => [
        {
            title: 'MailChimp',
            background: '#000000',
            checked: isEmailConnected,
            isLoading: isMailChimpLoading,
            onChange: () => {
                if (isEmailConnected) {
                    setIsMailChimpLoading(true)
                    handleMailChimpDisconnect(stopMailChimpLoading, setIsMailChimpLoading)
                } else {
                    navigate(routePaths.emailConnect)
                }
            }
        }
    ], [isMailChimpLoading])

    const fetchCampaigns = () => {
        axios.get('/mailchimp-campaign/campaign-list')
            .then((response) => {
                setCampaignList(response.data.data.result.campaigns.map((data) => {

                    let day = new Date(data.send_time).getDate()
                    let month = new Date(data.send_time).getMonth() + 1
                    let year = new Date(data.send_time).getFullYear()

                    let starthrs = new Date(data.send_time).getHours()
                    let startmin = new Date(data.send_time).getMinutes()

                    let endhrs = new Date(data.send_time).getHours() + 5
                    let endmin = new Date(data.send_time).getMinutes()

                    return ({
                        id: data.id,
                        title: data.settings.subject_line,
                        start: new Date(year, parseInt(month) - 1, day, starthrs, startmin),
                        end: new Date(year, parseInt(month) - 1, day, endhrs, endmin)
                    })
                }))
            })
    }

    useEffect(() => {
        fetchCampaigns()

    }, [])


    return (
        <div>
            <div
                className={styles.title_container}
            >
                <div
                    className={styles.title}
                >
                    Email Marketing
                </div>

                <div
                    className={styles.swicth_section}
                >
                    {connections.map((data) => (
                        <div
                            className={styles.switch_container}
                            key={data.title}
                        >
                            <div
                                className={styles.switch_label}
                            >
                                {data.title}
                            </div>
                            <GenericSwitch
                                background={data.background}
                                width={108}
                                height={24}
                                thumb={19}
                                isStatus={true}
                                checked={data.checked}
                                onChange={data.onChange}
                            />

                            {data.isLoading && (
                                <CircularProgress style={{ marginLeft: 6 }} size={15} />
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <TabsSection
                tabs={tabs}
                tabsBody={[
                    {
                        component: <EmailAnalytics />
                    },
                    // {
                    //     component: (
                    //         <LaunchCampaign />
                    //     )
                    // },
                    {
                        component: (
                            <EmailCampaign
                                campaignList={campaignList}
                                fetchCampaigns={fetchCampaigns}
                            />
                        )
                    }
                ]}
            />
        </div>
    )
}

export default EmailMarketing