import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import routePaths from '../../../config/route-paths';
import assigntagstoproductimg from "../../../assets/assign-tags-to-product.png";
import assigntagstoproductdesistoreimg from "../../../assets/assign-tags-to-product-desistore.png";
const AssignTagstoProduct = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Product & Inventory               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.addNewProduct}>How to Add New Products</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.createNewTags} >Create New Tags</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.assigntagstoproduct} className={cn('selected')}>Assign Tag to Product</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.uploadInventory}>Upload Inventory</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.searchProductbyCategory}>Search Product by Category</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.editProductAttributes}>Edit Product Attributes</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.changingItemVisiblity}>Changing Item Visiblity</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Assign Tags to Product        
         </Typography>
         <p><strong>Item tags are useful for organizing products into your own custom categories, such as seasonal or promotional items.</strong></p>
         
         <p>
            
         <ol>
            <li>After clicking the ‘<strong>Tags</strong>’ button in the top-right of the ‘<strong>My Inventory</strong>’ page, you will be able to select a tag that you wish to assign to items.
Click on the name of the tag you want to assign.
</li>
<li>Click the ‘<strong>Add Product</strong>’ button on the right.</li>
    <span className={cn('addnewproductsimg')}>
    <img src={Const.isOurDesiStore ? assigntagstoproductdesistoreimg : assigntagstoproductimg}/>
    </span>

    <li>Here you can search your inventory for items you want to assign the tag to. When you see the product in the search results,
click ‘Add to Tag’ next to the corresponding product.</li>

<li>Once you are done adding products, you can close the pop-up window. Now you can see the list of products assigned to the tag when
you click on the tag name under ‘<strong>Select Tag</strong>’.</li>
<li>If you need to remove an item, simply click the trash can icon next to the name of the product.</li>

         </ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default AssignTagstoProduct