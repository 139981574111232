const initialState = {
  isLoading: false,
  isLoggedIn: false,
  venueList: [],
  venueListError: "",
  venuePost: "",
  venuePostError: "",
};

const initialStateExternalWebsite = {
  isLoading: false,
  data: '',
  err: false
}
export function venue(state = initialState, action) {
  switch (action.type) {
    case "SET_VENUE_LIST_LOADING":
      return { ...initialState, isLoading: true };
    case "SET_VENUE_LIST_LOADED":
      return { ...initialState, isLoading: false, venueList: action.payload };
    case "SET_VENUE_LIST_ERROR":
      return { ...initialState, venueListError: action.payload };
    case "SET_CREATE_VENUE_DETAILS_LOADING":
      return { ...state, isCheckinLoading: true };
    case "SET_CREATE_VENUE_DETAILS":
      return {
        ...state,
        isCheckinLoading: true,
        venuePost: action.payload,
      };
    case "SET_CREATE_VENUE_DETAILS_ERROR":
      return { ...initialState, venuePostError: action.payload };
    default:
      return state;
  }
}

export const VenueExternalWebsiteReducer = (state = initialStateExternalWebsite, action) => {
  switch (action.type) {
    case 'POST_VENUE_EXTERNAL_URL_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'POST_VENUE_EXTERNAL_URL_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'POST_VENUE_EXTERNAL_URL_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    default:
      break;
  }
}