import { DialogContent, DialogTitle, IconButton, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './GiftCardDetailsDialog.module.scss'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import { Close } from '@mui/icons-material'
import Loader from '../../Library/Loader'
import { getVenueStoreAdmin } from '../../../redux/actions/venueStoreAction'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const GiftCardDetailsDialog = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [details, setDetails] = useState({})
    const [isDetailLoading, setIsDetailLoading] = useState(true)
    const [orderIds, setOrderIds] = useState([])
    const [pagination, setPagination] = useState({})

    const fetchDetails = () => {
        setIsDetailLoading(true)
        axios.get('/cards/get-gift-order', {
            params: {
                order_id: props.id,
                type: props.type
            }
        })
            .then((response) => {
                setDetails(response.data.data.result)

                axios.get('/cards/order-list', {
                    params: {
                        created_at: response.data.data.result.created_at,
                        redeem_user_id: response.data.data.result.redeem_user_id
                    }
                })
                    .then((response) => {
                        setOrderIds(response.data.data.result)
                        setPagination(response.data.data.pagination)
                    })
            })
            .finally(() => {
                setIsDetailLoading(false)
            })
    }

    const handlePagination = (e, value) => {
        axios.get('/cards/order-list', {
            params: {
                created_at: details.created_at,
                redeem_user_id: details.redeem_user_id,
                page: value
            }
        })
            .then((response) => {
                setOrderIds(response.data.data.result)
                setPagination(response.data.data.pagination)
            })
    }

    useEffect(() => {
        fetchDetails()
    }, [])

    return (
        <>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <div>
                        GiftCard Detail
                    </div>

                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onClick={props.onClose}
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    minWidth: 300
                }}
            >
                {isDetailLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <div
                            className={styles.row}
                            style={{
                                justifyContent: 'center'
                            }}
                        >
                            <img
                                src={details.photo}
                                height={80}
                            />
                        </div>
                        <div
                            className={styles.row}
                        >
                            <div
                                className={styles.title_section}
                            >
                                Purchase By
                            </div>
                            <div>
                                {details.purchase_user}
                            </div>
                        </div>

                        {details.purchase_user_email && (
                            <div
                                className={styles.row}
                            >
                                <div
                                    className={styles.title_section}
                                >
                                    Purchase Email
                                </div>
                                <div>
                                    {details.purchase_user_email}
                                </div>
                            </div>
                        )}

                        {details.redeem_user_email && (
                            <div
                                className={styles.row}
                            >
                                <div
                                    className={styles.title_section}
                                >
                                    Redeem Email
                                </div>
                                <div>
                                    {details.redeem_user_email}
                                </div>
                            </div>
                        )}

                        {details.from_name && (
                            <div
                                className={styles.row}
                            >
                                <div
                                    className={styles.title_section}
                                >
                                    From
                                </div>
                                <div>
                                    {details.from_name}
                                </div>
                            </div>
                        )}

                        <div
                            className={styles.row}
                        >
                            <div
                                className={styles.title_section}
                            >
                                To
                            </div>
                            <div>
                                {details.redeem_user}
                            </div>
                        </div>

                        <div
                            className={styles.row}
                        >
                            <div
                                className={styles.title_section}
                            >
                                {details.to_email ? 'To Email' : 'For MySelf'}
                            </div>
                            <div>
                                {details.purchase_user_email}
                            </div>
                        </div>

                        {details.message && (
                            <div
                                className={styles.row}
                            >
                                <div
                                    className={styles.title_section}
                                >
                                    Message
                                </div>
                                <div>
                                    {details.message}
                                </div>
                            </div>
                        )}

                        {details.gift_card_number && (
                            <div
                                className={styles.row}
                            >
                                <div
                                    className={styles.title_section}
                                >
                                    Code
                                </div>
                                <div>
                                    {details.gift_card_number}
                                </div>
                            </div>
                        )}

                        {details.created_at && (
                            <div
                                className={styles.row}
                            >
                                <div
                                    className={styles.title_section}
                                >
                                    Created At
                                </div>
                                <div>
                                    {details.created_at}
                                </div>
                            </div>
                        )}

                        {details.amount && (
                            <div
                                className={styles.row}
                            >
                                <div
                                    className={styles.title_section}
                                >
                                    Amount
                                </div>
                                <div>
                                    {details.amount}
                                </div>
                            </div>
                        )}

                        {Boolean(orderIds.length) && (
                            <div
                                className={styles.row}
                            >
                                <div
                                    className={styles.title_section}
                                >
                                    Orders
                                </div>
                                <div>
                                    {orderIds.map((data) => (
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline'
                                            }}
                                            onClick={() => {
                                                axios.get('/venue/get-stores-admin', {
                                                    params: {
                                                        venue_id: data.venue_id
                                                    }
                                                })
                                                    .then((response) => {
                                                        nodeAxios.post('/auth/shadow-login', {
                                                            id: response.data?.data?.user?.id
                                                        })
                                                            .then((response) => {
                                                                localStorage.setItem("userToken", response.data.data.token);
                                                                nodeAxios.get('/auth/get-user')
                                                                    .then((response) => {
                                                                        dispatch({
                                                                            type: "LOGIN_SUCCESS",
                                                                            payload: response.data.data,
                                                                        });
                                                                        localStorage.setItem("StoreName", data.venue_name);
                                                                        localStorage.setItem("isShiftedFrom", true)
                                                                        navigate(`/order-details?id=${data.id}`)
                                                                    })
                                                            })
                                                    })
                                            }}
                                        >
                                            {data.id}
                                        </div>
                                    ))}

                                    {(pagination.last_page > 1) && (
                                        <div
                                            style={{
                                                marginTop: 20
                                            }}
                                        >
                                            <Pagination
                                                count={pagination.last_page}
                                                onChange={handlePagination}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                    </div>
                )}
            </DialogContent>
        </>
    )
}

export default GiftCardDetailsDialog