import { FindInPage, Folder } from '@mui/icons-material'
import { Box, Drawer, FormControlLabel, IconButton, Radio, RadioGroup, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import A11yProps from '../../../Library/A11yProps/A11yProps'
import GenericButton from '../../../Library/GenericButton/GenericButton'
import Loader from '../../../Library/Loader'
import TabPanel from '../../../Library/TabPanel/TabPanel'
import styles from './SelectFromGallery.module.scss'
import MenuIcon from '@mui/icons-material/Menu';

const SelectFromGallery = (props) => {

  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 400,
        '@media (max-width: 1020px)': {
          flexDirection: 'column'
        }
      }}
    >
      <div>
        <IconButton
          onClick={() => {
            setOpenDrawer(true)
          }}
          sx={{
            '@media (min-width: 1020px)': {
              display: 'none'
            }
          }}
        >
          <MenuIcon />
        </IconButton>
      </div>

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        classes={{
          root: styles.drawer_root
        }}
      >
        <Tabs
          style={{
            backgroundColor: '#E1E1E1',
            minWidth: 220
          }}
          classes={{
            indicator: styles.indicator
          }}
          orientation="vertical"
          variant="scrollable"
          value={props.bannerCat}
          onChange={(event, newvalue) => {
            setOpenDrawer(false)
            props.fetchBannerGallery(newvalue)
            props.setBannerCat(newvalue)
          }}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab
            style={{
              padding: '0 15px',
              minHeight: 35
            }}
            classes={{
              selected: styles.tab_selected
            }}
            value={'undefined'}
            label={(
              <div
                style={{
                  display: 'flex',
                  gap: 10,
                  justifyContent: 'flex-start',
                  width: '100%'
                }}
              >
                <div>
                  <Folder />
                </div>

                <div>
                  All
                </div>
              </div>
            )}
          />
          {props.bannerCatList.map((data) => (
            <Tab
              style={{
                padding: '0 15px',
                minHeight: 35
              }}
              classes={{
                selected: styles.tab_selected
              }}
              label={(
                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                >
                  <div>
                    <Folder />
                  </div>

                  <div>
                    {data.title}
                  </div>
                </div>
              )}
              {...A11yProps(data.id)}
            />
          ))}
        </Tabs>
      </Drawer>

      {!(props.file || props.imageLink) ? (
        <>
          <Tabs
            style={{
              backgroundColor: '#E1E1E1',
              minWidth: 220
            }}
            classes={{
              indicator: styles.indicator
            }}
            orientation="vertical"
            variant="scrollable"
            value={props.bannerCat}
            onChange={(event, newvalue) => {
              props.fetchBannerGallery(newvalue)
              props.setBannerCat(newvalue)
            }}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              '@media (max-width: 1020px)': {
                display: 'none'
              }
            }}
          >
            <Tab
              style={{
                padding: '0 15px',
                minHeight: 35
              }}
              classes={{
                selected: styles.tab_selected
              }}
              value={'undefined'}
              label={(
                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                >
                  <div>
                    <Folder />
                  </div>

                  <div>
                    All
                  </div>
                </div>
              )}
            />
            {props.bannerCatList.map((data) => (
              <Tab
                style={{
                  padding: '0 15px',
                  minHeight: 35
                }}
                classes={{
                  selected: styles.tab_selected
                }}
                value={data.id}
                label={(
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      justifyContent: 'flex-start',
                      width: '100%'
                    }}
                  >
                    <div>
                      <Folder />
                    </div>

                    <div>
                      {data.title}
                    </div>
                  </div>
                )}
              />
            ))}
          </Tabs>

          <TabPanel
            className={styles.tab_panel}
          >
            {props.isGalleryLoading ? (
              <Loader />
            ) : (
              <RadioGroup
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row'
                }}
                value={props.selectedbanner}
                onChange={(event) => {

                  if (props.isEdit) {
                    let parsedValue = JSON.parse(event.target.value)
                    props.setBannerdata((prevState) => {
                      let newState = [...prevState]
                      newState[props.editImageIndex].image = parsedValue.image
                      newState[props.editImageIndex].banner_id = parsedValue.id
                      return newState
                    });
                    props.setSelectedBanner(parsedValue)
                    props.setBannerTitle(parsedValue.title)
                  } else {
                    let parsedValue = JSON.parse(event.target.value)
                    props.setBannerdata((prevState) => [...prevState, { banner_id: parsedValue.id, image: parsedValue.image, banner_type: 'contain', banner_title: '', description: '', button_text: '' }]);
                    props.setSelectedBanner(parsedValue)
                    props.setBannerTitle(parsedValue.title)
                  }
                }}
              >
                <div className={styles.rightsection}>
                  <div className={styles.rightsectiontable}>
                    {!props.currentGallery.length ? (
                      <div
                        className={styles.nodata_container}
                      >
                        <div>
                          <FindInPage
                            style={{
                              fontSize: 80
                            }}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: 25
                          }}
                        >
                          No Data Found
                        </div>
                      </div>
                    ) : (
                      <ul
                        className={styles.rightsectiontableul}
                      >
                        {props.currentGallery.map((data) => (
                          <FormControlLabel
                            style={{
                              width: '100%'
                            }}
                            value={JSON.stringify(data)}
                            classes={{
                              label: styles.formcontrollabel
                            }}
                            control={<Radio size="small" />}
                            label={(
                              <li
                                className={styles.rightsectiontableulli}
                              >
                                <span className={styles.imageview}>
                                  <img
                                    className={styles.image}
                                    src={data.image}
                                  />
                                  <div
                                    style={{
                                      fontSize: 10,
                                      color: 'black'
                                    }}
                                  >
                                    View Image
                                  </div>
                                </span>

                                <span
                                  className={styles.image_title}
                                >
                                  {data.title}
                                </span>
                              </li>
                            )}
                          />
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </RadioGroup>
            )}
          </TabPanel>
        </>
      ) : props.imageComponent}
    </Box>
  )
}

export default SelectFromGallery