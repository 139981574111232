import clsx from 'clsx'
import React from 'react'
import styles from './GenericRadioWithLabel.module.scss'
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, outlinedInputClasses, Radio, RadioGroup, styled, TextField } from '@mui/material';

const GenericRadioWithLabel = ({ label, containerStyle, containerClassname, colorType, values, onChange, radios, name, ...rest }) => {
    return (
        <div
            style={containerStyle}
            className={clsx(styles.container, containerClassname)}
        >
            <div
                className={clsx(styles.label_text, (colorType === 'secondary') && styles.label_text_2, styles.advance_container)}
            >
                {label}
                {rest.required && (
                    <span
                        className={styles.required_asterisk}
                    >
                        *
                    </span>
                )}
            </div>

            <RadioGroup
                name={name}
                value={values}
                onChange={onChange}
            >
                {radios?.map((data) => (
                    <FormControlLabel
                        control={(
                            <Radio />
                        )}
                        value={data.value}
                        label={data.label}
                    />
                ))}
            </RadioGroup>
        </div>
    )
}

export default GenericRadioWithLabel