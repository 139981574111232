import React from 'react';
import { Select } from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    select: {
        backgroundColor: "white",
        borderRadius: 3,
        border: "1px solid #E6E6E6",
        transition: "none",
        width: "100%",
        "& > select": {
            padding: "12px 10px 12px 10px !important",
            fontWeight: 500,
            fontSize: '1.05rem',
            "&:focus": {
                backgroundColor: "white",
            },
        },
        "&:hover": {
            backgroundColor: "white",
        },
    },
    selectFieldOutlined: {
        border: 'none !important'
    },
}))
const Fee = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Select value={props.value} onChange={e => props.onChange(e.target.value)} native variant="outlined" className={`${classes.select} ${classes.selectFieldOutlined}`}>
                <option value="dollar">Dollar</option>
                <option value="percentage">Percent</option>
            </Select>
        </div>
    );
};

export default Fee;