import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import StyledTableCell from '../Library/StyledTableCell/StyledTableCell'
import StyledTableRow from '../Library/StyledTableRow/StyledTableRow'
import styles from './AnalyticsTable.module.scss'

const AnalyticsTable = (props) => {
    return (
        <div>
            <div
                className={styles.title}
            >
                {props.title}
            </div>

            <TableContainer
                component={Paper}
            >
                <Table
                    sx={{
                        minWidth: 700
                    }}

                >
                    <TableHead>
                        <TableRow>
                            {props.headings.map((data) => (
                                <StyledTableCell
                                    key={data.label}
                                    style={{
                                        color: 'black'
                                    }}
                                >
                                    {data.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.isEmail ? (
                            <>
                                {props.rows.map((row) => (
                                    <StyledTableRow key={row.campaignName}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.campaignName}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.uniqueOpenRate || 0}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.openRate}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.emailSent}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.opens}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.clicks}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.clickRate}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.unsubscribed}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.hard_bounces}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </>
                        ) : (
                            <>
                                {props.rows.map((row, index) => (
                                    <StyledTableRow
                                        key={row.profile + index}
                                    >
                                        <StyledTableCell component="th" scope="row">
                                            {row.profile}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.followers}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.impressions}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.engagement}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default AnalyticsTable