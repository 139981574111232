import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './AddNewProductDialog.module.scss'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import { useFormik } from 'formik'
import { Autocomplete, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, ListItem, ListItemText, Menu, MenuItem, Radio, Select, TextField, inputBaseClasses, outlinedInputClasses } from '@mui/material'
import { AttachMoney, ChevronRight, Close, Delete, Edit, ExpandMore, HelpOutline, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { getImage, getImage2 } from '../../../utils/helpers'
import GenericAutoCompleteWithLabel from '../../Library/GenericAutoCompleteWithLabel/GenericAutoCompleteWithLabel'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import GenericTextField from '../../Library/GenericTextField/GenericTextField'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { toast } from 'react-toastify'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { debounce } from 'lodash'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import * as yup from 'yup';
import LightTooltip from '../../../util_comps/LightTooltip'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorder } from '../../CustomPageBuilder/EditSection/Util/helpers'
import ReOrderDialog from './ReOrderDialog/ReOrderDialog'
import Loader from '../../Library/Loader'
import { useConfirm } from 'material-ui-confirm'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch'


const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 5px',
    margin: `0 0 8px 0`,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});


const validationSchema = yup.object({
    ProductName: yup
        .string()
        .required('This field is required'),
    category_id: yup
        .string()
        .required('This field is required'),
    inventories: yup.array()
        .of(
            yup.object().shape({
                Price: yup.string().required('This field is required'),
                min_quantity: yup.number()
                    .min(1, 'Minimum value must be 1 or greater than 1')
                    .integer('Minimum value must be an integer')
                    .nullable(true), // Allows min to be null or undefined
                max_quantity: yup.number()
                    .min(1, 'Minimum value must be 1 or greater than 1')
                    .integer('Maximum value must be an integer')
                    .nullable(true)
                    .test(
                        'is-greater',
                        'Maximum value must be greater than or equal to minimum value',
                        function (value) {
                            const { min_quantity } = this.parent;
                            if (!isNaN(value) && !isNaN(min_quantity)) {
                                return value >= min_quantity;
                            } else {
                                return true
                            }
                        }
                    ), // Allows max to be null or undefined
            })
        )
});

const AddNewProductDialog = (props) => {

    const imageRef = useRef()
    const confirm = useConfirm()
    const [formats, setFormats] = useState([])
    const [showModifierReorder, setShowModifierReorder] = useState(false)
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [openBrandOption, setOpenBrandOption] = useState(false)
    const [venues, setVenues] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedPropertiesOptions, setSelectedPropertiesOptions] = useState([]);
    const [properties, setProperties] = useState([])
    const [options, setOptions] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCat, setSelectedCat] = useState(props.isEdit ? props?.editObj?.category_name : '')
    const [options2, setOptions2] = useState({})
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const fetchModifiers = () => {
        axios.get('/modifier')
            .then((res) => {
                setOptions(res.data?.data?.results)
            })
    }

    const fetchProperty = () => {
        axios.get('/product-property')
            .then((res) => {
                setProperties(res.data?.data?.results)
            })
    }


    const fetchFormats = () => {
        axios.post('/product/formats')
            .then((res) => {
                setFormats(res.data?.data?.result)
            })
    }

    const fetchVenueCatMenu = () => {
        axios.get('/venue-category-order')
            .then((res) => {
                setVenueCategoryMenu(res.data?.data?.results)
            })
    }

    useEffect(() => {
        fetchFormats()
        fetchVenueCatMenu()
        fetchModifiers()
        fetchProperty()
    }, [])

    const handleBrandChange = (e, val) => {
        try {
            axios.get(`/venue/list`, {
                params: {
                    q: val
                }
            })
                .then((res) => {
                    setVenues(res?.data?.data?.result)
                })
        } catch (err) {
            toast.error('There was an error while fetching inventories, try again later')
        }
    }

    const debounceBrandSearch = useCallback(debounce(handleBrandChange, 1000), [])

    const formik = useFormik({
        initialValues: props.isEdit ? props.editObj : {
            ProductName: '',
            ProducerName: '',
            image_file: '',
            category_id: '',
            image_link: '',
            inventories: [
                {
                    Price: '',
                    format: '',
                    track_quantity: 0,
                    stockqty: '',
                    sku: '',
                    min_quantity: '',
                    max_quantity: ''
                }
            ]
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsLoading(true)
            console.log(values)
            const fd = new FormData()
            Object.entries(values).forEach(([key, value]) => {

                if (key == 'inventories') {

                    if (value?.length > 0) {
                        value.forEach((element, index) => {
                            console.log(element)
                            if (element.id) {
                                fd.append(`inventories[${index}][id]`, element.id);
                            }
                            fd.append(`inventories[${index}][Price]`, element.Price === null ? '' : element.Price);
                            fd.append(`inventories[${index}][track_quantity]`, element.track_quantity === null ? '' : element.track_quantity);
                            fd.append(`inventories[${index}][min_quantity]`, element.min_quantity === null ? '' : element.min_quantity);
                            fd.append(`inventories[${index}][max_quantity]`, element.max_quantity === null ? '' : element.max_quantity);
                            fd.append(`inventories[${index}][stockqty]`, element.stockqty === null ? '' : element.stockqty);
                            fd.append(`inventories[${index}][sku]`, element.sku === null ? '' : element.sku);
                            fd.append(`inventories[${index}][format]`, element.format === null ? '' : element.format);
                        });
                    }

                } else {
                    fd.append(key, value)
                }
            })

            if (selectedOptions?.length) {
                fd.append('modifier_id', selectedOptions?.map(data => data.id))
            }
            if (selectedPropertiesOptions?.length) {
                fd.append('property_id', selectedPropertiesOptions?.map(data => data.id))
            }
            nodeAxios.post(props.isEdit ? `/products/edit/${props.editObj?.id}` : '/products/create', fd)
                .then((res) => {
                    toast.success('Product added')
                    props.onClose()
                })
                .catch((err) => {
                    toast.error(err.response?.data?.message)
                })
                .finally(() => {
                    setIsLoading(false)
                })

        }
    })

    const getTreeItem = (child) => {
        return child?.map((data) => (
            <TreeItem icon={(
                <Radio
                    checked={data.category_id == formik.values.category_id}
                    onChange={(event) => {
                        formik.setFieldValue('category_id', event.target.value)
                        setSelectedCat(data?.category_name)
                    }}
                    value={data.category_id}
                />
            )}
                nodeId={data.category_id}
                label={(
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8
                        }}
                    >
                        <div>
                            {data.category_name}
                        </div>

                        {Boolean(data?.child?.length) && (
                            <KeyboardArrowRight />
                        )}
                    </div>
                )}
            >
                {Boolean(data?.child?.length) && getTreeItem(data?.child)}
            </TreeItem>
        ))
    }

    useEffect(() => {
        if (props.editObj) {
            setSelectedOptions(props.editObj?.modifier?.map((data) => ({ id: data.modifier_id, title: data.modifier_title })))
            setSelectedPropertiesOptions(props.editObj?.properties?.map((data) => ({ id: data.id, title: data.title })))
        }
    }, [props.editObj])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            formik.values.inventories,
            result.source.index,
            result.destination.index
        );

        formik.setFieldValue('inventories', newitems)
    };

    const handleChangeVisibility = (data) => {

        if (!(options2[data.id]?.noWebSales != undefined ? options2[data.id]?.noWebSales : (data.noWebSales === 0))) {
            axios.get(`/product/visibility/${data.id}`, {
                params: {
                    product_id: data.ProductId
                }
            })
                .then((res) => {
                    toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
                    setOptions2((prevState) => ({
                        ...prevState,
                        [data.id]: {
                            ...prevState[data.id],
                            noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
                        }
                    }))
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
        } else {

            confirm({
                title: 'Are you sure you want to disable this product',
            })
                .then(() => {
                    axios.get(`/product/visibility/${data.id}`, {
                        params: {
                            product_id: data.ProductId
                        }
                    })
                        .then((res) => {
                            toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
                            setOptions2((prevState) => ({
                                ...prevState,
                                [data.id]: {
                                    ...prevState[data.id],
                                    noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
                                }
                            }))
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.message)
                        })

                })
                .catch(() => {

                })

        }
    };


    return (
        <>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <input
                    style={{
                        display: 'none'
                    }}
                    ref={imageRef}
                    type="file"
                    onChange={(event) => {
                        formik.setFieldValue('image_file', event.target.files[0])
                        formik.setFieldValue('image_link', URL.createObjectURL(event.target.files[0]))
                        formik.setFieldValue('image_file_remove', 0)
                        event.target.value = ''
                    }}
                />
                {props.isEdit2Loading ? (
                    <Loader />
                ) : (
                    <div
                        className={styles.form_container}
                    >
                        <div
                            className={styles.title_container}
                        >
                            <div
                                style={{
                                    cursor: 'pointer',
                                    position: 'relative'
                                }}
                                onClick={() => {
                                    imageRef.current?.click()
                                }}
                            >
                                <img
                                    style={{
                                        minWidth: 100,
                                        minHeight: 100
                                    }}
                                    src={formik.values.image_link || formik.values.image || getImage2('addimage.png-1699973235.png')}
                                    height={100}
                                />

                                {(Boolean(formik.values.image_file) || Boolean(formik.values.image)) && (
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            padding: 2
                                        }}
                                        onClick={(event) => {
                                            formik.setFieldValue('image_file_remove', 1)
                                            formik.setFieldValue('image_file', '')
                                            formik.setFieldValue('image_link', '')
                                            formik.setFieldValue('image', '')
                                            event.stopPropagation()
                                        }}
                                    >
                                        <Delete
                                            style={{
                                                position: 'absolute',
                                                backgroundColor: 'white',
                                                padding: 2,
                                                left: 0,
                                                bottom: 3
                                            }}
                                        />
                                    </div>
                                )}

                                <Edit
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                        padding: 2,
                                        right: 0,
                                        bottom: 3
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <div
                                    className={styles.title}
                                >
                                    {props.isEdit ? "Edit product" : "Add new product"}
                                </div>

                                <div>
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            marginTop: 5
                                        }}
                                    >
                                        Enabled
                                    </div>
                                    <GenericSwitch
                                        background="#00C57E"
                                        width={60}
                                        height={24}
                                        translateX={38}
                                        thumb={19}
                                        isStatus={true}
                                        checked={(options2[props.editObj?.id]?.noWebSales != undefined ? options2[props.editObj?.id]?.noWebSales : (props.editObj?.noWebSales === 0))}
                                        onText="'On'"
                                        offText="'Off'"
                                        onChange={(event) => {
                                            handleChangeVisibility(props.editObj)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className={styles.two_items}
                        >
                            <GenericTextFieldWithLabel
                                containerStyle={{
                                    width: '100%'
                                }}
                                id="ProductName"
                                name="ProductName"
                                label="Product name"
                                placeholder="Enter product name"
                                value={formik.values.ProductName}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                required={true}
                                error={formik.touched.ProductName && Boolean(formik.errors.ProductName)}
                                helperText={formik.touched.ProductName && formik.errors.ProductName}
                            />

                            <GenericAutoCompleteWithLabel
                                label="Brand"
                                containerStyle={{
                                    width: '100%'
                                }}
                                options={venues || []}
                                clearOnBlur={false}
                                onBlur={() => setOpenBrandOption(false)}
                                open={openBrandOption}
                                filterOptions={(options) => options}
                                getOptionLabel={(option) => option.Name}
                                freeSolo={true}
                                onChange={(event, val) => {
                                    setOpenBrandOption(false)
                                    formik.setFieldValue('ProducerName', val.Name)
                                    formik.setFieldValue('venue_id', val.VenueId)
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason === 'input') {
                                        if (!(newInputValue?.length < 3)) {
                                            setOpenBrandOption(true)
                                        } else {
                                            setOpenBrandOption(false)
                                        }
                                        formik.setFieldValue('ProducerName', newInputValue)
                                        formik.setFieldValue('venue_id', '')

                                        debounceBrandSearch(event, newInputValue)
                                    }
                                }}
                                inputValue={formik.values.ProducerName || ''}
                                getOptionSelected={() => true}
                                renderInput={(params) => (
                                    <GenericTextField
                                        {...params}
                                        variant="outlined"
                                        style={{
                                            backgroundColor: 'white'
                                        }}
                                        placeholder="Item brand"
                                        error={formik.touched.ProducerName && Boolean(formik.errors.ProducerName)}
                                        helperText={formik.touched.ProducerName && formik.errors.ProducerName}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                                root: styles.autocomplete_input
                                            },
                                            endAdornment: null
                                        }}
                                        fullWidth={true}
                                    />
                                )}
                            />
                        </div>

                        <div
                            className={styles.two_items}
                        >

                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: 600
                                    }}
                                >
                                    Category

                                    {Boolean(formik.values.category_name) && (
                                        <span style={{ fontSize: 10 }}>({formik.values.category_name})</span>
                                    )}
                                    <sup
                                        style={{
                                            color: 'red'
                                        }}
                                    >
                                        *
                                    </sup>
                                </div>

                                {(formik.touched.category_id && Boolean(formik.errors.category_id)) && (
                                    <small>
                                        {formik.touched.category_id && formik.errors.category_id}
                                    </small>
                                )}

                                <div
                                    style={{
                                        width: '100%',
                                        overflow: 'auto',
                                        padding: 5,
                                        outline: (formik.touched.category_id && Boolean(formik.errors.category_id)) ? '1px solid red' : '1px solid #d3d3d3',
                                        borderRadius: 5
                                    }}
                                >
                                    <Button
                                        // style={{
                                        //     color: '#d3d3d3'
                                        // }}
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        fullWidth={true}
                                        endIcon={<KeyboardArrowDown />}
                                    >
                                        {selectedCat ? selectedCat : "Select Category"}

                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        slotProps={{
                                            paper: {
                                                elevation: 0,
                                                sx: {
                                                    filter: 'none',
                                                    border: '1px solid #D3D3D3',
                                                    height: 250
                                                },
                                            }
                                        }}
                                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                    >
                                        <TreeView
                                            defaultCollapseIcon={<ExpandMore />}
                                            defaultExpandIcon={<ChevronRight />}
                                        // expanded={props.isEdit}
                                        >
                                            {venueCategoryMenu?.map((data) => (
                                                <TreeItem
                                                    icon={(
                                                        <Radio
                                                            checked={data.category_id == formik.values.category_id}
                                                            onChange={(event) => {
                                                                formik.setFieldValue('category_id', event.target.value)
                                                                setSelectedCat(data?.category_name)
                                                            }}
                                                            value={data.category_id}
                                                        />
                                                    )}
                                                    nodeId={data.category_id}
                                                    label={(
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 8
                                                            }}
                                                        >
                                                            <div>
                                                                {data.category_name}
                                                            </div>

                                                            {Boolean(data?.child?.length) && (
                                                                <KeyboardArrowRight />
                                                            )}
                                                        </div>
                                                    )}
                                                >
                                                    {getTreeItem(data?.child)}
                                                </TreeItem>
                                            ))}
                                        </TreeView>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                                className={styles.label_text}
                            >
                                <div>
                                    Assign modifiers <span>
                                        <LightTooltip
                                            title={<span style={{ display: 'inline-block', padding: '0 15px' }}
                                            >
                                                Modifiers let customers customize their orders, choosing options like ingredient substitutions, portion sizes, and toppings for a personalized dining experience.
                                            </span>
                                            } arrow placement="top"><HelpOutline /></LightTooltip></span>
                                </div>
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        setShowModifierReorder(true)
                                    }}
                                >
                                    Reorder
                                </Button>
                            </div>
                            <Autocomplete
                                multiple={true}
                                options={options || []}
                                value={selectedOptions || []}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(event, newValue, reason) => {
                                    setSelectedOptions(newValue);
                                }}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip label={option?.title} {...getTagProps({ index })} />
                                    ))
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Select Modifiers" />
                                )}
                                renderOption={(props2, option, state) => {
                                    console.log(option, props2, props2?.key?.id)
                                    return (
                                        <>
                                            {state.index == 0 && (
                                                <Button
                                                    variant="text"
                                                    onClick={props.onCreateModifier}
                                                    fullWidth={true}
                                                >
                                                    Create new modifier
                                                </Button>
                                            )}

                                            <ListItem {...props2} key={option}>
                                                <Checkbox
                                                    checked={props2['aria-selected']}
                                                />
                                                <ListItemText primary={option.title} />
                                            </ListItem>
                                        </>
                                    )
                                }}
                            />
                        </div>

                        <div>
                            <div
                                className={styles.label_text}
                            >
                                Assign Properties
                            </div>
                            <Autocomplete
                                multiple={true}
                                options={properties || []}
                                value={selectedPropertiesOptions || []}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(event, newValue, reason) => {
                                    setSelectedPropertiesOptions(newValue);
                                }}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip label={option?.title} {...getTagProps({ index })} />
                                    ))
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Select Properties" />
                                )}
                                renderOption={(props2, option, state) => {
                                    return (
                                        <>
                                            {state.index == 0 && (
                                                <Button
                                                    variant="text"
                                                    onClick={props.onCreateProperty}
                                                    fullWidth={true}
                                                >
                                                    Create new property
                                                </Button>
                                            )}

                                            <ListItem {...props2} key={option}>
                                                <Checkbox
                                                    checked={props2['aria-selected']}
                                                />
                                                <ListItemText primary={option.title} />
                                            </ListItem>
                                        </>
                                    )
                                }}
                            />
                        </div>

                        <div>
                            <GenericTextFieldWithLabel
                                id="description"
                                name="description"
                                label="Item Description"
                                placeholder="Provide a clear and concise description of the product, highlighting its key features and benefits."
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                multiline={true}
                                rows={3}
                                sx={{
                                    [`& .${inputBaseClasses.root}`]: {
                                        padding: 0,
                                        // height: 33
                                    },
                                }}
                            />

                        </div>

                        <div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='droppable'>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={styles.scroll}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {formik.values.inventories?.map((item, index) => {
                                                return (
                                                    <Draggable
                                                        key={index.toString()}
                                                        draggableId={index.toString()}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                onClick={() => {

                                                                }}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style,
                                                                )}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        width: '100%',
                                                                        borderRadius: 6,
                                                                        backgroundColor: '#F6F6F6',
                                                                        border: '1px solid #D3D3D3',
                                                                        padding: 10,
                                                                        gap: 10
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'flex-end',
                                                                            gap: 10
                                                                        }}
                                                                    >
                                                                        <GenericTextFieldWithLabel
                                                                            id={`inventories[${index}].Price`}
                                                                            name={`inventories[${index}].Price`}
                                                                            containerStyle={{
                                                                                width: '100%'
                                                                            }}
                                                                            type="number"
                                                                            label="Pricing"
                                                                            fullWidth={true}
                                                                            placeholder="Item price"
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment
                                                                                        style={{
                                                                                            backgroundColor: '#D9D9D9',
                                                                                            height: '100%',
                                                                                            paddingLeft: 13,
                                                                                            paddingRight: 13,
                                                                                            maxHeight: 'unset',
                                                                                        }}
                                                                                        position='start'
                                                                                    >
                                                                                        <AttachMoney style={{ height: 42 }} />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            sx={{
                                                                                [`.${outlinedInputClasses.root}`]: {
                                                                                    paddingLeft: 0,
                                                                                    // height: 33
                                                                                },
                                                                                [`.${outlinedInputClasses.input}`]: {
                                                                                    paddingLeft: 0,
                                                                                },
                                                                                '@media (max-width: 600px)': {
                                                                                    width: '100%',
                                                                                },
                                                                            }}
                                                                            value={item.Price}
                                                                            onChange={formik.handleChange}
                                                                            required={true}
                                                                            error={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.Price && Boolean(formik.errors.inventories[index]?.Price)}
                                                                            helperText={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.Price && formik.errors.inventories[index]?.Price}
                                                                        />

                                                                        <FormControlLabel
                                                                            style={{
                                                                                width: '100%'
                                                                            }}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={Boolean(parseInt(item.track_quantity))}
                                                                                    onChange={(event) => {
                                                                                        formik.setFieldValue(`inventories[${index}].track_quantity`, event.target.checked ? 1 : 0)

                                                                                        if (!event.target.checked) {
                                                                                            formik.setFieldValue(`inventories[${index}].stockqty`, '')
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={(
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        gap: 10,
                                                                                        alignItems: 'center',
                                                                                        width: '100%'
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            whiteSpace: 'nowrap'
                                                                                        }}
                                                                                    >
                                                                                        Track quantity
                                                                                    </div>

                                                                                    <div>
                                                                                        <GenericTextFieldWithLabel
                                                                                            containerStyle={{
                                                                                                width: '100%'
                                                                                            }}
                                                                                            id={`inventories[${index}].stockqty`}
                                                                                            name={`inventories[${index}].stockqty`}
                                                                                            placeholder="Enter Quantity"
                                                                                            type="number"
                                                                                            disabled={!Boolean(parseInt(item.track_quantity))}
                                                                                            value={item.stockqty}
                                                                                            onChange={formik.handleChange}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'flex-end',
                                                                            gap: 10
                                                                        }}
                                                                    >
                                                                        <GenericAutoCompleteWithLabel
                                                                            label="Format"
                                                                            containerStyle={{
                                                                                width: '100%'
                                                                            }}
                                                                            options={formats || []}
                                                                            clearOnBlur={false}
                                                                            getOptionLabel={(option) => option.format}
                                                                            freeSolo={true}
                                                                            inputValue={((item.format == 'Esplanda') ? '' : item.format) || ''}
                                                                            onChange={(e, newVal) => {
                                                                                formik.setFieldValue(`inventories[${index}].format`, newVal?.format)
                                                                            }}
                                                                            onInputChange={(e, newVal, reason) => {
                                                                                if (reason === 'input') {
                                                                                    formik.setFieldValue(`inventories[${index}].format`, newVal)
                                                                                }
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <GenericTextField
                                                                                    {...params}
                                                                                    placeholder="Select or enter format"
                                                                                    error={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.format && Boolean(formik.errors.inventories[index]?.format)}
                                                                                    helperText={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.format && formik.errors.inventories[index]?.format}
                                                                                    variant="outlined"
                                                                                    style={{
                                                                                        backgroundColor: 'white'
                                                                                    }}
                                                                                    InputProps={{
                                                                                        ...params.InputProps,
                                                                                        classes: {
                                                                                            root: styles.autocomplete_input
                                                                                        },
                                                                                        // endAdornment: (
                                                                                        //     <React.Fragment>
                                                                                        //         {
                                                                                        //             params.InputProps
                                                                                        //                 .endAdornment
                                                                                        //         }
                                                                                        //     </React.Fragment>
                                                                                        // )
                                                                                    }}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )}
                                                                        />


                                                                        <GenericTextFieldWithLabel
                                                                            containerStyle={{
                                                                                width: '100%'
                                                                            }}
                                                                            id={`inventories[${index}].sku`}
                                                                            name={`inventories[${index}].sku`}
                                                                            label="Inventory"
                                                                            placeholder="Bracode #"
                                                                            value={item.sku}
                                                                            onChange={formik.handleChange}
                                                                            fullWidth={true}
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                fontWeight: 600
                                                                            }}
                                                                        >
                                                                            Allowable Qty for Purchase
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                gap: 10
                                                                            }}
                                                                        >
                                                                            <GenericTextFieldWithLabel
                                                                                label="Min"
                                                                                containerStyle={{
                                                                                    width: '100%'
                                                                                }}
                                                                                id={`inventories[${index}].min_quantity`}
                                                                                name={`inventories[${index}].min_quantity`}
                                                                                placeholder="Min"
                                                                                type="number"
                                                                                value={item.min_quantity}
                                                                                onChange={formik.handleChange}
                                                                                fullWidth={true}
                                                                                error={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.min_quantity && Boolean(formik.errors.inventories[index]?.min_quantity)}
                                                                                helperText={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.min_quantity && formik.errors.inventories[index]?.min_quantity}
                                                                            />

                                                                            <GenericTextFieldWithLabel
                                                                                label="Max"
                                                                                containerStyle={{
                                                                                    width: '100%'
                                                                                }}
                                                                                id={`inventories[${index}].max_quantity`}
                                                                                name={`inventories[${index}].max_quantity`}
                                                                                placeholder="Max"
                                                                                type="number"
                                                                                value={item.max_quantity}
                                                                                onChange={formik.handleChange}
                                                                                fullWidth={true}
                                                                                error={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.max_quantity && Boolean(formik.errors.inventories[index]?.max_quantity)}
                                                                                helperText={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.max_quantity && formik.errors.inventories[index]?.max_quantity}
                                                                            />

                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-end'
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            style={{
                                                                                color: 'red'
                                                                            }}
                                                                            variant="text"
                                                                            onClick={() => {
                                                                                let allOptions = formik.values.inventories
                                                                                allOptions.splice(index, 1)
                                                                                formik.setFieldValue('inventories', allOptions)

                                                                                if (item.id) {
                                                                                    confirm({
                                                                                        title: 'Are you sure you want to remove this format?',
                                                                                    })
                                                                                        .then(() => {
                                                                                            axios.delete(`/venue-product/delete/${item.id}`)
                                                                                                .then(() => {

                                                                                                })
                                                                                        })
                                                                                }

                                                                            }}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>

                        <Button
                            style={{
                                borderColor: '#D3D3D3',
                                color: 'black'
                            }}
                            variant="outlined"
                            onClick={() => {
                                let allOptions = formik.values.inventories

                                if (Array.isArray(allOptions)) {
                                    allOptions.push({
                                        Price: '',
                                        track_quantity: 0,
                                        stockqty: '',
                                        sku: '',
                                        min_quantity: '',
                                        max_quantity: '',
                                        format: ''
                                    })
                                } else {
                                    allOptions = [{
                                        Price: '',
                                        track_quantity: 0,
                                        stockqty: '',
                                        sku: '',
                                        min_quantity: '',
                                        max_quantity: '',
                                        format: ''
                                    }]
                                }
                                formik.setFieldValue('inventories', allOptions)
                            }}
                        >
                            Add new format
                        </Button>
                    </div>
                )}
            </DialogContent>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 10
                }}
            >
                <div>
                    {props.isEdit && (
                        <Button
                            style={{
                                color: 'red'
                            }}
                            variant="text"
                            onClick={() => {
                                props.handleDelete(props?.editObj?.id)
                                props.onClose()
                            }}
                        >
                            Remove Product
                        </Button>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        gap: 15
                    }}
                >
                    <Button
                        style={{
                            backgroundColor: '#d3d3d3',
                            color: 'black'
                        }}
                        variant="text"
                        onClick={() => {
                            props.onClose()
                        }}
                    >
                        Cancel
                    </Button>

                    <GenericLoadingButton
                        variant="contained"
                        loading={isLoading}
                        loadingPosition="end"
                        type="submit"
                        onClick={formik.handleSubmit}
                    >
                        Save product
                    </GenericLoadingButton>
                </div>
            </div>

            <Dialog
                open={showModifierReorder}
                onClose={() => {
                    setShowModifierReorder(false)
                }}
            >
                <ReOrderDialog
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    onClose={() => setShowModifierReorder(false)}
                />
            </Dialog>
        </>
    )
}

export default AddNewProductDialog