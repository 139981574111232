import { FormControl, InputLabel, Select, inputBaseClasses, outlinedInputClasses, selectClasses, styled } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import styles from './GenericSelectWithLabel.module.scss'

const GenericSelect = styled((props) => (
    <Select
        {...props}
    />
))(({ theme }) => ({
    padding: 0,
    [`& .${outlinedInputClasses.notchedOutline}`]:{
        minHeight: 40,
        border: '1px solid #d3d3d3 !important',
    },
    [`& .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input}.${selectClasses.select}`]:{
        padding: '8px 32px 8px 14px'
    }
}))


const GenericSelectWithLabel = ({ label, labelClassName, placeholder, containerClassname,colorType, ...rest }) => {
    return (
        <div
            className={clsx(styles.container, containerClassname)}
        >
            <div
                className={clsx(styles.label_text, (colorType === 'secondary') && styles.label_text_2, labelClassName)}
            >
                {label}
                {rest.required && (
                    <span
                        className={styles.required_asterisk}
                    >
                        *
                    </span>
                )}
            </div>

            <div>
                <FormControl
                    className={styles.form_control}
                >
                    <InputLabel
                        classes={{
                            shrink: styles.input_label_shrink
                        }}
                        style={{
                            color: 'gray'
                        }}
                        sx={{
                            transform: 'translate(14px, 6px) scale(1)',
                        }}
                    >
                        {placeholder}
                    </InputLabel>
                    <GenericSelect
                        {...rest}
                    >
                        {rest.children}
                    </GenericSelect>
                </FormControl>
            </div>
        </div>
    )
}

export default GenericSelectWithLabel