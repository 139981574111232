import React, { useEffect, useState } from 'react';
import './CustomPageInfo.scss';
import { classname, cns } from '../../utils/bem';
import { useNavigate } from 'react-router-dom';
import { deleteCustomPage, getCustomPage } from '../../redux/actions/customPageAction';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Delete } from '@mui/icons-material';
import GenericButton from '../Library/GenericButton/GenericButton';

const CustomPageInfo = (props) => {

    const cn = classname('custom-page-info')

    const history = useNavigate()
    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState(false);
    const [pageName, setPageName] = useState('');

    const customPage = useSelector((state) => state.getCustomPageReducer)

    useEffect(() => {
        dispatch(getCustomPage())
    }, []);

    const handleSaveName = (name) => {
        history(`/custom-page/${name}`)
    }

    const deletePage = (id) => {
        dispatch(deleteCustomPage(id))
    }

    const handleEditPage = (name, id) => {
        history(`/custom-page/edit/${name}/${id}`)
    }

    return (
        <div
            className={cns(
                cn(),
                props.className,
            )}
        >
            <div
                className={cn('add-new-btn-container')}
            >
                <GenericButton
                    variant="contained"
                    onClick={() => setOpenModal(true)}
                    disabled={props.isActionsDisabled}
                >
                    Add New
                </GenericButton>
            </div>

            <TableContainer
                style={{
                    width: '400px'
                }}
                component={Paper}
            >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customPage.data.map((row) => (
                            <TableRow key={row.title}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    {row.title}
                                </TableCell>
                                <TableCell>
                                    <div
                                        style={{
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => handleEditPage(row.title, row.id)}
                                    >
                                        Edit
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => deletePage(row.id)}
                                    >
                                        <Delete />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <DialogTitle>
                    Enter Page Name
                </DialogTitle>
                <DialogContent>
                    <label
                        style={{
                            marginRight: '10px',
                            color: 'black'
                        }}
                        htmlFor="pagename"
                    >
                        Page Name:
                    </label>
                    <input
                        id="pagename"
                        style={{
                            border: '1px solid lightgray',
                            borderRadius: '3px'
                        }}
                        value={pageName}
                        onChange={(e) => {
                            setPageName(e.target.value.replace(/\s/g, '-'))
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <GenericButton
                        variant="contained"
                        onClick={() => handleSaveName(pageName)}
                        disabled={props.isActionsDisabled}
                    >
                        Save
                    </GenericButton>
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default CustomPageInfo;
