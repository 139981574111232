import React from 'react'

const Input = ({ label, id, type, placeholder, required, value, onChange, disabled, name }) => {
    return (
        <div>
            {label && (
                <label
                    htmlFor={id}
                    className="tw-block tw-mb-1 tw-text-sm tw-font-semibold tw-text-[#626262]"
                >
                    {label}
                </label>
            )}
            <input
                type={type}
                id={id}
                name={name}
                className="tw-bg-white tw-border tw-border-[#D3D3D3] focus:tw-outline-none tw-text-[#626262] tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required={required}
                disabled={disabled}
            />
        </div>
    )
}

export default Input