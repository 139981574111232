import { DeleteForever, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view';
import { IconButton } from '@mui/material';
import React from 'react'
import { useOutletContext } from 'react-router-dom';
import StyledTreeItem from '../../../Library/StyledTreeItem/StyledTreeItem';
import styles from './Categories.module.scss'

const Categories = () => {

    const { menu } = useOutletContext()
    
    return (
        <div
        className={styles.container}
        >
            <TreeView
                aria-label="customized"
                defaultCollapseIcon={<KeyboardArrowDown />}
                defaultExpandIcon={<KeyboardArrowRight />}
                defaultEndIcon={<></>}
                sx={{ flexGrow: 1, overflowY: 'auto' }}
            >
                {menu.map((data) => {
                    return (
                        <StyledTreeItem
                            nodeId={data.MainCatId}
                            // label={<TreeTitleContainer id={data.MainCatId} handleEdit={handleEdit} isEditMode={isEditMode} image={data.image} title={data.title} noIcon={data.child.length === 0} />}
                            label={data.MainCatName}
                            // endIcon={<IconButton onClick={() => { }} ><DeleteForever /></IconButton>}
                        // ContentProps={{
                        //     contentEditable: isEditMode,
                        //     onBlur: (e) => {
                        //         if (e.currentTarget.textContent && e.currentTarget.textContent !== data.title) {
                        //             handleEdit({ text: e.currentTarget.textContent, id: data.MainCatId });
                        //         }
                        //     }
                        // }}
                        >
                            {data.child.length > 0 && data.child.map((child1) => {
                                return (
                                    <StyledTreeItem
                                        nodeId={child1.MainCatId}
                                        // label={<TreeTitleContainer id={child1.id} handleEdit={handleEdit} isEditMode={isEditMode} image={child1.image} title={child1.title} noIcon={child1.child.length === 0} />}
                                        label={child1.MainCatName}
                                        // endIcon={<IconButton onClick={() => { }} ><DeleteForever /></IconButton>}
                                    // ContentProps={{
                                    //     contentEditable: isEditMode,
                                    //     onBlur: (e) => {
                                    //         if (e.currentTarget.textContent && e.currentTarget.textContent !== child1.title) {
                                    //             handleEdit({ text: e.currentTarget.textContent, id: child1.id });
                                    //         }
                                    //     }
                                    // }}
                                    >
                                        {child1.child?.length > 0 && child1.child.map((child2) => {
                                            return (
                                                <StyledTreeItem
                                                    nodeId={child2.MainCatId}
                                                    // label={<TreeTitleContainer id={child1.id} handleEdit={handleEdit} isEditMode={isEditMode} image={child1.image} title={child1.title} noIcon={child1.child.length === 0} />}
                                                    label={child2.MainCatName}
                                                    // endIcon={<IconButton onClick={() => { }} ><DeleteForever /></IconButton>}
                                                // ContentProps={{
                                                //     contentEditable: isEditMode,
                                                //     onBlur: (e) => {
                                                //         if (e.currentTarget.textContent && e.currentTarget.textContent !== child1.title) {
                                                //             handleEdit({ text: e.currentTarget.textContent, id: child1.id });
                                                //         }
                                                //     }
                                                // }}
                                                />
                                            )
                                        })}
                                    </StyledTreeItem>
                                )
                            })}
                        </StyledTreeItem>
                    )
                })}
            </TreeView>
        </div>
    )
}

export default Categories