import React, { useMemo } from 'react'
import styles from './AllMarketing.module.scss'
import SocialImage from '../../assets/images/social-marketing.jpg'
import EmailImage from '../../assets/images/email-marketing.jpg'
import WebsiteImage from '../../assets/images/website-performance.jpg'
import MarketingItems from './MarketingItems/MarketingItems'
import { useOutletContext } from 'react-router-dom'
import routePaths from '../../config/route-paths'

const AllMarketing = () => {

    const { isOneConnected,isEmailConnected } = useOutletContext()

    const items = useMemo(() => [
        {
            image: SocialImage,
            title: 'Social Media Marketing',
            link: isOneConnected ? routePaths.marketingDashboard : routePaths.socialConnect
        },
        {
            image: EmailImage,
            title: 'Email Marketing',
            link: isEmailConnected ? routePaths.marketingEmail : routePaths.emailConnect
        },
        {
            image: WebsiteImage,
            title: 'Website Performance',
            link: routePaths.webPerformance
        },
    ], []);

    return (
        <div
            className={styles.marketing_items_container}
        >
            {items.map((data) => (
                <MarketingItems
                    image={data.image}
                    title={data.title}
                    link={data.link}
                />
            ))}
        </div>
    )
}

export default AllMarketing