import React, { useState } from 'react'
import styles from './AddHoliday.module.scss'
import { Button, DialogActions, DialogContent, DialogTitle, Drawer, IconButton, inputBaseClasses, outlinedInputClasses } from '@mui/material'
import { Close, DeleteForever, Edit, KeyboardArrowRight } from '@mui/icons-material'
import { useFormik } from 'formik'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from '../../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import GenericEditDrawer from '../../../../Library/V2/GenericEditDrawer/GenericEditDrawer'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import AddHolidayForm from './AddHolidayForm'
import { BagsIcon, DeleteIcon } from '../../../../../assets/svgs/svgs'

const AddHoliday = ({ holidays, ...props }) => {
    const [open, setOpen] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editObj, setEditObj] = useState({})

    // const formik = useFormik({
    //     initialValues: isEdit ? editObj : {
    //         title: '',
    //         date: ''
    //     },
    //     onSubmit: (values) => {
    //         axios.post(isEdit ? `/venue-holiday/edit/${editObj?.id}` : '/venue-holiday/create', {
    //             ...values,
    //             date: format(new Date(values.date), 'yyyy-MM-dd')
    //         })
    //             .then((res) => {
    //                 toast.success('Holiday Added')
    //                 setOpenForm(false); 
    //                 setIsEdit(false); 
    //                 setEditObj({}); 
    //                 props.fetchHoliday() 
    //                 formik.resetForm();
    //             })
    //     }
    // });
    

    
    const handleHolidayDelete = (id) => {
        axios.delete(`/venue-holiday/delete/${id}`)
            .then(() => {
                props.fetchHoliday()
            })
    }

    return (
        <div className='tw-flex tw-flex-col tw-mt-8'>
            <div className='tw-flex tw-justify-between tw-flex-row tw-items-center'>
                <div className='tw-font-bold tw-text-xl'>
                    <div>Holidays/Closures</div>
                    <span className='tw-text-dark-gray tw-text-sm tw-font-normal'>Set dates you're closed.</span>
                </div>
                <div className='tw-flex tw-justify-end'>
                    <Button 
                        className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs' 
                        onClick={() => {setOpen(true);}}
                    >
                        {holidays?.length > 0 ? 'Edit Holiday' : 'Add Holiday'}</Button>
                </div>
            </div>

            <div className='tw-py-2 tw-flex tw-flex-col tw-gap-2'>
                {holidays?.map((data) => (
                    <div className='tw-text-sm'>
                        <b>{data.title}</b>
                        <div className='tw-text-[#545454] tw-text-xs'>{data.date}</div>
                    </div>
                ))}
            </div>

            <Drawer
                anchor={props.isSmall ? "bottom" :"right"}
                open={open}
                onClose={() => {setOpen(false); }}
                ModalProps={{ className: '!tw-z-[1202]'}}
                PaperProps={{ className: 'tw-min-w-[350px] lg:tw-max-w-[25%] !tw-h-auto tw-min-h-[400px] tw-max-h-screen tw-p-4' }}
            >
                <GenericEditDrawer
                    {...{
                    title: 'Holiday Hours', 
                    description: "Set dates you're closed.",
                    onClose : () => {setOpen(false);  props.fetchHoliday();},
                }}
                >
                    
                    {holidays?.length > 0 &&
                    <div className='tw-py-4'>
                        {holidays?.map((data) => (
                            <div className='tw-flex tw-justify-between tw-items-center tw-border-b tw-border-gray tw-cursor-pointer tw-pt-1'
                                onClick={() => {
                                    setEditObj(data)
                                    setIsEdit(true)
                                    setOpenForm(true)
                                }}
                            >
                                <div className='tw-flex tw-gap-2'>
                                    <IconButton onClick={(e) => {e.stopPropagation(); handleHolidayDelete(data.id);}}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <div className='tw-text-sm'>
                                        <b>{data.title}</b>
                                        <div className='tw-text-[#545454] tw-text-xs'>{data.date}</div>
                                    </div>
                                </div>

                                <div>
                                    <IconButton
                                        onClick={() => {
                                            setEditObj(data)
                                            setIsEdit(true)
                                            setOpenForm(true)
                                        }}
                                    >
                                        <KeyboardArrowRight />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                        
                        <Button 
                            className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs !tw-mt-4' 
                            onClick={() => {setOpenForm(true);}}
                        >
                            Add Holiday
                        </Button>
                    </div>}
                    {holidays?.length === 0 && (
                        <div className='tw-py-4 tw-flex tw-items-center tw-flex-col tw-justify-center tw-text-center tw-gap-2'>
                            <BagsIcon />
                            <div>
                                <b className='tw-text-base'>Add holiday</b>
                                <div className='tw-text-[#888888] tw-text-sm'>Set dates you're closed.</div>
                            </div>
                            
                            <Button 
                                className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1.5 !tw-px-4 !tw-text-black !tw-text-xs' 
                                onClick={() => {setOpenForm(true);}}
                            >
                                Add Holiday
                            </Button>
                        </div>
                    )}
                    <Drawer
                        anchor={props.isSmall ? "bottom" :"right"}
                        open={openForm}
                        onClose={() => {
                            setOpenForm(false); 
                            setIsEdit(false); 
                            setEditObj({}); 
                            props.fetchHoliday();
                        }}
                        ModalProps={{ className: '!tw-z-[1202]'}}
                        PaperProps={{ className: 'tw-min-w-[350px] lg:tw-max-w-[25%] !tw-h-auto tw-min-h-[400px] tw-max-h-screen tw-p-4' }}
                    >
                        <AddHolidayForm {...props} 
                            isEdit={isEdit}
                            editObj={editObj}
                            // formik={formik}
                            onClose={() => {setOpenForm(false); setIsEdit(false); setEditObj({}); props.fetchHoliday()}}
                            />
                    </Drawer>
                </GenericEditDrawer>
            </Drawer>
        </div>
    )
}


export default AddHoliday;

