import React from 'react'
import { useSelector } from 'react-redux';
import VenueOrderListing from '../pages/VenueOrderListing/VenueOrderListing'
import Orders from '../Orders/Orders';
import ErrorFallbackBoundary from './ErrorFallbackBoundary';
import { ErrorBoundary } from 'react-error-boundary';

const VenueOrderListingRoute = () => {

  const user = useSelector((state) => state.user);

  return (
    <ErrorBoundary
      fallbackRender={ErrorFallbackBoundary}
    >
      <Orders isActionsDisabled={user?.data?.is_claim_business === 1} />
    </ErrorBoundary>
  )
}

export default VenueOrderListingRoute