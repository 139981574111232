import React, { useState, useRef, useEffect, useCallback } from "react";
import { TableRow, TableCell, Typography, TextField } from "@mui/material";
import {
  Close,
  Create,
  Delete,
  LocalOfferOutlined,
  VisibilityOutlined,
  VisibilityOffOutlined,
  ExpandMore,
  ExpandLess,
  Grade,
  Link,
  Duo,
  Description,
  CalendarToday,
  Edit,
} from "@mui/icons-material";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import LightTooltip from "../../../../util_comps/LightTooltip";
import { deleteInventory } from "../../../../servise/inventoryAPI";
import Alert from "../../../../util_comps/alert";
import {
  changeVisibility,
  deleteInventory as deleteInventoryRedux,
  editInventory as editInventoryAction,
} from "../../../../redux/actions/inventoryActions";
import { useDispatch } from "react-redux";
import AddForm from "./AddForm";
import Const from "../../../helpers/const";
import { debounce } from "lodash";
import { postUpdateProductListItem } from "../../../../redux/actions/productMasterListActions";
import ReadMoreReact from 'read-more-react';
import { toast } from "react-toastify";
import StyledTableRow from "../../../Library/StyledTableRow/StyledTableRow";
import StyledTableCell from "../../../Library/StyledTableCell/StyledTableCell";
import { getImageUrlDefault } from "../../../../utils/isImageExists";
import { useConfirm } from "material-ui-confirm";
import { imageBaseUrl } from "../../../../utils/baseUrl";

const InventoryItem = ({
  data,
  setActiveModal,
  activeForm,
  setActiveForm,
  setDirty,
  setPristine,
  fetchInventory,
  isActionsDisabled,
  mobile
}) => {

  const confirm = useConfirm()
  let [priceEditable, setPriceEditable] = useState(false);
  let [quantityEditable, setQuantityEditable] = useState(false);
  const [nameChangeEditable, setNameChangeEditable] = useState(false)
  const [descriptionChangeEditable, setDescriptionChangeEditable] = useState(false)
  const [productName, setProductName] = useState('')
  const [productDescription, setProductDescription] = useState(false)
  const [descriptionMore, setDescriptionMore] = useState(false)
  let [editables, setEditables] = useState({
    Price: data.Price,
    stockqty: data.stockqty,
    limited: data.limited,
    name: data.ProductName,
    description: data.description
  });
  let initState = useRef({
    editables,
  });
  let priceRef = useRef(null);
  let quantityRef = useRef(null);
  let ProductNameRef = useRef(null);
  let DescriptionRef = useRef(null);
  useEffect(() => {
    setEditables({
      Price: data.Price,
      stockqty: data.stockqty,
      limited: data.limited,
      name: data.ProductName,
      description: data.description
    });
  }, [data]);
  let dispatch = useDispatch((data) => data);
  useEffect(() => {
    let newStateStr = JSON.stringify({
      editables,
    });
    let initStateStr = JSON.stringify(initState.current);
    if (initStateStr === newStateStr) {
      setPristine();
    } else {
      setDirty();
    }
  }, [editables]);
  let saveProp = async (prop, setter) => {
    try {
      setter(false);
      if (editables.Price === "" && editables.stockqty === "") {
        return;
      }
      let editData = {
        data: {
          ...data,
          ...editables,
        },
        editables: {
          ...editables,
        },
      };
      dispatch(editInventoryAction(editData));
    } catch (err) {
      toast.error("Error while updating field, make sure to fill it.")
    }
  };
  const handleChangeVisibility = () => {

    if (!Boolean(data?.noWebSales)) {
      dispatch(changeVisibility({ id: data.id }));
      toast.warn(data.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
    } else {
      confirm({
        title: `Are you sure you want to disable this product`,
      })
        .then(() => {
          dispatch(changeVisibility({ id: data.id }));
          toast.warn(data.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
        })
        .catch(() => {

        })
    }
  };
  const handleEdit = (name, value) => {
    if (name === "limited") {
      if (value < 0) {
        return;
      }

      dispatch(
        editInventoryAction({
          data: { ...data, limited: value },
          editables: {
            ...editables,
            limited: value,
          },
        })
      );
    }
    setEditables({
      ...editables,
      [name]: value,
    });
  };
  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(
        `https://our${Const.isOurDesiStore ? 'desiStore' : 'liquorstore'}.com/product/${data?.ProductSlug}/${data?.ProductId}`
      )
      .then(() => {
        toast.success('Url is copied successfully')
      });
  };
  const handleDelete = async (id) => {
    try {
      let result = await Alert(
        "Are You Sure",
        "This will permanently delete inventory",
        "warning",
        {
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }
      );
      if (result.isConfirmed) {
        await deleteInventory(id);
        dispatch(deleteInventoryRedux(id));
        toast.success("Inventory Item was successfully deleted")
      }
    } catch (err) {
      toast.error("Error while deleting inventory items, try again")
    }
  };
  useClickOutside(priceRef, () => saveProp(priceEditable, setPriceEditable));
  useClickOutside(quantityRef, () =>
    saveProp(quantityEditable, setQuantityEditable)
  );

  const handleNameChangeFunction = (id) => {
    let data = {
      name: editables.name
    }
    dispatch(postUpdateProductListItem(id, data, fetchInventory))
  }

  const handleDescriptionChangeFunction = (id) => {
    let data = {
      name: editables.name,
      description: editables.description
    }
    dispatch(postUpdateProductListItem(id, data, fetchInventory))
  }

  const onFileChange = (e, id, name) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let fd = new FormData();
      fd.append('name', name);
      fd.append('image_logo', file)
      dispatch(postUpdateProductListItem(id, fd, fetchInventory))
    }
  }

  return (
    <React.Fragment >
      {activeForm.name === data.id ? (
        <AddForm
          setActiveForm={setActiveForm}
          setNewInventory={(val) =>
            setActiveForm({ name: activeForm.name, props: val })
          }
          newInventory={data}
        />
      ) : (
        <StyledTableRow >
          <StyledTableCell aalign="left">
            <div
              style={{ minWidth: mobile ? "80px" : "128px", maxWidth: mobile ? "80px" : "128px", height: "20vh", position: 'relative' }}
            >
              <label
                style={{
                  height: '30px',
                  width: '30px',
                  position: 'absolute',
                  top: 0,
                  right: '20px',
                }}
              >
                <input
                  style={{
                    display: 'none'
                  }}
                  type="file"
                  onChange={(e) => onFileChange(e, data.ProductId, data.ProductName)}
                  accept="image/*"
                  disabled={isActionsDisabled}
                />
                <Edit
                  style={{
                    fontSize: '16px',
                    color: isActionsDisabled ? 'gray' : 'black',
                    cursor: 'pointer'
                  }}
                />
              </label>

              <img
                style={{ width: "90%", height: "100%", objectFit: "contain" }}
                src={`${imageBaseUrl}/Product/${data.ProductId}/resize/${data.image}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = getImageUrlDefault();
                }}
                alt={data.ProductName}
              />
            </div>
          </StyledTableCell>

          <StyledTableCell
            align="left"
            style={{ paddingLeft: '0px' }}
          >
            <div >
              <Typography
                variant={mobile ? "h2" : "h6"}
                style={{
                  fontWeight: mobile ? "600" : "600",
                  marginBottom: 0,
                  fontSize: "1.1rem",

                }}
              >
                {nameChangeEditable ? (
                  <input
                    autoFocus={true}
                    value={editables.name}
                    onChange={(e) => {
                      e.persist()
                      setEditables({ ...editables, name: e.target.value })
                    }}
                    onBlur={() => {
                      setNameChangeEditable(false)
                      handleNameChangeFunction(data.ProductId)
                    }}
                  />
                ) : (
                  <>
                    {editables.name}
                  </>
                )}
                <Edit
                  style={{
                    fontSize: '16px',
                    marginLeft: '8px',
                    cursor: 'pointer',
                    color: isActionsDisabled ? 'gray' : 'black',
                    display: nameChangeEditable ? 'none' : 'inline-block'
                  }}
                  onClick={() => {
                    if (!isActionsDisabled) {
                      setNameChangeEditable(true)
                    }
                  }}
                />
              </Typography>
              <div style={{ display: "flex", }}>
                <div
                  style={{
                    marginRight: "30px",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div>
                    <Typography
                      variant="caption"
                      className="inventory-item-desc-type"
                    >
                      Format
                    </Typography>
                    <Typography
                      variant="h6"
                      className="inventory-item-desc-val"
                    >
                      {data.format}
                    </Typography>
                  </div>
                  <div
                    style={{
                      marginTop: '6px'
                    }}
                  >
                    <Typography
                      variant="caption"
                      className="inventory-item-desc-type"
                    >
                      Sku
                    </Typography>
                    <Typography
                      variant="h6"
                      className="inventory-item-desc-val"
                    >
                      {data.sku}
                    </Typography>
                  </div>
                  {mobile ? (<div
                    style={{
                      marginTop: '6px'
                    }}
                  >

                    <Typography
                      variant="caption"
                      className="inventory-item-desc-type"
                    >
                      Price
                    </Typography>
                    <Typography
                      variant="h6"
                      className="inventory-item-desc-val"
                    >
                      ${editables.Price}
                    </Typography>
                  </div>) : <></>}

                  <div>
                    <div
                      style={{
                        color: 'black',
                        fontWeight: 500
                      }}
                    >
                      Description
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginBottom: "6px" }}>
                    <Typography
                      variant="caption"
                      className="inventory-item-desc-type"
                    >
                      Category
                    </Typography>
                    <Typography
                      variant="h6"
                      className="inventory-item-desc-val"
                    >
                      {data.category_name}
                    </Typography>
                  </div>
                  {mobile ? (<div
                    style={{
                      marginTop: '0px',
                      marginBottom: '6px'
                    }}
                  >

                    <Typography
                      variant="caption"
                      className="inventory-item-desc-type"
                    >
                      Quantity
                    </Typography>
                    <Typography
                      variant="h6"
                      className="inventory-item-desc-val"
                    >
                      {editables.stockqty}
                    </Typography>
                  </div>) : <></>}
                  <div>
                    <Typography
                      variant="caption"
                      className="inventory-item-desc-type"
                    >
                      Brand
                    </Typography>
                    <Typography
                      variant="h6"
                      className="inventory-item-desc-val"
                    >
                      {data.ProducerName}
                    </Typography>
                    <div>
                      <div
                        style={{
                          maxHeight: descriptionMore ? '100%' : '19px',
                          overflow: 'hidden'
                        }}
                      >
                        <Edit
                          style={{
                            fontSize: '16px',
                            marginRight: '8px',
                            cursor: 'pointer',
                            color: isActionsDisabled ? 'gray' : 'black',
                            display: descriptionChangeEditable ? 'none' : 'inline-block'
                          }}
                          onClick={() => {
                            if (!isActionsDisabled) {
                              setDescriptionChangeEditable(true)
                              setDescriptionMore(true)
                            }
                          }}
                        />
                        {descriptionChangeEditable ? (
                          <textarea
                            style={{
                              minWidth: '300px'
                            }}
                            autoFocus={true}
                            value={editables.description}
                            onChange={(e) => {
                              e.persist()
                              setEditables({ ...editables, description: e.target.value })
                            }}
                            onBlur={() => {
                              setDescriptionChangeEditable(false)
                              handleDescriptionChangeFunction(data.ProductId)
                            }}
                          />
                        ) : (
                          <>
                            {editables.description}
                          </>
                        )}
                      </div>
                      {mobile ? (<></>) : (<div
                        style={{
                          color: 'red',
                          cursor: 'pointer'
                        }}
                        onClick={() => setDescriptionMore(!descriptionMore)}
                      >
                        {descriptionMore ? <>Less -</> : <>More +</>}
                      </div>)}
                    </div>
                  </div>

                </div>

              </div>
              {mobile ? (<div style={{

                height: "50px", width: "100%", marginTop: "10px", marginBottom: "-16px", flexDirection: "row"
              }}>
                <StyledTableCell

                >
                  <div style={{ flexDirection: "row" }}>

                    <div style={{ display: "flex", marginTop: "-4px" }}>
                      <div
                        style={{
                          marginRight: "23px",
                          fontSize: "20px",
                        }}
                      >
                        <LightTooltip
                          title={
                            <span style={{ display: "inline-block", padding: "0 15px" }}>
                              Delete
                            </span>
                          }
                          arrow
                          placement="top"
                        >
                          <div style={{ fontSize: "20px", color: "#7C8798", cursor: isActionsDisabled ? 'text' : 'pointer', opacity: isActionsDisabled ? 0.3 : 1 }}>
                            <Delete
                              onClick={(e) => {
                                if (!isActionsDisabled) {
                                  handleDelete(data.id)
                                }
                              }}
                              color="inherit"
                              fontSize="inherit"
                            />
                          </div>
                        </LightTooltip>
                      </div>
                      <div
                        onClick={(e) => {
                          if (!isActionsDisabled) {
                            setActiveModal({
                              name: "addtag",
                              props: {
                                productName: data.ProductName,
                                productId: data.ProductId,
                              },
                            })
                          }
                        }}
                        style={{
                          marginRight: "23px",
                          fontSize: "20px",
                          cursor: isActionsDisabled ? 'text' : 'pointer',
                          opacity: isActionsDisabled ? 0.3 : 1,
                          color: "#7C8798"
                        }}
                      >
                        <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Add to collection.</span>} arrow placement="top">
                          <LocalOfferOutlined fontSize="inherit" color="inherit" />
                        </LightTooltip>
                      </div>
                      <div
                        onClick={(e) => {
                          if (!isActionsDisabled) {
                            handleCopyUrl()
                          }
                        }}
                        style={{
                          marginRight: "23px",
                          fontSize: "20px",
                          cursor: isActionsDisabled ? 'text' : 'pointer',
                          opacity: isActionsDisabled ? 0.3 : 1,
                          color: "#7C8798",
                        }}
                      >
                        <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Copy this product url to clipboard.</span>} arrow placement="top">
                          <Link fontSize="inherit" color="inherit" />
                        </LightTooltip>
                      </div>
                      {/*                                 <div style={{marginRight: '23px', fontSize: '20px',cursor: 'pointer', color: '#7C8798'}}>
                                    <Duo fontSize="inherit" color="inherit"/>
                                </div> */}


                      <LightTooltip
                        interactive
                        arrow
                        placement="top"
                        title={
                          <div>
                            <div
                              style={{
                                color: "black",
                                display: 'flex',
                                justifyContent: 'flex-end',
                                fontSize: "0.7em",
                              }}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={(e) => handleEdit("limited", 0)}
                              >Clear filters</span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 10px 0 0",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "21px",
                                  color: "black",
                                  marginRight: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  onClick={(e) => {
                                    if (!isActionsDisabled) {
                                      handleEdit("limited", editables.limited + 1)
                                    }
                                  }}
                                >
                                  <ExpandLess
                                    color="inherit"
                                    fontSize="inherit"
                                    style={{
                                      position: "relative",
                                      top: "6px",
                                      cursor: isActionsDisabled ? 'text' : 'pointer',
                                      opacity: isActionsDisabled ? 0.3 : 1,
                                    }}
                                  />
                                </div>
                                <div
                                  onClick={(e) => {
                                    if (!isActionsDisabled) {
                                      handleEdit("limited", editables.limited - 1)
                                    }
                                  }}
                                >
                                  <ExpandMore
                                    color="inherit"
                                    fontSize="inherit"
                                    style={{
                                      cursor: isActionsDisabled ? 'text' : 'pointer',
                                      opacity: isActionsDisabled ? 0.3 : 1,
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                onBlur={(e) => {
                                  if (e.currentTarget.textContent && parseInt(e.currentTarget.textContent) > 0) {
                                    handleEdit(
                                      "limited",
                                      parseInt(e.currentTarget.textContent)
                                    );
                                  }
                                }}
                                contentEditable
                                style={{ fontSize: "1rem" }}
                              >
                                {editables.limited || "None"}
                              </div>
                              <Typography
                                variant="h6"
                                style={{ fontSize: ".7rem", marginLeft: "10px" }}
                              >
                                Limit
                              </Typography>
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            marginRight: "23px",
                            fontSize: "20px",
                            cursor: isActionsDisabled ? 'text' : 'pointer',
                            opacity: isActionsDisabled ? 0.3 : 1,
                            color: "#7C8798",
                          }}
                        >
                          <Description fontSize="inherit" color="inherit" />
                        </div>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          <span
                            style={{ display: "inline-block", padding: "0 15px" }}
                          >
                            Change Visibility of this item
                          </span>
                        }
                        arrow
                        placement="top"
                      >
                        <div
                          onClick={() => {
                            if (!isActionsDisabled) {
                              handleChangeVisibility()
                            }
                          }}
                          style={{
                            marginRight: "23px",
                            fontSize: "20px",
                            cursor: isActionsDisabled ? 'text' : 'pointer',
                            opacity: isActionsDisabled ? 0.3 : 1,
                            color: "#7C8798",
                          }}
                        >
                          {data.noWebSales === 0 ? (
                            <VisibilityOutlined fontSize="inherit" color="inherit" />
                          ) : (
                            <VisibilityOffOutlined
                              style={{
                                color: '#ef2d2c'
                              }}
                              fontSize="inherit"
                              color="inherit"
                            />
                          )}
                        </div>
                      </LightTooltip>
                    </div>
                  </div>
                </StyledTableCell>
              </div>) : (<></>)}

            </div>
          </StyledTableCell>
          {
            mobile ? (<></>) : (<StyledTableCell
              align="center"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {priceEditable ? (
                  <TextField
                    onChange={(e) => handleEdit(e.target.name, e.target.value)}
                    className="edit-existing-prop-input"
                    name="Price"
                    id="outlined-basic"
                    variant="outlined"
                    ref={priceRef}
                    InputProps={{
                      endAdornment: <Create style={{ transform: "scale(.8)" }} />,
                    }}
                    value={editables.Price}
                  />
                ) : (
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                    onClick={(e) => {
                      if (!isActionsDisabled) {
                        setPriceEditable(true)
                      }
                    }}
                  >
                    ${editables.Price}
                  </Typography>
                )}
              </div>
            </StyledTableCell>)
          }

          {
            mobile ? (<></>) : (
              <StyledTableCell
                align="center"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {quantityEditable ? (
                    <TextField
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                      ref={quantityRef}
                      className="edit-existing-prop-input"
                      name="stockqty"
                      id="outlined-basic"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <Create style={{ transform: "scale(.8)" }} />,
                      }}
                      value={editables.stockqty}
                    />
                  ) : (
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        if (!isActionsDisabled) {
                          setQuantityEditable(true)
                        }
                      }}
                    >
                      {editables.stockqty}
                    </Typography>
                  )}
                </div>
              </StyledTableCell>)
          }
          {
            mobile ? (<></>) : (
              <StyledTableCell
                align="center"
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <LightTooltip
                    title={
                      <span style={{ display: "inline-block", padding: "0 15px" }}>
                        Delete
                      </span>
                    }
                    arrow
                    placement="top"
                  >
                    <div style={{ fontSize: "20px", color: "#7C8798", cursor: isActionsDisabled ? 'text' : 'pointer', opacity: isActionsDisabled ? 0.3 : 1 }}>
                      <Delete
                        onClick={(e) => {
                          if (!isActionsDisabled) {
                            handleDelete(data.id)
                          }
                        }}
                        color="inherit"
                        fontSize="inherit"
                      />
                    </div>
                  </LightTooltip>
                </div>
              </StyledTableCell>)
          }
          {
            mobile ? (<></>) : (
              <StyledTableCell
                align="center"
              >
                <div>
                  <div style={{ display: "flex", marginBottom: "13px" }}>
                    <div
                      onClick={(e) => {
                        if (!isActionsDisabled) {
                          setActiveModal({
                            name: "addtag",
                            props: {
                              productName: data.ProductName,
                              productId: data.ProductId,
                            },
                          })
                        }
                      }}
                      style={{
                        marginRight: "23px",
                        fontSize: "20px",
                        cursor: isActionsDisabled ? 'text' : 'pointer',
                        opacity: isActionsDisabled ? 0.3 : 1,
                        color: "#7C8798"
                      }}
                    >
                      <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Add to collection.</span>} arrow placement="top">
                        <LocalOfferOutlined fontSize="inherit" color="inherit" />
                      </LightTooltip>
                    </div>
                    <div
                      onClick={(e) => {
                        if (!isActionsDisabled) {
                          handleCopyUrl()
                        }
                      }}
                      style={{
                        marginRight: "23px",
                        fontSize: "20px",
                        cursor: isActionsDisabled ? 'text' : 'pointer',
                        opacity: isActionsDisabled ? 0.3 : 1,
                        color: "#7C8798",
                      }}
                    >
                      <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Copy this product url to clipboard.</span>} arrow placement="top">
                        <Link fontSize="inherit" color="inherit" />
                      </LightTooltip>
                    </div>
                    {/*                                 <div style={{marginRight: '23px', fontSize: '20px',cursor: 'pointer', color: '#7C8798'}}>
                                    <Duo fontSize="inherit" color="inherit"/>
                                </div> */}
                  </div>
                  <div style={{ display: "flex", marginBottom: "13px" }}>
                    <LightTooltip
                      interactive
                      arrow
                      placement="top"
                      title={
                        <div>
                          <div
                            style={{
                              color: "black",
                              display: 'flex',
                              justifyContent: 'flex-end',
                              fontSize: "0.7em",
                            }}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleEdit("limited", 0)}
                            >Clear filters</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0px 10px 0 0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                fontSize: "21px",
                                color: "black",
                                marginRight: "10px",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                onClick={(e) => {
                                  if (!isActionsDisabled) {
                                    handleEdit("limited", editables.limited + 1)
                                  }
                                }}
                              >
                                <ExpandLess
                                  color="inherit"
                                  fontSize="inherit"
                                  style={{
                                    position: "relative",
                                    top: "6px",
                                    cursor: isActionsDisabled ? 'text' : 'pointer',
                                    opacity: isActionsDisabled ? 0.3 : 1,
                                  }}
                                />
                              </div>
                              <div
                                onClick={(e) => {
                                  if (!isActionsDisabled) {
                                    handleEdit("limited", editables.limited - 1)
                                  }
                                }}
                              >
                                <ExpandMore
                                  color="inherit"
                                  fontSize="inherit"
                                  style={{
                                    cursor: isActionsDisabled ? 'text' : 'pointer',
                                    opacity: isActionsDisabled ? 0.3 : 1,
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              onBlur={(e) => {
                                if (e.currentTarget.textContent && parseInt(e.currentTarget.textContent) > 0) {
                                  handleEdit(
                                    "limited",
                                    parseInt(e.currentTarget.textContent)
                                  );
                                }
                              }}
                              contentEditable
                              style={{ fontSize: "1rem" }}
                            >
                              {editables.limited || "None"}
                            </div>
                            <Typography
                              variant="h6"
                              style={{ fontSize: ".7rem", marginLeft: "10px" }}
                            >
                              Limit
                            </Typography>
                          </div>
                        </div>
                      }
                    >
                      <div
                        style={{
                          marginRight: "23px",
                          fontSize: "20px",
                          cursor: isActionsDisabled ? 'text' : 'pointer',
                          opacity: isActionsDisabled ? 0.3 : 1,
                          color: "#7C8798",
                        }}
                      >
                        <Description fontSize="inherit" color="inherit" />
                      </div>
                    </LightTooltip>
                    <LightTooltip
                      title={
                        <span
                          style={{ display: "inline-block", padding: "0 15px" }}
                        >
                          Change Visibility of this item
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <div
                        onClick={() => {
                          if (!isActionsDisabled) {
                            handleChangeVisibility()
                          }
                        }}
                        style={{
                          marginRight: "23px",
                          fontSize: "20px",
                          cursor: isActionsDisabled ? 'text' : 'pointer',
                          opacity: isActionsDisabled ? 0.3 : 1,
                          color: "#7C8798",
                        }}
                      >
                        {data.noWebSales === 0 ? (
                          <VisibilityOutlined fontSize="inherit" color="inherit" />
                        ) : (
                          <VisibilityOffOutlined
                            style={{
                              color: '#ef2d2c'
                            }}
                            fontSize="inherit"
                            color="inherit"
                          />
                        )}
                      </div>
                    </LightTooltip>
                  </div>
                </div>
              </StyledTableCell>)
          }

        </StyledTableRow >
      )
      }
    </React.Fragment >
  );
};

export default InventoryItem;
