/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styles from './FormResponsesTable.module.scss';
import { Pagination } from '@mui/material';
import Loader from '../../../Library/Loader';
import no_user_available from '../../../../assets/svgs/no_user_available.svg';
import dayjs from 'dayjs';
import clsx from 'clsx';
import ReadMore from '../../../Library/ReadMore/ReadMore';


const formatDate = (date, _format) => {
  const dt = dayjs(date);
  return dt.format(_format || 'YYYY-MM-DD');
};

function FormResponsesTable(props) {
  console.log('🚀OUTPUT --> props:', props);

  return (
    <div className={styles.list_grid_container}>
      {!props.listings?.data ? (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
        ) : props.listings?.data?.map((responses_item, index) => {


          return responses_item?.response?.length > 0 && (
            <TableItem responses_item={responses_item} />
          )})
      }
      {props.totalPages > 1 && (
        <Pagination
          classes={{
            root: styles.schoolPaginateRootEl,
            ul: styles.schoolPaginateListEl,
          }}
          count={props.totalPages}
          page={props.pageNo}
          onChange={(event, value) => {
            props.handleChangePage(value);
          }}
        />
      )}
    </div>
  );
}

export default FormResponsesTable;


const TableItem = ({ responses_item  }) => {

  
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    if (responses_item?.response?.length) {
      let theading = [];
      let tData = [];
      responses_item?.response?.forEach((data) => {
        if (data?.response) {
          Object.entries(data?.response).forEach(
            ([key, value]) => {
              if (!theading.includes(key)) {
                theading.push(key);
              }
            }
          );
        }
      });

      setHeadings(theading);
    }
  }, [responses_item]);



  return (
    <div className={styles.studentTableContainer}>
      <div style={{ fontSize:'20px', padding: '1rem', fontWeight: 600 }} >
        {responses_item?.title}
      </div>
        <table className={styles.studentTable}>
          <thead>
              <th>
                <div className={clsx(styles.table_head_container, 'tw-min-w-[100px]')} >Response Date</div>
              </th>
            {headings?.map((data) => (
              <th>
                <div className={styles.table_head_container}>{data}</div>
              </th>
            ))}
          </thead>
          <tbody>
            {responses_item?.response?.length === 0 ? (
              <div className={styles.no_data_available}>
                <img
                  priority
                  src={no_user_available}
                  height={100}
                  width={100}
                  alt='No user available'
                />
                No Responses Yet!
              </div>
            ) : (
              responses_item?.response?.map((data, index) => {
                
                return (
                  <tr>
                    <td>{formatDate(data?.created_at)}</td>
                    {/* <td>{index + 1}</td> */}
                      {data?.response && headings.map((header) => (
                        <td key={header} title={data?.response[header]}>
                          {/* {data?.response[header]} */}
                          <ReadMore
                              text={data?.response[header]}
                              maxLength={60}
                              readMoreClassName={'tw-text-[303030]'}
                          /> 
                        </td>
                      ))} 
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
  )
}
