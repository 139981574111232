
import { styled } from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    [`&.${checkboxClasses.root}`]: {
        color: theme.palette.common.black,
        transform: 'scale(.75)'
    },
}));

export default CustomCheckbox;