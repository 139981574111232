import { useNavigate, useParams } from 'react-router'

import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, FormControl, IconButton, Menu, Tab, Tabs, TextField } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import _without from 'lodash/without'
import styles from './CreatePost.module.scss'
import PublishToSection from '../PublishToSection/PublishToSection'
import { Add, DeleteForever, DeleteForeverOutlined, Edit, EditOutlined } from '@mui/icons-material'
import PreviewPost from '../PreviewPost/PreviewPost'
// import SearchProduct from '../../SearchProduct2/SearchProduct'
import GenericTextFieldWithLabel from '../Library/GenericTextFieldWithLabel/GenericTextFieldWithLabel'
import { FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa'
import axios from '../Util/axiosConfig'
import { toast } from 'react-toastify'
import { Swiper, SwiperSlide } from "swiper/react";
import { format } from 'date-fns'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'
import MarketingContext from '../Util/marketingContext'
import imageBaseUrl from '../Util/imageBaseUrl'
import SearchActivity from '../SearchActivity/SearchActivity'
import SearchProduct from '../../SearchProduct2/SearchProduct'

const videoExt = [
  'mp4',
  'MOV',
  'WMV',
  'AVI',
  'AVCHD',
  'FLV',
  'F4V',
  'SWF',
  'MKV',
  'WEBM',
  'HTML5',
  'mpg',
  'ogg'
]

const CreatePost = () => {

  const { pages, names, externalWebsite, subDomain } = useContext(MarketingContext)
  const navigate = useNavigate()

  const { id } = useParams();
  const fileRef = useRef()
  const fileRef2 = useRef()

  const [platformName, setPlatformName] = useState([...pages, ...names]);
  const [tab, setTab] = useState(0)
  const [file, setFile] = useState([])
  const [link, setLink] = useState('')
  const [description, setDescription] = useState('')
  const [selectedProduct, setSelectedProduct] = useState([])
  const [showSearchProduct, setShowSearchProduct] = useState(false)
  const [selectionIndex, setSelectionIndex] = useState(0)
  const [tabValue, setTabValue] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFb, setShowFb] = useState(false)
  const [showInsta, setShowInsta] = useState(false)
  const [showTwitter, setShowTwitter] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const [isLoading3, setIsLoading3] = useState(false)

  const [isSuccess1, setIsSuccess1] = useState(false)
  const [isSuccess2, setIsSuccess2] = useState(false)
  const [isSuccess3, setIsSuccess3] = useState(false)
  const [isDone1, setIsDone1] = useState(false)
  const [isDone2, setIsDone2] = useState(false)
  const [isDone3, setIsDone3] = useState(false)

  const [isUploading, setIsUploading] = useState(false)
  const [schedulePost, setSchedulePost] = useState(false)
  const [scheduleDate, setScheduleDate] = useState(format(new Date(), 'yyyy-MM-dd').replace(' ', 'T'))
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd').replace(' ', 'T'))
  const [time, setTime] = useState(format(new Date(), 'HH:mm').replace(' ', 'T'))
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)


  const contentTabs = useMemo(() => [
    {
      value: 0,
      label: <FaFacebookSquare style={{ fill: 'white', fontSize: 24 }} />,
      show: showFb,
      bgstyle: styles.fb_bg
    },
    {
      value: 1,
      label: <FaTwitter style={{ fill: 'white', fontSize: 24 }} />,
      show: showTwitter,
      bgstyle: styles.tw_bg
    },
    {
      value: 2,
      label: <FaInstagram style={{ fill: 'white', fontSize: 24 }} />,
      show: showInsta,
      bgstyle: styles.in_bg
    }
  ], [showFb, showInsta, showTwitter])

  const fetchImages = () => {
    axios.get(`/marketing/social-post-image/list/${id}`)
      .then(async (response) => {
        setFile(response.data.data.results.map((data) => ({
          image: `${imageBaseUrl}/SocialPostImage/${id}/${data.image}`,
          isVideo: videoExt.includes(data.image.split('.')[data.image.split('.').length - 1]),
          id: data.id
        })))
      })
  }

  const fetchDetails = () => {
    axios.get(`/marketing/social-post/detail/${id}`)
      .then((response) => {
        if (response.data?.data?.result?.length) {
          setDescription(response.data?.data?.result[0]?.content)
        }
      })
  }

  useEffect(() => {
    fetchImages()
    fetchDetails()
  }, [])

  useEffect(() => {
    setPlatformName([...pages, ...names])
  }, [pages, names])

  useEffect(() => {
    if (platformName.filter((data) => data.isFacebook).length) {
      setShowFb(true)
    } else {
      setShowFb(false)
    }
    if (platformName.filter((data) => data.isInstagram).length) {
      setShowInsta(true)
    } else {
      setShowInsta(false)
    }
    if (platformName.filter((data) => data.isTwitter).length) {
      setShowTwitter(true)
    } else {
      setShowTwitter(false)
    }
  }, [platformName])

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePlatform = (data) => {

    setPlatformName((prevState) => {
      if (prevState.filter((data1) => data1.id == data.id).length) {
        return _without(prevState, data)
      } else {

        if (data.isFacebook) {
          setTabValue(0)
        }
        if (data.isInstagram) {
          setTabValue(2)
        }
        if (data.isTwitter) {
          setTabValue(1)
        }

        return [...prevState, data]
      }
    });
  };

  const handleDelete = (value) => {
    setPlatformName((current) => _without(current, value));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePost = (schedulePost) => {
    setIsLoading1(true)
    setIsLoading2(true)
    setIsLoading3(true)
    let fbPages = platformName.filter((data) => data.isFacebook)
    let instaDetails = platformName.filter((data) => data.isInstagram)
    let twitterDetails = platformName.filter((data) => data.isTwitter)

    if (tab == 0) {
      if (!description && !file.length) {
        toast.error('Please add media or description')
      }
    } else {
      if (!description && !selectedProduct.length) {
        toast.error('Please add media or description')
      }
    }

    if (!fbPages.length) {
      setIsLoading1(false)
      setIsDone1(true)
    }
    if (!instaDetails.length) {
      setIsLoading2(false)
    }
    if (!twitterDetails.length) {
      setIsLoading3(false)
    }
    fbPages.forEach((data) => {
      let fd = new FormData()
      fd.append('page_id', data.page_id)
      fd.append('access_token', data.page_access_token)
      fd.append('post_group_id', id)
      fd.append('message', description.substring(0, 63206))

      if (tab == 0) {
        // fd.append('image_file', file)
        fd.append('media_type', 'photos')
      } else {
        if (selectedProduct.length) {
          // selectedProduct.forEach((element, index) => {
          //     fd.append(`product_ids[${index}]`, element.image);
          // });
          // fd.append('product_ids', selectedProduct.map((data) => data.image))
          fd.append('media_type', 'photos')
        } else {
          fd.append('media_type', 'feed')
        }
      }

      if (schedulePost) {
        fd.append('scheduled_publish_time', Math.floor(new Date(scheduleDate).getTime() / 1000))
      }
      axios.post('/marketing/social-post/facebook', fd)
        .then((response) => {
          setIsSuccess1(true)
          toast.success(schedulePost ? 'Post Scheduled for Facebook' : 'Successfully Posted On Facebook')
        })
        .catch((error) => {
          if (error?.response?.data?.message?.error?.message) {
            toast.error(error?.response?.data?.message?.error?.message)
          } else {
            toast.error(error?.response?.data?.message)
          }
        })
        .finally(() => {
          setIsLoading1(false)
          setIsDone1(true)
        })
    })

    if (twitterDetails.length) {

      let fd2 = new FormData()

      fd2.append('text', description.substring(0, 280))
      fd2.append('post_group_id', id)

      if (schedulePost) {
        fd2.append('scheduled_publish_time', Math.floor(new Date(scheduleDate).getTime() / 1000))
      }
      if (tab == 0) {
        // fd2.append('image_file', file)
      } else {
        if (selectedProduct.length) {
          // selectedProduct.forEach((element, index) => {
          //     fd.append(`product_ids[${index}]`, element.ProductId);
          // });
        }
      }

      axios.post('/marketing/social-post/twitter', fd2)
        .then((response) => {
          setIsSuccess3(true)
          toast.success('Successfully Posted On Twitter')
        })
        .catch((err) => {
          toast.error(err.response?.data?.message)
          toast.error(err.response?.message?.message)
        })
        .finally(() => {
          setIsDone3(true)
          setIsLoading3(false)
        })
    } else {
      setIsDone3(true)
    }

    if (instaDetails.length) {
      instaDetails.forEach((data) => {
        let fd2 = new FormData()

        fd2.append('page_id', data.page_id)
        fd2.append('media_type', 'media')
        fd2.append('post_group_id', id)
        fd2.append('caption', description.substring(0, 2190))
        if (schedulePost) {
          fd2.append('scheduled_publish_time', Math.floor(new Date(scheduleDate).getTime() / 1000))
        }
        if (tab == 0) {
          if (file.length == 0) {
            toast.error('Image is required to make instagram post')
            setIsLoading2(false)
            return
          }
        } else {
          if (selectedProduct.length == 0) {
            toast.error('Image is required to make instagram post')
            setIsLoading2(false)
            return
          }
        }

        axios.post('/marketing/social-post/instagram', fd2)
          .then((response) => {
            setIsSuccess2(true)
            toast.success(`Successfully Posted On Instagram ${data.name}`)
          })
          .catch((error) => {
            if (error?.response?.data?.message?.error?.message) {
              toast.error(error?.response?.data?.message?.error?.message)
            } else {
              toast.error(error?.response?.data?.message)
            }
          })
          .finally(() => {
            setIsDone2(true)
            setIsLoading2(false)
          })
      })
    } else {
      setIsDone2(true)
    }

  }

  useEffect(() => {
    setScheduleDate(new Date(`${date} ${time}`))
  }, [date, time])


  useEffect(() => {

    if (isDone1 && isDone2 && isDone3) {
      if (isSuccess1 || isSuccess2 || isSuccess3) {
        navigate(`/marketing/post-details/${id}`)
      }
    }
  }, [isSuccess1, isSuccess2, isSuccess3, isDone1, isDone2, isDone3])

  const postLinkImage = () => {
    setIsUploading(true)
    axios.post(`/marketing/social-post-image/upload/${id}`, {
      activity_ids: selectedProduct.map((data) => data.id)
    })
      .then(async (response) => {
        setFile(response.data.data.results.map((data) => ({
          image: `${imageBaseUrl}/SocialPostImage/${id}/${data.image}`,
          isVideo: videoExt.includes(data.image.split('.')[data.image.split('.').length - 1]),
          id: data.id
        })))
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
      .finally(() => {
        setIsUploading(false)
      })
  }

  return (
    <div
      className={styles.container}
    >
      <div
        className={styles.title_container}
      >
        <div
          className={styles.dashboard_text}
        >
          Post details
        </div>

        <div
          style={{
            display: 'flex',
            gap: 10
          }}
        >
          <div>

            <Button
              variant="text"
              type="button"
              onClick={handleClick}
            >
              Schedule post
            </Button>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              // onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <div
                style={{
                  padding: '5px 20px 20px 20px'
                }}
              >
                <div
                  className={styles.select_account_text}
                >
                  Set Schedule
                </div>

                <small
                  style={{
                    fontWeight: 400,
                    cursor: 'pointer',
                    color: '#ef2d2c',
                    display: 'block'
                  }}
                >
                  Schedule Post time should be after 10 minutes from current time
                </small>
                <GenericTextFieldWithLabel
                  // className={styles.textfield_item}
                  fullWidth={true}
                  label="Schedule Date"
                  id="date"
                  type="date"
                  InputProps={{ inputProps: { min: format(new Date(), 'yyyy-MM-dd') } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />

                <GenericTextFieldWithLabel
                  // className={styles.textfield_item}
                  fullWidth={true}
                  label="Schedule Time"
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={time}
                  onChange={(event) => setTime(event.target.value)}
                />

                <GenericLoadingButton
                  style={{
                    backgroundColor: '#0B9DE4',
                    fontWeight: 600,
                    width: '100%',
                    marginTop: 20
                  }}
                  variant="contained"
                  type="button"
                  loadingPosition="end"
                  loading={(isLoading1 || isLoading2 || isLoading3) && schedulePost}
                  onClick={() => {
                    setSchedulePost(true)
                    handlePost(true)
                  }}
                >
                  Done
                </GenericLoadingButton>
              </div>
            </Menu>
          </div>

          <GenericLoadingButton
            style={{
              backgroundColor: '#0B9DE4',
              borderRadius: '30px',
              fontWeight: 600
            }}
            variant="contained"
            type="button"
            loadingPosition="end"
            loading={(isLoading1 || isLoading2 || isLoading3) && !schedulePost}
            onClick={() => {
              setSchedulePost(false)
              handlePost(false)
            }}
          >
            Post Now
          </GenericLoadingButton>
        </div>
      </div>

      <div>
        <PublishToSection
          platformName={platformName}
          handleChangePlatform={handleChangePlatform}
          pages={pages}
          names={names}
          handleDelete={handleDelete}
          setPlatformName={setPlatformName}
        />
      </div>

      <div
        className={styles.container1}
      >
        <div
          className={styles.post_details_container}
        >
          <div
            className={styles.type_container}
          >
            <Chip
              style={{
                marginTop: '6px',
                marginRight: '8px',
                border: (tab === 0) ? '1px solid #0B9DE4' : 'none'
              }}
              classes={{
                label: styles.chip_label
              }}
              label="Create your own post"
              clickable={true}
              className={styles.chip}
              onClick={() => {
                setTab(0)
              }}
            />

            <Chip
              style={{
                marginTop: '6px',
                marginRight: '8px',
                border: (tab === 1) ? '1px solid #0B9DE4' : 'none'
              }}
              classes={{
                label: styles.chip_label
              }}
              label="Select product to post"
              clickable={true}
              className={styles.chip}
              onClick={() => {
                setTab(1)
                setShowSearchProduct(true)
              }}
            />
          </div>

          <div
            className={styles.add_images_container}
          >
            <div
              className={styles.add_images_text}
            >
              Add Images

              <div
                className={styles.messages_container}
              >
                <small
                  style={{
                    fontWeight: 400,
                    cursor: 'pointer',
                    color: '#ef2d2c'
                  }}
                >
                  {(Boolean(platformName.filter((data) => data.isInstagram).length) && !Boolean(file.length)) && "(Image is required to make a Instagram Post)"}
                </small>

                <small
                  style={{
                    fontWeight: 400,
                    cursor: 'pointer',
                    color: '#ef2d2c'
                  }}
                >
                  {(Boolean(platformName.filter((data) => data.isTwitter).length) && Boolean(file.length > 4)) && "(On twitter first 4 images will be posted rest will be truncated)"}
                </small>
              </div>
            </div>

            <div
              className={styles.input_image_container}
            >
              {(tab == 0) && (
                <div
                  className={styles.upload_photo}
                  onClick={e => {
                    if (!isUploading) {
                      fileRef.current.click()
                    }
                  }}
                  variant="contained"
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      className={styles.add_icon_container}
                    >
                      <Add
                        style={{
                          color: 'white'
                        }}
                      />
                    </div>

                    <div
                      style={{
                        marginTop: 10
                      }}
                    >
                      {isUploading ? (<CircularProgress />) : "Click to add media files"}
                    </div>
                  </div>
                </div>
              )}
              <input
                style={{ display: 'none', maxHeight: 50 }}
                ref={fileRef2}
                type="file"
                accept={file.length ? (!file[0].isVideo ? "image/*" : "video/*") : "image/*|video/*"}
                onChange={(e) => {
                  // axios.post(`/venue-social-post/upload/${id}`, {
                  //     image_file: e.target.files[0]
                  // })
                  // .then(async (response)=>{
                  //     await setFile((prevState) => {
                  //         let newState = [...prevState]
                  //         newState.splice(selectionIndex, 1, `${imageBaseUrl}/VenueSocialImage/${id}/${response.data.data.results}`);
                  //         return newState
                  //     })
                  // })
                  let fd = new FormData()
                  fd.append('image_file', e.target.files[0])

                  axios.post(`/marketing/social-post-image/edit/${selectionIndex}`, fd)
                    .then(async (response) => {
                      fetchImages()
                    })
                  e.target.value = ''
                }}
              />
              {console.log(file)}
              <input
                style={{ display: 'none', maxHeight: 50 }}
                ref={fileRef}
                type="file"
                accept={file.length ? (!file[0].isVideo ? "image/*" : "video/*") : "image/*|video/*"}
                onChange={async (e) => {

                  if (selectedProduct.length == 10) {
                    toast.error('Cannot add more than 10 images in a single post')
                  } else {
                    let fd = new FormData()
                    fd.append('image_file', e.target.files[0])
                    setIsUploading(true)
                    axios.post(`/marketing/social-post-image/upload/${id}`, fd)
                      .then(async (response) => {
                        setFile(response.data.data.results.map((data) => ({
                          image: `${imageBaseUrl}/SocialPostImage/${id}/${data.image}`,
                          isVideo: videoExt.includes(data.image.split('.')[data.image.split('.').length - 1]),
                          id: data.id
                        })))
                        toast.success('File Uploaded')
                      })
                      .catch((error) => {
                        toast.error(error?.response?.data?.message)
                      })
                      .finally(() => {
                        setIsUploading(false)
                      })
                    e.target.value = ''
                  }
                }}
              />

              {(((tab == 1) && Boolean(selectedProduct.length)) || ((tab == 0) && Boolean(file.length))) && (
                <div
                  className={styles.photo_swiper}
                >
                  <Swiper
                    style={{
                      width: '100%'
                    }}
                    navigation={true}
                    // loop={true}
                    centeredSlides={true}
                    observeParents={true}
                    observer={true}
                    spaceBetween={5}
                  >
                    {(tab == 1) ? (
                      <>
                        {selectedProduct.map((data, index) => (
                          <SwiperSlide
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <div
                              style={{
                                position: 'relative'
                              }}
                              className={styles.upload_photo}
                              variant="contained"
                            >
                              <img
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  borderRadius: '10px',
                                  objectFit: 'contain'
                                }}
                                src={`${imageBaseUrl}/Event/${data.image}`}
                              />

                              {/* <div
                                                                className={styles.image_option_1}
                                                                onClick={() => {
                                                                    setSelectionIndex(data.id)
                                                                    fileRef2.current.click()
                                                                }}
                                                            >
                                                                <EditOutlined />
                                                            </div>

                                                            <div
                                                                className={styles.image_option_2}
                                                                onClick={() => {
                                                                    axios.post(`/venue-social-post-image/delete/${data.id}`, {
                                                                        post_group_id: id
                                                                    })
                                                                        .then(async (response) => {
                                                                            fetchImages()
                                                                        })
                                                                }}
                                                            >
                                                                <DeleteForeverOutlined />
                                                            </div> */}
                            </div>
                          </SwiperSlide>
                        ))}
                      </>
                    ) : (
                      <>
                        {file.map((data, index) => (
                          <SwiperSlide
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <div
                              style={{
                                position: 'relative'
                              }}
                              className={styles.upload_photo}
                              variant="contained"
                            >
                              {data.isVideo ? (
                                <video
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '10px',
                                    objectFit: 'contain'
                                  }}
                                  src={data.image}
                                />
                              ) : (
                                <img
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '10px',
                                    objectFit: 'contain'
                                  }}
                                  src={data.image}
                                />
                              )}

                              <div
                                className={styles.image_option_1}
                                onClick={() => {
                                  setSelectionIndex(data.id)
                                  fileRef2.current.click()
                                }}
                              >
                                <EditOutlined />
                              </div>

                              <IconButton
                                style={{
                                  padding: 0,
                                  display: 'block'
                                }}
                                className={styles.image_option_2}
                                disabled={isDeleteLoading}
                                onClick={() => {
                                  setIsDeleteLoading(true)
                                  axios.post(`/marketing/social-post-image/delete/${data.id}`, {
                                    post_group_id: id
                                  })
                                    .then(async (response) => {
                                      fetchImages()
                                    })
                                    .catch(() => {

                                    })
                                    .finally(() => {
                                      setIsDeleteLoading(false)
                                    })
                                }}
                              >
                                {isDeleteLoading ? <CircularProgress size={20} /> : <DeleteForeverOutlined />}
                              </IconButton>
                            </div>
                          </SwiperSlide>
                        ))}
                      </>
                    )}
                  </Swiper>
                </div>
              )}
            </div>
          </div>

          <div
            className={styles.add_images_container}
          >
            <div
              className={styles.add_images_text}
            >
              Description
            </div>

            {/* <textarea
                            style={{
                                width: 280,
                                height: 130,
                                outline: 'none',
                                borderRadius: 8,
                                border: '1px solid #AFAFAF'
                            }}
                            placeholder="Text Here"
                            value={description}
                            onChange={(event) => {
                                setDescription(event.target.value)
                            }}
                            rows={4}
                        /> */}

            <div
              className={styles.content_container}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  // maxWidth: { xs: 320, sm: 480 },
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  classes={{
                    indicator: styles.indicator
                  }}
                >
                  {contentTabs?.map((data) => ((data.show) && (
                    <Tab
                      className={data.bgstyle}
                      value={data.value}
                      label={data.label}
                    />
                  )))}
                </Tabs>
              </Box>

              <div>
                {description?.length}/{{
                  0: 63206,
                  1: 280,
                  2: 2190
                }[tabValue]}
              </div>
            </div>

            <div>
              <FormControl
                className={styles.form_control}
              >
                <TextField
                  placeholder='Enter your texts and links'
                  multiline
                  rows={4}
                  variant="outlined"
                  value={description}
                  inputProps={{
                    maxLength: {
                      0: 63206,
                      1: 280,
                      2: 2190
                    }[tabValue]
                  }}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FormControl>

              {((description?.length >= 280) && Boolean(platformName.filter((data) => data.isTwitter).length)) && (
                <small
                  style={{
                    fontWeight: 400,
                    cursor: 'pointer',
                    color: '#ef2d2c',
                    display: 'block'
                  }}
                >
                  Twitter can only take 280 characters of description rest will be truncated.
                </small>
              )}

              {((description?.length >= 2190) && Boolean(platformName.filter((data) => data.isInstagram).length)) && (

                <small
                  style={{
                    fontWeight: 400,
                    cursor: 'pointer',
                    color: '#ef2d2c',
                    display: 'block'
                  }}
                >
                  Instagram can only take 2190 characters of description rest will be truncated.
                </small>
              )}

              {((description?.length >= 63206) && Boolean(platformName.filter((data) => data.isFacebook).length)) && (

                <small
                  style={{
                    fontWeight: 400,
                    cursor: 'pointer',
                    color: '#ef2d2c',
                    display: 'block'
                  }}
                >
                  Facebook can only take 63206 characters of description rest will be truncated.
                </small>
              )}

            </div>
          </div>
        </div>

        <div
          className={styles.post_preview}
        >
          <PreviewPost
            platformName={platformName}
            file={file}
            link={link}
            caption={description}
          />
        </div>
      </div>

      <Dialog
        open={showSearchProduct}
        onClose={() => {
          setShowSearchProduct(false)
          postLinkImage()
        }}
      >
        <DialogContent
          style={{
            height: 450
          }}
        >
          <SearchProduct
            isVenueList={true}
            selectedProduct={selectedProduct}
            onProductChipClick={(data) => {
              if (selectedProduct.length == 10) {
                toast.error('Cannot select more than 10 products in a single post')
              } else {
                setSelectedProduct((prevState) => {

                  if (prevState.filter((data1) => data1.product_id_integer == data.product_id_integer).length) {

                    let prodRemovedArr = prevState.filter((data1) => data1.product_id_integer != data.product_id_integer)

                    if (prodRemovedArr.length == 0) {
                      setDescription('')
                    } else if (prodRemovedArr.length == 1) {

                      setDescription(`${prodRemovedArr[0]?.description || prodRemovedArr[0]?.Description || ''}

                                            Shop Online: https://${externalWebsite ? externalWebsite : `${subDomain}.esplanda.com`}/product/${prodRemovedArr[0]?.ProductName?.toLowerCase().replaceAll(' ', '-')}-${prodRemovedArr[0]?.ProducerName?.toLowerCase().replaceAll(' ', '-')}/${prodRemovedArr[0]?.product_id_integer}
                                                                                    `)

                    } else if (prodRemovedArr.length > 1) {
                      setDescription(`Shop Online: https://${externalWebsite ? externalWebsite : `${subDomain}.esplanda.com`}`)
                    }

                    return prodRemovedArr
                  } else {
                    if (prevState.length == 0) {
                      setDescription(`${data.description || data.Description || ''}

                                            Shop Online: https://${externalWebsite ? externalWebsite : `${subDomain}.esplanda.com`}/product/${data.ProductName?.toLowerCase().replaceAll(' ', '-')}-${data.ProducerName?.toLowerCase().replaceAll(' ', '-')}/${data.product_id_integer}
                                                                                    `)
                    } else {
                      setDescription(`Shop Online: https://${externalWebsite ? externalWebsite : `${subDomain}.esplanda.com`}`)
                    }
                    return ([...prevState, data])
                  }
                })
              }
              // setLink(data.image || data.image_file)
              // setSelectedProductId(data.ProductId)
              // setShowSearchProduct(false)

            }}
            onDoubleClick={() => {
              setShowSearchProduct(false)
              postLinkImage()
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CreatePost