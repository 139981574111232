import { weekOrder } from "../../helpers/const";

//accepts an array and status
// status is  pickup || delivery
export const filteredVenue = (array, status) =>
  array
    .filter(({ venuetimings }) => venuetimings.toLowerCase() === status)
    .sort((a, b) => weekOrder[a.dayname] - weekOrder[b.dayname]);

export const validateAndUpdateBaseUrl = (url) => {
  var reSpecialCharacters = /^[a-zA-Z0-9-_]+$/;

  if (url.search(reSpecialCharacters) === -1) {
    return true;
  } else {
    return false;
  }
};

export const formatTwoDecimals = (number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  }).format(number);

export const removeDuplicateBanner = (banners) => {
  const seen = new Set();

  const filteredBanner = banners.filter(({ title }) => {
    const duplicate = seen.has(title);
    seen.add(title);

    return !duplicate;
  });

  return filteredBanner;
};

export const isObjectEmpty = (object) =>
  Object.keys(object).length === 0 && object.constructor === Object;
