import axios, { nodeAxios } from "../../utils/axiosConfig";

export const getInventories = (params) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: "SET_INVENTORIES_REQUEST"
      })
      let { data } = await nodeAxios.get(`/products/list?page=${params.page}&limit=10&q=${params.searchStr}&category_id=${params.category_id}&sort_by=${params.sort_by}&sort_by_order=${params.sort_by_order}`);
      let inventories = data.data;
      let pagination = data.pagination;

      dispatch({
        type: "SET_INVENTORIES",
        payload: {
          inventories,
          pagination,
        },
      });
    };
  } catch (err) {
    throw err;
  }
};

export const editInventory = (params) => {
  try {
    return async (dispatch) => {
      await axios.post(
        `/product/update-my-product/${params.data.id}`,
        { id: params.data.id, ...params.editables }
      );
      dispatch({
        type: "EDIT_INVENTORIES",
        payload: params,
      });
    };
  } catch (err) {
    throw err;
  }
};

export const deleteInventory = (id) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: "DELETE_INVENTORY",
        payload: id,
      });
    };
  } catch (err) {
    throw err;
  }
};

export const getFormats = () => {
  try {
    return async (dispatch) => {
      let { data } = await axios.post(`/product/formats`, null);
      let formats = data.data.result;
      dispatch({
        type: "SET_FORMATS",
        payload: formats,
      });
    };
  } catch (err) {
    throw err;
  }
};

export const getSuggestions = (params) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: "SET_SUGGESTIONS_REQUEST",
      })
      let {
        data: {
          data: { list: inventories },
        },
      } = await axios.get(`/product/master-list?q=${params.q}${params.catId ? `&category_id=${params.catId}` : ''}&${params.searchType ? params.searchType : ""}&limit=10&sort=Name&by=ASC`
      );
      dispatch({
        type: "SET_SUGGESTIONS",
        payload: inventories,
      });
    };
  } catch (err) {
    throw err;
  }
};

export const changeVisibility = (params) => {
  try {
    return async (dispatch) => {
      await axios.get(
        `/product/visibility/${params.id}`
      );
      dispatch({
        type: "CHANGE_PRODUCT_VISIBILITY",
        payload: params.id,
      });
    };
  } catch (err) {
    throw err;
  }
};
