import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EmailTemplateIcon from '../../../../assets/images/email-template.png'

const TemplateLabel = (props) => {
    return (
        <div
            style={{
                height: 150,
                width: 150
            }}
        >
            <img
                style={{
                    objectFit: 'contain'
                }}
                height={120}
                width={120}
                src={EmailTemplateIcon}
            />

            <div
                style={{
                    lineHeight: '40px'
                }}
            >
                {props.label}
            </div>
        </div>
    )
}

export default function EmailTemplates(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                // maxWidth: { xs: 320, sm: 480 },
                bgcolor: 'background.paper',
            }}
        >
            <div
                style={{
                    fontWeight: 600,
                    fontSize: 18
                }}
            >
                Select Template
            </div>
            {/* <Tabs
                value={value}
                onChange={handleChange}
                aria-label="visible arrows tabs example"
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                    [`& .${tabsClasses.flexContainer}`]: {
                        flexWrap: 'wrap'
                    },
                }}
            > */}
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}
            >
                <Tab
                    onClick={() => {
                        props.loadTemplateDesign({
                            "counters": {
                                "u_column": 1,
                                "u_row": 1
                            },
                            "body": {
                                "id": "--xSdROfBH",
                                "rows": [
                                    {
                                        "id": "aKK8e0JDRs",
                                        "cells": [
                                            1
                                        ],
                                        "columns": [
                                            {
                                                "id": "VLdb5GpDX6",
                                                "contents": [],
                                                "values": {
                                                    "backgroundColor": "",
                                                    "padding": "0px",
                                                    "border": {},
                                                    "_meta": {
                                                        "htmlID": "u_column_1",
                                                        "htmlClassNames": "u_column"
                                                    }
                                                }
                                            }
                                        ],
                                        "values": {
                                            "displayCondition": null,
                                            "columns": false,
                                            "backgroundColor": "",
                                            "columnsBackgroundColor": "",
                                            "backgroundImage": {
                                                "url": "",
                                                "fullWidth": true,
                                                "repeat": false,
                                                "center": true,
                                                "cover": false
                                            },
                                            "padding": "0px",
                                            "anchor": "",
                                            "hideDesktop": false,
                                            "_meta": {
                                                "htmlID": "u_row_1",
                                                "htmlClassNames": "u_row"
                                            },
                                            "selectable": true,
                                            "draggable": true,
                                            "duplicatable": true,
                                            "deletable": true,
                                            "hideable": true
                                        }
                                    }
                                ],
                                "values": {
                                    "popupPosition": "center",
                                    "popupWidth": "600px",
                                    "popupHeight": "auto",
                                    "borderRadius": "10px",
                                    "contentAlign": "center",
                                    "contentVerticalAlign": "center",
                                    "contentWidth": "500px",
                                    "fontFamily": {
                                        "label": "Arial",
                                        "value": "arial,helvetica,sans-serif"
                                    },
                                    "textColor": "#000000",
                                    "popupBackgroundColor": "#FFFFFF",
                                    "popupBackgroundImage": {
                                        "url": "",
                                        "fullWidth": true,
                                        "repeat": false,
                                        "center": true,
                                        "cover": true
                                    },
                                    "popupOverlay_backgroundColor": "rgba(0, 0, 0, 0.1)",
                                    "popupCloseButton_position": "top-right",
                                    "popupCloseButton_backgroundColor": "#DDDDDD",
                                    "popupCloseButton_iconColor": "#000000",
                                    "popupCloseButton_borderRadius": "0px",
                                    "popupCloseButton_margin": "0px",
                                    "popupCloseButton_action": {
                                        "name": "close_popup",
                                        "attrs": {
                                            "onClick": "document.querySelector('.u-popup-container').style.display = 'none';"
                                        }
                                    },
                                    "backgroundColor": "#fff",
                                    "backgroundImage": {
                                        "url": "",
                                        "fullWidth": true,
                                        "repeat": false,
                                        "center": true,
                                        "cover": false
                                    },
                                    "preheaderText": "",
                                    "linkStyle": {
                                        "body": true,
                                        "linkColor": "#0000ee",
                                        "linkHoverColor": "#0000ee",
                                        "linkUnderline": true,
                                        "linkHoverUnderline": true
                                    },
                                    "_meta": {
                                        "htmlID": "u_body",
                                        "htmlClassNames": "u_body"
                                    }
                                }
                            },
                            "schemaVersion": 10
                        })
                    }}
                    label={<TemplateLabel label="Blank" />}
                />
                {props.templates?.map((data) => (
                    <Tab
                        onClick={() => {
                            if (data.design) {
                                props.loadTemplateDesign(JSON.parse(data.design))
                            }

                        }}
                        label={<TemplateLabel label={data.name} />}
                    />
                ))}
            </div>
        </Box>
    );
}
