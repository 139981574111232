import React from 'react'

const PricingIcon = () => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="clip-pricetag">
                    <rect width="32" height="32" />
                </clipPath>
            </defs>
            <g id="pricetag" clip-path="url(#clip-pricetag)">
                <g id="Group_2408" data-name="Group 2408" transform="translate(-156 -260)">
                    <g id="Group_2404" data-name="Group 2404">
                        <g id="Group_2403" data-name="Group 2403">
                            <g id="Group_2402" data-name="Group 2402">
                                <path id="Path_3858" data-name="Path 3858" d="M174.716,280.041a2.81,2.81,0,0,0-.9-.555q-.5-.192-1.354-.426v-2.853a1.553,1.553,0,0,1,1.09,1.139.855.855,0,0,0,.871.727.827.827,0,0,0,.607-.251.81.81,0,0,0,.251-.593,1.66,1.66,0,0,0-.168-.679,2.9,2.9,0,0,0-.477-.72,2.483,2.483,0,0,0-.916-.686,4.277,4.277,0,0,0-1.258-.3V274.1c0-.375-.147-.562-.439-.562s-.433.192-.433.576v.727a3.36,3.36,0,0,0-2.17.878,2.94,2.94,0,0,0-.367,3.416,2.582,2.582,0,0,0,1,.888,8.413,8.413,0,0,0,1.536.566v3.189a1.837,1.837,0,0,1-.737-.377,1.67,1.67,0,0,1-.415-.545,8.714,8.714,0,0,1-.322-.861.741.741,0,0,0-.295-.4.91.91,0,0,0-.522-.144.859.859,0,0,0-.641.264.832.832,0,0,0-.257.594,2.364,2.364,0,0,0,.192.909,3,3,0,0,0,.593.891,3.327,3.327,0,0,0,1,.717,4.317,4.317,0,0,0,1.4.377v1.852a.867.867,0,0,0,.1.467.384.384,0,0,0,.346.151.335.335,0,0,0,.347-.186,1.626,1.626,0,0,0,.079-.59v-1.708a3.839,3.839,0,0,0,1.694-.514,2.906,2.906,0,0,0,1.08-1.115,3.04,3.04,0,0,0,.367-1.464,2.838,2.838,0,0,0-.23-1.149A2.563,2.563,0,0,0,174.716,280.041Zm-3.128-1.234a2.777,2.777,0,0,1-.919-.505,1.058,1.058,0,0,1-.3-.819,1.069,1.069,0,0,1,.322-.851,2.41,2.41,0,0,1,.9-.453Zm1.942,4.485a1.884,1.884,0,0,1-1.07.556v-3a3,3,0,0,1,1.08.552,1.168,1.168,0,0,1,.367.922A1.41,1.41,0,0,1,173.53,283.292Z" fill="#344952" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_2407" data-name="Group 2407">
                        <g id="Group_2406" data-name="Group 2406">
                            <g id="Group_2405" data-name="Group 2405">
                                <path id="Path_3859" data-name="Path 3859" d="M181.273,271.04l-4.151-7.189a1,1,0,0,0-.866-.5H172.5v-2.7a.5.5,0,0,0-1,0v2.7h-3.756a1,1,0,0,0-.866.5l-4.149,7.187a1,1,0,0,0-.271.684v19.129a1,1,0,0,0,1,1h17.082a1,1,0,0,0,1-1V271.723A1,1,0,0,0,181.273,271.04Zm-1.731,18.811H164.458V272.043l3.863-6.692H171.5v1.822a2,2,0,1,0,1,0v-1.821h3.178l3.864,6.691ZM172,269.6a.5.5,0,0,0,.5-.5v-.846a.988.988,0,0,1,.5.846,1,1,0,1,1-1.707-.706,1.018,1.018,0,0,1,.207-.14v.846A.5.5,0,0,0,172,269.6Z" fill="#344952" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default PricingIcon