import React, { useCallback, useEffect, useState } from 'react'
import styles from './Modifiers.module.scss'
import InputField from '../Library/InputField/InputField'
import { Button, CircularProgress, Dialog, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material'
import { Add, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import { debounce } from 'lodash'
import axios from '../../utils/axiosConfig'
import { toast } from 'react-toastify'
// import AddModifierDialog from '../RestaurantsInventory/AddModifierDialog/AddModifierDialog' 
import AddModifierDialog from '../pages/Menu/Items/AddModifierDialog/AddModifierDialog' 
import Loader from '../Library/Loader'
import { useConfirm } from 'material-ui-confirm'

const columns = [
    {
        id: 'title',
        label: 'Modifier Name',
        width: '80%'
    },
    {
        id: 'options',
        label: '# of options',
        format: (value) => value?.length,
        width: '20%'
    }
];


const Modifiers = () => {

    const confirm = useConfirm()

    const [searchStr, setSearchStr] = useState("");
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState({})
    const [showAddModifier, setShowAddModifier] = useState(false)
    const [editModifierObj, setEditModifierObj] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [editObj, setEditObj] = useState({})
    const [isListLoading, setIsListLoading] = useState(false)
    const [sort, setSort] = useState({ sort_by: 'created_at', sort_by_order: 'DESC' })
    const [catId, setCatId] = useState(0)

    const fetchMyList = (page, queryStr) => {
        setIsListLoading(true)
        axios.get('/modifier', {
            params: {
                page: page || 1,
                q: queryStr || searchStr,
                sort_by: sort.sort_by,
                sort_by_order: sort.sort_by_order,
                category_id: catId || undefined
            }
        })
            .then((res) => {
                setProducts(res.data?.data?.results)
                setPagination(res.data?.data?.pagination)
            })
            .finally(() => {
                setIsSearchLoading(false)
                setIsListLoading(false)
            })
    }


    const handleSearch = (searchStr) => {
        try {
            setIsSearchLoading(true)
            fetchMyList(pagination.current_page, searchStr)
        } catch (err) {
            toast.error('Error occured while fetching inventories, please try again.')
        };
    };

    const debounceSearch = useCallback(debounce(handleSearch, 1000), [])

    useEffect(() => {
        fetchMyList()
    }, [])

    useEffect(() => {
        fetchMyList(pagination.current_page)
    }, [sort, catId])


    const handleDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete product',
        })
            .then(() => {
                axios.delete(`/modifier/delete/${id}`)
                    .then(() => {
                        fetchMyList()
                        toast.success('Product deleted')
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
            })
    }

    const handleEdit2 = (id) => {
        axios.get(`/modifier/get/${id}`)
            .then((res) => {
                setEditObj(res.data?.data?.result)
                setIsEdit(true)
                setShowAddModifier(true)
            })
    }

    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.heading_container}
            >
                <div>
                    <InputField
                        placeholder="Search modifier"
                        value={searchStr}
                        onChange={(e) => {
                            setSearchStr(e.target.value)
                            debounceSearch(e.target.value)
                        }}
                        onIconButtonClick={e => debounceSearch(searchStr)}
                        showIconButton={true}
                        endIcon={isSearchLoading ? (
                            <CircularProgress
                                size={16}
                                style={{
                                    color: '#ef2d2c'
                                }}
                            />
                        ) : (
                            <Search
                                style={{
                                    color: '#ef2d2c'
                                }}
                            />
                        )}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10
                    }}
                >
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        style={{
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: 20
                        }}
                        onClick={() => {
                            setShowAddModifier(true)
                            // navigate(routePaths.categoryMapping)
                        }}
                    >
                        Create modifier
                    </Button>
                </div>
            </div>

            {isListLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer
                        style={{
                            border: '1px solid #D3D3D3'
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ width: column.width, cursor: 'pointer' }}
                                            // onClick={() => {
                                            //     if (column.sortid) {
                                            //         if (sort.sort_by === column.sortid) {
                                            //             if (sort.sort_by_order === 'ASC') {
                                            //                 setSort({ ...sort, sort_by_order: 'DESC' })
                                            //             } else {
                                            //                 setSort({ ...sort, sort_by_order: 'ASC' })
                                            //             }
                                            //         } else {
                                            //             setSort({ sort_by_order: 'DESC', sort_by: column.sortid })
                                            //         }
                                            //     }
                                            // }}
                                            sx={{
                                                [`&.${tableCellClasses.head}`]: {
                                                    backgroundColor: '#F0F0F0',
                                                    color: 'black',
                                                    fontWeight: 600
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 5,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <div>
                                                    {column.label}
                                                </div>

                                                {/* {((sort.sort_by === column.sortid) && (sort.sort_by_order === 'DESC')) ? (
                                                    <KeyboardArrowUp />
                                                ) : (
                                                    <KeyboardArrowDown />
                                                )} */}
                                            </div>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    ></TableCell>
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            {Boolean(products?.length) && (
                                <TableBody>
                                    {products.map((row) => {
                                        return (
                                            <TableRow
                                                sx={{
                                                    '&:nth-of-type(even)': {
                                                        backgroundColor: '#F7F5F7',
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}

                                                <TableCell>
                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            handleEdit2(row.id)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>

                                                <TableCell>
                                                    <Button
                                                        style={{
                                                            color: 'red'
                                                        }}
                                                        variant="text"
                                                        onClick={() => {
                                                            handleDelete(row.id)
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>

                    {!Boolean(products?.length) && (
                        <div
                            style={{
                                textAlign: 'center',
                                padding: 15,
                                fontSize: 20,
                                fontWeight: 600
                            }}
                        >
                            No Modifier Found
                        </div>
                    )}
                    {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}

                    {(pagination.last_page > 1) && (
                        <div
                            style={{
                                padding: '10px 0'
                            }}
                        >
                            <Pagination
                                page={pagination.current_page}
                                count={pagination.last_page}
                                onChange={(e, page) => fetchMyList(page)}
                            />
                        </div>
                    )}
                </Paper>
            )}

            <Dialog
                open={showAddModifier}
                onClose={() => {
                    setShowAddModifier(false)
                    fetchMyList()
                    setIsEdit(false)
                    setEditObj({})
                }}
                PaperProps={{
                    style: {
                        width: '100%'
                    }
                }}
                keepMounted={false}
            >
                <AddModifierDialog
                    editObj={editObj}
                    isEdit={isEdit}
                    // handleDelete={handleDelete}
                    onClose={() => {
                        setShowAddModifier(false)
                        fetchMyList()
                        setIsEdit(false)
                        setEditObj({})
                    }}
                />
            </Dialog>
        </div>
    )
}

export default Modifiers