const initialState = {
  emailTypes: {},
};

function emailSettings(state = initialState, action) {
  switch (action.type) {
    case "SET_EMAIL_TYPES":
      return {
        ...state,
        emailTypes: action.payload,
      };
    default:
      return state;
  }
}

export default emailSettings;
