/* eslint-disable react/prop-types */
import React from 'react'
import styles from './ImageGallery.module.scss'
import TabsSection from '../TabsSection/TabsSection'
import { IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import MyLibrary from './MyLibrary/MyLibrary'
import FreeImages from './FreeImages/FreeImages'

const tabs = [
    {
      value: 0,
      label: 'My Library',
    },
    {
      value: 1,
      label: 'Unsplash Images',
    },
    // {
    //   value: 2,
    //   label: 'Generate with AI',
    // }
  ]

function ImageGallery(props) {
  return ( 
    <div> 
        <IconButton
            sx={{ position: 'absolute', top: 24, right: 24, zIndex: 1  }}
            onClick={() => props.onClose()}
        >
            <CloseIcon sx={{ width: 30, height: 30 }} />
        </IconButton>
    <TabsSection
      tabs={tabs}
      defaultTab={0}
    //   tabsbg='var(--bg-3)'
      tabsBody={[
        {
          component: <MyLibrary {...props}  />,
        },
        {
          component: <FreeImages {...props} />,
        },
        {
          component: <div>Generate with AI</div>,
        },
      ]}
    />
    </div>
  )
}

export default ImageGallery
