const initialState = {
    result: {
        store_info_check: {},
        order_details: [],
        pagination: {},
        users: {},
        order: {},
        order_venue: {}
    },
    isLoading: false,
    suggestions: [],
    formats: []
};

function orderDetailsNew(state = initialState, action) {
    switch (action.type) {
        case "SET_ORDER_SUGGESTIONS_REQUEST":
            return {
                ...state,
                isLoading: true
            }
        case "SET_ORDER_DETAILS":
            return {
                ...state,
                result: {
                    ...action.payload.data.results
                }
            }
        case "SET_ORDER_SUGGESTIONS":
            return {
                ...state,
                suggestions: action.payload,
                isLoading: false
            }
        case "SET_PRODUCT_FORMATS":
            return {
                ...state,
                formats: action.payload
            }
        case "ADD_ORDER_ITEM":
            return {
                ...state,
                result: {
                    ...state.result,
                    order: {
                        ...state.result.order,
                        cc_amount: parseFloat(state.result.order.cc_amount) + (parseFloat(action.payload.price) * parseInt(action.payload.quantity)),
                        grand_total: parseFloat(state.result.order.grand_total) + (parseFloat(action.payload.price) * parseInt(action.payload.quantity))
                    },
                    order_venue: {
                        ...state.result.order_venue,
                        venueproducttotal: parseFloat(state.result.order_venue.venueproducttotal) + (parseFloat(action.payload.price) * parseInt(action.payload.quantity))
                    },
                    order_details: [action.payload, ...state.result.order_details]
                }
            }
        case "UPDATE_ORDER_ITEM":
            let prevDetail_1 = state.result.order_details.find(e => e.product_id === action.payload.prevId);
            let updatedDetails = state.result.order_details.map((val) => {
                if (val.product_id === action.payload.prevId) {
                    return {
                        product_id: action.payload.value.ProductId,
                        name: action.payload.value.ProductName,
                        productformat: action.payload.value.format,
                        product_image: action.payload.value.image,
                        quantity: 1,
                        price: action.payload.value.Price,
                        vpid: action.payload.value.id,
                        SKU: action.payload.value.sku,
                        brewery_name: action.payload.value.ProducerName,
                        product_category: action.payload.value.category_name
                    }
                } else {
                    return val;
                };
            });
            return {
                ...state,
                result: {
                    ...state.result,
                    order: {
                        ...state.result.order,
                        cc_amount: (parseFloat(state.result.order.cc_amount) - parseFloat(prevDetail_1.price)) + parseFloat(action.payload.value.Price),
                        grand_total: (parseFloat(state.result.order.grand_total) - parseFloat(prevDetail_1.price)) + parseFloat(action.payload.value.Price)
                    },
                    order_venue: {
                        ...state.result.order_venue,
                        venueproducttotal: (parseFloat(state.result.order_venue.venueproducttotal) - parseFloat(prevDetail_1.price)) + parseFloat(action.payload.value.Price)
                    },
                    order_details: updatedDetails
                }
            }
        case "DELETE_ORDER_ITEM":
            let prevDetail_2 = state.result.order_details.find(e => e.product_id === action.payload.id);
            let newDetails = state.result.order_details.filter(e => e.product_id !== action.payload.id)
            return {
                ...state,
                result: {
                    ...state.result,
                    order: {
                        ...state.result.order,
                        cc_amount: parseFloat(state.result.order.cc_amount) - parseFloat(prevDetail_2.price),
                        grand_total: parseFloat(state.result.order.grand_total) - parseFloat(prevDetail_2.price)
                    },
                    order_venue: {
                        ...state.result.order_venue,
                        venueproducttotal: parseFloat(state.result.order_venue.venueproducttotal) - parseFloat(prevDetail_2.price)
                    },
                    order_details: newDetails
                }
            }
        case "UPDATE_ORDER_ITEM_FORMAT":
            let prevDetail_3 = state.result.order_details.find(e => e.product_id === action.payload.product_id);
            let changedFormatDetails = state.result.order_details.map((val) => {
                if (val.product_id === action.payload.product_id) {
                    return {
                        ...val,
                        productformat: action.payload.format.format,
                        price: action.payload.format.Price,
                        product_id: action.payload.format.ProductId,
                        vpid: action.payload.format.id
                    }
                } else {
                    return val;
                }
            });
            return {
                ...state,
                result: {
                    ...state.result,
                    order: {
                        ...state.result.order,
                        cc_amount: (parseFloat(state.result.order.cc_amount) - parseFloat(prevDetail_3.price)) + parseFloat(action.payload.format.Price),
                        grand_total: (parseFloat(state.result.order.grand_total) - parseFloat(prevDetail_3.price)) + parseFloat(action.payload.format.Price)
                    },
                    order_venue: {
                        ...state.result.order_venue,
                        venueproducttotal: (parseFloat(state.result.order_venue.venueproducttotal) - parseFloat(prevDetail_3.price)) + parseFloat(action.payload.format.Price)
                    },
                    order_details: changedFormatDetails
                }
            }
        case "UPDATE_ORDER_ITEM_PRICE":
            let prevDetail_5 = state.result.order_details.find(e => e.product_id === action.payload.product_id);
            let changedPriceDetails = state.result.order_details.map((val) => {
                if (val.product_id === action.payload.product_id) {
                    return {
                        ...val,
                        unit_price: action.payload.price,
                        price: parseFloat(val.quantity) * parseFloat(action.payload.price)
                    }
                } else {
                    return val;
                }
            });

            let venueProductTotal = changedPriceDetails.reduce((total, data) => parseFloat(total) + (parseFloat(data.unit_price) * data.quantity), 0)
            return {
                ...state,
                result: {
                    ...state.result,
                    order_venue: {
                        ...state.result.order_venue,
                        venueproducttotal: venueProductTotal
                    },
                    order_details: changedPriceDetails
                }
            }
        case "UPDATE_ORDER_ITEM_SUBFEES":
            return {
                ...state,
                result: {
                    ...state.result,
                    order_venue: {
                        ...state.result.order_venue,
                        [action.payload.key]: action.payload.value
                    },
                }
            }
        case "UPDATE_ORDER_ITEM_QUANTITY":
            console.log(state.result)
            let prevDetail_4 = state.result.order_details.find(e => e.product_id === action.payload.product_id);
            let changedQuantityDetails = state.result.order_details.map((val) => {
                if (val.product_id === action.payload.product_id) {
                    return {
                        ...val,
                        quantity: action.payload.quantity
                    }
                } else {
                    return val;
                }
            });

            if (action.payload.action === 'increment') {
                return {
                    ...state,
                    result: {
                        ...state.result,
                        order: {
                            ...state.result.order,
                            cc_amount: parseFloat(state.result.order.cc_amount) + parseFloat(prevDetail_4.unit_price),
                            grand_total: parseFloat(state.result.order.grand_total) + parseFloat(prevDetail_4.unit_price)
                        },
                        order_venue: {
                            ...state.result.order_venue,
                            venueproducttotal: parseFloat(state.result.order_venue.venueproducttotal) + parseFloat(prevDetail_4.unit_price)

                        },
                        order_details: changedQuantityDetails
                    }
                }
            } else {
                return {
                    ...state,
                    result: {
                        ...state.result,
                        order: {
                            ...state.result.order,
                            cc_amount: parseFloat(state.result.order.cc_amount) - parseFloat(prevDetail_4.unit_price),
                            grand_total: parseFloat(state.result.order.grand_total) - parseFloat(prevDetail_4.unit_price)
                        },
                        order_venue: {
                            ...state.result.order_venue,
                            venueproducttotal: parseFloat(state.result.order_venue.venueproducttotal) - parseFloat(prevDetail_4.unit_price)

                        },
                        order_details: changedQuantityDetails
                    }
                }
            }
        case "CLOSE_ORDER":
            return {
                ...state,
                result: {
                    ...state.result,
                    order: {
                        ...state.result.order,
                        status: 'can'
                    }
                }
            }
        default: return state;
    };
};

export default orderDetailsNew;