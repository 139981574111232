import React from 'react';
import { TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PrimaryButton, SecondaryButton } from '../../../../util_comps/CustomButton';
import { useDispatch } from 'react-redux';
import { getOrders } from '../../../../redux/actions/ordersActions';
import day from 'dayjs';
import { toast } from 'react-toastify';
import GenericButton from '../../../Library/GenericButton/GenericButton';
import GenericTextFieldWithLabel from '../../../Library/TextFieldWithLabel/GenericTextFieldWithLabel';

const useStyles = makeStyles(() => ({
    textInput: {
        borderRadius: "0",
        width: "100%",
        minWidth: '17vw',
        fontSize: '.9rem',
        padding: '0 !important',
        "& > input": {
            padding: "10px 12px !important",
        },
    }
}));

const SearchSection = ({ filters, setFilters, id, setSnackbar, ...props }) => {
    const classes = useStyles();
    const dispatch = useDispatch(data => data);
    const handleFilterChange = (val) => {
        setFilters(val);
    }
    const handleSearch = () => {
        try {
            dispatch(getOrders({ ...filters, state: '', id }));
        } catch (error) {
            toast.error('Error occured, try again later')
        };
    };
    return (
        <div style={{ display: 'flex', marginBottom: 20, alignItems: 'flex-end', gap: 15 }}>
            <GenericTextFieldWithLabel
                label="Start Date"
                id="date"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                value={filters.startDate}
                onChange={e => handleFilterChange({ ...filters, startDate: e.target.value })}
            />

            <GenericTextFieldWithLabel
                label="End Date"
                id="date"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                value={filters.endDate}
                onChange={e => handleFilterChange({ ...filters, endDate: e.target.value })}
            />

            <div
                style={{
                    display: 'flex',
                    gap: 10
                }}
            >
                <GenericButton
                    variant="contained"
                    onClick={handleSearch}
                    disabled={props.isActionsDisabled}
                >
                    Search
                </GenericButton>
                <GenericButton
                    variant="contained"
                    btnColor="secondary"
                    onClick={e => handleFilterChange({ startDate: day().subtract(1, 'month').format('YYYY-MM-DD'), endDate: day().format('YYYY-MM-DD') })}
                    disabled={props.isActionsDisabled}
                >
                    Clear
                </GenericButton>
            </div>
        </div>
    );
};

export default SearchSection;