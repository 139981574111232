import React, { useEffect, useRef, useState } from 'react'
import { format } from 'date-fns'
import { axios2 } from '../utils/axiosConfig';
import { getUTCDateObj } from '../utils/helpers';

const useTimeSlotPicker = ({ onTimeslotChange, cart, venueId, type, dateTime, onDeliveryChange, timeSlotData }) => {

    const abortControllerRef = useRef(null);

    const [openCal, setOpenCal] = useState(false)
    const [slots, setSlots] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController for the new request
        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        setIsLoading(true)
        axios2.get(`/venue-hour/slot/${venueId}/${type || "delivery"}`, { signal: abortController.signal })
            .then((response) => {
                setSlots(response.data?.data?.result)
                let isAvailable = true

                if (response.data.data?.result?.length) {
                    isAvailable = true
                } else {
                    isAvailable = false
                }

                onTimeslotChange({
                    isAvailable: isAvailable,
                    result: response.data.data?.result
                })
            })
            .catch((response) => {

            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [type]);

    useEffect(() => {

        if (Array.isArray(slots) && slots?.length) {
            if (!dateTime?.date || !dateTime?.time || (dateTime?.date && slots?.length && !Boolean(parseInt(slots?.filter((data) => data.date != dateTime.date)?.length)))) {
                onDeliveryChange({
                    date: slots[0]?.date,
                    time: slots[0]?.intervals[0]
                })
            }

            if (dateTime.date && slots?.length) {
                if (!slots?.find((data) => data.date == format(getUTCDateObj(dateTime?.date), 'yyy-MM-dd'))?.intervals?.includes(dateTime.time)) {
                    onDeliveryChange({
                        date: slots[0]?.date,
                        time: slots[0]?.intervals[0]
                    })
                }
            }
        }
    }, [timeSlotData, slots])


    return {
        openCal,
        setOpenCal,
        slots,
        setSlots,
        isLoading
    }
}

export default useTimeSlotPicker