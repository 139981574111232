import React from 'react'
import { visuallyHidden } from '@mui/utils';
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';


const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={order === headCell.id ? orderBy : false}
                    >
                        <TableSortLabel
                            active={order === headCell.id}
                            direction={order === headCell.id ? orderBy : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {order === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {orderBy === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead