import { Close } from '@mui/icons-material'
import { DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import Const from '../../helpers/const'
import styles from './GetStoreIdDialog.module.scss'

const GetStoreIdDialog = (props) => {
    return (
        <>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onClick={props.onClose}
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent>
                <div
                    className={styles.website_instructions_modal}
                >
                    <ol>
                        <li className={styles.website_instructions_list}>
                            Login to Esplanda Admin, using the credentials for the venue you want to connect to.
                        </li>

                        <li className={styles.website_instructions_list}>
                            Click on My Store Settings, in the left menu
                        </li>

                        <li className={styles.website_instructions_list}>
                            Click on Multi-store management tab.
                        </li>

                        <li className={styles.website_instructions_list}>
                            Get the ‘Store Connect id” for that venue & enter it here.
                        </li>
                    </ol>
                </div>
            </DialogContent>
        </>
    )
}

export default GetStoreIdDialog