import React from 'react'
import { useSelector } from 'react-redux';
import CustomizeHomePage from '../CustomizeHomePage/CustomizeHomePage'

const CustomizeHomepageRoute = () => {
  const user = useSelector((state) => state.user);
  return (
    <CustomizeHomePage isActionsDisabled={user?.data?.is_claim_business === 1} />
  )
}

export default CustomizeHomepageRoute