import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import GenericButton from '../../Library/GenericButton/GenericButton'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import styles from './EventDialog.module.scss'
import { format } from 'date-fns'
import axios from '../../../utils/axiosConfig'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { toast } from 'react-toastify'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { DeleteForever, Edit } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import UploadImage from '../../Library/UploadImage/UploadImage'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'

const localizer = momentLocalizer(moment)

const imageType = [
    {
        value: 'contain',
        label: 'Contain'
    },
    {
        value: 'cover',
        label: 'Cover'
    },
    {
        value: 'fill',
        label: 'Fill'
    }
]

const EventDialog = (props) => {

    const fileRef = useRef()

    const confirm = useConfirm()

    const [showCalender, setShowCalender] = useState(true)
    const [file, setFile] = useState()
    const [eventName, setEventName] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [address, setAddress] = useState('')
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [image, setImage] = useState('')
    const [isEditingId, setIsEditingId] = useState(false)
    const [eventImageType, setEventImageType] = useState('contain')
    const [events, setEvents] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const deleteEvent = (id) => {
        axios.delete(`/venue-event/delete/${isEditingId}`)
            .then(() => {
                toast.success('Event Deleted')
                setShowCalender(true)
                props.onClose()
            })
            .catch(() => {
                toast.error('Something went wrong')
            })
    }

    const fetchEvents = () => {
        axios.get('/venue-event/list')
            .then((response) => {

                let formatedArr = response.data.data.result.map((data) => {

                    let day = data.event_date.split('-')[2]
                    let month = data.event_date.split('-')[1]
                    let year = data.event_date.split('-')[0]

                    let starthrs = data.start_time.split(':')[0]
                    let startmin = data.start_time.split(':')[1]

                    let endhrs = data.end_time.split(':')[0]
                    let endmin = data.end_time.split(':')[1]

                    return ({
                        id: data.id,
                        title: data.title,
                        start: new Date(year, parseInt(month) - 1, day, starthrs, startmin),
                        end: new Date(year, parseInt(month) - 1, day, endhrs, endmin),
                        address: data.address,
                        lat: data.lat,
                        lng: data.lng,
                        image: data.image
                    })
                })
                setEvents(formatedArr)
            })
    }
    console.log(events)

    useEffect(() => {
        if (props.open) {
            fetchEvents()
        }
    }, [props])

    const onSubmit = (event) => {
        event.preventDefault()

        if (!file && !isEditingId) {
            toast.error('Please Upload Image')
            return
        }

        if (!lat) {
            toast.error('Please Select Event Location')
            return
        }
        setIsLoading(true)
        const fd = new FormData()
        fd.append('title', eventName)
        fd.append('event_date', eventDate)
        fd.append('start_time', startTime)
        fd.append('end_time', endTime)
        fd.append('image_file', file)
        fd.append('address', address)
        fd.append('lat', lat)
        fd.append('lng', lng)
        fd.append('image_type', eventImageType)

        axios.post(isEditingId ? `/venue-event/edit/${isEditingId}` : '/venue-event/create', fd)
            .then((response) => {
                setEventDate('')
                setEventName('')
                setStartTime('')
                setEndTime('')
                setFile()
                setAddress('')
                setLat('')
                setLng('')
                toast.success('Event Created')
                setShowCalender(true)
                props.onClose()
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    maxWidth: 'unset',
                    width: showCalender ? '100%' : '50%',
                    height: showCalender ? '100%' : '60%',
                }
            }}
            open={props.open}
            onClose={() => {
                setShowCalender(true)
                props.onClose()
            }}
            maxWidth="xl"
        >
            <form
                style={{
                    height: '110%'
                }}
                onSubmit={onSubmit}
            >
                <DialogContent
                    style={{
                        height: '100%',
                        overflow: 'hidden'
                    }}
                >
                    {showCalender ? (
                        <div
                            style={{
                                height: '100%'
                            }}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                    fontSize: '17px'
                                }}
                            >
                                Please click on the day to add an event for that day
                            </div>
                            <Calendar
                                localizer={localizer}
                                selectable
                                onSelectSlot={(slotInfo) => {
                                    setEventDate(format(slotInfo.start, 'yyyy-MM-dd'))
                                    setShowCalender(false)
                                }}
                                onSelectEvent={(event) => {
                                    setEventDate(format(new Date(event.start), 'yyyy-MM-dd'))
                                    // setEventDate(format(event.start, "dd-MM-yyyy"))
                                    setStartTime(format(event.start, "HH:mm"))
                                    setEndTime(format(event.end, "HH:mm"))
                                    setEventName(event.title)
                                    setShowCalender(false)
                                    setIsEditingId(event.id)
                                    setAddress(event.address)
                                    setLat(event.lat)
                                    setLng(event.lng)
                                    setImage(event.image)
                                }}
                                views={['month']}
                                events={events}
                            //   events={myEventsList}
                            />
                        </div>
                    ) : (
                        <div>

                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: 20,
                                    fontWeight: 600
                                }}
                            >
                                {isEditingId ? "Edit Event" : "Add Event"}
                            </div>

                            <div>
                                Event Image
                            </div>

                            <div
                                className={styles.upload_btn_container}
                            >
                                <div>
                                    {(file || image) && (
                                        <div
                                            className={styles.image_edit_delete_container}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    fileRef.current?.click()
                                                }}
                                            >
                                                <Edit style={{ fontSize: 18, color: 'black' }} />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => {
                                                    setFile()
                                                    setImage('')
                                                }}
                                            >
                                                <DeleteForever style={{ fontSize: 18, color: 'black' }} />
                                            </IconButton>
                                        </div>
                                    )}

                                    <UploadImage
                                        uploadText="Upload Image"
                                        ref={fileRef}
                                        image={file}
                                        imageLink={image}
                                        onChange={(event) => {
                                            setFile(event.target.files[0])
                                            event.target.value = ''
                                        }}
                                        onImageClose={(event) => {
                                            event.preventDefault()
                                            setFile()
                                        }}
                                    />
                                </div>
                            </div>
                            {console.log(eventDate)}
                            <div
                                className={styles.input_field_container}
                                style={{
                                    marginBottom: 10
                                }}
                            >
                                <label
                                    className={styles.input_label}
                                    htmlFor="address"
                                >
                                    Event Location
                                    <span
                                        className={styles.required_asterisk}
                                    >
                                        *
                                    </span>

                                    <small
                                        style={{
                                            color: '#ef2d2c'
                                        }}
                                    >
                                        (Please Select Event Location From The List)
                                    </small>
                                </label>
                                <div
                                    style={{
                                        position: 'relative'
                                    }}
                                >
                                    <GooglePlacesAutocomplete
                                        // ref={googlePlacesAutocompleteRef}
                                        inputClassName={styles.location_input}
                                        apiKey="AIzaSyDAVj9EIbpwwynljEQX-R8NyxngpUf7PG4"
                                        initialValue={address}
                                        onSelect={({ description }) => {
                                            geocodeByAddress(description)
                                                .then((results) => {
                                                    return getLatLng(results[0])
                                                })
                                                .then(({ lat, lng }) => {
                                                    setAddress(description)
                                                    setLat(lat)
                                                    setLng(lng)
                                                });
                                        }}
                                    />
                                </div>

                            </div>

                            <div
                                className={styles.input_section_container}
                            >
                                <div
                                    className={styles.input_section}
                                >
                                    <label
                                        className={styles.input_label}
                                        htmlFor="eventName"
                                    >
                                        Event Name
                                        <span
                                            className={styles.required_asterisk}
                                        >
                                            *
                                        </span>
                                    </label>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                notchedOutline: styles.input_box_style
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: '9px 12px'
                                            }
                                        }}
                                        variant="outlined"
                                        id="eventName"
                                        name="eventName"
                                        type="text"
                                        fullWidth
                                        value={eventName}
                                        onChange={(event) => {
                                            setEventName(event.target.value)
                                        }}
                                        required={true}
                                    />
                                </div>

                                <div
                                    className={styles.input_section}
                                >
                                    <label
                                        className={styles.input_label}
                                        htmlFor="date"
                                    >
                                        Date

                                        <span
                                            className={styles.required_asterisk}
                                        >
                                            *
                                        </span>
                                    </label>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                notchedOutline: styles.input_box_style
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: '9px 12px'
                                            }
                                        }}
                                        variant="outlined"
                                        id="date"
                                        name="date"
                                        type="date"
                                        fullWidth
                                        value={eventDate}
                                        onChange={(event) => {
                                            setEventDate(event.target.value)
                                        }}
                                        // onClick={(event) => {
                                        //     setShowCalender(true)
                                        // }}
                                        required
                                    />
                                </div>
                            </div>

                            <div
                                className={styles.input_section_container}
                            >
                                <div
                                    className={styles.input_section}
                                >
                                    <label
                                        className={styles.input_label}
                                        htmlFor="startTime"
                                    >
                                        Start Time

                                        <span
                                            className={styles.required_asterisk}
                                        >
                                            *
                                        </span>
                                    </label>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                notchedOutline: styles.input_box_style
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: '9px 12px'
                                            }
                                        }}
                                        variant="outlined"
                                        id="startTime"
                                        name="startTime"
                                        type="time"
                                        fullWidth
                                        value={startTime}
                                        onChange={(event) => {
                                            setStartTime(event.target.value)
                                        }}
                                        required
                                    />
                                </div>

                                <div
                                    className={styles.input_section}
                                >
                                    <label
                                        className={styles.input_label}
                                        htmlFor="endTime"
                                    >
                                        End time

                                        <span
                                            className={styles.required_asterisk}
                                        >
                                            *
                                        </span>
                                    </label>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                notchedOutline: styles.input_box_style
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: '9px 12px'
                                            }
                                        }}
                                        variant="outlined"
                                        id="endTime"
                                        name="endTime"
                                        type="time"
                                        fullWidth
                                        value={endTime}
                                        onChange={(event) => {
                                            setEndTime(event.target.value)
                                        }}
                                        required
                                    />
                                </div>
                            </div>

                            <div>
                                <GenericSelectWithLabel
                                    labelClassName={styles.select_label}
                                    label="Image Type"
                                    variant="outlined"
                                    value={eventImageType}
                                    onChange={(event) => setEventImageType(event.target.value)}
                                >
                                    {imageType.map((data) => (
                                        <MenuItem
                                            value={data.value}
                                        >
                                            {data.label}
                                        </MenuItem>
                                    ))}
                                </GenericSelectWithLabel>
                            </div>
                        </div>
                    )}
                </DialogContent>

                <DialogActions>
                    <GenericLoadingButton
                        variant="contained"
                        loading={isLoading}
                        loadingPosition="end"
                        type="submit"
                    >
                        Save
                    </GenericLoadingButton>

                    <Button
                        onClick={(e) => {
                            setShowCalender(true)
                            props.onClose()
                        }}
                        style={{
                            backgroundColor: "#DDDDDD",
                            color: "#171717",
                        }}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </DialogActions>

                {isEditingId && (
                    <div
                        className={styles.delete_container}
                    >
                        <Button
                            style={{
                                border: '1px solid red',
                                color: 'red'
                            }}
                            onClick={() => {
                                confirm({
                                    title: 'Do you wish to confirm to delete this event.',
                                    description: 'Deleting this event means losing all its detail'
                                })
                                    .then(() => {
                                        deleteEvent(isEditingId)
                                    })
                                    .catch(() => {

                                    });
                            }}
                            variant="outlined"
                            fullWidth={true}
                        >
                            Delete
                        </Button>
                    </div>
                )}

            </form>
        </Dialog>
    )
}

export default EventDialog