import React, { useState, useEffect } from "react";
import { Autocomplete, Typography, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  RegularInput,
  TextArea,
  InputDropdown,
} from "../../../../../util_comps/Input";
import BlackBtn from "../Buttons/BlackBtn";
import { useNavigate } from "react-router-dom";
import Const from "../../../../helpers/const";
import GenericButton from "../../../../Library/GenericButton/GenericButton";

const StoreDetails = (props) => {
  const [storeData, setStoreData] = useState({});

  const { VenueSettingList, isLoading } = useSelector(
    (store) => store.venueSetting
  );
  useEffect(() => {
    if (!isLoading) {
      setStoreData({
        ...VenueSettingList?.data?.venue,
        ...VenueSettingList?.data?.venue_social_links,
        venue_hours: VenueSettingList?.data?.venue_hours,
      });
    }
  }, [isLoading, VenueSettingList]);

  const timeZonesList = [
    { title: "America/New_York" },
    { title: "America/Chicago" },
    { title: "America/Phoenix" },
    { title: "America/Los_Angeles" },
    { title: "US/Hawaii" },
    { title: "US/Alaska" },
  ];

  const {
    Name,
    timeZone,
    full_address,
    contact_no,
    venuetype,
    Description,
    email,
  } = storeData;
  let history = useNavigate()
  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography
            style={{
              color: "black",
              fontSize: "1.15rem",
              fontWeight: "bold",
              marginBottom: 7,
            }}
          >
            Store Details
          </Typography>
          <p
            style={{
              color: props.isActionsDisabled ? 'gray' : 'blue',
              textDecoration: 'underline',
              cursor: props.isActionsDisabled ? 'text' : 'pointer',
            }}
            onClick={e => {
              if (!props.isActionsDisabled) {
                history('/MyUsers')
              }
            }}
          >
            My Employees
          </p>
        </div>
        <div style={{ marginBottom: 30 }}>
          <div
            style={{
              marginBottom: 15,
              display: 'flex',
              gap: 10
            }}
          >
            <div
            >
              <Typography
              >
                Store Name</Typography>
              <TextField
                value={Name}
                variant="filled"
                disabled
              />
            </div>
            <div
            >
              <Typography
              >
                Phone Number
              </Typography>
              <TextField
                disabled
                value={contact_no}
                variant="filled"

              />
            </div>
            <div style={{ flex: 1 }}>
              <Typography
              >
                Email</Typography>
              <TextField
                value={email}
                variant="filled"
                disabled
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              gap: 10
            }}>
            <div
              style={{
                width: 250
              }}
            >
              <Typography
              >Time-Zone</Typography>
              <Autocomplete
                id="timezone-autocomplete"
                options={timeZonesList}
                clearOnBlur={false}
                getOptionLabel={(option) => option.title}
                value={{ title: storeData.timeZone }}
                onChange={(event, newInputValue) => {
                  setStoreData({ ...storeData, timeZone: newInputValue.title });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{
                      width: "auto",
                      display: "block",
                      height: "100%",
                    }}
                    defaultValue={storeData.timeZone}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div style={{ flex: 1, display: Const.isOurDesiStore ? 'none' : 'initial' }}>
              <Typography
              >
                Type</Typography>
              <TextField
                disabled
                value={venuetype}
                variant="filled"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div
          >
            <div
            >
              <Typography
              >
                Full Address
              </Typography>
              <TextField
                disabled
                value={full_address}
                variant="filled"

              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div
            >
              <Typography
              >Description</Typography>
              <TextArea
                name="Description"
                cols="20"
                value={Description}
                onChange={(event) => {
                  setStoreData({
                    ...storeData,
                    Description: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "25px",
              marginBottom: 30
            }}
          >
            <GenericButton
              variant="contained"
              onClick={() => props.save(storeData)}
              disabled={props.isActionsDisabled}
              size="large"
            >
              Save
            </GenericButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDetails;
