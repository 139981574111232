import React, { useCallback, useContext, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Dialog, Pagination, useMediaQuery } from '@mui/material';
import { Add, DragIndicator, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import styles from './Categories.module.scss'
import axios, { nodeAxios } from '../../../../utils/axiosConfig';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import MarketingContext from '../../../Marketing2/Util/marketingContext';
import { debounce } from 'lodash';
import Loader from '../../../Library/Loader';
import { useNavigate } from 'react-router-dom';
import { reorder } from '../../../CustomPageBuilder/EditSection/Util/helpers';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddCategory from './AddCategory/AddCategory';
import CategoriesHeader from './CategoriesHeader/CategoriesHeader';
import CategoriesTable from './CategoriesTable/CategoriesTable';
import MobileItems from './MobileItems/MobileItems';

const columns = [
    {
        id: 'category_name',
        label: 'Category',
    }
];

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle
});

const Categories = ({ isActionDisabled }) => {

    const isMobile = useMediaQuery('(max-width:1000px)')

    const confirm = useConfirm()
    const [defaulTaxValue, setDefaulTaxValue] = useState('')
    const [pagination, setPagination] = useState({})
    const [searchStr, setSearchStr] = useState("");
    const [sort, setSort] = useState({ sort_by: 'sequence_id', sort_by_order: 'ASC' })
    const [cat, setCat] = useState({})
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(true)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const [showAddCategory, setShowAddCategory] = useState(false)


    const fetchVenueCatMenu = (page, queryStr) => {
        axios.get('/venue-category-order', {
            params: {
                page: page || 1,
                q: queryStr || searchStr,
                // sort_by: sort.sort_by,
                // sort_by_order: sort.sort_by_order
            }
        })
            .then((res) => {
                setVenueCategoryMenu(res.data?.data?.results)
                setDefaulTaxValue(res.data?.data?.venue_tax)
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setIsListLoading(false)
                setIsSearchLoading(false)
            })
    }


    const handleSearch = (searchStr) => {
        try {
            setIsSearchLoading(true)
            fetchVenueCatMenu(pagination.current_page, searchStr)
        } catch (err) {
            toast.error('Error occured while fetching inventories, please try again.')
        };
    };

    const debounceSearch = useCallback(debounce(handleSearch, 1000), [])


    useEffect(() => {
        fetchVenueCatMenu()
    }, [])

    const handleSetsequence = (items) => {
        let payload = items.map(element => element.id)
        // items?.forEach((element, index) => {
        //     payload[index] = element?.id
        // });

        axios.post('/venue-category-order/change-order', {
            venue_category_order_ids: payload
        })
            .then(() => {

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            venueCategoryMenu,
            result.source.index,
            result.destination.index
        );

        setVenueCategoryMenu(newitems);
        handleSetsequence(newitems)
    };

    const handleDelete = (row) => {
        confirm({
            title: `Are you sure you want to delete ${row?.category_name} category`,
        })
            .then(() => {
                axios.delete(`/venue-category-order/delete/${row?.id}`)
                    .then(() => {
                        nodeAxios.post('/change-log/create', { what: `${row?.category_name} is deleted` })
                            .then(() => {

                            })
                            .catch((error) => {
                                console.log('🚀OUTPUT --> error:', error);
                            });
                        fetchVenueCatMenu()

                        toast.success('Category deleted')
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
            })
    }


    return (
        <div className="tw-py-[10px] lg:tw-py-[25px] tw-flex tw-flex-col tw-gap-5">
            <CategoriesHeader {...{ searchStr, setSearchStr, debounceSearch, isSearchLoading, setShowAddCategory, isMobile, isActionDisabled }} />

            <div
                style={{
                    display: 'none',
                    justifyContent: 'space-between',
                    padding: 10
                }}
            >
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 700
                    }}
                >
                    Categories
                </div>

                <Button
                    style={{
                        backgroundColor: 'black',
                        color: 'white'
                    }}
                    variant="contained"
                    onClick={() => {
                        setShowAddCategory(true)
                    }}
                    disabled={isActionDisabled}
                >
                    Add Category
                </Button>
            </div>

            {isListLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                    {isMobile ? (
                        <MobileItems
                            {...{ products: venueCategoryMenu, setIsEdit, setCat, setShowAddCategory, isActionDisabled }}
                        />
                    ) : (
                        <CategoriesTable
                            {...{ venueCategoryMenu, handleDelete, setIsEdit, setCat, setShowAddCategory, onDragEnd, sort, setSort, getItemStyle, isActionDisabled }}
                        />
                    )}
                    {!Boolean(venueCategoryMenu?.length) && (
                        <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-p-8 tw-max-w-[320px] tw-mx-auto tw-mt-12'>
                            <p className='tw-font-bold tw-text-lg '>New Category</p>
                            <p className='tw-text-[#8B8B8B] tw-text-center tw-text-sm my-2'>Create a set of categories to categorize items.</p>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: 20
                                }}
                                disabled={isActionDisabled}
                                onClick={() => {
                                    setShowAddCategory(true)
                                }}
                            >
                                New Category
                            </Button>
                        </div>
                    )}

                    <Dialog
                        open={showAddCategory}
                        onClose={() => {
                            setShowAddCategory(false)
                            fetchVenueCatMenu()
                            setCat({})
                            setIsEdit(false)
                        }}
                        classes={{
                            root: 'tw-font-inter',
                            container: '!tw-items-end sm:!tw-items-center',
                            paper: '!tw-m-0 sm:!tw-m-8 !tw-w-full sm:!tw-w-auto !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                        }}
                    >
                        <AddCategory
                            defaulTaxValue={defaulTaxValue}
                            isEdit={isEdit}
                            cat={cat}
                            onClose={() => {
                                setShowAddCategory(false)
                                fetchVenueCatMenu()
                                setCat({})
                                setIsEdit(false)
                            }}
                        />
                    </Dialog>

                    {!Boolean(venueCategoryMenu?.length) && (
                        <div
                            style={{
                                textAlign: 'center',
                                padding: 15,
                                fontSize: 20,
                                fontWeight: 600
                            }}
                        >
                            No Category Found
                        </div>
                    )}

                    {Boolean(venueCategoryMenu?.length) && (pagination?.last_page > 1) && (
                        <div
                            style={{
                                padding: '10px 0'
                            }}
                        >
                            <Pagination
                                page={pagination.current_page}
                                count={pagination.last_page}
                                onChange={(e, page) => fetchMyList(page)}
                            />
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
}

export default Categories