import { DeleteForever } from '@mui/icons-material'
import { Checkbox, FormControlLabel, IconButton, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { FieldArray } from 'formik'
import React from 'react'
import Const from '../../helpers/const'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import LightTooltip from '../../Library/LightTooltip/LightTooltip'
import SelectTextField from '../../Library/SelectTextField/SelectTextField'
import StyledTableCell from '../../Library/StyledTableCell/StyledTableCell'
import StyledTableRow from '../../Library/StyledTableRow/StyledTableRow'
import ValueTextField from '../../Library/ValueTextField/ValueTextField'
import Maps from '../../Maps/Maps'
import BusinessHours from '../BusinessHours/BusinessHours'
import styles from './DeliverySetting.module.scss'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch'
import PreparationTime from '../PreparationTime/PreparationTime'

const headings = [
  {
    label: (
      <LightTooltip
        title={
          <span
            style={{ display: 'inline-block', padding: '0 15px' }}
          >
            Define your delhivery radius, in miles.
          </span>
        }
        arrow={true}
        placement="top"
      >
        <div>
          Deliver From(Miles)
        </div>
      </LightTooltip>
    )
  },
  {
    label: (
      <LightTooltip
        title={
          <span
            style={{ display: 'inline-block', padding: '0 15px' }}
          >
            Define your delhivery radius, in miles.
          </span>
        }
        arrow={true}
        placement="top"
      >
        <div>
          Deliver To(Miles)
        </div>
      </LightTooltip>
    )
  },
  {
    label: (
      <LightTooltip
        title={
          <span
            style={{ display: 'inline-block', padding: '0 15px' }}
          >
            If you charge flat fees, choose "Dollar". Choose "Percent", if you want us  to calculate the fees based on order value.
          </span>
        }
        arrow={true}
        placement="top"
      >
        <div>Delivery Fee Type</div>
      </LightTooltip>
    )
  },
  {
    label: (
      <LightTooltip
        title={
          <span
            style={{ display: 'inline-block', padding: '0 15px' }}
          >
            Enter Delivery fees here
          </span>
        }
        arrow={true}
        placement="top"
      >
        <div>Delivery Fees</div>
      </LightTooltip>
    )
  },
  {
    label: (
      <LightTooltip
        title={
          <span
            style={{ display: 'inline-block', padding: '0 15px' }}
          >
            Only orders with value greater than this amount will be allowed to process. We recommend to have a low value here to get more delivery orders
          </span>
        }
        arrow={true}
        placement="top"
      >
        <div>Min. Delivery Amount</div>
      </LightTooltip>
    )
  },
]

const DeliverySetting = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          padding: '10px 0'
        }}
      >
        <div>
          We Offer Delivery
        </div>
        <GenericSwitch
          checked={Boolean(props.formik.values.is_offer_delivery)}
          onChange={(event) => {
            props.formik.setFieldValue('is_offer_delivery', event.target.checked ? 1 : 0)
          }}
        />

        {Boolean(props.formik.values.is_offer_delivery) && (
          <FormControlLabel
            style={{
              marginLeft: 0,
              gap: 6
            }}
            control={(
              <GenericSwitch
                checked={Boolean(parseInt(props.formik.values?.is_show_no_of_stock?.delivery))}
                onChange={(event) => {
                  props.formik.setFieldValue('is_show_no_of_stock.delivery', event.target.checked ? 1 : 0)
                }}
              />
            )}
            label="Show in-stock number"
            labelPlacement="start"
          />
        )}
      </div>

      {Boolean(props.formik.values.is_offer_delivery) && (
        <div
          className={styles.container}
        >
          <div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={props.formik.values.delivery_service_charge_to_customer}
                  onChange={(event) => {
                    props.formik.setFieldValue('delivery_service_charge_to_customer', event.target.checked ? 1 : 0)
                  }}
                  color="default"
                />
              )}
              label={
                <span
                  style={{
                    textDecoration: 'underline'
                  }}
                >
                  <LightTooltip
                    title={
                      <span
                      // style={{
                      //   display: 'inline-block',
                      //   padding: '0 15px'
                      // }}
                      >
                        If this checkbox is enabled, this fees will be added to each order to offset your store fees.
                      </span>
                    }
                    arrow={true}
                    placement="top"
                  >
                    <span>
                      Add platform Service
                    </span>
                  </LightTooltip>
                  Charge of {props.formik.values.delivery_fee} to customer order.
                </span>
              }
            />
            <FieldArray
              name="venue_delivery_zone"
              render={arrayHelpers => (
                <div>
                  <TableContainer
                    component={Paper}
                  >
                    <Table
                      sx={{
                        minWidth: 700
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {headings.map((data) => (
                            <StyledTableCell
                              style={{
                                color: 'black'
                              }}
                            >
                              {data.label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {props.formik.values.venue_delivery_zone?.map((member, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <ValueTextField
                                id={`venue_delivery_zone[${index}].radius_from`}
                                name={`venue_delivery_zone[${index}].radius_from`}
                                type="radius"
                                value={member.radius_from}
                                onChange={props.formik.handleChange}
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <ValueTextField
                                id={`venue_delivery_zone[${index}].radius_to`}
                                name={`venue_delivery_zone[${index}].radius_to`}
                                type="radius"
                                value={member.radius_to}
                                onChange={props.formik.handleChange}
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <GenericSelectWithLabel
                                id={`venue_delivery_zone[${index}].fee_type`}
                                name={`venue_delivery_zone[${index}].fee_type`}
                                containerClassname={styles.small_input_container}
                                value={member.fee_type}
                                onChange={props.formik.handleChange}
                              >
                                <MenuItem value="percentage">Percentage</MenuItem>
                                <MenuItem value="dollar">Dollar</MenuItem>
                              </GenericSelectWithLabel>
                            </StyledTableCell>

                            <StyledTableCell>
                              <ValueTextField
                                id={`venue_delivery_zone[${index}].fees`}
                                name={`venue_delivery_zone[${index}].fees`}
                                type={member.fee_type}
                                value={member.fees}
                                onChange={props.formik.handleChange}
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <ValueTextField
                                id={`venue_delivery_zone[${index}].min_delivery_amount`}
                                name={`venue_delivery_zone[${index}].min_delivery_amount`}
                                type="dollar"
                                value={member.min_delivery_amount}
                                onChange={props.formik.handleChange}
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              {((props.formik.values.venue_delivery_zone.length - 1) === index) && (index !== 0) && (
                                <IconButton
                                  onClick={() => {
                                    arrayHelpers.remove(index)
                                  }}
                                >
                                  <DeleteForever />
                                </IconButton>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>

                  {(props.formik.values.venue_delivery_zone.length < 10) && (
                    <div
                      className={styles.add_more_btn_container}
                    >
                      <GenericButton
                        variant="contained"
                        onClick={() => arrayHelpers.push({
                          fee_type: 'dollar',
                          fees: '0',
                          min_delivery_amount: '0',
                          radius_from: props.formik.values.venue_delivery_zone[props.formik.values.venue_delivery_zone.length - 1]?.radius_to || 0,
                          radius_to: parseInt(props.formik.values.venue_delivery_zone[props.formik.values.venue_delivery_zone.length - 1]?.radius_to || 0) + 5
                        })}
                      >
                        Add New Zone
                      </GenericButton>
                    </div>
                  )}
                </div>
              )}
            />

            <BusinessHours
              formik={props.formik}
            />
          </div>

          <div
            className={styles.map_container}
          >
            <Maps
              googleMapURL={`http://maps.googleapis.com/maps/api/js?key=${Const.GOOGLE_MAP_KEY}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div className={styles.container_element} />}
              mapElement={<div style={{ height: `100%` }} />}
              center={{ lat: props.formik.values.latitude, lng: props.formik.values.longitude }}
              zoom={19}
              places={props.formik.values.venue_delivery_zone.map((data, index) => {
                console.log(data)
                return ({
                  lat: props.formik.values.latitude,
                  lng: props.formik.values.longitude,
                  id: index,
                  circle: {
                    radius: parseFloat(data.radius_to) * 1600,
                    options: {
                      strokeColor: "#2381FF"
                    }
                  }
                })
              })}
            />

            <div>
              <LightTooltip
                title={
                  <span
                    style={{ display: 'inline-block', padding: '0 15px' }}
                  >
                    Irrespective of delivery radius, you will only be able to deliver to the following states. Leave it blank if you would like to get orders based on your delivery zones, from any state.
                  </span>
                }
                arrow={true}
                placement="top"
              >
                <div>
                  Restrict Delivery to following States:
                </div>
              </LightTooltip>

              <SelectTextField
                onChange={(value) => {
                  if (value?.length > 0) {
                    props.formik.setFieldValue('delivery_states', value.map((val) => val.title).join(','))
                  } else {
                    props.formik.setFieldValue('delivery_states', null)
                  }
                }}
                value={props.formik.values.delivery_states?.split(",")?.map((val) => ({ title: val }))}
                venueStates={props.venueStates}
              />
              <small
                style={{
                  fontWeight: 400,
                  cursor: 'pointer',
                  color: '#ef2d2c',
                  display: 'block'
                }}
              >
                Please choose from the suggestions
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeliverySetting