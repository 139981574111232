import React from 'react'
import styles from './IncDecCounter.module.scss'

const IncDecCounter = (props) => {
    return (
        <div
        style={props.style}
            className={styles.incdec_container}
        >
            <div
                className={styles.quantity_minus}
                onClick={props.onMinus}
            >
                -
            </div>
            <div
                className={styles.quantity}
            >
                {props.value}
            </div>
            <div
                className={styles.quantity_plus}
                onClick={props.onPlus}
            >
                +
            </div>
        </div>
    )
}

export default IncDecCounter