import React, { useEffect } from "react";
import { TextField, Chip, outlinedInputClasses } from "@mui/material";
import Downshift from "downshift";
import GenericTextFieldWithLabel from "../TextFieldWithLabel/GenericTextFieldWithLabel";
import { toast } from "react-toastify";

export default function TagsInput({ ...props }) {
    const { selectedTags, placeholder, tags,inputValue, setInputValue, ...other } = props;
    const [selectedItem, setSelectedItem] = React.useState([]);

    useEffect(() => {
        setSelectedItem(tags || []);
    }, [tags]);

    useEffect(() => {
        selectedTags(selectedItem);
    }, [selectedItem, selectedTags]);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const newSelectedItem = [...selectedItem];
            const duplicatedValues = newSelectedItem.indexOf(
                event.target.value?.trim()
            );

            if (duplicatedValues !== -1) {
                setInputValue("");
                return;
            }
            if (!event.target.value.replace(/\s/g, "").length) return;

            if(!(/\S+@\S+\.\S+/.test(event.target.value?.trim()))){
toast.error('Please enter a valid email')
return
            }

            newSelectedItem.push(event.target.value?.trim());
            setSelectedItem(newSelectedItem);
            setInputValue("");
        }
        if (
            selectedItem.length &&
            !inputValue.length &&
            event.key === "Backspace"
        ) {
            setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
        }
    }
    function handleChange(item) {
        let newSelectedItem = [...selectedItem];
        if (newSelectedItem.indexOf(item) === -1) {
            newSelectedItem = [...newSelectedItem, item];
        }
        setInputValue("");
        setSelectedItem(newSelectedItem);
    }

    const handleDelete = item => () => {
        const newSelectedItem = [...selectedItem];
        newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
        setSelectedItem(newSelectedItem);
    };

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }
    return (
        <React.Fragment>
            <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                onChange={handleChange}
                selectedItem={selectedItem}
            >
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        onKeyDown: handleKeyDown,
                        placeholder
                    });

                    return (
                        <div>
                            <GenericTextFieldWithLabel
                                label="Send test email to"
                                sx={{
                                    [`& .${outlinedInputClasses.root}`]: {
                                        flexWrap: 'wrap'
                                    }
                                }}
                                InputProps={{
                                    startAdornment: selectedItem?.map(item => (
                                        <Chip
                                            style={{
                                                margin: 5
                                            }}
                                            key={item}
                                            tabIndex={-1}
                                            label={item}
                                            onDelete={handleDelete(item)}
                                        />
                                    )),
                                    onBlur,
                                    onChange: event => {
                                        handleInputChange(event);
                                        onChange(event);
                                    },
                                    onFocus
                                }}
                                {...other}
                                {...inputProps}
                            />
                        </div>
                    );
                }}
            </Downshift>
        </React.Fragment>
    );
}