import React, { useCallback, useEffect, useState } from 'react';
import styles from './Template.module.scss';
import TemplateChip from './TemplateChip/TemplateChip';
import CustomTemplate from '../../../assets/images/custom-template.png';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import axios, { nodeAxios } from '../../../utils/axiosConfig';
import {
  ArrowBackIos,
  ArrowForwardIos,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import LoaderGif from '../../../assets/gif/OFAF.gif';
import { useNavigate } from 'react-router-dom';
import imageBaseUrl from '../../../utils/imageBaseUrl';
import Loader from '../../Library/Loader';

const Template = () => {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('ASC');
  const [searchQuery, setSearchQuery] = useState('');
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [subDomain, setSubDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchSubdomains = () => {
    nodeAxios.get('/web-builder/web-page/domains').then((response) => {
      if (Boolean(response.data?.data?.subdomain)) {
        setSubDomain(response.data?.data?.subdomain);
      }
    });
  };

  const fetchTemplates = (page, q) => {
    axios
      .get('/web-builder/web-template', {
        params: {
          page: page || 1,
          q: q || searchQuery,
          'sort-by-order': sortBy,
          'sort-by': 'title',
          pageSize: 8,
        },
      })
      .then((response) => {
        setTemplates(response.data.data.results);
        setPagination(response.data?.data?.pagination);
      })
      .finally(() => {
        setIsTemplateLoading(false);
      });
  };

  useEffect(() => {
    fetchSubdomains();
  }, []);

  useEffect(() => {
    fetchTemplates(page);
  }, [page, sortBy]);

  const debounceSearch = useCallback(debounce(fetchTemplates, 1000), []);

  const handleTemplateSelect = (id) => {
    setIsLoading(true);
    nodeAxios
      .post(`/web-builder/web-templates/setup-using-template/${id}`)
      .then(() => {
        navigate(`/website-builder/manage-site`);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <img src={LoaderGif} height={300} width={400} />
          <div
            style={{
              textAlign: 'center',
              fontWeight: 600,
              fontSize: 18,
              marginTop: 15,
            }}
          >
            We are building your site
          </div>
        </div>
      ) : (
        <>
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}
          >
            <div className={styles.searh_input_container}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z'
                  stroke='#919191'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M18.9999 18.9999L14.6499 14.6499'
                  stroke='#919191'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>

              <TextField
                InputProps={{
                  classes: {
                    notchedOutline: styles.input_box_style,
                    input: styles.input_element,
                  },
                }}
                variant='outlined'
                placeholder={`Search templates`}
                value={searchQuery}
                onChange={(event) => {
                  debounceSearch(page, event.target.value);
                  setSearchQuery(event.target.value);
                }}
              />
            </div>
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                background: '#E8ECF1',
                borderRadius: '20px',
                border: 'none',
              }}
              size='small'
            >
              <InputLabel focused={false}>Sort By</InputLabel>
              <Select
                value={sortBy}
                label='Sort By'
                onChange={(event) => {
                  setSortBy(event.target.value);
                }}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '0 solid !important',
                  },
                }}
                IconComponent={ExpandMoreOutlinedIcon}
              >
                <MenuItem value='ASC'>Name A-Z</MenuItem>
                <MenuItem value='DESC'>Name Z-A</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div className={styles.pick_text}>Pick a Website template</div>

            <div
              style={{
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>
                Page {page} of {pagination.last_page}
              </div>

              <div>
                <IconButton
                  onClick={() => {
                    if (page > 1) {
                      setPage((prevState) => prevState - 1);
                    }
                  }}
                  size='small'
                >
                  <ChevronLeft
                    size='small'
                    style={{
                      color: 'black',
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() => {
                    if (page < pagination.last_page) {
                      setPage((prevState) => prevState + 1);
                    }
                  }}
                  size='small'
                >
                  <ChevronRight
                    size='small'
                    style={{
                      color: 'black',
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </div>

          <div className={styles.pick_container}>
            {isTemplateLoading ? (
              <Loader />
            ) : (
              templates.length === 0 && (
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: 20,
                    fontWeight: 600,
                    width: '100%',
                  }}
                >
                  No templates Available
                </div>
              )
            )}
            {templates.map((data) => (
              <div className={styles.template_chip_container}>
                <TemplateChip
                  image={
                    `${imageBaseUrl}/WebTemplate/${data.thumbnail}` ||
                    CustomTemplate
                  }
                  text={data.title || 'Create your custom website'}
                  onPreviewClick={() => {
                    window.open(data.link, '_blank');
                  }}
                  onClick={() => {
                    handleTemplateSelect(data.id);
                  }}
                />
              </div>
            ))}
          </div>

          <div className={styles.or_container}>
            <hr className={styles.hr_line} />

            <div className={styles.or_text}>OR</div>
          </div>

          <div className={styles.pick_text}>Create your custom website</div>

          <div className={styles.custom_container}>
            <TemplateChip
              noPreview={true}
              image={CustomTemplate}
              text='Create your custom website'
              onClick={() => {
                setIsLoading(true);
                nodeAxios
                  .post('/web-builder/web-page/create', {
                    title: 'Header',
                    type_id: 1,
                    uri: 'header',
                    payload: {
                      'custom-assets': [],
                      'custom-styles': [],
                      'custom-pages': [
                        {
                          frames: [
                            {
                              component: {
                                type: 'wrapper',
                                attributes: { Target: '_self', id: 'ifv8' },
                              },
                            },
                          ],
                          type: 'main',
                          id: '0GysKeXxcWqs1pXu',
                        },
                      ],
                      'custom-css': '',
                      'custom-html': `<div id="HEADER"></div>`,
                    },
                    // template_id: response.data?.data?.result?.id
                  })
                  .then((response) => {

                    nodeAxios
                      .post('/web-builder/web-page/create', {
                        title: 'Footer',
                        type_id: 2,
                        uri: 'footer',
                        payload: {
                          'custom-assets': [],
                          'custom-styles': [],
                          'custom-pages': [
                            {
                              frames: [
                                {
                                  component: {
                                    type: 'wrapper',
                                    attributes: { Target: '_self', id: 'ifv8' },
                                  },
                                },
                              ],
                              type: 'main',
                              id: '0GysKeXxcWqs1pXu',
                            },
                          ],
                          'custom-css': '',
                          'custom-html': `<div id="FOOTER"></div>`,
                        },
                        // template_id: response.data?.data?.result?.id
                      })
                      .then((response) => {
                        
                        nodeAxios
                          .post('/web-builder/web-page/create', {
                            title: 'MobileNavigation',
                            type_id: 4,
                            uri: 'tabbar',
                            payload: MOBILE_NAVIGATION_PAYLOAD,
                            // template_id: response.data?.data?.result?.id
                          })
                          .then((response) => {
                            
                            nodeAxios
                              .post('/web-builder/web-page/create', {
                                title: 'Home',
                                type_id: 3,
                                is_homepage: 1,
                                uri: 'index',
                                payload: {
                                  'custom-assets': [],
                                  'custom-styles': [],
                                  'custom-pages': [
                                    {
                                      frames: [
                                        {
                                          component: {
                                            type: 'wrapper',
                                            attributes: {
                                              Target: '_self',
                                              id: 'ifv8',
                                            },
                                          },
                                        },
                                      ],
                                      type: 'main',
                                      id: '0GysKeXxcWqs1pXu',
                                    },
                                  ],
                                  'custom-css': '',
                                  'custom-html': `<body target="_self"><div id="HEADER"></div><div id="FOOTER"></div></body>`,
                                },
                                // template_id: response.data?.data?.result?.id
                              })
                              .then((response) => {
                                // axios2
                                //   .post(
                                //     'https://nodeapi.mykidreports.com/convert-thumbnail',
                                //     {
                                //       web_page_id: response.data?.data?.result?.id,
                                //     }
                                //   )
                                //   .catch((err) => {
                                //     // toast.error(err?.data?.message);
                                //     console.log(err);
                                //   });
                                console.log('test');
                                navigate(
                                  `/website-builder/template/${response.data?.data?.result?.id}/Home/index?device=desktop`
                                );
                              })
                              .catch((err) => {
                                toast.error(err?.response?.data?.message);
                              })
                              .finally(() => {
                                setIsLoading(false);
                              });


                          })
                          .catch((err) => {
                            setIsLoading(false);
                            toast.error(err?.response?.data?.message);
                          })
                      })
                      .catch((err) => {
                        setIsLoading(false);
                        toast.error(err?.response?.data?.message);
                      });
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                  });
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Template;

const MOBILE_NAVIGATION_PAYLOAD = {
  "MobileNavigation-assets": [],
  "MobileNavigation-styles": [
      {
          "selectors": [
              "#ikyu"
          ],
          "style": {
              "background-color": "#ffffff"
          },
          "mediaText": "(max-width: 480px)",
          "atRuleType": "media"
      }
  ],
  "MobileNavigation-pages": [
      {
          "frames": [
              {
                  "component": {
                      "type": "wrapper",
                      "attributes": {
                          "Target": "_self",
                          "id": "ifv8"
                      },
                      "components": [
                          {
                              "type": "tabbar1react",
                              "style": "",
                              "attributes": {
                                  "id": "ikyu"
                              },
                              "components": [
                                  {
                                      "type": "tabbar1typereact",
                                      "selectable": false,
                                      "hoverable": false,
                                      "attributes": {
                                          "items": "[\n                    {\"image\":\"https://img.icons8.com/windows/32/home.png\", \"buttontitle\" :\"Home\", \"buttonLink\":\"/\"},\n                    {\"image\":\"https://img.icons8.com/ios/50/user-male-circle--v2.png\",  \"buttontitle\" :\"About Us\", \"buttonLink\":\"/aboutus\" }\n                ]",
                                          "spacing_top": "8",
                                          "spacing_bottom": "8",
                                          "is_button": "true",
                                          "image": "https://images.mykidreports.com/SchoolWebPage/rock-formations-lower-antelope-slot-canyon-near-page-arizona-usa-1686938264.jpg",
                                          "image_fit": "contain",
                                          "image_round_corners": "0"
                                      },
                                      "components": [
                                          {
                                              "type": "textnode",
                                              "content": "\n                "
                                          }
                                      ]
                                  }
                              ]
                          }
                      ]
                  }
              }
          ],
          "type": "main",
          "id": "0GysKeXxcWqs1pXu"
      }
  ],
  "MobileNavigation-css": "* { box-sizing: border-box; } body {margin: 0;}@media (max-width: 480px){#ikyu{background-color:#ffffff;}}undefined",
  "MobileNavigation-html": "<div id=\"MobileNavigation\"><section id=\"ikyu\"><tabbar-1 items=\"[\n{&quot;image&quot;:&quot;https://img.icons8.com/windows/32/home.png&quot;, &quot;buttontitle&quot; :&quot;Home&quot;, &quot;buttonLink&quot;:&quot;/&quot;},\n{&quot;image&quot;:&quot;https://img.icons8.com/ios/50/user-male-circle--v2.png&quot;,  &quot;buttontitle&quot; :&quot;About Us&quot;, &quot;buttonLink&quot;:&quot;/aboutus&quot; }\n                ]\" spacing_top=\"8\" spacing_bottom=\"8\" is_button=\"true\" image=\"https://images.mykidreports.com/SchoolWebPage/rock-formations-lower-antelope-slot-canyon-near-page-arizona-usa-1686938264.jpg\" image_fit=\"contain\" image_round_corners=\"0\">\n</tabbar-1></section></div>",
}