const initialState = {
    isLoading: false,
    isLoggedIn: false,
    paymentslist: {},
    productListError: ""
};
function paymentsList(state = initialState, action) {
    switch (action.type) {
        case "SET_PAYMENTS_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_PAYMENTS_LIST_LOADED":
            return { ...initialState, isLoading: false , paymentslist: action.payload };
        case "SET_PAYMENTS_LIST_ERROR":
            return { ...initialState,paymentsListError:action.payload};
        default:
            return state;
    }
};
export default paymentsList;  