import {
    GET_COUPONS_FAILURE,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_REQUEST,
    POST_NEW_COUPON_REQUEST,
    POST_NEW_COUPON_SUCCESS,
    POST_NEW_COUPON_FAILURE,
    POST_UPDATE_COUPON_REQUEST,
    POST_UPDATE_COUPON_SUCCESS,
    POST_UPDATE_COUPON_FAILURE
} from '../types/couponsTypes';

const initialGetCouponState = {
    loading: true,
    data: [],
    pagination: {}
}

const initialPostCouponState = {
    loading: false,
    err: false,
    errMessage: ''
}

const initialUpdateCouponState = {
    loading: false,
}

export const getCouponsReducer = (state = initialGetCouponState, action) => {
    switch (action.type) {
        case GET_COUPONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_COUPONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                pagination: action.pagination
            }
        case GET_COUPONS_FAILURE:
            return {
                ...state,
                loading: false
            }

        default:
            return state
    }
}

export const postNewCouponReducer = (state = initialPostCouponState, action) => {
    switch (action.type) {
        case POST_NEW_COUPON_REQUEST:
            return {
                ...state,
                loading: true,
                err: false
            }
        case POST_NEW_COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                err: false
            }
        case POST_NEW_COUPON_FAILURE:
            return {
                ...state,
                loading: false,
                err: true,
                errMessage: action.payload
            }

        default:
            return state
    }
}

export const postUpdateCouponReducer = (state = initialUpdateCouponState, action) => {
    switch (action.type) {
        case POST_UPDATE_COUPON_REQUEST:
            return {
                ...state,
                loading: true
            }
        case POST_UPDATE_COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case POST_UPDATE_COUPON_FAILURE:
            return {
                ...state,
                loading: false
            }

        default:
            return state
    }
}