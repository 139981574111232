import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import Customize from "../../../assets/feature.png";
import listicon from "../../../assets/list-text.png";
import editgroupimg from "../../../assets/edit-group.png";
import editgrouplistimg from "../../../assets/edit-group-list.png";
import editgroupdesistoreimg from "../../../assets/edit-group-desistore.png";
import editgrouplistdesistoreimg from "../../../assets/edit-group-list-desistore.png";
import routePaths from '../../../config/route-paths';
const EditExistingProductGroup = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={Customize} />
            </div>
            Customize Your Website & App              
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AddNewProductGroups}>How to Add New Product Groups</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropProductGroupInterface}>Drag & Drop Product Group Interface</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingProductGroup} className={cn('selected')}>Edit Existing Product Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropBanners}>Drag & Drop Banners</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateNewBannerGroup}>Create New Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingBannerGroup}>Edit Existing Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateCustomStorePage}>Create Custom Store Page</Link></li>
</ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Edit Existing Product Group
         </Typography>
         <p><strong>Customizing the layout of your store’s home page is done through the drag & drop interface on the ‘Customize Homepage’ page of the
admin website. You can play around with the layout of product groups and banners to tailor the shopping experience for your customer base.</strong></p>
         <p>
            
         <ol>
            <li>Go to the ‘<strong>Customize Homepage</strong>’ section of the admin website.</li>

            <li>Click on ‘<strong>View Product Groups</strong>’ below the ‘<strong>Search Product Groups</strong>’ search bar.</li>

            <span className={cn('addnewproductsimg')}>

    <img src={Const.isOurDesiStore ? editgroupdesistoreimg : editgroupimg}/>
    </span>

    <li>Find the product group you would like to edit. Below the name, click on ‘<strong>Edit</strong>’.</li>

    <span className={cn('addnewproductsimg')}>   
    <img src={Const.isOurDesiStore ? editgrouplistdesistoreimg : editgrouplistimg}/>
    </span>

    <li>You can change the name of the product group as well as the list type, whether you would like to list by <strong>Category, Product, or Tags</strong>.
</li>

    <li>When you are finished editing your Product Group, click the ‘<strong>Apply</strong>’ button in the bottom-right.</li>    
   

</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default EditExistingProductGroup