import { Button, buttonClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const GenericButton = styled(Button)(({ theme, btnColor }) => ({
  [`&.${buttonClasses.contained}`]: {
    backgroundColor: btnColor == 'secondary' ? '#dddddd' : (btnColor == 'primary') ? '#ef2d2c' : theme.palette.common.black,
    color: btnColor == 'secondary' ? theme.palette.common.black : theme.palette.common.white,
  },
  [`&.${buttonClasses.disabled}`]: {
    opacity: .5
  }
}));

export default GenericButton