import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import searchstatusimg from "../../../assets/search-status.png";
import statuslistimg from "../../../assets/status-list.png";
import routePaths from '../../../config/route-paths';
const TransactionStatusReview = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Orders & Payments               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.findOrders}>Find Orders</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.transactionStatusReview} className={cn('selected')}>Transaction Status Review</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.ordersDetails}>Order Details</Link></li>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.OrdersStatus}>Order Status</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Transaction Status Review  
         </Typography>
         
         <p>
            
         <ol>
            <li>To view your transactions and payments, go to the ‘<strong>Orders</strong>’ page.</li>


            <li>At the top-right corner of the page, you will see ‘<strong>My Payments</strong>’ in underlined blue text. This is to the left of the Order ID search bar.
Click on ‘My Payments’.</li>

    <span className={cn('addnewproductsimg')}>
    <img src={searchstatusimg} />
    </span>
   
    <li>All orders that have been successfully closed will appear on this page. You can filter results by time interval, such as the
past 7, 30, or 90 days. You can also set a custom range of dates using the Start Date and End Date parameters.</li>

<li>Once you’ve selected a Period from the drop-down, or selected a custom date range, click the ‘<strong>Search</strong>’ button to the right.</li>

    
    <span className={cn('addnewproductsimg')}>
    <img src={statuslistimg} />
    </span>


<li>Now you can view your completed payments, and see the <strong>order date, customer information, credit card fees,</strong> Esplanda fees, and your net total.</li>


</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default TransactionStatusReview