import React, { useState } from "react";
import Buttons from "../components/pages/Signup/Parts/Buttons";
import logo from "../assets/New-logo.png";
import desiLogo from "../assets/desi-logo.png";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  ArrowDownward,
  Close,
  KeyboardArrowDown,
  Menu,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Const from "../components/helpers/const";
const useStyles = makeStyles({
  mobMenu: {
    zIndex: 9999999999,
    backgroundColor: "rgb(15,15,15)",
    position: "fixed",
    width: "100%",
    height: "100vh",
    left: "-100%",
    top: 0,
    display: "none",
    transitionDuration: '.3s',
    "@media (max-width: 768px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  mobMenuActive: {
    left: "0% !important",
  },
  deskMenu: {
    display: "flex",
    alignItems: "center",
    marginLeft: "35px",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  hamMenu: {
    display: "none",
    marginLeft: "30px",
    "@media (max-width: 768px)": {
      display: "initial",
    },
  },
  logo: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  text: {
    "@media (max-width: 768px)": {
      fontSize: ".85em !important",
    },
  },
  container: {
    "@media (max-width: 768px)": {
      padding: "2em 10px !important",
    },
  },
});

const GetStarted = (props) => {
  const history = useNavigate();
  const classes = useStyles();
  const [mobMenuActive, setMobMenuActive] = useState(false);
  return (
    <div
      className={classes.container}
      style={{
        position: "relative",
        width: "100%",
        backgroundColor: "rgb(15,15,15)",
        display: "flex",
        justifyContent: "space-between",
        padding: "1em 2em",
        alignItems: "center",
      }}
    >
      <div
        className={`${classes.mobMenu} ${mobMenuActive ? classes.mobMenuActive : ""
          }`}
      >
        <div style={{ position: "absolute", top: "10px", left: "90%" }}>
          <Close
            onClick={(e) => setMobMenuActive(false)}
            style={{ color: "white" }}
          />
        </div>
        <div
          style={{
            marginBottom: "25px",
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
          onClick={(e) => history("/Our-Features")}
        >
          <p
            style={{
              color: "white",
              fontSize: "1.2rem",
              marginRight: "15px",
              cursor: "pointer",
              marginBottom: 0,
            }}
          >
            Features
          </p>
          {/* <KeyboardArrowDown style={{ color: "white", fontSize: "23px" }} /> */}
        </div>
        <div
          style={{
            marginBottom: "25px",
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={(e) => history("/Pricing")}
        >
          <p
            style={{
              color: "white",
              fontSize: "1.2rem",
              marginRight: "15px",
              marginBottom: 0,
              fontWeight: "bold",
            }}
          >
            Pricing
          </p>
          {/* <KeyboardArrowDown style={{ color: "white", fontSize: "23px" }} /> */}
        </div>
        <div
          style={{
            marginBottom: "25px",
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
          onClick={(e) => history("/SignUp")}
        >
          <p
            style={{
              color: "white",
              fontSize: "1.2rem",
              marginRight: "15px",
              marginBottom: 0,
            }}
          >
            Sign-Up
          </p>
          {/* <KeyboardArrowDown style={{ color: "white", fontSize: "23px" }} /> */}
        </div>

        <div
          style={{
            marginBottom: "25px",
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
          onClick={(e) => history('/why-us')}
        >
          <p
            style={{
              color: "white",
              fontSize: "1.2rem",
              marginRight: "15px",
              marginBottom: 0,
            }}
          >
            Why Us
          </p>
        </div>

        <div
          style={{
            marginBottom: "25px",
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
          onClick={(e) => history("/howitworks")}
        >
          <p
            style={{
              color: "white",
              fontSize: "1.2rem",
              marginRight: "15px",
              marginBottom: 0,
            }}
          >
            How It Work
          </p>
        </div>

        <div
          style={{
            marginBottom: "25px",
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
          onClick={(e) => history("/integrations")}
        >
          <p
            style={{
              color: "white",
              fontSize: "1.2rem",
              marginRight: "15px",
              marginBottom: 0,
            }}
          >
            Integrations
          </p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={classes.logo} style={{ width: 100 }}>
          <a
            target="_blank"
            href={Const.isOurDesiStore ? "https://ourdesistore.com" : "https://ourliquorstore.com"}
          >
            <img src={Const.isOurDesiStore ? desiLogo : logo} style={{ width: "100%" }}
            />
          </a>
        </div>
        <div className={classes.hamMenu}>
          <Menu
            onClick={(e) => setMobMenuActive(true)}
            style={{ color: "white" }}
          />
        </div>
        <div className={classes.deskMenu}>
          <div
            style={{
              marginRight: "45px",
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
            onClick={(e) => history("/Our-Features")}
          >
            <p
              style={{
                color: "white",
                fontSize: "1rem",
                marginRight: "15px",
                cursor: "pointer",
                marginBottom: 0,
              }}
            >
              Features
            </p>

          </div>
          <div
            style={{
              marginRight: "45px",
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={(e) => history("/Pricing")}
          >
            <p
              style={{
                color: "white",
                fontSize: "1rem",
                marginRight: "15px",
                marginBottom: 0,
                fontWeight: "bold",
              }}
            >
              Pricing
            </p>

          </div>
          <div
            style={{
              marginRight: "45px",
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
            onClick={(e) => history("/SignUp")}
          >
            <p
              style={{
                color: "white",
                fontSize: "1rem",
                marginRight: "15px",
                marginBottom: 0,
              }}
            >
              Sign-Up
            </p>

          </div>

          <div
            style={{
              marginRight: "45px",
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
            onClick={(e) => history('/why-us')}
          >
            <p
              style={{
                color: "white",
                fontSize: "1rem",
                marginRight: "15px",
                marginBottom: 0,
              }}
            >
              Why Us
            </p>
          </div>

          <div
            style={{
              marginRight: "45px",
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
            onClick={(e) => history("/howitworks")}
          >
            <p
              style={{
                color: "white",
                fontSize: "1rem",
                marginRight: "15px",
                marginBottom: 0,
              }}
            >
              How It Work
            </p>
          </div>

          <div
            style={{
              marginRight: "45px",
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
            onClick={(e) => history("/integrations")}
          >
            <p
              style={{
                color: "white",
                fontSize: "1rem",
                marginRight: "15px",
                marginBottom: 0,
              }}
            >
              Integrations
            </p>
          </div>

        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{ display: "flex", alignItems: "center", margin: "0 40px" }}
        >
          <Typography
            className={classes.text}
            style={{ fontSize: "1.05rem", color: "white" }}
          >
            Already have an account?
          </Typography>
          <Link
            className={classes.text}
            style={{
              color: "#CF5141",
              borderBottom: "1px solid #CF5141",
              display: "inline-block",
              marginLeft: "7px",
              fontSize: "1.05rem",
            }}
            to="/"
          >
            Login
          </Link>
        </div>
        <Buttons.GetStarted small action={props.action} />
      </div>
    </div>
  );
};

export default GetStarted;
