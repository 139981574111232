import {bizBaseurl} from '../../env';
import axios from '../../utils/axiosConfig';

export const getReviews = (params) => {
    try{
        return async (dispatch) => {   
            let {data: {data: {result}}} = await axios.get(`/app-review/list`);
            dispatch({
                type: "SET_APP_REVIEWS",
                payload: result
            });
        };
    }catch(err){
        throw err;
    };
};