import React, { useState } from 'react'
import { Cancel, Edit, HelpOutlineOutlined, InfoOutlined } from '@mui/icons-material'
import { IconButton, MenuItem, Drawer, Button, DialogContent, DialogTitle, DialogActions } from '@mui/material'
import clsx from 'clsx'
import GenericSelectWithLabel from '../../../../Library/V2/GenericSelectWithLabel/GenericSelectWithLabel'
// import GenericSelectWithLabel from '../../../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import GenericTextField from '../../../../Library/GenericTextField/GenericTextField'
import styles from './StoreInformation.module.scss'
import HtmlTooltip from '../../../../Library/V2/HtmlToolTip/HtmlToolTip'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import GenericEditDrawer from '../../../../Library/V2/GenericEditDrawer/GenericEditDrawer'
import GoogleAddressInput from '../../../../Library/GoogleAddressInput/GoogleAddressInput'
import LightTooltip from '../../../../../util_comps/LightTooltip'

const timeZonesList = [
    { title: "America/New_York" },
    { title: "America/Chicago" },
    { title: "America/Phoenix" },
    { title: "America/Los_Angeles" },
    { title: "US/Hawaii" },
    { title: "US/Alaska" },
];

const StoreInformation = (props) => {
    
    const [open, setOpen] = useState(false)

    return (
        <div className='tw-p-4 lg:tw-px-12 lg:tw-py-8 tw-flex tw-flex-col tw-gap-3 tw-text-sm tw-h-full tw-justify-center'>
            <div className='tw-mb-2'>
                <div className="tw-font-bold tw-text-xl">{props.venueDetails.Name} {props.venueDetails?.venuesubtype && `(${props.venueDetails?.venuesubtype})`}</div>
                {props.venueDetails.full_address && <span className='tw-text-dark-gray tw-font-medium'>{props.venueDetails.full_address}</span>}
            </div>

            {props.venueDetails.email && (
                <Item
                    label="Business Email"
                    value={props.venueDetails.email}
                    tooltip="A business email for a store facilitates easy communication, enabling customers to inquire, book, or provide feedback conveniently."
                />
            )}
            {props.venueDetails.contact_no && (
                <Item
                    label="Phone"
                    value={props.venueDetails.contact_no}
                    tooltip="A store's phone number enables quick contact for reservations, inquiries, and customer service, providing convenience and accessibility to patrons."
                />
            )}
            {props.venueDetails.timeZone && (
                <Item
                    label="Timezone"
                    value={props.venueDetails.timeZone}
                    tooltip="Restaurant operating timezone"
                />
            )}
            <Button
                sx={{ backgroundColor: '#E5E5E5', color: '#000000', textTransform: 'none', fontSize: '13px', fontWeight: '500', padding: '2px 10px', width: '40px', borderRadius: '20px' }}
                onClick={() => { setOpen(true); }}
            >Edit</Button>

            <Drawer
                anchor={props.isSmall ? "bottom" : "right"}
                open={open}
                onClose={() => { setOpen(false); props.formik.resetForm(); }}
                ModalProps={{ className: '!tw-z-[1202]' }}
                PaperProps={{ className: 'tw-min-w-[350px] lg:tw-max-w-[25%] !tw-h-auto tw-min-h-[400px] tw-max-h-screen tw-p-4' }}
            >
                <GenericEditDrawer
                    {...{
                        title: 'Store Information',
                        onClose: () => { setOpen(false); props.formik.resetForm(); },
                        actions: (
                            <GenericLoadingButton
                                variant="contained"
                                type="button"
                                fullWidth
                                onClick={props.formik.handleSubmit}
                                size="large"
                                loading={props.loading}
                                loadingPosition="end"
                            // disabled={props.isActionsDisabled}
                            >
                                Update Info
                            </GenericLoadingButton>
                        )
                    }}
                >
                    <GenericTextFieldWithLabel
                        containerStyle={{ width: '100%' }}
                        id="Name"
                        name="Name"
                        label="Name"
                        placeholder="Enter your store name"
                        value={props.formik.values.Name}
                        onChange={props.formik.handleChange}
                        fullWidth={true}
                        isSmallLabel={true}
                    // required={true} 
                    />
                    <GenericSelectWithLabel
                        id="venuesubtype"
                        name="venuesubtype"
                        label="Store sub type"
                        // placeholder="Enter your store name" 
                        value={props.formik.values.venuesubtype}
                        onChange={props.formik.handleChange}
                        fullWidth={true}
                        isSmallLabel={true}
                    // required={true} 
                    >
                        {props.venueSubTypes?.data?.map((data) => (
                            <MenuItem key={data.id} value={data.title}>
                                {data.title}
                            </MenuItem>
                        ))}
                    </GenericSelectWithLabel>
                    <GenericTextFieldWithLabel
                        containerStyle={{ width: '100%' }}
                        id="contact_no"
                        name="contact_no"
                        label="Phone number"
                        placeholder="Phone"
                        value={props.formik.values.contact_no}
                        onChange={props.formik.handleChange}
                        fullWidth={true}
                        isSmallLabel={true}
                    // required={true} 
                    />

                    <GoogleAddressInput
                        label={
                            <>
                                Address

                                <span
                                    className="tw-mx-[2px]"
                                >
                                    <LightTooltip
                                        title={(<span
                                            style={{ display: 'inline-block', padding: '0 15px' }}
                                        >
                                            An address is crucial for a restaurant as it serves as a geographical marker, making it easily locatable for potential customers, thereby increasing foot traffic and visibility, ultimately boosting revenue and establishing a strong presence in the community.
                                        </span>)}
                                        arrow
                                        placement="top"
                                    >
                                        <HelpOutlineOutlined
                                            style={{
                                                fontSize: 12
                                            }}
                                        />
                                    </LightTooltip>
                                </span>
                            </>
                        }
                        containerClasses="tw-w-full"
                        isLabel={true}
                        variant="standard"
                        isSmallLabel={true}
                        placeholder="Enter and select address from list"
                        value={props.formik.values.Address1}
                        setLat={lat => props.formik.setFieldValue('latitude', lat)}
                        setLng={lng => props.formik.setFieldValue('longitude', lng)}
                        setAddress={address => props.formik.setFieldValue('Address1', address)}
                        setCity={city => props.formik.setFieldValue('city', city)}
                        setState={state => props.formik.setFieldValue('state', state)}
                        setCountry={country => props.formik.setFieldValue('country', country)}
                        required={true}
                    />
                    <GenericSelectWithLabel
                        id="timeZone"
                        name="timeZone"
                        label="Operating time zone"
                        value={props.formik.values.timeZone}
                        onChange={props.formik.handleChange}
                        fullWidth={true}
                        isSmallLabel={true}
                    >
                        {timeZonesList.map((data) => (
                            <MenuItem
                                value={data.title}
                            >
                                {data.title}
                            </MenuItem>
                        ))}
                    </GenericSelectWithLabel>
                </GenericEditDrawer>
            </Drawer>
        </div>
    )
}

const Item = ({ label, value, tooltip }) => {

    return (
        <div>
            <div className='tw-font-semibold tw-flex tw-items-center tw-gap-1 tw-text-black'>
                {label}
                {tooltip && (
                    <HtmlTooltip title={tooltip} >
                        <InfoOutlined sx={{ fontSize: '16px' }} />
                    </HtmlTooltip>
                )}
            </div>
            <span className='tw-text-dark-gray tw-font-medium'>{value}</span>
        </div>
    )
}
export default StoreInformation;



const StoreInformation2 = (props) => {

    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.title_container}
            >
                <div
                    className={styles.title}
                >
                    Store Information
                </div>

                <IconButton
                    onClick={() => {
                        props.setIsEditMode((prevState) => !prevState)
                    }}
                >
                    <Edit style={{ fontSize: 18, color: 'black' }} />
                </IconButton>
            </div>

            <div
                className={styles.form_container}
            >

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Name
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {props.isEditMode ? (
                            <GenericTextField
                                id="Name"
                                name="Name"
                                value={props.formik.values.Name}
                                onChange={props.formik.handleChange}
                                fullWidth={true}
                            />
                        ) : props.venueDetails.Name}

                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Email
                    </div>

                    <div
                        className={styles.input_field_container}
                    >
                        {/* {props.isEditMode ? (
                            <GenericTextField
                                style={{
                                    width: 200
                                }}
                                id="email"
                                name="email"
                                value={props.formik.values.email}
                                onChange={props.formik.handleChange}
                            />
                        ) : props.venueDetails.email} */}

                        {props.venueDetails.email}
                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Phone
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {props.isEditMode ? (
                            <GenericTextField
                                id="contact_no"
                                name="contact_no"
                                value={props.formik.values.contact_no}
                                onChange={props.formik.handleChange}
                                fullWidth={true}
                            />
                        ) : props.venueDetails.contact_no}

                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Timezone
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {/* {props.isEditMode ? (
                            <GenericSelect
                                id="timeZone"
                                name="timeZone"
                                value={props.formik.values.timeZone}
                                onChange={props.formik.handleChange}
                                fullWidth={true}
                            >
                                {timeZonesList.map((data) => (
                                    <MenuItem
                                        value={data.title}
                                    >
                                        {data.title}
                                    </MenuItem>
                                ))}
                            </GenericSelect>
                        ) : props.venueDetails.timeZone} */}
                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Store Address
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {/* {props.isEditMode ? (
                            <GenericTextField
                                style={{
                                    width: 200
                                }}
                                id="full_address"
                                name="full_address"
                                value={props.formik.values.full_address}
                                onChange={props.formik.handleChange}
                                multiline={true}
                            />
                        ) : props.venueDetails.full_address} */}

                        {props.venueDetails.full_address}
                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Description
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {props.isEditMode ? (
                            <GenericTextField
                                id="Description"
                                name="Description"
                                value={props.formik.values.Description}
                                onChange={props.formik.handleChange}
                                multiline={true}
                                fullWidth={true}
                            />
                        ) : props.venueDetails.Description}
                    </div>
                </div>

            </div>
        </div>
    )
}