import axios from "../utils/axiosConfig";
import { bizBaseurl } from "../env";

export const updateEmailSetting = async (type, text) => {
  try {
    await axios.post(`/venue-email-config/update/${type}`,
      { text }
    );
  } catch (err) {
    throw err;
  }
};

export const getEmailSetting = async (type) => {
  try {
    let {
      data,
    } = await axios.get(`/venue-email-config/get-by-type/${type}`);
    return data.data.result.text;
  } catch (err) {
    throw err;
  }
};
