import React from "react";
import { TextField, Select } from "@mui/material";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
    whiteIcon: {
        color: 'white'
    }
});
const Search = (props) => {
    const classes = useStyles();
    return (
        <div style={{ display: "flex", alignItems: "center", marginBottom: '22px', height: 40 }}>
            <TextField
                className="create-product-group-search-input"
                style={{ height: '100%', width: '100%'}}
                InputProps={{
                    disableUnderline: true,
                }}
                value={props.filters.searchString}
                onChange={e => props.setFilters({...props.filters, searchString: e.target.value})}
                placeholder="ABC Product Group"
                variant="outlined"
            />
        </div>
    );
};

export default Search;
