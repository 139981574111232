import { Radio } from '@mui/material'
import React from 'react'
import styles from './ProductChip.module.scss'

const ProductChip = (props) => {
    return (
        <div
            style={props.style}
            className={styles.container}
            onClick={props.wholeChipClick && props.onClick}
        >
            <div
                style={{
                    width: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src={props.image}
                        height={30}
                    />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems:'center',
                            width: '100%'
                        }}
                    >
                        <div
                            className={styles.name}
                        >
                            {props.name}
                        </div>
                        {props.price && (
                            <div
                                style={{
                                    fontWeight: 700,
                                    textAlign: 'right'
                                }}
                            >
                                ${props.price}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {props.isRadio && (
                <Radio size="small" checked={props.value == props.selectedProductId} onClick={props.onClick} color="default" />
            )}
        </div>
    )
}

export default ProductChip