import { outlinedInputClasses, styled, TextField } from '@mui/material'
import React from 'react'

const GenericTextFieldUndeline = styled((props) => (
    <TextField
        {...props}
    />
))(({ theme, iconType, iconVariant }) => ({
    padding: 0,
    backgroundColor: 'white',
    borderBottom: "1px solid grey",
    [`& .${outlinedInputClasses.notchedOutline}`]: {

        outline: 'none',
    },
    [`& .${outlinedInputClasses.input}`]: {
        padding: iconVariant === 'dollar' ? '10px 10px 10px 0' : 10,
    }
}))

export default GenericTextFieldUndeline