import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import classNames from 'classnames';
import Option from '../Component/Image/ImageTool/Option';
import { ResizableBox } from 'react-resizable';
import { formatUri } from '../../../EditSection/Util/helpers';

const getImageComponent = config => class Image extends Component {

    state = {
        hovered: false,
    };

    setEntityAlignmentLeft = () => {
        this.setEntityAlignment('left');
    };

    setEntityAlignmentRight = () => {
        this.setEntityAlignment('right');
    };

    setEntityAlignmentCenter = () => {
        this.setEntityAlignment('none');
    };

    setEntityAlignment = (alignment) => {
        const { block, contentState } = this.props;
        const entityKey = block.getEntityAt(0);
        contentState.mergeEntityData(
            entityKey,
            { alignment },
        );

        const { getEditorState, setEditorState } = config
        const editorState = getEditorState();
        const newEditorState = EditorState.push(editorState, contentState, 'change-block-data');
        setEditorState(newEditorState);

        this.setState({
            dummy: true,
        });
    };

    toggleHovered = () => {
        const hovered = !this.state.hovered;
        this.setState({
            hovered,
        });
    };

    renderAlignmentOptions(alignment) {
        return (
            <div
                className={classNames(
                    'rdw-image-alignment-options-popup',
                    {
                        'rdw-image-alignment-options-popup-right': alignment === 'right',
                    },
                )}
            >
                <Option
                    onClick={this.setEntityAlignmentLeft}
                    className="rdw-image-alignment-option"
                >
                    L
                </Option>
                <Option
                    onClick={this.setEntityAlignmentCenter}
                    className="rdw-image-alignment-option"
                >
                    C
                </Option>
                <Option
                    onClick={this.setEntityAlignmentRight}
                    className="rdw-image-alignment-option"
                >
                    R
                </Option>
            </div>
        );
    }

    onResizeStop = (event, data) => {
        const { getEditorState, setEditorState } = config
        const { block, contentState } = this.props;
        console.log('bbbbbbbb', block)
        const editorState = getEditorState();

        const newWidth = data.size.width;
        const newHeight = data.size.height;

        // Update editor state with new dimensions
        const content = editorState.getCurrentContent();
        const updatedContent = content.mergeEntityData(block.getEntityAt(0), {
            width: `${newWidth}px`,
            height: `${newHeight}px`,
        });

        const newEditorState = EditorState.push(editorState, updatedContent);
        setEditorState(newEditorState);
    };

    render() {
        console.log(config, 'eeeeee')
        const { block, contentState } = this.props;
        const { hovered } = this.state;
        const entity = contentState.getEntity(block.getEntityAt(0));
        const { src, alignment, height, width, alt, href } = entity.getData();

        console.log(src, alignment, height, width, alt, href, 'ddddddddddddddd')

        return (
            <span
                onMouseEnter={this.toggleHovered}
                onMouseLeave={this.toggleHovered}
                className={classNames(
                    'rdw-image-alignment',
                    {
                        'rdw-image-left': alignment === 'left',
                        'rdw-image-right': alignment === 'right',
                        'rdw-image-center': !alignment || alignment === 'none',
                    },
                )}
            >
                <span className="rdw-image-imagewrapper">
                    {href ? (
                        <a
                            href={formatUri(href)}
                        >
                            {/* <ResizableBox onResizeStop={this.onResizeStop} width={isNaN(parseInt(width)) ? (this.props?.blockProps?.width || 200) : parseInt(width)} height={isNaN(parseInt(height)) ? (this.props?.blockProps?.height || 200) : parseInt(height)} minConstraints={[10, 10]} maxConstraints={[1000, 1000]}> */}
                                <img src={src} alt="" style={{ width: width, height: height }} />
                            {/* </ResizableBox> */}
                        </a>
                    ) : (
                        // <ResizableBox onResizeStop={this.onResizeStop} width={isNaN(parseInt(width)) ? (this.props?.blockProps?.width || 200) : parseInt(width)} height={isNaN(parseInt(height)) ? (this.props?.blockProps?.height || 200) : parseInt(height)} minConstraints={[10, 10]} maxConstraints={[1000, 1000]}>
                            <img src={src} alt="" style={{ width: width, height: height }} />
                        // </ResizableBox>
                    )}
                    {
                        hovered ?
                            this.renderAlignmentOptions(alignment)
                            :
                            undefined
                    }
                </span>
            </span >
        );
    }
};

export default getImageComponent;
