import React, { useEffect, useState } from 'react'
import { getImage2 } from '../../../utils/helpers'
import styles from './LoaderGif.module.scss'
import { motion } from 'framer-motion';
import ImageSvg from './ImageSvg';
import clsx from 'clsx';

const textVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: 25, opacity: 0 }
};

const LoaderGif = ({ onNext, isTemplateLoading, formik, type, text, loaderClass }) => {

    const texts1 = [
        'Hold on, your website is being created',
        'Adding pages',
        'Creating sections',
        'Generating text and Images'
    ]
    const texts2 = ["Hold on, your website is being created.", "Creating sections.", "Generating text and images", "Creating menu"]

    const texts = text || ((type == 1) ? texts1 : texts2)
    const [index, setIndex] = useState(0);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        let interval
        if (type == 1) {
            interval = setInterval(() => {
                setIndex((current) => (current + 1) % texts.length);
            }, 1000); // Change text every 2 seconds
        } else {
            interval = setInterval(() => {
                setFade(false);
                setTimeout(() => {
                    setCurrentWordIndex((prevIndex) => (prevIndex + 1) % texts.length);
                    setFade(true);
                }, 500); // Match this with your fadeOut duration for smooth transition
            }, 1500);
        }

        return () => clearInterval(interval);
    }, [texts.length]);

    useEffect(() => {
        if (!isTemplateLoading) {
            setTimeout(() => {
                onNext()
            }, ((type == 1) ? 3000 : 5000));
        }
    }, [isTemplateLoading])


    return (
        <div
            className="tw-flex tw-justify-center tw-items-center tw-h-full tw-flex-col tw-gap-6"
        >
            {(type == 1) ? (
                <>
                    <div>
                        <img
                            className="tw-h-[22rem]"
                            src={(formik.values.venuetype == 'Restaurant') ? getImage2('gif_restaurant2.gif-1709825705.gif') : getImage2('loader.gif-1708001146.gif')}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {texts.map((text, i) => (
                            <motion.div
                                key={i}
                                variants={textVariants}
                                initial="hidden"
                                animate={index === i ? "visible" : "exit"}
                                transition={{ duration: 0.5 }}
                                style={{ position: 'absolute' }}
                            >
                                {text}
                            </motion.div>
                        ))}
                    </div>
                </>
            ) : (
                <div className={clsx("tw-w-screen tw-h-screen tw-overflow-hidden tw-relative", loaderClass)}>
                    <ImageSvg />
                    <div
                        className="text-container tw-max-w-full tw-h-11 tw-rounded-[50px] tw-flex tw-items-center tw-justify-center tw-absolute tw-top-1/2 tw-left-1/2 tw-bg-white tw-text-base tw-font-medium tw-text-black tw-px-5 tw-overflow-hidden tw-shadow-scrollText tw-transition-[width_0.5s_ease]"
                        style={{ opacity: fade ? 1 : 0, transition: 'opacity 0.5s ease', transform: 'translate(-50%, -50%)' }}
                    >
                        <div className="tw-flex tw-items-center tw-justify-center">
                            <span className="tw-whitespace-nowrap">{texts[currentWordIndex]}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default LoaderGif