import React from 'react'
import Const from "../helpers/const";
import { classname, cns } from '../../utils/bem';
import { Typography, Button } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import './HelpPage.scss'
import productinventory from "../../assets/packet.png";
import orderpayment from "../../assets/bill.png";
import Customize from "../../assets/feature.png";
import storelist from "../../assets/store-list.png";
import listicon from "../../assets/list-text.png";
import routePaths from '../../config/route-paths';
const HelpPage = (props) => {
const cn = classname('help-page')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
</div>


<div className={cn('help-page-body')}>

<div className={cn('helpdesk-grid')}>
      <div className={cn('first-body-content')}>

      <div className={cn('list-content')}>
      <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Product & Inventory               
         </Typography>
                    <ul className={cn('helpdeskinnerlist')}>
                        <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.addNewProduct}>How to Add New Products</Link></li>
                        <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.createNewTags}>Create New Tags</Link></li>
                        <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.assigntagstoproduct}>Assign Tag to Product</Link></li>
                        <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.uploadInventory}>Upload Inventory</Link></li>
                        <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.searchProductbyCategory}>Search Product by Category</Link></li>
                        <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.editProductAttributes}>Edit Product Attributes</Link></li>
                        <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.changingItemVisiblity}>Changing Item Visiblity</Link></li>
                    </ul>
                </div>
      </div>


      <div className={cn('first-body-content')}>
      <div className={cn('list-content')}>
      <Typography variant="h3">
      <div className={cn('iconProduct')}>
               <img src={orderpayment} />
            </div>
            Orders & Payments             
         </Typography>

      <ul className={cn('helpdeskinnerlist')}>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.findOrders}>Find Orders</Link></li>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.transactionStatusReview}>Transaction Status Review</Link></li>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.ordersDetails}>Order Details</Link></li>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.OrdersStatus}>Order Status</Link></li>
      </ul>


                    </div>
      </div>




      <div className={cn('first-body-content')}>
      <div className={cn('list-content')}>
      <Typography variant="h3">
      <div className={cn('iconProduct')}>
               <img src={Customize} />
            </div>
            Customize Your Website & App            
         </Typography>

<ul className={cn('helpdeskinnerlist')}>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AddNewProductGroups}>How to Add New Product Groups</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropProductGroupInterface}>Drag & Drop Product Group Interface</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingProductGroup}>Edit Existing Product Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropBanners}>Drag & Drop Banners</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateNewBannerGroup}>Create New Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingBannerGroup}>Edit Existing Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateCustomStorePage}>Create Custom Store Page</Link></li>
</ul>


                    </div>
      </div>



      <div className={cn('first-body-content')}>
      <div className={cn('list-content')}>
      <Typography variant="h3">
      <div className={cn('iconProduct')}>
               <img src={storelist} />
            </div>
            Store Settings            
         </Typography>

         <ul className={cn('helpdeskinnerlist')}>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.StoreDetails}>Store Details</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.FulfillmentMethods}>Fulfillment Methods</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.BankInformation}>Bank Information</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.LiquorLicense}>Business License</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.SubmitLogo}>Submit Logo</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.POSIntegration}>POS Integration</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AccessYourWebsite}>Access Your Website</Link></li>
                    </ul>


                    </div>
      </div>






</div>

</div>



<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://esplanda.com/contact-us/" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default HelpPage