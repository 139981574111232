import { AttachMoney, Cancel, Close, DeleteForever, DragIndicator } from '@mui/icons-material'
import { Button, Checkbox, DialogContent, DialogTitle, FormControlLabel, FormHelperText, IconButton, InputAdornment, MenuItem, Tooltip, outlinedInputClasses, textFieldClasses } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import styles from './AddModifierDialog.module.scss'
import GenericSwitch from '../../../../Library/GenericSwitch/GenericSwitch'
import { reorder } from '../../../../CustomPageBuilder/EditSection/Util/helpers'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import axios, { nodeAxios } from '../../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import MenuIcon from '@mui/icons-material/Menu';
import GenericTextField from '../../../../Library/V2/GenericTextField/GenericTextField'
import * as Yup from 'yup';
import GenericSelectWithLabel from '../../../../Library/GenericSelectWithLabel/GenericSelectWithLabel'

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 5px',
    margin: `0 0 8px 0`,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const validationSchema = Yup.object().shape({
    title: Yup.string().required('This field is required'),
    price: Yup.number()
        .min(0, 'Minimum value must be 0 or positive')
        .integer('Minimum value must be an integer')
        .nullable(true), // Allows min to be null or undefined
    min: Yup.number()
        .min(0, 'Minimum value must be 0 or positive')
        .integer('Minimum value must be an integer')
        .nullable(true), // Allows min to be null or undefined
    max: Yup.number()
        .min(0, 'Minimum value must be 0 or positive')
        .integer('Maximum value must be an integer')
        .nullable(true)
        .test(
            'is-greater',
            'Maximum value must be greater than or equal to minimum value',
            function (value) {
                const { min } = this.parent;

                console.log(!isNaN(parseInt(value)), !isNaN(parseInt(min)), value, min)
                if (!isNaN(parseInt(value)) && !isNaN(parseInt(min))) {
                    return value >= min;
                } else {
                    return true
                }
            }
        ), // Allows max to be null or undefined
    options: Yup.array()
        .of(
            Yup.object().shape({
                title: Yup.string().required('This field is required'),
                price: Yup.number().nullable(true).test(
                    'price-two-decimal',
                    'Maximum price must have at most 2 decimal places',
                    number => {
                        if (number) {
                            return Number.isInteger(number) || /^\d+(\.\d{1,2})?$/.test(String(number))
                        } else {
                            return true
                        }
                    }
                )
            })
        )
        .when('min', (min, schema) => (
            !isNaN(min) && min > 0 ? schema.test(
                'length-check-min',
                'Array length must be greater than or equal to minimum value',
                array => array.length >= min
            ) : schema
        ))
});

// const validationSchema = Yup.object().shape({
//     max: Yup.number()?. test(
//             'is-greater',
//             'Maximum value must be greater than or equal to minimum value',
//             function (value) {
//                 const { min } = this.parent;
//                 return value >= min;
//             }
//         ),
//     options: Yup.array()
//         .of(Yup.object())
//         .test(
//             'length-check',
//             'Array length must be greater than or equal to minimum value',
//             function (array) {
//                 const { min } = this.parent;
//                 return array.length >= min;
//             }
//         ),
// });

const AddModifierDialog = (props) => {

    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: props.isEdit ? props?.editObj : {
            title: '',
            min: '',
            max: '',
            is_price: 1,
            is_expandable: 0,
            price: '',
            options: [
                {
                    title: '',
                    price: '',
                    is_default: 0
                }
            ]
        },
        // enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsLoading(true)

            nodeAxios.post(props.isEdit ? `/modifiers/edit/${props?.editObj?.id}` : '/modifiers/create', values)
                .then((res) => {
                    nodeAxios.post('/change-log/create', { what: `${values.title} is ${props.isEdit ? 'edited' : 'added'}` })
                        .then(() => {

                        })
                        .catch((error) => {
                            console.log('🚀OUTPUT --> error:', error);
                        });
                    if (props.onSuccess) {
                        props.onSuccess()
                    }
                    props.onClose()
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    })

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            formik.values.options,
            result.source.index,
            result.destination.index
        );

        formik.setFieldValue('options', newitems)
    };

    return (
        <>
            <DialogTitle
                align='left'
                fontWeight={600}
                fontSize={14}
                borderBottom="1px solid #D3D3D3"
                className='tw-flex tw-items-center !tw-text-base tw-justify-between !tw-py-1 !tw-px-4 sm:!tw-px-5 !tw-mb-4 !tw-mt-[5px] sm:!tw-mt-0'
            >
                {props.isEdit ? "Edit Customization" : "Add Customization"}
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{ color: '#000' }}
                >
                    <Cancel />
                </IconButton>
            </DialogTitle>

            <DialogContent className='!tw-px-4 sm:!tw-px-5'>
                <div
                    className={styles.form_container}
                >
                    {/* <div className={styles.title}>
                        {props.isEdit ? "Edit Customization" : "Add Customization"}
                    </div> */}

                    <div className='tw-flex tw-items-end tw-gap-4 tw-flex-col sm:tw-flex-row'>
                        <GenericTextFieldWithLabel
                            containerStyle={{
                                width: '100%'
                            }}
                            id="title"
                            name="title"
                            label="Customization Name"
                            placeholder="Enter customization name"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            fullWidth={true}
                            required={true}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                        />

                        <FormControlLabel
                            style={{
                                margin: 0,
                                gap: 10,
                                whiteSpace: 'nowrap',
                                fontSize: 14
                            }}
                            labelPlacement="start"
                            label="Show expanded"
                            control={(
                                <GenericSwitch
                                    background="#00C57E"
                                    width={60}
                                    height={24}
                                    translateX={38}
                                    thumb={19}
                                    isStatus={true}
                                    checked={!formik.values?.is_expandable}
                                    onText="'On'"
                                    offText="'Off'"
                                    onChange={(event) => {
                                        formik?.setFieldValue('is_expandable', event.target?.checked ? 0 : 1)
                                    }}
                                />
                            )}
                        />
                        {/* <GenericSelectWithLabel
                            id="is_expandable"
                            name="is_expandable"
                            label="Modifier type"
                            containerClassname={styles.small_input_container}
                            value={formik.values.is_expandable}
                            onChange={formik.handleChange}
                        >
                            <MenuItem value={0}>Expand (Show all options)</MenuItem>
                            <MenuItem value={1}>Collapsed (Show all options on modifier click)</MenuItem>
                        </GenericSelectWithLabel> */}
                    </div>
                    <div className='tw-flex tw-gap-3 tw-flex-col sm:tw-flex-row'>
                        <div className='tw-w-full tw-flex tw-gap-3'>
                            <GenericTextFieldWithLabel
                                id="min"
                                name="min"
                                label="Min # of selection"
                                placeholder="Min"
                                type="number"
                                containerClassname="tw-w-[50%] sm:tw-w-auto"
                                value={formik.values.min}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                error={formik.touched.min && Boolean(formik.errors.min)}
                                helperText={formik.touched.min && formik.errors.min}
                            />

                            <GenericTextFieldWithLabel
                                id="max"
                                name="max"
                                label="Max # of selection"
                                placeholder="Max"
                                type="number"
                                containerClassname="tw-w-[50%] sm:tw-w-auto"
                                value={formik.values.max}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                error={formik.touched.max && Boolean(formik.errors.max)}
                                helperText={formik.touched.max && formik.errors.max}
                            />
                        </div>
                        <div className='tw-w-1/2 sm:tw-w-auto'>
                            <GenericTextFieldWithLabel
                                id="price"
                                name="price"
                                label="Customization Price"
                                type="number"
                                placeholder="Price"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            style={{ height: '100%' }}
                                            position='start'
                                        >
                                            <AttachMoney />
                                        </InputAdornment>
                                    ),
                                }}
                                value={formik.values.price}
                                onChange={formik.handleChange}
                                error={formik.touched.price && Boolean(formik.errors.price)}
                                helperText={formik.touched.price && formik.errors.price}
                            />
                        </div>

                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                            alignItems: 'flex-start'
                        }}
                    >
                        {/* <FormControlLabel
                            style={{
                                margin: 0,
                                gap: 10
                            }}
                            labelPlacement="start"
                            label="Modifier Overall Price"
                            control={(
                                <GenericSwitch
                                    background="#00C57E"
                                    width={60}
                                    height={24}
                                    translateX={38}
                                    thumb={19}
                                    isStatus={true}
                                    checked={formik.values?.is_price}
                                    onText="'On'"
                                    offText="'Off'"
                                    onChange={(event) => {
                                        formik?.setFieldValue('is_price', event.target?.checked ? 1 : 0)
                                        if (!event.target?.checked) {
                                            formik?.setFieldValue('price', 0)
                                        }
                                    }}
                                />
                            )}
                        /> */}

                        {/* {Boolean(formik.values?.is_price) && ( */}

                        {/* )} */}

                    </div>

                    <div className='tw-font-bold'>
                        Options
                        <sup className='tw-text-red-600 tw-text-[100%]'>*</sup>


                        {!Array.isArray(formik.errors.options) && formik.touched.options && Boolean(formik.errors.options) && (
                            <FormHelperText style={{ display: 'block' }} error>
                                {formik.touched.options && formik.errors.options}
                            </FormHelperText>
                        )}

                    </div>

                    <div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='droppable'>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className={styles.scroll}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {formik.values.options?.map((item, index) => {
                                            return (
                                                <Draggable
                                                    key={index.toString()}
                                                    draggableId={index.toString()}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            onClick={() => {

                                                            }}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style,
                                                            )}
                                                        >
                                                            <div className="tw-flex tw-w-full tw-border tw-border-gray sm:tw-px-3 tw-rounded-md sm:tw-rounded-sm">
                                                                <div className='tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-relative tw-w-full tw-gap-1'>
                                                                    <div className='tw-flex tw-items-center tw-relative tw-w-full tw-gap-1 tw-pt-1 tw-px-3 sm:tw-p-0'>
                                                                        <div>
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    let allOptions = formik.values.options
                                                                                    allOptions.splice(index, 1)

                                                                                    formik.setFieldValue('options', allOptions)
                                                                                }}
                                                                            >
                                                                                <DoDisturbOnIcon
                                                                                    style={{
                                                                                        color: 'red'
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </div>

                                                                        <input
                                                                            className="tw-border-none tw-outline-none tw-w-full tw-py-1"
                                                                            id={`options[${index}].title`}
                                                                            name={`options[${index}].title`}
                                                                            placeholder="Enter option name"
                                                                            value={item.title}
                                                                            onChange={formik.handleChange}
                                                                        />

                                                                        <div className='tw-hidden sm:tw-block'>
                                                                            <GenericSwitch
                                                                                background="#00C57E"
                                                                                width={80}
                                                                                height={24}
                                                                                translateX={58}
                                                                                thumb={19}
                                                                                isStatus={true}
                                                                                onText="'Default'"
                                                                                offText="'Default'"
                                                                                checked={item.is_default}
                                                                                onChange={(event) => {
                                                                                    formik?.setFieldValue(`options[${index}].is_default`, event.target?.checked ? 1 : 0)
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className='tw-flex  sm:tw-hidden tw-items-center tw-gap-[6px] tw-text-black tw-cursor-pointer'>
                                                                            <Tooltip
                                                                                // style={{
                                                                                //     width: 140
                                                                                // }}
                                                                                title="Drag item in order to arrange"
                                                                                placement="top"
                                                                            >
                                                                                <MenuIcon />
                                                                            </Tooltip>
                                                                        </div>


                                                                    </div>
                                                                    <div className='tw-flex tw-items-center tw-relative tw-w-full sm:tw-w-auto tw-gap-1 tw-border-t tw-border-gray sm:tw-border-none tw-px-3 sm:tw-p-0'>
                                                                        <div className='sm:tw-hidden tw-w-[45%]'>
                                                                            <GenericSwitch
                                                                                background="#00C57E"
                                                                                width={80}
                                                                                height={24}
                                                                                translateX={58}
                                                                                thumb={19}
                                                                                isStatus={true}
                                                                                onText="'Default'"
                                                                                offText="'Default'"
                                                                                checked={item.is_default}
                                                                                onChange={(event) => {
                                                                                    formik?.setFieldValue(`options[${index}].is_default`, event.target?.checked ? 1 : 0)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <GenericTextField
                                                                            style={{
                                                                                minWidth: 110
                                                                            }}
                                                                            type="number"
                                                                            id={`options[${index}].price`}
                                                                            name={`options[${index}].price`}
                                                                            placeholder="Price"
                                                                            value={item.price}
                                                                            onChange={formik.handleChange}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment
                                                                                        style={{ height: '100%' }}
                                                                                        position='start'
                                                                                    >
                                                                                        <AttachMoney />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            sx={{
                                                                                [`.${textFieldClasses.root}`]: {
                                                                                    "@media (max-width: 600px)": {
                                                                                        width: "55%"
                                                                                    }
                                                                                },
                                                                                [`.${outlinedInputClasses.root}`]: {
                                                                                    paddingLeft: 1,
                                                                                },
                                                                                [`.${outlinedInputClasses.input}`]: {
                                                                                    paddingLeft: 0,
                                                                                },
                                                                                [`.${outlinedInputClasses.notchedOutline}`]: {
                                                                                    boxShadow: 'none',
                                                                                    border: 'none !important',
                                                                                    borderLeft: '1px solid #d3d3d3 !important',
                                                                                    borderRadius: 0
                                                                                },
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>

                                                                <div className='tw-hidden sm:tw-flex tw-items-center tw-gap-[6px] tw-text-black tw-cursor-pointer'>
                                                                    <Tooltip
                                                                        // style={{
                                                                        //     width: 140
                                                                        // }}
                                                                        title="Drag item in order to arrange"
                                                                        placement="top"
                                                                    >
                                                                        <MenuIcon />
                                                                    </Tooltip>
                                                                </div>
                                                            </div>

                                                            {Boolean(formik?.touched?.options) && Boolean(formik?.errors?.options) && Array.isArray(formik.errors.options) && formik.touched.options[index]?.title && Boolean(formik.errors.options[index]?.title) && (
                                                                <FormHelperText style={{ display: 'block' }} error>
                                                                    {formik.touched.options[index]?.title && formik.errors.options[index]?.title}
                                                                </FormHelperText>
                                                            )}
                                                            {Boolean(formik?.touched?.options) && Boolean(formik?.errors?.options) && Array.isArray(formik.errors.options) && formik.touched.options[index]?.price && Boolean(formik.errors.options[index]?.price) && (
                                                                <FormHelperText style={{ display: 'block' }} error>
                                                                    {formik.touched.options[index]?.price && formik.errors.options[index]?.price}
                                                                </FormHelperText>
                                                            )}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    {console.log(formik.values)}
                    <Button
                        style={{
                            borderColor: '#D3D3D3',
                            color: 'black'
                        }}
                        variant="outlined"
                        onClick={() => {
                            let allOptions = formik.values.options
                            console.log(allOptions)
                            if (Array.isArray(allOptions)) {
                                allOptions.push({
                                    title: '',
                                    price: '',
                                    is_default: 0
                                })
                            } else {
                                allOptions = [{
                                    title: '',
                                    price: '',
                                    is_default: 0
                                }]
                            }

                            console.log(allOptions)
                            formik.setFieldValue('options', allOptions)
                        }}
                    >
                        Add new Options
                    </Button>
                </div>
            </DialogContent>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 10
                }}
            >
                <div>
                    {/* {props.isEdit && (
                        <Button
                            style={{
                                color: 'red'
                            }}
                            variant="text"
                            onClick={() => {
                                props.handleDelete(props?.editObj?.id)
                                props.onClose()
                            }}
                        >
                            Remove Product
                        </Button>
                    )} */}
                </div>

                <div className='tw-flex tw-gap-4 tw-w-full tw-justify-end'>
                    <Button
                        style={{
                            backgroundColor: '#d3d3d3',
                            color: 'black'
                        }}
                        className="!tw-w-full lg:!tw-w-auto !tw-rounded-3xl"
                        variant="text"
                        disableElevation
                        onClick={() => {
                            props.onClose()
                        }}
                    >
                        Cancel
                    </Button>

                    <GenericLoadingButton
                        variant="contained"
                        loading={isLoading}
                        className="!tw-w-full lg:!tw-w-auto !tw-rounded-3xl"
                        loadingPosition="end"
                        type="submit"
                        onClick={formik.handleSubmit}
                    >
                        Save
                    </GenericLoadingButton>
                </div>
            </div>
        </>
    )
}

export default AddModifierDialog