import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { EMAIL_VERIFICATION_CONSTANTS } from '../../../../../utils/helpers';
import { nodeAxios } from '../../../../../utils/axiosConfig';
import { Dialog, Drawer, IconButton, useMediaQuery } from '@mui/material';
import EmailPreferences from './EmailPreferences/EmailPreferences';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';


const OrderEmailPreferences = () => {
    const { venueDetails } = useOutletContext()
    // console.log('🚀OUTPUT --> venueDetails:', venueDetails);
    const isTablet = useMediaQuery('(max-width: 1024px)');
    
    const [isSettingsLoading, setIsSettingsLoading] = useState(false);
    const [venueSettings, setVenueSettings] = useState(null);
    const [ccMails, setCCMails] = useState(null);
    const [showEmailPreferencesModal, setShowEmailPreferencesModal] = useState(false);
    const [toArray, setToArray] = useState([venueDetails.Name]);
    const [isRemoveLoading, setIsRemoveLoading] = useState(null);
    const [showCCEmails, setShowCCEmails] = useState(false);


    
  const fetchVenueSettings = () => {
    setIsSettingsLoading(true);
    nodeAxios
      .get('/venue/settings')
      .then((response) => {
        setVenueSettings(response.data?.data);
      })
      .catch((err) => {
        console.log('🚀OUTPUT --> err:', err);
      }).finally(() => {
        setIsSettingsLoading(false);
      });
  };
  const fetchCCMails = () => {
    setIsSettingsLoading(true);
    nodeAxios
      .get('/email-settings/get-cc-emails')
      .then((response) => {
        setCCMails(response.data?.data?.ccEmails);
        const toArray = Object.values(response.data?.data?.ccEmails)
        setToArray([venueDetails.Name, ...toArray]);
      })
      .catch((err) => {
        console.log('🚀OUTPUT --> err:', err);
      }).finally(() => {
        setIsSettingsLoading(false);
      });
  };

  
  const handleRemoveEmail = () => {
    setIsRemoveLoading(true)
    nodeAxios
      .delete(`/email-settings/remove-email`)
      .then((response) => {
        toast.success('Removed..!!');
        fetchVenueSettings();
      })
      .catch((err) => {
        console.log('🚀OUTPUT --> err:', err);
      })
      .finally(() => {
        setIsRemoveLoading(false);
      });
  }

  
  useEffect(() => {
    setToArray([venueDetails.Name]);
  }, [venueDetails]);
  useEffect(() => {
    fetchVenueSettings();
    fetchCCMails();
  }, []);

  return (
    <>
        <div className='tw-mt-4'>
            <h2 className='tw-text-black tw-text-lg tw-font-semibold'>Order email preferences</h2>
            <p className='ttw-text-sm tw-text-[#868686]'>
                Choose the email sender (Esplanda or your own) and additionally specify recipients in the CC field for order emails.
            </p>
        </div>


        <div className='tw-mt-4'>
            <div className='tw-flex tw-justify-between tw-items-center'>
                <div className='tw-flex tw-flex-wrap tw-gap-3 tw-text-sm tw-w-full'>
                    <span className='tw-font-semibold tw-w-10'>From:</span>
                    {isSettingsLoading ? (
                        <span className='tw-animate-pulse tw-w-[250px] tw-bg-slate-200 tw-rounded-3xl tw-px-2 tw-py-0.5'></span>
                    ) : (
                        <>
                        {(venueSettings?.custom_from_email_address && 
                        venueSettings?.is_from_email_verified == EMAIL_VERIFICATION_CONSTANTS.VERIFIED) ? (
                            <span className='tw-bg-blue tw-text-white tw-rounded-3xl tw-px-2 tw-py-1 tw-inline-flex tw-items-center tw-text-xs tw-cursor-pointer'>
                            {venueSettings?.custom_from_email_address}
                            <span className='tw-ml-1 tw-bg-green-500 tw-py-0.5 tw-px-2 tw-rounded-2xl'>(Verified)</span>
                            </span>
                        ) : (
                            <span className='tw-bg-blue tw-text-white tw-rounded-3xl tw-px-2 tw-py-0.5 tw-text-xs tw-cursor-pointer'>
                              Esplanda
                              {(venueSettings?.custom_from_email_address &&
                                venueSettings?.is_from_email_verified != EMAIL_VERIFICATION_CONSTANTS.VERIFIED) && (
                                " (Current)"
                              )}
                            </span>
                        )}
                        {(venueSettings?.custom_from_email_address && 
                        venueSettings?.is_from_email_verified != EMAIL_VERIFICATION_CONSTANTS.VERIFIED) && (
                            <span className='tw-bg-orange-600 tw-inline-flex tw-items-center tw-text-white tw-rounded-3xl tw-pl-2 tw-pr-5 tw-py-0.5 tw-text-xs tw-cursor-pointer tw-relative'>
                              {venueSettings?.custom_from_email_address} (Unverified)
                              <button className='tw-dui-btn tw-dui-btn-xs tw-dui-btn-square tw-absolute -tw-top-2 tw-min-h-0 tw-w-5 tw-h-5 -tw-right-1 tw-border-0 tw-shadow-md tw-rounded-full tw-bg-[#ff0000] tw-text-white hover:tw-bg-[#f00000]'
                                onClick={handleRemoveEmail}>
                                <Close sx={{ fontSize: '16px', p: 0 }} />
                              </button>
                            </span>
                        )}
                        {(venueSettings?.custom_from_email_address_secondary && 
                        venueSettings?.is_secondary_from_email_verified != EMAIL_VERIFICATION_CONSTANTS.VERIFIED) && (
                          <span className='tw-bg-orange-600 tw-inline-flex tw-items-center tw-text-white tw-rounded-3xl tw-pl-2 tw-pr-5 tw-py-0.5 tw-text-xs tw-cursor-pointer tw-relative'>
                            {venueSettings?.custom_from_email_address_secondary} (Unverified)
                            <button className='tw-dui-btn tw-dui-btn-xs tw-dui-btn-square tw-absolute -tw-top-2 tw-min-h-0 tw-w-5 tw-h-5 -tw-right-1 tw-border-0 tw-shadow-md tw-rounded-full tw-bg-[#ff0000] tw-text-white hover:tw-bg-[#f00000]'
                            onClick={handleRemoveEmail}>
                              <Close sx={{ fontSize: '16px', p: 0 }} />
                            </button>
                          </span>
                        )}
                        <button className='tw-dui-btn tw-dui-btn-xs tw-bg-[#EBEBEB] tw-rounded-3xl tw-px-2 tw-py-0.5 tw-text-xs tw-border tw-border-gray'
                        onClick={() => setShowEmailPreferencesModal(true)}>Edit</button>
                        </>
                    )}
                </div>
            </div>
            <div className='tw-flex tw-justify-between tw-items-center'>
                <div className='tw-flex tw-flex-wrap tw-gap-2 lg:tw-gap-3 tw-text-sm tw-mt-3  tw-w-full'>
                    <span className='tw-font-semibold tw-w-10'>CC:</span>
                    <span className='tw-bg-blue tw-text-white tw-rounded-3xl tw-px-2 tw-py-0.5 tw-text-xs tw-cursor-pointer'>{toArray[0]}</span>
                    {toArray?.slice(1).slice(0, 3)?.map((to) => (
                      <span className='tw-hidden lg:tw-inline-block tw-bg-blue tw-text-white tw-rounded-3xl tw-px-2 tw-py-0.5 tw-text-xs tw-cursor-pointer'>{to}</span>
                    ))}
                    {toArray.length > 1 && (
                      <span className='lg:tw-hidden tw-bg-blue tw-text-white tw-rounded-3xl tw-px-2 tw-py-0.5 tw-text-xs tw-cursor-pointer' onClick={() => setShowCCEmails(true)}>+{toArray.length - 1}</span>
                    )}
                    {toArray.length > 4 && (
                      <span className='tw-hidden lg:tw-inline-block tw-bg-blue tw-text-white tw-rounded-3xl tw-px-2 tw-py-0.5 tw-text-xs tw-cursor-pointer' onClick={() => setShowCCEmails(true)}>+{toArray.length - 4}</span>
                    )}
                    {/* {toArray.map((to) => (
                        <span className='tw-bg-blue tw-text-white tw-rounded-3xl tw-px-2 tw-py-0.5 tw-text-xs tw-cursor-pointer'>{to}</span>
                    ))} */}
                    <button className='tw-dui-btn tw-dui-btn-xs tw-bg-[#EBEBEB] tw-rounded-3xl tw-px-2 tw-py-0.5 tw-text-xs tw-border tw-border-gray'
                    onClick={() => setShowEmailPreferencesModal(true)}>Edit</button>
                </div>
            </div>
        </div>





        <Drawer
            anchor='right'
            open={showEmailPreferencesModal}
            onClose={() => {
            setShowEmailPreferencesModal(false);
            }}
            classes={{
                root: '!tw-z-[1202]',
                paper: 'tw-w-full md:!tw-w-[400px]',
            }}
        >
            <EmailPreferences
                onClose={() => setShowEmailPreferencesModal(false)}
                venueSettings={venueSettings}
                fetchVenueSettings={fetchVenueSettings}
                fetchCCMails={fetchCCMails}
            />
        </Drawer>

        
      <Dialog
        open={!isTablet && showCCEmails}
        onClose={() => {
          setShowCCEmails(false);
        }}
        // classes={{
        //   paper: 'tw-w-full',
        // }}
      >
        <CCEMails ccMails={toArray} onClose={() => setShowCCEmails(false)} />

      </Dialog>
      <Drawer
        anchor='bottom'
        open={isTablet && showCCEmails}
        onClose={() => {
          setShowCCEmails(false);
        }}
        classes={{
          paper: 'tw-w-full',
        }}
      >
        <div className='tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-white tw-h-full'>
          <div className='tw-flex tw-justify-between tw-items-center'>
            <div className='tw-text-xl tw-font-semibold'>CC Emails</div>
            <IconButton onClick={() => setShowCCEmails(false)}>
              <Close />
            </IconButton>
          </div>
          <div className='tw-flex tw-flex-col tw-gap-2'>
            {toArray?.map((ccMail) => (
              <div className='tw-flex tw-justify-between tw-items-center tw-border-b tw-pb-2'>
                {ccMail}
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default OrderEmailPreferences;


const CCEMails = ({ ccMails, onClose }) => {

  return (
    <div className='tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-white tw-h-full'>
        <div className='tw-flex tw-justify-between tw-items-center'>
          <div className='tw-text-xl tw-font-semibold'>CC Emails</div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className='tw-flex tw-flex-col tw-gap-2'>
          {ccMails?.map((ccMail) => (
            <div className='tw-flex tw-justify-between tw-items-center tw-border-b tw-pb-2'>
              {ccMail}
            </div>
          ))}
        </div>
      </div>
  )
}
