import React, { useRef, useState } from 'react'
import UploadImage from '../../../../Library/V2/UploadImage/UploadImage'
import styles from './LiquorLicense.module.scss'
import { Dialog, IconButton } from '@mui/material'
import { DeleteForever, Edit } from '@mui/icons-material'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
// import Const from '../../helpers/const'

const LiquorLicense = (props) => {

  const imageRef = useRef();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className='tw-flex tw-justify-between tw-items-center tw-mb-2'>
        <div className='tw-font-semibold tw-text-sm'>Business License</div>
      </div>

      <div className='tw-w-full tw-mb-2'>
        <UploadImage
          uploadText="Upload License"
          isBig={true}
          ref={imageRef}
          image={props.licenseFile}
          imageLink={props.license.licence_file}
          viewImageLabel="View License"
          removeLabel='Remove License'
          onViewImage={() => setOpen(true)}
          onRemoveImage={() => {
            props.removeLicenceImage()
            props.setLicenseFile()
            props.setNoLicense(true)
          }}
          onChange={(event) => {
            props.setLicenseFile(event.target.files[0])
            props.setNoLicense(false)
            event.target.value = ''
          }}
          onImageClose={(event) => {
            event.preventDefault()
            props.setLicenseFile()
          }}
        />
      </div>

      <GenericTextFieldWithLabel
        label={`License Number`}
        isSmallLabel={true}
        value={props.licenseNumber}
        onChange={(event) => {
          props.setLicenseNumber(event.target.value)
        }}
        fullWidth={true}
      />

      
      <Dialog
          open={open}
          onClose={() => setOpen(false)}
      >
          <div className={styles.uploaded_image}>
              <img
                  className='tw-max-w-full sm:tw-max-w-80 tw-max-h-full'
                  src={props.licenseFile ? URL.createObjectURL(props.licenseFile) : props.license.licence_file}
              />
          </div>
      </Dialog>
    </div>
  )
}

export default LiquorLicense