import React from 'react';
import {AttachMoney} from '@mui/icons-material';
import {TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    textInput: {
        borderRadius: "0",
        width: "100%",
        padding: '0 !important',
        "& > input": {
            padding: props => `${props.padding} !important`
        },
        fontSize: props => `${props.fontSize} !important`
    }
}))

const DollarInput = (props) => {
    const classes = useStyles(props)
    return (
        <div style={{display: 'flex'}}>
            <div style={{display: 'flex', alignItems: 'center',backgroundColor: '#DADADA', justifyContent: 'center',marginTop: '1px',width: props.imageWidth}}>
                <AttachMoney style={{height: '99%' ,color: 'white' ,cursor: 'pointer'}} />
            </div>
            <TextField
                InputProps={{
                    classes: {
                        root: classes.textInput
                    }
                }}
                variant='outlined'
                style={{ width: "100%", margin: 'auto'}}
                placeholder={props.placeholder}
            />
        </div>
    )
};

export default DollarInput