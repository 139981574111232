import {
  Breadcrumbs,
  Button,
  Divider,
  List,
  TextField,
  Typography,
} from "@mui/material";
import { nanoid } from "nanoid";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import footer from "../../assets/footer.png";
import header from "../../assets/header.png";
import { getBanners } from "../../redux/actions/bannerActions";
import { getProductGroups } from "../../redux/actions/productGroupActions";
import { getGroupList } from "../../redux/actions/groupListActions";
import { updateHomepage } from "../../servise/customizeAPI";
import {
  previewCategoryList,
  previewProductList,
} from "../../servise/previewAPI";
import Banner from "./Parts/Banner";
import BannersList from "./Parts/BannersList";
import CustomizePreview from "./Parts/CustomizePreview";
import GroupList from "./Parts/GroupList";
import { usePrompt } from "../../hooks/usePageLeave";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import LightTooltip from "../../util_comps/LightTooltip";
import { classname, cns } from "../../utils/bem";
import './CustomizeHomepage.scss';
import { Box, Dialog, MenuItem, Pagination, Select, Tab, Tabs } from "@mui/material";
import InputField from "../Library/InputField/InputField";
import { Search } from "@mui/icons-material";
import { debounce } from 'lodash'
import { toast } from "react-toastify";
import styles from './CustomizeHomePage.module.scss';
import SortBySelect from "../Library/SortBySelect/SortBySelect";
import AddCollectionDialog from "../Dialogs/AddCollectionDialog/AddCollectionDialog";
import TabPanel from "../Library/TabPanel/TabPanel";
import BannerPreview from "../Dialogs/BannerPreview/BannerPreview";
import AddBannerDialog from "../Dialogs/AddBannerDialog/AddBannerDialog";
import axios from '../../utils/axiosConfig'
import DragHereChip from "../Library/DragHereChip/DragHereChip";
import GenericSwitch from "../Library/GenericSwitch/GenericSwitch";
import ShowPopupFEDialog from "../Dialogs/ShowPopupFEDialog/ShowPopupFEDialog";
import EventDialog from "../Dialogs/EventDialog/EventDialog";
import OthersList from "../OthersList/OthersList";
import GenericButton from "../Library/GenericButton/GenericButton";
import routePaths from "../../config/route-paths";
import Banners from "./Banners/Banners";
import TabsSection from "../Library/TabsSection/TabsSection";
import Collections from "./Collections/Collections";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabs = [
  {
    label: 'Banners'
  },
  {
    label: 'Collections'
  },
  {
    label: 'Others'
  }
]

const CustomizeHomePage = (props) => {

  const cn = classname('customize-homepage')
  const history = useNavigate();
  const dispatch = useDispatch();

  const [selectedList, setSelectedList] = useState("product_group");
  const [showEventDialog, setShowEventDialog] = useState(false)
  const [query, setQuery] = useState("");
  const [isResponsive, setIsResponsive] = useState(false);
  const [isLaptopView, setIsLaptopView] = useState(true);
  const [sortBy, setSortBy] = useState('newproduct')
  const [value, setValue] = useState(0);
  const [subCollection, setSubCollection] = useState(0)
  const [showAddCollection, setShowAddCollection] = useState(false)
  const [bannerPreview, setBannerPreview] = useState(false)
  const [bannerIdForPreview, setBannerIdForPreview] = useState(null)
  const [bannerIdForEdit, setBannerIdForEdit] = useState(null)
  const [isBannerEdit, setIsBannerEdit] = useState(false)
  const [showAddBanner, setShowAddBanner] = useState(false)
  const [isProductGroupEdit, setIsProductGroupEdit] = useState('')
  const [showFEPopup, setShowFEPopup] = useState(false)
  const [showFEPopupDialog, setShowFEPopupDialog] = useState(false)
  const [othersItem, setOthersItem] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCollection = (event, newValue) => {
    setSubCollection(newValue);
    dispatch(getProductGroups({ type: newValue == 0 ? undefined : newValue }))
  };

  const { banner, productGroup, groupList } = useSelector((state) => state);

  const [isDirty, setIsDirty] = useState(false)
  // usePrompt("Are you sure want to discard changes?", isDirty);

  const initState = useRef({
    selectedItems: groupList.items,
  });
  const fetched = useRef(0);


  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsLaptopView(false)
      setIsResponsive(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (fetched.current === 1) {
      initState.current = {
        selectedItems: groupList.items,
      };
    } else if (fetched.current === 0) {
      return;
    }
    fetched.current = 2;
    let newStateStr = JSON.stringify({
      selectedItems: groupList.items,
    });
    let initStateStr = JSON.stringify(initState.current);
    if (newStateStr === initStateStr) {
      setIsDirty(false);
    } else {
      setIsDirty(true)
    }
  }, [groupList.items]);

  const [popUpId, setPopUpId] = useState()

  const fetchPopup = () => {
    axios.get('/venue-popup/list')
      .then((response) => {
        if (response.data?.data?.result?.id) {
          setPopUpId(response.data?.data?.result?.id)
          setShowFEPopup(Boolean(response.data?.data?.result?.state_id))
        }
      })
  }

  const fetchOthers = () => {
    axios.get('/venue-product-group/list', {
      params: {
        type: 'others'
      }
    })
      .then((response) => {
        setOthersItem(response.data.data.result)
      })
  }

  useEffect(() => {
    dispatch(getGroupList());
    fetchPopup()
    fetchOthers()
    fetched.current = 1;
  }, []);

  const handleSearch = () => {
    if (selectedList === "product_group") {
      searchGroup(query, 1);
    } else {
      searchBanners(query, 1)
    }
  };


  const onDragEnd2 = async (result) => {
    let isBanner = value == 0
    let isCollection = value == 1
    let isOther = value == 2

    if (!result.destination) return;
    const { source, destination } = result;

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const getList = () => {
      return isBanner ? {
        list: banner.banners,
        setter: (items) =>
          dispatch({ type: "SET_BANNERS", payload: { banners: items } }),
      } : {
        list: productGroup.productGroups,
        setter: (items) =>
          dispatch({
            type: "SET_PRODUCT_GROUPS",
            payload: { productGroups: items },
          }),
      };
    };

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "rightsection") {
        const items = reorder(
          groupList.items,
          result.source.index,
          result.destination.index
        );

        dispatch({
          payload: items,
          type: "REORDER_GROUP_LIST",
        });

        return;

      } else {
        let data = getList(source.droppableId);
        const items = reorder(
          data.list,
          result.source.index,
          result.destination.index
        );
        data.setter(items);
        return;
      }
    }

    if (groupList.items.length === 12 && destination.droppableId === "rightsection") {
      toast.info('You can only add 12 items to your homepage')
      return;
    }

    if (source.droppableId === "rightsection" && destination.droppableId === "leftsection") {
      return;
    }

    let start = isBanner ? banner.banners : productGroup.productGroups;

    let selectedItem;
    if (destination.droppableId === "rightsection" && source.droppableId === "leftsection" && isCollection) {

      if (start[source.index].data.productGroupType === "ProductList") {
        let preview = await previewProductList(
          start[source.index].data.api_uri,
          start[source.index].data.productListURL,
          true
        );
        selectedItem = {
          type: "productGroup",
          productGroupType: "productList",
          data: /* preview.data */ preview,
          productGroupName: start[source.index].data.productGroupName,
          productGroupId: start[source.index].data.productGroupId,
          productGroupUuid: start[source.index].data.productGroupUuid,
          draggableId: `${start[source.index].data.productGroupId}-${nanoid(
            15
          )}`,
          showTitle: true,
        };
      } else if (
        start[source.index].data.productGroupType === "TagProductList"
      ) {
        let preview = await previewProductList(
          start[source.index].data.api_uri,
          start[source.index].data.productListURL,
          true
        );
        selectedItem = {
          type: "productGroup",
          productGroupType: "tagList",
          data: /* preview.data */ preview,
          productGroupName: start[source.index].data.productGroupName,
          productGroupId: start[source.index].data.productGroupId,
          productGroupUuid: start[source.index].data.productGroupUuid,
          draggableId: `${start[source.index].data.productGroupId}-${nanoid(
            15
          )}`,
          showTitle: true,
        };
      } else if (start[source.index].data.productGroupType === "CategoryList") {
        let preview = await previewCategoryList(
          start[source.index].data.categoryIds
        );
        selectedItem = {
          type: "productGroup",
          productGroupType: "categoryList",
          data: /* preview.data, */ preview,
          productGroupName: start[source.index].data.productGroupName,
          productGroupId: start[source.index].data.productGroupId,
          productGroupUuid: start[source.index].data.productGroupUuid,
          draggableId: `${start[source.index].data.productGroupId}-${nanoid(
            15
          )}`,
          showTitle: true,
        };
      }
    } else if (destination.droppableId === "rightsection" && source.droppableId === "leftsection" && isOther) {
      if (othersItem[source.index].productGroupType === "NewsLetter") {
        selectedItem = {
          type: "productGroup",
          productGroupType: "NewsLetter",
          data: {
            result: [{ id: 1 }]
          },
          productGroupName: othersItem[source.index].productGroupName,
          productGroupId: othersItem[source.index].productGroupId,
          productGroupUuid: othersItem[source.index].productGroupUuid,
          draggableId: othersItem[source.index].productGroupId,
          showTitle: true,
        };
      }

      if (othersItem[source.index].productGroupType === "Event") {
        let preview = await axios.get('/venue-event/list')

        selectedItem = {
          type: "productGroup",
          productGroupType: "Event",
          data: preview.data.data,
          productGroupName: othersItem[source.index].productGroupName,
          productGroupId: othersItem[source.index].productGroupId,
          productGroupUuid: othersItem[source.index].productGroupUuid,
          draggableId: othersItem[source.index].productGroupId,
          showTitle: true,
        };
      }
    } else if (destination.droppableId === "rightsection" && source.droppableId === "leftsection" && isBanner) {
      selectedItem = {
        type: "banner",
        data: start[source.index].data,
        draggableId: `${start[source.index].data.id}-${nanoid(15)}`,
      };
    }
    let finishData = Array.from(groupList.items);
    finishData.splice(destination.index, 0, selectedItem);
    dispatch({
      payload: finishData,
      type: "REORDER_GROUP_LIST",
    });

  };

  const paginate = async (e, page) => {
    try {
      if (selectedList === "product_group") {
        searchGroup(query, page);
      } else {
        searchBanners(query, page);
      }
    } catch (err) {
      toast.error('Error while searching product groups, try again')
    }
  };

  const showTitle = (productGroupId) => {
    let newSelectedItems = groupList.items.map((val) => {
      if (val.productGroupId === productGroupId) {
        return {
          ...val,
          showTitle: true,
        };
      }
      return val;
    });
    dispatch({
      type: "REORDER_GROUP_LIST",
      payload: newSelectedItems,
    });
  };

  const hideTitle = (productGroupId) => {
    let newSelectedItems = groupList.items.map((val) => {
      if (val.productGroupId === productGroupId) {
        return {
          ...val,
          showTitle: false,
        };
      }
      return val;
    });
    dispatch({
      type: "REORDER_GROUP_LIST",
      payload: newSelectedItems,
    });
  };

  const handleApply = async () => {
    try {
      let selectedItemsBody = groupList.items.map((val, i) => {
        return {
          OrderNumber: i + 1,
          GroupType: val.type === "productGroup" ? "ProductGroup" : "Banner",
          ProductGroupId:
            val.type === "productGroup" ? val.productGroupId : null,
          BannerId: val.type === "banner" ? parseInt(val.data.id) : null,
          noTitle: val.showTitle ? val.showTitle : false,
        };
      });
      let body = {
        list: selectedItemsBody,
      };
      await updateHomepage(body);
      toast.success('Homepage was updated successfully')
      setIsDirty(false);
    } catch (err) {
      toast.error(err.message)
    }
  };
  useEffect(() => {
    (async () => {
      searchGroup(query, productGroup.pagination.currentPage);
      searchBanners(query, banner.pagination.current_page);
    })();
  }, []);

  const borderBottomRadius = !isLaptopView ? 10 : "initial";


  const searchBanners = (query, page) => {
    try {
      page = page ? page : 1;

      dispatch(getBanners({ page, query }));

    } catch (err) {
      toast.error('Error while searching banner, try again')
    }
  };

  const searchGroup = (searchStr, page, type) => {
    try {
      page = page ? page : 1;
      dispatch(getProductGroups({ type: type, searchStr, page }));
    } catch (err) {
      toast.error('Error while searching product groups, try again')
    }
  };

  const debounceSearchBanner = useCallback(debounce(searchBanners, 1000), [])
  const debounceSearchGroup = useCallback(debounce(searchGroup, 1000), [])

  return (
    <div
      className={cn()}
    >
      <Dialog
        onClose={() => {
          setShowAddCollection(false)
          setIsProductGroupEdit(false)
          history('/customize-homepage')
        }}
        open={showAddCollection}
        maxWidth="lg"
        keepMounted={false}
      >
        <AddCollectionDialog
          onClose={() => {
            setShowAddCollection(false)
            setIsProductGroupEdit(false)
            dispatch(getProductGroups({ type: subCollection == 0 ? undefined : subCollection }))
            history('/customize-homepage')
          }}
          subCollection={subCollection}
          setSubCollection={setSubCollection}
          isProductGroupEdit={isProductGroupEdit}
        />
      </Dialog>

      <BannerPreview
        showBannerPreview={bannerPreview}
        setShowBannerPreview={setBannerPreview}
        id={bannerIdForPreview}
      />

      <EventDialog
        open={showEventDialog}
        onClose={() => {
          setShowEventDialog(false)
        }}
      />


      <Dialog
        open={showAddBanner}
        onClose={() => {
          setShowAddBanner(false)
          setIsBannerEdit(false)
          setBannerIdForEdit()
        }}
        maxWidth="lg"
        keepMounted={false}
      >
        <AddBannerDialog
          open={showAddBanner}
          onClose={() => {
            setShowAddBanner(false)
            setIsBannerEdit(false)
            setBannerIdForEdit()
          }}
          isBannerEdit={isBannerEdit}
          bannerIdForEdit={bannerIdForEdit}
        />
      </Dialog>

      <ShowPopupFEDialog
        open={showFEPopupDialog}
        onClose={() => setShowFEPopupDialog(false)}
      />

      <Breadcrumbs
        style={{ fontSize: 13 }}
        aria-label="breadcrumb"
      >
        <Link
          style={{
            color: 'black'
          }}
          to="/dashboard"
        >
          Home
        </Link>
        <Typography
          style={{
            borderBottom: "1px solid grey",
            color: 'black'
          }}
        >
          Customize My Site
        </Typography>
      </Breadcrumbs>

      <Typography
        variant="h6"
        style={{
          fontSize: 19,
          margin: "10px 0"
        }}
      >
        Create the Perfect Store Page with Powerful Drag & Drop Tools
      </Typography>

      <div
        className={styles.drag_drop_container}
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd2(result)}
        >
          <div
            className={styles.leftsection}
            key="leftsection"
          >
            <div style={{ margin: 8, width: '100%' }}>

              {/* <TabsSection
                tabs={tabs}
                scrollButtons="auto"
                variant="scrollable"
                tabsBody={[
                  {
                    component: (
                      <Banners
                        setShowAddBanner={setShowAddBanner}
                        isActionsDisabled={props.isActionsDisabled}
                        styles={styles}
                        query={query}
                        setQuery={setQuery}
                        debounceSearchBanner={debounceSearchBanner}
                        banner={banner}
                        setBannerPreview={setBannerPreview}
                        setBannerIdForPreview={setBannerIdForPreview}
                        setIsBannerEdit={setIsBannerEdit}
                        setBannerIdForEdit={setBannerIdForEdit}
                        paginate={paginate}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                      />
                    )
                  },
                  {
                    component: (
                      <Collections
                        subCollection={subCollection}
                        handleChangeCollection={handleChangeCollection}
                        styles={styles}
                        isActionsDisabled={props.isActionsDisabled}
                        setShowAddCollection={setShowAddCollection}
                        query={query}
                        setQuery={setQuery}
                        debounceSearchGroup={debounceSearchGroup}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        hideTitle={hideTitle}
                        showTitle={showTitle}
                        productGroup={productGroup}
                        searchGroup={searchGroup}
                        setIsProductGroupEdit={setIsProductGroupEdit}
                        paginate={paginate}
                      />
                    )
                  },
                ]}
              /> */}

              <Box>
                <Box>
                  <Tabs
                    style={{
                      minHeight: 'unset'
                    }}
                    classes={{
                      indicator: cn('tab_indicator')
                    }}
                    value={value} onChange={handleChange} aria-label="basic tabs example"
                    scrollButtons="auto"
                    variant="scrollable"
                  >
                    <Tab
                      classes={{
                        root: cns(cn('tabs_root'), cn('sub_tab')),
                        selected: cn('tab_selected')
                      }}
                      label="Banners"
                      {...a11yProps(0)}
                    />
                    <Tab
                      classes={{
                        root: cns(cn('tabs_root'), cn('sub_tab')),
                        selected: cn('tab_selected')
                      }}
                      label="Collections"
                      {...a11yProps(1)}
                    />
                    <Tab
                      classes={{
                        root: cns(cn('tabs_root'), cn('sub_tab')),
                        selected: cn('tab_selected')
                      }}
                      label="Others"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>

                <Droppable droppableId="leftsection" key="leftsection">
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          minHeight: 500,
                          padding: '8px',
                          border: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                      >
                        <React.Fragment>
                          <TabPanel value={value} index={0}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: '6px'
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: '#676767',
                                  color: 'white',
                                }}
                                onClick={() => {
                                  setShowAddBanner(true)
                                }}
                                disabled={props.isActionsDisabled}
                              >
                                Add Banner
                              </Button>
                            </div>

                            <div
                              className={styles.search_sortby_container}
                            >
                              <div
                                style={{
                                  width: 220
                                }}
                              >
                                <InputField
                                  placeholder="Search Banner"
                                  value={query}
                                  onChange={(e) => {
                                    setQuery(e.target.value)
                                    debounceSearchBanner(e.target.value)
                                  }}
                                  onIconButtonClick={e => debounceSearchBanner(query)}
                                  showIconButton={true}
                                  endIcon={(
                                    <Search
                                      style={{
                                        color: '#ef2d2c'
                                      }}
                                    />
                                  )}
                                />
                              </div>

                              <div
                                style={{
                                  width: '125px'
                                }}
                              >
                                <SortBySelect
                                  value={sortBy}
                                  onChange={(event) => {
                                    setSortBy(event.target.value)
                                    dispatch(getBanners({ page: 1, sortby: event.target.value }));
                                  }}
                                  menuItems={[
                                    {
                                      value: "newproduct",
                                      name: "Newly Added"
                                    },
                                    {
                                      value: "nameaz",
                                      name: "Name (A-Z)"
                                    },
                                    {
                                      value: "nameza",
                                      name: "Name (Z-A)"
                                    }
                                  ]}
                                />
                              </div>
                            </div>

                            <BannersList
                              itemsToSelect={banner.banners}
                              provided={provided}
                              snapshot={snapshot}
                              setBannerPreview={setBannerPreview}
                              setBannerIdForPreview={setBannerIdForPreview}
                              setIsBannerEdit={setIsBannerEdit}
                              setShowAddBanner={setShowAddBanner}
                              setBannerIdForEdit={setBannerIdForEdit}
                              isActionsDisabled={props.isActionsDisabled}
                            />

                            {(banner.pagination.last_page > 1) && (
                              <Pagination
                                style={{ marginTop: 15 }}
                                size="small"
                                page={banner.pagination.current_page}
                                count={banner.pagination.last_page}
                                onChange={paginate}
                                variant="outlined"
                                shape="rounded"
                              />
                            )}
                          </TabPanel>

                          <TabPanel value={value} index={1}>

                            <Tabs
                              classes={{
                                indicator: cn('tab_indicator')
                              }}
                              value={subCollection}
                              onChange={handleChangeCollection}
                            >
                              <Tab
                                classes={{
                                  root: cn('tabs_root'),
                                  selected: cn('tab_selected')
                                }}
                                label="All"
                                {...a11yProps(0)}
                              />
                              <Tab
                                classes={{
                                  root: cn('tabs_root'),
                                  selected: cn('tab_selected')
                                }}
                                label="My Collections"
                                value="TagProductList"
                                {...a11yProps('TagProductList')}
                              />
                              <Tab
                                classes={{
                                  root: cn('tabs_root'),
                                  selected: cn('tab_selected')
                                }}
                                label="Products"
                                value="ProductList"
                                {...a11yProps('ProductList')}
                              />
                              <Tab
                                classes={{
                                  root: cn('tabs_root'),
                                  selected: cn('tab_selected')
                                }}
                                label="Categories"
                                value="CategoryList"
                                {...a11yProps('CategoryList')}
                              />
                            </Tabs>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: '10px'
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: '#676767',
                                  color: 'white',
                                }}
                                onClick={() => setShowAddCollection(true)}
                                disabled={props.isActionsDisabled}
                              >
                                Add Collection
                              </Button>
                            </div>

                            <div
                              className={styles.search_sortby_container}
                            >
                              <div
                                style={{
                                  width: 220
                                }}
                              >
                                <InputField
                                  placeholder="Search"
                                  value={query}
                                  onChange={(e) => {
                                    setQuery(e.target.value)
                                    debounceSearchGroup(e.target.value, 1, subCollection)
                                  }}
                                  onIconButtonClick={e => debounceSearchGroup(query)}
                                  showIconButton={true}
                                  endIcon={(
                                    <Search
                                      style={{
                                        color: '#ef2d2c'
                                      }}
                                    />
                                  )}
                                />
                              </div>

                              <div
                                style={{
                                  width: '125px'
                                }}
                              >
                                <SortBySelect
                                  value={sortBy}
                                  onChange={(event) => {
                                    setSortBy(event.target.value)
                                    dispatch(getProductGroups({ type: subCollection == 0 ? undefined : subCollection, query, sort: event.target.value }))
                                  }}
                                  menuItems={[
                                    {
                                      value: "newproduct",
                                      name: "Newly Added"
                                    },
                                    {
                                      value: "name_asc",
                                      name: "Name (A-Z)"
                                    },
                                    {
                                      value: "name_desc",
                                      name: "Name (Z-A)"
                                    }
                                  ]}
                                />
                              </div>
                            </div>

                            <GroupList
                              hideTitle={hideTitle}
                              showTitle={showTitle}
                              itemsToSelect={productGroup.productGroups}
                              searchGroup={searchGroup}
                              setShowAddCollection={setShowAddCollection}
                              provided={provided}
                              snapshot={snapshot}
                              setIsProductGroupEdit={setIsProductGroupEdit}
                              subCollection={subCollection}
                              isActionsDisabled={props.isActionsDisabled}
                            />

                            {(productGroup.pagination.last_page > 1) && (
                              <Pagination
                                style={{ marginTop: 15 }}
                                size="small"
                                page={productGroup.pagination.current_page}
                                count={productGroup.pagination.last_page}
                                onChange={paginate}
                                variant="outlined"
                                shape="rounded"
                              />
                            )}
                          </TabPanel>

                          <TabPanel
                            value={value}
                            index={2}
                          >
                            <div
                              className={styles.others_item_container}
                            >
                              <OthersList
                                items={othersItem}
                                setShowEventDialog={setShowEventDialog}
                                isActionsDisabled={props.isActionsDisabled}
                              />

                              <div
                                className={styles.show_popup_container}
                              >
                                <div
                                  style={{
                                    marginRight: '6px',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    if (!props.isActionsDisabled) {
                                      setShowFEPopupDialog(true)
                                    }
                                  }}
                                >
                                  Show popup on page
                                </div>
                                <GenericSwitch
                                  checked={showFEPopup}
                                  onChange={(event) => {
                                    if (!props.isActionsDisabled) {
                                      setShowFEPopup(event.target.checked)
                                      if (popUpId) {
                                        axios.post(`/venue-popup/toggle/${popUpId}`).then(() => {
                                          toast.success('Success')
                                        })
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </TabPanel>
                        </React.Fragment>

                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </Box>
            </div>
          </div>

          <DragHereChip />

          <div
            className={styles.rightsection}
            key="rightsection"
          >
            <div style={{ margin: 8, width: '100%' }}>

              <div
                style={{
                  display: "flex",
                  marginBottom: 5,
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    borderRadius: 50,
                    padding: "8px 20px",
                    margin: "0 7px"
                  }}
                  onClick={(e) => setIsLaptopView(true)}
                  variant="outlined"
                >
                  Website View
                </Button>
                <Button
                  style={{
                    borderRadius: 50,
                    padding: "8px 20px",
                    margin: "0 7px",
                  }}
                  onClick={(e) => setIsLaptopView(false)}
                  variant="outlined"
                >
                  Mobile App View
                </Button>
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  overflow: 'hidden'
                }}
              >
                <div
                  style={{
                    width: isLaptopView ? '76%' : '64%',
                    height: '82%',
                    overflow: 'auto',
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      marginBottom: "10px",
                      position: 'relative'
                    }}
                  >
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={header}
                      alt="Header"
                    />

                    <button
                      className={styles.edit_menu_btn}
                      onClick={() => {
                        history(routePaths.editMenu)
                      }}
                    >
                      Edit Menu
                    </button>
                  </div>

                  <Droppable droppableId="rightsection" key="rightsection">
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            padding: 4,
                            maxHeight: 400,
                            overflow: 'auto'
                          }}
                        >
                          {groupList.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.draggableId}
                                draggableId={item.draggableId}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  if (item.type === "productGroup") {
                                    return (
                                      <CustomizePreview
                                        isLaptopView={isLaptopView}
                                        provided={provided}
                                        snapshot={snapshot}
                                        item={item}
                                        index={index}
                                      />
                                    );
                                  }

                                  return (
                                    <Banner
                                      isLaptopView={isLaptopView}
                                      provided={provided}
                                      snapshot={snapshot}
                                      data={item}
                                      index={index}
                                    />
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>

                  <div
                    style={{
                      width: "100%",
                      height: "55px",
                      marginTop: "auto",
                      borderBottomLeftRadius: borderBottomRadius,
                      borderBottomRightRadius: borderBottomRadius,
                    }}
                  >
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={footer}
                      alt="Footer"
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </DragDropContext>
      </div>

      <Divider
        style={{ marginTop: "20px" }}
      />

      <div
        className={cn('apply-btn-container')}
      >
        <GenericButton
          onClick={handleApply}
          variant="contained"
          disabled={props.isActionsDisabled}
          size="large"
        >
          Apply
        </GenericButton>
      </div>

    </div>
  );
};

export default CustomizeHomePage;