import React from 'react';
import { Favorite, LocationOn, ShoppingCartOutlined, Star } from '@mui/icons-material';
import styles1 from './ProductCard.module.scss'
import styles2 from './ProductCard2.module.scss'
import clsx from 'clsx';
import Const from '../helpers/const';
import { getImageUrlDefault } from '../../utils/isImageExists';

const ProductCard = (props) => {
    const styles = props.isBigPreview ? styles2 : styles1
    return (
        <div
            className={clsx(styles.product_card, props.className)}
            onClick={props.handleCardClick}
        >
            {!props.isEvent && (
                <>
                    <div
                        className={styles.rating_icon_container}
                    >
                        <Star
                            className={styles.rating_icon}
                            style={{
                                color: '#E5131C',
                            }}
                        />
                        <div
                            className={styles.rating_number}
                        >
                            {props.avgRating?.toFixed(1)}
                        </div>
                    </div>
                    <div
                        className={styles.favorite_icon_container}
                        onClick={props.handleFavourites}
                    >
                        <Favorite
                            className={styles.favorite_icon}
                            style={{
                                cursor: "pointer",
                                color: props.isFavourite ? "ef2d2c" : "grey",
                            }}
                        />
                    </div>
                </>
            )}

            <div
                className={clsx(
                    styles.product_card_image_container,
                    props.imageContainerClassname
                )}
            >
                <img
                    className={styles.product_card_image}
                    src={props.noLogo ? props.catImage : props.productImage}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        // currentTarget.src = getImageUrlDefault();
                        currentTarget.className = clsx(styles.product_card_image, styles.product_card_error_image, !Const.isOurDesiStore && styles.notdesi_err_img)
                    }}
                    loading="lazy"
                />
            </div>
            <div
                className={clsx(styles.product_card_name_container, props.productCardNameClassname)}
            >
                {props.name}
            </div>
            <div
                className={clsx(styles.product_card_venue_name_container, props.productCardVenueNameClassname)}
            >
                {props.venueName}
            </div>

            {!props.isEvent && (
                <div
                    className={clsx(styles.product_card_price_checkin_container, props.productCardPriceContainerClassname)}
                >
                    <div
                        className={clsx(styles.product_card_price, props.productCardPriceClassname)}
                    >
                        {props.productPrice} {props.format && <>| {props.format}</>}
                    </div>

                    <div
                        className={styles.cart_checkin_icon_container}
                    >
                        <ShoppingCartOutlined
                            className={clsx(styles.cart_icon, props.cartIconClassname)}
                            style={{
                                color: '#E5131C'
                            }}
                        />

                        <div
                            className={styles.location_on_icon_container}
                            onClick={props.checkinView}
                        >
                            <LocationOn
                                className={clsx(styles.location_on_icon, props.locationOnIconClassname)}
                            />
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default React.memo(ProductCard)
