import axios from '../utils/axiosConfig'

export const updateProductGroup = async (requestData, parsedQstr) => {
  await axios.post(`/venue-product-group/edit/${parsedQstr.productGroupId}`, requestData);
};

export const addProductGroup = async (requestData) => {
  await axios.post(`/venue-product-group/create`, requestData);
};

export const deleteProductgroup = async (id) => {
  await axios.delete(`/venue-product-group/delete/${id}`);
};
