import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MarketingItems.module.scss'

const MarketingItems = (props) => {
    return (
        <Link
            className={styles.container}
            to={props.link}
        >
            <img
                className={styles.image}
                src={props.image}
            />

            <div
                className={styles.title}
            >
                {props.title}
            </div>
        </Link>
    )
}

export default MarketingItems