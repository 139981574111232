import { ColorLensOutlined, Refresh, West } from '@mui/icons-material'
import { Button, Tab, Tabs, Tooltip, styled, tooltipClasses } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import styles from './TopPanel.module.scss'
import clsx from 'clsx'
import { getImage2 } from '../../../../utils/helpers'
// import { driver } from 'driver.js'
// import { topPanelSteps } from './driverSteps'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
  />
))(({ }) => ({
  p: 2,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#525252',
    maxWidth: 220,
    fontSize: 16,
    border: 'none',
    borderRadius: 10,
    padding: '10px 15px',
    boxShadow: '0px 2px 16px #c0c0c0'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#fff',
  },
}));

const DesktopIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33333 1.77824C1.33333 1.68392 1.36845 1.59346 1.43096 1.52676C1.49348 1.46006 1.57826 1.42259 1.66667 1.42259H18.3333C18.4217 1.42259 18.5065 1.46006 18.569 1.52676C18.6315 1.59346 18.6667 1.68392 18.6667 1.77824V12.4477C18.6667 12.542 18.6315 12.6325 18.569 12.6992C18.5065 12.7659 18.4217 12.8033 18.3333 12.8033H1.66667C1.57826 12.8033 1.49348 12.7659 1.43096 12.6992C1.36845 12.6325 1.33333 12.542 1.33333 12.4477V1.77824ZM1.66667 0C0.746667 0 0 0.796653 0 1.77824V12.4477C0 13.4293 0.746667 14.2259 1.66667 14.2259H6.764L6.44 16.0739C6.42023 16.1868 6.424 16.3029 6.45104 16.4141C6.47807 16.5253 6.52771 16.6287 6.59644 16.7171C6.66516 16.8055 6.75127 16.8767 6.84867 16.9256C6.94607 16.9744 7.05236 16.9999 7.16 17H12.84C12.9476 16.9999 13.0539 16.9744 13.1513 16.9256C13.2487 16.8767 13.3348 16.8055 13.4036 16.7171C13.4723 16.6287 13.5219 16.5253 13.549 16.4141C13.576 16.3029 13.5798 16.1868 13.56 16.0739L13.236 14.2259H18.3333C19.2533 14.2259 20 13.4293 20 12.4477V1.77824C20 0.796653 19.2533 0 18.3333 0H1.66667ZM12.0133 14.2259H7.98667L7.72267 15.7197H12.2773L12.0133 14.2259Z" fill={color || "black"} />
    </svg>
  )
}
const TabletIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19.749 4C20.3457 4 20.918 4.23705 21.34 4.65901C21.7619 5.08097 21.999 5.65326 21.999 6.25V17.752C21.999 18.3487 21.7619 18.921 21.34 19.343C20.918 19.7649 20.3457 20.002 19.749 20.002H4.25C3.95453 20.002 3.66194 19.9438 3.38896 19.8307C3.11598 19.7177 2.86794 19.5519 2.65901 19.343C2.45008 19.1341 2.28434 18.886 2.17127 18.613C2.0582 18.3401 2 18.0475 2 17.752V6.25C2 5.65326 2.23705 5.08097 2.65901 4.65901C3.08097 4.23705 3.65326 4 4.25 4H19.749ZM19.749 5.5H4.25C4.05109 5.5 3.86032 5.57902 3.71967 5.71967C3.57902 5.86032 3.5 6.05109 3.5 6.25V17.752C3.5 18.167 3.836 18.502 4.25 18.502H19.749C19.9479 18.502 20.1387 18.423 20.2793 18.2823C20.42 18.1417 20.499 17.9509 20.499 17.752V6.25C20.499 6.05109 20.42 5.86032 20.2793 5.71967C20.1387 5.57902 19.9479 5.5 19.749 5.5ZM10.25 15.5H13.75C13.9414 15.4981 14.1263 15.5693 14.2668 15.6993C14.4073 15.8292 14.4928 16.0079 14.5059 16.1989C14.5189 16.3898 14.4585 16.5785 14.3369 16.7263C14.2153 16.8742 14.0419 16.9699 13.852 16.994L13.75 17H10.25C10.06 16.9999 9.87706 16.9278 9.73821 16.798C9.59936 16.6683 9.51493 16.4907 9.50197 16.3011C9.48902 16.1115 9.54851 15.9241 9.66843 15.7767C9.78835 15.6293 9.95975 15.5329 10.148 15.507L10.25 15.5Z" fill={color || "black"} />
    </svg>
  )
}
const MobileIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.33333 5.96154C7.33333 5.78813 7.40357 5.62182 7.5286 5.4992C7.65362 5.37658 7.82319 5.30769 8 5.30769H16C16.1768 5.30769 16.3464 5.37658 16.4714 5.4992C16.5964 5.62182 16.6667 5.78813 16.6667 5.96154V19.0385C16.6667 19.2119 16.5964 19.3782 16.4714 19.5008C16.3464 19.6234 16.1768 19.6923 16 19.6923H8C7.82319 19.6923 7.65362 19.6234 7.5286 19.5008C7.40357 19.3782 7.33333 19.2119 7.33333 19.0385V5.96154ZM8 4C7.46957 4 6.96086 4.20666 6.58579 4.57452C6.21071 4.94238 6 5.44131 6 5.96154V19.0385C6 19.5587 6.21071 20.0576 6.58579 20.4255C6.96086 20.7933 7.46957 21 8 21H16C16.5304 21 17.0391 20.7933 17.4142 20.4255C17.7893 20.0576 18 19.5587 18 19.0385V5.96154C18 5.44131 17.7893 4.94238 17.4142 4.57452C17.0391 4.20666 16.5304 4 16 4H8ZM10 17.9269C9.87623 17.9269 9.75753 17.9751 9.67002 18.061C9.5825 18.1468 9.53333 18.2632 9.53333 18.3846C9.53333 18.506 9.5825 18.6224 9.67002 18.7083C9.75753 18.7941 9.87623 18.8423 10 18.8423H14C14.1238 18.8423 14.2425 18.7941 14.33 18.7083C14.4175 18.6224 14.4667 18.506 14.4667 18.3846C14.4667 18.2632 14.4175 18.1468 14.33 18.061C14.2425 17.9751 14.1238 17.9269 14 17.9269H10Z" fill={color || "black"} />
    </svg>
  )
}

const TopPanel = ({ handleSave, device, setDevice, onPrev, lg, onRegenerate, isPageLoading, siteReady }) => {

  const driveObjRef = useRef()

  useEffect(() => {
    // driveObjRef.current = driver({
    //   showProgress: true,
    //   showButtons: lg ? ['close'] : ['next', 'previous', 'close'],
    //   doneBtnText: 'Got it',
    //   popoverOffset: 1,
    //   onCloseClick: () => {
    //     driveObjRef.current?.destroy()
    //   },
    //   steps: lg ? topPanelSteps?.slice(1) : topPanelSteps,
    //   onDestroyStarted: () => {
    //     driveObjRef.current?.destroy()
    //   },
    //   // allowClose:false
    // })

    if (!isPageLoading) {
      // driveObjRef.current?.drive()
    }
  }, [isPageLoading])

  // useEffect(() => {
  //   if (!siteReady && !isPageLoading) {
  //     driveObjRef.current?.drive()
  //   }
  // }, [siteReady])



  return (
    <div
      className="tw-h-14 tw-flex tw-items-center tw-justify-between tw-w-full tw-px-3"
    >
      <div
        className="tw-flex tw-gap-1"
      >
        <Button
          style={{
            color: 'black'
          }}
          variant="text"
          startIcon={<West />}
          onClick={onPrev}
        >
          Back
        </Button>

        {!lg && (
          <Button
            id="showapp"
            style={{
              borderRadius: 30,
              border: '1px solid #D3D3D3',
              color: 'black',
              width: 210,
              fontSize: 14
            }}
            variant="text"
            startIcon={(
              <img
                className="tw-h-7"
                src={getImage2('showapp.png-1709031662.png')}
              />
            )}
            onClick={() => {
              if(device == 'mobile'){
                setDevice('desktop')
              }else{
                setDevice('mobile')
              }
            }}
          >
            {(device == 'mobile') ? "Show me my Website" : "Show me my App"}
          </Button>
        )}
      </div>

      {!lg && (
        <div>
          <Tabs
            style={{
              minHeight: 30
            }}
            value={device}
            onChange={(event, value) => {
              setDevice(value)
            }}
            classes={{ flexContainer: styles.tabs_container }}
            TabIndicatorProps={{
              className: styles.tab_indicator
            }}
          >
            <Tab
              className={clsx((device == 'desktop') && styles.tab_selected)}
              classes={{
                root: styles.tab_root,
                selected: styles.tab_selected
              }}
              label={(
                <HtmlTooltip
                  title={
                    <div>
                      Switch to desktop
                      {/* <div style={{ fontSize: '14px', fontWeight: 400, marginTop: '2px' }}>
                    Edit your site for desktop.
                  </div> */}
                    </div>
                  }
                >
                  <div
                    style={{
                      fontSize: 16,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <DesktopIcon
                      style={{
                        marginRight: 4,
                        fontSize: 16
                      }}
                    />
                    {/* Desktop */}
                  </div>
                </HtmlTooltip>
              )}
              value={'desktop'}
            />
            <Tab
              className={clsx((device == 'tablet') && styles.tab_selected)}
              classes={{
                root: styles.tab_root,
                selected: styles.tab_selected
              }}
              label={(
                <HtmlTooltip
                  title={
                    <div>
                      Switch to Ipad/Tablet
                      {/* <div style={{ fontSize: '14px', fontWeight: 400, marginTop: '2px' }}>
                    Edit your site for Ipad/Tablet.
                  </div> */}
                    </div>
                  }
                >
                  <div
                    style={{
                      fontSize: 16,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <TabletIcon
                      style={{
                        marginRight: 4,
                        fontSize: 16
                      }}
                    />
                    {/* Ipad/Tablet */}
                  </div>
                </HtmlTooltip>
              )}
              value={'tablet'}
            />
            <Tab
              className={clsx((device == 'mobile') && styles.tab_selected)}
              classes={{
                root: styles.tab_root,
                selected: styles.tab_selected
              }}
              label={(
                <HtmlTooltip
                  title={
                    <div>
                      Switch to mobile
                      {/* <div style={{ fontSize: '14px', fontWeight: 400, marginTop: '2px' }}>
                    Edit your site for mobile.
                  </div> */}
                    </div>
                  }
                >
                  <div
                    style={{
                      fontSize: 16,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <MobileIcon
                      style={{
                        marginRight: 4,
                        fontSize: 16
                      }}
                    />
                    {/* Mobile App */}
                  </div>
                </HtmlTooltip>
              )}
              value={'mobile'}
            />
          </Tabs>
        </div>
      )}

      {!isPageLoading && (
        <div
          className="tw-flex tw-gap-4"
        >
          {!lg ? (
            <Button
              style={{
                borderRadius: 30,
                border: '1px solid #D3D3D3',
                color: 'black',
                fontSize: 14
              }}
              variant="text"
              startIcon={<Refresh />}
              onClick={onRegenerate}
            >
              Regenerate
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: 'white',
                borderRadius: 30,
                border: '1px solid #D3D3D3',
                color: 'black',
                fontSize: 14,
                position: 'fixed',
                bottom: 20,
                zIndex: 1000,
                right: 20
              }}
              variant="text"
              startIcon={<Refresh />}
              onClick={onRegenerate}
            >
              Regenerate
            </Button>
          )}

          <Button
            id="savesite"
            style={{
              backgroundColor: 'black',
              color: 'white',
              borderRadius: 30,
              fontSize: 14
            }}
            variant="contained"
            startIcon={<ColorLensOutlined />}
            onClick={handleSave}
          >
            Save & Customize
          </Button>

        </div>
      )}
    </div>
  )
}

export default TopPanel