import React, { useEffect, useState } from 'react'
import axios, { nodeAxios } from '../../utils/axiosConfig'
import Loader from '../Library/Loader'
import { useSearchParams } from 'react-router-dom'
import StripeIcon from '../../assets/images/stripe-black.png'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'
import { toast } from 'react-toastify'

const PaymentAccount = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [stripeData, setStripeData] = useState()
    const [feesData, setFeesData] = useState()
    console.log("🚀 ~ PaymentAccount ~ stripeData:", stripeData)
    const [searchParams, setSearchParams] = useSearchParams()
    const authCode = searchParams.get('code')
    const from = searchParams.get('from')

    const fetchFeesData = () =>{
        axios.get('/venue/setting').then((response) => {
            if (response.data.data?.venue_esplanda_fee) {
                setFeesData(response.data.data?.venue_esplanda_fee)
            }
        })
        .catch(() => {
          
        })
    }

    const fetchVenuePayment = () => {
        setIsLoading(true)
        axios.get('/venue-payment/get')
            .then((response) => {
                if (response.data.data?.payment_type == 1) {
                    setStripeData(response.data.data?.stripe)
                }else{
                    setStripeData()
                }
            })
            .catch(() => {
              
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleDisconnect = () => {
        setIsLoading(true)
        nodeAxios.delete('/stripe/disconnect')
            .then((response) => {

            })
            .catch(() => {

            })
            .finally(() => {
                fetchVenuePayment()
            })
    }

    const handleConnect = () => {
        setIsLoading(true)
        nodeAxios.post(
            '/stripe/connect',
            {
                return_url: `${window.location.href}?from=stripe`
            }
        )
            .then((response) => {
                console.log("🚀 ~ .then ~ response:", response)
              
                window.open(response.data?.data?.url, '_self')
            })
            .catch((err) => {
                toast.error(err.response?.data?.message)
            })
            .finally(() => {
              
                fetchVenuePayment()
            })
    }

    const handleAuthConnect = () => {
        nodeAxios
            .post('/stripe/auth-connect', {
                code: authCode
            })
            .then((res) => {
                toast.success('Your account is successfully connected.');
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {
                fetchVenuePayment()

            });
    };

    useEffect(() => {
        setTimeout(() => {
            fetchFeesData()
            fetchVenuePayment()
        }, 1000);
    }, [])

    useEffect(() => {
        if (from == 'stripe') {
            handleAuthConnect();
        }
    }, [searchParams]);

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="tw-p-8">
                    <div>
                        <div className="tw-font-semibold tw-text-2xl">Payment Account</div>

                        <div className="tw-font-semibold tw-text-sm tw-pt-8">Choose a Credit Card Processor</div>

                        <div className="tw-text-sm tw-pt-2 tw-pb-14" style={{color: '#868686'}}>To process Credit Card transactions, you need to have an account with one of the CC processors
                            we integrate with, Heartland and Stripe to process CC transactions.
                        </div>

                      </div>
                    {
                        stripeData ?
                            <div className="tw-max-w-[350px]">
                                <div
                                    className="tw-flex tw-flex-col tw-gap-[15px] tw-items-center tw-p-2 tw-border tw-border-gray-300 tw-rounded-lg"
                                >
                                    <div
                                        style={{
                                            color: '#00BF36',
                                            alignSelf: 'end',
                                            fontWeight: 600
                                        }}
                                    >
                                        • Connected
                                    </div>
                                    <div
                                        className="tw-flex tw-flex-col tw-items-center tw-text-center"
                                    >
                                        <img
                                            src={StripeIcon}
                                            width={80}
                                            height={50}
                                        />

                                        <div>
                                            Your website/App is ready to accept payments
                                        </div>
                                    </div>
                                    <GenericLoadingButton
                                        style={{
                                            backgroundColor: '#CB0000',
                                            borderRadius: 40,
                                            marginBottom: '2rem',
                                        }}
                                        variant='contained'
                                        loading={isLoading}
                                        loadingPosition='end'
                                        size='medium'
                                        // disabled={Boolean(venueDetails?.stripe_connect_status == 2) && (paymentMethod.type_id == 1)}
                                        onClick={() => {
                                            handleDisconnect()
                                        }}
                                    >
                                        Click to disconnnect
                                    </GenericLoadingButton>
                                </div>
                                <p className="tw-mt-4">You are all set to receive orders.</p>
                            </div> :
                            <div className="tw-max-w-[350px]">
                                <div
                                    className="tw-flex tw-flex-col tw-gap-[15px] tw-items-center  tw-p-2 tw-border tw-border-gray-300 tw-rounded-lg"
                                >
                                    <div
                                        className="tw-flex tw-flex-col tw-items-center tw-text-center"
                                    >
                                        <img
                                            src={StripeIcon}
                                            width={80}
                                            height={50}
                                        />

                                        <div>
                                            Click and connect payment gateway to accept payment made by your customers
                                        </div>
                                    </div>
                                    <GenericLoadingButton
                                        style={{
                                            borderRadius: 40,
                                            marginBottom: '2rem',
                                        }}
                                        variant='contained'
                                        loading={isLoading}
                                        loadingPosition='end'
                                        size='medium'
                                        onClick={() => {
                                            handleConnect()
                                        }}
                                    >
                                        Connect
                                    </GenericLoadingButton>
                                </div>
                                {
                                    feesData &&  <p className="tw-mt-4">Esplanda charges {feesData.pickup_fee??''} per order, only when you receive an order.</p>
                                }
                            </div>
                    }

                </div>
            )}
        </div>
    )
}

export default PaymentAccount