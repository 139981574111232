import axios, { axios3, nodeAxios } from "../../utils/axiosConfig";
import Cookies from 'js-cookie';
import axiosOptions from "../../components/helpers/axiosOptions";
import Const from "../../components/helpers/const";

export function getVenueStoreList(params) {
    return (dispatch) => {
        if (localStorage.userToken) {
            let url = params !== "" ? `/venue/get-stores?q=${params}` : '/venue/get-stores';
            dispatch({ type: "SET_VENUE_STORE_LIST_LOADING" });
            return axios
                .get(url)
                .then((response) => {
                    dispatch({
                        type: "SET_VENUE_STORE_LIST_LOADED",
                        payload: response.data,
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {
                    dispatch({
                        type: "SET_VENUE_STORE_LIST_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    };
}
export function getVenueStoreAdmin(params) {
    return (dispatch) => {
        if (localStorage.userToken) {
            let url = params !== "" ? `/venue/get-stores-admin?venue_id=${params}` : '/venue/get-stores-admin?venue_id=';
            dispatch({ type: "SET_VENUE_STORE_ADMIN_LOADING" });
            return axios
                .get(url)
                .then((response) => {
                    dispatch({
                        type: "SET_VENUE_STORE_ADMIN_LOADED",
                        payload: response.data,
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {

                    dispatch({
                        type: "SET_VENUE_STORE_ADMIN_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    };
}

export function shadowLogin(params) {
    return (dispatch) => {
        dispatch({ type: "SET_SHADOW_LOGIN_LOADING" });
        return nodeAxios.post('/auth/shadow-login', {
            id: params
        })
            .then((response) => {
                localStorage.setItem("userToken", response.data.data.token);
                localStorage.setItem("venue_id", response.data.data.user.venueid);
                if (params !== "7") {
                    localStorage.setItem("isShiftedFrom", true)
                }

                return nodeAxios
                    .get("/auth/get-user", {
                        headers: {
                            Authorization: `Bearer ${response.data.data.token}`
                        }
                    })
                    .then((response) => {
                        Cookies.set('scopes', response.data.data?.scopes?.join(','));
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: response.data.data,
                        });
                        return {
                            type: "success",
                            userId: response.data.data.id,
                            roleID: response.data.data?.biz_users_role,
                        };
                    })
                    .catch((error) => {
                        localStorage.removeItem("userToken");
                        Cookies.remove('scopes')
                        dispatch({
                            type: "LOGIN_ERROR",
                            payload: error.response,
                        });
                        return {
                            type: "error",
                            payload: error.response,
                        };
                    });
            })
            .catch((error) => dispatch({
                error: true, type: "SET_SHADOW_LOGIN_ERROR", message: error.response.data.message
            }));

    };
}
export function superAdminLogin(params) {
    return (dispatch) => {
        var userToken = localStorage.getItem("superAdminToken");
        const config = {
            headers: { Authorization: `Bearer ${userToken}` },
        };
        dispatch({ type: "SET_SHADOW_LOGIN_LOADING" });
        return axios3
            .post('/auth/shadow-login', {
                id: params
            }, config)
            .then((response) => {
                localStorage.setItem("userToken", userToken);
                localStorage.setItem("venue_id", response.data.data.user.venueid);
                localStorage.setItem("isShiftedFrom", false);
                localStorage.setItem("StoreName", "");

                return nodeAxios
                    .get("/auth/get-user", {
                        headers: {
                            Authorization: `Bearer ${response.data.data.token}`
                        }
                    })
                    .then((response) => {
                        Cookies.set('scopes', response.data.data?.scopes?.join(','));
                        if (response.data.data?.biz_users_role == 0) {
                            localStorage.setItem("superAdminToken", userToken);
                        }
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: response.data.data,
                        });
                        return {
                            type: "success",
                            userId: response.data.data.id,
                            roleID: response.data.data?.biz_users_role,
                        };
                    })
                    .catch((error) => {
                        localStorage.removeItem("userToken");
                        Cookies.remove('scopes')
                        dispatch({
                            type: "LOGIN_ERROR",
                            payload: error.response,
                        });
                        return {
                            type: "error",
                            payload: error.response,
                        };
                    });
            })
            .catch((error) => dispatch({
                error: true, type: "SET_SHADOW_LOGIN_ERROR", message: error.response.data.message
            }));

    };
}