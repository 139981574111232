import React from 'react'
import TabsSection from '../Library/TabsSection/TabsSection'
import AppAnalytics from './AppAnalytics/AppAnalytics'
import GoogleAnalytics from './GoogleAnalytics/GoogleAnalytics'
import MerchantAnalytics from './MerchantAnalytics/MerchantAnalytics'
import SeoAnalytics from './SeoAnalytics/SeoAnalytics'
import styles from './WebsitePerformance.module.scss'

const tabs = [
    {
        label: 'Website Analytics'
    },
    {
        label: 'SEO Analytics'
    },
    {
        label: 'Merchant Analytics'
    },
    {
        label: 'App Analytics'
    }
]

const WebsitePerformance = () => {
    return (
        <div>
            <div
                className={styles.title_container}
            >
                <div
                    className={styles.title}
                >
                    Website Performance
                </div>
            </div>

            <TabsSection
                tabs={tabs}
                tabsBody={[
                    {
                        component: <GoogleAnalytics />
                    },
                    {
                        component: <SeoAnalytics />
                    },
                    {
                        component: <MerchantAnalytics />
                    },
                    {
                        component: <AppAnalytics />
                    }
                ]}
            />
        </div>
    )
}

export default WebsitePerformance