//New Orders
import { nanoid } from 'nanoid';
import {
    previewCategoryList,
    previewProductList,
} from '../../servise/previewAPI';
import axios from '../../utils/axiosConfig'

export const getGroupList = (params) => {
    return async (dispatch) => {

        let { data: { data: { result: data } } } = await axios.get('/venue-home-page-order/list');

        let promises = [];

        for (let val of data) {
            if (val.productGroupType) {
                if (val.productGroupType === "ProductList" || val.productGroupType === "TagProductList") {
                    let preview = previewProductList(val.api_uri, val.productListURL, true)
                    promises.push(preview)
                } else {
                    if(val.categoryIds){
                        let preview = previewCategoryList(
                            val.categoryIds
                        )
                        promises.push(preview);
                    }
                };
            } else {
                promises.push(null)
            }
        };
        
        Promise.all(promises)
            .then((resolvedPromises) => {
                let items = data.map((val, i) => {
                    if (val.groupListingType === "Banner") {
                        return {
                            type: 'banner',
                            data: {
                                image: val.bannerImage,
                                id: val.bannerGroupId
                            },
                            draggableId: `${val.bannerGroupId}-${nanoid(15)}`
                        }
                    } else {
                        if (val.productGroupType === "ProductList" || val.productGroupType === "TagProductList") {
                            return {
                                type: 'productGroup',
                                productGroupType: val.productGroupType === "ProductList" ? 'productList' : 'tagList',
                                data: resolvedPromises[i],
                                productGroupName: val.productGroupName,
                                productGroupId: val.productGroupId,
                                productGroupUuid: nanoid(15),
                                draggableId: `${val.productGroupId}-${nanoid(
                                    15
                                )}`,
                                showTitle: val.showTitle
                            }
                        } else {
                            return {
                                type: 'productGroup',
                                productGroupType: 'categoryList',
                                data: resolvedPromises[i],
                                productGroupName: val.productGroupName,
                                productGroupId: val.productGroupId,
                                productGroupUuid: nanoid(15),
                                draggableId: `${val.productGroupId}-${nanoid(
                                    15
                                )}`,
                                showTitle: val.showTitle,
                            };
                        }
                    }
                })

                const uniqueBanner = items.filter((item, index, self) =>
                    index === self.findIndex((selfItem) => (
                        selfItem.type === 'banner' && selfItem.data?.id === item.data.id
                    ))
                )

                items = items.filter((item)=>{
                    if(item.type === 'banner'){
                        return !(uniqueBanner.filter((ubanner)=> (ubanner.data.id === item.data.id)).length > 0 )
                    }
                    return true
                })

                if(uniqueBanner.length > 0 ){
                    items = [...uniqueBanner, ...items]
                }

                dispatch({
                    type: 'SET_GROUP_LIST',
                    payload: items
                });
            })
    };
};