const initialState = {
    isLoading: false,
    isLoggedIn: false,
    productList: {},
    productListError: ""
};
function productList(state = initialState, action) {
    switch (action.type) {
        case "SET_PRODUCTSTORE_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_PRODUCTSTORE_LIST_LOADED":
            return { ...initialState, isLoading: false , productList: action.payload };
        case "SET_PRODUCTSTORE_LIST_ERROR":
            return { ...initialState,productListError:action.payload};
        default:
            return state;
    }
};
export default productList;  