import React from 'react';
import styles from './GenericSearchField.module.scss';
import { FormControl, InputBase, TextField } from '@mui/material';

const GenericSearchField = (props) => {
  return (
    <div className={styles.searh_input_container}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z'
          stroke='#919191'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.9999 18.9999L14.6499 14.6499'
          stroke='#919191'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>

      <FormControl
        classes={{
          root: styles.input_form_control,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          classes={{
            root: styles.input_box_style,
            input: styles.input_element,
          }}
          {...props}
        />
      </FormControl>
    </div>
  );
};

export default GenericSearchField;
