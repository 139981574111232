import React from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/styles';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    //   disableFocusListener
    //   disableHoverListener
    //   disableTouchListener
    // placement="left-end"
      PopperProps={{
        disablePortal: true,
      }}
      arrow
    //   leaveDelay={5000}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000',
      color: '#fff',
      maxWidth: 350,
      fontSize: 14,
      border: 'none',
      borderRadius: 10,
      padding: '10px 15px',
    //   textAlign: 'center',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#000  ',
    },
  }));


  export default HtmlTooltip