import React, { useEffect, useState } from 'react'
import styles from './Modifier.module.scss'
import { Checkbox, Collapse, FormControlLabel, FormGroup, FormHelperText, Radio } from '@mui/material'

const Modifier = ({ data, formik, modifierValidation, isEdit, customizeObj }) => {

    const [isCollapse, setIsCollapse] = useState(isEdit ? Boolean(formik.values?.modifierAllData?.filter((fdata) => fdata?.modifier_id == data?.modifier_id)?.length) : !Boolean(data.modifier_is_expandable))

    return (
        <div className={styles.modifierbox}>
            <div
                style={{
                    display: 'flex',
                    gap: 6,
                    alignItems: 'center',
                    paddingBottom: '5px'
                }}
            >
                <Checkbox
                    style={{
                        padding: 0
                    }}
                    checked={isCollapse}
                    onChange={(event) => {
                        if (event.target.checked) {
                            // const filteredModifier = formik.values?.modifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                            // const obj = {
                            //     ...data,
                            //     modifier_option: []
                            // }
                            // formik.setFieldValue('modifierAllData', [
                            //     ...filteredModifier,
                            //     obj
                            // ])

                            // formik.setFieldValue('modifiers', {
                            //     ...formik.values?.modifiers,
                            //     [data?.modifier_id]: []
                            // })

                            let newModifierAllData = []
                            let newModifiers = {}

                            if (formik.values?.modifierAllData) {
                                newModifierAllData = [...formik.values?.modifierAllData];
                            }

                            if (formik.values?.modifiers) {
                                newModifiers = { ...formik.values?.modifiers };
                            }


                            if (data?.modifier_max == 1) {
                                data?.modifier_option?.forEach((data1) => {
                                    if (Boolean(data1?.is_default)) {
                                        const filteredModifier = newModifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                                        const obj = {
                                            ...data,
                                            modifier_option: [{
                                                ...data1,
                                                price: Boolean(data1.price || 0) ? data1.price : 0
                                            }]
                                        }
                                        console.log(obj, filteredModifier, 'obj')
                                        newModifierAllData = [
                                            ...filteredModifier,
                                            obj
                                        ]

                                        newModifiers = {
                                            ...newModifiers,
                                            [data?.modifier_id]: [data1.id]
                                        }
                                    }
                                })
                            } else {
                                data?.modifier_option?.forEach((data1) => {
                                    if (Boolean(data1?.is_default)) {
                                        const filteredModifier = newModifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                                        const filteredModifier2 = newModifierAllData?.filter((fdata) => fdata?.modifier_id == data?.modifier_id)


                                        if (newModifiers[data?.modifier_id]) {

                                            const obj = {
                                                ...data,
                                                modifier_option: filteredModifier2[0] ? [...filteredModifier2[0]?.modifier_option, {
                                                    ...data1,
                                                    price: Boolean(data1.price || 0) ? data1.price : 0
                                                }] : [{
                                                    ...data1,
                                                    price: Boolean(data1.price || 0) ? data1.price : 0
                                                }]
                                            }
                                            console.log(obj, 'obj2')
                                            newModifierAllData = [
                                                ...filteredModifier,
                                                obj
                                            ]

                                            newModifiers = {
                                                ...newModifiers,
                                                [data?.modifier_id]: [...newModifiers[data?.modifier_id], data1.id]
                                            }

                                        } else {

                                            const obj = {
                                                ...data,
                                                modifier_option: [{
                                                    ...data1,
                                                    price: Boolean(data1.price || 0) ? data1.price : 0
                                                }]
                                            }
                                            console.log(obj, 'obj3')
                                            newModifierAllData = [
                                                ...filteredModifier,
                                                obj
                                            ]

                                            newModifiers = {
                                                ...newModifiers,
                                                [data?.modifier_id]: [data1.id]
                                            }
                                        }
                                    }
                                })
                            }

                            formik.setFieldValue('modifierAllData', newModifierAllData);
                            formik.setFieldValue('modifiers', newModifiers);
                        } else {

                            const filteredModifier = formik.values?.modifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)

                            formik.setFieldValue('modifierAllData', [
                                ...filteredModifier,
                            ])

                            let modifier = formik.values?.modifiers
                            delete modifier[data?.modifier_id]
                            formik.setFieldValue('modifiers', modifier)
                        }

                        setIsCollapse(event.target.checked)

                    }}
                />
                <div
                    style={{
                        fontWeight: 600
                    }}
                >
                    {data.modifier_title}{' '}{Boolean(parseFloat(data.modifier_price)) ? `+$${parseFloat(data.modifier_price)?.toFixed(2)}` : ''}
                </div>

                <div
                    style={{
                        fontSize: 13,
                        color: '#878787'
                    }}
                >
                    {modifierValidation[data?.modifier_id] ? (
                        <FormHelperText style={{ display: 'flex' }} error>{modifierValidation[data?.modifier_id]}</FormHelperText>
                    ) : (
                        <>
                            {Boolean(data?.modifier_min) && `Choose at least ${data?.modifier_min} option`}
                        </>
                    )}
                </div>
            </div>

            <Collapse
                in={isCollapse}
            >
                <FormGroup row={true} style={{ display: 'flex', flexFlow: 'wrap', flexDirection: 'column', marginLeft: 20 }}>
                    {data.modifier_option?.map((data1) => {
                        let sOptions = []

                        if(formik.values?.modifierAllData){
                            sOptions = formik.values?.modifierAllData?.find((fdata) => fdata?.modifier_id == data?.modifier_id)?.modifier_option || []
                        }
                        
                        return (
                            <FormControlLabel
                                control={(data?.modifier_max == 1) ? (
                                    <Radio
                                        checked={Boolean(sOptions?.filter((fdata) => fdata?.id == data1.id)?.length) || false}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                let filteredModifier = []
                                                if(formik.values?.modifierAllData){
                                                    filteredModifier = formik.values?.modifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                                                }
                                                const obj = {
                                                    ...data,
                                                    modifier_option: [{
                                                        ...data1,
                                                        price: Boolean(data1.price || 0) ? data1.price : 0
                                                    }]
                                                }
                                                formik.setFieldValue('modifierAllData', [
                                                    ...filteredModifier,
                                                    obj
                                                ])

                                                formik.setFieldValue('modifiers', {
                                                    ...formik.values?.modifiers,
                                                    [data?.modifier_id]: [data1.id]
                                                })
                                            } else {
                                                const checked = formik.values?.modifiers[data?.modifier_id]?.filter((id) => id != data1.id)

                                                let filteredModifier = []
                                                if(formik.values?.modifierAllData){
                                                    filteredModifier = formik.values?.modifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                                                }
                                                
                                                let newOption = []
                                                if (Boolean(sOptions?.length)) {
                                                    newOption = sOptions?.filter((fdata) => fdata.id != data1.id)
                                                }
                                                const obj = {
                                                    ...data,
                                                    modifier_option: newOption
                                                }
                                                const obj2 = {
                                                    ...data,
                                                    modifier_option: []
                                                }

                                                if (checked && checked?.length) {
                                                    formik.setFieldValue('modifierAllData', [
                                                        ...filteredModifier,
                                                        obj
                                                    ])

                                                    formik.setFieldValue('modifiers', {
                                                        ...formik.values?.modifiers,
                                                        [data?.modifier_id]: checked
                                                    })
                                                } else {
                                                    let modifier = formik.values?.modifiers
                                                    modifier[data?.modifier_id] = []
                                                    formik.setFieldValue('modifiers', modifier)
                                                    formik.setFieldValue('modifierAllData', [
                                                        ...filteredModifier,
                                                        obj2
                                                    ])
                                                }
                                            }
                                        }}
                                    />
                                ) : (
                                    <Checkbox
                                        checked={Boolean(sOptions?.filter((fdata) => fdata?.id == data1.id)?.length) || false}
                                        onChange={(event) => {
                                            console.log(event.target.checked)
                                            if (event.target.checked) {

                                                let filteredModifier = []
                                                if(formik.values?.modifierAllData){
                                                    filteredModifier = formik.values?.modifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                                                }
                                                
                                                let filteredModifier2 = []
                                                if(formik.values?.modifierAllData){
                                                    filteredModifier2 = formik.values?.modifierAllData?.filter((fdata) => fdata?.modifier_id == data?.modifier_id)
                                                }
                                                

                                                if (formik.values?.modifiers[data?.modifier_id]) {
                                                    const obj = {
                                                        ...data,
                                                        modifier_option: filteredModifier2[0] ? [...filteredModifier2[0]?.modifier_option, {
                                                            ...data1,
                                                            price: Boolean(data1.price || 0) ? data1.price : 0
                                                        }] : [{
                                                            ...data1,
                                                            price: Boolean(data1.price || 0) ? data1.price : 0
                                                        }]
                                                    }
                                                    formik.setFieldValue('modifierAllData', [
                                                        ...filteredModifier,
                                                        obj
                                                    ])

                                                    formik.setFieldValue('modifiers', {
                                                        ...formik.values?.modifiers,
                                                        [data?.modifier_id]: [...formik.values?.modifiers[data?.modifier_id], data1.id]
                                                    })
                                                } else {

                                                    const obj = {
                                                        ...data,
                                                        modifier_option: [{
                                                            ...data1,
                                                            price: Boolean(data1.price || 0) ? data1.price : 0
                                                        }]
                                                    }
                                                    formik.setFieldValue('modifierAllData', [
                                                        ...filteredModifier,
                                                        obj
                                                    ])

                                                    formik.setFieldValue('modifiers', {
                                                        ...formik.values?.modifiers,
                                                        [data?.modifier_id]: [data1.id]
                                                    })
                                                }
                                            } else {
                                                const checked = formik.values?.modifiers[data?.modifier_id]?.filter((id) => id != data1.id)
                                                let filteredModifier = []
                                                if(formik.values?.modifierAllData){
                                                    filteredModifier = formik.values?.modifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                                                }
                                                

                                                let newOption = []
                                                if (Boolean(sOptions?.length)) {
                                                    newOption = sOptions?.filter((fdata) => fdata.id != data1.id)
                                                }
                                                const obj = {
                                                    ...data,
                                                    modifier_option: newOption
                                                }

                                                const obj2 = {
                                                    ...data,
                                                    modifier_option: []
                                                }

                                                if (checked && checked?.length) {
                                                    console.log('checkkkkkked')
                                                    formik.setFieldValue('modifierAllData', [
                                                        ...filteredModifier,
                                                        obj
                                                    ])

                                                    formik.setFieldValue('modifiers', {
                                                        ...formik.values?.modifiers,
                                                        [data?.modifier_id]: checked
                                                    })
                                                } else {
                                                    let modifier = formik.values?.modifiers
                                                    modifier[data?.modifier_id] = []
                                                    formik.setFieldValue('modifiers', modifier)
                                                    formik.setFieldValue('modifierAllData', [
                                                        ...filteredModifier,
                                                        obj2
                                                    ])
                                                }
                                            }
                                        }}
                                    />
                                )}
                                label={(
                                    <div style={{ fontSize: '13px', color: '#000000' }}>
                                        {data1?.title}{' '}

                                        {Boolean(parseFloat(data1.price)) && (
                                            <span>
                                                (+${parseFloat(data1.price || 0).toFixed(2)})
                                            </span>
                                        )}
                                    </div>
                                )}
                                style={{ fontSize: '14px', marginTop: '-5px', marginBottom: '-5px' }}
                            />
                        )
                    }
                    )}
                    {modifierValidation[data?.modifier_id] && (
                        <FormHelperText style={{ display: 'flex' }} error>{modifierValidation[data?.modifier_id]}</FormHelperText>
                    )}
                </FormGroup>
            </Collapse>
        </div>
    )
}

export default Modifier