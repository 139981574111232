const initialState = {
    isLoading: false,
    isLoggedIn: false,
    SettingList: {},
    SettingListError: "",
    SettingDetail: {},
    SettingDetailError: "",
    settingDetailsUpdate:{},
    settingDetailsUpdateError:"",
    settingDelete:{},
    settingDeleteError:"",

    bannerCategoryLoading: false,
    bannerCategoryList:{},
    bannerCategoryError:""
};
export function setting(state = initialState, action) {
    switch (action.type) {
        case "SET_SETTING_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_SETTING_LIST_LOADED":
            return { ...initialState, isLoading: false , SettingList: action.payload };
        case "SET_SETTING_LIST_ERROR":
            return { ...initialState,SettingListError:action.payload};
        default:
            return state;
    }
};
export function settingCreate(state = initialState, action) {
    switch (action.type) {
        case "ADD_SETTING_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "ADD_SETTING_LIST_LOADED":
            return { ...initialState, isLoading: false , SettingDetail: action.payload };
        case "ADD_SETTING_LIST_ERROR":
            return { ...initialState,SettingDetailError:action.payload};
        default:
            return state;
    }
};
export  function settingDetailsUpdate(state = initialState, action) {
    switch (action.type) {
        case "SET_SETTING_DETAILS_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_SETTING_DETAILS_LOADED":
            return { ...initialState, isLoading: false , settingDetailsUpdate: action.payload };
        case "SET_SETTING_DETAILS_ERROR":
            return { ...initialState,settingDetailsUpdateError:action.payload};
        default:
            return state;
    }
};
export function settingDelete(state = initialState, action) {
    switch (action.type) {
        case "DELETE_SETTING_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "DELETE_SETTING_LIST_LOADED":
            return { ...initialState, isLoading: false , settingDelete: action.payload };
        case "DELETE_SETTING_LIST_ERROR":
            return { ...initialState,settingDeleteError:action.payload};
        default:
            return state;
    }
};




export function bannerCategory  (state = initialState, action){
    switch (action.type) {
        case "SET_BANNER_CATEGORY_LOADING":
            return { ...initialState, bannerCategoryLoading: true };
        case "SET_BANNER_CATEGORY_LOADED":
            return { ...initialState, bannerCategoryLoading: false , bannerCategoryList: action.payload };
        case "SET_BANNER_CATEGORY_ERROR":
            return { ...initialState,bannerCategoryError:action.payload};
        default:
            return state;
    }
}