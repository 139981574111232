import clsx from 'clsx'
import React from 'react'
import GenericTextField from '../GenericTextField/GenericTextField'
import styles from './GenericTextFieldWithLabel.module.scss'

const GenericTextFieldWithLabel = ({ label, containerStyle,containerClassname, ...rest }) => {
    return (
        <div
            style={containerStyle}
            className={clsx(styles.container, containerClassname)}
        >
            <div
                className={styles.label_text}
            >
                {label}
                {rest.required && (
                    <span
                        className={styles.required_asterisk}
                    >
                        *
                    </span>
                )}
            </div>

            <GenericTextField
                {...rest}
            />
        </div>
    )
}

export default GenericTextFieldWithLabel