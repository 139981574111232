import React from 'react'
import SelectFormDialogContainer from './SelectFormDialogContainer/SelectFormDialogContainer'

const Dialogs = () => {
  return (
    <div>
        <SelectFormDialogContainer />
    </div>
  )
}

export default Dialogs