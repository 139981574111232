import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import storelist from "../../../assets/store-list.png";
import listicon from "../../../assets/list-text.png";
import storedetailsimg from "../../../assets/store-details.png";
import routePaths from '../../../config/route-paths';
const StoreDetails = (props) => {
   const cn = classname('add-new-product')
   return (
      <div
         className={cns(
            cn(),
            props.className,
         )}>
         <div className={cn('help-page-title')}>
            <div className={cn('title-text')}>
               <Typography variant="h1">
                  Help & Support
               </Typography>
            </div>
            <div className={cn('help-page-title-search')}>
               Search
            </div>
         </div>
         <div className={cn('help-page-body')}>
            <div className={cn('helpdesk-grid')}>
               <div className={cn('left-list')}>
                  <Typography variant="h2">
                     <div className={cn('iconProduct')}>
                        <img src={storelist} />
                     </div>
                     Store Settings
                  </Typography>
                  <ul className={cn('helpdeskinnerlist')}>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.StoreDetails} className={cn('selected')}>Store Details</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.FulfillmentMethods}>Fulfillment Methods</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.BankInformation}>Bank Information</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.LiquorLicense}>Business License</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.SubmitLogo}>Submit Logo</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.POSIntegration}>POS Integration</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AccessYourWebsite}>Access Your Website</Link></li>
                  </ul>
               </div>
               <div className={cn('right-body-content')}>
                  <Typography variant="h3">
                     Store Details
                  </Typography>

                  <p>

                     <ol>
                        <li>Go to the ‘<strong>My Store Settings</strong>’ section of the admin website.</li>

                        <span className={cn('addnewproductsimg')}>
                           <img src={storedetailsimg} />
                        </span>

                        <li>The first tab that you will see is ‘<strong>Store Details</strong>’. The majority of these fields are already pre-filled from sign-up, such as your store
                           name, phone number, email, and physical address.
                        </li>



                        <li>You can edit the time zone as well as the description of your store. The description is the content that appears
                           in the ‘<strong>About Us</strong>’ section of your store.</li>


                        <li>When you are finished editing your store details, click the ‘<strong>Save</strong>’ button in the bottom-right of the page.</li>




                     </ol>
                  </p>
               </div>
            </div>
         </div>
         <div className={cn('answersection')}>
            <Typography variant="h4">
               Still can’t find an answer?
            </Typography>
            <p>Send us a ticket and we will get back to you.</p>
            <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
         </div>
      </div>
   )
}
export default StoreDetails