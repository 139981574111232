import React from 'react'
import styles from './SocialMedia.module.scss'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import FacebookIcon from '../../../../../assets/facebook.png'
import InstagramIcon from '../../../../../assets/instagram.png'
import TwitterIcon from '../../../../../assets/twitter.png'
import SnapchatIcon from '../../../../../assets/images/snapchat.jpg'
import TikTokIcon from '../../../../../assets/images/tiktok.png'
import LinkedInIcon from '../../../../../assets/images/linkedin.png'
import YoutubeIcon from '../../../../../assets/images/youtube.png'
import PinterestIcon from '../../../../../assets/images/pinterest.png'

const SocialMedia = ({ formik }) => {
    return (
        <div className=''>

            <div className='tw-font-semibold tw-text-sm tw-mb-2'>Social media links</div>

            <div className='tw-flex tw-flex-col tw-gap-4'>
                <SocialItem 
                    icon={FacebookIcon}
                    value={formik.values.social_links?.facebook_url}
                    onChange={formik.handleChange}
                    placeholder='Facebook username'
                />

                <SocialItem 
                    icon={InstagramIcon}
                    value={formik.values.social_links?.instagram_url}
                    onChange={formik.handleChange}
                    placeholder='Instagram username'
                />

                <SocialItem 
                    icon={TwitterIcon}
                    value={formik.values.social_links?.twitter_url}
                    onChange={formik.handleChange}
                    placeholder='Twitter username'
                />

                <SocialItem 
                    icon={SnapchatIcon}
                    value={formik.values.social_links?.snapchat_url}
                    onChange={formik.handleChange}
                    placeholder='Snapchat username'
                />

                <SocialItem 
                    icon={TikTokIcon}
                    value={formik.values.social_links?.tiktok_url}
                    onChange={formik.handleChange}
                    placeholder='Tiktok username'
                />

                <SocialItem 
                    icon={LinkedInIcon}
                    value={formik.values.social_links?.linkedin_url}
                    onChange={formik.handleChange}
                    placeholder='LinkedIn username'
                />

                <SocialItem 
                    icon={YoutubeIcon}
                    value={formik.values.social_links?.youtube_url}
                    onChange={formik.handleChange}
                    placeholder='Youtube username'
                />

                <SocialItem 
                    icon={PinterestIcon}
                    value={formik.values.social_links?.pinterest_url}
                    onChange={formik.handleChange}
                    placeholder='Pinterest username'
                />
            </div>
        </div>
    )
}

export default SocialMedia;

const SocialItem = ({icon, value, onChange, placeholder}) => {
    return (
        
        <div className='tw-flex tw-gap-4 tw-items-center'>
            <span className='tw-w-9 tw-h-9 tw-rounded-md border tw-border-gray tw-flex tw-items-center tw-justify-center'>
                <img src={icon} className='tw-max-w-full tw-h-full tw-rounded-md' />
            </span>
            <GenericTextFieldWithLabel
                containerStyle={{
                    width: '80%'
                }}
                placeholder={placeholder}
                size='small'
                id="social_links.facebook_url"
                name="social_links.facebook_url"
                value={value}
                onChange={onChange}
                fullWidth={true}
            />
        </div>
    )
}