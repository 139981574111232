import { Box, Drawer, drawerClasses, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import React, { useContext, useEffect, useRef } from 'react'
import { useState } from 'react'
import WebsiteEditorContext from '../../../utils/context/websiteEditorContext'
import CategorySelection from '../CategorySelection/CategorySelection'
import { ArrowRight, Close, Title, YouTube } from '@mui/icons-material'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import imageBaseUrl from '../../../utils/imageBaseUrl'
import { getInitialsAvatar } from '../Util/helpers'
import AppointmentIcon from '../CustomPageBuilder/Library/Icons/AppointmentIcon'
import styles from './LeftDrawer.module.scss'
import WelcomeIcon from '../CustomPageBuilder/Library/Icons/WelcomeIcon'
import AboutIcon from '../CustomPageBuilder/Library/Icons/AboutIcon'
import ImageGalerryIcon from '../CustomPageBuilder/Library/Icons/ImageGalerryIcon'
import TextBlocksIcon from '../CustomPageBuilder/Library/Icons/TextBlocksIcon'
import ContactIcon from '../CustomPageBuilder/Library/Icons/ContactIcon'
import FeatureIcon from '../CustomPageBuilder/Library/Icons/FeatureIcon'
import PromotionIcon from '../CustomPageBuilder/Library/Icons/PromotionIcon'
import ServicesIcon from '../CustomPageBuilder/Library/Icons/ServicesIcon'
import TestimonialsIcon from '../CustomPageBuilder/Library/Icons/TestimonialsIcon'
import FaqIcon from '../CustomPageBuilder/Library/Icons/FaqIcon'
import PortfolioIcon from '../CustomPageBuilder/Library/Icons/PortfolioIcon'
import SocialIcon from '../CustomPageBuilder/Library/Icons/SocialIcon'
import PricingIcon from '../CustomPageBuilder/Library/Icons/PricingIcon'
import EsplandaElementsIcon from '../CustomPageBuilder/Library/Icons/EsplandaElementsIcon'
import FormsIcon from '../CustomPageBuilder/Library/Icons/FormsIcon'

const menu1Items = [
    {
        id: 'All',
        label: 'Block Designs',
        icon: (
            <svg
                style={{
                    width: 24
                }}
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16"
                enable-background="new 0 0 24 16"
            >
                <rect fill="#FFFFFF" width="24" height="16"></rect>
                <path d="M23,1v14H1V1H23 M24,0H0v16h24V0L24,0z"></path>
            </svg>
        )
    }
]

const thumbnail = (name) => {
    return `
     <div class="gjs_label_main_border" > <div class="">${name}</div></div>
    `
}

const LeftDrawer = ({
    open,
    onClose
}) => {

    const drawerRef = useRef()
    const tabPanelBlockRef = useRef()
    const { editor, blockCategories } = useContext(WebsiteEditorContext)

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [cat, setCat] = useState('')
    const [forceUpdate, setForceUpdate] = useState(false)
    const [menuItems, setMenuItems] = useState([])

    useEffect(() => {
        if (!open) {
            setAnchorEl(null)
            setAnchorEl2(null)
            setAnchorEl3(null)
            setCat('')
            setForceUpdate((prevState) => !prevState)
            setMenuItems([])
        }
    }, [open])



    const open1 = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);

    const handleClick = (event, isEvent) => {
        if (isEvent) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(event);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick2 = (event, isEvent) => {
        if (isEvent) {
            setAnchorEl2(event.currentTarget);
        } else {
            setAnchorEl2(event);
        }
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClick3 = (event, isEvent) => {
        if (isEvent) {
            setAnchorEl3(event.currentTarget);
        } else {
            setAnchorEl3(event);
        }
    };
    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    const pblockFn1 = (block, editor, id) => {
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
            const selectedComponentIndex = selectedComponent.index()

            return editor.getWrapper().append({ ...block.getContent(), groupId: id }, { at: selectedComponentIndex + 1 })
        } else {
            return editor.getWrapper().append({ ...block.getContent(), groupId: id })
        }
    }

    const ablockFn1 = (block, editor, id) => {
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
            const selectedComponentIndex = selectedComponent.index()
            return editor.getWrapper().append({ ...block.getContent(), appt_id: id }, { at: selectedComponentIndex + 1 })
        } else {
            return editor.getWrapper().append({ ...block.getContent(), appt_id: id })
        }
    }
    const pblockFn2 = (block, editor, id) => {
        return editor.getWrapper().append({ ...block.getContent(), groupId: id }, { at: 0 })
    }

    const ablockFn2 = (block, editor, id) => {
        return editor.getWrapper().append({ ...block.getContent(), appt_id: id }, { at: 0 })
    }


    const fetchCollection = (selectedCat) => {
        axios.get('/venue-product-group/list', {
            params: {
                product_group_type: selectedCat
            }
        })
            .then((res) => {

                const collection = res.data?.data?.result?.map((data, index) => {
                    let type
                    switch (selectedCat) {
                        case 'TagProductList':
                            type = 'esplanda-product-card-group'
                            break;
                        case 'ProductList':
                            type = 'esplanda-product-card-group'
                            break;
                        case 'CategoryList':
                            type = 'esplanda-category-card-group'
                            break;
                    }

                    let block = editor.BlockManager.get(type)

                    return ({
                        ...block,
                        id: `${type}-${index}`,
                        // label: data.productGroupName,
                        media: thumbnail(`${data.productGroupName}`),
                        // onClick: props.isAddSectionUp ? (bl, editor) => pblockFn2(block, editor, data.productGroupId) : (bl, editor) => pblockFn1(block, editor, data.productGroupId),
                        onClick: (bl, editor) => pblockFn1(block, editor, data.productGroupId)
                    })
                })
                const newBlocksEl = editor.BlockManager.render(collection)
                tabPanelBlockRef.current?.appendChild(newBlocksEl);
            })
    }

    const fetchAppoinments = () => {
        nodeAxios.get('/products/appt/list')
            .then((res) => {

                const collection = res.data?.data?.map((data, index) => {
                    let type = 'appt-product-details'

                    let block = editor.BlockManager.get(type)

                    return ({
                        ...block,
                        id: `${type}-${index}`,
                        // label: data.productGroupName,
                        media: `
                  <div class="tw-flex tw-justify-between tw-items-start tw-p-3 tw-border tw-border-gray tw-text-black tw-rounded-md tw-text-base tw-font-medium" data-bs-toggle="tooltip" title=''>
                    <div class="tw-flex">
                      ${data?.image && (
                                `<img 
                          class="tw-w-36 tw-h-40 tw-rounded-md tw-mr-2 tw-object-cover" 
                          src="${`${imageBaseUrl}/ApptProduct/${data.id}/${data.image}`}" 
                          onerror="this.src='${getInitialsAvatar(data.name)}'"
                        />`
                            )}
                      <div class="tw-font-semibold">
                        ${data.name}
                      </div>
                    </div>
                    <div class="tw-bg-black tw-px-6 tw-py-1.5 tw-text-white tw-rounded-3xl tw-text-sm">Select</div>
                  </div>
                `,
                    })
                })

                const newBlocksEl = editor.BlockManager.render(collection)
                tabPanelBlockRef.current?.appendChild(newBlocksEl);

            })
    }

    const fetchForms = (blocks) => {
        axios.get('/web-builder/web-page-form')
            .then((res) => {

                const collection = res.data?.data?.results?.map((data, index) => {

                    let block = editor.BlockManager.get('esplandaformsreact')

                    return ({
                        ...block,
                        id: `esplandaformsreact-${index}`,
                        label: data.title
                    })
                })

                const newBlocksEl = props.editor.BlockManager.render([...blocks, ...collection])
                tabPanelBlockRef.current?.appendChild(newBlocksEl);

            })
            .catch(() => {
                const newBlocksEl = editor.BlockManager.render(blocks)
                tabPanelBlockRef.current?.appendChild(newBlocksEl);
            })
    }

    const menu2Items = [
        {
            id: 'TagProductList',
            label: 'Custom Collections',
            onClick: fetchCollection,
            icon: (
                <svg
                    style={{
                        width: 24
                    }}
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16"
                    enable-background="new 0 0 24 16"
                >
                    <rect fill="#FFFFFF" width="24" height="16"></rect>
                    <path d="M23,1v14H1V1H23 M24,0H0v16h24V0L24,0z"></path>
                </svg>
            )
        },
        {
            id: 'ProductList',
            label: 'Product Collection',
            onClick: fetchCollection,
            icon: (
                <svg
                    style={{
                        width: 24
                    }}
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16"
                    enable-background="new 0 0 24 16"
                >
                    <rect fill="#FFFFFF" width="24" height="16"></rect>
                    <path d="M23,1v14H1V1H23 M24,0H0v16h24V0L24,0z"></path>
                </svg>
            )
        },
        {
            id: 'CategoryList',
            label: 'Category Collection',
            onClick: fetchCollection,
            icon: (
                <svg
                    style={{
                        width: 24
                    }}
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16"
                    enable-background="new 0 0 24 16"
                >
                    <rect fill="#FFFFFF" width="24" height="16"></rect>
                    <path d="M23,1v14H1V1H23 M24,0H0v16h24V0L24,0z"></path>
                </svg>
            )
        },
    ]

    const firstSection = [
        {
            id: 'Grid',
            label: 'Grid',
            icon: (
                <svg style={{ width: 24 }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16" enable-background="new 0 0 24 16">
                    <rect fill="#FFFFFF" width="24" height="16"></rect>
                    <path d="M23,0.9V15H1V0.9 M24,0H0v16h24V0L24,0z"></path>
                    <rect x="11" y="0" width="1" height="16"></rect>
                    <rect x="11" y="7" width="13" height="1"></rect>
                </svg>
            )
        },
        {
            id: 'Image',
            label: 'Image',
            icon: (
                <svg style={{ width: 24 }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16" enable-background="new 0 0 24 16">
                    <path fill="#4399FA" d="M23,0H1v16h22V0L23,0z"></path>
                    <path fill="#62D376" d="M10.2,11.5c-3.6-2.6-7.5-5-9.2-5V16h4.6C6.9,14.8,8.6,13.1,10.2,11.5z"></path>
                    <path fill="#A5F3B3" d="M14.3,6C12.9,5.7,11,7,11,7c-0.9,0.4-3,2.4-5.2,4.5c-1.7,1.6-3.3,3.2-4.6,4.5h10.4H23v-2.8
	C23,13.2,17,6.6,14.3,6z"></path>
                    <circle fill="#FFF200" cx="7.3" cy="4.5" r="2.5"></circle>
                </svg>
            )
        },
        {
            id: 'Text',
            label: 'Text',
            icon: (
                <svg style={{ width: 24 }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16">
                    <polygon points="5,1 5,3 11,3 11,15 13,15 13,3 19,3 19,1 "></polygon>
                </svg>
            )
        },
        {
            id: 'Audio',
            label: 'Audio',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="tw-size-6 tw-text-[#ea6052]">
                    <path fillRule="evenodd" d="M19.952 1.651a.75.75 0 0 1 .298.599V16.303a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122Z" clipRule="evenodd" />
                </svg>

            )
        },
        {
            id: 'Video',
            label: 'Video',
            icon: (
                <YouTube
                    style={{
                        color: '#ea6052'
                    }}
                />
            )
        },
        {
            id: 'Forms',
            label: 'Forms',
            icon: (
                <FormsIcon />
            )
        },
        {
            id: 'HTML',
            label: 'HTML',
            icon: (
                <svg style={{ width: 24 }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16" enable-background="new 0 0 24 16">
                    <path fill="#EA6052" d="M6,14l6,2l6-2l1-14H5L6,14z M9.5,4.8l0.2,1.8h6.5L15.7,12L12,13l-3.7-1L8.1,9.2h1.8l0.1,1.4l2,0.5l2-0.5l0.2-2.3H8L7.5,3h9l-0.2,1.8H9.5z"></path>
                </svg>
            )
        },
        {
            id: 'Modalpopup',
            label: 'Modal popup',
            icon: (
                <svg viewBox="0 0 24 16" width="24px" height="16px">
                    <path fill="gray" d="M24,0H0v16h24V0L24,0z"></path>
                    <path fill="#555555" d="M20,4H6v10h14V4L20,4z"></path>
                    <path fill="#FFFFFF" d="M19,3H5v10h14V3L19,3z"></path>
                    <path d="M17,6H7V5h10V6z M17,7H7v1h10V7z"></path>
                    <rect x="9" y="9" fill="#4399FA" width="6" height="2.1"></rect>
                </svg>
            )
        },
        {
            id: 'Slider',
            label: 'Slider',
            icon: (
                <svg viewBox="0 0 24 16" width="24px" height="16px">
                    <path fill="#4399FA" d="M4,10.3L2.3,7.9l1.6-2.2L3,5L1,7.9L3,11L4,10.3z"></path>
                    <path fill="#4399FA" d="M21,11l2-3.1L21,5l-0.9,0.7l1.6,2.2L20,10.3L21,11z"></path>
                    <path fill="#4399FA" d="M18,1H6v8h12V1L18,1z"></path>
                    <path d="M6,10h12v1H6V10z M6,12h12v1H6V12z M6,14h10v1H6V14z"></path>
                </svg>
            )
        },
        {
            id: 'Button',
            label: 'Button',
            icon: (
                <svg style={{ width: 24 }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16" enable-background="new 0 0 24 16">
                    <path fill="#4399FA" d="M20,16H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h16c2.2,0,4,1.8,4,4v8C24,14.2,22.2,16,20,16z"></path>
                    <g>
                        <path fill="#FFFFFF" d="M4.5,10.7h3.2V12H3V4.4h1.5V10.7z"></path>
                        <path fill="#FFFFFF" d="M8.5,4.9c0-0.2,0.1-0.4,0.2-0.6C8.8,4.2,9,4.1,9.3,4.1c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.6
		c0,0.2-0.1,0.4-0.2,0.6C9.7,5.6,9.5,5.7,9.3,5.7S8.8,5.6,8.7,5.5C8.5,5.3,8.5,5.1,8.5,4.9z M10,12H8.6V6.4H10V12z"></path>
                        <path fill="#FFFFFF" d="M12.5,6.4l0,0.7c0.4-0.5,0.9-0.8,1.6-0.8c0.6,0,1,0.2,1.3,0.5c0.3,0.4,0.4,0.9,0.4,1.6V12h-1.5V8.4
		c0-0.3-0.1-0.6-0.2-0.7s-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.2-0.9,0.5v4h-1.5V6.4H12.5z"></path>
                        <path fill="#FFFFFF" d="M18.9,9.7l-0.5,0.5V12H17V4h1.5v4.4l0.3-0.4l1.4-1.7h1.7l-2,2.3L22,12h-1.7L18.9,9.7z"></path>
                    </g>
                </svg>
            )
        },
        {
            id: 'Table',
            label: 'Table',
            icon: (
                <svg viewBox="0 0 24 16" width="24px" height="16px">
                    <g>
                        <rect x="1" fill="#FFFFFF" width="22" height="16"></rect>
                        <rect x="1" fill="#C2D8E7" width="22" height="6"></rect>
                        <path d="M22,1v14H2V1 M23,0H1v16h22V0L23,0z"></path>
                        <path d="M1,5h22v1H1V5z M1,10h22v1H1V10z M8,0h1v16H8V0z M15,0h1v16h-1V0z"></path>
                    </g>
                </svg>
            )
        },
        {
            id: 'Socialicons',
            label: 'Social Icons',
            icon: (
                <svg viewBox="0 0 24 16" width="24px" height="16px">
                    <circle fill="#3B5998" cx="5.5" cy="10.5" r="5.5"></circle>
                    <path fill="#FFFFFF" d="M6.8,10.9H5.6V15H3.8v-4.1H3V9.5h0.8V8.7C3.8,8.1,4.2,7,5.7,7H7v1.4H6c-0.1,0-0.4,0.1-0.4,0.4v0.7h1.3
	L6.8,10.9z"></path>
                    <circle fill="#111111" cx="12" cy="5.5" r="5.5"></circle>
                    <path fill="#FFFFFF" d="M13.1,4.9L15.6,2h-1l-1.9,2.3L11.2,2H8.7l2.6,3.9L8.7,9h0.9l2.1-2.4L13.2,9h2.5L13.1,4.9z M12.1,6l-0.4-0.6
	L9.9,2.8h0.9l1.3,2l0.4,0.6l1.8,2.8h-0.8L12.1,6z"></path>
                    <circle fill="#C837AB" cx="18.5" cy="10.5" r="5.5"></circle>
                    <path fill="#FFFFFF" d="M20.2,7h-3.4c-1,0-1.8,0.9-1.8,1.8v3.4c0,1,0.9,1.8,1.8,1.8h3.4c1,0,1.8-0.9,1.8-1.8V8.8
	C22,7.8,21.1,7,20.2,7z M18.6,12.3c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9c1,0,1.9,0.8,1.9,1.9S19.6,12.3,18.6,12.3z M20.8,8.9
	c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S21,8.9,20.8,8.9z"></path>
                </svg>
            )
        },
        {
            id: 'Googlemaps',
            label: 'Google maps',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="tw-size-6 tw-text-[#ea6052]">
                    <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                </svg>

            )
        },
        {
            id: 'Icon',
            label: 'Icon',
            icon: (
                <svg style={{ width: 24 }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16" enable-background="new 0 0 24 16">
                    <path fill="#4399FA" d="M16.1,16c-0.2,0-0.4-0.1-0.6-0.2L12,13.9l-3.5,1.9c-0.4,0.2-1,0.2-1.4-0.1s-0.6-0.8-0.5-1.3l0.7-4.1L4.4,7.4
                    C4,7,3.9,6.5,4.1,6s0.6-0.8,1-0.9l4-0.6l1.8-3.7C11.1,0.3,11.5,0,12,0s0.9,0.3,1.2,0.8L15,4.5l4,0.6c0.5,0.1,0.9,0.4,1,0.9
                    c0.2,0.5,0,1-0.3,1.4l-2.9,2.9l0.7,4.1c0.1,0.5-0.1,1-0.5,1.3C16.7,15.9,16.4,16,16.1,16z M6,6.7l2.5,2.5C8.8,9.5,9,10,8.9,10.4
                    L8.3,14l3.1-1.7c0.4-0.2,0.8-0.2,1.2,0l3.1,1.7l-0.6-3.6C15,10,15.2,9.5,15.5,9.2L18,6.7l-3.5-0.5c-0.4-0.1-0.8-0.3-1-0.7L12,2.1
                    l-1.5,3.3c-0.2,0.4-0.6,0.7-1,0.7L6,6.7z M11.7,1.5L11.7,1.5L11.7,1.5z"></path>
                </svg>
            )
        },
        {
            id: 'Shape',
            label: 'Shape',
            icon: (
                <svg style={{ width: 24 }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="16px" viewBox="0 0 24 16" enable-background="new 0 0 24 16">
                    <path fill="#4399FA" d="M0,5v11h17V5H0z"></path>
                    <path fill="none" d="M18,11.5c2.2-0.4,4-2.4,4-4.7C22,4.2,19.8,2,17.2,2c-1.7,0-3.1,0.8-4,2H18V11.5z"></path>
                    <path d="M24,6.8C24,3.1,20.9,0,17.2,0C14.4,0,12,1.6,11,4h2.3c0.9-1.2,2.3-2,4-2C19.8,2,22,4.2,22,6.8
	c0,2.3-1.8,4.3-4,4.7v2C21.4,13.1,24,10.2,24,6.8z"></path>
                </svg>
            )
        }
    ]

    const navigationSection = [
        {
            id: 'Header',
            label: 'Header',
            icon: (
                <svg className="tw-size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="header"><g><path fill="#dadde2" d="M5,21H19a2,2,0,0,0,2-2V7H3V19A2,2,0,0,0,5,21Z"></path><path fill="#55abff" d="M5,3H19a2,2,0,0,1,2,2V7a0,0,0,0,1,0,0H3A0,0,0,0,1,3,7V5A2,2,0,0,1,5,3Z"></path><path fill="#0078ee" d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM4,5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1V6H4ZM20,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8H20Z"></path></g></svg>
            )
        },
        {
            id: 'Footer',
            label: 'Footer',
            icon: (
                <svg className="tw-size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="footer"><g><path fill="#dadde2" d="M19,3H5A2,2,0,0,0,3,5V17H21V5A2,2,0,0,0,19,3Z"></path><path fill="#55abff" d="M5,17H19a2,2,0,0,1,2,2v2a0,0,0,0,1,0,0H3a0,0,0,0,1,0,0V19A2,2,0,0,1,5,17Z" transform="rotate(180 12 19)"></path><path fill="#0078ee" d="M5,22H19a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V19A3,3,0,0,0,5,22Zm15-3a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V18H20ZM4,5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1V16H4Z"></path></g></svg>
            )
        },
    ]

    const blocksSection = [
        {
            id: 'Welcome',
            label: 'Welcome',
            icon: (
                <WelcomeIcon />
            )
        },
        {
            id: 'About',
            label: 'About',
            icon: (
                <AboutIcon />
            )
        },
        {
            id: 'Imagegallery',
            label: 'Image Gallery',
            icon: (
                <ImageGalerryIcon />
            )
        },
        {
            id: 'Textblocks',
            label: 'Text Blocks',
            icon: (
                <TextBlocksIcon />
            )
        },
        {
            id: 'Contact',
            label: 'Contact',
            icon: (
                <ContactIcon />
            )
        },
        {
            id: 'Feature',
            label: 'Feature',
            icon: (
                <FeatureIcon />
            )
        },
        {
            id: 'Promotion',
            label: 'Promotion',
            icon: (
                <PromotionIcon />
            )
        },
        {
            id: 'Services',
            label: 'Services',
            icon: (
                <ServicesIcon />
            )
        },
        {
            id: 'Testimonials',
            label: 'Testimonials',
            icon: (
                <TestimonialsIcon />
            )
        },
        {
            id: 'FAQ',
            label: 'FAQ',
            icon: (
                <FaqIcon />
            )
        },
        {
            id: 'Portfolio',
            label: 'Portfolio',
            icon: (
                <PortfolioIcon />
            )
        },
        {
            id: 'Social',
            label: 'Social',
            icon: (
                <SocialIcon />
            )
        },
        {
            id: 'Pricing',
            label: 'Pricing',
            icon: (
                <PricingIcon />
            )
        },
        {
            id: 'Esplandaelements',
            label: 'Esplanda Elements',
            icon: (
                <EsplandaElementsIcon />
            )
        },
    ]


    const advancedSection = [
        {
            id: 'Appointments',
            label: 'Appointments',
            onClick: fetchAppoinments,
            icon: (
                <AppointmentIcon />
            )
        },
        {
            id: 'Menu',
            label: 'Restaurant Menus',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            )
        },
        {
            id: 'Ecommerce',
            label: 'Ecommerce',
            hasChild: true,
            child: menu2Items,
            icon: (
                <svg className="tw-size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="footer"><g><path fill="#dadde2" d="M19,3H5A2,2,0,0,0,3,5V17H21V5A2,2,0,0,0,19,3Z"></path><path fill="#55abff" d="M5,17H19a2,2,0,0,1,2,2v2a0,0,0,0,1,0,0H3a0,0,0,0,1,0,0V19A2,2,0,0,1,5,17Z" transform="rotate(180 12 19)"></path><path fill="#0078ee" d="M5,22H19a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V19A3,3,0,0,0,5,22Zm15-3a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V18H20ZM4,5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1V16H4Z"></path></g></svg>
            )
        },
    ]


    useEffect(() => {
        if (editor && cat) {
            console.log({ cat })
            console.log(blockCategories)


            if (cat == 'All') {
                let mergedBlocks = []
                for (let [key, value] of blockCategories) {
                    mergedBlocks = [...mergedBlocks, ...value.blocks]
                }
                const newBlocksEl = editor.BlockManager.render(mergedBlocks)
                tabPanelBlockRef.current?.appendChild(newBlocksEl);
            } else {
                const block = blockCategories.get(cat)
                console.log(block)
                const newBlocksEl = editor.BlockManager.render(block?.blocks)
                tabPanelBlockRef.current?.appendChild(newBlocksEl);
            }
        }
    }, [cat, editor, forceUpdate])

    return (
        <div
            className="tw-z-[1]"
        >
            <Drawer
                open={open}
                onClose={onClose}
                hideBackdrop={true}
                PaperProps={{
                    className: styles.drawer_container
                }}
                sx={{
                    width: '250px',
                    top: '45px',
                    [`& .${drawerClasses.paper}`]: {
                        top: '45px'
                    }
                }}
            >

                <Box
                    ref={drawerRef}
                    // onClick={(event) => {
                    //     if (data.id == 'blocks') {
                    //         handleClick(event)
                    //     } else {
                    //         handleClick2(event)
                    //     }
                    // }}
                    sx={{ width: 250, paddingBottom: '80px' }}
                    role="presentation"
                >
                    <div
                        className="tw-flex tw-justify-end"
                    >
                        <IconButton
                            className="tw-bg-white tw-rounded-full"
                            onClick={onClose}
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div
                        className="tw-scrollbar-thumb-rounded-full tw-scrollbar-track-rounded-full tw-scrollbar tw-scrollbar-thumb-slate-700 tw-scrollbar-track-slate-300 tw-overflow-y-auto"
                    >
                        <List disablePadding>
                            <div className="tw-dui-divider tw-my-0">

                            </div>
                            {firstSection.map((data) => (
                                <ListItem
                                    style={{ height: '30px' }}
                                    key={data.label}
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            if (data.hasChild) {
                                                handleClick(drawerRef.current)
                                                setMenuItems(data.child)
                                            } else {
                                                setCat(data.id)
                                                setForceUpdate((prevState) => prevState)
                                                handleClick2(drawerRef.current)
                                            }
                                            if (data?.onClick) {
                                                data.onClick(data.id)
                                            }
                                        }}
                                        sx={{
                                            height: '100%',
                                            padding: '5px 10px'
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: '34px'
                                            }}
                                        >
                                            {data.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={data.label} />
                                        <ArrowRight style={{ color: '#898989' }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}

                            <div className="tw-dui-divider tw-mb-0">
                                Navigation
                            </div>
                            {navigationSection.map((data) => (
                                <ListItem
                                    style={{ height: '30px' }}
                                    key={data.label}
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            if (data.hasChild) {
                                                handleClick(drawerRef.current)
                                                setMenuItems(data.child)
                                            } else {
                                                setCat(data.id)
                                                setForceUpdate((prevState) => prevState)
                                                handleClick2(drawerRef.current)
                                            }
                                            if (data?.onClick) {
                                                data.onClick(data.id)
                                            }
                                        }}
                                        sx={{
                                            height: '100%',
                                            padding: '5px 10px'
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: '34px'
                                            }}
                                        >
                                            {data.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={data.label} />
                                        <ArrowRight style={{ color: '#898989' }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <div className="tw-dui-divider tw-mb-0">
                                Blocks
                            </div>
                            {blocksSection.map((data) => (
                                <ListItem
                                    style={{ height: '30px' }}
                                    key={data.label}
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            if (data.hasChild) {
                                                handleClick(drawerRef.current)
                                                setMenuItems(data.child)
                                            } else {
                                                setCat(data.id)
                                                setForceUpdate((prevState) => prevState)
                                                handleClick2(drawerRef.current)
                                            }
                                            if (data?.onClick) {
                                                data.onClick(data.id)
                                            }
                                        }}
                                        sx={{
                                            height: '100%',
                                            padding: '5px 10px'
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: '34px'
                                            }}
                                        >
                                            {data.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={data.label} />
                                        <ArrowRight style={{ color: '#898989' }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <div className="tw-dui-divider tw-mb-0">
                                Advanced
                            </div>
                            {advancedSection.map((data) => (
                                <ListItem
                                    style={{ height: '30px' }}
                                    key={data.label}
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            if (data.hasChild) {
                                                handleClick(drawerRef.current)
                                                setMenuItems(data.child)
                                            } else {
                                                setCat(data.id)
                                                setForceUpdate((prevState) => prevState)
                                                handleClick2(drawerRef.current)
                                            }
                                            if (data?.onClick) {
                                                data.onClick(data.id)
                                            }
                                        }}
                                        sx={{
                                            height: '100%',
                                            padding: '5px 10px'
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: '34px'
                                            }}
                                        >
                                            {data.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={data.label} />
                                        <ArrowRight style={{ color: '#898989' }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        horizontal: "right"
                    }}
                    open={open1}
                    onClose={handleClose}
                >
                    <Box
                        onClick={(event) => handleClick3(event, true)}
                        sx={{ width: 250 }}
                        role="presentation"
                    >
                        {menuItems.map((data) => (
                            <MenuItem
                                key={data.id}
                                onClick={() => {
                                    setCat(data.id)
                                    setForceUpdate((prevState) => prevState)
                                    if (data?.onClick) {
                                        data.onClick(data.id)
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    {data.icon}
                                </ListItemIcon>
                                <ListItemText>
                                    {data.label}
                                </ListItemText>
                            </MenuItem>
                        ))}
                    </Box>

                    <Menu
                        anchorEl={anchorEl3}
                        slotProps={{
                            paper: {
                                sx: {
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                        height: '8px'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#C3C3C3',
                                        borderRadius: '10px'
                                    }
                                }
                            }
                        }}
                        anchorOrigin={{
                            horizontal: "right"
                        }}
                        open={open3}
                        onClose={handleClose3}
                        keepMounted={true}
                    >
                        <Box
                            sx={{ width: 320 }}
                            role="presentation"
                        >
                            {/* <CategorySelection
                                cat={cat}
                                setCat={setCat}
                            /> */}
                            <div
                                ref={tabPanelBlockRef}
                            />
                        </Box>
                    </Menu>
                </Menu>

                <Menu
                    anchorEl={anchorEl2}
                    slotProps={{
                        paper: {
                            sx: {
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                    height: '8px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#C3C3C3',
                                    borderRadius: '10px'
                                }
                            }
                        }
                    }}
                    anchorOrigin={{
                        horizontal: "right"
                    }}
                    open={open2}
                    onClose={handleClose2}
                    keepMounted={true}
                >
                    <Box
                        sx={{ width: 320 }}
                        role="presentation"
                    >

                        {/* <CategorySelection
                            cat={cat}
                            setCat={setCat}
                        /> */}
                        <div
                            ref={tabPanelBlockRef}
                        />
                    </Box>
                </Menu>
            </Drawer>
        </div >
    )
}

export default LeftDrawer