const initialState = {
    isLoading: false,
    giftCardList: {},
    giftCardListError: "",
    giftCardDetail: {},
    giftCardDetailError: "",
    giftCardDetailsUpdate: {},
    giftCardDetailsUpdateError: "",
    giftCardDelete: {},
    giftCardDeleteError: "",
    giftCardHistory: {},
    giftCardHistoryError: ""
};
export default function giftCard(state = initialState, action) {
    switch (action.type) {
        case "SET_GIFT_CARD_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_GIFT_CARD_LIST_LOADED":
            return { ...initialState, isLoading: false, giftCardList: action.payload };
        case "SET_GIFT_CARD_LIST_ERROR":
            return { ...initialState, giftCardListError: action.payload };
        case "ADD_GIFT_CARD_LOADING":
            return { ...initialState, isLoading: true };
        case "ADD_GIFT_CARD_LOADED":
            return { ...initialState, isLoading: false, giftCardDetail: action.payload };
        case "ADD_GIFT_CARD_ERROR":
            return { ...initialState, giftCardDetailError: action.payload };
        case "SET_GIFT_CARD_DETAILS_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_GIFT_CARD_DETAILS_LOADED":
            return { ...initialState, isLoading: false, giftCardDetailsUpdate: action.payload };
        case "SET_GIFT_CARD_DETAILS_ERROR":
            return { ...initialState, giftCardDetailsUpdateError: action.payload };
        case "DELETE_GIFT_CARD_LOADING":
            return { ...initialState, isLoading: true };
        case "DELETE_GIFT_CARD_LOADED":
            return { ...initialState, isLoading: false, giftCardDelete: action.payload };
        case "DELETE_GIFT_CARD_ERROR":
            return { ...initialState, giftCardDeleteError: action.payload };
        case "SET_GIFT_CARD_HISTORY_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_GIFT_CARD_HISTORY_LOADED":
            return { ...initialState, isLoading: false, giftCardHistory: action.payload };
        case "SET_GIFT_CARD_HISTORY_ERROR":
            return { ...initialState, giftCardHistoryError: action.payload };
        default:
            return state;
    }
};