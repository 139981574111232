import React, { useState } from 'react'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import { useFormik } from 'formik'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import { Button } from '@mui/material'
import styles from './Clover.module.scss'
import { getImage2 } from '../../../utils/helpers'

const Clover = ({ cloverCred, handleDisconnect }) => {

    const [isLoading, setIsLoading] = useState(false)

    const handleCloverConnect = () => {
        setIsLoading(true)
        nodeAxios.get('/clover/oauth-url', {
            params: {
                redirect_uri: `${window.location.href}?from=clover`
            }
        })
            .then((res) => {
                window.open(res.data?.data, '_self')
            })
    }

    const handlePullInventory = () => {
        setIsLoading(true)
        nodeAxios.get('/clover/pull-inventory')
            .then((res) => {
                toast.success('Your inventory is being synced from clover, You will recieve an email when sync is completed')
            })
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 10
            }}
        >

            <div
                className={styles.item_box}
            >
                {Boolean(cloverCred) && (
                    <div
                        style={{
                            color: '#00BF36',
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            fontWeight: 600
                        }}
                    >
                        Connected
                    </div>
                )}
                <div
                    className={styles.item_box_text}
                >
                    <img
                        src={getImage2('clover.png-1708525997.png')}
                        width={80}
                        height={50}
                    />

                    <div
                        className={styles.item_description}
                    >
                        {/* Launch and scale embedded payments and financial services */}
                    </div>
                </div>
                <GenericLoadingButton
                    style={{
                        backgroundColor: !Boolean(cloverCred) ? '#0B9DE4' : '#FF5151',
                    }}
                    variant='contained'
                    // loading={isStripeLoading}
                    loadingPosition='end'
                    size='medium'
                    // disabled={Boolean(venueDetails?.stripe_connect_status == 2) && (paymentMethod.type_id == 1)}
                    onClick={() => {
                        if (Boolean(cloverCred)) {
                            handleDisconnect()
                        } else {
                            handleCloverConnect()
                        }
                        // handleCloverConnect()
                    }}
                >
                    {Boolean(cloverCred) ? "Click to disconnnect" : "Click to setup"}
                </GenericLoadingButton>

                {Boolean(cloverCred) && (
                    <div
                        className="tw-mt-5"
                    >
                        <GenericLoadingButton
                            style={{
                                backgroundColor:'#0B9DE4',
                            }}
                            variant='contained'
                            // loading={isStripeLoading}
                            loadingPosition='end'
                            size='medium'
                            // disabled={Boolean(venueDetails?.stripe_connect_status == 2) && (paymentMethod.type_id == 1)}
                            onClick={handlePullInventory}
                        >
                            Sync Inventory
                        </GenericLoadingButton>
                    </div>
                )}

                {/* {!props.isStripeSucces && (router.query?.from == 'stripe') && (
                        <div
                            style={{
                                textAlign: 'center',
                                color: '#ef2d2c'
                            }}
                        >
                            There is some more information needed from you to verify your account. Please login to <a style={{ color: 'blue' }} href="https://dashboard.stripe.com" target="_blank">Stripe</a> to verify your account
                        </div>
                    )} */}
            </div>

            {/* <div
                style={{ 
                    display: 'flex',
                    gap: 10
                }}
            >
                <GenericTextFieldWithLabel
                    label="Mid"
                    id="mid"
                    name="mid"
                    value={formik.values.mid}
                    onChange={formik.handleChange}
                />

                <GenericTextFieldWithLabel
                    label="Api token"
                    id="api_token"
                    name="api_token"
                    value={formik.values.api_token}
                    onChange={formik.handleChange}
                />
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <GenericLoadingButton
                    variant="contained"
                    type="button"
                    onClick={formik.handleSubmit}
                    size="large"
                    loading={isLoading}
                    loadingPosition="end"
                >
                    Save
                </GenericLoadingButton>
            </div> */}
        </div>
    )
}

export default Clover