import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import GenericButton from '../Library/GenericButton/GenericButton'
import Branding from './Branding/Branding'
import BusinessHours from './OpenHours/BusinessHours'
import styles from './StoreInfo.module.scss'
import StoreInformation from './StoreInformation/StoreInformation'
import axios from '../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'
import Loader from '../Library/Loader'
import { Button, Dialog, IconButton } from '@mui/material'
import { Add, DeleteForever, Edit } from '@mui/icons-material'
import AddHoliday from './AddHoliday/AddHoliday'

const StoreInfo = () => {

    const { venueDetails, pickupVenueHours, openVenueHours, fetchStoreInfo, removeImage, removeFavicon, isLoading, isActionsDisabled } = useOutletContext()
    const [isEditMode, setIsEditMode] = useState(false)
    const [showAddHoliday, setShowAddHoliday] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imageFile, setImageFile] = useState()
    const [faviconFile, setFaviconFile] = useState()
    const [noLogo, setNoLogo] = useState(!Boolean(venueDetails.image))
    const [holidays, setHolidays] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editObj, setEditObj] = useState({})

    const fetchHoliday = () => {
        axios.get('/venue-holiday')
            .then((res) => {
                setHolidays(res.data?.data?.results)
            })
    }

    useEffect(() => {
        setNoLogo(!Boolean(venueDetails.image))
        fetchHoliday()
    }, [venueDetails])


    const formik = useFormik({
        initialValues: {
            ...venueDetails,
            venue_hours: Boolean(openVenueHours.length) ? openVenueHours : [
                {
                    venuetimings: 'open',
                    dayname: 'Sunday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Monday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Tuesday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Wednesday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Thursday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Friday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Saturday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                }
            ]
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)

            axios.post('/venue/setting', values)
                .then(() => {
                    toast.success('Saved Successfully')
                    setIsEditMode(false)
                })
                .finally(() => {
                    setLoading(false)
                    fetchStoreInfo()
                })

            let formData = new FormData();
            if (imageFile) {
                formData.append('image', imageFile);
            }
            if (faviconFile) {
                formData.append('favicon_image', faviconFile);
            }
            formData.append('no_logo', noLogo);

            axios.post('/venue/steps/branding', formData)
                .then(() => {

                })
        }
    })

    const handleHolidayDelete = (id) => {
        axios.delete(`/venue-holiday/delete/${id}`)
            .then(() => {
                fetchHoliday()
            })
    }

    return isLoading ? (<Loader />) : (
        <div
            className={styles.container}
        >
            <div
                className={styles.box_containers}
            >
                <div
                    className={styles.branding_container}
                >
                    <Branding
                        venueDetails={venueDetails}
                        removeFavicon={removeFavicon}
                        removeImage={removeImage}
                        imageFile={imageFile}
                        setImageFile={setImageFile}
                        faviconFile={faviconFile}
                        setFaviconFile={setFaviconFile}
                        noLogo={noLogo}
                        setNoLogo={setNoLogo}
                    />
                </div>

                <div
                    className={styles.store_info_container}
                >
                    <StoreInformation
                        venueDetails={venueDetails}
                        formik={formik}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                    />

                    <div>
                        <div
                            style={{
                                padding: 10,
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div
                                className={styles.holiday_txt}
                            >
                                Holidays
                            </div>

                            <Button
                                startIcon={<Add />}
                                onClick={() => {
                                    setShowAddHoliday(true)
                                }}
                            >
                                Add Holiday
                            </Button>
                        </div>

                        <div
                            style={{
                                padding: 15
                            }}
                        >
                            {holidays?.map((data) => (
                                <div
                                    className={styles.date_chip}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 10
                                        }}
                                    >
                                        <div>
                                            {data.date}
                                        </div>
                                        <div>
                                            {data.title}
                                        </div>
                                    </div>

                                    <div>
                                        <IconButton
                                            onClick={() => {
                                                setEditObj(data)
                                                setIsEdit(true)
                                                setShowAddHoliday(true)
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleHolidayDelete(data.id)}
                                        >
                                            <DeleteForever
                                                style={{
                                                    color: 'red'
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div
                    className={styles.business_hours}
                >
                    <BusinessHours
                        formik={formik}
                    />
                </div>
            </div>

            <div
                className={styles.save_btn_container}
            >
                <GenericLoadingButton
                    variant="contained"
                    type="button"
                    onClick={formik.handleSubmit}
                    size="large"
                    loading={loading}
                    loadingPosition="end"
                    disabled={isActionsDisabled}
                >
                    Save
                </GenericLoadingButton>
            </div>

            <Dialog
                open={showAddHoliday}
                onClose={() => {
                    setShowAddHoliday(false)
                    setEditObj({})
                    setIsEdit(false)
                }}
            >
                <AddHoliday
                    editObj={editObj}
                    isEdit={isEdit}
                    onClose={() => {
                        setShowAddHoliday(false)
                        setEditObj({})
                        setIsEdit(false)
                        fetchHoliday()
                    }}
                />
            </Dialog>
        </div>
    )
}

export default StoreInfo