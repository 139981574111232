import axios from 'axios';
import bizBaseUrl, { BIZ_NODE_API_URL, siteBaseUrl } from './baseUrl';

const instance = axios.create({
    baseURL: bizBaseUrl,
});

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use((res) => res,
    async (err) => {
        console.log(err.response, 'errrrrr', err.response?.config?.url)
        if (err.response && err?.response?.status === 401) {
            localStorage.removeItem('userToken')
            window.location.href = '/'
        }
        return Promise.reject(err)
    })


const instance3 = axios.create({
    baseURL: BIZ_NODE_API_URL,
});

instance3.interceptors.request.use(
    config => {
        const token = localStorage.getItem('superAdminToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

const instance2 = axios.create({
    baseURL: siteBaseUrl,
});

instance2.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

const instance4 = axios.create({
    baseURL: BIZ_NODE_API_URL,
});

instance4.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);


export default instance;
export const axios2 = instance2;
export const axios3 = instance3;
export const nodeAxios = instance4;
