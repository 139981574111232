import React from 'react'
import { Button, IconButton } from '@mui/material'
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import GenericSearchField from '../../../../Library/V2/GenericSearchField/GenericSearchField'


function CustomizationHeader({ searchStr, setSearchStr, debounceSearch, isSearchLoading, setShowAddModifier, isMobile,isActionDisabled }) {
  return (
    <div>
        <div className='tw-hidden lg:tw-block '>
            <h1 className="tw-text-2xl tw-font-semibold tw-mb-4 tw-text-black">Customizations</h1>
        </div>
        <div className="tw-flex tw-justify-between">
            <div className="tw-flex tw-gap-4 tw-whitespace-nowrap tw-w-full lg:tw-w-auto">

                <GenericSearchField {...{ 
                        value: searchStr, 
                        debounceSearch, 
                        setValue: setSearchStr, 
                        isSearchLoading,
                        placeholder: 'Search customizations',
                        fullWidth: isMobile && true
                        }} />
            </div>

            {isMobile ? (
                <div className='tw-fixed tw-bottom-10 tw-right-9 tw-z-10'>
                    <IconButton className='!tw-bg-black !tw-rounded-3xl !tw-text-white' onClick={() => setShowAddModifier(true)}>
                        <Add />
                    </IconButton>
                </div>
            ) : (
                <div className='tw-flex tw-items-center tw-gap-3'>
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        disableElevation
                        style={{
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: 20
                        }}
                        disabled={isActionDisabled}
                        onClick={() => {
                            setShowAddModifier(true)
                        }}
                    >
                        Create Customization
                    </Button>
                </div>
            )}
        </div>
    </div>
  )
}

export default CustomizationHeader;