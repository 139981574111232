/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import styles from './FormsList.module.scss';
import FormsListHeader from './FormsListHeader/FormsListHeader';

import { debounce } from 'lodash';
import FormsListFilters from './FormsListFilters/FormsListFilters';
import FormsTable from './FormsTable/FormsTable';
import { useMediaQuery } from '@mui/material';
import FormsMobileItems from './FormsMobileItems/FormsMobileItems';

import { nodeAxios } from '../../../utils/axiosConfig';

function FormsList(props) {
  const mobileSizeCheck = useMediaQuery('(max-width:600px)');

  const [sortBy, setSortBy] = useState('created_at');
  console.log('🚀OUTPUT --> sortBy:', sortBy);
  const [searchQuery, setSearchQuery] = useState('');
  const [type_id, setType_id] = useState('all');
  const [forms, setForms] = useState(null);

  const fetchForms = (params) => {
    setForms(null);
    nodeAxios
      .get('/web-builder/web-page-form', { params })
      .then((response) => {
        setForms({
          data: response.data?.data,
          pagination: response.data?.pagination,
        });
      })
      .catch((error) => {
        setForms({
          data: [],
        });
        console.log('🚀 ~ error', error);
      });
  };

  useEffect(() => {
    const params = {
      'sort-by': 'created_at',
      'sort-by-order': 'DESC'
    };
    fetchForms(params);
  }, []);

  const handleChangeSort = (event) => {
    setSortBy(event.target.value);
    let params = { page: forms?.pagination?.current_page };
    if (event.target.value === 'created_at') {
      // params['sort-by-order'] = event.target.value;
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = event.target.value;
    } else if (event.target.value) {
      params['sort-by-order'] = event.target.value;
    }
    if (searchQuery) {
      params.q = searchQuery;
    }
    if (type_id && type_id !== 'all') {
      params.room_id = type_id;
    }

    fetchForms(params);
  };

  const handleSearchChange = (event) => {
    let params = { page: forms?.pagination?.current_page };
    if (event.target.value) {
      params.q = event.target.value;
    }
    if (type_id && type_id !== 'all') {
      params.room_id = type_id;
    }

    if (sortBy === 'created_at') {
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = sortBy;
    } else if (sortBy) {
      params['sort-by-order'] = sortBy;
    }
    fetchForms(params);
  };

  const handleChangeType_id = (event) => {
    let params = { page: forms?.pagination?.current_page };
    setType_id(event.target.value);
    if (event.target.value && event.target.value !== 'all') {
      params.room_id = event.target.value;
    }
    if (searchQuery) {
      params.q = searchQuery;
    }

    if (sortBy === 'created_at') {
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = sortBy;
    } else if (sortBy) {
      params['sort-by-order'] = sortBy;
    }
    fetchForms(params);
  };

  const handleClearAllFilters = (event) => {
    setSortBy('created_at');
    setSearchQuery('');
    setType_id('all');
    let params = {
      page: forms?.pagination?.current_page,
      'sort-by-order': 'DESC',
      'sort-by': 'created_at',
    };

    fetchForms(params);
  };

  const handleFetchListing = (event) => {
    let params = { page: forms?.pagination?.current_page };

    if (searchQuery) {
      params.q = searchQuery;
    }
    if (type_id && type_id !== 'all') {
      params.room_id = type_id;
    }
    if (sortBy === 'created_at') {
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = sortBy;
    } else if (sortBy) {
      params['sort-by-order'] = sortBy;
    }
    fetchForms(params);
  };

  const handleChangePage = (page_no) => {
    let params = { page: page_no };

    if (searchQuery) {
      params.q = searchQuery;
    }
    if (type_id && type_id !== 'all') {
      params.room_id = type_id;
    }
    if (sortBy === 'created_at') {
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = sortBy;
    } else if (sortBy) {
      params['sort-by-order'] = sortBy;
    }
    fetchForms(params);
  };

  const debounceSearch = useCallback(debounce(handleSearchChange, 1000), [
    type_id,
    sortBy,
    searchQuery,
  ]);

  return (
    <div className={styles.school_list}>
      <FormsListHeader {...{ type_id, handleFetchListing }} {...props} />
      <FormsListFilters
        {...{
          type_id,
          sortBy,
          searchQuery,
          handleChangeSort,
          setSearchQuery,
          debounceSearch,
          handleChangeType_id,
          handleClearAllFilters,
          listings: forms,
          fetchListing: fetchForms,
        }}
        {...props}
      />

      <div>
        {mobileSizeCheck ? (
          <FormsMobileItems
            {...{
              handleFetchListing,
              handleChangePage,
              totalPages: props?.listings?.pagination?.last_page ?? 1,
              pageNo: props?.listings?.pagination?.current_page ?? 1,
              listings: forms,
              fetchListing: fetchForms,
            }}
            {...props}
          />
        ) : (
          <FormsTable
            {...{
              handleFetchListing,
              handleChangePage,
              totalPages: props?.listings?.pagination?.last_page ?? 1,
              pageNo: props?.listings?.pagination?.current_page ?? 1,
              listings: forms,
              fetchListing: fetchForms,
            }}
            {...props}
          />
        )}
      </div>
    </div>
  );
}

export default FormsList;
