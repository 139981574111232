import React, { useState, useEffect } from 'react';
import Fee from '../Inputs/Fee';
import ValueInput from '../Inputs/Value';
import RadiusInput from '../Inputs/Radius';
import { Typography, Button, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Config from '../Config/Config';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '../../../../../../../assets/trash-can.png';
import DeleteIconBlack from '../../../../../../../assets/trash-can-black.png';
import MultiInput from "../Inputs/Multi";
import "./Delivery.css";
import LightTooltip from '../../../../../../../util_comps/LightTooltip';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
    fieldDescription: {
        color: 'blue',
        fontSize: '.9rem',
        marginBottom: '5px',
        width: 'fit-content'
    },
    inputsContainer: {
        width: '95%',
        "@media only screen and (max-width: 1024px)": {
            flexDirection: 'column',
            marginBottom: 30
        }
    },
    input: {
        flex: 3,
        marginRight: '3.5%',
        "@media only screen and (max-width: 1024px)": {
            marginRight: '0 !important',
            marginBottom: 15,
            width: '100%'
        }
    },
    lastinput: {
        flex: 3,
        marginRight: '10px',
        "@media only screen and (max-width: 1024px)": {
            marginRight: '0 !important',
            marginBottom: 15,
            width: '100%'
        }
    }
}));

const Delivery = (props) => {
    const classes = useStyles();
    const [priceOption, setPriceOption] = useState('percentage');
    const { storeInfo } = useSelector(state => state.venueInfo);
    const storeState = useSelector(
        (store) => store.venueSetting.VenueSettingList?.data
    );
    const { venue } = storeInfo;
    const dispatch = useDispatch(data => data);
    useEffect(() => {
        if (venue.fee_type) {
            setPriceOption(venue.fee_type);
        };
    }, [venue]);

    useEffect(() => {
        handleInputChange([{ key: "radius_from", val: 0, index: 0 }, { key: "fee_type", val: "dollar", index: 0 }])
    }, [])

    const [noOfZones, setNoOfZones] = useState(venue.venue_delivery_zone?.length === 0 ? 1 : venue.venue_delivery_zone?.length)

    const handleInputChange = (pairs) => {
        let setter = {
            ...storeInfo,
            venue: {
                ...venue
            }
        }
        let isDirty = false;
        let venue_delivery_zone

        pairs.map((val) => {
            if (val.val !== '') {
                isDirty = true;
            }

            if (setter.venue.venue_delivery_zone) {
                venue_delivery_zone = [...setter.venue.venue_delivery_zone]
            } else {
                venue_delivery_zone = []
            }

            venue_delivery_zone[val.index] = { ...venue_delivery_zone[val.index], [val.key]: val.val }
            setter.venue = {
                ...setter.venue,
                venue_delivery_zone,
            }
        });

        if (isDirty === '') {
            props.setPristine()
        } else {
            props.setDirty()
        }
        dispatch({
            type: "SET_STORE_INFO",
            payload: setter
        })
    }

    let venueHours = storeInfo.venue_hours?.filter((val) => val.venuetimings === 'delivery') || [];

    const handleMultiInputChange = (pairs) => {
        let setter = {
            ...storeInfo,
            venue: {
                ...venue,
            },
        };
        let isDirty = false;
        pairs.map((val) => {
            if (val.val !== "") {
                isDirty = true;
            }
            setter.venue = {
                ...setter.venue,
                [val.key]: val.val,
            };
        });
        if (isDirty === "") {
            props.setPristine();
        } else {
            props.setDirty();
        }
        dispatch({
            type: "SET_STORE_INFO",
            payload: setter,
        });
    };

    const handleDeleteZone = () => {
        let setter = {
            ...storeInfo,
            venue: {
                ...venue,
            },
        };

        setter.venue.venue_delivery_zone.pop()

        dispatch({
            type: "SET_STORE_INFO",
            payload: setter,
        });
    }

    const getDeliveryRows = () => {
        let deliveryForm = []
        for (let i = 0; i < noOfZones; i++) {
            deliveryForm.push(
                <div
                    className="inputandiconcontainer"
                    style={{ display: 'flex' }}
                >
                    <div className={classes.inputsContainer} style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.input}>
                            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Define your delhivery radius, in miles.</span>} arrow placement="top">
                                <Typography className={classes.fieldDescription}>Deliver From(Miles)</Typography>
                            </LightTooltip>
                            <RadiusInput
                                onChange={() => { }}
                                value={venue.venue_delivery_zone ? venue.venue_delivery_zone[i].radius_from : ''}
                                isDisabled={true}
                            />
                        </div>
                        <div className={classes.input}>
                            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Define your delhivery radius, in miles.</span>} arrow placement="top">
                                <Typography className={classes.fieldDescription}>Deliver To(Miles)</Typography>
                            </LightTooltip>
                            <RadiusInput
                                onChange={val => handleInputChange([{ key: "radius_to", val: parseInt(val) || '', index: i }])}
                                value={venue.venue_delivery_zone ? venue.venue_delivery_zone[i].radius_to : ''}
                                isDisabled={!(noOfZones - 1 === i)}

                            />
                        </div>
                        <div className={classes.input}>
                            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>If you charge flat fees, choose "Dollar". Choose "Percent", if you want us  to calculate the fees based on order value. </span>} arrow placement="top">
                                <Typography className={classes.fieldDescription}>Delivery Fee Type</Typography>
                            </LightTooltip>
                            <Fee onChange={(val) => handleInputChange([{ key: "fee_type", val, index: i }, { key: "fees", val: venue.fee_type === "dollar" ? venue.fees || "0" : venue.fees || "0.00", index: i }])} value={venue.venue_delivery_zone[i] ? venue.venue_delivery_zone[i].fee_type : ''} />
                        </div>
                        <div className={classes.input}>
                            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Enter Delivery fees here</span>} arrow placement="top">
                                <Typography className={classes.fieldDescription}>Delivery Fees</Typography>
                            </LightTooltip>
                            <ValueInput onChange={(val) => handleInputChange([{ key: "fees", val, index: i }])} value={venue.venue_delivery_zone[i] ? venue.venue_delivery_zone[i].fees : ''} type={venue.venue_delivery_zone[i].fee_type === "percentage" ? "percentage" : "dollar"} />
                        </div>
                        <div className={classes.lastinput}>
                            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Only orders with value greater than this amount will be allowed to process. We recommend to have a low value here to get more delivery orders</span>} arrow placement="top">
                                <Typography className={classes.fieldDescription}>Min. Delivery Amount</Typography>
                            </LightTooltip>
                            <ValueInput onChange={(val) => handleInputChange([{ key: "min_delivery_amount", val, index: i }])} value={venue.venue_delivery_zone[i] ? venue.venue_delivery_zone[i].min_delivery_amount : ''} />
                        </div>
                    </div>
                    {(noOfZones - 1 === i) && (i !== 0) && (
                        <>
                            <div
                                className="delivery-delete-icon-container"
                                onClick={() => {
                                    setNoOfZones((prevState) => {
                                        handleDeleteZone()
                                        return prevState - 1
                                    })
                                }}
                            >
                                <img
                                    className="delivery-delete-icon"
                                    src={DeleteIcon}
                                />
                            </div>
                            <div
                                className="delivery-delete-icon-mobile-container"
                                onClick={() => {
                                    setNoOfZones((prevState) => {
                                        handleDeleteZone()
                                        return prevState - 1
                                    })
                                }}
                            >
                                <img
                                    className="delivery-delete-icon"
                                    src={DeleteIconBlack}
                                />
                            </div>
                        </>
                    )}
                </div>
            )
        }
        return deliveryForm
    }

    return (
        <div style={{ marginTop: 10 }}>
            {getDeliveryRows()}
            {(noOfZones < 10) && (
                <div
                    style={{
                        marginTop: '25px',
                        textAlign: 'right',
                        cursor: 'pointer',
                        color: 'blue',
                        textDecoration: 'underline'
                    }}
                >
                    <span
                        onClick={() => {
                            setNoOfZones((prevState) => {
                                if (Object.keys(venue.venue_delivery_zone[prevState - 1]).length === 5) {
                                    handleInputChange([{ key: "radius_from", val: venue.venue_delivery_zone[prevState - 1].radius_to, index: prevState }, { key: "fee_type", val: "dollar", index: prevState }])
                                    return prevState + 1
                                } else {
                                    toast.warning('Please fill all the values before adding new zone.')
                                    return prevState
                                }
                            })
                        }}
                    >
                        Add New Zone
                    </span>
                </div>
            )}
            <div>
                <Config setDirty={props.setDirty} configs={venueHours} />
            </div>

            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Irrespective of delivery radius, you will only be able to deliver to the following states. Leave it blank if you would like to get orders based on your delivery zones, from any state.</span>} arrow placement="top">
                <p
                    style={{
                        marginBottom: '0px',
                        color: 'blue',
                        width: 'fit-content'
                    }}
                >
                    Restrict Delivery to following States:
                </p>
            </LightTooltip>

            <div
                className="delivery-shiptostates"
            >
                <MultiInput
                    onChange={(value) =>
                        value.length > 0
                            ? handleMultiInputChange([{
                                val: value.map((val) => val.title).join(','),
                                key: 'delivery_states'
                            }])
                            : handleMultiInputChange([{ val: null, key: 'delivery_states' }])
                    }
                    value={venue.delivery_states
                        ?.split(",")
                        ?.map((val) => ({ title: val }))}
                />
            </div>
        </div>
    );
};

export default Delivery;