import React, { useState, useEffect } from "react";
import Fee from "../Inputs/Fee";
import ValueInput from "../Inputs/Value";
import MultiInput from "../Inputs/Multi";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Config from "../Config/Config";
import { useSelector, useDispatch } from "react-redux";
import LightTooltip from "../../../../../../../util_comps/LightTooltip";

const useStyles = makeStyles(() => ({
  fieldDescription: {
    color: "blue",
    fontSize: "1rem",
    marginBottom: "5px",
    width: 'fit-content'
  },
  inputsContainer: {
    "@media only screen and (max-width: 1024px)": {
      flexDirection: "column",
      marginBottom: 30,
    },
  },
  input: {
    flex: 3,
    marginRight: "3.5%",
    "@media only screen and (max-width: 1024px)": {
      marginRight: "0 !important",
      marginBottom: 15,
    },
  },
}));

const Shipping = (props) => {
  const classes = useStyles();
  const [priceOption, setPriceOption] = useState("percent");
  const { storeInfo } = useSelector((state) => state.venueInfo);
  const { venue } = storeInfo;
  const storeState = useSelector(
    (store) => store.venueSetting.VenueSettingList?.data
  );
  const dispatch = useDispatch((data) => data);
  useEffect(() => {
    if (venue.shippingfeestype) {
      setPriceOption(venue.shippingfeestype);
    }
  }, [venue]);
  useEffect(() => {
    if (storeState?.venue) {
      const { minshipamount, shiptostates, shippingfees, shippingfeestype } =
        storeState?.venue;
      let setter = {
        ...storeInfo,
        venue: {
          ...venue,
          shippingfeestype: shippingfeestype,
          minshippingamount: minshipamount,
          shippingfees: shippingfees,
        },
      };
      dispatch({
        type: "SET_STORE_INFO",
        payload: setter,
      });
    }
  }, [storeState?.venue]);
  const handleInputChange = (pairs) => {
    let setter = {
      ...storeInfo,
      venue: {
        ...venue,
      },
    };
    let isDirty = false;
    pairs.map((val) => {
      if (val.val !== "") {
        isDirty = true;
      }
      setter.venue = {
        ...setter.venue,
        [val.key]: val.val,
      };
    });
    if (isDirty === "") {
      props.setPristine();
    } else {
      props.setDirty();
    }
    dispatch({
      type: "SET_STORE_INFO",
      payload: setter,
    });
  };
  return (
    <div style={{ marginTop: 20 }}>
      <div className={classes.inputsContainer} style={{ display: "flex" }}>
        <div className={classes.input} style={{ flex: 4.5 }}>
          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Irrespective of delivery radius, you will only be able to deliver to the following states. Leave it blank if you would like to get orders based on your delivery zones, from any state.</span>} arrow placement="top">
            <Typography className={classes.fieldDescription}>
              Ship To States
            </Typography>
          </LightTooltip>
          <MultiInput
            onChange={(value) =>
              value.length > 0
                ? handleInputChange([{
                  val: value.map((val) => val.title).join(','),
                  key: 'shiptostates'
                }])
                : handleInputChange([{ val: null, key: 'shiptostates' }])
            }
            value={venue.shiptostates
              ?.split(",")
              ?.map((val) => ({ title: val }))}
          />
        </div>
        <div className={classes.input}>
          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>If you charge flat fees, choose "Dollar". Choose "Percent", if you want us  to calculate the fees based on order value.</span>} arrow placement="top">
            <Typography className={classes.fieldDescription}>
              Shipping Fee Type
            </Typography>
          </LightTooltip>
          <Fee
            onChange={(val) =>
              handleInputChange([
                { key: "shippingfeestype", val },
                {
                  key: "shippingfees",
                  val:
                    venue.shippingfeestype === "dollar"
                      ? venue.shippingfees || "0"
                      : venue.shippingfees || "0.00",
                },
              ])
            }
            value={venue.shippingfeestype}
          />
        </div>
        <div className={classes.input}>
          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Enter Shipping fees here</span>} arrow placement="top">
            <Typography className={classes.fieldDescription}>
              Shipping Fees
            </Typography>
          </LightTooltip>
          <ValueInput
            onChange={(val) =>
              handleInputChange([{ key: "shippingfees", val }])
            }
            value={venue.shippingfees}
            type={priceOption}
          />
        </div>
        <div className={classes.input}>
          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Only orders with value greater than this amount will be allowed to process. We recommend to have a low value here to get more shipping orders.</span>} arrow placement="top">
            <Typography className={classes.fieldDescription}>
              Min. Shipping Amount
            </Typography>
          </LightTooltip>
          <ValueInput
            onChange={(val) =>
              handleInputChange([{ key: "minshipamount", val }])
            }
            value={venue.minshipamount}
            type="dollar"
          />
        </div>
      </div>
    </div>
  );
};

export default Shipping;
