import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import PercentIcon from '@mui/icons-material/Percent';
import GenericTextFieldWithLabel from '../TextFieldWithLabel/GenericTextFieldWithLabel';

const ValueTextField = (props) => {

    return (

        <GenericTextFieldWithLabel
            id={props.id}
            name={props.name}
            iconType={true}
            iconVariant={props.type}
            InputProps={
                (props.type === 'percentage' || props.type === 'radius') ? {
                    endAdornment: (
                        <InputAdornment position="end">
                            {props.type === 'radius' ? 'Miles' : (
                                <PercentIcon
                                    style={{
                                        color: 'black'
                                    }}
                                />
                            )}
                        </InputAdornment>
                    ),
                } : {
                    startAdornment: (
                        <InputAdornment position="start">
                            <AttachMoney
                                style={{
                                    color: 'black'
                                }}
                            />
                        </InputAdornment>
                    ),
                }}
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            required={props.required}
            type={props.texttype ? props.texttype : 'string'}
            isSmallLabel={props.isSmallLabel}
            error={props.error}
            helperText={props.helperText}
            fullWidth={props.fullWidth}
            containerClassname={props.containerClassname}
            placeholder={props.placeholder}
        />
    )
};


export default ValueTextField;