import { format } from 'date-fns'
import React, { useState } from 'react'
import MarketingInfoChip from '../../Library/MarketingInfoChip/MarketingInfoChip'
import MarketingFilters from '../../MarketingFilters/MarketingFilters'
import styles from './GoogleAnalytics.module.scss'

const overAllInsights = [
  {
    id: 'users',
    label: 'Users',
    value: 0,
    isUp: true,
    moveValue: 0
  },
  {
    id: 'new_users',
    label: 'New Users',
    value: 0,
    isUp: true,
    moveValue: 0
  },
  {
    id: 'avg_session_duration',
    label: 'Avg Session Duration',
    value: 0,
    isUp: true,
    moveValue: 0
  },
  {
    id: 'subscriber',
    label: 'Subscribers',
    value: 0,
    isUp: true,
    moveValue: 0
  },
  {
    id: 'bounce_rate',
    label: 'Bounce Rate',
    value: 0,
    isUp: true,
    moveValue: 0
  },
  {
    id: 'session',
    label: 'Sessions',
    value: 0,
    isUp: true,
    moveValue: 0
  },
]

const GoogleAnalytics = () => {

  const [periodState, setPeriodState] = useState(7)

  const [startDate, setStartDate] = useState(format((new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
  const [pstartDate, setpStartDate] = useState(format((new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
  const [endDate, setEndDate] = useState(format((new Date()), "yyyy-MM-dd"))
  const [pendDate, setpEndDate] = useState(format((new Date()), "yyyy-MM-dd"))
  const [isCompare, setIsCompare] = useState(false)

  return (
    <div>
      <MarketingFilters
        periodState={periodState}
        setPeriodState={setPeriodState}
        isCompare={isCompare}
        setIsCompare={setIsCompare}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        pstartDate={pstartDate}
        pendDate={pendDate}
        setpEndDate={setpEndDate}
        setpStartDate={setpStartDate}
        onShowClick={() => {
        }}
      />

      <div>
        <div>

        </div>


        <div
          className={styles.info_container}
        >
          {overAllInsights.map((data) => (
            <MarketingInfoChip
              label={data.label}
              isUp={data.isUp}
              value={data.value}
              moveValue={data.moveValue}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default GoogleAnalytics