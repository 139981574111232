import React, { useContext } from 'react'
import WebsiteEditorContext from '../../../utils/context/websiteEditorContext'

const CategorySelection = ({
    cat,
    setCat
}) => {

    const { blockCategories } = useContext(WebsiteEditorContext)

    return (
        <div
            className="tw-mb-5 tw-flex tw-gap-1 tw-px-2 tw-items-center"
        >
            <div>
                Category:
            </div>

            <select
                className="tw-block tw-w-full tw-p-2 tw-text-sm tw-text-gray-900 tw-border tw-border-gray-300 tw-rounded-lg tw-bg-gray-50"
                value={cat}
                onChange={(e) => setCat(e.target.value)}
            >
                <option
                    value="All"
                >
                    All
                </option>
                {Array.from(blockCategories.entries()).map(([key, value]) => {
                    return (
                        <option
                            key={key}
                            value={key}
                        >
                            {key}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}

export default CategorySelection