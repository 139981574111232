import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_STORE_URL || 'https://staging-app-apiv1.esplanda.com/',
});

const instance2 = axios.create({
    baseURL: process.env.REACT_APP_API_STORE_URL || 'https://staging-app-apiv1.esplanda.com/',
});
const instance3 = axios.create({
    baseURL: process.env.REACT_APP_BIZ_NODE_API_URL || 'https://biz-nodeapi-production.esplanda.com',
});


instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

instance2.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken2');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

instance3.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

export default instance;
export const axios2 = instance2;
export const nodeAxios = instance3;