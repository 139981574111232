import { nanoid } from 'nanoid';
import axios from '../../utils/axiosConfig'

export const getProductGroups = (params) => {
  try {
    return async (dispatch) => {
      let { data: { data: { result: data, pagination } } } = await axios.get('/venue-product-group/list',
        {
          params: {
            page: params.page,
            product_group_type: params.type,
            order_by: params.sort,
            search: params.searchStr
          },
        }
      );

      let productGroups = data.map((val) => ({
        data: { ...val, productGroupUuid: nanoid(15) },
        type: 'productGroup',
      }));
      dispatch({
        type: 'SET_PRODUCT_GROUPS',
        payload: {
          productGroups,
          pagination,
        },
      });
    };
  } catch (err) {
    throw err;
  }
};

export const deleteProductGroup = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'DELETE_PRODUCT_GROUP',
      payload: id,
    });
  };
};
