import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Typography, Select } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import "./styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { getEmailTypes } from "../../../redux/actions/emailSettingActions";
import { PrimaryButton } from "../../../util_comps/CustomButton";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  updateEmailSetting,
  getEmailSetting,
} from "../../../servise/emailSetting";
import { toast } from "react-toastify";
import GenericButton from "../../Library/GenericButton/GenericButton";


const EmailSettings = (props) => {
  const dispatch = useDispatch();
  const { emailTypes } = useSelector((store) => store.emailSetting);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [currentType, setCurrentType] = useState();

  useEffect(() => {
    dispatch(getEmailTypes());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(emailTypes).length > 0) {
      setCurrentType(Object.keys(emailTypes)[0]);
    }
  }, [emailTypes]);

  useEffect(() => {
    (async () => {
      if (currentType) {
        let data = await getEmailSetting('delivery_order_ready');
        const blocksFromHtml = htmlToDraft(data);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    })();
  }, [currentType]);

  const onChange = (editorState) => {
    setEditorState(editorState);
  };

  const submitChange = async () => {
    try {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftToHtml(rawContentState);
      await updateEmailSetting(currentType, markup);
      toast.success('Email Configuration Updated Succesfully')

    } catch (err) {
      toast.error(err.message)
    }
  };

  return (
    <React.Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div style={{ padding: "5px 20px" }}>
            {/* <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                position: "relative",
                zIndex: 10000000,
              }}
            >
              <Breadcrumbs style={{ fontSize: 13 }} aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  Home
                </Link>
                <Typography
                  style={{
                    borderBottom: "1px solid grey",
                    fontSize: "inherit",
                  }}
                  color="textPrimary"
                >
                  Email Settings
                </Typography>
              </Breadcrumbs>
            </div> */}
            <div style={{ marginTop: 30 }}>
              <div style={{ marginBottom: 15 }}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "1.3rem",
                    color: "black",
                  }}
                >
                  Email Settings
                </Typography>
              </div>
              <div style={{ marginBottom: 15 }}>
                <Typography
                  style={{ marginBottom: 5, fontSize: ".9em", color: "black" }}
                >
                  Email Type
                </Typography>
                {/* <Select
                  style={{ width: "100%" }}
                  variant="outlined"
                  native
                  value={currentType}
                  onChange={(e) => setCurrentType(e.target.value)}
                >
                  {Object.keys(emailTypes).map((val) => {
                    return <option value={val}>{emailTypes[val]}</option>;
                  })}
                </Select> */}
              </div>
              <Editor
                // wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onChange}
                editorState={editorState}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "embedded",
                    "image",
                    "remove",
                    "history",
                  ],
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 25,
              }}
            >
              <GenericButton
                variant="contained"
                onClick={submitChange}
                disabled={props.isActionsDisabled}
                size="large"
              >
                Submit
              </GenericButton>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailSettings;
