import React, { useEffect, useState } from "react";
import './style.css';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';
import Items from "./Items/Items";
import Customization from "./Customization/Customization";
import Categories from "./Categories/Categories";
import TabsSection from '../../Library/V2/TabsSection/TabsSection';
import styles from './Menu.module.scss';
import Modifiers from '../../Modifiers/Modifiers';
import DietaryAttributes from './DietaryAttributes/DietaryAttributes';
import { nodeAxios } from "../../../utils/axiosConfig";
import { toast } from "react-toastify";

const tabs = [
  {
    value: 'items',
    label: 'Items',
  },
  {
    value: 'categories',
    label: 'Categories',
  },
  {
    value: 'customizations',
    label: 'Customizations',
  },
  {
    value: 'dietary_attributes',
    label: 'Dietary Attributes',
  },
];

const Menu = (props) => {
  useAuth();
  const [selectedTab, setSelectedTab] = useState('items')
  const [isActionDisabled, setIsActionDisabled] = useState(false)
  const [cloverCred, setCloverCred] = useState()
  const [isPullInventoryLoading, setIsPullInventoryLoading] = useState(false)

  const fetchCloverSettings = () => {
    nodeAxios.get('/clover/connection-credentials')
      .then((res) => {
        setIsActionDisabled(true)
        setCloverCred(res?.data?.data)
        // setIsClover(true)
      })
      .catch(() => {
        setCloverCred()
        setIsActionDisabled(false)
        // setIsClover(false)
      })
  }

  useEffect(() => {
    fetchCloverSettings()
  }, [])

  const handlePullInventory = () => {
    setIsPullInventoryLoading(true)
    nodeAxios.get('/clover/pull-inventory')
      .then((res) => {
        toast.success('Your inventory is being synced from clover, You will recieve an email when sync is completed')
      })
  }

  return (
    <React.Fragment>

      <div className="page-wrapper tw-my-2 tw-px-4 sm:tw-px-11 ">
        <TabsSection
          tabs={tabs}
          tabsbg='var(--bg-3)'
          tabsPadding='15px'
          tabValue={selectedTab}
          tabs_container={styles.tabs_container}
          onChange={(_, newValue) => {
            setSelectedTab(newValue)
          }}
          boxStyles={{
            p: '0 !important',
            borderBottom: '1px solid #D3D3D3',
          }}
        />

        {Boolean(cloverCred) && (
        <div
          className="tw-my-2 tw-text-red-400"
        >
          Your menu cannot be edited by esplanda as it is currently being synced through clove please update menu in clover and click

          <span
            className="tw-cursor-pointer tw-underline tw-text-blue tw-ml-1"
            onClick={handlePullInventory}
          >
            Sync inventory
          </span>
        </div>
        )}

        {(selectedTab === 'items') && (
          <Items
            isActionDisabled={isActionDisabled}
          />
        )}
        {(selectedTab === 'categories') && (
          <Categories
            isActionDisabled={isActionDisabled}
          />
        )}
        {(selectedTab === 'customizations') && (
          <Customization
            isActionDisabled={isActionDisabled}
          />
        )}

        {(selectedTab === 'dietary_attributes') && (
          <DietaryAttributes
            isActionDisabled={isActionDisabled}
          />
        )}
      </div>
    </React.Fragment >
  );
};

export default Menu;
