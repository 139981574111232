import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import searchbyidimg from "../../../assets/search-by-id.png";
import calendarimg from "../../../assets/calendar.png";
import routePaths from '../../../config/route-paths';
const FindOrders = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Orders & Payments               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.findOrders} className={cn('selected')}>Find Orders</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.transactionStatusReview}>Transaction Status Review</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.ordersDetails}>Order Details</Link></li>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.OrdersStatus}>Order Status</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Find Orders   
         </Typography>
         <p><strong>To find an order that was placed through your website or app, go to the ‘Orders’ page.</strong></p>
         <p>
            
         <ol>
            <li>Within the ‘<strong>Orders</strong>’ page, you can search for an order by the Order ID or browse by date.</li>

    <span className={cn('addnewproductsimg1')}>
    <img src={searchbyidimg} />
    </span>
   
    

    <li>To search by Order ID, enter the ID into the search bar in the top-right of the page, next to ‘<strong>My Payments</strong>’.</li>
    <span className={cn('addnewproductsimg')}>
    <img src={calendarimg} />
    </span>

<li>To search by date, use the ‘<strong>Start Date</strong>’ and ‘<strong>End Date</strong>’ selectors at the top of the page. It will automatically be showing orders from the past 30 days.</li>
<li>You may type in the start and end dates, or select the dates by clicking the calendar icon. Click the ‘<strong>Search</strong>’ button to view all the orders between the dates you selected.</li>
<li>To reset your search parameters, click the ‘<strong>Clear</strong>’ button next to ‘<strong>Search</strong>’.</li>

</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default FindOrders