const initialState = {
    isLoading: false,
    isLoggedIn: false,
    OrderList: {},
    orderListError: "",
    cancelOrder:{},
    orderCancelError: "",
    statusOrder:{},
    orderStatusError: ""
};
export function order(state = initialState, action) {
    switch (action.type) {
        case "SET_ORDER_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_ORDER_LIST_LOADED":
            return { ...initialState, isLoading: false , OrderList: action.payload };
        case "SET_ORDER_LIST_ERROR":
            return { ...initialState,orderListError:action.payload};
        default:
            return state;
    }
};

export function cancelOrder(state = initialState, action) {
    switch (action.type) {
        case "SET_CANCEL_ORDER_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_CANCEL_ORDER_LOADED":
            return { ...initialState, isLoading: false , cancelOrder: action.payload };
        case "SET_CANCEL_ORDER_ERROR":
            return { ...initialState,orderCancelError:action.payload};
        default:
            return state;
    }
};
export function changeOrderStatus(state = initialState, action) {
    switch (action.type) {
        case "SET_STATUS_ORDER_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_STATUS_ORDER_LOADED":
            return { ...initialState, isLoading: false , statusOrder: action.payload };
        case "SET_STATUS_ORDER_ERROR":
            return { ...initialState,  isLoading: false ,orderStatusError:action.payload};
        default:
            return state;
    }
};