import { inputBaseClasses, outlinedInputClasses } from '@mui/material'
import { MuiColorInput } from 'mui-color-input'
import React from 'react'
import styles from './ColorInputWithLabel.module.scss'

const ColorInputWithLabel = (props) => {
    return (
        <div>
            <div
            className={styles.title}
            >
                {props.title}
            </div>

            <MuiColorInput
                value={props.value || ''}
                onChange={props.onChange}
                fullWidth={true}
                sx={{
                    [`& .${inputBaseClasses.input}`]: {
                        padding: '0 0 0 8px',
                        height: '40px'
                    },
                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                        border: '1px solid #A8A8A8 !important',
                        outline: 'none',
                        minHeight: '40px'
                    }
                }}
            />
        </div>
    )
}

export default ColorInputWithLabel