import React, { useCallback, useContext, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, Dialog, ListItemIcon, Menu, MenuItem, Pagination, Radio, Select, Typography } from '@mui/material';
import { Add, ChevronRight, Description, DragIndicator, ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, Link, LocalOfferOutlined, Search, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import styles from './CategoryTable.module.scss'
import axios from '../../utils/axiosConfig'
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import MarketingContext from '../Marketing2/Util/marketingContext';
import { debounce } from 'lodash';
import Loader from '../Library/Loader';
import { useNavigate } from 'react-router-dom';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { reorder } from '../CustomPageBuilder/EditSection/Util/helpers';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddCategory from './AddCategory/AddCategory';

const columns = [
    {
        id: 'category_name',
        label: 'Category',
    }
];

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle
});

const CategoryTable = ({ setActiveModal }) => {

    const { subDomain } = useContext(MarketingContext)
    const navigate = useNavigate()

    const confirm = useConfirm()
    const [defaulTaxValue, setDefaulTaxValue] = useState('')
    const [showAddNewProduct, setShowAddNewProduct] = useState(false)
    const [showAddModifier, setShowAddModifier] = useState(false)
    const [showAddProperties, setShowAddProperties] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState({})
    const [editObj, setEditObj] = useState({})
    const [editModifierObj, setEditModifierObj] = useState({})
    const [options, setOptions] = useState({})
    const [searchStr, setSearchStr] = useState("");
    const [sort, setSort] = useState({ sort_by: 'sequence_id', sort_by_order: 'ASC' })
    const [cat, setCat] = useState({})
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(true)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const [selectedCatName, setSelectedCatName] = useState('')
    const [showAddCategory, setShowAddCategory] = useState(false)


    // const fetchMyList = (page, queryStr) => {
    //     setIsListLoading(true)
    //     axios.get('/product/my-list', {
    //         params: {
    //             page: page || 1,
    //             q: queryStr || searchStr,
    //             sort_by: sort.sort_by,
    //             sort_by_order: sort.sort_by_order,
    //             category_id: catId || undefined,
    //             limit: Boolean(catId) ? 100 : undefined
    //         }
    //     })
    //         .then((res) => {
    //             setProducts(res.data?.data?.result)
    //             setPagination(res.data?.data?.pagination)
    //         })
    //         .finally(() => {
    //             setIsSearchLoading(false)
    //             setIsListLoading(false)
    //         })
    // }

    // const handleSearch = (searchStr) => {
    //     try {
    //         setIsSearchLoading(true)
    //         fetchMyList(pagination.current_page, searchStr)
    //     } catch (err) {
    //         toast.error('Error occured while fetching inventories, please try again.')
    //     };
    // };

    // const debounceSearch = useCallback(debounce(handleSearch, 1000), [])

    // useEffect(() => {
    //     fetchMyList()
    // }, [])

    // useEffect(() => {
    //     fetchMyList(pagination.current_page)
    // }, [sort, catId])

    const fetchVenueCatMenu = () => {
        axios.get('/venue-category-order')
            .then((res) => {
                setVenueCategoryMenu(res.data?.data?.results)
                setDefaulTaxValue(res.data?.data?.venue_tax)
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setIsListLoading(false)
            })
    }

    useEffect(() => {
        fetchVenueCatMenu()
    }, [])

    const handleSetsequence = (items) => {
        let payload = items.map(element => element.id)
        // items?.forEach((element, index) => {
        //     payload[index] = element?.id
        // });

        axios.post('/venue-category-order/change-order', {
            venue_category_order_ids: payload
        })
            .then(() => {

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            venueCategoryMenu,
            result.source.index,
            result.destination.index
        );

        setVenueCategoryMenu(newitems);
        handleSetsequence(newitems)
    };

    const handleDelete = (row) => {
        confirm({
            title: `Are you sure you want to delete ${row?.category_name} category`,
        })
            .then(() => {
                axios.delete(`/venue-category-order/delete/${row?.id}`)
                    .then(() => {
                        fetchVenueCatMenu()
                        toast.success('Category deleted')
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
            })
    }


    return (
        <div
            className={styles.container}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 10
                }}
            >
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 700
                    }}
                >
                    Categories
                </div>

                <Button
                    style={{
                        backgroundColor: 'black',
                        color: 'white'
                    }}
                    variant="contained"
                    onClick={() => {
                        setShowAddCategory(true)
                    }}
                >
                    Add Category
                </Button>
            </div>

            <div
                className={styles.heading_container}
            >
                {/* <div
                    style={{
                        display: 'flex',
                        gap: 35,
                        whiteSpace: 'nowrap'
                    }}
                >

                    <Button
                        variant="text"
                        endIcon={((sort.sort_by === 'created_at') && (sort.sort_by_order === 'DESC')) ? (
                            <KeyboardArrowDown />
                        ) : (
                            <KeyboardArrowUp />
                        )}
                        onClick={() => {
                            if (sort.sort_by_order === 'ASC') {
                                setSort({ ...sort, sort_by_order: 'DESC' })
                            } else {
                                setSort({ ...sort, sort_by_order: 'ASC' })
                            }
                        }}
                    >
                        Recently Added
                    </Button>

                    <InputField
                        placeholder="Search by name, Barcode#"
                        value={searchStr}
                        onChange={(e) => {
                            setSearchStr(e.target.value)
                            debounceSearch(e.target.value)
                        }}
                        onIconButtonClick={e => debounceSearch(searchStr)}
                        showIconButton={true}
                        endIcon={isSearchLoading ? (
                            <CircularProgress
                                size={16}
                                style={{
                                    color: '#ef2d2c'
                                }}
                            />
                        ) : (
                            <Search
                                style={{
                                    color: '#ef2d2c'
                                }}
                            />
                        )}
                    />
                </div> */}
            </div>

            {isListLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, cursor: 'pointer' }}
                                            onClick={() => {
                                                if (column.sortid) {
                                                    if (sort.sort_by === column.sortid) {
                                                        if (sort.sort_by_order === 'ASC') {
                                                            setSort({ ...sort, sort_by_order: 'DESC' })
                                                        } else {
                                                            setSort({ ...sort, sort_by_order: 'ASC' })
                                                        }
                                                    } else {
                                                        setSort({ sort_by_order: 'DESC', sort_by: column.sortid })
                                                    }
                                                }
                                            }}

                                            sx={{
                                                [`&.${tableCellClasses.head}`]: {
                                                    backgroundColor: '#F0F0F0',
                                                    color: 'black',
                                                    fontWeight: 600
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 5,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <div>
                                                    {column.label}
                                                </div>

                                                {((sort.sort_by === column.sortid) && (sort.sort_by_order === 'DESC')) ? (
                                                    <KeyboardArrowUp />
                                                ) : (
                                                    <KeyboardArrowDown />
                                                )}
                                            </div>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    />


                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    />
                                    {/*
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    /> */}
                                </TableRow>
                            </TableHead>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <TableBody
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        // style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {venueCategoryMenu.map((row, index) => (
                                                <Draggable
                                                    key={row.id}
                                                    draggableId={"q-" + row.id}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <TableRow
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            sx={{
                                                                '&:nth-of-type(even)': {
                                                                    backgroundColor: '#F7F5F7',
                                                                },
                                                                // hide last border
                                                                '&:last-child td, &:last-child th': {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            {columns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        <div
                                                                            className={(column?.id == 'ProductName') && styles.product_name}
                                                                            onClick={() => {
                                                                                if ((column?.id == 'ProductName')) {
                                                                                    handleEdit2(row.id)
                                                                                }
                                                                            }}
                                                                        >
                                                                            {column.format ? column.format(value, row.track_quantity) : value}
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            })}

                                                            <TableCell
                                                                align="right"
                                                            >
                                                                <Button
                                                                    variant="text"
                                                                    onClick={() => {
                                                                        setIsEdit(true)
                                                                        setCat(row)
                                                                        setShowAddCategory(true)
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </TableCell>


                                                            <TableCell
                                                                align="right"
                                                            >
                                                                <Button
                                                                    style={{
                                                                        color: 'red'
                                                                    }}
                                                                    variant="text"
                                                                    onClick={() => {
                                                                        handleDelete(row)
                                                                    }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </TableCell>

                                                            <TableCell
                                                                align="right"
                                                            >
                                                                <DragIndicator />
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {/* </TableBody> */}
                        </Table>
                    </TableContainer>

                    <Dialog
                        open={showAddCategory}
                        onClose={() => {
                            setShowAddCategory(false)
                            fetchVenueCatMenu()
                        }}
                    >
                        <AddCategory
                            defaulTaxValue={defaulTaxValue}
                            isEdit={isEdit}
                            cat={cat}
                            onClose={() => {
                                setShowAddCategory(false)
                                fetchVenueCatMenu()
                                setIsEdit(false)
                                setCat({})
                            }}
                        />
                    </Dialog>

                    {!Boolean(venueCategoryMenu?.length) && (
                        <div
                            style={{
                                textAlign: 'center',
                                padding: 15,
                                fontSize: 20,
                                fontWeight: 600
                            }}
                        >
                            No Category Found
                        </div>
                    )}
                    {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}

                    {Boolean(venueCategoryMenu?.length) && (pagination?.last_page > 1) && (
                        <div
                            style={{
                                padding: '10px 0'
                            }}
                        >
                            <Pagination
                                page={pagination.current_page}
                                count={pagination.last_page}
                                onChange={(e, page) => fetchMyList(page)}
                            />
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
}

export default CategoryTable