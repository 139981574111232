import React, {useState} from "react";
import { Typography, TextField } from "@mui/material";
import UploadFile from "../UploadFile/UploadFile";
import { useSelector, useDispatch } from "react-redux";
import Const from "../../../../helpers/const";

const LiquorLicense = (props) => {
  const dispatch = useDispatch((data) => data);
  const { licenseInfo } = useSelector((state) => state.venueInfo);
  const [objUrl,setObjUrl] = useState('');
  const handleChange = (newVal) => {
    let isDirty = false;
    Object.entries(newVal).map(([key, val], i) => {
      if (val !== "") {
        isDirty = true;
      }
    });
    if (isDirty) {
      props.setDirty();
    } else {
      props.setPristine();
    }
    dispatch({
      type: "SET_LICENSE_INFO",
      payload: {
        ...licenseInfo,
        ...newVal,
      },
    });
  };
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Typography
          style={{
            color: "black",
            fontSize: "1.15rem",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Business License
        </Typography>
        <div>
          <Typography
            style={{
              color: "black",
              fontSize: "1rem",
              marginBottom: "5px",
            }}
          >
            License Number
          </Typography>
          <TextField
            value={licenseInfo?.license_no && licenseInfo?.license_no !== "undefined" ? licenseInfo?.license_no : ""}
            onChange={(e) =>
              handleChange({
                license_no: e.target.value,
              })
            }
            variant="outlined"
            style={{ width: "100%" }}
            
          />
        </div>
      </div>
      <div>
        <Typography style={{ color: "black", marginBottom: "7px" }}>
          Upload License *
        </Typography>
        <div
        >
          <UploadFile
            setFile={(file) => {
              if (file.size > 20e6) {
                window.alert("Please upload a file smaller than 20 MB");
                return false;
              }
              setObjUrl(URL.createObjectURL(file))
              handleChange({
                ...licenseInfo,
                file: file,
                license_file_name: file.name,
              });
            }}
            defaultFile={objUrl || licenseInfo?.file}
            filename={licenseInfo?.license_file_name}
          />
          <Typography
            style={{ color: "black", textAlign: "center", marginTop: "10px" }}
          >
            Supports JPG/PNG/PDF, up to 20MB
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LiquorLicense;
