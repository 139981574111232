import React from "react";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  let history = useNavigate();
  let userToken = localStorage.getItem("userToken");
  if (!userToken) {
    history("/");
  }
};
