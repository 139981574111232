import axios from '../../utils/axiosConfig'
import Const from '../../components/helpers/const'
import {
    POST_CREATE_CUSTOM_PAGE_REQUEST,
    POST_CREATE_CUSTOM_PAGE_SUCCESS,
    POST_CREATE_CUSTOM_PAGE_FAILURE,
    GET_CUSTOM_PAGE_REQUEST,
    GET_CUSTOM_PAGE_SUCCESS,
    GET_CUSTOM_PAGE_FAILURE,
    GET_CUSTOM_PAGE_DATA_REQUEST,
    GET_CUSTOM_PAGE_DATA_SUCCESS,
    GET_CUSTOM_PAGE_DATA_FAILURE,
    DELETE_CUSTOM_PAGE_REQUEST,
    DELETE_CUSTOM_PAGE_SUCCESS,
    DELETE_CUSTOM_PAGE_FAILURE
} from '../types/customPageTypes'

export const getCustomPage = () => {
    return (dispatch) => {
        dispatch({
            type: GET_CUSTOM_PAGE_REQUEST
        })
        axios.get(`/venue-page`)
            .then((response) => {
                dispatch({
                    type: GET_CUSTOM_PAGE_SUCCESS,
                    payload: response.data.data.result,
                    baseLink: response.data.data.base_link
                })
            })
            .catch(() => {
                dispatch({
                    type: GET_CUSTOM_PAGE_FAILURE
                })
            })
    }
}

export const deleteCustomPage = (id) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_CUSTOM_PAGE_REQUEST
        })
        axios.delete(`/venue-page/delete/${id}`)
            .then((response) => {
                dispatch(getCustomPage())
                dispatch({
                    type: DELETE_CUSTOM_PAGE_SUCCESS,
                })
            })
            .catch(() => {
                dispatch({
                    type: DELETE_CUSTOM_PAGE_FAILURE
                })
            })
    }
}