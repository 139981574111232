import { Button } from '@mui/material'
import React from 'react'
import styles from './TemplateChip.module.scss'

const TemplateChip = (props) => {
    return (
        <div
            className={styles.chip}
        >
            <div
                style={{
                    position: 'relative'
                }}
            >
                <img
                    className={styles.template_thumbnail}
                    src={props.image}
                    width={280}
                    height={150}
                />

                <div
                    className={styles.template_overlay}
                >
                    <Button
                        style={{
                            backgroundColor: '#0B9DE4',
                            borderRadius: '20px',
                            width: '100px'
                        }}
                        variant="contained"
                        onClick={props.onClick}
                    >
                        Select
                    </Button>

                    {!props.noPreview && (
                        <Button
                            style={{
                                borderRadius: '20px',
                                width: '100px',
                                color: 'white',
                                textDecoration: 'underline'
                            }}
                            variant="text"
                            onClick={props.onPreviewClick}
                        >
                            Preview
                        </Button>
                    )}
                </div>
            </div>
            <div
                className={styles.title}
            >
                {props.text}
            </div>
        </div>
    )
}

export default TemplateChip