import { outlinedInputClasses, styled, TextField } from '@mui/material'
import React from 'react'

const GenericTextField = styled((props) => (
    <TextField
        {...props}
    />
))(({ theme, iconType, iconVariant }) => ({
    padding: 0,
    backgroundColor: 'white',

    [`& .${outlinedInputClasses.notchedOutline}`]: {
        border: 'none',
        boxShadow: '0px 1px 3px #00000033',
        outline: 'none',
    },
    [`& .${outlinedInputClasses.input}`]: {
        padding: iconVariant === 'dollar' ? '10px 10px 10px 0' : 10,
    }
}))

export default GenericTextField