import React, { useState } from 'react'
import axios, { nodeAxios } from '../utils/axiosConfig';
import { toast } from 'react-toastify';

const useDeliveryFlow = ({ order, onEstimate }) => {

    const [isDeliveryLoading, setIsDeliveryLoading] = useState(false)
    const [isShippingLoading, setIsShippingLoading] = useState(false)
    const [shippingPartners, setShippingPartners] = useState([])
    const [showDeliveryOption, setShowDeliveryOption] = useState(false)
    const [estimate, setEstimate] = useState(0)
    const [drivers, setDrivers] = useState([])
    const [isDoordashDisabled, setIsDoordashDisabled] = useState(false)
    const [isEstimateLoading, setisEstimateLoading] = useState(false)
    const [pickupTimeEstimated, setPickupTimeEstimated] = useState('')
    const [orderDetails, setOrderDetails] = useState({})

    const fetchDrivers = () => {
        axios.get('/user/list')
            .then((res) => {
                setDrivers(res.data?.data?.result)
                setShowDeliveryOption(true)
                setIsDoordashDisabled(false)
            })
    }


    const fetchEstimate = () => {
        setisEstimateLoading(true)

        axios.get(`/driver/get-order-detail/${order?.Id}`)
            .then((orderDetailsRes) => {
                setOrderDetails(orderDetailsRes.data?.data?.results)
                nodeAxios.get('/doordash/estimate', {
                    params: {
                        orderId: order?.Id,
                        pickupInstructions: '',
                        isAlcohol: false
                    }
                })
                    .then((res) => {
                        setPickupTimeEstimated(res?.data?.data?.pickup_time_estimated)
                        setEstimate(res?.data?.data?.fee / 100)
                        console.log(parseFloat(order?.grand_total), (parseFloat(res?.data?.data?.fee) / 100) , parseFloat(order?.venuedrivertip || 0) + 2)
                        if (parseFloat(order?.grand_total) > ((parseFloat(res?.data?.data?.fee) / 100) + parseFloat(order.venuedrivertip) + 2)) {
                            fetchDrivers()
                        } else {
                            setIsDoordashDisabled(true)
                            axios.get('/user/list')
                                .then((res) => {
                                    setDrivers(res.data?.data?.result)

                                    if (res.data?.data?.result?.length > 1) {
                                        setShowDeliveryOption(true)
                                    } else {
                                        if(onEstimate){
                                            onEstimate()   
                                        }
                                    }
                                })
                        }
                    })
                    .catch((err) => {
                        setOrderDetails({})
                        axios.get('/user/list')
                            .then((res) => {
                                setIsDoordashDisabled(true)
                                setDrivers(res.data?.data?.result)

                                if (res.data?.data?.result?.length > 1) {
                                    setShowDeliveryOption(true)
                                } else {
                                    if(onEstimate){
                                        onEstimate()   
                                    }
                                }
                            })

                        err.response?.data?.message?.field_errors?.map((data) => {
                            toast.error(data.error)
                        })
                        toast.error(err?.response?.data?.message?.message)
                        toast.error(err?.response?.data?.message)
                    })
                    .finally(() => {
                        setisEstimateLoading(false)
                    })
            })

    }

    return {
        fetchDrivers,
        fetchEstimate,
        setShowDeliveryOption,
        isDoordashDisabled,
        drivers,
        estimate,
        showDeliveryOption,
        isEstimateLoading,
        pickupTimeEstimated,
    }
}

export default useDeliveryFlow