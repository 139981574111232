const initialState = {
    isLoading: false,
    isLoggedIn: false,
    categoryUpdate: {},
    categoryUpdateError: "",
    categoryDetailsUpdate:{},
    categoryDetailsUpdateError:{},
    newCategory:{},
    newCategoryError:"",
    ChangeCategoryLevel:{},
    changeCategoryLevelError:{}
};
 export  function categoryUpdate(state = initialState, action) {
    switch (action.type) {
        case "SET_CATEGORY_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_CATEGORY_LOADED":
            return { ...initialState, isLoading: false , categoryUpdate: action.payload };
        case "SET_CATEGORY_ERROR":
            return { ...initialState,categoryUpdateError:action.payload};
        default:
            return state;
    }
};
export  function categoryDetailsUpdate(state = initialState, action) {
    switch (action.type) {
        case "SET_CATEGORY_DETAILS_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_CATEGORY_DETAILS_LOADED":
            return { ...initialState, isLoading: false , categoryDetailsUpdate: action.payload };
        case "SET_CATEGORY_DETAILS_ERROR":
            return { ...initialState,categoryDetailsUpdateError:action.payload};
        default:
            return state;
    }
};
export  function addNewCategoey(state = initialState, action) {
    switch (action.type) {
        case "SET_ADD_CATEGORY_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_ADD_CATEGORY_LOADED":
            return { ...initialState, isLoading: false , newCategory: action.payload };
        case "SET_ADD_CATEGORY_ERROR":
            return { ...initialState,newCategoryError:action.payload};
        default:
            return state;
    }
};
export  function changeCategoryLevel(state = initialState, action) {
    switch (action.type) {
        case "SET_CATEGORY_CHANGE_LEVEL_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_CATEGORY_CHANGE_LEVEL_LOADED":
            return { ...initialState, isLoading: false , ChangeCategoryLevel: action.payload };
        case "SET_CATEGORY_CHANGE_LEVEL_ERROR":
            return { ...initialState,changeCategoryLevelError:action.payload};
        default:
            return state;
    }
};