import { IconButton, InputBase, Paper } from '@mui/material'
import React from 'react'
import styles from './InputField.module.scss'

const InputField = (props) => {
    return (
        <Paper
            style={props.style}
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: 40
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
            />

            {props.showIconButton && (
                <IconButton
                    sx={{ p: '10px' }}
                    onClick={props.onIconButtonClick}
                >
                    {props.endIcon}
                </IconButton>
            )}
        </Paper>
    )
}

export default InputField