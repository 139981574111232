import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorder } from '../../../CustomPageBuilder/EditSection/Util/helpers';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { Menu } from '@mui/icons-material';
import styles from './ReOrderDialog.module.scss'

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 5px',
    margin: `0 0 8px 0`,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});


const ReOrderDialog = (props) => {

    const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions)

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            selectedOptions,
            result.source.index,
            result.destination.index
        );

        setSelectedOptions(newitems)
    };


    return (
        <DialogContent
        style={{
            width: 300
        }}
        >

            <DialogTitle>
                Reorder Selection
            </DialogTitle>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.scroll}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {selectedOptions?.map((item, index) => {
                                return (
                                    <Draggable
                                        key={index.toString()}
                                        draggableId={index.toString()}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() => {

                                                }}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                )}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        borderRadius: 4,
                                                        border: '1px solid #D3D3D3',
                                                        padding: '8px 10px'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                            width: '100%',
                                                            gap: 5
                                                        }}
                                                    >
                                                        {item?.title}
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 6,
                                                            color: 'black',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <Tooltip
                                                            // style={{
                                                            //     width: 140
                                                            // }}
                                                            title="Drag item in order to arrange"
                                                            placement="top"
                                                        >
                                                            <Menu />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <DialogActions>
                <Button
                style={{
                    color: 'black'
                }}
                    variant="text"
                    onClick={() => {
                        setSelectedOptions([])
                        props.onClose()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: 20
                    }}
                    variant="contained"
                    onClick={() => {
                        props.setSelectedOptions(selectedOptions)
                        setSelectedOptions([])
                        props.onClose()
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    )
}

export default ReOrderDialog