import React from 'react'
import { useSelector } from 'react-redux';
import MyInventory from '../pages/MyInventory/MyInventory'

const MyInventoryRoute = () => {
  const user = useSelector((state) => state.user);

  return (
    <MyInventory isActionsDisabled={user?.data?.is_claim_business === 1} />
  )
}

export default MyInventoryRoute