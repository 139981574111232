import React from "react";
import { Button } from "@mui/material";

const GetStarted = (props) => {
    return (
        <Button
            style={{
                color: "white",
                backgroundColor: "#EA2B19",
                padding: props.small ? "9px 19px" : "11px 23px",
                outline: 'none',
                ...props.style
            }}
            onClick={props.action}
        >
            Get Started
        </Button>
    );
};

const OurFeatures = (props) => {
    return (
        <Button
            style={{
                color: "white",
                backgroundColor: "#171717",
                padding: props.small ? "9px 19px" : "9px 20px",
                outline: 'none',
                ...props.style
            }}
            onClick={props.action}
        >
            Our Features
        </Button>
    );
};


export default {
    GetStarted,
    OurFeatures
}