import React from 'react';
import { TextField } from '@mui/material';
import {makeStyles} from '@mui/styles';
import { AttachMoney } from '@mui/icons-material';
import {PercentageOutlined} from '@ant-design/icons';

const useStyles = makeStyles(() => ({
    textInput: {
        borderRadius: "0",
        width: "100%",
        padding: '0 !important',
        "& > input": {
            padding: "12px 10px 12px 10px !important",
        },
    }
}))

const ValueInput = (props) => {
    const classes = useStyles(props)
    return (
        <div style={{display: 'flex', flexDirection: props.type === "percentage" ? 'row-reverse' : 'row'}}>
            <div style={{display: 'flex', alignItems: 'center',backgroundColor: 'rgb(108,117,124)', justifyContent: 'center',marginTop: '1px',width: 50}}>
                {props.type === "percentage" ? <PercentageOutlined style={{height: '99%' ,color: 'white',display: 'flex',alignItems: 'center',fontWeight: 'bold',cursor: 'pointer'}} /> : <AttachMoney style={{height: '99%' ,color: 'white' ,cursor: 'pointer'}} />}
            </div>
            <TextField
                InputProps={{
                    classes: {
                        root: classes.textInput
                    }
                }}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                variant='outlined'
                style={{ width: "100%", margin: 'auto'}}
                required={props.required}
                type={props.texttype ? props.texttype : 'string'}
            />
        </div>
    )
};


export default ValueInput;