import { lazy } from 'react';
import { createBrowserRouter, useRoutes } from 'react-router-dom';

import Cookies from 'js-cookie'
import AccessYourWebsite from '../components/HelpPage/Pages/AccessYourWebsite';
import AddNewProduct from '../components/HelpPage/Pages/AddNewProduct';
import AddNewProductGroups from '../components/HelpPage/Pages/AddNewProductGroups';
import AssignTagstoProduct from '../components/HelpPage/Pages/AssignTagstoProduct';
import BankInformation from '../components/HelpPage/Pages/BankInformation';
import ChangingItemVisiblity from '../components/HelpPage/Pages/ChangingItemVisiblity';
import CreateCustomStorePage from '../components/HelpPage/Pages/CreateCustomStorePage';
import CreateNewBannerGroup from '../components/HelpPage/Pages/CreateNewBannerGroup';
import CreateNewTags from '../components/HelpPage/Pages/CreateNewTags';
import DragandDropBanners from '../components/HelpPage/Pages/DragandDropBanners';
import DragandDropProductGroupInterface from '../components/HelpPage/Pages/DragandDropProductGroupInterface';
import EditExistingBannerGroup from '../components/HelpPage/Pages/EditExistingBannerGroup';
import EditExistingProductGroup from '../components/HelpPage/Pages/EditExistingProductGroup';
import WhyRoute from '../components/Routes/WhyRoute';
import EditProductAttributes from '../components/HelpPage/Pages/EditProductAttributes';
import FindOrders from '../components/HelpPage/Pages/FindOrders';
import FulfillmentMethods from '../components/HelpPage/Pages/FulfillmentMethods';
import LiquorLicense from '../components/HelpPage/Pages/LiquorLicense';
import OrdersDetails from '../components/HelpPage/Pages/OrdersDetails';
import OrdersStatus from '../components/HelpPage/Pages/OrdersStatus';
import POSIntegration from '../components/HelpPage/Pages/POSIntegration';
import SearchProductbyCategory from '../components/HelpPage/Pages/SearchProductbyCategory';
import StoreDetails from '../components/HelpPage/Pages/StoreDetails';
import SubmitLogo from '../components/HelpPage/Pages/SubmitLogo';
import TransactionStatusReview from '../components/HelpPage/Pages/TransactionStatusReview';
import UploadInventory from '../components/HelpPage/Pages/UploadInventory';
import NotProtectedLayout from '../components/NotProtectedLayout/NotProtectedLayout';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import ClaimMyBusinessRoute from '../components/Routes/ClaimMyBusinessRoute';
import SignupRoute from '../components/Routes/SignupRoute';
import HelpPageRoute from '../components/Routes/HelpPageRoute';
import HowItWorksRoute from '../components/Routes/HowItWorksRoute';
import LoginRoute from '../components/Routes/LoginRoute';
import NotProtectedRoute from '../components/Routes/NotProtectedRoute';
import OurFeaturesRoute from '../components/Routes/OurFeaturesRoute';
import PosIntegrationsRoute from '../components/Routes/PosIntegrationsRoute';
import PricingRoute from '../components/Routes/PricingRoute';
import ManageListingRoute from '../components/Routes/ManageListingRoute';
import MarketingRoutes from '../components/Routes/MarketingRoutes';
import MyInventoryRoute from '../components/Routes/MyInventoryRoute';
import MenuRoute from '../components/Routes/MenuRoute';
import MyStoreSettingsNewRoute from '../components/Routes/MyStoreSettingsNewRoute';
import MyStoreSettingsRoute from '../components/Routes/MyStoreSettingsRoute';
import CustomizeHomepageRoute from '../components/Routes/CustomizeHomepageRoute';
import CustomPageInfoRoute from '../components/Routes/CustomPageInfoRoute';
import OrderDetailsRoute from '../components/Routes/OrderDetailsRoute';
import DashboardRoute from '../components/Routes/DashboardRoute';
import ProtectedRoute from '../components/Routes/ProtectedRoute';
import MarketingConnections from '../components/MarketingConnections/MarketingConnections';
import MyStoreSettingsNew from '../components/MyStoreSettingsNew/MyStoreSettingsNew';
import VenueOrderListingRoute from '../components/Routes/VenueOrderListingRoute';
import SocialMarketing from '../components/SocialMarketing/SocialMarketing';
import WebsitePerformance from '../components/WebsitePerformance/WebsitePerformance';
import AllMarketing from '../components/AllMarketing/AllMarketing';
import EmailConnection from '../components/EmailConnection/EmailConnection';
import EmailMarketing from '../components/EmailMarketing/EmailMarketing';
import routePaths from './route-paths';
import GiftCardHistoryRoute from '../components/Routes/GiftCardHistoryRoute';
import ThankYouRoute from '../components/Routes/ThankYouRoute';
import EditMenuRoute from '../components/Routes/EditMenuRoute';
// import CustomPageBuilderRoute from '../components/Routes/CustomPageBuilderRoute';
import WebsiteBuilderRoute from '../components/Routes/WebsiteBuilderRoute';
import ManageSite from '../components/ManageSite/ManageSite';
// import WebsiteSettings from '../components/ManageSite/WebsiteSettings/WebsiteSettings';
import WebsiteBuilderGetStarted from '../components/CustomPageBuilder/WebsiteBuilderGetStarted/WebsiteBuilderGetStarted';
import Template from '../components/CustomPageBuilder/Template/Template';
import SyncWebsite from '../components/SyncWebsite/SyncWebsite';
import FormsList from '../components/Forms/FormsList/FormsList';
import FormDetails from '../components/Forms/FormDetails/FormDetails';
import FormResponsesPage from '../components/pages/FormReponsesPage/FormReponsesPage';
import MarketingDashboard from '../components/Marketing2/MarketingDashboard/MarketingDashboard';
import MarketingPosts from '../components/Marketing2/MarketingPosts/MarketingPosts';
import MarketingPlanner from '../components/Marketing2/MarketingPlanner/MarketingPlanner';
import PostDetails from '../components/Marketing2/PostDetails/PostDetails';
import CreatePost from '../components/Marketing2/CreatePost/CreatePost';
import BlogsPage from '../components/Routes/Blogs';
import CreateBlogsPage from '../components/Routes/CreateBlogs';
import CategoryMapping from '../components/CategoryMapping/CategoryMapping';
import Customization from '../components/pages/Menu/Customization/Customization';
import Modifiers from '../components/Modifiers/Modifiers';
import Properties from '../components/Properties/Properties';
import CategoryTable from '../components/CategoryTable/CategoryTable';
import AuthorizeUnsplash from '../components/AuthorizeUnsplash/AuthorizeUnsplash';
import GettingStarted from '../components/GettingStarted/GettingStarted';
import HomeRoute from '../components/Routes/HomeRoute';
import Store from '../components/pages/Store/Store';
import StoreRoute from '../components/Routes/StoreRoute';
import OffersRoute from '../components/Routes/OffersRoute';
import GettingStartedRoute from '../components/Routes/GettingStartedRoute';
import AdminTemplateRoutes from '../components/Routes/AdminTemplateRoutes';
import ChangeLogRoute from '../components/Routes/ChangeLogRoutes';
import PaymentAccount from '../components/PaymentAccount/PaymentAccount';
import WebsiteBuilderLayout from '../components/WebsiteBuilderLayout/WebsiteBuilderLayout';
import OrderSettings from '../components/pages/OrderDetails/OrderSettings';
import RecoverPassword from '../components/RecoverPassword/RecoverPassword';
import EmailVerifySuccess from '../components/Routes/EmailVerifySuccess';
import EmailVerifyFailure from '../components/Routes/EmailVerifyFailure';
// import WebsitePageSettings from '../components/ManageSite/WebsitePageSettings/WebsitePageSettings';

const WebsitePageSettings = lazy(() => import('../components/ManageSite/WebsitePageSettings/WebsitePageSettings'));
// const AccessYourWebsite = lazy(() => import('../components/HelpPage/Pages/AccessYourWebsite'));
// const AddNewProduct = lazy(() => import('../components/HelpPage/Pages/AddNewProduct'));
// const AddNewProductGroups = lazy(() => import('../components/HelpPage/Pages/AddNewProductGroups'));
// const AssignTagstoProduct = lazy(() => import('../components/HelpPage/Pages/AssignTagstoProduct'));
// const BankInformation = lazy(() => import('../components/HelpPage/Pages/BankInformation'));
// const ChangingItemVisiblity = lazy(() => import('../components/HelpPage/Pages/ChangingItemVisiblity'));
// const CreateCustomStorePage = lazy(() => import('../components/HelpPage/Pages/CreateCustomStorePage'));
// const CreateNewBannerGroup = lazy(() => import('../components/HelpPage/Pages/CreateNewBannerGroup'));
// const CreateNewTags = lazy(() => import('../components/HelpPage/Pages/CreateNewTags'));
// const DragandDropBanners = lazy(() => import('../components/HelpPage/Pages/DragandDropBanners'));
// const DragandDropProductGroupInterface = lazy(() => import('../components/HelpPage/Pages/DragandDropProductGroupInterface'));
// const EditExistingBannerGroup = lazy(() => import('../components/HelpPage/Pages/EditExistingBannerGroup'));
// const EditExistingProductGroup = lazy(() => import('../components/HelpPage/Pages/EditExistingProductGroup'));
// const WhyRoute = lazy(() => import('../components/Routes/WhyRoute'));
// const EditProductAttributes = lazy(() => import('../components/HelpPage/Pages/EditProductAttributes'));
// const FindOrders = lazy(() => import('../components/HelpPage/Pages/FindOrders'));
// const FulfillmentMethods = lazy(() => import('../components/HelpPage/Pages/FulfillmentMethods'));
// const LiquorLicense = lazy(() => import('../components/HelpPage/Pages/LiquorLicense'));
// const OrdersDetails = lazy(() => import('../components/HelpPage/Pages/OrdersDetails'));
// const OrdersStatus = lazy(() => import('../components/HelpPage/Pages/OrdersStatus'));
// const POSIntegration = lazy(() => import('../components/HelpPage/Pages/POSIntegration'));
// const SearchProductbyCategory = lazy(() => import('../components/HelpPage/Pages/SearchProductbyCategory'));
// const StoreDetails = lazy(() => import('../components/HelpPage/Pages/StoreDetails'));
// const SubmitLogo = lazy(() => import('../components/HelpPage/Pages/SubmitLogo'));
// const TransactionStatusReview = lazy(() => import('../components/HelpPage/Pages/TransactionStatusReview'));
// const UploadInventory = lazy(() => import('../components/HelpPage/Pages/UploadInventory'));
// const NotProtectedLayout = lazy(() => import('../components/NotProtectedLayout/NotProtectedLayout'));
// const PrivacyPolicy = lazy(() => import('../components/PrivacyPolicy/PrivacyPolicy'));
// const ClaimMyBusinessRoute = lazy(() => import('../components/Routes/ClaimMyBusinessRoute'));
// const SignupRoute = lazy(() => import('../components/Routes/SignupRoute'));
// const HelpPageRoute = lazy(() => import('../components/Routes/HelpPageRoute'));
// const HowItWorksRoute = lazy(() => import('../components/Routes/HowItWorksRoute'));
// const LoginRoute = lazy(() => import('../components/Routes/LoginRoute'));
// const NotProtectedRoute = lazy(() => import('../components/Routes/NotProtectedRoute'));
// const OurFeaturesRoute = lazy(() => import('../components/Routes/OurFeaturesRoute'));
// const PosIntegrationsRoute = lazy(() => import('../components/Routes/PosIntegrationsRoute'));
// const PricingRoute = lazy(() => import('../components/Routes/PricingRoute'));
// const ManageListingRoute = lazy(() => import('../components/Routes/ManageListingRoute'));
// const MarketingRoutes = lazy(() => import('../components/Routes/MarketingRoutes'));
// const MyInventoryRoute = lazy(() => import('../components/Routes/MyInventoryRoute'));
// const MenuRoute = lazy(() => import('../components/Routes/MenuRoute'));
// const MyStoreSettingsNewRoute = lazy(() => import('../components/Routes/MyStoreSettingsNewRoute'));
// const MyStoreSettingsRoute = lazy(() => import('../components/Routes/MyStoreSettingsRoute'));
// const CustomizeHomepageRoute = lazy(() => import('../components/Routes/CustomizeHomepageRoute'));
// const CustomPageInfoRoute = lazy(() => import('../components/Routes/CustomPageInfoRoute'));
// const OrderDetailsRoute = lazy(() => import('../components/Routes/OrderDetailsRoute'));
// const DashboardRoute = lazy(() => import('../components/Routes/DashboardRoute'));
// const ProtectedRoute = lazy(() => import('../components/Routes/ProtectedRoute'));
// const MarketingConnections = lazy(() => import('../components/MarketingConnections/MarketingConnections'));
// const MyStoreSettingsNew = lazy(() => import('../components/MyStoreSettingsNew/MyStoreSettingsNew'));
// const VenueOrderListingRoute = lazy(() => import('../components/Routes/VenueOrderListingRoute'));
// const SocialMarketing = lazy(() => import('../components/SocialMarketing/SocialMarketing'));
// const WebsitePerformance = lazy(() => import('../components/WebsitePerformance/WebsitePerformance'));
// const AllMarketing = lazy(() => import('../components/AllMarketing/AllMarketing'));
// const EmailConnection = lazy(() => import('../components/EmailConnection/EmailConnection'));
// const EmailMarketing = lazy(() => import('../components/EmailMarketing/EmailMarketing'));
// const routePaths = lazy(() => import('./route-paths'));
// const GiftCardHistoryRoute = lazy(() => import('../components/Routes/GiftCardHistoryRoute'));
// const ThankYouRoute = lazy(() => import('../components/Routes/ThankYouRoute'));
// const EditMenuRoute = lazy(() => import('../components/Routes/EditMenuRoute'));
// const CustomPageBuilderRoute = lazy(() => import('../components/Routes/CustomPageBuilderRoute'));
// const WebsiteBuilderRoute = lazy(() => import('../components/Routes/WebsiteBuilderRoute'));
// const ManageSite = lazy(() => import('../components/ManageSite/ManageSite'));
const WebsiteSettings = lazy(() => import('../components/ManageSite/WebsiteSettings/WebsiteSettings'));
// const WebsiteBuilderGetStarted = lazy(() => import('../components/CustomPageBuilder/WebsiteBuilderGetStarted/WebsiteBuilderGetStarted'));
// const Template = lazy(() => import('../components/CustomPageBuilder/Template/Template'));
// const SyncWebsite = lazy(() => import('../components/SyncWebsite/SyncWebsite'));
// const FormsList = lazy(() => import('../components/Forms/FormsList/FormsList'));
// const FormDetails = lazy(() => import('../components/Forms/FormDetails/FormDetails'));
// const FormResponsesPage = lazy(() => import('../components/pages/FormReponsesPage/FormReponsesPage'));
// const MarketingDashboard = lazy(() => import('../components/Marketing2/MarketingDashboard/MarketingDashboard'));
// const MarketingPosts = lazy(() => import('../components/Marketing2/MarketingPosts/MarketingPosts'));
// const MarketingPlanner = lazy(() => import('../components/Marketing2/MarketingPlanner/MarketingPlanner'));
// const PostDetails = lazy(() => import('../components/Marketing2/PostDetails/PostDetails'));
// const CreatePost = lazy(() => import('../components/Marketing2/CreatePost/CreatePost'));
// const BlogsPage = lazy(() => import('../components/Routes/Blogs'));
// const CreateBlogsPage = lazy(() => import('../components/Routes/CreateBlogs'));
// const CategoryMapping = lazy(() => import('../components/CategoryMapping/CategoryMapping'));
// const Customization = lazy(() => import('../components/pages/Menu/Customization/Customization'));
// const Modifiers = lazy(() => import('../components/Modifiers/Modifiers'));
// const Properties = lazy(() => import('../components/Properties/Properties'));
// const CategoryTable = lazy(() => import('../components/CategoryTable/CategoryTable'));
// const AuthorizeUnsplash = lazy(() => import('../components/AuthorizeUnsplash/AuthorizeUnsplash'));
// const GettingStarted = lazy(() => import('../components/GettingStarted/GettingStarted'));
// const HomeRoute = lazy(() => import('../components/Routes/HomeRoute'));
// const Store = lazy(() => import('../components/pages/Store/Store'));
// const StoreRoute = lazy(() => import('../components/Routes/StoreRoute'));
// const OffersRoute = lazy(() => import('../components/Routes/OffersRoute'));
// const GettingStartedRoute = lazy(() => import('../components/Routes/GettingStartedRoute'));
// const AdminTemplateRoutes = lazy(() => import('../components/Routes/AdminTemplateRoutes'));
// const ChangeLogRoute = lazy(() => import('../components/Routes/ChangeLogRoutes'));
// const PaymentAccount = lazy(() => import('../components/PaymentAccount/PaymentAccount'));

const ProductGroupEditRoute = lazy(() =>
  import('../components/Routes/ProductGroupEditRoute')
);
const MyStoreInfoRoute = lazy(() =>
  import('../components/Routes/MyStoreInfoRoute')
);
const Coupons = lazy(() => import('../components/Coupons/Coupons'));
const CustomPageBuilder = lazy(() =>
  import('../components/WebsiteBuilderLayout/CustomPageBuilder/CustomPageBuilder')
);
const Order = lazy(() => import('../components/DriverOrder/Order'));
const OrderDashboard = lazy(() =>
  import('../components/DriverOrder/OrderDashboard')
);
const OrderDetails = lazy(() =>
  import('../components/DriverOrder/OrderDetails')
);
const CreateVenue = lazy(() => import('../components/CreateVenue/CreateVenue'));
const ProductMaster = lazy(() =>
  import('../components/ProductMaster/ProductMaster')
);
const CategoryList = lazy(() =>
  import('../components/CategoryList/CategoryList')
);
const VenueListing = lazy(() =>
  import('../components/VenueSetting/VenueListing')
);
const VenueDetails = lazy(() =>
  import('../components/VenueSetting/VenueDetails')
);
const VenueCreate = lazy(() =>
  import('../components/VenueSetting/VenueCreate')
);
const VenueSettingCreate = lazy(() =>
  import('../components/VenueAdminSetting/VenueCreate')
);
const VenueSettingDetails = lazy(() =>
  import('../components/VenueAdminSetting/VenueDetails')
);
const UserListing = lazy(() => import('../components/UserListing/UserListing'));
const CommingSoon = lazy(() => import('../components/helpers/CommingSoonPage'));

const StripeConnect = lazy(() =>
  import('../components/StripeConnect/StripeConnect')
);
const Payments = lazy(() => import('../components/Payments/Payments'));
const GiftCardListing = lazy(() =>
  import('../components/GiftCard/GiftCardListing')
);
const GiftCardCreate = lazy(() =>
  import('../components/GiftCard/GiftCardCreate')
);
const GiftCardEdit = lazy(() => import('../components/GiftCard/GiftCardEdit'));
const GiftCardHistory = lazy(() =>
  import('../components/GiftCard/GiftCardHistory')
);
const SetProductGroup = lazy(() =>
  import('../components/pages/ProductGroup/Set')
);
const ProductGroupListing = lazy(() =>
  import('../components/pages/ProductGroupListing/ProductGroupListing')
);
const OrderDetailsNew = lazy(() =>
  import('../components/pages/OrderDetails/OrderDetails')
);
const EmailSettings = lazy(() =>
  import('../components/pages/EmailSetting/EmailSetting')
);

const RoutesArr = () => {

  const scopes = Cookies.get('scopes')?.split(',') || []
  console.log({ scopes })
  const startsWith = (pathname) => {
    const protectedRoute = [];
    let result = false;
    protectedRoute?.forEach((route) => {
      const cleanRoute = route?.split('/')[0];        // family/childrens/881 => family
      const cleanPathname = pathname?.substring(1);  // /family/childrens/881 => family/childrens/881
      if (cleanPathname?.startsWith(cleanRoute)) {
        result = true;
      }
    });
    return result;
  }

  let childRoutes = [
    {
      path: routePaths.index,
      element: <NotProtectedRoute />,
    },
    {
      path: routePaths.gettingStarted,
      element: <GettingStartedRoute />,
    },
    {
      path: routePaths.restaurantGettingStarted,
      element: (
        <GettingStartedRoute
          businessTypeNo={3}
          type="Restaurant"
        />
      ),
    },
    {
      path: routePaths.login,
      element: <NotProtectedRoute />,
    },
    {
      path: routePaths.authorizeUnsplash,
      element: <AuthorizeUnsplash />,
    },
    {
      path: routePaths.recoverPassword,
      element: <RecoverPassword />,
    },
    {
      element: <NotProtectedLayout />,
      children: [
        {
          path: routePaths.signup,
          element: <SignupRoute />,
        },
        {
          path: routePaths.ourFeatures,
          element: <OurFeaturesRoute />,
        },
        {
          path: routePaths.integrations,
          element: <PosIntegrationsRoute />,
        },
        {
          path: routePaths.howItWorks,
          element: <HowItWorksRoute />,
        },
        {
          path: routePaths.whyOurLiquorStore,
          element: <WhyRoute />,
        },
        {
          path: routePaths.whyOurDesiStore,
          element: <WhyRoute />,
        },
        {
          path: routePaths.pricing,
          element: <PricingRoute />,
        },
        {
          path: routePaths.helpPage,
          element: <HelpPageRoute />,
        },
        {
          path: routePaths.addNewProduct,
          element: <AddNewProduct />,
        },
        {
          path: routePaths.createNewTags,
          element: <CreateNewTags />,
        },
        {
          path: routePaths.assigntagstoproduct,
          element: <AssignTagstoProduct />,
        },
        {
          path: routePaths.uploadInventory,
          element: <UploadInventory />,
        },
        {
          path: routePaths.searchProductbyCategory,
          element: <SearchProductbyCategory />,
        },
        {
          path: routePaths.editProductAttributes,
          element: <EditProductAttributes />,
        },
        {
          path: routePaths.changingItemVisiblity,
          element: <ChangingItemVisiblity />,
        },
        {
          path: routePaths.findOrders,
          element: <FindOrders />,
        },
        {
          path: routePaths.transactionStatusReview,
          element: <TransactionStatusReview />,
        },
        {
          path: routePaths.ordersDetails,
          element: <OrdersDetails />,
        },
        {
          path: routePaths.OrdersStatus,
          element: <OrdersStatus />,
        },
        {
          path: routePaths.AddNewProductGroups,
          element: <AddNewProductGroups />,
        },
        {
          path: routePaths.DragandDropProductGroupInterface,
          element: <DragandDropProductGroupInterface />,
        },
        {
          path: routePaths.EditExistingProductGroup,
          element: <EditExistingProductGroup />,
        },
        {
          path: routePaths.DragandDropBanners,
          element: <DragandDropBanners />,
        },
        {
          path: routePaths.CreateNewBannerGroup,
          element: <CreateNewBannerGroup />,
        },
        {
          path: routePaths.EditExistingBannerGroup,
          element: <EditExistingBannerGroup />,
        },
        {
          path: routePaths.CreateCustomStorePage,
          element: <CreateCustomStorePage />,
        },
        {
          path: routePaths.StoreDetails,
          element: <StoreDetails />,
        },
        {
          path: routePaths.FulfillmentMethods,
          element: <FulfillmentMethods />,
        },
        {
          path: routePaths.BankInformation,
          element: <BankInformation />,
        },
        {
          path: routePaths.LiquorLicense,
          element: <LiquorLicense />,
        },
        {
          path: routePaths.SubmitLogo,
          element: <SubmitLogo />,
        },
        {
          path: routePaths.POSIntegration,
          element: <POSIntegration />,
        },
        {
          path: routePaths.AccessYourWebsite,
          element: <AccessYourWebsite />,
        },
        {
          path: routePaths.privacyPolicy,
          element: <PrivacyPolicy />,
        },
        {
          path: routePaths.claimMyBusiness,
          element: <ClaimMyBusinessRoute />,
        },
      ],
    },
    {
      element: <ProtectedRoute />,
      children: [
        {
          path: routePaths.home,
          element: <HomeRoute />,
        },
        {
          path: routePaths.dashboard,
          element: <DashboardRoute />,
        },
        {
          path: routePaths.orderDetails,
          element: <OrderDetails />,
        },
        {
          path: routePaths.venueOrderListing,
          element: <VenueOrderListingRoute />,
        },
        {
          path: routePaths.emailSettings,
          element: <EmailSettings />,
        },
        {
          path: routePaths.paymentAccount,
          element: <PaymentAccount />,
        },
        {
          path: routePaths.createVenue,
          element: <CreateVenue />,
        },
        {
          path: routePaths.productMaster,
          element: <ProductMaster />,
        },
        {
          path: routePaths.categoryList,
          element: <CategoryList />,
        },
        {
          path: routePaths.bannerListing,
          element: <VenueListing />,
        },
        {
          path: routePaths.stripeThanks,
          element: <StripeConnect />,
        },
        {
          path: routePaths.venueDetails,
          element: <VenueDetails />,
        },
        {
          path: routePaths.venueCreate,
          element: <VenueCreate />,
        },
        {
          path: routePaths.venueSettingDetails,
          element: <VenueSettingDetails />,
        },
        {
          path: routePaths.venueSettingCreate,
          element: <VenueSettingCreate />,
        },
        {
          path: routePaths.commingSoon,
          element: <CommingSoon />,
        },
        {
          path: routePaths.myUsers,
          element: <UserListing />,
        },
        {
          path: routePaths.myStoreInfo,
          element: <MyStoreInfoRoute />,
        },
        {
          path: routePaths.myStoreSettingNew,
          element: <MyStoreSettingsRoute />,
        },
        {
          path: routePaths.coupons,
          element: <Coupons />,
        },
        {
          path: routePaths.products,
          element: <MyInventoryRoute />,
        },
        {
          path: routePaths.menu,
          element: <MenuRoute />,
        },
        {
          path: routePaths.offers,
          element: <OffersRoute />,
        },
        {
          element: <StoreRoute />,
          children: [
            {
              path: routePaths.store,
              element: <Store />,
            },
            {
              path: routePaths.orderSettings,
              element: <OrderSettings />,
            },
          ],
        },
        {
          path: routePaths.customization,
          element: <Customization />,
        },
        {
          path: routePaths.modifiers,
          element: <Modifiers />,
        },
        {
          path: routePaths.properties,
          element: <Properties />,
        },
        {
          path: routePaths.categories,
          element: <CategoryTable />,
        },
        {
          path: routePaths.payments,
          element: <Payments />,
        },
        {
          path: routePaths.giftCardListing,
          element: <GiftCardListing />,
        },
        {
          path: routePaths.giftCardCreate,
          element: <GiftCardCreate />,
        },
        {
          path: routePaths.giftCardEdit,
          element: <GiftCardEdit />,
        },
        {
          path: routePaths.giftCardHistory,
          element: <GiftCardHistoryRoute />,
        },
        {
          path: routePaths.productGroupListing,
          element: <ProductGroupListing />,
        },
        {
          path: routePaths.productGroup,
          element: <SetProductGroup />,
        },
        {
          path: routePaths.productGroupEdit,
          element: <ProductGroupEditRoute />,
        },
        {
          path: routePaths.customizeHomepage,
          element: <CustomizeHomepageRoute />,
        },
        {
          path: routePaths.createPage,
          element: <CustomPageInfoRoute />,
        },
        {
          element: <WebsiteBuilderRoute />,
          children: [
            {
              path: routePaths.websitegetStarted,
              element: <WebsiteBuilderGetStarted />,
            },
            {
              path: routePaths.websiteTemplates,
              element: <Template />,
            },
            {
              path: routePaths.manageSite2,
              element: <ManageSite />,
            },
            {
              path: routePaths.websiteSettings,
              element: <WebsiteSettings />,
            },
            {
              path: routePaths.websitePageSettings,
              element: <WebsitePageSettings />,
            },
            {
              path: routePaths.blog,
              element: <BlogsPage />,
            },
            {
              path: routePaths.createBlog,
              element: <CreateBlogsPage />,
            },
          ],
        },
        // {
        //   element: <CustomPageBuilderRoute />,
        //   children: [
        //     {
        //       path: routePaths.customPage,
        //       element: <CustomPageBuilder />,
        //     },
        //     {
        //       path: routePaths.customPageEdit,
        //       element: <CustomPageBuilder isEdit={true} />,
        //     },
        //   ],
        // },
        {
          path: routePaths.orderDetailsNew,
          element: <OrderDetailsRoute />,
        },
        {
          element: <MarketingRoutes />,
          children: [
            {
              path: routePaths.marketing,
              element: <AllMarketing />,
            },
            {
              path: routePaths.socialConnect,
              element: <MarketingConnections />,
            },
            {
              path: routePaths.emailConnect,
              element: <EmailConnection />,
            },
            {
              // path: routePaths.marketingSocial,
              element: <SocialMarketing />,
              children: [
                {
                  path: routePaths.marketingDashboard,
                  element: <MarketingDashboard />,
                },
                {
                  path: routePaths.marketingPosts,
                  element: <MarketingPosts />,
                },
                {
                  path: routePaths.marketingPlanner,
                  element: <MarketingPlanner />,
                },
                {
                  path: routePaths.marketingPostDetails,
                  element: <PostDetails />,
                },
                {
                  path: routePaths.marketingCreatePost,
                  element: <CreatePost />,
                },
              ],
            },
            {
              path: routePaths.marketingEmail,
              element: <EmailMarketing />,
            },
            {
              path: routePaths.webPerformance,
              element: <WebsitePerformance />,
            },
          ],
        },
        {
          path: routePaths.manageListing,
          element: <ManageListingRoute />,
        },
        {
          element: <MyStoreSettingsNewRoute />,
          children: [
            {
              path: routePaths.myStoreSetting,
              element: <MyStoreSettingsNew />,
            },
          ],
        },
        {
          path: routePaths.editMenu,
          element: <EditMenuRoute />,
        },
        {
          path: routePaths.syncWebsite,
          element: <SyncWebsite />,
        },
        {
          path: routePaths.forms,
          element: <FormsList />,
        },
        {
          path: routePaths.formEdit,
          element: <FormDetails />,
        },
        {
          path: routePaths.formResponses,
          element: <FormResponsesPage />,
        },
        {
          path: routePaths.adminTemplate,
          element: <AdminTemplateRoutes />,
        },
        {
          path: routePaths.changeLog,
          element: <ChangeLogRoute />,
        },
        {
          path: routePaths.emailVerifySuccess,
          element: <EmailVerifySuccess />,
        },
        {
          path: routePaths.emailVerifyFailure,
          element: <EmailVerifyFailure />,
        },
      ]
      // ?.filter((data) => !(scopes?.includes(data.path?.substring(1)) || startsWith(data.path))),
    },
    {
      path: routePaths.websiteBuilder,
      element: <WebsiteBuilderLayout />,
    }
  ];

  // return useRoutes(childRoutes);
  return createBrowserRouter(childRoutes);
};

export default RoutesArr;
