import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  buttonBaseClasses,
  inputBaseClasses,
  outlinedInputClasses,
  Radio,
  radioClasses,
  Select,
  selectClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const GenericSelect = styled((props) => (
  <Select IconComponent={ExpandMoreOutlined} {...props} />
))(({ theme, noBorder, noPadding }) => ({
  padding: 0,
  height: noPadding ? 'auto' : 38,
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    // minHeight: 36,
    border: noBorder ? 'none' : '1.5px solid #A8A8A8 !important',
  },
  [`& .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input}.${selectClasses.select}`]:
    {
      // padding: '4px 32px 4px 14px',
      padding: noPadding && '0px 34px 0px 14px',
    },
}));

export default GenericSelect;
