import React, { useState, useEffect } from "react";
import { Typography, Divider } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { usePrompt } from "../../../hooks/usePageLeave";
import Steps from "./Parts/Step/Steps";
import Customize from "./Parts/Customize/Customize";
import StoreInfo from "./Parts/StoreInfo/StoreInfo";
import BlackBtn from "./Parts/Buttons/BlackBtn";
import "./style.css";
import BankInfo from "./Parts/BankInfo/BankInfo";
import LiquorLicense from "./Parts/LiquorLicense/LiquorLicense";
import Branding from "./Parts/Branding/Branding";
import Integration from "./Parts/Integration/Integration";
import Website from "./Parts/Website/Website";
import {
  postBankingInfo,
  postBranding,
  postLicenseInfo,
  postIntegration,
  postStoreInfo,
  postWebsite,
} from "../../../servise/venueInfo";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  getVenueInfo,
  getBankingInfo,
  getLicenseInfo,
  getBranding,
} from "../../../redux/actions/venueInfoActions";
import {
  getVenueSetting,
  getVenueStates,
  updateVenueDetails,
} from "../../../redux/actions/VenueSetting";
import StoreDetails from "./Parts/StoreDetails/StoreDetails";
import { useNavigate } from "react-router-dom";
import Const from "../../helpers/const";
import { toast } from "react-toastify";
import GenericButton from "../../Library/GenericButton/GenericButton";
const percentage = "0";

const MyStoreSettings = (props) => {
  const [activePage, setActivePage] = useState("Store Details");

  const history = useNavigate();
  const venueInfo = useSelector((state) => state.venueInfo);
  const user = useSelector((state) => state.user);
  let dispatch = useDispatch((data) => data);

  const [isDirty, setIsDirty] = useState(false)
  // usePrompt("Are you sure want to discard changes?", isDirty);

  useEffect(() => {
    dispatch(getVenueSetting());
    dispatch(getVenueStates());
    dispatch(getVenueInfo());
    dispatch(getBankingInfo());
    dispatch(getLicenseInfo());
    dispatch(getBranding());
  }, []);
  let pageToRender = null;
  let saveFunction = () => { };
  let dispatchPayload = {};
  let validator = () => false;

  const save = async (data) => {
    try {
      validator();
      if (activePage === "Website") {
        await saveFunction(data);
        toast.success('Changes have been successfully saved')
        dispatch({
          type: "SET_SAVED_PAGE",
          payload: dispatchPayload,
        });
      } else if (activePage === "Store Details") {
        await saveFunction(data);
        toast.success('Changes have been successfully saved')
        dispatch({
          type: "SET_SAVED_PAGE",
          payload: dispatchPayload,
        });
      } else {
        await saveFunction()
        setIsDirty(false);
        dispatch({
          type: "SET_SAVED_PAGE",
          payload: dispatchPayload,
        });
        toast.success('Changes have been successfully saved')
      }
    } catch (error) {
      toast.error(error.message)
    }
  };
  switch (activePage) {
    case "Store Details":
      pageToRender = (
        <StoreDetails
          isActionsDisabled={props.isActionsDisabled}
          save={save}
          setPristine={() => setIsDirty(false)}
          setDirty={() => setIsDirty(true)}
        />
      );
      saveFunction = async (data) => {
        dispatch(updateVenueDetails(data)).then((res) => {
          dispatch(getVenueSetting("")).then(() => {
            toast.success('Changes have been succesfully saved')
          });
        });
      };
      dispatchPayload = {
        page: "Store Details",
        saved: true,
      };
      break;
    case "Fulfillment Methods":
      pageToRender = (
        <StoreInfo setPristine={() => setIsDirty(false)} setDirty={() => setIsDirty(true)} />
      );
      saveFunction = async () => {
        // venue_delivery_zone: [...venueInfo.storeInfo.venue.venue_delivery_zone],
        let deliveryDaysData = venueInfo.storeInfo.venue_hours?.filter((val) => ((val.venuetimings === 'delivery' && val.isclosed == "0") && (!Boolean(val.closedtimehours) || !Boolean(val.opentimehours))));

        if (deliveryDaysData?.length > 0) {
          throw new Error("Please select the hours for the days you are open.")
        }

        try {
          await postStoreInfo({
            ...venueInfo.storeInfo.venue,
            delivery_states: venueInfo.storeInfo.venue.delivery_states,
            venue_delivery_zone: venueInfo.storeInfo.venue.venue_delivery_zone.filter((zone) => Object.keys(zone).length === 5),
            venue_hours: venueInfo.storeInfo.venue_hours,
          });
        } catch (err) {
          if (err.response) {
            throw new Error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          }
          throw err;
        }
      };
      dispatchPayload = {
        page: "Fulfillment Methods",
        saved: true,
      };
      break;
    case "Banking Info":
      pageToRender = <BankInfo setPristine={() => setIsDirty(false)} setDirty={() => setIsDirty(true)} />;
      validator = () => {
        if (!venueInfo.bankingInfo.terms_accepted) {
          throw new Error("Accept Terms and Service");
        }
      };
      saveFunction = async () => {
        try {
          await postBankingInfo(venueInfo.bankingInfo);
        } catch (err) {
          if (err.response) {
            throw new Error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          }
          throw err;
        }
      };
      dispatchPayload = {
        page: "Banking Info",
        saved: true,
      };
      break;
    case "Business License":
      pageToRender = (
        <LiquorLicense setPristine={() => setIsDirty(false)} setDirty={() => setIsDirty(true)} />
      );
      saveFunction = async () => {
        try {
          await postLicenseInfo(venueInfo.licenseInfo);
        } catch (err) {
          if (err.response) {
            throw new Error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          }
          throw err;
        }
      };

      dispatchPayload = {
        page: "Business License",
        saved: true,
      };
      break;
    case "Branding":
      pageToRender = <Branding isActionsDisabled={props.isActionsDisabled} setPristine={() => setIsDirty(false)} setDirty={() => setIsDirty(true)} />;
      saveFunction = async () => {
        try {
          await postBranding(venueInfo.branding);
        } catch (err) {
          if (err.response) {
            throw new Error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          }
          throw err;
        }
      };
      dispatchPayload = {
        page: "Branding",
        saved: true,
      };
      break;
    case "Integration":
      pageToRender = <Integration />;
      saveFunction = async () => {
        try {
          await postIntegration();
        } catch (err) {
          if (err.response) {
            throw new Error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          }
          throw err;
        }
      };
      dispatchPayload = {
        page: "Integration",
        saved: true,
      };
      break;
    case "Website":
      pageToRender = (
        <Website isActionsDisabled={props.isActionsDisabled} save={save} setPristine={() => setIsDirty(false)} setDirty={() => setIsDirty(true)} />
      );
      saveFunction = async (data) => {
        try {
          await postWebsite({ base_url: data });
        } catch (err) {
          if (err.response) {
            throw new Error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          }
          throw err;
        }
      };
      dispatchPayload = {
        page: "Website",
        saved: true,
      };
      break;
    case "Add Products":
      history("/products");
  }
  useAuth();
  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div style={{ padding: "5px 20px" }}>
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <Typography
              variant="h4"
              style={{
                color: "black",
                fontWeight: "bold",
                marginBottom: 12,
              }}
            >
              {user.data?.profile_status?.profile_status === 100
                ? "Your Online store is ready to process orders"
                : "All Set! Your store is almost ready to be published"}
            </Typography>
            <Typography style={{ fontSize: "1.3rem", color: "#D3884B" }}>
              {user.data?.profile_status?.profile_status !== 100
                ? `Just give us a little bit of more info & your store
                            will be online in a jiffy.`
                : `Go to Our${Const.isOurDesiStore ? 'DesiStore' : 'LiquorStore'}.com (Select your store) or go to your personalized Website to see it in Action.`}
            </Typography>
          </div>
          <Divider style={{ marginTop: "25px" }} />
          <div
            className="getstarted-content-wrapper"
            style={{ margin: "30px 30px 10px 20px" }}
          >
            <div
              className="getstarted-progressbar"
              style={{
                position: "absolute",
                top: /* activePage === "Branding" ? "14%" : activePage === "Website" ? "17.5%" : "12.5%", */ 65,
                left: /* "86%" */ "90%",
                width: 75,
                height: 75,
                fontWeight: "bold",
              }}
            >
              <CircularProgressbar
                value={
                  user.data?.profile_status?.profile_status ||
                  parseInt(percentage)
                }
                text={`${user.data?.profile_status?.profile_status ||
                  parseInt(percentage)
                  }%`}
                strokeWidth={15}
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: "butt",
                  textSize: "24px",
                  fontWeight: "bold",
                  pathTransitionDuration: 0.5,
                  pathColor: `green`,
                  textColor: "black",
                  trailColor: "rgba(253,185,51,.3)",
                  backgroundColor: "#3e98c7",
                  border: "2px solid rgb(253,185,51)",
                  backgroundPadding: 10,
                })}
              />
            </div>
            <div
              className="getstarted-content-container"
              style={{ display: "flex" }}
            >
              <div
                className="getstarted-steps-wrapper"
                style={{
                  flex: 1,
                  position: "relative",
                  marginRight: "40px",
                }}
              >
                <Steps
                  setActivePage={setActivePage}
                  steps={user.data.profile_status?.steps || []}
                  activePage={activePage}
                />
                <div
                  className="getstarted-steps-divider"
                  style={{
                    height: "125%",
                    backgroundColor: "#E6E6E6",
                    width: "1px",
                    position: "absolute",
                    top: "-4.65%",
                    left: "100%",
                  }}
                ></div>
              </div>
              <div style={{ flex: 5 }}>{pageToRender}</div>
            </div>
            <div
              style={{
                display:
                  activePage === "Integration" ||
                    activePage === "Website" ||
                    activePage === "Store Details" ||
                    activePage === "Branding"
                    ? "none"
                    : "flex",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              <GenericButton
                variant="contained"
                disabled={!venueInfo.bankingInfo.terms_accepted || props.isActionsDisabled}
                onClick={() => save()}
                size="large"
              >
                Save
              </GenericButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyStoreSettings;
