import clsx from 'clsx'
import React from 'react'

const Input = ({
    label,
    id,
    type,
    placeholder,
    required,
    value,
    onChange,
    disabled,
    name,
    error,
    helperText,
    startContent,
    startContentClass,
    min,
    className,
    inputClass,
    helper
}) => {
    return (
        <div
            className={className}
        >
            {label && (
                <label
                    htmlFor={id}
                    className="tw-block tw-mb-1 tw-text-sm tw-font-semibold tw-text-[#626262]"
                >
                    {label}
                </label>
            )}
            <div
                className="tw-relative"
            >
                {Boolean(startContent) && (
                    <div className={clsx("tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-px-2.5", startContentClass)}>
                        {startContent}
                    </div>
                )}
                <input
                    type={type}
                    id={id}
                    name={name}
                    className={clsx("tw-bg-white tw-border tw-border-[#D3D3D3] tw-outline-none focus:tw-outline-none tw-text-black tw-text-sm tw-rounded tw-block tw-w-full tw-p-2.5", error && "tw-border-[#FF0000]", inputClass)}
                    min={min}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                />
            </div>
            {error && (
                <p className="tw-mt-0 tw-ml-1 tw-text-sm tw-text-[#FF0000]">{helperText}</p>
            )}
            {helper && (
                <p className="tw-mt-0 tw-ml-1 tw-text-sm tw-text-[#626262]">{helperText}</p>
            )}
        </div>
    )
}

export default Input