import React, { useEffect, useState } from "react";
import { Typography, Checkbox } from "@mui/material";
import UploadFile from "../UploadFile/UploadFile";
import { useSelector, useDispatch } from "react-redux";
import { getVenueInfo } from "../../../../../redux/actions/venueInfoActions";
import DefaultLogo from "../../../../../util_comps/DefaultLogo";
import SocialLinks from "./Parts/SocialLinks";
import { classname } from '../../../../../utils/bem'
import './Branding.scss';
import { postBranding } from "../../../../../servise/venueInfo";
import LaptopImage from '../../../../../assets/images/laptop.png'
import BlackBtn from "../Buttons/BlackBtn";
import { toast } from "react-toastify";
import axios from "../../../../../utils/axiosConfig";
import { bizBaseurl } from "../../../../../env";
import styles from './Branding.module.scss'
import EmailSettings from "../../../EmailSetting/EmailSetting";
import { getVenueSetting } from "../../../../../redux/actions/VenueSetting";
import { InsertDriveFile } from "@mui/icons-material";
import GenericButton from "../../../../Library/GenericButton/GenericButton";

const Branding = (props) => {

  const cn = classname('branding')
  const dispatch = useDispatch((data) => data);
  
  const [tab, setTab] = useState('branding')
  const venueInfo = useSelector((state) => state.venueInfo);
  
  const [activeModal, setActiveModal] = useState({ name: 'none', props: {} });
  const { branding, storeInfo } = useSelector((state) => state.venueInfo);

  const [tagId, setTagId] = useState('')
  const [analyticsId, setAnalyticsId] = useState('')
  const [merchantId, setMerchantId] = useState('')
  const [merchantJson, setMerchantJson] = useState('')
  const [files, setFiles] = useState(null);

  const storeImage = useSelector((store) => store.venueSetting.VenueSettingList?.data?.venue.image);

  useEffect(() => {
    if (storeInfo?.venue_social_links?.google_analytics_id) {
      setAnalyticsId(storeInfo?.venue_social_links?.google_analytics_id)
    }

    if (storeInfo?.venue_social_links?.google_tag_id) {
      setTagId(storeInfo?.venue_social_links?.google_tag_id)
    }
    if (storeInfo?.venue_social_links?.merchant_id) {
      setMerchantId(storeInfo?.venue_social_links?.merchant_id)
      setMerchantJson(storeInfo?.venue_social_links?.merchant_json)
    }
  }, [storeInfo])

  useEffect(() => {
    if (!storeInfo.venue?.Name) {
      dispatch(getVenueInfo());
    }
  }, []);
  const handleChange = (newVal) => {
    let isDirty = false;
    Object.entries(newVal).map(([key, val], i) => {
      if (val !== "") {
        isDirty = true;
      }
    });
    if (isDirty) {
      props.setDirty();
    } else {
      props.setPristine();
    }
    dispatch({
      type: "SET_BRANDING",
      payload: {
        ...branding,
        ...newVal,
      },
    });
  };

  const handleJsonChange = e => {
    setFiles(e.target.files[0]);
  };

  const handleBrandingSave = () => {
    postBranding(venueInfo.branding)
      .then(() => {
        dispatch(getVenueSetting("")).then(() => {
          toast.success('Saved')
        })
      })
      .catch(() => {
        toast.error('Error While Saving')
      })
  }

  const handleGoogleSave = () => {
    let fd = new FormData()
    if (analyticsId && analyticsId !== storeInfo?.venue_social_links?.google_analytics_id) {
      fd.append('google_analytics_id', analyticsId)
    }

    if (tagId && tagId !== storeInfo?.venue_social_links?.google_tag_id) {
      fd.append('google_tag_id', tagId)
    }

    if (files && !merchantId) {
      toast.error('Please Upload json file with Merchant ID')
      return
    }

    if (Boolean(merchantId) && !files) {
      return
    }

    if (merchantId && merchantId !== storeInfo?.venue_social_links?.merchant_id) {
      fd.append('merchant_id', merchantId)
      fd.append('merchant_json_file', files)
    }

    axios.post(`/venue/setting`, fd, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
      }
    }).then(() => {
      toast.success('Saved Successfully')
    })
  }

  return (
    <div>

      <div
        className={cn('tabs-container')}
      >
        <div
          className={cn('tab', {
            'is-active': tab === 'branding'
          })}
          onClick={() => {
            setTab('branding')
          }}
        >
          Branding
        </div>
        <div
          className={cn('tab', {
            'is-active': tab === 'social'
          })}
          onClick={() => {
            setTab('social')
          }}
        >
          Social Links
        </div>
        <div
          className={cn('tab', {
            'is-active': tab === 'google'
          })}
          onClick={() => {
            setTab('google')
          }}
        >
          Google id's
        </div>
        <div
          className={cn('tab', {
            'is-active': tab === 'email'
          })}
          onClick={() => {
            setTab('email')
          }}
        >
          Email Settings
        </div>

      </div>
      {tab === 'branding' && (
        <>
          <SocialLinks active={activeModal.name === "socLinks"} setActiveModal={setActiveModal} />
          <div style={{ width: '100vw', display: activeModal.name !== 'none' ? 'initial' : 'none', position: 'absolute', zIndex: '99999', background: 'rgba(0, 0, 0, 0.3)', height: '100%', top: 0, left: 0 }}></div>
          <div style={{ marginBottom: 35 }}>
            <Typography
              style={{
                color: "black",
                fontSize: "1.15rem",
                fontWeight: "bold",
              }}
            >
              Branding
            </Typography>
          </div>
          <div style={{ marginBottom: "45px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: !!storeImage ? "space-between" : "flex-end",
                marginBottom: "5px",
                paddingRight: 20
              }}
            >
              <div style={{ display: !!storeImage ? "block" : "none" }}>
                <img
                  src={storeImage}
                  style={{ width: "100%", maxHeight: "52px", maxWidth: "140px", objectFit: "cover" }}
                />
              </div>
              <div>
                <p
                  onClick={e => {
                    if (!props.isActionsDisabled) {
                      setActiveModal({ name: 'socLinks', props: {} })
                    }
                  }}
                  style={{
                    color: props.isActionsDisabled ? 'gray' : 'blue',
                    textDecoration: 'underline',
                    cursor: props.isActionsDisabled ? 'text' : 'pointer'
                  }}
                >
                  My Social Links
                </p>
              </div>
            </div>

            <Typography style={{ color: "black", marginBottom: "7px" }}>
              Upload Logo *
            </Typography>

            <div>
              <UploadFile
                filename={branding.logo_name}
                defaultFile={branding.image}
                setFile={(file) => {
                  if (file.size > 10e6) {
                    window.alert("Please upload a file smaller than 10 MB");
                    return false;
                  }
                  handleChange({
                    no_logo: false,
                    image: file,
                    logo_name: file.name,
                  });
                }}
              />
              <Typography
                style={{
                  color: "black",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Supports JPG/PNG/PDF, up to 10MB
              </Typography>
            </div>

            <Typography style={{ color: "black", marginBottom: "7px" }}>
              Upload Favicon *
            </Typography>
            <div>
              <UploadFile
                defaultFile={branding.favicon}
                selectedFile={branding.favicon_image}
                setFile={(file) => {
                  handleChange({
                    favicon_image: file,
                  });
                }}
              />
            </div>
          </div>
          <div
          // className={classes.noLogo}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <Checkbox
                checked={branding.no_logo}
                onChange={(e) =>
                  handleChange({
                    image: branding.no_logo ? branding.image : {},
                    no_logo: !branding.no_logo,
                    logo_name: "",
                  })
                }
                // className={classes.checkbox}
                disableRipple
              />
              <Typography style={{ color: "black" }}>
                I don't have a logo, use this as a logo
              </Typography>
            </div>
            <div
              style={{
                border: "1px solid grey",
                width: "100%",
                maxWidth: "245px",
                height: "91px",
                display: branding.no_logo ? "flex" : "none",
                marginLeft: "15px",
              }}
            >
              <DefaultLogo
                fontSize="1rem"
                color="white"
                name={storeInfo.venue?.Name || "Logo"}
              />
            </div>
          </div>
          <div
            className={cn('save-btn-container')}
          >
            <GenericButton
              variant="contained"
              onClick={handleBrandingSave}
              disabled={props.isActionsDisabled}
            >
              Save
            </GenericButton>
          </div>
        </>
      )}

      {tab === 'social' && (
        <SocialLinks isActionsDisabled={props.isActionsDisabled} isNotModal={true} />
      )}

      {tab === 'google' && (
        <div
          className={cn('google-container')}
        >
          <div
            className={cn('input-container')}
          >
            <div
              className={cn('input-items')}
            >
              <label>
                Google Tag Id
              </label>
              <input
                className={cn('input')}
                value={tagId}
                onChange={(event) => setTagId(event.target.value)}
              />

              <div
                style={{
                  position: 'relative',
                  marginTop: '10px',
                  width: '346px'
                }}
              >
                <img
                  className={cn('laptop-image')}
                  src={LaptopImage}
                />
                <iframe
                  style={{
                    width: '346px'
                  }}
                  className={cn('video-iframe')}
                  width="200"
                  height="187"
                  src="https://www.youtube.com/embed/JlYG2Svyz-4"
                  title="How to Create Tag Id"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>

            <div
              className={cn('input-items')}
            >
              <label>
                Google Analytics Id
              </label>
              <input
                className={cn('input')}
                value={analyticsId}
                onChange={(event) => setAnalyticsId(event.target.value)}
              />

              <div
                style={{
                  position: 'relative',
                  marginTop: '10px',
                  width: '346px'
                }}
              >
                <img
                  className={cn('laptop-image')}
                  src={LaptopImage}
                />
                <iframe
                  style={{
                    width: '346px'
                  }}
                  className={cn('video-iframe')}
                  width="200"
                  height="187"
                  src="https://www.youtube.com/embed/Clzshz42TaY"
                  title="How to Create Google analytics"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>

            <div
              className={cn('input-items')}
            >
              <label>
                Merchant Id
              </label>
              <input
                className={cn('input')}
                value={merchantId}
                onChange={(event) => setMerchantId(event.target.value)}
              />

              <div
                style={{
                  marginTop: '10px'
                }}
              >
                <label
                  className={cn('upload-json-label')}
                  htmlFor="inputjson"
                >
                  {merchantJson ? (
                    <div
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <div>
                        <InsertDriveFile />
                        File Exists
                      </div>

                      <div
                        style={{
                          color: '#ef2d2c'
                        }}
                      >
                        Change
                      </div>
                    </div>
                  ) : (
                    <div>
                      Upload Json File
                    </div>
                  )}

                </label>
                <input
                  id="inputjson"
                  style={{
                    // display: 'none'
                  }}
                  type="file"
                  accept=".json"
                  onChange={handleJsonChange}
                />
              </div>

              <div
                style={{
                  position: 'relative',
                  marginTop: '10px',
                  width: '346px'
                }}
              >
                <img
                  className={cn('laptop-image')}
                  src={LaptopImage}
                />
                <iframe
                  style={{
                    width: '346px'
                  }}
                  className={cn('video-iframe')}
                  width="200"
                  height="187"
                  src="https://www.youtube.com/embed/s8UwrT2xG4I"
                  title="How to Create Merchant Id"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>
          </div>

          <div
            className={cn('save-btn-container')}
          >
            <GenericButton
              variant="contained"
              onClick={handleGoogleSave}
              disabled={props.isActionsDisabled}
              size="large"
            >
              Save
            </GenericButton>
          </div>
        </div >
      )}

      {
        (tab === 'email') && (
          <div
            className={styles.email_setting_container}
          >
            <EmailSettings
              isActionsDisabled={props.isActionsDisabled}
            />
          </div>
        )
      }
    </div >
  );
};

export default Branding;
