import { Close } from '@mui/icons-material'
import { DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import Const from '../../helpers/const'
import styles from './GetWebsiteLive.module.scss'

const GetWebsiteLive = (props) => {
    return (
        <>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onClick={props.onClose}
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent>
                <div
                    className={styles.website_instructions_modal}
                >
                    <ol>
                        <li className={styles.website_instructions_list}>
                            Log in to your GoDaddy account.
                        </li>
                        <li className={styles.website_instructions_list}>
                            From the Top Menu, Select "My Account" and click on "Domains"
                            quick link.
                        </li>
                        <li className={styles.website_instructions_list}>
                            Select your domain by clicking on that Domain name, to access
                            the <b>Domain Settings</b> page.
                        </li>
                        <li className={styles.website_instructions_list}>
                            Under <b> Additional Settings </b>, select <b>Manage DNS</b>.
                        </li>
                        <li className={styles.website_instructions_list}>
                            If you have a CNAME Record, with Type "www", Click on Edit,
                            Otherwise Click Add.
                            <ul>
                                <li>
                                    <b>Type:</b> CNAME
                                </li>
                                <li>
                                    <b>Host: </b> www
                                </li>
                                <li>
                                    <b>Points to: hosting.Our{Const.isOurDesiStore ? 'DesiStore' : 'LiquorStore'}.com</b>
                                </li>
                                <li>
                                    <b>TTL: </b> Custom & Seconds: 600
                                </li>
                            </ul>
                            <div
                                style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                OR
                            </div>
                            <ul>
                                <li>
                                    <b>Type:</b> A
                                </li>
                                <li>
                                    <b>Host: </b> @
                                </li>
                                <li>
                                    <b>Points to: 3.233.84.79</b>
                                </li>
                                <li>
                                    <b>TTL: </b> Custom & Seconds: 600
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p className={styles.website_instructions_list}>
                        Walla, All Donel! It's that simple.
                    </p>
                    <p className={styles.website_instructions_list}>
                        Once all the above steps are performed, you can verify by going
                        to your website or following the steps below.
                    </p>
                </div>
            </DialogContent>
        </>
    )
}

export default GetWebsiteLive