const Const = {
  BASE_URL: process.env.REACT_APP_API_STORE_URL || "https://staging-biz-api.ourliquorstore.com/",
  GOOGLE_MAP_KEY: "AIzaSyAo0TBYHScMZOUiroGdDfFrzBo8ULEvZCA",
  isOurDesiStore:
    (process.env.REACT_APP_STORE_TYPE || "ourliquorstore") === "ourdesistore",
};

export default Const;

export const weekOrder = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

export const hourMinutesFormat = "hh:mm a";
