import { North, South } from '@mui/icons-material'
import { Chip } from '@mui/material'
import React from 'react'

const MovementIndicatorChip = (props) => {
    return (
        <div>
            <Chip
                style={{
                    color: props.isUp ? '#3D753D' : '#C04028',
                    height: 16
                }}
                icon={props.isUp ? <North style={{ fontSize: 'inherit', color: '#3D753D' }} /> : <South style={{ fontSize: 'inherit', color: '#C04028' }} />}
                label={props.value}
            />
        </div>
    )
}

export default MovementIndicatorChip