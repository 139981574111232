import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import EditProductAttributesimg from "../../../assets/Edit-Product-Attributes.png";
import EditProductAttributesdesistoreimg from "../../../assets/Edit-Product-Attributes-desistore.png";
import routePaths from '../../../config/route-paths';
const EditProductAttributes = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Product & Inventory               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.addNewProduct}>How to Add New Products</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.createNewTags} >Create New Tags</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.assigntagstoproduct}>Assign Tag to Product</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.uploadInventory}>Upload Inventory</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.searchProductbyCategory}>Search Product by Category</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.editProductAttributes} className={cn('selected')}>Edit Product Attributes</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.changingItemVisiblity}>Changing Item Visiblity</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Edit Product Attributes   
         </Typography>
         <p><strong>If you’d like to edit an existing product, such as the name, image, or description, you can do that from the ‘My Inventory’ page. You can also assign tags and set purchase limits.</strong></p>
         
         <p>
            
         <ol>
            <li>When viewing your inventory list from the ‘<strong>My Inventory</strong>’ page, you will notice small pencil icons next to product images, names, and descriptions.</li>

    <span className={cn('addnewproductsimg')}>
    <img src={Const.isOurDesiStore ? EditProductAttributesdesistoreimg : EditProductAttributesimg}/>
    </span>

    <li>Click on each pencil icon to edit the corresponding field. When you are done editing the name or description, press ‘<strong>Enter</strong>’ on your keyboard to save the changes.</li>

<li>On the right side each product, there will be icons for deletion, tags, and limits.</li>
<li>Clicking the tag icon allows you to assign a tag to the product for custom categorization.</li>
<li>Hover over the limit icon and click on the up and down arrows to set buying limits. All changes are saved immediately.</li>
         </ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default EditProductAttributes