import { LoadingButton, loadingButtonClasses } from "@mui/lab";
import { styled } from "@mui/material/styles";

const GenericLoadingButton = styled((props) => <LoadingButton endIcon={<div style={{ width: props.loading ? 15 : 0 }} />} {...props} />)(({ theme }) => ({
  [`&.${loadingButtonClasses.root}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${loadingButtonClasses.root}.Mui-disabled`]: {
    opacity: .5
  }
}));

export default GenericLoadingButton