import axios from 'axios'
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import routePaths from '../../config/route-paths'
import { checkAuth } from '../../redux/actions/userActions'
import { siteBaseUrl } from '../../utils/baseUrl'
import Layout from '../Layout/Layout'
import Loader from '../Library/Loader'
import { baseurl } from '../../env'

const ProtectedRoute = () => {

    const user = useSelector((store) => store.user);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [menu, setMenu] = useState([])
    const [categoryMenuBaseImageLink, setCategoryMenuBaseImageLink] = useState('')
    const [menuBaseImageLink, setMenuBaseImageLink] = useState('')
    const [open, setOpen] = useState(window.innerWidth > 600);

    const fetchCategoryMenu = () => {
        axios.get(`https://apiv1.esplanda.com/v2/category/menu`, {
            params: {
                'refresh-cache': 1,
                'venue_id': user?.data?.venue_id_integer
            }
        })
            .then((response) => {
                setMenu(response.data.data.category)
                setCategoryMenuBaseImageLink(response.data.data.category_image)
                setMenuBaseImageLink(response.data.data.category_image)
            })
    }

    useEffect(() => {

        dispatch(checkAuth())
            .then((response) => {
                window?.ReactNativeWebView?.postMessage(JSON.stringify({ isAppLoaded: 'true' }));
                if (window.location.pathname === '/') {
                    if (Boolean(response.isClaimBusiness)) {
                        navigate(routePaths.manageListing);
                        return
                    }

                    switch (response.venueType) {
                        case 'Ice Cream Truck':
                            navigate('/MyStoreSettings')
                            break;
                        default:
                            if (response.roleID === 1) {
                                navigate(routePaths.myStoreSetting);
                                return
                            } else if (response.roleID === 0) {
                                navigate(routePaths.commingSoon);
                                return
                            } else if (response.roleID === 2) {
                                // navigate(routePaths.orderDashboard);
                                navigate("/VenueOrderListing");
                                return
                            } else if (response.roleID === 3) {
                                navigate("/VenueOrderListing");
                                return
                            }
                            break;
                    }


                }
            })
            .catch(() => {
                localStorage.removeItem('userToken')
                window?.ReactNativeWebView?.postMessage(JSON.stringify({ isAppLoaded: 'true' }));
                navigate('/login', {
                    state: {
                        isMultiStore: searchParams.get('token') ? true : false,
                        token: searchParams.get('token')
                    }
                })
            })
            .finally(() => {
                window?.ReactNativeWebView?.postMessage(JSON.stringify({ isAppLoaded: 'true' }));
                setIsLoading(false)
            })

        fetchCategoryMenu()
    }, []);

    return (
        <Suspense
            fallback={<Loader />}
        >
            <Layout
                open={open}
                setOpen={setOpen}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <Outlet
                        context={{
                            menu,
                            categoryMenuBaseImageLink,
                            menuBaseImageLink,
                            open,
                            setOpen
                        }}
                    />
                )}
            </Layout>
        </Suspense>
    )
}

export default ProtectedRoute