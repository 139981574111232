import React from 'react'
import AdminTemplate from '../AdminTemplate/AdminTemplate'

const AdminTemplateRoutes = () => {
    return (
        <div>
            <AdminTemplate />
        </div>
    )
}

export default AdminTemplateRoutes