import React, { useEffect, useState } from 'react'
import axios from '../../../Util/axiosConfig'
import { Autocomplete, Chip, ListItem, ListItemText, TextField } from '@mui/material';
import styles from './HideCategoryOption.module.scss'

const HideCategoryOption = (props) => {

    const [selectedOptions, setSelectedOptions] = useState(props.value || []);
    const [options, setOptions] = useState([])

    const fetchCategory = () => {
        axios.get('/venue-category-order')
            .then((res) => {
                setOptions(res.data?.data?.results)
            })
    }

    useEffect(() => {
        fetchCategory()

    }, [])


    return (
        <div>
            <div
                className={styles.label_text}
            >
                Hide Categories
            </div>
            <Autocomplete
                multiple={true}
                options={options || []}
                value={selectedOptions || []}
                disableCloseOnSelect={true}
                onChange={(event, newValue) => {
                    setSelectedOptions(newValue);
                    props.onChange(JSON.stringify(newValue))
                }}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                        <Chip label={option?.MainCatName} {...getTagProps({ index })} />
                    ))
                }}
                renderInput={(params) => (
                    <TextField style={{height: 230, overflow: 'auto'}} {...params} placeholder="Select category" />
                )}
                renderOption={(props2, option, state) => {
                    return (
                        <ListItem {...props2} key={option}>
                            <ListItemText primary={option.MainCatName} />
                        </ListItem>
                    )
                }}
            />
        </div>
    )
}

export default HideCategoryOption