import { buttonBaseClasses, inputBaseClasses, outlinedInputClasses, Radio, radioClasses, Select, selectClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

const GenericSelect = styled((props) => (
    <Select
        {...props}
    />
))(({ theme }) => ({
    padding: 0,
    backgroundColor: 'white',
    [`& .${outlinedInputClasses.notchedOutline}`]:{
        minHeight: 50,
        border: 'none',
        boxShadow: '0px 1px 3px #00000033'
    },
    [`& .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input}.${selectClasses.select}`]:{
        padding: '12px 32px 12px 14px'
    }
}))

export default GenericSelect