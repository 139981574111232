import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import GetStoreIdDialog from '../GetStoreIdDialog/GetStoreIdDialog'
import styles from './ConnectStoreDialog.module.scss'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'

const ConnectStoreDialog = (props) => {

    const [showHowTo, setShowHowTo] = useState(false)

    const formik = useFormik({
        initialValues: {
            connect_id: ''
        },
        onSubmit: (values) => {
            axios.post('/venue/multistore-connect-request', values)
                .then(() => {

                })
                .catch(() => {
                    toast.error('Something went wrong')
                })
        }
    })

    return (
        <>
            <DialogTitle
                textAlign="center"
            >
                Please Enter the connect id of the store that you wish to connect
            </DialogTitle>

            <DialogContent>

                <GenericTextFieldWithLabel
                    label="Connect Store Id"
                    id="connect_id"
                    name="connect_id"
                    value={formik.values.connect_id}
                    onChange={formik.handleChange}
                    fullWidth={true}
                />

                <div
                    className={styles.how_to}
                    onClick={() => {
                        setShowHowTo(true)
                    }}
                >
                    How to get connect id for a store
                </div>

            </DialogContent>

            <DialogActions>
                <GenericButton
                    variant="contained"
                    onClick={formik.handleSubmit}
                >
                    Connect
                </GenericButton>

                <GenericButton
                    variant="contained"
                    onClick={props.onClose}
                    btnColor="secondary"
                >
                    Cancel
                </GenericButton>
            </DialogActions>

            <Dialog
                open={showHowTo}
                onClose={() => {
                    setShowHowTo(false)
                }}
            >
                <GetStoreIdDialog
                    onClose={() => {
                        setShowHowTo(false)
                    }}
                />
            </Dialog>
        </>
    )
}

export default ConnectStoreDialog