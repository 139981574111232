import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import BlackBtn from "../Buttons/BlackBtn";
import "./Website.css";
import { postVenueExternalUrl } from "../../../../../redux/actions/venueActions";
import { Modal } from "react-bootstrap";
import Const from "../../../../helpers/const";
import GenericButton from "../../../../Library/GenericButton/GenericButton";

const useStyles = makeStyles(() => ({
  input: {
    borderRadius: "3px",
    padding: "0 !important",
    "& fieldset": {
      border: "1px solid rgb(229,229,229)",
    },
    "& > input": {
      padding: "14px 9px !important",
    },
  },
  anchor: {
    textDecoration: "underline !important",
    color: "blue !important",
  },
  textContainer: {
    "@media only screen and (max-width: 768px)": {
      width: "90% !important",
    },
  },
}));

const Website = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch((data) => data);
  const { storeInfo } = useSelector((state) => state.venueInfo);
  const { VenueSettingList } = useSelector((store) => store.venueSetting);
  const [localWebsite, setLocalWebsite] = useState("");
  const [websiteAddress, setWebsiteAddress] = useState("");
  const [modal, setModal] = useState(false);

  const isMobile = useMediaQuery("(max-width: 650px)");

  const handleChange = (newVal) => {
    let isDirty = false;
    Object.entries(newVal).map(([key, val], i) => {
      if (val !== "") {
        isDirty = true;
      }
    });
    if (isDirty) {
      props.setDirty();
    } else {
      props.setPristine();
    }
    setLocalWebsite(newVal.base_url);
  };
  const save = () => {
    dispatch({
      type: "SET_STORE_INFO",
      payload: {
        ...storeInfo,
        venue: {
          ...storeInfo.venue,
          ...{ base_url: localWebsite },
        },
      },
    });
  };

  const handleSaveDomain = () => {
    dispatch(postVenueExternalUrl(websiteAddress));
  };

  return (
    <div>
      <div>
        <h3 className="website-haveyourdomain-title">
          Your temporary website is ready. Click below to access your website.
        </h3>
        <div className="website-venuebaseurl-link-container">
          <a
            className="website-venuebaseurl-link"
            href={`https://${storeInfo.venue?.base_url}.${Const.isOurDesiStore ? "OurDesiStore" : "OurLiquorStore"}.com`}
            target="__blank"
          >
            https://{storeInfo.venue?.base_url}.
            {Const.isOurDesiStore ? "OurDesiStore" : "OurLiquorStore"}.com
          </a>
        </div>
        <span
          className="ml-1"
          style={{
            color: "black",
          }}
        >
          {/* is your personalized website. Check it out & Do let us know your Domain name & we will connect this website to your domain. */}
        </span>
      </div>

      {storeInfo.venue?.external_website ? (
        <div>
          <p className="website-externalwebsite-label">
            Your website is:
            <a
              className="website-venuebaseurl-link"
              style={{
                marginLeft: "6px",
              }}
              href={`https://${storeInfo.venue?.external_website}`}
              target="__blank"
            >
              {storeInfo.venue?.external_website}
            </a>
          </p>
        </div>
      ) : (
        <div className="website-haveyourdomain-container">
          <h3 className="website-haveyourdomain-title">
            Have your own domain?
          </h3>

          <div className="website-haveyourdomain-websiteaddress-input">
            <label
              htmlFor="websiteaddress"
              className="website-haveyourdomain-label"
            >
              Please Enter your website address:
            </label>
            <TextField
              style={{
                width: isMobile ? "240px" : "417px",
                marginLeft: "10px",
                marginRight: "20px",
              }}
              placeholder="https://YourDomain.com"
              id="websiteaddress"
              name="websiteaddress"
              variant="outlined"
              value={websiteAddress}
              onChange={(e) => setWebsiteAddress(e.target.value)}
              InputProps={{
                classes: {
                  root: classes.input,
                },
              }}
            />
            <GenericButton
              variant="contained"
              onClick={handleSaveDomain}
              disabled={props.isActionsDisabled}
            >
              Save
            </GenericButton>
          </div>
        </div>
      )}

      <div className="website-instructions-container">
        <h3
          className="website-websitelive-title"
          onClick={() => setModal(true)}
        >
          How to Get your Website LIVE
        </h3>

        <Modal
          style={{
            padding: 0,
          }}
          show={modal}
          onHide={() => setModal(false)}
        >
          <Modal.Body>
            <div className="website-instructions-modal">
              <ol>
                <li className="website-instructions-list">
                  Log in to your GoDaddy account.
                </li>
                <li className="website-instructions-list">
                  From the Top Menu, Select "My Account" and click on "Domains"
                  quick link.
                </li>
                <li className="website-instructions-list">
                  Select your domain by clicking on that Domain name, to access
                  the <b>Domain Settings</b> page.
                </li>
                <li className="website-instructions-list">
                  Under <b> Additional Settings </b>, select <b>Manage DNS</b>.
                </li>
                <li className="website-instructions-list">
                  If you have a CNAME Record, with Type "www", Click on Edit,
                  Otherwise Click Add.
                  <ul>
                    <li>
                      <b>Type:</b> CNAME
                    </li>
                    <li>
                      <b>Host: </b> www
                    </li>
                    <li>
                      <b>Points to: hosting.Our{Const.isOurDesiStore ? 'DesiStore' : 'LiquorStore'}.com</b>
                    </li>
                    <li>
                      <b>TTL: </b> Custom & Seconds: 600
                    </li>
                  </ul>
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    OR
                  </div>
                  <ul>
                    <li>
                      <b>Type:</b> A
                    </li>
                    <li>
                      <b>Host: </b> @
                    </li>
                    <li>
                      <b>Points to: 3.233.84.79</b>
                    </li>
                    <li>
                      <b>TTL: </b> Custom & Seconds: 600
                    </li>
                  </ul>
                </li>
              </ol>
              <p className="website-instructions-list">
                Walla, All Donel! It's that simple.
              </p>
              <p className="website-instructions-list">
                Once all the above steps are performed, you can verify by going
                to your website or following the steps below.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
              }}
              variant="contained"
              onClick={() => setModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* <div
        className={classes.textContainer}
        style={{ width: "75%", marginTop: 20 }}
      >
        <Typography
          style={{
            fontSize: "1.9rem",
            color: "black",
            marginBottom: 30,
            fontWeight: 600,
          }}
        >
          Have your own Website? We can get it LIVE for you.
        </Typography>
        <Typography style={{ color: "black" }}>
          Your inventory is synced, your logo is uploaded. What’s next? It’s
          time to take your new site or shop page live! When you are ready to
          take your site live, you have three options:
        </Typography>
        <div style={{ marginLeft: "5%", margin: "20px 0" }}>
          <Typography style={{ color: "black" }}>
            1. <a className={classes.anchor}>Invite us to Godaddy or Google</a>{" "}
            If you have a GoDaddy or Google account to manage your domain, the
            easiest way to go live is to invite us to your account. Click the
            link to learn how.
          </Typography>
          <Typography style={{ color: "black", margin: "15px 0" }}>
            2. <a className={classes.anchor}>Setup your DNS Records</a> - If you
            know how to manage your DNS records for your domain, you can use the
            information in the article linked above to do so. Just be sure to
            call us when you’ve made the change and we’ll complete the setup.
          </Typography>
          <Typography style={{ color: "black" }}>
            3. <a className={classes.anchor}>Share Login for Your Domain</a> -
            If you’re using a host that doesn’t allow granting access to others
            to adjust the domain, you can send us the username and password and
            we’ll make the switch for you. Send us an email to
            <a style={{ marginLeft: "3px" }} className={classes.anchor}>
              support@ourliquorstore.com
            </a>{" "}
            with the following information:
          </Typography>
          <div style={{ marginLeft: "5%", marginTop: "20px" }}>
            <Typography style={{ color: "black" }}>1. Domain Name:</Typography>
            <Typography style={{ color: "black", margin: "15px 0" }}>
              2. Domain Host:
            </Typography>
            <Typography style={{ color: "black" }}>3. Credentials:</Typography>
          </div>
        </div>
        <Typography style={{ color: "black" }}>
          Once we receive the invite or the credentials, your site will be live
          within 24 hours!
        </Typography>
      </div> */}
    </div>
  );
};

export default Website;
