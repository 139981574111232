import React, { useState } from 'react'
import styles from './EmailConnection.module.scss'
import MailChimpIcon from '../../assets/images/mailchimpicon.jpg'
import GenericTextField from '../Library/GenericTextField/GenericTextField'
import { useFormik } from 'formik'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'
import axios from '../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { useNavigate, useOutletContext } from 'react-router-dom'
import routePaths from '../../config/route-paths'
import Mailchimp from '../../assets/images/mailchimp-ss.png'
import GenericButton from '../Library/GenericButton/GenericButton'

const EmailConnection = () => {

    const navigate = useNavigate()
    const { fetchSocialList, isActionsDisabled } = useOutletContext()

    const [showKeyGuide, setShowKeyGuide] = useState(false)
    const [showAudienceGuide, setShowAudienceGuide] = useState(false)
    const [showImage, setShowImage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleMailChimpConnect = () => {
        navigate(routePaths.marketingEmail)
    }

    const formik = useFormik({
        initialValues: {
            mailchimpkey: '',
            mailchimpid: ''
        },
        onSubmit: (values) => {
            setIsLoading(true)
            axios.post('/venue/setting', {
                mail_chimp_api_key: values.mailchimpkey,
                mail_chimp_audience_id: values.mailchimpid
            })
                .then(() => {
                    toast.success('Saved Successfully')
                    fetchSocialList(handleMailChimpConnect)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    })

    return (
        <div>
            <div
                className={styles.title}
            >
                Email marketing
            </div>

            <form
                className={styles.box}
                onSubmit={formik.handleSubmit}
            >
                <img
                    src={MailChimpIcon}
                    height={60}
                />

                <div
                    className={styles.fields_container}
                >
                    <div>
                        <GenericTextField
                            id="mailchimpkey"
                            name="mailchimpkey"
                            placeholder="Mailchimp Key"
                            fullWidth={true}
                            value={formik.values.mailchimpkey}
                            onChange={formik.handleChange}
                            helperText="How to get mailchimp key"
                            FormHelperTextProps={{
                                style: {
                                    textDecoration: 'underline',
                                    fontWeight: 600,
                                    cursor: 'pointer'
                                },
                                onClick: () => setShowKeyGuide(true)
                            }}
                            required={true}
                        />
                    </div>

                    {/* <div
                        className={styles.input_section}
                    >
                        <GenericTextField
                            id="mailchimpid"
                            name="mailchimpid"
                            placeholder="Audience ID"
                            fullWidth={true}
                            value={formik.values.mailchimpid}
                            onChange={formik.handleChange}
                            helperText="How to get audience id"
                            FormHelperTextProps={{
                                style: {
                                    textDecoration: 'underline',
                                    fontWeight: 600,
                                    cursor: 'pointer'
                                },
                                onClick: () => setShowAudienceGuide(true)
                            }}
                            required={true}
                        />
                    </div> */}

                    <div
                        className={styles.btn_section}
                    >
                        <GenericLoadingButton
                            type="submit"
                            loading={isLoading}
                            loadingPosition="end"
                            variant="contained"
                            disabled={isActionsDisabled}
                        >
                            Submit
                        </GenericLoadingButton>
                    </div>

                </div>
            </form>

            <Dialog
                open={showKeyGuide}
                onClose={() => setShowKeyGuide(false)}
            >
                <DialogTitle>
                    How to get mailchimp key
                </DialogTitle>

                <DialogContent>
                    <ul>
                        <li>
                            Login (or Create free account) on Mail chimp
                            <a
                            style={{
                                marginLeft: 5,
                                color: '#27748d'
                            }}
                                href="https://login.mailchimp.com/"
                                target="_blank"
                            >
                                https://login.mailchimp.com/
                            </a>
                        </li>
                        <li>
                            From Left Menu, Click on last item (on your name), and click on Profile.
                        </li>
                        <li>
                            Click on “Extra” tab & select “API Keys”.
                        </li>
                        <li>
                            Under “Your API Keys”, click on “Create a Key”
                        </li>
                        <li>
                            Copy that API key & paste it here.
                        </li>
                    </ul>

                    <div
                        className={styles.btn_container}
                    >
                        <div
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                setShowImage(true)
                            }}
                        >
                            See Image
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showImage}
                onClose={() => setShowImage(false)}
                maxWidth="xl"
            >
                <DialogContent>
                    <img
                        src={Mailchimp}
                        height={400}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={showAudienceGuide}
                onClose={() => setShowAudienceGuide(false)}
            >
                <DialogTitle>
                    How to get audience id
                </DialogTitle>

                <DialogContent>
                    <ul>
                        <li>Go to Audience</li>
                        <li>Select All Contact</li>
                        <li>Choose audience name and default option from Setting Menu</li>
                        <li>You will get Audience ID</li>
                    </ul>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default EmailConnection