import React from 'react'

const AppointmentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024" width="24" height="24">
            <path transform="translate(474)" d="m0 0h76l49 7 35 7 26 7 30 10 27 11 30 14 23 13 18 11 19 13 14 10 16 13 11 9 16 15 4 3v2l4 2 28 30 9 11 12 15 12 17 14 21 14 24 12 23 13 29 11 29 10 33 6 26 6 33 5 39v68l-5 40-6 34-6 25-8 27-9 26-12 28-9 19-12 22-10 17-12 18-12 17-13 16-8 10-9 10-11 12-23 23-8 7-15 13-17 13-15 11-20 13-22 13-23 12-25 12-34 13-29 9-28 7-32 6-35 5-8 1h-71l-22-3-40-6-31-7-31-9-28-10-26-11-19-9-22-12-20-12-12-8-17-12-13-10-11-9-14-12-17-16-19-19-7-8-13-15-13-17-11-15-12-18-12-20-12-22-12-25-11-27-12-36-7-27-7-36-5-35-1-9v-77l7-48 8-38 9-31 9-26 11-26 14-29 15-27 11-17 14-20 10-13 11-14 11-12 14-16h2l2-4 8-8 8-7 7-7 11-9 13-11 16-12 13-9 15-10 17-10 18-10 16-8 26-12 27-10 28-9 28-7 36-7z" fill="#00D8A9" />
            <path transform="translate(288,229)" d="m0 0h1l1 49 1 17 3 5 4 2h11l5-4 2-6 1-61 1-1h54l280-1 1 60 2 8 5 4 8 2 6-3 4-5 1-11 1-54h115l20 4 14 7 6 5v2l4 2 1 3 4 4 6 12 3 12v506l-3 16-7 14-8 10-11 9-12 6-16 3h-568l-15-3-16-8-14-14-9-17-3-13-1-16 1-67v-321l-1-38 1-71 1-5h2l2-10 8-12 8-8 12-7 9-3 10-2z" fill="#FBFBFB" />
            <path transform="translate(288,229)" d="m0 0h1l1 49 1 17 3 5 4 2h11l5-4 2-6 1-61 1-1h54l280-1 1 60 2 8 5 4 8 2 6-3 4-5 1-11 1-54h115l20 4 14 7 6 5v2l4 2 1 3 4 4 6 12 3 12v506l-3 16-7 14-8 10-11 9-12 6-16 3h-568l-15-3-16-8-14-14-9-17-3-13-1-16 1-67v-321l-1-38 1-71 1-5h2l2-10 8-12 8-8 12-7 9-3 10-2zm-72 165-1 7 1 325 1 34 4 10 4 5 10 6 4 1 188 1h277l79-1 8-2 9-6 5-8 2-13 1-356-1-2-126-1z" fill="#E2E5EB" />
            <path transform="translate(288,229)" d="m0 0h1l1 49 1 17 3 5 4 2h11l5-4 2-6 1-61 1-1h54l280-1 1 60 2 8 5 4 8 2 6-3 4-5 1-11 1-54h115l20 4 14 7 6 5v2l4 2 1 3 4 4 6 12 3 12v68l-4 1h-672l-5-1-1-2v-70l1-5h2l2-10 8-12 8-8 12-7 9-3 10-2z" fill="#FC5A6B" />
            <path transform="translate(171,718)" d="m0 0h1l1 60 8 16 6 8 7 7 14 7 12 3 5 1h438l139-1 12-3 9-4 11-8 1-2h2l2-4 7-11 3-3 2-6 1-13h1v22l-3 16-7 14-8 10-11 9-12 6-16 3h-568l-15-3-16-8-14-14-9-17-3-13-1-16z" fill="#C0CAD6" />
            <path transform="translate(803,389)" d="m0 0h8l2 1 2 311-1 39-1 21-3 8-6 8-9 8-8 3-8 1-57 1-477-1-11-2-9-5-9-10-4-8-2-9v-184l1-13v-168zm-587 5-1 7 1 325 1 34 4 10 4 5 10 6 4 1 188 1h277l79-1 8-2 9-6 5-8 2-13 1-356-1-2-126-1z" fill="#C0CAD6" />
            <path transform="translate(171,347)" d="m0 0 1 2h681v25h-1l-1-13-31 1-47-1h-22l-32-1h-44l-39-1h-263l-21 1h-77l-33 1h-63l-7-1v26h-1l-1-38z" fill="#C0CAD6" />
            <path transform="translate(325,180)" d="m0 0h10l12 3 10 5 7 7 5 9 2 8 1 19h-54l-1 61-3 7-5 4h-11l-6-4-2-4-1-17v-60l3-15 6-9 9-8 8-4z" fill="#E1E5EA" />
            <path transform="translate(689,180)" d="m0 0h8l16 4 10 7 6 7 4 10 1 5v16l12 1v1h-32l-34-1v56l-1 9-6 7-6 2-9-3-5-6-1-6v-73l3-12 4-8 9-9 10-5z" fill="#E1E5EA" />
            <path transform="translate(684,553)" d="m0 0h72v72l-5 1h-66l-1-1z" fill="#FD5C6D" />
            <path transform="translate(651,229)" d="m0 0h1v55h-1v-37h-42l-145-1h-137l-1-14-2-2h48z" fill="#F98795" />
            <path transform="translate(268,433)" d="m0 0h71l1 14-1 58-15 1h-56l-1-1v-71z" fill="#FD5B6C" />
            <path transform="translate(406,673)" d="m0 0h72l1 70-1 2-18 1h-52l-2-2z" fill="#FD5C6C" />
            <path transform="translate(311,553)" d="m0 0h27l1 72h-71v-71z" fill="#C0CAD6" />
            <path transform="translate(594,673)" d="m0 0h21l1 1 1 71h-70l-1-1v-70z" fill="#C0CAD6" />
            <path transform="translate(268,674)" d="m0 0h70l1 1v18l-1 52h-70z" fill="#C1CBD7" />
            <path transform="translate(407,434)" d="m0 0h70l1 2v69h-71z" fill="#C0CAD6" />
            <path transform="translate(602,433)" d="m0 0h13l1 1v71h-70v-71z" fill="#C0CAD6" />
            <path transform="translate(685,674)" d="m0 0h70v70l-1 1-68 1-1-1z" fill="#C1CBD7" />
            <path transform="translate(546,554)" d="m0 0h70v71h-70z" fill="#C0CAD6" />
            <path transform="translate(685,434)" d="m0 0h70l1 5-1 66-69 1-1-25z" fill="#C0CAD6" />
            <path transform="translate(407,554)" d="m0 0h71l-1 70-23 1-43 1-3-1-1-13z" fill="#C0CAD6" />
            <path transform="translate(173,363)" d="m0 0h6l3 2 1 8 2-1 3-6 1-3h11l-2 8-7 2v5l-3 2-1-2-3 5h3l1 4-1 8 1 1v5l-5 5-1 11h2v6l-3-1v13l-3 6h2v7l-2 4 1 5v36l-1 12 2 17-3 7 3 1-1 10 1 5v27l-1 11 1 11-1 21 2 23-1 6v17l2 1v7 1 26l1 29 1 1 1 15 3 9v12l1 7-4 9-5 1-1 8h2l-1 3-3-2-5-10-1-71v-289l1-52z" fill="#E4E2EB" />
            <path transform="translate(816,349)" d="m0 0h37l-1 4-39 1-159 1h-414l-38-1-9-1 1-2 47-1z" fill="#F5F6F7" />
            <path transform="translate(803,389)" d="m0 0h8l1 2-1 216h-2l-1-106v-72l1-32h-2v-2h-588l-3-1-1 128-1 8h-1v-139h549l38-1z" fill="#A5B7C5" />
            <path transform="translate(216,394)" d="m0 0h475l116 1 1 2-1 356-2 13-7 10-9 5-6 1-79 1h-277l-188-1v-1l487-1 61-1 8-4 6-7 2-4 1-17v-29l1-95 1-226h-589v24l-2 3v-23z" fill="#E2E5EB" />
            <path transform="translate(689,180)" d="m0 0h8l16 4 10 7 6 7 4 10 1 5v16l12 1v1h-32l-1-1-2-15-2-6-4-2-5-4h-7l-6 3-6 7-6-1-9 15-4 2-5 5-5 2v-20l3-12 4-8 9-9 10-5z" fill="#E6E3EC" />
            <path transform="translate(325,180)" d="m0 0h10l12 3 10 5 7 7 5 9 2 8 1 19h-22l-1-2-1-15-4-6-7-5h-7l-6 3-5 4-2-5-5 2-2 3h-2l-2 9-1 7h-2l-1 4-6 7-4 1-2 4-1-2v-22l3-15 6-9 9-8 8-4z" fill="#E6E3EC" />
            <path transform="translate(231,781)" d="m0 0 196 1h212l22 1h110l21-2v2l-3 2-17 2h-484l-45-1-10-2z" fill="#AFC4D0" />
            <path transform="translate(686,230)" d="m0 0h109l20 4 14 7 2 3-2 2-5 1-26-1-4 1h-99l-4-1-1-15z" fill="#FB8794" />
            <path transform="translate(288,229)" d="m0 0h1l1 49v17h-1l-1-14-1-30-1-3-9-1h-72l-14-1-6 7h-2l2-4 7-7 12-7 9-3 10-2z" fill="#F88895" />
            <path transform="translate(171,347)" d="m0 0 1 2 69 1v1l-47 1 8 1 52 1h116l-3 2h-44l-63 1h118l214 1v1h-219l-21 1h-77l-33 1h-63l-7-1v26h-1l-1-38z" fill="#BDC9D5" />
            <path transform="translate(323,355)" d="m0 0h353l39 1v1l-104 1h-354l3-2z" fill="#9DB0BF" />
            <path transform="translate(851,276)" d="m0 0h1l1 5v68l-4 1h-672l-5-1v-1h579l99-1 1-16z" fill="#FF8693" />
            <path transform="translate(330,202)" d="m0 0h7l9 7 3 5 1 15-1 1h-30l-3-2v-12l4-7 5-5z" fill="#00D9A9" />
            <path transform="translate(693,202)" d="m0 0h7l9 7 3 6 1 7v8h-30l-4-2v-13l4-6 7-6z" fill="#00D9A9" />
            <path transform="translate(803,389)" d="m0 0h8l1 2-1 216h-2l-1-106v-72l1-32h-2v-3h-45l2-1v-2l36-1z" fill="#ADC3CF" />
            <path transform="translate(816,349)" d="m0 0h37l-1 4-39 1h-99v-1l16-1-178-1v-1z" fill="#DFE4E9" />
            <path transform="translate(171,347)" d="m0 0 1 2 69 1v1l-47 1 8 1 52 1h116l-3 2h-44l-101 1h-49l-2-1-1-8z" fill="#BECAD6" />
            <path transform="translate(231,781)" d="m0 0 9 1 121 1 23 1 1 2-29 1h-68l-45-1-10-2z" fill="#AEC4D0" />
            <path transform="translate(241,350)" d="m0 0h112v1l-61 1 56 1v1l-108 1-38-1-9-1 1-2z" fill="#E2E6EB" />
            <path transform="translate(722,194)" d="m0 0 3 4v3h2l2 3 2-2 3 11v16l12 1v1h-32l-1-1v-6l4-1 1-4 4-1 2-4h2l3 3 1-5h-2l-6-12-1-5z" fill="#DEE2E8" />
            <path transform="translate(676,355)" d="m0 0h107l10 1 2 2h-184v-1l65-1z" fill="#ACC2CE" />
            <path transform="translate(368,204)" d="m0 0 2 4 1 4 1 19h-22l-1-2 1-5 5-2 3-7 3 1v2h2l1-4-1-8h3l1 2z" fill="#E0E4EA" />
            <path transform="translate(240,782)" d="m0 0h187l53 1v1l-96 1-23-1-121-1z" fill="#C1CBD7" />
            <path transform="translate(851,276)" d="m0 0h1l1 5v68l-4 1h-98l1-2 98-1 1-16z" fill="#F98895" />
            <path transform="translate(171,356)" d="m0 0h89l-4 3h-80l-4-1v28h-1z" fill="#B0C4D0" />
            <path transform="translate(803,389)" d="m0 0h8l1 5-4-3v6l-1-3h-45l2-1v-2l36-1z" fill="#AFC4D0" />
            <path transform="translate(746,355)" d="m0 0h37l10 1 2 2h-50z" fill="#AEC4D0" />
            <path transform="translate(335,182)" d="m0 0h9l10 4 4 3-1 3-4-1-5-1-8-3-5-3z" fill="#F8F9F9" />
            <path transform="translate(175,802)" d="m0 0h2l5 10 4 6 5 5 3 5h-3l-8-8-8-15z" fill="#DDE2E8" />
            <path transform="translate(336,197)" d="m0 0 6 1 3 6-1 4-7-5-7-1 4-4z" fill="#E0E5EA" />
            <path transform="translate(700,199)" d="m0 0 5 1 5 5 1 4-2 2v-2l-4-2-5-4z" fill="#E0E5EA" />
            <path transform="translate(325,180)" d="m0 0h10l6 1v1h-6v2l5 2 8 3v1l-8-1-5-1v-2l-4-2 3-1v-2h-9z" fill="#DBE0E7" />
            <path transform="translate(362,197)" d="m0 0 4 1 2 4v7l-2-1v-2l-3-1z" fill="#F6F6F8" />
            <path transform="translate(797,356)" d="m0 0h15l16 1v1l-23 1-9-1z" fill="#AEC4D0" />
            <path transform="translate(244,356)" d="m0 0h16l-4 3h-11z" fill="#AABFCC" />
            <path transform="translate(722,193)" d="m0 0 4 1 4 6v5l-3-2v-2h-2z" fill="#F3F4F6" />
            <path transform="translate(315,286)" d="m0 0h1v10l-3 4-2-1 2-2v-9z" fill="#F8F9F9" />
            <path transform="translate(200,763)" d="m0 0 4 1 3 3-1 2-1-1-7-1v-3z" fill="#E7E4ED" />
            <path transform="translate(739,355)" d="m0 0h7l-1 3h-8l-3-2z" fill="#A8BDCA" />
        </svg>
    )
}

export default AppointmentIcon