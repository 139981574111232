import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import ordertabsimg from "../../../assets/order-tabs.png";
import routePaths from '../../../config/route-paths';
const OrdersStatus = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Orders & Payments               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.findOrders}>Find Orders</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.transactionStatusReview}>Transaction Status Review</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.ordersDetails}>Order Details</Link></li>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.OrdersStatus} className={cn('selected')}>Order Status</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Orders Status
         </Typography>
         <p><strong>You can view orders placed through your website and app and filter them based on their status.</strong></p>
         <p>
            
         <ol>
            <li>Go to the ‘<strong>Orders</strong>’ page of the admin website.</li>

            <li>At the top of the page, below the Start Date and End Date boxes, you will see some filters.</li>
            
            <span className={cn('addnewproductsimg')}>
    <img src={ordertabsimg} />
    </span>
    
   
    <li>These filters are <strong>‘All’, ‘Open’, ‘Accepted’, ‘Ready’, ‘Closed’, and ‘Cancelled’.</strong></li>

<li>Click on each filter to view the orders corresponding to each status filter. If you have many orders at once, you can clear the clutter
and only view the ones you need to fulfill.</li>

<li>To view all your orders again, simply select the ‘<strong>All</strong>’ filter.</li>

</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default OrdersStatus