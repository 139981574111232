import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { DeleteForever, Upload as UploadIcon } from '@mui/icons-material'
import styles from './ImageUpload.module.scss'
import { Checkbox, Dialog, DialogContent, FormControlLabel, FormGroup, IconButton, Menu, MenuItem } from '@mui/material'
import axios from '../../../Util/axiosConfig'
import GenericSwitchWithLabel from '../GenericSwitchWithLabel/GenericSwitchWithLabel'
import LinkSelector from '../LinkSelector/LinkSelector'
import ImageGallery from '../ImageGallery/ImageGallery'
import Loader from '../Loader/Loader'
import WebsiteEditorContext from '../../../../../utils/context/websiteEditorContext'

const ImageUpload = forwardRef(({ imageSrc, setImageSrc, ...props }, ref) => {

    const { pages } = useContext(WebsiteEditorContext)
    const imageRef = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const [imageData, setImageData] = useState({})
    const [isImageLink, setIsImageLink] = useState(Boolean(props.imageLink))
    const [showImageGallery, setShowImageGallery] = useState(false)

    const [showListMenuEl, setShowListMenuEl] = useState(false);
    const showListMenu = Boolean(showListMenuEl);

    const handleOpenListMenu = (event) => {
        event.stopPropagation();
        setShowListMenuEl(event.currentTarget);
    };
    const handleCloseListMenu = (event) => {
        event.preventDefault();
        setShowListMenuEl(null);
    };

    useImperativeHandle(ref, () => ({

        imageDelete() {
            setImageData({})
            setImageSrc('')
        }

    }));

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const image = new Image();
            image.onload = () => {
                const width = image.width;
                const height = image.height;
                setImageData({
                    height: height,
                    width: width,
                    name: file.name
                })
            };
            image.src = e.target.result;
        };

        reader.readAsDataURL(file);
        const fd = new FormData()
        fd.append('image_file', event.target.files[0])
        setIsLoading(true)
        axios.post('/web-builder/web-page/upload-image', fd)
            .then((res) => {
                props.onUpload(`${res.data?.data?.base_image}/${res.data?.data?.content}`)
                setImageSrc(`${res.data?.data?.base_image}/${res.data?.data?.content}`)
                setIsLoading(false);
            }).catch((err) => {
                // console.log(err); 
                setIsLoading(false)
            })
        event.target.value = ''
    }

    const handleImageGalleryUpload = async ({ image, width, height, name }) => {

        props.onUpload(image)
        setImageSrc(image)
         setImageData({
                        height: height,
                        width: width,
                        name: name
                    })

        setShowImageGallery(false)

        // const reader = new FileReader();
        // reader.onload = (e) => {
        //     const img = new Image();
        //     img.onload = () => {
        //         const width = img.width;
        //         const height = img.height;
        //         setImageData({
        //             height: height,
        //             width: width,
        //             name:  name
        //         })
        //     };
        //     img.src = e.target.result;
        // };

        // // reader.readAsText(imageData);
        // console.log('🚀OUTPUT --> reader:', reader);

        // setIsLoading(true)
        // const fd = new FormData()
        // fd.append('image_file', imageData)
        // axios.post('/web-builder/web-page/upload-image', fd)
        //     .then((res) => {
        //         props.onUpload(`${res.data?.data?.base_image}/${res.data?.data?.content}`)
        //         setImageSrc(`${res.data?.data?.base_image}/${res.data?.data?.content}`)
        //         setIsLoading(false);
        //     }).catch((err) => { 
        //         // console.log(err); 
        //         setIsLoading(false)
        //      })
    }

    return (
        <div>
            <input
                style={{
                    display: 'none'
                }}
                accept="image/*"
                ref={imageRef}
                type="file"
                onChange={handleImageChange}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    className={styles.label_text}
                >
                    {props.label}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 15
                    }}
                    id='image-button'
                    aria-controls={showListMenu ? 'image' : undefined}
                    aria-haspopup='true'
                    aria-expanded={showListMenu ? 'true' : undefined}
                    onClick={handleOpenListMenu}
                    // onClick={() => {
                    //     imageRef.current?.click()
                    // }}
                >
                    {imageSrc ? (
                        <div
                            className={styles.add_image_container}
                        >
                            {isLoading ? <Loader /> : (
                                <img
                                    className={styles.image_link}
                                    src={imageSrc}
                                />
                            )}

                            {/* {!imageData?.name && (
                                <IconButton
                                    className={styles.delete_container}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        props.onDelete()
                                        setImageData({})
                                        setImageSrc('')
                                    }}
                                >
                                    <DeleteForever style={{ color: '#FF3333' }} />
                                </IconButton>
                            )} */}
                        </div>
                    ) : (
                        <div
                            className={styles.add_image_container}
                        >
                            {isLoading ? <Loader /> : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src="https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/addimage.png-1689616360.png"
                                        height={30}
                                        width={30}
                                    />
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            marginTop: 5,
                                            color: '#0D7AEA'
                                        }}
                                    >
                                        {props.title}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: 14,
                                            color: '#8c8c8c'
                                        }}
                                    >
                                        PNG, JPG or WPEG
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {imageData?.name && (
                        <div
                            style={{
                                fontSize: 14
                            }}
                        >
                            <div
                                className={styles.name_container}
                            >
                                <span
                                    style={{
                                        color: '#8D8D8D'
                                    }}
                                >
                                    Name:
                                </span>
                                {imageData.name}
                            </div>
                            <div>
                                <span
                                    style={{
                                        color: '#8D8D8D'
                                    }}
                                >
                                    Dimension:
                                </span>
                                {imageData?.height} x {imageData?.width}
                            </div>
                        </div>
                    )}
                </div>
                {(imageSrc || imageData?.name) && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontSize: 14,
                                marginTop: 15,
                                marginBottom: 10
                            }}
                        >
                            <div
                                style={{
                                    color: '#8D8D8D',
                                    fontWeight: 600,
                                    cursor: 'pointer'
                                }}
                                id='image-button'
                                aria-controls={showListMenu ? 'image' : undefined}
                                aria-haspopup='true'
                                aria-expanded={showListMenu ? 'true' : undefined}
                                onClick={handleOpenListMenu}
                                // onClick={() => {
                                //     imageRef.current?.click()
                                // }}
                            >
                                REPLACE
                            </div>

                            <div
                                style={{
                                    color: '#CE2C30',
                                    fontWeight: 600,
                                    cursor: 'pointer'
                                }}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    props.onDelete()
                                    setImageData({})
                                    setImageSrc('')
                                }}
                            >
                                REMOVE
                            </div>
                        </div>
                        {!props.hideImageLink && (
                            <div>
                                <GenericSwitchWithLabel
                                    label="Link this Image"
                                    height={18}
                                    width={36}
                                    thumb={13}
                                    translateX={18}
                                    default
                                    checked={isImageLink}
                                    onChange={(event) => {
                                        setIsImageLink(Boolean(event.target.checked))
                                    }}
                                />

                                {isImageLink && (
                                    <div>
                                        <LinkSelector
                                            variant="outlined"
                                            label="Link image to"
                                            colorType="secondary"
                                            value={props.imageLink}
                                            onChange={props.onImageLinkChange}
                                            fullWidth={true}
                                            pages={pages}
                                        />

                                        <FormGroup>
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        size="small"
                                                        checked={props.imageTarget === '_blank'}
                                                        onChange={props.onImageTargetChange}
                                                    />
                                                )}
                                                label="Open link in a new page"
                                            />
                                        </FormGroup>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                <Menu
                    id='image'
                    anchorEl={showListMenuEl}
                    open={showListMenu}
                    onClose={handleCloseListMenu}
                    classes={{ paper: styles.menu_container }}
                    MenuListProps={{
                        'aria-labelledby': 'image-button',
                    }}
                    transformOrigin={{
                        horizontal: 'left',
                        vertical: 'top',
                    }}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                >
                    <MenuItem onClick={(e) => { imageRef.current?.click(); handleCloseListMenu(e); }}>
                        <UploadIcon />
                        Upload File
                    </MenuItem>
                    <MenuItem onClick={() => setShowImageGallery(true)} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M17 10C18.6569 10 20 8.65685 20 7C20 5.34315 18.6569 4 17 4C15.3431 4 14 5.34315 14 7C14 8.65685 15.3431 10 17 10Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7 20C8.65685 20 10 18.6569 10 17C10 15.3431 8.65685 14 7 14C5.34315 14 4 15.3431 4 17C4 18.6569 5.34315 20 7 20Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 14H20V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H15C14.7348 20 14.4804 19.8946 14.2929 19.7071C14.1054 19.5196 14 19.2652 14 19V14ZM4 4H10V9C10 9.26522 9.89464 9.51957 9.70711 9.70711C9.51957 9.89464 9.26522 10 9 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V4Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Select from library
                    </MenuItem>
                </Menu>
            </div>

            <Dialog
                open={showImageGallery}
                classes={{ paper: styles.image_gallery_paper }}
                onClose={() => setShowImageGallery(false)}
            >
                <DialogContent>
                    <ImageGallery handleImageGalleryUpload={handleImageGalleryUpload} onClose={() => setShowImageGallery(false)} />
                </DialogContent>
            </Dialog>
        </div>
    )
})

export default ImageUpload