export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';

export const POST_NEW_COUPON_REQUEST = 'POST_NEW_COUPON_REQUEST';
export const POST_NEW_COUPON_SUCCESS = 'POST_NEW_COUPON_SUCCESS';
export const POST_NEW_COUPON_FAILURE = 'POST_NEW_COUPON_FAILURE';

export const POST_UPDATE_COUPON_REQUEST = 'POST_UPDATE_COUPON_REQUEST';
export const POST_UPDATE_COUPON_SUCCESS = 'POST_UPDATE_COUPON_SUCCESS';
export const POST_UPDATE_COUPON_FAILURE = 'POST_UPDATE_COUPON_FAILURE';