import React, { useRef, useState } from "react";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../util_comps/CustomButton";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { createTag } from "../../../../redux/actions/tagListActions";
import { Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  textInput: {
    borderRadius: "0",
    width: "100%",
    padding: "0 7px !important",
    border: "none",
    "& > input": {
      padding: `11px 6px !important`,
    },
  },
}));

const Modal = (props) => {
  const modalRef = useRef(null);
  const classes = useStyles();
  const [tagName, setTagName] = useState("");
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    severity: "",
    open: false,
    text: "",
  });
  const handleClose = () => {
    if (props.active) {
      setTagName("");
      props.setActiveModal({ name: "none", props: {} });
    }
  };
  const save = () => {
    dispatch(createTag({ title: tagName }));
    props.setActiveModal({ name: "none", props: {} });
    toast.success('Tag Created Successfully')
  };

  return (
    <Dialog
      open={props.active}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
      >
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            fontWeight: 600
          }}
        >
          Product Tags
        </Typography>

        <IconButton
          aria-label="close"
          onClick={props.closeIsTagsModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: "20px 28px 28px 28px" }}>
          <div>
            <div>
              <Typography
                variant="h6"
                style={{ fontSize: "1rem", marginBottom: "10px" }}
              >
                Add New Tag
              </Typography>
              <TextField
                variant="outlined"
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                style={{ width: "100%", margin: "auto" }}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "27px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <PrimaryButton
              onClick={save}
              style={{
                padding: "10px 25px",
                fontSize: ".9rem",
                marginRight: "10px",
              }}
            >
              Save
            </PrimaryButton>
            <SecondaryButton
              onClick={handleClose}
              style={{
                padding: "10px 25px",
                fontSize: ".9rem",
                marginRight: "10px",
              }}
            >
              Cancel
            </SecondaryButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
