const getOptions = (items) => items.map(item => ({ id: item }));

export const styleSector = [
    {
        type: 'image',
        array: [
            {
                open: false,
                id: 'dimension',
                name: 'Dimension',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-width',
                        property: 'width',
                    },
                ],
            },
            {
                id: 'dropshadow',
                open: false,
                name: 'Drop shadow',
                properties: [
                    'box-shadow',
                ],
            },
            {
                id: 'roundcorners',
                open: false,
                name: 'Round corners',
                properties: [
                    {
                        label: 'Border Radius',
                        full: true,
                        type: 'my-custom-border-radius',
                        property: 'border-radius',
                    },
                ],
            },
            {
                id: 'border',
                open: false,
                name: 'Border',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-border',
                        property: 'border',
                    },
                ],
            },
            {
                id: 'spacing',
                open: false,
                name: 'Spacing',
                properties: [
                    {
                        label: 'Padding',
                        full: true,
                        type: 'my-custom-padding',
                        property: 'padding',
                    },
                    {
                        label: 'Margin',
                        full: true,
                        type: 'my-custom-margin',
                        property: 'margin',
                    },
                ],
            },
            {
                id: 'backgroundpos',
                open: false,
                name: 'Background Pos',
                properties: [
                    {
                        type: 'radio',
                        property: 'object-fit',
                        label: 'Object fit',
                        options: [
                            { id: 'contain', label: 'Contain' },
                            { id: 'fill', label: 'Fill' },
                            { id: 'cover', label: 'cover' },
                        ]
                    },
                    {
                        label: 'Background Pos',
                        property: 'background-position',
                        default: 'left top',
                        type: 'select',
                        full: true,
                        options: getOptions([
                            'left top',
                            'left center',
                            'left bottom',
                            'right top',
                            'right center',
                            'right bottom',
                            'center top',
                            'center center',
                            'center bottom',
                        ]),
                    },
                ],
            },
        ]
    },
    {
        type: 'audio',
        array: [
            {
                open: false,
                id: 'dimension',
                name: 'Dimension',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-width',
                        property: 'width',
                    },
                ],
            },
            {
                id: 'dropshadow',
                open: false,
                name: 'Drop shadow',
                properties: [
                    'box-shadow',
                ],
            },
            {
                id: 'roundcorners',
                open: false,
                name: 'Round corners',
                properties: [
                    {
                        label: 'Border Radius',
                        full: true,
                        type: 'my-custom-border-radius',
                        property: 'border-radius',
                    },
                ],
            },
            {
                id: 'border',
                open: false,
                name: 'Border',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-border',
                        property: 'border',
                    },
                ],
            },
        ]
    },
    {
        type: 'video',
        array: [
            {
                open: false,
                id: 'dimension',
                name: 'Dimension',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-width',
                        property: 'width',
                    },
                ],
            },
            {
                id: 'dropshadow',
                open: false,
                name: 'Drop shadow',
                properties: [
                    'box-shadow',
                ],
            },
            {
                id: 'roundcorners',
                open: false,
                name: 'Round corners',
                properties: [
                    {
                        label: 'Border Radius',
                        full: true,
                        type: 'my-custom-border-radius',
                        property: 'border-radius',
                    },
                ],
            },
            {
                id: 'border',
                open: false,
                name: 'Border',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-border',
                        property: 'border',
                    },
                ],
            },
        ]
    },
    {
        type: 'button',
        array: [
            {
                id: 'roundcorners',
                open: false,
                name: 'Round corners',
                properties: [
                    {
                        label: 'Border Radius',
                        full: true,
                        type: 'my-custom-border-radius',
                        property: 'border-radius',
                    },
                ],
            },
            {
                id: 'border',
                open: false,
                name: 'Border',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-border',
                        property: 'border',
                    },
                ],
            },
            {
                id: 'spacing',
                open: false,
                name: 'Spacing',
                properties: [
                    {
                        label: 'Padding',
                        full: true,
                        type: 'my-custom-padding',
                        property: 'padding',
                    },
                    {
                        label: 'Margin',
                        full: true,
                        type: 'my-custom-margin',
                        property: 'margin',
                    },
                ],
            },
            {
                id: 'background',
                open: false,
                name: 'Background',
                properties: [
                    {
                        label: 'Background Color',
                        property: 'background-color',
                        default: 'none',
                        type: 'my-custom-color',
                        full: true,
                    },
                ],
            },
            {
                id: 'fontstyle',
                open: false,
                name: 'Font Style',
                properties: [
                    {
                        label: 'Text Color',
                        property: 'color',
                        default: 'none',
                        type: 'my-custom-color',
                        full: true,
                    },
                    {
                        type: 'select',
                        label: 'Font family',
                        property: 'font-family',
                        full: true,
                        options: [
                            { value: 'Roboto' },
                            { value: 'Lato' },
                            { value: 'Bubblegum Sans' },
                            { value: 'Poppins' },
                            { value: 'Raleway' },
                        ]
                    },
                    {
                        full: true,
                        type: 'select',
                        label: 'Font Size',
                        property: 'font-size',
                        options: [
                            { id: '36px', label: 'Heading1' },
                            { id: '30px', label: 'Heading2' },
                            { id: '26px', label: 'Heading3' },
                            { id: '22px', label: 'Heading4' },
                            { id: '18px', label: 'Heading5' },
                            { id: '16px', label: 'Paragraph' },
                            { id: '14px', label: 'Body' },
                        ]
                    },
                    {
                        full: true,
                        type: 'select',
                        label: 'Font Weight',
                        property: 'font-weight',
                        options: [
                            { id: '400', label: 'thin' },
                            { id: '500', label: 'medium' },
                            { id: '600', label: 'bold' },
                            { id: '700', label: 'X-bold' },
                        ]
                    },
                    {
                        id: 'dropshadow',
                        open: false,
                        name: 'Drop shadow',
                        properties: [
                            'box-shadow',
                        ],
                    },
                ],
            },
        ]
    },
    {
        type: 'rowdiv',
        array: [
            {
                open: false,
                id: 'dimension',
                name: 'Dimension',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-width',
                        property: 'width',
                    },
                ],
            },
            {
                id: 'dropshadow',
                open: false,
                name: 'Drop shadow',
                properties: [
                    'box-shadow',
                ],
            },
            {
                id: 'roundcorners',
                open: false,
                name: 'Round corners',
                properties: [
                    {
                        label: 'Border Radius',
                        full: true,
                        type: 'my-custom-border-radius',
                        property: 'border-radius',
                    },
                ],
            },
            {
                id: 'border',
                open: false,
                name: 'Border',
                properties: [
                    {
                        label: '',
                        full: true,
                        type: 'my-custom-border',
                        property: 'border',
                    },
                ],
            },
            {
                id: 'spacing',
                open: false,
                name: 'Spacing',
                properties: [
                    {
                        label: 'Padding',
                        full: true,
                        type: 'my-custom-padding',
                        property: 'padding',
                    },
                    {
                        label: 'Margin',
                        full: true,
                        type: 'my-custom-margin',
                        property: 'margin',
                    },
                ],
            },
            {
                id: 'background',
                open: false,
                name: 'Background',
                properties: [
                    {
                        label: 'Background Color',
                        property: 'background-color',
                        default: 'none',
                        type: 'my-custom-color',
                        full: true,
                    },
                ],
            },
            {
                id: 'fontstyle',
                open: false,
                name: 'Font Style',
                properties: [
                    {
                        label: 'Text Color',
                        property: 'color',
                        default: 'none',
                        type: 'my-custom-color',
                        full: true,
                    },
                    {
                        type: 'select',
                        label: 'Font family',
                        property: 'font-family',
                        full: true,
                        options: [
                            { value: 'Roboto' },
                            { value: 'Lato' },
                            { value: 'Bubblegum Sans' },
                            { value: 'Poppins' },
                            { value: 'Raleway' },
                        ]
                    },
                    {
                        full: true,
                        type: 'select',
                        label: 'Font Size',
                        property: 'font-size',
                        options: [
                            { id: '36px', label: 'Heading1' },
                            { id: '30px', label: 'Heading2' },
                            { id: '26px', label: 'Heading3' },
                            { id: '22px', label: 'Heading4' },
                            { id: '18px', label: 'Heading5' },
                            { id: '16px', label: 'Paragraph' },
                            { id: '14px', label: 'Body' },
                        ]
                    },
                    {
                        full: true,
                        type: 'select',
                        label: 'Font Weight',
                        property: 'font-weight',
                        options: [
                            { id: '400', label: 'thin' },
                            { id: '500', label: 'medium' },
                            { id: '600', label: 'bold' },
                            { id: '700', label: 'X-bold' },
                        ]
                    },
                ],
            },
            {
                id: 'alignitems',
                open: false,
                name: 'Align Items',
                properties: [
                    'align-items'
                ],
            },
            {
                id: 'opacity',
                open: false,
                name: 'Opacity',
                properties: [
                    {
                        label: 'Opacity',
                        full: true,
                        type: 'my-custom-opacity',
                        property: 'opacity',
                    },
                ],
            },
            {
                id: 'display',
                open: false,
                name: 'Display',
                properties: [
                    'display'
                ],
            },
        ]
    },
]

export const allSector = [
    {
        open: false,
        id: 'dimension',
        name: 'Dimension',
        properties: [
            {
                label: '',
                full: true,
                type: 'my-custom-width',
                property: 'width',
            },
        ],
    },
    {
        id: 'dropshadow',
        open: false,
        name: 'Drop shadow',
        properties: [
            'box-shadow',
        ],
    },
    {
        id: 'roundcorners',
        open: false,
        name: 'Round corners',
        properties: [
            {
                label: 'Border Radius',
                full: true,
                type: 'my-custom-border-radius',
                property: 'border-radius',
            },
        ],
    },
    {
        id: 'border',
        open: false,
        name: 'Border',
        properties: [
            {
                label: '',
                full: true,
                type: 'my-custom-border',
                property: 'border',
            },
        ],
    },
    {
        id: 'spacing',
        open: false,
        name: 'Spacing',
        properties: [
            {
                label: 'Padding',
                full: true,
                type: 'my-custom-padding',
                property: 'padding',
            },
            {
                label: 'Margin',
                full: true,
                type: 'my-custom-margin',
                property: 'margin',
            },
        ],
    },
    {
        id: 'background',
        open: false,
        name: 'Background',
        properties: [
            {
                label: 'Background Color',
                property: 'background-color',
                default: 'none',
                type: 'my-custom-color',
                full: true,
            },
        ],
    },
    {
        id: 'fontstyle',
        open: false,
        name: 'Font Style',
        properties: [
            {
                label: 'Text Color',
                property: 'color',
                default: 'none',
                type: 'my-custom-color',
                full: true,
            },
            {
                type: 'select',
                label: 'Font family',
                property: 'font-family',
                full: true,
                options: [
                    { value: 'Roboto' },
                    { value: 'Lato' },
                    { value: 'Bubblegum Sans' },
                    { value: 'Poppins' },
                    { value: 'Raleway' },
                ]
            },
            {
                full: true,
                type: 'select',
                label: 'Font Size',
                property: 'font-size',
                options: [
                    { id: '36px', label: 'Heading1' },
                    { id: '30px', label: 'Heading2' },
                    { id: '26px', label: 'Heading3' },
                    { id: '22px', label: 'Heading4' },
                    { id: '18px', label: 'Heading5' },
                    { id: '16px', label: 'Paragraph' },
                    { id: '14px', label: 'Body' },
                ]
            },
            {
                full: true,
                type: 'select',
                label: 'Font Weight',
                property: 'font-weight',
                options: [
                    { id: '400', label: 'thin' },
                    { id: '500', label: 'medium' },
                    { id: '600', label: 'bold' },
                    { id: '700', label: 'X-bold' },
                ]
            },
        ],
    },
    {
        id: 'backgroundpos',
        open: false,
        name: 'Background Pos',
        properties: [
            {
                type: 'radio',
                property: 'object-fit',
                label: 'Object fit',
                options: [
                    { id: 'contain', label: 'Contain' },
                    { id: 'fill', label: 'Fill' },
                    { id: 'cover', label: 'cover' },
                ]
            },
            {
                label: 'Background Pos',
                property: 'background-position',
                default: 'left top',
                type: 'select',
                full: true,
                options: getOptions([
                    'left top',
                    'left center',
                    'left bottom',
                    'right top',
                    'right center',
                    'right bottom',
                    'center top',
                    'center center',
                    'center bottom',
                ]),
            },
        ],
    },
    {
        id: 'alignitems',
        open: false,
        name: 'Align Items',
        properties: [
            'align-items'
        ],
    },
    {
        id: 'opacity',
        open: false,
        name: 'Opacity',
        properties: [
            {
                label: 'Opacity',
                full: true,
                type: 'my-custom-opacity',
                property: 'opacity',
            },
        ],
    },
    {
        id: 'display',
        open: false,
        name: 'Display',
        properties: [
            'display'
        ],
    },
]

export const findUniqueObject = (arr1, arr2, property) => {
    var uniqueObject = [];

    // Check objects from arr1 that are not in arr2
    for (var i = 0; i < arr1.length; i++) {
        var found = false;
        for (var j = 0; j < arr2.length; j++) {
            if (arr1[i][property] === arr2[j][property]) {
                found = true;
                break;
            }
        }
        if (!found) {
            uniqueObject.push(arr1[i]);
        }
    }

    // Check objects from arr2 that are not in arr1
    for (var k = 0; k < arr2.length; k++) {
        var found = false;
        for (var l = 0; l < arr1.length; l++) {
            if (arr2[k][property] === arr1[l][property]) {
                found = true;
                break;
            }
        }
        if (!found) {
            uniqueObject.push(arr2[k]);
        }
    }

    return uniqueObject;
}

export const blocksList = [
    "main-page",
    "introduction",
    "welcome",
    "about",
    "forms",
    "link-block",
    "text-basic",
    "gallery",
    'portfolio',
    'social',
    //'logos',
    'qutoe',
    'event',
    'gallery',
    "contact",
    "video",
    "hero",
    "esplanda-elements-biz",


    'pricing',
    "faq",
    "services",
    "audio",
    // "people",
    //  "quote",
    "testimonials",
    // "table",
    "feature",
    //"tabbar",
    "text",
    "image",
    "cta",
    "promotion",
    "list",
    "team",
    "embed",
    "navigation",
    "footer"
]

export const headerBlocksList = ['navigation']
export const footerBlocksList = ['footer']
export const tabbarBlocksList = ['tabbar']