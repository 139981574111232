import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";

import FacebookIcon from "../../../../../../assets/facebook.png";
import TwitterIcon from "../../../../../../assets/twitter.png";
import InstagramIcon from "../../../../../../assets/instagram.png";
import MailchimpIcon from "../../../../../../assets/images/mailchimpicon.jpg";

import { validateAndUpdateBaseUrl } from "../../../../StoreSettings/helpers";

import { PrimaryButton } from "../../../../../../util_comps/CustomButton";

import { useAlert } from "../../../../../../provider/AlertProvider";
import {
  updateVenueDetails,
  getVenueSetting,
} from "../../../../../../redux/actions/VenueSetting";

import {
  SubTitle,
  LabelText,
  ErrorText,
  ButtonFooterEnd,
} from "../../../../StoreSettings/components/reusableStyles";
import { FlexContainer, SocialInput, FlexDiv, BorderInput } from "./styles";
import Close from "@mui/icons-material/Close";
import { useClickOutside } from "../../../../../../hooks/useClickOutside";
import { toast } from "react-toastify";
import ReactFacebookLogin from "react-facebook-login";
import { FaFacebookF, FaTwitter } from 'react-icons/fa'
import axios from '../../../../../../utils/axiosConfig';
import laxios from 'axios'
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import TwitterLogin from "../../../../../TwitterLogin/TwitterLogin";
import styles from './SocialLinks.module.scss'
import Const from "../../../../../helpers/const";
import GenericButton from "../../../../../Library/GenericButton/GenericButton";
// import TwitterLogin from "react-twitter-auth";

//twitter

// api key = db53tnAiElOPxmnHb3LlPYdRg

// api secret = iVC3XR3swVNpHi3TcIwXG8aeliGAvCL9PTqMqdoDl2A0v7iipj

// token = AAAAAAAAAAAAAAAAAAAAANdBgAEAAAAAEfKVP7d%2BaR3nf3mJgXJ942nGKuI%3DsOawxT07E01rf7Lldx8LTGcKcIFp0cdiFbDkueni4DdTQzh6QM
//acc token = 1165143772407058437-yseRdfIrElvbONoa7dK95RbulqlaxU
//acc token sec = uSVAoZ4vGkDSC6HrYSmwryHnutBwsDSFFipsIgopp3Y8Q
const SocialLinks = (props) => {
  /*   const { setModal } = useAlert();
   */
  const [socialData, setSocialData] = useState({});
  const [socialErrors, setSocialErrors] = useState({
    igValid: true,
    fbValid: true,
    twValid: true,
  });

  const [isFbTokenSet, setIsFbTokenSet] = useState(false)
  const [showKeyGuide, setShowKeyGuide] = useState(false)
  const [showAudienceGuide, setShowAudienceGuide] = useState(false)
  const dispatch = useDispatch();

  const { VenueSettingList } = useSelector((store) => store.venueSetting);

  useEffect(() => {
    if (VenueSettingList.message === "Success") {
      setSocialData({
        ...VenueSettingList?.data?.venue,
        ...VenueSettingList?.data?.venue_social_links,
        venue_hours: VenueSettingList?.data?.venue_hours,
      });
    }
  }, [VenueSettingList]);

  const changeStoreDetails = (e) => {
    setSocialData({ ...socialData, [e.target.name]: e.target.value });
  };

  const updateVenue = () => {
    // validate social urls
    let fbErr = false;
    let igErr = false;
    let twErr = false;
    if (facebook_url && validateAndUpdateBaseUrl(facebook_url)) {
      setSocialErrors({ ...socialErrors, fbValid: false });
      fbErr = true;
    }

    if (instagram_url && validateAndUpdateBaseUrl(instagram_url)) {
      setSocialErrors({ ...socialErrors, igValid: false });
      igErr = true;
    }

    if (twitter_url && validateAndUpdateBaseUrl(twitter_url)) {
      twErr = true;
      setSocialErrors({ ...socialErrors, twValid: false });
    }
    if (fbErr || igErr || twErr) {
      return;
    }
    dispatch(updateVenueDetails({
      ...socialData
    })).then((res) => {
      dispatch(getVenueSetting("")).then(() => {
        toast.success('Changes have been saved')

        setSocialErrors({ twValid: true, igValid: true, fbValid: true })
      });
    });
  };

  const { facebook_url, instagram_url, twitter_url, mail_chimp_api_key, mail_chimp_audience_id, fb_long_token, tw_oauth_token } = socialData;
  const { igValid, fbValid, twValid } = socialErrors;
  const modalRef = useRef(null);
  const handleClose = () => {
    if (props.active) {
      props.setActiveModal({ name: "none", props: {} });
    }
  };
  useClickOutside(modalRef, handleClose);

  const onSuccess = (response) => {
    response.json().then(body => {
      toast.success(`${body.screen_name} Connected`)
      dispatch(getVenueSetting(""))
      // alert(JSON.stringify(body));
    });
  }

  const onFailed = (error) => {
    toast.error(error)
  }


  return (
    <>
      {props.isNotModal ? (
        <>
          <div>
            <SubTitle>Social URLs</SubTitle>
            <FlexContainer>
              <div>
                <LabelText>Facebook</LabelText>
                <FlexDiv>
                  <img src={FacebookIcon} alt="facebook icon" />
                  <BorderInput>
                    <span>https://facebook.com/</span>
                    <SocialInput
                      name="facebook_url"
                      type="text"
                      onChange={changeStoreDetails}
                      value={facebook_url}
                    />
                  </BorderInput>
                </FlexDiv>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px'
                  }}
                >
                  {fb_long_token ? (
                    <button
                      className="react-facebook-btn"
                      onClick={() => {
                        axios.post('/venue-setting/disconnect-fb').then(() => {
                          dispatch(getVenueSetting("")).then(() => {
                            toast.success('Facebook Disconnected')
                          })

                        }).catch(() => {
                          toast.error('Something went wrong')
                        })
                      }}
                    >
                      <FaFacebookF />
                      Disconnect Facebook
                    </button>
                  ) : (
                    <ReactFacebookLogin
                      cssClass="react-facebook-btn"
                      appId={Const.isOurDesiStore ? "1372600669893266" : "446667517180549"}
                      version="14.0"
                      fields="name,email,picture"
                      scope="pages_show_list,pages_read_engagement,pages_manage_posts,instagram_basic,instagram_content_publish,instagram_manage_insights,read_insights"
                      textButton="Connect with Facebook"
                      callback={(res) => {
                        let data = {
                          token: res.accessToken,
                          user_id: res.userID,
                        }

                        axios.post('/venue-setting/connect-fb', data).then(() => {
                          dispatch(getVenueSetting("")).then(() => {
                            toast.success('Facebook Connected')
                          })
                        }).catch(() => {
                          toast.error('Something went wrong')
                        })
                      }}
                      icon={<FaFacebookF />}
                    />
                  )}
                </div>

                {!fbValid && <ErrorText>No special characters allowed</ErrorText>}
              </div>

              <div>
                <LabelText>Twitter</LabelText>
                <FlexDiv>
                  <img src={TwitterIcon} alt="Twitter icon" />
                  <BorderInput>
                    <span>https://twitter.com/</span>
                    <SocialInput
                      name="twitter_url"
                      type="text"
                      onChange={changeStoreDetails}
                      value={twitter_url}
                    />
                  </BorderInput>
                </FlexDiv>
                {!twValid && <ErrorText>No special characters allowed</ErrorText>}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px'
                  }}
                >
                  {tw_oauth_token ? (
                    <button
                      className="react-twitter-btn"
                      onClick={() => {
                        axios.post('/venue-setting/disconnect-twitter').then(() => {
                          dispatch(getVenueSetting(""))
                            .then(() => {
                              toast.success('Twitter Disconnected')
                            })
                            .catch(() => {
                              toast.error('Something went wrong')
                            })
                        })
                      }}
                    >
                      <FaTwitter />
                      Disconnect Twitter
                    </button>
                  ) : (
                    <TwitterLogin
                      className={styles.twitter_btn}
                      text="Connect Twitter"
                      loginUrl={`${Const.BASE_URL}venue-setting/verifier-twitter`}
                      onFailure={onFailed}
                      onSuccess={onSuccess}
                      requestTokenUrl={`${Const.BASE_URL}venue-setting/reverse-twitter`}
                      showIcon={true}
                      forceLogin={false}
                    // requestTokenUrlBody={{
                    //   callback_url: window.location.hostname
                    // }}
                    />
                  )}
                </div>
              </div>

              <div>
                <LabelText>Instagram</LabelText>
                <FlexDiv>
                  <img src={InstagramIcon} alt="Instagram icon" />
                  <BorderInput>
                    <span>https://instagram.com/</span>
                    <SocialInput
                      name="instagram_url"
                      type="text"
                      onChange={changeStoreDetails}
                      value={instagram_url}
                    />
                  </BorderInput>
                </FlexDiv>
                {!igValid && <ErrorText>No special characters allowed</ErrorText>}
              </div>

              <div>
                <LabelText>Mailchimp</LabelText>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <img height={60} src={MailchimpIcon} alt="Instagram icon" />

                  <div style={{ marginLeft: '15px' }}>
                    <div
                      style={{
                        textDecoration: 'underline',
                        color: '#3366BB',
                        cursor: 'pointer',
                        marginBottom: '4px'
                      }}
                      onClick={() => setShowKeyGuide(true)}
                    >
                      How to get mailchimp key
                    </div>
                    <TextField label="Mailchimp Key" name="mail_chimp_api_key" variant="outlined" type="text" value={mail_chimp_api_key} onChange={changeStoreDetails} />
                  </div>

                  <div style={{ marginLeft: '15px' }}>
                    <div
                      style={{
                        textDecoration: 'underline',
                        color: '#3366BB',
                        cursor: 'pointer',
                        marginBottom: '4px'
                      }}
                      onClick={() => setShowAudienceGuide(true)}
                    >
                      How to get audience id
                    </div>
                    <TextField label="Audience Id" name="mail_chimp_audience_id" variant="outlined" type="text" value={mail_chimp_audience_id} onChange={changeStoreDetails} />
                  </div>
                </div>
                {!igValid && <ErrorText>No special characters allowed</ErrorText>}
              </div>
            </FlexContainer>
            <ButtonFooterEnd>
              <GenericButton
                variant="contained"
                onClick={updateVenue}
                disabled={props.isActionsDisabled}
                size="large"
              >
                Update
              </GenericButton>
            </ButtonFooterEnd>
          </div>

        </>
      ) : (
        <>
          <div
            ref={modalRef}
            style={{
              position: "fixed",
              display: props.active === true ? "initial" : "none",
              backgroundColor: "white",
              zIndex: "999999999",
              top: "35%",
              left: "60%",
              width: "40vw",
              transform: "translate(-60%,-25%)",
              padding: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Close onClick={e => props.setActiveModal({ name: 'none', props: {} })} style={{ color: "black", cursor: 'pointer' }} />
            </div>
            <div>
              <SubTitle>Social URLs</SubTitle>
              <FlexContainer>
                <div>
                  <LabelText>Facebook</LabelText>
                  <FlexDiv>
                    <img src={FacebookIcon} alt="facebook icon" />
                    <BorderInput>
                      <span>https://facebook.com/</span>
                      <SocialInput
                        name="facebook_url"
                        type="text"
                        onChange={changeStoreDetails}
                        value={facebook_url}
                      />
                    </BorderInput>
                  </FlexDiv>
                  {!fbValid && <ErrorText>No special characters allowed</ErrorText>}
                </div>

                <div>
                  <LabelText>Twitter</LabelText>
                  <FlexDiv>
                    <img src={TwitterIcon} alt="Twitter icon" />
                    <BorderInput>
                      <span>https://twitter.com/</span>
                      <SocialInput
                        name="twitter_url"
                        type="text"
                        onChange={changeStoreDetails}
                        value={twitter_url}
                      />
                    </BorderInput>
                  </FlexDiv>
                  {!twValid && <ErrorText>No special characters allowed</ErrorText>}
                </div>

                <div>
                  <LabelText>Instagram</LabelText>
                  <FlexDiv>
                    <img src={InstagramIcon} alt="Instagram icon" />
                    <BorderInput>
                      <span>https://instagram.com/</span>
                      <SocialInput
                        name="instagram_url"
                        type="text"
                        onChange={changeStoreDetails}
                        value={instagram_url}
                      />
                    </BorderInput>
                  </FlexDiv>
                  {!igValid && <ErrorText>No special characters allowed</ErrorText>}
                </div>
              </FlexContainer>
              <ButtonFooterEnd>
                {console.log(props.isActionsDisabled)}
                <GenericButton
                  variant="contained"
                  onClick={updateVenue}
                  disabled={props.isActionsDisabled}
                  size="large"
                >
                  Update
                </GenericButton>
              </ButtonFooterEnd>
            </div>
          </div>
        </>
      )}

      <Dialog
        open={showKeyGuide}
        onClose={() => setShowKeyGuide(false)}
      >
        <DialogTitle>
          How to get mailchimp key
        </DialogTitle>

        <DialogContent>
          <ul>
            <li>Signup</li>
            <li>Go to profile setting from bottom left side</li>
            <li>Choose setting tab</li>
            <li>Generate new api key</li>
          </ul>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showAudienceGuide}
        onClose={() => setShowAudienceGuide(false)}
      >
        <DialogTitle>
          How to get audience id
        </DialogTitle>

        <DialogContent>
          <ul>
            <li>Go to Audience</li>
            <li>Select All Contact</li>
            <li>Choose audience name and default option from Setting Menu</li>
            <li>You will get Audience ID</li>
          </ul>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SocialLinks;
