import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import storelist from "../../../assets/store-list.png";
import listicon from "../../../assets/list-text.png";
import bankinfoimg from "../../../assets/bank-info.png";
import routePaths from '../../../config/route-paths';
const BankInformation = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={storelist} />
            </div>
            Store Details             
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.StoreDetails}>Store Details</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.FulfillmentMethods}>Fulfillment Methods</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.BankInformation} className={cn('selected')}>Bank Information</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.LiquorLicense}>Business License</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.SubmitLogo}>Submit Logo</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.POSIntegration}>POS Integration</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AccessYourWebsite}>Access Your Website</Link></li>
        </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Bank Information
         </Typography>
       
         <p>
            
         <ol>
            <li>Go to the ‘<strong>My Store Settings</strong>’ section of the admin website.</li>

            <span className={cn('addnewproductsimg')}>
    <img src={bankinfoimg} />
    </span>

    <li>Click on the ‘<strong>Banking Info</strong>’ tab on the left.</li>
           

            <li>On this page, you will be able to enter your banking information and tax ID. If you are paying any monthly fees, you will be charged
on the 1st of every month.</li>
                  

    <li>Fill out the store owner information, and make sure to read our Terms of Service. Check the box to accept these terms, and then
click ‘<strong>Save</strong>’ in the bottom-right of the page to finish.</li>
  
</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default BankInformation