import React, { useContext, useEffect, useRef, useState } from 'react'
import WebsiteEditorContext from '../../../utils/context/websiteEditorContext'
import LayersDrag from './LayersDrag/LayersDrag'
import { reorder } from '../Util/helpers'

const Layers = () => {

    const { editor, forceUpdate } = useContext(WebsiteEditorContext)
    const [layers, setLayers] = useState([])

    useEffect(() => {
        if (editor) {
            const components = editor?.getComponents();
            const wrapper = editor?.getWrapper()
            // const LayerManager = props.editor?.LayerManager
            setLayers(components.map(component => {
                // Recursively check for nested components
                return component
                // return { title: component?.attributes?.type }
            }))

            // setLayers(components)
        }
    }, [editor, forceUpdate])



    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const components = editor?.getComponents();
        const wrapper = editor?.getWrapper()
        const newitems = reorder(
            layers,
            result.source.index,
            result.destination.index
        );
        let src = result.source.index
        let dest = result.destination.index
        if (src < dest) {
            for (let i = src; i < dest; i++) {
                components.at(i + 1)?.move(wrapper, { at: i })
            }
        } else {
            components.at(src)?.move(wrapper, { at: dest })
        }
        setLayers(newitems);

        editor?.refresh()
    };

    return (
        <div
            className="tw-z-0"
        >
            <div
                className="tw-border-b tw-border-b-[#d3d3d3] tw-py-3 tw-text-center tw-font-bold"
            >
                Layers
            </div>

            <LayersDrag
                onDragEnd={onDragEnd}
                items={layers}
            />
        </div>
    )
}

export default Layers