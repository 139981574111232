import { HeadsetMic, Language, WindowOutlined } from '@mui/icons-material'
import React, { useCallback, useEffect } from 'react'
import styles from './WebsiteBuilderGetStarted.module.scss'
// import WbGetStarted from '../../assets/images/wbgetstarted.png'
import { Button, TextField } from '@mui/material'
import { useState } from 'react'
import { useFormik } from 'formik'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import { debounce } from 'lodash';
import { toast } from 'react-toastify'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import { useNavigate } from 'react-router-dom'
import routePaths from '../../../config/route-paths'

const WebsiteBuilderGetStarted = () => {

    const navigate = useNavigate()
    const [isDomain, setIsDomain] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const fetchSubdomains = () => {
        nodeAxios.get('/web-builder/web-page/domains')
          .then((response) => {
            if (Boolean(response.data?.data?.subdomain)) {
              axios.get('/web-builder/web-page')
                .then((response2) => {
                  if (response2.data?.data?.results?.length) {
                    navigate(routePaths.manageSite2)
                  } else {
                    navigate(`/website-builder/template`)
                  }
                })
                .catch(() => {
                //   setTimeout(() => {
                //     setIsLoading(false)
                //   }, 2000);
                })
                .finally(() => {
                //   setTimeout(() => {
                //     setIsLoading(false)
                //   }, 2000);
                })
            } else {
            //   setTimeout(() => {
            //     setIsLoading(false)
            //   }, 2000);
            }
          })
          .catch(() => {
            // setTimeout(() => {
            //   setIsLoading(false)
            // }, 2000);
          })
          .finally(() => {
            // setIsLoading(false)
          })
      }
    
      useEffect(() => {
        fetchSubdomains()
      }, [])    

    const formik = useFormik({
        initialValues: {
            title: '',
            url: ''
        },
        onSubmit: (values) => {
            setIsLoading(true)
            nodeAxios.post('/web-builder/web-page/check-domain-available', {
                subdomain: values.url
            })
                .then((response) => {

                    nodeAxios.post('/web-builder/create-subdomain', {
                        subdomain: values.url,
                        subdomain_title: values.url
                    })
                        .then((response) => {
                            navigate(routePaths.websiteTemplates)
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.message);
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                })
                .catch(() => {
                    formik.setFieldError('url', 'Web address already taken, please choose another one')
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    })

    const checkSubDomainAvailablity = (domain) => {
        nodeAxios.post('/web-builder/web-page/check-domain-available', {
            subdomain: domain
        })
            .then((response) => {

            })
            .catch(() => {
                formik.setFieldError('url', 'Web address already taken, please choose another one')
            })
    }

    const debounceSearch = useCallback(debounce(checkSubDomainAvailablity, 1000), []);

    useEffect(() => {
        if (formik.values.url) {
            debounceSearch(formik.values.url)
        }
    }, [formik.values.url])


    return (
        <div
            style={{
                backgroundColor: '#F5F7FB'
            }}
            className={styles.container}
        >
            {isDomain ? (
                <div
                    className={styles.domain_container}
                >
                    <form
                        className={styles.form}
                        onSubmit={formik.handleSubmit}
                    >
                        <div
                            className={styles.what_text}
                        >
                            What should we call your site?
                        </div>

                        <div
                            className={styles.change_text}
                        >
                            Don’t worry you can always change this later.
                        </div>

                        {/* <div
                            className={styles.input_field_container}
                        >
                            <label
                                className={styles.input_label}
                                htmlFor="title"
                            >
                                Site title:
                                <span
                                    className={styles.required_asterisk}
                                >
                                    *
                                </span>
                            </label>
                            <TextField
                                InputProps={{
                                    classes: {
                                        notchedOutline: styles.input_box_style,
                                        input: styles.input
                                    }
                                }}
                                placeholder="eg: perfect store"
                                variant="outlined"
                                id="title"
                                name="title"
                                type="title"
                                fullWidth
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                                required
                            />
                        </div> */}

                        <div
                            className={styles.input_field_container}
                        >
                            <div
                                className={styles.http_text}
                            >
                                https://
                            </div>

                            <div
                                className={styles.domain_text}
                            >
                                .esplanda.com
                            </div>

                            <label
                                className={styles.input_label}
                                htmlFor="url"
                            >
                                Site title:
                                <span
                                    className={styles.required_asterisk}
                                >
                                    *
                                </span>
                            </label>
                            <TextField
                                InputProps={{
                                    classes: {
                                        notchedOutline: styles.input_box_style,
                                        input: styles.inputurl
                                    }
                                }}
                                variant="outlined"
                                id="url"
                                name="url"
                                fullWidth
                                value={formik.values.url}
                                onChange={(event) => {

                                    if (event.target.value) {
                                        var pattern = /^[a-z\d\-_]+$/i;

                                        //Validating the textBox value against our regex pattern.
                                        var isValid = pattern.test(event.target.value.toLowerCase().replaceAll(' ', '-'));

                                        if (isValid) {
                                            formik.setFieldValue('url', event.target.value.toLowerCase().replaceAll(' ', '-'))
                                        } else {
                                            console.log(event.target.value.toLowerCase().replaceAll(' ', '-'))
                                            toast.error('For url only numbers alphabets and dashes are allowed')
                                        }
                                    } else {
                                        formik.setFieldValue('url', event.target.value.toLowerCase().replaceAll(' ', '-'))
                                    }
                                }}
                                error={formik.touched.url && Boolean(formik.errors.url)}
                                helperText={formik.touched.url && formik.errors.url}
                                required
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <GenericLoadingButton
                                style={{
                                    backgroundColor: '#0B9DE4',
                                    borderRadius: '20px',
                                    // width: '200px'
                                }}
                                type="submit"
                                variant="contained"
                                loading={isLoading}
                                loadingPosition="end"
                                size="large"
                            >
                                Save & Continue
                            </GenericLoadingButton>
                        </div>

                    </form>

                </div>
            ) : (
                <div
                    style={{
                        paddingTop: 30
                    }}
                >
                    <div
                        className={styles.get_started_container}
                    >
                        <div
                            className={styles.text_container}
                        >
                            <div
                                className={styles.create_text}
                            >
                                Create your Website with Ease
                            </div>

                            <div>
                                No  coding required. Easily create a website, optimized for speed and performance
                            </div>

                            <div
                                className={styles.text_with_icon}
                            >
                                <Language /> Free Micro website and domain hosting
                            </div>

                            <div
                                className={styles.text_with_icon}
                            >
                                <HeadsetMic /> 24/7 Customer Support
                            </div>

                            <div
                                className={styles.text_with_icon}
                            >
                                <WindowOutlined /> Easily create your web without coding in 10 min
                            </div>

                            <div>
                                <Button
                                    style={{
                                        backgroundColor: '#0B9DE4',
                                        borderRadius: '20px',
                                        width: '200px'
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        setIsDomain(true)
                                    }}
                                >
                                    Get Started
                                </Button>
                            </div>
                        </div>

                        <div
                            className={styles.image_container}
                        >
                            {/* <Image
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                src={WbGetStarted}
                                height={500}
                                width={500}
                            /> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WebsiteBuilderGetStarted