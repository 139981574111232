import React from 'react'
import GenericTextField from '../../../Library/GenericTextField/GenericTextField'

const WebAddress = (props) => {



  return (
    <div>
      <GenericTextField
        value={props.webAddress}
        onChange={(event) => {
          props.setWebAddress(event.target.value)
        }}
        fullWidth={true}
      />
    </div>
  )
}

export default WebAddress