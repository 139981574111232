import { ContentState, EditorState } from "draft-js"
const htmlToDraft = typeof window === 'object' && require('html-to-draftjs').default;
// import htmlToDraft from "html-to-draftjs"

export const handleInsideAttrChange = ({ model, key, value, isOutside }) => {
  // console.log(model, JSON.parse(JSON.stringify(model)))
  if (model) {
    const parsedModel = JSON.parse(JSON.stringify(model));
    if (model?.findType(parsedModel.components?.[0]?.type)?.length && !isOutside) {
      const newmodel = model?.findType(parsedModel.components?.[0]?.type)[0];
      const attr = newmodel.getAttributes();
      console.log(newmodel, key, value);
      newmodel.setAttributes({ ...attr, [key]: value });
    } else if (model?.find(parsedModel.components?.[0]?.tagName)?.length && !isOutside) {
      const newmodel = model?.find(parsedModel.components?.[0]?.tagName)[0];
      const attr = newmodel.getAttributes();
      console.log(newmodel, key, value);
      newmodel.setAttributes({ ...attr, [key]: value });
    } else {
      const attr = model.getAttributes();
      model.setAttributes({ ...attr, [key]: value });
    }
  }
};

export const getInsideModel = (model) => {
  if (model) {
    const parsedModel = JSON.parse(JSON.stringify(model));
    console.log(parsedModel)
    if (model?.find(parsedModel.components?.[0]?.tagName)?.length) {
      const newmodel = model?.find(parsedModel.components?.[0]?.tagName)[0];
      return newmodel;
    }
    if (model?.findType(parsedModel.components?.[0]?.type)?.length) {
      const newmodel = model?.findType(parsedModel.components?.[0]?.type)[0];
      return newmodel;
    }
  }
};

export const getParsedBoolean = (stringValue) =>
  stringValue == 'true' || stringValue == true;

export const getImage = (name) =>
  `https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/${name}`;

export const formatStyle = (styleObj) => {
  let styleString = '';
  for (let property in styleObj) {
    styleString += `${property}: ${styleObj[property]}; `;
  }
  return styleString;
};

export const isHTML = (str) => {
  var doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export const resolveHeight = (model, resolveHeightTimer) => {
  try {
    if (model) {
      const newmodel = getInsideModel(model);
      const modelStyle = model?.getStyle();
      if (newmodel?.getEl()) {
        let newModelStyle = getComputedStyle(newmodel?.getEl());
        if (resolveHeightTimer.current) {
          clearTimeout(resolveHeightTimer.current);
        }
        resolveHeightTimer.current = setTimeout(() => {
          console.log(model, 'model');
          if (model) {
            model.setStyle({
              ...modelStyle,
              height: newModelStyle.height,
            });
          }
        }, 1000);
      }
    }
  } catch (error) {
    console.log(error)
  }
};

export const getInitialsAvatar = (name) => {
  return `https://ui-avatars.com/api/?background=random&length=1&name=${name}`;
};

export const downloadCSV = (csvString, filename) => {
  const blob = new Blob([csvString], { type: 'text/csv' });
  const link = document.createElement('a');
  link.style = 'display: none;';
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  document.body?.appendChild(link);
  link.click();
};

export const downloadPDF = (url, filename) => {
  // const blob = new Blob([csvString], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.style = 'display: none;';
  link.href = url; //URL.createObjectURL(blob);
  link.download = filename;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  document.body?.appendChild(link);
  link.click();
};

const getElementAttributes = (element) => {
  var attributes = {};
  for (var attr of element.attributes) {
    attributes[attr.name] = attr.value;
  }
  return attributes;
}

export const htmlToDraftConverter = (setEditorState, content, isNew) => {
  if (isNew) {
    setEditorState(content)
  } else {
    const contentBlock = htmlToDraft(content, (nodeName, node) => {
      console.log(nodeName, node)
      if (node?.firstChild?.nodeName === 'IMG') {
        const attributes1 = getElementAttributes(node)
        const attributes2 = getElementAttributes(node.firstChild)
        console.log(attributes1, attributes2)
        return {
          type: 'IMAGE',
          mutability: 'MUTABLE',
          data: { src: attributes2.src, height: attributes2.height, width: attributes2.width, alt: attributes2.alt, href: attributes1.href }
        };
      }
      return
    });
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
    setEditorState(EditorState.createWithContent(contentState))
  }
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const formatUri = (uri) => (uri?.startsWith('/') || uri?.startsWith('http')) ? uri : `/${uri}`

export const handleActuallComponentsAdd = (model, parsedModel) => {
  console.log(parsedModel)
  switch (parsedModel.type) {
    case 'esplanda-product-card-group':
      handleInsideAttrChange({ model, key: 'product_group_id', value: parsedModel.groupId })
      break;
    case 'esplanda-category-card-group':
      handleInsideAttrChange({ model, key: 'product_group_id', value: parsedModel.groupId })
      break;
    case 'appt-product-details':
      if(parsedModel.appt_id){
        handleInsideAttrChange({ model, key: 'appt_id', value: parsedModel.appt_id })
      }
      break;
    case 'esplandaformsreact':
      handleInsideAttrChange({ model, key: 'group_id', value: parsedModel.groupId })
      handleInsideAttrChange({ model, key: 'form_id', value: parsedModel?.attributes?.id })
      break;
  }
}