import styled from "styled-components";

export const FlexContainer = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 900px;
`;

export const SocialInput = styled.input`
  background: none;
  border: none;
  font-size: 15px;
  font-family: "Poppins";
  width: 100%;
  border-bottom: 1px solid #dee2e6;
  margin: 0 8px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #171717;
    font-family: inherit;
  }
  :-ms-input-placeholder {
    color: #171717;
    font-family: inherit;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dee2e6;
  width:100%;
`;

export const BorderInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    background: #f8f8f8;
    font-size: 15px;
    font-family: "Poppins";
    color: #171717;

    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 8px;
    padding: 0 8px;
  }
`;
