export default function getVenueHours() {
    var days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ]
    var id = 1;
    var pickupHours =  getPickupHours("pickup", days,id);
    id=8
   var hours= pickupHours.concat(getPickupHours("delivery", days,id));
    return hours;
}
const getPickupHours=(type, days, id )=>{
    var hours=[]
    days.forEach((element) => {
        var hour = {
            Id: id++,
            closedtimehours: "",
            closedtimemins: "",
            dayname: element,
            isclosed: "1",
            opentimehours: "",
            opentimemins: "",
            slots: [],
            venueid: "",
            venuetimings: type
        }
        hours.push(hour);
    });
    return hours;
}