import React, { useState } from 'react'
import TraitsResolver from './TraitsResolver/TraitsResolver'
import StyleResolver from './StyleResolver/StyleResolver'
import clsx from 'clsx'
import ItemsTraitResolver from './ItemsTraitResolver/ItemsTraitResolver'
import { IconButton } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'

const RightPanel = () => {
    const [tab, setTab] = useState('content')
    const [editIndex, setEditIndex] = useState()
    const [itemsKey, setItemsKey] = useState('items')

    return (
        <div
            className="tw-w-[230px] tw-min-w-[230px] tw-p-2 tw-h-[calc(100vh-50px)] tw-overflow-y-auto tw-z-[1]"
        >
            <div
                className="tw-flex tw-gap-2 tw-mb-4"
            >
                <div
                    className={clsx("tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-cursor-pointer", (tab == 'content' ? "tw-bg-gray" : "tw-bg-white"))}
                    onClick={() => setTab('content')}
                >
                    Content
                </div>

                <div
                    className={clsx("tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-cursor-pointer", (tab == 'style' ? "tw-bg-gray" : "tw-bg-white"))}
                    onClick={() => setTab('style')}
                >
                    Style
                </div>
            </div>

            {(tab == 'content') && (
                <TraitsResolver
                    setEditIndex={setEditIndex}
                    setTab={setTab}
                    setItemsKey={setItemsKey}
                />
            )}

            {(tab == 'style') && (
                <StyleResolver />
            )}

            {(tab == 'itemsedit') && (
                <div>
                    <div>
                        <IconButton
                            onClick={() => {
                                setTab('content')
                            }}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>
                    </div>
                    <ItemsTraitResolver
                        itemsKey={itemsKey}
                        editIndex={editIndex}
                        onBack={() => {
                            setTab('content')
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default RightPanel