import React, { useRef, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, SpeedDial, SpeedDialAction, TextField } from '@mui/material'
import { Add, CameraAlt, Cancel, Close, CloseOutlined, ColorLensOutlined, KeyboardArrowDown, KeyboardArrowUp, Refresh, RefreshOutlined, Search } from '@mui/icons-material'
import InputField from '../../../../Library/InputField/InputField'
import GenericSearchField from '../../../../Library/V2/GenericSearchField/GenericSearchField'
import Button2 from '../../../../Library/Button/Button'
import { nodeAxios } from '../../../../../utils/axiosConfig'
import { useFormik } from 'formik'
import clsx from 'clsx'
import Input from '../../../../Library/Input/Input'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import LoaderGif from '../../../../GettingStarted/LoaderGif/LoaderGif'
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

function ItemsHeader({ sort, setSort, searchStr, setSearchStr, debounceSearch, isSearchLoading, setShowAddNewProduct, isMobile, isActionDisabled, fetchMyList }) {

    const menuRef = useRef()
    const menuRef2 = useRef()
    const camera = useRef()
    const uploadRef = useRef(null);

    const user = useSelector((state) => state.user);

    const [products, setProducts] = useState(Array.from({ length: 5 }, () => ({
        category_name: '',
        items: [{
            product_name: "",
            description: "",
            price: null,
            size: null
        }]
    })))
    // const [products, setProducts] = useState([
    //     {
    //         "category_name": "Soups",
    //         "items": [
    //             {
    //                 "product_name": "ROASTED PUMPKIN SOUP",
    //                 "description": "Served in a edible bread bowl.",
    //                 "price": '$265',
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "TOMATO AND TULSI SOUP",
    //                 "description": "A soup of thick rich tomato and tulsi. Quite filling!",
    //                 "price": '225',
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "BHUTTE KA SHORBA",
    //                 "description": null,
    //                 "price": '$200',
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "SHILLONG SOUP",
    //                 "description": "Our Chef's special soup prepared with freshly cut veggies.",
    //                 "price": 250,
    //                 "size": null
    //             }
    //         ]
    //     },
    //     {
    //         "category_name": "Starters",
    //         "items": [
    //             {
    //                 "product_name": "KULCHA PLATTER",
    //                 "description": "Choose from ghing kuli kulchas and spiced cottage cheese mini kulchas, served with chatapaaty chutneys.",
    //                 "price": 350,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "DAHI KE KEBAB",
    //                 "description": "Melt-in-your mouth dahi kebabs served with a mint-meyonnaise dip.",
    //                 "price": 325,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "VEG SAMPLER",
    //                 "description": "A variety of veg starters served on a platter with assorted chutneys.",
    //                 "price": 400,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "PANEER TIKKA",
    //                 "description": "Paneer tikka served with pudina chutney and lachha salad.",
    //                 "price": 475,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "SWEET CORN MATAR KEBAB",
    //                 "description": "Sweet corn and green peas mash and shallow fried, served with chutneys.",
    //                 "price": 350,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "MALAI BROCCOLI",
    //                 "description": "Broccoli florets cooked with mild cheese and yogurt marinade served with mint chutney.",
    //                 "price": 425,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "CHEESY NAAN BALL WITH MUSHROOM SAMOSA",
    //                 "description": "Mini naan balls filled with cheese served with mushroom samosas and chipotle sauce.",
    //                 "price": 375,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "BHARWAN MIRCH, SUJI POORI & FRIED PAPAD ROLL",
    //                 "description": null,
    //                 "price": 375,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "GHEE PODI OPEN ROOMALI",
    //                 "description": "Our ever-popular street eat within! Roomali roti toasted in ghee slathered with podi masala and served with freshly churned butter and assorted achaar.",
    //                 "price": 275,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "REVIVAL KEBAB PLATTER",
    //                 "description": "An assortment of kebabs, served with pudina chutney and kachumber salad.",
    //                 "price": 475,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "REVIVAL BHINDI RAJASTHANI",
    //                 "description": "With a unique presentation! Whole bhindi stuffed with spicy rajasthani masala, lightly fried and served with mini bedmi puri.",
    //                 "price": 400,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "PANEERLAY",
    //                 "description": "Stuffed paneer fingers filled with shredded vegetables in pine, tomato, and chili sauce.",
    //                 "price": 425,
    //                 "size": null
    //             },
    //             {
    //                 "product_name": "REVIVAL ANGARII TIKKA",
    //                 "description": "Florets of broccoli, thick wedges of pineapple & paneer and other vegetables are appropriately marinated and grilled before serving to you.",
    //                 "price": 400,
    //                 "size": null
    //             }
    //         ]
    //     }
    // ].map((data) => ({
    //     ...data,
    //     items: data?.items?.map((data2) => {
    //         const price = data2?.price ? `${data2?.price}` : '0'

    //         return ({
    //             ...data2,
    //             price: parseFloat(price.match(/[0-9]+(\.[0-9]+)?/g)[0] || 0)
    //         })
    //     })
    // })))
    const [showProductsEdit, setShowProductsEdit] = useState(false)
    const [byImageLoading, setByImageLoading] = useState(false)
    const [isCameraOn, setIsCameraOn] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    const handleMenuImage = (event) => {
        const file = event.target.files[0];
        const fd = new FormData()
        fd.append('image_file', file)
        setByImageLoading(true)
        setShowProductsEdit(true)
        nodeAxios.post('/products/by-image', fd)
            .then((res) => {
                setProducts(res.data?.data?.map((data) => ({
                    ...data,
                    items: data?.items?.map((data2) => {
                        const price = data2?.price ? `${data2?.price}` : '0'
                        return ({
                            ...data2,
                            price: parseFloat(price.match(/[0-9]+(\.[0-9]+)?/g)[0] || 0)
                        })
                    })
                })))
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setByImageLoading(false)
            })

        event.target.value = ''
    }

    const formik = useFormik({
        initialValues: {
            payload: products || []
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log('🚀OUTPUT --> values:', values);
            setByImageLoading(true)
            nodeAxios.post('/products/by-payload', {
                ...values,
                venueType: user?.data?.venue_type,
                venueId: user?.data?.venueid
            })
                .then(() => {
                    setShowProductsEdit(false)
                    if (fetchMyList) {
                        fetchMyList()
                    }
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
                .finally(() => {
                    setByImageLoading(false)
                })
        }
    })

    const actions = [
        {
            icon: <Add />,
            name: 'Add another item',
            onClick: () => setShowAddNewProduct(true)
        },
        {
            icon: <CameraAlt />,
            name: <span className="tw-whitespace-nowrap">Take picture of menu</span>,
            onClick: () => {
                setIsCameraOn(true)
                setShowInfo(true)
            }
        },
    ];

    const handleUploadCsv = async (e) => {
        try {
            e.preventDefault();
            if (!e.target.value) {
                return;
            }
            let file = e.target.files[0];
            const fd = new FormData()
            fd.append('image_file', file)
            nodeAxios.post('/products/by-csv', fd)

            toast.success('Please check back in some time your products are getting uploaded')
            uploadRef.current.value = ""
        } catch (err) {
            toast.error('Error occured while uploading, select valid .csv file and try again')
            return;
        };
    }

    return (
        <div>

            <input
                ref={menuRef2}
                accept="image/*"
                capture="environment"
                onChange={handleMenuImage}
                className="tw-hidden"
                type="file"
            />

            <input
                ref={menuRef}
                accept="image/*"
                onChange={handleMenuImage}
                className="tw-hidden"
                type="file"
            />

            <input
                className="tw-hidden"
                type="file"
                accept=".csv"
                onChange={e => handleUploadCsv(e)}
                ref={uploadRef}
            />

            {/* {isCameraOn && (
                <div
                    className="tw-z-50 tw-fixed tw-h-screen tw-w-screen tw-top-0 tw-left-0"
                >
                    <Camera facingMode="environment" ref={camera} />
                    <Button2
                        className="tw-fixed tw-bottom-10 tw-left-1/2 tw-rounded-full tw-text-white tw-bg-black -tw-translate-x-1/2"
                        onClick={async () => {

                            const res = await fetch(camera.current.takePhoto())
                            const file = await res.blob()
console.log(file)
                            const event = {
                                target: {
                                    files: [file]
                                }
                            }
                            handleMenuImage(event)
                            setIsCameraOn(false)
                        }}
                    >
                        Take photo
                    </Button2>
                </div>
            )} */}
            <div className='tw-hidden lg:tw-block '>
                <h1 className="tw-text-2xl tw-font-semibold tw-mb-4 tw-text-black">Items</h1>
            </div>
            <div className="tw-flex tw-justify-between">
                <div className="tw-flex tw-gap-4 tw-whitespace-nowrap tw-w-full lg:tw-w-auto">
                    <Button
                        variant="text"
                        endIcon={((sort.sort_by === 'created_at') && (sort.sort_by_order === 'DESC')) ? (
                            <KeyboardArrowDown />
                        ) : (
                            <KeyboardArrowUp />
                        )}
                        className='/'
                        classes={{
                            root: '!tw-bg-[#f2f2f2] !tw-rounded-3xl !tw-text-black !tw-px-4 !tw-hidden lg:!tw-flex'
                        }}
                        onClick={() => {
                            if (sort.sort_by_order === 'ASC') {
                                setSort({ ...sort, sort_by_order: 'DESC' })
                            } else {
                                setSort({ ...sort, sort_by_order: 'ASC' })
                            }
                        }}
                    >
                        Sort By: Recently Added
                    </Button>

                    <GenericSearchField {...{
                        value: searchStr,
                        debounceSearch,
                        setValue: setSearchStr,
                        isSearchLoading,
                        placeholder: 'Search by name, Barcode#',
                        fullWidth: isMobile && true
                    }} />
                </div>

                {isMobile ? (
                    <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'fixed', bottom: 16, right: 16 }}
                        icon={<SpeedDialIcon />}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                onClick={action.onClick}
                            />
                        ))}
                    </SpeedDial>
                    // <div className='tw-fixed tw-bottom-10 tw-right-9 tw-z-10'>
                    //     <IconButton className='!tw-bg-black !tw-rounded-3xl !tw-text-white' onClick={() => setShowAddNewProduct(true)}>
                    //         <Add />
                    //     </IconButton>
                    // </div>
                ) : (
                    <div className='tw-flex tw-items-center tw-gap-3'>
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            disableElevation
                            style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: 20
                            }}
                            onClick={() => {
                                setShowAddNewProduct(true)
                            }}
                            disabled={isActionDisabled}
                        >
                            Add Item
                        </Button>

                        <Button2
                            className="tw-rounded-full tw-text-white tw-bg-black"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                        >
                            Add Menu CSV
                        </Button2>

                        <Button2
                            className="tw-rounded-full tw-text-white tw-bg-black"
                            onClick={() => {
                                setShowInfo(true)
                            }}
                        >
                            Upload Menu image
                        </Button2>
                    </div>
                )}

            </div>

            <Dialog
                open={showProductsEdit}
                // onClose={() => {
                //     setShowProductsEdit(false)
                // }}
                maxWidth="xl"
                PaperProps={{
                    style: {
                        width: '100%',
                        maxWidth: '650px'
                    }
                }}
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setShowProductsEdit(false)
                        }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 0,
                            color: '#000'
                        }}
                    >
                        <Cancel />
                    </IconButton>
                </DialogTitle>
                {byImageLoading ? (
                    <LoaderGif
                        text={["Hold on, menu is being uploaded.", "Extracting data.", "Parsing data", "Creating menu"]}
                        loaderClass="tw-w-[80vh] tw-h-[80vh]"
                        isTemplateLoading={true}
                        formik={formik}
                        type={2}
                        onNext={() => {

                        }}
                    />
                ) : (
                    <DialogContent
                        style={{
                            overflow: 'hidden',
                            paddingRight: 0
                        }}
                    >
                        <form onSubmit={formik.handleSubmit} className="tw-overflow-x-auto" >
                            <div
                                className="tw-max-h-[650px] tw-overflow-auto"
                            >
                                <table className={clsx("tw-dui-table")}>
                                    <thead
                                        className="tw-text-sm tw-text-black"
                                    >
                                        <tr>
                                            {/* <th className='tw-w-10'></th> */}
                                            <th
                                                className="tw-text-black"
                                            >
                                                Product Name
                                            </th>
                                            <th
                                                className="tw-text-black"
                                            >
                                                Price
                                            </th>
                                            <th
                                                className="tw-text-black"
                                            >
                                                Size
                                            </th>
                                            <th
                                                className="tw-text-black"
                                            >
                                                Category
                                            </th>
                                            <th
                                                className="tw-text-black"
                                            >
                                                Description
                                            </th>
                                            <th className='tw-w-20'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formik?.values?.payload?.map((data1, index) => data1?.items?.map((data2, index2) => (
                                            <tr key={index}>
                                                {/* <th className='tw-w-14'>{index2 + 1}</th> */}
                                                <td>
                                                    <Input
                                                        type="text"
                                                        placeholder="Product name"
                                                        inputClass="tw-min-w-[100px]"
                                                        name={`payload[${index}].items[${index2}].product_name`}
                                                        value={data2.product_name}
                                                        onChange={formik.handleChange}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        placeholder="Price"
                                                        inputClass="tw-min-w-[80px]"
                                                        name={`payload[${index}].items[${index2}].price`}
                                                        value={data2.price}
                                                        onChange={formik.handleChange}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        placeholder="Size"
                                                        inputClass="tw-min-w-[50px]"
                                                        name={`payload[${index}].items[${index2}].size`}
                                                        value={data2.size}
                                                        onChange={formik.handleChange}
                                                        helper={true}
                                                        helperText="Optional"
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        placeholder="Category"
                                                        inputClass="tw-min-w-[100px]"
                                                        name={`payload[${index}].category_name`}
                                                        value={data1.category_name}
                                                        onChange={formik.handleChange}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        placeholder="Description"
                                                        name={`payload[${index}].items[${index2}].description`}
                                                        value={data2.description}
                                                        onChange={formik.handleChange}
                                                        helper={true}
                                                        helperText="Optional"
                                                    />
                                                </td>
                                                <td className='tw-w-20'>
                                                    {formik.values.payload?.length > 0 && (
                                                        <button
                                                            className='tw-dui-btn tw-dui-btn-square tw-dui-btn-ghost tw-h-10 tw-w-10 tw-min-h-0 hover:tw-bg-whitesmoke'
                                                            onClick={() => {
                                                                let products = formik.values.payload
                                                                if (formik.values.payload[index]?.items?.length == 1) {
                                                                    products = products.filter((data, i) => i !== index)
                                                                } else {
                                                                    products[index] = {
                                                                        ...products[index],
                                                                        items: products[index]?.items?.filter((data, i) => i !== index2)
                                                                    }
                                                                }

                                                                formik.setFieldValue('payload', products)
                                                            }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        )))}
                                    </tbody>
                                </table>
                            </div>

                            <div
                                className="tw-flex tw-justify-end tw-pr-5 tw-h-12 tw-mb-2 tw-items-center"
                            >
                                <button
                                    className='tw-dui-btn tw-dui-btn-ghost tw-h-auto tw-min-h-0 tw-py-2 tw-px-0 tw-rounded-3xl tw-text-blue tw-gap-1 tw-normal-case'
                                    type='button'
                                    onClick={() => {
                                        formik.setFieldValue('payload', [
                                            ...formik.values.payload,
                                            {
                                                category_name: '',
                                                items: [{
                                                    product_name: "",
                                                    description: "",
                                                    price: null,
                                                    size: null
                                                }]
                                            }
                                        ])
                                    }}
                                >
                                    <Add sx={{ p: 0 }} /> Add another item
                                </button>
                            </div>

                            <div
                                // className="tw-fixed sm:tw-static tw-bottom-0 tw-py-3 tw-left-0 tw-flex tw-justify-end tw-gap-2 tw-bg-white tw-w-full tw-px-5"
                                className="tw-py-3 tw-flex tw-justify-end tw-gap-2 tw-bg-white tw-w-full tw-px-5"
                            >
                                <Button2
                                    className='tw-bg-black tw-text-white tw-rounded-full'
                                    type='submit'
                                >
                                    Add items to my website
                                </Button2>
                            </div>
                        </form>
                    </DialogContent>
                )
                }
            </Dialog>

            <Dialog
                hideBackdrop={true}
                open={showInfo}
                onClose={() => {
                    setShowInfo(false)
                }}
            >

                <div
                    className="tw-w-full tw-relative tw-rounded-xl tw-bg-white tw-shadow-[0px_4px_30px_rgba(0,_0,_0,_0.15)] tw-overflow-hidden tw-flex tw-flex-col tw-items-start tw-justify-start tw-pt-5 tw-px-0 tw-pb-[22px] tw-box-border tw-gap-[20px_0px] tw-text-left tw-text-[20px] tw-text-black tw-font-inter"
                >
                    <div
                        className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-between tw-py-0 tw-px-5"
                    >
                        <div
                            className="tw-w-[190px] sm:tw-w-[276px] tw-relative tw-font-medium tw-inline-block tw-shrink-0"
                        >
                            Upload Menu Steps
                        </div>
                        <IconButton
                            onClick={() => {
                                setShowInfo(false)
                            }}
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div
                        className="tw-self-stretch tw-flex tw-flex-col tw-items-start tw-justify-start tw-py-0 tw-px-5 tw-gap-[15px_0px] tw-text-sm"
                    >
                        <div
                            className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-[0px_15px]"
                        >
                            <div
                                className="tw-flex-1 tw-relative tw-flex tw-gap-2 tw-flex-wrap tw-items-center"
                            >
                                <ul class="list-disc">
                                    <li>We have made it easier for you to take a picture and upload your menu</li>
                                    <li>Before upload verify your items and its details</li>
                                    <li>If you have any modifiers add modifiers from customization tab after adding menu items</li>
                                </ul>
                            </div>
                        </div>

                        <div
                            className="tw-flex tw-justify-end"
                        >
                            <Button2
                                className="tw-bg-black tw-text-white tw-rounded"
                                variant="text"
                                onClick={() => {
                                    if (!isMobile) {
                                        menuRef.current?.click()
                                    } else {
                                        menuRef2.current?.click()
                                    }
                                    setShowInfo(false)
                                }}
                            >
                                Continue
                            </Button2>

                        </div>
                    </div>
                </div>
            </Dialog>

        </div >
    )
}

export default ItemsHeader;