import React, { useEffect, useState } from "react";
import { Collapse, Dialog, Drawer, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { ChevronRight, KeyboardArrowUp } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import TimeSlotPicker from "../../TimeSlotPicker/TimeSlotPicker";
import { nodeAxios } from "../../../../../../utils/axiosConfig";
import { toast } from "react-toastify";
import { generateDeliveryPickupSlot } from "../../../../../../utils/helpers";
import { useConfirm } from "material-ui-confirm";
import useDeliveryFlow from "../../../../../../hooks/useDeliveryFlow";
import DeliveryOption from "../../../../../DeliveryAcceptButton/DeliveryOption/DeliveryOption";
import DeliveryOption2 from "../../../../../DeliveryAcceptButton/DeliveryOption/DeliveryOption2";

const useStyles = makeStyles(() => ({
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    infoContainerVal: {
        width: '60%',
        fontWeight: '600',
        color: 'black',
        wordBreak: 'break-word',
        fontSize: '13px',
    },
    infoContainerHead: {
        fontSize: '13px',
    }
}));

const Detail = (props) => {

    const classes = useStyles()
    const user = useSelector((store) => store.user);

    const isMobileScreen = useMediaQuery('(max-width:768px)');
    const [showBody, setShowBody] = useState(true)
    const [timeslot, setTimeslot] = useState({})
    const [deliveryPickupSlot, setDeliveryPickupSlot] = useState({
        date: props?._6value?.val?.split(' ')[0],
        time: props?._6value?.val?.split(' ')[1]
    })

    return (
        <div
            style={{
                flex: props.flex ? 1 : 'initial',
                border: '1px solid rgb(232,232,232)',
                borderRadius: 4
            }}
        >
            <Typography
                onClick={() => {
                    if (isMobileScreen) {
                        setShowBody(!showBody)
                    }
                }}
                style={{
                    fontSize: 18,
                    color: 'black',
                    padding: 10, backgroundColor: '#f9f9f9',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    transition: 'all .3s ease-in-out'
                }}>
                {props.title}

                {isMobileScreen && (
                    showBody ? <KeyboardArrowUp /> : <ChevronRight />
                )}
            </Typography>
            {isMobileScreen ? (
                <Collapse in={showBody}>
                    <DetailBody timeslot={timeslot} setTimeslot={setTimeslot} deliveryPickupSlot={deliveryPickupSlot} setDeliveryPickupSlot={setDeliveryPickupSlot} classes={classes} {...props} />
                </Collapse>
            ) : (
                <DetailBody timeslot={timeslot} setTimeslot={setTimeslot} deliveryPickupSlot={deliveryPickupSlot} setDeliveryPickupSlot={setDeliveryPickupSlot} classes={classes} {...props} />
            )}

        </div>
    );
};

export default Detail;

const DetailBody = ({ classes, timeslot, setTimeslot, deliveryPickupSlot, setDeliveryPickupSlot, externalDeliveryId, ...props }) => {

    const mobile = useMediaQuery('(max-width:1000px)');
    const user = useSelector((store) => store.user);

    const [forceUpdate, setForceUpdate] = useState(false)
    const [isSlotEdit, setIsSlotEdit] = useState(false)
    const [deliveryStatus, setDeliveryStatus] = useState()
    const [isUpdateSlotLoading, setIsUpdateSlotLoading] = useState(false)
    const [isGetDeliveryLoading, setIsGetDeliveryLoading] = useState(false)
    const [isEditSlotLoading, setIsEditSlotLoading] = useState(false)

    const {
        fetchEstimate,
        showDeliveryOption,
        setShowDeliveryOption,
        pickupTimeEstimated,
        isDoordashDisabled,
        drivers,
        estimate,
        isEstimateLoading
    } = useDeliveryFlow({
        order: {
            ...props.order,
            venuedrivertip: props.orderVenue?.venuedrivertip
        }
    })
    const confirm = useConfirm()

    const fetchDeliveryStatus = () => {
        setIsGetDeliveryLoading(true)
        nodeAxios.get(`/doordash/delivery/${externalDeliveryId}`)
            .then((res) => {
                setDeliveryStatus(res.data?.data?.delivery_status)
            })
            .finally(() => {
                setIsGetDeliveryLoading(false)
            })
    }

    useEffect(() => {
        if (externalDeliveryId) {
            fetchDeliveryStatus()
        }
    }, [externalDeliveryId])

    const handleSlotUpdate = () => {
        setIsUpdateSlotLoading(true)
        nodeAxios.post('/orders/update-slot', {
            id: props._1value.val,
            delivery_pickup_slot: generateDeliveryPickupSlot(deliveryPickupSlot.date, deliveryPickupSlot.time),
            action_taken_from: (window.innerWidth < 1000) ? 'Mobile' : 'Web'
        })
            .then(() => {
                setIsSlotEdit(false)
                toast.success('Slot Updated')
                props.fetchOrderDetails()
                if (props.doordashFee > 0) {
                    setTimeout(() => {
                        fetchEstimate()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setIsUpdateSlotLoading(false)
            })
    }

    return (
        <div
            style={{
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: 12
            }}
        >
            {props.isTracking ? (
                <>
                    {props.tracking?.map((data) => (
                        <div className={classes.infoContainer}>
                            <Typography className={classes.infoContainerHead}>
                                {data.order_action?.includes('DASHER') ? '' : 'Order'} {data.order_action}
                            </Typography>
                            {' '}
                            <Typography className={classes.infoContainerVal}>
                                <Typography style={{ fontWeight: '700', fontSize: '.85rem' }} component="span">
                                    {data.created_at}
                                </Typography>
                                {' '}
                                {Boolean(data.event_details) && (
                                    <Typography style={{ fontWeight: '400', fontSize: '.85rem' }} component="span">
                                        {data.event_details}
                                    </Typography>
                                )}
                            </Typography>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props._1header.style }}>
                            {props._1header.val}
                        </Typography>
                        <Typography style={{ ...props._1value.style }} className={classes.infoContainerVal}>
                            {props._1value.val}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props._2header.style }}>
                            {props._2header.val}
                        </Typography>
                        <Typography style={{ ...props._2value.style }} className={classes.infoContainerVal}>
                            {props._2value.val}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props._3header.style }}>
                            {props._3header.val}
                        </Typography>
                        <Typography style={{ ...props._3value.style }} className={classes.infoContainerVal}>
                            {props._3value.val}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props._4header.style }}>
                            {props._4header.val}
                        </Typography>
                        <Typography style={{ ...props._4value.style }} className={classes.infoContainerVal}>
                            {props._4value.val}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props?._5header?.style }}>
                            {props?._5header?.val}
                        </Typography>
                        <Typography style={{ ...props?._5value?.style }} className={classes.infoContainerVal}>
                            {props?._5value?.val}
                        </Typography>
                    </div>

                    {((props.orderStatus == 'a') || (props.orderStatus == 'r')) && (props.doordashFee > 0) && (props.otype == 'delivery') && (
                        <div
                            className="tw-text-[#0076E3] tw-underline tw-cursor-pointer tw-flex tw-items-center tw-gap-2"
                            onClick={() => {
                                if (((deliveryStatus == 'cancelled') || (deliveryStatus == 'quote') || !deliveryStatus)) {
                                    if (props.doordashFee > 0) {
                                        fetchEstimate()
                                    }
                                } else {
                                    confirm({
                                        title: 'Doordash has already been requested to deliver this order. Would you like to cancel this request',
                                    })
                                        .then(() => {
                                            nodeAxios.post(`/doordash/cancel-delivery`, {
                                                orderId: props._1value.val,
                                                deliveryId: externalDeliveryId
                                            })
                                                .then((res) => {
                                                    toast.success('Doordash delivery cancelled')
                                                    props.fetchOrderDetails()
                                                    fetchDeliveryStatus()
                                                    if (props.doordashFee > 0) {
                                                        fetchEstimate()
                                                    }
                                                })
                                                .finally(() => {

                                                })
                                        })
                                        .catch(() => {

                                        })
                                        .finally(() => {
                                        })
                                }
                            }}
                        >
                            {((deliveryStatus == 'cancelled') || (deliveryStatus == 'quote') || !deliveryStatus) ? "Request External Delivery partner" : "Cancel external delivery partner request"}

                            {isEstimateLoading && (
                                <span className="tw-dui-loading tw-dui-loading-spinner tw-dui-loading-xs"></span>
                            )}
                        </div>
                    )}

                    {isSlotEdit ? (
                        <div>
                            <TimeSlotPicker
                                isMenu={false}
                                isCheckout={true}
                                address={true}
                                timeSlotData={timeslot}
                                onTimeslotChange={(ts) => {
                                    setTimeslot(ts)
                                }}
                                onDeliveryChange={(dateTime) => {
                                    setForceUpdate((prevState) => !prevState)
                                    console.log(dateTime)
                                    setDeliveryPickupSlot(dateTime)

                                }}
                                delivery={deliveryPickupSlot}
                                type={props.otype}
                                isSmall={false}
                                isCollapseIcon={true}
                            />
                            <div
                                className="tw-flex tw-justify-end tw-gap-1"
                            >
                                <button
                                    onClick={() => {
                                        setIsSlotEdit(false)
                                    }}
                                    className="tw-dui-btn tw-dui-btn-sm tw-dui-btn-ghost"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="tw-dui-btn tw-dui-btn-sm tw-dui-btn-info tw-text-white"
                                    onClick={handleSlotUpdate}
                                >
                                    Save
                                    {isUpdateSlotLoading && (
                                        <span className="tw-dui-loading tw-dui-loading-spinner tw-dui-loading-xs"></span>
                                    )}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.infoContainer}>
                            <Typography className={classes.infoContainerHead} style={{ ...props?._6header?.style }}>
                                {props?._6header?.val}
                            </Typography>
                            <Typography style={{ ...props?._6value?.style }} className={classes.infoContainerVal}>
                                {props?._6value?.val}
                            </Typography>

                            {isEditSlotLoading && (
                                <span className="tw-dui-loading tw-dui-loading-spinner tw-dui-loading-xs"></span>
                            )}

                            {(props.otype == 'delivery') && (
                                <button
                                    onClick={() => {
                                        if (((deliveryStatus == 'cancelled') || (deliveryStatus == 'quote') || !deliveryStatus)) {
                                            setIsSlotEdit(true)
                                        }

                                        if (deliveryStatus && (deliveryStatus != 'cancelled')) {
                                            confirm({
                                                title: 'Doordash has already been requested to deliver this order. Would you like to cancel this request',
                                            })
                                                .then(() => {
                                                    setIsEditSlotLoading(true)
                                                    nodeAxios.post(`/doordash/cancel-delivery`, {
                                                        orderId: props._1value.val,
                                                        deliveryId: externalDeliveryId
                                                    })
                                                        .then((res) => {
                                                            toast.success('Doordash delivery request has been cancelled')
                                                            props.fetchOrderDetails()
                                                            fetchDeliveryStatus()
                                                            setIsSlotEdit(true)
                                                        })
                                                        .catch(() => {
                                                            toast.error('We cannot edit the delivery date/time for this order, as Doordash delivery request is already in progress & cannot be cancelled.')
                                                        })
                                                        .finally(() => {
                                                            setIsEditSlotLoading(false)
                                                        })
                                                })
                                                .catch(() => {

                                                })
                                                .finally(() => {
                                                })
                                        }
                                    }}
                                    className="tw-dui-btn tw-dui-btn-circle tw-dui-btn-xs"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                    </svg>
                                </button>
                            )}
                        </div>
                    )}
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props?._6header?.style }}>
                            {props?._7header?.val}
                        </Typography>
                        <Typography style={{ ...props?._6value?.style }} className={classes.infoContainerVal}>
                            {props?._7value?.val}
                        </Typography>
                    </div>
                </>
            )}

            {mobile ? (
                <Drawer
                    PaperProps={{
                        style: {
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15
                        }
                    }}
                    anchor="bottom"
                    open={showDeliveryOption}
                    onClose={() => {
                        setShowDeliveryOption(false)
                    }}
                >
                    <DeliveryOption2
                        orderDetails={props.orderDetails}
                        order={props.order}
                        pickupTimeEstimated={pickupTimeEstimated}
                        isDoordashDisabled={isDoordashDisabled}
                        user={user}
                        drivers={drivers}
                        isDoorDashEnabled={true}
                        estimate={estimate}
                        onClose={() => {
                            props.fetchOrderDetails()
                            fetchDeliveryStatus()
                            setShowDeliveryOption(false)
                        }}
                    />
                </Drawer>
            ) : (
                <Dialog
                    open={showDeliveryOption}
                    onClose={() => {
                        setShowDeliveryOption(false)
                    }}
                >
                    <DeliveryOption2
                        orderDetails={props.orderDetails}
                        order={props.order}
                        pickupTimeEstimated={pickupTimeEstimated}
                        isDoordashDisabled={isDoordashDisabled}
                        user={user}
                        drivers={drivers}
                        isDoorDashEnabled={true}
                        estimate={estimate}
                        onClose={() => {
                            props.fetchOrderDetails()
                            fetchDeliveryStatus()
                            setShowDeliveryOption(false)
                        }}
                    />
                </Dialog>
            )}
        </div >
    )
}