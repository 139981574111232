import { useNavigate, useParams } from 'react-router'

import clsx from 'clsx'
import React, { forwardRef, useContext } from 'react'
import { toast } from 'react-toastify'
import baseUrl from '../Util/baseUrl'
import MarketingContext from '../Util/marketingContext'
import routePaths from '../Util/route-paths'
import TwitterLogin from '../../TwitterLogin/TwitterLogin'
import styles from './TwitterButton.module.scss'

const TwitterButton = forwardRef((props, ref) => {

    const navigate = useNavigate()

    const { fetchSocialList, fetchAll } = useContext(MarketingContext)

    const onSuccess = (response) => {
        response.json().then(body => {
            toast.success(`${body?.data?.screen_name} Connected`)
            fetchAll()
            fetchSocialList()
            navigate(routePaths.marketingDashboard)
        });
    }

    const onFailed = (error) => {
        toast.error(error)
    }

    return (
        <TwitterLogin
            ref={ref}
            className={clsx(styles.btn, props.twitterBtnClass)}
            text={props.text || "Connect"}
            loginUrl={`${baseUrl}/social-account/verifier-twitter`}
            onFailure={onFailed}
            onSuccess={onSuccess}
            requestTokenUrl={`${baseUrl}/social-account/reverse-twitter`}
            showIcon={props.showIcon || false}
            forceLogin={false}
        />
    )
})

export default TwitterButton