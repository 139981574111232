import React, { useMemo } from 'react'
import styles from './MarketingConnections.module.scss'
import { FaFacebookSquare, FaTwitter, FaInstagram } from 'react-icons/fa'
import ConnectionItems from './ConnectionItems/ConnectionItems';
import FacebookButton from './FacebookButton/FacebookButton';
import TwitterButton from './TwitterButton/TwitterButton';
import { useId } from 'react';

const MarketingConnections = () => {

    const id = useId()

    const connections = useMemo(() => [
        {
            icon: <FaFacebookSquare className={styles.social_icon} />,
            title: 'Facebook',
            containerClassName: styles.facebook,
            button: <FacebookButton />
        },
        {
            icon: <FaTwitter className={styles.social_icon} />,
            title: 'Twitter',
            containerClassName: styles.twitter,
            button: <TwitterButton />
        },
        {
            icon: <FaInstagram className={styles.social_icon} />,
            title: 'Instagram',
            containerClassName: styles.instagram,
            button: <FacebookButton />
        },
    ], []);

    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.heading}
            >
                To Get Started Connect your Social Account First
            </div>

            <div
                className={styles.connections_container}
            >
                {connections.map((data) => (
                    <ConnectionItems
                        key={id + data.title}
                        {...data}
                    />
                ))}
            </div>
        </div>
    )
}

export default MarketingConnections