import React, { useEffect, useState, useRef } from "react";
import { Typography, Button, TableContainer, Paper, Table, TableBody, TableCell, TableRow, TableHead } from "@mui/material";
import Package from "./Parts/Package";
import { useNavigate } from "react-router-dom";
import Buttons from "../Signup/Parts/Buttons";
import GetStarted from "../../../util_comps/GetStarted";
import "./style.css";
import { CheckCircleOutline, Check, Close, ArrowBack } from "@mui/icons-material";
import LightTooltip from "../../../util_comps/LightTooltip";
import Const from "../../helpers/const";
import { fontSize, fontWeight } from "@mui/system";
import Shop from '../../../assets/shop.png';
import Retail from '../../../assets/retail.png'
import Laptop from '../../../assets/laptop-phone.png'
import Icon1 from '../../../assets/speedometer.png';
import Icon2 from '../../../assets/search.png';
import Icon3 from '../../../assets/shopping-store.png';
import Icon4 from '../../../assets/best-price.png';
import Icon5 from '../../../assets/dashboard.png';
import Icon6 from '../../../assets/checklists.png';
import Icon7 from '../../../assets/review.png';
import Icon8 from '../../../assets/badge.png';
import Icon9 from '../../../assets/notebook-computer.png';
import Icon10 from '../../../assets/product.png';
import Icon11 from '../../../assets/driver-license.png';
import Icon12 from '../../../assets/location.png';
import Icon13 from '../../../assets/payment.png';
import LeftLaptop from '../../../assets/laotop.png';
import Phone from '../../../assets/iphone.png';
import OurFeatures from "../OurFeatures/OurFeatures";

const Pricing = (props) => {
  const history = useNavigate();

  const [showExploreNow, setShowExploreNow] = useState(false)

  const rows = [
    {
      sales: "$0 - $100k",
      fees: "- 1.59%"
    },
    {
      sales: "$100k - $250k",
      fees: "- 1.49%"
    },
    {
      sales: "$250k - $500k",
      fees: "- 1.39%"
    },
    {
      sales: "$500k - $1M",
      fees: "- 1.29%"
    },
    {
      sales: "$1M+",
      fees: "- 1.19%"
    }
  ];

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#fdfdfd" }}>
      {showExploreNow ? (
        <>
          <div style={{ margin: "30px 10vw", textAlign: "center", color: "black" }}>
            <div style={{ marginBottom: "8vh", paddingTop: "0px" }}>
              <div
                onClick={() => setShowExploreNow(false)}
                style={{
                  display: 'flex',
                  fontSize: '18px',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
              >
                <ArrowBack /> Back
              </div>
              <Typography
                variant="h1" className="titletext"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "20px",
                  fontSize: '45px'
                }}
              >
                Don't want to Pay Transaction Fees ?
                <div style={{ fontSize: '20px', fontWeight: 700, color: '#ef2d2c' }}>
                  Flat Rate Model and No Transaction Fees, No Setup Fees, and No Contracts
                </div>
              </Typography>
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              color: "black",
            }}
          >
            <div className='mobileview' style={{ display: "flex", margin: "auto", marginBottom: '30px', width: "97%", justifyContent: 'center', minWidth: '350px', flexWrap: 'wrap' }}>
              <div className="boxshaodow" style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ padding: '30px', margin: 'auto' }}>
                  <div className="plan-image">
                    <img src={Retail} />
                  </div>
                  <Typography variant="h3" className="title" style={{ marginTop: '10px' }}>
                    Flat Rate Plan
                  </Typography>
                  <Typography style={{ display: "flex", justifyContent: "center", color: "red", fontSize: '75px' }} variant="h3" className="pricetitle">
                    {Const.isOurDesiStore ? "$299" : "$99"}
                  </Typography>
                  <p style={{ display: "flex", justifyContent: "center", fontSize: '20px', fontWeight: 'normal' }} className="permonth">per month</p>


                  <div className="fees" style={{ textAlign: 'center', fontSize: '12px' }}>
                    Absolutely no Transaction Fees.
                  </div>

                  <div className="marginbutton" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      onClick={(e) => history("/SignUp")}
                      className="selectredbutton"
                    >
                      Get Started
                    </Button>
                  </div>
                </div>

                <div className="listitem" style={{ padding: 'auto', margin: 'auto' }}>
                  <ul style={{ marginTop: '15px' }}>
                    <li style={{ marginBottom: '50px' }}>
                      <span>
                        <span className="checkicon">
                          <CheckCircleOutline className="greencheck" />
                        </span>{" "}
                        A custom branded website for your store that will look like ‘YourStoreName.com’{" "}
                      </span>
                    </li>
                    <li style={{ marginBottom: '50px' }}>
                      <span>
                        <span className="checkicon">
                          <CheckCircleOutline className="greencheck" />
                        </span>{" "}
                        All your inventory posted online and automatically synced
                      </span>
                    </li>
                    <li style={{ marginBottom: '50px' }}>
                      <span>
                        <span className="checkicon">
                          <CheckCircleOutline className="greencheck" />
                        </span>{" "}
                        All product descriptions, categories, and pictures automatically filled for you{" "}
                      </span>
                    </li>
                    <li style={{ marginBottom: '50px' }}>
                      <span>
                        <span className="checkicon">
                          <CheckCircleOutline className="greencheck" />
                        </span>{" "}
                        Your products will show up on Google Shopping search results{" "}
                      </span>
                    </li>
                    <li style={{ marginBottom: '50px' }}>
                      <span>
                        <span className="checkicon">
                          <CheckCircleOutline className="greencheck" />
                        </span>{" "}
                        For an additional $99 a month, we’ll create and maintain your own Apple and Android mobile app.{" "}
                      </span>
                    </li>

                  </ul>
                </div>
              </div>

            </div>
          </div>

          <div style={{ marginTop: '40px', marginBottom: '30px' }}>
            <Typography
              variant="h2"
              style={{ fontWeight: "bold", fontSize: '30px', textAlign: 'center' }}
              className="bigtext"
            >
              Every Plan includes the Following
            </Typography>
            <br />
          </div>

          <div className='mobileview' style={{ display: "flex", margin: "auto", marginBottom: '30px', width: "97%", justifyContent: 'space-evenly', flexWrap: 'wrap' }}>

            <div className="boxshaodow" style={{ width: '45%', margin: 'auto' }}>
              <p style={{ display: "flex", justifyContent: "start", fontSize: '20px', fontWeight: 'bold', paddingLeft: '30px', paddingTop: '40px', color: 'red' }} className="permonth">Technology</p>

              <div className="listitem">
                <ul>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon1} className="redcheck" />
                      </span>{" "}
                      Lightning-Fast Website Speeds for Quick Browsing & Easy Searching{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon2} className="redcheck" />
                      </span>{" "}
                      Search Engine Visibility{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon3} className="redcheck" />
                      </span>{" "}
                      Multi-Store Capabilities{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon4} className="redcheck" />
                      </span>{" "}
                      Unlimited Subscribers & Transactions{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon5} className="redcheck" />
                      </span>{" "}
                      Analytics dashboard with real time metrics{" "}
                    </span>
                  </li>

                </ul>
              </div>
            </div>

            <div className="boxshaodow" style={{ width: '45%' }}>
              <p style={{ display: "flex", justifyContent: "start", fontSize: '20px', fontWeight: 'bold', paddingLeft: '30px', paddingTop: '40px', color: 'red' }} className="permonth">Integrations</p>

              <div className="listitem">
                <ul>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon6} className="redcheck" />
                      </span>{" "}
                      Inventory integration and syncing with your POS system{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon7} className="redcheck" />
                      </span>{" "}
                      Product Images / Descriptions / Categories / Ratings provided on your website{" "}
                    </span>
                  </li>

                </ul>
              </div>
            </div>

          </div>

          <div className='mobileview' style={{ display: "flex", margin: "auto", marginBottom: '30px', width: "97%", justifyContent: 'space-evenly', flexWrap: 'wrap' }}>

            <div className="boxshaodow" style={{ width: '45%' }}>
              <p style={{ display: "flex", justifyContent: "start", fontSize: '20px', fontWeight: 'bold', paddingLeft: '30px', paddingTop: '40px', color: 'red' }} className="permonth">Customization</p>

              <div className="listitem">
                <ul>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon8} className="redcheck" />
                      </span>{" "}
                      Promos / Coupons based on customizable rules{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon9} className="redcheck" />
                      </span>{" "}
                      Total creative control of your website & app{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon10} className="redcheck" />
                      </span>{" "}
                      Ability to group products and display promotional banners on your website{" "}
                    </span>
                  </li>

                </ul>
              </div>
            </div>

            <div className="boxshaodow" style={{ width: '45%' }}>
              <p style={{ display: "flex", justifyContent: "start", fontSize: '20px', fontWeight: 'bold', paddingLeft: '30px', paddingTop: '40px', color: 'red' }} className="permonth">Fulfillment</p>

              <div className="listitem">
                <ul>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon11} className="redcheck" />
                      </span>{" "}
                      Ability to fulfill Pickup / Delivery / Shipping orders as per your license requirements{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon12} className="redcheck" />
                      </span>{" "}
                      Define multiple delivery zones and ability to charge delivery fees based on each zone{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="checkicon">
                        <img src={Icon6} className="redcheck" />
                      </span>{" "}
                      Store/business listing page with directions, contact info, and reviews{" "}
                    </span>
                  </li>

                </ul>
              </div>
            </div>
          </div>

          <div
            className="gaptop"
          >
            <Typography
              variant="h2"
              style={{ fontWeight: "normal", textAlign: "center" }}
              className="bigtext"
            >
              What are you waiting for? <str style={{ fontWeight: 'bold' }}>Start here and we’ll get in touch with you</str>
            </Typography>
            <br />
            <div className="marginbutton" style={{ margin: '5px 0 10px 0', textAlign: 'center' }}>
              <Button
                onClick={(e) => history("/SignUp")}
                className="selectredbutton"
              >
                Sign Up
              </Button>
            </div>
          </div>
        </>
      ) : (
        <OurFeatures
          setShowExploreNow={setShowExploreNow}
        />
      )}
    </div>
  );
};

export default Pricing;
