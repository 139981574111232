import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import routePaths from '../../../config/route-paths';
import SearchProductbyCategoryimg from "../../../assets/Search-Product-by-Category.png";
import SearchProductbyCategorydesistoreimg from "../../../assets/Search-Product-by-Category-desistore.png";
const SearchProductbyCategory = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={productinventory} />
            </div>
            Product & Inventory               
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.addNewProduct}>How to Add New Products</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.createNewTags} >Create New Tags</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.assigntagstoproduct}>Assign Tag to Product</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.uploadInventory}>Upload Inventory</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.searchProductbyCategory} className={cn('selected')}>Search Product by Category</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.editProductAttributes}>Edit Product Attributes</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.changingItemVisiblity}>Changing Item Visiblity</Link></li>
         </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Search Product by Category     
         </Typography>
         <p><strong>If you would like to view your products by category, you can use filters at the top of the ‘My Inventory’ page.</strong></p>
         
         <p>
            
         <ol>
            <li>On the ‘<strong>My Inventory</strong>’ page, there are four drop-down menus. The first is labeled ‘<strong>Category</strong>’, and the following three are labeled ‘<strong>Subcategory</strong>’.
</li>

    <span className={cn('addnewproductsimg')}>
    <img src={Const.isOurDesiStore ? SearchProductbyCategorydesistoreimg : SearchProductbyCategoryimg}/>
    </span>

    <li>Select a main category from the ‘<strong>Category</strong>’ drop-down.</li>

<li>Proceed to the first ‘<strong>Subcategory</strong>’ drop-down. Here, you can further refine your filter with a more specific subcategory.</li>
<li>You can continue to narrow down your search by selecting increasingly specific subcategories. Keep in mind that certain product categories do not necessarily have three subcategories. Certain types of products may only be subcategorized once or twice.</li>
<li>To reset your search parameters and view your entire inventory again, select ‘<strong>Selected</strong>’ from the first ‘<strong>Category</strong>’ drop-down.</li>
         </ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default SearchProductbyCategory