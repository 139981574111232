import React from "react";
import { Typography } from "@mui/material";
import {Check} from '@mui/icons-material';
import Const from "../../../../helpers/const";


const Steps = ({steps,setActivePage,activePage}) => {
    return (
        <div className="getstarted-steps" style={{ display: "flex", flexDirection: "column" }}>
            {steps.map((val,i) => {
                return (
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center',cursor: 'pointer'}}>
                        <div onClick={() => setActivePage(val.page)} style={{ display: "flex", backgroundColor: activePage === val.page ? 'rgba(207, 205, 202,.4)' : 'transparent',alignItems: "center" }}>
                            <div
                                style={{
                                    width: 23,
                                    height: 23,
                                    borderRadius: 100,
                                    border: "2px solid #ECBFBF",
                                    backgroundColor: val.saved ? "#EA2D1D" : "transparent",
                                    marginRight: '20px',
                                    cursor: 'pointer',
                                    position: 'relative'
                                }}
                            >
                                {val.saved ? <Check style={{transform: 'scale(0.7)', color: 'white', position: 'absolute', top: '-8%', left: '-8%'}} /> : null}
                            </div>
                            <Typography
                                style={{
                                    fontSize: "1rem",
                                    borderBottom: "1px solid black",
                                    paddingBottom: "2px",
                                    color: 'black'
                                }}
                            >
                                {val.page === 'Liquor License' ? Const.isOurDesiStore ? 'Business License' : 'Liquor License' : val.page}
                            </Typography>
                        </div>
                        {i === steps.length - 1 ? null : <div style={{backgroundColor: '#EAEAEA', height: '22px', width: '1px', marginLeft: '11px'}}></div>}
                    </div>
                );
            })}
        </div>
    );
};


export default Steps;