import React from 'react'
import { FaFacebook, FaTwitter } from 'react-icons/fa'
import imageBaseUrl from '../Util/imageBaseUrl'
import styles from './Comments.module.scss'
import { getImage } from '../Util/helpers'

const Comments = (props) => {
    return (
        <div
            className={styles.container}
            onClick={props.onClick}
        >
            <div
                style={{
                    color: (props.title == 'Post need attention') ? '#FF5E5E' : 'rgb(141, 141, 141)'
                }}
                className={styles.title}
            >
                {props.title}
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: 6,
                        width: '100%'
                    }}
                >
                    {props.images && (
                        <>
                            {props.images[0]?.type_id == 1 ? (
                                <video
                                    style={{
                                        height: 50,
                                        width: 50,
                                        objectFit: 'cover',
                                        borderRadius: '50%'
                                    }}
                                    src={`${imageBaseUrl}/SocialPostImage/${props.groupId}/${props.images[0]?.image}`}
                                />
                            ) : (
                                <img
                                    style={{
                                        height: 50,
                                        width: 50,
                                        objectFit: 'cover',
                                        borderRadius: '50%'
                                    }}
                                    src={`${imageBaseUrl}/SocialPostImage/${props.groupId}/${props.images[0]?.image}`}
                                />
                            )}
                        </>
                    )}

                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <div
                            className={styles.description_container}
                        >
                            {props.description}
                        </div>

                        <div
                            style={{
                                fontSize: 13,
                                color: '#929292'
                            }}
                        >
                            {props.time}
                        </div>
                    </div>
                </div>

                <div>
                    {props.isFacebook && (
                        <FaFacebook
                            color="#4267B2"
                            fontSize={20}
                        />
                    )}

                    {props.isInstagram && (
                        <img
                        src={getImage('instagram.png-1699456353.png')}
                            height={20}
                        />
                    )}

                    {props.isTwitter && (
                        <FaTwitter
                            color="#4267B2"
                            fontSize={20}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Comments