import { addSeconds, differenceInSeconds, format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import { useStopwatch } from 'react-timer-hook';
import useInterval from './useInterval';
import { utcToZonedTime } from 'date-fns-tz';

const OrderTimer = (props) => {
    console.log(props.user?.data?.venue_timezone)
    const zonedDate1 = utcToZonedTime(new Date(), props.user?.data?.venue_timezone);
    const initialDiffInSeconds = useRef(differenceInSeconds(zonedDate1, props.datetime))
    const [timeElapsed, setTimeElapsed] = useState('');

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         const newDiffInSeconds = initialDiffInSeconds + differenceInSeconds(new Date(), new Date());
    //         const elapsedTime = addSeconds(new Date(0), newDiffInSeconds);  // Date 0 is the epoch time

    //         const hours = format(elapsedTime, 'HHHH');
    //         const minutes = format(elapsedTime, 'mm');
    //         const seconds = format(elapsedTime, 'ss');

    //         timeElapsedRef.current = `${hours}:${minutes}:${seconds}`
    //         setTimeElapsed(`${hours}:${minutes}:${seconds}`);
    //     }, 1000);

    //     return () => clearInterval(intervalId);  // Cleanup interval on component unmount
    // }, [props.datetime]);

    useInterval(() => {
        initialDiffInSeconds.current = initialDiffInSeconds.current + 1
        // const elapsedTime = addSeconds(new Date(0), initialDiffInSeconds.current);  // Date 0 is the epoch time
        const hours = Math.floor(initialDiffInSeconds.current / 3600);
        const minutes = Math.floor((initialDiffInSeconds.current % 3600) / 60);
        const remainingSeconds = initialDiffInSeconds.current % 60;

        // const hours = format(elapsedTime, 'HHHH');
        // const minutes = format(elapsedTime, 'mm');
        // const seconds = format(elapsedTime, 'ss');

        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

        setTimeElapsed(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
    }, 1000);

    return (
        <div
            style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: 4,
                padding: 2
            }}
        >
            {timeElapsed}
        </div>
    )
}

export default OrderTimer