import { AttachMoney, Close, Delete, DeleteForever, DragIndicator, Edit } from '@mui/icons-material'
import { Button, DialogContent, DialogTitle, FormControlLabel, FormHelperText, IconButton, InputAdornment, Tooltip, outlinedInputClasses } from '@mui/material'
import { useFormik } from 'formik'
import React, { useRef, useState } from 'react'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import styles from './AddPropertiesDialog.module.scss'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch'
import { reorder } from '../../CustomPageBuilder/EditSection/Util/helpers'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import MenuIcon from '@mui/icons-material/Menu';
import GenericTextField from '../../Library/GenericTextField/GenericTextField'
import * as Yup from 'yup';
import { getImage2, getImage3 } from '../../../utils/helpers'

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 5px',
    margin: `0 0 8px 0`,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const AddPropertiesDialog = (props) => {

    const imageRef = useRef()

    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: props.isEdit ? props?.editObj : {
            title: '',
            image_file: '',
            image_link: '',
        },
        // enableReinitialize: true,
        onSubmit: (values) => {
            setIsLoading(true)

            const fd = new FormData()
            Object.entries(values).forEach(([key, value]) => {
                fd.append(key, value)
            })

            axios.post(props.isEdit ? `/product-property/edit/${props?.editObj?.id}` : '/product-property/create', fd)
                .then((res) => {
                    if(props.onSuccess){
                        props.onSuccess()
                    }
                    props.onClose()
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    })

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            formik.values.options,
            result.source.index,
            result.destination.index
        );

        formik.setFieldValue('options', newitems)
    };
    console.log(formik)
    return (
        <>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <input
                    style={{
                        display: 'none'
                    }}
                    ref={imageRef}
                    type="file"
                    onChange={(event) => {
                        formik.setFieldValue('image_file', event.target.files[0])
                        formik.setFieldValue('image_link', URL.createObjectURL(event.target.files[0]))
                        formik.setFieldValue('image_file_remove', 0)
                        event.target.value = ''
                    }}
                />
                <div
                    className={styles.form_container}
                >
                    <div
                        className={styles.title_container}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                                position: 'relative'
                            }}
                            onClick={() => {
                                imageRef.current?.click()
                            }}
                        >
                            <img
                                style={{
                                    minWidth: 100,
                                    minHeight: 100
                                }}
                                src={formik.values.image_link || (formik.values.image && getImage3(formik.values.image)) || getImage2('addimage.png-1699973235.png')}
                                height={100}
                            />

                            {(Boolean(formik.values.image_file) || Boolean(formik.values.image)) && (
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        padding: 2
                                    }}
                                    onClick={(event) => {
                                        formik.setFieldValue('image_file_remove', 1)
                                        formik.setFieldValue('image_file', '')
                                        formik.setFieldValue('image_link', '')
                                        formik.setFieldValue('image', '')
                                        event.stopPropagation()
                                    }}
                                >
                                    <Delete
                                        style={{
                                            position: 'absolute',
                                            backgroundColor: 'white',
                                            padding: 2,
                                            left: 0,
                                            bottom: 3
                                        }}
                                    />
                                </div>
                            )}

                            <Edit
                                style={{
                                    position: 'absolute',
                                    backgroundColor: 'white',
                                    padding: 2,
                                    right: 0,
                                    bottom: 3
                                }}
                            />
                        </div>

                        <div
                            className={styles.title}
                        >
                            {props.isEdit ? "Edit Proterties" : "Add Properties"}
                        </div>
                    </div>

                    <GenericTextFieldWithLabel
                        id="title"
                        name="title"
                        label="Property"
                        placeholder="Enter property name"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        fullWidth={true}
                        required={true}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />
                </div>
            </DialogContent>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 10
                }}
            >
                <div>
                    {/* {props.isEdit && (
                        <Button
                            style={{
                                color: 'red'
                            }}
                            variant="text"
                            onClick={() => {
                                props.handleDelete(props?.editObj?.id)
                                props.onClose()
                            }}
                        >
                            Remove Product
                        </Button>
                    )} */}
                </div>

                <div
                    style={{
                        display: 'flex',
                        gap: 15
                    }}
                >
                    <Button
                        style={{
                            backgroundColor: '#d3d3d3',
                            color: 'black'
                        }}
                        variant="text"
                        onClick={() => {
                            props.onClose()
                        }}
                    >
                        Cancel
                    </Button>

                    <GenericLoadingButton
                        variant="contained"
                        loading={isLoading}
                        loadingPosition="end"
                        type="submit"
                        onClick={formik.handleSubmit}
                    >
                        Save
                    </GenericLoadingButton>
                </div>
            </div>
        </>
    )
}

export default AddPropertiesDialog