/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './FormsListHeader.module.scss';
import { Button, Dialog, DialogContent, useMediaQuery } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import CreateFormDialog from './CreateFormDialog/CreateFormDialog';

function FormsListHeader(props) {

  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [showListMenuEl, setShowListMenuEl] = useState(false);

  const showListMenu = Boolean(showListMenuEl);
  const handleOpenListMenu = (event) => {
    event.stopPropagation();
    setShowListMenuEl(event.currentTarget);
  };
  const handleCloseListMenu = (event) => {
    event.preventDefault();
    setShowListMenuEl(null);
  };

  return (
    <div className={styles.school_list_header}>
      <div className={styles.school_list_header_main}>
        <div className={styles.page_title}>Forms & Documents</div>

        <div className={styles.page_actions}>
          <Button
            variant='contained'
            color='primary'
            // id='add-field-button'
            // aria-controls={showListMenu ? 'add-field' : undefined}
            // aria-haspopup='true'
            // aria-expanded={showListMenu ? 'true' : undefined}
            // onClick={handleOpenListMenu}
            onClick={() => setIsAddUserDialogOpen(true)}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            Create New
          </Button>
          {/* <Menu
            id='add-field'
            anchorEl={showListMenuEl}
            open={showListMenu}
            onClose={handleCloseListMenu}
            classes={{ paper: styles.menu_container }}
            MenuListProps={{
              'aria-labelledby': 'add-field-button',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            <MenuItem onClick={() => setIsAddUserDialogOpen(true)}>
              Form for website
            </MenuItem>
          </Menu> */}
        </div>
      </div>

      <Dialog
        open={isAddUserDialogOpen}
        onClose={() => setIsAddUserDialogOpen(false)}
        classes={{
          container: styles.dialog_container,
          paper: styles.dialog_paper,
        }}
      >
        <DialogContent classes={{ root: styles.dialog_content }}>
          <CreateFormDialog
            closeDialog={() => setIsAddUserDialogOpen(false)}
            setIsAddUserDialogOpen={setIsAddUserDialogOpen}
            {...props}
            // rooms={rooms}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default FormsListHeader;
