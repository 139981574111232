import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoupons, postIsActiveToggle, postNewCoupon, postUpdateCoupon } from '../../redux/actions/couponsAction';
import { classname, cns } from '../../utils/bem';
import './Coupons.scss';
import { Create, Close, Inventory } from '@mui/icons-material'
import { TextField, Select, MenuItem, Snackbar, TableContainer, Paper, Table, TableHead, TableRow, TableBody, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, FormControlLabel, Checkbox } from '@mui/material'
import { Autocomplete, Pagination } from '@mui/material';
import GenericSwitch from '../Library/GenericSwitch/GenericSwitch';
import StyledTableCell from '../Library/StyledTableCell/StyledTableCell';
import StyledTableRow from '../Library/StyledTableRow/StyledTableRow';
import GenericTextField from '../Library/GenericTextField/GenericTextField';
import GenericTextFieldWithLabel from '../Library/TextFieldWithLabel/GenericTextFieldWithLabel';
import GenericSelectWithLabel from '../Library/GenericSelectWithLabel/GenericSelectWithLabel';
import ValueTextField from '../Library/ValueTextField/ValueTextField';
import GenericButton from '../Library/GenericButton/GenericButton';
import CouponsDialog from '../Dialogs/CouponsDialog/CouponsDialog';
import Loader from '../Library/Loader';
import axios from '../../utils/axiosConfig'
import { toast } from 'react-toastify';

const headings = [
    'Coupon Code',
    'Valid From',
    'Valid To',
    'Discount',
    'Valid Above',
    'Valid For',
    'Is Active',
    ''
]

const Coupons = (props) => {
    const cn = classname('coupons')

    const dispatch = useDispatch()

    const coupons = useSelector(state => state.getCouponsReducer.data)
    const couponsLoading = useSelector(state => state.getCouponsReducer.loading)
    const couponsPagination = useSelector(state => state.getCouponsReducer.pagination)

    const [couponsState, setCouponsState] = useState([])
    const [PaginationState, setPaginationState] = useState({})
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [modalForm, setModalForm] = useState({ couponFor: [], couponCode: '', validFrom: '', validTo: '', discountType: 'dollar', discountAmount: '', orderLimit: '', validAbove: '', firstPurchase: false })
    const [modalEditForm, setModalEditForm] = useState({ id: '', couponFor: [], couponCode: '', validFrom: '', validTo: '', discountType: '', discountAmount: '', orderLimit: '', validAbove: '', firstPurchase: false })
    const [newCouponErr, setNewCouponErr] = useState(false)

    const newCouponCreated = useSelector(state => state.postNewCouponReducer.loading)
    const newCoupon = useSelector(state => state.postNewCouponReducer)
    const CouponUpdated = useSelector(state => state.postUpdateCouponReducer.loading)

    useEffect(() => {
        if (!newCouponCreated) {
            dispatch(getCoupons(page))
        }
        if (!newCoupon.err && !newCouponCreated) {
            setOpen(false)
        } else {
            setNewCouponErr(newCoupon.err)
        }
    }, [newCouponCreated])

    useEffect(() => {
        if (!CouponUpdated) {
            dispatch(getCoupons(page))
        }
    }, [CouponUpdated])

    useEffect(() => {
        setCouponsState(coupons)
    }, [coupons])

    useEffect(() => {
        setPaginationState(couponsPagination)
    }, [couponsPagination])


    const handleSwitchChange = (index) => {
        setCouponsState((prevState) => {
            let newCoupons = [...prevState]
            newCoupons[index].state_id = event.target?.checked
            return newCoupons
        })
        axios.post(`/coupon/change-status/${couponsState[index].id}`)
            .then(() => {
                toast.success('Coupon Status Changed')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const handleFormSubmit = (event) => {
        event.preventDefault()
        dispatch(postNewCoupon(modalForm))
    }

    const handleEditFormSubmit = (event) => {
        event.preventDefault()
        dispatch(postUpdateCoupon(modalEditForm))
        setEditOpen(false)
    }

    const handleEditCoupon = (id) => {
        const selectedCoupon = couponsState.filter((coupon) => coupon.id === id)
        const selectedCouponObj = selectedCoupon[0]
        setModalEditForm({
            id: id,
            couponFor: selectedCouponObj.valid_for.split(',').map((val) => ({ title: val[0].toUpperCase() + val.slice(1) })),
            couponCode: selectedCouponObj.code,
            validFrom: selectedCouponObj.valid_from.split(" ")[0],
            validTo: selectedCouponObj.valid_to.split(" ")[0],
            discountType: selectedCouponObj.type,
            discountAmount: selectedCouponObj.value,
            orderLimit: selectedCouponObj.limit,
            validAbove: selectedCouponObj.min_purchase,
            firstPurchase: selectedCouponObj.first_purchase === 1 ? true : false
        })
        setEditOpen(true)
    }

    const modalAutoCompleteOptions = [
        {
            title: 'Delivery'
        },
        {
            title: 'Pickup'
        },
        {
            title: 'Shipping'
        }
    ]

    const handlePaginationChange = (event, value) => {
        dispatch(getCoupons(value))
        setPage(value)
    }

    return (
        <div
            className={cns(
                cn(),
                props.className,
            )}
        >
            <div
                className={cn('coupons-title-container')}
            >
                <h3
                    className={cn('coupons-title')}
                >
                    Coupons
                </h3>

                <GenericButton
                    variant="contained"
                    disabled={props.isActionsDisabled}
                    onClick={() => setOpen(true)}
                >
                    Add New Coupon
                </GenericButton>
            </div>

            <TableContainer
                component={Paper}
            >
                <Table
                    sx={{
                        minWidth: 700
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {headings.map((data) => (
                                <StyledTableCell
                                    style={{
                                        color: 'black'
                                    }}
                                >
                                    {data}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {couponsState.map((row, index) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    <div>
                                        {row.code}
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.valid_from?.split(" ")[0]}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.valid_to?.split(" ")[0]}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.type === 'dollar' && '$'}{row.value}{row.type === 'percentage' && '%'}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {Boolean(parseInt(row.min_purchase)) ? `$${row.min_purchase}` : '-'}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.valid_for.split(",").map((option) => {
                                        let newText = option
                                        newText = newText[0].toUpperCase() + newText.slice(1)
                                        return newText
                                    }).join(', ')}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <GenericSwitch
                                        background="#00C57E"
                                        width={60}
                                        height={24}
                                        translateX={38}
                                        thumb={19}
                                        isStatus={true}
                                        checked={row.state_id}
                                        onText="'On'"
                                        offText="'Off'"
                                        onChange={() => handleSwitchChange(index)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <IconButton
                                        onClick={() => handleEditCoupon(row.id)}
                                    >
                                        <Create
                                            className={cn('edit-icon')}
                                        />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>

                {couponsLoading ? (
                    <Loader />
                ) : (
                    <>
                        {!Boolean(couponsState.length) && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    fontSize: 25,
                                    padding: 20
                                }}
                            >
                                <div>
                                    <Inventory
                                        fontSize="large"
                                    />
                                </div>
                                <div>
                                    No coupons found
                                </div>
                            </div>
                        )}
                    </>
                )}
            </TableContainer>

            {Boolean(couponsState?.length) && (PaginationState.last_page > 1) && (
                <div
                    className={cn('pagination')}
                >
                    <Pagination
                        count={PaginationState.last_page}
                        page={page}
                        onChange={handlePaginationChange}
                        variant="outlined"
                        shape="rounded" />
                </div>
            )}

            <Dialog
                open={open || editOpen}
                onClose={() => {
                    setOpen(false)
                    setEditOpen(false)
                }}
                maxWidth="xl"
            >
                <CouponsDialog
                    modalForm={editOpen ? modalEditForm : modalForm}
                    setModalForm={setModalForm}
                    onClose={() => {
                        setOpen(false)
                        setEditOpen(false)
                        dispatch(getCoupons(page))
                    }}
                    isEdit={editOpen}
                />
            </Dialog>

            {/* <Dialog
                open={editOpen}
                onClose={() => setEditOpen(false)}
            >
                <DialogContent>
                    <form
                        onSubmit={handleEditFormSubmit}
                    >
                        <div
                            className={cn('modal-title-container')}
                        >
                            <div />
                            <h3
                                className={cn('modal-title')}
                            >
                                {modalEditForm.couponCode}
                            </h3>

                            <div
                                onClick={() => setEditOpen(false)}
                            >
                                <Close
                                    className={cn('modal-close-icon')}
                                />
                            </div>
                        </div>

                        <div
                            className={cn('modal-form-section')}
                        >
                            <div
                                className={cn('modal-form-autocomplete-item-container')}
                            >
                                <div
                                    className={cn('modal-form-medium-item-container-title')}
                                >
                                    Coupon valid for
                                    <sup
                                        className={cn('modal-required-star')}
                                    >
                                        *
                                    </sup>
                                </div>

                                <Autocomplete
                                    multiple
                                    limitTags={3}
                                    id={cn('autocomplete-input')}
                                    options={modalAutoCompleteOptions}
                                    getOptionLabel={(option) => option.title}
                                    isOptionEqualToValue={(option, value) => option.title === value.title}
                                    value={modalEditForm.couponFor}
                                    onChange={(event, newValue) => {
                                        let newArray = []
                                        newArray.push(...newValue)
                                        const arrayUniqueByKey = [...new Map(newArray.map(item =>
                                            [item['title'], item])).values()];
                                        setModalEditForm({
                                            ...modalEditForm,
                                            couponFor: arrayUniqueByKey
                                        })
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                className={cn('autocomplete-input')}
                                                variant="outlined"
                                                {...params}
                                                multiline={false}
                                                required={modalEditForm.couponFor.length === 0}
                                            />
                                        )
                                    }}
                                    ChipProps={{
                                        className: cn('autocomplete-chip')
                                    }}
                                />

                            </div>

                            <div
                                className={cn('modal-form-item-container')}
                            >
                                <div
                                    className={cn('modal-form-medium-item-container-title')}
                                >
                                    Coupon Code
                                    <sup
                                        className={cn('modal-required-star')}
                                    >
                                        *
                                    </sup>
                                </div>
                                <input
                                    className={cn('modal-form-input')}
                                    placeholder="Type your coupon code"
                                    value={modalEditForm.couponCode}
                                    // onChange={(event) => setModalEditForm({ ...modalEditForm, couponCode: event.target.value })}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div
                            className={cn('modal-form-section')}
                        >
                            <div
                                className={cn('modal-form-item-container')}
                            >
                                <div
                                    className={cn('modal-form-medium-item-container-title')}
                                >
                                    Valid from
                                    <sup
                                        className={cn('modal-required-star')}
                                    >
                                        *
                                    </sup>
                                </div>

                                <TextField
                                    className={cn('modal-form-item')}
                                    variant="outlined"
                                    id={cn('modal-date-valid-from')}
                                    type="date"
                                    value={modalEditForm.validFrom}
                                    onChange={(event) => setModalEditForm({ ...modalEditForm, validFrom: event.target.value })}
                                    inputProps={{
                                        className: cn('modal-form-input')
                                    }}
                                    required
                                />

                            </div>

                            <div
                                className={cn('modal-form-item-container')}
                            >
                                <div
                                    className={cn('modal-form-medium-item-container-title')}
                                >
                                    Valid to
                                    <sup
                                        className={cn('modal-required-star')}
                                    >
                                        *
                                    </sup>
                                </div>

                                <TextField
                                    className={cn('modal-form-item')}
                                    variant="outlined"
                                    id={cn('modal-date-valid-to')}
                                    type="date"
                                    value={modalEditForm.validTo}
                                    onChange={(event) => setModalEditForm({ ...modalEditForm, validTo: event.target.value })}
                                    inputProps={{
                                        className: cn('modal-form-input')
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        <div
                            className={cn('modal-form-section')}
                        >
                            <div
                                className={cn('modal-form-small-item-section')}
                            >
                                <div
                                    className={cn('modal-form-small-item-container')}
                                >
                                    <div
                                        className={cn('modal-form-small-item-container-title')}
                                    >
                                        Discount Type
                                    </div>
                                    <Select
                                        className={cn('select')}
                                        variant="outlined"
                                        value={modalEditForm.discountType}
                                        onChange={(event => setModalEditForm({ ...modalEditForm, discountType: event.target.value }))}
                                        inputProps={{
                                            className: cn('select-input')
                                        }}
                                    >
                                        <MenuItem value="dollar">Dollar</MenuItem>
                                        <MenuItem value="percentage">Percent</MenuItem>
                                    </Select>

                                </div>

                                <div
                                    className={cn('modal-form-small-item-container')}
                                >
                                    <div
                                        className={cn('modal-form-small-item-container-title')}
                                    >
                                        Discount Amount
                                        <sup
                                            className={cn('modal-required-star')}
                                        >
                                            *
                                        </sup>
                                    </div>
                                    <ValueInput
                                        value={modalEditForm.discountAmount}
                                        onChange={(value) => setModalEditForm({ ...modalEditForm, discountAmount: value })}
                                        type={modalEditForm.discountType}
                                        required={true}
                                        texttype="number"
                                    />
                                </div>
                            </div>

                            <div
                                className={cn('modal-form-small-item-section')}
                            >
                                <div
                                    className={cn('modal-form-small-item-container')}
                                >
                                    <div
                                        className={cn('modal-form-small-item-container-title')}
                                    >
                                        Order Limit
                                        <sup
                                            className={cn('modal-required-star')}
                                        >
                                            *
                                        </sup>
                                    </div>
                                    <input
                                        className={cn('modal-form-input')}
                                        value={modalEditForm.orderLimit}
                                        onChange={(event) => setModalEditForm({ ...modalEditForm, orderLimit: event.target.value })}
                                        type="number"
                                        required
                                    />
                                </div>
                                <div
                                    className={cn('modal-form-medium-item-container')}
                                >
                                    <div
                                        className={cn('modal-form-small-item-container-title')}
                                    >
                                        Coupon only valid above
                                        <sup
                                            className={cn('modal-required-star')}
                                        >
                                            *
                                        </sup>
                                    </div>
                                    <ValueInput
                                        value={modalEditForm.validAbove}
                                        onChange={(value) => setModalEditForm({ ...modalEditForm, validAbove: value })}
                                        type="dollar"
                                        required={true}
                                        texttype="number"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className={cn('modal-form-checkbox-section')}
                        >
                            <input
                                id={cn('modal-form-checkbox')}
                                type="checkbox"
                                checked={modalEditForm.firstPurchase}
                                onChange={(event) => setModalEditForm({ ...modalEditForm, firstPurchase: event.target.checked })}
                            />
                            <label
                                htmlFor={cn('modal-form-checkbox')}
                                className={cn('modal-form-checkbox-label')}
                            >
                                Applicable for 1st purchase only
                            </label>
                        </div>

                        <DialogActions>
                            <GenericButton
                                variant="contained"
                                type="submit"
                            >
                                Add
                            </GenericButton>

                            <GenericButton
                                variant="contained"
                                btnColor="secondary"
                                type="button"
                                onClick={() => setEditOpen(false)}
                            >
                                Cancel
                            </GenericButton>
                        </DialogActions>

                    </form>
                </DialogContent>
            </Dialog> */}
        </div>
    )
}

export default Coupons
