import React, { useEffect, useState } from 'react'
import IncDecCounter from '../../pages/OrderDetails/Parts/Products/IncDecCounter/IncDecCounter'
import { Button, DialogActions, MenuItem } from '@mui/material'
import { FieldArray, Formik, useFormik } from 'formik'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import GenericTextField from '../../Library/GenericTextField/GenericTextField'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import * as Yup from 'yup';
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import Loader from '../../Library/Loader'

const validationSchema = Yup.object().shape({
    preparation_times: Yup.array()
        .of(
            Yup.object().shape({
                start_time: Yup.string().required('This field is required'),
                end_time: Yup.string().required('This field is required'),
                dayname: Yup.string().required('This field is required'),
            })
        )
        .when('min', (min, schema) => (
            !isNaN(min) && min > 0 ? schema.test(
                'length-check-min',
                'Array length must be greater than or equal to minimum value',
                array => array.length >= min
            ) : schema
        ))
});


const PreparationTime = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [time, setTime] = useState(0)
    const [prepTime, setPrepTime] = useState()
    const [isPageLoading, setIsPageLoading] = useState(true)

    const handleSubmit = (values) => {
        setIsLoading(true)

        axios.post('/venue-preparation-time/create', {
            ...values,
            order_type: props.type,
            overall_preparation_time: time
        })
            .then(() => {
                toast.success('Preparation time updated')
                props.onClose()
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setIsLoading(false)
            })

    }

    const getDays = (times) => {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let filteredDays = days?.filter((data) => !Boolean(times?.filter(data2 => data2.dayname == data)?.length))
        return filteredDays?.map((days) => (
            <MenuItem value={days}>{days}</MenuItem>
        ))
    }

    const fetchPrepTime = () => {
        axios.get('/venue-preparation-time', {
            params: {
                filter_order_type: props.type
            }
        })
            .then((res) => {
                setPrepTime(res?.data?.data)
                setTime(res?.data?.data?.overall_preparation_time)
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setIsPageLoading(false)
            })
    }

    useEffect(() => {
        fetchPrepTime()
    }, [])


    return isPageLoading ? (
        <div
            style={{
                minWidth: 400
            }}
        >
            <Loader />
        </div>
    ) : (
        <div>
            <div
                style={{
                    fontWeight: 600
                }}
            >
                Average Preparation Time
            </div>
            <div>
                Let us know how long it usually takes to prepare an order, and we'll use this information to improve {props.type} accuracy
            </div>

            <IncDecCounter
                onMinus={(e) => {
                    setTime((prevState) => {
                        console.log(prevState)
                        if ((parseInt(prevState) - 5) >= 0) {
                            return (parseInt(prevState) - 5)
                        }
                        return parseInt(prevState)
                    })
                }}
                value={time}
                onPlus={(e) => {
                    setTime((prevState) => {
                        return parseInt(prevState) + 5
                    })
                }}
            />
            <Formik
                initialValues={prepTime || {
                    overall_preparation_time: '',
                    preparation_times: [
                        {
                            preparation_time: 0,
                            start_time: '',
                            end_time: '',
                            dayname: '',
                            order_type: props.type
                        }
                    ]
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <form
                        onSubmit={formik.handleSubmit}
                    >
                        <FieldArray
                            name="preparation_times"
                            render={arrayHelpers => (
                                <div
                                    style={{
                                        padding: '10px 10px 10px 25px'
                                    }}
                                >
                                    {formik.values.preparation_times?.map((member, index) => (
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    gap: 15
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontWeight: 600
                                                        }}
                                                    >
                                                        Minutes
                                                    </div>
                                                    <IncDecCounter
                                                        style={{
                                                            height: 46,
                                                            width: '100%'
                                                        }}
                                                        onMinus={(e) => {
                                                            if ((parseInt(member.preparation_time) - 5) >= 0) {
                                                                formik.setFieldValue(`preparation_times[${index}].preparation_time`, parseInt(member.preparation_time) - 5)
                                                            }
                                                        }}
                                                        value={member.preparation_time}
                                                        onPlus={(e) => {
                                                            formik.setFieldValue(`preparation_times[${index}].preparation_time`, parseInt(member.preparation_time) + 5)
                                                        }}
                                                    />
                                                </div>

                                                <GenericSelectWithLabel
                                                    containerStyle={{
                                                        flex: 1
                                                    }}
                                                    style={{
                                                        minWidth: 150,
                                                    }}
                                                    id={`preparation_times[${index}].dayname`}
                                                    name={`preparation_times[${index}].dayname`}
                                                    label="Day"
                                                    // containerClassname={styles.small_input_container}
                                                    value={member.dayname}
                                                    onChange={formik.handleChange}
                                                    fullWidth={true}
                                                    required={true}
                                                    {...(Boolean(formik?.touched?.preparation_times) && Boolean(formik?.errors?.preparation_times) && Array.isArray(formik.errors.preparation_times) && formik.touched.preparation_times[index]?.dayname && Boolean(formik.errors.preparation_times[index]?.dayname) && {
                                                        error: formik.errors.preparation_times?.length && formik.errors.preparation_times[index]?.dayname && Boolean(formik.errors.preparation_times[index]?.dayname),
                                                        helperText: formik.touched.preparation_times?.length && formik.touched.preparation_times[index]?.dayname && formik.errors.preparation_times[index]?.dayname
                                                    })}
                                                >
                                                    {member.dayname && (
                                                        <MenuItem style={{
                                                            display: 'none'
                                                        }} value={member.dayname}>{member.dayname}</MenuItem>
                                                    )}
                                                    {getDays(formik.values?.preparation_times)}
                                                </GenericSelectWithLabel>

                                                <GenericTextFieldWithLabel
                                                    label="Start Time"
                                                    containerStyle={{
                                                        flex: 1
                                                    }}
                                                    style={{
                                                        minWidth: 150,
                                                    }}
                                                    id={`preparation_times[${index}].start_time`}
                                                    name={`preparation_times[${index}].start_time`}
                                                    type="time"
                                                    value={member.start_time}
                                                    onChange={formik.handleChange}
                                                    {...(Boolean(formik?.touched?.preparation_times) && Boolean(formik?.errors?.preparation_times) && Array.isArray(formik.errors.preparation_times) && formik.touched.preparation_times[index]?.start_time && Boolean(formik.errors.preparation_times[index]?.start_time) && {
                                                        error: formik.errors.preparation_times?.length && formik.errors.preparation_times[index]?.start_time && Boolean(formik.errors.preparation_times[index]?.start_time),
                                                        helperText: formik.touched.preparation_times?.length && formik.touched.preparation_times[index]?.start_time && formik.errors.preparation_times[index]?.start_time
                                                    })}
                                                    fullWidth={true}
                                                />

                                                <GenericTextFieldWithLabel
                                                    label="End Time"
                                                    containerStyle={{
                                                        flex: 1
                                                    }}
                                                    style={{
                                                        minWidth: 150,
                                                    }}
                                                    id={`preparation_times[${index}].end_time`}
                                                    name={`preparation_times[${index}].end_time`}
                                                    type="time"
                                                    value={member.end_time}
                                                    onChange={formik.handleChange}
                                                    {...(Boolean(formik?.touched?.preparation_times) && Boolean(formik?.errors?.preparation_times) && Array.isArray(formik.errors.preparation_times) && formik.touched.preparation_times[index]?.end_time && Boolean(formik.errors.preparation_times[index]?.end_time) && {
                                                        error: formik.errors.preparation_times?.length && formik.errors.preparation_times[index]?.end_time && Boolean(formik.errors.preparation_times[index]?.end_time),
                                                        helperText: formik.touched.preparation_times?.length && formik.touched.preparation_times[index]?.end_time && formik.errors.preparation_times[index]?.end_time
                                                    })}
                                                    fullWidth={true}
                                                />

                                            </div>

                                            <Button
                                                variant="text"
                                                onClick={() => {
                                                    arrayHelpers.remove(index)
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </div>
                                    ))}

                                    {(formik.values.preparation_times?.length < 7) && (
                                        <div
                                        // className={styles.add_more_btn_container}
                                        >
                                            <GenericButton
                                                variant="text"
                                                onClick={() => arrayHelpers.push({
                                                    preparation_time: 0,
                                                    start_time: '',
                                                    end_time: '',
                                                    dayname: '',
                                                    order_type: props.type
                                                })}
                                            >
                                                + Add More
                                            </GenericButton>
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                        <DialogActions>
                            <Button
                                style={{
                                    color: '#989898'
                                }}
                                variant="text"
                                onClick={props.onClose}
                            >
                                Cancel
                            </Button>

                            <GenericLoadingButton
                                variant="contained"
                                loading={isLoading}
                                loadingPosition="end"
                                type="submit"
                            >
                                Save
                            </GenericLoadingButton>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default PreparationTime