import React, { useState } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import TabsSection from '../../../Library/V2/TabsSection/TabsSection';
import styles from './Notifications.module.scss';
// import OrderProperties from './OrderInfo/OrderProperties';
import { useMediaQuery } from '@mui/material';
import EmailNotification from './EmailNotification/EmailNotification';
import Loader from '../../../Library/Loader';
import TextVoiceNotification from './TextVoiceNotification/TextVoiceNotification';

const tabs = [
  {
    value: 'email_notification',
    label: 'Email Notification',
  },
  {
    value: 'text_and_voice',
    label: 'Text & Voice',
  },
];


function Notifications() {
  const { isLoading } = useOutletContext();

  const [searchParams, ] = useSearchParams();
  const section = searchParams.get('subSection');

  const [selectedTab, setSelectedTab] = useState(section || 'email_notification');
  const isSmall = useMediaQuery('(max-width: 1000px)');

  return isLoading ? <Loader /> : (
    <div className="page-wrapper tw-my-2">
      <TabsSection
        tabs={tabs}
        tabsbg='var(--bg-3)'
        tabsPadding='15px'
        tabValue={selectedTab}
        tabs_container={styles.tabs_container}
        onChange={(_, newValue) => {
          setSelectedTab(newValue)
        }}
        boxStyles={{
          p: '0 !important',
          borderBottom: '1px solid #D3D3D3',
        }}
      />

      {selectedTab === 'email_notification' ? (
        <EmailNotification {...{ isSmall }} />
      ) : selectedTab === 'text_and_voice' ? (
        <TextVoiceNotification />
      ) : null}

    </div>
  )
}

export default Notifications;