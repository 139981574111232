import React, { useEffect, useState } from 'react'
import styles from './GoogleIds.module.scss'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import LaptopImage from '../../../assets/images/laptop.png'
import { InsertDriveFile } from '@mui/icons-material'
import GenericButton from '../../Library/GenericButton/GenericButton'
import { toast } from 'react-toastify'
import { bizBaseurl } from '../../../env'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../../utils/axiosConfig'
import { getVenueInfo } from '../../../redux/actions/venueInfoActions'

const GoogleIds = (props) => {

    const dispatch = useDispatch()
    const { storeInfo } = useSelector((state) => state.venueInfo);

    const [tagId, setTagId] = useState('')
    const [analyticsId, setAnalyticsId] = useState('')
    const [merchantId, setMerchantId] = useState('')
    const [merchantJson, setMerchantJson] = useState('')
    const [files, setFiles] = useState(null);

    const handleJsonChange = e => {
        setFiles(e.target.files[0]);
    };


    const handleGoogleSave = () => {

        let fd = new FormData()
        if (analyticsId && analyticsId !== storeInfo?.venue_social_links?.google_analytics_id) {
            fd.append('google_analytics_id', analyticsId)
        }

        if (tagId && tagId !== storeInfo?.venue_social_links?.google_tag_id) {
            fd.append('google_tag_id', tagId)
        }

        if (files && !merchantId) {
            toast.error('Please Upload json file with Merchant ID')
            return
        }

        if (Boolean(merchantId) && !files && !storeInfo?.venue_social_links?.merchant_json) {
            return
        }

        if (merchantId && merchantId !== storeInfo?.venue_social_links?.merchant_id) {
            fd.append('merchant_id', merchantId)
            fd.append('merchant_json_file', files)
        }

        axios.post(`/venue/setting`, fd, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`
            }
        }).then(() => {
            toast.success('Saved Successfully')
        })
    }

    useEffect(() => {
        if (storeInfo?.venue_social_links?.google_analytics_id) {
            setAnalyticsId(storeInfo?.venue_social_links?.google_analytics_id)
        }

        if (storeInfo?.venue_social_links?.google_tag_id) {
            setTagId(storeInfo?.venue_social_links?.google_tag_id)
        }
        if (storeInfo?.venue_social_links?.merchant_id) {
            setMerchantId(storeInfo?.venue_social_links?.merchant_id)
            setMerchantJson(storeInfo?.venue_social_links?.merchant_json)
        }
    }, [storeInfo])

    useEffect(() => {
        if (!storeInfo.venue?.Name) {
            dispatch(getVenueInfo());
        }
    }, []);

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 80
                }}
            >
                <div>
                    <GenericTextFieldWithLabel
                        label="Google Tag Id"
                        value={tagId}
                        onChange={(event) => setTagId(event.target.value)}
                    />

                    <div
                        style={{
                            position: 'relative',
                            marginTop: '10px',
                            width: '346px'
                        }}
                    >
                        <img
                            className={styles.laptop_image}
                            src={LaptopImage}
                        />
                        <iframe
                            style={{
                                width: '346px'
                            }}
                            className={styles.video_iframe}
                            width="200"
                            height="187"
                            src="https://www.youtube.com/embed/JlYG2Svyz-4"
                            title="How to Create Tag Id"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />
                    </div>
                </div>

                <div>
                    <GenericTextFieldWithLabel
                        label="Google Analytics Id"
                        value={analyticsId}
                        onChange={(event) => setAnalyticsId(event.target.value)}
                    />

                    <div
                        style={{
                            position: 'relative',
                            marginTop: '10px',
                            width: '346px'
                        }}
                    >
                        <img
                            className={styles.laptop_image}
                            src={LaptopImage}
                        />
                        <iframe
                            style={{
                                width: '346px'
                            }}
                            className={styles.video_iframe}
                            width="200"
                            height="187"
                            src="https://www.youtube.com/embed/Clzshz42TaY"
                            title="How to Create Google analytics"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />
                    </div>
                </div>

                <div>
                    <GenericTextFieldWithLabel
                        label="Merchant Id"
                        value={merchantId}
                        onChange={(event) => setMerchantId(event.target.value)}
                    />

                    <div
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <label
                            className={styles.upload_json_label}
                            htmlFor="inputjson"
                        >
                            {merchantJson ? (
                                <div
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <div>
                                        <InsertDriveFile />
                                        File Exists
                                    </div>

                                    <div
                                        style={{
                                            color: '#ef2d2c'
                                        }}
                                    >
                                        Change
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    Upload Json File
                                </div>
                            )}

                        </label>
                        <input
                            id="inputjson"
                            style={{
                                // display: 'none'
                            }}
                            type="file"
                            accept=".json"
                            onChange={handleJsonChange}
                        />
                    </div>

                    <div
                        style={{
                            position: 'relative',
                            marginTop: '10px',
                            width: '346px'
                        }}
                    >
                        <img
                            className={styles.laptop_image}
                            src={LaptopImage}
                        />
                        <iframe
                            style={{
                                width: '346px'
                            }}
                            className={styles.video_iframe}
                            width="200"
                            height="187"
                            src="https://www.youtube.com/embed/s8UwrT2xG4I"
                            title="How to Create Merchant Id"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />
                    </div>
                </div>
            </div>

            <div
                className={styles.save_btn_container}
            >
                <GenericButton
                    variant="contained"
                    onClick={handleGoogleSave}
                    disabled={props.isActionsDisabled}
                    size="large"
                >
                    Save
                </GenericButton>
            </div>
        </div>
    )
}

export default GoogleIds