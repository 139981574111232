import React from 'react'

const PortfolioIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" version="1.1" id="Layer_1" viewBox="0 0 512 512">
            <polygon style={{ fill: '#D80027' }} points="120.887,512 99.554,497.778 78.221,512 78.221,440.889 120.887,440.889 " />
            <rect x="405.333" y="166.073" style={{ fill: '#FFDA44' }} width="71.111" height="303.26" />
            <rect x="405.333" y="166.073" style={{ fill: '#FF5023' }} width="71.111" height="209.394" />
            <rect x="405.333" y="166.073" style={{ fill: '#D80027' }} width="71.111" height="115.527" />
            <rect x="405.333" y="93.867" style={{ fill: '#6C0014' }} width="71.111" height="93.867" />
            <rect x="405.333" width="71.111" height="93.867" />
            <rect x="78.222" y="0" style={{ fill: '#ACABB1' }} width="369.778" height="469.333" />
            <rect x="63.999" y="0" style={{ fill: '#818085' }} width="199.111" height="469.333" />
            <rect x="35.556" width="42.667" height="469.333" />
            <g>
                <path style={{ fill: '#FFFFFF' }} d="M130.62,220.029v-63.495h27.365c3.1,0,5.961,0.641,8.586,1.923c2.623,1.283,4.874,2.951,6.751,5.008   c1.877,2.057,3.354,4.415,4.426,7.066c1.074,2.654,1.61,5.321,1.61,8.004c0,2.803-0.506,5.531-1.519,8.183   c-1.015,2.652-2.431,5.008-4.248,7.066c-1.819,2.057-4.023,3.698-6.618,4.918c-2.593,1.222-5.44,1.833-8.54,1.833h-10.374v19.496   H130.62V220.029z M148.059,185.331h9.212c1.074,0,2.071-0.492,2.995-1.476c0.923-0.983,1.387-2.758,1.387-5.321   c0-2.623-0.536-4.412-1.61-5.366s-2.146-1.432-3.22-1.432h-8.764V185.331z" />
                <path style={{ fill: '#FFFFFF' }} d="M216.293,220.565c-4.828,0-9.212-0.924-13.147-2.772c-3.935-1.847-7.305-4.277-10.105-7.289   c-2.803-3.009-4.979-6.453-6.528-10.33c-1.552-3.874-2.325-7.869-2.325-11.984c0-4.173,0.804-8.198,2.414-12.072   c1.61-3.876,3.86-7.289,6.753-10.24c2.89-2.951,6.318-5.305,10.283-7.066c3.965-1.758,8.303-2.637,13.012-2.637   c4.828,0,9.212,0.924,13.146,2.772c3.937,1.849,7.303,4.292,10.106,7.333c2.802,3.041,4.962,6.498,6.484,10.374   c1.519,3.877,2.281,7.841,2.281,11.894c0,4.174-0.805,8.182-2.415,12.028c-1.61,3.846-3.846,7.245-6.707,10.196   c-2.862,2.951-6.275,5.321-10.24,7.11C225.338,219.671,221.002,220.565,216.293,220.565z M201.894,188.37   c0,2.146,0.297,4.218,0.895,6.215c0.594,1.998,1.489,3.77,2.682,5.321c1.193,1.552,2.697,2.803,4.517,3.758   c1.818,0.954,3.979,1.431,6.484,1.431s4.681-0.492,6.529-1.476c1.847-0.983,3.352-2.264,4.517-3.844   c1.162-1.579,2.025-3.383,2.593-5.412c0.565-2.027,0.85-4.083,0.85-6.171c0-2.146-0.3-4.217-0.895-6.215   c-0.597-1.997-1.508-3.756-2.728-5.276c-1.223-1.52-2.743-2.742-4.561-3.666c-1.819-0.923-3.949-1.387-6.394-1.387   c-2.505,0-4.665,0.478-6.484,1.431c-1.819,0.954-3.325,2.223-4.517,3.802c-1.193,1.58-2.072,3.369-2.637,5.365   C202.176,184.243,201.894,186.284,201.894,188.37z" />
                <path style={{ fill: '#FFFFFF' }} d="M257.431,220.029v-63.495h29.154c3.099,0,5.961,0.641,8.585,1.923   c2.624,1.283,4.875,2.951,6.753,5.008c1.877,2.057,3.352,4.415,4.426,7.066c1.074,2.654,1.61,5.321,1.61,8.004   c0,3.698-0.804,7.141-2.414,10.33c-1.61,3.19-3.846,5.798-6.709,7.825l13.416,23.342h-19.675l-11.18-19.497h-6.528v19.496   L257.431,220.029L257.431,220.029z M274.87,185.331h10.999c1.072,0,2.071-0.596,2.995-1.789c0.923-1.192,1.387-2.863,1.387-5.008   c0-2.206-0.536-3.89-1.61-5.053c-1.074-1.162-2.146-1.745-3.22-1.745h-10.553v13.595H274.87z" />
                <path style={{ fill: '#FFFFFF' }} d="M367.697,171.736h-18.243v48.293h-17.439v-48.293h-18.334v-15.202h54.016L367.697,171.736   L367.697,171.736z" />
                <path style={{ fill: '#FFFFFF' }} d="M130.62,298.398v-63.495h43.553v15.202h-26.113v10.553h21.285v14.131h-21.285v23.61H130.62V298.398z   " />
                <path style={{ fill: '#FFFFFF' }} d="M210.123,298.935c-4.83,0-9.213-0.923-13.147-2.772c-3.935-1.847-7.305-4.277-10.105-7.289   c-2.803-3.009-4.979-6.453-6.528-10.33c-1.552-3.874-2.325-7.869-2.325-11.984c0-4.173,0.804-8.198,2.413-12.072   c1.61-3.876,3.86-7.289,6.753-10.24c2.89-2.951,6.318-5.305,10.283-7.066c3.965-1.758,8.303-2.638,13.012-2.638   c4.83,0,9.212,0.924,13.146,2.772c3.937,1.849,7.303,4.292,10.106,7.333c2.802,3.041,4.962,6.498,6.484,10.374   c1.519,3.876,2.281,7.841,2.281,11.894c0,4.174-0.805,8.182-2.415,12.028c-1.61,3.846-3.846,7.245-6.707,10.196   c-2.861,2.951-6.275,5.321-10.24,7.11C219.17,298.041,214.832,298.935,210.123,298.935z M195.725,266.741   c0,2.146,0.297,4.218,0.895,6.215c0.594,1.998,1.489,3.77,2.682,5.321c1.193,1.552,2.697,2.803,4.517,3.758   c1.818,0.954,3.979,1.431,6.484,1.431c2.505,0,4.681-0.492,6.529-1.476c1.847-0.983,3.352-2.264,4.517-3.844   c1.162-1.579,2.025-3.383,2.593-5.412c0.565-2.027,0.85-4.083,0.85-6.171c0-2.146-0.3-4.217-0.895-6.215   c-0.597-1.997-1.506-3.756-2.728-5.276c-1.223-1.52-2.743-2.742-4.561-3.667c-1.819-0.923-3.95-1.387-6.394-1.387   c-2.505,0-4.665,0.478-6.484,1.431c-1.819,0.954-3.325,2.223-4.517,3.802c-1.193,1.58-2.072,3.369-2.638,5.365   C196.006,262.612,195.725,264.654,195.725,266.741z" />
                <path style={{ fill: '#FFFFFF' }} d="M251.261,298.398v-63.495H268.7v48.293h28.796v15.202H251.261z" />
                <path style={{ fill: '#FFFFFF' }} d="M304.919,298.398v-63.495h17.439v63.495H304.919z" />
                <path style={{ fill: '#FFFFFF' }} d="M363.226,298.935c-4.828,0-9.212-0.923-13.147-2.772c-3.935-1.847-7.305-4.277-10.105-7.289   c-2.803-3.009-4.979-6.453-6.528-10.33c-1.552-3.874-2.325-7.869-2.325-11.984c0-4.173,0.804-8.198,2.414-12.072   c1.61-3.876,3.86-7.289,6.753-10.24c2.89-2.951,6.318-5.305,10.283-7.066c3.965-1.758,8.303-2.638,13.012-2.638   c4.83,0,9.212,0.924,13.146,2.772c3.937,1.849,7.303,4.292,10.106,7.333c2.802,3.041,4.962,6.498,6.484,10.374   c1.519,3.876,2.28,7.841,2.28,11.894c0,4.174-0.805,8.182-2.415,12.028c-1.61,3.846-3.846,7.245-6.707,10.196   c-2.862,2.951-6.275,5.321-10.24,7.11C372.272,298.041,367.935,298.935,363.226,298.935z M348.828,266.741   c0,2.146,0.297,4.218,0.895,6.215c0.595,1.998,1.489,3.77,2.682,5.321c1.193,1.552,2.697,2.803,4.517,3.758   c1.818,0.954,3.979,1.431,6.484,1.431c2.505,0,4.681-0.492,6.529-1.476c1.847-0.983,3.352-2.264,4.517-3.844   c1.162-1.579,2.025-3.383,2.593-5.412c0.565-2.027,0.85-4.083,0.85-6.171c0-2.146-0.3-4.217-0.895-6.215   c-0.597-1.997-1.506-3.756-2.728-5.276c-1.223-1.52-2.743-2.742-4.561-3.667c-1.819-0.923-3.95-1.387-6.394-1.387   c-2.505,0-4.665,0.478-6.484,1.431c-1.819,0.954-3.325,2.223-4.517,3.802c-1.193,1.58-2.072,3.369-2.637,5.365   C349.11,262.612,348.828,264.654,348.828,266.741z" />
            </g>
        </svg>
    )
}

export default PortfolioIcon