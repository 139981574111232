import React, { useEffect, useRef, useState } from 'react'
import styles from './ModifierDialog.module.scss'
import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, FormHelperText, IconButton } from '@mui/material'
import { useFormik } from 'formik'
import { Close } from '@mui/icons-material'
import GenericTextFieldWithLabel from '../../../../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import IncDecCounter from '../IncDecCounter/IncDecCounter'
import clsx from 'clsx'
import Modifier from './Modifier/Modifier'

// const ModifierDialog = (props) => {

//   const [modifierValidation, setModifierValidation] = useState({})
//   const modifierValidationRef = useRef(modifierValidation);

//   useEffect(() => {
//     modifierValidationRef.current = modifierValidation;
//   }, [modifierValidation]);


//   const validation = () => {
//     const modifier = props?.customizeObj[props.isEdit ? 'modifiers_list' : 'modifier']
//     modifier.forEach(element => {
//       if (!element.modifier_min && !element.modifier_max) {
//         setModifierValidation((prevState) => {
//           const newState = { ...prevState }
//           delete newState[element.modifier_id]
//           return newState
//         })
//         // return true
//       } else if (element.modifier_min && !element.modifier_max) {
//         if ((formik.values?.modifiers[element.modifier_id]?.length || 0) >= element.modifier_min) {
//           setModifierValidation((prevState) => {
//             const newState = { ...prevState }
//             delete newState[element.modifier_id]
//             return newState
//           })
//           // return true
//         } else {
//           setModifierValidation((prevState) => ({
//             ...prevState,
//             [element.modifier_id]: `Choose at least ${element.modifier_min} option`
//           }))
//           // return false
//         }
//       } else if (!element.modifier_min && element.modifier_max) {
//         if ((formik.values?.modifiers[element.modifier_id]?.length || 0) <= element.modifier_max) {
//           setModifierValidation((prevState) => {
//             const newState = { ...prevState }
//             delete newState[element.modifier_id]
//             return newState
//           })
//           // return true
//         } else {
//           setModifierValidation((prevState) => ({
//             ...prevState,
//             [element.modifier_id]: `You can choose upto ${element.modifier_max} option only`
//           }))
//           // return false
//         }
//       } else if (element.modifier_min && element.modifier_max) {
//         if (((formik.values?.modifiers[element.modifier_id]?.length || 0) >= element.modifier_min) && ((formik.values?.modifiers[element.modifier_id]?.length || 0) <= element.modifier_max)) {
//           setModifierValidation((prevState) => {
//             const newState = { ...prevState }
//             delete newState[element.modifier_id]
//             return newState
//           })
//           // return true
//         } else {
//           setModifierValidation((prevState) => ({
//             ...prevState,
//             [element.modifier_id]: `You can choose between ${element.modifier_min} - ${element.modifier_max} option only`
//           }))
//           // return false
//         }
//       }
//     });
//   }

//   const formik = useFormik({
//     initialValues: props.isEdit ? props?.customizeObj : {
//       note: '',
//       modifiers: {},
//       modifierData: {}
//     },
//     onSubmit: (values) => {
//       validation()
//       setTimeout(() => {
//         console.log(modifierValidationRef.current, Object.entries(modifierValidationRef.current || {})?.length)

//         if(!Boolean(Object.entries(modifierValidationRef.current || {})?.length)){
//           if (props.isEdit) {
//             props.validateCartValue(values)
//           } else {
//             props.validateCartValue(props?.customizeObj, values)
//           }
//           props.onClose()
//         }
//       }, 1000);
//     }
//   })

//   return (
//     <>
//       <DialogTitle
//         align='left'
//         fontWeight={600}
//         fontSize={14}
//         borderBottom="1px solid #D3D3D3"
//         style={{
//           padding: 10,
//           // position: 'absolute',
//           // top: 0,
//           width: '100%'
//         }}
//       >
//         {props.isEdit ? props?.customizeObj?.name : props?.customizeObj?.name}

//         <IconButton
//           aria-label='close'
//           onClick={props.onClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 5,
//           }}
//         >
//           <Close />
//         </IconButton>
//       </DialogTitle>

//       <DialogContent
//         style={{
//           width: 500
//         }}
//       >
//         <GenericTextFieldWithLabel
//           variant="outlined"
//           name="note"
//           id="note"
//           label="Note"
//           placeholder="Provide any special comments towards this item"
//           value={formik.values.note}
//           onChange={formik.handleChange}
//           fullWidth={true}
//         />

//         {props?.customizeObj[props.isEdit ? 'modifiers_list' : 'modifier']?.map((data) => (
//           <div>
//             <div>
//               {data.modifier_title}
//             </div>

//             <FormGroup row={true}>
//               {data.modifier_option?.map((data1) => (
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={formik.values?.modifiers[data?.modifier_id]?.includes(data1.id) || false}
//                       onChange={(event) => {
//                         if (event.target.checked) {
//                           if (formik.values?.modifiers[data?.modifier_id]) {
//                             // formik.setFieldValue('modifierData', {
//                             //   ...formik.values?.modifierData,
//                             //   [data?.modifier_id]: [...formik.values?.modifierData[data?.modifier_id], {
//                             //     ...data1,
//                             //     price: Boolean(data.modifier_is_price || 0) ? data1.price : 0
//                             //   }]
//                             // })

//                             formik.setFieldValue('modifiers', {
//                               ...formik.values?.modifiers,
//                               [data?.modifier_id]: [...formik.values?.modifiers[data?.modifier_id], data1.id]
//                             })
//                           } else {
//                             // formik.setFieldValue('modifierData', {
//                             //   ...formik.values?.modifierData,
//                             //   [data?.modifier_id]: [{
//                             //     ...data1,
//                             //     price: Boolean(data.modifier_is_price || 0) ? data1.price : 0
//                             //   }]
//                             // })

//                             formik.setFieldValue('modifiers', {
//                               ...formik.values?.modifiers,
//                               [data?.modifier_id]: [data1.id]
//                             })
//                           }
//                         } else {
//                           const checked = formik.values?.modifiers[data?.modifier_id]?.filter((id) => id != data1.id)
//                           // const checked2 = formik.values?.modifierData[data?.modifier_id]?.filter((fdata) => fdata.id != data1.id)

//                           if (checked && checked?.length) {
//                             // formik.setFieldValue('modifierData', {
//                             //   ...formik.values?.modifierData,
//                             //   [data?.modifier_id]: checked2
//                             // })

//                             formik.setFieldValue('modifiers', {
//                               ...formik.values?.modifiers,
//                               [data?.modifier_id]: checked
//                             })
//                           } else {
//                             let modifier = formik.values?.modifiers
//                             delete modifier[data?.modifier_id]
//                             formik.setFieldValue('modifiers', modifier)

//                             // let modifierData = formik.values?.modifierData
//                             // delete modifierData[data?.modifier_id]
//                             // formik.setFieldValue('modifierData', modifierData)
//                           }
//                         }
//                       }}
//                     />
//                   }
//                   label={(
//                     <div>
//                       {data1?.title}

//                       {Boolean(data1.price) && (
//                         <span>
//                           (+ ${parseFloat(data1.price || 0).toFixed(2)})
//                         </span>
//                       )}
//                     </div>
//                   )}
//                 />
//               ))}
//               {modifierValidation[data?.modifier_id] && (
//                 <FormHelperText style={{display: 'flex'}} error>{modifierValidation[data?.modifier_id]}</FormHelperText>
//               )}
//             </FormGroup>
//           </div>
//         ))}
//       </DialogContent>

//       <DialogActions>
//         <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//           {/* <IncDecCounter
//             onMinus={(e) => {
//               if (props.isEdit) {
//                 props.setQuantityById((prevState) => {
//                   let newState = { ...prevState }
//                   newState[props?.customizeObj?.order_itemId] = (newState[props?.customizeObj?.order_itemId] == 1) ? 1 : (newState[props?.customizeObj?.order_itemId] - 1)
//                   return newState
//                 })
//               } else {
//                 props.setSelectedFormat((prevstate) => ({
//                   ...prevstate,
//                   [props?.customizeObj?.product_id]: {
//                     ...prevstate[props?.customizeObj?.product_id],
//                     quantity: (props.selectedFormat[props?.customizeObj?.product_id]?.quantity == 1) ? 1 : (props.selectedFormat[props?.customizeObj?.product_id]?.quantity - 1),
//                     isCollapse: false
//                   }
//                 }))
//               }
//             }}
//             value={props.isEdit ? props.quantityById[props?.customizeObj?.order_itemId] : props.selectedFormat[props?.customizeObj?.product_id]?.quantity}
//             onPlus={(e) => {
//               if (props.isEdit) {
//                 props.setQuantityById((prevState) => {
//                   let newState = { ...prevState }
//                   newState[props?.customizeObj?.order_itemId] = ((newState[props?.customizeObj?.order_itemId] || 1) + 1)
//                   return newState
//                 })
//               } else {
//                 props.setSelectedFormat((prevstate) => ({
//                   ...prevstate,
//                   [props?.customizeObj?.product_id]: {
//                     ...prevstate[props?.customizeObj?.product_id],
//                     quantity: ((props.selectedFormat[props?.customizeObj?.product_id]?.quantity || 1) + 1),
//                     isCollapse: false
//                   }
//                 }))
//               }
//             }}
//           /> */}

//           <Button
//             style={{
//               borderColor: '#1E9223',
//               color: '#1E9223',
//               borderRadius: 40,
//               textTransform: 'none',
//               whiteSpace: 'nowrap'
//             }}
//             variant="outlined"
//             onClick={formik.handleSubmit}
//           >
//             {props.isEdit ? "Save" : "Add to cart"}
//           </Button>
//         </div>
//       </DialogActions>
//     </>
//   )
// }

const ModifierDialog = (props) => {

  const [modifierValidation, setModifierValidation] = useState({})
  const modifierValidationRef = useRef(modifierValidation);
  const [isInit, setIsInit] = useState(false)

  useEffect(() => {
    modifierValidationRef.current = modifierValidation;
  }, [modifierValidation]);


  const validation = () => {
    console.log(formik.values)
    const modifier = props?.customizeObj[props.isEdit ? 'modifiers_list' : 'modifier']
    modifier.forEach(element => {
      if (!element.modifier_min && !element.modifier_max) {
        setModifierValidation((prevState) => {
          const newState = { ...prevState }
          delete newState[element.modifier_id]
          return newState
        })
        // return true
      } else if (element.modifier_min && !element.modifier_max) {
        if ((formik.values?.modifiers[element.modifier_id]?.length || 0) >= element.modifier_min) {
          setModifierValidation((prevState) => {
            const newState = { ...prevState }
            delete newState[element.modifier_id]
            return newState
          })
          // return true
        } else {
          setModifierValidation((prevState) => ({
            ...prevState,
            [element.modifier_id]: `Choose at least ${element.modifier_min} option`
          }))
          // return false
        }
      } else if (!element.modifier_min && element.modifier_max) {
        if ((formik.values?.modifiers[element.modifier_id]?.length || 0) <= element.modifier_max) {
          setModifierValidation((prevState) => {
            const newState = { ...prevState }
            delete newState[element.modifier_id]
            return newState
          })
          // return true
        } else {
          setModifierValidation((prevState) => ({
            ...prevState,
            [element.modifier_id]: `You can choose upto ${element.modifier_max} option only`
          }))
          // return false
        }
      } else if (element.modifier_min && element.modifier_max) {
        if (((formik.values?.modifiers[element.modifier_id]?.length || 0) >= element.modifier_min) && ((formik.values?.modifiers[element.modifier_id]?.length || 0) <= element.modifier_max)) {
          setModifierValidation((prevState) => {
            const newState = { ...prevState }
            delete newState[element.modifier_id]
            return newState
          })
          // return true
        } else {
          setModifierValidation((prevState) => ({
            ...prevState,
            [element.modifier_id]: `Choose at least ${element.modifier_min} option, You can choose upto ${element.modifier_max} option only`
          }))
          // return false
        }
      }
    });
  }

  const formik = useFormik({
    initialValues: props.isEdit ? props?.customizeObj : {
      note: '',
      modifiers: {},
      modifierData: {},
      modifierAllData: [],
    },
    onSubmit: (values) => {
      validation()
      setTimeout(() => {
        console.log(modifierValidationRef.current, Object.entries(modifierValidationRef.current || {})?.length)

        if (!Boolean(Object.entries(modifierValidationRef.current || {})?.length)) {
          if (props.isEdit) {
            props.validateCartValue(values)
          } else {
            props.validateCartValue(props?.customizeObj, values)
          }
          props.onClose()
        }
      }, 1000);
    }
  })

  useEffect(() => {

    if (!props.isEdit) {

      const filteredModifier = props?.customizeObj?.modifier?.filter((fdata) => !Boolean(fdata?.modifier_is_expandable))
      let newModifierAllData = []
      let newModifiers = {}

      filteredModifier?.forEach((data) => {

        const obj = {
          ...data,
          modifier_option: []
        }
        newModifierAllData.push(obj)

        newModifiers[data?.modifier_id] = []
      })

      props?.customizeObj?.modifier?.forEach((data) => {
        if (!Boolean(data?.modifier_is_expandable)) {
          if (data?.modifier_max == 1) {
            data?.modifier_option?.forEach((data1) => {
              if (Boolean(data1?.is_default)) {
                const filteredModifier = newModifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                const obj = {
                  ...data,
                  modifier_option: [{
                    ...data1,
                    price: Boolean(data1.price || 0) ? data1.price : 0
                  }]
                }
                console.log(obj, filteredModifier, 'obj')
                newModifierAllData = [
                  ...filteredModifier,
                  obj
                ]

                newModifiers = {
                  ...newModifiers,
                  [data?.modifier_id]: [data1.id]
                }
              }
            })
          } else {
            data?.modifier_option?.forEach((data1) => {
              if (Boolean(data1?.is_default)) {
                const filteredModifier = newModifierAllData?.filter((fdata) => fdata?.modifier_id != data?.modifier_id)
                const filteredModifier2 = newModifierAllData?.filter((fdata) => fdata?.modifier_id == data?.modifier_id)


                if (newModifiers[data?.modifier_id]) {

                  const obj = {
                    ...data,
                    modifier_option: [...filteredModifier2[0]?.modifier_option, {
                      ...data1,
                      price: Boolean(data1.price || 0) ? data1.price : 0
                    }]
                  }
                  console.log(obj, 'obj2')
                  newModifierAllData = [
                    ...filteredModifier,
                    obj
                  ]

                  newModifiers = {
                    ...newModifiers,
                    [data?.modifier_id]: [...newModifiers[data?.modifier_id], data1.id]
                  }

                } else {

                  const obj = {
                    ...data,
                    modifier_option: [{
                      ...data1,
                      price: Boolean(data1.price || 0) ? data1.price : 0
                    }]
                  }
                  console.log(obj, 'obj3')
                  newModifierAllData = [
                    ...filteredModifier,
                    obj
                  ]

                  newModifiers = {
                    ...newModifiers,
                    [data?.modifier_id]: [data1.id]
                  }
                }
              }
            })
          }
        }
      })
      // })

      formik.setFieldValue('modifierAllData', newModifierAllData);
      formik.setFieldValue('modifiers', newModifiers);

    }

    setIsInit(true)
  }, [])
  console.log('modifierts', formik.values)
  return (

    <div
      className={clsx(styles.modifiercontainer, props.isDrawer && styles.drawerbox)}
    >
      <div
        style={{
          borderBottom: '1px solid #d3d3d3',
          display: 'flex', justifyContent: 'space-between',
          padding: '5px'
        }}
      >

        <div style={{ padding: '10px 4px 10px 16px', display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
          <h2 style={{ fontSize: '18px', fontWeight: 600, margin: 0 }}>
            {props.isEdit ? props?.customizeObj?.name : props?.customizeObj?.name}
          </h2>
          <h2 style={{ fontSize: '18px', fontWeight: 600, margin: 0 }}>
            ${props.isEdit
              ? parseFloat(props?.customizeObj?.price)?.toFixed(2)
              : parseFloat(props.selectedFormat[props?.customizeObj?.product_id]?.price)?.toFixed(2)}
          </h2>
        </div>
        <IconButton
          aria-label='close'
          onClick={props.onClose}
        >
          <Close />
        </IconButton>

      </div>
      <DialogContent
        className={styles.modifieroptionbox}
        style={{
          width: 480
        }}
      >
        {isInit && props?.customizeObj[props.isEdit ? 'modifiers_list' : 'modifier']?.map((data) => (
          <Modifier
            data={data}
            formik={formik}
            modifierValidation={modifierValidation}
            isEdit={props.isEdit}
            customizeObj={props.customizeObj}
          />
        ))}

        <div className={styles.genericnotes}>
          <GenericTextFieldWithLabel
            variant="outlined"
            name="note"
            id="note"
            label="Notes"
            placeholder="Have an allergy, dietary restriction, or special request?"
            value={formik.values.note}
            onChange={formik.handleChange}
            fullWidth={true}
            styles={{ padding: '10px 0 15px 0' }}
          />
        </div>
      </DialogContent>

      <DialogActions style={{ borderTop: '1px solid #d3d3d3', position: 'sticky', bottom: '0', backgroundColor: 'white', zIndex: 1, }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {/* <IncDecCounter
            onMinus={(e) => {
              if (props.isEdit) {
                props.setQuantityById((prevState) => {
                  let newState = { ...prevState }
                  newState[props?.customizeObj?.venueProductId] = (newState[props?.customizeObj?.venueProductId] == 1) ? 1 : (newState[props?.customizeObj?.venueProductId] - 1)
                  return newState
                })
              } else {
                props.setSelectedFormat((prevstate) => ({
                  ...prevstate,
                  [props?.customizeObj?.product_id]: {
                    ...prevstate[props?.customizeObj?.product_id],
                    quantity: (props.selectedFormat[props?.customizeObj?.product_id]?.quantity == 1) ? 1 : (props.selectedFormat[props?.customizeObj?.product_id]?.quantity - 1),
                    isCollapse: false
                  }
                }))
              }
            }}
            value={props.isEdit ? props.quantityById[props?.customizeObj?.venueProductId] : props.selectedFormat[props?.customizeObj?.product_id]?.quantity}
            onPlus={(e) => {
              if (props.isEdit) {
                props.setQuantityById((prevState) => {
                  let newState = { ...prevState }
                  newState[props?.customizeObj?.venueProductId] = ((newState[props?.customizeObj?.venueProductId] || 1) + 1)
                  return newState
                })
              } else {
                props.setSelectedFormat((prevstate) => ({
                  ...prevstate,
                  [props?.customizeObj?.product_id]: {
                    ...prevstate[props?.customizeObj?.product_id],
                    quantity: ((props.selectedFormat[props?.customizeObj?.product_id]?.quantity || 1) + 1),
                    isCollapse: false
                  }
                }))
              }
            }}
          /> */}

          <Button
            style={{
              border: '1px solid #1E9223',
              color: '#1E9223',
              borderRadius: 40,
              textTransform: 'none',
              whiteSpace: 'nowrap'
            }}
            variant="outlined"
            onClick={formik.handleSubmit}
          >
            {props.isEdit ? "Save" : "Add to cart"}
          </Button>
        </div>
      </DialogActions>
    </div>
  )
}

export default ModifierDialog