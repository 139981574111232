import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import styles from './AddNewPageDialog.module.scss';
import axios, { nodeAxios } from '../../../utils/axiosConfig';
import axios2 from 'axios';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton';
import { useNavigate } from 'react-router-dom';

// const validationSchema = yup.object({
//     url: yup
//         .string()
//         .test(
//             'urlValid',
//             'For url only numbers alphabets and dashes are allowed',
//             function(value) {
//               return (this.parent.field1 || this.parent.field2 || this.parent.field3 || this.parent.field4)
//             }
//           ),
//     ,
//   });

const AddNewPageDialog = (props) => {

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: props.modalInputTitle || '',
      url: props.modalInputUrl || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsLoading(true);
      let url = '/web-builder/web-page/create';

      switch (props.pageModal) {
        case 'copy':
          url = `/web-builder/web-page/copy/${props.id}`;
          break;
        case 'edit':
          url = `/web-builder/web-page/edit/${props.id}`;
          break;
      }

      nodeAxios
        .post(url, {
          web_page_id: props.id,
          title: values.title,
          type_id: 3,
          uri: values.url,
          // template_id: props.id
        })
        .then((response) => {
          // axios2
          //   .post('https://nodeapi.mykidreports.com/convert-thumbnail', {
          //     web_page_id: response.data?.data?.result?.id,
          //   })
          //   .catch((err) => {
          //     // toast.error(err?.data?.message);
          //     console.log(err);
          //   });
          if (props.pageModal == 'add') {
            const item = response.data?.data?.result;
            navigate(
              `/website-builder/template/${item.id}/${item.title}/${item.uri}?device=desktop`
            );
            window.location.reload();
          } else {
            props.onClose();
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <DialogTitle align='center'>
        {
          {
            add: 'Add new page',
            copy: 'Copy page',
            edit: 'Edit page details',
          }[props.pageModal]
        }
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.input_field_container}>
            <TextField
              size='small'
              label='Enter Page Title'
              placeholder='Page Title'
              variant='outlined'
              id='title'
              name='title'
              fullWidth
              value={formik.values.title}
              onChange={(event) => {
                formik.setFieldValue('title', event.target.value);

                var pattern = /^[a-z\d\-_]+$/i;

                //Validating the textBox value against our regex pattern.
                var isValid = pattern.test(
                  event.target.value.toLowerCase().replaceAll(' ', '-')
                );
                console.log(isValid);
                if (isValid) {
                  formik.setFieldValue(
                    'url',
                    event.target.value.toLowerCase().replaceAll(' ', '-')
                  );
                  // formik.setFieldError('url', false)
                } else {
                  // formik.setFieldTouched('url', true)
                  formik.setFieldError(
                    'url',
                    'For url only numbers alphabets and dashes are allowed'
                  );
                }
              }}
              // error={formik.touched.title && Boolean(formik.errors.title)}
              // helperText={formik.touched.title && formik.errors.title}
              helperText='The page title will appear as the link name in your website and will be part of your page address.'
              required
            />
          </div>

          <div className={styles.input_field_container}>
            <TextField
              size='small'
              label='Enter Page address'
              placeholder='Enter Page address'
              variant='outlined'
              id='url'
              name='url'
              fullWidth
              value={formik.values.url}
              onChange={(event) => {
                var pattern = /^(?:[a-zA-Z\/\-]+|)$/;

                //Validating the textBox value against our regex pattern.
                var isValid = pattern.test(
                  event.target.value.toLowerCase().replaceAll(' ', '-')
                );

                if (isValid) {
                  formik.setFieldValue(
                    'url',
                    event.target.value.toLowerCase().replaceAll(' ', '-')
                  );
                } else {
                  // formik.setFieldTouched('url', true)
                  // formik.setFieldError('url', 'For url only numbers alphabets and dashes are allowed')
                }
              }}
              // error={true && Boolean(formik.errors.url)}
              // helperText={true && formik.errors.url}
              helperText='Only numbers, alphabets and dashes are allowed.'
              required
            />
          </div>

          <DialogActions>
            <GenericLoadingButton
              style={{
                backgroundColor: '#0B9DE4',
                borderRadius: '20px',
                // width: '200px'
              }}
              type='submit'
              variant='contained'
              loading={isLoading}
              loadingPosition='end'
              size='large'
            >
              Continue
            </GenericLoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </>
  );
};

export default AddNewPageDialog;
