import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import productinventory from "../../../assets/packet.png";
import listicon from "../../../assets/list-text.png";
import ChangingItemVisiblityimg from "../../../assets/Changing-Item-Visiblity.png";
import ChangingItemVisiblitydesistoreimg from "../../../assets/Changing-Item-Visiblity-desistore.png";
import routePaths from '../../../config/route-paths';
const ChangingItemVisiblity = (props) => {
   const cn = classname('add-new-product')
   return (
      <div
         className={cns(
            cn(),
            props.className,
         )}>
         <div className={cn('help-page-title')}>
            <div className={cn('title-text')}>
               <Typography variant="h1">
                  Help & Support
               </Typography>
            </div>
            <div className={cn('help-page-title-search')}>
               Search
            </div>
         </div>
         <div className={cn('help-page-body')}>
            <div className={cn('helpdesk-grid')}>
               <div className={cn('left-list')}>
                  <Typography variant="h2">
                     <div className={cn('iconProduct')}>
                        <img src={productinventory} />
                     </div>
                     Product & Inventory
                  </Typography>
                  <ul className={cn('helpdeskinnerlist')}>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.addNewProduct}>How to Add New Products</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.createNewTags} >Create New Tags</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.assigntagstoproduct}>Assign Tag to Product</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.uploadInventory}>Upload Inventory</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.searchProductbyCategory}>Search Product by Category</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.editProductAttributes}>Edit Product Attributes</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.changingItemVisiblity} className={cn('selected')}>Changing Item Visiblity</Link></li>
                  </ul>
               </div>
               <div className={cn('right-body-content')}>
                  <Typography variant="h3">
                     Changing Item Visiblity
                  </Typography>
                  <p><strong>Item visibility can be an important tool for temporarily removing a product from your store website. Maybe you entered an item incorrectly, or you would like to hide a product from customers until a certain holiday or event.</strong></p>

                  <p>

                     <ol>
                        <li>Under the ‘<strong>My Inventory</strong>’ page, find the product you would like to hide.</li>

                        <span className={cn('addnewproductsimg')}>
                           <img src={Const.isOurDesiStore ? ChangingItemVisiblitydesistoreimg : ChangingItemVisiblityimg} />
                        </span>

                        <li>On the right side of the page, there will be icons for tags, copying the product link, limits, and visibility. The visibility icon is
                           represented by an eye.</li>

                        <li>Click on the eye icon once. An alert will show on the top of the web page indicating that this product will no longer be visible on the website and app. Notice that the eye icon is now crossed out.</li>
                        <li>To make a product visible to customers again, click the crossed-out eye icon again. Notice that the icon has returned back to the original eye icon.</li>
                     </ol>
                  </p>
               </div>
            </div>
         </div>
         <div className={cn('answersection')}>
            <Typography variant="h4">
               Still can’t find an answer?
            </Typography>
            <p>Send us a ticket and we will get back to you.</p>
            <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
         </div>
      </div>
   )
}
export default ChangingItemVisiblity