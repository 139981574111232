const initialState = {
    pagination: {},
    banners: []
};

function banners(state=initialState,action){
    switch(action.type){
        case "SET_BANNERS":
            return {
                ...state,
                ...action.payload
            }
        default: return state;
    };
};

export default banners;