import React, { useEffect, useState } from 'react'
import axios from '../../../utils/axiosConfig'
import { FieldArray, Formik } from 'formik'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { Button, FormHelperText, Menu, MenuItem, Radio } from '@mui/material'
import ValueTextField from '../../Library/ValueTextField/ValueTextField'
import { ChevronRight, ExpandMore, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import { toast } from 'react-toastify'
import styles from './VenueTax.module.scss'
import * as Yup from 'yup';

const VenueTax = ({ formik }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [tax, setTax] = useState()
    const open2 = Boolean(anchorEl2);

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };


    const handleSubmit = (values) => {
        console.log(values)
        // setIsLoading(true)

        // axios.post('/venue-tax/create', {
        //     tax_datas: values?.tax
        // })
        //     .then(() => {
        //         toast.success('Tax updated')
        //     })
        //     .catch((err) => {
        //         toast.error(err?.response?.data?.message)
        //     })
        //     .finally(() => {
        //         setIsLoading(false)
        //     })
    }

    // const getTreeItem = (child, formik, index) => {
    //     return child?.map((data) => (
    //         <TreeItem icon={(
    //             <Radio
    //                 checked={data.slug == formik.values.tax[index]?.dept}
    //                 onChange={(event) => {
    //                     formik.setFieldValue(`tax[${index}].dept`, data.slug)
    //                     formik.setFieldValue(`tax[${index}].name`, data.category_name)
    //                 }}
    //                 value={data.slug}
    //             />
    //         )}
    //             nodeId={data.slug}
    //             label={<div
    //                 style={{
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                     gap: 8
    //                 }}
    //             >
    //                 <div>
    //                     {data.category_name}
    //                 </div>

    //                 {Boolean(data?.child?.length) && (
    //                     <KeyboardArrowRight />
    //                 )}
    //             </div>}
    //         >
    //             {Boolean(data?.child?.length) && getTreeItem(data?.child, formik, index)}
    //         </TreeItem>
    //     ))
    // }

    // const fetchVenueCatMenu = () => {
    //     axios.get('/venue-category-order')
    //         .then((res) => {
    //             setVenueCategoryMenu(res.data?.data?.results)
    //         })
    // }

    // const fetchTax = () => {
    //     axios.get('/venue-tax')
    //         .then((res) => {
    //             // setTax(res.data?.data?.results)
    //         })
    // }

    // useEffect(() => {
    //     fetchVenueCatMenu()
    //     fetchTax()
    // }, [])

    // useEffect(() => {
    //     if (tax && venueCategoryMenu) {
    //         setTax((prevState) => {

    //             return prevState?.map((data) => ({ ...data, name: venueCategoryMenu?.find((data1) => data.dept == data1.slug)?.category_name }))
    //         })
    //     }

    // }, [tax, venueCategoryMenu])


    return (
        <div>
            <ValueTextField
                label="Default Tax"
                id={'venue_tax'}
                name={'venue_tax'}
                type={'percentage'}
                texttype="number"
                value={formik.values.venue_tax}
                onChange={formik.handleChange}
                error={formik.touched.venue_tax && Boolean(formik.errors.venue_tax)}
                helperText={formik.touched.venue_tax && formik.errors.venue_tax}
            />

            {/* <FieldArray
                            name="tax"
                            render={arrayHelpers => (
                                <div>
                                    {formik.values.tax?.map((member, index) => (
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    gap: 15
                                                }}
                                            >
                                                <div>
                                                    <div
                                                        style={{
                                                            overflow: 'auto',
                                                            padding: 5,
                                                            outline: '1px solid #d3d3d3',
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        <Button
                                                            aria-controls={open2 ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open2 ? 'true' : undefined}
                                                            onClick={handleClick2}
                                                            fullWidth={true}
                                                            endIcon={<KeyboardArrowDown />}
                                                        >
                                                            <div
                                                                className={styles.category_name}
                                                            >
                                                                {member.name || "Select Category"}
                                                            </div>
                                                        </Button>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl2}
                                                            open={open2}
                                                            onClose={handleClose2}
                                                            slotProps={{
                                                                paper: {
                                                                    elevation: 0,
                                                                    sx: {
                                                                        filter: 'none',
                                                                        border: '1px solid #D3D3D3',
                                                                        height: 250
                                                                    },
                                                                }
                                                            }}
                                                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                                        >
                                                            <TreeView
                                                                defaultCollapseIcon={<ExpandMore />}
                                                                defaultExpandIcon={<ChevronRight />}
                                                            // expanded={props.isEdit}
                                                            >
                                                                {venueCategoryMenu?.filter((data)=> !Boolean(formik.values.tax?.filter((data1) => (data1.dept == data.slug))?.length))?.map((data) => (
                                                                    <TreeItem
                                                                        icon={(
                                                                            <Radio
                                                                                checked={data.slug == member?.dept}
                                                                                onChange={(event) => {
                                                                                    formik.setFieldValue(`tax[${index}].dept`, data.slug)
                                                                                    formik.setFieldValue(`tax[${index}].name`, data.category_name)
                                                                                    handleClose2()
                                                                                }}
                                                                                value={data.slug}
                                                                            />
                                                                        )}
                                                                        nodeId={data.slug}
                                                                        label={(
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    gap: 8
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    {data.category_name}
                                                                                </div>

                                                                                {Boolean(data?.child?.length) && (
                                                                                    <KeyboardArrowRight />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    >
                                                                        {getTreeItem(data?.child, formik, index)}
                                                                    </TreeItem>
                                                                ))}
                                                            </TreeView>
                                                        </Menu>

                                                    </div>

                                                    {Boolean(formik?.touched?.tax) && Boolean(formik?.errors?.tax) && Array.isArray(formik.errors.tax) && formik.touched.tax[index]?.dept && Boolean(formik.errors.tax[index]?.dept) && (
                                                        <FormHelperText style={{ display: 'block', textAlign: 'center' }} error>
                                                            {formik.touched.tax[index]?.dept && formik.errors.tax[index]?.dept}
                                                        </FormHelperText>
                                                    )}
                                                </div>

                                                <GenericSelectWithLabel
                                                    containerStyle={{
                                                        width: 130
                                                    }}
                                                    id={`tax[${index}].tax_type`}
                                                    name={`tax[${index}].tax_type`}
                                                    value={member.tax_type}
                                                    onChange={formik.handleChange}
                                                >
                                                    <MenuItem value="percentage">Percentage</MenuItem>
                                                    <MenuItem value="dollar">Dollar</MenuItem>
                                                </GenericSelectWithLabel>

                                                <ValueTextField
                                                    id={`tax[${index}].tax_value`}
                                                    name={`tax[${index}].tax_value`}
                                                    type={member.tax_type}
                                                    value={member.tax_value}
                                                    onChange={formik.handleChange}
                                                    {...(Boolean(formik?.touched?.tax) && Boolean(formik?.errors?.tax) && Array.isArray(formik.errors.tax) && formik.touched.tax[index]?.tax_value && Boolean(formik.errors.tax[index]?.tax_value) && {
                                                        error: formik.errors.tax?.length && formik.errors.tax[index]?.tax_value && Boolean(formik.errors.tax[index]?.tax_value),
                                                        helperText: formik.touched.tax?.length && formik.touched.tax[index]?.tax_value && formik.errors.tax[index]?.tax_value
                                                    })}
                                                />

                                            </div>

                                            {(formik.values.tax?.length > 1) && (
                                                <Button
                                                    variant="text"
                                                    onClick={() => {
                                                        arrayHelpers.remove(index)
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            )}
                                        </div>
                                    ))}

                                    <div>
                                        <GenericButton
                                            variant="text"
                                            onClick={() => arrayHelpers.push({
                                                dept: '',
                                                tax_type: 'dollar',
                                                tax_value: ''
                                            })}
                                        >
                                            + Add More
                                        </GenericButton>
                                    </div>
                                </div>
                            )}
                        /> */}
        </div>
    )
}

export default VenueTax