import React, { useEffect, useState } from 'react'
import styles from './BankingInfo.module.scss'
import StripeIcon from '../../assets/images/stripe.png'
import HeartlandIcon from '../../assets/images/heartland.png'
import { Divider } from '@mui/material'
import StripeForm from './StripeForm/StripeForm'
import HeartlandForm from './HeartlandForm/HeartlandForm'
import axios from '../../utils/axiosConfig'
import Loader from '../Library/Loader'
import { useOutletContext } from 'react-router-dom'

const BankingInfo = () => {

    const { isActionsDisabled, fetchStoreInfo } = useOutletContext()

    const [showStripeForm, setShowStripeForm] = useState(false)
    const [showHeartlandForm, setShowHeartlandForm] = useState(false)
    const [hlData, setHlData] = useState({})
    const [stripeData, setStripeData] = useState({})
    const [isHlSaveDisabled, setIsHlSaveDisabled] = useState(false)
    const [isStripeSaveDisabled, setIsStripeSaveDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const fetchVenuePayment = () => {
        setIsLoading(true)
        axios.get('/venue-payment/get')
            .then((response) => {
                setHlData(response.data.data?.heartland)
                setStripeData(response.data.data?.stripe)
                if (response.data.data?.payment_type == 2) {
                    setShowHeartlandForm(true)
                    setHlData(response.data.data?.heartland)
                    setIsHlSaveDisabled(true)
                } else {
                    setShowHeartlandForm(false)
                    setHlData(response.data.data?.heartland)
                    setIsHlSaveDisabled(false)
                }

                if (response.data.data?.payment_type == 1) {
                    setShowStripeForm(true)
                    setIsStripeSaveDisabled(true)
                } else {
                    setShowStripeForm(false)
                    setIsStripeSaveDisabled(false)
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        setTimeout(() => {
            fetchVenuePayment()
            fetchStoreInfo()
        }, 1000);
    }, [])


    return (
        <div className={styles.container}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {!(showStripeForm || showHeartlandForm) && (
                        <>
                            <div className={styles.processor_text}>

                                <div className={styles.processor}>Choose a Credit Card Processor</div>

                                <div className={styles.para}>To process Credit Card transactions, you need to have an account with one of the CC processors
                                    we integrate with, Heartland and Stripe to process CC transactions.
                                </div>

                                <div className={styles.para}>Click on one of the processors below, to open an account.</div>
                            </div>

                            <div className={styles.stripe_container}>
                                <div className={styles.stripe_img}>
                                    <img className={styles.logo} src={StripeIcon} />
                                    <div
                                        onClick={() => {
                                            if (!isActionsDisabled) {
                                                setShowStripeForm(true)
                                            }
                                        }}
                                    >
                                        Click here to connect Stripe
                                    </div>
                                </div>

                                <div className={styles.stripe_box}>
                                    <div className={styles.advantages_text}>Advantages of Stripe</div>
                                    <ol className={styles.advantages}>
                                        <li> Flat-rate, transparent pricing with no setup, cancellation or monthly fees.</li>
                                        <li> Supports a wide variety of payment methods and currencies.</li>
                                        <li> Highly customizable checkout flow.</li>
                                        <li> 24/7 customer support via phone, email and LIVE chat.</li>
                                        <li> Easy and quick to set up.</li>
                                    </ol>
                                </div>
                            </div>


                            <div
                                className={styles.or_container}
                            >
                                <Divider />
                                <div className={styles.or_circle}>or</div>
                            </div>

                            <div className={styles.stripe_container}>
                                <div className={styles.stripe_img}>
                                    <img className={styles.logo} src={HeartlandIcon} />
                                    <div
                                        onClick={() => {
                                            if (!isActionsDisabled) {
                                                setShowHeartlandForm(true)
                                            }
                                        }}
                                    >
                                        Click here to connect Heartland
                                    </div>
                                </div>

                                <div className={styles.stripe_box}>
                                    <div className={styles.advantages_text}>Advantages of Heartland</div>
                                    <ol className={styles.advantages}>
                                        <li> Flat-rate, transparent pricing with no setup, cancellation or monthly fees.</li>
                                        <li> Supports a wide variety of payment methods and currencies.</li>
                                        <li> Highly customizable checkout flow.</li>
                                        <li> 24/7 customer support via phone. email and LIVE chat.</li>
                                        <li> Easy and quick to set up.</li>
                                    </ol>
                                </div>

                            </div>
                        </>
                    )}

                    {showStripeForm && (
                        <div
                            className={styles.stripe_form_container}
                        >
                            <StripeForm
                                {...stripeData}
                                fetchVenuePayment={fetchVenuePayment}
                                isStripeSaveDisabled={isStripeSaveDisabled}
                                onClose={() => {
                                    setShowStripeForm(false)
                                }}
                            />
                        </div>
                    )}

                    {showHeartlandForm && (
                        <div
                            className={styles.heartland_form_container}
                        >
                            <HeartlandForm
                                {...hlData}
                                fetchVenuePayment={fetchVenuePayment}
                                isHlSaveDisabled={isHlSaveDisabled}
                                onClose={() => {
                                    setShowHeartlandForm(false)
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default BankingInfo