import { DeleteForever, Edit } from '@mui/icons-material'
import { Checkbox, IconButton } from '@mui/material'
import React, { useRef, useState } from 'react'
import DefaultLogo from '../../Library/DefaultLogo/DefaultLogo'
import UploadImage from '../../Library/UploadImage/UploadImage'
import styles from './Branding.module.scss'
import LiquorFavicon from '../../../assets/images/favicon.ico'
import DesiFavicon from '../../../assets/images/desifavicon.png'
import Const from '../../helpers/const'
import LightTooltip from '../../Library/LightTooltip/LightTooltip'

const Branding = (props) => {

  const imageRef = useRef()
  const faviconRef = useRef()

  return (
    <div
      className={styles.container}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div
            className={styles.title}
          >
            Logo
          </div>
          {/* {(props.imageFile || props.venueDetails.image) && ( */}
          <div
            className={styles.image_edit_delete_container}
          >
            <IconButton
              onClick={() => {
                imageRef.current?.click()
              }}
            >
              <Edit style={{ fontSize: 18, color: 'black' }} />
            </IconButton>

            <IconButton
              style={{
                display: props.noLogo ? 'none' : 'flex'
              }}
              onClick={() => {
                props.removeImage()
                props.setImageFile()
                props.setNoLogo(true)
              }}
            >
              <DeleteForever style={{ fontSize: 18, color: 'black' }} />
            </IconButton>
          </div>
        </div>
        {/* )} */}

        <div
          style={{
            display: props.noLogo ? 'flex' : 'none'
          }}
          className={styles.deafult_logo_container}
        >
          <DefaultLogo
            name={props.venueDetails?.Name || "Logo"}
          />
        </div>

        <div
          style={{
            display: props.noLogo ? 'none' : 'flex'
          }}
          className={styles.upload_image_container}
        >
          <UploadImage
            uploadText="Upload Logo"
            ref={imageRef}
            image={props.imageFile}
            imageLink={props.venueDetails.image}
            onChange={(event) => {
              props.setImageFile(event.target.files[0])
              props.setNoLogo(false)
              event.target.value = ''
            }}
            onImageClose={(event) => {
              event.preventDefault()
              props.setImageFile()
            }}
          />
        </div>
      </div>

      <div
        className={styles.name_container}
      >
        {props.venueDetails.Name}
      </div>

      <div
        className={styles.type_container}
      >
        <div>
          {props.venueDetails.venuetype}
        </div>

        {/* <div
          className={styles.favicon_container}
        >

          <IconButton
            style={{
              position: 'absolute',
              top: -5,
              right: -16
            }}
            onClick={() => {
              faviconRef.current?.click()
            }}
          >
            <Edit style={{ fontSize: 18, color: 'black' }} />
          </IconButton>

          {props.faviconFile && (
            <IconButton
              style={{
                position: 'absolute',
                top: -25,
                right: -25
              }}
              onClick={() => {
                props.setFaviconFile()
              }}
            >
              <DeleteForever style={{ fontSize: 18, color: 'black' }} />
            </IconButton>
          )}
          <input
            style={{
              display: 'none'
            }}
            type="file"
            accept="image/*"
            onChange={(event) => {
              props.setFaviconFile(event.target.files[0])
              event.target.value = ''
            }}
            ref={faviconRef}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textDecoration: 'underline'
            }}
          >
            <img
              className={styles.favicon}
              src={props.faviconFile ? URL.createObjectURL(props.faviconFile) : (props.venueDetails?.favicon ? props.venueDetails?.favicon : Const.isOurDesiStore ? DesiFavicon : LiquorFavicon)}
            />

            <LightTooltip
              title={
                <span>
                  A favicon is a small icon associated with a website. It’s displayed within the browser tabs and bookmarks bar.
                </span>
              }
              arrow={true}
              placement="top"
            >
              <small>
                Favicon
              </small>
            </LightTooltip>
          </div>

        </div> */}
      </div>

    </div >
  )
}

export default Branding