import { bizBaseurl } from "../../env";
import axios from "../../utils/axiosConfig";
import getVenueHours from "../../components/helpers/venueHours";

export const getVenueInfo = (params) => {
  try {
    return async (dispatch) => {
      let {
        data: { data },
      } = await axios.get(`/venue/setting`);

      dispatch({
        type: "SET_STORE_INFO",
        payload: {
          ...data,
          venue: {
            ...data.venue,
            venue_delivery_zone: data.venue_delivery_zone,
          },
          venue_hours: data.venue_hours.length > 0 ? data.venue_hours : [...getVenueHours()]
        },
      });
    };
  } catch (err) {
    throw err;
  }
};

export const getBankingInfo = (params) => {
  try {
    return async (dispatch) => {
      let { data } = await axios.get(`/venue/steps/banking_info`);
      dispatch({
        type: "SET_BANKING_INFO",
        payload: {
          bank_account_no: data[0].original.data?.result.bank_account_no,
          bank_routing_no: data[0].original.data?.result.bank_routing_no,
          dba_name: data[0].original.data?.result.dba_name,
          first_name: data[0].original.data?.result.first_name,
          last_name: data[0].original.data?.result.last_name,
          birthdate: data[0].original.data?.user?.birthdate,
          legal_name: data[0].original.data?.result.legal_name,
          tax_id: data[0].original.data?.result.tax_id,
          terms_accepted: true,
        },
      });
    };
  } catch (err) {
    throw err;
  }
};

export const getLicenseInfo = (params) => {
  try {
    return async (dispatch) => {
      let { data } = await axios.get(`/venue/steps/licence-info`);
      dispatch({
        type: "SET_LICENSE_INFO",
        payload: {
          license_no:
            data[0].original.data?.result?.licence_no === "undefined"
              ? ""
              : data[0].original.data?.result?.licence_no,
          file: data[0].original.data?.result?.licence_file,
          license_file_name: data[0].original.data?.result?.licence_file_name,
        },
      });
    };
  } catch (err) {
    throw err;
  }
};

export const getBranding = (params) => {
  try {
    return async (dispatch) => {
      let { data } = await axios.get(`/venue/steps/branding`);

      dispatch({
        type: "SET_BRANDING",
        payload: {
          no_logo: !!data[0].original.data?.result?.no_image,
          image: data[0].original.data?.result?.logo,
          logo_name: data[0].original.data?.result?.venue_default_logo,
        },
      });
    };
  } catch (err) {
    throw err;
  }
};
