import React, { useState } from 'react'
import styles from './AddHoliday.module.scss'
import { Button, DialogActions, DialogContent, DialogTitle, Drawer, IconButton, inputBaseClasses, outlinedInputClasses } from '@mui/material'
import { Close, DeleteForever, Edit } from '@mui/icons-material'
import { useFormik } from 'formik'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from '../../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import GenericEditDrawer from '../../../../Library/V2/GenericEditDrawer/GenericEditDrawer'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'

const AddHolidayForm = ({ holidays, ...props }) => {
    const [loading, setLoading] = useState(false)

    const handleSave = (values, resetForm = false) => {
        // if(values.title === ''){
        //     toast.error('Please enter a title');
        //     return;
        // }
        if(values.date === ''){
            toast.error('Please enter a date');
            return;
        }
        setLoading(true);
        axios.post(props.isEdit ? `/venue-holiday/edit/${props.editObj?.id}` : '/venue-holiday/create', {
            ...values,
            date: format(new Date(values.date), 'yyyy-MM-dd')
        })
            .then((res) => {
                toast.success('Holiday Added')
                if(resetForm){
                    props.fetchHoliday(); 
                } else {
                    props.onClose();
                }
                formik.resetForm();
            }).catch((err) => {
                console.log('🚀OUTPUT --> err:', err);
                // toast.error('Error adding holiday')
            }).finally(() => {
                setLoading(false);
            })
    }
    
    const formik = useFormik({
        initialValues: props.isEdit ? props.editObj : {
            title: '',
            date: ''
        },
        onSubmit: (values) => {
            handleSave(values)
        }
    });
    

    return (
        <>
            <GenericEditDrawer
                {...{
                title: props.isEdit ? 'Edit Hours' : 'Add Hours', 
                description: "Set dates you're closed or have special hours.",
                onClose : () => {props.onClose();  formik.resetForm();}, 
                actions : (
                    <div className='tw-flex tw-flex-col tw-w-full'>
                        {!props.isEdit && 
                            <GenericLoadingButton
                                variant="outlined"
                                type="button"
                                fullWidth
                                onClick={() => {
                                    handleSave(formik.values, true);
                                }}
                                size="large"
                                loading={loading}
                                loadingPosition="end"
                                sx={{ 
                                    backgroundColor: 'white !important',
                                    color: 'black !important',
                                    borderColor: 'black !important',
                                    marginBottom: '5px'

                                }}
                                // disabled={props.isActionsDisabled}
                            >
                                + Save and Add Another Holiday
                            </GenericLoadingButton>
                        }
                        <GenericLoadingButton
                            variant="contained"
                            type="button"
                            fullWidth
                            onClick={formik.handleSubmit}
                            size="large"
                            loading={loading}
                            loadingPosition="end"
                            // disabled={props.isActionsDisabled}
                        >
                        Save Holiday
                        </GenericLoadingButton>
                    </div>
                ) 
            }}
            >
                <GenericTextFieldWithLabel
                    id="title"
                    name="title"
                    label="Name of holiday"
                    placeholder="Good Friday, Christmas Eve etc"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    size='small'
                    // required={true}
                    isSmallLabel={true}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className='tw-w-full'>
                        <div className='tw-font-semibold tw-text-sm'>
                            Date of Holiday
                            <span className={styles.required_asterisk}>*</span>
                        </div>
                        <DatePicker
                            value={new Date(formik.values.date)}
                            onChange={(date) => {
                                formik.setFieldValue('date', date)
                            }}
                            renderInput={(params) => <GenericTextFieldWithLabel
                                {...params}
                                id="date"
                                name="date"
                                label=""
                                placeholder="Select Date"
                                fullWidth={true}
                                size='small'
                                required={true}
                                isSmallLabel={true}
                                error={formik.touched.date && Boolean(formik.errors.date)}
                                helperText={formik.touched.date && formik.errors.date}
                            />}
                            sx={{
                                width: '100%',
                                [`& .${outlinedInputClasses.notchedOutline}`]: {
                                    borderColor: '#d3d3d3 !important',
                                    outline: 'none',
                                },
                                [`& .${outlinedInputClasses.input}`]: {
                                    padding: '8px',
                                }
                            }}
                            minDate={new Date()}
                        />
                    </div>
                </LocalizationProvider>
            </GenericEditDrawer>
        </>
    )
}

export default AddHolidayForm;

