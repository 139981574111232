import React, { useRef } from 'react';
import { Typography, TextField } from '@mui/material';
import { PrimaryButton, SecondaryButton } from '../../../../util_comps/CustomButton';
import { Close } from '@mui/icons-material';
import { useClickOutside } from '../../../../hooks/useClickOutside'
import DollarInput from './DollarInput';
import { makeStyles } from '@mui/styles';
import GenericSwitch from '../../../Library/GenericSwitch/GenericSwitch';

const useStyles = makeStyles(() => ({
    textInput: {
        borderRadius: "0",
        width: "100%",
        padding: '0 !important',
        "& > input": {
            padding: "8px 9px !important",
        },
    }
}))

const Modal = (props) => {
    const modalRef = useRef(null);
    const classes = useStyles();
    const handleClose = () => {
        if (props.active) {
            props.setActiveModal({ name: "none", props: {} });
        }
    };
    useClickOutside(modalRef, handleClose);
    return (
        <div ref={modalRef} className='inventory-modal' style={{ position: 'fixed', display: props.active === true ? 'initial' : 'none', backgroundColor: 'white', zIndex: '999999999', top: '36%', left: '60%', width: '34vw', transform: 'translate(-60%,-33%)' }}>
            <div style={{ padding: '12px 28px 15px 28px' }}>
                <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
                    <Typography variant="h6" style={{ fontSize: '1.1rem', textAlign: 'center' }}>Discount This Item</Typography>
                    <div onClick={handleClose} style={{ fontSize: '23px', cursor: 'pointer', position: 'absolute', left: '95%', transform: 'translateY(-2px)', color: 'black', display: 'flex', alignItems: 'center' }}>
                        <Close fontSize="inherit" color="inherit" />
                    </div>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <Typography variant="h6" style={{ fontSize: '.9rem' }}>Coupon Name</Typography>
                    <TextField style={{ width: '100%', marginBottom: '4px' }} InputProps={{ classes: { root: classes.textInput } }} variant="outlined" />
                    <Typography variant="caption" style={{ fontSize: '.7rem', width: '90%', display: 'block' }}>Enter name you want this coupon to be associated with</Typography>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <Typography variant="h6" style={{ fontSize: '.9rem', marginBottom: '5px' }}>Coupon Description</Typography>
                    <textarea rows={4} style={{ resize: 'none', width: '100%', fontSize: '.9rem', border: '1px solid #DADADA', fontFamily: "Poppins, sans-serif", padding: '7px' }}></textarea>
                    <Typography variant="caption" style={{ fontSize: '.7rem', width: '70%' }}>Briefly describe this coupon</Typography>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <Typography variant="h6" style={{ fontSize: '.9rem', marginBottom: '5px' }}>Discount Amount</Typography>
                    <DollarInput padding="10px 9px" fontSize=".75rem" imageWidth="44px" placeholder="New Price" />
                    <Typography variant="caption" style={{ fontSize: '.7rem', width: '70%' }}>Point out amount of discount this coupon should hold</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ width: '60%' }}>
                        <Typography variant="h6" style={{ fontSize: '.9rem' }}>Fixed Discount</Typography>
                        <Typography variant="caption" style={{ fontSize: '.7rem', width: '90%' }}>Point out whether discount is fixed or not</Typography>
                    </div>
                    <div>
                        <GenericSwitch />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ width: '60%' }}>
                        <Typography variant="h6" style={{ fontSize: '.9rem' }}>Fixed Price</Typography>
                        <Typography variant="caption" style={{ fontSize: '.7rem', width: '90%' }}>Point out whether price is fixed or not</Typography>
                    </div>
                    <div>
                        <GenericSwitch />
                    </div>
                </div>
                <div>
                    <Typography variant="h6" style={{ fontSize: '.9rem' }}>Order Limit</Typography>
                    <TextField style={{ width: '100%', marginBottom: '4px' }} InputProps={{ classes: { root: classes.textInput } }} variant="outlined" />
                    <Typography variant="caption" style={{ fontSize: '.7rem', width: '90%', display: 'block' }}>Point out limit of inventory that can be purchased with this coupon</Typography>
                </div>
                <div style={{ marginTop: '27px', display: 'flex', justifyContent: 'flex-end' }}>
                    <SecondaryButton onClick={handleClose} style={{ padding: '8px 19px', fontSize: '.9rem', marginRight: '10px' }}>Cancel</SecondaryButton>
                    <PrimaryButton style={{ padding: '7px 17px', fontSize: '.9rem' }}>Submit</PrimaryButton>
                </div>
            </div>
        </div>
    )
};

export default Modal;