const initialState = {
    isLoading: false,
    isLoggedIn: false,
    categoryByID: {},
    categoryByIDError: ""
};
function categoryList(state = initialState, action) {
    switch (action.type) {
        case "SET_CATEGORY_ByID_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_CATEGORY_ByID_LOADED":
            return { ...initialState, isLoading: false , categoryByID: action.payload };
        case "SET_CATEGORY_ByID_ERROR":
            return { ...initialState,categoryByIDError:action.payload};
        default:
            return state;
    }
};
export default categoryList;  