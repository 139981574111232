import React, { useCallback, useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  getTagList,
  deleteTag as deleteTagAction,
  updateTag,
  getProductsFromTag,
  deleteProductFromTag,
} from "../../../../redux/actions/tagListActions";
import { useDispatch, useSelector } from "react-redux";
import AddProductToTagTagItem from "./AddProductToTagTagItem";
import { Dialog, DialogContent, DialogTitle, IconButton, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import InputField from "../../../Library/InputField/InputField";
import { debounce } from 'lodash'
import { Search } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  textInput: {
    borderRadius: "0",
    width: "100%",
    padding: "0 7px !important",
    border: "none",
    "& > input": {
      padding: `10px 6px !important`,
    },
  },
  list: {
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#161616",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F3F3F3",
    },
  },
  productsListsItem: {
    "&:nth-child(even)": {
      background: "#FCFCFC",
    },
  },
}));

const Modal = (props) => {
  const modalRef = useRef(null);
  const classes = useStyles();
  const [activeTag, setActiveTag] = useState({});
  const dispatch = useDispatch((data) => data);
  const tags = useSelector((state) => state.tagList.tags);
  const tagProducts = useSelector((state) => state.tagList.tagProducts);
  const [searchStr, setSearchStr] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [activeEditable, setActiveEditable] = useState("");

  const handleSearch = (e) => {
    dispatch(getTagList({ query: e.target.value }));
  }

  const searchProductQuery = (e, id) => {
    dispatch(getProductsFromTag({ tagId: id, searchStr: e.target.value }));
  };

  const debounceSearch = useCallback(debounce(handleSearch, 1000), [])
  const debounceSearchProduct = useCallback(debounce(searchProductQuery, 1000), [])

  useEffect(() => {
    dispatch(getTagList());
  }, [props.active, activeTag]);

  const deleteTag = (id) => {
    setActiveTag({});
    setActiveEditable("");
    dispatch(deleteTagAction({ id }))
      .then(() => {
        toast.success('Tag removed successfully')
        dispatch(getTagList());
      })
      .catch((err) => {
        toast.error(err)
      })
  };
  const editTag = (id, name, val) => {
    try {
      if (val === name) {
        return;
      }
      dispatch(updateTag({ id, title: val }));
    } catch (error) {
      toast.error('Something went wrong')
    }
  };
  const getTagProducts = (id) => {
    dispatch(getProductsFromTag({ tagId: id }));
  };

  const deleteProduct = (tagId, productId) => {
    try {
      dispatch(deleteProductFromTag({ productId, tagId }));
    } catch (error) {
      toast.error('Something went wrong')
    }
  };

  return (
    <Dialog
      open={props.active}
      onClose={props.closeIsTagsModal}
      maxWidth="md"
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
      >
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            fontWeight: 600
          }}
        >
          Product Tags
        </Typography>

        <IconButton
          aria-label="close"
          onClick={props.closeIsTagsModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '6px'
          }}
        >
          <Button
            variant="contained"
            onClick={(e) =>
              props.setSecondaryModal({ name: "createtag", props: {} })
            }
            style={{
              backgroundColor: '#001425',
              color: 'white',
              width: '100px',
              whiteSpace: 'nowrap'
            }}
          >
            Add New Tag
          </Button>
        </div>
        <div
          style={{
            height: "65vh",
            display: "grid",
            gridTemplateColumns: "35% 65%",
          }}
        >
          <div
            style={{
              background: "#F9F9F9",
              height: "100%",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                margin: '5px 0 0 0'
              }}
            >
              <Typography
                variant="h6"
                style={{
                  marginBottom: '8px',
                  fontSize: "1.3rem",
                  fontWeight: 600,
                }}
              >
                Select Tag
              </Typography>

              <div style={{ width: '90%' }}>
                <InputField
                  placeholder="Search Tags"
                  onChange={(e) => {
                    setSearchStr(e.target.value);
                    debounceSearch(e)
                  }}
                  value={searchStr}
                  onIconButtonClick={debounceSearch}
                  showIconButton={true}
                  endIcon={(
                    <Search
                      style={{
                        color: '#ef2d2c'
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div
              style={{
                height: "100%",
                overflowY: "auto",
                maxHeight: 400
              }}
            >
              {tags.map((val, i) => {
                return (
                  <AddProductToTagTagItem
                    getTagProducts={getTagProducts}
                    setActiveTag={setActiveTag}
                    val={val}
                    activeTag={activeTag}
                    editTag={editTag}
                    activeEditable={activeEditable}
                    setActiveEditable={setActiveEditable}
                    deleteTag={deleteTag}
                  />
                );
              })}
            </div>
          </div>

          <div
            style={{
              padding: "13px 16px",
              height: "100%",
              overflowY: "auto",
              visibility: activeTag.id ? "initial" : "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: 500,
                }}
              >
                Products in {activeTag.title}
              </Typography>

              <div
                style={{
                  width: '90%',
                  marginLeft: '10%',
                  lineHeight: '20px'
                }}
              >
                <InputField
                  placeholder="Search Products"
                  onChange={(e) => {
                    setSearchProduct(e.target.value)
                    debounceSearchProduct(e, activeTag.id)
                  }}
                  value={searchProduct}
                  onIconButtonClick={(e) => debounceSearchProduct(e, activeTag.id)}
                  showIconButton={true}
                  endIcon={(
                    <Search
                      style={{
                        color: '#ef2d2c'
                      }}
                    />
                  )}
                />
              </div>

            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "3%"
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontSize: "1.15rem",
                  fontWeight: 500,
                }}
              >
                Products
              </Typography>

              <Button
                onClick={() => props.setSecondaryModal({ name: "addproductsubmodal", props: { tagId: activeTag.id } })}
                variant="contained"
                style={{
                  backgroundColor: '#001425',
                  color: 'white',
                  width: '100px',
                  whiteSpace: 'nowrap'
                }}
              >
                Add Product
              </Button>
            </div>

            <div
              style={{
                border: "1px solid #F4F4F4",
                marginTop: "4%",
                height: "100%",
              }}
            >
              {tagProducts.map((val) => {
                return (
                  <div
                    className={classes.productsListsItem}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "7px 10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1rem",
                        textAlign: "center",
                        fontWeight: 400,
                      }}
                    >
                      {val.product_detail.Name}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <DeleteOutlineOutlinedIcon
                        onClick={(e) =>
                          deleteProduct(val.tag_id, val.product_id)
                        }
                        style={{ color: "#868990" }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
