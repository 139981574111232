import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    TableCell,
    TableRow,
    Typography,
    TextField,
    Select,
    Button,
    FormControl,
    TableBody,
    CircularProgress,
    Autocomplete,
    MenuItem
} from "@mui/material";
import { Close, SystemUpdateAltOutlined } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { getSuggestions } from "../../../../redux/actions/inventoryActions";
import { getVenueList } from "../../../../redux/actions/venueActions";
import { addInventory } from '../../../../servise/inventoryAPI';
import debounce from 'lodash.debounce';
import { classname, cns } from '../../../../utils/bem';
import { useFormik } from "formik";
import * as yup from 'yup';
import './AddForm.scss';
import { toast } from "react-toastify";
import StyledTableCell from "../../../Library/StyledTableCell/StyledTableCell";
import StyledTableRow from "../../../Library/StyledTableRow/StyledTableRow";
import GenericSelectWithLabel from "../../../Library/GenericSelectWithLabel/GenericSelectWithLabel";
import GenericTextFieldWithLabel from "../../../Library/TextFieldWithLabel/GenericTextFieldWithLabel";
import GenericAutoCompleteWithLabel from "../../../Library/GenericAutoCompleteWithLabel/GenericAutoCompleteWithLabel";
import GenericTextField from "../../../Library/GenericTextField/GenericTextField";
import styles from './AddForm.module.scss'
import GenericButton from "../../../Library/GenericButton/GenericButton";

const validationSchema = yup.object({
    Price: yup
        .number()
        .test(('price-len', 'Cannot be more than 6 digits', val => val?.toString().length <= 6))
        .required()
    ,
    dept: yup
        .string()
        .required()
    ,
    format: yup
        .string()
        .required()
    ,
    product_id: yup
        .string()
    ,
    product_name: yup
        .string()
        .required()
    ,
    sku: yup
        .string()
        .min(12)
        .required()
    ,
    stockqty: yup
        .number()
        .test(('quantity-len', 'Cannot be more than 6 digits', val => val?.toString().length <= 6))
        .required()
    ,
    venue_name: yup
        .string()
        .required()
    ,
    venue_id: yup
        .string()
    ,
    image: yup
        .string()
    ,
    image_file: yup
        .mixed()
})

const AddForm = (props) => {

    const cn = classname('add-product-form')

    const dispatch = useDispatch(data => data);

    const [catId, setCatId] = useState(1)
    const [openOption, setOpenOption] = useState(false)
    const [openBrandOption, setOpenBrandOption] = useState(false)
    const [showOtherFields, setShowOtherFields] = useState(false)
    let { formats, suggestions, isSuggestionsLoading } = useSelector((state) => state.inventory);
    const mainCategories = useSelector((state) => state.search.categoryMenu);
    let { venueList } = useSelector((state) => state.venue);

    const formik = useFormik({
        initialValues: {
            Price: null,
            dept: '',
            format: '',
            product_id: '',
            product_name: '',
            sku: '',
            stockqty: null,
            venue_name: '',
            venue_id: '',
            image: '',
            image_file: null
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            try {
                addInventory(values).then(() => {
                    props.fetchInventory()
                    toast.success('Inventory Item Was Successfully Added')
                })
                    .catch((err) => {
                        if (err.response?.data?.message) {
                            toast.error(err.response.data.message)
                        }
                    })
            } catch (err) {
                toast.error('Error Occured While Adding Inventory Item, Make Sure You Fill Out Every Field')
            }
        }
    })

    useEffect(() => {
        formik.setFieldValue('dept', mainCategories[0]?.MainCatName)
    }, [])

    let venues = venueList.data ? venueList.data.result : [];

    const handleClose = () => {
        props.setActiveForm({ name: '', props: {} });
    };

    const handleInventoryChange = (e, val, paramCatId) => {
        try {
            dispatch(getSuggestions({
                q: val,
                catId: paramCatId,
                searchType: 'search=0'
            }));
        } catch (err) {
            toast.error('There was an error while fetching inventories, try again later')
            return;
        }
    };

    const handleBrandChange = (e, val) => {
        try {
            dispatch(getVenueList(val));
        } catch (err) {
            toast.error('There was an error while fetching inventories, try again later')
        }
    }

    const debounceSearch = useCallback(debounce(handleInventoryChange, 1000), [])
    const debounceBrandSearch = useCallback(debounce(handleBrandChange, 1000), [])

    const handleImageUploadClick = (event) => {
        let file = event.target.files[0];
        formik.setFieldValue('image_file', file)
        event.target.value = ''
    }

    return (
        <StyledTableRow>
            <StyledTableCell component="th" scope="row">
                {showOtherFields && (
                    <div
                        style={{ display: "flex" }}
                    >
                        <div
                            className={cn('image-upload-input-container')}
                        >
                            {Boolean(formik.values.image) || formik.values.image_file ? (
                                <div
                                    className={cn('product-image-container')}
                                >
                                    <Close
                                        style={{
                                            color: 'white',
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                            backgroundColor: 'red',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            fontSize: 14
                                        }}
                                        onClick={() => {
                                            formik.setFieldValue('image', '')
                                            formik.setFieldValue('image_file', null)
                                        }}
                                    />
                                    <img
                                        className={cn('product-image')}
                                        src={formik.values.image ? formik.values.image : URL.createObjectURL(formik.values.image_file)}
                                    />
                                </div>
                            ) : (
                                <>
                                    <input
                                        accept="image/*"
                                        className={cn('image-input')}
                                        id={cn('image-input')}
                                        type="file"
                                        onChange={handleImageUploadClick}
                                    />
                                    <label
                                        className={cn('image-input-label')}
                                        htmlFor={cn('image-input')}
                                    >
                                        <SystemUpdateAltOutlined
                                            fontSize="inherit"
                                            style={{
                                                color: "black",
                                                transform: "rotate(180deg)",
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            style={{ fontSize: ".85rem" }}
                                        >
                                            Upload Image
                                        </Typography>
                                    </label>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </StyledTableCell>

            <StyledTableCell>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 6
                    }}
                >
                    <div>
                        <GenericSelectWithLabel
                            style={{
                                backgroundColor: 'white'
                            }}
                            label="Category"
                            value={formik.values.dept}
                            name="dept"
                            onChange={e => {
                                formik.setFieldValue('dept', e.target?.value)
                            }}
                        >
                            {mainCategories.map((cat) => {
                                return (
                                    <MenuItem
                                        onClick={() => {
                                            setCatId(cat.MainCatId)
                                        }}
                                        value={cat.MainCatName}
                                    >
                                        {cat.MainCatName}
                                    </MenuItem>
                                )
                            })}
                        </GenericSelectWithLabel>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 10
                        }}
                    >
                        <GenericAutoCompleteWithLabel
                            containerStyle={{
                                width: '100%'
                            }}
                            label="Product Name"
                            options={[
                                ...suggestions,
                                {
                                    "Name": formik.values.product_name,
                                    "catid": catId,
                                    "category_trees": [
                                        {
                                            "id": catId,
                                            "name": formik.values.dept
                                        },
                                    ]
                                }
                            ]}
                            clearOnBlur={false}
                            onBlur={() => setOpenOption(false)}
                            open={openOption}
                            filterOptions={(options) => options}
                            getOptionLabel={(option) => option.Name}
                            freeSolo={true}
                            onChange={(event, val) => {
                                setOpenOption(false)
                                formik.setFieldValue('product_name', val.Name)
                                formik.setFieldValue('product_id', val.ProductId)
                                formik.setFieldValue('format', val.format)
                                formik.setFieldValue('sku', val.SKU)
                                formik.setFieldValue('venue_name', val.venue_name)
                                formik.setFieldValue('venue_id', val.producervenueid)
                                formik.setFieldValue('dept', val.category_trees && val.category_trees[0]?.name)
                                formik.setFieldValue('image', val.image_file)
                                setShowOtherFields(true)
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === 'input') {
                                    if (!(newInputValue?.length < 3)) {
                                        setOpenOption(true)
                                    } else {
                                        setOpenOption(false)
                                    }
                                    formik.setFieldValue('product_name', newInputValue)
                                    debounceSearch(event, newInputValue, catId)
                                }
                            }}
                            inputValue={formik.values.product_name}
                            renderInput={(params) => (
                                <GenericTextField
                                    {...params}
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    error={formik.touched.product_name && Boolean(formik.errors.product_name)}
                                    helperText={formik.touched.product_name && formik.errors.product_name}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        classes: {
                                            root: styles.autocomplete_input
                                        },
                                        endAdornment: isSuggestionsLoading ? <CircularProgress style={{ marginRight: '10px' }} size={20} /> : null
                                    }}
                                />
                            )}
                        />

                        {showOtherFields && (
                            <GenericAutoCompleteWithLabel
                                label="Brand"
                                containerStyle={{
                                    width: '100%'
                                }}
                                options={venues}
                                clearOnBlur={false}
                                onBlur={() => setOpenBrandOption(false)}
                                open={openBrandOption}
                                filterOptions={(options) => options}
                                getOptionLabel={(option) => option.Name}
                                freeSolo={true}
                                onChange={(event, val) => {
                                    setOpenBrandOption(false)
                                    formik.setFieldValue('venue_name', val.Name)
                                    formik.setFieldValue('venue_id', val.VenueId)
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason === 'input') {
                                        if (!(newInputValue?.length < 3)) {
                                            setOpenBrandOption(true)
                                        } else {
                                            setOpenBrandOption(false)
                                        }
                                        formik.setFieldValue('venue_name', newInputValue)
                                        formik.setFieldValue('venue_id', '')

                                        debounceBrandSearch(event, newInputValue)
                                    }
                                }}
                                inputValue={formik.values.venue_name}
                                getOptionSelected={() => true}
                                renderInput={(params) => (
                                    <GenericTextField
                                        {...params}
                                        variant="outlined"
                                        style={{
                                            backgroundColor: 'white'
                                        }}
                                        error={formik.touched.product_name && Boolean(formik.errors.product_name)}
                                        helperText={formik.touched.product_name && formik.errors.product_name}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                                root: styles.autocomplete_input
                                            },
                                            endAdornment: null
                                        }}
                                    />
                                )}
                            />
                        )}
                    </div>

                    {showOtherFields && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: 10
                            }}
                        >
                            <GenericAutoCompleteWithLabel
                                label="Format"
                                containerStyle={{
                                    width: '100%'
                                }}
                                options={formats}
                                clearOnBlur={false}
                                getOptionLabel={(option) => option.format}
                                freeSolo={true}
                                onChange={(e, newVal) => {
                                    formik.setFieldValue('format', newVal?.format)
                                }}
                                onInputChange={(e, newVal, reason) => {
                                    if (reason === 'input') {
                                        formik.setFieldValue('format', newVal)
                                    }
                                }}
                                renderInput={(params) => (
                                    <GenericTextField
                                        {...params}
                                        error={formik.touched.format && Boolean(formik.errors.format)}
                                        helperText={formik.touched.format && formik.errors.format}
                                        variant="outlined"
                                        style={{
                                            backgroundColor: 'white'
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                                root: styles.autocomplete_input
                                            },
                                            endAdornment: (
                                                <React.Fragment>
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />

                            <GenericTextFieldWithLabel
                                label="SKU"
                                containerStyle={{
                                    width: '100%'
                                }}
                                name="sku"
                                id="sku"
                                variant="outlined"
                                error={formik.touched.sku && Boolean(formik.errors.sku)}
                                helperText={formik.touched.sku && formik.errors.sku}
                                style={{
                                    backgroundColor: 'white'
                                }}
                                fullWidth={true}
                                value={formik.values.sku}
                                onChange={formik.handleChange}
                            />
                        </div>

                    )}
                </div>
            </StyledTableCell>

            <StyledTableCell style={{ verticalAlign: 'baseline', paddingTop: 40 }} align="center">
                {showOtherFields && (
                    <div style={{ display: "flex" }}>
                        <GenericTextField
                            name="Price"
                            id="Price"
                            variant="outlined"
                            style={{
                                width: '8vw',
                                margin: 'auto',
                                backgroundColor: 'white'
                            }}
                            value={formik.values.Price}
                            error={formik.touched.Price && Boolean(formik.errors.Price)}
                            helperText={formik.touched.Price && formik.errors.Price}
                            onChange={(event) => {
                                if (event.target.value.toString().length <= 6) {
                                    formik.handleChange(event)
                                }
                            }}
                            type="number"
                        />
                    </div>
                )}
            </StyledTableCell>

            <StyledTableCell style={{ verticalAlign: 'baseline', paddingTop: 40 }} align="center">
                {showOtherFields && (
                    <div style={{ display: "flex" }}>
                        <GenericTextField
                            name="stockqty"
                            id="stockqty"
                            variant="outlined"
                            value={formik.values.stockqty}
                            error={formik.touched.stockqty && Boolean(formik.errors.stockqty)}
                            helperText={formik.touched.stockqty && formik.errors.stockqty}
                            onChange={(event) => {
                                if (event.target.value.toString().length <= 6) {
                                    formik.handleChange(event)
                                }
                            }}
                            style={{
                                width: '8vw',
                                margin: 'auto',
                                backgroundColor: 'white'
                            }}
                            type="number"
                        />
                    </div>
                )}
            </StyledTableCell>

            <StyledTableCell style={{ verticalAlign: 'baseline', paddingTop: 40 }} align="center" colSpan={2}>
                {showOtherFields && (
                    <div
                        className={styles.btn_container}
                    >
                        <GenericButton
                            variant="contained"
                            type="submit"
                            onClick={formik.handleSubmit}
                        >
                            Add
                        </GenericButton>

                        <GenericButton
                            variant="contained"
                            btnColor="secondary"
                            onClick={handleClose}
                        >
                            Cancel
                        </GenericButton>
                    </div>
                )}
            </StyledTableCell>
        </StyledTableRow>

    );
};

export default AddForm;
