import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Alert from '../../../util_comps/alert';
import InventoryList from './Parts/InventoryList';
import './style.css';
import DiscountModal from './Parts/DiscountModal';
import StaffPickModal from './Parts/StaffPick';
import PromoModal from './Parts/PromoModal';
import AddTagToProductModal from './Parts/AddTagToProduct';
import AddProductToTagModal from './Parts/AddProductToTag';
import CreateTagModal from './Parts/CreateTag';
import AddProductSubModal from './Parts/AddProductSubModal';
import CategorySelection from './Parts/CategorySelection';
import { SystemUpdateAltOutlined } from '@mui/icons-material';
import { uploadCsv } from '../../../servise/inventoryAPI';
import { Pagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFormats, getInventories } from '../../../redux/actions/inventoryActions';
import { Add, Close } from '@mui/icons-material';
import { useAuth } from '../../../hooks/useAuth';
import { usePrompt } from '../../../hooks/usePageLeave';
import LightTooltip from "../../../util_comps/LightTooltip";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Breadcrumbs, Button, IconButton, InputBase, Paper, Typography, useMediaQuery } from "@mui/material";
import { NavigateNext, Search } from "@mui/icons-material";
import { toast } from "react-toastify";
import { debounce } from 'lodash'
import Loader from "../../Library/Loader";
import styles from './MyInventory.module.scss'
import InputField from "../../Library/InputField/InputField";
import RestaurantsInventory from "../../RestaurantsInventory/RestaurantsInventory";
import { nodeAxios } from "../../../utils/axiosConfig";

const MyInventory = (props) => {

    const [isTagsModal, setIsTagsModal] = useState(false)
    const [activeModal, setActiveModal] = useState({ name: 'none', props: {} });
    const [activeForm, setActiveForm] = useState({ name: 'none', props: {} });
    const [secondaryModal, setSecondaryModal] = useState({ name: 'none', props: {} });
    const [sort, setSort] = useState({ sort_by: 'created_at', sort_by_order: 'DESC' })
    const user = useSelector((store) => store.user);

    const [isDirty, setIsDirty] = useState(false)
    // usePrompt("Are you sure want to discard changes?", isDirty);

    const [selected, setSelected] = useState([{
        index: 0,
        value: []
    }])

    // let initState = useRef({
    //     newInventory: {
    //         Price: "",
    //         dept: "Beer",
    //         format: "",
    //         limited: "",
    //         product_id: "",
    //         product_name: "",
    //         sku: "",
    //         stockqty: "",
    //         venue_name: "",
    //         venue_id: ""
    //     }
    // })
    const [searchStr, setSearchStr] = useState("");
    const history = useNavigate()
    const mobile = useMediaQuery('(max-width:600px)');
    const { tags } = queryString.parse(window.location.search);

    const uploadRef = useRef(null);
    const dispatch = useDispatch(data => data);
    const { pagination } = useSelector(state => state.inventory.inventories);
    const isListLoading = useSelector(state => state.inventory.isLoading);

    const handleUploadClick = async () => {
        // let result = await Alert('Are You Sure', 'This will overwrite existing inventories', 'warning', {
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes, let me upload!'
        // });
        // if (result.isConfirmed) {
        uploadRef.current.click();
        // };
    }
    const handleUploadCsv = async (e) => {
        try {
            e.preventDefault();
            if (!e.target.value) {
                return;
            }
            let file = e.target.files[0];
            const fd = new FormData()
            fd.append('image_file', file)
            nodeAxios.post('/products/by-csv', fd)

            toast.success('Please check back in some time your products are getting uploaded')
            uploadRef.current.value = ""
        } catch (err) {
            toast.error('Error occured while uploading, select valid .csv file and try again')
            return;
        };
    }
    useAuth();

    const fetchInventory = (categoryId = '', page = 1, searchStr = '') => {
        dispatch(getInventories({ page: page, searchStr, category_id: categoryId, sort_by: sort.sort_by, sort_by_order: sort.sort_by_order }));
        setActiveForm({ name: '', props: {} })
    }

    useEffect(() => {
        let categoryId = (!selected[selected.length - 2] || selected[selected.length - 2].selectedValue === "none") ? "" : selected[selected.length - 2].selectedValue.MainCatId
        fetchInventory(categoryId, 1)
        // dispatch(getInventories({ page: 1, searchStr, category_id: categoryId, sort_by: sort.sort_by, sort_by_order: sort.sort_by_order }));
    }, [sort])

    useEffect(() => {
        try {
            // fetchInventory('', 1)
            // dispatch(getInventories({ page: 1, searchStr, category_id: "", sort_by: sort.sort_by, sort_by_order: sort.sort_by_order }));
            dispatch(getFormats());
        } catch (err) {
            toast.error('Error occured while fetching inventories, try again.')
        };
        if (tags) {
            setIsTagsModal(true)
        }
    }, []);

    const handleSearch = (searchStr) => {
        try {
            let categoryId = (!selected[selected.length - 2] || selected[selected.length - 2].selectedValue === "none") ? "" : selected[selected.length - 2].selectedValue.MainCatId
            fetchInventory(categoryId, 1, searchStr)
        } catch (err) {
            toast.error('Error occured while fetching inventories, please try again.')
        };
    };

    const debounceSearch = useCallback(debounce(handleSearch, 1000), [])

    const paginate = (e, page) => {
        try {
            let categoryId = (!selected[selected.length - 2] || selected[selected.length - 2].selectedValue === "none") ? "" : selected[selected.length - 2].selectedValue.MainCatId
            fetchInventory(categoryId, page)
        } catch (err) {
            toast.error('Error occured while fetching inventories, try again.')
        };
    };

    const removeSelected = (index) => {
        let newSelected = selected.filter((val, i) => i <= index);
        newSelected[index] = {
            ...newSelected[index],
            selectedValue: 'none'
        }
        setSelected(newSelected);
        let categoryId = (!newSelected[newSelected.length - 2] || newSelected[newSelected.length - 2].selectedValue === "none") ? "" : newSelected[newSelected.length - 2].selectedValue.MainCatId
        fetchInventory(categoryId, 1)
        // dispatch(getInventories({ page: 1, searchStr, category_id: categoryId }));
    };
    console.log(activeModal)
    return (
        <React.Fragment>
            <div style={{ width: '100vw', display: (activeModal.name !== 'none' && activeModal.name !== 'addtag') ? 'initial' : 'none', position: 'absolute', zIndex: '99999', background: 'rgba(0, 0, 0, 0.3)', height: '100%', }}></div>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div style={{ padding: '5px 20px' }}>
                        <StaffPickModal setActiveModal={setActiveModal} active={activeModal.name === "staffpick"} />
                        <DiscountModal oldPrice={activeModal.props.oldPrice} setActiveModal={setActiveModal} active={activeModal.name === "discount"} />
                        <PromoModal oldPrice={activeModal.props.oldPrice} setActiveModal={setActiveModal} active={activeModal.name === "promo"} />
                        <AddTagToProductModal productName={activeModal.props.productName} productId={activeModal.props.productId} setActiveModal={setActiveModal} active={activeModal.name === "addtag"} />

                        <AddProductToTagModal
                            // onClose={() => history('/products')}
                            secondaryModal={secondaryModal}
                            setSecondaryModal={setSecondaryModal}
                            productName={activeModal.props.productName}
                            productId={activeModal.props.productId}
                            closeIsTagsModal={() => setIsTagsModal(false)}
                            active={isTagsModal}
                        />

                        <CreateTagModal
                            secondaryModal={secondaryModal}
                            setActiveModal={setSecondaryModal}
                            active={secondaryModal.name === "createtag"}
                        />

                        <AddProductSubModal secondaryModal={secondaryModal} setActiveModal={setSecondaryModal} active={secondaryModal.name === "addproductsubmodal"} />
                        {mobile ? (<></>) : (<div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Breadcrumbs
                                separator={
                                    <NavigateNext
                                        fontSize="small"
                                    />
                                }
                            >
                                <Link
                                    className="bread_sublink"
                                    to="/"
                                >
                                    Home
                                </Link>
                                <Typography
                                    color="textPrimary"
                                >
                                    My Inventory
                                </Typography>
                            </Breadcrumbs>
                        </div>)}

                        {(user?.data?.venue_type == 'Restaurant') ? (
                            <RestaurantsInventory
                                setActiveModal={setActiveModal}
                            />
                        ) : (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 15
                                    }}
                                >
                                    <div
                                        className={styles.top_searchbar}
                                    >
                                        <InputField
                                            placeholder="Search Inventory"
                                            value={searchStr}
                                            onChange={(e) => {
                                                setSearchStr(e.target.value)
                                                debounceSearch(e.target.value)
                                            }}
                                            onIconButtonClick={e => debounceSearch(searchStr)}
                                            showIconButton={true}
                                            endIcon={(
                                                <Search
                                                    style={{
                                                        color: '#ef2d2c'
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        marginTop: '15px',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        paddingBottom: 25
                                    }}
                                >
                                    <div
                                        className={styles.cat_section_container}
                                    >
                                        <CategorySelection
                                            selected={selected}
                                            setSelected={setSelected}
                                            searchStr={searchStr}
                                        />

                                        <div
                                            className={styles.cat_btn_container}
                                        >
                                            <Button
                                                onClick={e => setActiveForm({ name: 'add', props: {} })}
                                                variant="contained"
                                                startIcon={mobile ? undefined : <Add />}
                                                style={{
                                                    backgroundColor: '#001425',
                                                    color: 'white',
                                                    whiteSpace: 'nowrap',
                                                    height: '40px',
                                                    padding: '0 15px',
                                                    marginRight: '6px',
                                                    opacity: props.isActionsDisabled ? 0.5 : 1
                                                }}
                                                disabled={props.isActionsDisabled}
                                            >
                                                {mobile ? (
                                                    <Add />
                                                ) : (
                                                    "Add New Product"
                                                )}
                                            </Button>

                                            <input type="file" accept=".csv" style={{ display: 'none' }} onChange={e => handleUploadCsv(e)} ref={uploadRef} />
                                            <Button
                                                onClick={handleUploadClick}
                                                startIcon={mobile ? undefined : <SystemUpdateAltOutlined style={{ transform: 'rotate(180deg)', marginRight: '5px' }} fontSize="small" color="inherit" />}
                                                variant="contained"
                                                style={{
                                                    backgroundColor: '#001425',
                                                    color: 'white',
                                                    whiteSpace: 'nowrap',
                                                    height: '40px',
                                                    padding: '0 15px',
                                                    marginRight: '6px',
                                                    opacity: props.isActionsDisabled ? 0.5 : 1
                                                }}
                                                disabled={props.isActionsDisabled}
                                            >
                                                {mobile ? (
                                                    <SystemUpdateAltOutlined style={{ transform: 'rotate(180deg)', marginRight: '5px' }} fontSize="small" color="inherit" />
                                                ) : (
                                                    "Upload Inventory File"
                                                )}
                                            </Button>

                                            <Button
                                                startIcon={mobile ? undefined : <SystemUpdateAltOutlined style={{ marginRight: '5px' }} fontSize="small" color="inherit" />}
                                                variant="contained"
                                                style={{
                                                    backgroundColor: '#001425',
                                                    color: 'white',
                                                    whiteSpace: 'nowrap',
                                                    height: '40px',
                                                    padding: '0 15px',
                                                    marginRight: '6px',
                                                    opacity: props.isActionsDisabled ? 0.5 : 1
                                                }}
                                                disabled={props.isActionsDisabled}
                                            >
                                                {mobile ? (
                                                    <SystemUpdateAltOutlined style={{ marginRight: '5px' }} fontSize="small" color="inherit" />
                                                ) : (
                                                    <a href='../../../docs/download_template.csv' style={{ textDecoration: 'none', color: 'inherit' }} download>
                                                        Download Template
                                                    </a>
                                                )}
                                            </Button>
                                        </div>

                                    </div>

                                    <div className="my-inventory-category-filters" style={{ display: 'flex', width: '100%', marginTop: '13px', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', marginRight: 'auto' }}>

                                            <div>
                                                {selected.map((val, i) => {
                                                    if (val.selectedValue && val.selectedValue !== 'none') {
                                                        return (
                                                            <div style={{ color: 'white', borderRadius: '20px', padding: '10px 18px', marginRight: '8px', marginBottom: '8px', backgroundColor: '#323232', alignItems: 'center', display: 'inline-flex' }}>
                                                                <Typography variant="h6" style={{ fontSize: '.9rem', display: 'inline-block', color: 'white' }}>{val.selectedValue.MainCatName}</Typography>
                                                                <Close onClick={e => removeSelected(i)} style={{ fontSize: '1rem', cursor: 'pointer', color: 'white', marginLeft: '10px' }} />
                                                            </div>
                                                        )
                                                    }
                                                })}
                                                {searchStr ?
                                                    (
                                                        <div style={{ color: 'white', borderRadius: '20px', padding: '10px 18px', marginRight: '8px', backgroundColor: '#323232', alignItems: 'center', display: 'inline-flex' }}>
                                                            <Typography variant="h6" style={{ fontSize: '.9rem', display: 'inline-block', color: 'white' }}>{searchStr}</Typography>
                                                            <Close onClick={e => { setSearchStr(""); search("") }} style={{ fontSize: '1rem', cursor: 'pointer', color: 'white', marginLeft: '10px' }} />
                                                        </div>
                                                    )
                                                    : null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {isListLoading ? (
                                    <Loader />
                                ) : (
                                    <InventoryList isActionsDisabled={props.isActionsDisabled} setDirty={() => setIsDirty(true)} setPristine={() => setIsDirty(false)} activeForm={activeForm} setActiveForm={setActiveForm} setActiveModal={setActiveModal} sort={sort} setSort={setSort} fetchInventory={fetchInventory} mobile={mobile} />
                                )}

                                {(pagination.last_page > 1) && (
                                    <Pagination style={{ marginTop: '4vh', marginBottom: '2.5vh' }} size="small" page={pagination.current_page} count={pagination.last_page} onChange={paginate} variant="outlined" shape="rounded" />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default MyInventory;
