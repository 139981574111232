export const constructSearchString = (searchObject) => {
    
    let venueid = localStorage.getItem("venue_id");
    let searchString = "";
    if (
        searchObject.category &&
        (searchObject.category > 0 || searchObject.category === -1)
    ) {
        searchString += `&category=${searchObject.category}`;
    } else {
        searchString += `&category=1`;
    }
    if (
        searchObject.selectedCategories &&
        searchObject.selectedCategories.length > 0
    ) {
        searchObject.selectedCategories.forEach(
            (item) => (searchString += `&category_id[]=${item.category_id}`)
        );
    }
    if (
        searchObject.filters.searchString &&
        searchObject.filters.searchString.length > 0 &&
        searchObject.filters.searchString !== "none"
    ) {
        searchString += `&q=${searchObject.filters.searchString}`;
    }
    if (venueid && venueid.length > 0) {
        searchString += `&venue_id[]=${venueid}`;
    }
    if (searchObject.filters.sortby && searchObject.filters.sortby !== "none") {
        searchString += `&sortby=${searchObject.filters.sortby}`;
    }
    if (
        searchObject.filters.countries &&
        searchObject.filters.countries.length > 0
    ) {
        searchObject.filters.countries.forEach(
            (item) => (searchString += `&country[]=${item}`)
        );
    }
    if (searchObject.filters.cities && searchObject.filters.cities.length > 0) {
        searchObject.filters.cities.forEach(
            (item) => (searchString += `&city[]=${item}`)
        );
    }
    if (searchObject.filters.states && searchObject.filters.states.length > 0) {
        searchObject.filters.states.forEach(
            (item) => (searchString += `&state[]=${item}`)
        );
    }
    if (searchObject.filters.abv && searchObject.filters.abv !== "none") {
        searchString += `&abvvalue=${searchObject.filters.abv}`;
    }
    if (searchObject.filters.breweries && searchObject.filters.breweries.length > 0) {
        searchObject.filters.breweries.forEach(
            (item) => (searchString += `&brewery_id[]=${item.VenueId}`)
        );
    }
    if (searchObject.filters.ratings && searchObject.filters.ratings > 0) {
        searchString += `&rating=${searchObject.filters.ratings}`;
    }
    if (searchObject.filters.minPrice && searchObject.filters.minPrice > 0) {
        searchString += `&min=${searchObject.filters.minPrice}`;
    }
    if (searchObject.filters.maxPrice && searchObject.filters.maxPrice < 500) {
        searchString += `&max=${searchObject.filters.maxPrice}`;
    }

    return searchString.length > 0 ? `?${searchString.slice(1)}` : "";
};

