import { FormControl, InputLabel } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import styles from './GenericSwitchWithLabel.module.scss'

import { styled } from '@mui/material/styles';
import Switch, { switchClasses } from '@mui/material/Switch';

const GenericSwitch = styled(({ isStatus, ...props }) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, background, width, height, thumb, isStatus, translateX, onText, offText }) => ({
    width: width ? width : 32,
    height: height ? height : 16,
    padding: 0,
    [`& .${switchClasses.switchBase}`]: {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',


        [`& .${switchClasses.input}`]: {
            ...(isStatus && {
                width: 108,
                left: 0
            })
        },

        '&.Mui-checked': {
            transform: `translateX(${translateX || (width ? (width / 2 + 31) : 16)}px)`,
            color: '#fff',
            [`& .${switchClasses.input}`]: {
                ...(isStatus && {
                    left: -88
                })
            },
            [`& + .${switchClasses.track}`]: {
                background: background ? background : '#19B135',
                opacity: 1,
                border: 0,
                position: 'relative',
                ...(isStatus && {
                    "&:after": {
                        content: `"${onText}"` || "'Connected'",
                        color: 'white',
                        left: '8px',
                        position: 'absolute',
                        top: '3px',
                        fontSize: 13
                    }
                })

            },
            [`&.Mui-disabled + .${switchClasses.track}`]: {
                opacity: 0.5,
            },
        },
        [`&.Mui-focusVisible .${switchClasses.thumb}`]: {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        [`&.Mui-disabled .${switchClasses.thumb}`]: {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        [`&.Mui-disabled .${switchClasses.track}`]: {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    [`& .${switchClasses.thumb}`]: {
        boxSizing: 'border-box',
        width: thumb ? thumb : 12,
        height: thumb ? thumb : 12,
    },
    [`& .${switchClasses.track}`]: {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#DDDDDD' : '#DDDDDD',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        position: 'relative',
        ...(isStatus && {
            "&:after": {
                content: `"${offText}"` || "'Disconnected'",
                color: 'black',
                left: '24px',
                position: 'absolute',
                top: '5px',
                fontSize: 11
            }
        })
    },
}));

const GenericSwitchWithLabel = ({ label, placeholder, containerClassname, ...rest }) => {
    return (
        <div
            className={clsx(styles.container, containerClassname)}
        >
            <div
                className={clsx(styles.label_text, rest.labelClassName)}
            >
                <div>
                    {label}
                    {rest.required && (
                        <span
                            className={styles.required_asterisk}
                        >
                            *
                        </span>
                    )}
                </div>

                {rest.description && (
                    <div
                        className={styles.description}
                    >
                        {rest.description}
                    </div>
                )}
            </div>

            <div>
                <FormControl
                    className={styles.form_control}
                >
                    <InputLabel
                        classes={{
                            shrink: styles.input_label_shrink
                        }}
                        style={{
                            color: 'gray'
                        }}
                        sx={{
                            transform: 'translate(14px, 6px) scale(1)',
                        }}
                    >
                        {placeholder}
                    </InputLabel>
                    <GenericSwitch
                        {...rest}
                    />
                </FormControl>
            </div>
                  
        </div>
    )
}

export default GenericSwitchWithLabel