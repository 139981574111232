const initialState = {
    isLoading: false,
    isLoggedIn: false,
    orderDetails: {},
    orderDetailsError: ""
};
function orderDetails(state = initialState, action) {
    switch (action.type) {
        case "SET_ORDER_DETAILS_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_ORDER_DETAILS_LOADED":
            return { ...initialState, isLoading: false , orderDetails: action.payload };
        case "SET_ORDER_DETAILS_ERROR":
            return { ...initialState,orderListError:action.payload};
        default:
            return state;
    }
};
export default orderDetails;  