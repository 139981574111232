import { Cancel } from '@mui/icons-material'
import { DialogActions, DialogContent, DialogTitle, Drawer, IconButton } from '@mui/material'
import React from 'react'



const GenericEditDrawer = ({title, description, open, onClose, actions, children}) => {
    return (
        <>
            <DialogTitle className='tw-flex tw-justify-between tw-items-center !tw-p-0'>
                <div className='tw-font-bold tw-text-xl'>{title}</div>
                <IconButton onClick={onClose}>
                    <Cancel sx={{ color: '#000' }} />
                </IconButton>
            </DialogTitle>

            <DialogContent className='tw-flex tw-flex-col tw-gap-4 !tw-p-0'>
                {description && <div className='tw-text-sm tw-text-[#888888]'>{description}</div>}
                {children}
            </DialogContent>

            <DialogActions className='!tw-p-0 tw-mt-2'>
                {actions}
            </DialogActions>
        </>
    )
}

export default GenericEditDrawer