export const POST_CREATE_CUSTOM_PAGE_REQUEST = 'POST_CREATE_CUSTOM_PAGE_REQUEST'
export const POST_CREATE_CUSTOM_PAGE_SUCCESS = 'POST_CREATE_CUSTOM_PAGE_SUCCESS'
export const POST_CREATE_CUSTOM_PAGE_FAILURE = 'POST_CREATE_CUSTOM_PAGE_FAILURE'

export const GET_CUSTOM_PAGE_REQUEST = 'GET_CUSTOM_PAGE_REQUEST'
export const GET_CUSTOM_PAGE_SUCCESS = 'GET_CUSTOM_PAGE_SUCCESS'
export const GET_CUSTOM_PAGE_FAILURE = 'GET_CUSTOM_PAGE_FAILURE'

export const GET_CUSTOM_PAGE_DATA_REQUEST = 'GET_CUSTOM_PAGE_DATA_REQUEST'
export const GET_CUSTOM_PAGE_DATA_SUCCESS = 'GET_CUSTOM_PAGE_DATA_SUCCESS'
export const GET_CUSTOM_PAGE_DATA_FAILURE = 'GET_CUSTOM_PAGE_DATA_FAILURE'

export const DELETE_CUSTOM_PAGE_REQUEST = 'DELETE_CUSTOM_PAGE_REQUEST'
export const DELETE_CUSTOM_PAGE_SUCCESS = 'DELETE_CUSTOM_PAGE_SUCCESS'
export const DELETE_CUSTOM_PAGE_FAILURE = 'DELETE_CUSTOM_PAGE_FAILURE'