import React, { useRef, useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from '@mui/material/Typography'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: 320,
    },
    greyListItem: {
        backgroundColor: "#e8e8e8",
        color: "black",
        margin: "5px 0",
        cursor: "pointer",
    },
}));

export default function SelectedCategories(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const OnDragEnd = (result) => {
        if (!result.destination || result.destination.droppableId === result.source.droppableId && result.destination.index === result.source.index) {
            return;
        }
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };
        const items = reorder(
            props.categories,
            result.source.index,
            result.destination.index
        );      
        props.setCategories(items);
    };
    return (
        <React.Fragment>
            <Typography style={{fontSize: 18,marginBottom: '10px'}} variant="h6">Selected Categories</Typography>            
            <div className={classes.root} style={{ border: "1px solid lightgrey", height: 310}}>
                <div style={{ padding: "15px" }}>
                    <DragDropContext onDragEnd={OnDragEnd}>
                        <Droppable droppableId="characters">
                            {(provided) => (
                                <List
                                    className="characters"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {props.categories.map(({ category_id, categoryName }, index) => {
                                        return (
                                            <Draggable
                                                key={category_id}
                                                draggableId={category_id.toString()}
                                                index={index}
                                            >
                                                {(provided, snapshot) => {
                                                    const otherProps = {
                                                        ...provided.draggableProps,
                                                        style: {
                                                            ...provided
                                                                .draggableProps
                                                                .style,
                                                            color: "black",
                                                            margin: "5px 0",
                                                            cursor: "pointer",
                                                            background: "#F6F6F6",
                                                            cursor: 'grab'
                                                        },
                                                    };
                                                    return (
                                                        <ListItem
                                                            ref={provided.innerRef}
                                                            {...otherProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <ListItemText
                                                                primary={categoryName}
                                                            />
                                                            <ListItemIcon onClick={e=> props.handleCategoryRemove({category_id,categoryName})} style={{justifyContent: 'flex-end'}}>
                                                                <ClearIcon style={{cursor: 'pointer'}} fontSize="small" />
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </React.Fragment>
    );
}
