import { Slider } from '@mui/material'
import React from 'react'
import styles from './SliderWithLabel.module.scss'
import clsx from 'clsx'

const SliderWithLabel = (props) => {
    return (
        <div>
            <div
                className={styles.title_container}
            >
                <div
                    className={clsx(styles.title, (props.colorType === 'secondary') && styles.label_text_2)}
                >
                    {props.title}
                </div>

                <div>
                    {props.value}px
                </div>
            </div>

            <Slider
            classes={{
                rail: styles.rail,
                track: styles.track,
                thumb: styles.thumb
            }}
                value={props.value}
                onChange={props.handleChange}
                min={props.min || 0}
                max={props.max || 150}
            />
        </div>
    )
}

export default SliderWithLabel