import { Add, Cancel, Close, HelpOutlined, KeyboardArrowDown } from '@mui/icons-material'
import { Checkbox, Chip, Collapse, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import styles from './PublishToSection.module.scss'

const PublishToSection = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                className={styles.title_container}
            >
                <div
                    className={styles.publish_to_text}
                >
                    Accounts
                </div>
            </div>

            <div
                className={styles.chips}
            >
                <div
                    className={styles.chips_container}
                >
                    {props.platformName.map((data) => (
                        <div
                            style={{
                                position: 'relative'
                            }}
                        >
                            <Chip
                                style={{
                                    marginTop: '6px',
                                    marginRight: '8px',
                                    background: data.isInstagram ? 'linear-gradient(258.74deg, #9E1DE1 9.19%, #FC105B 48.58%, #FCB700 89.13%)' : (data.isFacebook ? '#007CBD' : '#1D9BF0'),
                                    color: 'white'
                                }}
                                key={data}
                                classes={{
                                    label: styles.chip_label
                                }}
                                label={data.name}
                                // icon={
                                //     <div
                                //         style={{
                                //             margin: '6px 5px 6px 12px',
                                //             height: 20,
                                //             width: 20
                                //         }}
                                //     >
                                //         {value.isFacebook && (
                                //             <FaFacebook
                                //                 color="#4267B2"
                                //                 fontSize={20}
                                //             />
                                //         )}

                                //         {value.isInstagram && (
                                //             <img
                                //                 src={InstagramColorIcon}
                                //                 height={20}
                                //             />
                                //         )}

                                //         {value.isTwitter && (
                                //             <FaTwitter
                                //                 color="#4267B2"
                                //                 fontSize={20}
                                //             />
                                //         )}
                                //     </div>
                                // }
                                clickable={true}
                                className={styles.chip}
                            />
                            <div
                                className={styles.close_container}
                                onClick={() => {
                                    props.handleDelete(data)
                                }}
                            >
                                <Close
                                    style={{
                                        color: 'white',
                                        fontSize: 14
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                    <IconButton
                        style={{
                            backgroundColor: '#9B9B9B',
                            height: 34,
                            width: 34
                        }}
                        size="small"
                        onClick={handleClick}
                    >
                        <Add
                            style={{
                                color: 'white'
                            }}
                        />
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        // onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <div>
                            <div
                                className={styles.select_account_text}
                            >
                                Select account to post
                            </div>

                            {props.pages.concat(props.names).map((value) => (
                                <MenuItem
                                    style={{
                                        paddingLeft: 0
                                    }}
                                    onClick={() => {
                                        if (props.platformName.filter((page) => {
                                            if (value.id == page.id) {
                                                return true
                                            }
                                            return false
                                        }).length > 0) {
                                            props.handleDelete(value)
                                        } else {
                                            props.handleChangePlatform(value)
                                        }
                                    }}
                                >
                                    <Checkbox
                                        style={{
                                            color: 'black'
                                        }}
                                        checked={props.platformName.filter((page) => {
                                            if (value.id == page.id) {
                                                return true
                                            }
                                            return false
                                        }).length > 0}
                                        size="small"
                                    />

                                    <div>
                                        {value.name}
                                    </div>

                                </MenuItem>
                            ))}
                        </div>
                    </Menu>
                </div>
            </div>
        </>
    )
}

export default PublishToSection