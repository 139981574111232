import React, { useRef, useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: 320,
  },
  greyListItem: {
    backgroundColor: "#e8e8e8",
    color: "black",
    margin: "5px 0",
    cursor: "pointer",
  },
}));

export default function SelectedTags(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography style={{ fontSize: 18, marginBottom: "10px" }} variant="h6">
        Selected Tags
      </Typography>
      <div
        className={classes.root}
        style={{ border: "1px solid lightgrey", height: 310 }}
      >
        <div style={{ padding: "15px" }}>
          <List
            className="characters"
          >
            {props.selectedTags?.map((data, index) => (
              <>
                {
                  data.products.map((data1) => (
                    <ListItem className={classes.greyListItem}>
                      <div>
                        {/* <img /> */}
                      </div>
                      <ListItemText primary={data1.product_detail.Name} />
                    </ListItem>
                  ))
                }
              </>
            ))}
          </List>
        </div>
      </div>
    </React.Fragment>
  );
}
