import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios, { nodeAxios } from '../../utils/axiosConfig'
import Loader from '../Library/Loader';

const StoreRoute = () => {

  const user = useSelector((state) => state.user);

  const [venueDetails, setVenueDetails] = useState({})
  const [pickupSettings, setPickupSettings] = useState({})
  const [deliverySettings, setDeliverySettings] = useState({})
  const [service, setService] = useState({})
  const [pickupVenueHours, setPickupVenueHours] = useState([])
  const [openVenueHours, setOpenVenueHours] = useState([])
  const [deliveryVenueHours, setDeliveryVenueHours] = useState([])
  const [deliveryZone, setDeliveryZone] = useState([])
  const [license, setLicense] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [venueStates, setVenueStates] = useState([])
  const [venueSubTypes, setVenueSubTypes] = useState([])

  const removeImage = () => {
    setVenueDetails((prevState) => ({ ...prevState, image: null }))
  }

  const removeLicenceImage = () => {
    setLicense((prevState) => ({ ...prevState, licence_file: null }))
  }

  const removeFavicon = () => {
    setVenueDetails((prevState) => ({ ...prevState, favicon: null }))
  }

  const setServiceChargeToCustomer = (key, value) => {
    setVenueDetails((prevState) => ({ ...prevState, [key]: value }))
  }

  const fetchVenueStates = () => {
    axios.get('/venue/states')
      .then((response) => {
        setVenueStates(response.data?.data?.result)
      })
  }
  // const fetchVenueSubTypes = () => {
  //   axios.get('/venue-sub-type')
  //     .then((response) => {
  //       setVenueSubTypes(response.data?.data?.result)
  //     })
  // }

  const fetchPickupInfo = () => {
    setIsLoading(true)
    // import axios, { nodeAxios } from '../../../../../utils/axiosConfig'
    nodeAxios.get('/venue/pickup-settings')
      .then((response) => {
        console.log('fetchPickupInfo called', response.data.data)
        setPickupSettings({
          ...response.data.data,
        })

      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const fetcDeliveryInfo = () => {
    setIsLoading(true)
    // import axios, { nodeAxios } from '../../../../../utils/axiosConfig'
    nodeAxios.get('/venue/delivery-settings')
      .then((response) => {
        console.log('fetcDeliveryInfo called', response.data.data)
        setDeliverySettings({
          ...response.data.data,
        })

      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fetchStoreInfo = () => {
    setIsLoading(true)
    axios.get('/venue/setting')
      .then((response) => {
        setVenueDetails({
          ...response.data.data.venue,
          ...response.data.data.venue_social_links,
          ...response.data.data.venue_esplanda_fee,
          social_links: response.data.data.venue_social_links.social_links || null
        })
        setService(response.data.data.store_info_check)
        setDeliveryZone(response.data.data.venue_delivery_zone)
        setOpenVenueHours(response.data.data.venue_hours?.filter((data) => data.venuetimings == 'open'))
        setPickupVenueHours(response.data.data.venue_hours?.filter((data) => data.venuetimings == 'pickup'))
        setDeliveryVenueHours(response.data.data.venue_hours?.filter((data) => data.venuetimings == 'delivery'))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fetchLiquorLicense = () => {
    axios.get('/venue/steps/licence-info')
      .then((response) => {
        setLicense(response.data.data?.result || {})
      })
      .catch(() => {
        // toast.error('Something went wrong')
      })
  }

  const requestVerification = (page_no, prevData) => {
    setIsLoading(true);
    nodeAxios
        .post('/email-settings/update-verification-status') 
        .then(() => {})
        .catch((err) => {
            console.log('🚀OUTPUT --> err:', err);
            // toast.error(err.response.data.message);
        })
        .finally(() => {
        setIsLoading(false);
    });
  };


  useEffect(() => {
    fetchLiquorLicense()
    fetchStoreInfo()
    fetchVenueStates()
    fetchPickupInfo()
    fetcDeliveryInfo()
    requestVerification();
    // fetchVenueSubTypes()
  }, [])

  return (
    <Box
      sx={{
        padding: {
          xs: '0px 5px',
          sm: '0px 20px'
        }
      }}
    >
      <Outlet
        context={{
          isActionsDisabled: user?.data?.is_claim_business === 1,
          venueDetails: venueDetails,
          pickupSettings: pickupSettings,
          deliverySettings: deliverySettings,
          pickupVenueHours,
          openVenueHours,
          deliveryVenueHours,
          license,
          service,
          deliveryZone,
          isLoading,
          venueStates,
          removeImage,
          removeLicenceImage,
          removeFavicon,
          setServiceChargeToCustomer,
          fetchLiquorLicense,
          fetchStoreInfo,
          fetchPickupInfo,
          fetcDeliveryInfo,
        }}
      />
    </Box>
  )
}

export default StoreRoute