import { Add, CameraAlt, Edit } from '@mui/icons-material'
import { Dialog, DialogContent } from '@mui/material'
import React, { forwardRef } from 'react'
import TabsSection from '../../Library/TabsSection/TabsSection'
import styles from './ImageGalleryDialog.module.scss'
import SelectFromGallery from './SelectFromGallery/SelectFromGallery'
import UploadPhoto from './UploadPhoto/UploadPhoto'

const tabs = [
    {
        value: 0,
        label: 'Upload Photo'
    },
    {
        value: 1,
        label: 'Select From Gallery'
    },
]

const ImageGalleryDialog = forwardRef((props, ref) => {

    const { inputRef, titleRef, descriptionRef, buttonRef } = ref

    return (
        // <Dialog
        //     open={true}
        //     maxWidth="xl"
        // >
        //     <DialogContent>
        <TabsSection
            defaultTab={props.isEdit ? 1 : 0}
            tabValue={props.tabValue}
            onChange={props.handleChange}
            tabs={tabs}
            tabsBody={[
                {
                    component: (
                        <UploadPhoto
                            ref={inputRef}
                            onChange={props.handleFileChange}
                            file={props.file}
                            imageLink={props.imageLink}
                            onImageClose={props.onImageClose}
                        />
                    )
                },
                {
                    component: (
                        <SelectFromGallery
                            isPost={props.isPost}
                            bannerCat={props.bannerCat}
                            fetchBannerGallery={props.fetchBannerGallery}
                            setBannerCat={props.setBannerCat}
                            bannerCatList={props.bannerCatList}
                            isGalleryLoading={props.isGalleryLoading}
                            selectedbanner={props.selectedbanner}
                            setBannerdata={props.setBannerdata}
                            setSelectedBanner={props.setSelectedBanner}
                            setBannerTitle={props.setBannerTitle}
                            currentGallery={props.currentGallery}
                            
                            file={props.file}
                            imageLink={props.imageLink}

                            imageComponent={!props.isPost && (
                                props.isEdit ? (
                                    <div
                                        style={{
                                            position: 'relative'
                                        }}
                                    >
                                        <img
                                            src={props.bannerdata[props.editImageIndex]?.image}
                                            height={180}
                                            width={800}
                                        />

                                        <div
                                            className={styles.change_image}
                                            onClick={() => {
                                                props.setSelectedBanner()
                                                props.setFile()
                                                props.setBannerdata((prevState) => {
                                                    let newState = [...prevState]
                                                    newState[props.editImageIndex].image = null
                                                    return newState
                                                })
                                            }}
                                        >
                                            <CameraAlt style={{ marginRight: '8px' }} />
                                            Change Image
                                        </div>

                                        <div
                                            className={styles.add_image_details}
                                            onClick={() => {
                                                props.setShowBannerDetails(true)
                                            }}
                                        >
                                            Add Image Details
                                        </div>

                                        <div
                                            className={styles.add_details}
                                        >
                                            <div
                                                className={styles.add_details_title}
                                                onBlur={(e) => {
                                                    if (e.currentTarget?.textContent) {
                                                        titleRef.current = e.currentTarget?.textContent
                                                        props.setBannerdata((prevState) => {
                                                            let newState = [...prevState]
                                                            newState[props.editImageIndex]['banner_title'] = titleRef.current
                                                            return newState
                                                        })
                                                    }
                                                }}
                                                contentEditable
                                            >
                                                {props.bannerdata[props.editImageIndex]?.banner_title ? props.bannerdata[props.editImageIndex]?.banner_title : "Add Title"}
                                                <Edit
                                                    style={{
                                                        position: 'absolute',
                                                        top: -6
                                                    }}
                                                />
                                            </div>

                                            <div
                                                className={styles.add_details_description}
                                                onBlur={(e) => {
                                                    if (e.currentTarget?.textContent) {
                                                        descriptionRef.current = e.currentTarget?.textContent
                                                        props.setBannerdata((prevState) => {
                                                            let newState = [...prevState]
                                                            newState[props.editImageIndex]['description'] = descriptionRef.current
                                                            return newState
                                                        })
                                                    }
                                                }}
                                                contentEditable
                                            >
                                                {props.bannerdata[props.editImageIndex]?.description ? props.bannerdata[props.editImageIndex]?.description : "Add Description"}
                                                <Edit
                                                    style={{
                                                        position: 'absolute',
                                                        top: -6
                                                    }}
                                                />
                                            </div>

                                            <div
                                                className={styles.add_details_item_btn}
                                                onBlur={(e) => {
                                                    if (e.currentTarget?.textContent) {
                                                        buttonRef.current = e.currentTarget?.textContent
                                                        props.setBannerdata((prevState) => {
                                                            let newState = [...prevState]
                                                            newState[props.editImageIndex]['button_text'] = buttonRef.current
                                                            return newState
                                                        })
                                                    }
                                                }}
                                                contentEditable
                                            >
                                                {props.bannerdata[props.editImageIndex]?.button_text ? props.bannerdata[props.editImageIndex]?.button_text : "Button Text"}
                                                <Edit
                                                    style={{
                                                        position: 'absolute',
                                                        top: -6
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            position: 'relative'
                                        }}
                                    >
                                        <img
                                            src={props.file ? URL.createObjectURL(props.file) : props.imageLink}
                                            height={180}
                                            width={800}
                                        />

                                        <div
                                            className={styles.change_image}
                                            onClick={() => {
                                                props.setSelectedBanner()
                                                props.setFile()
                                            }}
                                        >
                                            <CameraAlt style={{ marginRight: '8px' }} />
                                            Change Image
                                        </div>

                                        <div
                                            className={styles.add_image_details}
                                            onClick={() => {
                                                props.setShowBannerDetails(true)
                                            }}
                                        >
                                            Add Image Details
                                        </div>

                                        {!props.showBannerAttributes ? (
                                            <div
                                                className={styles.add_details_icon}
                                                onClick={() => props.setShowBannerAttributes(true)}
                                            >
                                                <Add />
                                            </div>
                                        ) : (
                                            <div
                                                className={styles.add_details}
                                            >
                                                <div
                                                    className={styles.add_details_title}
                                                    onBlur={(e) => {
                                                        if (e.currentTarget?.textContent) {
                                                            titleRef.current = e.currentTarget?.textContent
                                                            props.setBannerdata((prevState) => {
                                                                let newState = [...prevState]
                                                                newState[newState.length - 1]['banner_title'] = titleRef.current
                                                                return newState
                                                            })
                                                        }
                                                    }}
                                                    contentEditable
                                                >
                                                    {props.bannerdata[props.bannerdata.length - 1]?.banner_title ? props.bannerdata[props.bannerdata.length - 1]?.banner_title : "Add Title"}
                                                </div>

                                                <div
                                                    className={styles.add_details_description}
                                                    onBlur={(e) => {
                                                        if (e.currentTarget?.textContent) {
                                                            descriptionRef.current = e.currentTarget?.textContent
                                                            setBannerdata((prevState) => {
                                                                let newState = [...prevState]
                                                                newState[newState.length - 1]['description'] = descriptionRef.current
                                                                return newState
                                                            })
                                                        }
                                                    }}
                                                    contentEditable
                                                >
                                                    {props.bannerdata[props.bannerdata.length - 1]?.description ? props.bannerdata[props.bannerdata.length - 1]?.description : "Add Description"}
                                                </div>

                                                <div
                                                    className={styles.add_details_item_btn}
                                                    onBlur={(e) => {
                                                        if (e.currentTarget?.textContent) {
                                                            buttonRef.current = e.currentTarget?.textContent
                                                            setBannerdata((prevState) => {
                                                                let newState = [...prevState]
                                                                newState[newState.length - 1]['button_text'] = buttonRef.current
                                                                return newState
                                                            })
                                                        }
                                                    }}
                                                    contentEditable
                                                >
                                                    {props.bannerdata[props.bannerdata.length - 1]?.button_text ? props.bannerdata[props.bannerdata.length - 1]?.button_text : "Button Text"}
                                                </div>

                                            </div>
                                        )}
                                    </div>
                                ))}
                        />
                    )
                }
            ]}
        />
        //     </DialogContent>
        // </Dialog>
    )
})

export default ImageGalleryDialog