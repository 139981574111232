import React from 'react';
import { CircularProgress, IconButton, TextField } from '@mui/material';


const GenericSearchField = ({ value, debounceSearch, setValue, isSearchLoading, placeholder, fullWidth }) => {
  return (
    <div className={`tw-mr-2 tw-relative sm:tw-w-[300px] ${fullWidth && 'tw-w-full'}`}>
      {isSearchLoading ? (
          <CircularProgress
              size={16}
              className='tw-absolute tw-z-10 tw-w-4 tw-h-4 tw-top-3 tw-left-[18px] !tw-text-black'
          />
      ) : (
          <IconButton 
              onClick={() => debounceSearch(value)}
              className='!tw-absolute tw-z-10 tw-top-[1px] sm:tw-top-[3px] tw-left-1.5'
          >
              <svg
              className='tw-w-4 tw-h-4'
              width='15'
              height='15'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              >
              <path d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z' stroke='#919191' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
              <path d='M18.9999 18.9999L14.6499 14.6499' stroke='#919191' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
              </svg>
          </IconButton>
      )}

      <TextField
          InputProps={{
              classes: {
              notchedOutline: '!tw-border-none !tw-shadow-none !tw-outline-none !tw-bg-[#f2f2f2] !tw-rounded-[20px] !tw-w-full sm:!tw-w-[310px]',
              input: '!tw-z-[1] !tw-text-sm !tw-py-[8px] !tw-pr-[25px] !tw-pl-[40px] !tw-w-full sm:!tw-w-[235px] !tw-rounded-[20px]',
              },
          }}
          fullWidth={fullWidth}
          variant='outlined'
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
              debounceSearch(event.target.value);
              setValue(event.target.value);
          }}
      />
      {value && !isSearchLoading && (
          <IconButton
              onClick={() => {
                  setValue('');
                  debounceSearch('');
              }}
              className='!tw-absolute tw-z-10 tw-top-[1px] sm:tw-top-[3px] tw-right-1.5 sm:-tw-right-1.5 !tw-text-sm'
          >
              <svg width="16" height="16" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.5 1.1875C4.86875 1.1875 1.1875 4.86875 1.1875 9.5C1.1875 14.1313 4.86875 17.8125 9.5 17.8125C14.1313 17.8125 17.8125 14.1313 17.8125 9.5C17.8125 4.86875 14.1313 1.1875 9.5 1.1875ZM9.5 16.625C5.58125 16.625 2.375 13.4187 2.375 9.5C2.375 5.58125 5.58125 2.375 9.5 2.375C13.4187 2.375 16.625 5.58125 16.625 9.5C16.625 13.4187 13.4187 16.625 9.5 16.625Z" fill="black"/>
                  <path d="M12.7062 13.6562L9.5 10.45L6.29375 13.6562L5.34375 12.7062L8.55 9.5L5.34375 6.29375L6.29375 5.34375L9.5 8.55L12.7062 5.34375L13.6562 6.29375L10.45 9.5L13.6562 12.7062L12.7062 13.6562Z" fill="black"/>
              </svg>
          </IconButton>
      )}
  </div>
  );
  };

export default GenericSearchField;
