import { toast } from 'react-toastify';
import axios, { nodeAxios } from '../utils/axiosConfig';

export const changeStatus = async (id, status) => {
    try {
        await nodeAxios.post('/orders/change-status', {
            orderId: id,
            nextStatus: status,
            actionTakenFrom: (window.innerWidth < 1000) ? 'App' : 'Web',
        })
        return;
    } catch (err) {
        toast.error(err.response?.data?.message)
        throw err;
    };
};

export const saveOrders = async (orderId, newOrders, orderVenue) => {
    let newOrderVenue = { ...orderVenue }

    try {
        await axios.post(`/order/substitute-order/${orderId}`, {
            order_items: newOrders,
            ...newOrderVenue
        });
        return;
    } catch (error) {
        throw error;
    };
};

export const cancelOrder = async (orderId) => {
    try {
        await nodeAxios.get(`/orders/cancel/${orderId}`, {
            params: {
                action_taken_from: (window.innerWidth < 1000) ? 'App' : 'Web'
            }
        });
        return;
    } catch (error) {
        throw error;
    };
}