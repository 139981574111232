import React from 'react'
import OrderEmailPreferences from './OrderEmailPreferences';
import EmailCustomizations from './EmailCustomizations';
import { useOutletContext } from 'react-router-dom';
import Loader from '../../../../Library/Loader';

const EmailNotification = () => {
  const { isLoading } = useOutletContext()
    

  return isLoading ? <Loader /> : (
    <div className="tw-my-2">

        <h2 className='tw-text-2xl tw-font-semibold tw-py-4'>Order Email Settings</h2>

        <div className='tw-border-b tw-border-gray tw-pb-2'>
            <h2 className='tw-text-black tw-text-lg tw-font-semibold'>Send emails when order is ready</h2>
            <p className='tw-text-sm tw-text-[#868686]'>An email will be automatically sent to customers notifying them when their order is ready.</p>
        </div>

        <OrderEmailPreferences />


        <EmailCustomizations />

    </div>
  )
}

export default EmailNotification