import React from 'react'
import { useSelector } from 'react-redux';
import Offers from '../pages/AutomatedOffers/Offers'

const OffersRoute = () => {
  const user = useSelector((state) => state.user);

  return (
    <Offers isActionsDisabled={user?.data?.is_claim_business === 1} />
  )
}

export default OffersRoute