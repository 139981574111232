import React, { useState } from 'react'
import { ArrowForward } from '@mui/icons-material';
import { Button, Drawer, List, MenuItem, Typography, outlinedInputClasses } from '@mui/material';
import GenericSwitch from '../../../../Library/GenericSwitch/GenericSwitch';
import GenericTextField from '../../../../Library/V2/GenericTextField/GenericTextField';
import GenericEditDrawer from '../../../../Library/V2/GenericEditDrawer/GenericEditDrawer';
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton';

const hours = Array.from({ length: 48 }, (_, i) => {
    const hours = Math.floor(i / 2).toString().padStart(2, '0');
    const minutes = (i % 2 === 0) ? '00' : '30';
    return `${hours}:${minutes}`;
});

const findDivisors = (num) => {
    // Check if the number is less than 1
    if (num < 1) {
        return 'Input must be a positive integer greater than 0.';
    }

    // Initialize an array to hold the divisors
    let divisors = [];

    // Check every number from 1 to the target number
    // If 'num' is divisible by this number, then it's a divisor
    for (let i = 1; i <= num; i++) {
        if (num % i === 0) {
            divisors.push(i);
        }
    }

    return divisors;
}
const gatDayName = (day) => {
    return {
        'Sunday' : 'Sun',
        'Monday' : 'Mon',
        'Tuesday' : 'Tue',
        'Wednesday' : 'Wed',
        'Thursday' : 'Thu',
        'Friday' : 'Fri',
        'Saturday' : 'Sat',
    }[day]
}

const BusinessHours = (props) => {
    const [open, setOpen] = useState(false);

    const handleInputChange = (time, index, hrkey, minkey) => {
        let [hour, minute] = time.split(':');
        console.log(hour, minute)
        hour = parseInt(hour) >= 10 ? parseInt(hour) : parseInt(hour[1]);
        minute = parseInt(minute) >= 10 ? parseInt(minute) : parseInt(minute[1]);
        console.log(hour, minute)
        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    [hrkey]: hour,
                    [minkey]: minute,
                    time_interval: 15
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    };

    const handleSlotChange = (time_interval, index, key) => {

        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    [key]: time_interval,
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    };


    const handleSwitch = (index) => {

        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    isclosed: Boolean(parseInt(val.isclosed)) ? '0' : '1'
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    }
    
    console.log('🚀OUTPUT --> props.formik:', props.formik.values);
    return (
        <div className='tw-flex tw-flex-col tw-mt-8'>
            <div className='tw-flex tw-justify-between tw-flex-col lg:tw-flex-row lg:tw-items-center'>
                <div className='tw-font-bold tw-text-xl tw-w-full'>
                    <div className='tw-flex tw-justify-between tw-items-center tw-w-full'>
                        Business Hours
                    <Button 
                        className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs' 
                        onClick={() => {setOpen(true);}}
                    >
                        {(props.formik.values.venue_hours?.filter(e => e?.venuetimings !== 'delivery' && e.isclosed !== '1').length > 0) ? 'Edit ' : 'Add '} 
                        Store Hours</Button>
                    </div>
                    <span className='tw-text-dark-gray tw-text-sm tw-font-normal'>Choose times during which your store is open to customers or clients.</span>
                </div>
                {/* <div className='tw-flex tw-justify-end'>
                    <Button 
                        className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs' 
                        onClick={() => {setOpen(true);}}
                    >
                        {(props.formik.values.venue_hours?.filter(e => e?.venuetimings !== 'delivery' && e.isclosed !== '1').length > 0) ? 'Edit ' : 'Add '} 
                        Store Hours</Button>
                </div> */}
            </div>

            <div className='tw-mt-2'>
                {props.formik.values.venue_hours.map((val, index) => {
                    if (val?.venuetimings == 'delivery' ) {
                        return
                    }
                    return (
                        <div className='tw-flex tw-justify-start tw-items-center tw-gap-4 tw-text-xs tw-font-medium'>
                            <div className='tw-flex tw-items-center tw-w-16'>
                                {val.dayname}
                            </div>

                            {val.isclosed == '0' ? (
                                <div className='tw-flex tw-items-center tw-gap-4'>
                                    {`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins || '00'}`} -{' '}
                                    {`${val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:${val.closedtimemins || '00'}`}
                                </div>
                            ) : <i className='tw-text-dark-gray tw-text-xs'>Closed</i>}
                        </div>
                    );
                })}
            </div>

                
            <Drawer
                anchor={props.isSmall ? "bottom" :"right"}
                open={open}
                onClose={() => {setOpen(false);  props.formik.resetForm();}}
                ModalProps={{ className: '!tw-z-[1202]'}}
                PaperProps={{ className: 'tw-min-w-[350px] lg:tw-max-w-[25%] !tw-h-auto tw-min-h-[400px] tw-max-h-screen tw-p-4' }}
            >
                <GenericEditDrawer
                    {...{
                    title: 'Store Hours', 
                    description: 'Choose times during which your restaurant is open to customers or clients.',
                    onClose : () => {setOpen(false);  props.formik.resetForm();}, 
                    actions : (
                        <GenericLoadingButton
                            variant="contained"
                            type="button"
                            fullWidth
                            onClick={props.formik.handleSubmit}
                            size="large"
                            loading={props.loading}
                            loadingPosition="end"
                            // disabled={props.isActionsDisabled}
                        >
                        Save Hours
                    </GenericLoadingButton>
                    ) 
                }}
                >
                    <List disablePadding>
                        {props.formik.values.venue_hours.map((val, index) => {
                            if (val?.venuetimings == 'delivery') {
                                return
                            }
                            const hourDiff = val.opentimehours - val.closedtimehours
                            return (
                                <div className='tw-flex tw-justify-start lg:tw-justify-between tw-items-center tw-w-full tw-border-b tw-border-gray tw-py-2 tw-gap-4'>
                                    <div className='tw-flex tw-items-center'>
                                        <Typography
                                            style={{ color: "black", marginRight: 10, width: 30 }}
                                        >
                                            {gatDayName(val.dayname)}
                                        </Typography>
                                        <div className='tw-flex tw-items-center tw-flex-col'>
                                            <GenericSwitch
                                            background='#0AB221'
                                                checked={val.isclosed == '0'}
                                                onChange={e => {
                                                    handleSwitch(index)
                                                }}
                                            />

                                            <div className={`tw-ml-1 tw-text-[10px] tw-font-medium ${val.isclosed == '0' ? 'tw-text-[#0AB221]' :'tw-text-[#B0B0B0]'}`}>
                                                {val.isclosed == '0' ? "Open" : "Closed"}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='tw-w-full tw-max-w-[400px]'>
                                        {val.isclosed == '0' && (
                                            <div className='tw-flex tw-items-center tw-gap-4'>
                                                <div className='tw-w-full'>
                                                    <div className='tw-text-[10px] tw-font-medium'>Open time</div>
                                                    <GenericTextField
                                                        select
                                                        // defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                                        value={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins || '00'}`}
                                                        onChange={(e) => {
                                                            handleInputChange(e.target.value, index, 'opentimehours', 'opentimemins')
                                                        }}
                                                        variant="outlined"
                                                        className='!tw-w-full'
                                                        sx={{
                                                            [`& .${outlinedInputClasses.input}`]: {
                                                                padding: '6px',
                                                            }                                                    
                                                        }}
                                                    >
                                                        {hours.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </GenericTextField>
                                                </div>

                                                {/* <ArrowForward
                                                    className={styles.arrow}
                                                /> */}
                                                <div className='tw-w-full'>
                                                    <div className='tw-text-[10px] tw-font-medium'>Close time</div>
                                                    <GenericTextField
                                                        select
                                                        // defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                                        className='!tw-w-full'
                                                        value={`${val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:${val.closedtimemins || '00'}`}
                                                        onChange={(e) => {
                                                            handleInputChange(e.target.value, index, 'closedtimehours', 'closedtimemins')
                                                        }}
                                                        variant="outlined"
                                                        sx={{
                                                            [`& .${outlinedInputClasses.input}`]: {
                                                                padding: '6px',
                                                            }                                                    
                                                        }}
                                                    >
                                                        {hours.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </GenericTextField>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </List>
                </GenericEditDrawer>
            </Drawer>
        </div>
    )
}

export default BusinessHours