import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, Typography, Checkbox, Radio } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import BlackCheckbox from '../../../util_comps/BlackCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import LightTooltip from "../../../util_comps/LightTooltip";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";
import { deleteSettingDetail } from "../../../redux/actions/SettingListing";
import { getBanners } from "../../../redux/actions/bannerActions";
import { toast } from "react-toastify";
import { useConfirm } from 'material-ui-confirm';

const BannersList = (props) => {

    const dispatch = useDispatch()
    const confirm = useConfirm();

    const { groupList } = useSelector((state) => state);

    const handleDelete = (groupIdDelete, draggableId) => {
        confirm({
            title: 'Do you wish to confirm to delete a Banner Group',
            description: 'Deleting this banner means losing all its detail'
        })
            .then(() => {
                dispatch(deleteSettingDetail(groupIdDelete))
                    .then(() => {
                        window.location.reload()
                    })

            })
            .catch(() => {

            });
    };

    return (
        <>
            <List
                style={{
                    height: 350,
                    overflow: 'auto',
                    padding: 8
                }}
            >
                {props.itemsToSelect.map((val, i) => {
                    return (
                        <Draggable
                            key={`banner-${val.data.id}`}
                            draggableId={`banner-${val.data.id}`}
                            index={i}
                        >
                            {(provided, snapshot) => {
                                let isSelected = groupList.items.findIndex(i => i?.data.id === parseInt(val.data.id)) !== -1;

                                return (
                                    <ListItem
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            userSelect: "none",
                                            padding: '0 12px',
                                            margin: "0 0 8px 0",
                                            minHeight: "50px",
                                            boxShadow: '0px 3px 6px #0000001A',
                                            borderRadius: '4px',
                                            backgroundColor: 'white',
                                            width: props.snapshot.isDragging ? '50%' : '100%',
                                            ...provided.draggableProps.style
                                        }}
                                    >
                                        {props.isPopup && (
                                            <Radio
                                                onClick={() => props.onProductGroupSelect(val.data.id)}
                                                size="small"
                                            />
                                        )}

                                        {isSelected && <BlackCheckbox
                                            checked={true}
                                            style={{
                                                position: 'absolute',
                                                left: '-21px'
                                            }}
                                        />}
                                        <ListItemText
                                            style={{ lineHeight: 0, width: '100%' }}
                                            primary={
                                                <LightTooltip
                                                    title={
                                                        <span style={{ display: 'inline-block', padding: '0 15px' }}>
                                                            Drag to the right, if you want this banner displayed on your homepage.
                                                        </span>
                                                    } arrow placement="top"
                                                >
                                                    <Typography
                                                        onClick={() => {
                                                            props.setBannerIdForPreview(val.data.id)
                                                            props.setBannerPreview(true)
                                                        }}
                                                        variant="h6"
                                                        style={{
                                                            fontSize: '13px', width: 'fit-content', color: '#707070', textDecoration: 'underline',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {(!Boolean(val.data.group_name) || val.data.group_name == ".") ? "Banner" : val.data.group_name}
                                                    </Typography>
                                                </LightTooltip>
                                            }
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'baseline'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    alt="Banner"
                                                    style={{
                                                        height: 18,
                                                        width: 80,
                                                        objectFit: "contain",
                                                        backgroundColor: 'black'
                                                    }}
                                                    src={val.data.image}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    marginLeft: '6px'
                                                }}
                                            >
                                                <IconButton
                                                    style={{
                                                        padding: '5px',
                                                    }}
                                                    onClick={() => {
                                                        props.setIsBannerEdit(true)
                                                        props.setShowAddBanner(true)
                                                        props.setBannerIdForEdit(val.data.id)
                                                    }}
                                                    disabled={props.isActionsDisabled}
                                                >
                                                    <Edit
                                                        style={{
                                                            color: 'black',
                                                            fontSize: '15px'
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    style={{
                                                        padding: '5px'
                                                    }}
                                                    onClick={() => {
                                                        handleDelete(val.data.id, val.draggableId)
                                                    }}
                                                    disabled={props.isActionsDisabled}
                                                >
                                                    <DeleteForever
                                                        style={{
                                                            color: 'black',
                                                            fontSize: '15px'
                                                        }}
                                                    />
                                                </IconButton>
                                            </div>

                                        </div>
                                    </ListItem>
                                )

                            }}
                        </Draggable>
                    );
                })}
            </List>
        </>
    );
};

export default BannersList;
