import React, { useEffect, useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import styles from './EditMenu.module.scss'
import { Dialog, DialogContent } from '@mui/material';
import EditMenuDialog from '../Dialogs/EditMenuDialog/EditMenuDialog';
import { useOutletContext } from 'react-router-dom';
import EditableHeader from './EditableHeader/EditableHeader';

const EditMenu = () => {

  const { menu, categoryMenuBaseImageLink, menuBaseImageLink } = useOutletContext()

  const [showEdit, setShowEdit] = useState(false)
  const [editableMenu, setEditableMenu] = useState([])

  useEffect(() => {
    setEditableMenu(menu)
  }, [menu])


  return (
    <div>
      <div
        className={styles.edit_menu_text}
      >
        Edit Menu
      </div>

      <div
        className={styles.header_container}
      >
        <EditableHeader
          menu={editableMenu}
          categoryMenuBaseImageLink={categoryMenuBaseImageLink}
          menuBaseImageLink={menuBaseImageLink}
          setShowEdit={setShowEdit}
        />
      </div>

      <div
        className={styles.list_icon_container}
      >
        <ListIcon
          style={{
            fontSize: 80
          }}
        />
        <div
          style={{
            fontSize: 30
          }}
        >
          Click on the menu item above to make changes
        </div>
      </div>

      <Dialog
        open={showEdit}
        onClose={() => {
          setShowEdit(false)
        }}
      >
        <EditMenuDialog
        onClose={() => {
          setShowEdit(false)
        }}
        />
      </Dialog>
    </div>
  )
}

export default EditMenu