import React from 'react'
import styles from './PreviewPost.module.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import { getImage } from '../Util/helpers';

const PreviewPost = (props) => {

    return (
        <div
            className={styles.preview_container}
        >

            <Swiper
                style={{
                    width: '100%'
                }}
                navigation={true}
                // loop={true}
                centeredSlides={true}
                observeParents={true}
                observer={true}
                spaceBetween={5}
            >
                {Boolean(props.platformName.filter((data) => data.isInstagram).length) && (
                    <SwiperSlide
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <h4
                            className={styles.template_title}
                        >
                            <img
                                style={{
                                    marginRight: '6px'
                                }}
                                src={getImage('instagram.png-1699456353.png')}
                                height={25}
                            />
                            Preview Instagram
                        </h4>
                        <div
                            className={styles.preview_image_container}
                        >
                            <img
                                className={styles.preview_template}
                                src={getImage('instagram-template.png-1699462092.png')}
                            />

                            {(props.file[0] || props.link) && (
                                <>
                                    {props.file[0].isVideo ? (
                                        <video
                                            className={styles.preview_image}
                                            src={props.file[0] ? props.file[0].image : props.link}
                                            controls
                                        />
                                    ) : (
                                        <>
                                            <img
                                                className={styles.preview_image}
                                                src={props.file[0] ? props.file[0].image : props.link}
                                            />
                                        </>
                                    )}
                                </>
                            )}

                            <div
                                className={styles.preview_caption}
                            >
                                {props.caption}
                            </div>
                        </div>
                    </SwiperSlide>
                )}

                {Boolean(props.platformName.filter((data) => data.isFacebook).length) && (
                    <SwiperSlide
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <h4
                            className={styles.template_title}
                        >
                            <FaFacebook
                                style={{
                                    marginRight: '6px'
                                }}
                                color="#4267B2"
                            />
                            Preview Facebook
                        </h4>
                        <div
                            className={styles.preview_image_container}
                        >
                            <img
                                className={styles.preview_template}
                                src={getImage('facebook-template.png-1699462185.png')}
                            />

                            {(props.file[0] || props.link) && (
                                <>
                                    {props.file[0].isVideo ? (
                                        <video
                                            className={styles.preview_image}
                                            src={props.file[0] ? props.file[0].image : props.link}
                                            controls
                                        />
                                    ) : (
                                        <>
                                            <img
                                                className={styles.preview_image}
                                                src={props.file[0] ? props.file[0].image : props.link}
                                            />
                                        </>
                                    )}
                                </>
                            )}

                            <div
                                className={styles.preview_caption}
                            >
                                {props.caption}
                            </div>
                        </div>
                    </SwiperSlide>
                )}

                {Boolean(props.platformName.filter((data) => data.isTwitter).length) && (
                    <SwiperSlide
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <h4
                            className={styles.template_title}
                        >
                            <FaTwitter
                                style={{
                                    marginRight: '6px'
                                }}
                                color="#4267B2"
                            />
                            Preview Twitter
                        </h4>
                        <div
                            className={styles.preview_image_container}
                        >
                            <div
                                style={{
                                    padding: 20
                                }}
                            >
                                <img
                                    className={styles.preview_template}
                                    src={getImage('twitter-template.png-1699462230.png')}
                                />
                            </div>

                            {(props.file[0] || props.link) && (
                                <>
                                    {props.file[0].isVideo ? (
                                        <video
                                            className={styles.preview_image}
                                            src={props.file[0] ? props.file[0].image : props.link}
                                            controls
                                        />
                                    ) : (
                                        <>
                                            <img
                                                className={styles.preview_image}
                                                src={props.file[0] ? props.file[0].image : props.link}
                                            />
                                        </>
                                    )}
                                </>
                            )}

                            <div
                                className={styles.preview_caption_twitter}
                            >
                                {props.caption}
                            </div>
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    )
}

export default PreviewPost