import { Dialog, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GetWebsiteLive from '../../../../Dialogs/GetWebsiteLive/GetWebsiteLive'
import Const from '../../../../helpers/const'
import GenericTextFieldWithLabel from '../../../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import styles from './MicroWebsite.module.scss'
import axios, { nodeAxios } from '../../../../../utils/axiosConfig'

const MicroWebsite = (props) => {

    const [showGetWebsite, setShowGetWebsite] = useState(false)
    const [isCustomDomain, setIsCustomDomain] = useState(false)
    const [subDomain, setSubDomain] = useState('')

    const fetchSubdomains = () => {
        nodeAxios.get('/web-builder/web-page/domains').then((response) => {
            if (Boolean(response.data?.data?.subdomain)) {
                if (Boolean(response.data?.data?.subdomain)) {
                    if (response.data?.data?.custom_domain) {
                        setIsCustomDomain(true);
                        setSubDomain(
                            `https://${response.data?.data?.custom_domain}`
                        );
                    } else {
                        setIsCustomDomain(false);
                        setSubDomain(
                            `https://${response.data?.data?.subdomain}.esplanda.com`
                        );
                    }
                }
            }
        });
    };

    useEffect(() => {
      fetchSubdomains()
    
    }, [])
    


    return (
        <div
            className={styles.container}
        >

            {isCustomDomain ? (
                <div>
                    <span
                        style={{
                            marginRight: 4
                        }}
                    >
                        Your website
                    </span>
                    <a
                        style={{
                            textDecoration: 'underline'
                        }}
                        href={subDomain}
                        target='_blank'
                    >
                        {subDomain}
                    </a>
                    <span
                        style={{
                            marginLeft: 4
                        }}
                    >
                        is connected with us.
                    </span>
                </div>
            ) : (
                <>

                    <div>
                        <div
                            className={styles.micro_website_text}
                        >
                            Your branded micro website is:
                        </div>
                        <a
                            style={{
                                textDecoration: 'underline'
                            }}
                            href={subDomain}
                            target='_blank'
                        >
                            {subDomain}
                        </a>
                    </div>

                    <Divider
                        style={{
                            backgroundColor: '#FF0000'
                        }}
                    />

                    {/* <div>
                        <GenericTextFieldWithLabel
                            label="Have your own website, Enter it here"
                            value={props.externalWebsite}
                            onChange={(event) => {
                                props.setExternalWebsite(event.target.value)
                            }}
                        />
                        <div
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setShowGetWebsite(true)
                            }}
                        >
                            How to Get your website live
                        </div>
                    </div> */}
                </>
            )}

            <Dialog
                open={showGetWebsite}
                onClose={() => {
                    setShowGetWebsite(false)
                }}
            >
                <GetWebsiteLive
                    onClose={() => {
                        setShowGetWebsite(false)
                    }}
                />
            </Dialog>
        </div>
    )
}

export default MicroWebsite