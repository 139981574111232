import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import axios, { nodeAxios } from '../../Util/axiosConfig';
import styles from './Blogs.module.scss';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import Loader from '../../../Library/Loader/Loader';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import BlogsTable from './BlogsTable/BlogsTable';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { Add } from '@mui/icons-material';


const Blogs = (props) => {
  console.log('🚀OUTPUT --> props:', props);
  const navigate = useNavigate()

  // const { helpPage, showHelpPage } = useContext(HelpPageContext);

  // const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState('');
  const [blogCategoryId, setBlogCategoryId] = useState('');
  const [showBlogs, setShowBlogs] = useState('All');
  const [sortByField, setSortByField] = useState('');
  const [blogs, setBlogs] = useState(null);
  console.log('🚀OUTPUT --> blogs:', blogs);

  const [pageNo, setPageNo] = useState(blogs?.pagination?.current_page ?? 1);

  const fetchBlogs = (params) => {
    setBlogs(null);
    nodeAxios
      .get('/web-builder/public/blog/v2', { params : { ...params, identifier: localStorage.getItem("venue_id")} }) // web-builder/blog
      .then((response) => {
        // console.log(response)
        setBlogs({
          data: response.data.data?.blogs,
          subdomain: response.data.data?.subdomain,
          pagination: response.data.pagination,
        });
      })
      .catch((e) => {
        console.log(e);
        setBlogs({ data: [] });
        toast.error(e?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchBlogs();
    // dispatch(getFamilyMembersAction('Family'));
  }, []);

  const handleSearchChange = (event) => {
    let params = { page: pageNo };
    if (event.target.value) {
      params.title = event.target.value;
    }
    if (blogCategoryId) {
      params['category_id[0]'] = blogCategoryId;
    }
    if (sortByField) {
      params['sort-by'] = sortByField;
    }
    if (showBlogs || showBlogs === 0) {
      params['status'] = showBlogs;
    }
    fetchBlogs(params);

    // dispatch(getChildrenAction({ page: props.page, params }));
  };

  const handleChange = (event) => {
    console.log('🚀OUTPUT --> event.target.value:', event.target.value);
    setBlogCategoryId(event.target.value);
    let params = { page: pageNo };
    if (event.target.value) {
      params['category_id'] = event.target.value;
    }
    if (searchQuery) {
      params.title = searchQuery;
    }
    if (showBlogs || showBlogs === 0) {
      params['status'] = showBlogs;
    }
    if (sortByField) {
      params['sort-by'] = sortByField;
    }
    fetchBlogs(params);
  };

  const handleChangeShowBlogs = (event) => {
    setShowBlogs(event.target.value);
    let params = { page: pageNo };
    if (event.target.value || event.target.value === 0) {
      params['status'] = event.target.value;
    }
    if (searchQuery) {
      params.title = searchQuery;
    }
    if (blogCategoryId) {
      params['category_id'] = blogCategoryId;
    }
    if (sortByField) {
      params['sort-by'] = sortByField;
    }
    fetchBlogs(params);
  };

  const handleSortByField = (name) => {
    setSortByField(name);
    let params = { page: pageNo };
    if (name) {
      params['sort-by'] = name;
    }
    if (searchQuery) {
      params.title = searchQuery;
    }
    if (blogCategoryId) {
      params['category_id'] = blogCategoryId;
    }
    if (showBlogs || showBlogs === 0) {
      params['status'] = showBlogs;
    }
    fetchBlogs(params);
  };

  const handleChangePage = (page_no) => {
    let params = { page: page_no };

    fetchBlogs(params);
  };

  const debounceSearch = useCallback(debounce(handleSearchChange, 1000), []);

  return (
    <div className={clsx(styles.children_list)}>
      <div className={styles.children_list_header}>
        <div className={styles.children_title}>Blogs</div>

        <Button
          // classes={{ root: styles.record_btn }}
          variant='contained'
          color='primary'
          onClick={() => navigate('/website-builder/blogs/create')}
        >
          <Add />
          Add Blog
        </Button>
      </div>

      {/* {showHelpPage ? (
        helpPage
      ) : ( */}
      <>
        <div className={styles.searh_section_container}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={styles.searh_input_container}>
              <svg
                width='15'
                height='15'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z'
                  stroke='#919191'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M18.9999 18.9999L14.6499 14.6499'
                  stroke='#919191'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>

              <TextField
                InputProps={{
                  classes: {
                    notchedOutline: styles.input_box_style,
                    input: styles.input_element,
                  },
                }}
                variant='outlined'
                placeholder={`Search post`}
                value={searchQuery}
                onChange={(event) => {
                  debounceSearch(event);
                  setSearchQuery(event.target.value);
                }}
              />
            </div>
            <div
              style={{
                fontSize: '12px',
                marginLeft: '1rem',
              }}
            >
              Show:{' '}
            </div>
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                background: 'var(--bg-8)',
                borderRadius: '20px',
                border: 'none',
              }}
              size='small'
            >
              <Select
                value={showBlogs}
                label='Sort By'
                onChange={handleChangeShowBlogs}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '0 solid !important',
                  },
                  color: '#3A3A3AD6',
                  fontSize: '12px',
                }}
                IconComponent={ExpandMoreOutlinedIcon}
              >
                <MenuItem value={'All'}>
                  <div className={styles.studentNameContainer}>
                    <span>All</span>
                  </div>
                </MenuItem>
                <MenuItem value={1}>
                  <div className={styles.studentNameContainer}>
                    <span>Published</span>
                  </div>
                </MenuItem>
                <MenuItem value={0}>
                  <div className={styles.studentNameContainer}>
                    <span>Un Published</span>
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
            <div
              style={{
                fontSize: '12px',
                marginLeft: '1rem',
              }}
            >
              Categories:{' '}
            </div>
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                background: 'var(--bg-8)',
                borderRadius: '20px',
                border: 'none',
              }}
              size='small'
            >
              <Select
                value={blogCategoryId}
                label='Sort By'
                onChange={handleChange}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '0 solid !important',
                  },
                  color: '#3A3A3AD6',
                  fontSize: '12px',
                }}
                IconComponent={ExpandMoreOutlinedIcon}
              >
                <MenuItem value={''}>
                  <div className={styles.studentNameContainer}>
                    <span>Select</span>
                  </div>
                </MenuItem>
                {props.blogCategories?.data?.map((blogCategory) => (
                  <MenuItem value={blogCategory.id}>
                    <div className={styles.studentNameContainer}>
                      <span>{blogCategory.title}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <BlogsTable
          // getChildren={getChildren}
          pageNo={blogs?.pagination?.current_page ?? 1}
          totalPages={blogs?.pagination?.last_page ?? 1}
          handleChangePage={handleChangePage}
          blogs={blogs}
          handleSortByField={handleSortByField}
          sortByField={sortByField}
          fetchBlogs={fetchBlogs}
          {...props}
        />
      </>
      {/* )} */}
    </div>
  );
};

export default Blogs;
