import React, { useCallback, useContext, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, Dialog, ListItemIcon, Menu, MenuItem, Pagination, Radio, Select, Typography } from '@mui/material';
import { Add, ChevronRight, Description, DragIndicator, ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, Link, LocalOfferOutlined, Search, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import styles from './RestaurantsInventory.module.scss'
import AddNewProductDialog from './AddNewProductDialog/AddNewProductDialog';
import axios from '../../utils/axiosConfig'
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import LightTooltip from '../../util_comps/LightTooltip';
import MarketingContext from '../Marketing2/Util/marketingContext';
import InputField from '../Library/InputField/InputField';
import { debounce } from 'lodash';
import Loader from '../Library/Loader';
import { useNavigate } from 'react-router-dom';
import routePaths from '../../config/route-paths';
import AddModifierDialog from './AddModifierDialog/AddModifierDialog';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import AddPropertiesDialog from '../Properties/AddPropertiesDialog/AddPropertiesDialog';
import { reorder } from '../CustomPageBuilder/EditSection/Util/helpers';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import GenericSwitch from '../Library/GenericSwitch/GenericSwitch';

const columns = [
    {
        id: 'category_name',
        label: 'Category',
    },
    {
        id: 'ProductName',
        sortid: 'product_name',
        label: 'Product',
    },
    {
        id: 'Price',
        sortid: 'price',
        label: 'Price',
        format: (value) => `$${parseFloat(value)?.toFixed(2)}`
    },
    {
        id: 'stockqty',
        sortid: 'quantity',
        label: 'Quantity',
        format: (value, isTrack) => isTrack ? value : ' '
    }
];

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle
});

const RestaurantsInventory = ({ setActiveModal }) => {

    const { subDomain } = useContext(MarketingContext)
    const navigate = useNavigate()

    const confirm = useConfirm()
    const [showAddNewProduct, setShowAddNewProduct] = useState(false)
    const [showAddModifier, setShowAddModifier] = useState(false)
    const [showAddProperties, setShowAddProperties] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState({})
    const [editObj, setEditObj] = useState({})
    const [editModifierObj, setEditModifierObj] = useState({})
    const [options, setOptions] = useState({})
    const [searchStr, setSearchStr] = useState("");
    const [sort, setSort] = useState({ sort_by: 'sequence_id', sort_by_order: 'ASC' })
    const [catId, setCatId] = useState(0)
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isEdit2Loading, setIsEdit2Loading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const [selectedCatName, setSelectedCatName] = useState('')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchMyList = (page, queryStr) => {
        setIsListLoading(true)
        axios.get('/product/my-list', {
            params: {
                page: page || 1,
                q: queryStr || searchStr,
                sort_by: sort.sort_by,
                sort_by_order: sort.sort_by_order,
                category_id: catId || undefined,
                limit: Boolean(catId) ? 100 : undefined
            }
        })
            .then((res) => {
                setProducts(res.data?.data?.result)
                setPagination(res.data?.data?.pagination)
            })
            .finally(() => {
                setIsSearchLoading(false)
                setIsListLoading(false)
            })
    }

    const handleSearch = (searchStr) => {
        try {
            setIsSearchLoading(true)
            fetchMyList(pagination.current_page, searchStr)
        } catch (err) {
            toast.error('Error occured while fetching inventories, please try again.')
        };
    };

    const debounceSearch = useCallback(debounce(handleSearch, 1000), [])


    useEffect(() => {
        fetchMyList()
    }, [])

    useEffect(() => {
        fetchMyList(pagination.current_page)
    }, [sort, catId])


    const handleDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete product',
        })
            .then(() => {
                axios.delete(`/venue-product/delete/${id}`)
                    .then(() => {
                        fetchMyList()
                        toast.success('Product deleted')
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
            })
    }

    const handleEdit2 = (id) => {
        setIsEdit2Loading(true)
        setShowAddNewProduct(true)
        axios.get(`/venue-product/get/${id}`)
            .then((res) => {
                setEditObj(res.data?.data?.result)
                setIsEdit(true)
                // setShowAddNewProduct(true)
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setIsEdit2Loading(false)
            })
    }

    const handleCopyUrl = (data) => {
        navigator.clipboard
            .writeText(
                `${subDomain}/product/${data?.ProductSlug}/${data?.ProductId}`
            )
            .then(() => {
                toast.success('Url is copied successfully')
            });
    };

    const handleChangeVisibility = (data) => {

        if (!(options[data.id]?.noWebSales != undefined ? options[data.id]?.noWebSales : (data.noWebSales === 0))) {
            axios.get(`/product/visibility/${data.id}`, {
                params: {
                    product_id: data.ProductId
                }
            })
                .then((res) => {
                    toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
                    setOptions((prevState) => ({
                        ...prevState,
                        [data.id]: {
                            ...prevState[data.id],
                            noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
                        }
                    }))
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
        } else {
            confirm({
                title: `Are you sure you want to disable this product`,
            })
                .then(() => {
                    axios.get(`/product/visibility/${data.id}`, {
                        params: {
                            product_id: data.ProductId
                        }
                    })
                        .then((res) => {
                            toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
                            setOptions((prevState) => ({
                                ...prevState,
                                [data.id]: {
                                    ...prevState[data.id],
                                    noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
                                }
                            }))
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.message)
                        })
                })
                .catch(() => {

                })
        }
    };

    const handleEdit = (name, value, data) => {
        if (name === "limited") {
            if (value < 0) {
                return;
            }

            axios.post(`/product/update-my-product/${data.id}`, {
                id: data.id,
                limited: value
            })
                .then(() => {
                    toast.success('Updated')
                })
            setOptions((prevState) => ({
                ...prevState,
                [data.id]: {
                    ...prevState[data.id],
                    limited: value
                }
            }))
        }
    };

    const fetchVenueCatMenu = () => {
        axios.get('/venue-category-order', {
            params: {
                only_if_product_exists: 1
            }
        })
            .then((res) => {
                setVenueCategoryMenu(res.data?.data?.results)
            })
    }

    useEffect(() => {
        fetchVenueCatMenu()
    }, [])

    const getTreeItem = (child) => {
        return child?.map((data) => (
            <TreeItem icon={(
                <Radio
                    checked={data.category_id == catId}
                    onChange={(event) => {
                        setSelectedCatName(data.category_name)
                        setCatId(event.target.value)
                    }}
                    value={data.category_id}
                />
            )}
                nodeId={data.category_id}
                label={<div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8
                    }}
                >
                    <div>
                        {data.category_name}
                    </div>

                    {Boolean(data?.child?.length) && (
                        <KeyboardArrowRight />
                    )}
                </div>}
            >
                {Boolean(data?.child?.length) && getTreeItem(data?.child)}
            </TreeItem>
        ))
    }

    const handleSetsequence = (items) => {
        let payload = items.map(element => element.id)
        // items?.forEach((element, index) => {
        //     payload[index] = element?.id
        // });

        axios.post('/venue-product/set-sequence', {
            venue_product_ids: payload
        })
            .then(() => {

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            products,
            result.source.index,
            result.destination.index
        );

        setProducts(newitems);
        handleSetsequence(newitems)
    };


    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.heading_container}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: 35,
                        whiteSpace: 'nowrap'
                    }}
                >

                    <Button
                        variant="text"
                        endIcon={((sort.sort_by === 'created_at') && (sort.sort_by_order === 'DESC')) ? (
                            <KeyboardArrowDown />
                        ) : (
                            <KeyboardArrowUp />
                        )}
                        onClick={() => {
                            if (sort.sort_by_order === 'ASC') {
                                setSort({ ...sort, sort_by_order: 'DESC' })
                            } else {
                                setSort({ ...sort, sort_by_order: 'ASC' })
                            }
                        }}
                    >
                        Recently Added
                    </Button>

                    <InputField
                        placeholder="Search by name, Barcode#"
                        value={searchStr}
                        onChange={(e) => {
                            setSearchStr(e.target.value)
                            debounceSearch(e.target.value)
                        }}
                        onIconButtonClick={e => debounceSearch(searchStr)}
                        showIconButton={true}
                        endIcon={isSearchLoading ? (
                            <CircularProgress
                                size={16}
                                style={{
                                    color: '#ef2d2c'
                                }}
                            />
                        ) : (
                            <Search
                                style={{
                                    color: '#ef2d2c'
                                }}
                            />
                        )}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10
                    }}
                >
                    {/* <Button
                        variant="contained"
                        startIcon={<Add />}
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            borderRadius: 20
                        }}
                        onClick={() => {
                            navigate(routePaths.categoryMapping)
                        }}
                    >
                        Manage category
                    </Button> */}

                    {/* <Button
                        variant="contained"
                        startIcon={<Add />}
                        style={{
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: 20
                        }}
                        onClick={() => {
                            setShowAddModifier(true)
                            // navigate(routePaths.categoryMapping)
                        }}
                    >
                        Add modifier
                    </Button> */}

                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        style={{
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: 20
                        }}
                        onClick={() => {
                            setShowAddNewProduct(true)
                        }}
                    >
                        Add Product
                    </Button>
                </div>
            </div>

            {isListLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, cursor: 'pointer' }}
                                            onClick={() => {
                                                if (column.sortid) {
                                                    if (sort.sort_by === column.sortid) {
                                                        if (sort.sort_by_order === 'ASC') {
                                                            setSort({ ...sort, sort_by_order: 'DESC' })
                                                        } else {
                                                            setSort({ ...sort, sort_by_order: 'ASC' })
                                                        }
                                                    } else {
                                                        setSort({ sort_by_order: 'DESC', sort_by: column.sortid })
                                                    }
                                                }
                                            }}

                                            sx={{
                                                [`&.${tableCellClasses.head}`]: {
                                                    backgroundColor: '#F0F0F0',
                                                    color: 'black',
                                                    fontWeight: 600
                                                },
                                            }}
                                        >
                                            {(column.id == 'category_name') ? (
                                                <>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            overflow: 'auto',
                                                            padding: 5,
                                                            outline: '1px solid #d3d3d3',
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        <Button
                                                            aria-controls={open2 ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open2 ? 'true' : undefined}
                                                            onClick={handleClick2}
                                                            fullWidth={true}
                                                            endIcon={<KeyboardArrowDown />}
                                                        >
                                                            {selectedCatName || "Select Category"}
                                                        </Button>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl2}
                                                            open={open2}
                                                            onClose={handleClose2}
                                                            slotProps={{
                                                                paper: {
                                                                    elevation: 0,
                                                                    sx: {
                                                                        filter: 'none',
                                                                        border: '1px solid #D3D3D3',
                                                                        height: 250
                                                                    },
                                                                }
                                                            }}
                                                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                                        >
                                                            <TreeView
                                                                defaultCollapseIcon={<ExpandMore />}
                                                                defaultExpandIcon={<ChevronRight />}
                                                            // expanded={props.isEdit}
                                                            >
                                                                <TreeItem
                                                                    icon={(
                                                                        <Radio
                                                                            checked={0 == catId}
                                                                            onChange={(event) => {
                                                                                setSelectedCatName('')
                                                                                setCatId(0)
                                                                                handleClose2()
                                                                            }}
                                                                            value={0}
                                                                        />
                                                                    )}
                                                                    nodeId={0}
                                                                    label={(
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                gap: 8
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                All
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                />
                                                                {venueCategoryMenu?.map((data) => (
                                                                    <TreeItem
                                                                        icon={(
                                                                            <Radio
                                                                                checked={data.category_id == catId}
                                                                                onChange={(event) => {
                                                                                    setSelectedCatName(data.category_name)
                                                                                    setCatId(event.target.value)
                                                                                    handleClose2()
                                                                                }}
                                                                                value={data.category_id}
                                                                            />
                                                                        )}
                                                                        nodeId={data.category_id}
                                                                        label={(
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    gap: 8
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    {data.category_name}
                                                                                </div>

                                                                                {Boolean(data?.child?.length) && (
                                                                                    <KeyboardArrowRight />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    >
                                                                        {getTreeItem(data?.child)}
                                                                    </TreeItem>
                                                                ))}
                                                            </TreeView>
                                                        </Menu>
                                                    </div>
                                                </>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: 5,
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <div>
                                                        {column.label}
                                                    </div>

                                                    {((sort.sort_by === column.sortid) && (sort.sort_by_order === 'DESC')) ? (
                                                        <KeyboardArrowUp />
                                                    ) : (
                                                        <KeyboardArrowDown />
                                                    )}
                                                </div>
                                            )}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    >
                                        <div>
                                            Enabled
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    />
                                    {/* <TableCell
                                        sx={{
                                            [`&.${tableCellClasses.head}`]: {
                                                backgroundColor: '#F0F0F0',
                                                color: 'black',
                                                fontWeight: 600
                                            },
                                        }}
                                    /> */}
                                </TableRow>
                            </TableHead>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <TableBody
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        // style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {products.map((row, index) => (
                                                <Draggable
                                                    key={row.id}
                                                    draggableId={"q-" + row.id}
                                                    index={index}
                                                    isDragDisabled={!Boolean(catId)}
                                                >
                                                    {(provided, snapshot) => (
                                                        <TableRow
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            sx={{
                                                                '&:nth-of-type(even)': {
                                                                    backgroundColor: '#F7F5F7',
                                                                },
                                                                // hide last border
                                                                '&:last-child td, &:last-child th': {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            {columns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        <div
                                                                            className={(column?.id == 'ProductName') && styles.product_name}
                                                                            onClick={() => {
                                                                                if ((column?.id == 'ProductName')) {
                                                                                    handleEdit2(row.id)
                                                                                }
                                                                            }}
                                                                        >
                                                                            {column.format ? column.format(value, row.track_quantity) : value}
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            })}

                                                            <TableCell>
                                                                <GenericSwitch
                                                                    background="#00C57E"
                                                                    width={60}
                                                                    height={24}
                                                                    translateX={38}
                                                                    thumb={19}
                                                                    isStatus={true}
                                                                    checked={(options[row.id]?.noWebSales != undefined ? options[row.id]?.noWebSales : (row.noWebSales === 0))}
                                                                    onText="'On'"
                                                                    offText="'Off'"
                                                                    onChange={(event) => {
                                                                        handleChangeVisibility(row)
                                                                    }}
                                                                />
                                                            </TableCell>

                                                            <TableCell>
                                                                <Button
                                                                    variant="text"
                                                                    onClick={() => {
                                                                        handleEdit2(row.id)
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </TableCell>

                                                            <TableCell>
                                                                <Button
                                                                    style={{
                                                                        color: 'red'
                                                                    }}
                                                                    variant="text"
                                                                    onClick={() => {
                                                                        handleDelete(row.id)
                                                                    }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </TableCell>

                                                            {/* <TableCell>
                                                                <Button
                                                                    id="basic-button"
                                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    onClick={handleClick}
                                                                >
                                                                    Actions
                                                                </Button>
                                                                <Menu
                                                                    id="basic-menu"
                                                                    anchorEl={anchorEl}
                                                                    open={open}
                                                                    onClose={handleClose}
                                                                    slotProps={{
                                                                        paper: {
                                                                            elevation: 0,
                                                                            sx: {
                                                                                overflow: 'visible',
                                                                                filter: 'none',
                                                                                border: '1px solid #D3D3D3',
                                                                                mt: 1.5,
                                                                                '& .MuiAvatar-root': {
                                                                                    width: 32,
                                                                                    height: 32,
                                                                                    ml: -0.5,
                                                                                    mr: 1,
                                                                                },
                                                                                '&:before': {
                                                                                    content: '""',
                                                                                    display: 'block',
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    right: 14,
                                                                                    width: 10,
                                                                                    height: 10,
                                                                                    bgcolor: 'background.paper',
                                                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                                                    zIndex: 0,
                                                                                    borderTop: '1px solid #D3D3D3',
                                                                                    borderLeft: '1px solid #D3D3D3'
                                                                                },
                                                                            },
                                                                        }
                                                                    }}
                                                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                                >
                                                                    <div>
                                                                        <MenuItem>
                                                                            <div
                                                                                onClick={(e) => {
                                                                                    setActiveModal({
                                                                                        name: "addtag",
                                                                                        props: {
                                                                                            productName: row.ProductName,
                                                                                            productId: row.ProductId,
                                                                                        },
                                                                                    })
                                                                                }}
                                                                                style={{
                                                                                    marginRight: "23px",
                                                                                    fontSize: "20px",
                                                                                    cursor: false ? 'text' : 'pointer',
                                                                                    opacity: false ? 0.3 : 1,
                                                                                    color: "#7C8798"
                                                                                }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Add to collection.</span>} arrow placement="top">
                                                                                        <LocalOfferOutlined fontSize="inherit" color="inherit" />
                                                                                    </LightTooltip>
                                                                                </ListItemIcon>

                                                                                Add to collection
                                                                            </div>
                                                                        </MenuItem>

                                                                        <MenuItem>
                                                                            <div
                                                                                onClick={(e) => {
                                                                                    handleCopyUrl(row)
                                                                                }}
                                                                                style={{
                                                                                    marginRight: "23px",
                                                                                    fontSize: "20px",
                                                                                    cursor: false ? 'text' : 'pointer',
                                                                                    opacity: false ? 0.3 : 1,
                                                                                    color: "#7C8798",
                                                                                }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Copy this product url to clipboard.</span>} arrow placement="top">
                                                                                        <Link fontSize="inherit" color="inherit" />
                                                                                    </LightTooltip>
                                                                                </ListItemIcon>

                                                                                Copy Link
                                                                            </div>
                                                                        </MenuItem>

                                                                        <MenuItem>
                                                                            <LightTooltip
                                                                                interactive
                                                                                arrow
                                                                                placement="top"
                                                                                title={
                                                                                    <div>
                                                                                        <div
                                                                                            style={{
                                                                                                color: "black",
                                                                                                display: 'flex',
                                                                                                justifyContent: 'flex-end',
                                                                                                fontSize: "0.7em",
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{ cursor: "pointer" }}
                                                                                                onClick={(e) => handleEdit("limited", 0, row)}
                                                                                            >Clear filters</span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                padding: "0px 10px 0 0",
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    fontSize: "21px",
                                                                                                    color: "black",
                                                                                                    marginRight: "10px",
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    onClick={(e) => {
                                                                                                        handleEdit("limited", ((options[row.id]?.limited != undefined) ? (options[row.id]?.limited) : (row.limited)) + 1, row)
                                                                                                    }}
                                                                                                >
                                                                                                    <ExpandLess
                                                                                                        color="inherit"
                                                                                                        fontSize="inherit"
                                                                                                        style={{
                                                                                                            position: "relative",
                                                                                                            top: "6px",
                                                                                                            cursor: false ? 'text' : 'pointer',
                                                                                                            opacity: false ? 0.3 : 1,
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    onClick={(e) => {
                                                                                                        handleEdit("limited", ((options[row.id]?.limited != undefined) ? (options[row.id]?.limited) : (row.limited)) - 1, row)
                                                                                                    }}
                                                                                                >
                                                                                                    <ExpandMore
                                                                                                        color="inherit"
                                                                                                        fontSize="inherit"
                                                                                                        style={{
                                                                                                            cursor: false ? 'text' : 'pointer',
                                                                                                            opacity: false ? 0.3 : 1,
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                onBlur={(e) => {
                                                                                                    if (e.currentTarget.textContent && parseInt(e.currentTarget.textContent) > 0) {
                                                                                                        handleEdit(
                                                                                                            "limited",
                                                                                                            parseInt(e.currentTarget.textContent),
                                                                                                            row
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                contentEditable
                                                                                                style={{ fontSize: "1rem" }}
                                                                                            >
                                                                                                {(options[row.id]?.limited != undefined) ? (options[row.id]?.limited || "None") : (row.limited || "None")}
                                                                                            </div>
                                                                                            <Typography
                                                                                                variant="h6"
                                                                                                style={{ fontSize: ".7rem", marginLeft: "10px" }}
                                                                                            >
                                                                                                Limit
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        marginRight: "23px",
                                                                                        fontSize: "20px",
                                                                                        cursor: false ? 'text' : 'pointer',
                                                                                        opacity: false ? 0.3 : 1,
                                                                                        color: "#7C8798",
                                                                                    }}
                                                                                >
                                                                                    <ListItemIcon>
                                                                                        <Description fontSize="inherit" color="inherit" />
                                                                                    </ListItemIcon>

                                                                                    Limit
                                                                                </div>
                                                                            </LightTooltip>
                                                                        </MenuItem>

                                                                        <MenuItem>
                                                                            <div
                                                                                onClick={() => {
                                                                                    handleChangeVisibility(row)
                                                                                }}
                                                                                style={{
                                                                                    marginRight: "23px",
                                                                                    fontSize: "20px",
                                                                                    cursor: false ? 'text' : 'pointer',
                                                                                    opacity: false ? 0.3 : 1,
                                                                                    color: "#7C8798",
                                                                                }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    {(options[row.id]?.noWebSales != undefined ? options[row.id]?.noWebSales : (row.noWebSales === 0)) ? (
                                                                                        <VisibilityOutlined fontSize="inherit" color="inherit" />
                                                                                    ) : (
                                                                                        <VisibilityOffOutlined
                                                                                            style={{
                                                                                                color: '#ef2d2c'
                                                                                            }}
                                                                                            fontSize="inherit"
                                                                                            color="inherit"
                                                                                        />
                                                                                    )}
                                                                                </ListItemIcon>

                                                                                Change Visibility
                                                                            </div>
                                                                        </MenuItem>
                                                                    </div>
                                                                </Menu>
                                                            </TableCell> */}



                                                            <TableCell>
                                                                {Boolean(catId) && (
                                                                    <DragIndicator />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {/* </TableBody> */}
                        </Table>
                    </TableContainer>

                    {!Boolean(products?.length) && (
                        <div
                            style={{
                                textAlign: 'center',
                                padding: 15,
                                fontSize: 20,
                                fontWeight: 600
                            }}
                        >
                            No Products Found
                        </div>
                    )}
                    {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}

                    {(pagination.last_page > 1) && (
                        <div
                            style={{
                                padding: '10px 0'
                            }}
                        >
                            <Pagination
                                page={pagination.current_page}
                                count={pagination.last_page}
                                onChange={(e, page) => fetchMyList(page)}
                            />
                        </div>
                    )}
                </Paper>
            )}

            <Dialog
                open={showAddNewProduct}
                onClose={() => {
                    setShowAddNewProduct(false)
                    fetchMyList()
                    setIsEdit(false)
                    setEditObj({})
                }}
                PaperProps={{
                    style: {
                        width: '100%'
                    }
                }}
                keepMounted={false}
            >
                <AddNewProductDialog
                    isEdit2Loading={isEdit2Loading}
                    editObj={editObj}
                    isEdit={isEdit}
                    handleDelete={handleDelete}
                    onCreateModifier={() => {
                        setShowAddModifier(true)
                    }}
                    onCreateProperty={() => {
                        setShowAddProperties(true)
                    }}
                    onClose={() => {
                        setShowAddNewProduct(false)
                        fetchMyList()
                        setIsEdit(false)
                        setEditObj({})
                    }}
                />
            </Dialog>

            <Dialog
                open={showAddModifier}
                onClose={() => {
                    setShowAddModifier(false)
                }}
                PaperProps={{
                    style: {
                        width: '100%'
                    }
                }}
                keepMounted={false}
            >
                <AddModifierDialog
                    editObj={editModifierObj}
                    isEdit={false}
                    // handleDelete={handleDelete}
                    onClose={() => {
                        setShowAddModifier(false)
                    }}
                />
            </Dialog>

            <Dialog
                open={showAddProperties}
                onClose={() => {
                    setShowAddProperties(false)
                }}
                PaperProps={{
                    style: {
                        width: '100%'
                    }
                }}
                keepMounted={false}
            >
                <AddPropertiesDialog
                    editObj={{}}
                    isEdit={isEdit}
                    // handleDelete={handleDelete}
                    onClose={() => {
                        setShowAddProperties(false)
                    }}
                />
            </Dialog>
        </div>
    );
}

export default RestaurantsInventory