const initialState = {
    isLoading: false,
    isLoggedIn: false,
    venueStoreList: [],
    venueListError: "",
    venueStoreAdmin: [],
    venueAdminError: {},
    shadowLogin: [],
    shadowLoginError: "",
};
function venueStore(state = initialState, action) {
    switch (action.type) {
        case "SET_VENUE_STORE_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_VENUE_STORE_LIST_LOADED":
            return { ...initialState, isLoading: false, venueList: action.payload };
        case "SET_VENUE_STORE_LIST_ERROR":
            return { ...initialState, venueListError: action.payload };
        case "SET_VENUE_STORE_ADMIN_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_VENUE_STORE_ADMIN_LOADED":
            return { ...initialState, isLoading: false, venueStoreAdmin: action.payload };
        case "SET_VENUE_STORE_ADMIN_ERROR":
            return { ...initialState, venueAdminError: action.payload };
        case "SET_SHADOW_LOGIN_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_SHADOW_LOGIN_LOADED":
            return { ...initialState, isLoading: false, shadowLogin: action.payload };
        case "SET_SHADOW_LOGIN_ERROR":
            return { ...initialState, shadowLoginError: action.payload };
        case "SET_SUPERADMIN_LOGIN_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_SUPERADMIN_LOGIN_LOADED":
            return { ...initialState, isLoading: false, shadowLogin: action.payload };
        case "SET_SUPERADMIN_LOGIN_ERROR":
            return { ...initialState, shadowLoginError: action.payload };
        default:
            return state;
    }
}
export default venueStore;