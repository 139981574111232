import React, { useCallback, useEffect, useRef, useState } from 'react'

import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import { useFormik } from 'formik'
import { Autocomplete, Button, Checkbox, Chip, ClickAwayListener, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, ListItem, ListItemText, Menu, MenuItem, Popper, Radio, Select, TextField, Tooltip, inputBaseClasses, outlinedInputClasses, tooltipClasses, useMediaQuery } from '@mui/material'
import { AttachMoney, Cancel, ChevronLeft, ChevronRight, Close, Delete, Edit, EditOutlined, ExpandMore, HelpOutline, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import { getImage, getImage2 } from '../../../../../utils/helpers'
import GenericAutoCompleteWithLabel from '../../../../Library/GenericAutoCompleteWithLabel/GenericAutoCompleteWithLabel'
import axios, { nodeAxios } from '../../../../../utils/axiosConfig'

import GenericTextField from '../../../../Library/V2/GenericTextField/GenericTextField'
import GenericSelectWithLabel from '../../../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { toast } from 'react-toastify'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { debounce } from 'lodash'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import * as yup from 'yup';
import LightTooltip from '../../../../../util_comps/LightTooltip'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorder } from '../../../../CustomPageBuilder/EditSection/Util/helpers'
//import ReOrderDialog from './ReOrderDialog/ReOrderDialog'
import Loader from '../../../../Library/Loader'
import { useConfirm } from 'material-ui-confirm'
import GenericSwitch from '../../../../Library/V2/GenericSwitch/GenericSwitch'
import { styled } from '@mui/styles'
import clsx from 'clsx'
import { Divider } from '@mui/material'
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import { loadingButtonClasses } from "@mui/lab";

import RadioGroup from '@mui/material/RadioGroup';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TabSelector from './TabSelector';
import { classname } from '../../../../../utils/bem'
import EmailPreview from '../EmailPreview/EmailPreview';
import ValueTextField from '../../../../Library/ValueTextField/ValueTextField'
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        //   disableFocusListener
        //   disableHoverListener
        //   disableTouchListener
        // placement="left-end"
        PopperProps={{
            disablePortal: true,
        }}
        arrow
    //   leaveDelay={5000}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 360,
        fontSize: 14,
        border: 'none',
        borderRadius: 10,
        padding: '10px 15px',
        whiteSpace: 'break-spaces',
        //   wordBreak: 'break-word',
        //   textAlign: 'center',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000  ',
    },
}));


const getListStyle = (isDraggingOver, isMobile) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: !isMobile && 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: '0 5px',
    margin: `0 0 8px 0`,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});


const validationSchema = yup.object({
    ProductName: yup
        .string()
        .required('This field is required'),
    category_id: yup
        .string()
        .required('This field is required'),
    inventories: yup.array()
        .of(
            yup.object().shape({
                Price: yup.string().required('This field is required'),
                min_quantity: yup.number()
                    .min(1, 'Minimum value must be 1 or greater than 1')
                    .integer('Minimum value must be an integer')
                    .nullable(true), // Allows min to be null or undefined
                max_quantity: yup.number()
                    .min(1, 'Minimum value must be 1 or greater than 1')
                    .integer('Maximum value must be an integer')
                    .nullable(true)
                    .test(
                        'is-greater',
                        'Maximum value must be greater than or equal to minimum value',
                        function (value) {
                            const { min_quantity } = this.parent;
                            if (!isNaN(parseInt(value)) && !isNaN(parseInt(min_quantity))) {
                                return value >= min_quantity;
                            } else {
                                return true
                            }
                        }
                    ), // Allows max to be null or undefined
                // format: yup.string().when([], {
                //     is: (inventories) => inventories && inventories.length > 1,
                //     then: yup.string().required('This field is required when there are more than one inventory'),
                //     otherwise: yup.string() // No requirement when there's only one inventory
                // })
            })
        )
});

const AddNewProductDialog = ({
    modalForm,
    onClose,
    isEdit,
    withDiscount,
    ...props
}) => {
    const cn = classname('coupons')
    const imageRef = useRef()
    const confirm = useConfirm()
    const isMobile = useMediaQuery('(max-width:1000px)')
    const isMobileSmall = useMediaQuery('(max-width:700px)')
    const [formats, setFormats] = useState([])
    const [showModifierReorder, setShowModifierReorder] = useState(false)
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [openBrandOption, setOpenBrandOption] = useState(false)
    const [venues, setVenues] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedPropertiesOptions, setSelectedPropertiesOptions] = useState([]);
    // const [properties, setProperties] = useState([])
    // const [options, setOptions] = useState([])

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCat, setSelectedCat] = useState(props.isEdit ? props?.editObj?.category_name : '')
    const [options2, setOptions2] = useState({})
    const [selectedStep, setSelectedStep] = useState(0)
    const [selectedTab, setselectedTab] = useState(withDiscount ? 'offer' : 'without_offer')
    const [showPrivewEmail, setShowPrivewEmail] = useState(false)
    const [errors, setErrors] = useState({})
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const modalAutoCompleteOptions = [
        {
            title: 'Delivery'
        },
        {
            title: 'Pickup'
        },
        {
            title: 'Shipping'
        }
    ]

    // const fetchModifiers = () => {
    //     axios.get('/modifier')
    //         .then((res) => {
    //             console.log('🚀OUTPUT --> props.isEdit /modifier:', props.isEdit);
    //             const data = res.data?.data?.results?.map((data) => ({
    //                 ...data,
    //                 modifier_id: data?.id,
    //                 [props.isEdit ? 'modifier_options' :'options']: data.options?.map((data2) => ({ ...data2, is_visible: 1 }))
    //             }))
    //             setOptions(data)
    //         })
    // }

    // const fetchProperty = () => {
    //     axios.get('/product-property')
    //         .then((res) => {
    //             setProperties(res.data?.data?.results)
    //         })
    // }


    const fetchFormats = () => {
        axios.post('/product/formats')
            .then((res) => {
                setFormats(res.data?.data?.result)
            })
    }

    const fetchVenueCatMenu = () => {
        axios.get('/venue-category-order')
            .then((res) => {
                setVenueCategoryMenu(res.data?.data?.results)
            })
    }

    useEffect(() => {
        if (props.isEdit) {
            setSelectedCat(props?.editObj?.category_name)
        }
        // props.fetchModifiers()

    }, [props.isEdit])


    const handleBrandChange = (e, val) => {
        try {
            axios.get(`/venue/list`, {
                params: {
                    q: val
                }
            })
                .then((res) => {
                    setVenues(res?.data?.data?.result)
                })
        } catch (err) {
            toast.error('There was an error while fetching inventories, try again later')
        }
    }


    const debounceBrandSearch = useCallback(debounce(handleBrandChange, 1000), [])
    const formik = useFormik({
        initialValues: {
            offer_name: modalForm.offer_name || '',
            is_active: modalForm.is_active || true,
            valid_for: modalForm.valid_for || '7',
            purchase_frequency: modalForm.purchase_frequency || '',
            in_days: modalForm.in_days || '',
            with_discount: modalForm.with_discount || '',
            coupon_valid_for: modalForm.coupon_valid_for ? (Array.isArray(modalForm.coupon_valid_for) ? modalForm.coupon_valid_for : modalForm.coupon_valid_for?.split(',').map((data) => _.startCase(_.toLower(data)))) : [],
            coupon_type: modalForm.coupon_type || 'percentage',
            coupon_limit: modalForm.coupon_limit || '',
            email_subject: modalForm.email_subject || '',
            email_body: modalForm.email_body || 'Thank you for your Patronage. We appreciate your business. Here is a small token of appreciation for your next purchase.',
            start_date: '',
            typee: modalForm.purchase_frequency?.includes("=1") ? 'newcustomers' : modalForm.purchase_frequency?.includes("<") ? 'every_customer_less_than' : modalForm.purchase_frequency?.includes(">") ? 'every_customer_more_than' : modalForm.purchase_frequency?.includes("=0") ? 'customer_who_didn’t_purchase' : modalForm.purchase_amount?.includes(">") ? 'customer_who_purchase' : '',
            purchased_more_then: modalForm.purchase_frequency?.includes(">") ? modalForm.purchase_frequency?.split(">")[1] : '',
            purchased_more_then_days: modalForm.purchase_frequency?.includes(">") ? modalForm.in_days : '',
            purchased_less_then: modalForm.purchase_frequency?.includes("<") ? modalForm.purchase_frequency?.split("<")[1] : '',
            purchased_less_then_days: modalForm.purchase_frequency?.includes("<") ? modalForm.in_days : '',
            didnt_purchase_in_last: modalForm.purchase_frequency?.includes("=0") ? modalForm.in_days : '',
            purchased_more_than_inamout: modalForm.purchase_amount?.includes(">") ? modalForm.purchase_amount?.split(">")[1] : '',
            purchased_more_then_days_inamout: modalForm.purchase_amount?.includes(">") ? modalForm.in_days : '',
            select_audience: 'automatically'
        },
        enableReinitialize: true,

        onSubmit: (values) => {
            let currentDate = new Date().toJSON().slice(0, 10);

            let postValues = { offer_name: values.offer_name, is_active: values.is_active, valid_for: selectedTab == 'offer' ? parseInt(values.valid_for) : null, start_date: currentDate, purchase_frequency: '', in_days: 0, with_discount: selectedTab == 'offer' ? true : false, coupon_valid_for: selectedTab == 'offer' ? values.coupon_valid_for.map((option) => option).toString().toLowerCase() : '', coupon_type: selectedTab == 'offer' ? values.coupon_type : '', coupon_limit: selectedTab == 'offer' ? values.coupon_limit : '', email_subject: values.offer_name, email_body: values.email_body }
            if (values.typee == 'newcustomers') {
                postValues.purchase_frequency = '=1'
                postValues.in_days = 1
            } else if (values.typee == 'every_customer_more_than') {
                let purchase_frequency = '>' + values.purchased_more_then;
                postValues.purchase_frequency = purchase_frequency
                postValues.in_days = values.purchased_more_then_days

            } else if (values.typee == 'every_customer_less_than') {
                let purchase_frequency = '<' + values.purchased_less_then;
                postValues.purchase_frequency = purchase_frequency
                postValues.in_days = values.purchased_less_then_days

            } else if (values.typee == 'customer_who_didn’t_purchase') {

                postValues.purchase_frequency = '=0'
                postValues.in_days = values.didnt_purchase_in_last

            } else if (values.typee == 'customer_who_purchase') {

                let purchase_amount = '>' + values.purchased_more_than_inamout;
                postValues.purchase_frequency = null
                postValues.purchase_amount = purchase_amount
                postValues.in_days = values.purchased_more_then_days_inamout

            }

            console.log('postValues>>>', postValues);


            //postValues.valid_for = values.valid_for.map((option) => option?.title).toString().toLowerCase()
            if (isEdit) {
                nodeAxios.put(`/automated-offers/update-offer/${modalForm.id}`, postValues)
                    .then(() => {
                        toast.success(isEdit ? 'Offer Updated' : 'Offer Added')
                        onClose()
                    })
                    .catch((err) => {
                        if (err?.response?.data?.code?.length) {
                            toast.error(err?.response?.data?.code[0])
                        } else {
                            toast.error(err?.response?.data?.message)
                        }
                    })
            } else {
                nodeAxios.post('/automated-offers/create-offer', postValues)
                    .then(() => {
                        toast.success(isEdit ? 'Offer Updated' : 'Offer Added')
                        onClose()
                    })
                    .catch((err) => {
                        if (err?.response?.data?.code?.length) {
                            toast.error(err?.response?.data?.code[0])
                        } else {
                            toast.error(err?.response?.data?.message)
                        }
                    })
            }
        }
    })
    console.log('🚀OUTPUT --> formik:', formik.values);
    console.log('🚀OUTPUT --> modalForm:', modalForm);
    const getTreeItem = (child) => {
        return child?.map((data) => (
            <TreeItem icon={(
                <Radio
                    checked={data.category_id == formik.values.category_id}
                    onChange={(event) => {
                        formik.setFieldValue('category_id', event.target.value)
                        setSelectedCat(data?.category_name)
                    }}
                    value={data.category_id}
                />
            )}
                nodeId={data.category_id}
                label={(
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8
                        }}
                    >
                        <div>
                            {data.category_name}
                        </div>

                        {Boolean(data?.child?.length) && (
                            <KeyboardArrowRight />
                        )}
                    </div>
                )}
            >
                {Boolean(data?.child?.length) && getTreeItem(data?.child)}
            </TreeItem>
        ))
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            formik.values.inventories,
            result.source.index,
            result.destination.index
        );

        formik.setFieldValue('inventories', newitems)
    };

    const handleShowEmailPreivew = (id) => {
        setShowPrivewEmail(true)
    }
    const handleChangeVisibility = (data) => {

        // setOptions2(true);

        // if (!(options2[data.id]?.noWebSales != undefined ? options2[data.id]?.noWebSales : (data.noWebSales === 0))) {
        //     axios.get(`/product/visibility/${data.id}`, {
        //         params: {
        //             product_id: data.ProductId
        //         }
        //     })
        //         .then((res) => {
        //             toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
        //             setOptions2((prevState) => ({
        //                 ...prevState,
        //                 [data.id]: {
        //                     ...prevState[data.id],
        //                     noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
        //                 }
        //             }))
        //         })
        //         .catch((err) => {
        //             toast.error(err?.response?.data?.message)
        //         })
        // } else {

        //     confirm({
        //         title: 'Are you sure you want to disable this product',
        //     })
        //         .then(() => {
        //             axios.get(`/product/visibility/${data.id}`, {
        //                 params: {
        //                     product_id: data.ProductId
        //                 }
        //             })
        //                 .then((res) => {
        //                     toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
        //                     setOptions2((prevState) => ({
        //                         ...prevState,
        //                         [data.id]: {
        //                             ...prevState[data.id],
        //                             noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
        //                         }
        //                     }))
        //                 })
        //                 .catch((err) => {
        //                     toast.error(err?.response?.data?.message)
        //                 })

        //         })
        //         .catch(() => {

        //         })

        // }
    };
    const steps = ['Select Audience', 'Create Offer', 'Review Offer'];

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(0,0,0) 0%,rgb(0,0,0) 50%,rgb(0,0,0) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(0,0,0) 0%,rgb(0,0,0) 50%,rgb(0,0,0) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(0,0,0) 0%, rgb(0,0,0) 50%, rgb(0,0,0) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(0,0,0) 0%, rgb(0,0,0) 50%, rgb(0,0,0) 100%)',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <DoneIcon />,
            2: <DoneIcon />,
            3: <DoneIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {completed ? icons[String(props.icon)] : String(props.icon)}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    const handleBackButtonPressed = () => {
        if (selectedStep > 0) {
            setSelectedStep(selectedStep => selectedStep - 1)
        }
    }
    const handleNextButtonPressed = () => {

        if (selectedStep == 0) {
            if (formik.values.typee == '') {
                toast.error('Please select your audience');
                return;
            }
        }

        if (selectedStep == 1) {
            if (selectedTab == 'offer') {
                if (formik.values.offer_name == '' || formik.values.coupon_limit == '') {
                    toast.error('Please add mandatory field values');
                    return;
                }
            }
        }

        if (selectedStep == 2) {

            if (formik.values.offer_name == '' || formik.values.email_body == '') {
                toast.error('Please add mandatory field values');
                return;
            }

        }
        if (selectedStep < 2) {
            setSelectedStep(selectedStep => selectedStep + 1)
        } else {
            formik.handleSubmit()
            // onClose()
        }

    }
    return (
        <>
            <DialogTitle
                align='left'
                fontWeight={600}
                fontSize={14}
                borderBottom="1px solid #D3D3D3"
                className='tw-flex tw-items-center !tw-text-base  !tw-py-1 !tw-px-4 sm:!tw-px-5 !tw-mb-4 !tw-mt-[5px] sm:!tw-mt-0'
            >
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        handleBackButtonPressed()
                    }}
                    sx={{ color: '#000' }}
                >
                    <ChevronLeft />
                </IconButton>

                {isEdit ? "Edit Offer" :"Create New Offer"}

                <IconButton
                    aria-label="close"
                    onClick={() => {

                        onClose()
                    }}
                    sx={{ color: '#000', position: 'absolute', right: '10px' }}
                >
                    <Cancel />
                </IconButton>
            </DialogTitle>
            <DialogContent className='!tw-px-4 sm:!tw-px-5 tw-mt-[-10px]'>
                <input
                    style={{
                        display: 'none'
                    }}
                    ref={imageRef}
                    type="file"
                    onChange={(event) => {
                        formik.setFieldValue('image_file', event.target.files[0])
                        formik.setFieldValue('image_link', URL.createObjectURL(event.target.files[0]))
                        formik.setFieldValue('image_file_remove', 0)
                        event.target.value = ''
                    }}
                />
                {props.isEdit2Loading ? (
                    <Loader />
                ) : (
                    <>
                        <Stack sx={{ width: '100%', marginTop: '5px' }} spacing={4}>
                            <Stepper alternativeLabel activeStep={selectedStep} connector={<ColorlibConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Stack>
                        {selectedStep == 0 ? <div
                            className='tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4'
                        >
                            <div
                                className='flex gap-10'
                            >


                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}
                                >
                                    <div className='tw-w-full tw-font-semibold tw-text-base tw-md:text-lg'>


                                        {props.isEdit && (
                                            <Button
                                                color='error'
                                                variant="text"
                                                startIcon={<Delete />}
                                                onClick={() => {
                                                    //props.handleDelete(props?.editObj?.id)
                                                    onClose()
                                                }}
                                            >
                                                Remove Item
                                            </Button>
                                        )}
                                    </div>

                                </div>
                            </div>


                            <div className="tw-flex tw-items-center tw-w-full tw-gap-2 sm:tw-gap-4 tw-text-sm tw-py-2 tw-px-[10px] sm:tw-items-end tw-flex-row tw-border tw-border-solid tw-border-gray tw-rounded">
                                <div className='tw-font-semibold'>Offer Status</div>
                                <GenericSwitch
                                    background="#0AB221"
                                    width={35}
                                    height={18}
                                    translateX={16}
                                    thumb={13}
                                    isStatus={true}
                                    onText="'Active'"
                                    offText="''"
                                    textOffSet={0}
                                    checked={formik.values?.is_active}
                                    onChange={(event) => {
                                        formik?.setFieldValue('is_active', event.target?.checked)

                                    }}
                                />
                            </div>

                            <div className="w-full tw-border tw-border-solid tw-border-gray tw-rounded tw-p-4">
                                <div className='tw-w-full'>
                                    <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mb-[10px] tw-flex tw-gap-2'>
                                        Select Audience
                                    </div>
                                    <div className='tw-bg-grayBg tw-px-3 tw-py-2 tw-rounded-md'>
                                        Automatically send offer via email to
                                    </div>
                                    {/* <GenericSelectWithLabel
                                        id="select_audience"
                                        name="select_audience"
                                        sx={{
                                            backgroundColor: '#EFEFEF'
                                        }}
                                        value={formik.values.select_audience}
                                        onChange={formik.handleChange}
                                        isSmallLabel={true}

                                    >
                                        <MenuItem value="automatically">Automatically send offer via email to</MenuItem>

                                    </GenericSelectWithLabel> */}
                                </div>
                                <div className='tw-w-full tw-mt-2'>
                                    <FormControl fullWidth>
                                        <RadioGroup aria-labelledby="demo-radio-buttons-group-label">
                                            <FormControlLabel 
                                                name="typee" 
                                                onChange={(e) => {
                                                    formik.setFieldValue('typee', e.target.checked == true ? 'newcustomers' : '')
                                                }} 
                                                value="newcustomers" 
                                                control={<Radio size="small" checked={formik.values.typee === 'newcustomers'} />} 
                                                label={(<div className='tw-text-sm md:tw-text-base'>New customers only (after first purchase)</div>)} 
                                            />
                                            <Divider />

                                            <Divider />
                                            <FormControlLabel 
                                                name="typee" 
                                                onChange={(e) => {
                                                    formik.setFieldValue('typee', e.target.checked == true ? 'specificcustomersegment' : '')
                                                }} 
                                                value="specificcustomersegment" 
                                                control={<Radio size="small" checked={formik.values.typee === 'specificcustomersegment' || formik.values.typee === 'every_customer_more_than' || formik.values.typee === 'every_customer_less_than' || formik.values.typee === 'customer_who_didn’t_purchase' || formik.values.typee === 'customer_who_purchase'} />} 
                                                label={(<div className='tw-text-sm md:tw-text-base'>Specific customer segment</div>)} 
                                            />
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                className='tw-gap-2 lg:!tw-gap-4 !tw-ml-5'
                                            >
                                                <FormControlLabel 
                                                    name="typee" 
                                                    onChange={(e) => {
                                                        formik.setFieldValue('typee', e.target.checked == true ? 'every_customer_more_than' : '')
                                                    }} 
                                                    value="every_customer_more_than" 
                                                    control={ <Radio size="small" checked={formik.values.typee === 'every_customer_more_than'} sx={{ p: 0, m: '3px 9px' }} />} 
                                                    sx={{ alignItems: 'flex-start' }}
                                                    label={(
                                                        <div className='tw-text-sm md:tw-text-base'>
                                                            Every customer who have purchased more than
                                                            <TextField
                                                                type="number"
                                                                id="purchased_more_then"
                                                                name="purchased_more_then"
                                                                disabled={formik.values.typee == 'every_customer_more_than' ? false : true}
                                                                classes={{ root: 'tw-bg-grayBg tw-w-16 tw-rounded-md !tw-mx-1 !tw-mb-1 md:!tw-mb-0' }}
                                                                sx={{ 
                                                                    [`& .${outlinedInputClasses.input}`]: {
                                                                        p: '2px 8px',
                                                                        '@media (max-width: 768px)': { p: '0px 8px' }
                                                                    }
                                                                }}
                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                size='small'
                                                                value={formik.values.purchased_more_then}
                                                                onChange={formik.handleChange}
                                                            />
                                                            number of times, in last
                                                            <TextField
                                                                type="number"
                                                                id="purchased_more_then_days"
                                                                name="purchased_more_then_days"
                                                                disabled={formik.values.typee == 'every_customer_more_than' ? false : true}
                                                                classes={{ root: 'tw-bg-grayBg tw-w-16 tw-rounded-md !tw-mx-1 !tw-mb-1 md:!tw-mb-0' }}
                                                                sx={{ 
                                                                    [`& .${outlinedInputClasses.input}`]: {
                                                                        p: '2px 8px',
                                                                        '@media (max-width: 768px)': { p: '0px 8px' }
                                                                    }
                                                                }}
                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                size='small'
                                                                value={formik.values.purchased_more_then_days}
                                                                onChange={formik.handleChange}
                                                            />
                                                            number of days.
                                                        </div>
                                                    )} 
                                                />
                                                <FormControlLabel 
                                                    name="typee" 
                                                    onChange={(e) => {
                                                        formik.setFieldValue('typee', e.target.checked == true ? 'every_customer_less_than' : '')
                                                    }} 
                                                    value="every_customer_less_than" 
                                                    control={<Radio size="small" checked={formik.values.typee === 'every_customer_less_than'} sx={{ p: 0, m: '3px 9px' }} />} 
                                                    sx={{ alignItems: 'flex-start' }}
                                                    label={(
                                                        <div className='tw-text-sm md:tw-text-base'>
                                                            Every customer who have purchased less than
                                                            <TextField
                                                                type="number"
                                                                id="purchased_less_then"
                                                                name="purchased_less_then"
                                                                disabled={formik.values.typee == 'every_customer_less_than' ? false : true}
                                                                classes={{ root: 'tw-bg-grayBg tw-w-16 tw-rounded-md !tw-mx-1 !tw-mb-1 md:!tw-mb-0' }}
                                                                sx={{ 
                                                                    [`& .${outlinedInputClasses.input}`]: {
                                                                        p: '2px 8px',
                                                                        '@media (max-width: 768px)': { p: '0px 8px' }
                                                                    }
                                                                }}
                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                size='small'
                                                                value={formik.values.purchased_less_then}
                                                                onChange={formik.handleChange}
                                                            />
                                                            number of times, in last
                                                            <TextField
                                                                type="number"
                                                                id="purchased_less_then_days"
                                                                name="purchased_less_then_days"
                                                                disabled={formik.values.typee == 'every_customer_less_than' ? false : true}
                                                                classes={{ root: 'tw-bg-grayBg tw-w-16 tw-rounded-md !tw-mx-1 !tw-mb-1 md:!tw-mb-0' }}
                                                                sx={{ 
                                                                    [`& .${outlinedInputClasses.input}`]: {
                                                                        p: '2px 8px',
                                                                        '@media (max-width: 768px)': { p: '0px 8px' }
                                                                    }
                                                                }}
                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                size='small'
                                                                value={formik.values.purchased_less_then_days}
                                                                onChange={formik.handleChange}
                                                            />
                                                            number of days.
                                                        </div>
                                                    )} 
                                                />
                                                <FormControlLabel 
                                                    name="typee" 
                                                    onChange={(e) => {
                                                        formik.setFieldValue('typee', e.target.checked == true ? 'customer_who_didn’t_purchase' : '')
                                                    }} 
                                                    value="customer_who_didn’t_purchase" 
                                                    control={<Radio size="small" checked={formik.values.typee === 'customer_who_didn’t_purchase'} sx={{ p: 0, m: '3px 9px' }} />} 
                                                    sx={{ alignItems: 'flex-start' }}
                                                    label={(
                                                        <div className='tw-text-sm md:tw-text-base'>
                                                            Customers who didn’t purchase in last
                                                            <TextField
                                                                type="number"
                                                                id="didnt_purchase_in_last"
                                                                name="didnt_purchase_in_last"
                                                                disabled={formik.values.typee == 'customer_who_didn’t_purchase' ? false : true}
                                                                classes={{ root: 'tw-bg-grayBg tw-w-16 tw-rounded-md !tw-mx-1 !tw-mb-1 md:!tw-mb-0' }}
                                                                sx={{ 
                                                                    [`& .${outlinedInputClasses.input}`]: {
                                                                        p: '2px 8px',
                                                                        '@media (max-width: 768px)': { p: '0px 8px' }
                                                                    }
                                                                }}
                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                size='small'
                                                                value={formik.values.didnt_purchase_in_last}
                                                                onChange={formik.handleChange}
                                                            />
                                                            days.
                                                        </div>
                                                    )} 
                                                />
                                                <FormControlLabel 
                                                    name="typee" 
                                                    onChange={(e) => {
                                                        formik.setFieldValue('typee', e.target.checked == true ? 'customer_who_purchase' : '')
                                                    }} 
                                                    value="customer_who_purchase" 
                                                    control={<Radio size="small" checked={formik.values.typee === 'customer_who_purchase'} sx={{ p: 0, m: '3px 9px' }} />} 
                                                    sx={{ alignItems: 'flex-start' }}
                                                    label={(
                                                        <div className='tw-text-sm md:tw-text-base'>
                                                            Customers who purchased more than
                                                            <TextField
                                                                type="number"
                                                                id="purchased_more_than_inamout"
                                                                name="purchased_more_than_inamout"
                                                                disabled={formik.values.typee == 'customer_who_purchase' ? false : true}
                                                                classes={{ root: 'tw-bg-grayBg tw-w-16 tw-rounded-md !tw-mx-1 !tw-mb-1 md:!tw-mb-0' }}
                                                                sx={{ 
                                                                    [`& .${outlinedInputClasses.input}`]: {
                                                                        p: '2px 8px',
                                                                        '@media (max-width: 768px)': { p: '0px 8px' }
                                                                    }
                                                                }}
                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                size='small'
                                                                value={formik.values.purchased_more_than_inamout}
                                                                onChange={formik.handleChange}
                                                            />
                                                            in last
                                                            <TextField
                                                                type="number"
                                                                id="purchased_more_then_days_inamout"
                                                                name="purchased_more_then_days_inamout"
                                                                disabled={formik.values.typee == 'customer_who_purchase' ? false : true}
                                                                classes={{ root: 'tw-bg-grayBg tw-w-16 tw-rounded-md !tw-mx-1 !tw-mb-1 md:!tw-mb-0' }}
                                                                sx={{ 
                                                                    [`& .${outlinedInputClasses.input}`]: {
                                                                        p: '2px 8px',
                                                                        '@media (max-width: 768px)': { p: '0px 8px' }
                                                                    }
                                                                }}
                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                size='small'
                                                                value={formik.values.purchased_more_then_days_inamout}
                                                                onChange={formik.handleChange}
                                                            />
                                                            days.
                                                        </div>
                                                    )} 
                                                />
                                            </RadioGroup>

                                        </RadioGroup>
                                    </FormControl>


                                </div>
                            </div>



                        </div > : selectedStep == 1 ? <>
                            <div className="tw-w-full tw-border tw-border-solid tw-border-gray tw-rounded tw-p-4 tw-mt-4">
                                <TabSelector
                                    onChange={(value) => {
                                        setselectedTab(value)
                                    }}
                                    value={selectedTab}
                                    containerClass='tw-h-[44px]'
                                    labelClass="!tw-text-sm lg:!tw-text-base"
                                    tabs={[
                                        {
                                            label: 'Offer Discount',
                                            value: 'offer'
                                        },
                                        {
                                            label: 'Without Discount',
                                            value: 'without_offer'
                                        }
                                    ]}
                                />
                                {selectedTab == 'offer' ? <>
                                    <div className='tw-flex tw-gap-[10px] tw-items-start tw-mt-4 tw-w-full'>
                                        <div className="tw-w-[calc(-10px+50%)] lg:tw-w-[calc(-10px+33%)]">
                                            <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                                Offer name*
                                            </div>
                                            <GenericTextField
                                                containerStyle={{
                                                    width: '100%',

                                                }}
                                                sx={{
                                                    [`& .${inputBaseClasses.root}`]: {
                                                        backgroundColor: '#EFEFEF',
                                                        height: 47,
                                                        // height: 33
                                                    },
                                                }}
                                                id="offer_name"
                                                name="offer_name"
                                                label=""
                                                placeholder="Offer name"
                                                value={formik.values.offer_name}
                                                onChange={formik.handleChange}
                                                fullWidth={true}
                                                required={true}
                                                error={formik.touched.offer_name && Boolean(formik.errors.offer_name)}
                                                helperText={formik.touched.offer_name && formik.errors.offer_name}
                                            />
                                        </div>
                                        <div className="tw-w-[calc(-10px+50%)] lg:tw-w-[calc(-10px+33%)]">
                                            <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                                Offer valid for*
                                            </div>
                                            <GenericSelectWithLabel
                                                id="valid_for"
                                                name="valid_for"
                                                sx={{
                                                    backgroundColor: '#EFEFEF',


                                                }}
                                                value={formik.values.valid_for}
                                                onChange={formik.handleChange}
                                                isSmallLabel={true}
                                            >
                                                <MenuItem value="7">One Week</MenuItem>
                                                <MenuItem value="14">Two Weeks</MenuItem>
                                                <MenuItem value="30">One Month</MenuItem>
                                                <MenuItem value="90">Three Months</MenuItem>
                                                <MenuItem value="180">6 Months</MenuItem>
                                                <MenuItem value="365">1 Year</MenuItem>
                                                <MenuItem value="-1">Forever(No end date)</MenuItem>

                                            </GenericSelectWithLabel>
                                        </div>
                                    </div>

                                    <div className='tw-flex tw-gap-[10px] tw-items-start tw-flex-row tw-flex-wrap tw-mt-4'>
                                        <div className='tw-w-[calc(-10px+50%)] lg:tw-w-[calc(-10px+33%)]'>
                                            <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                                Fulfillment type*
                                            </div>
                                            <GenericSelectWithLabel
                                                id="coupon_valid_for"
                                                name="coupon_valid_for"
                                                sx={{
                                                    backgroundColor: '#EFEFEF'
                                                }}
                                                multiple
                                                value={formik.values.coupon_valid_for}
                                                onChange={formik.handleChange}
                                                placeholder="Select valid for"
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', gap: 0.5, overflow:'hidden', maxWidth: '150px' }}>
                                                      {selected.map((value) => (
                                                        <Chip sx={{ height: '22px' }} key={value} label={value} />
                                                      ))}
                                                    </Box>
                                                  )}
                                                isSmallLabel={true}
                                            >
                                                {/* <MenuItem value="delivery">Delivery</MenuItem>
                                                <MenuItem value="pickup">Pickup</MenuItem>
                                                <MenuItem value="delivery,pickup">Both Delivery and Pickup</MenuItem> */}
                                                {modalAutoCompleteOptions.map((option) => (
                                                    <MenuItem key={option?.title} value={option?.title}>
                                                         <Checkbox checked={formik.values.coupon_valid_for?.findIndex((data) => data == option?.title) > -1} />
                                                        {option?.title}
                                                    </MenuItem>
                                                ))}

                                            </GenericSelectWithLabel>
                                        </div>
                                        <div className='tw-w-[calc(-10px+50%)] lg:tw-w-[calc(-10px+33%)]'>
                                            <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                                Offer type*
                                            </div>
                                            <GenericSelectWithLabel
                                                id="coupon_type"
                                                name="coupon_type"
                                                sx={{
                                                    backgroundColor: '#EFEFEF'
                                                }}
                                                value={formik.values.coupon_type}
                                                onChange={formik.handleChange}
                                                isSmallLabel={true}
                                            >
                                                <MenuItem value="percentage">Percentage</MenuItem>
                                                <MenuItem value="dollar">Dollar</MenuItem>
                                            </GenericSelectWithLabel>
                                        </div>
                                        <div className='tw-w-[calc(-10px+50%)] lg:tw-w-[calc(-10px+33%)]'>
                                            <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                                {formik.values.coupon_type === 'percentage' ? 'Offer Percentage*' : 'Offer Amount*'}
                                            </div>
                                            <ValueTextField
                                                containerStyle={{
                                                    width: '100%',

                                                }}
                                                sx={{
                                                    [`& .${inputBaseClasses.root}`]: {
                                                        backgroundColor: '#EFEFEF',
                                                        height: 47,
                                                    },
                                                }}
                                                id="coupon_limit"
                                                name="coupon_limit"
                                                placeholder="Amount"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                type={formik.values.coupon_type}
                                                value={formik.values.coupon_limit}
                                                onChange={formik.handleChange}
                                                texttype="number"
                                                isSmallLabel={true}
                                                fullWidth={true}
                                            />
                                            {/* <GenericTextField
                                                type="number"
                                                containerStyle={{
                                                    width: '100%',
                                                }}
                                                sx={{
                                                    [`& .${inputBaseClasses.root}`]: {
                                                        backgroundColor: '#EFEFEF',
                                                        height: 47
                                                        // height: 33
                                                    },
                                                }}
                                                // onInput={(e) => {
                                                //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                                // }}
                                                // min={0}
                                                id="coupon_limit"
                                                name="coupon_limit"
                                                placeholder="Amount"
                                                value={formik.values.coupon_limit}
                                                onChange={formik.handleChange}
                                                fullWidth={true}
                                                required={true}
                                                error={formik.touched.coupon_limit && Boolean(formik.errors.coupon_limit)}
                                                helperText={formik.touched.coupon_limit && formik.errors.coupon_limit}
                                            /> */}
                                        </div>
                                    </div>
                                    <div className='tw-text-black tw-w-full tw-mt-4'>
                                        <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>Offer {formik.values.coupon_type != 'percentage' ? 'USD' : ''} {formik.values.coupon_limit} {formik.values.coupon_type == 'percentage' ? '%' : ''}  for {formik.values.coupon_valid_for?.map((data, index) => (index < (formik.values.coupon_valid_for.length - 1) ? (data + ', ') : (data + '')))} orders to:</div>
                                        <div className='tw-font-normal tw-text-sm tw-mr-4'> {formik.values.typee == 'newcustomers' ? 'New customers only (after first purchase) ' : formik.values.typee == 'every_customer_more_than' ? 'Every customer who have purchased more than ' + formik.values.purchased_more_then + ' number of times, in last ' + formik.values.purchased_more_then_days + ' number days.' : formik.values.typee == 'every_customer_less_than' ? 'Every customer who have purchased less than ' + formik.values.purchased_less_then + ' number of times, in last ' + formik.values.purchased_less_then_days + ' number days.' : formik.values.typee == 'customer_who_didn’t_purchase' ? 'Customers who didn’t purchase in last ' + formik.values.didnt_purchase_in_last + ' Days' : formik.values.typee == 'customer_who_purchase' ? 'Customers who purchased more than ' + formik.values.purchased_more_than_inamout + ' in last ' + formik.values.purchased_more_then_days_inamout + ' days' : ''}</div>
                                        <div className='tw-font-normal tw-text-sm tw-mr-4'>This offer is valid for {formik.values.valid_for == "7" ? 'One Week' : formik.values.valid_for == "14" ? 'Two Weeks' : formik.values.valid_for == "30" ? 'One Month' : formik.values.valid_for == "90" ? 'Three Months' : formik.values.valid_for == "180" ? '6 Months' : formik.values.valid_for == "365" ? '1 Year' : formik.values.valid_for == "-1" ? 'Forever' : ''}</div>



                                    </div></> : <div className='tw-w-full tw-mt-4'>
                                    <div className='tw-flex tw-gap-[10px] tw-items-start tw-flex-col sm:tw-flex-row tw-w-80 tw-mt-4 tw-mb-4'>
                                        <div>
                                            <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                                Offer name*
                                            </div>
                                            <GenericTextField
                                                containerStyle={{
                                                    width: '100%',
                                                }}
                                                sx={{
                                                    [`& .${inputBaseClasses.root}`]: {
                                                        backgroundColor: '#EFEFEF',
                                                        height: 47,
                                                        minWidth: 200
                                                        // height: 33
                                                    },
                                                }}
                                                id="offer_name"
                                                name="offer_name"
                                                label=""
                                                placeholder="Offer name"
                                                value={formik.values.offer_name}
                                                onChange={formik.handleChange}
                                                fullWidth={true}
                                                required={true}
                                                error={formik.touched.offer_name && Boolean(formik.errors.offer_name)}
                                                helperText={formik.touched.offer_name && formik.errors.offer_name}
                                            />
                                        </div>
                                        {/* <div>
                                            <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                                Offer valid for*
                                            </div>

                                            <GenericSelectWithLabel
                                                id="valid_for"
                                                name="valid_for"
                                                sx={{
                                                    backgroundColor: '#EFEFEF'
                                                }}
                                                value={formik.values.valid_for}
                                                onChange={formik.handleChange}
                                                isSmallLabel={true}
                                            >
                                                <MenuItem value="7">One Week</MenuItem>
                                                <MenuItem value="14">Two Weeks</MenuItem>
                                                <MenuItem value="30">One Month</MenuItem>
                                                <MenuItem value="90">Three Months</MenuItem>
                                                <MenuItem value="180">6 Months</MenuItem>
                                                <MenuItem value="365">1 Year</MenuItem>
                                                <MenuItem value="-1">Forever(No end date)</MenuItem>

                                            </GenericSelectWithLabel>
                                        </div> */}
                                    </div>
                                    <GenericTextFieldWithLabel
                                        id="email_body"
                                        name="email_body"
                                        label="Message you want to share to your customer"
                                        placeholder="Thank you for your Patronage. We appreciate your business. Here is a small token of appreciation for your next purchase."
                                        inputProps={{ maxLength: 400 }}
                                        value={formik.values.email_body}
                                        // onChange={formik.handleChange}
                                        onChange={(event) => {
                                            formik.handleChange(event)

                                            if (event.target?.value?.length == 400) {
                                                setErrors({ button: "Maximum 400 characters allowed" })
                                            } else {
                                                setErrors({})
                                            }
                                        }}
                                        fullWidth={true}
                                        multiline={true}
                                        rows={3}
                                        sx={{
                                            [`& .${inputBaseClasses.root}`]: {
                                                padding: 0,
                                                // height: 33
                                            },
                                        }}
                                        error={Boolean(errors?.button)}
                                        helperText={errors?.button}
                                    />



                                </div>}



                            </div>
                        </> : <div className="tw-flextw-w-full tw-border tw-border-solid tw-border-gray tw-rounded tw-p-4 tw-mt-4">
                            <GenericTextFieldWithLabel
                                containerStyle={{
                                    width: '100%',
                                }}
                                sx={{
                                    [`& .${inputBaseClasses.root}`]: {
                                        backgroundColor: '#EFEFEF',
                                        // height: 33
                                    },
                                }}
                                id="offer_name"
                                name="offer_name"
                                label="Subject*"
                                placeholder="Subject"
                                value={formik.values.offer_name}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                required={false}

                            />


                            <GenericTextFieldWithLabel
                                id="email_body"
                                name="email_body"
                                label="Body*"
                                placeholder="Thank you for your Patronage. We appreciate your business. Here is a small token of appreciation for your next purchase."
                                inputProps={{ maxLength: 400 }}
                                value={formik.values.email_body}
                                onChange={(event) => {
                                    formik.handleChange(event)

                                    if (event.target?.value?.length == 400) {
                                        setErrors({ button: "Maximum 400 characters allowed" })
                                    } else {
                                        setErrors({})
                                    }
                                }}
                                fullWidth={true}
                                multiline={true}
                                rows={3}
                                sx={{
                                    [`& .${inputBaseClasses.root}`]: {
                                        backgroundColor: '#EFEFEF',
                                        padding: 0,
                                        // height: 33
                                    },
                                }}
                                error={Boolean(errors?.button)}
                                helperText={errors?.button}
                            />



                            <div className='tw-flex tw-gap-4 tw-w-full tw-justify-start tw-mt-6'>


                                <GenericLoadingButton
                                    variant="contained"
                                    loadingPosition="end"

                                    className="!tw-w-full lg:!tw-w-auto !tw-rounded-3xl"
                                    style={{
                                        backgroundColor: '#D3D3D3'
                                    }}
                                    onClick={() => {
                                        handleShowEmailPreivew()
                                    }}
                                >
                                    <div style={{
                                        color: 'black'
                                    }}>Preview Email</div>
                                </GenericLoadingButton>
                            </div>

                        </div>}
                    </>

                )}
            </DialogContent >

            <div className='tw-flex tw-justify-between tw-p-4 lg:tw-pt-0'>

                <div className='tw-flex tw-gap-4 tw-w-full tw-justify-end'>

                    <GenericLoadingButton
                        variant="contained"
                        loading={isLoading}
                        loadingPosition="end"
                        type="submit"
                        className="!tw-w-full lg:!tw-w-auto !tw-rounded-3xl !tw-px-4 "
                        onClick={() => {
                            handleNextButtonPressed()
                        }}
                    >
                        {selectedStep == 2 ? 'Save offer' : 'Next'}
                    </GenericLoadingButton>
                </div>
            </div>

            <Dialog
                open={showPrivewEmail}
                onClose={() => {
                    setShowPrivewEmail(false)
                }}
                PaperProps={{
                    style: {
                        width: '100%',
                        maxWidth: '700px'
                    }
                }}
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <EmailPreview
                    formik={formik}
                    selectedTab={selectedTab}
                    onClose={() => {
                        setShowPrivewEmail(false)
                    }} />
            </Dialog>

        </>
    )
}

export default AddNewProductDialog;

