import React, { useEffect, useRef, useState } from 'react'

const HTMLRenderer = ({ html }) => {

    const iframeRef = useRef(null);
    const [iframeHeight, setIframeHeight] = useState('60px');

    useEffect(() => {
        if (html) {
            const iframe = iframeRef.current;
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

            // HTML content to be rendered in the iframe
            const htmlContent = html

            // Set up the iframe document
            iframeDoc.open();
            iframeDoc.write(`
          <html style="height:100%">
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
              <link rel="stylesheet" href="https://wb.esplanda.com/js2/main.css" />
              <script src="https://cdn.tailwindcss.com"></script>
              <script src="https://wb.esplanda.com/js2/bundle.js" async></script>
<script>
    tailwind.config = {
        theme: {
            extend: {
                screens: {
                    'mcustom': '1000px',
                    'mobile': '500px',
                    '1536': '1536px',
                    '1400': '1400px',
                    '1200': '1200px',
                    '1000': '1000px',
                    '1024': '1024px',
                    '992': '992px',
                    '768': '768px',
                    '576': '576px',
                },
                padding: {
                    '-3': '-0.75rem',
                },
                boxShadow: {
                    'primary': '0 4px 56px 0 rgba(24,24,29,.18)',
                },
                flex: {
                    '100': '1 0 0',
                    '0': '0 0 auto',
                    '11': '1 1 auto',
                },
                aspectRatio: {
                    '1.5/1': '1.5 / 1',
                    '2/1': '2 / 1',
                },
                height: {
                    '80vh': '80vh',
                    '50vh': '50vh',
                    '10vh': '10vh',
                },
            },
        },
        plugins: [],
        prefix: 'tw-',
        daisyui: {
            // themes: ['light'], // false: only light + dark | true: all themes | array: specific themes like this ["light", "dark", "cupcake"]
            // darkTheme: 'light', // name of one of the included themes for dark mode
            base: true, // applies background color and foreground color for root element by default
            styled: true, // include daisyUI colors and design decisions for all components
            utils: true, // adds responsive and modifier utility classes
            prefix: 'dui-', // prefix for daisyUI classnames (components, modifiers and responsive class names. Not colors)
            logs: true, // Shows info about daisyUI version and used config in the console when building your CSS
            themeRoot: ':root', // The element that receives theme color CSS variables
        },
    }
</script>
            </head>
            <body style="transform: scale(0.09);transform-origin: top left;">
              ${htmlContent}
            </body>
          </html>
        `);
            iframeDoc.close();
            // console.log('iframeDoc', iframeDoc);
            //             if (iframeDoc.body) {

            //                 setTimeout(() => {
            //                     const body = iframeDoc.body;
            //                     const height = getComputedStyle(body).height;
            //                     console.log('height', height);
            //                     setIframeHeight(height + 'px');
            //                 }, 2000);

            //                 const adjustIframeHeight = () => {
            //                     const body = iframeDoc.body;
            //                     const height = getComputedStyle(body).height;
            //                     console.log('height', height);
            //                     setIframeHeight(height + 'px');
            //                 };

            //                 // Ensure the height adjustment happens after the content is loaded
            //                 iframe.onload = adjustIframeHeight;
            //                 // Recalculate the height whenever the content inside the iframe changes
            //                 iframeDoc.body.onresize = adjustIframeHeight;

            //                 // Clean up event listeners on component unmount
            //                 return () => {
            //                     iframe.onload = null;
            //                     iframeDoc.body.onresize = null;
            //                 };

            //             }
        }
    }, [html]);

    return (
        <div
            style={{
                padding: 4,
                border: 'rgb(67, 153, 250) solid 2px',
                marginBottom: 8,
                marginRight: 4
            }}
        >
            <iframe
                ref={iframeRef}
                style={{
                    width: '1920px',
                    height: iframeHeight,
                    pointerEvents: 'none'
                }}
                title="Layers"
            ></iframe>
        </div>
    )
}

export default HTMLRenderer