import { DeleteForever, Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { forwardRef } from 'react'
import UploadImage from '../../../Library/UploadImage/UploadImage'
import styles from './UploadPhoto.module.scss'

const UploadPhoto = forwardRef((props, ref) => {

  return (
    <div className={styles.upload}>
      {(props.file) && (
        <div
          className={styles.image_edit_delete_container}
        >
          <IconButton
            onClick={() => {
              ref.current?.click()
            }}
          >
            <Edit style={{ fontSize: 18, color: 'black' }} />
          </IconButton>

          <IconButton
            onClick={() => {
              props.onImageClose()
            }}
          >
            <DeleteForever style={{ fontSize: 18, color: 'black' }} />
          </IconButton>
        </div>
      )}
      <UploadImage
        ref={ref}
        isBig={true}
        uploadText="Upload"
        image={props.file}
        onChange={props.onChange}
      />
      <p>maximum file size: 2 MB, allowed file types: JPG, GIF, PNG</p>
    </div>
  )
})

export default UploadPhoto