import React, { useEffect, useRef, useState } from 'react';
import { ArrowForwardIosRounded, DeleteForever, Edit } from '@mui/icons-material';
import styles from './EditableHeader.module.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Const from '../../helpers/const';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { IconButton } from '@mui/material';

const data = [
    {
        id: '1',
        Task: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent.',
        // Assigned_To: 'Beltran',
        // Assignee: 'Romona',
        // Status: 'To-do',
        // Priority: 'Low',
        Due_Date: '25-May-2020',
    },
    {
        id: '2',
        Task: 'Fix Styling',
        // Assigned_To: 'Dave',
        // Assignee: 'Romona',
        // Status: 'To-do',
        // Priority: 'Low',
        Due_Date: '26-May-2020',
    },
    {
        id: '3',
        Task: 'Handle Door Specs',
        // Assigned_To: 'Roman',
        // Assignee: 'Romona',
        // Status: 'To-do',
        // Priority: 'Low',
        Due_Date: '27-May-2020',
    },
    {
        id: '4',
        Task: 'morbi',
        // Assigned_To: 'Gawen',
        // Assignee: 'Kai',
        // Status: 'Done',
        // Priority: 'High',
        Due_Date: '23-Aug-2020',
    },
    {
        id: '5',
        Task: 'proin',
        // Assigned_To: 'Bondon',
        // Assignee: 'Antoinette',
        // Status: 'In Progress',
        // Priority: 'Medium',
        Due_Date: '05-Jan-2021',
    },
];

const columnsFromBackend = {
    [uuidv4()]: {
        title: 'To-do',
        items: data,
    },
    [uuidv4()]: {
        title: 'In Progress',
        items: [],
    },
    [uuidv4()]: {
        title: 'Done',
        items: [],
    },
};

const EditableHeader = React.memo((props) => {

    const otherElemHovered = useRef(false);
    const [visibleMenuElements, setVisibleMenuElements] = useState({ data: [], mainCat: null, });
    const [columns, setColumns] = useState({});

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
        }
    };

    const unhoverMenuChildItem = (val) => {
        setTimeout(() => {
            let children = visibleMenuElements.data.find(
                (e) => e.parentId === val.mainCatId
            );
            if (children || otherElemHovered.current) {
                return;
            }
            let newHoveredElements = visibleMenuElements.data.filter(
                (e) => e.mainCatId !== val.mainCatId
            );
            setVisibleMenuElements({
                ...visibleMenuElements,
                data: newHoveredElements,
            });
        }, 100);
    };

    const newMenuItemHover = (val, parentId, root) => {
        let elemHovered = visibleMenuElements.data.find(
            (e) => e.mainCatId === val.MainCatId
        );
        if (!val.child) {
            let lastElem = visibleMenuElements.data[
                visibleMenuElements.data?.length - 1
            ].data.find((e) => e.MainCatId === val.MainCatId);
            if (lastElem) {
                return;
            }
            let newElements = visibleMenuElements.data.filter(
                (val, i) => i !== visibleMenuElements.data.length - 1
            );
            setVisibleMenuElements({
                ...visibleMenuElements,
                data: newElements,
            });
            otherElemHovered.current = true;
        }
        if (val.child && val.child.length > 0 && !elemHovered) {
            if (root) {
                setVisibleMenuElements({
                    mainCat: val.MainCatId,
                    data: [
                        {
                            data: val.child,
                            parentId: null,
                            mainCatId: val.MainCatId,
                            from: val.from,
                        },
                    ],
                });
            } else {
                let childrenToDelete = [];
                let findChildren = (parentId) => {
                    let newHoveredElements = visibleMenuElements.data.find(
                        (e) => e.parentId === parentId
                    );
                    if (newHoveredElements) {
                        childrenToDelete.push(newHoveredElements.mainCatId);
                        findChildren(newHoveredElements.mainCatId);
                    } else {
                        return;
                    }
                };
                findChildren(parentId);
                let newHoveredElements = visibleMenuElements.data.filter(
                    (e) => e.parentId !== parentId
                );
                for (let childToDelete of childrenToDelete) {
                    newHoveredElements = newHoveredElements.filter(
                        (e) => e.mainCatId !== childToDelete
                    );
                }
                newHoveredElements.push({
                    data: val.child,
                    parentId: parentId,
                    mainCatId: val.MainCatId,
                    from: val.from,
                });
                setVisibleMenuElements({
                    ...visibleMenuElements,
                    data: newHoveredElements,
                });
                otherElemHovered.current = true;
            }
        }
    };

    useEffect(() => {
        let newColumnObj = {}
        visibleMenuElements.data.forEach((data) => {
            newColumnObj[uuidv4()] = { title: 'test', items: data.data, mainCatId: data.mainCatId, from: data.from }
        })

        setColumns(newColumnObj)
    }, [visibleMenuElements])

    return (
        <div
            className={styles.header_menu}
        >
            {props.menu?.map((val, i) => {
                if (val.from === 'venue_page') {
                    return
                }
                return (
                    <h2
                        className={styles.menu_item_container}
                        key={i}
                        onMouseLeave={(e) => {
                            setVisibleMenuElements({ data: [], mainCat: null, })
                        }}
                    >
                        <div>
                            <div
                                className={styles.maincat_container}
                            >
                                <img
                                    className={styles.maincat_icon}
                                    src={`${val.from === 'menu' ? props.menuBaseImageLink : props.categoryMenuBaseImageLink}${val.image_ico}`}
                                    // src={val.image}
                                    title={val.MainCatName}
                                    alt={val.MainCatName}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.className = styles.empty_maincat_icon;
                                    }}
                                />

                                <div
                                    className={clsx(styles.menu_item, !Const.isOurDesiStore && styles.liquor_font)}
                                    onClick={(e) => {
                                        if (val.from !== 'venue_page') {
                                            newMenuItemHover(val, null, true);
                                        }
                                    }}
                                >
                                    <div>
                                        {val.MainCatName}
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 5
                                }}
                            >
                                <IconButton
                                    onClick={() => {
                                        props.setShowEdit(true)
                                    }}
                                >
                                    <Edit
                                        style={{
                                            fontSize: 15,
                                            color: 'black',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => {
                                        // props.setShowEdit(true)
                                    }}
                                >
                                    <DeleteForever
                                        style={{
                                            fontSize: 15,
                                            color: 'black',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </IconButton>
                            </div>
                        </div>

                        <div
                            style={{
                                position: "absolute",
                                display:
                                    `${val.MainCatId}-${val.from}` !==
                                        `${visibleMenuElements?.mainCat}-${visibleMenuElements.data[0]?.from}`
                                        ? "none"
                                        : "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            <DragDropContext
                                onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                            >
                                <div>
                                    <div
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        {Object.entries(columns).map(([columnId, column], index) => {
                                            return (
                                                <Droppable key={columnId} droppableId={columnId}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            className={styles.menu_category_container}
                                                        >
                                                            {column.items.map((item, index) => (
                                                                <Draggable key={item.MainCatId.toString()} draggableId={item.MainCatId.toString()} index={index}>
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div
                                                                                key={index}
                                                                                className={styles.hovered_menu_item}
                                                                                onClick={(e) =>
                                                                                    newMenuItemHover(
                                                                                        item,
                                                                                        column.mainCatId,
                                                                                        false
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={styles.child1_container}
                                                                                >
                                                                                    <img
                                                                                        className={styles.child1_icon}
                                                                                        src={`${column.from === 'menu' ? props.menuBaseImageLink : props.categoryMenuBaseImageLink}${item.image}`}
                                                                                        title={item.MainCatName}
                                                                                        onError={({ currentTarget }) => {
                                                                                            currentTarget.onerror = null;
                                                                                            currentTarget.className = styles.empty_child_icon;
                                                                                        }}
                                                                                    />

                                                                                    <div
                                                                                        className={styles.child1_catname}
                                                                                    >
                                                                                        <div
                                                                                            style={{ marginRight: "6px" }}
                                                                                        >
                                                                                            {item.MainCatName}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {item.child &&
                                                                                    item.child.length > 0 && (
                                                                                        <ArrowForwardIosRounded
                                                                                            style={{ transform: "scale(0.6)" }}
                                                                                        />
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            );
                                        })}
                                    </div>
                                </div>
                            </DragDropContext>
                        </div>
                    </h2>
                )
            })}

            {/* {visibleMenuElements.data?.map((val, i) => {
                                    return (
                                        <div
                                            key={i}
                                            // onMouseLeave={(e) => unhoverMenuChildItem(val)}
                                            className={styles.menu_category_container}
                                        >
                                            {val.data.map((hoveredVal, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={styles.hovered_menu_item}
                                                        onClick={(e) =>
                                                            newMenuItemHover(
                                                                hoveredVal,
                                                                val.mainCatId,
                                                                false
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={styles.child1_container}
                                                        >
                                                            <img
                                                                className={styles.child1_icon}
                                                                src={`${val.from === 'menu' ? props.menuBaseImageLink : props.categoryMenuBaseImageLink}${hoveredVal.image}`}
                                                                title={hoveredVal.MainCatName}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.className = styles.empty_child_icon;
                                                                }}
                                                            />

                                                            <div
                                                                className={styles.child1_catname}
                                                            >
                                                                <div
                                                                    style={{ marginRight: "6px" }}
                                                                >
                                                                    {hoveredVal.MainCatName}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {hoveredVal.child &&
                                                            hoveredVal.child.length > 0 && (
                                                                <ArrowForwardIosRounded
                                                                    style={{ transform: "scale(0.6)" }}
                                                                />
                                                            )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })} */}
        </div>
    )
})

export default EditableHeader
