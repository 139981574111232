/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FormResponses.module.scss';
import FormResponsesTable from './FormResponsesTable/FormResponsesTable';
import { Button } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton';
import ExcelIcon from '../../../assets/images/excel_icon.png';

const getDate = (date) => {
  const dt = new Date(date);
  return dt;
};

function FormResponses(props) {
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState('created_at');
  console.log('🚀OUTPUT --> sortBy:', sortBy);
  
  const [type_id, setType_id] = useState('all');
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, 'week')?.format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs()?.format('YYYY-MM-DD'));

  const handleChangeSort = (event) => {
    setSortBy(event.target.value);
    let params = { page: props.listings?.pagination?.current_page };
    if (event.target.value === 'created_at') {
      // params['sort-by-order'] = event.target.value;
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = event.target.value;
    } else if (event.target.value) {
      params['sort-by-order'] = event.target.value;
    }

    props.fetchListing(params);
  };

  const handleFetchListing = (event) => {
    let params = { page: props.listings?.pagination?.current_page };


    if (type_id && type_id !== 'all') {
      params.room_id = type_id;
    }
    if (sortBy === 'created_at') {
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = sortBy;
    } else if (sortBy) {
      params['sort-by-order'] = sortBy;
    }
    props.fetchListing(params);
  };

  const handleChangePage = (page_no) => {
    let params = { page: page_no };

  
    if (type_id && type_id !== 'all') {
      params.room_id = type_id;
    }
    if (sortBy === 'created_at') {
      params['sort-by-order'] = 'DESC';
      params['sort-by'] = sortBy;
    } else if (sortBy) {
      params['sort-by-order'] = sortBy;
    }
    props.fetchListing(params);
  };
  
  const handleChangeDates = (date, type) => {
    type === 'since'
      ? setFromDate(date)
      : type === 'until'
      ? setToDate(date)
      : null;
    // setFeePlanId(event.target.value);
    let params = {
      'sort-by-order': 'DESC',
      'sort-by': 'created_at',
    };
    
    if (type === 'since') {
      params['since'] = date;
    } else if (type === 'until') {
      params['until'] = date;
    }
    if (type === 'until' && fromDate) {
      params.since = fromDate;
    }
    if (type === 'since' && toDate) {
      params.until = toDate;
    }
  
    console.log(params);
    props.fetchListing(params);
  };

  return (
    <div className={styles.school_list}>
      <div className={styles.responses_header}>
        <div className={styles.responses_container}>
          <div className={styles.responses_title}>
            {props.form?.data?.title} ({props.listings?.data?.length})
          </div>

          <div className={styles.buttons}>
            <Button
              variant='outlined'
              color='inherit'
              disableElevation
              sx={{ backgroundColor: '#fff' }}
              onClick={() => navigate(`/forms/edit/${props.form?.data?.id}`)}
            >
              Edit form fields
            </Button>
          </div>
        </div>
        <div
          className={styles.responses_container}
          style={{ marginTop: '1rem' }}
        >
          <DateFiltersElement {...{ fromDate, toDate, handleChangeDates }} />
          {props.listings?.data?.length > 0 && (
            <GenericLoadingButton
              onClick={() => props.downloadReport({
                since: fromDate,
                until: toDate
              },props.form?.data?.title)}
              style={{
                background: 'white',
                color: 'black',
                boxShadow:'none',
                padding: '5px 12px',
                borderRadius: '5px',
                border: '1px solid #d3d3d3'

              }} 
              variant='contained' 
              loading={props.isCSVDownloadLoading}
              loadingPosition='end'
              size='medium'
              >
              <img src={ExcelIcon} width={30} height={30} />
                Download Responses
            </GenericLoadingButton>
          )}
        </div>
      </div>

      <div>
        {/* {mobileSizeCheck ? (
          <FormResponsesMobileItems
            {...{
              handleFetchListing,
              handleChangePage,
              totalPages: props?.listings?.pagination?.last_page ?? 1,
              pageNo: props?.listings?.pagination?.current_page ?? 1,
            }}
            {...props}
          />
        ) : (
          )} */}
          <FormResponsesTable
            {...{
              handleFetchListing,
              handleChangePage,
              totalPages: props?.listings?.pagination?.last_page ?? 1,
              pageNo: props?.listings?.pagination?.current_page ?? 1,
            }}
            {...props}
          />
      </div>
    </div>
  );
}

export default FormResponses;


const DateFiltersElement = ({ fromDate, toDate, handleChangeDates }) => {
  const CalIcon = () => {
    return (
      <svg
        width='14'
        height='18'
        viewBox='0 0 18 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5 12C4.71667 12 4.479 11.904 4.287 11.712C4.09567 11.5207 4 11.2833 4 11C4 10.7167 4.09567 10.479 4.287 10.287C4.479 10.0957 4.71667 10 5 10C5.28333 10 5.521 10.0957 5.713 10.287C5.90433 10.479 6 10.7167 6 11C6 11.2833 5.90433 11.5207 5.713 11.712C5.521 11.904 5.28333 12 5 12ZM9 12C8.71667 12 8.47933 11.904 8.288 11.712C8.096 11.5207 8 11.2833 8 11C8 10.7167 8.096 10.479 8.288 10.287C8.47933 10.0957 8.71667 10 9 10C9.28333 10 9.521 10.0957 9.713 10.287C9.90433 10.479 10 10.7167 10 11C10 11.2833 9.90433 11.5207 9.713 11.712C9.521 11.904 9.28333 12 9 12ZM13 12C12.7167 12 12.4793 11.904 12.288 11.712C12.096 11.5207 12 11.2833 12 11C12 10.7167 12.096 10.479 12.288 10.287C12.4793 10.0957 12.7167 10 13 10C13.2833 10 13.5207 10.0957 13.712 10.287C13.904 10.479 14 10.7167 14 11C14 11.2833 13.904 11.5207 13.712 11.712C13.5207 11.904 13.2833 12 13 12ZM2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4C0 3.45 0.195667 2.97933 0.587 2.588C0.979 2.196 1.45 2 2 2H3V0H5V2H13V0H15V2H16C16.55 2 17.021 2.196 17.413 2.588C17.8043 2.97933 18 3.45 18 4V18C18 18.55 17.8043 19.021 17.413 19.413C17.021 19.8043 16.55 20 16 20H2ZM2 18H16V8H2V18ZM2 6H16V4H2V6Z'
          fill='#505050'
        />
      </svg>
    );
  };
  return (
    <div className='tw-flex tw-items-center tw-gap-2'>
      <div className='tw-font-semibold tw-text-sm'>
        Response Date Form :{' '}
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(fromDate)}
          onChange={(newValue) => {
            // const value = new Date(newValue?.toUTCString());
            if (
              JSON.stringify(newValue) &&
              JSON.stringify(newValue) !== 'null'
            ) {
              if (toDate && newValue > getDate(toDate)) {
                toast.error('From date should be less then To Date');
              } else {
                handleChangeDates(newValue?.format('YYYY-MM-DD'), 'since');
                // handleChangeDates(format(newValue?.$d, 'yyyy-LL-dd'), 'since');
              }
            }
          }}
          components={{
            OpenPickerIcon: CalIcon,
          }}
          sx={{
            m: 1,
            background: '#E8ECF1',
            borderRadius: '20px',
            width: '130px',
            height: '40px',
            fontSize: '12px',
            flexDirection: 'row-reverse',
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '.MuiInputBase-input': {
              fontSize: '12px',
            },
          }}
        />
      </LocalizationProvider>
      to
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(toDate)}
          onChange={(newValue) => {
            // const value = new Date(newValue?.toUTCString());
            if (
              JSON.stringify(newValue) &&
              JSON.stringify(newValue) !== 'null'
            ) {
              if (fromDate && newValue < getDate(fromDate)) {
                toast.error('To date should be greater then From Date');
              } else {
                handleChangeDates(newValue?.format('YYYY-MM-DD'), 'until');
              }
            }
          }}
          components={{
            OpenPickerIcon: CalIcon,
          }}
          sx={{
            m: 1,
            background: '#E8ECF1',
            borderRadius: '20px',
            width: '130px',
            height: '40px',
            fontSize: '12px',
            flexDirection: 'row-reverse',
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '.MuiInputBase-input': {
              fontSize: '12px',
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};