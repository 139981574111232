import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import AnalyticsTable from '../../AnalyticsTable/AnalyticsTable'
import BarGraph from '../../Charts/BarGraph'
import LineGraph from '../../Charts/LineGraph'
import PieChart from '../../Charts/PieChart'
import MarketingInfoChip from '../../Library/MarketingInfoChip/MarketingInfoChip'
import MarketingFilters from '../../MarketingFilters/MarketingFilters'
import styles from './EmailAnalytics.module.scss'
import axios from '../../../utils/axiosConfig'
import { useOutletContext } from 'react-router-dom'
import { Pagination } from '@mui/material'

// function createData(profile, followers, impressions, engagement) {
//   return  {
//     "id": 1,
//     "campaign_name": "-",
//     "unique_open_rate": null,
//     "open_rate": 3,
//     "email_sent": 1,
//     "opens": 2,
//     "clicks": 5,
//     "click_rate": 6,
//     "send_time": "2022-10-04 07:42:45",
//     "unsubscribed": null,
//     "hard_bounces": null
// };
// }

// const rows = [
//   createData('Facebook', 159, 6.0, 24),
//   createData('Instagram', 237, 9.0, 37),
//   createData('Twitter', 262, 16.0, 24),
// ];


const EmailAnalytics = () => {

  const { fetchNewMailChimpData } = useOutletContext()

  const [periodState, setPeriodState] = useState(7)

  const [startDate, setStartDate] = useState(format((new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
  const [pstartDate, setpStartDate] = useState(format((new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
  const [endDate, setEndDate] = useState(format((new Date()), "yyyy-MM-dd"))
  const [pendDate, setpEndDate] = useState(format((new Date()), "yyyy-MM-dd"))
  const [isCompare, setIsCompare] = useState(false)
  const [isPieEmpty, setIsPieEmpty] = useState(false)
  const [campaignPagination, setCampaignPagination] = useState({})
  const [rows, setRows] = useState([])
  const [pieData, setPieData] = useState({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  })
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [],
  })
  const [overAllInsights, setOverAllInsights] = useState([
    {
      id: 'email_sent',
      label: 'Email Sent',
      value: 0,
      isUp: true,
      moveValue: 0
    },
    {
      id: 'open_rate',
      label: 'Open Rate',
      value: 0,
      isUp: true,
      moveValue: 0
    },
    {
      id: 'clicks',
      label: 'Clicks',
      value: 0,
      isUp: true,
      moveValue: 0
    },
    {
      id: 'subscriber',
      label: 'Subscribers',
      value: 0,
      isUp: true,
      moveValue: 0
    },
    {
      id: 'un-subscriber',
      label: 'Un-Subscribers',
      value: 0,
      isUp: true,
      moveValue: 0
    },
  ])

  const fetchMailChimpInsights = (since, until) => {
    let params = {
      since: startDate,
      until: endDate,
    }

    if (isCompare) {
      params.since_previous = pstartDate
      params.until_previous = pendDate
    }

    axios.get('/mailchimp-campaign/dashboard', {
      params: params
    })
      .then((response) => {
        setOverAllInsights((prevState) => {
          let newState = [...prevState]
          newState = newState.map((data) => {
            return {
              ...data,
              value: response.data?.data?.total[data.id],
              isUp: response.data?.data?.total[data.id] > response.data?.data?.total_previous[data.id],
              moveValue: (response.data?.data?.total[data.id] > response.data?.data?.total_previous[data.id]) ? parseFloat(response.data?.data?.total[data.id]) - parseFloat(response.data?.data?.total_previous[data.id]) : parseFloat(response.data?.data?.total_previous[data.id]) - parseFloat(response.data?.data?.total[data.id])
            }
          })
          return newState
        })
      })
  }

  const fetchMailChimpInsightsByCampaign = () => {
    axios.get('/mailchimp-campaign/by-campaign', {
      params: {
        since: startDate,
        until: endDate,
      }
    })
      .then((response) => {
        let result = response.data.data.result
        setBarData({
          labels: result.map((data) => data.campaign_name),
          datasets: [
            {
              label: 'Total Open Rate',
              data: result.map((data) => data.open_rate),
              backgroundColor: '#49ABE0',
              margin: 0,
              padding: 0,
              categoryPercentage: 0.5,
              barPercentage: 1
            },
            {
              label: 'Total Unique Open Rate',
              data: result.map((data) => data.unique_open_rate),
              backgroundColor: '#171717',
              margin: 0,
              padding: 0,
              categoryPercentage: 0.5,
              barPercentage: 1
            }
          ]
        })
      })
  }

  const fetchCampaignByClick = (e, page) => {
    axios.get('/mailchimp-campaign/by-click', {
      params: {
        page: page
      }
    })
      .then((response) => {
        let result = response.data.data.result

        setCampaignPagination(response.data.data.pagination)
        let test = result.filter((data) => data.opens != 0)
        if (Boolean(test.length)) {
          setIsPieEmpty(false)
        } else {
          setIsPieEmpty(true)
        }
        setPieData({
          labels: result.map((data) => data.campaign_name),
          datasets: [
            {
              label: 'Campaign',
              data: result.map((data) => data.opens),
              backgroundColor: [
                '#ff6384',
                '#ff9f40',
                '#ffcd56',
                '#4bc0c0',
                '#36a2eb',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
              ],
              borderWidth: 1,
            },
          ],
        })
      })
  }

  const fetchCampaignDetailList = () => {
    axios.get('/mailchimp-campaign/detail-list')
      .then((response) => {
        let result = response.data.data.result
        setRows(() => {
          let newState = result.map((data) => ({
            campaignName: data.campaign_name,
            uniqueOpenRate: data.unique_open_rate,
            openRate: data.open_rate,
            emailSent: data.email_sent,
            opens: data.opens,
            clicks: data.clicks,
            clickRate: data.click_rate,
            unsubscribed: data.unsubscribed,
            hard_bounces: data.hard_bounces
          }))
          return newState
        })
      })
  }

  useEffect(() => {
    fetchMailChimpInsights()
    fetchMailChimpInsightsByCampaign()
    fetchCampaignByClick()
    fetchCampaignDetailList()
  }, [])

  return (
    <div>
      <MarketingFilters
        periodState={periodState}
        setPeriodState={setPeriodState}
        isCompare={isCompare}
        setIsCompare={setIsCompare}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        pstartDate={pstartDate}
        pendDate={pendDate}
        setpEndDate={setpEndDate}
        setpStartDate={setpStartDate}
        isRefresh={true}
        refresh={() => {
          fetchNewMailChimpData()
            .then(() => {
              fetchMailChimpInsights()
              fetchMailChimpInsightsByCampaign()
              fetchCampaignByClick()
              fetchCampaignDetailList()
            })
        }}
        onShowClick={() => {
          fetchMailChimpInsightsByCampaign()
          fetchMailChimpInsights()
        }}
      />

      <div
        className={styles.info_container}
      >
        {overAllInsights.map((data) => (
          <MarketingInfoChip
            label={data.label}
            isUp={data.isUp}
            value={data.value}
            moveValue={data.moveValue}
          />
        ))}
      </div>

      <div>
        <BarGraph
          data={barData}
        />
      </div>

      <div
        className={styles.line_pie_container}
      >
        {/* <div>
          <LineGraph />
        </div> */}

        {!isPieEmpty && (
          <div>
            <div
              style={{
                textAlign: 'center',
                fontSize: 18,
                fontWeight: 600
              }}
            >
              Opens comparison in pie chart
            </div>
            <PieChart
              data={pieData}
            />
          </div>
        )}
      </div>

      <AnalyticsTable
        title="Campaign Data"
        headings={
          [
            {
              label: 'Name'
            },
            {
              label: 'Unique Open Rate'
            },
            {
              label: 'Open Rate'
            },
            {
              label: 'Email Sent'
            },
            {
              label: 'Opens'
            },
            {
              label: 'Clicks'
            },
            {
              label: 'Click Rate'
            },
            {
              label: 'Unsubscribed'
            },
            {
              label: 'Hard bounces'
            },
          ]
        }
        isEmail={true}
        rows={rows}
      />

      {(campaignPagination.last_page > 1) && (
        <div
          style={{
            marginTop: 20
          }}
        >
          <Pagination
            count={campaignPagination.last_page}
            onChange={fetchCampaignByClick}
          />
        </div>
      )}
    </div>
  )
}

export default EmailAnalytics 