import { MenuItem, Select } from '@mui/material'
import React from 'react'
import styles from './SortBySelect.module.scss';

const SortBySelect = (props) => {
    return (
        <Select
            className={styles.select}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            size="small"
            fullWidth={props.fullWidth}
        >
            {props.menuItems?.map((data) => (
                <MenuItem
                    key={data.name}
                    value={data.value}
                >
                    {data.name}
                </MenuItem>
            ))}
        </Select>
    )
}

export default SortBySelect