import { Close, East } from '@mui/icons-material'
import { Button, IconButton, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { getImage2 } from '../../utils/helpers'
import CustomProgressBar from './CustomProgressBar/CustomProgressBar'
import StepChip from './StepChip/StepChip'
import axios from '../../utils/axiosConfig'
import AppContext from '../../utils/context/appContext'
import MarketingContext from '../Marketing2/Util/marketingContext'
import { useNavigate } from 'react-router-dom'
import routePaths from '../../config/route-paths'

const HomePageSteps = () => {

    const navigate = useNavigate()
    const { user } = useContext(AppContext)
    const { subDomain, isTemplateSelected, templateId, externalWebsite } = useContext(MarketingContext)
    const [status, setStatus] = useState({})
    const [totalDone, setTotalDone] = useState(0)
    const [greeting, setGreeting] = useState('Hello')
    const [hideBlueBox, setHideBlueBox] = useState(false)
    const lg = useMediaQuery('(max-width:1000px)');

    const fetchStatus = () => {
        axios.get('/dashboard/check')
            .then((res) => {
                setStatus(res?.data?.data?.results)
                setTotalDone(Object.values(res?.data?.data?.results)?.filter((data) => data)?.length)
            })
    }

    const greetUser = () => {
        const now = new Date();
        const hour = now.getHours();

        let greet;
        if (hour < 12) {
            greet = "Good morning";
        } else if (hour < 18) {
            greet = "Good afternoon";
        } else {
            greet = "Good evening";
        }

        setGreeting(greet)
    }

    useEffect(() => {
        fetchStatus()
        greetUser()
    }, [])

    return (
        <div
            className="tw-p-5 tw-flex tw-flex-col tw-gap-4"
        >
            <div
                className="tw-flex tw-justify-between"
            >
                <div
                    className="tw-font-semibold tw-text-2xl"
                >
                    {greeting}, {user?.data?.name}
                </div>

                {/* <Button
                    style={{
                        color: 'black'
                    }}
                    variant="text"
                    endIcon={<East />}
                >
                    Take me to dashboard
                </Button> */}
            </div>

            {!hideBlueBox && (
                <div
                    className="tw-p-3 tw-relative tw-bg-[#1F57C3] tw-flex tw-rounded-lg"
                >
                    <div
                        className="tw-text-white"
                    >
                        <div
                            className="tw-text-sm"
                        >
                            Hurray, your {user?.data?.venue_type} is live with us {' '}
                            {(externalWebsite || subDomain) && (
                                <>
                                    at <b
                                        className="tw-text-white"
                                    >
                                        {externalWebsite ? (
                                            <a
                                                className="tw-underline tw-text-white"
                                                href={externalWebsite}
                                                target="_blank"
                                            >
                                                {externalWebsite}
                                            </a>
                                        ) : (
                                            <a
                                                className="tw-underline tw-text-white"
                                                href={subDomain}
                                                target="_blank"
                                            >
                                                {subDomain}
                                            </a>
                                        )}
                                    </b>,
                                </>
                            )}
                        </div>
                        <div
                            className="tw-text-sm"
                        >
                            but customers cannot search it yet. Connect your custom web address and let customers know your presence.
                        </div>

                        <div
                            className="tw-mt-3"
                        >
                            <Button
                                style={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    borderRadius: 30,
                                    fontSize: 14,
                                    width: 190
                                }}
                                variant="contained"
                                onClick={() => {
                                    if (isTemplateSelected) {
                                        navigate(routePaths.manageSite2)
                                    } else {
                                        navigate(routePaths.websitegetStarted);
                                    }
                                }}
                            >
                                Customize my Website
                            </Button>
                        </div>
                    </div>
                    <div>
                        {!lg && (
                            <img
                                className="tw-h-28 tw-w-32"
                                src={getImage2('rocket.png-1707897718.png')}
                            />
                        )}
                    </div>

                    <div
                        className="tw-absolute -tw-top-2 -tw-right-2 sm:tw-top-1 sm:tw-right-1"
                    >
                        <IconButton
                            onClick={() => {
                                setHideBlueBox(true)
                            }}
                        >
                            <Close
                                style={{
                                    color: 'white'
                                }}
                            />
                        </IconButton>
                    </div>
                </div>
            )}

            <div
                className="tw-font-medium"
            >
                Follow the steps below to get up and running. Finish as many as you can!
            </div>

            <div
                className="tw-flex tw-gap-2"
            >
                {[1, 2, 3, 4, 5, 6].map((data) => (
                    <CustomProgressBar value={(data <= totalDone) ? 100 : 0} />
                ))}
            </div>

            <div
                className="tw-flex tw-flex-col tw-gap-3"
            >
                <StepChip
                    isDone={status.subdomain}
                    title="Customize your online store Website & App (Apple and Android)"
                    description="Boost online visibility, attract customers, and streamline the ordering process."
                    link={isTemplateSelected ? `/website-builder/manage-site` : routePaths.websitegetStarted}
                />

                <StepChip
                    isDone={status.productCount}
                    title="Build your menu"
                    description="Spotlight special dishes attracts potential customers and caters to a wider audience"
                    link={routePaths.menu}
                />

                <StepChip
                    isDone={status.restaurantSetting}
                    title="Edit your restaurant settings"
                    description="Ensure accurate info for customer trust, seamless orders, and improved visibility."
                    link="/MyStoreSettings"
                />

                <StepChip
                    isDone={status.venueHours}
                    title="Edit your order settings"
                    description="Attract local customers, and optimize order fulfillment for success."
                    link="/MyStoreSettings"
                />

                <StepChip
                    isDone={status.onlinePayment}
                    title="Connect your bank account to start accepting payments"
                    description="Money goes into your account directly."
                    link="/MyStoreSettings"
                />

                <StepChip
                    isDone={status.customDomain}
                    title="Connect your custom web address and let customers know your presence."
                    description="Your brand, your customers."
                    link={isTemplateSelected ? `/website-builder/manage-site` : routePaths.websitegetStarted}
                />

            </div>
        </div>
    )
}

export default HomePageSteps