import { Dialog, IconButton, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import StyledTableCell from '../Library/StyledTableCell/StyledTableCell'
import styles from './Users.module.scss'
import axios, { nodeAxios } from '../../utils/axiosConfig'
import StyledTableRow from '../Library/StyledTableRow/StyledTableRow'
import GenericButton from '../Library/GenericButton/GenericButton'
import { DeleteForever, Edit, PersonOff, Search } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useConfirm } from 'material-ui-confirm'
import AddUsersDialog from '../Dialogs/AddUsersDialog/AddUsersDialog'
import InputField from '../Library/InputField/InputField'
import Arrows from '../../assets/arrows.png'
import { debounce } from 'lodash'
import Loader from '../Library/Loader'
import { useOutletContext } from 'react-router-dom'

const headings = [
    '',
    'Full Name',
    'Email',
    'Phone Number',
    'Checkin Code',
    'Role',
    ''
]

const Users = () => {

    const { isActionsDisabled } = useOutletContext()

    const confirm = useConfirm();

    const [users, setUsers] = useState([])
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [showEditUser, setShowEditUser] = useState(false)
    const [showAddUser, setShowAddUser] = useState(false)
    const [editId, setEditId] = useState({})
    const [query, setQuery] = useState('')
    const [sortByOrder, setSortByOrder] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [pagination, setPagination] = useState({})
    const [page, setPage] = useState(1)

    const removeImage = () => {
        setEditId((prevState) => ({ ...prevState, image: null }))
    }

    const fetchUsersList = (q) => {
        setIsLoading(true)
        nodeAxios.get('/users/list', {
            params: {
                q: q,
                page: page
            }
        })
            .then((response) => {
                setUsers(response.data.data)
                setPagination(response.data.pagination)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    useEffect(() => {
        fetchUsersList()

    }, [page])


    const handleDelete = (id) => {

        confirm({
            title: 'Are you sure you want to delete this user'
        })
            .then(() => {
                axios.delete(`/driver/delete/${id}`)
                    .then(() => {
                        toast.success('User Deleted')
                        fetchUsersList()
                    })
            })
    }

    useEffect(() => {
        fetchUsersList()
    }, [sortByOrder])

    const debounceSearchUser = useCallback(debounce(fetchUsersList, 1000), [])

    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.search_container}
            >
                <div
                    style={{
                        width: 220
                    }}
                >
                    <InputField
                        placeholder="Search Users"
                        value={query}
                        onChange={(e) => {
                            setQuery(e.target.value)
                            debounceSearchUser(e.target.value)
                        }}
                        onIconButtonClick={e => debounceSearchUser(query)}
                        showIconButton={true}
                        endIcon={(
                            <Search
                                style={{
                                    color: '#ef2d2c'
                                }}
                            />
                        )}
                    />
                </div>

                <GenericButton
                    variant="contained"
                    onClick={() => {
                        setShowAddUser(true)
                    }}
                    disabled={isActionsDisabled}
                >
                    Add User
                </GenericButton>

            </div>

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {Boolean(users?.length) ? (
                        <TableContainer
                            component={Paper}
                        >
                            <Table
                                sx={{
                                    minWidth: 700
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        {headings.map((data) => (
                                            <StyledTableCell
                                                style={{
                                                    color: 'black'
                                                }}
                                            // onClick={() => {
                                            //     fetchUsersList('',)
                                            // }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => setSortByOrder((prevState) => !prevState)}
                                                >
                                                    {data}
                                                    {(data === 'Full Name') && (
                                                        <div
                                                            style={{ width: '10px', marginLeft: '6px', cursor: 'pointer' }}
                                                        >
                                                            <img
                                                                src={Arrows}
                                                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((row) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell component="th" scope="row">
                                                <div>
                                                    <img
                                                        style={{
                                                            maxWidth: 100,
                                                            objectFit: 'contain'
                                                        }}
                                                        height={60}
                                                        src={row.image}
                                                    />
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {row.name}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.email}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.contact_no}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.code}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {{
                                                    0: "Super Admin",
                                                    1: 'Admin',
                                                    2: 'Manager',
                                                    3: 'Driver'
                                                }[row.biz_users_role]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <div
                                                    className={styles.actions_container}
                                                >
                                                    <GenericButton
                                                        style={{
                                                            backgroundColor: '#82F171',
                                                            color: 'black'
                                                        }}
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() => {
                                                            setEditId({
                                                                ...row,
                                                                scopes: row.scopes?.split(',') || []
                                                            })
                                                            setShowChangePassword(true)
                                                        }}
                                                    >
                                                        Change password
                                                    </GenericButton>

                                                    <IconButton
                                                        onClick={() => {
                                                            setEditId({
                                                                ...row,
                                                                scopes: row.scopes?.split(',') || []
                                                            })
                                                            setShowEditUser(true)
                                                        }}
                                                    >
                                                        <Edit />
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => {
                                                            handleDelete(row.id)
                                                        }}
                                                    >
                                                        <DeleteForever />
                                                    </IconButton>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: 25,
                                padding: 20
                            }}
                        >
                            <div>
                                <PersonOff
                                    fontSize="large"
                                />
                            </div>
                            <div>
                                No users found
                            </div>
                        </div>
                    )}

                    <Pagination
                        count={pagination.last_page}
                        page={pagination.current_page}
                        onChange={(event, value) => {
                            setPage(value)
                        }}
                    />
                </>
            )}

            <Dialog
                open={showAddUser}
                onClose={() => {
                    setShowAddUser(false)
                }}
            >
                <AddUsersDialog
                    page="Add"
                    removeImage={removeImage}
                    onClose={() => {
                        setShowAddUser(false)
                        setEditId({})
                        fetchUsersList()
                    }}
                />
            </Dialog>

            <Dialog
                open={showEditUser}
                onClose={() => {
                    setShowEditUser(false)
                    setEditId({})
                }}
            >
                <AddUsersDialog
                    page="Edit"
                    isEdit={true}
                    editId={editId}
                    removeImage={removeImage}
                    onClose={() => {
                        setShowEditUser(false)
                        setEditId({})
                        fetchUsersList()
                    }}
                />
            </Dialog>

            <Dialog
                open={showChangePassword}
                onClose={() => {
                    setShowChangePassword(false)
                    setEditId({})
                }}
            >
                <AddUsersDialog
                    page="Change"
                    editId={editId}
                    removeImage={removeImage}
                    onClose={() => {
                        setShowChangePassword(false)
                        setEditId({})
                        fetchUsersList()
                    }}
                />
            </Dialog>
        </div>
    )
}

export default Users