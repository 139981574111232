import { Divider, Drawer, IconButton, List, ListItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import routePaths from '../../config/route-paths';
import { classname, cns } from '../../utils/bem';
import Const from '../helpers/const';
import MenuIcon from '@mui/icons-material/Menu';
import './NotProtectedLayout.scss'
import esplandaLogo from '../../assets/images/esplanda-logo.png'
import GenericButton from '../Library/GenericButton/GenericButton';
import { getImage, getImage2 } from '../../utils/helpers';

const NotProtectedLayout = (props) => {

    const cn = classname('not-protected-layout')
    const [openDrawer, setOpenDrawer] = useState(false)

    const { pathname } = useLocation();

    useEffect(() => {
        const canControlScrollRestoration = 'scrollRestoration' in window.history
        if (canControlScrollRestoration) {
            window.history.scrollRestoration = 'manual';
        }

        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div
            className={cn()}
        >
            <Drawer
                anchor="top"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <List>
                    <ListItem
                        button
                    >
                        <NavLink
                            onClick={() => setOpenDrawer(false)}
                            to={Const.isOurDesiStore ? routePaths.whyOurDesiStore : routePaths.whyOurLiquorStore}
                            className={cn('mobile-nav-link')}
                            activeClassName={cn('active')}
                        >
                            Why Us
                        </NavLink>
                    </ListItem>
                    <ListItem
                        button
                    >
                        <NavLink
                            onClick={() => setOpenDrawer(false)}
                            to={routePaths.howItWorks}
                            className={cn('mobile-nav-link')}
                            activeClassName={cn('active')}
                        >
                            How It Works
                        </NavLink>
                    </ListItem>
                    <ListItem
                        button
                    >
                        <NavLink
                            onClick={() => setOpenDrawer(false)}
                            to={routePaths.pricing}
                            className={cn('mobile-nav-link')}
                            activeClassName={cn('active')}
                        >
                            Pricing
                        </NavLink>
                    </ListItem>
                    <ListItem
                        button
                    >
                        <NavLink
                            onClick={() => setOpenDrawer(false)}
                            to={routePaths.helpPage}
                            className={cn('mobile-nav-link')}
                            activeClassName={cn('active')}
                        >
                            Help & Support
                        </NavLink>
                    </ListItem>
                    <ListItem button={true}>
                        <GenericButton
                            fullWidth
                            variant="contained"
                            LinkComponent={Link}
                            to={routePaths.login}
                            btnColor="secondary"
                        >
                            Login
                        </GenericButton>
                    </ListItem>
                    <ListItem button={true}>
                        <GenericButton
                            fullWidth
                            variant="contained"
                            LinkComponent={Link}
                            to={routePaths.signup}
                            btnColor="primary"
                        >
                            Signup
                        </GenericButton>
                    </ListItem>
                </List>
            </Drawer>
            <header
                className={cn('header')}
            >
                <div className={cn('logo-menu-container')}>

                    <div
                        className={cn('desktop-menu-container')}
                    >
                        {/* <IconButton
                            className={cn('menu-icon-btn')}
                            onClick={() => setOpenDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton> */}

                        <div>
                            <img
                                className={cn('logo')}
                                src={getImage2('fullred.png-1704278730.png')}
                                onClick={()=>{
                                    window.open('https://esplanda.com','_blank')
                                  }}
                            />
                        </div>

                        <NavLink
                            style={{
                                marginLeft: '30px'
                            }}
                            to={Const.isOurDesiStore ? routePaths.whyOurDesiStore : routePaths.whyOurLiquorStore}
                            className={cn('nav-link')}
                            activeClassName={cn('active')}
                        >
                            Why Us
                        </NavLink>

                        <NavLink
                            to={routePaths.howItWorks}
                            className={cn('nav-link')}
                            activeClassName={cn('active')}
                        >
                            How It Works
                        </NavLink>

                        <NavLink
                            to={routePaths.pricing}
                            className={cn('nav-link')}
                            activeClassName={cn('active')}
                        >
                            Pricing
                        </NavLink>

                        <NavLink
                            to={routePaths.helpPage}
                            className={cn('nav-link')}
                            activeClassName={cn('active')}
                        >
                            Help & Support
                        </NavLink>
                    </div>
                    <div className={cn('menu-login-btns')}>
                        <GenericButton
                            variant="contained"
                            LinkComponent={Link}
                            to={routePaths.login}
                            btnColor="secondary"
                        >
                            Login
                        </GenericButton>

                        <GenericButton
                            variant="contained"
                            LinkComponent={Link}
                            to={routePaths.signup}
                            btnColor="primary"
                        >
                            Signup
                        </GenericButton>
                    </div>
                </div>
            </header>

            <Outlet />

            <footer
                className={cn('footer')}
            >
                <div>
                    © 2022 Esplanda Inc., All Rights Reserved
                </div>
                <div
                    className={cn('footer-link-container')}
                >
                    <Link
                        to={Const.isOurDesiStore ? routePaths.whyOurDesiStore : routePaths.whyOurLiquorStore}
                        className={cn('footer-link')}
                    >
                        Why Us
                    </Link>

                    <Divider
                        className={cn('divider')}
                        orientation='vertical'
                    />

                    <Link
                        to={routePaths.howItWorks}
                        className={cn('footer-link')}
                    >
                        How It Works
                    </Link>

                    <Divider
                        className={cn('divider')}
                        orientation='vertical'
                    />

                    <Link
                        to={routePaths.pricing}
                        className={cn('footer-link')}
                    >
                        Pricing
                    </Link>
                </div>
            </footer>
        </div>
    )
}

export default NotProtectedLayout