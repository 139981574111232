import React, { useState, useEffect } from 'react'
import { Cancel, Edit, HelpOutlineOutlined, InfoOutlined } from '@mui/icons-material'
import { IconButton, MenuItem, Drawer, Button, Table, Paper, TableContainer, List, Typography, TableHead, TableRow, TableBody } from '@mui/material'
import clsx from 'clsx'
import GenericSelectWithLabel from '../../../../Library/V2/GenericSelectWithLabel/GenericSelectWithLabel'
import styles from './PickUpDeliverySettings.module.scss'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import GenericEditDrawer from '../../../../Library/V2/GenericEditDrawer/GenericEditDrawer'
import GenericSwitch from './../../../../Library/GenericSwitch/GenericSwitch'
import TabsSection from './../../../../Library/V2/TabsSection/TabsSection';
import { ArrowForward, Close } from '@mui/icons-material';
import IncDecCounter from '../../../../pages/OrderDetails/Parts/Products/IncDecCounter/IncDecCounter'
import { FieldArray, Formik, useFormik } from 'formik'
import GenericButton from '../../../../Library/GenericButton/GenericButton'
import LightTooltip from '../../../../Library/LightTooltip/LightTooltip'
import StyledTableCell from '../../../../Library/StyledTableCell/StyledTableCell'
import StyledTableRow from '../../../../Library/StyledTableRow/StyledTableRow'
import ValueTextField from '../../../../Library/ValueTextField/ValueTextField'
import * as Yup from 'yup';
import { useOutletContext } from 'react-router-dom'
import axios, { nodeAxios } from '../../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { DeleteForever } from "@mui/icons-material";
import info_icon from '../../../../../assets/ic_outline-info.svg';
import CancelIcon from '@mui/icons-material/Cancel';
// import { Close } from '@mui/icons-material'
import { Dialog, Divider } from '@mui/material'
import { DialogActions, DialogContent, DialogTitle, useMediaQuery, outlinedInputClasses } from '@mui/material'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';

import Box from '@mui/material/Box';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const timeZonesList = [
    { title: "America/New_York" },
    { title: "America/Chicago" },
    { title: "America/Phoenix" },
    { title: "America/Los_Angeles" },
    { title: "US/Hawaii" },
    { title: "US/Alaska" },
];

const headings = [
    {
        label: (
            <LightTooltip
                title={
                    <span
                        style={{ display: 'inline-block', padding: '0 10px' }}
                    >
                        Define your delhivery radius, in miles.
                    </span>
                }
                arrow={true}
                placement="top"
            >
                <div>
                    Deliver From(Miles)
                </div>
            </LightTooltip>
        )
    },
    {
        label: (
            <LightTooltip
                title={
                    <span
                        style={{ display: 'inline-block', padding: '0 10px' }}
                    >
                        Define your delhivery radius, in miles.
                    </span>
                }
                arrow={true}
                placement="top"
            >
                <div>
                    Deliver To(Miles)
                </div>
            </LightTooltip>
        )
    },
    // {
    //     label: (
    //         <LightTooltip
    //             title={
    //                 <span
    //                     style={{ display: 'inline-block', padding: '0 15px' }}
    //                 >
    //                     If you charge flat fees, choose "Dollar". Choose "Percent", if you want us  to calculate the fees based on order value.
    //                 </span>
    //             }
    //             arrow={true}
    //             placement="top"
    //         >
    //             <div>Delivery Fee Type</div>
    //         </LightTooltip>
    //     )
    // },
    {
        label: (
            <LightTooltip
                title={
                    <span
                        style={{ display: 'inline-block', padding: '0 10px' }}
                    >
                        Enter Delivery fees here
                    </span>
                }
                arrow={true}
                placement="top"
            >
                <div>Delivery Fees</div>
            </LightTooltip>
        )
    },
    {
        label: (
            <LightTooltip
                title={
                    <span
                        style={{ display: 'inline-block', padding: '0 10px' }}
                    >
                        Only orders with value greater than this amount will be allowed to process. We recommend to have a low value here to get more delivery orders
                    </span>
                }
                arrow={true}
                placement="top"
            >
                <div>Min. Delivery Amount</div>
            </LightTooltip>
        )
    },
]
const tabs = [
    {
        value: 'pickup',
        label: 'Pickup',
    },
    {
        value: 'preparation_time',
        label: 'Preparation Time',
    },
];
const tabsDelivery = [
    {
        value: 'delivery',
        label: 'Delivery',
    },
    {
        value: 'delivery_zone',
        label: 'Delivery Zone',
    },
    {
        value: 'preparation_time',
        label: 'Preparation Time',
    },
];

const timePicker = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];


const hours = Array.from({ length: 48 }, (_, i) => {
    const hours = Math.floor(i / 2).toString().padStart(2, '0');
    const minutes = (i % 2 === 0) ? '00' : '30';
    return `${hours}:${minutes}`;
});

const findDivisors = (num) => {
    // Check if the number is less than 1
    if (num < 1) {
        return 'Input must be a positive integer greater than 0.';
    }

    // Initialize an array to hold the divisors
    let divisors = [];

    // Check every number from 1 to the target number
    // If 'num' is divisible by this number, then it's a divisor
    for (let i = 1; i <= num; i++) {
        if (num % i === 0) {
            divisors.push(i);
        }
    }

    return divisors;
}
const validationSchema = Yup.object().shape({
    preparation_times: Yup.array()
        .of(
            Yup.object().shape({
                start_time: Yup.string().required('This field is required'),
                end_time: Yup.string().required('This field is required'),
                dayname: Yup.string().required('This field is required'),
            })
        )
        .when('min', (min, schema) => (
            !isNaN(min) && min > 0 ? schema.test(
                'length-check-min',
                'Array length must be greater than or equal to minimum value',
                array => array.length >= min
            ) : schema
        ))
});
const PickUpDeliverySettings = (props) => {
    const { fetchStoreInfo } = useOutletContext()
    const [open, setOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState(props.type === 'Pickup' ? 'pickup' : 'delivery');
    const [time, setTime] = useState(0)
    const [timeDelivery, setTimeDelivery] = useState(0)
    const [prepTime, setPrepTime] = useState()
    const [prepTimeDelivery, setPrepTimeDelivery] = useState()
    const [loading, setLoading] = useState(false)
    const [showDiloag, setShowDiloag] = useState(false)
    const isMobileScreen = useMediaQuery('(max-width:600px)');
    const [isChargePickupFee, setISChargePickupFee] = useState(props.formik.values.pickupfees > 0 ? true : false)

    useEffect(() => {
        //  getPickupSetting();

    }, [])


    const handleInputChange = (time, index, hrkey, minkey) => {
        let [hour, minute] = time.split(':');
        console.log(hour, minute)
        hour = parseInt(hour) >= 10 ? parseInt(hour) : parseInt(hour[1]);
        minute = parseInt(minute) >= 10 ? parseInt(minute) : parseInt(minute[1]);
        console.log(hour, minute)
        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    [hrkey]: hour,
                    [minkey]: minute,
                    time_interval: 15
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    };

    const handleSlotChange = (time_interval, index, key) => {

        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    [key]: time_interval,
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    };


    const getDays = (times) => {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let filteredDays = days?.filter((data) => !Boolean(times?.filter(data2 => data2.dayname == data)?.length))
        return filteredDays?.map((days) => (
            <MenuItem value={days}>{days}</MenuItem>
        ))
    }

    const handleSwitch = (index) => {

        let newVenueHours = props.formik.values.venue_hours.map((val, i) => {
            if (index === i) {
                return {
                    ...val,
                    isclosed: Boolean(parseInt(val.isclosed)) ? '0' : '1'
                }
            } else {
                return val;
            }
        });

        props.formik.setFieldValue('venue_hours', newVenueHours)
    }

    const handleSubmit = (values) => {
        // setIsLoading(true)
        console.log('Api called')
        axios.post('/venue-preparation-time/create', {
            ...values,
            order_type: props.type,
            overall_preparation_time: time
        })
            .then(() => {
                callApisToLoadData()
                // toast.success('Preparation time updated')
                //props.onClose()
            })
            .catch((err) => {
                //toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                // setIsLoading(false)
            })

    }

    useEffect(() => {
        fetchPrepTime()
        fetchPrepTimeDelievery()
        //getPickupSetting()

    }, [selectedTab])

    const callApisToLoadData = () => {
        fetchPrepTime()
        fetchPrepTimeDelievery()
    }

    const fetchPrepTime = () => {
        axios.get('/venue-preparation-time', {
            params: {
                filter_order_type: 'pickup'
            }
        })
            .then((res) => {
                setPrepTime(res?.data?.data)
                setTime(res?.data?.data?.overall_preparation_time)
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                //  setIsPageLoading(false)
            })
    }
    const fetchPrepTimeDelievery = () => {
        axios.get('/venue-preparation-time', {
            params: {
                filter_order_type: 'delivery'
            }
        })
            .then((res) => {

                setPrepTimeDelivery(res?.data?.data)
                setTimeDelivery(res?.data?.data?.overall_preparation_time)
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                //  setIsPageLoading(false)
            })
    }



    const formikpreptime = useFormik({


        initialValues: props.type === 'Pickup' ? prepTime : prepTimeDelivery || {
            overall_preparation_time: '',
            preparation_times: [
                {
                    preparation_time: 0,
                    start_time: '',
                    end_time: '',
                    dayname: '',
                    order_type: props.type.toLowerCase()
                }
            ]
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true)
            axios.post('/venue-preparation-time/create', {
                ...values,
                order_type: props.type.toLowerCase(),
                overall_preparation_time: time
            })
                .then(() => {
                    toast.success('Preparation time updated')
                    setOpen(false)
                    callApisToLoadData()
                    // props.setEditObj(true);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
                .finally(() => {
                    setLoading(false)
                })

        }

        // initialValues: {
        //     ...venueDetails,
        //     venue_delivery_zone: deliveryZone,
        //     venue_hours: [...deliveryHr, ...pickupHr]
        // },
        // enableReinitialize: true,
        // onSubmit: (values) => {
        //     setLoading(true)


        // }
    })


    const handleChangeFee = (e, fee_type, index) => {
        const newValue = e.target.value;

        // Regular expression to match a valid number with up to two decimal places
        const regex = /^\d*\.?\d{0,2}$/;

        if (regex.test(newValue)) {
            if (fee_type == 'percentage') {
                if ((newValue === '' || parseFloat(newValue) <= 100)) {
                    props.formik.setFieldValue(`venue_delivery_zone[${index}].fees`, newValue)
                }
            } else {
                props.formik.setFieldValue(`venue_delivery_zone[${index}].fees`, newValue)
            }


        }
    };
    const gatDayName = (day) => {
        return {
            'Sunday': 'Sun',
            'Monday': 'Mon',
            'Tuesday': 'Tue',
            'Wednesday': 'Wed',
            'Thursday': 'Thu',
            'Friday': 'Fri',
            'Saturday': 'Sat',

        }[day] || ''
    }

    // console.log('FOrmik value', props.formik.values.pickupfees)
    return (
        <div className=' tw-flex tw-flex-col tw-gap-3 tw-text-sm tw-h-full tw-justify-center'>

            <div className='tw-flex tw-justify-between tw-flex-col lg:tw-flex-row lg:tw-items-center'>
                <div className='tw-font-bold tw-text-xl tw-w-full'>
                    <div className='tw-flex tw-justify-between tw-items-center tw-w-full'>
                        {props.title}
                        <Button
                            className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs'
                            onClick={() => { setOpen(true); }}
                        >
                            Edit {props.title}
                        </Button>
                    </div>
                </div>
                {/* <div className='tw-flex tw-justify-end'>
                    <Button 
                        className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs' 
                        onClick={() => {setOpen(true);}}
                    >
                        {(props.formik.values.venue_hours?.filter(e => e?.venuetimings !== 'delivery' && e.isclosed !== '1').length > 0) ? 'Edit ' : 'Add '} 
                        Store Hours</Button>
                </div> */}
            </div>
            <div className='tw-flex tw-flex-row tw-gap-8'>


                {props.type === 'Pickup' && !Boolean(props.formik.values.is_offer_pickup) && (
                    <>
                        <div>
                            Do you offer {props.type}?
                        </div>
                        <GenericSwitch
                            checked={Boolean(props.formik.values.is_offer_pickup)}
                            onChange={(event) => {
                                props.formik.setFieldValue('is_offer_pickup', event.target.checked ? 1 : 0)
                            }}
                        />
                    </>)}

                {props.type === 'Delivery' && !Boolean(props.formik.values.is_offer_delivery) && (
                    <>
                        <div>
                            Do you offer {props.type}?
                        </div>
                        <GenericSwitch
                            checked={Boolean(props.formik.values.is_offer_delivery)}
                            onChange={(event) => {
                                props.formik.setFieldValue('is_offer_delivery', event.target.checked ? 1 : 0)
                            }}
                        /></>)}

            </div>

            {props.title === "Pickup Settings" && Boolean(isChargePickupFee) && (
                <div className='tw-mb-2 tw-flex tw-flex-row tw-gap-4'>

                    <div class="tw-mb-4">
                        <h3 class="tw-text-base tw-font-semibold tw-mb-4">Pickup fee type</h3>

                        <h3 class="tw-text-base tw-font-semibold tw-mb-4">Min pickup fee</h3>

                        <h3 class="tw-text-base tw-font-semibold tw-mb-4">Min pickup amount</h3>
                    </div>
                    <div class="tw-mb-4">
                        <h3 class="tw-text-base tw-font-medium tw-normal-case tw-mb-4">{props.formik.values.pickupfeestype == 'percentage' ? 'Percentage' : 'Dollor'}</h3>

                        <h3 class="tw-text-base tw-font-medium tw-normal-case tw-mb-4">{props.formik.values.pickupfeestype == 'percentage' ? `${props.formik.values.pickupfees} %` : `$ ${props.formik.values.pickupfees}`}</h3>

                        <h3 class="tw-text-base tw-font-medium tw-normal-case tw-mb-4">$ {props.formik.values.minpickupamount}</h3>
                    </div>


                </div>)}

            {props.title === "Delivery Settings" && Boolean(props.formik.values.is_offer_delivery) && (<div class="tw-flex tw-flex-col tw-justify-center">
                <div class="tw-text-xl tw-font-bold tw-flex tw-flex-row tw-gap-2">
                    Delivery Zones

                    <LightTooltip
                        title={
                            <span
                                style={{ display: 'inline-block', padding: '0 15px' }}
                            >
                                Delivery zones are geographical areas that restaurants use to define where restaurant can deliver food. This helps to calculate the cost of delivery and set delivery fees.
                            </span>
                        }
                        arrow={true}
                        placement="top"
                    >
                        <img
                            src={info_icon}
                            height={15}
                            width={15}
                            style={{
                                marginTop: '8px'
                            }}

                        />
                    </LightTooltip>

                    <Button
                        className='w-font-bold tw-text-xs tw-text-blue !tw-p-0'
                        onClick={() => { setShowDiloag(true); }}
                    >
                        Help me set delivery zone pricing
                    </Button>

                </div>
                <div class="tw-flex">
                    <div class="tw-w-1/4 tw-p-2 tw-font-bold">Deliver from (miles)</div>
                    <div class="tw-w-1/4 tw-p-2 tw-font-bold">Deliver to (miles)</div>
                    <div class="tw-w-1/4 tw-p-2 tw-font-bold">Delivery fee type</div>
                    <div class="tw-w-1/4 tw-p-2 tw-font-bold">Delivery fee</div>
                    <div class="tw-w-1/4 tw-p-2 tw-font-bold">Min. Delivery Amount</div>
                </div>
                <div className='tw-flex tw-h-[1px] tw-w-full tw-bg-gray tw-my-1'></div>
                {props.deliverySettings?.venue_delivery_zone?.map((val) => (
                    <>
                        {console.log('Va')}
                        <div class="tw-flex">
                            <div class="tw-w-1/4 tw-p-2">{val.radius_from} Miles</div>
                            <div class="tw-w-1/4 tw-p-2">{val.radius_to} Miles</div>
                            <div class="tw-w-1/4 tw-p-2">{val.fee_type == 'dollar' ? 'Dollar' : 'Percentage'}</div>
                            <div class="tw-w-1/4 tw-p-2">${val.fees}</div>
                            <div class="tw-w-1/4 tw-p-2">${val.min_delivery_amount}</div>
                        </div>

                    </>

                ))}


            </div>)}

            {props.type === 'Pickup' && Boolean(props.formik.values.is_offer_pickup) && (
                <div class="tw-p-0">
                    <h2 class="tw-text-xl tw-font-bold tw-mb-2">{props.type} Hours</h2>
                    <p class="tw-text-gray-500 mb-4">Times during which your restaurant is open to customers for {props.type}.</p>
                    <div class="tw-flex tw-flex-wrap tw-gap-2">

                        {props.formik.values.venue_hours.map((val) => (
                            <>
                                {val?.venuetimings != 'pickup' ? null : <div class="tw-lex tw-items-center tw-bg-[#F6F6F6] tw-rounded-full tw-px-3 tw-py-1 tw-mb-2">
                                    <span class="tw-font-medium tw-mr-2">{val.dayname}</span>
                                    <span class="tw-text-blue">{val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:{val.opentimemins || '00'} - {val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:{val.closedtimemins || '00'}</span>
                                </div>}
                            </>

                        ))}


                    </div>
                </div>)
            }

            {props.type === 'Delivery' && Boolean(props.formik.values.is_offer_delivery) && (
                <div class="tw-p-0">
                    <h2 class="tw-text-xl tw-font-bold tw-mb-2">{props.type} Hours</h2>
                    <p class="tw-text-gray-500 mb-4">Times during which your restaurant is open to customers for {props.type}.</p>
                    <div class="tw-flex tw-flex-wrap tw-gap-2">

                        {props.formik.values.venue_hours.map((val) => (
                            <>
                                {val?.venuetimings != 'delivery' ? null : <div class="tw-lex tw-items-center tw-bg-[#F6F6F6] tw-rounded-full tw-px-3 tw-py-1 tw-mb-2">
                                    <span class="tw-font-medium tw-mr-2">{gatDayName(val.dayname)}</span>
                                    <span class="tw-text-blue">{val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:{val.opentimemins || '00'} - {val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:{val.closedtimemins || '00'}</span>
                                </div>}
                            </>

                        ))}


                    </div>
                </div>)
            }

            {props.type === 'Pickup' && Boolean(props.formik.values.is_offer_pickup) && (
                <div class="tw-p-0">
                    <h2 class="tw-text-xl tw-font-bold tw-mb-2">Preparation Time</h2>
                    <p class="tw-text-gray-500 mb-2">Time taken by your restaurant to prepare the order.</p>
                    <div className='tw-mb-4'>
                        <span class="tw-text-gray-500 ">Over All Preparation time </span>
                        <span class="tw-text-blue">{time}{' Min'} </span>
                    </div>

                    <div class="tw-flex tw-flex-wrap tw-gap-2">



                        {prepTime?.preparation_times.map((val) => (
                            <div class="tw-lex tw-items-center tw-bg-[#F6F6F6] tw-rounded-full tw-px-3 tw-py-1 tw-mb-2">
                                <span class="tw-font-medium tw-mr-2">{val.dayname}{' ('}{val.start_time}{' - '}{val.end_time}{') '} </span>
                                <span class="tw-text-blue">{val.preparation_time}{' Min'} </span>
                            </div>

                        ))}

                    </div>
                </div>)}

            {props.type === 'Delivery' && Boolean(props.formik.values.is_offer_delivery) && (
                <div class="tw-p-0">
                    <h2 class="tw-text-xl tw-font-bold tw-mb-2">Preparation Time</h2>
                    <p class="tw-text-gray-500 mb-2">Time taken by your restaurant to prepare the order.</p>
                    <div className='tw-mb-4'>
                        <span class="tw-text-gray-500 ">Over All Preparation time </span>
                        <span class="tw-text-blue">{timeDelivery}{' Min'} </span>
                    </div>


                    <div class="tw-flex tw-flex-wrap tw-gap-2">
                        {prepTimeDelivery?.preparation_times.map((val) => (
                            <div class="tw-lex tw-items-center tw-bg-[#F6F6F6]  tw-rounded-full tw-px-3 tw-py-1 tw-mb-2">
                                <span class="tw-font-medium tw-mr-2">{val.dayname}{' ('}{val.start_time}{' - '}{val.end_time}{') '} </span>
                                <span class="tw-text-blue">{val.preparation_time}{' Min'} </span>
                            </div>
                        ))}

                    </div>
                </div>)}

            <Drawer
                anchor={isMobileScreen ? "bottom" : "right"}
                open={open}
                onClose={() => { setOpen(false); props.formik.resetForm(); }}
                ModalProps={{ className: '!tw-z-[1202]' }}
                PaperProps={{ className: 'tw-min-w-[360px] lg:tw-max-w-[26%] tw-h-full  tw-max-h-screen tw-p-4' }}
            >
                <GenericEditDrawer
                    {...{
                        title: props.title,
                        onClose: () => { setOpen(false); props.formik.resetForm(); },
                        actions: (

                            <GenericLoadingButton
                                variant="contained"
                                type="button"
                                fullWidth

                                onClick={() => {
                                    if (selectedTab == 'preparation_time') {

                                        formikpreptime.handleSubmit()
                                    } else {
                                        console.log('Test call')
                                        props.formik.handleSubmit()
                                    }

                                }}
                                size="large"
                                loading={props.loading}
                                loadingPosition="end"
                            // disabled={props.isActionsDisabled}
                            >
                                Save Settings
                            </GenericLoadingButton>
                        )
                    }}
                >


                    <TabsSection
                        tabs={props.type === 'Pickup' ? tabs : tabsDelivery}
                        tabsbg='var(--bg-3)'
                        tabsPadding='15px'
                        tabValue={selectedTab}
                        tabs_container={styles.tabs_container}
                        onChange={(_, newValue) => {
                            setSelectedTab(newValue)
                        }}
                        boxStyles={{
                            p: '0 !important',
                            borderBottom: '1px solid #D3D3D3',
                        }}
                    />


                    {selectedTab === 'pickup' || selectedTab === 'delivery' ? (
                        <div className='tw-w-full'>
                            <div className='tw-flex tw-flex-row tw-gap-8'>
                                <div>
                                    Do you offer {props.type}?
                                </div>

                                {props.type === 'Pickup' && (
                                    <GenericSwitch
                                        checked={Boolean(props.formik.values.is_offer_pickup)}
                                        onChange={(event) => {
                                            props.formik.setFieldValue('is_offer_pickup', event.target.checked ? 1 : 0)
                                        }}
                                    />
                                )}
                                {props.type === 'Delivery' && (
                                    <GenericSwitch
                                        checked={Boolean(props.formik.values.is_offer_delivery)}
                                        onChange={(event) => {
                                            props.formik.setFieldValue('is_offer_delivery', event.target.checked ? 1 : 0)
                                        }}
                                    />
                                )}

                            </div>
                            <div
                                className={styles.title}
                            >
                                {props.type} Hours

                            </div>
                            <List disablePadding>
                                {props.formik.values.venue_hours.map((val, index) => {
                                    //console.log('val for venue_hours', val)
                                    if (val?.venuetimings != props.type.toLowerCase()) {
                                        return
                                    }
                                    // console.log('val for venue_hours', props.type.toLowerCase())
                                    // console.log('val for venue_hours 2', val.venuetimings)
                                    const hourDiff = val.opentimehours - val.closedtimehours
                                    const slotsOption = findDivisors(Math.abs(hourDiff))

                                    return (
                                        <div
                                            className={styles.hour_item_container}
                                        >
                                            <Typography
                                                style={{ color: "black", marginRight: 5, width: 20, fontSize: '.90rem', fontWeight: '600' }}
                                            >
                                                {gatDayName(val.dayname)}
                                            </Typography>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <GenericSwitch
                                                    checked={val.isclosed == '0'}
                                                    onChange={e => {
                                                        handleSwitch(index)
                                                    }}
                                                />


                                            </div>

                                            <div
                                                className={styles.hours_container}
                                            >
                                                {val.isclosed == '0' && (
                                                    <div
                                                        className={styles.time_container}
                                                    >
                                                        {/* <GenericTextField
                                            type="time"
                                            defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                            onChange={(e) => {
                                                console.log(e.target.value)
                                                handleInputChange(e.target.value, index, 'opentimehours', 'opentimemins')
                                            }}
                                        /> */}

                                                        <GenericTextFieldWithLabel
                                                            label="Open time"
                                                            isXSmallLabel={true}
                                                            select
                                                            // defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                                            value={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins || '00'}`}
                                                            onChange={(e) => {
                                                                handleInputChange(e.target.value, index, 'opentimehours', 'opentimemins')
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            {hours.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </GenericTextFieldWithLabel>

                                                        <ArrowForward
                                                            className={styles.arrow}
                                                        />

                                                        <GenericTextFieldWithLabel
                                                            label="Close time"
                                                            isXSmallLabel={true}
                                                            select
                                                            // defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                                            value={`${val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:${val.closedtimemins || '00'}`}
                                                            onChange={(e) => {
                                                                handleInputChange(e.target.value, index, 'closedtimehours', 'closedtimemins')
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            {hours.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </GenericTextFieldWithLabel>

                                                        {/* <GenericTextField
                                            type="time"
                                            defaultValue={`${val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:${val.closedtimemins < 10 ? `0${val.closedtimemins}` : val.closedtimemins}`}
                                            onChange={(e) => handleInputChange(e.target.value, index, 'closedtimehours', 'closedtimemins')}
                                        /> */}

                                                        <GenericTextFieldWithLabel
                                                            label="Time interval"
                                                            isXSmallLabel={true}
                                                            style={{
                                                                marginLeft: '5px'
                                                            }}
                                                            select
                                                            // defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                                                            value={val.time_interval || 60}
                                                            onChange={(e) => handleSlotChange(e.target.value, index, 'time_interval')}
                                                            variant="outlined"
                                                        >
                                                            <MenuItem
                                                                value={15}
                                                            >
                                                                15 min
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={30}
                                                            >
                                                                30 min
                                                            </MenuItem>
                                                            {slotsOption?.map((option) => (
                                                                <MenuItem key={option} value={parseFloat(option) * 60}>
                                                                    {option} hour
                                                                </MenuItem>
                                                            ))}
                                                        </GenericTextFieldWithLabel>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </List>
                        </div>
                    ) : selectedTab === 'delivery_zone' ? (
                        // <FieldArray
                        //     name="venue_delivery_zone"
                        //     render={arrayHelpers => (
                        <div>
                            <TableContainer
                                component={Paper}
                            >
                                <Table sx={{
                                    minWidth: 100,

                                }}>
                                    <TableBody>
                                        {props.formik.values.venue_delivery_zone.map((member, index) => (
                                            <StyledTableRow key={index}>
                                                <div className='tw-flex tw-items-start tw-gap-0 tw-flex-wrap !tw-p-0 tw-my-2'>
                                                    <div className='tw-flex tw-justify-between tw-items-center tw-w-full'>

                                                        <div className='tw-ml-2 tw-text-base tw-font-semibold tw-italic' >
                                                            Zone {index + 1}
                                                        </div>
                                                        {((props.formik.values.venue_delivery_zone?.length - 1) === index) && (index !== 0) && (
                                                            <IconButton
                                                                onClick={() => {
                                                                    //arrayHelpers.remove(index)
                                                                    const updatedVenue_delivery_zone = props.formik.values.venue_delivery_zone.filter((_, i) => i !== index);
                                                                    props.formik.setFieldValue('venue_delivery_zone', updatedVenue_delivery_zone);

                                                                }}
                                                            >
                                                                <DeleteForever />
                                                            </IconButton>
                                                        )}


                                                    </div>
                                                    <div className='tw-flex tw-items-end tw-justify-center'>
                                                        <StyledTableCell component="th" scope="row" style={{
                                                            padding: '2px 4px'
                                                        }}>
                                                            <GenericTextFieldWithLabel
                                                                containerClassname='tw-max-w-[60px]'
                                                                isXSmallLabel={true}
                                                                id={`venue_delivery_zone[${index}].radius_from`}
                                                                name={`venue_delivery_zone[${index}].radius_from`}
                                                                type="text"
                                                                value={member.radius_from}
                                                                onChange={props.formik.handleChange}
                                                                label='From (miles)'

                                                            />
                                                        </StyledTableCell>


                                                        <StyledTableCell style={{
                                                            padding: '2px 4px'
                                                        }}>

                                                            <GenericTextFieldWithLabel
                                                                containerClassname='tw-max-w-[60px]'
                                                                isXSmallLabel={true}
                                                                id={`venue_delivery_zone[${index}].radius_to`}
                                                                name={`venue_delivery_zone[${index}].radius_to`}
                                                                type="text"
                                                                value={member.radius_to}
                                                                onChange={props.formik.handleChange}
                                                                label='To (miles)'
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{
                                                            padding: '2px 4px'
                                                        }}>
                                                            <GenericTextFieldWithLabel
                                                                containerClassname='tw-max-w-[130px]'
                                                                isXSmallLabel={true}
                                                                id={`venue_delivery_zone[${index}].fees`}
                                                                name={`venue_delivery_zone[${index}].fees`}
                                                                type={member.fee_type}
                                                                label='Delivery fee'
                                                                value={member.fees}
                                                                onChange={(e) => {

                                                                    handleChangeFee(e, member.fee_type, index)
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Box sx={{ display: 'flex', backgroundColor: '#e0e0e0', borderRadius: '0px', padding: '0 0px', marginLeft: '-10px', }}>
                                                                                <Select
                                                                                    id={`venue_delivery_zone[${index}].fee_type`}
                                                                                    name={`venue_delivery_zone[${index}].fee_type`}
                                                                                    containerClassname={styles.small_input_container}
                                                                                    value={member.fee_type}
                                                                                    onChange={props.formik.handleChange}

                                                                                    disableUnderline
                                                                                    IconComponent={ArrowDropDownIcon}
                                                                                    sx={{
                                                                                        '.MuiSelect-select': {
                                                                                            padding: '0 2px',
                                                                                        },
                                                                                        '.MuiOutlinedInput-notchedOutline': {
                                                                                            border: 'none',
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    <MenuItem value="percentage">%</MenuItem>
                                                                                    <MenuItem value="dollar">$</MenuItem>
                                                                                </Select>
                                                                            </Box>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}

                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{
                                                            padding: '2px 4px'
                                                        }}>
                                                            <ValueTextField

                                                                containerStyle={{
                                                                    maxWidth: '80px'
                                                                }}
                                                                isXSmallLabel={true}
                                                                id={`venue_delivery_zone[${index}].min_delivery_amount`}
                                                                name={`venue_delivery_zone[${index}].min_delivery_amount`}
                                                                type="dollar"
                                                                value={member.min_delivery_amount}
                                                                onChange={props.formik.handleChange}
                                                                label='Min delivery amt'
                                                            />
                                                        </StyledTableCell>
                                                    </div>


                                                </div>
                                            </StyledTableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {(props.formik.values.venue_delivery_zone?.length < 10) && (
                                <div
                                    className={styles.add_more_btn_container}
                                >
                                    <GenericButton
                                        variant="contained"
                                        onClick={() =>

                                            props.formik.setFieldValue('venue_delivery_zone', [...props.formik.values.venue_delivery_zone, {
                                                fee_type: 'dollar',
                                                fees: '0',
                                                min_delivery_amount: '0',
                                                radius_from: props.formik.values.venue_delivery_zone[props.formik.values.venue_delivery_zone.length - 1]?.radius_to || 0,
                                                radius_to: parseInt(props.formik.values.venue_delivery_zone[props.formik.values.venue_delivery_zone.length - 1]?.radius_to || 0) + 5
                                            }])


                                        }
                                        style={{ width: '100%', backgroundColor: '#0071C3' }}
                                    >
                                        + Add New Zone
                                    </GenericButton>
                                </div>
                            )}
                        </div>
                        //     )}
                        // />
                        // <div></div>
                    ) : selectedTab === 'preparation_time' ? (
                        <div>
                            <div>

                                <div>
                                    Does your restaurant busy at some times? Let us know how long it usually takes to prepare an order, and we'll use this information to improve {props.type} accuracy.
                                </div>

                                <div className={clsx(styles.title_sub, 'tw-mt-4')}>
                                    Minutes
                                </div>
                                <IncDecCounter
                                    onMinus={(e) => {
                                        setTime((prevState) => {
                                            console.log(prevState)
                                            if ((parseInt(prevState) - 5) >= 0) {
                                                return (parseInt(prevState) - 5)
                                            }
                                            return parseInt(prevState)
                                        })
                                    }}
                                    value={time}
                                    onPlus={(e) => {
                                        setTime((prevState) => {
                                            return parseInt(prevState) + 5
                                        })
                                    }}

                                />
                                <div className={'tw-mb-4'} />

                                {/* <Formik
                                    initialValues={formikpreptime}
                                    enableReinitialize={true}
                                    validationSchema={validationSchema}
                                    onSubmit={formikpreptime.handleSubmit}
                                >
                                    {formikpreptime => ( */}
                                <form
                                    onSubmit={formikpreptime.handleSubmit}
                                >
                                    {/* <FieldArray
                                                name="preparation_times"
                                                validateOnChange="false"
                                                render={arrayHelpers => ( */}
                                    <div >

                                        {formikpreptime.values.preparation_times?.map((member, index, setFieldValue) => (
                                            <div className='tw-w-full tw-flex'>

                                                <div

                                                    className='tw-flex tw-items-end tw-gap-1 tw-flex-wrap tw-justify-between'
                                                >
                                                    <div className='tw-w-full tw-h-4 tw-flex tw-flex-row tw-justify-between tw-mt-1'>
                                                        <div className='tw-text-base tw-font-semibold tw-italic'></div>
                                                        <div className='tw-flex'>
                                                            <Button
                                                                variant="text"
                                                                onClick={() => {


                                                                    const updatedPreparation_times = formikpreptime.values.preparation_times.filter((_, i) => i !== index);
                                                                    formikpreptime.setFieldValue('preparation_times', updatedPreparation_times);
                                                                }}
                                                                style={{

                                                                    color: 'black',
                                                                    minWidth: '40px',
                                                                }}


                                                            >
                                                                <DeleteForever />
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <GenericSelectWithLabel
                                                        containerStyle={{
                                                            flex: 1,
                                                            maxWidth: '70px',

                                                        }}
                                                        sx={{
                                                            [`.${outlinedInputClasses.input}`]: {

                                                                fontSize: '12px',

                                                            },
                                                        }}
                                                        isXSmallLabel={true}
                                                        size='small'
                                                        id={`preparation_times[${index}].dayname`}
                                                        name={`preparation_times[${index}].dayname`}
                                                        label="Day"
                                                        // containerClassname={styles.small_input_container}
                                                        value={gatDayName(member.dayname)}
                                                        onChange={formikpreptime.handleChange}
                                                        fullWidth={false}
                                                        required={true}
                                                        {...(Boolean(formikpreptime?.touched?.preparation_times) && Boolean(formikpreptime?.errors?.preparation_times) && Array.isArray(formikpreptime.errors.preparation_times) && formikpreptime.touched.preparation_times[index]?.dayname && Boolean(formikpreptime.errors.preparation_times[index]?.dayname) && {
                                                            error: formikpreptime.errors.preparation_times?.length && formikpreptime.errors.preparation_times[index]?.dayname && Boolean(formikpreptime.errors.preparation_times[index]?.dayname),
                                                            helperText: formikpreptime.touched.preparation_times?.length && formikpreptime.touched.preparation_times[index]?.dayname && formikpreptime.errors.preparation_times[index]?.dayname
                                                        })}
                                                    >
                                                        {member.dayname && (
                                                            <MenuItem style={{
                                                                display: 'none'
                                                            }} value={gatDayName(member.dayname)}>{gatDayName(member.dayname)}</MenuItem>
                                                        )}
                                                        {getDays(formikpreptime.values?.preparation_times)}
                                                    </GenericSelectWithLabel>


                                                    <GenericTextFieldWithLabel
                                                        label="Start Time"
                                                        containerStyle={{
                                                            flex: 1,
                                                            minWidth: '90px',
                                                        }}
                                                        sx={{
                                                            [`.${outlinedInputClasses.input}`]: {
                                                                minWidth: '90px',
                                                                fontSize: '12px',
                                                                width: '50px',
                                                                height: '45px',
                                                                padding: '0px 0px 0px 0px',
                                                            },


                                                        }}


                                                        isXSmallLabel={true}
                                                        id={`preparation_times[${index}].start_time`}
                                                        name={`preparation_times[${index}].start_time`}
                                                        type="time"
                                                        value={member.start_time}
                                                        onChange={formikpreptime.handleChange}
                                                        {...(Boolean(formikpreptime?.touched?.preparation_times) && Boolean(formikpreptime?.errors?.preparation_times) && Array.isArray(formikpreptime.errors.preparation_times) && formik.touched.preparation_times[index]?.start_time && Boolean(formikpreptime.errors.preparation_times[index]?.start_time) && {
                                                            error: formikpreptime.errors.preparation_times?.length && formikpreptime.errors.preparation_times[index]?.start_time && Boolean(formikpreptime.errors.preparation_times[index]?.start_time),
                                                            helperText: formikpreptime.touched.preparation_times?.length && formikpreptime.touched.preparation_times[index]?.start_time && formikpreptime.errors.preparation_times[index]?.start_time
                                                        })}
                                                        fullWidth={true}
                                                    />


                                                    <GenericTextFieldWithLabel
                                                        label="End Time"
                                                        containerStyle={{
                                                            flex: 1,
                                                            minWidth: '90px',
                                                        }}
                                                        sx={{
                                                            [`.${outlinedInputClasses.input}`]: {
                                                                minWidth: '90px',
                                                                fontSize: '12px',
                                                                width: '50px',
                                                                height: '45px',
                                                                padding: '0px 0px 0px 0px'

                                                            },
                                                        }}

                                                        isXSmallLabel={true}
                                                        id={`preparation_times[${index}].end_time`}
                                                        name={`preparation_times[${index}].end_time`}
                                                        type="time"
                                                        value={member.end_time}
                                                        onChange={formikpreptime.handleChange}
                                                        {...(Boolean(formikpreptime?.touched?.preparation_times) && Boolean(formikpreptime?.errors?.preparation_times) && Array.isArray(formikpreptime.errors.preparation_times) && formikpreptime.touched.preparation_times[index]?.end_time && Boolean(formikpreptime.errors.preparation_times[index]?.end_time) && {
                                                            error: formikpreptime.errors.preparation_times?.length && formikpreptime.errors.preparation_times[index]?.end_time && Boolean(formikpreptime.errors.preparation_times[index]?.end_time),
                                                            helperText: formikpreptime.touched.preparation_times?.length && formikpreptime.touched.preparation_times[index]?.end_time && formikpreptime.errors.preparation_times[index]?.end_time
                                                        })}
                                                        fullWidth={true}
                                                    />


                                                    <GenericSelectWithLabel
                                                        containerStyle={{
                                                            flex: 1,
                                                            maxWidth: '65px',

                                                        }}
                                                        sx={{
                                                            [`.${outlinedInputClasses.input}`]: {

                                                                fontSize: '12px',
                                                            },
                                                        }}
                                                        isXSmallLabel={true}
                                                        id={`preparation_times[${index}].preparation_time`}
                                                        name={`preparation_times[${index}].preparation_time`}
                                                        label="Min"
                                                        // containerClassname={styles.small_input_container}
                                                        value={member.preparation_time}
                                                        onChange={formikpreptime.handleChange}

                                                        fullWidth={false}
                                                        required={true}
                                                        {...(Boolean(formikpreptime?.touched?.preparation_times) && Boolean(formikpreptime?.errors?.preparation_times) && Array.isArray(formikpreptime.errors.preparation_times) && formikpreptime.touched.preparation_times[index]?.dayname && Boolean(formikpreptime.errors.preparation_times[index]?.dayname) && {
                                                            error: formikpreptime.errors.preparation_times?.length && formikpreptime.errors.preparation_times[index]?.dayname && Boolean(formikpreptime.errors.preparation_times[index]?.dayname),
                                                            helperText: formikpreptime.touched.preparation_times?.length && formikpreptime.touched.preparation_times[index]?.dayname && formikpreptime.errors.preparation_times[index]?.dayname
                                                        })}
                                                    >



                                                        {timePicker.map((val) => (

                                                            <MenuItem
                                                                value={val}
                                                            >
                                                                {val}
                                                            </MenuItem>

                                                        ))}

                                                    </GenericSelectWithLabel>

                                                    {/* <div
                                                        className='tw-flex-1'
                                                    >


                                                        <div
                                                            style={{
                                                                fontWeight: 600
                                                            }}
                                                        >
                                                            Minutes
                                                        </div>
                                                        <IncDecCounter
                                                            style={{
                                                                height: 46,
                                                                maxWidth: '130px',
                                                            }}
                                                            onMinus={(e) => {
                                                                if ((parseInt(member.preparation_time) - 5) >= 0) {
                                                                    formikpreptime.setFieldValue(`preparation_times[${index}].preparation_time`, parseInt(member.preparation_time) - 5)
                                                                }
                                                            }}
                                                            value={member.preparation_time}
                                                            onPlus={(e) => {
                                                                formikpreptime.setFieldValue(`preparation_times[${index}].preparation_time`, parseInt(member.preparation_time) + 5)
                                                            }}
                                                        />
                                                    </div> */}




                                                    <div className='tw-flex tw-h-[1px] tw-w-full tw-bg-gray tw-my-1'></div>


                                                </div>


                                            </div>
                                        ))}

                                        {(formikpreptime.values.preparation_times?.length < 7) && (
                                            <div
                                            // className={styles.add_more_btn_container}
                                            >
                                                <GenericButton
                                                    variant="text"
                                                    onClick={() =>

                                                        formikpreptime.setFieldValue('preparation_times', [...formikpreptime.values.preparation_times, {
                                                            preparation_time: 0,
                                                            start_time: '',
                                                            end_time: '',
                                                            dayname: '',
                                                            order_type: props.type
                                                        }])
                                                        //     formikpreptime.values.push({
                                                        //     preparation_time: 0,
                                                        //     start_time: '',
                                                        //     end_time: '',
                                                        //     dayname: '',
                                                        //     order_type: props.type
                                                        // })

                                                    }
                                                >
                                                    + Add Busy Times for Each Day
                                                </GenericButton>
                                            </div>
                                        )}
                                    </div>
                                    {/* )}
                                            /> */}

                                </form>
                                {/* )}
                                </Formik> */}
                            </div>
                        </div>
                    ) : null}



                    {(selectedTab === 'pickup' || selectedTab === 'delivery') && (

                        <div>

                            {props.type === 'Pickup' && (<div className='tw-flex tw-flex-row tw-gap-8'>
                                <div className={styles.title_sub}>
                                    Do you charge customers any pickup fee?
                                </div>
                                <GenericSwitch
                                    checked={isChargePickupFee}
                                    onChange={(event) => {
                                        setISChargePickupFee(event.target.checked ? true : false)
                                        if (event.target.checked === false) {
                                            props.formik.setFieldValue('pickupfees', 0)
                                            props.formik.setFieldValue('minpickupamount', 0)
                                        }
                                    }}
                                />
                            </div>)}
                            {Boolean(isChargePickupFee) && props.type === 'Pickup' && (
                                <div
                                    className='tw-flex tw-flex-row tw-gap-8'
                                >
                                    <div>
                                        <GenericSelectWithLabel
                                            id="pickupfeestype"
                                            name="pickupfeestype"
                                            label={
                                                <LightTooltip
                                                    title={
                                                        <span
                                                            style={{ display: 'inline-block', padding: '0 15px' }}
                                                        >
                                                            If you charge flat fees, choose "Dollar". Choose "Percent", if you want us  to calculate the fees based on order value.
                                                        </span>
                                                    }
                                                    arrow={true}
                                                    placement="top"
                                                >
                                                    <div>Pickup Fee Type</div>
                                                </LightTooltip>
                                            }
                                            containerClassname={styles.small_input_container}
                                            value={props.formik.values.pickupfeestype}
                                            onChange={props.formik.handleChange}
                                        >
                                            <MenuItem value="percentage">%</MenuItem>
                                            <MenuItem value="dollar">$</MenuItem>
                                        </GenericSelectWithLabel>
                                    </div>
                                    <div
                                        className={styles.input_field}
                                    >
                                        <ValueTextField
                                            id="pickupfees"
                                            name="pickupfees"
                                            label={
                                                <LightTooltip
                                                    title={
                                                        <span
                                                            style={{ display: 'inline-block', padding: '0 15px' }}
                                                        >
                                                            Enter Pickup fees here
                                                        </span>
                                                    }
                                                    arrow={true}
                                                    placement="top"
                                                >
                                                    <div>Pickup Fees</div>
                                                </LightTooltip>
                                            }
                                            type={props.formik.values.pickupfeestype}
                                            value={props.formik.values.pickupfees}
                                            onChange={props.formik.handleChange}
                                        />
                                    </div>

                                </div>)}
                            {Boolean(isChargePickupFee) && props.type === 'Pickup' && (
                                <div
                                    className={styles.input_field}
                                >
                                    <ValueTextField
                                        id="minpickupamount"
                                        name="minpickupamount"
                                        label={
                                            <LightTooltip
                                                title={
                                                    <span
                                                        style={{ display: 'inline-block', padding: '0 15px' }}
                                                    >
                                                        Only orders with value greater than this amount will be allowed to process. We recommend to have a low value here to get more pickup orders.
                                                    </span>
                                                }
                                                arrow={true}
                                                placement="top"
                                            >
                                                <div>
                                                    Min. Pickup Amount
                                                </div>
                                            </LightTooltip>
                                        }
                                        type="dollar"
                                        value={props.formik.values.minpickupamount}
                                        onChange={props.formik.handleChange}
                                    />
                                </div>)}

                            <div className='tw-flex tw-flex-row tw-gap-8 tw-mt-2'>
                                <div className={styles.title_sub}>
                                    Show inventory quantity to customers?
                                </div>
                                {props.type === 'Pickup' && (<GenericSwitch
                                    checked={Boolean(parseInt(props.formik.values?.is_show_no_of_stock?.pickup))}
                                    onChange={(event) => {
                                        props.formik.setFieldValue('is_show_no_of_stock.pickup', event.target.checked ? 1 : 0)
                                    }}
                                />)}
                                {props.type === 'Delivery' && (<GenericSwitch
                                    checked={Boolean(parseInt(props.formik.values?.is_show_no_of_stock?.delivery))}
                                    onChange={(event) => {
                                        props.formik.setFieldValue('is_show_no_of_stock.delivery', event.target.checked ? 1 : 0)
                                    }}
                                />)}


                            </div>


                            <div className='tw-flex tw-flex-row tw-gap-8 !tw-mb-8 tw-mt-2'>
                                {props.type === 'Pickup' && (<div className={styles.title_sub}>
                                    Do you want to pass Esplanda fee of {props.pickupSettings?.esplanda_fee_type == 'dollar' ? '$' : ''}{props.pickupSettings?.esplanda_fee} {props.pickupSettings?.esplanda_fee_type == 'percent' ? ' %' : ''} to customers?
                                </div>)}

                                {props.type === 'Delivery' && (<div className={styles.title_sub}>
                                    Do you want to pass Esplanda fee of {props.deliverySettings?.esplanda_fee_type == 'dollar' ? '$' : ''}{props.deliverySettings?.esplanda_fee} {props.deliverySettings?.esplanda_fee_type == 'percent' ? ' %' : ''} to customers?
                                </div>)}


                                {props.type === 'Pickup' && (<GenericSwitch
                                    checked={props.formik.values.pickup_service_charge_to_customer}
                                    onChange={(event) => {
                                        props.formik.setFieldValue('pickup_service_charge_to_customer', event.target.checked ? 1 : 0)
                                    }}
                                />)}
                                {props.type === 'Delivery' && (<GenericSwitch
                                    checked={props.formik.values.delivery_service_charge_to_customer}
                                    onChange={(event) => {
                                        props.formik.setFieldValue('delivery_service_charge_to_customer', event.target.checked ? 1 : 0)
                                    }}
                                />)}


                            </div>
                        </div>)}
                </GenericEditDrawer>
            </Drawer>

            <Dialog
                open={showDiloag}
                onClose={() => {
                    setShowDiloag(false)
                }}
                maxWidth="lg"
            >


                <DialogContent>
                    <div class="tw-bg-white tw-p-2 tw-rounded-lg tw-w-full tw-max-w-lg">
                        <div class="tw-flex tw-justify-between ">
                            <h2 class="tw-text-xl tw-font-semibold">How to set delivery zones pricing?</h2>

                            <Button
                                className='!tw-text-black'
                                onClick={() => { setShowDiloag(false); }}
                            >
                                <CancelIcon />
                            </Button>
                        </div>
                        <p class="tw-mt-4 text-gray-700">Our Delivery Zone pricing is fully configurable, and you can create as many delivery zones as you like.</p>
                        <ol class="tw-mt-2 tw-list-decimal tw-list-inside tw-text-gray-700">
                            <li>You can set minimum order amount, for each deliver zone separately.</li>
                            <li>For each zone, you can charge either flat fee or percentage of order amount, as delivery fee.</li>
                        </ol>
                        <h3 class="tw-mt-4 tw-font-semibold tw-text-gray-900">For best practices, we recommend</h3>
                        <ol class="tw-mt-2 tw-list-decimal tw-list-inside tw-text-gray-700">
                            <li>Offer Free Delivery for up to 5 miles Radius (To maximize revenue.)</li>
                            <li>Or Charge $4.99 for up to 5 miles radius AND $0.75 per mile after that</li>
                        </ol>
                        <p class="tw-mt-4 tw-text-gray-700">If you are calling Doordash, refer to <a href="#" class="tw-text-blue-500 hover:tw-underline">DoorDash pricing here</a>.</p>
                        <div class="tw-flex tw-justify-end tw-mt-4">
                            <Button
                                className='!tw-bg-[#050505] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-white    !tw-text-xs tw-mt-4'
                                onClick={() => { setShowDiloag(false); }}
                            >
                                Got it
                            </Button>
                        </div>


                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}


export default PickUpDeliverySettings;


