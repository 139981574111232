import React, { useState } from "react";
import {
    List,
    ListItem,
    ListItemText,
    Radio,
    Typography
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import BlackCheckbox from '../../../util_comps/BlackCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import LightTooltip from "../../../util_comps/LightTooltip";
import GenericSwitch from "../../Library/GenericSwitch/GenericSwitch";
import { IconButton } from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";
import { deleteProductgroup } from "../../../servise/productGroupAPI";
import { deleteProductGroup as deleteProductGroupAction } from "../../../redux/actions/productGroupActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ProductPreview from "../../Dialogs/ProductPreview/ProductPreview";
import { previewCategoryList, previewProductList } from "../../../servise/previewAPI";
import { useConfirm } from "material-ui-confirm";

const GroupList = (props) => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const confirm = useConfirm()
    const { groupList } = useSelector((state) => state);
console.log(groupList, props.itemsToSelect)
    const [showPreview, setShowPreview] = useState(false)
    const [previewData, setPreviewData] = useState([])
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)
    const [baseImageLink, setBaseImageLink] = useState('')
    const [isSquarePreview, setIsSquarePreview] = useState(false)

    const handleDelete = async (id) => {

        confirm({
            title: 'Do you wish to confirm to delete this collection',
            description: 'Deleting this collection means losing all its detail'
        })
            .then(async () => {
                await deleteProductgroup(id);
                dispatch(deleteProductGroupAction(id));
                toast.success('Product Group Deleted Successfully')
            })
            .catch((err) => {
                toast.error(err.response?.data?.message)
            });
    };

    return (
        <>
            <List
                style={{
                    height: 350,
                    overflow: 'auto',
                    padding: 8
                }}
            >
                {props.itemsToSelect.map(({ data }, i) => {
                    return (
                        <Draggable
                            key={data.productGroupId.toString()}
                            draggableId={data.productGroupId.toString()}
                            index={i}
                        >
                            {(provided, snapshot) => {
                                let isSelected = groupList.items.findIndex(i => i.productGroupId === data.productGroupId) !== -1;
                                let showTitle = groupList.items.findIndex(i => i.productGroupId === data.productGroupId && i.showTitle === true) !== -1;

                                return (
                                    <ListItem
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            userSelect: "none",
                                            padding: '4px 16px',
                                            margin: "8px auto",
                                            minHeight: "50px",
                                            boxShadow: '0px 3px 6px #0000001A',
                                            borderRadius: '4px',
                                            backgroundColor: 'white',
                                            ...provided.draggableProps.style
                                            // width: props.snapshot.isDragging ? '50%' : '95%',
                                        }}
                                    >
                                        {props.isPopup && (
                                            <Radio
                                                onClick={() => props.onProductGroupSelect(data.productGroupId)}
                                                size="small"
                                            />
                                        )}
                                        {isSelected && <BlackCheckbox
                                            checked={true}
                                            style={{
                                                position: 'absolute',
                                                left: '-21px'
                                            }}
                                        />}
                                        <ListItemText
                                            style={{
                                                lineHeight: 0,
                                            }}
                                            primary={<LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>
                                                Drag to the right, if you want this product group displayed on your homepage.</span>} arrow placement="top"
                                            >
                                                <div>
                                                    <Typography

                                                        onClick={async () => {
                                                            setShowPreview(true)
                                                            setIsPreviewLoading(true)
                                                            if (data.productGroupType !== 'CategoryList') {

                                                                let preview = await previewProductList(
                                                                    data.api_uri,
                                                                    data.productListURL,
                                                                    true
                                                                )

                                                                setPreviewData(preview?.result)
                                                                setBaseImageLink(preview.product_image)
                                                                setIsSquarePreview(false)
                                                            } else {
                                                                let preview = await previewCategoryList(
                                                                    data.categoryIds
                                                                )

                                                                setPreviewData(preview)
                                                                setIsSquarePreview(true)

                                                                // setBaseImageLink(preview.product_image)
                                                            }
                                                            setIsPreviewLoading(false)
                                                        }}
                                                        variant="h6"
                                                        style={{ cursor: 'pointer', fontSize: '13px', width: 'fit-content', color: '#707070', textDecoration: 'underline' }}
                                                    >
                                                        {data.productGroupName}
                                                    </Typography>

                                                    {!Boolean(props.subCollection) && (
                                                        <div
                                                            style={{
                                                                padding: '5px',
                                                                fontSize: '12px',
                                                                backgroundColor: '#dddddd',
                                                                width: 'fit-content',
                                                                borderRadius: '4px'
                                                            }}
                                                        >
                                                            {data.productGroupType === 'CategoryList' && "Category"}
                                                            {data.productGroupType === 'TagProductList' && "My Collection"}
                                                            {data.productGroupType === 'ProductList' && "Products"}
                                                        </div>
                                                    )}
                                                </div>
                                            </LightTooltip>
                                            }
                                        />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Toggle on/off if you want to display/hide title from homepage</span>} arrow placement="top"
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end'
                                                        }}
                                                    >
                                                        <IconButton
                                                            style={{
                                                                padding: 8
                                                            }}
                                                            onClick={() => {
                                                                props.setIsProductGroupEdit(true)
                                                                history(
                                                                    `/customize-homepage?categoryIds=${data.categoryIds
                                                                    }&isSystem=${data.is_system}&productGroupName=${data.productGroupName
                                                                        ? encodeURIComponent(
                                                                            data.productGroupName
                                                                        )
                                                                        : ''
                                                                    }&productGroupType=${data.productGroupType
                                                                    }&productListURL=${data.productListURL
                                                                        ? encodeURIComponent(
                                                                            data.productListURL
                                                                        )
                                                                        : ''
                                                                    }&productGroupId=${data.productGroupId}&selectedTagId=${data?.api_uri?.split('/')[data.api_uri?.split('/')?.length - 1]}`
                                                                );

                                                                props.setShowAddCollection(true)
                                                            }}
                                                            disabled={props.isActionsDisabled}
                                                        >
                                                            <Edit
                                                                style={{
                                                                    color: '#000000',
                                                                    fontSize: '16px'
                                                                }}
                                                            />
                                                        </IconButton>

                                                        {!Boolean(data.is_system) && (
                                                            <IconButton
                                                                onClick={() => {
                                                                    handleDelete(data.productGroupId);
                                                                }}
                                                                style={{
                                                                    padding: 8
                                                                }}
                                                                disabled={props.isActionsDisabled}
                                                            >
                                                                <DeleteForever
                                                                    style={{
                                                                        color: '#000000',
                                                                        fontSize: '16px'
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </div>

                                                    {isSelected && (
                                                        <div
                                                            style={{
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            <Typography variant="h6" style={{ fontSize: '10px', marginRight: '6px', color: '#000000' }}
                                                            >
                                                                {showTitle ? 'Hide Title' : "Show Title"}
                                                            </Typography>

                                                            <GenericSwitch
                                                                checked={showTitle}
                                                                disabled={!isSelected}
                                                                onChange={e => {
                                                                    if (!props.isActionsDisabled) {
                                                                        e.target.checked ? props.showTitle(data.productGroupId) : props.hideTitle(data.productGroupId)
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </LightTooltip>
                                        </div>
                                    </ListItem>
                                )

                            }}
                        </Draggable>
                    );
                })}
            </List>


            <ProductPreview
                open={showPreview}
                isLoading={isPreviewLoading}
                isSquarePreview={isSquarePreview}
                products={previewData}
                baseImageLink={baseImageLink}
                onClose={() => {
                    setShowPreview(false)
                }}
            />
        </>
    );
};
export default GroupList;
