import React from "react";
import {
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";

const OthersList = (props) => {

    return (
        <List
            style={{
                height: 350,
                overflow: 'auto',
                padding: 8
            }}
        >
            {props.items.map((data, i) => {

                return (
                    <Draggable
                        key={data.productGroupId.toString()}
                        draggableId={data.productGroupId.toString()}
                        index={i}
                    >
                        {(provided, snapshot) => {
                            // let isSelected = groupList.items.findIndex(i => i.productGroupId === data.productGroupId) !== -1;

                            return (
                                <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                        userSelect: "none",
                                        padding: '4px 16px',
                                        margin: "8px auto",
                                        minHeight: "50px",
                                        boxShadow: '0px 3px 6px #0000001A',
                                        borderRadius: '4px',
                                        backgroundColor: 'white',
                                        textDecoration: data.productGroupType == "Event" ? 'underline' : '',
                                        cursor: 'pointer',
                                        ...provided.draggableProps.style
                                        // width: props.snapshot.isDragging ? '50%' : '95%',
                                    }}

                                    onClick={() => {
                                        if (!props.isActionsDisabled) {
                                            if (data.productGroupType == "Event") {
                                                props.setShowEventDialog(true)
                                            }
                                        }
                                    }}
                                >
                                    {/* {isSelected && <BlackCheckbox
                                        checked={true}
                                        style={{
                                            position: 'absolute',
                                            left: '-21px'
                                        }}
                                    />} */}
                                    <ListItemText
                                        style={{
                                            lineHeight: 0,
                                        }}
                                        primary={data.productGroupName}
                                    />
                                </ListItem>
                            )

                        }}
                    </Draggable>
                );
            })}
        </List>
    );
};
export default OthersList;
