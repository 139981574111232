import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { useId } from 'react'
import styles from './UploadImage.module.scss'

const UploadImage = forwardRef((props, ref) => {

    const id = useId()

    return (
        <div
            className={clsx(styles.upload_image_input_container, props.className)}
        >
            <input
                style={{
                    display: 'none'
                }}
                id={id}
                type="file"
                accept="image/*"
                onChange={(event) => {
                    props.onChange(event)
                    event.target.value = ''
                }}
                ref={ref}

            />

            <label
                htmlFor={id}
                className={styles.image_upload_label}
            >
                {(props.image || props.imageLink) ? (
                    <div
                        className={styles.uploaded_image}
                    >
                        <img
                            style={{
                                height: props.isBig ? 100 : 50
                            }}
                            className={styles.product_image}
                            src={props.image ? URL.createObjectURL(props.image) : props.imageLink}
                        />
                    </div>
                ) : (
                    <div
                        className={styles.upload_image_icon_container}
                    >
                        {props.uploadText}
                    </div>
                )}
            </label>

        </div>
    )
})

export default UploadImage