import React, { useCallback, useEffect, useRef, useState } from 'react'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import { useFormik } from 'formik'
import { Autocomplete, Button, Checkbox, Chip, ClickAwayListener, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, ListItem, ListItemText, Menu, MenuItem, Popper, Radio, Select, TextField, Tooltip, inputBaseClasses, outlinedInputClasses, tooltipClasses, useMediaQuery } from '@mui/material'
import { AttachMoney, Cancel, ChevronLeft, ChevronRight, Close, Delete, Edit, EditOutlined, ExpandMore, HelpOutline, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import { getImage, getImage2 } from '../../../../../utils/helpers'
import GenericAutoCompleteWithLabel from '../../../../Library/GenericAutoCompleteWithLabel/GenericAutoCompleteWithLabel'
import axios from '../../../../../utils/axiosConfig'
import GenericTextField from '../../../../Library/V2/GenericTextField/GenericTextField'
import GenericSelectWithLabel from '../../../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { toast } from 'react-toastify'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { debounce } from 'lodash'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import * as yup from 'yup';
import LightTooltip from '../../../../../util_comps/LightTooltip'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorder } from '../../../../CustomPageBuilder/EditSection/Util/helpers'
//import ReOrderDialog from './ReOrderDialog/ReOrderDialog'
import Loader from '../../../../Library/Loader'
import { useConfirm } from 'material-ui-confirm'
import GenericSwitch from '../../../../Library/V2/GenericSwitch/GenericSwitch'
import { styled } from '@mui/styles'
import clsx from 'clsx'
import { Divider } from '@mui/material'
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import { loadingButtonClasses } from "@mui/lab";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import RadioGroup from '@mui/material/RadioGroup';
import { classname } from '../../../../../utils/bem'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ValueTextField from '../../../../Library/ValueTextField/ValueTextField'

import GenericButton from '../../../../Library/GenericButton/GenericButton'








const AddNewCoupnDialog = ({
    modalForm,
    onClose,
    isEdit,
    ...props
}) => {
    const cn = classname('coupons')
    const imageRef = useRef()
    const confirm = useConfirm()
    const isMobile = useMediaQuery('(max-width:1000px)')
    const [formats, setFormats] = useState([])
    const [showModifierReorder, setShowModifierReorder] = useState(false)
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [openBrandOption, setOpenBrandOption] = useState(false)
    const [venues, setVenues] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedPropertiesOptions, setSelectedPropertiesOptions] = useState([]);
    // const [properties, setProperties] = useState([])
    // const [options, setOptions] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCat, setSelectedCat] = useState(props.isEdit ? props?.editObj?.category_name : '')
    const [options2, setOptions2] = useState({})
    const [selectedStep, setSelectedStep] = useState(0)
    const [selectedTab, setselectedTab] = useState('offer')
    const open = Boolean(anchorEl);


    const modalAutoCompleteOptions = [
        {
            title: 'Delivery'
        },
        {
            title: 'Pickup'
        },
        {
            title: 'Shipping'
        }
    ]



    const formik = useFormik({
        initialValues: {
            code: modalForm.couponCode || '',
            type: modalForm.discountType || '',
            value: modalForm.discountAmount || '',
            valid_from: modalForm.validFrom || '',
            valid_to: modalForm.validTo || '',
            limit: modalForm.orderLimit || '',
            min_purchase: modalForm.validAbove || '',
            valid_for: modalForm.couponFor ? (Array.isArray(modalForm.couponFor) ? modalForm.couponFor : modalForm.couponFor.split(',').map((data) => _.startCase(_.toLower(data)))) : [],
            first_purchase: modalForm.firstPurchase || false
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            let postValues = { ...values }
            postValues.valid_for = values.valid_for.map((option) => option?.title).toString().toLowerCase()
            axios.post(isEdit ? `/coupon/update/${modalForm.id}` : '/coupon/create', postValues)
                .then(() => {
                    toast.success(isEdit ? 'Coupon Updated' : 'Coupon Added')
                    onClose()
                })
                .catch((err) => {
                    if (err?.response?.data?.code?.length) {
                        toast.error(err?.response?.data?.code[0])
                    } else {
                        toast.error(err?.response?.data?.message)
                    }
                })
        }
    })
    console.log('🚀OUTPUT --> formik:', formik.values);
    console.log('🚀OUTPUT --> modalForm:', modalForm);


    useEffect(() => {

    }, [])







    return (
        <>
            <DialogTitle
                align='left'
                fontWeight={600}
                fontSize={14}

                className='tw-flex tw-items-center !tw-text-base  !tw-py-1 !tw-px-4 sm:!tw-px-5 !tw-mb-4 !tw-mt-[5px] sm:!tw-mt-0'
            >
                {isEdit ? "Update Coupon" : "Create New Coupon"}

                <IconButton
                    aria-label="close"
                    onClick={() => {
                        onClose()
                    }}
                    sx={{ color: '#000', position: 'absolute', right: '10px' }}
                >
                    <Cancel />
                </IconButton>

            </DialogTitle>

            <DialogContent className='!tw-px-4 sm:!tw-px-5 tw-mt-[-10px]'>
                <div>
                    Attract new customers, encourage repeat business, and differentiate yourself in a competitive market, ultimately driving overall profitability.
                </div>
                <input
                    style={{
                        display: 'none'
                    }}
                    ref={imageRef}
                    type="file"
                    onChange={(event) => {
                        formik.setFieldValue('image_file', event.target.files[0])
                        formik.setFieldValue('image_link', URL.createObjectURL(event.target.files[0]))
                        formik.setFieldValue('image_file_remove', 0)
                        event.target.value = ''
                    }}
                />
                {props.isEdit2Loading ? (
                    <Loader />
                ) : (
                    <div className="tw-flextw-w-full tw-border-solid tw-border-0 tw-border-stone-300 tw-rounded tw-mt-4">


                        <div className='tw-flex tw-gap-[10px] tw-items-end tw-flex-col sm:tw-flex-row tw-w-full tw-mt-4'>
                            <div className='tw-w-full sm:tw-w-1/2'>
                                <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4  tw-flex tw-gap-2'>
                                    Coupon valid for*
                                </div>
                                {/* <Autocomplete
                                    multiple={true}
                                    options={properties || [{ index: 0 }]}
                                    value={selectedPropertiesOptions || []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(event, newValue, reason) => {
                                        setSelectedPropertiesOptions(newValue);
                                    }}
                                    sx={{
                                        [`& .${inputBaseClasses.root}`]: {
                                            backgroundColor: '#EFEFEF'

                                        },
                                    }}
                                    renderTags={(value, getTagProps) => {
                                        return value.map((option, index) => (
                                            <Chip label={option?.title} {...getTagProps({ index })} />
                                        ))
                                    }}
                                    renderInput={(params) => (
                                        <GenericTextField
                                            {...params}
                                            placeholder="Select valid for"
                                            sx={{
                                                [`& .${outlinedInputClasses.root}`]: {
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px'
                                                }
                                            }}
                                        />
                                    )}

                                    renderOption={(props2, option, state) => {
                                        return (
                                            <>
                                                {state.index == 0 && (
                                                    <Button
                                                        variant="text"
                                                        onClick={props.onCreateProperty}
                                                        fullWidth={true}
                                                    >
                                                        Create new property
                                                    </Button>
                                                )}

                                                <ListItem {...props2} key={option}>
                                                    <Checkbox
                                                        checked={props2['aria-selected']}
                                                    />
                                                    <ListItemText primary={option.title} />
                                                </ListItem>
                                            </>
                                        )
                                    }}
                                /> */}
                                <Autocomplete
                                    multiple
                                    limitTags={3}
                                    id={cn('autocomplete-input')}
                                    options={modalAutoCompleteOptions}
                                    getOptionLabel={(option) => option.title}
                                    isOptionEqualToValue={(option, value) => option.title === value.title}
                                    value={formik.values.valid_for}
                                    onChange={(event, newValue) => {
                                        let newArray = []
                                        newArray.push(...newValue)
                                        const arrayUniqueByKey = [...new Map(newArray.map(item =>
                                            [item['title'], item])).values()];
                                        formik.setFieldValue('valid_for', arrayUniqueByKey)
                                    }}
                                    sx={{
                                        [`& .${inputBaseClasses.root}`]: {
                                            backgroundColor: '#EFEFEF'

                                        },
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <GenericTextFieldWithLabel

                                                placeholder="Select valid for"
                                                {...params}
                                                multiline={false}

                                                sx={{
                                                    [`& .${outlinedInputClasses.root}`]: {
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }
                                                }}
                                            />
                                        )
                                    }}
                                    ChipProps={{
                                        className: cn('autocomplete-chip')
                                    }}
                                />

                            </div>
                            <div className='tw-w-full sm:tw-w-1/2'>
                                <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                    Coupon Code*
                                </div>

                                <GenericTextField
                                    id="code"
                                    name="code"

                                    placeholder="Coupon Code"
                                    containerStyle={{
                                        width: '100%',

                                    }}
                                    sx={{
                                        [`& .${inputBaseClasses.root}`]: {
                                            backgroundColor: '#EFEFEF',
                                            height: 47,
                                        },
                                    }}
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    required={true}
                                    fullWidth={true}
                                    isSmallLabel={true}
                                />
                            </div>
                        </div>

                        <div className='tw-flex tw-gap-[10px] tw-items-start tw-flex-col sm:tw-flex-row tw-mt-4'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div className='tw-w-full sm:tw-w-1/2'>
                                    <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                        Valid from*
                                    </div>
                                    <GenericTextField
                                        id="valid_from"
                                        name="valid_from"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        containerStyle={{
                                            width: '100%',

                                        }}
                                        sx={{
                                            [`& .${inputBaseClasses.root}`]: {
                                                backgroundColor: '#EFEFEF',
                                                height: 47,
                                            },
                                        }}
                                        value={formik.values.valid_from}
                                        onChange={formik.handleChange}
                                        required={true}
                                        fullWidth={true}
                                        isSmallLabel={true}
                                    />
                                </div>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div className='tw-w-full sm:tw-w-1/2'>
                                    <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                        Valid to*
                                    </div>
                                    <GenericTextField
                                        id="valid_to"
                                        name="valid_to"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        containerStyle={{
                                            width: '100%',

                                        }}
                                        sx={{
                                            [`& .${inputBaseClasses.root}`]: {
                                                backgroundColor: '#EFEFEF',
                                                height: 47,
                                            },
                                        }}
                                        value={formik.values.valid_to}
                                        onChange={formik.handleChange}
                                        required={true}
                                        fullWidth={true}
                                        isSmallLabel={true}
                                    />
                                </div>
                            </LocalizationProvider>

                        </div>
                        <div className='tw-flex tw-gap-[10px] tw-items-end tw-flex-col sm:tw-flex-row tw-w-full tw-mt-4'>
                            <div className='tw-w-full sm:tw-w-1/2'>
                                <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                    Discount type
                                </div>

                                <GenericSelectWithLabel
                                    id="type"
                                    name="type"
                                    sx={{
                                        backgroundColor: '#EFEFEF'
                                    }}
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    isSmallLabel={true}
                                >
                                    <MenuItem value="percentage">Percentage</MenuItem>
                                    <MenuItem value="dollar">Dollar</MenuItem>
                                </GenericSelectWithLabel>
                            </div>
                            <div className='tw-w-full sm:tw-w-1/2'>
                                <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                    Discount amount*
                                </div>


                                <ValueTextField
                                    containerStyle={{
                                        width: '100%',

                                    }}
                                    sx={{
                                        [`& .${inputBaseClasses.root}`]: {
                                            backgroundColor: '#EFEFEF',
                                            height: 47,
                                        },
                                    }}
                                    id="value"
                                    name="value"
                                    type={formik.values.type}
                                    value={formik.values.value}
                                    onChange={formik.handleChange}
                                    texttype="number"
                                    isSmallLabel={true}
                                    fullWidth={true}
                                />



                            </div>
                        </div>

                        <div className='tw-flex tw-gap-[10px] tw-items-end tw-flex-col sm:tw-flex-row tw-w-full tw-mt-4'>
                            <div className='tw-w-full sm:tw-w-1/2'>
                                <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                    Number of uses
                                </div>
                                <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                    (leave empty for unlimited)
                                </div>

                                <GenericTextField
                                    containerStyle={{
                                        width: '100%',

                                    }}
                                    sx={{
                                        [`& .${inputBaseClasses.root}`]: {
                                            backgroundColor: '#EFEFEF',
                                            height: 47,
                                        },
                                    }}
                                    id="limit"
                                    name="limit"
                                    placeholder="Number of uses"
                                    value={formik.values.limit}
                                    onChange={formik.handleChange}
                                    type="number"
                                    fullWidth={true}
                                />
                            </div>
                            <div className='tw-w-full sm:tw-w-1/2'>
                                <div className='tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-mr-4 tw-flex tw-gap-2'>
                                    Coupon valid above
                                </div>
                                <div className='tw-font-normal tw-text-sm tw-mr-4 tw-flex tw-gap-2'>
                                    (if empty, then Assume 0)
                                </div>

                                <ValueTextField
                                    containerStyle={{
                                        width: '100%',

                                    }}
                                    sx={{
                                        [`& .${inputBaseClasses.root}`]: {
                                            backgroundColor: '#EFEFEF',
                                            height: 47,
                                        },
                                    }}
                                    id="min_purchase"
                                    name="min_purchase"
                                    value={formik.values.min_purchase}
                                    onChange={formik.handleChange}
                                    type="dollar"
                                    texttype="number"
                                    fullWidth={true}
                                />
                            </div>

                        </div>
                        <div className="tw-flex tw-items-center tw-w-full tw-border-solid tw-border-0 tw-border-stone-300 tw-rounded">
                            <div className="tw-flex tw-items-start tw-gap-2 sm:tw-gap-4 tw-text-sm tw-p-1 sm:tw-items-end sm:tw-flex-row tw-flex-col">
                                <div
                                    style={{
                                        fontWeight: 600,
                                        marginTop: 5
                                    }}
                                >
                                    Coupon applicable for first purchase only
                                </div>
                                <GenericSwitch
                                    background="#0AB221"
                                    width={35}
                                    height={18}
                                    translateX={16}
                                    thumb={13}
                                    isStatus={true}
                                    checked={formik.values.first_purchase}
                                    onText="'ENABLED'"
                                    offText="''"
                                    onChange={(event) => {
                                        formik.setFieldValue('first_purchase', event.target.checked)
                                    }}
                                />

                            </div>
                        </div>




                    </div>
                )}
            </DialogContent >

            <div className='tw-flex tw-justify-between tw-p-4'>
                {/* {props.isEdit && (
                    <div className='tw-min-w-[200px]'>
                        <Button
                            style={{
                                color: 'red'
                            }}
                            variant="text"
                            onClick={() => {
                                props.handleDelete(props?.editObj?.id)
                                props.onClose()
                            }}
                        >
                            Remove Product
                        </Button>
                    </div>
                )} */}

                <div className='tw-flex tw-gap-4 tw-w-full tw-justify-end'>

                    <DialogActions>

                        <GenericButton
                            variant="contained"
                            //type="onclic"
                            onClick={formik.handleSubmit}
                        >
                            {isEdit ? "Update" : "Add"}
                        </GenericButton>
                    </DialogActions>
                </div>
            </div>


        </>
    )
}

export default AddNewCoupnDialog;

