import React,{useState} from "react";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Checkbox,
    Collapse,
    ListItemSecondaryAction,
    IconButton,
    Switch,
    Typography,
    FormControlLabel
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LightTooltip from '../../../../util_comps/LightTooltip';

export const ProductListCategoryList = (props) => {
    let [opened,setOpened] = useState([]);
    let newMargin = props.prevMargin * 2;
    const handleSelect = (e,val) => {
        e.stopPropagation();
        if(props.rootCategory !== props.mainCategory){
            return;
        }
        if(e.target.checked){
            props.handleCategorySelection(val);
        }else{
            props.handleCategoryRemove(val);
        }
    };
    const handleOpen = (category) => {
        let openedCategories = [...opened]
        if(opened.indexOf(category) !== -1){
            openedCategories = openedCategories.filter(val => val !== category);
            setOpened(openedCategories);
        }else{
            openedCategories.push(category);
            setOpened(openedCategories);
        }
    };
    return (
        <React.Fragment>
            <List style={{width: '100%',paddingTop: 0}}>
                {props.categories.map((val) => {
                    let active = false;
                    let getChild = (val) => {
                        if(val.child){
                            for(let cat of val.child){
                                if(props.selectedCategories.findIndex(i => i.category_id === cat.MainCatId) !== -1){
                                    active = true;
                                    break;
                                }else{
                                    getChild(cat)
                                }
                            }
                        }
                    };
                    getChild(val);
                    return (
                        <React.Fragment>
                            <ListItem
                                style={{width: '100%', paddingTop: 0 ,marginLeft: props.prevMargin ? `${newMargin}%` : 'initial' }}
                                key={val.MainCatName}
                                role={undefined}
                                button
                                onClick={e => handleOpen(val.MainCatName)}
                            >
                                <ListItemIcon style={{minWidth: 35}}>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={props.selectedCategories.findIndex(i => i.category_id === val.MainCatId) !== -1}
                                        onChange={e => handleSelect(e,{categoryName: val.MainCatName, category_id: val.MainCatId, category_image: val.image})}
                                        onClick={e => e.stopPropagation()}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="h6" style={{fontSize: '1rem', color: active ? 'red' : 'black'}}>{val.MainCatName}</Typography>}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                    style={{
                                        padding: 0
                                    }}
                                    >
                                        {opened.indexOf(val.MainCatName) === -1 ? <ExpandMoreIcon onClick={e=> handleOpen(val.MainCatName)} /> : <ExpandLessIcon onClick={e => handleOpen(val.MainCatName)} />}
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Collapse
                                timeout="auto"
                                unmountOnExit
                                in={opened.indexOf(val.MainCatName) !== -1}
                            >
                                {val.child && val.child.length > 0 && (
                                    <ProductListCategoryList mainCategory={props.mainCategory} rootCategory={props.rootCategory} handleCategorySelection={props.handleCategorySelection} handleCategoryRemove={props.handleCategoryRemove} selectedCategories={props.selectedCategories} categories={val.child} prevMargin={props.prevMargin ? newMargin : 2} />
                                )}
                            </Collapse>
                        </React.Fragment>
                    );
                })}
            </List>
        </React.Fragment>
    );
};

export const CategoryListCategoryList = (props) => {
    let [opened,setOpened] = useState([]);
    let newMargin = props.prevMargin * 2;
    const handleSelect = (e,val,child) => {
        e.stopPropagation();
        if(e.target.checked){
            props.handleCategorySelection(val,child);
        }else{
            props.handleCategoryRemove(val,child);
        }
    };
    const handleOpen = (category) => {
        let openedCategories = [...opened]
        if(opened.indexOf(category) !== -1){
            openedCategories = openedCategories.filter(val => val !== category);
            setOpened(openedCategories);
        }else{
            openedCategories.push(category);
            setOpened(openedCategories);
        }
    };
    return (
        <React.Fragment>
            <List style={{width: '100%',paddingTop: 0}}>
                {props.categories.map((val) => {
                    let toggleOn = false;
                    let checkboxOn = props.selectedCategories.findIndex(i => i.category_id === val.MainCatId) !== -1;
                    let active = false;
                    let getChild = (val) => {
                        if(val.child){
                            for(let cat of val.child){
                                if(props.selectedCategories.findIndex(i => i.category_id === cat.MainCatId) !== -1){
                                    active = true;
                                    break;
                                }else{
                                    getChild(cat)
                                }
                            }
                        }
                    };
                    getChild(val);
                    if(val.child && val.child.length > 0 && !checkboxOn){
                        for(let child of val.child){
                            if(props.selectedCategories.findIndex(i => i.category_id === child.MainCatId) !== -1){
                                toggleOn = true;
                                break;
                            };
                        }
                    };
                    return (
                        <React.Fragment>
                            <ListItem
                                style={{width: '100%', paddingTop: 0 ,marginLeft: props.prevMargin ? `${newMargin}%` : 'initial' }}
                                key={val.MainCatName}
                                role={undefined}
                                button
                                onClick={e => handleOpen(val.MainCatName)}
                            >
                                <ListItemIcon style={{minWidth: 35}}>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={checkboxOn}
                                        onChange={e => handleSelect(e,{categoryName: val.MainCatName, category_id: val.MainCatId, category_image: val.image,parent_id:props.parentId},val.child)}
                                        onClick={e => e.stopPropagation()}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="h6" style={{fontSize: 12, color: active ? 'red' : 'black'}}>{val.MainCatName}</Typography>}
                                />
                                <ListItemSecondaryAction>
                                    {val.child ?
                                    <LightTooltip title={<span style={{display: 'inline-block', padding: '0 15px'}}>Show Sub-Categories Only</span>} arrow placement="top">
                                        <FormControlLabel
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            checked={toggleOn}
                                            style={{marginLeft: 'auto',marginRight: 0}}
                                            control={<Switch size="small" onChange={e => props.handleToggle(e,val,val.child,props.parentId)} /* checked={props.selectedCategories.findIndex(i => i.category_id === val.MainCatId) !== -1} */ />}
                                        />
                                    </LightTooltip>
                                    : null
                                    }
                                    <IconButton edge="end">
                                        {opened.indexOf(val.MainCatName) === -1 ? <ExpandMoreIcon onClick={e=> handleOpen(val.MainCatName)} /> : <ExpandLessIcon onClick={e => handleOpen(val.MainCatName)} />}
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Collapse
                                timeout="auto"
                                unmountOnExit
                                in={opened.indexOf(val.MainCatName) !== -1}
                            >
                                {val.child && val.child.length > 0 && (
                                    <CategoryListCategoryList handleToggle={props.handleToggle} parentId={val.MainCatId} handleCategorySelection={props.handleCategorySelection} handleCategoryRemove={props.handleCategoryRemove} selectedCategories={props.selectedCategories} categories={val.child} prevMargin={props.prevMargin ? newMargin : 2} />
                                )}
                            </Collapse>
                        </React.Fragment>
                    );
                })}
            </List>
        </React.Fragment>
    );
};
