import { Button, DialogActions, DialogContent } from '@mui/material'
import { FieldArray, Formik } from 'formik'
import React from 'react'
import AddRecipient from '../../EmailMarketing/LaunchCampaign/AddRecipient/AddRecipient'
import GenericButton from '../../Library/GenericButton/GenericButton'
import styles from './AddRecipientDialog.module.scss'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'

const AddRecipientDialog = (props) => {

    const handleSubmit = (values) => {
        axios.post(`/mailchimp-campaign/create-audience-subscriber/${props.id}`, {
            datas: values.recipient
        })
            .then(() => {
                toast.success('Audience Updated')
                props.onClose()
            })
            .catch(()=>{
                toast.error('Something went wrong')
            })
    }

    return (
        <Formik
            initialValues={{
                recipient: [
                    {
                        first_name: '',
                        last_name: '',
                        email: '',
                    }
                ]
            }}
            onSubmit={handleSubmit}
        >
            {formik => (
                <form
                    onSubmit={formik.handleSubmit}
                >
                    <DialogContent>
                        <FieldArray
                            name="recipient"
                            render={arrayHelpers => (
                                <div>
                                    {formik.values.recipient?.map((member, index) => (
                                        <AddRecipient
                                            index={index}
                                            inputFields={[
                                                {
                                                    id: `recipient[${index}].first_name`,
                                                    label: "First Name",
                                                    value: member.first_name,
                                                    onChange: formik.handleChange
                                                },
                                                {
                                                    id: `recipient[${index}].last_name`,
                                                    label: "Last Name",
                                                    value: member.last_name,
                                                    onChange: formik.handleChange
                                                },
                                                {
                                                    id: `recipient[${index}].email`,
                                                    label: "Email",
                                                    value: member.email,
                                                    onChange: formik.handleChange
                                                }
                                            ]}
                                            onDelete={(id) => arrayHelpers.remove(id)}
                                        />
                                    ))}

                                    {(formik.values.recipient.length < 5) && (
                                        <div
                                            className={styles.add_more_btn_container}
                                        >
                                            <GenericButton
                                                variant="contained"
                                                onClick={() => arrayHelpers.push({ first_name: '', last_name: '', email: '' })}
                                            >
                                                + Add More Recipient
                                            </GenericButton>
                                        </div>
                                    )}
                                </div>
                            )}
                        />

                    </DialogContent>

                    <DialogActions>
                        <GenericButton
                            type="submit"
                            variant="contained"
                        >
                            Save
                        </GenericButton>

                        <Button
                            onClick={(e) => {
                                props.onClose()
                            }}
                            style={{
                                backgroundColor: "#DDDDDD",
                                color: "#171717",
                            }}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                    </DialogActions>

                </form>
            )}
        </Formik>
    )
}

export default AddRecipientDialog