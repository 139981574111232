import { Box, Tab } from '@mui/material'
import { ContentState, EditorState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from 'react'
import styles from './EmailCustomizationsEditor.module.scss'
import { TreeView } from '@mui/x-tree-view';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { nodeAxios } from '../../../../../../utils/axiosConfig';
import StyledTreeItem from '../../../../../Library/StyledTreeItem/StyledTreeItem';
import Loader from '../../../../../Library/Loader';
import GenericSwitch2 from '../../../../../Library/GenericSwitch/GenericSwitch2';
import { toast } from 'react-toastify';

const EmailCustomizationsEditor = (props) => {
// console.log('🚀OUTPUT --> emailTypes:', props.emailTypes);

  const [emailSettings, setEmailSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    let emailObj = props.emailTypes.find((data) => data.id === newValue)
    let val;
    if (emailObj?.child?.length) {
      val = emailObj.child[0].id
      props.setValue(val)
    } else {
      val = newValue
      props.setValue(newValue);
    }
  };

  const fetchEditorState = (val) => {
    setIsLoading(true);
    nodeAxios.get(`/venue/email-config/get-by-type/${val}`)
      .then((response) => {
        const blocksFromHtml = htmlToDraft(response.data.data?.text);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        props.setEditorState(editorState);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  
  const fetchEmailSettings = () => {
    nodeAxios
    .get('/venue/email-settings')
    .then((response) => {
        setEmailSettings(response?.data?.data)
    })
    .catch((error) => {
        // toast.error('Something went wrong')
    })
  }
  const updateEmailSettings = (type, value) => {
    nodeAxios
    .post('/venue/update-email-settings', {
        [type]: value,
    })
    .then((response) => {
      toast.success('Email settings updated successfully')
      fetchEmailSettings();
    })
    .catch((error) => {
        // toast.error('Something went wrong')
    })
  }


  useEffect(() => {
    if (props.value) {
      fetchEditorState(props.value)
    }
  }, [props.value])
  useEffect(() => {
    fetchEmailSettings()
  }, [])


  const onChange = (editorState) => {
    props.setEditorState(editorState);
  };

  return (
    <div className='tw-flex tw-flex-col md:tw-flex-row tw-gap-5'>

      <Box className='tw-border-r tw-border-gray'>

        <div className='tw-font-semibold tw-mb-4'>
          Email Lists
        </div>

        <div className='tw-w-[270px]'>
          <TreeView
            aria-label="customized"
            defaultCollapseIcon={<KeyboardArrowDown />}
            defaultExpandIcon={<KeyboardArrowRight />}
            defaultEndIcon={<></>}
            selected={props.value}
            onNodeSelect={handleChange}
            sx={{ flexGrow: 1 }}
          >
            {props.emailTypes?.map((data) => {
              return (
                <StyledTreeItem
                  nodeId={data.id}
                  classes={{
                    selected: styles.tree_item_selected,
                    group: styles.tree_item_group,
                    iconContainer: styles.tree_item_icon_container,
                    content: styles.tree_item_content
                  }}
                  label={
                    <Tab
                      value={data.id}
                      key={data?.label}
                      classes={{
                        root: styles.tabs_root,
                        selected: styles.tab_selected
                      }}
                      label={<div>{data?.label}</div>}
                      onClick={data?.onClick}
                    />
                  }
                >
                  {data.child?.length > 0 && data.child?.map((child1) => {
                    return (
                      <StyledTreeItem
                        nodeId={child1.id}
                        classes={{
                          selected: styles.tree_item_selected
                        }}
                        label={
                          <Tab
                            value={data.id}
                            key={child1?.label}
                            classes={{
                              root: styles.tabs_root,
                              selected: styles.tab_selected
                            }}
                            label={
                              <div className='tw-w-full tw-flex tw-justify-between tw-items-center tw-gap-4'>
                                  <span>
                                    {child1?.label}
                                  </span>

                                  <span className='tw-text-xs tw-text-gray-400 tw-inline-flex tw-items-center tw-gap-1'
                                    // onClick={(e) => { e.stopPropagation(); e.preventDefault();}}
                                  >
                                    <GenericSwitch2
                                        checked={Boolean(emailSettings?.[child1?.id])}
                                        onChange={e => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          updateEmailSettings(child1?.id, e.target.checked)
                                          setEmailSettings({ ...emailSettings, [child1?.id]: e.target.checked })
                                        }}
                                    />
                                    Enabled
                                  </span>
                              </div>
                            }
                            onClick={child1?.onClick}
                          />
                        }
                      // endIcon={<IconButton onClick={() => deleteActivity(child1.id)} ><DeleteForever /></IconButton>}
                      // ContentProps={{
                      //   contentEditable: isEditMode,
                      //   onBlur: (e) => {
                      //     if (e.currentTarget.textContent && e.currentTarget.textContent !== child1.title) {
                      //       handleEdit({ text: e.currentTarget.textContent, id: child1.id });
                      //     }
                      //   }
                      // }}
                      >
                        {/* {child1.child.length > 0 && child1.child.map((child2) => {
                        return (
                          <StyledTreeItem
                            nodeId={child2.id}
                            label={<TreeTitleContainer id={child2.id} handleEdit={handleEdit} isEditMode={isEditMode} image={child2.image} title={child2.title} noIcon={true} />}
                            endIcon={<IconButton onClick={() => deleteActivity(child2.id)} ><DeleteForever /></IconButton>}
                            ContentProps={{
                              contentEditable: isEditMode,
                              onBlur: (e) => {
                                if (e.currentTarget.textContent && e.currentTarget.textContent !== child2.title) {
                                  handleEdit({ text: e.currentTarget.textContent, id: child2.id });
                                }
                              }
                            }}
                          />
                        )
                      })} */}
                      </StyledTreeItem>
                    )
                  })}
                </StyledTreeItem>
              )
            })}
          </TreeView>
        </div>



      </Box>

      <div>
        <div className='tw-font-semibold tw-mb-4'>
          You are editing {props.value.replaceAll('_', ' ')} email
        </div>

        {isLoading ? <Loader /> : (
          <Editor
            // wrapperClassName="wrapper-class"
            editorClassName="editor-class !tw-border-gray tw-rounded-md tw-px-4 "
            toolbarClassName="toolbar-class"
            onEditorStateChange={onChange}
            editorState={props.editorState}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "embedded",
                "image",
                "remove",
                "history",
              ],
            }}
          />
        )}

      </div>

    </div>
  )
}

export default EmailCustomizationsEditor