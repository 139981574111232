import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderList,
  changeOrderStatus,
} from "../../redux/actions/orderActions";
import { Pagination } from "antd";
import Loader from "react-loader-spinner";
import * as _ from "lodash";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Order = ({ orderStatus, orderType, orderChange }) => {
  const history = useNavigate();
  const user = useSelector((store) => store.user);
  const orderList = useSelector((store) => store.order);
  const dispatch = useDispatch();
  const [initialTableData, setInitialTableData] = useState([]);
  const [orderState, setorderState] = useState(false);
  const [allTable, setAllTable] = useState([]);
  const [allTablePageintion, setallTablePageintion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [OrderType, setOrderType] = useState(orderType);
  const [OrderStatus, setOrderStatus] = useState(orderStatus);
  const [errorMessgae, setErrorMessgae] = useState("");
  const [failureOpen, setFailureOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setFailureOpen(false);
  };
  const formatConfig = {
    minimumFractionDigits: 2,
  };
  const detailsView = (id) => {
    if (user.isLoggedIn) {
      history(`/OrderDetails?ID=${id}`);
    } else {
      history("/login");
    }
  };
  const sortBy = (text) => {
    var Data = initialTableData;
    var data = _.orderBy(
      Data,
      ["type", text],
      orderState ? ["asc", "desc"] : ["desc", "asc"]
    );
    setAllTable(data);
    setorderState(!orderState);
  };
  const handleChange = (page, limit) => {
    let params = `state=${getOrderStatusByFullLength(
      OrderStatus
    )}&page=${page}&limit=${limit}&otype=${orderType?.toLowerCase()}`;
    setIsLoading(true);
    dispatch(getOrderList(params)).then((reposnse) => {
      setAllTable(reposnse?.data?.result);
      setInitialTableData(reposnse?.data?.result);
      setallTablePageintion(reposnse?.data?.pagination);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    if (user.isLoggedIn) {
      setIsLoading(true);
      let params = `state=${getOrderStatusByFullLength(
        orderStatus
      )}&otype=${orderType?.toLowerCase()}&page=1&limit=10`;
      dispatch(getOrderList(params)).then((response) => {
        setInitialTableData(response?.data?.result);
        setAllTable(response?.data?.result);
        setallTablePageintion(response?.data?.pagination);
        setIsLoading(false);
      });
    } else {
      history("/");
    }
  }, [dispatch]);
  const getOrderStatusByFullLength = (status) => {
    return status == "Open"
      ? "o"
      : status == "Accepted"
        ? "a"
        : status == "EnRoute"
          ? "r"
          : status == "Cancelled"
            ? "can"
            : "clo";
  };
  const ChangeOrderStatus = (id, order_status) => {
    setIsLoading(true);
    let params = `open_id=${id}&open_status=${order_status}`;
    params = params + `&page=${allTablePageintion?.current_page}&limit=10`;
    setIsLoading(true);
    dispatch(changeOrderStatus(id, order_status)).then((response) => {
      if (response["error"]) {
        setFailureOpen(true);
        setErrorMessgae(response["errors"]);
        setIsLoading(false);
      } else {
        let param;
        if (order_status == "clo") {
          orderChange("o");
          param = `state=o&otype=${OrderType.toLowerCase()}&page=1&limit=10`;
        }
        else {
          orderChange(order_status);
          param = `state=${order_status}&otype=${OrderType.toLowerCase()}&page=1&limit=10`;
        }
        dispatch(getOrderList(param)).then((response1) => {
          setInitialTableData(response1?.data?.result);
          setAllTable(response1?.data?.result);
          setallTablePageintion(response1?.data?.pagination);
          setIsLoading(false);
        });
      }
    });
  };
  return (
    <>
      <div
        className="slides top-section-slider"
        style={{
          display: isLoading ? "block" : "none",
          position: "fixed",
          top: "50%",
          left: "50%",
        }}
      >
        <Loader
          visible={isLoading}
          type="RevolvingDot"
          color="#000"
          height={100}
          width={100}
        />
      </div>
      {!isLoading ? (
        allTable.length === 0 ? (
          <div className="text-center table-bordered pt-5 pb-5 text-driver">
            <img src="images/not-found-data.png" alt="" className="img-fluid mt-1" />
            <h1>No Records Found</h1>
          </div>

        ) : (
          <div class="table-responsive setingslisting">
            <table class="table table-bordered no-wrap">
              <thead>
                <tr>
                  <th
                    onClick={() => {
                      sortBy("Id");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Order id
                  </th>
                  <th
                    onClick={() => {
                      sortBy("name");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Name
                  </th>
                  <th
                    onClick={() => {
                      sortBy("orderdate");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Order Date
                  </th>
                  <th
                    onClick={() => {
                      sortBy("email");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Email
                  </th>
                  <th
                    onClick={() => {
                      sortBy("otype");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Order Type
                  </th>
                  <th
                    onClick={() => {
                      sortBy("payment_method");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Payment Method
                  </th>
                  <th
                    onClick={() => {
                      sortBy("cc_amount");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Charged to Credit Card Amount  </th>
                  <th
                    onClick={() => {
                      sortBy("gift_card_amount");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Charged to Gift Card Amount </th>
                  <th
                    onClick={() => {
                      sortBy("grand_total");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Total Price
                  </th>
                  {getOrderStatusByFullLength(orderStatus) === "o" ||
                    getOrderStatusByFullLength(orderStatus) === "a" ||
                    getOrderStatusByFullLength(orderStatus) === "r" ? (
                    <th>Change Status</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allTable?.length < 0
                  ? "No records Available"
                  : allTable.map((data) => {
                    return (
                      <tr>
                        <td>
                          <a href="" onClick={() => detailsView(data.Id)}>
                            {data.Id}
                          </a>
                        </td>
                        <td>{data.name}</td>
                        <td>{
                          moment(data?.orderdate).format("DD") + " " + moment(data.orderdate).format("MMM") + " " + moment(data?.orderdate).format("YYYY") + " " + moment(data?.orderdate).format("hh:mm A")
                        }</td>
                        <td>{data.email}</td>
                        <td>{data.otype}</td>
                        <td>{data.payment_method}</td>
                        <td>${data?.cc_amount && data?.cc_amount > 0 ?
                          new Intl.NumberFormat("en-IN", formatConfig).format(
                            parseFloat(data.cc_amount)?.toFixed(
                              2
                            )
                          ) : "0:00"}</td>
                        <td>$
                          {data?.gift_card_amount && data?.gift_card_amount > 0 ?
                            new Intl.NumberFormat("en-IN", formatConfig).format(
                              parseFloat(data.gift_card_amount)?.toFixed(
                                2
                              )
                            ) : "0:00"}</td>
                        <td>
                          {data.grand_total}
                        </td>
                        {data.status === "r" ? (
                          <td data-label="Change Status">
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              onClick={() =>
                                ChangeOrderStatus(data.Id, "clo")
                              }
                            >
                              <i class="fa fa-check"></i> Close
                            </button>
                          </td>
                        ) : null}
                        {data.status === "a" ? (
                          <td data-label="Change Status">
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              onClick={() => ChangeOrderStatus(data.Id, "r")}
                            >
                              <i class="fa fa-check"></i> Ready
                            </button>
                          </td>
                        ) : null}
                        {data.status === "o" ? (
                          <td data-label="Change Status">
                            <button
                              type="button"
                              class="btn btn-outline-warning"
                              onClick={() => ChangeOrderStatus(data.Id, "a")}
                            >
                              <i class="fa fa-check"></i> Accept
                            </button>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Snackbar open={failureOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                {errorMessgae}
              </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                {errorMessgae}
              </Alert>
            </Snackbar>
          </div>
        )
      ) : null}
      {(allTablePageintion?.last_page > 1) && (
        <Pagination
          defaultCurrent={allTablePageintion?.current_page}
          total={allTablePageintion?.total}
          onChange={handleChange}
          showSizeChanger={false}
        />
      )}
    </>
  );
};
export default Order;
