import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDashboardList } from "../../redux/actions/dashboardList";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import Carousel, { Modal, ModalGateway } from "react-images";
import { getImageUrlDefault, getSubstring, isImageExists } from "../helpers/isImageExists";
import LightTooltip from "../../util_comps/LightTooltip";
import { Breadcrumbs, Typography } from "@mui/material";
import Loader from "../Library/Loader";
import PieChart from "../Charts/PieChart";
import LineGraph from "../Charts/LineGraph";
import GenericButton from "../Library/GenericButton/GenericButton";
import styles from './Dashboard.module.scss'

const Dashboard = (props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [iscleared, setIsCleared] = useState(false);
  const [currentOrderDate, setCurrentOrderDate] = useState([]);
  const [previousOrderDate, setPreviousOrderDate] = useState([]);
  const [previousOrderAmount, setPreviousOrderAmount] = useState([]);
  const [currentOrderAmount, setCurrentOrderAmount] = useState([]);
  const [uniqueCurrentUser, setUniqueCurrentUser] = useState([]);
  const [totalCurrentUser, setTotalCurrentUser] = useState([]);
  const [lineCurrentUsers, setLineCurrentUsers] = useState([]);
  const [uniquePreviousUser, setUniquePreviousUser] = useState([]);
  const [totalPreviousUser, setTotalPreviousUser] = useState([]);
  const [linePreviousUsers, setLinePreviousUsers] = useState([]);
  const [salesBreakDownCurrent, setSalesBreakDownCurrent] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [defaultDropDownValue, setdefaultDropDownValue] = useState(moment().subtract(1, "weeks").format("YYYY-MM-DD"));
  const [salesBreakDownPrevious, setSalesBreakDownPrevious] = useState([]);

  const [salesCatBreakDownCurrent, setSalesCatBreakDownCurrent] = useState({
    beer: 0,
    wine: 0,
    liqour: 0,
    total: 0,
  });

  const [salesCatBreakDownPrevious, setSalesCatBreakDownPrevious] = useState({
    beer: 0,
    wine: 0,
    liqour: 0,
    total: 0,
  });
  const [currentDates, setCurrentDate] = useState({
    startDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [previousDates, setPreviousDates] = useState({
    startDate: moment(moment().subtract(1, "weeks"))
      .subtract(1, "days")
      .subtract(
        moment(moment()).diff(
          moment().subtract(1, "weeks").format("YYYY-MM-DD"),
          "days"
        ),
        "days"
      )
      .format("YYYY-MM-DD"),
    endDate: moment(moment().subtract(1, "weeks"))
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
  });

  const dashboardData = useSelector((store) => store.dashboard.dashboardlist.data);
  const isDashBoardLoading = useSelector((store) => store.dashboard.isDashBoardLoading);

  const formatConfig = {
    minimumFractionDigits: 2,
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  var loadjs = require("loadjs");
  const handleStartDateChange = (e) => {
    setCurrentDate({
      ...currentDates,
      ["startDate"]: moment(e.target.value).format("YYYY-MM-DD"),
    });
    setPreviousDates({
      ...previousDates,
      startDate: moment(e.target.value)
        .subtract(1, "days")
        .subtract(
          moment(currentDates.endDate).diff(e.target.value, "days"),
          "days"
        )
        .format("YYYY-MM-DD"),
      endDate: moment(e.target.value).subtract(1, "days").format("YYYY-MM-DD"),
    });
  };
  const openLightbox = (index, image_file, product_category) => {
    if (isImageExists(image_file)) {
      if (image_file) {
        const image = [
          {
            source: image_file,
          },
        ];
        setCurrentImage(index);
        setViewerIsOpen(true);
        setImage(image);
      }
    }
    else {
      const image = [
        {
          source: getImageUrlDefault(
            getSubstring(product_category)
          )
        },
      ];
      setCurrentImage(index);
      setViewerIsOpen(true);
      setImage(image);


    }
  };
  const handleEndDateChange = (e) => {
    setCurrentDate({
      ...currentDates,
      ["endDate"]: moment(e.target.value).format("YYYY-MM-DD"),
    });
    setPreviousDates({
      ...previousDates,
      startDate: moment(currentDates.startDate)
        .subtract(1, "days")
        .subtract(
          moment(e.target.value).diff(currentDates.startDate, "days"),
          "days"
        )
        .format("YYYY-MM-DD"),
      endDate: moment(currentDates.startDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
    });
  };
  const handleTypeChange = (e) => {
    var startdate;
    var enddate;
    if (e.target.value !== "CurrentMonth" && e.target.value !== "LastMonth") {
      setdefaultDropDownValue(e.target.value);
      startdate = e.target.value;
      enddate = moment();
      setCurrentDate({
        ...currentDates,
        ["startDate"]: startdate,
        ["endDate"]: moment().format("YYYY-MM-DD"),
      });
      setPreviousDates({
        ...previousDates,
        startDate: moment(e.target.value)
          .subtract(1, "days")
          .subtract(
            moment(enddate).diff(moment(e.target.value), "days"),
            "days"
          )
          .format("YYYY-MM-DD"),
        endDate: moment(startdate).subtract(1, "days").format("YYYY-MM-DD"),
      });
    } else if (e.target.value == "CurrentMonth") {
      setdefaultDropDownValue(e.target.value);
      startdate = moment().startOf("month");
      enddate = moment();
      setCurrentDate({
        ...currentDates,
        ["startDate"]: moment().startOf("month").format("YYYY-MM-DD"),
        ["endDate"]: moment().format("YYYY-MM-DD"),
      });
      setPreviousDates({
        ...previousDates,
        startDate: moment(startdate)
          .subtract(1, "days")
          .subtract(moment(enddate).diff(startdate, "days"), "days")
          .format("YYYY-MM-DD"),
        endDate: moment(startdate).subtract(1, "days").format("YYYY-MM-DD"),
      });
    } else if (e.target.value == "LastMonth") {
      setdefaultDropDownValue(e.target.value);
      startdate = moment().subtract(1, "months").startOf("month");
      enddate = moment().subtract(1, "months").endOf("month");
      setCurrentDate({
        ...currentDates,
        ["startDate"]: moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        ["endDate"]: moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD"),
      });
      setPreviousDates({
        ...previousDates,
        startDate: moment(startdate)
          .subtract(1, "days")
          .subtract(moment(enddate).diff(startdate, "days"), "days")
          .format("YYYY-MM-DD"),
        endDate: moment(startdate).subtract(1, "days").format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    let params = `current_start_date=${currentDates.startDate}&end_start_date=${currentDates.endDate}&current_previous_date=${previousDates.startDate}&end_previous_date=${previousDates.endDate}`;
    dispatch(getDashboardList(params));
  }, []);

  const handleSubmit = (e) => {
    setIsCleared(true)
  };
  useEffect(() => {
    if (iscleared) {
      setIsLoading(true);
      let params = `current_start_date=${currentDates.startDate}&end_start_date=${currentDates.endDate}&current_previous_date=${previousDates.startDate}&end_previous_date=${previousDates.endDate}`;
      dispatch(getDashboardList(params));
      setIsCleared(false)
    }

  }, [iscleared])
  const handleClear = (e) => {
    setCurrentDate({
      startDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
    setPreviousDates({
      startDate: moment(moment().subtract(1, "weeks"))
        .subtract(1, "days")
        .subtract(
          moment(moment()).diff(
            moment().subtract(1, "weeks").format("YYYY-MM-DD"),
            "days"
          ),
          "days"
        )
        .format("YYYY-MM-DD"),
      endDate: moment(moment().subtract(1, "weeks"))
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
    });
    setdefaultDropDownValue(moment()
      .subtract(1, "weeks")
      .format("YYYY-MM-DD"));
    setIsCleared(true)
  }
  const salesCurrent = {
    labels: ["Beer", "Wine", "Liquor", "cider", "mead", "others"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: [
          "#471527",
          "#e12c2c",
          "#ac5203",
          "#49598c",
          "#e12c2c",
          "#d2a83e"
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#35014F",
          "#175000"
        ],
        data: salesBreakDownCurrent,
        weight: 2,
      },
    ],
  };
  const salesPrevious = {
    labels: ["Beer", "Wine", "Liquor", "cider", "mead", "others"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: [
          "#471527",
          "#e12c2c",
          "#ac5203",
          "#49598c",
          "#e12c2c",
          "#d2a83e"
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#35014F",
          "#175000",
        ],
        data: salesBreakDownPrevious,
        weight: 2,
      },
    ],
  };
  const currentDonutData = {
    labels: ["Pickup", "Shipping", "Delivery", "Tips"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: [
          "#471527",
          "#ac5203",
          "#fdd552",
          "#49598c",
          "#e12c2c",
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#35014F",
        ],
        data: [
          dashboardData?.sales?.salesCurrentAmount[0]?.pickup,
          dashboardData?.sales?.salesCurrentAmount[0]?.shipping,
          dashboardData?.sales?.salesCurrentAmount[0]?.delivery,
        ],
        weight: 2,
      },
    ],
  };
  const previousDonutData = {
    labels: ["Pickup", "Shipping", "Delivery", "Tips"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: [
          "#471527",
          "#ac5203",
          "#fdd552",
          "#49598c",
          "#e12c2c",
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#35014F",
        ],
        data: [
          dashboardData?.sales?.salesPreviousAmount[0]?.pickup,
          dashboardData?.sales?.salesPreviousAmount[0]?.shipping,
          dashboardData?.sales?.salesPreviousAmount[0]?.delivery,
          dashboardData?.sales?.salesPreviousAmount[0]?.tip,
        ],
        weight: 2,
      },
    ],
  };
  const polarDataCurrent = {
    datasets: [
      {
        data: [
          dashboardData?.user_age?.currentAge["1921"],
          dashboardData?.user_age?.currentAge["2225"],
          dashboardData?.user_age?.currentAge["2630"],
          dashboardData?.user_age?.currentAge["3140"],
          dashboardData?.user_age?.currentAge["4150"],
          dashboardData?.user_age?.currentAge["51100"],
        ],
        backgroundColor: [
          "#d2a83e",
          "#471527",
          "#ac5203",
          "#fdd552",
          "#49598c",
          "#e12c2c",
        ],
        label: "My dataset",
      },
    ],
    labels: ["19-21", "22-25", "26-30", "31-40", "41-50", "51+"],
  };
  const polarDataPrevious = {
    datasets: [
      {
        data: [
          dashboardData?.user_age?.previousAge["1921"],
          dashboardData?.user_age?.previousAge["2225"],
          dashboardData?.user_age?.previousAge["2630"],
          dashboardData?.user_age?.previousAge["3140"],
          dashboardData?.user_age?.previousAge["4150"],
          dashboardData?.user_age?.previousAge["51100"],
        ],
        backgroundColor: [
          "#d2a83e",
          "#471527",
          "#ac5203",
          "#fdd552",
          "#49598c",
          "#e12c2c",
        ],
        label: "My dataset",
      },
    ],
    labels: ["19-21", "22-25", "26-30", "31-40", "41-50", "51+"],
  };
  useEffect(() => {



    var currentdate = dashboardData?.sales?.salesCurrentData?.map((item) => {
      return moment(item.order_date).format("MMM-DD");
    });
    setCurrentOrderDate(currentdate);
    var previousDate = dashboardData?.sales?.salesPreviousData?.map((item) => {
      return moment(item.order_date).format("MMM-DD");
    });
    setPreviousOrderDate(previousDate);
    var prevAmount = dashboardData?.sales?.salesPreviousData?.map((item) => {
      return item.amount;
    });
    setPreviousOrderAmount(prevAmount);
    var currentAmount = dashboardData?.sales?.salesCurrentData?.map((item) => {
      return item.amount;
    });
    setCurrentOrderAmount(currentAmount);
    var lineusers = dashboardData?.checkin?.checkInCurrent?.map((item) => {
      return item.checkin_date;
    });
    setLineCurrentUsers(lineusers);
    var lineusers = dashboardData?.checkin?.checkInCurrent?.map((item) => {
      return item.total;
    });
    setTotalCurrentUser(lineusers);
    var lineusers = dashboardData?.checkin?.checkInCurrent?.map((item) => {
      return item.unique_user;
    });
    setUniqueCurrentUser(lineusers);
    var linePrevioususers = dashboardData?.checkin?.checkInPrevious?.map(
      (item) => {
        return item.checkin_date;
      }
    );
    setLinePreviousUsers(linePrevioususers);
    var linePrevioususers = dashboardData?.checkin?.checkInPrevious?.map(
      (item) => {
        return item.total;
      }
    );
    setTotalPreviousUser(linePrevioususers);
    var linePrevioususers = dashboardData?.checkin?.checkInPrevious?.map(
      (item) => {
        return item.unique_user;
      }
    );
    setUniquePreviousUser(linePrevioususers);
    var beer = 0
    var wine = 0
    var liqour = 0;
    let Itemtotal = 0;
    var salesBreakdownCurrent = dashboardData?.sales_breakdown?.salesCurrentBreakDown?.map(
      (item) => {
        if (item.maincatid == "1") {
          beer = item.amount > 0 ? item.amount?.toFixed(2) : item.amount;
        } else if (item.maincatid == "2") {
          wine = item.amount > 0 ? item.amount?.toFixed(2) : item.amount;
        } else if (item.maincatid == "3") {
          liqour = item.amount > 0 ? item.amount?.toFixed(2) : item.amount;
        }
        Itemtotal += item.amount;
        return item.total;
      }

    );

    setSalesCatBreakDownCurrent({
      ...salesCatBreakDownCurrent,
      beer: beer,
      wine: wine,
      liqour: liqour,
      total: Itemtotal,
    });

    setSalesBreakDownCurrent(salesBreakdownCurrent);
    var pbeer = 0
    var pwine = 0
    var pliqour = 0;
    let pItemtotal = 0;
    var salesBreakdownPrevious = dashboardData?.sales_breakdown?.salesPreviousBreakDown?.map(
      (item) => {
        if (item.maincatid == "1") {
          pbeer = item.amount > 0 ? item.amount?.toFixed(2) : item.amount;
        } else if (item.maincatid == "2") {
          pwine = item.amount > 0 ? item.amount?.toFixed(2) : item.amount;
        } else if (item.maincatid == "3") {
          pliqour = item.amount > 0 ? item.amount?.toFixed(2) : item.amount;
        }
        pItemtotal += item.amount;
        return item.total;
      }
    );
    setSalesCatBreakDownPrevious({
      ...salesCatBreakDownPrevious,
      beer: pbeer,
      wine: pwine,
      liqour: pliqour,
      total: pItemtotal.toFixed(2),
    });
    setSalesBreakDownPrevious(salesBreakdownPrevious);
    setIsLoading(false);
  }, [dashboardData]);
  const linedataPrevious = {
    labels: previousOrderDate,
    datasets: [
      {
        label: "Previous",
        data: previousOrderAmount,
        fill: false,
        borderColor: "#471527",
      },
    ],
  };
  const linedataCurrent = {
    labels: currentOrderDate,
    datasets: [
      {
        label: "Current",
        data: currentOrderAmount,
        fill: false,
        borderColor: "#17a2b8",
      },
    ],
  };
  const lineDataCurrentUsers = {
    label: "Current",
    labels: lineCurrentUsers,
    datasets: [
      {
        label: "Total User",
        data: totalCurrentUser,
        fill: false,
        borderColor: "#003350",
      },
      {
        label: "Unique User",
        data: uniqueCurrentUser,
        fill: false,
        borderColor: "#ac5203",
      },
    ],
  };
  const lineDataPreviousUsers = {
    label: "Previous",
    labels: linePreviousUsers,
    datasets: [
      {
        label: "Total User",
        data: totalPreviousUser,
        fill: false,
        borderColor: "#003350",
      },
      {
        label: "Unique User",
        data: uniquePreviousUser,
        fill: false,
        borderColor: "#ac5203",
      },
    ],
  };
  const getColorByCategory = (catId) => {
    switch (catId) {
      case 1:
        return "#471527";
      case 2:
        return "#ac5203";
      case 3:
        return "#fdd552";
      case 4:
        return "#49598c";
      case 5:
        return "#e12c2c";
      default:
        return "#d2a83e";
    }
  }
  const getIconByCategory = (catId) => {
    switch (catId) {
      case 1:
        return "fas fa-beer color2";
      case 2:
        return "fas fa-wine-glass color3";
      case 3:
        return "fas fa-glass-martini color4";
      default:
        return "fas fa-wine-glass color3";
    }
  }


  return (
    <div class="page-wrapper">

      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <Breadcrumbs>
              <Typography
                color="textPrimary"
              >
                Home
              </Typography>
              <Typography
                color="textPrimary"
              >
                Dashboard
              </Typography>
            </Breadcrumbs>

            <div class="row">
              <div class="col-md-12">
                <div class="row inputheight1">
                  <div class="col-md-2 fullwidthnew">
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Select a period from the dropdown below and click on show button to see the data.</span>} arrow placement="top">
                      <label class="mt-2 titleheading">Period</label>
                    </LightTooltip>
                    <div class="">
                      <div class="form-group selectoption">
                        <select onChange={(e) => { handleTypeChange(e) }} value={defaultDropDownValue}>
                          <option value={moment().format("YYYY-MM-DD")} >
                            Today
                          </option>
                          <option
                            value={moment()
                              .subtract(1, "weeks")
                              .format("YYYY-MM-DD")}
                          >
                            Last 7 days
                          </option>
                          <option
                            value={moment()
                              .subtract(30, "days")
                              .format("YYYY-MM-DD")}
                          >
                            Last 30 days
                          </option>
                          <option
                            value={moment()
                              .subtract(60, "days")
                              .format("YYYY-MM-DD")}
                          >
                            Last 60 days
                          </option>
                          <option
                            value={moment()
                              .subtract(90, "days")
                              .format("YYYY-MM-DD")}
                          >
                            Last 90 days
                          </option>
                          <option value="CurrentMonth">Current Month</option>
                          <option value="LastMonth">Last Month</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 fullwidthnew">
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Enter start date below.</span>} arrow placement="top">
                      <label class="mt-2 titleheading">
                        Current Start Date
                      </label>
                    </LightTooltip>
                    <div class="">
                      <div class="form-group">
                        <input
                          type="date"
                          className="form-control"
                          onChange={handleStartDateChange}
                          value={currentDates.startDate}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 fullwidthnew">
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Enter end date below</span>} arrow placement="top">
                      <label class="mt-2 titleheading">Current End Date</label>
                    </LightTooltip>
                    <div class="">
                      <div class="form-group">
                        <input
                          type="date"
                          class="form-control"
                          onChange={handleEndDateChange}
                          value={currentDates.endDate}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>



                  {/*<span class="mt-3 text-center fullwidthnew">
                    <label class="mt-2 titleheading"></label>
                    <div class="">
                      <div class="form-group">
                        <h4 style={{ marginLeft: "0%", fontSize: "18px" }}>
                          Vs
                          </h4>
                      </div>
                    </div>
                  </span>*/}


                  <div class="col-md-2 fullwidthnew">
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>This date is calculated automatically to compare to relevant previous time.</span>} arrow placement="top">
                      <label class="mt-2 titleheading">
                        {" "}
                        Previous Start Date
                      </label>
                    </LightTooltip>
                    <div class="">
                      <div class="form-group">
                        <input
                          type="date"
                          disabled={true}
                          className="form-control"
                          value={previousDates.startDate}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 fullwidthnew">
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>This date is calculated automatically to compare to relevant previous time.</span>} arrow placement="top">
                      <label class="mt-2 titleheading">
                        {" "}
                        Previous End Date
                      </label>
                    </LightTooltip>
                    <div class="">
                      <div class="form-group">
                        <input
                          type="date"
                          class="form-control"
                          disabled={true}
                          value={previousDates.endDate}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={styles.filter_btns_container}
                  >
                    <GenericButton
                      variant="contained"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={props.isActionsDisabled}
                    >
                      Show
                    </GenericButton>
                    <GenericButton
                      variant="contained"
                      type="submit"
                      btnColor="secondary"
                      onClick={handleClear}
                      disabled={props.isActionsDisabled}
                    >
                      Clear
                    </GenericButton>
                  </div>

                </div>
              </div>
            </div>
          </div>

          {isDashBoardLoading ? (
            <div>
              <Loader />
              {/* <div class="lds-ripple">
                <div class="lds-pos"></div>
                <div class="lds-pos"></div>
              </div> */}
            </div>
          ) : null}

          {!isDashBoardLoading &&
            <>
              {!isLoading ? (
                <>
                  <div class="card-group newcard">
                    <div class="card border-right">
                      {/* <div class="col-md-12 pl-0">
                    <h4>
                      Current
            </h4>
                  </div> */}
                      <div class="card-body">
                        <span style={{ color: "#6c757d!important" }}>
                          Total Revenue
                        </span>
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                          <div>
                            <div class="d-inline-flex align-items-center">
                              <h2 class="text-dark mb-1 font-weight-medium">
                                ${dashboardData?.sales?.salesCurrentAmount[0]?.totalAmount?.toFixed(2) || '0.00'}
                              </h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">
                              Current
                            </h6>
                          </div>
                          <div class="ml-auto mt-md-3 mt-lg-0">
                            <span class="opacity-7 text-muted">
                              <i class="fas fa-box-open color2"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* 
                  <div class="col-md-12 pl-0">
                    <h4>
                      Previous
            </h4>
                  </div> */}
                      <div
                        class="card-body"
                        style={{ padding: "0.25rem !important" }}
                      >
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                          <div>
                            <div class="d-inline-flex align-items-center">
                              <h2 class="text-dark mb-1 font-weight-medium">
                                ${dashboardData?.sales?.salesPreviousAmount[0]?.totalAmount?.toFixed(2) || '0.00'}
                              </h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">
                              Previous
                            </h6>
                          </div>
                          <div class="ml-auto mt-md-3 mt-lg-0">
                            <span class="opacity-7 text-muted">
                              <i class="fas fa-box-open color2"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card border-right">
                      <div class="card-body">
                        Beer
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                          <div>
                            <div class="d-inline-flex align-items-center">
                              <h2 class="text-dark mb-1 font-weight-medium">
                                $
                                {new Intl.NumberFormat("en-IN", formatConfig).format(
                                  salesCatBreakDownCurrent?.beer
                                )}
                              </h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">
                              Current
                            </h6>
                          </div>
                          <div class="ml-auto mt-md-3 mt-lg-0">
                            {" "}
                            <span class="opacity-7 text-muted">
                              <i class="fas fa-beer color2" style={{ color: "#471527" }}></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                          <div>
                            <div class="d-inline-flex align-items-center">
                              <h2 class="text-dark mb-1 font-weight-medium">
                                $
                                {new Intl.NumberFormat("en-IN", formatConfig).format(
                                  salesCatBreakDownPrevious.beer
                                )}
                              </h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">
                              Previous
                            </h6>
                          </div>
                          <div class="ml-auto mt-md-3 mt-lg-0">
                            {" "}
                            <span class="opacity-7 text-muted">
                              <i class="fas fa-beer color2" style={{ color: "#471527" }}></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card border-right">
                      <div class="card-body">
                        Wine
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                          <div>
                            <div class="d-inline-flex align-items-center">
                              <h2 class="text-dark mb-1 font-weight-medium">
                                $
                                {new Intl.NumberFormat("en-IN", formatConfig).format(
                                  salesCatBreakDownCurrent?.wine
                                )}
                              </h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">
                              Current
                            </h6>
                          </div>
                          <div class="ml-auto mt-md-3 mt-lg-0">
                            {" "}
                            <span class="opacity-7 text-muted">
                              <i class="fas fa-wine-glass color3"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                          <div>
                            <div class="d-inline-flex align-items-center">
                              <h2 class="text-dark mb-1 font-weight-medium">
                                $
                                {new Intl.NumberFormat("en-IN", formatConfig).format(
                                  salesCatBreakDownPrevious.wine
                                )}
                              </h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">
                              Previous
                            </h6>
                          </div>
                          <div class="ml-auto mt-md-3 mt-lg-0">
                            {" "}
                            <span class="opacity-7 text-muted">
                              <i class="fas fa-wine-glass color3"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card border-right">
                      <div class="card-body">
                        Business
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                          <div>
                            <div class="d-inline-flex align-items-center">
                              <h2 class="text-dark mb-1 font-weight-medium">
                                ${" "}
                                {new Intl.NumberFormat("en-IN", formatConfig).format(
                                  salesCatBreakDownCurrent?.liqour
                                )}
                              </h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">
                              Current
                            </h6>
                          </div>
                          <div class="ml-auto mt-md-3 mt-lg-0">
                            {" "}
                            <span class="opacity-7 text-muted">
                              <i class="fas fa-glass-martini color4"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                          <div>
                            <div class="d-inline-flex align-items-center">
                              <h2 class="text-dark mb-1 font-weight-medium">
                                $
                                {new Intl.NumberFormat("en-IN", formatConfig).format(
                                  salesCatBreakDownPrevious.liqour
                                )}
                              </h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">
                              Previous
                            </h6>
                          </div>
                          <div class="ml-auto mt-md-3 mt-lg-0">
                            {" "}
                            <span class="opacity-7 text-muted">
                              <i class="fas fa-glass-martini color4"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6">
                      <div class="card">
                        <div class="card-body">
                          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Your sales summary for the period chosen above</span>} arrow placement="top">
                            <h4 class="card-title">Sales Summary</h4>
                          </LightTooltip>

                          <ul class="list-inline text-center mt-4">
                            <li class="list-inline-item">
                              <h5>
                                <i class="fa fa-circle mr-1 text-info"></i>Current
                                : $
                                {dashboardData?.sales?.salesCurrentAmount[0]?.totalAmount?.toFixed(2) || '0.00'}
                              </h5>
                            </li>
                          </ul>
                          <LineGraph
                            data={linedataCurrent}
                          />
                          <h4 style={{ paddingLeft: "50%" }}>Vs</h4>
                          <ul class="list-inline text-center mt-4">
                            <li class="list-inline-item">
                              <h5>
                                <i class="fa fa-circle mr-1 text-cyan"></i>
                                Previous : $
                                {dashboardData?.sales?.salesPreviousAmount[0]?.totalAmount?.toFixed(2) || '0.00'}
                              </h5>
                            </li>
                          </ul>
                          <LineGraph
                            data={linedataPrevious}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="card pb-5">
                        <div class="card-body">
                          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Number of orders for the period chosen above</span>} arrow placement="top">
                            <h4 class="card-title">Orders</h4>
                          </LightTooltip>
                          <div class="row mt-4">
                            <div class="col-lg-6">
                              <ul class="list-inline text-center mb-0">
                                <li class="list-inline-item">
                                  <h5>Current</h5>
                                  <h1 class="mb-0">
                                    {dashboardData?.sales?.salesCurrentAmount[0]?.total || '0.00'}
                                  </h1>
                                </li>
                              </ul>
                              <Doughnut
                                data={currentDonutData}
                                options={{
                                  responsive: true,
                                  legend: {
                                    display: false,
                                  },
                                }}
                              />
                            </div>
                            <div class="col-lg-6">
                              <ul class="list-inline text-center mb-0">
                                <li class="list-inline-item">
                                  <h5>Previous</h5>
                                  <h1 class="mb-0">
                                    {dashboardData?.sales?.salesPreviousAmount[0]?.total || '0.00'}
                                  </h1>
                                </li>
                              </ul>
                              <Doughnut
                                data={previousDonutData}
                                options={{
                                  responsive: true,
                                  legend: {
                                    display: false,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card pb-4">
                        <div class="card-body">
                          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Number of transactions for the period chosen above</span>} arrow placement="top">
                            <h4 class="card-title">Purchases</h4>
                          </LightTooltip>
                          <div class="row mt-4">
                            <div class="col-lg-6">
                              <ul class="list-inline text-center mb-0">
                                <li class="list-inline-item">
                                  <h5>Current</h5>
                                </li>
                              </ul>
                              <div class="card card-hover mt-4">
                                <div class="p-2 bg-primary1 text-center">
                                  <h1 class="font-light text-white">
                                    {dashboardData?.purchase?.purchaseCurrent[0]
                                      ?.total > 0
                                      ? new Intl.NumberFormat("en-IN").format(
                                        dashboardData?.purchase
                                          ?.purchaseCurrent[0]?.total
                                      )
                                      : "0"}
                                  </h1>
                                  <h6 class="text-white">Transaction</h6>
                                </div>
                              </div>
                              <div class="card card-hover">
                                <div class="p-2 bg-cyan text-center">
                                  <h1 class="font-light text-white">
                                    $
                                    {dashboardData?.purchase?.purchaseCurrent[0]
                                      ?.totalAmount > 0
                                      ? new Intl.NumberFormat("en-IN", formatConfig).format(
                                        dashboardData?.purchase?.purchaseCurrent[0]?.totalAmount?.toFixed(
                                          2
                                        )
                                      )
                                      : "0"}
                                  </h1>
                                  <h6 class="text-white">Sales</h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <ul class="list-inline text-center mb-0">
                                <li class="list-inline-item">
                                  <h5>Previous</h5>
                                </li>
                              </ul>
                              <div class="card card-hover mt-4">
                                <div class="p-2 bg-primary1 text-center">
                                  <h1 class="font-light text-white">
                                    {dashboardData?.purchase?.purchasePrevious[0]
                                      ?.total > 0
                                      ? new Intl.NumberFormat("en-IN").format(
                                        dashboardData?.purchase
                                          ?.purchasePrevious[0]?.total
                                      )
                                      : "0"}
                                  </h1>
                                  <h6 class="text-white">Transaction</h6>
                                </div>
                              </div>
                              <div class="card card-hover">
                                <div class="p-2 bg-cyan text-center">
                                  <h1 class="font-light text-white">
                                    $
                                    {dashboardData?.purchase?.purchasePrevious[0]
                                      ?.totalAmount > 0
                                      ? new Intl.NumberFormat("en-IN", formatConfig).format(
                                        dashboardData?.purchase?.purchasePrevious[0]?.totalAmount?.toFixed(
                                          2
                                        )
                                      )
                                      : "0"}
                                  </h1>
                                  <h6 class="text-white">Sales</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="card">
                        <div class="card-body">
                          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Breakdown by category for the period chosen above</span>} arrow placement="top">
                            <h4 class="card-title">Sales Breakdown</h4>
                          </LightTooltip>
                          <div>
                            <div class="row mt-4">
                              <div class="col-lg-6">
                                <ul class="list-inline text-center mb-0">
                                  <li class="list-inline-item">
                                    <h5>Current</h5>
                                  </li>
                                </ul>

                                <PieChart
                                  data={salesCurrent}
                                />
                              </div>
                              <div class="col-lg-6">
                                <ul class="list-inline text-center mb-0">
                                  <li class="list-inline-item">
                                    <h5>Previous</h5>
                                  </li>
                                </ul>
                                <PieChart
                                  data={salesPrevious}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Demographics Breakdown of purchasers for the period chosen above</span>} arrow placement="top">
                            <h4 class="card-title">Customer Demographics</h4>
                          </LightTooltip>
                          <div class="row">
                            <div class="col-lg-12">
                              <ul class="list-inline text-center mb-0 mt-4">
                                <li class="list-inline-item">
                                  <h5>Age Group</h5>
                                </li>
                              </ul>
                            </div>
                            <div class="col-lg-6">
                              <ul class="list-inline text-center mb-0 mt-4">
                                <li class="list-inline-item">
                                  <h5>Current</h5>
                                </li>
                              </ul>
                              <PieChart
                                data={polarDataCurrent}
                              />
                            </div>
                            <div class="col-lg-6">
                              <ul class="list-inline text-center mb-0 mt-4">
                                <li class="list-inline-item">
                                  <h5>Previous</h5>
                                </li>
                              </ul>
                              <PieChart
                                data={polarDataPrevious}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="card">
                        <div class="card-body">
                          <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Number of product reviews for the period chosen above</span>} arrow placement="top">
                            <h4 class="card-title">Check In’s</h4>
                          </LightTooltip>
                          <div class="row mt-4">
                            <div class="col-lg-6">
                              <ul class="list-inline text-center mb-0">
                                <li class="list-inline-item">
                                  <h5>Current</h5>
                                </li>
                              </ul>
                              <div class="card card-hover mt-4">
                                <div class="p-2 bg-primary1 text-center">
                                  <h1 class="font-light text-white">{dashboardData?.checkin?.checkInCurrentTotal[0]?.total > 0 ? new Intl.NumberFormat("en-IN").format(
                                    dashboardData?.checkin?.checkInCurrentTotal[0]?.total
                                  ) : 0}</h1>
                                  <h6 class="text-white">Total Check In</h6>
                                </div>
                              </div>
                              <div class="card card-hover">
                                <div class="p-2 bg-cyan text-center">
                                  <h1 class="font-light text-white">{dashboardData?.checkin?.checkInCurrentTotal[0]?.unique_user > 0 ? new Intl.NumberFormat("en-IN").format(
                                    dashboardData?.checkin?.checkInCurrentTotal[0]?.unique_user
                                  ) : 0}</h1>
                                  <h6 class="text-white">Unique User</h6>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <ul class="list-inline text-center mb-0">
                                <li class="list-inline-item">
                                  <h5>Previous</h5>
                                </li>
                              </ul>
                              <div class="card card-hover mt-4">
                                <div class="p-2 bg-primary1 text-center">
                                  <h1 class="font-light text-white">{dashboardData?.checkin?.checkInPreviousTotal[0]?.total > 0 ? new Intl.NumberFormat("en-IN").format(
                                    dashboardData?.checkin?.checkInPreviousTotal[0]?.total
                                  ) : 0}</h1>
                                  <h6 class="text-white">Total Check In</h6>
                                </div>
                              </div>
                              <div class="card card-hover">
                                <div class="p-2 bg-cyan text-center">
                                  <h1 class="font-light text-white">{dashboardData?.checkin?.checkInPreviousTotal[0]?.unique_user > 0 ? new Intl.NumberFormat("en-IN").format(
                                    dashboardData?.checkin?.checkInPreviousTotal[0]?.unique_user
                                  ) : 0}</h1>
                                  <h6 class="text-white">Unique User</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <ul class="list-inline text-center mb-0">
                              <li class="list-inline-item">
                                <h5>Current</h5>
                              </li>
                            </ul>
                            <LineGraph
                              data={lineDataCurrentUsers}
                            />
                            <h4 style={{ paddingLeft: "50%" }}>Vs</h4>
                            <ul class="list-inline text-center mb-0">
                              <li class="list-inline-item">
                                <h5>Previous</h5>
                              </li>
                            </ul>
                            <LineGraph
                              data={lineDataPreviousUsers}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      {dashboardData?.popular_product
                        ?.popularProductNearByVenueCurrent?.length > 0 ? (
                        <div class="card">
                          <div class="card-body">
                            <h4 class="card-title">Popular Product Near by</h4>
                            <div class="scrollheight">
                              {dashboardData?.popular_product
                                ?.popularProductNearByVenueCurrent &&
                                dashboardData?.popular_product?.popularProductNearByVenueCurrent.map(
                                  (item) => {
                                    return (
                                      <div class="border-bootm">
                                        <div class="row">
                                          <div class="col-md-9 text">
                                            <div class="imgupload">
                                              <img src={item.image_file}
                                                class="img-fluid"
                                                width="50"
                                                height="50"
                                                onClick={() =>
                                                  openLightbox(0, item.image_file, item?.main_category_name)
                                                } />
                                            </div>
                                            <h5>{item?.Name}</h5>
                                            <p>{item?.Description}</p>
                                          </div>
                                          <div class="col-md-3">
                                            {" "}
                                            <span class="tagsblue">
                                              {item?.main_category_name}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {dashboardData?.popular_product
                        ?.popularProductAtMyVenueCurrent?.length > 0 ? (
                        <div class="card">
                          <div class="card-body">
                            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Best Selling products for the period chosen above</span>} arrow placement="top">
                              <h4 class="card-title">
                                Popular Product at your Store
                              </h4>
                            </LightTooltip>
                            <div class="scrollheight">
                              {dashboardData?.popular_product
                                ?.popularProductAtMyVenueCurrent &&
                                dashboardData?.popular_product?.popularProductAtMyVenueCurrent.map(
                                  (item) => {
                                    return (
                                      <div class="border-bootm">
                                        <div class="row">
                                          <div class="col-md-9 text">
                                            <div class="imgupload">
                                              <img src={item.image_file}
                                                class="img-fluid"
                                                width="50"
                                                height="50"
                                                onClick={() =>
                                                  openLightbox(0, item.image_file, item?.main_category_name)
                                                }
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src = getImageUrlDefault(
                                                    getSubstring(item?.main_category_name)
                                                  );
                                                }} />
                                            </div>
                                            <h5>{item?.Name}</h5>
                                            <p>{item?.Description}</p>
                                          </div>
                                          <div class="col-md-3" >
                                            {" "}

                                            <span class="tagsblue" style={{ backgroundColor: getColorByCategory(item?.maincatid) }}>
                                              <i className={getIconByCategory(item?.maincatid)} style={{ fontSize: "15px" }}></i>   {item?.main_category_name}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <ModalGateway>
                      {viewerIsOpen && image.length > 0 && (
                        <Modal onClose={closeLightbox}>
                          <Carousel
                            currentIndex={currentImage}
                            views={image.map((x) => ({
                              ...x,
                              srcset: x.source,
                              caption: "esplanda",
                            }))}
                          />
                        </Modal>
                      )}
                    </ModalGateway>
                  </div>
                </>
              ) : null}{" "}</>}
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
