/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './MyLibraryFilters.module.scss';
import { Button, FormControl, InputLabel, MenuItem } from '@mui/material';
import clsx from 'clsx';
import GenericSelect from '../../../GenericSelect/GenericSelect';
import GenericSearchField from '../../../GenericSearchField/GenericSearchField';


function MyLibraryFilters(props) {
  const [expanded, setExpanded] = useState(); //'panel0'

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function showClearFilters() {
    return (
      props.sortBy !== 'created_at' ||
      props.searchQuery !== ''
    );
  }

  return (
    <div className={clsx(styles.school_list_filters_container)}>
      <SearchContainer {...props} />
      {/* <SortByFilter {...props} /> */}

      {/* {showClearFilters() && (
          <Button
            variant='text'
            color='primary'
            sx={{ fontWeight: 'semibold' }}
            onClick={props.handleClearAllFilters}
          >
            Clear all filters
          </Button>
        )} */}
    </div>
  );
}

export default MyLibraryFilters;

const SortByFilter = ({ sortBy, handleChangeSort }) => {
  return (
    <FormControl
      sx={{
        // m: 1,
        minWidth: 120,
        maxWidth: 125,
        '@media (max-width: 600px)': {
          maxWidth: '100%',
          width: '100% !important',
        },
      }}
      size='small'
    >
      <InputLabel focused={false}>Sort By</InputLabel>
      <GenericSelect value={sortBy} label='Sort By' onChange={handleChangeSort}>
        <MenuItem value='created_at'>Recently added</MenuItem>
        <MenuItem value='ASC'>Name A-Z</MenuItem>
        <MenuItem value='DESC'>Name Z-A</MenuItem>
      </GenericSelect>
    </FormControl>
  );
};


const SearchContainer = ({
  searchQuery,
  debounceSearch,
  setSearchQuery,
  ...props
}) => {
  return (
    <GenericSearchField
      variant='outlined'
      placeholder={`Search Image `}
      value={searchQuery}
      fullWidth={true}
      onChange={(event) => {
        debounceSearch(event);
        setSearchQuery(event.target.value);
      }}
    />
  );
};
