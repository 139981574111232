import React from 'react'
import styles from './PageLoader.module.scss'
import { CircularProgress } from '@mui/material'

const PageLoader = () => {
    return (
        <div
            className={styles.container}
        >
            <CircularProgress
                style={{
                    color: 'white'
                }}
                size={200}
            />
        </div>
    )
}

export default PageLoader