import React, { useCallback, useState } from 'react';
import styles from './FormHeader.module.scss';
import { getInitialsAvatar } from '../../../../utils/helpers';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import * as yup from 'yup';
import { nodeAxios } from '../../../../utils/axiosConfig';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import GenericSwitch from '../../../Library/GenericSwitch/GenericSwitch';
import { useFormik } from 'formik';
import Input from '../../../Library/Input/Input';
import { debounce } from 'lodash';

const validationSchema = yup.object({
  title: yup.string().required('Form title is required'),
});

function FormHeader({ form, ...props }) {
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: form?.data?.title,
      is_email: form?.data?.is_email,
      is_sms: form?.data?.is_sms,
      twilio_type: form?.data?.twilio_type || '1',
      twilio_number: form?.data?.twilio_number
      // config: {
      //   ...form?.data?.config,
      // },
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log('🚀OUTPUT --> values:', values);
      setIsLoading(true);

      const payload = {
        title: values.title,
        is_email: Boolean(values.is_email) ? 1 : 0,
        is_sms: Boolean(values.is_sms) ? 1 : 0,
        twilio_type: values.twilio_type,
        twilio_number: values.twilio_number
      }
      nodeAxios
        .post(`/web-builder/web-page-form/edit/${form?.data?.id}`, payload)
        .then((response) => {
          console.log('🚀OUTPUT --> response:', response);

          setIsLoading(false);
          toast.success('Form updated successfully!');
          props.fetchForm();
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
          setIsLoading(false);
        });
    },
  });
  // console.log('🚀OUTPUT --> formik:', formik.values);
  // console.log('🚀OUTPUT --> formik:', typeof formik.values.config);

  const debounceSubmit = useCallback(debounce(formik.handleSubmit, 1000), [])

  return (
    <div className={styles.children_profile_header}>
      <div className={styles.children_profile_header_detail}>
        <div className={styles.children_profile_header_detail_section}>
          <div
            style={{ display: 'none' }}
            className={styles.children_profile_image}
          >
            <div className={styles.children_image_circle}>
              <img
                className={styles.personal_info_image_tile_avatar}
                src={getInitialsAvatar(form?.data?.title)}
                height={180}
                width={180}
              />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className={styles.children_profile_detail}
          >
            <div className={styles.child_name_container}>
              <div className={styles.child_name}>
                {!edit ? (
                  <div>
                    {form?.data?.title && form?.data?.title !== 'null'
                      ? form?.data?.title
                      : ''}
                  </div>
                ) : (
                  <div>
                    <TextField
                      variant='outlined'
                      color='primary'
                      name='title'
                      label='Title'
                      fullWidth
                      size='small'
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      sx={{ fontSize: '24px', fontWeight: 'bold' }}
                      required
                      onBlur={formik.handleBlur}
                      error={formik.touched.title && formik.values.title === ''}
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </div>
                )}
              </div>
              {edit ? (
                <div className={styles.editBtnRow}>
                  <Button
                    variant='text'
                    color='inherit'
                    // sx={{ py: 0, px: 1, minWidth: 'auto' }}
                    onClick={() => {
                      formik.resetForm();
                      setEdit(false);
                    }}
                  >
                    cancel
                  </Button>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    // sx={{ py: 0, px: 2, minWidth: 'auto' }}
                    type='submit'
                    loading={isLoading}
                    loadingPosition='end'
                    size='medium'
                    // sx={{ px: 1, py: 0.5 }}
                    endIcon={<div style={{ width: isLoading ? 20 : 0 }} />}
                  >
                    Save
                  </LoadingButton>
                </div>
              ) : (
                <div className={styles.editBtnRow}>
                  <Button
                    variant='text'
                    color='primary'
                    sx={{ fontSize: '18px', fontWeight: 'bold' }}
                    onClick={() => {
                      formik.resetForm();
                      setEdit(true);
                    }}
                  >
                    Edit form name
                  </Button>
                </div>
              )}
            </div>
            {/* <div>
              Show as popup
              <GenericSwitch
                height={18}
                width={36}
                thumb={13}
                translateX={18}
                sx={{ ml: 1 }}
                checked={formik.values.config?.show_as_popup}
                onChange={(e) => {
                  formik.setFieldValue(
                    'config.show_as_popup',
                    e.target.checked
                  );
                  formik.handleSubmit();
                }}
              />
            </div> */}
            <div
              className="tw-flex tw-flex-col tw-items-end tw-gap-1"
            >
              <div>
                Send email for every response.
                <GenericSwitch
                  height={18}
                  width={36}
                  thumb={13}
                  translateX={18}
                  sx={{ ml: 1 }}
                  checked={formik.values.is_email}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'is_email',
                      e.target.checked
                    );
                    formik.handleSubmit();
                  }}
                />
              </div>
              <div
                className="tw-flex tw-flex-col tw-items-end tw-gap-1"
              >
                <div>
                  Send sms and voice notifications.
                  <GenericSwitch
                    height={18}
                    width={36}
                    thumb={13}
                    translateX={18}
                    sx={{ ml: 2 }}
                    checked={formik.values.is_sms}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'is_sms',
                        e.target.checked
                      );
                      formik.handleSubmit();
                    }}
                  />
                </div>
                <small
                  className="tw-w-96"
                >
                  Sms and voice support is only available for US  or canada. Use Whatsapp for other international destination.
                </small>
                <div
                  className="tw-flex tw-gap-1 tw-items-center"
                >
                  <div>
                    When new order is created, send
                  </div>

                  <Select
                    id="twilio_type"
                    name="twilio_type"
                    size="small"
                    value={formik.values.twilio_type}
                    onChange={(event) => {
                      formik.handleChange(event)
                      formik.handleSubmit()
                    }}
                  >
                    <MenuItem value={1}>Text/SMS</MenuItem>
                    <MenuItem value={2}>Voice</MenuItem>
                    <MenuItem value={3}>Whatsapp</MenuItem>
                  </Select>

                  <div>
                    To
                  </div>

                  <Input
                    id="twilio_number"
                    name="twilio_number"
                    value={formik.values.twilio_number}
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={formik.handleSubmit}
                  >
                    Save Number
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.children_profile_header_actions}>
      </div>
    </div>
  );
}

export default FormHeader;
