import React from "react";
import { makeStyles } from "@mui/styles";
import "react-input-range/lib/css/index.css";
import PriceRange from "./PriceRange";
import Rating from "./Rating";
import Country from "./Country";
import ABV from "./Abvs";
import Brewery from "./Brewery";
import {
    InputLabel,
    FormControl,
    Select,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import LightTooltip from "../../../../util_comps/LightTooltip";
import SortBySelect from "../../../Library/SortBySelect/SortBySelect";
const useStyles = makeStyles({
    root: {
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        height: 328,
    },
});


export default function Filter(props) {
    const [checkedBox, setCheckedBox] = React.useState("none");
    const [activePanel, setActivePanel] = React.useState("none");
    const classes = useStyles();
    const handlePanelOpen = (panelName) => {
        if (panelName === activePanel) {
            setActivePanel("none");
        } else {
            setActivePanel(panelName);
        }
    };
    return (
        <React.Fragment>
            <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Apply any filters below to filter product.</span>} arrow placement="top">
                <Typography style={{ fontSize: 18, marginBottom: '10px', width: 'fit-content' }} variant="h6">Apply Filters</Typography>
            </LightTooltip>
            <div
                style={{ border: "1px solid lightgrey" }}
                className={classes.root}
            >
                <div style={{ padding: "10px" }}>
                    <div style={{ width: '100%', textAlign: 'end', marginBottom: '3px', display: JSON.stringify(props.initState.current.filters) !== JSON.stringify(props.filters) ? 'block' : 'none' }}>
                        <Typography onClick={e => props.setFilters(props.initState.current.filters)} variant="h6" style={{ color: 'red', fontSize: '.7rem', cursor: 'pointer' }}>(Clear all)</Typography>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginBottom: "3%",
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <div
                                style={{
                                    marginRight: '6px'
                                }}
                            >
                                Sort By
                            </div>
                            <SortBySelect
                                value={props.filters.sortby}
                                onChange={(e) =>
                                    props.setFilters({
                                        ...props.filters,
                                        sortby: e.target.value,
                                    })
                                }
                                menuItems={[
                                    {
                                        value: "none",
                                        name: "Select"
                                    },
                                    {
                                        value: "newproduct",
                                        name: "Newly Added"
                                    },
                                    {
                                        value: "pricelowhigh",
                                        name: "Price Low To High"
                                    },
                                    {
                                        value: "pricehighlow",
                                        name: "Price High To Low"
                                    },
                                    {
                                        value: "nameaz",
                                        name: "Name (A-Z)"
                                    },
                                    {
                                        value: "nameza",
                                        name: "Name (Z-A)"
                                    },
                                    {
                                        value: "ratehighlow",
                                        name: "Ratings (High To Low)"
                                    },
                                    {
                                        value: "ratelowhigh",
                                        name: "Ratings (Low To High)"
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <PriceRange
                        activePanel={activePanel}
                        handlePanelOpen={handlePanelOpen}
                        setFilters={props.setFilters}
                        filters={props.filters}
                        initState={props.initState}
                    />
                    <Rating
                        activePanel={activePanel}
                        handlePanelOpen={handlePanelOpen}
                        setFilters={props.setFilters}
                        filters={props.filters}
                        initState={props.initState}
                    />
                    <Country
                        activePanel={activePanel}
                        checkedBox={checkedBox}
                        setFilters={props.setFilters}
                        filters={props.filters}
                        handlePanelOpen={handlePanelOpen}
                        initState={props.initState}
                    />
                    <ABV
                        activePanel={activePanel}
                        handlePanelOpen={handlePanelOpen}
                        setFilters={props.setFilters}
                        filters={props.filters}
                        initState={props.initState}
                    />
                    <Brewery
                        setFilters={props.setFilters}
                        filters={props.filters}
                        activePanel={activePanel}
                        handlePanelOpen={handlePanelOpen}
                        initState={props.initState}
                        mainCategory={props.mainCategory}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
