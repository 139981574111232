import React, { useEffect, useState } from 'react'
import WebsiteBuilder from '../ManageSite/ManageSite'
import { Outlet, useOutletContext } from 'react-router-dom'
import axios from '../../utils/axiosConfig'

const WebsiteBuilderRoute = () => {

  const { open, setOpen } = useOutletContext()

  const [externalWebsite, setExternalWebsite] = useState('')
  const [subDomain, setSubDomain] = useState('')
  const [openFindProduct, setOpenFindProduct] = useState(false)
  const [messaging, setMessaging] = useState({})
  const [rte, setRte] = useState()


  const fetchSocialList = (cb) => {
    axios.get('/venue-setting/social-list')
      .then((response) => {
        setExternalWebsite(response?.data?.data?.result?.external_website)
        setSubDomain(response?.data?.data?.result?.sub_domain)
      })
      .catch(() => {
        // setNames([])
      })
      .finally(() => {
      })
  }

  useEffect(() => {
    setOpen(false)
  }, [])

  return (
    <Outlet
      context={{
        externalWebsite,
        subDomain,
        open,
        setOpen,
        openFindProduct: openFindProduct,
        setOpenFindProduct: setOpenFindProduct,
        messaging: messaging,
        setMessaging: setMessaging,
        setRte,
        rte
      }}
    />
  )
}

export default WebsiteBuilderRoute