import React, {useRef} from 'react';
import {Typography} from '@mui/material';
import { PrimaryButton } from '../../../../util_comps/CustomButton';
import { Close } from '@mui/icons-material';
import {useClickOutside} from '../../../../hooks/useClickOutside'
import DollarInput from './DollarInput';

const Modal = (props) => {
    const modalRef = useRef(null);
    const handleClose = () => {
        if(props.active){
            props.setActiveModal({name: "none", props: {}});
        }    
    };
    useClickOutside(modalRef,handleClose);
    return (
        <div ref={modalRef} className='inventory-modal' style={{position: 'fixed' , display: props.active === true ? 'initial' : 'none' ,backgroundColor: 'white' , zIndex: '999999999',top: '25%', left: '60%', width: '35vw' ,transform: 'translate(-60%,-25%)'}}>
            <div style={{padding: '20px 28px 28px 28px'}}>
                <div style={{display: 'flex', position: 'relative' ,alignItems: 'center', justifyContent: 'center', marginBottom: '30px'}}>
                    <Typography variant="h6" style={{fontSize: '1.1rem', textAlign: 'center'}}>Discount This Item</Typography>
                    <div onClick={handleClose} style={{fontSize: '23px', cursor: 'pointer' ,position: 'absolute', left: '95%', transform: 'translateY(-2px)' ,color: 'black', display: 'flex', alignItems: 'center'}}>
                        <Close fontSize="inherit" color="inherit" />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px'}}>
                    <Typography variant="h6" style={{fontSize: '1rem', marginRight: '15px'}}>Old Price</Typography>
                    <Typography variant="h6" style={{fontSize: '1rem'}}>{props.oldPrice}</Typography>
                </div>
                <div style={{paddingLeft: '10px'}}>
                    <Typography variant="h6" style={{fontSize: '.93rem', marginBottom: '5px'}}>New Price</Typography>
                    <DollarInput padding="10px 14px" fontSize="initial" imageWidth="50px" placeholder="New Price" />
                </div>
                <div style={{marginTop: '40px', display: 'flex', justifyContent: 'flex-end'}}>
                    <PrimaryButton style={{padding: '8px 20px',fontSize: '.95rem'}}>Submit</PrimaryButton>
                </div>
            </div>
        </div>
    )
};

export default Modal;