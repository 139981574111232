import React from "react";
import { Checkbox, Typography, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LightTooltip from "../../../../../util_comps/LightTooltip";
import Const from "../../../../helpers/const";

const BankInfo = (props) => {
  const dispatch = useDispatch((data) => data);
  const { bankingInfo } = useSelector((state) => state.venueInfo);
  const handleChange = (newVal) => {
    let isDirty = false;
    Object.entries(newVal).map(([key, val], i) => {
      if (val !== "") {
        isDirty = true;
      }
    });
    if (isDirty) {
      props.setDirty();
    } else {
      props.setPristine();
    }
    dispatch({
      type: "SET_BANKING_INFO",
      payload: {
        ...bankingInfo,
        ...newVal,
      },
    });
  };
  return (
    <div>
      <div>
        <Typography
          style={{
            color: "black",
            fontSize: "1.15rem",
            fontWeight: "bold",
            marginBottom: 7,
          }}
        >
          Bank Info
        </Typography>
        <div style={{ marginBottom: 30 }}>
          <div
            style={{
              marginBottom: 15,
              display: 'flex',
              gap: 10
            }}
          >
            <div

            >
              <Typography
              >
                Legal Name *
              </Typography>
              <TextField
                onChange={(e) =>
                  handleChange({
                    legal_name: e.target.value,
                  })
                }
                value={bankingInfo.legal_name}
                variant="outlined"
              />
            </div>
            <div
            >
              <Typography
              >
                DBA Name *</Typography>
              <TextField
                onChange={(e) =>
                  handleChange({
                    dba_name: e.target.value,
                  })
                }
                value={bankingInfo.dba_name}
                variant="outlined"

              />
            </div>
            <div style={{ flex: 1 }}>
              <Typography

              >Tax ID *</Typography>
              <TextField
                onChange={(e) =>
                  handleChange({
                    tax_id: e.target.value,
                  })
                }
                value={bankingInfo.tax_id}
                variant="outlined"

              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 10
            }}
          >
            <div
            >
              <Typography
              >
                Bank Routing Number *
              </Typography>
              <TextField
                onChange={(e) =>
                  handleChange({
                    bank_routing_no: e.target.value,
                  })
                }
                value={bankingInfo.bank_routing_no}
                variant="outlined"

              />
            </div>
            <div style={{ flex: 1 }}>
              <LightTooltip
                title={
                  <span style={{ display: "inline-block", padding: "0 15px" }}>
                    Your daily sales will be deposited into this account by
                    credit card proccessor. If you have any Our{Const.isOurDesiStore ? 'DesiStore' : 'LiquorStore'}
                    monthly fees, we will automatically debit from this account,
                    on every 1<sup>st</sup> of the month
                  </span>
                }
                arrow
                placement="top"
              >
                <Typography
                  style={{
                    color: "blue",
                  }}
                >
                  Bank Account Number *
                </Typography>
              </LightTooltip>
              <TextField
                onChange={(e) =>
                  handleChange({
                    bank_account_no: e.target.value,
                  })
                }
                value={bankingInfo.bank_account_no}
                variant="outlined"

              />
            </div>
          </div>
        </div>
      </div>

      <div
      >
        <Typography
          style={{
            color: "black",
            fontSize: "1.15rem",
            fontWeight: "bold",
            marginBottom: 7,
          }}
        >
          Store Owner Info
        </Typography>
        <div>
          <div
            style={{
              display: 'flex',
              gap: 10
            }}
          >
            <div
            >
              <Typography
              >
                First Name *
              </Typography>
              <TextField
                onChange={(e) =>
                  handleChange({
                    first_name: e.target.value,
                  })
                }
                value={bankingInfo.first_name}
                variant="outlined"

              />
            </div>
            <div
            >
              <Typography
              >Last Name *</Typography>
              <TextField
                onChange={(e) =>
                  handleChange({
                    last_name: e.target.value,
                  })
                }
                value={bankingInfo.last_name}
                variant="outlined"

              />
            </div>
            <div
            >
              <Typography
              >
                Owners Birthday *
              </Typography>
              <TextField
                onChange={(e) =>
                  handleChange({
                    birthdate: e.target.value,
                  })
                }
                value={bankingInfo.birthdate}
                variant="outlined"
                type="date"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "25px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            disableRipple
            disableTouchRipple
            disableFocusRipple
            checked={bankingInfo.terms_accepted}
            onChange={(e) =>
              handleChange({
                terms_accepted: !bankingInfo.terms_accepted,
              })
            }
          />
          <Typography style={{ color: "black" }}>
            Accept{" "}
            <a
              href={`https://www.Our${Const.isOurDesiStore ? "DesiStore" : "LiquorStore"
                }.com/terms`}
              style={{ textDecoration: "underline", color: "#0000EE" }}
            >
              Terms Of Service
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default BankInfo;
