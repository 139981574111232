const initialState = {
    isLoading: false,
    isLoggedIn: false,
    productDetails: {},
    productDetailsError: ""
};
function productDetails(state = initialState, action) {
    switch (action.type) {
        case "SET_PRODUCT_DETAILS_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_PRODUCT_DETAILS_LOADED":
            return { ...initialState, isLoading: false , productDetails: action.payload };
        case "SET_PRODUCT_DETAILS_ERROR":
            return { ...initialState,productDetailsError:action.payload};
        default:
            return state;
    }
};
export default productDetails;  