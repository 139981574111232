import React, { useCallback, useContext, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/styles';
import { Button, Tooltip, Dialog, Pagination, Radio, tooltipClasses, useMediaQuery } from '@mui/material';
import { Add, KeyboardArrowRight } from '@mui/icons-material';
import AddNewProductDialog from './AddNewProductDialog/AddNewProductDialog';
import axios, { nodeAxios } from '../../../../utils/axiosConfig'
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import MarketingContext from '../../../Marketing2/Util/marketingContext';
import { debounce } from 'lodash';
import Loader from '../../../Library/Loader';
import { useNavigate } from 'react-router-dom';
import AddModifierDialog from './AddModifierDialog/AddModifierDialog';
import { TreeItem } from '@mui/x-tree-view';
import AddPropertiesDialog from '../../../Properties/AddPropertiesDialog/AddPropertiesDialog';
import { reorder } from '../../../CustomPageBuilder/EditSection/Util/helpers';
import ItemsHeader from './ItemsHeader/ItemsHeader';
import { ReactComponent as EmptyPlates } from '../../../../assets/svgs/empty_plates.svg';
import ItemsTable from './ItemsTable/ItemsTable';
import MobileItems from './MobileItems/MobileItems';


const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle
});

const Items = ({ isActionDisabled }) => {

    const { subDomain } = useContext(MarketingContext)
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:1000px)')

    const confirm = useConfirm()
    const [showAddNewProduct, setShowAddNewProduct] = useState(false)
    const [showAddModifier, setShowAddModifier] = useState(false)
    const [showAddProperties, setShowAddProperties] = useState(false)
    const [showCategoryTooltip, setShowCategoryTooltip] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState({})
    const [editObj, setEditObj] = useState({})
    const [editModifierObj, setEditModifierObj] = useState({})
    const [options, setOptions] = useState({})
    const [searchStr, setSearchStr] = useState("");
    const [sort, setSort] = useState({ sort_by: 'sequence_id', sort_by_order: 'ASC' })
    console.log('🚀OUTPUT --> sort:', sort);
    const [catId, setCatId] = useState(0)
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isEdit2Loading, setIsEdit2Loading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const [selectedCatName, setSelectedCatName] = useState('')
    const [modifiers, setModifiers] = useState([])
    const [properties, setProperties] = useState([])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchMyList = (page, queryStr) => {
        setIsListLoading(true)
        axios.get('/product/my-list', {
            params: {
                page: page || 1,
                q: queryStr || searchStr,
                sort_by: sort.sort_by,
                sort_by_order: sort.sort_by_order,
                category_id: catId || undefined,
                limit: Boolean(catId) ? 100 : undefined
            }
        })
            .then((res) => {
                setProducts(res.data?.data?.result)
                setPagination(res.data?.data?.pagination)
            })
            .finally(() => {
                setIsSearchLoading(false)
                setIsListLoading(false)
            })
    }


    const fetchModifiers = () => {
        axios.get('/modifier')
            .then((res) => {
                console.log('🚀OUTPUT --> props.isEdit /modifier:', isEdit);
                const data = res.data?.data?.results?.map((data) => ({
                    ...data,
                    modifier_id: data?.id,
                    [isEdit ? 'modifier_options' : 'options']: data.options?.map((data2) => ({ ...data2, is_visible: 1 }))
                }))
                setModifiers(data)
            })
    }

    const fetchProperty = () => {
        axios.get('/product-property')
            .then((res) => {
                setProperties(res.data?.data?.results)
            })
    }


    const handleSearch = (searchStr) => {
        try {
            setIsSearchLoading(true)
            fetchMyList(pagination.current_page, searchStr)
        } catch (err) {
            toast.error('Error occured while fetching inventories, please try again.')
        };
    };

    const debounceSearch = useCallback(debounce(handleSearch, 500), [])


    useEffect(() => {
        fetchMyList()
    }, [])

    useEffect(() => {
        fetchMyList(pagination.current_page)
    }, [sort, catId])


    const handleDelete = (id, name) => {
        confirm({
            title: 'Are you sure you want to delete product',
        })
            .then(() => {
                axios.delete(`/venue-product/delete/${id}`)
                    .then(() => {

                        toast.success('Product deleted')

                        nodeAxios.post('/change-log/create', { what: `${name} is deleted` })
                            .then(() => {

                            })
                            .catch((error) => {
                                console.log('🚀OUTPUT --> error:', error);
                            });
                        fetchMyList()
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
            })
    }

    const handleEdit2 = (id) => {
        setIsEdit2Loading(true)
        setShowAddNewProduct(true)
        axios.get(`/venue-product/get/${id}`)
            .then((res) => {
                setEditObj(res.data?.data?.result)
                setIsEdit(true)
                // setShowAddNewProduct(true)
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setIsEdit2Loading(false)
            })
    }

    const handleCopyUrl = (data) => {
        navigator.clipboard
            .writeText(
                `${subDomain}/product/${data?.ProductSlug}/${data?.ProductId}`
            )
            .then(() => {
                toast.success('Url is copied successfully')
            });
    };

    const handleChangeVisibility = (data) => {

        if (!(options[data.id]?.noWebSales != undefined ? options[data.id]?.noWebSales : (data.noWebSales === 0))) {
            axios.get(`/product/visibility/${data.id}`, {
                params: {
                    product_id: data.ProductId
                }
            })
                .then((res) => {
                    toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
                    setOptions((prevState) => ({
                        ...prevState,
                        [data.id]: {
                            ...prevState[data.id],
                            noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
                        }
                    }))

                    nodeAxios.post('/change-log/create', { what: `${res?.data?.data?.result.ProductName} is visible` })
                        .then(() => {

                        })
                        .catch((error) => {
                            console.log('🚀OUTPUT --> error:', error);
                        });
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
        } else {
            confirm({
                title: `Are you sure you want to disable this product`,
            })
                .then(() => {
                    axios.get(`/product/visibility/${data.id}`, {
                        params: {
                            product_id: data.ProductId
                        }
                    })
                        .then((res) => {
                            toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
                            setOptions((prevState) => ({
                                ...prevState,
                                [data.id]: {
                                    ...prevState[data.id],
                                    noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
                                }
                            }))

                            nodeAxios.post('/change-log/create', { what: `${res?.data?.data?.result.ProductName} is hidden` })
                                .then(() => {

                                })
                                .catch((error) => {
                                    console.log('🚀OUTPUT --> error:', error);
                                });
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.message)
                        })
                })
                .catch(() => {

                })
        }
    };

    const handleEdit = (name, value, data) => {
        if (name === "limited") {
            if (value < 0) {
                return;
            }

            axios.post(`/product/update-my-product/${data.id}`, {
                id: data.id,
                limited: value
            })
                .then(() => {
                    toast.success('Updated')
                })
            setOptions((prevState) => ({
                ...prevState,
                [data.id]: {
                    ...prevState[data.id],
                    limited: value
                }
            }))
        }
    };

    const fetchVenueCatMenu = () => {
        axios.get('/venue-category-order', {
            params: {
                only_if_product_exists: 1
            }
        })
            .then((res) => {
                setVenueCategoryMenu(res.data?.data?.results)
            })
    }

    useEffect(() => {
        fetchVenueCatMenu()
    }, [])

    const getTreeItem = (child) => {
        return child?.map((data) => (
            <TreeItem icon={(
                <Radio
                    checked={data.category_id == catId}
                    onChange={(event) => {
                        setSelectedCatName(data.category_name)
                        setCatId(event.target.value)
                    }}
                    value={data.category_id}
                />
            )}
                nodeId={data.category_id}
                label={<div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8
                    }}
                >
                    <div>
                        {data.category_name}
                    </div>

                    {Boolean(data?.child?.length) && (
                        <KeyboardArrowRight />
                    )}
                </div>}
            >
                {Boolean(data?.child?.length) && getTreeItem(data?.child)}
            </TreeItem>
        ))
    }

    const handleSetsequence = (items) => {
        let payload = items.map(element => element.id)
        // items?.forEach((element, index) => {
        //     payload[index] = element?.id
        // });

        axios.post('/venue-product/set-sequence', {
            venue_product_ids: payload
        })
            .then(() => {

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            products,
            result.source.index,
            result.destination.index
        );

        setProducts(newitems);
        handleSetsequence(newitems)
    };


    return (
        <div className="tw-py-[10px] lg:tw-py-[25px] tw-flex tw-flex-col tw-gap-5">
            <ItemsHeader {...{ sort, setSort, searchStr, setSearchStr, debounceSearch, isSearchLoading, setShowAddNewProduct, isMobile, isActionDisabled, fetchMyList }} />

            {isListLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>

                    {isMobile ? (
                        <MobileItems
                            {...{ products, options, sort, setSort, handleEdit2, handleDelete, handleChangeVisibility, getTreeItem, venueCategoryMenu, catId, setCatId, selectedCatName, setSelectedCatName, handleClick2, handleClose2, anchorEl2, open2, onDragEnd, getItemStyle, isActionDisabled }}
                        />
                    ) : (
                        <ItemsTable
                            {...{ products, options, sort, setSort, handleEdit2, handleDelete, handleChangeVisibility, getTreeItem, venueCategoryMenu, catId, setCatId, selectedCatName, setSelectedCatName, handleClick2, handleClose2, anchorEl2, open2, onDragEnd, getItemStyle, isActionDisabled }}
                        />
                    )}

                    {!Boolean(products?.length) && (
                        <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-p-8 tw-max-w-[360px] tw-mx-auto tw-mt-12'>
                            <span className='-tw-ml-5'><EmptyPlates /></span>
                            <p className='tw-font-bold tw-text-lg '>You do not have any item</p>
                            <p className='tw-text-[#8B8B8B] tw-text-center tw-text-base mb-2'>Spotlight special dishes attracts potential customers.</p>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: 20
                                }}
                                disabled={isActionDisabled}
                                onClick={() => {
                                    setShowAddNewProduct(true)
                                }}
                            >
                                Add Item
                            </Button>
                        </div>
                    )}

                    {(pagination.last_page > 1) && (
                        <div
                            style={{
                                padding: '10px 0'
                            }}
                        >
                            <Pagination
                                page={pagination.current_page}
                                count={pagination.last_page}
                                onChange={(e, page) => fetchMyList(page)}
                            />
                        </div>
                    )}
                </Paper>
            )}

            <Dialog
                open={showAddNewProduct}
                onClose={() => {
                    setShowAddNewProduct(false)
                    fetchMyList()
                    setIsEdit(false)
                    setEditObj({})
                }}
                PaperProps={{
                    style: {
                        width: '100%',
                        maxWidth: '550px'
                    }
                }}
                className=''
                // !tw-max-h-full sm:!tw-max-h-[calc(-64px+100%)]
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <AddNewProductDialog
                    isEdit2Loading={isEdit2Loading}
                    editObj={editObj}
                    isEdit={isEdit}
                    handleDelete={handleDelete}
                    modifiers={modifiers}
                    properties={properties}
                    fetchModifiers={fetchModifiers}
                    fetchProperty={fetchProperty}
                    onCreateModifier={() => {
                        setShowAddModifier(true)
                    }}
                    onCreateProperty={() => {
                        setShowAddProperties(true)
                    }}
                    onClose={() => {
                        setShowAddNewProduct(false)
                        fetchMyList()
                        setIsEdit(false)
                        setEditObj({})
                    }}
                />
            </Dialog>

            <Dialog
                open={showAddModifier}
                onClose={() => {
                    setShowAddModifier(false)
                }}
                PaperProps={{
                    style: {
                        width: '100%'
                    }
                }}
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <AddModifierDialog
                    editObj={editModifierObj}
                    isEdit={false}
                    onSuccess={() => {
                        fetchModifiers()
                    }}
                    // handleDelete={handleDelete}
                    onClose={() => {
                        setShowAddModifier(false)
                    }}
                />
            </Dialog>

            <Dialog
                open={showAddProperties}
                onClose={() => {
                    setShowAddProperties(false)
                }}
                PaperProps={{
                    style: {
                        width: '100%',
                    }
                }}
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <AddPropertiesDialog
                    editObj={{}}
                    isEdit={false}
                    onSuccess={() => {
                        fetchProperty()
                    }}
                    // handleDelete={handleDelete}
                    onClose={() => {
                        setShowAddProperties(false)
                    }}
                />
            </Dialog>
        </div>
    );
}

export default Items