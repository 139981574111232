import React from 'react'
import { Box } from '@mui/material';
import { getImage3 } from '../../../../../utils/helpers';



function MobileItems({ products, handleEdit2, isActionDisabled }) {
    return (
        <Box>
            {products.map((row, index) => (
                <Box
                    key={row.id}
                    style={{
                        pointerEvents: isActionDisabled ? 'none' : 'all'
                    }}
                    onClick={() => handleEdit2(row.id)}
                    className='tw-border-b tw-border-gray tw-py-2 tw-flex tw-items-center tw-gap-2'
                >
                    <div className='tw-w-full'>
                        <div className='tw-flex tw-justify-start tw-text-sm tw-gap-4 tw-text-black'>
                            <img src={getImage3(row.image)} height={20} width={20} />
                            <span className='tw-font-bold tw-max-w-[80%] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.title}</span>
                            {/* <span className='tw-text-right tw-max-w-[20%]'> {row.options?.length}</span> */}
                        </div>

                        {/* <div className='tw-flex tw-justify-between tw-text-xs tw-text-[#7C7C7C]'>
                        <span className='tw-max-w-[80%] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.description}</span>
                    </div> */}
                    </div>
                </Box>
            ))}
        </Box>
    )
}

export default MobileItems;