import React from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import RangeSliderContainer from "./RangeSliderContainer";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";
import LightTooltip from "../../../../util_comps/LightTooltip";

const PriceRange = (props) => {
    return (
        <React.Fragment>
            <Accordion
                square
                TransitionProps={{ unmountOnExit: true }}
                onChange={() => props.handlePanelOpen("rating")}
                expanded={props.activePanel === "rating"}
            >
                <AccordionSummary
                    expandIcon={
                        props.activePanel === "rating" ? (
                            <IndeterminateCheckBoxIcon style={{ fill: "black" }} />
                        ) : (
                            <AddBoxIcon style={{ fill: "black" }} />
                        )
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions2-content"
                    id="additional-actions2-header"
                >
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Only show products that are rated higher than your selected value.</span>} arrow placement="top">
                        <Typography style={{ fontSize: '16px', color: props.initState.current.filters.ratings !== props.filters.ratings && props.activePanel !== "rating" ? 'red' : 'black' }} variant="h6">Rating</Typography>
                    </LightTooltip>
                </AccordionSummary>
                <AccordionDetails style={{ marginBottom: "4%" }}>
                    <RangeSliderContainer color="red">
                        <InputRange
                            minLabel="none"
                            maxLabel="none"
                            maxValue={5}
                            minValue={0}
                            value={props.filters.ratings}
                            onChange={(value) => props.setFilters({ ...props.filters, ratings: value })}
                        />
                    </RangeSliderContainer>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

export default PriceRange;
