import React, { useState } from 'react';
import styles from './FormsActionMenu.module.scss';
import { Button, Dialog, DialogContent, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import axios, { nodeAxios } from '../../../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';
import CreateFormDialog from '../../FormsListHeader/CreateFormDialog/CreateFormDialog';

const DeleteIcon = (props) => {
  return (
    <svg
      {...props}
      width='20'
      height='24'
      viewBox='0 0 32 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.41675 35.25C5.36258 35.25 4.45983 34.8743 3.7085 34.123C2.95716 33.3717 2.58214 32.4696 2.58341 31.4167V6.5H0.666748V2.66667H10.2501V0.75H21.7501V2.66667H31.3334V6.5H29.4167V31.4167C29.4167 32.4708 29.0411 33.3736 28.2897 34.1249C27.5384 34.8763 26.6363 35.2513 25.5834 35.25H6.41675ZM25.5834 6.5H6.41675V31.4167H25.5834V6.5ZM10.2501 27.5833H14.0834V10.3333H10.2501V27.5833ZM17.9167 27.5833H21.7501V10.3333H17.9167V27.5833Z'
        fill='#FF4242'
      />
    </svg>
  );
};
function FormsActionMenu({ data, ...props }) {
  const [showListMenuEl, setShowListMenuEl] = useState(null);
  const [createFormDialogOpen, setCreateFormDialogOpen] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const showListMenu = Boolean(showListMenuEl);
  const confirm = useConfirm();
  const navigate = useNavigate();

  const handleOpenListMenu = (event) => {
    event.stopPropagation();
    setShowListMenuEl(event.currentTarget);
  };
  const handleCloseListMenu = (event) => {
    event.preventDefault();
    setShowListMenuEl(null);
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const handleDeleteForm = () => {
    // if (data?.response_count > 0) {
    //   toast.error('You cannot delete this form as it has responses');
    //   //This form has X resposnes associated with it, Deleting this form will permenatly delete all the responses. Are tou sure?
    //   return;
    // }

    confirm({
      title: (
        <div>
          <div>
            <DeleteIcon style={{ marginRight: '10px' }} />
            Are you sure you want to delete this Form?
          </div>
          <p style={{ fontWeight: '500', fontSize: '14px' }}>
            {(data?.response_count > 0) ? `This form has ${data?.response_count} responses associated with it, Deleting this form will permanently delete all the responses.` : "Once delete form data will permanently be deleted and cannot be recovered."}
          </p>
        </div>
      ),
      confirmationText: 'Delete permanently',
      confirmationButtonProps: {
        style: {
          background: '#FF3232',
          padding: '4px 16px',
        },
      },
      cancellationButtonProps: {
        style: {
          background: '#EEEEEE',
          border: '1px solid #D3D3D3',
          color: 'black',
          boxShadow: 'none',
          padding: '4px 16px',
        },
      },
    })
      .then(() => {
        axios
          .delete(`/web-builder/web-page-form/delete/${data.id}`)
          .then((res) => {
            toast.success('Form deleted successfully');
            props.handleFetchListing();
          })
          .catch((err) => {
            console.log('🚀OUTPUT --> err:', err);
            toast.error(err?.response?.data?.message || 'Something went wrong');
            // toast.error('Something went wrong');
          });
      })
      .catch(() => {});
  };

  const handleOpenCreateFromDialog = (form) => {
    setCreateFormDialogOpen(true);
    setSelectedForm(form);
  };

  const handleDuplicateForm = () => {
    nodeAxios
      .get(`/web-builder/web-page-form/generate-title/${data.id}`)
      .then((res) => {
        // toast.success('Form duplicated successfully');
        // props.handleFetchListing();
        
        setSelectedForm({ ...data, title: res.data?.data?.title })
        setCreateFormDialogOpen(true);
      })
      .catch((err) => {
        // toast.error('Something went wrong');
      });
  }

  return (
    <>
      <Button
        variant='outlined'
        color='inherit'
        id={`list-menu-button-${data.id}`}
        aria-controls={showListMenu ? `list-menu-${data.id}` : undefined}
        aria-haspopup='true'
        aria-expanded={showListMenu ? 'true' : undefined}
        onClick={handleOpenListMenu}
      >
        Action
        <KeyboardArrowDown />
      </Button>

      <Menu
        id={`list-menu-${data.id}`}
        anchorEl={showListMenuEl}
        open={showListMenu}
        onClose={handleCloseListMenu}
        // classes={{ paper: styles.headerAccountMenuPaper }}
        MenuListProps={{
          'aria-labelledby': `list-menu-button-${data.id}`,
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      >
        <MenuItem
          onClick={() => navigate(`/forms/edit/${data.id}`)}
          sx={{ fontSize: '14px' }}
        >
          Edit form fields
        </MenuItem>
        <MenuItem
          onClick={() => handleDuplicateForm(data?.id)}
          // onClick={() => handleOpenCreateFromDialog(data)}
          sx={{ fontSize: '14px' }}
        >
          Duplicate form
        </MenuItem>
        <MenuItem
          onClick={handleDeleteForm}
          sx={{ fontSize: '14px', color: '#FF1515' }}
        >
          Delete form
        </MenuItem>
      </Menu>

      <Dialog
        open={createFormDialogOpen}
        onClose={() => setCreateFormDialogOpen(false)}
        classes={{
          container: styles.dialog_container,
          paper: styles.dialog_paper,
        }}
      >
        <DialogContent classes={{ root: styles.dialog_content }}>
          <CreateFormDialog
            closeDialog={() => setCreateFormDialogOpen(false)}
            setCreateFormDialogOpen={setCreateFormDialogOpen}
            selectedForm={selectedForm}
            setSelectedForm={setSelectedForm}
            {...props}
            // rooms={rooms}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FormsActionMenu;
