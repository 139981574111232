import React, { Fragment, useEffect, useState } from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle,
    withScriptjs
} from "react-google-maps";

const Markers = ({ places }) => {
    return places.map(place => {
        return (
            <Fragment key={place.id}>
                <Marker
                    position={{
                        lat: parseFloat(place.lat),
                        lng: parseFloat(place.lng)
                    }}
                />
                {place.circle && (
                    <Circle
                        defaultCenter={{
                            lat: parseFloat(place.lat),
                            lng: parseFloat(place.lng)
                        }}
                        radius={place.circle.radius}
                        options={place.circle.options}
                    />
                )}
            </Fragment>
        );
    });
};

const Map = ({ places, zoom, center }) => {
    return (
        <GoogleMap defaultZoom={zoom} center={center}>
            <Markers places={places} />
        </GoogleMap>
    );
};

const MapWithMarker = (props) => {

    const [places, setPlaces] = useState(props.places)
    const [center, setCenter] = useState(props.center)

    useEffect(() => {
        setPlaces(props.places)
    }, [props.places])

    useEffect(() => {
        setCenter(props.center)
    }, [props.center])

    return (
        <Map
            center={center}
            zoom={props.zoom}
            places={places}
        />
    );
}

export default withScriptjs(withGoogleMap(MapWithMarker));
