import axios from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import Const from "../../components/helpers/const";
import { getBanners } from "./bannerActions";

export function getSettingList(params) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url = "/banner-group/list";
      // let url=params!==""?Const.BASE_URL + `banner-setting/list?${params}`:Const.BASE_URL + 'banner-setting/list';
      dispatch({ type: "SET_SETTING_LIST_LOADING" });
      return axios
        .get(url)
        .then((response) => {
          dispatch({
            type: "SET_SETTING_LIST_LOADED",
            payload: response.data,
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: "SET_SETTING_LIST_ERROR",
            payload: error.response,
          });
          return Promise.resolve();
        });
    }
  };
}
export function updateSettingDetails(params, group_id) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url = `/banner-group/edit/${group_id}`;
      
      dispatch({ type: "SET_SETTING_DETAILS_LOADING" });
      return axios
        .put(url, params)
        .then((response) => {
          dispatch({
            type: "SET_SETTING_DETAILS_LOADED",
            payload: response.data,
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: "SET_SETTING_DETAILS_ERROR",
            payload: error.response,
          });
          return Promise.reject(error);
        });
    }
  };
}
export function addSettingDetail(params) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url = `/banner-group/create`;
      
      dispatch({ type: "ADD_SETTING_LIST_LOADING" });
      return axios
        .post(url, params)
        .then((response) => {
          dispatch({
            type: "ADD_SETTING_LIST_LOADED",
            payload: response.data,
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject(error);
        });
    }
  };
}
export function deleteSettingDetail(group_id) {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url = `/banner-group/delete/${group_id}`;
      
      dispatch({ type: "DELETE_SETTING_LIST_LOADING" });
      return axios
        .delete(url)
        .then((response) => {
          dispatch({
            type: "DELETE_SETTING_LIST_LOADED",
            payload: response.data,
          });

          dispatch(getBanners({ page: 1, query: '' }))
          toast.success('Banner Deleted')
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          toast.error('Something went wrong')
          dispatch({
            type: "DELETE_SETTING_LIST_ERROR",
            payload: error.response,
          });
          return Promise.resolve();
        });
    }
  };
}

export function getBannerCategory() {
  return (dispatch) => {
    if (localStorage.userToken) {
      let url = `/banner-category/list`;
      dispatch({ type: "SET_BANNER_CATEGORY_LOADING" });
      return axios
        .get(url)
        .then((response) => {
          dispatch({
            type: "SET_BANNER_CATEGORY_LOADED",
            payload: response.data,
          });

          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: "SET_BANNER_CATEGORY_ERROR",
            payload: error.response,
          });
          return Promise.resolve();
        });
    }
  };
}
