//New Orders
import axios from '../../utils/axiosConfig';

export const getOrders = (params) => {
    return async (dispatch) => {
        dispatch({
            type: 'SET_ORDERS_REQUEST'
        })
        let { data: { data } } = await axios.get('/order/list-new', {
            params: {
                start_date: params.startDate,
                end_date: params.endDate,
                id: params.id,
                page: params.page || 1,
                state: params.state,
                sort_by: params.sortBy,
                sort_by_order: params.sortByOrder
            }
        });
        let setter = {
            orders: data.result,
            pagination: data.pagination
        };
        if (data.count) {
            setter['totals'] = data.count;
        }
        dispatch({
            type: 'SET_ORDERS',
            payload: setter
        });
    };
};

export const changeOrderStatus = (params) => {
    return async (dispatch) => {
        dispatch({
            type: 'CHANGE_ORDER_STATUS',
            payload: params
        });
    };
};