import { East, Timelapse, West } from '@mui/icons-material'
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, selectClasses, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import GenericAutoCompleteWithLabel from '../../Library/GenericAutoCompleteWithLabel/GenericAutoCompleteWithLabel'
import GenericTextField from '../../Library/GenericTextField/GenericTextField'

const RestaurantType = ({ formik, onNext, fetchRandomTemplate, onPrev }) => {

    const sm = useMediaQuery('(max-width:640px)');
    const [venueSubType, setVenueSubType] = useState([])

    const fetchVenueSubType = () => {
        nodeAxios.get('/web-builder/helper/venue-sub-type')
            .then((res) => {
                setVenueSubType(res.data?.data)
            })

    }

    useEffect(() => {
        fetchVenueSubType()
    }, [])


    return (
        <div
            className="tw-flex tw-justify-center tw-h-full"
        >
            <div
                className="tw-flex tw-items-center tw-justify-center tw-h-full tw-flex-col tw-max-w-lg tw-gap-3 tw-p-4"
            >
                <div
                    className="tw-flex tw-w-full"
                >
                    <IconButton
                        onClick={onPrev}
                    >
                        <West
                            style={{
                                color: 'black',
                                fontSize: 20
                            }}
                        />
                    </IconButton>
                </div>
                <div
                    className="tw-font-semibold tw-text-[28px] sm:tw-text-4xl"
                >
                    Your site is ready, help us to personalize your site
                </div>

                <div
                    className="tw-my-5"
                >
                    We are asking so that personalize your website and content, don't worry you can always change it later
                </div>

                <GenericTextFieldWithLabel
                    containerClassname="tw-w-full"
                    isSmallLabel={true}
                    variant="standard"
                    label="Restaurant Name"
                    placeholder="Your restaurant name"
                    name="Name"
                    value={formik.values.Name}
                    onChange={formik.handleChange}
                    error={formik.touched.Name && Boolean(formik.errors.Name)}
                    helperText={formik.touched.Name && formik.errors.Name}
                    fullWidth={true}
                    required={true}
                />

                <GenericAutoCompleteWithLabel
                    isSmallLabel={true}
                    label="What type of restaurant are you?"
                    containerStyle={{
                        width: '100%'
                    }}
                    options={venueSubType || []}
                    clearOnBlur={false}
                    // onBlur={() => setOpenBrandOption(false)}
                    // open={openBrandOption}
                    // filterOptions={(options) => options}
                    getOptionLabel={(option) => option.title}
                    freeSolo={true}
                    onChange={(event, val) => {
                        // setOpenBrandOption(false)
                        formik.setFieldValue('venuesubtype', val.title)
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'input') {
                            // if (!(newInputValue?.length < 3)) {
                            //     setOpenBrandOption(true)
                            // } else {
                            //     setOpenBrandOption(false)
                            // }
                            formik.setFieldValue('venuesubtype', newInputValue)
                            // formik.setFieldValue('venue_id', '')
                        }
                    }}
                    inputValue={formik.values.venuesubtype || ''}
                    getOptionSelected={() => true}
                    renderInput={(params) => (
                        <GenericTextField
                            {...params}
                            variant="standard"
                            style={{
                                backgroundColor: 'white'
                            }}
                            placeholder="Search & select your restaurant type"
                            error={formik.touched.venuesubtype && Boolean(formik.errors.venuesubtype)}
                            helperText={formik.touched.venuesubtype && formik.errors.venuesubtype}
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    // root: styles.autocomplete_input
                                },
                                endAdornment: null
                            }}
                            fullWidth={true}
                        />
                    )}
                />

                {/* <GenericSelectWithLabel
                    variant="standard"
                    id="venuesubtype"
                    name="venuesubtype"
                    label="Search & select your restaurant type"
                    containerStyle={{
                        width: '100%'
                    }}
                    // containerClassname={styles.small_input_container}
                    value={formik.values.venuesubtype}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    style={{
                        marginTop: 0
                    }}
                >
                    {venueSubType?.map((data) => (
                        <MenuItem value={data.id}>{data.title}</MenuItem>
                    ))}
                </GenericSelectWithLabel> */}

                {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth={true}>
                    <InputLabel id="demo-simple-select-standard-label">Search & select your restaurant type</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="venuesubtype"
                        value={formik.values.venuesubtype}
                        onChange={formik.handleChange}
                        variant="standard"
                        fullWidth={true}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl> */}

                <div
                    className="tw-w-full tw-flex tw-justify-between tw-flex-col sm:tw-flex-row tw-gap-2"
                >
                    <div>
                        {/* {showSkip && (
                            <Button
                                style={{
                                    color: 'black',
                                    borderRadius: 30,
                                    backgroundColor: sm ? '#EEEEEE' : 'white'
                                }}
                                variant="text"
                                startIcon={<Timelapse fontSize="small" />}
                                endIcon={<East fontSize="small" />}
                                onClick={onNext}
                                fullWidth={sm}
                            >
                                Skip
                            </Button>
                        )} */}
                    </div>

                    <Button
                        style={{
                            color: 'white',
                            backgroundColor: !formik.values.Name ? '#CACACA' : 'black',
                            borderRadius: 30,
                            width: sm ? '100%' : 120,
                            height: 40
                        }}
                        variant="contained"
                        onClick={() => {
                            fetchRandomTemplate(onNext)
                        }}
                        fullWidth={sm}
                        disabled={!formik.values.Name}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default RestaurantType