import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGiftCardHistory } from "../../redux/actions/giftCardActions";
import * as _ from "lodash";
// import { Pagination } from "antd";
import Carousel, { Modal, ModalGateway } from "react-images";
import { isImageExists } from "../helpers/isImageExists";
import { Breadcrumbs, Dialog, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import StyledTableCell from "../Library/StyledTableCell/StyledTableCell";
import Arrows from '../../assets/arrows.png'
import StyledTableRow from "../Library/StyledTableRow/StyledTableRow";
import styles from './GiftCardHistory.module.scss'
import InputField from "../Library/InputField/InputField";
import { Search } from "@mui/icons-material";
import { useDeferredValue } from "react";
import { debounce } from "lodash";
import GiftCardDetailsDialog from "../Dialogs/GiftCardDetailsDialog/GiftCardDetailsDialog";
import GenericTextFieldWithLabel from "../Library/TextFieldWithLabel/GenericTextFieldWithLabel";
import { format } from "date-fns";
import InventoryIcon from '@mui/icons-material/Inventory';
import Loader from "../Library/Loader";

const headings = [
  {
    label: 'Image',
    value: null
  },
  {
    label: 'Order Id',
    value: 'order_id'
  },
  {
    label: 'User Name',
    value: 'username'
  },
  {
    label: 'From',
    value: 'from_name'
  },
  {
    label: 'To',
    value: 'to_email'
  },
  {
    label: 'Message',
    value: 'message'
  },
  {
    label: 'Amount',
    value: 'amount'
  },
  {
    label: 'Date',
    value: 'created_at'
  }
]

const GiftCardHistory = () => {

  const dispatch = useDispatch();
  const [orderState, setorderState] = useState(false);
  const [query, setQuery] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [type, setType] = useState('')

  const [currentImage, setCurrentImage] = useState(0);
  const [image, setImage] = useState([]);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [since, setSince] = useState(format((new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
  const [until, setUntil] = useState(format((new Date()), "yyyy-MM-dd"))

  const [editId, setEditId] = useState('')

  const PaymentsList = useSelector((store) => store?.giftCard.giftCardHistory);
  const PaymentsListLoading = useSelector((store) => store?.giftCard.isLoading);

  const openLightbox = (index, image_file) => {
    if (isImageExists(image_file)) {
      if (image_file) {
        const image = [
          {
            source: image_file,
          },
        ];
        setCurrentImage(index);
        setViewerIsOpen(true);
        setImage(image);
      }
    }
    else {
      const image = [
        {
          source: "images/logo-text - Copy.png",
        },
      ];
      setCurrentImage(index);
      setViewerIsOpen(true);
      setImage(image);
    }

  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    dispatch(getGiftCardHistory(`${since ? `&since=${since}` : ''}${until ? `&until=${until}` : ''}&page=1`));
  }, []);

  const handleSearch = (text, sortby, since, until) => {
    let params = `q=${text}${sortby ? `&sort_by=${sortby}&sort_by_order=${orderState ? 'asc' : 'desc'}` : ''}${since ? `&since=${since}` : ''}${until ? `&until=${until}` : ''}`;
    dispatch(getGiftCardHistory(params));
  };

  useEffect(() => {
    handleSearch(query, '', since, until)
  }, [since, until])


  const handleChange = (event, page) => {
    let params = `q=${query}${since ? `&since=${since}` : ''}${until ? `&until=${until}` : ''}&page=${page}`;
    dispatch(getGiftCardHistory(params));
  };

  const debounceSearch = useCallback(debounce(handleSearch, 1000), [])

  return (
    <div>
      <Breadcrumbs
        style={{ fontSize: 13, marginBottom: "20px" }}
        aria-label="breadcrumb"
      >
        <Link
          style={{
            color: 'black'
          }}
          to="/dashboard"
        >
          Home
        </Link>
        <Typography
          style={{
            borderBottom: "1px solid grey",
            color: 'black'
          }}
        >
          Gift Card Purchases
        </Typography>
      </Breadcrumbs>

      <div
        style={{
          display: 'flex',
          gap: 15,
          marginLeft: 20
        }}
      >
        <div
          style={{
            width: 220,
            margin: '20px 0'
          }}
        >
          <InputField
            placeholder="Search"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value)
              debounceSearch(e.target.value, since, until)
            }}
            onIconButtonClick={e => debounceSearch(query, since, until)}
            showIconButton={true}
            endIcon={(
              <Search
                style={{
                  color: '#ef2d2c'
                }}
              />
            )}
          />
          <div
            style={{
              fontSize: 12
            }}
          >
            Search by order id, username, from name, to email, gift card number.
          </div>
        </div>

        <div
          className={styles.item}
        >
          <GenericTextFieldWithLabel
            className={styles.textfield_item}
            label="From Date"
            id="date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={since}
            onChange={(event) => setSince(event.target.value)}
          />
        </div>

        <div
          className={styles.item}
        >
          <GenericTextFieldWithLabel
            className={styles.textfield_item}
            label="To Date"
            id="date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={until}
            onChange={(event) => setUntil(event.target.value)}
          />
        </div>
      </div>

      <TableContainer
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headings.map((data) => (
                <StyledTableCell
                  style={{
                    color: 'black'
                  }}
                  onClick={() => { }}
                >
                  <div
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      whiteSpace: 'nowrap'
                    }}
                    onClick={() => {
                      handleSearch(query, data.value, since, until)
                      setorderState(!orderState);
                    }}
                  >
                    {data.label}
                    {data.value && (
                      <div
                        style={{ width: '10px', marginLeft: '6px', cursor: 'pointer' }}
                      >
                        <img
                          src={Arrows}
                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                      </div>
                    )}
                  </div>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {PaymentsList?.data?.result?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  <div>
                    <img
                      height={60}
                      width={60}
                      src={row.photo}
                      onClick={() =>
                        openLightbox(0, row.photo)
                      }
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => {
                    setShowDetails(true)
                    setEditId(row.order_id)
                    setType(row.type)
                  }}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                >
                  {row.order_id}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.userName}
                </StyledTableCell>
                <StyledTableCell>
                  {row.from_name}
                </StyledTableCell>
                <StyledTableCell>
                  {row.to_email || 'Myself'}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    maxWidth: 240
                  }}
                >
                  {row.message}
                </StyledTableCell>
                <StyledTableCell>
                  {row?.amount ? row.amount : "0:00"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.created_at}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {PaymentsListLoading ? (
          <Loader />
        ) : (
          <>
            {!Boolean(PaymentsList?.data?.result?.length) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  fontSize: 25,
                  padding: 20
                }}
              >
                <div>
                  <InventoryIcon
                    fontSize="large"
                  />
                </div>
                <div>
                  No gift card found
                </div>
              </div>
            )}
          </>
        )}

      </TableContainer>

      {(PaymentsList?.data?.pagination?.last_page > 1) && (
        <div
          className={styles.pagination_container}
        >
          <Pagination
            // className={styles.pagination}
            count={PaymentsList?.data?.pagination?.last_page}
            onChange={handleChange}
          />
        </div>
      )}

      <ModalGateway>
        {viewerIsOpen && image.length > 0 && (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={image.map((x) => ({
                ...x,
                srcset: x.source,
                caption: "esplanda",
              }))}
            />
          </Modal>
        )}
      </ModalGateway>

      <Dialog
        open={showDetails}
        onClose={() => {
          setShowDetails(false)
        }}
      >
        <GiftCardDetailsDialog
          id={editId}
          type={type}
          onClose={() => {
            setShowDetails(false)
          }}
        />
      </Dialog>
    </div>
  );
};
export default GiftCardHistory;
