import React, { useCallback, useContext, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/styles';
import { Button, Tooltip, Dialog, Pagination, Radio, tooltipClasses, useMediaQuery } from '@mui/material';
import { Add, KeyboardArrowRight } from '@mui/icons-material';
import AddNewCoupnDialog from './AddNewCoupnDialog/AddNewCoupnDialog';
import axios from '../../../../utils/axiosConfig'
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import MarketingContext from '../../../Marketing2/Util/marketingContext';
import { debounce } from 'lodash';
import Loader from '../../../Library/Loader';
import { useNavigate } from 'react-router-dom';
import { TreeItem } from '@mui/x-tree-view';
import AddPropertiesDialog from '../../../Properties/AddPropertiesDialog/AddPropertiesDialog';
import { reorder } from '../../../CustomPageBuilder/EditSection/Util/helpers';
import ItemsHeader from './ItemsHeader/ItemsHeader';
import { ReactComponent as EmptyPlates } from '../../../../assets/svgs/empty_coupon.svg';
import ItemsTable from './ItemsTable/ItemsTable';
import MobileItems from './MobileItems/MobileItems';


const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle
});

const Items = ({ setActiveModal }) => {

    const { subDomain } = useContext(MarketingContext)
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:1000px)')

    const confirm = useConfirm()
    const [showAddNewProduct, setShowAddNewProduct] = useState(false)
    const [showAddModifier, setShowAddModifier] = useState(false)
    const [showAddProperties, setShowAddProperties] = useState(false)
    const [showCategoryTooltip, setShowCategoryTooltip] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState({})
    const [editObj, setEditObj] = useState({})
    const [editModifierObj, setEditModifierObj] = useState({})
    const [options, setOptions] = useState({})
    const [searchStr, setSearchStr] = useState("");
    const [sort, setSort] = useState({ sort_by: 'sequence_id', sort_by_order: 'ASC' })
    console.log('🚀OUTPUT --> sort:', sort);
    const [catId, setCatId] = useState(0)
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isEdit2Loading, setIsEdit2Loading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const [selectedCatName, setSelectedCatName] = useState('')
    const [modifiers, setModifiers] = useState([])
    const [properties, setProperties] = useState([])
    const [modalForm, setModalForm] = useState({ couponFor: [], couponCode: '', validFrom: '', validTo: '', discountType: 'dollar', discountAmount: '', orderLimit: '', validAbove: '', firstPurchase: false })
    const [modalEditForm, setModalEditForm] = useState({ id: '', couponFor: [], couponCode: '', validFrom: '', validTo: '', discountType: '', discountAmount: '', orderLimit: '', validAbove: '', firstPurchase: false })
    const handleClick = (event) => {
        // setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        //  setAnchorEl(null);
    };

    const handleClick2 = (event) => {
        //  setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        //   setAnchorEl2(null);
    };

    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(+event.target.value);
        // setPage(0);
    };

    const fetchMyList = (page) => {
        setIsListLoading(true)
        axios.get('/coupon/list', {
            params: {
                page: page || 1,
            }
        })
            .then((res) => {
                console.log('coupon/list', res.data?.data?.result)
                setProducts(res.data?.data?.result)
                setPagination(res.data?.data?.pagination)
            })
            .finally(() => {
                setIsSearchLoading(false)
                setIsListLoading(false)
            })
    }


    const fetchModifiers = () => {
        axios.get('/modifier')
            .then((res) => {
                console.log('🚀OUTPUT --> props.isEdit /modifier:', isEdit);
                const data = res.data?.data?.results?.map((data) => ({
                    ...data,
                    modifier_id: data?.id,
                    [isEdit ? 'modifier_options' : 'options']: data.options?.map((data2) => ({ ...data2, is_visible: 1 }))
                }))
                setModifiers(data)
            })
    }

    const fetchProperty = () => {
        axios.get('/product-property')
            .then((res) => {
                setProperties(res.data?.data?.results)
            })
    }



    const handleSearch = (searchStr) => {

    };
    const debounceSearch = useCallback(debounce(handleSearch, 500), [])


    useEffect(() => {
        fetchMyList()
    }, [])


    const handleDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete coupon',
        })
            .then(() => {
                // axios.delete(`/venue-product/delete/${id}`)
                //     .then(() => {
                //         fetchMyList()
                //         toast.success('Product deleted')
                //     })
                //     .catch((err) => {
                //         toast.error(err?.response?.data?.message)
                //     })
            })
    }

    const handleEdit2 = (id) => {
        setIsEdit2Loading(true)
        setShowAddNewProduct(true)
        const selectedCoupon = products.filter((coupon) => coupon.id === id)
        const selectedCouponObj = selectedCoupon[0]
        console.log('testst', selectedCouponObj);
        setModalEditForm({
            id: id,
            couponFor: selectedCouponObj.valid_for.split(',').map((val) => ({ title: val[0].toUpperCase() + val.slice(1) })),
            couponCode: selectedCouponObj.code,
            validFrom: selectedCouponObj.valid_from.split(" ")[0],
            validTo: selectedCouponObj.valid_to.split(" ")[0],
            discountType: selectedCouponObj.type,
            discountAmount: selectedCouponObj.value,
            orderLimit: selectedCouponObj.limit,
            validAbove: selectedCouponObj.min_purchase,
            firstPurchase: selectedCouponObj.first_purchase === 1 ? true : false
        })
        setIsEdit(true)
    }

    const handleCopyUrl = (data) => {
        navigator.clipboard
            .writeText(
                `${subDomain}/product/${data?.ProductSlug}/${data?.ProductId}`
            )
            .then(() => {
                toast.success('Url is copied successfully')
            });
    };

    const handleChangeVisibility = (index) => {

        setProducts((prevState) => {
            let newCoupons = [...prevState]
            newCoupons[index].state_id = event.target?.checked
            return newCoupons
        })
        axios.post(`/coupon/change-status/${products[index].id}`)
            .then(() => {
                toast.success('Coupon Status Changed')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    };



    const handleEdit = (name, value, data) => {
        if (name === "limited") {
            if (value < 0) {
                return;
            }

            axios.post(`/product/update-my-product/${data.id}`, {
                id: data.id,
                limited: value
            })
                .then(() => {
                    toast.success('Updated')
                })
            setOptions((prevState) => ({
                ...prevState,
                [data.id]: {
                    ...prevState[data.id],
                    limited: value
                }
            }))
        }
    };

    const fetchVenueCatMenu = () => {
        axios.get('/venue-category-order', {
            params: {
                only_if_product_exists: 1
            }
        })
            .then((res) => {
                setVenueCategoryMenu(res.data?.data?.results)
            })
    }

    useEffect(() => {
        fetchVenueCatMenu()
    }, [])


    const getTreeItem = (child) => {
        return child?.map((data) => (
            <TreeItem icon={(
                <Radio
                    checked={data.category_id == catId}
                    onChange={(event) => {
                        setSelectedCatName(data.category_name)
                        setCatId(event.target.value)
                    }}
                    value={data.category_id}
                />
            )}
                nodeId={data.category_id}
                label={<div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8
                    }}
                >
                    <div>
                        {data.category_name}
                    </div>

                    {Boolean(data?.child?.length) && (
                        <KeyboardArrowRight />
                    )}
                </div>}
            >
                {Boolean(data?.child?.length) && getTreeItem(data?.child)}
            </TreeItem>
        ))
    }

    const handleSetsequence = (items) => {
        let payload = items.map(element => element.id)
        // items?.forEach((element, index) => {
        //     payload[index] = element?.id
        // });

        axios.post('/venue-product/set-sequence', {
            venue_product_ids: payload
        })
            .then(() => {

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            products,
            result.source.index,
            result.destination.index
        );

        setProducts(newitems);
        handleSetsequence(newitems)
    };

    const handleEditCoupon = (id) => {

    }


    return (
        <div className="tw-py-[10px] lg:tw-py-[25px] tw-flex tw-flex-col tw-gap-5">
            <ItemsHeader {...{ sort, setSort, searchStr, setSearchStr, debounceSearch, isSearchLoading, setShowAddNewProduct, isMobile }} />

            {isListLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>

                    {isMobile ? (
                        <MobileItems
                            {...{ products, options, sort, setSort, handleEdit2, handleDelete, handleChangeVisibility, getTreeItem, venueCategoryMenu, catId, setCatId, selectedCatName, setSelectedCatName, handleClick2, handleClose2, anchorEl2, open2, onDragEnd, getItemStyle }}
                        />
                    ) : (
                        <ItemsTable
                            {...{ products, options, sort, setSort, handleEdit2, handleDelete, handleChangeVisibility, getTreeItem, venueCategoryMenu, catId, setCatId, selectedCatName, setSelectedCatName, handleClick2, handleClose2, anchorEl2, open2, onDragEnd, getItemStyle }}
                        />
                    )}
                    {!Boolean(products?.length) && (

                        <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-p-8 tw-max-w-[360px] tw-mx-auto tw-mt-12'>
                            <span className='-tw-ml-5'><EmptyPlates /></span>
                            <p className='tw-font-bold tw-text-lg tw-text-center'>You have not created any offer yet</p>
                            <p className='tw-text-[#8B8B8B] tw-text-center tw-text-base mb-2'>Automatically send offers to your customers</p>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: 20
                                }}
                                onClick={() => {
                                    setShowAddNewProduct(true)
                                }}
                            >
                                New Coupon
                            </Button>
                        </div>
                    )}


                    {(pagination.last_page > 1) && (
                        <div
                            style={{
                                padding: '10px 0'
                            }}
                        >
                            <Pagination
                                page={pagination.current_page}
                                count={pagination.last_page}
                                onChange={(e, page) => fetchMyList(page)}
                            />
                        </div>
                    )}
                </Paper>
            )}

            <Dialog
                open={showAddNewProduct}
                onClose={() => {
                    setShowAddNewProduct(false)
                    fetchMyList()
                    setIsEdit(false)
                    setEditObj({})
                }}
                PaperProps={{
                    style: {
                        width: '100%',
                        maxWidth: '650px'
                    }
                }}
                className=''
                // !tw-max-h-full sm:!tw-max-h-[calc(-64px+100%)]
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <AddNewCoupnDialog
                    modalForm={isEdit ? modalEditForm : modalForm}
                    setModalForm={setModalForm}
                    handleDelete={handleDelete}
                    isEdit={isEdit}
                    onClose={() => {
                        setShowAddNewProduct(false)
                        setIsEdit(false)
                        fetchMyList()
                    }}
                />
            </Dialog>


        </div>
    );
}

export default Items