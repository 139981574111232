const initialState = {
  pagination: {},
  productGroups: [],
  deleteErrorMessage: '',
};

function productGroups(state = initialState, action) {
  switch (action.type) {
    case 'SET_PRODUCT_GROUPS':
      return {
        ...state,
        ...action.payload,
      };
    case 'DELETE_PRODUCT_GROUP':
      return {
        ...state,
        productGroups: [
          ...state.productGroups.filter(
            (item) => item.data.productGroupId !== action.payload
          ),
        ],
      };
    case 'DELETE_PRODUCT_GROUP_ERROR':
      return {
        ...state,
        deleteErrorMessage: action.payload,
      };
    default:
      return state;
  }
}

export default productGroups;
