import React from 'react';
import { OpenWith, DeleteOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

const Banner = (props) => {

    const dispatch = useDispatch(data => data);
    const { groupList } = useSelector((store) => store);
    const handleDelete = (data) => {
        let newSelectedItems = groupList.items.filter((val, i) => (props.index !== i));
        dispatch({
            type: "REORDER_GROUP_LIST",
            payload: newSelectedItems
        });
    };
    return (
        <div
            ref={props.provided.innerRef}
            {...props.provided.draggableProps}
            {...props.provided.dragHandleProps}
            style={{
                userSelect: "none",
                padding: 16,
                margin: "0 0 8px 0",
                minHeight: "50px",
                ...props.provided.draggableProps.style
            }}
        >
            <div style={{ width: '95%', margin: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ border: '1px solid #DFDADA' }}>
                    <OpenWith style={{ color: "black", margin: '2px 4px', fontSize: '17px' }} />
                    <DeleteOutlined onClick={e => handleDelete()} style={{ color: 'black', margin: '2px 4px', cursor: 'pointer', fontSize: '17px' }} />
                </div>
            </div>
            <div style={{ border: !props.isLaptopView ? "none" : '1px solid #B3DDF8', width: props.view === "mobile_view" ? "100%" : '95%', margin: 'auto' }}>
                <div style={{ width: '95%', height: !props.isLaptopView ? 67 : 76, margin: '10px auto' }}>
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={props.data.data.image} />
                </div>
            </div>
        </div>
    );
}
export default Banner;