import axios from "../../utils/axiosConfig";
import Const from "../../components/helpers/const";
import {
    POST_UPDATE_PRODUCT_CUSTOMIZATION_REQUEST,
    POST_UPDATE_PRODUCT_CUSTOMIZATION_SUCCESS,
    POST_UPDATE_PRODUCT_CUSTOMIZATION_FAILURE
} from '../types/productMasterListTypes';

export function getMasterProductList(params) {
    return (dispatch) => {
        if (localStorage.userToken) {
            let url = params !== "" ? `/product/master-list?${params}` : '/product/master-list/';
            dispatch({ type: "SET_PRODUCT_LIST_LOADING" });
            return axios
                .get(url)
                .then((response) => {
                    dispatch({
                        type: "SET_PRODUCT_LIST_LOADED",
                        payload: response.data
                    });
                    return Promise.resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({
                        type: "SET_PRODUCT_LIST_ERROR",
                        payload: error.response,
                    });
                    return Promise.resolve();
                });
        }
    }
}

export const postUpdateProductListItem = (id, data, cb) => {
    return (dispatch) => {
        dispatch({
            type: POST_UPDATE_PRODUCT_CUSTOMIZATION_REQUEST
        })
        axios.post(`/product/update-product-customization/${id}`, data)
            .then((response) => {
                dispatch({
                    type: POST_UPDATE_PRODUCT_CUSTOMIZATION_SUCCESS
                })
                if (cb) {
                    cb()
                }
            })
            .catch((err) => {
                dispatch({
                    type: POST_UPDATE_PRODUCT_CUSTOMIZATION_FAILURE
                })
            })
    }
}