import React from 'react'
import { Navigate } from 'react-router-dom'
import routePaths from '../../config/route-paths'
import LoginRoute from './LoginRoute'
const NotProtectedRoute = () => {

    if (localStorage.userToken) {
        return (
            <Navigate
                to={routePaths.venueOrderListing}
                replace
            />
        )
    }
    return <LoginRoute />
}

export default NotProtectedRoute