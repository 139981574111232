import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Outlet, useBlocker, useParams, useSearchParams } from 'react-router-dom'
import TopPanel from './TopPanel/TopPanel'
import axios, { nodeAxios } from '../../utils/axiosConfig'
import LeftPanel from './LeftPanel/LeftPanel'
import RightPanel from './RightPanel/RightPanel'
import Layers from './Layers/Layers'
import { WebsiteEditorProvider } from '../../utils/context/websiteEditorContext'
import { toast } from 'react-toastify'
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { html } from '@codemirror/lang-html';
import CustomPageBuilder from './CustomPageBuilder/CustomPageBuilder'
import PageLoader from '../Library/PageLoader/PageLoader'
import LeftDrawer from './LeftDrawer/LeftDrawer'
import Dialogs from './Dialogs/Dialogs'

const WebsiteBuilderLayout = () => {

    const { name, id, "*": uri } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();


    const isBlockerRef = useRef()
    const deviceRef = useRef();
    const device = searchParams.get('device')
    deviceRef.current = device
    const saveDeviceSelectionRef = useRef();
    const codeMirrorRef = useRef()
    const codeMirrorRefEditor = useRef()


    const [isTemplateSelected, setIsTemplateSelected] = useState(false)
    const [editor, setEditor] = useState()
    const [isSaved, setIsSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isRawHtmlEditor, setIsRawHtmlEditor] = useState(false)
    const [code, setCode] = useState('')
    const [saveDeviceSelection, setSaveDeviceSelection] = useState([])
    const [showLeftDrawer, setShowLeftDrawer] = useState(false)
    const [header, setHeader] = useState({});
    const [footer, setFooter] = useState({});
    const [bottomTabbar, setBottomTabbar] = useState({});
    const [isPagesLoading, setIsPagesLoading] = useState(true);
    const [pages, setPages] = useState([])
    const [forceUpdate, setForceUpdate] = useState(false)

    const [blockCategories, setBlockCategories] = useState([])
    const [selectedModel, setSelectedModel] = useState()

    const fetchWebPage = () => {
        axios.get('/web-builder/web-page')
            .then((response2) => {
                if (response2.data?.data?.results?.length) {
                    setIsTemplateSelected(true)
                } else {
                    setIsTemplateSelected(false)
                }


                setHeader(
                    response2.data.data.results.find((data) => data.type_id === 1)
                );
                setBottomTabbar(
                    response2.data.data.results.find((data) => data.type_id === 4)
                );
                setFooter(
                    response2.data.data.results.find((data) => data.type_id === 2)
                );

                let pageArr = response2.data.data.results
                    .filter((data) => data.type_id === 3)
                    .map((data) => ({ ...data, id: data.id?.toString() }));
                setPages(pageArr);
            })
            .catch(() => {

            })
    }

    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            console.log('ssssssss', isSaved, currentLocation, nextLocation)
            if (!isSaved && (currentLocation.pathname != nextLocation.pathname)) {
                if (!window.confirm('You may have unsaved changes that will be lost. Are you sure you want to leave?')) {
                    isBlockerRef.current = false
                    return true
                } else {
                    isBlockerRef.current = false
                    return false
                }
            }
            // currentLocation.pathname !== nextLocation.pathname
        }
    );

    const handleUnload2 = (event) => {
        if (isBlockerRef.current) {
            const e = event || window.event;
            // Cancel the event
            e.preventDefault();
            if (e) {
                e.returnValue = ''; // Legacy method for cross browser support
            }
            return ''; // Legacy method for cross browser support
        }
    }

    const getContentDocument = (editor) => {
        const iframe = editor.Canvas?.getFrameEl();
        if (iframe.contentDocument) {
            return iframe.contentDocument;
        } else if (iframe.contentWindow && iframe.contentWindow.document) {
            return iframe.contentWindow.document;
        } else if (iframe.document) {
            return iframe.document;
        } else {
            return null;
        }
    }


    const handleComponentHover = (model, editor) => {

    }

    const handleComponentSelect = (model, editor) => {

    }

    const handleEditorEvents = () => {
        editor.on('storage:start:store', (data) => {

            const newObject = {};

            Object.keys(data).map((key) => {
                return Object.assign(newObject, { [`${name}-${key}`]: data[key] })[key];
            });

            var tempEl = document.createElement('div');
            var tempE2 = document.createElement('div');

            let typeId = 3;

            if (name == 'Header') {
                typeId = 1;
                var htmlContent = editor.getHtml();
                tempE2 = document.createElement('header');
                tempE2.id = 'HEADER';
                tempEl.innerHTML = htmlContent;

                tempE2.innerHTML = tempEl.innerHTML;
            } else if (name == 'Footer') {
                typeId = 2;

                var htmlContent = editor.getHtml();
                tempE2 = document.createElement('footer');
                tempE2.id = 'FOOTER';
                tempEl.innerHTML = htmlContent;

                tempE2.innerHTML = tempEl.innerHTML;
            } else if (name == 'MobileNavigation') {
                typeId = 4;

                var htmlContent = editor.getHtml();
                tempE2 = document.createElement('div');
                tempE2.id = 'MOBILENAVIGATION';
                tempEl.innerHTML = htmlContent;

                tempE2.innerHTML = tempEl.innerHTML;
            } else {
                const htmlContent3 = editor.getHtml();

                const htmlString = htmlContent3;

                // create a new DOMParser object
                const parser = new DOMParser();

                // parse the HTML string and create a new document object
                const doc = parser.parseFromString(htmlString, 'text/html');

                // get the document element
                const docElement = doc.documentElement;

                tempE2 = docElement.querySelector('body');
            }

            setIsLoading(true);

            nodeAxios
                .post(`/web-builder/web-page/edit/${id}`, {
                    web_page_id: id,
                    title: name,
                    type_id: typeId,
                    uri: uri,
                    device_type: (deviceRef.current == 'mobile') ? 2 : ((deviceRef.current == 'tablet') ? 1 : 0),
                    destination: saveDeviceSelectionRef.current,
                    payload: {
                        ...newObject,
                        [`${name}-css`]: editor.getCss(),
                        [`${name}-html`]: tempE2.outerHTML,
                    },
                })
                .then(() => {
                    setIsSaved(true)
                    toast.success(
                        'Saved. It may take upto 5 min to reflect your changes on your website'
                    );

                    if (localStorage.getItem('synceditsite') && (localStorage.getItem('synceditsite') != device)) {
                        if (!localStorage.getItem('syncdevices')) {
                            setSearchParams({ device: localStorage.getItem('synceditsite') })
                            localStorage.removeItem('synceditsite')
                            window.location.reload()
                        } else {
                            setSearchParams({ device: localStorage.getItem('syncdevices') })
                            localStorage.removeItem('syncdevices')
                            window.location.reload()
                        }
                    }

                    if (saveDeviceSelectionRef.current?.length) {
                        localStorage.setItem('synceditsite', deviceRef.current)
                        if (saveDeviceSelectionRef.current[0]) {
                            setSearchParams({ device: (saveDeviceSelectionRef.current[0] == 2) ? 'mobile' : ((saveDeviceSelectionRef.current[0] == 1) ? 'tablet' : 'desktop') })
                        }
                        if (saveDeviceSelectionRef.current[1]) {
                            localStorage.setItem('syncdevices', (saveDeviceSelectionRef.current[1] == 2) ? 'mobile' : ((saveDeviceSelectionRef.current[1] == 1) ? 'tablet' : 'desktop'))
                        }
                    }

                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
                .finally(() => {
                    setIsLoading(false);
                    if (saveDeviceSelectionRef.current?.length) {
                        isBlockerRef.current = false
                        window.location.reload()
                    }
                });
        });


        const blockCategoriesMap = new Map(
            editor.BlockManager.getCategories().models
                .map((element, index) => {
                    let blocks = JSON.parse(JSON.stringify(editor.BlockManager.getAll()))
                        .filter((data) => (data.category?.id === element.id))
                    return [
                        element.id,
                        {
                            id: (index + 1),
                            title: element.id,
                            blocks: blocks.map((data) => ({ ...data, categoryData: data.category, category: undefined }))
                        }
                    ];
                })
        );

        setBlockCategories(blockCategoriesMap)

        editor.on('update', () => {
            setIsSaved(false)
        })


        editor.on('component:selected', (model) => handleComponentSelect(model, editor))

        editor.on('component:hover', (model) => handleComponentHover(model, editor))

        editor.on('component:add', (model) => {
            setShowLeftDrawer(false)
            setForceUpdate((prevState) => !prevState)
        })

        editor.on('trait:custom', props => {
            const sModel = editor?.getSelected();
            // if (props.editType === 'tabbar') {
            //     const allModel = props.editor?.getComponents();
            //     const tabbar_Model = allModel?.models?.find((data) => data?.attributes?.type?.includes("tabbar"));
            //     // console.log('🚀OUTPUT --> allModel:', allModel);
            //     setSelectedModel(tabbar_Model)
            // } else {
            setSelectedModel(sModel)
            // }
            // props.container (HTMLElement) - The default element where you can append your custom UI

            // Here you would put the logic to render/update your UI.
        });

        // editor.on('style:custom', props => {
        //     console.log('style',props)
        //     const sm = editor.StyleManager;
        //     console.log('style2', sm,sm?.getSectors())


        //     // props.container (HTMLElement)
        //     //    The default element where you can append your
        //     //    custom UI in order to render it in the default position.

        //     // Here you would put the logic to render/update your UI by relying on Style Manager API
        // });
    }

    const handleRawSave = () => {

        let typeId = 3;

        if (name == 'Header') {
            typeId = 1;
        } else if (name == 'Footer') {
            typeId = 2;
        } else if (name == 'MobileNavigation') {
            typeId = 4;
        }

        setIsLoading(true);
        nodeAxios
            .post(`/web-builder/web-page/html/${id}`, {
                web_page_id: id,
                title: name,
                type_id: typeId,
                uri: uri,
                device_type: (deviceRef.current == 'mobile') ? 2 : ((deviceRef.current == 'tablet') ? 1 : 0),
                destination: saveDeviceSelectionRef.current,
                html: codeMirrorRef.current
            })
            .then(() => {
                setIsSaved(true)
                toast.success(
                    'Saved. It may take upto 5 min to reflect your changes on your website'
                );
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSave = () => {
        editor.store()
    }


    useEffect(() => {

        if (!isSaved) {
            isBlockerRef.current = true
            window.addEventListener('beforeunload', handleUnload2);
        } else {
            isBlockerRef.current = false
            //   Router.events.off('routeChangeStart', handleUnload);
            window.removeEventListener('beforeunload', handleUnload2);
        }

    }, [isSaved])

    useEffect(() => {
        if (editor) {
            handleEditorEvents()
            window.esplandaeditor = editor;
        }
    }, [editor])

    useEffect(() => {
        saveDeviceSelectionRef.current = saveDeviceSelection
    }, [saveDeviceSelection])

    useEffect(() => {
        fetchWebPage()
    }, [])

    return (
        <WebsiteEditorProvider
            value={{
                setEditor,
                setCode,
                setIsSaved,
                handleSave,
                setShowLeftDrawer,
                setSelectedModel,
                editor,
                name,
                id,
                uri,
                device,
                blockCategories,
                selectedModel,
                header,
                footer,
                bottomTabbar,
                pages,
                forceUpdate
            }}
        >
            {isLoading && (
                <PageLoader />
            )}
            <div
                className="tw-flex"
            >
                <LeftPanel />

                <LeftDrawer
                    open={showLeftDrawer}
                    onClose={() => {
                        setShowLeftDrawer(false)
                    }}
                />

                <div
                    className="tw-w-full"
                >
                    <TopPanel
                        isTemplateSelected={isTemplateSelected}
                    />
                    <div
                        className="tw-flex"
                    >
                        <div
                            className="tw-w-[175px] tw-min-w-[175px]"
                        >
                            <Layers />
                        </div>

                        <div
                            className="tw-w-full"
                        >
                            {isRawHtmlEditor ? (
                                <div
                                    className={clsx(!isRawHtmlEditor && 'tw-hidden')}
                                >
                                    <CodeMirror
                                        ref={codeMirrorRefEditor}
                                        value={code}
                                        height="600px"
                                        extensions={[
                                            html(),
                                            // lineNumbers(),
                                            // foldGutter(),
                                            // gutter()
                                        ]}
                                        onChange={(val) => {
                                            codeMirrorRef.current = val
                                            // setCode(val)
                                        }}
                                        theme={vscodeDark}
                                        basicSetup={{
                                            foldGutter: true
                                        }}
                                    />
                                </div>
                            ) : (
                                <CustomPageBuilder />
                            )}

                        </div>

                        <RightPanel />
                    </div>

                    {/* <Suspense
                    fallback={<></>}
                >
                    <Outlet />
                </Suspense> */}

                </div>
            </div>

            <Dialogs />
        </WebsiteEditorProvider>
    )
}

export default WebsiteBuilderLayout