import axios from '../utils/axiosConfig';
import {bizBaseurl} from '../env';

export const postBankingInfo = async (body) => {
    try{
        let {data} = await axios.post(`/venue/steps/banking_info`,body);
        return;
    }catch(err){
        throw err;
    };
};

export const postBranding = async (body) => {
    try{
        let formData = new FormData();
        formData.append('image', body.image);
        formData.append('favicon_image', body.favicon_image);
        formData.append('no_logo', body.no_logo);
        let {data} = await axios.post(`/venue/steps/branding`,formData);
        return;
    }catch(err){
        throw err;
    };
};

export const postLicenseInfo = async (body) => {
    try{
        let formData = new FormData();
        formData.append('file', body.file);
        formData.append('licence_no', body.license_no);
        let {data} = await axios.post(`/venue/steps/licence-info`,formData);
        return;
    }catch(err){
        throw err;
    };
};

export const postIntegration = async (body) => {
    try{
        let {data} = await axios.post(`/venue/steps/integration`,{is_integration: "1"});
        return;
    }catch(err){
        throw err;
    };
};

export const postStoreInfo = async (body) => {
    try{
        let {data} = await axios.post(`/venue/setting`,body);
        return;
    }catch(err){
        throw err;
    };
};

export const postWebsite = async (body) => {
    try{
        let {data} = await axios.post(`/venue/create-url`,body);
        return;
    }catch(err){
        throw err;
    };
};