import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { classname } from '../../../utils/bem';
import axios from '../../../utils/axiosConfig';
import './BannerPreview.scss'

const BannerPreview = (props) => {
    const cn = classname('banner-preview')

    const [banners, setBanners] = useState([])

    useEffect(() => {
        axios.get('/banner-group/list', {
            params: {
                group_id: props.id
            }
        })
        .then((response)=>{
setBanners(response.data.data.result[0]?.banners)
        })
    }, [props.id, props.showBannerPreview])

    return (
        <Modal
            style={{
                zIndex: 2000
            }}
            contentClassName={cn('modal-main-content')}
            dialogClassName={cn('modal-main-dialog')}
            show={props.showBannerPreview}
            centered={true}
            onHide={() => props.setShowBannerPreview(false)}
        >
            <Swiper
                style={{
                    width: '100%'
                }}
                navigation={true}
                loop={true}
                centeredSlides={true}
                observeParents={true}
                observer={true}
                breakpoints={{
                    769: {
                        pagination: true,
                        slidesPerView: 1.6
                    },
                    420: {
                        pagination: true,
                        slidesPerView: 1
                    },
                    320: {
                        pagination: false,
                        slidesPerView: 1
                    }
                }}
                autoplay={{
                    "delay": 5000,
                    "disableOnInteraction": true
                }}
                spaceBetween={5}
            >
                {banners?.map((val, i) => {
                    return (
                        <SwiperSlide
                            key={cn(`banner-swiper-${i}`)}
                            className={cn('banner-swiper-slide', {
                                'venue-selected': true
                            })}
                            {...(val.button_link && { onClick: () => window.location.href = val.button_link })}
                            style={{
                                position: 'relative',
                            }}
                        >
                            <img
                                {...(val.banner_type && { style: { objectFit: val.banner_type } })}
                                className={cn('banner-image', {
                                    'venue-banner': true
                                })}
                                src={val.image}
                            />
                            <div
                                className={cn('banner-text-content-container', {
                                    venue: true
                                })}
                            >
                                {!(val.banner_title == ".") && (
                                    <div
                                        className={cn('title-container', {
                                            venue: true
                                        })}
                                    >
                                        {val.banner_title}
                                    </div>
                                )}

                                {!(val.description == ".") && (
                                    <div
                                        className={cn('description-container', {
                                            venue: true
                                        })}
                                    >
                                        {val.description}
                                    </div>
                                )}

                                {(val.button_text && val.button_link && !(val.button_text === '.')) && (
                                    <a
                                        className={cn('venue-banner-button-container', {
                                            'not-venue': false
                                        })}
                                        href={val.button_link}
                                    >
                                        <Button
                                            classes={{
                                                contained: cn('venue-banner-button')
                                            }}
                                            type="button"
                                            variant="contained"
                                        >
                                            {val.button_text}
                                        </Button>
                                    </a>
                                )}
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </Modal>
    )
}

export default BannerPreview