import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import Customize from "../../../assets/feature.png";
import listicon from "../../../assets/list-text.png";
import viewbannerimg from "../../../assets/view-banner.png";
import addbannergroupimg from "../../../assets/add-banner-group.png";
import plusimg from "../../../assets/plus.png";
import routePaths from '../../../config/route-paths';
const CreateNewBannerGroup = (props) => {
   const cn = classname('add-new-product')
   return (
      <div
         className={cns(
            cn(),
            props.className,
         )}>
         <div className={cn('help-page-title')}>
            <div className={cn('title-text')}>
               <Typography variant="h1">
                  Help & Support
               </Typography>
            </div>
            <div className={cn('help-page-title-search')}>
               Search
            </div>
         </div>
         <div className={cn('help-page-body')}>
            <div className={cn('helpdesk-grid')}>
               <div className={cn('left-list')}>
                  <Typography variant="h2">
                     <div className={cn('iconProduct')}>
                        <img src={Customize} />
                     </div>
                     Customize Your Website & App
                  </Typography>
                  <ul className={cn('helpdeskinnerlist')}>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AddNewProductGroups}>How to Add New Product Groups</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropProductGroupInterface}>Drag & Drop Product Group Interface</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingProductGroup}>Edit Existing Product Group</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropBanners}>Drag & Drop Banners</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateNewBannerGroup} className={cn('selected')}>Create New Banner Group</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingBannerGroup}>Edit Existing Banner Group</Link></li>
                     <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateCustomStorePage}>Create Custom Store Page</Link></li>
                  </ul>
               </div>
               <div className={cn('right-body-content')}>
                  <Typography variant="h3">
                     Create New Banner Group
                  </Typography>
                  <p>
                     <ol>
                        <li>Go to the ‘<strong>Customize Homepage</strong>’ section of the admin website.</li>

                        <span className={cn('addnewproductsimg1')}>
                           <img src={viewbannerimg} />
                        </span>

                        <li>Click on the Banners tab on the left side, next to Product Group. Click on ‘<strong>View Banners</strong>’ below the ‘<strong>Search Banners</strong>’ search bar.</li>

                        <span className={cn('addnewproductsimg2')}>
                           <img src={plusimg} />
                        </span>


                        <li>Now you will be on the Banner Groups page. On the right side of the screen, there will be a button with a ‘<strong>+</strong>’. Click this button.</li>

                        <span className={cn('addnewproductsimg1')}>
                           <img src={addbannergroupimg} />
                        </span>

                        <li>Here you can enter a name for your new Banner Group. Below that, click on ‘<strong>Add New Banner</strong>’. A pop-up window will appear.</li>

                        <li>At the top, you can upload an image which will act as the background for your banner. You can give this individual banner a name. The Banner Type describes how the uploaded image will fit in the banner frame.</li>

                        <li>The Banner title will be a large text displayed over your banner. The Description will be a small text displayed over your banner.</li>
                        <li>The Button Text and Button Link allow you to put a custom link through your banner to any link on your website, usually a product or category.</li>
                        <li>If you have multiple banners within a banner group, the Display Order is the order in which your banners will be displayed, and the Display Time is how long the banner will be visible before the next one is displayed.</li>
                        <li>When you are finished, click the ‘Save’ button to close the pop-up window. When you are completely done with your banner group, click ‘Save’ again.</li>

                     </ol>
                  </p>
               </div>
            </div>
         </div>
         <div className={cn('answersection')}>
            <Typography variant="h4">
               Still can’t find an answer?
            </Typography>
            <p>Send us a ticket and we will get back to you.</p>
            <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
         </div>
      </div>
   )
}
export default CreateNewBannerGroup