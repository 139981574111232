import { Dialog, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ConnectStoreDialog from '../Dialogs/ConnectStoreDialog/ConnectStoreDialog';
import GenericButton from '../Library/GenericButton/GenericButton';
import styles from './MultiStoreManagement.module.scss'
import axios from '../../utils/axiosConfig'
import StyledTableCell from '../Library/StyledTableCell/StyledTableCell';
import StyledTableRow from '../Library/StyledTableRow/StyledTableRow';
import InputField from '../Library/InputField/InputField';
import { DeleteForever, Search } from '@mui/icons-material';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import Loader from '../Library/Loader';
import { useOutletContext, useSearchParams } from 'react-router-dom';

const headings = [
  {
    label: ''
  },
  {
    label: 'Store Name'
  },
  {
    label: 'Email'
  },
  {
    label: 'Phone Number'
  },
  {
    label: 'Address'
  },
  {
    label: ''
  },
]

const MultiStoreManagement = (props) => {

  const { isActionsDisabled } = useOutletContext()

  const user = useSelector((state) => state.user);
  const confirm = useConfirm();

  const [searchParams, setSearchParams] = useSearchParams()
  const [showConnectStore, setShowConnectStore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [stores, setStores] = useState([])
  const [query, setQuery] = useState('')

  const fetchMultiStore = (q) => {
    setIsLoading(true)
    axios.get('/venue/multistore-list', {
      params: {
        q: q
      }
    })
      .then((response) => {
        setStores(response.data.data.result)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleConnect = () => {
    axios.post('/venue/multistore-connect', {
      token: props.token
    })
      .then(() => {
        toast.success('Successfully Connected')
        fetchMultiStore()
        setSearchParams({})
      })
      .catch(() => {
        toast.error('Something went wrong')
      })
  }

  useEffect(() => {
    fetchMultiStore()
  }, [])

  useEffect(() => {
    if (props.token) {
      handleConnect()
    }
  }, [props.token])


  const handleDisconnect = (id) => {
    confirm({
      title: 'Are you sure you want to disconnect this store'
    })
      .then(() => {
        axios.post('/venue/multistore-disconnect', {
          venue_id: id
        })
          .then(() => {
            toast.success('Successfully Disconnected')
            fetchMultiStore()
          })
      })
  }

  const debounceSearch = useCallback(debounce(fetchMultiStore, 1000), [])

  return (
    <div
      className={styles.container}
    >
      <div
        className={styles.your_store_text}
      >
        <span
          style={{
            color: '#ef2d2c',
            marginRight: 4
          }}
        >
          Your Store Connect id:
        </span>
        <span>
          {user.data.venueid}
        </span>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {Boolean(stores.length) ? (
            <div
              className={styles.table_container}
            >
              <div
                className={styles.search_container}
              >
                <div
                  style={{
                    width: 220
                  }}
                >
                  <InputField
                    placeholder="Search"
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value)
                      debounceSearch(e.target.value)
                    }}
                    onIconButtonClick={e => debounceSearch(query)}
                    showIconButton={true}
                    endIcon={(
                      <Search
                        style={{
                          color: '#ef2d2c'
                        }}
                      />
                    )}
                  />
                </div>

                <GenericButton
                  variant="contained"
                  onClick={() => {
                    setShowConnectStore(true)
                  }}
                  size="large"
                  disabled={isActionsDisabled}
                >
                  Connect a Store
                </GenericButton>
              </div>

              <TableContainer
                component={Paper}
              >
                <Table
                  sx={{
                    minWidth: 700
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {headings.map((data) => (
                        <StyledTableCell
                          style={{
                            color: 'black'
                          }}
                        >
                          {data.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {stores.map((row) => (
                      <StyledTableRow key={row.campaignName}>
                        <StyledTableCell component="th" scope="row">
                          <div>
                            <img
                              height={60}
                              src={row.image}
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.Name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.contact_no}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.address1}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={() => {
                              handleDisconnect(row.VenueId)
                            }}
                          >
                            <DeleteForever />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div
              className={styles.container}
            >
              <div
                className={styles.owner_text}
              >
                Owner of more than one location?
              </div>

              <div
                className={styles.create_text}
              >
                Create multi accounts to have better control over your inventory, marketing promos, design & more!
              </div>

              <GenericButton
                variant="contained"
                onClick={() => {
                  setShowConnectStore(true)
                }}
                size="large"
                disabled={isActionsDisabled}
              >
                Connect a Store
              </GenericButton>
            </div>
          )}
        </>
      )}
      <Dialog
        open={showConnectStore}
        onClose={() => {
          setShowConnectStore(false)
        }}
        maxWidth="xs"
      >
        <ConnectStoreDialog
          onClose={() => {
            setShowConnectStore(false)
          }}
        />
      </Dialog>

    </div>
  )
}

export default MultiStoreManagement