import React, { useState, useRef, useEffect, useCallback } from "react";
import {
    TableRow,
    TableCell,
    Typography,
    TextField,
    Select,
    Autocomplete,
    MenuItem,
    CircularProgress,
    Button,
    useMediaQuery
} from "@mui/material";
import { Add, Remove, DoneOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
    getSuggestions,
    getProductFormats,
} from "../../../../../redux/actions/orderDetailsActions";
import { useClickOutside } from "../../../../../hooks/useClickOutside";
import StyledTableRow from "../../../../Library/StyledTableRow/StyledTableRow";
import StyledTableCell from "../../../../Library/StyledTableCell/StyledTableCell";
import GenericAutoCompleteWithLabel from "../../../../Library/GenericAutoCompleteWithLabel/GenericAutoCompleteWithLabel";
import styles from './AddForm.module.scss'
import GenericTextField from "../../../../Library/GenericTextField/GenericTextField";
import { debounce } from "lodash";
import GenericSelect from "../../../../Library/GenericSelect/GenericSelect";
import GenericButton from "../../../../Library/GenericButton/GenericButton";
import { getImage } from "../../../../CustomPageBuilder/EditSection/Util/helpers";

const useStyles = makeStyles(() => ({
    tableText: {
        textAlign: "center",
        fontSize: ".9rem",
        fontWeight: "600",
    },
    textInput: {
        borderRadius: "0",
        width: "100%",
        padding: "0 !important",
        "& > input": {
            padding: "7px 9px !important",
        },
    },
    autocompleteInput: {
        borderRadius: "0",
        width: "100%",
        padding: "0 !important",
        "& > input": {
            padding: "9px 67px 9px 9px !important",
        },
    },
    selectField: {
        backgroundColor: "white",
        borderRadius: 0,
        border: "1px solid lightgrey",
        transition: "none",
        width: "100%",
        "& > select": {
            padding: "9px 32px 9px 10px !important",
            fontSize: ".93rem",
            "&:focus": {
                backgroundColor: "white",
            },
        },
        "&:hover": {
            backgroundColor: "white",
        },
    },
    selectFieldOutlined: {
        border: "none !important",
    },
}));

const OrderItem = ({ setOpen, open, setSaveable, ...props }) => {
    const classes = useStyles();
    const dispatch = useDispatch((data) => data);
    const isMobileScreen = useMediaQuery('(max-width:768px)');
    const {
        suggestions,
        formats,
        result: { order },
        isLoading
    } = useSelector((state) => state.orderDetailsNew);
    const itemRef = useRef(null);
    const [openOption, setOpenOption] = useState(false)
    const [newProduct, setNewProduct] = useState({
        productformat: '',
        name: '',
        product_id: '',
        unit_price: '',
        quantity: 1,
        product_image: '',
        vpid: '',
        sku: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [showOtherFields, setShowOtherFields] = useState(false)

    const handleFetchSuggestion = (value) => {
        dispatch(
            getSuggestions({
                name: value,
            })
        );
    }

    const debounceSearch = useCallback(debounce(handleFetchSuggestion, 1000), [])
    const handleProductNameChange = (event, value, manual) => {
        try {
            if (manual) {
                debounceSearch(value)
                setNewProduct((prevState) => ({
                    ...prevState,
                    name: value
                }));
            } else {

                dispatch(getProductFormats({ productID: value.ProductId }));
                setNewProduct({
                    ...newProduct,
                    name: value.ProductName,
                    product_id: value.ProductId,
                    product_image: value.image,
                    productformat: value.format,
                    unit_price: value.Price,
                    vpid: value.id,
                    sku: value.sku
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const handleFormatChange = (event) => {
        try {
            let format = formats.find((e) => e.format === event.target.value);
            setNewProduct({
                ...newProduct,
                productformat: format.format,
                unit_price: format.Price,
                vpid: format.id,
                product_id: format.ProductId
            })
        } catch (err) {
            console.log(err.message);
        }
    };
    const handleQuantityChange = (value) => {
        try {
            setNewProduct({
                ...newProduct,
                quantity: value
            });
        } catch (err) {
            console.log(err.message);
        }
    };

    const handleSubmit = () => {
        setSubmitted(true);
        if (!newProduct.name || (!newProduct.unit_price && newProduct.unit_price !== 0) || !newProduct.product_id || (!newProduct.quantity && newProduct.quantity !== 0) || !newProduct.productformat) {
            return;
        }
        props.addOrderItem(newProduct)

        setSubmitted(false);
        setNewProduct({
            productformat: '',
            name: '',
            product_id: '',
            unit_price: '',
            quantity: 1,
            product_image: '',
            vpid: '',
            sku: ''
        })
        setOpen(false);
        setSaveable(true);
    }

    return isMobileScreen ? (
        <MobileAddItem {...{
            suggestions,
            openOption,
            newProduct,
            setOpenOption,
            setShowOtherFields,
            handleProductNameChange,
            submitted,
            showOtherFields,
            handleFormatChange,
            formats,
            handleQuantityChange,
            setNewProduct,
            handleSubmit,
            setOpen,
            isLoading,
            classes
        }} />
    ) : (
        <StyledTableRow ref={itemRef}>
            <StyledTableCell size="small" >
                <div style={{ display: "flex" }}>
                    {showOtherFields && (
                        <div
                            style={{
                                minWidth: "128px",
                                maxWidth: "128px",
                                height: "13vh",
                            }}
                        >
                            <img
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    objectFit: "contain",
                                }}
                                src={newProduct.product_image}
                                alt={newProduct.name}
                                onError={({ currentTarget, target }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = getImage('no_image.png-1694435803.png')
                                }}
                            />
                        </div>
                    )}
                </div>
            </StyledTableCell>

            <StyledTableCell>
                <div
                    style={{
                        display: "flex",
                        width: "120px",
                        alignItems: "center",
                    }}
                >
                    <GenericAutoCompleteWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        options={suggestions}
                        clearOnBlur={false}
                        onBlur={() => setOpenOption(false)}
                        open={openOption}
                        filterOptions={(options) => options}
                        getOptionLabel={(option) => option.ProductName}
                        inputValue={newProduct.name}
                        freeSolo={true}
                        onChange={(event, val) => {
                            setOpenOption(false)
                            setShowOtherFields(true)
                            handleProductNameChange(
                                event,
                                val,
                                false
                            )
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason === 'input') {
                                setOpenOption(true)
                                handleProductNameChange(
                                    event,
                                    newInputValue,
                                    true
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <GenericTextField
                                {...params}
                                style={{
                                    backgroundColor: 'white'
                                }}
                                error={submitted && !newProduct.product_id}
                                variant="outlined"
                                placeholder={'Search product'}
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        root: styles.autocomplete_input
                                    },
                                    endAdornment: isLoading ? <CircularProgress style={{ marginRight: '10px' }} size={20} /> : null
                                }}
                            />
                        )}
                    />
                </div>
            </StyledTableCell>
            <StyledTableCell>
                <div
                    style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {showOtherFields && (
                        <GenericSelect
                            style={{
                                backgroundColor: 'white'
                            }}
                            fullWidth={true}
                            error={submitted && !newProduct.productformat}
                            value={newProduct.productformat}
                            onChange={handleFormatChange}
                        >
                            {formats.map((val) => {
                                return (
                                    <MenuItem value={val.format}>
                                        {val.format}
                                    </MenuItem>
                                );
                            })}
                        </GenericSelect>
                    )}
                </div>
            </StyledTableCell>
            <StyledTableCell>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {showOtherFields && (
                        <GenericTextField
                            style={{
                                backgroundColor: 'white'
                            }}
                            onChange={(event) => {
                                setNewProduct((prevState) => ({ ...prevState, unit_price: event.target.value }))
                            }}
                            value={newProduct.unit_price}
                        />
                    )}
                </div>
            </StyledTableCell>
            <StyledTableCell>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {showOtherFields && (
                        <div
                            style={{
                                display: "flex",
                                border: "1px solid #DADADA",
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "rgb(223,219,220)",
                                    color: "#767273",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={e => handleQuantityChange(newProduct.quantity - 1)}
                            >
                                <Remove
                                    color="inherit"
                                    style={{ transform: "scale(.75)" }}
                                />
                            </div>
                            <Typography
                                style={{ margin: "5px 14px" }}
                                className={classes.tableText}
                                variant="h6"
                            >
                                {newProduct.quantity}
                            </Typography>
                            <div
                                onClick={e => handleQuantityChange(newProduct.quantity + 1)}
                                style={{
                                    backgroundColor: "rgb(223,219,220)",
                                    color: "#767273",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <Add
                                    color="inherit"
                                    style={{ transform: "scale(.75)" }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </StyledTableCell>
            <StyledTableCell>
                <div>
                    {showOtherFields && (
                        <Typography className={classes.tableText} variant="h6">
                            USD {(newProduct.unit_price * newProduct.quantity).toFixed(2)}
                        </Typography>
                    )}
                </div>
            </StyledTableCell>

            <StyledTableCell>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10
                    }}
                >
                    {showOtherFields && (
                        <GenericButton
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Add
                        </GenericButton>
                    )}

                    <Button
                        variant="text"
                        style={{
                            color: '#E40000'
                        }}
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Remove
                    </Button>

                </div>
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default OrderItem;


const MobileAddItem = ({
    suggestions,
    openOption,
    newProduct,
    setOpenOption,
    setShowOtherFields,
    handleProductNameChange,
    submitted,
    showOtherFields,
    handleFormatChange,
    formats,
    handleQuantityChange,
    setNewProduct,
    handleSubmit,
    setOpen,
    isLoading,
    classes
}) => {



    return (
        <div className={styles.mobile_order_item_container}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <GenericAutoCompleteWithLabel
                    containerStyle={{
                        width: '100%'
                    }}
                    options={suggestions}
                    clearOnBlur={false}
                    onBlur={() => setOpenOption(false)}
                    open={openOption}
                    filterOptions={(options) => options}
                    getOptionLabel={(option) => option.ProductName}
                    inputValue={newProduct.name}
                    freeSolo={true}
                    onChange={(event, val) => {
                        setOpenOption(false)
                        setShowOtherFields(true)
                        handleProductNameChange(
                            event,
                            val,
                            false
                        )
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'input') {
                            setOpenOption(true)
                            handleProductNameChange(
                                event,
                                newInputValue,
                                true
                            );
                        }
                    }}
                    renderInput={(params) => (
                        <GenericTextField
                            {...params}
                            style={{
                                backgroundColor: 'white'
                            }}
                            error={submitted && !newProduct.product_id}
                            placeholder={'Search product'}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    root: styles.autocomplete_input
                                },
                                endAdornment: isLoading ? <CircularProgress style={{ marginRight: '10px' }} size={20} /> : null
                            }}
                        />
                    )}
                />
            </div>
            <div className={styles.mobile_order_item}>
                <div style={{ display: "flex" }}>
                    {showOtherFields && (
                        <div
                            style={{
                                minWidth: "128px",
                                maxWidth: "128px",
                                height: "13vh",
                            }}
                        >
                            <img
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    objectFit: "contain",
                                }}
                                src={newProduct.product_image}
                                alt={newProduct.name}
                                onError={({ currentTarget, target }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = getImage('no_image.png-1694435803.png')
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.mobile_order_body}>
                    {/* <Typography fontSize={12} variant="h6">
                    SKU: <span style={{ color: '#B12704' }}>{orderDetail.SKU}</span>
                    </Typography> */}
                    {showOtherFields && (
                        <Typography fontSize={12} variant="h6" display={'flex'} alignItems={'center'} gap={'10px'} >
                            Format:
                            <span style={{ color: '#B12704' }}>
                                <GenericSelect
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    fullWidth={true}
                                    error={submitted && !newProduct.productformat}
                                    value={newProduct.productformat}
                                    onChange={handleFormatChange}
                                >
                                    {formats.map((val) => {
                                        return (
                                            <MenuItem value={val.format}>
                                                {val.format}
                                            </MenuItem>
                                        );
                                    })}
                                </GenericSelect>
                            </span>
                        </Typography>
                    )}
                    {showOtherFields && (
                        <Typography fontSize={12} variant="h6" display={'flex'} alignItems={'center'} gap={'10px'} >
                            Quantity:
                            <div
                                style={{
                                    display: "flex",
                                    border: "1px solid #DADADA",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "rgb(223,219,220)",
                                        color: "#767273",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={e => handleQuantityChange(newProduct.quantity - 1)}
                                >
                                    <Remove
                                        color="inherit"
                                        style={{ transform: "scale(.75)" }}
                                    />
                                </div>
                                <Typography
                                    style={{ margin: "5px 14px" }}
                                    className={classes.tableText}
                                    variant="h6"
                                >
                                    {newProduct.quantity}
                                </Typography>
                                <div
                                    onClick={e => handleQuantityChange(newProduct.quantity + 1)}
                                    style={{
                                        backgroundColor: "rgb(223,219,220)",
                                        color: "#767273",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Add
                                        color="inherit"
                                        style={{ transform: "scale(.75)" }}
                                    />
                                </div>
                            </div>

                        </Typography>
                    )}
                    {showOtherFields && (
                        <Typography fontSize={12} variant="h6" display={'flex'} alignItems={'center'} gap={'10px'} >
                            Price:
                            <span style={{ width: 80 }}>
                                <GenericTextField
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    onChange={(event) => {
                                        setNewProduct((prevState) => ({ ...prevState, unit_price: event.target.value }))
                                    }}
                                    value={newProduct.unit_price}
                                    type="number"
                                />
                            </span>


                        </Typography>
                    )}
                    {showOtherFields && (
                        <Typography fontSize={12} variant="h6" display={'flex'} alignItems={'center'} gap={'10px'} >
                            Total:
                            <span style={{ color: '#B12704' }}>
                                <Typography className={classes.tableText} variant="h6">
                                    USD {(newProduct.unit_price * newProduct.quantity).toFixed(2)}
                                </Typography>
                            </span>
                        </Typography>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            gap: 10,
                            width: '100%',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {showOtherFields && (
                            <GenericButton
                                // sx={{ width: '50%' }}
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Add
                            </GenericButton>
                        )}

                        <Button
                            // sx={{ width: '50%' }}
                            variant="text"
                            style={{
                                color: '#E40000'
                            }}
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            Remove
                        </Button>

                    </div>

                </div>
            </div>
        </div>

    )
}
