import { Edit } from '@mui/icons-material'
import { IconButton, MenuItem } from '@mui/material'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import GenericSelect from '../../Library/GenericSelect/GenericSelect'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import GenericTextField from '../../Library/GenericTextField/GenericTextField'
import styles from './StoreInformation.module.scss'
import { nodeAxios } from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericSwitch from '../../Library/V2/GenericSwitch/GenericSwitch'

const timeZonesList = [
    { title: "America/New_York" },
    { title: "America/Chicago" },
    { title: "America/Phoenix" },
    { title: "America/Los_Angeles" },
    { title: "US/Hawaii" },
    { title: "US/Alaska" },
];

const StoreInformation = (props) => {

    const [isDoorDashEnabled, setIsDoorDashEnabled] = useState(false)
    const [isUberEnabled, setIsUberEnabled] = useState(false)

    const checkDoorDash = () => {
        nodeAxios.get('/doordash/check-doordash')
            .then((res) => {
                setIsDoorDashEnabled(res.data.data)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const checkUber = () => {
        nodeAxios.get('/uber/check-uber')
            .then((res) => {
                setIsUberEnabled(res.data.data)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    useEffect(() => {
        checkDoorDash()
        checkUber()
    }, [])

    const handleDoordashEnable = () => {
        nodeAxios.post('/doordash/enable-doordash')
            .then(() => {
                toast.success('Doordash Enabled')
                setIsDoorDashEnabled(true)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleDoordashDisable = () => {
        nodeAxios.post('/doordash/disable-doordash')
            .then(() => {
                toast.success('Doordash Disabled')
                setIsDoorDashEnabled(false)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleUberEnable = () => {
        nodeAxios.post('/uber/enable-uber')
            .then(() => {
                toast.success('Uber Deliveries Enabled')
                setIsUberEnabled(true)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleUberDisable = () => {
        nodeAxios.post('/uber/disable-uber')
            .then(() => {
                toast.success('Uber Deliveries Disabled')
                setIsUberEnabled(false)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.title_container}
            >
                <div
                    className={styles.title}
                >
                    Store Information
                </div>

                <IconButton
                    onClick={() => {
                        props.setIsEditMode((prevState) => !prevState)
                    }}
                >
                    <Edit style={{ fontSize: 18, color: 'black' }} />
                </IconButton>
            </div>

            <div
                className={styles.form_container}
            >

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Name
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {props.isEditMode ? (
                            <GenericTextField
                                id="Name"
                                name="Name"
                                value={props.formik.values.Name}
                                onChange={props.formik.handleChange}
                                fullWidth={true}
                            />
                        ) : props.venueDetails.Name}

                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Email
                    </div>

                    <div
                        className={styles.input_field_container}
                    >
                        {/* {props.isEditMode ? (
                            <GenericTextField
                                style={{
                                    width: 200
                                }}
                                id="email"
                                name="email"
                                value={props.formik.values.email}
                                onChange={props.formik.handleChange}
                            />
                        ) : props.venueDetails.email} */}

                        {props.venueDetails.email}
                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Phone
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {props.isEditMode ? (
                            <GenericTextField
                                id="contact_no"
                                name="contact_no"
                                value={props.formik.values.contact_no}
                                onChange={props.formik.handleChange}
                                fullWidth={true}
                            />
                        ) : props.venueDetails.contact_no}

                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Timezone
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {props.isEditMode ? (
                            <GenericSelect
                                id="timeZone"
                                name="timeZone"
                                value={props.formik.values.timeZone}
                                onChange={props.formik.handleChange}
                                fullWidth={true}
                            >
                                {timeZonesList.map((data) => (
                                    <MenuItem
                                        value={data.title}
                                    >
                                        {data.title}
                                    </MenuItem>
                                ))}
                            </GenericSelect>
                        ) : props.venueDetails.timeZone}
                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Store Address
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {/* {props.isEditMode ? (
                            <GenericTextField
                                style={{
                                    width: 200
                                }}
                                id="full_address"
                                name="full_address"
                                value={props.formik.values.full_address}
                                onChange={props.formik.handleChange}
                                multiline={true}
                            />
                        ) : props.venueDetails.full_address} */}

                        {props.venueDetails.full_address}
                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Description
                    </div>

                    <div
                        className={clsx(styles.input_field_container, props.isEditMode && styles.input_field_edit_mode)}
                    >
                        {props.isEditMode ? (
                            <GenericTextField
                                id="Description"
                                name="Description"
                                value={props.formik.values.Description}
                                onChange={props.formik.handleChange}
                                multiline={true}
                                fullWidth={true}
                            />
                        ) : props.venueDetails.Description}
                    </div>
                </div>

                <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Doordash
                    </div>

                    <GenericSwitch
                        background="#0AB221"
                        width={35}
                        height={18}
                        translateX={16}
                        thumb={13}
                        isStatus={true}
                        checked={isDoorDashEnabled}
                        onText="'On'"
                        offText="'Off'"
                        onChange={(event) => {
                            if (event.target.checked) {
                                handleDoordashEnable()
                            } else {
                                handleDoordashDisable()
                            }
                        }}
                    />
                </div>

                {/* <div
                    className={styles.input_container}
                >
                    <div
                        className={styles.input_field_text}
                    >
                        Uber
                    </div>

                    <GenericSwitch
                        background="#0AB221"
                        width={35}
                        height={18}
                        translateX={16}
                        thumb={13}
                        isStatus={true}
                        checked={isUberEnabled}
                        onText="'On'"
                        offText="'Off'"
                        onChange={(event) => {
                            if (event.target.checked) {
                                handleUberEnable()
                            } else {
                                handleUberDisable()
                            }
                        }}
                    />
                </div> */}

            </div>
        </div>
    )
}

export default StoreInformation