import React, { useState }from 'react';

import { useNavigate } from 'react-router-dom';
import no_user_available from '../../../../../../assets/svgs/no_user_available.svg';

import axios, { nodeAxios } from '../../../Util/axiosConfig';
import imageBaseUrl from '../../../Util/imageBaseUrl';   
import styles from './BlogsTable.module.scss';
import Loader from '../../../../Library/Loader/Loader';
import {
  Dialog,
  DialogContent,
  Menu,
  MenuItem,
  Pagination,
} from '@mui/material';
import { format } from 'date-fns';
import { MoreVert, Share } from '@mui/icons-material';
import { toast } from 'react-toastify';
import CreateBlogModal from '../../WebsiteBuilderCreateBlogs/CreateBlogModal/CreateBlogModal';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TumblrShareButton,
} from 'react-share';

const Blogs = (props) => {
  // console.log(props.blogs);

  const formatDate = (date) => {
    const dt = new Date(date);
    const dtDateOnly = new Date(
      dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
    );
    return format(dtDateOnly, 'LL/dd/yyyy');
  };

  return (
    <div className={styles.list_grid_container}>
      <div className={styles.studentTableContainer}>
        {!props?.blogs?.data ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : props.blogs.data?.length === 0 ? (
          <div className={styles.no_data_available}>
            <img
              src={no_user_available}
              height={100}
              width={100}
              alt='No blogs yet'
            />
            You don’t have any blog post yet
          </div>
        ) : (
          props.blogs.data?.map((data) => (
            <BlogCard key={data.id} blog={data} {...props} />
          ))
        )}
      </div>
      {props?.blogs?.data?.length > 0 && (
        <Pagination
          classes={{
            root: styles.schoolPaginateRootEl,
            ul: styles.schoolPaginateListEl,
          }}
          count={props.totalPages}
          page={props.pageNo}
          onChange={(event, value) => {
            props.handleChangePage(value);
          }}
        />
      )}
    </div>
  );
};

export default Blogs;

const BlogCard = ({ blog, ...props }) => {
  const navigate = useNavigate()

  const [showBlogsMenuEl, setShowBlogsMenuEl] = useState(null);
  const [isBlogSubmitDialogOpen, setIsBlogSubmitDialogOpen] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const showBlogsMenu = Boolean(showBlogsMenuEl);

  const updateBlogStatus = () => {
    const payload = { status: blog?.status === 0 ? 1 : 0 };
    nodeAxios
      .post(`/web-builder/blog/change-status/${blog?.id}`, payload)
      .then((response) => {
        toast.success(response?.data?.message);
        props?.fetchBlogs();
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        console.log(err);
      });
  };
  const deleteBlog = () => {
    const web_page_id = blog?.web_page_id;
    nodeAxios
      .delete(`/web-builder/blog/delete/${blog?.id}`)
      .then((response) => {
        // toast.success(response?.data?.message);
        // props?.fetchBlogs();
        nodeAxios
          .delete(`/web-builder/web-page/delete/${web_page_id}`)
          .then((response) => {
            toast.success(response?.data?.message);
            props?.fetchBlogs();
          })
          .catch((err) => {
            toast.error(err?.data?.message);
            console.log(err);
          });
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        console.log(err);
      });
  };

  const getImageUrl = (image) => {
    return `${imageBaseUrl}/WebPage/${image}`;
  };
  const getInitialsAvatar = (name) => {
    return `https://ui-avatars.com/api/?background=random&length=1&name=${name}`;
  };
  const blogLink = `${props.blogs?.subdomain}/${blog?.template_url}`;
  // props.blogs?.subdomain === 'esplanda.esplanda.com' || blog?.school_id === 1
  //     ? `https://esplanda.com/${blog?.template_url}`
  //     : `${props.blogs?.subdomain}/${blog?.template_url}`;

  return (
    <div key={blog.id} className={styles.blogs_card}>
      <div className={styles.blogs_card_body}>
        <div className={styles.blogs_card_thumbnail}>
          {blog?.image ? (
            <img
              priority
              src={getImageUrl(blog?.image)}
              height={1000}
              width={1000}
              alt='Blog'
            />
          ) : (
            <img
              priority
              src={getInitialsAvatar(blog?.title)}
              height={1000}
              width={1000}
              alt='Blog'
            />
          )}
        </div>
        <div className={styles.blogs_card_content}>
          <div>
            <a
              style={{ textDecoration: 'underline' }}
              target='_blank'
              rel='noopener noreferrer'
              href={blogLink}
            >
              {blog?.title}
            </a>
          </div>
          <p>
            {blog?.status === 1 ? 'Published' : 'Un published'} ·{' '}
            {blog?.published_at ? format(new Date(blog?.published_at), 'dd LLL yyyy') :
              blog?.blog_date ? format(new Date(blog?.blog_date), 'dd LLL yyyy') : ''}
          </p>
        </div>
      </div>
      <div className={styles.blogs_card_actions}>
        <div
          id={`blogs-menu-button`}
          aria-controls={showBlogsMenu ? `blogs-menu` : undefined}
          aria-haspopup='true'
          aria-expanded={showBlogsMenu ? 'true' : undefined}
          onClick={(e) => setShowBlogsMenuEl(e.currentTarget)}
        >
          <MoreVert width={25} height={25} sx={{ cursor: 'pointer' }} />
        </div>
        <Share
          onClick={(e) => setIsShareDialogOpen(true)}
          width={20}
          height={20}
          sx={{ marginRight: '5px', cursor: 'pointer' }}
        />
      </div>

      {/* Blog Menu */}
      <Menu
        id={`blogs-menu`}
        anchorEl={showBlogsMenuEl}
        open={showBlogsMenu}
        onClose={() => setShowBlogsMenuEl(null)}
        classes={{ paper: styles.headerAccountMenuPaper }}
        MenuListProps={{
          'aria-labelledby': `blogs-menu-button`,
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        // onBackdropClick={handleBackdropClick}
        sx={{
          '.MuiPaper-root': {
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.14)',
          },
        }}
      >
        <div className={styles.headerAccountSubMenu}>
          <MenuItem
            classes={{ root: styles.headerAccountSubMenuItem }}
            // onClick={() => setIsBlogSubmitDialogOpen(true)}
            onClick={() =>
              navigate(
                `/website-builder/blogs/create?blog=${blog.id}&page_id=${blog.web_page_id}&uri=${blog.template_url}`
              )
            }
          >
            <div>Edit Post</div>
          </MenuItem>
          <MenuItem
            classes={{ root: styles.headerAccountSubMenuItem }}
            onClick={() => updateBlogStatus()}
          >
            <div>{blog?.status === 0 ? 'Publish post' : 'Un publish post'}</div>
          </MenuItem>
          <MenuItem
            classes={{ root: styles.headerAccountSubMenuItem }}
            onClick={() => deleteBlog()}
          >
            <div style={{ color: '#FF2020' }}>Delete</div>
          </MenuItem>
        </div>
      </Menu>

      <Dialog
        open={isBlogSubmitDialogOpen}
        classes={{ paper: styles.create_blog_modal_paper }}
        onClose={() => setIsBlogSubmitDialogOpen(false)}
      >
        <DialogContent>
          <CreateBlogModal
            closeDialog={() => setIsBlogSubmitDialogOpen(false)}
            page={props.page}
            setIsAddUserDialogOpen={setIsBlogSubmitDialogOpen}
            edit={true}
            fetchBlogs={props?.fetchBlogs}
            blog={{
              id: blog.id,
              web_page_id: blog.web_page_id,
              blogTitle: blog?.title,
              imageFile: blog?.image,
              status: blog?.status,
              categories: blog?.category_ids,
            }}
            {...props}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isShareDialogOpen}
        classes={{ paper: styles.create_blog_modal_paper }}
        onClose={() => setIsShareDialogOpen(false)}
      >
        <DialogContent>
          <div className={styles.blog_share_title}>
            Share{' '}
            <a target='_blank' rel='noopener noreferrer' href={blogLink}>
              {blog?.title}
            </a>
          </div>
          <div className={styles.blog_share_container}>
            <FacebookShareButton url={blogLink}>
              <img
                src='https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/facebook.png-1684402289.png'
                alt='Facebook'
              />
            </FacebookShareButton>
            <TwitterShareButton url={blogLink}>
              <img
                src='https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/twitter.png-1684402404.png'
                alt='Twitter'
              />
            </TwitterShareButton>
            <TumblrShareButton url={blogLink}>
              <img
                src='https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/tumblr.png-1684407001.png'
                alt='Tumblr'
              />
            </TumblrShareButton>
            <LinkedinShareButton url={blogLink}>
              <img
                src='https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/linkedin.png-1684405663.png'
                alt='LinkedIn'
              />
            </LinkedinShareButton>
            <RedditShareButton url={blogLink}>
              <img
                src='https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/reddit.png-1684405635.png'
                alt='Reddit'
              />
            </RedditShareButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
