const initialState = {
    isLoading: false,
    orderCount:{},
    orderCountError: ""
};
export default function orderCount(state = initialState, action) {
    switch (action.type) {
        case "SET_ORDER_COUNT_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_ORDER_COUNT_LOADED":
            return { ...initialState, isLoading: false , orderCount: action.payload };
        case "SET_ORDER_COUNT_ERROR":
            return { ...initialState,orderCountError:action.payload};
        default:
            return state;
    }
};