import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import IntegrationsDialog from '../Dialogs/IntegrationsDialog/IntegrationsDialog'
import TabsSection from '../Library/TabsSection/TabsSection'
import CloudBasedIntegration from './CloudBasedIntegration/CloudBasedIntegration'
import OnSiteIntegration from './OnSiteIntegration/OnSiteIntegration'
import axios from '../../utils/axiosConfig'
import Loader from '../Library/Loader'
import IntegratedPos from './IntegratedPos/IntegratedPos'
import { useOutletContext } from 'react-router-dom'

const tabs = [
    {
        label: 'Cloud Based Integrations'
    },
    {
        label: 'On Site Integrations'
    }
]

const Integrations = () => {

    const { isActionsDisabled } = useOutletContext()

    const [showIntegrations, setShowIntegrations] = useState(false)
    const [platformName, setPlatformName] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [posProvider, setPosProvider] = useState()
    const [tableData, setTableData] = useState([])

    const fetchIntegrations = () => {
        setIsLoading(true)
        axios.get('/venue/import-files')
            .then((response) => {
                setPosProvider(response.data.data?.pos_provider)
                setTableData(response.data.data?.result)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchIntegrations()
    }, [])

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {posProvider ? (
                        <IntegratedPos posProvider={posProvider} tableData={tableData} />
                    ) : (
                        <TabsSection
                            tabs={tabs}
                            tabsBody={[
                                {
                                    component: (
                                        <CloudBasedIntegration
                                            setPlatformName={setPlatformName}
                                            showIntegrations={() => {
                                                setShowIntegrations(true)
                                            }}
                                        />
                                    )
                                },
                                {
                                    component: (
                                        <OnSiteIntegration
                                            setPlatformName={setPlatformName}
                                            showIntegrations={() => {
                                                setShowIntegrations(true)
                                            }}
                                        />
                                    )
                                }
                            ]}
                        />
                    )}
                </>
            )}

            <Dialog
                open={showIntegrations}
                onClose={() => {
                    setShowIntegrations(false)
                }}
            >
                <IntegrationsDialog
                    platformName={platformName}
                    onClose={() => {
                        setShowIntegrations(false)
                    }}
                />
            </Dialog>
        </div>
    )
}

export default Integrations