/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './FormsTable.module.scss';
import { Button, Dialog, DialogContent, IconButton, Pagination, outlinedInputClasses } from '@mui/material';
import Loader from '../../../Library/Loader';
import no_user_available from '../../../../assets/svgs/no_user_available.svg';
import imageBaseUrl from '../../../../utils/imageBaseUrl';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { nodeAxios } from '../../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import {  LoadingButton } from '@mui/lab';
import FormsActionMenu from './FormsActionMenu/FormsActionMenu';
import { Close, InsertLink } from '@mui/icons-material';
import clsx from 'clsx';
import GenericTextFieldWithLabel from '../../../Library/TextFieldWithLabel/GenericTextFieldWithLabel';
import GenericRadioWithLabel from '../../../Library/GenericRadioWithLabel/GenericRadioWithLabel';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import GenericTextField from '../../../Library/GenericTextField/GenericTextField';
import GenericSelectWithLabel from '../../../Library/GenericSelectWithLabel/GenericSelectWithLabel';

const getInitialsAvatar = (name) => {
  return `https://ui-avatars.com/api/?background=random&length=1&name=${name}`;
};

const cutExtraChars = (string) => {
  return String(string).length > 20
    ? String(string).substring(0, 20) + '...'
    : string;
};
const formatTime = (date, _format) => {
  return format(new Date(date), _format || 'hh:mm aa');
};
const formatDate = (date, _format) => {
  const dt = dayjs(date);
  return dt.format(_format || 'YYYY-MM-DD');
};
const getImageUrl = (image, name) => {
  return `${imageBaseUrl}/${name}/${image}`;
};

function FormsTable(props) {
  const [showFieldsDialog, setShowFieldsDialog] = useState(false);
  const [selectedForm, setSelectedForm] = useState(false);

  return (
    <div className={styles.list_grid_container}>
      <div className={styles.studentTableContainer}>
        <table className={styles.studentTable}>
          <thead>
            <th>Name</th>
            <th>Last Response</th>
            <th>Total Responses</th>
            <th></th>
            <th></th>
            <th></th>
          </thead>
          {!props.listings ? (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          ) : (
            <tbody>
              {props.listings?.data?.length === 0 ? (
                <div className={styles.no_data_available}>
                  <img
                    priority
                    src={no_user_available}
                    height={100}
                    width={100}
                    alt='No user available'
                  />
                  No Forms Yet!
                </div>
              ) : (
                props.listings?.data?.map((data, index) => (
                  <FormRow {...{ data, setSelectedForm, setShowFieldsDialog, ...props }} />
                ))
              )}
            </tbody>
          )}
        </table>
      </div>
      {props.totalPages > 1 && (
        <Pagination
          classes={{
            root: styles.schoolPaginateRootEl,
            ul: styles.schoolPaginateListEl,
          }}
          count={props.totalPages}
          page={props.pageNo}
          onChange={(event, value) => {
            props.handleChangePage(value);
          }}
        />
      )}

      <Dialog
        open={showFieldsDialog}
        onClose={() => setShowFieldsDialog(false)}
        classes={{
          container: styles.dialog_container,
          paper: styles.dialog_paper,
        }}
      >
        <DialogContent sx={{ width: '600px' }}>
          <div className='tw-font-bold tw-text-lg tw-mb-4 tw-flex tw-justify-between tw-items-center'>
            <span>{selectedForm?.title} - Preview</span>
            <IconButton onClick={() => setShowFieldsDialog(false) }>
              <Close />
            </IconButton>
          </div>
          {selectedForm?.fields?.map((fields, index) => (
            Array.isArray(fields) ? (
              <div className='tw-flex tw-gap-2 tw-items-center'>
                {fields?.map((field, index) => (
                  <>
                    {formFields(field)}
                  </>
                ))}
              </div>
              ) : (
                <>
                  {formFields(fields)}
                </>
          )))}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default FormsTable;

const FormRow = ({ data, setSelectedForm, setShowFieldsDialog, ...props }) => {

  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerateFormLink = (id) => {
    // /web-page-form/generate-link/{id}
    setIsGenerating(true)
    nodeAxios.get(`/web-builder/web-page-form/generate-link/${id}`).then(res => {
      
      const params = {
        'sort-by': 'created_at',
        'sort-by-order': 'DESC'
      };
      props.fetchListing(params)
    }).catch(err => {
      console.log('🚀OUTPUT --> err:', err);
    }).finally(() => {
      setIsGenerating(false)
    })


  }

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      // toast.success('Copied...!');
      return await navigator.clipboard.writeText(text);
    } else {
      // toast.success('Copied...!');
      return document.execCommand('copy', true, text);
    }
  }

  
  return (
    <tr>
      <td
        style={{
          width: '25%',
          paddingRight: '20px',
        }}
      >
        <a
          className={styles.studentNameContainer}
          href={`/forms/${data.id}`}
        >
          {data?.image ? (
            <img
              className={styles.studentImage}
              src={getImageUrl(data?.image, 'Forms')}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = getInitialsAvatar(
                  data?.title
                );
              }}
              style={{ objectFit: 'cover' }}
              height={40}
              width={40}
            />
          ) : (
            <img
              className={styles.tile_avatar}
              src={getInitialsAvatar(data?.title)}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = getInitialsAvatar(
                  data?.title
                );
              }}
              height={1000}
              width={1000}
            />
          )}
          <div>
            <div className={styles.item_title}>
              {data.title && data.title !== 'null'
                ? data.title
                : ''}
            </div>
            <Button
              variant='text'
              color='primary'
              className={styles.room_title}
              style={{ color: '#036EE4', fontSize: '13px', p: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedForm(data);
                setShowFieldsDialog(true);
              }}
            >
              (View form)
            </Button>
          </div>
        </a>
      </td>
      <td>
        {data?.last_response_at
          ? formatDate(data?.last_response_at, 'MM/DD/YYYY')
          : ''}
      </td>
      <td>
        <a
          className={styles.studentNameContainer}
          href={`/forms/${data.id}`}
        >
          <div className='tw-flex tw-flex-col tw-items-center tw-text-[#036EE4] tw-underline'>
            <div>
              {data?.response_count}
            </div>
          </div>
        </a>
      </td>
      <td>
        {data?.form_link ? (
          <Button
            variant='text'
            className={clsx(styles.studentNameContainer, '!tw-bg-[#EFF6FF] !tw-rounded-xl !tw-py-1 !tw-px-3')}
            onClick={() =>
              copyTextToClipboard(
                `https://forms.esplanda.com/form-preview/${data?.form_link}`
              ).then(() => toast.success('Copied to clipboard'))
            }
          >
            <InsertLink /> Copy form link
          </Button>
        ) : (
          <LoadingButton
            variant='text'
            disableElevation
            loading={isGenerating}
            loadingPosition='end'
            size='medium'
            endIcon={<div style={{ width: isGenerating ? 15 : 0 }} />}
            className={clsx(styles.studentNameContainer, '!tw-bg-[#EFF6FF] !tw-rounded-xl !tw-py-1 !tw-px-3')}
            onClick={() => handleGenerateFormLink(data?.id)}
          >
              Generate form link
          </LoadingButton>
        )}
      </td>
      <td>
        <Button
          LinkComponent={'a'}
          variant='text'
          className={clsx(styles.studentNameContainer, '!tw-underline')}
          href={`/forms/${data.id}`}
        >
            (View Responses)
        </Button>
      </td>
      <td>
        <FormsActionMenu {...{ data }} {...props} />
      </td>
    </tr>
  )
}

const formFields = (data)  => (
  <>
      {{
          0: (
              <GenericTextFieldWithLabel
                  containerStyle={{ width: '100%' }}
                  variant="outlined"
                  label={data.title}
                  placeholder={data.placeholder}
                  type={data.type}
                  name={data.title?.replaceAll(' ', '_')?.replaceAll('.', '')}
                  disabled={true}
                  fullWidth={true}
                  required={data.required}
              />
          ),
          1: (
              <GenericTextFieldWithLabel
                  containerStyle={{ width: '100%' }}
                  variant="outlined"
                  label={data.title}
                  placeholder={data.placeholder}
                  type={data.type}
                  name={data.title?.replaceAll(' ', '_')?.replaceAll('.', '')} 
                  fullWidth={true}
                  disabled={true}
                  required={data.required}
              />
          ),
          2: (
              <GenericTextFieldWithLabel
                  containerStyle={{ width: '100%' }}
                  variant="outlined"
                  label={data.title}
                  placeholder={data.placeholder}
                  type={data.type}
                  name={data.title?.replaceAll(' ', '_')?.replaceAll('.', '')} 
                  fullWidth={true}
                  disabled={true}
                  required={data.required}
              />
          ),
          3: (
              <GenericSelectWithLabel
                  containerClassname='tw-w-full'
                  containerStyle={{ width: '100%' }}
                  variant="outlined"
                  label={data.title}
                  required={data.required}
                  // checkboxes={data.options}
                  disabled={true}
                  sx={{
                      [`& .${outlinedInputClasses.notchedOutline}`]:{
                          color: '#2e2e2e !important',
                          outline: 'none !important',
                          boxShadow: 'none  !important',
                          border: '1px solid #d3d3d3 !important'
                      },
                  }}
              >
                  {/* {data.options?.map((option) => (
                      <MenuItem value={option.value} >{option.label}</MenuItem>
                  ))} */}
              </GenericSelectWithLabel>
          ),
          // 3: (
          //     <GenericCheckboxWithLabel
          //         containerStyle={{ width: '100%' }}
          //         variant="outlined"
          //         label={data.title}
          //         name={data.title?.replaceAll(' ', '_')?.replaceAll('.', '')} 
          //         required={data.required}
          //         checkboxes={data.options}
          //         disabled={true}
          //     />
          // ),
          4: (
              <GenericRadioWithLabel
                  containerStyle={{ width: '100%' }}
                  variant="outlined"
                  label={data.title}
                  name={data.title?.replaceAll(' ', '_')?.replaceAll('.', '')}
                  required={data.required}
                  radios={data.options}
                  disabled={true}
              />
          ),
          5: (
              <GenericTextFieldWithLabel
                  containerStyle={{ width: '100%' }}
                  variant="outlined"
                  label={data.title}
                  placeholder={data.placeholder}
                  type={data.type}
                  name={data.title?.replaceAll(' ', '_')?.replaceAll('.', '')} 
                  fullWidth={true}
                  disabled={true}
                  // multiline={true}
                  // rows={3}
                  required={data.required}
              />
          ),
          6: (
              <GenericTextFieldWithLabel
                  containerStyle={{ width: '100%' }}
                  variant="outlined"
                  label={data.title}
                  placeholder={data.placeholder}
                  type={data.type}
                  name={data.title?.replaceAll(' ', '_')?.replaceAll('.', '')} 
                  fullWidth={true}
                  multiline={true}
                  disabled={true}
                  rows={3}
                  required={data.required}
              />
          ),
          7: (
              <div className='tw-w-full'>
                  <div className='tw-text-base tw-font-semibold tw-mb-[5px] tw-text-[#6F6F6F] tw-whitespace-nowrap tw-mr-1'>
                      {data.title}
                      {data.required && (
                          <span className='tw-text-[#ff0000] tw-text-base tw-ml-1'>*</span>
                      )}
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker 
                          slots={{
                              textField : (rest) => (
                                  <GenericTextField
                                    variant="outlined"
                                      {...rest}
                                      disabled={true}
                                  />
                              ),
                              openPickerIcon: () => <span></span>                              
                          }}
                          sx={{ width: '100%' }}
                      />
                  </LocalizationProvider>
              </div>
          ),
          8: (
              <GenericTextFieldWithLabel
                  containerStyle={{ width: '100%' }}
                  variant="outlined"
                  label={data.title}
                  placeholder={data.placeholder}
                  type={data.type}
                  name={data.title?.replaceAll(' ', '_')?.replaceAll('.', '')} 
                  fullWidth={true}
                  required={data.required}
                  disabled={true}
              />
          ),
      }[parseInt(data.field_id)]}
  </>
)