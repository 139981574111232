import React,{useState} from "react";
import {
    Checkbox,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
} from "@mui/material";
const CitiesList = (props) => {
    const [openedCity,setOpenedCity] = useState('none');
    const handleCityOpen = (city) => {
        if(openedCity === city){
            setOpenedCity('none');
        }else{
            setOpenedCity(city);
        };
    };
    return (
        <List component="div" disablePadding>
            {props.state.citydata.map((city) => {
                return (
                    <React.Fragment>
                        <ListItem
                            style={{
                                marginLeft: "14%",
                            }}
                            button
                            onClick={handleCityOpen}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    onChange={e => e.target.checked ? props.setCity(city.City,props.state.State) : props.removeCity(city.City)}
                                    checked={props.filters.cities.indexOf(city.City) !== -1}
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": city.City,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText primary={city.City} />
                        </ListItem>
                        <Collapse
                            in={openedCity === city.City}
                            timeout="auto"
                            unmountOnExit
                        ></Collapse>
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default CitiesList;