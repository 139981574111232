import axios from "axios";
import { baseurl, bizBaseurl } from '../../env';
import ConstForCheck from "../../components/helpers/const";

const Const = {
  BASE_URL: process.env.NODE_ENV !== "production" ?
    "https://staging-apiv1.esplanda.com/" : (ConstForCheck.isOurDesiStore ? "https://production-api.ourdesistore.com/" : "https://api.ourliquorstore.com/"),
  GOOGLE_MAP_KEY: "AIzaSyDAVj9EIbpwwynljEQX-R8NyxngpUf7PG4",
  STRIPE_KEY:
    process.env.NODE_ENV !== "production"
      ? "pk_test_W3bfZ89mb2gc5stAWRLetiSF00EbyI4lGc"
      : "pk_live_QsIzkxFIeYWvIimbeaL0iGtm006LbwslCL",
};

export function getCategoriesMenu(venueId) {
  return async (dispatch, getState) => {
    const state = getState()
    let { data: { data: { category } } } = await axios.get(`https://apiv1.esplanda.com/v2/category/menu`, {
      params: {
        'refresh-cache': 1,
        venue_id: state?.user?.data?.venue_id_integer
      }
    });
    dispatch({
      type: "SET_CATEGORY_MENU",
      payload: category
    });
  };
}

export function search(params, isLoading) {
  return (dispatch, getState) => {
    if (isLoading) {
      dispatch({
        type: "SET_SEARCH_LOADING",
      });
    }

    return axios.get(`${bizBaseurl}/product/filter/v1?${params}`)
      .then((response) => response.json())
      .then((result) => {
        dispatch({
          type: "SET_SEARCH",
          payload: result.data,
        });
        return {
          type: "success",
          data: result.data,
        };
      })
      .catch(() => dispatch({ type: "SET_SEARCH_ERROR" }));

    // return axios
    //   .get(Const.BASE_URL + `product/filter-new?${params}`, axiosOptions(""))
    //   .then((response) => {
    //     dispatch({
    //       type: "SET_SEARCH",
    //       payload: response.data.data,
    //     });
    //     return {
    //       type: "success",
    //       data: response.data.data,
    //     };
    //   })
    //   .catch(() => dispatch({ type: "SET_SEARCH_ERROR" }));
  };
}

export function getCategoryChildList(categoryId) {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_CATEGORY_LIST_LOADING",
    });

    return axios
      .get(`${baseurl}/category/child-list/${categoryId}`)
      .then((response) => {
        dispatch({
          type: "SET_CATEGORY_CHILD_LIST",
          payload: response.data.data,
        });
        return {
          type: "success",
        };
      })
      .catch(() => dispatch({ type: "SET_CATEGORY_CHILD_LIST_ERROR" }));
  };
}

export function countryApi() {
  return (dispatch, getState) => {
    axios
      .get(`${baseurl}/venue/get-country?type=c`)
      .then((response) => {
        dispatch({
          type: "SET_COUNTRY",
          payload: response.data.data,
        });
      })
      .catch(() => dispatch({ type: "SET_COUNTRY_ERROR" }));
  };
}

export function getVenuesByType(venueType, page, query) {
  return (dispatch, getState) => {
    return axios
      .get(`${baseurl}/venue/by-type?type=${venueType}&page=${page}&q=${query ? query : ""
        }`)
      .then((response) => {
        dispatch({
          type: "SET_BREWERY",
          payload: response.data.data.result,
        });
        return response.data.data.result;
      })
      .catch(() => {
        dispatch({
          type: "SET_BREWERY_ERROR",
        });

        return [];
      });
  };
}

export function setLocationData(data) {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOCATION_DATA",
      payload: data,
    });
  };
}

export function setFilterData(data) {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_FILTER_DATA",
      payload: data,
    });
  };
}
