import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from '../ProductCard/ProductCard';
import styles from "./ProductCardSlider.module.scss";
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Loader from '../Library/Loader';

const topSellersBreakpoint = {
    2700: {
        slidesPerView: 6.6,
    },
    2500: {
        slidesPerView: 6.3,
    },
    2300: {
        slidesPerView: 6,
    },
    2100: {
        slidesPerView: 5.5,
    },
    1900: {
        slidesPerView: 5.2,
    },
    1800: {
        slidesPerView: 4.9,
    },
    1630: {
        slidesPerView: 4.5,
    },
    1530: {
        slidesPerView: 4.2,
    },
    1440: {
        slidesPerView: 3.9,
    },
    1400: {
        slidesPerView: 6.5,
    },
    1280: {
        slidesPerView: 6.2,
    },
    1200: {
        slidesPerView: 5.7,
    },
    1074: {
        slidesPerView: 5.2,
    },
    991: {
        slidesPerView: 4.8,
    },
    950: {
        slidesPerView: 4.5,
    },
    860: {
        slidesPerView: 4.2,
    },
    769: {
        slidesPerView: 3.9,
    },
    700: {
        slidesPerView: 4.6,
    },
    660: {
        slidesPerView: 4.5,
    },
    610: {
        slidesPerView: 4.3,
    },
    570: {
        slidesPerView: 4.2,
    },
    520: {
        slidesPerView: 3.9,
    },
    480: {
        slidesPerView: 3.2,
    },
    440: {
        slidesPerView: 3,
    },
    370: {
        slidesPerView: 2.7,
    },
    320: {
        slidesPerView: 2.2,
    }
}

const topSellersBreakpointSmall = {
    320: {
        slidesPerView: 3.6
    },
}

const ProductCardSlider = (props) => {
    return (
        <div
            className={styles.product_card_slider}
        >
            <div
                className={styles.product_card_container}
            >
                <Swiper
                    className={styles.top_seller_swiper}
                    navigation={true}
                    loop={false}
                    lazy={true}
                    pagination={false}
                    observeParents={true}
                    observer={true}
                    parallax={true}
                    breakpoints={props.isBigPreview ? topSellersBreakpoint : topSellersBreakpointSmall}
                    // slidesPerView={props.slide ? props.slide : 3.6}
                    slidesPerGroup={1}
                    {...(props.isVenueSelected && { onSlideChange: () => props.handleLoadMore(props.itemId) })}
                >
                    {props.isLoading ? (
                        <div
                            style={{
                                minWidth: 300,
                                padding: 20
                            }}
                        >
                            <Loader />
                        </div>
                    ) : props.products?.length === 0 ? (
                        <div
                            className={styles.no_product}
                        >
                            <div
                                className={styles.no_product_text}
                            >
                                {props.isEvent ? "No Events" : "No Products"}
                            </div>
                        </div>
                    ) : props.products?.map((data, index) => {

                        return (
                            <SwiperSlide
                                key={`product_card_${data.product_id}_${index}`}
                            >
                                <ProductCard
                                    isBigPreview={props.isBigPreview}
                                    // isFavourite={data.product_id in isFavAdded ? isFavAdded[data.product_id] : Boolean(data.is_favorite)}
                                    productImage={props.isEvent ? data.image : (data.image_file ? data.image_file : `${props.baseImageLink}${data.product_id ? data.product_id : data.ProductId}/${data.logo ? data.logo : data.ProductLogo}`)}
                                    // productImage={`${props.baseImageLink}${data.product_id}/${data.logo}`}
                                    // catImage={`${categoryMenuBaseImageLink}/${data.category_logo}`}
                                    // noImage={!Boolean(data.category_logo) || !Boolean(data.logo)}
                                    // noLogo={!Boolean(data.logo)}
                                    name={props.isEvent ? data.title : (data.Name ? data.Name : data.name)}
                                    format={data.format}
                                    venueName={props.isEvent ? data.event_date : data.venue_name}
                                    productPrice={data.vPrice ? `$ ${data.vPrice}` : "Out of Stock"}
                                    avgRating={data.avg_rating}
                                    handleCardClick={(event) => { }}
                                    handleFavourites={(event) => {
                                    }}
                                    checkinView={(event) => {
                                    }}
                                    isEvent={props.isEvent}
                                />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}

export default React.memo(ProductCardSlider)