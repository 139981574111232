import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import Customize from "../../../assets/feature.png";
import listicon from "../../../assets/list-text.png";
import viewbannersimg from "../../../assets/view-banners.png";
import editbannergroupimg from "../../../assets/edit-banner-group.png";
import routePaths from '../../../config/route-paths';
const EditExistingBannerGroup = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={Customize} />
            </div>
            Customize Your Website & App              
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AddNewProductGroups}>How to Add New Product Groups</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropProductGroupInterface}>Drag & Drop Product Group Interface</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingProductGroup}>Edit Existing Product Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropBanners}>Drag & Drop Banners</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateNewBannerGroup}>Create New Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingBannerGroup} className={cn('selected')}>Edit Existing Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateCustomStorePage}>Create Custom Store Page</Link></li>
</ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Edit Existing Product Group
         </Typography>
       
         <p>
            
         <ol>
            <li>Go to the ‘<strong>Customize Homepage</strong>’ section of the admin website.</li>

            <span className={cn('addnewproductsimg')}>
    <img src={viewbannersimg} />
    </span>

            <li>Click on ‘<strong>View Banners</strong>’ below the ‘<strong>Search Banners</strong>’ search bar.</li>

            <span className={cn('addnewproductsimg')}>
    <img src={editbannergroupimg} />
    </span>
            

    <li>Find the banner group you would like to edit. Below ‘<strong>Modify Banners Group</strong>’, click on the red pencil icon to edit.</li>
  

    <li>You can change the name of the banner group as well as the content within. You can add and delete banners, or edit existing banners.</li>

    <li>When you are finished editing your banner group, click the ‘<strong>Save</strong>’ button in the bottom-right.</li>    
   

</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default EditExistingBannerGroup