import axios from "../../utils/axiosConfig";
import Const from "../../components/helpers/const";
import { getVenueInfo } from "./venueInfoActions";

export function getVenueList(params) {
  return (dispatch) => {
    console.log(localStorage);
    if (localStorage.userToken) {
      let url = `/venue/list?q=${params}`;
      dispatch({ type: "SET_VENUE_LIST_LOADING" });
      return axios
        .get(url)
        .then((response) => {
          dispatch({
            type: "SET_VENUE_LIST_LOADED",
            payload: response.data,
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: "SET_VENUE_LIST_ERROR",
            payload: error.response,
          });
          return Promise.resolve();
        });
    }
  };
}

export function postCreateVenueDetails(params) {
  return (dispatch) => {

    dispatch({ type: "SET_CREATE_VENUE_DETAILS_LOADING" });
    return axios
      .post(`/venue/create`, params)
      .then((response) => {
        dispatch({
          type: "SET_CREATE_VENUE_DETAILS",
          payload: response,
        });
        return {
          error: false,
          status: "ok"
        };
      })
      .catch((error) => dispatch({
        error: true, type: "SET_CREATE_VENUE_DETAILS_ERROR", message: error.response.data.message
      }));

  };
}

export const postVenueExternalUrl = (data) => {
  
  return (dispatch) => {
    dispatch({
      type: "POST_VENUE_EXTERNAL_URL_REQUEST",
    });
    axios.post('/venue/external-url', {
      'external_url': data
    })
      .then((res) => {
        dispatch(getVenueInfo())
        dispatch({
          type: "POST_VENUE_EXTERNAL_URL_SUCCESS",
        });
      })
      .catch((err) => {

      })
  }
}