import React, { useEffect, useRef } from 'react'
import { Line } from 'react-chartjs-2';

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
    // scales: {
    //     yAxes: [
    //       {
    //         ticks: {
    //           beginAtZero: true
    //         }
    //       }
    //     ]
    //   }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
    labels,
    datasets: [
        {
            label: '',
            data: labels.map(() => Math.random(100)),
            // borderColor: 'rgb(255, 99, 132)',
            // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        // {
        //     label: 'Dataset 2',
        //     data: labels.map(() => Math.random(100)),
        //     borderColor: 'rgb(53, 162, 235)',
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
        // },
    ],
};



const LineGraph = (props) => {

    const ref = useRef()

    // useEffect(() => {
    //     setInterval(() => {
    //         console.log('resize', ref.current)
    //         const chart = ref.current;
    //         chart._doResize()
    //     }, 10000);
    // }, [])


    return (
        <div>
            <Line options={options} data={props.data ? props.data : data} ref={ref} />
        </div>
    )
}

export default LineGraph