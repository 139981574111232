import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Search } from '@mui/icons-material'
import { CircularProgress, Collapse, MenuItem, outlinedInputClasses, RadioGroup, Select } from '@mui/material'
import InputField from '../Library/InputField/InputField'
import styles from './SearchProduct.module.scss'
import axios from '../../utils/axiosConfig'
import { InView } from 'react-intersection-observer'
import ProductChip from './ProductChip/ProductChip'

const SearchProduct = (props) => {

    const [productQuery, setProductQuery] = useState('')
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState()
    const [searchIsLoading, setSearchIsLoading] = useState(false)
    const [isSearchOpen, setIsSearchOpen] = useState(true)
    const [canShowNoProducts, setCanShowNoProducts] = useState(false)

    const fetchMasterList = (query, sortbyOrder, page = 1, searchChange) => {
        setSearchIsLoading(true)
        axios.get(props.isVenueList ? '/product/my-product-list' : '/product/master-list', {
            params: {
                limit: 20,
                q: query,
                sort_by: 'created_at',
                sort_by_order: 'DESC',
                page: page
            }
        })
            .then((response) => {
                setIsSearchOpen(true)
                if (searchChange) {
                    setProducts(props.isVenueList ? response.data.data.result : response.data.data.list)
                } else {
                    setProducts((prevState) => props.isVenueList ? [...prevState, ...response.data.data.result] : [...prevState, ...response.data.data.list])
                }

                if (props.isVenueList ? response.data.data.result.length : response.data.data.list.length) {
                    setCanShowNoProducts(false)
                } else {
                    setCanShowNoProducts(true)
                }
                setPagination(response.data.data.pagination)
            })
            .finally(() => {
                setSearchIsLoading(false)
            })
    }

    useEffect(() => {
        fetchMasterList()
    }, [])


    const debounceSearchProduct = useCallback(debounce(fetchMasterList, 1000), [])

    return (
        <div>
            <div>
                <InputField
                    style={{
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 32%)'
                    }}
                    placeholder="Search Product"
                    value={productQuery}
                    onChange={(event) => {
                        setProductQuery(event.target.value)
                        debounceSearchProduct(event.target.value, undefined, 1, true)
                    }}
                    showIconButton={true}
                    endIcon={searchIsLoading ? (
                        <CircularProgress
                            style={{
                                color: '#ef2d2c'
                            }}
                            size={14}
                        />
                    ) : (
                        <Search
                            style={{
                                color: '#ef2d2c'
                            }}
                        />
                    )}
                />

                {/* <Select
                    value={dateValue}
                    onChange={(event)=>{
                        fetchMasterList(productQuery, event.target.value, pagination.current_page)
                    }}
                    size="small"
                    sx={{
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            border: 'none'
                        }
                    }}
                >
                    {[{value: 'DESC', label: 'New Arrivals'}].map((data) => (
                        <MenuItem
                            value={data.value}
                        >
                            {data.label}
                        </MenuItem>
                    ))}
                </Select> */}
            </div>
            {(!Boolean(products.length) && (Boolean(productQuery)) && (canShowNoProducts)) && (
                <div
                    className={styles.no_products_container}
                >
                    No Products Found
                </div>
            )}

            {/* {Boolean(products.length) && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <small
                            style={{
                                paddingTop: 5,
                                paddingRight: 5,
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={() => {
                                setIsSearchOpen((prevState) => !prevState)
                            }}
                        >
                            {isSearchOpen ? "-Minimize" : "+Expand"}
                        </small>
                    </div>
                )} */}

            <Collapse
                in={true}
            >
                <div
                    className={styles.products_container}
                >
                    {products.map((data) => (
                        <ProductChip
                            onDoubleClick={props.onDoubleClick}
                            key={data.ProductId}
                            style={{
                                cursor: 'pointer'
                            }}
                            image={data.image_file || data.image}
                            price={data.Price}
                            name={data.Name || `${data.ProductName} - ${data.ProducerName}`}
                            value={data.ProductId}
                            isRadio={false}
                            wholeChipClick={true}
                            selectedProduct={props.selectedProduct}
                            onClick={() => {
                                props.onProductChipClick(data)
                                // setIsSearchOpen(false)
                            }}
                        />
                    ))}

                    {Boolean(products.length) && (
                        <InView
                            style={{
                                display: (pagination.last_page === pagination.current_page) ? 'none' : 'block'
                            }}
                            as="div"
                            triggerOnce={false}
                            onChange={(inView, entry) => {
                                if (inView) {
                                    if ((pagination.current_page + 1) <= pagination.last_page)
                                        fetchMasterList(productQuery, undefined, pagination.current_page + 1)
                                }
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <CircularProgress
                                    size={26}
                                />
                            </div>
                        </InView>
                    )}
                </div>
            </Collapse>
        </div>
    )
}

export default SearchProduct