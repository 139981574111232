import React, { useCallback, useEffect, useState } from 'react'
import { Button, Dialog, Pagination, Paper, useMediaQuery } from '@mui/material'

import { debounce } from 'lodash'
import axios, { nodeAxios } from '../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import Loader from '../../../Library/Loader'
import { useConfirm } from 'material-ui-confirm'
import AddDietaryAttributesDialog from './AddDietaryAttributesDialog/AddDietaryAttributesDialog'
import { getImage3 } from '../../../../utils/helpers'
import DietaryAttributesHeader from './DietaryAttributesHeader/DietaryAttributesHeader'
import DietaryAttributesTable from './DietaryAttributesTable/DietaryAttributesTable'
import MobileItems from './MobileItems/MobileItems'
import { Add } from '@mui/icons-material'



const DietaryAttributes = ({ isActionDisabled }) => {

    const confirm = useConfirm()
    const isMobile = useMediaQuery('(max-width:1000px)')

    const [searchStr, setSearchStr] = useState("");
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState({})
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [editModifierObj, setEditModifierObj] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [editObj, setEditObj] = useState({})
    const [isListLoading, setIsListLoading] = useState(false)
    const [sort, setSort] = useState({ sort_by: 'created_at', sort_by_order: 'DESC' })
    const [catId, setCatId] = useState(0)

    const fetchMyList = (page, queryStr) => {
        setIsListLoading(true)
        axios.get('/product-property', {
            params: {
                page: page || 1,
                q: queryStr || searchStr,
                sort_by: sort.sort_by,
                sort_by_order: sort.sort_by_order,
                category_id: catId || undefined
            }
        })
            .then((res) => {
                setProducts(res.data?.data?.results)
                setPagination(res.data?.data?.pagination)
            })
            .finally(() => {
                setIsSearchLoading(false)
                setIsListLoading(false)
            })
    }


    const handleSearch = (searchStr) => {
        try {
            setIsSearchLoading(true)
            fetchMyList(pagination.current_page, searchStr)
        } catch (err) {
            toast.error('Error occured while fetching inventories, please try again.')
        };
    };

    const debounceSearch = useCallback(debounce(handleSearch, 1000), [])

    useEffect(() => {
        fetchMyList()
    }, [])

    useEffect(() => {
        fetchMyList(pagination.current_page)
    }, [sort, catId])


    const handleDelete = (id, name) => {
        confirm({
            title: 'Are you sure you want to delete product',
        })
            .then(() => {
                axios.delete(`/product-property/delete/${id}`)
                    .then(() => {
                        nodeAxios.post('/change-log/create', { what: `${name} is deleted` })
                            .then(() => {

                            })
                            .catch((error) => {
                                console.log('🚀OUTPUT --> error:', error);
                            });
                        fetchMyList()
                        toast.success('Product deleted')
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
            })
    }

    const handleEdit2 = (id) => {
        axios.get(`/product-property/get/${id}`)
            .then((res) => {
                setEditObj(res.data?.data?.result)
                setIsEdit(true)
                setShowAddDialog(true)
            })
    }

    return (
        <div className="tw-py-[10px] lg:tw-py-[25px] tw-flex tw-flex-col tw-gap-5">
            <DietaryAttributesHeader {...{ searchStr, setSearchStr, debounceSearch, isSearchLoading, setShowAddDialog, isMobile, isActionDisabled }} />


            {isListLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                    {isMobile ?
                        <MobileItems {...{ products, handleEdit2, isActionDisabled }} /> :
                        <DietaryAttributesTable {...{ products, handleEdit2, handleDelete, isActionDisabled }} />
                    }


                    {!Boolean(products?.length) && (
                        <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-p-8 tw-max-w-[360px] tw-mx-auto tw-mt-12'>
                            <p className='tw-font-bold tw-text-lg '>Add New Attribute</p>
                            <p className='tw-text-[#8B8B8B] tw-text-center tw-text-sm my-2'>Create and assign dietary attributes to items and let customer know about it.</p>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: 20
                                }}
                                disabled={isActionDisabled}
                                onClick={() => {
                                    setShowAddDialog(true)
                                }}
                            >
                                New Attribute
                            </Button>
                        </div>
                    )}


                    {(pagination.last_page > 1) && (
                        <div
                            style={{
                                padding: '10px 0'
                            }}
                        >
                            <Pagination
                                page={pagination.current_page}
                                count={pagination.last_page}
                                onChange={(e, page) => fetchMyList(page)}
                            />
                        </div>
                    )}
                </Paper>
            )}

            <Dialog
                open={showAddDialog}
                onClose={() => {
                    setShowAddDialog(false)
                    fetchMyList()
                    setIsEdit(false)
                    setEditObj({})
                }}
                PaperProps={{
                    style: {
                        width: '100%'
                    }
                }}
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <AddDietaryAttributesDialog
                    editObj={editObj}
                    isEdit={isEdit}
                    // handleDelete={handleDelete}
                    onClose={() => {
                        setShowAddDialog(false)
                        fetchMyList()
                        setIsEdit(false)
                        setEditObj({})
                    }}
                />
            </Dialog>
        </div>
    )
}

export default DietaryAttributes