import { Box, Divider, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { useId } from 'react';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import TabPanel from '../TabPanel/TabPanel';
import styles from './TabsSection.module.scss'
import InstagramColorIcon from '../../../assets/svgs/instagram-color-icon.svg'

const TabsSection = (props) => {

    const id = useId()
    const [value, setValue] = useState(props.defaultTab || 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Box>
                <Tabs
                    style={{
                        minHeight: 'unset'
                    }}
                    classes={{
                        indicator: (props.type === 'secondary') ? styles.tab_indicator_2 : styles.tab_indicator
                    }}
                    value={props.tabValue ? props.tabValue : value}
                    onChange={props.onChange ? props.onChange : handleChange}
                    scrollButtons={props.scrollButtons}
                    variant={props.variant}
                >
                    {props.tabs.map((data, index) => (
                        <Tab
                            value={data.value}
                            key={data?.label + index}
                            classes={{
                                root: (props.type === 'secondary') ? styles.tabs_root_2 : styles.tabs_root,
                                selected: (props.type === 'secondary') ? styles.tab_selected_2 : styles.tab_selected
                            }}
                            label={(props.type === 'secondary') ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 6,
                                        borderRight: '1px solid #C5C5C5',
                                        padding: '0 10px'
                                    }}
                                >
                                    {data.isFacebook && (
                                        <FaFacebook
                                            color={(props.tabValue == data.value) ? "#4267B2" : "#C5C5C5"}
                                            fontSize={20}
                                        />
                                    )}

                                    {data.isInstagram && (
                                        <img
                                            style={(props.tabValue == data.value) ? {} : { filter: 'grayscale(1)' }}
                                            src={InstagramColorIcon}
                                            height={20}
                                        />
                                    )}

                                    {data.isTwitter && (
                                        <FaTwitter
                                            color={(props.tabValue == data.value) ? "#4267B2" : "#C5C5C5"}
                                            fontSize={20}
                                        />
                                    )}
                                    <div
                                        style={(props.tabValue == data.value) ? {} : { color: "#C5C5C5" }}
                                    >
                                        {data?.label}
                                    </div>
                                </div>
                            ) : data?.label}
                            onClick={(props.type === 'secondary') ? () => props.onTabSelect(data) : data?.onClick}
                        />
                    ))}
                </Tabs>
            </Box>

            {props.tabsBody && (
                <div
                    style={{
                        minHeight: props.panelHeight || 350,
                        padding: '8px',
                        border: (props.type === 'secondary') ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
                        minWidth: props.tabPanelMinWidth,
                        backgroundColor: 'white'
                        // width: '100%'
                    }}
                >
                    {props.tabsBody.map((data, index) => (
                        <TabPanel
                            key={id + index}
                            value={props.tabValue ? props.tabValue : value}
                            index={index}
                        >
                            {data.component}
                        </TabPanel>
                    ))}
                </div>
            )}
        </div>
    )
}

export default TabsSection