import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import Doordash from '../../../../assets/images/doordash.svg'
import DoordashDiable from '../../../../assets/images/doordashDelivery.svg'

import PickUpDeliverySettings from './PickUpDeliverySettings/PickUpDeliverySettings'
import axios, { nodeAxios } from '../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericLoadingButton from '../../../Library/GenericLoadingButton/GenericLoadingButton'
import Loader from '../../../Library/Loader'
import { Button, Dialog, IconButton } from '@mui/material'
import { DialogContent } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';
const OrderProperties = ({ isSmall }) => {

    const { venueDetails, pickupVenueHours, openVenueHours, fetchStoreInfo, removeImage, removeFavicon, isLoading, isActionsDisabled, deliveryZone, deliveryVenueHours, pickupSettings, deliverySettings } = useOutletContext()
    console.log('🚀OUTPUT --> venueDetails:', venueDetails);
    const [isEditMode, setIsEditMode] = useState(false)
    const [showAddHoliday, setShowAddHoliday] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imageFile, setImageFile] = useState()
    const [faviconFile, setFaviconFile] = useState()
    const [noLogo, setNoLogo] = useState(!Boolean(venueDetails.image))
    const [holidays, setHolidays] = useState([])
    const [venueSubTypes, setVenueSubTypes] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editObj, setEditObj] = useState({})
    const [showDiloag, setShowDiloag] = useState(false)
    const [isDoorDashEnabled, setIsDoorDashEnabled] = useState(false)



    useEffect(() => {
        setNoLogo(!Boolean(venueDetails.image))

    }, [venueDetails])



    const deliveryHr = Boolean(deliverySettings?.venue_hours?.length) ? deliverySettings?.venue_hours : [{
        venuetimings: 'delivery',
        dayname: 'Sunday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'delivery',
        dayname: 'Monday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'delivery',
        dayname: 'Tuesday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'delivery',
        dayname: 'Wednesday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'delivery',
        dayname: 'Thursday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'delivery',
        dayname: 'Friday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'delivery',
        dayname: 'Saturday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    }]

    const pickupHr = Boolean(pickupSettings?.venue_hours?.length) ? pickupSettings.venue_hours : [{
        venuetimings: 'pickup',
        dayname: 'Sunday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'pickup',
        dayname: 'Monday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'pickup',
        dayname: 'Tuesday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'pickup',
        dayname: 'Wednesday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'pickup',
        dayname: 'Thursday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'pickup',
        dayname: 'Friday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    },
    {
        venuetimings: 'pickup',
        dayname: 'Saturday',
        opentimehours: 12,
        opentimemins: 0,
        closedtimehours: 18,
        closedtimemins: 0,
        isclosed: '1',
        time_interval: 60
    }]


    const formik = useFormik({
        initialValues: {
            ...venueDetails,
            venue_delivery_zone: deliveryZone,
            venue_hours: [...deliveryHr, ...pickupHr],
            //is_offer_pickup_fee: venueDetails.pickupfees > 0 ? 1 : 0,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("Updated values", values);
            setLoading(true)


            axios.post('/venue/setting', values)
                .then(() => {
                    // if (tabValue === 7) {
                    //     toast.success('Please make sure to change the tax rate on each category individually')
                    // } else {
                    toast.success('Saved Successfully')
                    //}
                })
                .catch((err) => {
                    toast.error(err.response?.data?.message)
                })
                .finally(() => {
                    setLoading(false)
                    fetchStoreInfo()
                })

            // let formData = new FormData();
            // if (imageFile) {
            //     formData.append('image', imageFile);
            // }
            // if (faviconFile) {
            //     formData.append('favicon_image', faviconFile);
            // }
            // formData.append('no_logo', noLogo);

            // axios.post('/venue/steps/branding', formData)
            //     .then(() => {
            //     }).finally(() => {
            //         fetchStoreInfo()
            //     })
        }
    })

    useEffect(() => {
        checkDoorDash();

    }, [])

    const checkDoorDash = () => {
        nodeAxios.get('/doordash/check-doordash')
            .then((res) => {
                setIsDoorDashEnabled(res.data.data)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }
    const handleDoordashEnable = () => {
        nodeAxios.post('/doordash/enable-doordash')
            .then(() => {
                toast.success('Doordash Enabled')
                setIsDoorDashEnabled(true)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }
    const handleDoordashDisable = () => {
        nodeAxios.post('/doordash/disable-doordash')
            .then(() => {
                toast.success('Doordash Disabled')
                setIsDoorDashEnabled(false)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }


    return isLoading ? (<Loader />) : (
        <div className="tw-mt-4">
            {/* <h2 className="tw-font-semibold tw-text-2xl tw-my-4">Pickup Settings</h2> */}

            <div className="tw-flex tw-justify-between tw-flex-col">

                <div >
                    <PickUpDeliverySettings
                        title={'Pickup Settings'}
                        type={'Pickup'}
                        venueDetails={venueDetails}
                        pickupSettings={pickupSettings}
                        deliverySettings={deliverySettings}
                        formik={formik}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        loading={loading}
                        isActionsDisabled={isActionsDisabled}
                        isSmall={isSmall}
                        venueSubTypes={venueSubTypes}
                        setEditObj={setEditObj}
                    />
                </div>
                <div className='tw-flex tw-h-[1px] tw-w-full tw-bg-gray tw-my-6'></div>
                <div >
                    <PickUpDeliverySettings
                        title={'Delivery Settings'}
                        type={'Delivery'}
                        venueDetails={venueDetails}
                        pickupSettings={pickupSettings}
                        deliverySettings={deliverySettings}
                        formik={formik}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        loading={loading}
                        isActionsDisabled={isActionsDisabled}
                        isSmall={isSmall}
                        venueSubTypes={venueSubTypes}
                        setEditObj={setEditObj}
                    />
                </div>
            </div>
            <div className='tw-flex tw-relative tw-mt-3'>
                {/* <img
                    className={'tw-w-full md:tw-h-[250px]  lg:tw-h-[350px]  tw-h-[100px] tw-mt-8'}
                    src={isDoorDashEnabled ? DoordashDiable : Doordash}
                    onClick={() => {
                        setShowDiloag(true)
                    }}

                    objectFit='cover'
                /> */}

                <div class="tw-w-full tw-mx-auto tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">

                    <div class="tw-flex tw-items-center tw-mb-4">
                        <img src={Doordash} alt="DoorDash Logo" class="h-10" />
                    </div>


                    <div class="tw-text-gray-800">
                        <p class="tw-mb-4">
                            Even if you enable DoorDash, you can still choose to deliver yourself at the time you accept the order, or you can call a DoorDash driver at that time.
                        </p>
                        <p class="tw-mb-4">
                            If you choose to call DoorDash, DoorDash charges you for each delivery made using this feature at the time of the delivery request. The delivery fee is dynamically calculated using the distance between pickup and dropoff. Deliveries within 5 miles incur a base rate of $7.00. For deliveries beyond 5 miles, the fee is an additional $0.75 per mile up to a maximum of 15 miles.
                        </p>
                        <p class="tw-mb-4">
                            100% of the tip amount will be passed to DoorDash so that they can provide the tip to the Dasher.
                        </p>
                    </div>



                    <Button
                        className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs'
                        onClick={() => { setShowDiloag(true); }}
                    >
                        {isDoorDashEnabled ? 'Disable Third Party Delivery' : 'Enable Third Party Delivery'}
                    </Button>
                </div>

                {isDoorDashEnabled && (<div
                    className='tw-text-[#00BF36] tw-absolute tw-top-10 tw-right-2 tw-font-semibold'

                >
                    Connected
                </div>)}

            </div>
            <Dialog
                open={showDiloag}
                onClose={() => {
                    setShowDiloag(false)
                }}
                maxWidth="lg"
            >


                <DialogContent>
                    <div class="tw-bg-white tw-p-2 tw-rounded-lg tw-w-full tw-max-w-lg">
                        <div class="tw-flex tw-justify-between ">
                            {isDoorDashEnabled ? <h2 class="tw-text-xl tw-font-semibold">Disable DoorDash Delivery</h2> : <h2 class="tw-text-xl tw-font-semibold">Enable DoorDash Delivery</h2>}

                            <Button
                                className='!tw-text-black'
                                onClick={() => { setShowDiloag(false); }}
                            >
                                <CancelIcon />
                            </Button>
                        </div>
                        {isDoorDashEnabled ? <p class="tw-mt-4 text-gray-700">Are you sure you want to disable DoorDash delivery, you can still choose to deliver yourself at the time you accept the order and cannot call DoorDash driver at that time.</p> : <p class="tw-mt-4 text-gray-700">Are you sure you want to enable DoorDash delivery, you can still choose to deliver yourself at the time you accept the order or you can call DoorDash driver at that time.</p>}


                        <div class="tw-flex tw-justify-end tw-mt-4 tw-gap-4">
                            <Button
                                className='!tw-bg-[#8b8b8b] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black    !tw-text-xs tw-mt-4'
                                onClick={() => { setShowDiloag(false); }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='!tw-bg-[#050505] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-white    !tw-text-xs tw-mt-4'
                                onClick={() => {
                                    if (isDoorDashEnabled) {
                                        handleDoordashDisable();
                                    } else {
                                        handleDoordashEnable();
                                    }
                                    setShowDiloag(false);

                                }}
                            >
                                {isDoorDashEnabled ? 'Yes, Disable' : 'Yes, Enable'}
                            </Button>
                        </div>


                    </div>
                </DialogContent>
            </Dialog>

        </div>
    )
}

export default OrderProperties