/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './FormsListFilters.module.scss';
import { Button, FormControl, InputLabel, MenuItem } from '@mui/material';
import {
  Add as AddIcon,
  InfoRounded as InfoRoundedIcon,
} from '@mui/icons-material';
import GenericSelect from '../../../Library/GenericSelect/GenericSelect';
import GenericSearchField from '../../../Library/GenericSearchField/GenericSearchField';
import GenericSwitch from '../../../Library/GenericSwitch/GenericSwitch';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { KeyboardArrowDown } from '@mui/icons-material';
import clsx from 'clsx';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  // borderBottom: `1px solid #D3D3D3`,
  background: 'transparent !important',
  '&:before': {
    display: 'none',
  },
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
}));

const AccordionSummary = styled(({ disabled, ...props }) => (
  <MuiAccordionSummary
    expandIcon={!disabled && <KeyboardArrowDown sx={{ fontSize: '1.5rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#EFEFEF',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  opacity: '1 !important',
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    flexGrow: 'inherit',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  borderTop: 'none',
  // background: '#EFEFEF',
  borderBottomRightRadius: '10px',
  borderBottomLeftRadius: '10px',
  padding: '8px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

function FormsListFilters(props) {
  const [expanded, setExpanded] = useState(); //'panel0'

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function showClearFilters() {
    return (
      props.sortBy !== 'created_at' ||
      props.type_id !== 'all' ||
      props.searchQuery !== ''
    );
  }

  return (
    <div className={clsx(styles.school_list_filters_container)}>
      <div className={styles.school_list_filters}>
        <SortByFilter {...props} />
        <SearchContainer {...props} />
        {/* <SortByFormType {...props} />  */}

        {showClearFilters() && (
          <Button
            variant='text'
            color='primary'
            sx={{ fontWeight: 'semibold' }}
            onClick={props.handleClearAllFilters}
          >
            Clear all filters
          </Button>
        )}
      </div>
      <div className={styles.school_mobile_list_filters}>
        <SearchContainer {...props} />
        <Accordion
          expanded={expanded === `panel`}
          onChange={handleChange(`panel`)}
        >
          <AccordionSummary
            disabled={props?.adminReportPage === 'signup-summary'}
            aria-controls={`paneld-content`}
            id={`paneld-header`}
            sx={{
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
              borderBottomRightRadius: !(expanded === `panel`) && '10px',
              borderBottomLeftRadius: !(expanded === `panel`) && '10px',
              color: '#595959',
              fontWeight: '600',
              fontSize: '14px',
              justifyContent: 'flex-end',
            }}
          >
            {expanded === `panel` ? 'Close' : 'Open'} Filters
          </AccordionSummary>
          <AccordionDetails>
            <SortByFilter {...props} />
            {/* <SortByFormType {...props} /> */}

            <div className={styles.row_btwn}>
              {showClearFilters() && (
                <Button
                  variant='text'
                  color='primary'
                  sx={{ fontWeight: 'semibold' }}
                  onClick={props.handleClearAllFilters}
                >
                  Clear all filters
                </Button>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default FormsListFilters;

const SortByFilter = ({ sortBy, handleChangeSort }) => {
  return (
    <FormControl
      sx={{
        // m: 1,
        minWidth: 120,
        maxWidth: 125,
        '@media (max-width: 600px)': {
          maxWidth: '100%',
          width: '100% !important',
        },
      }}
      size='small'
    >
      <InputLabel focused={false}>Sort By</InputLabel>
      <GenericSelect value={sortBy} label='Sort By' onChange={handleChangeSort}>
        <MenuItem value='created_at'>Recently added</MenuItem>
        <MenuItem value='ASC'>Name A-Z</MenuItem>
        <MenuItem value='DESC'>Name Z-A</MenuItem>
      </GenericSelect>
    </FormControl>
  );
};

const SortByFormType = ({ type_id, handleChangeType_id, ...props }) => {
  console.log('🚀OUTPUT --> props.rooms:', props.rooms);
  return (
    <FormControl
      sx={{
        // m: 1,
        minWidth: 120,
        maxWidth: 125,
        '@media (max-width: 600px)': {
          maxWidth: '100% !important',
          width: '100% !important',
        },
      }}
      size='small'
    >
      <InputLabel focused={false}>Room</InputLabel>
      <GenericSelect
        value={type_id}
        label='Room'
        onChange={handleChangeType_id}
      >
        <MenuItem value='all'>All </MenuItem>
        <MenuItem value={''}>All</MenuItem>
        <MenuItem value='forms'>Forms</MenuItem>
      </GenericSelect>
    </FormControl>
  );
};

const SearchContainer = ({
  searchQuery,
  debounceSearch,
  setSearchQuery,
  ...props
}) => {
  return (
    <GenericSearchField
      variant='outlined'
      placeholder={`Search forms `}
      value={searchQuery}
      onChange={(event) => {
        debounceSearch(event);
        setSearchQuery(event.target.value);
      }}
    />
  );
};
