import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash';
import axios from '../../../../Util/axiosConfig'
import Loader from '../../Loader/Loader';
import { InView } from 'react-intersection-observer';
import GenericSearchField from '../../GenericSearchField/GenericSearchField';
import styles from './FreeImages.module.scss'
import { Button } from '@mui/material';
// import { Masonry } from '../../../../Util/useMarcy';
import { Logout } from '@mui/icons-material';

// const API_KEY = "f82RpJiE9BHCjAWCci2T0jicHh5Ttrf5iL_OmHYJfx0"// puvnhjQGF7X3dyrcGhmDB6iLRR1EjWiIK6mvxhQXakE



function FreeImages(props) {
  
  const [searchQuery, setSearchQuery] = useState('');
  const [images, setImages] = useState(null);
  console.log('🚀OUTPUT --> images:', images);
  const [page, setPage] = useState(1);
  const [API_KEY, setAPI_KEY] = useState(undefined);


  const getClientId = () => {
    // console.log('🚀OUTPUT --> getClientId:');
    axios.get('/web-builder/web-gallery-setting/my')
    .then((resp) => {
      console.log('🚀OUTPUT --> resp:', resp);
      if(resp.data.data.result.unsplash_key){
        setAPI_KEY(resp.data.data.result.unsplash_key)
      } else {
        setAPI_KEY(null)
      }
    })
    .catch((err) => {
      console.log('🚀OUTPUT --> err:', err);
      setAPI_KEY(null)
    })
  }
  
  useEffect(() => {
    if(!API_KEY) {
      getClientId();
      const intervalCall = setInterval(() => {
        getClientId();
      }, 2000);
      return () => {
        // clean up
        clearInterval(intervalCall);
      };
    }
  }, [API_KEY]);

  const fetchImages = (params, setnull) => {

    let API_URL = ''
    if(params?.query){
      API_URL = `https://api.unsplash.com/search/photos`
    } else {
      API_URL = `https://api.unsplash.com/photos/random`
    }
    if(setnull){
      setImages(null)
    }
    axios.get(API_URL, {
      params: {
        client_id: API_KEY,
        per_page: 30,
        count: 30,
        ...params
      }
    } ).then((res) => {
      if(params?.page > 1){
         if(params?.query){
          setImages([...images, ...res.data.results])
         } else {
           setImages([...images, ...res.data])
          }
      } else {
        if(params?.query){
          setImages(res.data.results)
        } else {
          setImages(res.data)
        }
      }

    console.log('🚀OUTPUT --> res:', res);
    }).catch((err) => {
      console.log('🚀OUTPUT --> err:', err);
      if(err.response.status === 401){
        deleteAPIKey()
      }
      
    })
  }

  useEffect(() => {
    if(API_KEY){
      fetchImages()
    }
  }, [API_KEY])

  

  const handleSearchChange = (event) => {
    let params = {  };
    if (event.target.value) {
      params.query = event.target.value;
    }
    

    fetchImages(params, true);
  };

  const deleteAPIKey = () => {
    //web-builder/web-gallery-setting/remove
    axios.delete('/web-builder/web-gallery-setting/remove')
    .then((resp) => {
      console.log('🚀OUTPUT --> resp:', resp);
      setAPI_KEY(null);
      setImages(null);
    })
    .catch((err) => {
      console.log('🚀OUTPUT --> err:', err);
    })
  }
  
  
  const debounceSearch = useCallback(debounce(handleSearchChange, 1000), [API_KEY]);
    
  return (
    <div className={styles.free_image_container}>
      {API_KEY === undefined ?  <Loader /> : API_KEY ? (
        <>
          <div>
            <SearchContainer {...{ searchQuery, debounceSearch, setSearchQuery }} />  
            <div style={{ display: 'flex', justifyContent: 'flex-end', color: '#000' }}>
              <Button
                variant='text'
                color='inherit'
                onClick={deleteAPIKey}
                startIcon={<Logout />}
              >
                Logout
              </Button>
            </div>
          </div>
            {!images ? <Loader /> : images?.length === 0 ? (
               <div className='tw-flex tw-justify-center tw-items-center tw-min-h-[200px]'>
                No Images available
              </div>
            ) : (
              <div className={styles.image_wrapper}>
                {/* <Masonry> */}
                  {images.map((image) => (
                    <ImageItem key={image.id} image={image} {...props} />
                  ))}
                {/* </Masonry> */}
              </div>
              )}
            <InView onChange={(inView, entry) => {
              if (images && images?.length > 0 && inView) {
                let params = {page: page+1}
                if(searchQuery){
                  params.query = searchQuery
                }
                fetchImages(params)
                setPage(page+1)
              }
            }
            }>
              {images && images?.length > 0 && (
                <Loader />
              )}
              {/* <div> Loading... </div>   */}
            </InView>
        </>
      ) :  (
        <div className={styles.create_account_container}>
          <img src='https://images-stag.mykidreports.com/WebPage/unsplash-1700561179.png' alt='unsplash' />
          <p>Log in to your Unsplash account to access images.</p>
          {/* <a
            href={`https://unsplash.com/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_UNSPLASH_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=public`}
            target='_blank'
            rel='noopener refferer'
            style={{ 
              background:'#000',
              color:'#fff',
              padding: '6px 16px',
              borderRadius:'6px'
             }}
             >
             Create splash account
            </a> */}
          <Button
            variant='contained'
            onClick={() => {
                              window?.open(`https://unsplash.com/oauth/authorize?client_id=${process.env.REACT_APP_UNSPLASH_CLIENT_ID || "TThLIE6nK6DxWvnkcavoPFLw0SqKE5-pPn9WlzZkRug"}&redirect_uri=${window?.origin}/authorize_unsplash&response_type=code&scope=public+read_user`);                           
                              
                              //, 'sharer', 'toolbar=0,status=0,width=1248,height=625' 
                              // props.onClose()
                          }}
            sx={{
              background:'#000', color:'#fff', 
              "&:hover": {background:'#000'}
            }}
          >
            Login via Browser
          </Button>
        </div>
      )}
    </div>
  )
}

export default FreeImages 

const ImageItem = ({ image, ...props }) => {

  function shortenText(text) {
    return text.length > 10 ? text.substring(0, 10) + "..." : text;
  }

  const handleUpload = ({url, title, width, height}) => {
    fetch(url).then(function(response) {
        return response.blob()
      })
      .then(function(blob) {
        // console.log('🚀OUTPUT --> blob:', blob);
        
      const fd = new FormData()

      fd.append('image_file', blob)
      fd.append('title', title)
      // setIsLoading(true)
      axios.post('/web-builder/web-page/upload-image', fd)
          .then((res) => {
              console.log('🚀OUTPUT --> res:', res);
              // setIsLoading(false);
              props.handleImageGalleryUpload({
                image: `${res.data?.data?.base_image}/${res.data?.data?.content}`,
                name: title,
                width,
                height,

              })
          }).catch((err) => {
              console.log(err); 
          })
    }).catch((err) => {
      console.log('🚀OUTPUT --> err:', err);
    })
  }

  return (
    <div className={styles.image}>
      <img src={image.urls.small} alt={image.alt_description} />
      <div className={styles.image_text}> 
        <a href={`${image?.user?.links?.html}?utm_source=Esplanda&utm_medium=referral `} target='_blank'rel='noopener noreferrer' title={`@${image?.user?.username}`}>@{shortenText(image?.user?.username)}</a>
        <Button variant='contained' 
        sx={{ 
            background:'#fff', 
            color:'#000', 
            borderRadius:'20px',
            "&:hover": {
              color: "#fff"
            } 
        }} 
        onClick={() => {
          console.log(image);
          handleUpload({url: (image.urls.regular || image.urls.raw || image.urls.full), title: image.slug, width: image.width, height: image.height}) 
          // props.handleImageGalleryUpload({
          //   image: image.urls.small,
          //   width: image.width,
          //   height: image.height,
          // })
        }}
        >Use Image</Button>
      </div>
    </div>
  );
};


const SearchContainer = ({
  searchQuery,
  debounceSearch,
  setSearchQuery,
  ...props
}) => {
  return (
    <GenericSearchField
      variant='outlined'
      placeholder={`Search Image `}
      value={searchQuery}
      fullWidth={true}
      onChange={(event) => {
        debounceSearch(event);
        setSearchQuery(event.target.value);
      }}
    />
  );
};

