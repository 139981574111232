import React from 'react' 
import { Box } from '@mui/material'; 



function MobileItems({ products, handleEdit2,isActionDisabled }) {
  return (
    <Box>
        {products.map((row, index) => (
            <Box
                key={row.id}
                style={{
                    pointerEvents: isActionDisabled ? 'none' : 'all'
                }}
                onClick={() => handleEdit2(row.id)}
                className='tw-border-b tw-border-gray tw-py-2 tw-flex tw-items-center tw-gap-2'
            >
                <div className='tw-w-full'>
                    <div className='tw-flex tw-justify-between tw-text-sm tw-text-black tw-mb-1'>
                        <span className='tw-font-bold tw-max-w-[70%] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.title}</span>
                        <span className='tw-text-right tw-max-w-[30%]'>  <span className='tw-text-[#7C7C7C]'>Options : </span>{row.options?.length}</span>
                    </div>
                    {/* <div className='tw-flex tw-justify-between tw-text-xs tw-text-[#7C7C7C]'>
                        <span className='tw-max-w-[80%] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.options?.length}</span>
                    </div> */}
                </div>
            </Box>
        ))}
    </Box>
  )
}

export default MobileItems;