import React, { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'
import { useFormik } from 'formik';
import PhoneCountryInput, { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'

import GenericSwitch from '../../../../Library/V2/GenericSwitch/GenericSwitch2'
import GenericSelect from '../../../../Library/V2/GenericSelect/GenericSelect'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton';
import { nodeAxios } from '../../../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import Loader from '../../../../Library/Loader';

const TextVoiceNotification = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const fetchVenueNumbers = () => {
    setIsLoading(true)
    nodeAxios
    .get('/venue/get-venue-twilio-numbers')
    .then((response) => {
        let result = response.data.data?.numbers;
        formik.setFieldValue('send_sms_voice_notification', response.data.data?.send_sms_voice_notification)
        let numbers = result.map((data) => {
          return {
            twilio_type: data.twilio_type,
            phone: data.phone
          }
        })
        formik.setFieldValue('numbers', numbers?.length ? numbers : [
          {
            twilio_type: '1',
            phone: ''
          }
        ])
    })
    .catch((error) => {
        // toast.error('Something went wrong')
    })
    .finally(() => {
        setIsLoading(false)
    })
  }


  const formik = useFormik({
    initialValues: {
      send_sms_voice_notification: 0,
        numbers: [
          {
            twilio_type: '1',
            phone: ''
          }
        ],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log('🚀OUTPUT --> values:', values);

      // let isValid = true
      // values.numbers.forEach((number) => {
      //   if (!isValidPhoneNumber(number.phone)) {
      //     isValid = false
      //   }
      // })

      // if (!isValid) {
      //   toast.error('Please enter valid phone number')
      //   return
      // }

      let payload = {
        send_sms_voice_notification: values.send_sms_voice_notification,
        numbers: values.numbers
      }

      if (payload.numbers?.length == 1 && !payload.numbers?.[0]?.phone) {
        payload.numbers = []
      }

      handleSave(payload);
      // setIsSaveLoading(true)
      // nodeAxios
      // .post('/venue/set-venue-twilio-numbers', payload)
      // .then((response) => {
      //   toast.success('Text & Voice settings saved successfully');
      //   fetchVenueNumbers();
      // })
      // .catch((error) => {
      //   // toast.error('Something went wrong')
      // })
      // .finally(() => {
      //   setIsSaveLoading(false)
      // })

    }
  })

  const handleSave = (payload) => {
    
    setIsSaveLoading(true)
    nodeAxios
    .post('/venue/set-venue-twilio-numbers', payload)
    .then((response) => {
      toast.success('Text & Voice settings saved successfully');
      fetchVenueNumbers();
    })
    .catch((error) => {
      // toast.error('Something went wrong')
    })
    .finally(() => {
      setIsSaveLoading(false)
    })
  }

  useEffect(() => {
    fetchVenueNumbers();
  }, [])


  return isLoading ? <Loader /> : (
    <div className="tw-my-4">
      <div className='tw-mt-3 tw-mb-5'>
        <div>
          <GenericSwitch
              checked={Boolean(formik.values.send_sms_voice_notification)}
              onChange={e => {
                  formik.setFieldValue('send_sms_voice_notification', e.target.checked ? 1 : 0)
                  handleSave({ send_sms_voice_notification: e.target.checked ? 1 : 0, numbers: formik.values.numbers })
              }}
          />
          <span className='tw-font-semibold tw-ml-2'>Send SMS and Voice Notification</span>
        </div>
        <p className='tw-text-[#555555] tw-text-xs'>
          SMS and Voice support is only available in USA or Canada.
        </p>
        <p className='tw-text-[#555555] tw-text-xs'>
          Use WhatsApp for other International destination
        </p>
      </div>


      
      {Boolean(formik.values.send_sms_voice_notification) && (
        <>
          <div>
            {formik.values.numbers.map((number, index) => (
              <div className='tw-flex tw-font-semibold tw-flex-wrap tw-items-center tw-gap-2 tw-mb-2'>
                When a new order is created, Send
                <GenericSelect
                  padding='5px 32px 5px 5px'
                  bgColor="#F8F8F8"
                  sx={{ fontSize: '14px', width: '140px' }}
                  minHeight='35px'
                  value={formik.values.numbers?.[index]?.twilio_type}
                  onChange={(e) => {
                    formik.setFieldValue(`numbers[${index}].twilio_type`, e.target.value)
                    formik.setFieldValue(`numbers[${index}].phone`, '')
                  }}
                >
                  <MenuItem value={'1'}>Text/SMS</MenuItem>
                  <MenuItem value={'2'}>Voice</MenuItem>
                  <MenuItem value={'3'}>WhatsApp</MenuItem>
                </GenericSelect>
                To
                {formik.values.numbers?.[index]?.twilio_type == '3' ? (
                      <PhoneCountryInput
                          className="tw-bg-white tw-text-black tw-p-2 tw-border tw-border-gray tw-rounded"
                          international
                          defaultCountry="US"
                          country="US"
                          withCountryCallingCode
                          numberInputProps={{ maxLength: 35 }}
                          placeholder="Enter phone number"
                          countryCallingCodeEditable={false}
                          value={formik.values.numbers?.[index]?.phone}
                          onChange={(number) => {
                              formik.setFieldValue(`numbers[${index}].phone`, number)
                          }}
                      />
                ) : (
                    <PhoneInput
                        className="tw-bg-white tw-text-black tw-p-2 tw-border tw-border-gray tw-rounded"
                        international
                        // defaultCountry="US"
                        country="US"
                        withCountryCallingCode
                        placeholder="Enter phone number"
                        countryCallingCodeEditable={false}
                        value={formik.values.numbers?.[index]?.phone}
                        onChange={(number) => {
                            formik.setFieldValue(`numbers[${index}].phone`, number)
                        }}
                        maxLength={15}
                        />
                )}

                <button
                  className='tw-dui-btn tw-dui-btn-ghost tw-dui-btn-xs tw-text-red-500 tw-rounded-3xl tw-px-4 tw-py-1 tw-border-none'
                  onClick={() => {
                    if (formik.values.numbers?.length === 1) {
                      formik.setFieldValue(`numbers`, [
                        {
                          twilio_type: '1',
                          phone: ''
                        }
                      ])
                      return
                    }
                    formik.setFieldValue(`numbers`, formik.values.numbers.filter((_, i) => i !== index))
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
            {formik.values.numbers?.length < 3  && (
              <div>
                <button
                  className='tw-dui-btn tw-dui-btn-xs tw-bg-[#E9E9E9] tw-border-none tw-rounded-3xl tw-px-4 tw-py-1'
                  onClick={() => {
                    formik.setFieldValue(`numbers`, [
                      ...formik.values.numbers,
                      {
                        twilio_type: '1',
                        phone: ''
                      }
                    ])
                  }}
                >
                  Add new row
                </button>
              </div>
            )}
          </div>
          
          <p className='tw-text-[#555555] tw-text-xs tw-mt-5'>
            By entering your number and clicking "Save" you agree to receive order notifications, marketing, promotional messages, and information from Espladna.
          </p>

          <div className='tw-flex tw-justify-end tw-mt-4'>
              <GenericLoadingButton
                  variant="contained"
                  type="button"
                  onClick={formik.handleSubmit}
                  size="large"
                  loading={isSaveLoading}
                  loadingPosition="end"
                  disabled={isSaveLoading}
              >
                  Save
              </GenericLoadingButton>
          </div>
        </>
      )}


    </div>
  )
}

export default TextVoiceNotification