import React from 'react';
import { Button, buttonClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PrimaryButton = styled((props) => (
    <Button
        variant="contained"
        {...props}
    />
))(({ theme }) => ({
    [`&.${buttonClasses.contained}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${buttonClasses.disabled}`]: {
        opacity: .5
    }
}));


export const SecondaryButton = styled((props) => (
    <Button
        variant="contained"
        {...props}
    />
))(({ theme }) => ({
    [`&.${buttonClasses.contained}`]: {
        backgroundColor: '#DDDDDD',
        color: theme.palette.common.black,
    },
    [`&.${buttonClasses.disabled}`]: {
        opacity: .5
    }
}));
