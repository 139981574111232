import axios, { nodeAxios } from "../../utils/axiosConfig";
import Const from "../../components/helpers/const";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

export function login(data, deviceToken, deviceType) {
    return (dispatch) => {
        var device_token = localStorage.getItem("device_token");
        dispatch({ type: "LOGIN_LOADING" });
        // alert(`login api data: ${JSON.stringify(data)}`);
        // toast.error(`login api data: ${JSON.stringify(data)}`);
        return nodeAxios.post('/auth/login?device_token=' + device_token, data)
            .then((response1) => {
                Cookies.set('scopes', response1.data.data.user?.scopes?.join(','));
                localStorage.setItem("userToken", response1.data.data.token);
                localStorage.setItem("venue_id", response1.data.data.user.venueid);
                document.body.setAttribute('venue_id', response1.data.data.user.venue_id_integer)
                localStorage.setItem("isShiftedFrom", false);
                localStorage.setItem("StoreName", "");

                return nodeAxios
                    .get("/auth/get-user", {
                        headers: {
                            Authorization: `Bearer ${response1.data.data.token}`
                        }
                    })
                    .then((response) => {
                        Cookies.set('scopes', response.data.data?.scopes?.join(','));
                        if (response.data.data?.biz_users_role == 0) {
                            localStorage.setItem("superAdminToken", response1.data.data.token);
                        }
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: response.data.data,
                        });
                        return {
                            type: "success",
                            userId: response.data.data.id,
                            roleID: response.data.data?.biz_users_role,
                            venueType: response.data.data?.venue_type,
                            isClaimBusiness: response.data.data?.is_claim_business
                        };
                    })
                    .catch((error) => {
                        localStorage.removeItem("userToken");
                        Cookies.remove('scopes')
                        dispatch({
                            type: "LOGIN_ERROR",
                            payload: error.response,
                        });
                        return {
                            type: "error",
                            payload: error.response,
                        };
                    });
            })
            .catch((error) => {
                dispatch({
                    type: "LOGIN_ERROR",
                    payload: error.response,
                });
                return {
                    type: "error",
                    payload: error.response,
                };
            });
    };
}

export function forgotPassword(data) {
    return (dispatch) => {
        return nodeAxios
            .get(`/auth/forgot-password`, {
                params: {
                    email: data.email
                }
            })
            .then((response) => {
                return {
                    type: "success",
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    type: "error",
                    data: error.response?.data,
                };
            });
    };
}

export function checkAuth() {
    return (dispatch) => {
        if (localStorage.userToken) {
            return nodeAxios
                .get("/auth/get-user")
                .then((response) => {
                    Cookies.set('scopes', response.data.data?.scopes?.join(','));
                    dispatch({
                        type: "LOGIN_SUCCESS",
                        payload: response.data.data,
                    });

                    document.body.setAttribute('venue_id', response.data.data?.venue_id_integer)
                    document.body.setAttribute('venue_name', response.data.data?.name)
                    document.head.setAttribute('venue_id', response.data.data?.venue_id_integer)
                    document.head.setAttribute('venue_name', response.data.data?.name)
                    return Promise.resolve({
                        roleID: response.data.data?.biz_users_role,
                        isClaimBusiness: response.data.data?.is_claim_business
                    });
                })
                .catch((error) => {
                    localStorage.removeItem("userToken");
                    Cookies.remove('scopes')
                    dispatch({
                        type: "LOGIN_ERROR",
                        payload: error.response,
                    });
                    return Promise.reject();
                });
        } else {
            dispatch({ type: "NO_LOGGED_USER" });
            return Promise.reject();
        }
    };
}

export function logout() {
    let token = localStorage.getItem("device_token_app");
    const params = {
        device_token: token,
    };
    return (dispatch) => {
        return axios
            .post(`/auth/logout`, params)
            .then((response) => {
                localStorage.removeItem("userToken");
                Cookies.remove('scopes')
                dispatch({
                    type: "LOGOUT",
                });
                return "success";
            })
            .catch((error) => {
                dispatch({
                    type: "LOGIN_ERROR",
                    payload: error?.response,
                });
                window.location.pathname = '/'
                return "error";
            });
    };
}

export function setUserRedirect(slug) {
    return (dispatch) => {
        dispatch({
            type: "SET_USER_REDIRECT",
            payload: slug,
        });
    };
}

export function signUp(params) {
    return (dispatch) => {
        dispatch({ type: "SET_SIGNUP_DETAILS_LOADING" });
        return axios
            .post(`/venue/signup`, params)
            .then((response) => {
                dispatch({
                    type: "SET_SIGNUP_DETAILS_LOADED",
                    payload: response,
                });
                return Promise.resolve(response.data);

            })
            .catch((error) => dispatch({
                error: true, type: "SET_SIGNUP_ERROR", message: error.response.data.message
            }));

    };
}