import React, { useState } from "react";
import { Typography, Divider, Collapse, useMediaQuery } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { formatWithComma } from "../../../../../../utils/helpers";
import GenericTextField from "../../../../../Library/GenericTextField/GenericTextField";
import { useDispatch, useSelector } from "react-redux";
import { ChevronRight, KeyboardArrowUp } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    infoContainerVal: {
        // width: '46%',
        fontWeight: '600',
        color: 'black',
        fontSize: '13px',
    },
    infoContainerHead: {
        fontSize: '13px',
    }
}));

const PaymentInfo = (props) => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const isMobileScreen = useMediaQuery('(max-width:768px)');
    const [showBody, setShowBody] = useState(true)

    const handleSubFeesChange = (key, value) => {
        dispatch({
            type: "UPDATE_ORDER_ITEM_SUBFEES",
            payload: {
                key: key,
                value: value
            },
        });

        props.setSaveable(true);
    };


    return (
        <div
            style={{ flex: props.flex ? 1 : 'initial', border: '1px solid rgb(232,232,232)', margin: props.margin, borderRadius: 4 }}
        >
            <Typography
                onClick={() => {
                    if (isMobileScreen) {
                        setShowBody(!showBody)
                    }
                }}
                style={{
                    fontSize: 18,
                    color: 'black',
                    backgroundColor: '#f9f9f9',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    transition: 'all .3s ease-in-out'
                }}
            >
                {props.title}

                {isMobileScreen && (
                    showBody ? <KeyboardArrowUp /> : <ChevronRight />
                )}
            </Typography>


            {isMobileScreen ? (
                <Collapse in={showBody}>
                    <PaymentBody classes={classes} {...props} />
                </Collapse>
            ) : (
                <PaymentBody classes={classes} {...props} />
            )}
        </div>
    );
};

export default PaymentInfo;

const PaymentBody = ({ classes, ...props }) => {

    return (

        <div
            style={{
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: 8
            }}
        >
            <div className={classes.infoContainer}>
                <Typography className={classes.infoContainerHead}>
                    Items({props.orderDetails?.order_details?.length})
                </Typography>
                <Typography className={classes.infoContainerVal}>
                    ${formatWithComma(props.productTotal)}
                </Typography>
            </div>
            {props.subFees.map((val, i) => {
                console.log(val.value)
                if (Boolean(parseFloat(val.value))) {
                    return (
                        <div>
                            <div className={classes.infoContainer}>
                                <Typography className={classes.infoContainerHead}>
                                    {val.header}
                                </Typography>

                                {props.isEditMode ? (
                                    <div
                                        style={{
                                            width: 80
                                        }}
                                    >
                                        <GenericTextField
                                            style={{
                                                backgroundColor: 'white'
                                            }}
                                            onChange={(event) => handleSubFeesChange(val.key, event.target.value)}
                                            value={val.value}
                                        />
                                    </div>
                                ) : (
                                    <Typography className={classes.infoContainerVal}>
                                        {val.isNegative ? `($${formatWithComma(val.value)})` : `$${formatWithComma(val.value)}`}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    )
                }
            })}
            {/* <div style={{ display: parseInt(props.cc) > 0 ? 'flex' : 'none' }} className={classes.infoContainer}>
                <Typography className={classes.infoContainerHead}>
                    Credit Card
                </Typography>
                <Typography className={classes.infoContainerVal}>
                    ${formatWithComma(props.cc)}
                </Typography>
            </div> */}

            <div className={classes.infoContainer}>
                <Typography style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#B12704' }}>
                    Order Total
                </Typography>
                <Typography style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#B12704' }}>
                    ${formatWithComma(props.total)}
                </Typography>
            </div>
            <Divider />

            <div style={{ display: parseInt(props.gc) > 0 ? 'flex' : 'none' }} className={classes.infoContainer}>
                <Typography className={classes.infoContainerHead}>
                    Paid by gift card
                </Typography>
                <Typography className={classes.infoContainerVal}>
                    ${formatWithComma(props.gc)}
                </Typography>
            </div>
            {props.isApplePay && (
                <div style={{ display: 'flex' }} className={classes.infoContainer}>
                    <Typography className={classes.infoContainerHead}>
                        Paid by Applepay
                    </Typography>
                </div>
            )}
            {props.isGooglePay && (
                <div style={{ display: 'flex' }} className={classes.infoContainer}>
                    <Typography className={classes.infoContainerHead}>
                        Paid by Googlepay
                    </Typography>
                </div>
            )}

            {!(props.isApplePay || props.isGooglePay) && (
                <div style={{ display: parseInt(props.cc) > 0 ? 'flex' : 'none' }} className={classes.infoContainer}>
                    <Typography className={classes.infoContainerHead}>
                        Paid by cc({props.lastdigit})
                    </Typography>
                    <Typography className={classes.infoContainerVal}>
                        ${formatWithComma(props.cc)}
                    </Typography>
                </div>
            )}
            
            {(props.doordashFee) && (
                <div style={{ display: parseInt(props.doordashFee) > 0 ? 'flex' : 'none' }} className={classes.infoContainer}>
                    <Typography className={classes.infoContainerHead}>
                        Doordash fee
                    </Typography>
                    <Typography className={classes.infoContainerVal}>
                        (${formatWithComma(props.doordashFee)})
                    </Typography>
                </div>
            )}
        </div>
    )
}
