import React, { useEffect, useState, useRef } from "react";
import { Typography, Button } from "@mui/material";
import online from "../../../assets/online.png";
import branding from "../../../assets/branding.png";
import website from "../../../assets/website.png";
import inventory from "../../../assets/inventory.png";
import order from "../../../assets/order.png";
import delivery from "../../../assets/delivery.png";
import { useNavigate } from 'react-router-dom';
import Buttons from '../Signup/Parts/Buttons';
import GetStarted from '../../../util_comps/GetStarted';
import { Check, Close } from '@mui/icons-material';
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import './WhyOurLiquorStore.scss';
import one from "../../../assets/01.png";
import two from "../../../assets/02.png";
import three from "../../../assets/03.png";
import four from "../../../assets/04.png";
import five from "../../../assets/05.png";
import laptop from "../../../assets/laptop_1.png";
import online_platform from "../../../assets/online-platfrom.png";
import order_management from "../../../assets/Order Management.png";
import realtime from "../../../assets/Real-time Customization.png";
import inventory_management from "../../../assets/Inventory Management.png";
import flexible_pricing from "../../../assets/Flexible Pricing.png";
import community from "../../../assets/Community.png";
import checkmark from "../../../assets/check-mark.png";
import desistorewhy from "../../../assets/desistore-laptop-whyourliquorstore.png"
const WhyOurLiquorStore = (props) => {
const history = useNavigate();
const cn = classname('why-ourliquorstore')
return (
<div
className={cns(
cn(),
props.className,
)}
>
<div className={cn('pinkbgcolor')} id='title'>
<div className={cn('center-div')}>
   <Typography variant="h1">
      Joining Esplanda is the smartest decision you can make for your business      
   </Typography>
   <div className={cn('smallfont')}>Nearly half of all surveyed customers said that they shopped
         more frequently if the store has a website or mobile app.
      </div>
   <Button className={cn('getstarted')} onClick={e => history('/SignUp')} >Get Started</Button>
</div>
</div>


<div>
   <h2 className={cn('why-ourliquorstore-h2-body')}>
      How Do You Benefit?
   </h2>
</div>
<div
   className={cn('why-ourliquorstore-benefits-container')}
   >
   <div className={cn('why-ourliquorstore-benefits-list')}>
      <ul className={cn('benefitslist')}>
         <li>
         Attract New Customers<span className={cn('why-ourliquorstore-benefits-items')}></span>
         </li>
         <li>
         Gain More Repeat Customers <span className={cn('why-ourliquorstore-benefits-items')}></span>
         </li>
         <li>
            Understand & Gain insights about your Business like never before <span className={cn('why-ourliquorstore-benefits-items')}></span>
         </li>
         <li>
            Automated Social Media Marketing Posts <span className={cn('why-ourliquorstore-benefits-items')}></span>
         </li>
         <li>
            Maintain Control over your Customer Base <span className={cn('why-ourliquorstore-benefits-items')}></span>
         </li>
         <li>
            Your Products Automatically Appear in Google Search Results <span className={cn('why-ourliquorstore-benefits-items')}></span>
         </li>
      </ul>
   </div>

   <div className={cn('why-ourliquorstore-benefits-img')}>
      <img src={Const.isOurDesiStore ? desistorewhy : laptop} />
   </div>

   <div className={cn('why-ourliquorstore-benefits-listnew')}>
      <ul className={cn('benefitslistnew')}>
         <li>
            <span className={cn('why-ourliquorstore-benefits-items')}> Automatic Email Blasts for Customers</span>
         </li>
         <li>
            <span className={cn('why-ourliquorstore-benefits-items')}> Gain instant access to the world of ecommerce</span>
         </li>
         <li>
            <span className={cn('why-ourliquorstore-benefits-items')}> Operate your store or business on an efficient, secure, and SEO-friendly website</span>
         </li>
         <li>
            <span className={cn('why-ourliquorstore-benefits-items')}> Increase customer engagement</span>
         </li>
         <li>
            <span className={cn('why-ourliquorstore-benefits-items')}> Grow your business online and increase sales</span>
         </li>
         <li>
            <span className={cn('why-ourliquorstore-benefits-items')}> Complimentary Designs and Promotional Content</span>
         </li>
      </ul>
   </div>

</div>
<div className={cn('why-ourliquorstore-features-container')}>
   <Typography className={cn('whyfont')}  variant="h2">
      Our Mission is to Bring you More Customers & Increase Sales
   </Typography>
   <div className={cn('innerboxes')}>
      <div className={cn('boxshaodow')}>
         <img src={online_platform} height='100' width='100' className='center'></img>
         <Typography variant="h3" className={cn('why-ourliquorstore-features-name')}>Online Platform</Typography>
         <p>You have the products and we have the technology. We’ll host your inventory on a fully customizable website and optional mobile app. Embrace the world of ecommerce and increase your sales capabilities with online pickup orders, deliveries, and shipping. Hosting your store on Esplanda doesn’t cost you a dime!
         </p>
         <div className={cn('centerbtn')}><a href="https://www.youtube.com/watch?v=LSh4bKnrMro" target="_blank"><Button className='selectredbutton'>Find out more here</Button></a></div>
      </div>
      <div className={cn('boxshaodow')}>
         <img src={realtime} height='100' width='100' className='center'></img>
         <Typography variant="h3" className={cn('why-ourliquorstore-features-name')}>Real-time Customization</Typography>
         <p>Upload photos, create banners for promotions and product groups, and customize your website with intuitive drag-and-drop tools. Every change you make is updated instantly, whether you’re on the website or the mobile app. Rearranging your online storefront isn’t rocket science, anyone can do it with Esplanda!
         </p>
         <div className={cn('centerbtn')}><a href='https://www.youtube.com/watch?v=IlpgVH043LA&t=172s' target="_blank"><Button className='selectredbutton'>Find out more here</Button></a></div>
      </div>
      <div className={cn('boxshaodow')}>
         <img src={inventory_management} height='100' width='100' className='center'></img>
         <Typography variant="h3" className={cn('why-ourliquorstore-features-name')}>Inventory Management</Typography>
         <p>Add and remove products, set purchase limits, and update prices in real-time. Custom product groups allow you to effortlessly create promotions and showcase seasonal specials. Integration with your store’s POS system is quick and easy, so you can spend less time worrying about inventory and more time on sales.
         </p>
         <div className={cn('centerbtn')}><a href='https://www.youtube.com/watch?v=DhgtbGZZeRQ&t=34s' target="_blank"><Button className='selectredbutton'>Find out more here</Button></a></div>
      </div>
      <div className={cn('boxshaodow')}>
         <img src={order_management} height='100' width='100' className='center'></img>
         <Typography variant="h3" className={cn('why-ourliquorstore-features-name')}>Order Management</Typography>
         <p>View, process, modify, and complete your orders with an admin website and app. Set your own service fees and create custom delivery zones and schedules. There is even the option of passing the transaction fee to your customers.
         </p>
         <div className={cn('centerbtn')}><a href="https://www.youtube.com/watch?v=Gw8Bd3-vFZk" target="_blank"><Button className='selectredbutton'>Find out more here</Button></a></div>
      </div>
      <div className={cn('boxshaodow')}>
         <img src={flexible_pricing} height='100' width='100' className='center'></img>
         <Typography variant="h3" className={cn('why-ourliquorstore-features-name')}>Flexible Pricing</Typography>
         <p>We wish that one size always fits all. But we all know it’s rarely the case. Esplanda lets you choose how you want to pay. Whether you want to pay a fixed monthly rate or if you only want to work with transaction fees, we have the right plan for you. Find out more here.
         </p>
         <div className={cn('centerbtn')}><Button onClick={e => history('/Pricing')} className='selectredbutton'>Find out more here</Button></div>
      </div>
      <div className={cn('boxshaodow')}>
         <img src={community} height='100' width='100' className='center'></img>
         <Typography variant="h3" className={cn('why-ourliquorstore-features-name')}>Community</Typography>
         <p>Join the Esplanda network of stores and get more visibility from potential customers. Engage with customer feedback while improving your presence on search engines such as Google.
         </p>
         <div className={cn('centerbtn')}><a href="https://ourliquorstore.com/liquor-stores-near-me" target="_blank"><Button className='selectredbutton'>Find out more here</Button></a></div>
      </div>
   </div>
</div>

    <div className={cn('numbersecion')}>
    <Typography className="whyfont" variant="h4">
    The Numbers Don't Lie
    </Typography>
    <div className={cn('signup-benefits')}>

    <div className={cn('benefits-box')}>
    <img src={one} className='center' />
    <Typography variant="h5">
    More than half of customers prefer to shop online than in-person
    </Typography>
    </div>

    <div className={cn('benefits-box')}>
    <img src={two} className='center' />
    <Typography variant="h5">
    Studies indicate that by 2030, up to 76% of {Const.isOurDesiStore ? 'retail' : 'liquor'} store revenue will come directly from online sales
    </Typography>
    </div>

    <div className={cn('benefits-box')}>
    <img src={three} className='center' />
    <Typography variant="h5">
    80% of those surveyed claimed to prefer retailers that have their inventory online and available for purchase
    </Typography>
    </div>
    </div>
   

   
    <div className={cn('signup-benefits')}>

    <div className={cn('benefits-box1')}>
    <img src={four} className='center' />
    <Typography variant="h5">
    Nearly 75% of consumers became repeat customers after installing the OLS app on their phones
    </Typography>
    </div>

    <div className={cn('benefits-box1')}>
    <img src={five} className='center' />
    <Typography variant="h5">
    Over 90% of customers listed that their primary reason for using the website and/or mobile app is to browse inventory and view new arrivals
    </Typography>
    </div>

    </div>

    </div>
   

<div className={cn('answersection')}>
   <Typography variant="h4">
   What are you waiting for? <strong>Start here and we'll get in touch with you</strong>
   </Typography>
   <Button onClick={e => history('/SignUp')} className='selectredbutton'>Sign Up</Button>
</div>

</div>
)
};
export default WhyOurLiquorStore;