const initialState = {
    isLoading: false,
    isLoggedIn: false,
    categoryList: {},
    categoryListError: ""
};
function categoryList(state = initialState, action) {
    switch (action.type) {
        case "SET_CATEGORY_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_CATEGORY_LIST_LOADED":
            return { ...initialState, isLoading: false , categoryList: action.payload };
        case "SET_CATEGORY_LIST_ERROR":
            return { ...initialState,categoryListError:action.payload};
        default:
            return state;
    }
};
export default categoryList;  