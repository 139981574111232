import {
    GET_COUPONS_FAILURE,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_REQUEST,
    POST_NEW_COUPON_FAILURE,
    POST_NEW_COUPON_REQUEST,
    POST_NEW_COUPON_SUCCESS,
    POST_UPDATE_COUPON_FAILURE,
    POST_UPDATE_COUPON_REQUEST,
    POST_UPDATE_COUPON_SUCCESS
} from '../types/couponsTypes';
import axios from '../../utils/axiosConfig';
import { bizBaseurl } from '../../env';
import { toast } from 'react-toastify';

export const getCoupons = (page) => {
    return (dispatch) => {
        dispatch(getCouponsRequest())
        axios.get(`/coupon/list?page=${page}`)
            .then((response) => {
                dispatch(getCouponsSuccess(response.data.data.result, response.data.data.pagination))
            })
            .catch((err) => {
                // dispatch(getCouponsFailure(err.response.data))
            })
    }
}

export const getCouponsRequest = () => {
    return {
        type: GET_COUPONS_REQUEST
    }
}

export const getCouponsSuccess = (data, pagination) => {
    return {
        type: GET_COUPONS_SUCCESS,
        payload: data,
        pagination: pagination
    }
}

export const getCouponsFailure = () => {
    return {
        type: GET_COUPONS_FAILURE
    }
}

export const postNewCoupon = (data) => {
    return (dispatch) => {
        dispatch(postNewCouponRequest())
        axios.post(`/coupon/create`, {
            code: data.couponCode,
            type: data.discountType,
            value: data.discountAmount,
            valid_from: data.validFrom,
            valid_to: data.validTo,
            limit: data.orderLimit,
            min_purchase: data.validAbove,
            valid_for: data.couponFor.map((option) => option?.title).toString().toLowerCase(),
            first_purchase: data.firstPurchase
        })
            .then((response) => {
                dispatch(postNewCouponSuccess())
            })
            .catch((err) => {
                toast.error(err.response.data.code[0])
                dispatch(postNewCouponFailure(err.response.data.code[0]))
            })
    }
}

export const postNewCouponRequest = () => {
    return {
        type: POST_NEW_COUPON_REQUEST
    }
}

export const postNewCouponSuccess = () => {
    return {
        type: POST_NEW_COUPON_SUCCESS
    }
}

export const postNewCouponFailure = (errMessage) => {
    return {
        type: POST_NEW_COUPON_FAILURE,
        payload: errMessage
    }
}


export const postUpdateCoupon = (data) => {
    return (dispatch) => {
        dispatch(postUpdateCouponRequest())
        axios.post(`/coupon/update/${data.id}`, {
            code: data.couponCode,
            type: data.discountType,
            value: data.discountAmount,
            valid_from: data.validFrom,
            valid_to: data.validTo,
            limit: data.orderLimit,
            min_purchase: data.validAbove,
            valid_for: data.couponFor.map((option) => option?.title).toString().toLowerCase(),
            first_purchase: data.firstPurchase
        })
            .then((response) => {
                dispatch(postUpdateCouponSuccess())
            })
            .catch((err) => {
                // toast.error(err?.response?.data?.code[0])
                dispatch(postUpdateCouponFailure())
            })
    }
}

export const postUpdateCouponRequest = () => {
    return {
        type: POST_UPDATE_COUPON_REQUEST
    }
}

export const postUpdateCouponSuccess = () => {
    return {
        type: POST_UPDATE_COUPON_SUCCESS
    }
}

export const postUpdateCouponFailure = () => {
    return {
        type: POST_UPDATE_COUPON_FAILURE
    }
}

export const postIsActiveToggle = (id) => {
    return (dispatch) => {
        axios.post(`${bizBaseurl}/coupon/change-status/${id}`)
    }
}