import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import storelist from "../../../assets/store-list.png";
import listicon from "../../../assets/list-text.png";
import accessyourwebsiteimg from "../../../assets/access-your-website.png";
import routePaths from '../../../config/route-paths';
const AccessYourWebsite = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={storelist} />
            </div>
            Store Details             
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.StoreDetails}>Store Details</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.FulfillmentMethods}>Fulfillment Methods</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.BankInformation}>Bank Information</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.LiquorLicense}>Business License</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.SubmitLogo}>Submit Logo</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.POSIntegration}>POS Integration</Link></li>
            <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AccessYourWebsite} className={cn('selected')}>Access Your Website</Link></li>
        </ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Access Your Website
         </Typography>
       
         <p>
            
         <ol>
            <li>Go to the ‘<strong>My Store Settings</strong>’ section of the admin website.</li>

            <li>Click on the ‘<strong>Website</strong>’ tab on the left side.</li>

            <li>Here you will see the address to your Esplanda website. This link goes live as soon as you sign up with us, but keep in mind that
you won’t be able to sell until all the necessary information is verified. Make sure you upload your liquor license, fill out your banking
information, and integrate your inventory with us.</li>

<li>If you have an existing store website, its address will also appear on this page. Your Esplanda website runs in parallel with
your existing website.</li>

            <span className={cn('addnewproductsimg')}>
    <img src={accessyourwebsiteimg} />
    </span>   

<li>If you would like to merge your existing website with the Esplanda one, click on ‘<strong>How to Get Your Website LIVE</strong>’.</li>

<li>A pop-up window will appear with instructions for merging. Simply follow the steps, and you are all set.</li>
  
</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default AccessYourWebsite