import React, { useContext, useEffect, useRef, useState } from 'react'
import { getInsideModel, handleInsideAttrChange, htmlToDraftConverter, isHTML, resolveHeight } from '../../Util/helpers'
import WebsiteEditorContext from '../../../../utils/context/websiteEditorContext'
import RichTextEditor3 from '../../CustomPageBuilder/Library/RichTextEditor3/RichTextEditor3'
import ImageUpload from '../../CustomPageBuilder/Library/ImageUpload/ImageUpload'
import GenericTextFieldWithLabel from '../../CustomPageBuilder/Library/GenericTextFieldWithLabel/GenericTextFieldWithLabel'
import { Dialog, MenuItem, Rating } from '@mui/material'
import GenericSwitchWithLabel from '../../CustomPageBuilder/Library/GenericSwitchWithLabel/GenericSwitchWithLabel'
import LinkSelector from '../../CustomPageBuilder/Library/LinkSelector/LinkSelector'
import { KeyboardArrowRight } from '@mui/icons-material'
import GenericSelectWithLabel from '../../CustomPageBuilder/Library/GenericSelectWithLabel/GenericSelectWithLabel'
import styles from './ItemsTraitResolver.module.scss'
import EditMenuLinkDialog from '../../CustomPageBuilder/Library/EditMenuLinkDialog/EditMenuLinkDialog'

const ItemsTraitResolver = ({ editIndex, itemsKey,onBack }) => {

    const { selectedModel, pages } = useContext(WebsiteEditorContext)

    const editorRef = useRef()
    const resolveHeightTimer = useRef()

    const [model, setModel] = useState()
    const [traits, setTraits] = useState([])

    const [openRte, setOpenRte] = useState(false)
    const [editorState, setEditorState] = useState('');
    const [convertedContent, setConvertedContent] = useState(null);
    const [rteInput, setRteInput] = useState('')
    const [isReact, setIsReact] = useState(false)
    const [items, setItems] = useState([])

    useEffect(() => {
        if (selectedModel) {
            console.log({ selectedModel })

            const attr = selectedModel?.getAttributes()

            let insideModel
            let isReactComp
            let insideAttr
            try {
                insideModel = getInsideModel(selectedModel)
                insideAttr = insideModel?.getAttributes()
                isReactComp = insideAttr?.is_react
                setIsReact(isReactComp)
            } catch (error) {

            }


            if (isReactComp) {
                const insideTraits = JSON.parse(JSON.stringify(insideModel?.getTraits() || []))
                setModel(insideModel)
                if (insideAttr[itemsKey]) {
                    setItems(JSON.parse(insideAttr[itemsKey]))
                }
                setTraits(insideTraits)
            } else {
                setModel(selectedModel)
                const mainTraits = JSON.parse(JSON.stringify(selectedModel?.getTraits() || []))
                if (attr[itemsKey]) {
                    setItems(JSON.parse(attr[itemsKey]))
                }
                setTraits(mainTraits)
            }
        }
    }, [selectedModel])

    useEffect(() => {
        if (rteInput) {
            setItems((prevState) => {
                let newState = [...prevState]
                newState[editIndex] = {
                    ...newState[editIndex],
                    [rteInput]: convertedContent
                }
                return newState
            })
        }
    }, [convertedContent])

    useEffect(() => {
        if (items?.length && model) {
            handleInsideAttrChange({ model: model, key: itemsKey, value: JSON.stringify(items), isOutside: isReact })
        }
    }, [items, model])

    return (
        <div
            className="tw-flex tw-flex-col tw-gap-2"
        >
            {traits?.map?.((trait) => {
                switch (trait.name) {
                    case 'menu-item':
                        return (
                            <Dialog
                                open={true}
                                onClose={() => {
                                    onBack()
                                }}
                            >
                                <EditMenuLinkDialog
                                    traits={traits}
                                    menu={items}
                                    setMenu={setItems}
                                    editIndex={editIndex}
                                    onClose={() => {
                                        onBack()
                                    }}
                                    onDelete={() => {
                                        setItems((prevState) => {
                                            let newState = [...prevState]
                                            newState = newState?.filter((data, index) => index !== editIndex)
                                            return newState
                                        })
                                        onBack()
                                    }}
                                    pages={pages}
                                />
                            </Dialog>
                        )
                    case 'item-image':
                        return (
                            <ImageUpload
                                label={trait.label}
                                title={trait.placeholder}
                                imageSrc={items[editIndex]?.image}
                                setImageSrc={(image) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            image: image
                                        }
                                        return newState
                                    })
                                }}
                                onUpload={(link) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            image: link
                                        }
                                        return newState
                                    })
                                    resolveHeight(selectedModel, resolveHeightTimer)
                                }}
                                onImageLinkChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            image_link: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onImageTargetChange={(event) => {
                                    console.log('checl', event.target.checked)
                                    let val = '_self'
                                    if (event.target.checked) {
                                        val = '_blank'
                                    } else {
                                        val = '_self'
                                    }
                                    setItems((prevState) => {
                                        let newState = [...prevState]

                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            image_target: val
                                        }
                                        return newState
                                    })
                                }}
                                imageLink={items[editIndex]?.image_link}
                                imageTarget={items[editIndex]?.image_target}
                                onDelete={() => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            image: ''
                                        }
                                        return newState
                                    })
                                    resolveHeight(selectedModel, resolveHeightTimer)
                                }}
                                hideImageLink={trait.hideimagelink}
                            />
                        )
                    case 'item-label-image':
                        return (
                            <ImageUpload
                                label={trait.label}
                                title={trait.placeholder}
                                imageSrc={items[editIndex]?.labelimage}
                                setImageSrc={(image) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            labelimage: image
                                        }
                                        return newState
                                    })
                                }}
                                onUpload={(link) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            labelimage: link
                                        }
                                        return newState
                                    })
                                    resolveHeight(selectedModel, resolveHeightTimer)
                                }}
                                onImageLinkChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            labelimage_link: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onImageTargetChange={(event) => {
                                    let val = '_self'
                                    if (event.target.checked) {
                                        val = '_blank'
                                    } else {
                                        val = '_self'
                                    }
                                    setItems((prevState) => {
                                        let newState = [...prevState]

                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            labelimage_target: val
                                        }
                                        return newState
                                    })
                                }}
                                imageLink={items[editIndex]?.labelimage_link}
                                imageTarget={items[editIndex]?.labelimage_target}
                                onDelete={() => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            labelimage: ''
                                        }
                                        return newState
                                    })
                                    resolveHeight(selectedModel, resolveHeightTimer)
                                }}
                            />
                        )
                    case 'item-title':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.title}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            title: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('title')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.title, true)
                                }}
                                rows={1}
                                fullWidth={true}
                            />
                        )
                    case 'item-label':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.label}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            label: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('label')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.label, true)
                                }}
                                rows={1}
                                fullWidth={true}
                            />
                        )
                    case 'item-price':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.price}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            price: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('price')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.price, true)
                                }}
                                rows={1}
                                fullWidth={true}
                            />
                        )
                    case 'item-rating':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.rating}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            rating: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('rating')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.rating, true)
                                }}
                                rows={1}
                                fullWidth={true}
                            />
                        )
                    case 'item-position':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.itemposition}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            itemposition: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('itemposition')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.itemposition, true)
                                }}
                                rows={1}
                                fullWidth={true}
                            />
                        )
                    case 'item-description':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.description}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            description: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('description')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.description, true)
                                }}
                                multiline
                                rows={3}
                                fullWidth={true}
                            />
                        )
                    case 'item-pointtwo':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.pointtwo}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            pointtwo: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('pointtwo')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.pointtwo, true)
                                }}
                                rows={1}
                                fullWidth={true}
                            />
                        )
                    case 'item-pointthree':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.pointthree}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            pointthree: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('pointthree')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.pointthree, true)
                                }}
                                rows={1}
                                fullWidth={true}
                            />
                        )
                    case 'item-pointfour':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={items[editIndex]?.pointfour}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            pointfour: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    setRteInput('pointfour')
                                    htmlToDraftConverter(setEditorState, items[editIndex]?.pointfour, true)
                                }}
                                rows={1}
                                fullWidth={true}
                            />
                        )
                    case 'item-ratingvalue':
                        return (
                            <>
                                <div
                                    className={styles.label_text}
                                >
                                    Rating
                                </div>
                                <Rating
                                    name="half-rating"
                                    precision={0.5}
                                    value={items[editIndex]?.rating}
                                    onChange={(event, newValue) => {
                                        setItems((prevState) => {
                                            let newState = [...prevState]
                                            newState[editIndex] = {
                                                ...newState[editIndex],
                                                rating: newValue
                                            }
                                            return newState
                                        })
                                    }}
                                />
                            </>
                        )
                    case 'item-button':
                        return (
                            <>

                                <GenericSwitchWithLabel
                                    label="Button"
                                    height={18}
                                    width={36}
                                    thumb={13}
                                    translateX={18}
                                    checked={items[editIndex]?.itemIsButton}
                                    onChange={(event) => {
                                        setItems((prevState) => {
                                            let newState = [...prevState]
                                            newState[editIndex] = {
                                                ...newState[editIndex],
                                                itemIsButton: !items[editIndex]?.itemIsButton
                                            }
                                            return newState
                                        })
                                    }}
                                />


                                {items[editIndex]?.itemIsButton && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 20
                                        }}
                                    >
                                        <GenericTextFieldWithLabel
                                            variant="outlined"
                                            label="Label"
                                            colorType="secondary"
                                            value={items[editIndex]?.buttontitle}
                                            onChange={(event) => {
                                                setItems((prevState) => {
                                                    let newState = [...prevState]
                                                    newState[editIndex] = {
                                                        ...newState[editIndex],
                                                        buttontitle: event.target.value
                                                    }
                                                    return newState
                                                })
                                            }}

                                            fullWidth={true}
                                        />

                                        <LinkSelector
                                            variant="outlined"
                                            label="Link To"
                                            colorType="secondary"
                                            value={items[editIndex]?.buttonLink}
                                            tab={items[editIndex]?.tab}
                                            onTabChange={(checked) => {
                                                setItems((prevState) => {
                                                    let newState = [...prevState]
                                                    newState[editIndex] = {
                                                        ...newState[editIndex],
                                                        tab: checked
                                                    }
                                                    return newState
                                                })
                                            }}
                                            onChange={(event) => {
                                                setItems((prevState) => {
                                                    let newState = [...prevState]
                                                    newState[editIndex] = {
                                                        ...newState[editIndex],
                                                        buttonLink: event.target.value
                                                    }
                                                    return newState
                                                })
                                            }}
                                            fullWidth={true}
                                            pages={pages}
                                        />
                                    </div>
                                )}
                            </>
                        )
                    case 'item-social':
                        return (
                            <>
                                <GenericSwitchWithLabel
                                    label={trait.label}
                                    description={trait.placeholder}
                                    height={18}
                                    width={36}
                                    thumb={13}
                                    translateX={18}
                                    checked={items[editIndex]?.is_social}
                                    onChange={(event) => {
                                        if (Boolean(event.target.checked)) {
                                            setItems((prevState) => {
                                                let newState = [...prevState]
                                                newState[editIndex] = {
                                                    ...newState[editIndex],
                                                    is_social: Boolean(event.target.checked) ? 'true' : '',
                                                    social: [trait.data]
                                                }
                                                return newState
                                            })
                                        } else {
                                            setItems((prevState) => {
                                                let newState = [...prevState]
                                                newState[editIndex] = {
                                                    ...newState[editIndex],
                                                    is_social: Boolean(event.target.checked) ? 'true' : '',
                                                }
                                                return newState
                                            })
                                        }
                                    }}
                                />
                                {Boolean(getParsedBoolean(items[editIndex]?.is_social)) && (
                                    <div
                                        className={styles.item}
                                        onClick={() => {
                                            onChange('itemEdit2')
                                        }}
                                    >
                                        <div>
                                            Edit social links
                                        </div>
                                        <KeyboardArrowRight />

                                    </div>
                                )}
                            </>
                        )
                    case 'item-direction':
                        return (
                            <GenericSelectWithLabel
                                label={trait.label}
                                colorType="secondary"
                                value={items[editIndex]?.direction}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        let newState = [...prevState]
                                        newState[editIndex] = {
                                            ...newState[editIndex],
                                            direction: event.target.value
                                        }
                                        return newState
                                    })
                                }}
                                fullWidth={true}
                            >
                                <MenuItem
                                    value="row"
                                >
                                    Row
                                </MenuItem>
                                <MenuItem
                                    value="row-reverse"
                                >
                                    Row reverse
                                </MenuItem>
                            </GenericSelectWithLabel>
                        )
                    default:
                        break;
                }
            })}

            <RichTextEditor3
                isModal={true}
                editorRef={editorRef}
                open={openRte}
                onClose={() => {
                    setOpenRte(false)
                }}
                editorState={editorState}
                setEditorState={setEditorState}
                setConvertedContent={setConvertedContent}
            />
        </div>
    )
}

export default ItemsTraitResolver