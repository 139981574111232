import React, {useRef} from 'react';
import {Typography} from '@mui/material';
import { PrimaryButton } from '../../../../util_comps/CustomButton';
import { Close } from '@mui/icons-material';
import {useClickOutside} from '../../../../hooks/useClickOutside'

const Modal = (props) => {
    const modalRef = useRef(null); 
    const handleClose = () => {
        if(props.active){
            props.setActiveModal({name: "none", props: {}});
        }
    };
    useClickOutside(modalRef,handleClose);
    return (
        <div ref={modalRef} className='inventory-modal' style={{position: 'fixed' , display: props.active === true ? 'initial' : 'none' ,backgroundColor: 'white' , zIndex: '999999999',top: '25%', left: '60%', width: '35vw' ,transform: 'translate(-60%,-25%)'}}>
            <div style={{padding: '20px 28px 28px 28px'}}>
                <div style={{display: 'flex', position: 'relative' ,alignItems: 'center', justifyContent: 'center', marginBottom: '30px'}}>
                    <Typography variant="h6" style={{fontSize: '1.1rem', textAlign: 'center'}}>Mark As Staff Pick</Typography>
                    <div onClick={handleClose} style={{fontSize: '23px', cursor: 'pointer' ,position: 'absolute', left: '95%', transform: 'translateY(-2px)' ,color: 'black', display: 'flex', alignItems: 'center'}}>
                        <Close fontSize="inherit" color="inherit" />
                    </div>
                </div>
                <div>
                    <Typography variant="h6" style={{fontSize: '.9rem', marginBottom: '5px'}}>Why Do You Like This This Product</Typography>
                    <textarea rows={7} style={{resize: 'none', width: '100%', fontSize: '.9rem', fontFamily: "Poppins, sans-serif", padding: '7px'}}></textarea>
                    <Typography variant="caption" style={{fontSize: '.7rem', width: '70%'}}>Briefly point out and describe why this product deserves to be marked as staff pick</Typography>
                </div>
                <div style={{marginTop: '30px', display: 'flex', justifyContent: 'flex-end'}}>
                    <PrimaryButton style={{padding: '10px 25px',fontSize: '.95rem'}}>Submit</PrimaryButton>
                </div>
            </div>
        </div>
    )
};

export default Modal;