import React, { useEffect, useRef, useState } from "react";
import { Typography, TextField } from "@mui/material";
import { PrimaryButton } from "../../../../util_comps/CustomButton";
import { Close, SearchOutlined } from "@mui/icons-material";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { makeStyles } from "@mui/styles";
import axios from '../../../../utils/axiosConfig';
import { bizBaseurl } from "../../../../env";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import uuid from "uuid";
import { toast } from "react-toastify";
import Loader from "../../../Library/Loader";
import DragHereChip from "../../../Library/DragHereChip/DragHereChip";
import styles from './AddTagToProduct.module.scss'
import GenericButton from "../../../Library/GenericButton/GenericButton";

const Modal = (props) => {

  const handleClose = () => {
    if (props.active) {
      props.setActiveModal({ name: "none", props: {} });
    }
  };

  const [assignedTags, setAssignedTags] = useState([]);
  const [assignableTags, setAssignableTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [columns, setColumns] = useState({});
  const [isLoading, setIsLoading] = useState(true)
  const [newTagTitle, setNewTagTitle] = useState('')

  const [showAddCollection, setShowAddCollection] = useState(false)

  const fetchTags = async () => {
    let res = await axios.get(`/tag/get-tag-from-product/${props.productId}`);
    let data = res.data.data.result;
    setAssignedTags(data.assignTags);
    setAssignableTags(data.availableTags)

    setColumns({
      [0]: {
        name: "From",
        label: "Available Collections",
        items: data.availableTags
      },
      [1]: {
        name: "To",
        label: "Currently Assigned Collections",
        items: data.assignTags
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    if (props.active) {
      fetchTags()
    }
  }, [props.productId, props.active]);

  const submit = async () => {
    try {

      let res = await axios.post(`/tag/update-product-tag/${props.productId}`, {
        tags: Object.values(columns).filter((data) => data.name == 'To')[0]?.items?.map((data) => data.id)
      });
      handleClose()
      toast.success('Success')
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message)
      };
    }
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  const handleAdd = () => {
    axios.post('tag/create-tag-with-products', {
      title: newTagTitle,
      product_ids: []
    })
      .then((response) => {
        fetchTags()
        setNewTagTitle('')
        setShowAddCollection(false)
      })
  }

  return (
    <Dialog
      open={props.active}
      onClose={handleClose}
      maxWidth="lg"
    >
      <Dialog
        open={showAddCollection}
        onClose={() => {
          setShowAddCollection(false)
        }}
      >
        <DialogContent>
          <div
            className={styles.input_section}
          >
            <label
              className={styles.input_label}
              htmlFor="collectionName"
            >
              Collection Name
            </label>
            <TextField
              InputProps={{
                classes: {
                  notchedOutline: styles.input_box_style
                }
              }}
              inputProps={{
                style: {
                  padding: '9px 12px'
                }
              }}
              variant="outlined"
              id="collectionName"
              name="collectionName"
              type="text"
              fullWidth
              value={newTagTitle}
              onChange={(event) => {
                setNewTagTitle(event.target.value)
              }}
              required={true}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            style={{
              backgroundColor: 'black',
              color: 'white'
            }}
            variant="contained"
            onClick={handleAdd}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DialogTitle
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h6" style={{ fontSize: "1rem" }}>
          Assign {props.productName} to Collections
        </Typography>

        <Button
          style={{
            backgroundColor: '#676767',
            color: 'white'
          }}
          variant="contained"
          onClick={() => {
            setShowAddCollection(true)
          }}
        >
          Add Collection
        </Button>
      </DialogTitle>
      <DialogContent>

        {isLoading ? (
          <Loader />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <DragDropContext
              onDragEnd={result => onDragEnd(result, columns, setColumns)}
            >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <>
                    {index === 1 && (
                      <DragHereChip />
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: '48%'
                      }}
                      key={columnId}
                    >
                      <div style={{ margin: 8, width: '100%' }}>
                        <Droppable droppableId={columnId} key={columnId}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                style={{
                                  border: '1px solid #dddddd',
                                  minWidth: 350
                                }}
                              >
                                <div
                                  style={{
                                    padding: '15px',
                                    fontWeight: 600,
                                  }}
                                >
                                  {column.label}
                                </div>
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    padding: 4,
                                    minHeight: 399,
                                    maxHeight: 400,
                                    overflow: 'auto'
                                  }}
                                >
                                  {column.items.map((data, index) => {
                                    return (
                                      <Draggable
                                        key={data.title}
                                        draggableId={data.title}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                userSelect: "none",
                                                padding: 4,
                                                margin: "0 0 8px 0",
                                                minHeight: "30px",
                                                padding: 4,
                                                margin: '0px 0px 8px',
                                                minHeight: 30,
                                                display: 'flex',
                                                ...provided.draggableProps.style
                                              }}
                                            >
                                              <div
                                                style={{
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  padding: 10,
                                                  boxShadow: '0px 1px 4px #0000001a',
                                                  width: '100%',
                                                  borderRadius: '4px'
                                                }}
                                              >
                                                {data.title}
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    </div>
                  </>
                );
              })}
            </DragDropContext>
          </div>
        )}

        <div
          style={{
            marginTop: "40px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <GenericButton
            variant="contained"
            onClick={submit}
          >
            Submit
          </GenericButton>

          <Button
            style={{
              backgroundColor: '#dddddd',
              color: 'black',
              marginLeft: 10
            }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
