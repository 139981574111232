import { useNavigate, useParams } from 'react-router'

import React from 'react';
import styles from './GenericPostTable.module.scss';
import Loader from '../Loader/Loader';
import { Pagination } from '@mui/material';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import imageBaseUrl from '../../Util/imageBaseUrl';
import { FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa';

const GenericPostTable = (props) => {
  const navigate = useNavigate()

  const getInitialsAvatar = (name) => {
    return `https://ui-avatars.com/api/?background=random&length=1&name=${name}`;
  };

  return (
    <div className={styles.list_grid_container}>
      <div className={styles.studentTableContainer}>
        <table className={styles.studentTable}>
          <thead>
            {props.headings.map((data) => (
              <th>
                <div className={styles.table_head_container}>
                  {data.label}
                  {/* <div>
                                {true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </div> */}
                </div>
              </th>
            ))}
          </thead>

          <tbody>
            {props.posts.map((data) => (
              <tr
                onClick={() => {
                  navigate(`/marketing/post-details/${data.post_group_id}`);
                }}
              >
                <td>
                  {data.images?.length > 0 && data.images[0]?.image ? (
                    <>
                      {data.images[0]?.type_id == 1 ? (
                        <video
                          style={{
                            height: 60,
                            maxWidth: 100,
                            objectFit: 'contain',
                          }}
                          src={`${imageBaseUrl}/SocialPostImage/${data.post_group_id}/${data.images[0]?.image}`}
                        />
                      ) : (
                        <>
                          <img
                            style={{
                              height: 60,
                              maxWidth: 100,
                              objectFit: 'contain',
                            }}
                            src={`${imageBaseUrl}/SocialPostImage/${data.post_group_id}/${data.images[0]?.image}`}
                          />
                        </>
                      )}
                    </>

                  ) : (
                    <img
                      style={{
                        height: 60,
                        maxWidth: 100,
                        objectFit: 'contain',
                      }}
                      src={getInitialsAvatar(data?.content)}
                    />
                  )}
                </td>
                <td>
                  <div className={styles.description_container}>
                    {data.content}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      gap: 5,
                    }}
                  >
                    {data.posted_on?.includes('0') && (
                      <div className={styles.fb_bg}>
                        <FaFacebookSquare
                          style={{ fill: 'white', fontSize: 18 }}
                        />
                      </div>
                    )}

                    {data.posted_on?.includes('1') && (
                      <div className={styles.in_bg}>
                        <FaInstagram style={{ fill: 'white', fontSize: 18 }} />
                      </div>
                    )}

                    {data.posted_on?.includes('2') && (
                      <div className={styles.tw_bg}>
                        <FaTwitter style={{ fill: 'white', fontSize: 18 }} />
                      </div>
                    )}
                  </div>
                </td>
                <td
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {format(new Date(data.post_create_at), 'dd LLL yyyy')}
                </td>

                <td>{data.insights && data.insights[0]?.total_impression}</td>
                <td>{data.insights && data.insights[0]?.total_post_view}</td>
                <td>{data.insights && data.insights[0]?.total_like}</td>
                <td>{data.insights && data.insights[0]?.total_comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {!props.hidePagination && (
        <Pagination
          classes={{
            root: styles.schoolPaginateRootEl,
            ul: styles.schoolPaginateListEl,
          }}
          count={props.totalPages}
          page={props.pageNo}
          onChange={(event, value) => {
            props.handleChangePage(event, value);
          }}
        />
      )}
    </div>
  );
};

export default GenericPostTable;
