import { CheckCircleRounded } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const StepChip = ({ isDone, title, description, link }) => {

    const navigate = useNavigate()

    return (
        <div
            className="tw-flex tw-rounded tw-border tw-border-solid tw-border-border-color tw-p-3 tw-gap-2 tw-cursor-pointer"
            onClick={() => {
                navigate(link)
            }}
        >
            <div>
                {isDone ? <CheckCircleRounded style={{ color: '#0AB221' }} /> : (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.85" y="0.85" width="22.3" height="22.3" rx="11.15" fill="white" />
                        <rect x="0.85" y="0.85" width="22.3" height="22.3" rx="11.15" stroke="#B4B4B4" stroke-width="1.7" stroke-dasharray="5 5" />
                    </svg>
                )}
            </div>

            <div>
                <div
                    className="tw-font-medium tw-text-sm"
                >
                    {title}
                </div>

                <div
                    className="tw-font-normal tw-text-xs tw-text-[#8B8B8B]"
                >
                    {description}
                </div>
            </div>
        </div>
    )
}

export default StepChip