import { Dialog, DialogContent } from '@mui/material'
import React from 'react'
import ProductCardSliderVenue from '../../ProductCardSliderVenue/ProductCardSliderVenue'
import ProductSquareChipSlider from '../../ProductSquareChipSlider/ProductSquareChipSlider'
import styles from './ProductPreview.module.scss'

const ProductPreview = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                style: {
                    maxWidth: 'unset',
                    margin: 0,
                    width: '96%'
                }
            }}
        >
            {props.isSquarePreview ? (
                <div
                    className={styles.product_square_chip_slider}
                >
                    <ProductSquareChipSlider
                        products={props.products}
                        isBigPreview={true}
                    />
                </div>
            ) : (
                <div
                    className={styles.product_card_slider_container}
                >
                    <ProductCardSliderVenue
                        products={props.products}
                        isLoading={props.isLoading}
                        slide={7}
                        isBigPreview={true}
                        baseImageLink={props.baseImageLink}
                        isBaseUrl={Boolean(props.baseImageLink)}
                    />
                </div>
            )}
        </Dialog>
    )
}

export default ProductPreview