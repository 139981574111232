import React from 'react'
import { Navigate } from 'react-router-dom'
import routePaths from '../../config/route-paths'
import GettingStarted from '../GettingStarted/GettingStarted'

const GettingStartedRoute = (props) => {
    if (localStorage.userToken) {
        return <Navigate to={routePaths.venueOrderListing} replace />
    }
    return (
        <GettingStarted {...props} />
    )
}

export default GettingStartedRoute