import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from '../../utils/axiosConfig'
import styles from './ProductListForCollection.module.scss'

const ProductListForCollection = () => {

    const [products, setProducts] = useState([])

    const fetchProducts = () => {
        axios.get('/product/master-list', {
            params: {
                limit: 20
            }
        })
            .then((response) => {
                setProducts(response.data.data.list)
            })
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                border: '1px solid lightgray',
                height: 370,
                overflow: 'auto'
            }}
        >
    <List>
        {products.map((data) => (
            <ListItem
                className={styles.list_item}
                disablePadding
                disableGutters
            >
                <ListItemButton>
                    <ListItemIcon>
                        <img
                            src={data.image_file}
                            height={30}
                        />
                    </ListItemIcon>
                    <ListItemText className={styles.product_name} primary={data.Name} />
                </ListItemButton>
            </ListItem>
        ))}
    </List>
        </Box >
    )
}

export default ProductListForCollection