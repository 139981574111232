import React, { useEffect, useState, useRef } from "react";
import { Typography } from "@mui/material";
import login from "../../../assets/login.png";
import pacakage from "../../../assets/pacakage.png";
import websiteinventory from "../../../assets/websiteinventory.png";
import customizeorder from "../../../assets/customizeorder.png";
import notification from "../../../assets/productred.png";
import {useNavigate} from 'react-router-dom';
import Buttons from '../Signup/Parts/Buttons';
import GetStarted from '../../../util_comps/GetStarted';
import Const from "../../helpers/const";
import './HowItsWork.scss'
import PosIntegrations from "../../PosIntegrations/PosIntegrations.js";
import { cns, classname } from '../../../utils/bem';
import one from "../../../assets/01.png";
import two from "../../../assets/02.png";
import three from "../../../assets/03.png";
import four from "../../../assets/04.png";
import five from "../../../assets/05.png";
import laptop from "../../../assets/laptoptitle.png";
import { maxWidth } from "@mui/system";
import desistorehow from "../../../assets/desistore-howitsworks.png"

const HowItsWork = (props) => {
   const cn = classname('HowItWorks')
   const history = useNavigate();
return (

   <div className={cns(cn(),props.className,)}>
        
      <div className={cn('main-section')}>
      

         <div className={cn('HowItWorks-title')}>
         <div className={cn('help-page-body')}>
            <div className={cn('HowItWorks-title-image')}>
               <img src={Const.isOurDesiStore ? desistorehow : laptop} />
            </div>
            <div className={cn('HowItWorks-title-text')}>
            <Typography variant="h1">
               Start selling online in just 15 minutes
            </Typography>
            <p>It’s really that easy. Here’s how.</p>
            </div>
         </div>
      </div>

         <div className={cn('section')}>
            <div className={cn('inner-section')}>              
               <span className={cn('box-shadow')}> <img src={one} /></span>
               <div className={cn('left-scetion')}>
               <img src={login} />
               <div className={cn('left-text-scetion')}>
               <Typography variant="h2">
               Create an account and log in
               </Typography>
               <p>Fill out the form on the sign-up page and log in to your account on the admin website</p>
               </div>
               </div>              
               </div>
         </div>

         <div className={cn('grey-section')}>
            <div className={cn('inner-section')}>              
               <div className={cn('left-scetion')}>
               <div className={cn('left-text-scetion')}>
               <Typography variant="h2">
               Setup your payment & Legal Information
               </Typography>
               <p>Upload the necessary legal documents and licenses for approval</p>
               </div>
               <img src={websiteinventory} />
               </div> 
               <span className={cn('box-shadow')}> <img src={two} /></span>
               </div>
         </div>

         <div className={cn('section')}>
            <div className={cn('inner-section')}>              
               <span className={cn('box-shadow')}> <img src={three} /></span>
               <div className={cn('left-scetion')}>
               <img src={pacakage} />
               <div className={cn('left-text-scetion')}>
               <Typography variant="h2">
               Set up your Fulfillment Methods
               </Typography>
               <p>Update your website with your store hours and determine your pickup, delivery, and/or shipping options</p>
               </div>
               </div>              
               </div>
         </div>


         <div className={cn('grey-section')}>
            <div className={cn('inner-section')}>              
               <div className={cn('left-scetion')}>
               <div className={cn('left-text-scetion')}>
               <Typography variant="h2">
               Enter your Products
               </Typography>
               <p>Schedule your POS integration or enter your products manually</p>
               </div>
               <img src={notification} />
               </div> 
               <span className={cn('box-shadow')}> <img src={four} /></span>
               </div>
         </div>


         <div className={cn('section')}>
            <div className={cn('inner-section')}>              
               <span className={cn('box-shadow')}> <img src={five} /></span>
               <div className={cn('left-scetion')}>
               <img src={customizeorder} />
               <div className={cn('left-text-scetion')}>
               <Typography variant="h2">
               Customize your Website & app
               </Typography>
               <p>Add your logo and store photos and start customizing your online storefront. You’re ready to sell online!</p>
               </div>
               </div>              
               </div>
         </div>


         <PosIntegrations/>

         
         

      </div>



   </div>
);
};
export default HowItsWork;