import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorder } from '../../../../../CustomPageBuilder/EditSection/Util/helpers';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { Menu } from '@mui/icons-material';
import styles from './ReOrderDialog.module.scss'
import EditModifierOptionsDialog from './EditModifierOptionsDialog/EditModifierOptionsDialog';

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 5px',
    margin: `0 0 8px 0`,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});


const ReOrderDialog = (props) => {
console.log('🚀OUTPUT --> ReOrderDialog props:', props);

const [showEditModifier, setShowEditModifier] = useState(false)
const [editModifierObj, setEditModifierObj] = useState({})
const [editIndex, setEditIndex] = useState(0)

const handleEdit = (item, index) => {
    setEditModifierObj(item);
    setEditIndex(index);
    setShowEditModifier(true);
}

 

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            props.selectedOptions,
            result.source.index,
            result.destination.index
        );
        
        props.onSave(newitems) 
    };


    return (
        <DialogContent sx={{ p: 1 }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.scroll}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {props.selectedOptions?.map((item, index) => {
                                return (
                                    <Draggable
                                        key={index.toString()}
                                        draggableId={index.toString()}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() => {

                                                }}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                )}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        borderRadius: 4,
                                                        border: '1px solid #D3D3D3',
                                                        padding: '8px 10px'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                            width: '75%',
                                                            gap: 5
                                                        }}
                                                    >
                                                        {item?.title}
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 6,
                                                            color: 'black',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <Button variant='text' color='primary' sx={{ whiteSpace: 'nowrap' }} onClick={() => handleEdit(item, index)} >
                                                            Edit Option
                                                        </Button>
                                                        <Tooltip
                                                            // style={{
                                                            //     width: 140
                                                            // }}
                                                            title="Drag item in order to arrange"
                                                            placement="top"
                                                        >
                                                            <Menu />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            
            <Dialog
                open={showEditModifier}
                onClose={() => {
                    setShowEditModifier(false)
                }}
                PaperProps={{
                    style: {
                        width: '100%'
                    }
                }}
                classes={{ 
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-3xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <EditModifierOptionsDialog
                    editObj={editModifierObj}
                    editIndex={editIndex}
                    // isEdit={true}
                    {...props}
                    // handleDelete={handleDelete}
                    onClose={() => {
                        setEditModifierObj({});
                        setEditIndex(0);
                        setShowEditModifier(false);
                    }}
                />
            </Dialog>

        </DialogContent>
    )
}

export default ReOrderDialog;

