const initialState = {
    isSuggestionsLoading: false,
    inventories: {
        data: [],
        pagination: [],
    },
    suggestions: [],
    formats: [],
    isLoading: false,
};
function inventory(state = initialState, action) {
    switch (action.type) {
        case "SET_SUGGESTIONS_REQUEST": {
            return {
                ...state,
                isSuggestionsLoading: true
            }
        }
        case "SET_INVENTORIES_REQUEST": {
            return {
                ...state,
                isLoading: true
            }
        };
        case "SET_INVENTORIES": {
            return {
                ...state,
                isLoading: false,
                inventories: { data: action.payload.inventories, pagination: action.payload.pagination }
            }
        };
        case "EDIT_INVENTORIES": {
            let index = state.inventories.data.findIndex(i => i.id === action.payload.id);
            let newInventories = [...state.inventories.data];
            newInventories[index] = action.payload.data;
            return { ...state, inventories: { data: newInventories, pagination: state.inventories.pagination } }
        };
        case "SET_FORMATS": {
            return { ...state, formats: action.payload }
        };
        case "DELETE_INVENTORY": {
            let newInventories = state.inventories.data.filter(e => e.id !== action.payload);
            return { ...state, inventories: { data: newInventories, pagination: state.inventories.pagination } }
        };
        case "SET_SUGGESTIONS": {
            return {
                ...state,
                suggestions: action.payload,
                isSuggestionsLoading: false
            }
        };
        case "CHANGE_PRODUCT_VISIBILITY": {
            let newInventories = state.inventories.data.map((val) => {
                if (val.id === action.payload) {
                    return {
                        ...val,
                        noWebSales: val.noWebSales === 0 ? 1 : 0
                    }
                } else {
                    return val;
                }
            })
            return {
                ...state,
                inventories: {
                    pagination: state.inventories.pagination,
                    data: newInventories
                }
            }
        }
        default:
            return state;
    }
};
export default inventory;  