import React from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import RangeSliderContainer from "./RangeSliderContainer";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";
import LightTooltip from "../../../../util_comps/LightTooltip";

const PriceRange = (props) => {
    return (
        <React.Fragment>
            <Accordion
                TransitionProps={{ unmountOnExit: true }}
                square
                onChange={() => props.handlePanelOpen("price")}
                expanded={props.activePanel === "price"}
            >
                <AccordionSummary
                    expandIcon={
                        props.activePanel === "price" ? (
                            <IndeterminateCheckBoxIcon style={{ fill: "black" }} />
                        ) : (
                            <AddBoxIcon style={{ fill: "black" }} />
                        )
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <LightTooltip title={<span style={{ display: 'inline-block', padding: '0 15px' }}>Only show products that fall within a certain price range.</span>} arrow placement="top">
                        <Typography style={{ fontSize: '16px', color: (props.initState.current.filters.maxPrice !== props.filters.maxPrice || props.initState.current.filters.minPrice !== props.filters.minPrice) && props.activePanel !== "price" ? 'red' : 'black' }} variant="h6">Price Range</Typography>
                    </LightTooltip>
                </AccordionSummary>
                <AccordionDetails style={{ marginBottom: "4%" }}>
                    <RangeSliderContainer color="red">
                        <InputRange
                            minLabel="none"
                            maxLabel="none"
                            maxValue={500}
                            minValue={0}
                            value={{ max: props.filters.maxPrice, min: props.filters.minPrice }}
                            onChange={(value) => props.setFilters({ ...props.filters, maxPrice: value.max, minPrice: value.min })}
                        />
                    </RangeSliderContainer>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

export default PriceRange;
