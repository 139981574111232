import React, { useEffect, useRef, useState } from 'react';
import { useBlocker, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { Button, ClickAwayListener, Collapse, Dialog, DialogActions, DialogContent, Drawer, IconButton, Menu, MenuItem, Select } from '@mui/material';
// import { grapesjsInit } from 'webpage-builder-dev/grapesjs/init';
import { grapesjsInit } from 'webpage-builder/grapesjs/init';
import axios from '../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import styles from './Template.module.scss'
import { CheckCircle, CheckCircleOutline, Close, ColorLensOutlined, DeleteForever, DesktopWindowsOutlined, ExpandLess, ExpandMore, MoreHoriz, PhoneAndroid, Refresh, RefreshOutlined, Tablet, Visibility, VisibilityOff } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import Loader from '../../Library/Loader';
import baseUrl, { siteBaseUrl } from '../../../utils/baseUrl';
import { useSelector } from 'react-redux';
import { allSector, blocksList, findUniqueObject, tabbarBlocksList, footerBlocksList, headerBlocksList, styleSector } from './constants';
import TopPanel from './TopPanel/TopPanel';
import clsx from 'clsx';
import PageLoader from '../../Library/PageLoader/PageLoader';
import ReactConfetti from 'react-confetti';
// import 'driver.js/dist/driver.css';
import Signup from '../Signup/Signup';
import LoaderGif from '../LoaderGif/LoaderGif';
import { getImage2 } from '../../../utils/helpers';

const Template = ({ onNext, onPrev, lg, sm, isLoading: isSignupLoading, formik, template, onRegenerate, templateLink, isTemplateLoading }) => {

  const ctx = useOutletContext()
  const history = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const [device, setDevice] = useState('desktop')
  const [showConfetti, setShowConfetti] = useState(true)
  const [confettiAmount, setConfettiAmount] = useState(200);

  // const device = searchParams.get('device') || 'desktop'

  const formsRef = useRef([]);
  const deviceRef = useRef();
  const saveDeviceSelectionRef = useRef();
  const formCss = useRef();
  const isPreviewRef = useRef()
  const ctxRef = useRef();
  const venueIdRef = useRef();
  const venueNameRef = useRef();
  const isBlockerRef = useRef()
  const handleSaveRef = useRef()

  const confirm = useConfirm()

  ctxRef.current = ctx
  deviceRef.current = device


  const user = useSelector((state) => state.user);
  const [customPageData, setCustomPageData] = useState()
  const [editor, setEditor] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [isSetting, setIsSetting] = useState(false);
  const [header, setHeader] = useState({});
  const [footer, setFooter] = useState({});
  const [bottomTabbar, setBottomTabbar] = useState({});
  const [isPagesLoading, setIsPagesLoading] = useState(true);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [formBlockId, setFormBlockId] = useState();
  const [isPreview, setIsPreview] = useState(false)
  const [isShowGrid, setIsShowGrid] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [pages, setPages] = useState([])
  const [showAddNewPage, setShowAddNewPage] = useState(false)
  const [forms, setForms] = useState([])
  const [subDomain, setSubDomain] = useState('')
  const [blockCategories, setBlockCategories] = useState([])
  const [collectionBlockCategories, setCollectionBlockCategories] = useState([])
  const [showSection, setShowSection] = useState(false)
  const [isAddSectionUp, setIsAddSectionUp] = useState(false)
  const [showAddSection, setShowAddSection] = useState(true)
  const [showEditMenu, setShowEditMenu] = useState(false)
  const [editMenuCoord, setEditMenuCoord] = useState({
    top: 0,
    right: 0
  })
  const [editType, setEditType] = useState('')
  const [isSectionSelected, setIsSectionSelected] = useState(false)
  const [showLayers, setShowLayers] = useState(false)
  const [isSaved, setIsSaved] = useState(false);
  const [saveDeviceSelection, setSaveDeviceSelection] = useState([])
  const [isMultiDevice, setIsMultiDevice] = useState(false)
  const [siteReady, setSiteReady] = useState(false)
  const [showSignup, setShowSignup] = useState(false)

  useEffect(() => {
    let interval;

    if (showConfetti && !isPageLoading) {
      // Decrease the confetti amount smoothly over 5 seconds
      const startTime = Date.now();
      const duration = 5000; // 5 seconds

      interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const progress = elapsedTime / duration;

        if (progress < 1) {
          // Calculate a new amount based on progress. Adjust this calculation as needed.
          const newAmount = 200 * (1 - progress);
          setConfettiAmount(newAmount);
        } else {
          // End the confetti and clear the interval
          setShowConfetti(false);
          clearInterval(interval);
        }
      }, 500); // Adjust interval timing as needed for smoother animation
    }

    return () => {
      clearInterval(interval);
    };
  }, [showConfetti, isPageLoading])

  // useEffect(() => {
  //   if(!isPageLoading){
  //     setTimeout(() => {
  //       setShowConfetti(false)
  //     }, 3000);
  //   }
  // }, [isPageLoading])

  useEffect(() => {
    isPreviewRef.current = isPreview
  }, [isPreview])

  useEffect(() => {
    ctxRef.current = ctx
  }, [ctx])

  useEffect(() => {
    saveDeviceSelectionRef.current = saveDeviceSelection
  }, [saveDeviceSelection])

  useEffect(() => {
    venueIdRef.current = user?.data?.venue_id_integer
    venueNameRef.current = user?.data?.name
  }, [user])

  useEffect(() => {
    if (editor) {
      editor.setDevice(device?.charAt(0)?.toUpperCase() + device?.slice(1))
    }
  }, [device, editor])


  useEffect(() => {
    if (customPageData && editor) {
      console.log('customPageData', customPageData)
      // localStorage.setItem(`gjsProject-${name}`, customPageData)
      localStorage.setItem(`gjsProject-Home`, customPageData)
      load()
    } else if (editor) {
      load()
    }
  }, [customPageData, editor]);

  useEffect(() => {
    console.log({ template })
    if (Object.keys(template || {})?.length) {
      try {
        let responseObj = JSON.parse(template?.payload || '{}');
        let responseObj2 = JSON.parse(template?.payload || '{}');
        let responseObjHeader = JSON.parse(template?.header_payload || '{}');
        let responseObjFooter = JSON.parse(template?.footer_payload || '{}');
        let headerComponents = []
        let footerComponents = []

        try {
          headerComponents = responseObjHeader['Header-pages'][0]['frames'][0]['component']['components']
          footerComponents = responseObjFooter['Footer-pages'][0]['frames'][0]['component']['components']
        } catch (error) {
          console.log(error)
        }

        Object.keys(responseObj).forEach(function (key) {
          if (key.includes('html')) {
            delete responseObj[key];
          }
          if (key.includes('css')) {
            delete responseObj[key];
          }
          if (key.includes('assets')) {
            responseObj['assets'] = responseObj[key];
            delete responseObj[key];
          }
          if (key.includes('pages')) {
            responseObj['pages'] = responseObj[key];
            responseObj2['pages'] = responseObj2[key];
            responseObj['pages'][0]['frames'][0]['component']['components'] = [
              ...headerComponents,
              ...responseObj['pages'][0]['frames'][0]['component']['components'],
              ...footerComponents
            ]
            responseObj['pages'].push({
              id: 'my-actuall-page',
              name: 'My actuall page',
              frames: [
                {
                  component: {
                    components: responseObj2['pages'][0]['frames'][0]['component']['components']
                  }
                }
              ]
            })
            // responseObj['pages'][1]['frames'][0]['component']['components'] = responseObj['pages'][0]['frames'][0]['component']['components']
            delete responseObj[key];
          }
          if (key.includes('styles')) {
            responseObj['styles'] = responseObj[key];
            delete responseObj[key];
          }
        });
        console.log(responseObj)
        setCustomPageData(JSON.stringify(responseObj));
      } catch (e) {
        console.log('aaaaaaaaaaaaaaa', e);
      } finally {
        grapesjsInit({
          onEditorInit,
          ctx,
          name: 'Home',
          blocksArr: blocksList,
          imageApi: '',
          typeId: 3,
          pages,
          baseUrl,
          subDomain: '',
          forms,
          venueId: user?.data?.venueid,
          editorType: 'biz',
          initScript: process.env.REACT_APP_WEB_BUILDER_INIT || "https://stage-wb.esplanda.com/bundle.js",
          initCss: process.env.REACT_APP_WEB_BUILDER_INIT_CSS || "https://stage-wb.esplanda.com/main.css",
          // initScript: "/grapesjs/js/bundle.js",
          // initCss: "/grapesjs/js/main.css"
        })
      }
    }
  }, [template]);

  const getContentDocument = (editor) => {
    const iframe = editor?.Canvas?.getFrameEl();
    if (iframe?.contentDocument) {
      return iframe?.contentDocument;
    } else if (iframe?.contentWindow && iframe?.contentWindow?.document) {
      return iframe?.contentWindow.document;
    } else if (iframe?.document) {
      return iframe?.document;
    } else {
      return null;
    }
  }

  const handleComponentHover = (model, editor) => {

    const parsedModel = JSON.parse(JSON.stringify(model))
    const parsedModel2 = JSON.parse(JSON.stringify(model.parent() || {}))
    var contentDocument = getContentDocument(editor);

    if ((parsedModel2?.type == 'wrapper') && contentDocument) {
      setIsSectionSelected(true)
      let section = contentDocument?.getElementById(model.ccid)
      let blacktint = contentDocument?.getElementById(`blacktint-${model.ccid}`)
      const elements = contentDocument?.querySelectorAll('[id^="blacktint-"]');

      elements?.forEach((element) => {
        if (element.id !== `blacktint-${model.ccid}`) {
          element?.remove()
        }
      })

      section.style = 'position: relative;'

      if (!blacktint) {
        let blackTintDiv = contentDocument?.createElement('div');
        blackTintDiv.style = "position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.3);display:grid;place-items:center;z-index:100"
        blackTintDiv.id = `blacktint-${model.ccid}`

        let button = contentDocument?.createElement('button');
        button.style = "border-radius: 10px;border: 1px solid #D3D3D3;background: #FFF;box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.08);width: 155px;height: 43px;"
        button.innerHTML = `<div style="display: flex;justify-content: center;gap: 10px;align-items: center;">
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
<path d="M4.58333 17.4154H5.86667L13.7729 9.50911L12.4896 8.22578L4.58333 16.132V17.4154ZM17.6917 8.17995L13.7958 4.32995L15.0792 3.04661C15.4306 2.69523 15.8623 2.51953 16.3744 2.51953C16.8865 2.51953 17.318 2.69523 17.6687 3.04661L18.9521 4.32995C19.3035 4.68134 19.4868 5.10545 19.5021 5.60228C19.5174 6.09911 19.3493 6.52292 18.9979 6.8737L17.6917 8.17995ZM16.3625 9.53203L6.64583 19.2487H2.75V15.3529L12.4667 5.6362L16.3625 9.53203ZM13.1313 8.86745L12.4896 8.22578L13.7729 9.50911L13.1313 8.86745Z" fill="black"/>
</svg>
        </div>

        <div>
        Edit Section
        </div>
        </div>`
        button.addEventListener("click", function () {
          handleSaveRef?.current()
        });
        blackTintDiv.appendChild(button)

        section.appendChild(blackTintDiv)
      }
      // if (!isButton && uri !== 'tabbar') {
      //     section.style = 'position: relative;'
      //     let coord = section.getBoundingClientRect()
      //     let coorStyle = getComputedStyle(section)
      //     // console.log(section,coord, getComputedStyle(section).height, coord.height, parseInt(coorStyle.paddingTop), parseInt(coorStyle.paddingBottom))
      //     let button = contentDocument?.createElement('button');
      //     button.style = `height: 34px; padding: 5px 20px; background-color: #0B9DE4; color: white; border: none; border-radius: 4px; cursor: pointer; position: absolute; top: ${coord.height - 20}px; left: 50%; pointer-events: all; border-radius: 20px;z-index: 99999999999999999999999999; transform: translateX(-50%);`
      //     button.innerHTML = 'Add Section';
      //     button.id = `addsection-${model.ccid}`;
      //     button.addEventListener("click", function () {
      //         handleAddSection(false)
      //     });
      //     section.appendChild(button);

      //     const resizeObserver = new ResizeObserver(entries => {

      //         window.requestAnimationFrame(() => {
      //             if (!Array.isArray(entries) || !entries.length) {
      //                 return;
      //             }

      //             for (let entry of entries) {
      //                 const { width, height } = entry.contentRect;
      //                 const calcHeight = height + parseInt(coorStyle.paddingTop) + parseInt(coorStyle.paddingBottom)
      //                 button.style = `height: 34px; padding: 5px 20px; background-color: #0B9DE4; color: white; border: none; border-radius: 4px; cursor: pointer; position: absolute; top: ${calcHeight - 20}px; left: 50%; pointer-events: all; border-radius: 20px;z-index: 99999999999999999999999999;transform: translateX(-50%);`
      //             }
      //         });
      //     });

      //     // Step 3: Observe target elements
      //     resizeObserver.observe(section);
      // }
      // setShowSection(false)
    }

    console.log(model)
  }

  const onEditorInit = (editor) => {

    editor.on('component:hover', (model) => handleComponentHover(model, editor))


    editor.on('storage:start:store', (data) => {
      try {
        const Epages = editor.Pages;
        const allPages = Epages.getAll();

        console.log({ allPages })
        Epages.select('my-actuall-page');

        const newObject = {};

        Object.keys(data).map((key) => {
          return Object.assign(newObject, { [`Home-${key}`]: data[key] })[key];
        });

        var tempEl = document.createElement('div');
        var tempE2 = document.createElement('div');
        let formsHtml;
        let typeId = 3;

        const htmlContent3 = editor.getHtml();

        const htmlString = htmlContent3;

        // create a new DOMParser object
        const parser = new DOMParser();

        // parse the HTML string and create a new document object
        const doc = parser.parseFromString(htmlString, 'text/html');

        // get the document element
        const docElement = doc.documentElement;

        var newDiv3 = document.createElement('div');
        newDiv3.id = "google_translate_element"

        var newDiv = document.createElement('header');
        var noScript = document.createElement('noscript');
        newDiv.id = 'HEADER';

        var newDiv2 = document.createElement('footer');
        newDiv2.id = 'FOOTER';

        tempE2 = docElement.querySelector('body');
        tempE2.setAttribute('venue_id', venueIdRef.current)
        tempE2.setAttribute('venue_name', venueNameRef.current)
        tempE2.setAttribute('biz_base_url', process.env.REACT_APP_API_STORE_URL)
        tempE2.setAttribute('fe_base_url', process.env.REACT_APP_API_FE_STORE_URL)
        tempE2.setAttribute('stripe_key', process.env.REACT_APP_STRIPE_KEY)

        tempE2.insertBefore(newDiv3, tempE2.firstChild);

        tempE2.insertBefore(newDiv, tempE2.firstChild);

        tempE2.insertBefore(noScript, tempE2.firstChild);

        tempE2.appendChild(newDiv2);

        localStorage.setItem('gettingStartedTemplate', JSON.stringify({
          title: 'Home',
          type_id: 3,
          uri: 'index',
          device_type: 0,
          payload: {
            ...newObject,
            [`Home-css`]: editor.getCss() + formCss.current,
            [`Home-html`]: tempE2.outerHTML,
            'head-params': {
              biz_base_url: process.env.REACT_APP_API_STORE_URL,
              fe_base_url: process.env.REACT_APP_API_FE_STORE_URL,
              stripe_key: process.env.REACT_APP_STRIPE_KEY
            }
          }
        }))

      } catch (error) {
        console.log(error)
      } finally {
        // onNext()
        setShowSignup(true)
      }
    })

    setEditor(editor)
  }

  const handleSave = () => {
    editor.store()
  }

  const load = async () => {
    await editor.load()

    const iframe = editor.Canvas?.getFrameEl();
    iframe.classList?.add(styles.gjs_frame);

    var contentDocument = getContentDocument(editor);
    console.log(contentDocument.body)
    contentDocument.body?.classList?.add(styles.gjs_frame_body)
    contentDocument.body?.setAttribute('venue_id', venueIdRef.current)
    contentDocument.body?.setAttribute('venue_name', venueNameRef.current)
    contentDocument.body?.setAttribute('biz_base_url', process.env.REACT_APP_API_STORE_URL)
    contentDocument.body?.setAttribute('fe_base_url', process.env.REACT_APP_API_FE_STORE_URL)

    var components = editor.getComponents();

    if (components?.length === 0) {
      setShowAddSection(true)
      editor.on('canvas:drop', function () {
        setShowAddSection(false)
      });
    } else {
      setShowAddSection(false)
    }

    // setTimeout(() => {
    //   setIsPageLoading(false)
    // }, 4000);
  }

  useEffect(() => {
    handleSaveRef.current = handleSave
    if (!isPageLoading) {
      setSiteReady(true)
      // setTimeout(() => {
      var contentDocument = getContentDocument(editor);
      contentDocument.body?.setAttribute('venue_id', venueIdRef.current)
      contentDocument.body?.setAttribute('venue_name', venueNameRef.current)
      contentDocument.body?.setAttribute('biz_base_url', process.env.REACT_APP_API_STORE_URL)
      contentDocument.body?.setAttribute('fe_base_url', process.env.REACT_APP_API_FE_STORE_URL)
      // }, 4000);
    }
  }, [isPageLoading])

  return (
    <div
      className={clsx(styles.custom_page_builder, isSetting && styles.is_setting)}
    >
      {isLoading && (
        <PageLoader />
      )}

      {isPageLoading && (
        <LoaderGif
          isTemplateLoading={isTemplateLoading}
          formik={formik}
          type={2}
          onNext={() => {
            setIsPageLoading(false)
          }}
        />
      )}

      {showConfetti && !isPageLoading && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={parseInt(confettiAmount)}
        // numberOfPieces={200}
        />
      )}

      <div
        style={isPageLoading ? { height: 0, overflow: 'hidden' } : {}}
      >
        <TopPanel
          lg={lg}
          handleSave={handleSave}
          device={device}
          setDevice={setDevice}
          onPrev={onPrev}
          onRegenerate={onRegenerate}
          isPageLoading={isPageLoading}
          siteReady={siteReady}
        />

        <div
          style={isSetting ? { height: 0, overflow: 'hidden' } : {}}
          // style={isSetting ? { height: 0, overflow: 'hidden' } : { pointerEvents: showEditMenu ? 'none' : 'all' }}
          className={styles.editor_row}
        >
          <div className="editor-canvas">
            <div
              id="editor"
            />
          </div>
        </div>
      </div>

      <Dialog
        hideBackdrop={true}
        open={siteReady}
        onClose={() => {
          setSiteReady(false)
        }}
      >

        <div
          className="tw-w-full tw-relative tw-rounded-xl tw-bg-white tw-shadow-[0px_4px_30px_rgba(0,_0,_0,_0.15)] tw-overflow-hidden tw-flex tw-flex-col tw-items-start tw-justify-start tw-pt-5 tw-px-0 tw-pb-[22px] tw-box-border tw-gap-[20px_0px] tw-text-left tw-text-[20px] tw-text-black tw-font-inter"
        >
          <div
            className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-between tw-py-0 tw-px-5"
          >
            <div
              className="tw-w-[190px] sm:tw-w-[276px] tw-relative tw-font-medium tw-inline-block tw-shrink-0"
            >
              You are ready to sell online
            </div>
            <IconButton
              onClick={() => {
                setSiteReady(false)
              }}
            >
              <Close />
            </IconButton>
          </div>
          <div
            className="tw-self-stretch tw-flex tw-flex-col tw-items-start tw-justify-start tw-py-0 tw-px-5 tw-gap-[15px_0px] tw-text-sm"
          >
            <div
              className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-[0px_15px]"
            >
              <ColorLensOutlined />
              <div
                className="tw-flex-1 tw-relative tw-flex tw-gap-2 tw-flex-wrap tw-items-center"
              >
                Like the layout of your site? Click

                <Button
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 30,
                    fontSize: 14
                  }}
                  variant="contained"
                  startIcon={<ColorLensOutlined />}
                  onClick={handleSave}
                >
                  Save & Customize
                </Button>

                at the top right of your screen, to see your online store in action.
              </div>
            </div>
            <div
              className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-[0px_15px]"
            >
              <RefreshOutlined />
              <div
                className="tw-flex-1 tw-relative tw-flex tw-gap-2 tw-flex-wrap tw-items-center"
              >
                Want to change the layout? Click
                <Button
                  style={{
                    borderRadius: 30,
                    border: '1px solid #D3D3D3',
                    color: 'black',
                    fontSize: 14
                  }}
                  variant="text"
                  startIcon={<Refresh />}
                  onClick={onRegenerate}
                >
                  Regenerate
                </Button>
                at the top right of your screen, to have our AI tool present a new look for your store.
              </div>
            </div>
            {!lg && (
              <div
                className="tw-self-stretch tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-[0px_15px]"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9.28311 4.12206L3.31927 7.00699C2.03837 7.62661 1.4842 9.20609 2.08149 10.5349L4.86248 16.7216C5.45977 18.0504 6.98234 18.6253 8.26324 18.0057L14.2271 15.1207C15.508 14.5011 16.0621 12.9216 15.4649 11.5929L12.6839 5.4061C12.0866 4.07733 10.564 3.50244 9.28311 4.12206Z" fill="url(#paint0_linear_67_19435)" />
                  <path d="M8.24128 14.9331L8.24232 14.933L8.17877 15.8018C8.15557 16.119 7.92189 16.3536 7.65677 16.3259C7.39169 16.2981 7.19554 16.0186 7.21873 15.7014L7.26554 15.0613L7.27004 14.9999C7.28383 14.9049 7.33847 14.7236 7.55345 14.6321C7.55345 14.6321 8.06249 14.4247 8.215 14.7064C8.215 14.7064 8.2584 14.8029 8.24128 14.9331ZM12.2455 10.5706L11.2153 11.1453C11.143 11.1789 11.1006 11.1659 11.0818 11.1552L11.0804 11.1541L9.08691 9.48413L9.08595 9.48602L8.97559 9.40949C8.78989 9.27163 8.81546 9.87485 8.81546 9.87485C8.83024 10.5547 9.31538 11.0663 9.47285 11.2149L12.2418 13.5344C12.4597 13.717 12.7602 13.6537 12.9128 13.3928C13.0654 13.132 13.0125 12.7726 12.7944 12.5899L12.1021 12.0099C12.0872 11.9948 12.0539 11.9499 12.1324 11.9058L12.6528 11.6155C12.8939 11.4809 12.9983 11.138 12.8858 10.8494C12.7734 10.5608 12.4867 10.4361 12.2455 10.5706ZM10.5213 12.3971C10.5213 12.3971 10.7068 12.7011 10.494 12.8198L6.68562 14.9444C6.4444 15.0789 6.15775 14.9541 6.04526 14.6655C5.9328 14.377 6.03714 14.034 6.27831 13.8994L7.25742 13.3533C7.41122 13.2541 7.40614 13.1241 7.40614 13.1241H7.40723L7.65302 9.76275L7.65261 9.7629C7.65598 9.69872 7.61771 9.66121 7.61138 9.65543L6.84837 9.01628C6.63039 8.83372 6.57742 8.47423 6.73005 8.21345C6.88273 7.95267 7.18313 7.88924 7.40114 8.07185L7.75497 8.36831L7.79258 7.85436C7.81578 7.53723 8.04946 7.30257 8.31462 7.33036C8.5797 7.35811 8.77581 7.63767 8.75262 7.95486L8.41053 12.6322C8.41153 12.659 8.42286 12.6955 8.48312 12.6695L9.54619 12.0764L9.55123 12.0887C9.55123 12.0887 10.1701 11.7574 10.5213 12.3971Z" fill="white" />
                  <mask id="mask0_67_19435" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="9" y="4" width="15" height="17">
                    <path d="M23.9991 7.64329L12.6836 4.27734L9.08777 17.2862L20.4033 20.6521L23.9991 7.64329Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_67_19435)">
                    <g filter="url(#filter0_d_67_19435)">
                      <path d="M16.2544 12.0508L9.43947 16.1995C9.43833 16.4338 9.48878 16.6651 9.58689 16.8759C9.68505 17.0866 9.82822 17.2712 10.0056 17.4155C10.1829 17.5598 10.3897 17.66 10.6101 17.7084C10.8305 17.7568 11.0587 17.7522 11.2773 17.6949L18.1354 15.9574L16.2544 12.0508Z" fill="#EA4335" />
                      <path d="M21.8186 12.655L19.6898 10.3789L16.0977 12.1435L18.1611 15.955L21.1053 15.2055C21.363 15.1502 21.6014 15.0226 21.7945 14.8365C21.9876 14.6504 22.1282 14.413 22.2009 14.15C22.2736 13.887 22.2757 13.6085 22.2069 13.3445C22.1382 13.0806 22.0012 12.8413 21.8109 12.6526L21.8186 12.655Z" fill="#FBBC04" />
                      <path d="M12.4327 5.36719C12.3673 5.47948 12.3169 5.60039 12.2826 5.72664L9.4958 15.8086C9.4613 15.9347 9.44233 16.0649 9.43945 16.196L16.3935 12.2468L12.4327 5.36719Z" fill="#4285F4" />
                      <path d="M16.2125 12.353L19.6895 10.3785L14.7735 5.12104C14.5893 4.9203 14.3572 4.77396 14.1008 4.69693C13.4643 4.50622 12.7829 4.77922 12.4336 5.36505L16.2125 12.353Z" fill="#34A853" />
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_67_19435" x="-14.5605" y="-15.3633" width="52.8174" height="53.1055" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="-4" />
                      <feGaussianBlur stdDeviation="10" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_67_19435" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_67_19435" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_67_19435" x1="6.30119" y1="5.56452" x2="11.5503" y2="16.4156" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#18BEFB" />
                      <stop offset="1" stop-color="#2074F3" />
                    </linearGradient>
                  </defs>
                </svg>
                <div
                  className="tw-flex-1 tw-relative tw-flex tw-gap-2 tw-flex-wrap tw-items-center"
                >
                  Want to see how out app will look like? Click on

                  <Button
                    style={{
                      borderRadius: 30,
                      border: '1px solid #D3D3D3',
                      color: 'black',
                      width: 210,
                      fontSize: 14
                    }}
                    variant="text"
                    startIcon={(
                      <img
                        className="tw-h-7"
                        src={getImage2('showapp.png-1709031662.png')}
                      />
                    )}
                    onClick={() => {
                      if (device == 'mobile') {
                        setDevice('desktop')
                      } else {
                        setDevice('mobile')
                      }
                    }}
                  >
                    {(device == 'mobile') ? "Show me my Website" : "Show me my App"}
                  </Button>

                  at top left of your screen.
                </div>
              </div>
            )}

            <div
              className="tw-self-stretch tw-flex tw-flex-col tw-items-end tw-justify-start tw-text-white">
              <div
                className="tw-rounded-[31px] tw-bg-black tw-h-[30px] tw-overflow-hidden tw-shrink-0 tw-flex tw-flex-row tw-items-center tw-justify-center tw-py-1.5 tw-px-[19px] tw-box-border tw-cursor-pointer"
                onClick={() => {
                  setSiteReady(false)
                }}
              >
                <div
                  className="tw-relative"
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={showSignup}
        onClose={() => {
          setShowSignup(false)
        }}
        maxWidth="xl"
        PaperProps={{
          style: {
            margin: 0
          }
        }}
      >
        <DialogContent
          style={{
            position: 'relative',
            padding: 10
          }}
        >
          <IconButton
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              zIndex: 2
            }}
            onClick={() => {
              setShowSignup(false)
            }}
          >
            <Close />
          </IconButton>
          <div
            className="tw-flex"
          >
            <div>
              <Signup
                formik={formik}
                isLoading={isSignupLoading}
                sm={sm}
              />
            </div>

            {!lg && (
              <div className="tw-w-[315px] tw-relative tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[12px_0px] tw-text-left tw-text-sm tw-text-black tw-font-inter tw-pl-5 tw-pt-7">
                <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[0px_10px]">
                  <CheckCircleOutline fontSize="small" />
                  <div className="tw-flex-1 tw-relative">Gather customer feedback</div>
                </div>
                <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[0px_10px]">
                  <CheckCircleOutline fontSize="small" />
                  <div className="tw-flex-1 tw-relative">Run a promo or coupon deal</div>
                </div>
                <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[0px_10px]">
                  <CheckCircleOutline fontSize="small" />
                  <div className="tw-flex-1 tw-relative">Digital restaurant menu</div>
                </div>
                <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[0px_10px]">
                  <CheckCircleOutline fontSize="small" />
                  <div className="tw-flex-1 tw-relative">SEO-optimized</div>
                </div>
                <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[0px_10px]">
                  <CheckCircleOutline fontSize="small" />
                  <div className="tw-flex-1 tw-relative">{`3rd party delivery `}</div>
                </div>
                <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[0px_10px]">
                  <CheckCircleOutline fontSize="small" />
                  <div className="tw-flex-1 tw-relative">Online/credit card payment</div>
                </div>
                <div className="tw-self-stretch tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[0px_10px]">
                  <CheckCircleOutline fontSize="small" />
                  <div className="tw-flex-1 tw-relative">Detailed reports</div>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
};

export default Template;
