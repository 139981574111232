const initialState = {
    isLoading: false,
    productDetails: {},
    productDetailsError: "",
    productSkuDetails: {},
    productSkuDetailsError: "",
    productFormatDetails: {},
    productFormatError: "",
    addProductDetails: {},
    addProductDetailsError: "",
    deleteProductDetails: {},
    deleteProductError: "",
    uploadFileDetails: {},
    uploadFileError: {}
};
function productDetails(state = initialState, action) {
    switch (action.type) {
        case "SET_PRODUCT_STORE_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_PRODUCT_STORE_LOADED":
            return { ...initialState, isLoading: false, productDetails: action.payload };
        case "SET_PRODUCT_STORE_ERROR":
            return { ...initialState, productDetailsError: action.payload };
        case "SET_PRODUCTSTORE_SKU_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_PRODUCTSTORE_SKU_LOADED":
            return { ...initialState, isLoading: false, productSkuDetails: action.payload };
        case "SET_PRODUCTSTORE_SKU_ERROR":
            return { ...initialState, productSkuDetailsError: action.payload };

        case "SET_PRODUCT_FILE_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_PRODUCT_FILE_LOADED":
            return { ...initialState, isLoading: false, uploadFileDetails: action.payload, uploadFileError:{}};
        case "SET_PRODUCT_FILE_ERROR":
            return { ...initialState, uploadFileError: action.payload };

        case "SET_PRODUCT_FORMAT_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_PRODUCT_FORMAT_LOADED":
            return { ...initialState, isLoading: false, productFormatDetails: action.payload };
        case "SET_PRODUCT_FORMAT_ERROR":
            return { ...initialState, productFormatError: action.payload };
        case "ADD_PRODUCT_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "ADD_PRODUCT_LIST_LOADED":
            return { ...initialState, isLoading: false, addProductDetails: action.payload };
        case "ADD_PRODUCT_LIST_ERROR":
            return { ...initialState, addProductDetailsError: action.payload };
        case "DELETE_PRODUCT_LIST_LOADING":
            return { ...initialState, isLoading: true };
        case "DELETE_PRODUCT_LIST_LOADED":
            return { ...initialState, isLoading: false, deleteProductDetails: action.payload };
        case "DELETE_PRODUCT_LIST_ERROR":
            return { ...initialState, deleteProductError: action.payload };
        default:
            return state;
    }
};
export default productDetails;  