const initialState = {
  isLoading: false,
  isLoggedIn: false,
  VenueSettingList: {},
  VenueListError: "",
  editVenueSettings: {},
  editVenueSettingError: "",
  venueStateList: {},
  venueStateError: "",
  venueSettingUrl: {},
  venueSettingUrlError: "",
  venueStripeCode: {},
  venueStripeCodeError: {},


};
export function venueSetting(state = initialState, action) {
  switch (action.type) {
    case "SET_VENUE_SETTING_LIST_LOADING":
      return { ...initialState, isLoading: true };
    case "SET_VENUE_SETTING_LIST_LOADED":
      return {
        ...initialState,
        isLoading: false,
        VenueSettingList: { ...action.payload },
      };
    case "SET_VENUE_SETTING_LIST_ERROR":
      return { ...initialState, SettingListError: action.payload };
    default:
      return state;
  }
}
export function editVenueSetting(state = initialState, action) {
  switch (action.type) {
    case "SET_EDIT_VENUE_SETTING_LIST_LOADING":
      return { ...initialState, isLoading: true };
    case "SET_EDIT_VENUE_SETTING_LIST_LOADED":
      return {
        ...initialState,
        isLoading: false,
        editVenueSettings: { ...action.payload },
      };
    case "SET_EDIT_VENUE_SETTING_LIST_ERROR":
      return { ...initialState, editVenueSettingError: action.payload };
    default:
      return state;
  }
}
export function venueStates(state = initialState, action) {
  switch (action.type) {
    case "SET_VENUE_STATES_LIST_LOADING":
      return { ...initialState, isLoading: true };
    case "SET_VENUE_STATES_LIST_LOADED":
      return {
        ...initialState,
        isLoading: false,
        venueStateList: action.payload,
      };
    case "SET_VENUE_STATES_LIST_ERROR":
      return { ...initialState, venueStateError: action.payload };
    default:
      return state;
  }
}
export function updateVenueURL(state = initialState, action) {
  switch (action.type) {
    case "SET_VENUE_URL_LOADING":
      return { ...initialState, isLoading: true };
    case "SET_VENUE_URL_LOADED":
      return {
        ...initialState,
        isLoading: false,
        venueSettingUrl: action.payload,
      };
    case "SET_VENUE_URL_ERROR":
      return { ...initialState, venueSettingUrlError: action.payload };
    default:
      return state;
  }
}
export function postStripeCode(state = initialState, action) {
  switch (action.type) {
    case "SET_STRIPE_LOADING":
      return { ...initialState, isLoading: true };
    case "SET_STRIPE_LOADED":
      return {
        ...initialState,
        isLoading: false,
        venueStripeCode: action.payload,
      };
    case "SET_STRIPE_ERROR":
      return { ...initialState, venueStripeCodeError: action.payload };
    default:
      return state;
  }
}
