import { useNavigate } from 'react-router';

import { Box, Button, CircularProgress, Divider, ListItemIcon, Menu, MenuItem, outlinedInputClasses, Paper, Select, Tab, Table, TableBody, TableContainer, TableHead, TableRow, Tabs } from '@mui/material';
import clsx from 'clsx';
import React, { useContext, useRef, useState } from 'react'
import { FaFacebook, FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa';
import GenericSwitch from '../Library/GenericSwitch/GenericSwitch';
import TabsSection from '../Library/TabsSection/TabsSection';
import { useEffect } from 'react';
import TabPanel from '../Library/TabPanel/TabPanel';
import axios from '../Util/axiosConfig'
import LineGraph from '../Library/Charts/LineGraph';
import { format } from 'date-fns';
import { BsFillArrowUpCircleFill, BsFillArrowDownCircleFill } from 'react-icons/bs'
import styles from './MarketingDashboard.module.scss'
import Loader from '../Library/Loader/Loader';
import FacebookButton from '../FacebookButton/FacebookButton';
import TwitterButton from '../TwitterButton/TwitterButton';
import MarketingContext from '../Util/marketingContext';
import GenericPostTable from '../Library/GenericPostTable/GenericPostTable';
import MarketingConnections from '../MarketingConnections/MarketingConnections';
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton';
import { toast } from 'react-toastify';
import { getImage } from '../Util/helpers'

const menuTypes = [
  {
    value: 0,
    label: '@username'
  },
  {
    value: 1,
    label: '@username'
  },
  {
    value: 2,
    label: '@username'
  }
]

const headings = [
  {
    label: 'Post'
  },
  {
    label: 'Description'
  },
  {
    label: 'Accounts'
  },
  {
    label: 'Date Posted'
  },
  {
    label: 'Impressions'
  },
  {
    label: 'Views'
  },
  {
    label: 'Likes'
  },
  {
    label: 'Comments'
  }
]

const period = [
  {
    value: 7,
    label: 'Last 7 days'
  },
  {
    value: 15,
    label: 'Last 15 days'
  },
  {
    value: 30,
    label: 'Last 30 days'
  }
]

const rows = [
  {
    image: 'Image',
    description: 'Description',
    accounts: 'Accounts',
    date: 'datePosted',
    impressions: 'Imp',
    likes: 'Likes',
    views: 'Views',
    comments: 'comments'
  }
]

const MarketingDashboard = () => {


  const { pages, names, handleFBDisconnect, handleTwitterDisconnect } = useContext(MarketingContext)
console.log({pages})
  const navigate = useNavigate()

  const facebookBtnRef = useRef()
  const twitterBtnRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null);
  const [types, setTypes] = useState([])
  const [tabValue, setTabValue] = useState(0)
  const [value, setValue] = useState(0);
  const [graphData, setGraphData] = useState([])
  const [pageData, setPageData] = useState({})
  const [selectedTab, setSelectedTab] = useState({})
  const [posts, setPosts] = useState([])
  const [isPostLoading, setIsPostLoading] = useState(true)
  const [since, setSince] = useState(format((new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
  const [until, setUntil] = useState(format((new Date()), "yyyy-MM-dd"))
  const [dateValue, setDateValue] = useState(30)
  const [fbLoading, setFbLoading] = useState(false)
  const [instaLoading, setInstaLoading] = useState(false)
  const [twitterLoading, setTwitterLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCreatePostLoading, setIsCreatePostLoading] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event) => {
    setDateValue(event.target.value)
    setSince(format((new Date(Date.now() - event.target.value * 24 * 60 * 60 * 1000)), "yyyy-MM-dd"))
  };

  const fetchOverviewData = () => {
    setIsLoading(true)
    axios.get(`/marketing/social-page/dashboard/${tabValue}`, {
      params: {
        since: since,
        until: until
      }
    })
      .then((response) => {
        setPageData(response.data?.data?.page_detail)
        setGraphData(response.data?.data?.results)
      })
      .catch(() => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setTypes(() => {
      let newPages = pages.map((data) => ({ ...data, value: data.page_id, label: data.name }))
      let newNames = names.map((data) => ({ ...data, value: data.page_id, label: data.name }))
      return [...newPages, ...newNames]
    })
  }, [pages, names])

  useEffect(() => {
    setTabValue(types[0]?.value)
    setSelectedTab(types[0])
  }, [types])
  console.log(pages)
  useEffect(() => {
    if (tabValue) {
      fetchOverviewData()
    }
  }, [tabValue, since, until])


  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const fetchPosts = () => {
    setIsPostLoading(true)
    axios.get('/marketing/social-post/list', {
      params: {
        since: since,
        until: until,
        limit: 5
      }
    })
      .then((response) => {
        setPosts(response.data.data.results)
      })
      .catch(() => {

      })
      .finally(() => {
        setIsPostLoading(false)
      })
  }

  useEffect(() => {
    fetchPosts()
  }, [since, until])


  const stopFbLoading = () => {
    setFbLoading(false)
    setInstaLoading(false)
  }

  const handleFacebookChange = () => {
    if ([...pages, ...names].filter((data) => data.isFacebook).length === 0) {
      let btn = document.getElementsByClassName(styles.facebook_btn)
      btn[0].click()
    } else {
      setFbLoading(true)
      handleFBDisconnect(stopFbLoading, setFbLoading, setInstaLoading)
    }
  }

  const handleInstagramChange = () => {
    if ([...pages, ...names].filter((data) => data.isInstagram).length === 0) {
      let btn = document.getElementsByClassName(styles.facebook_btn)
      btn[0].click()
    } else {
      setFbLoading(true)
      handleFBDisconnect(stopFbLoading, setFbLoading, setInstaLoading)
    }
  }

  // const handleInstagramChange = () => {
  //     if (pages.length === 0) {
  //         let btn = document.getElementsByClassName(styles.facebook_btn)
  //         btn[0].click()
  //     } else {
  //         setFbLoading(true)
  //         handleFBDisconnect(stopFbLoading)
  //     }
  // }

  const stopTwitterLoading = () => {
    setTwitterLoading(false)
  }
  const handleTwitterChange = (event) => {
    if ([...pages, ...names].filter((data) => data.isTwitter).length === 0) {
      twitterBtnRef.current.onButtonClick(event)
    } else {
      setTwitterLoading(true)
      handleTwitterDisconnect(stopTwitterLoading)
    }
  }

  return (
    <div
      className={clsx(styles.container)}
    >
      <FacebookButton
        ref={facebookBtnRef}
        facebookBtnClass={styles.facebook_btn}
      />

      <TwitterButton
        ref={twitterBtnRef}
        twitterBtnClass={styles.twitter_btn}
      />

      <div
        className={styles.title_container}
      >
        <div
          className={styles.dashboard_text}
        >
          Dashboard
        </div>

        <div className={styles.header_actions}>

          {(Boolean(pages.length) || Boolean(names.length)) && (
            <GenericLoadingButton
              style={{
                backgroundColor: '#0B9DE4',
                borderRadius: '30px',
                fontWeight: 600
              }}
              variant="contained"
              type="button"
              loading={isCreatePostLoading}
              loadingPosition='end'
              onClick={() => {
                setIsCreatePostLoading(true)
                axios.post('/marketing/social-post-group/initiate')
                  .then((groupResponse) => {
                    navigate(`/marketing/create-post/${groupResponse.data?.data?.result?.id}`)
                  })
                  .catch((err) => {
                    toast.error(err?.response?.data?.message);
                  })
                  .finally(() => {
                    setIsCreatePostLoading(false)
                  })
              }}
            >
              Create Post
            </GenericLoadingButton>
          )}

          <Button
            style={{
              backgroundColor: '#0B9DE4',
              borderRadius: '30px',
              fontWeight: 600
            }}
            variant="contained"
            type="button"
            onClick={handleClick}
          >
            My Social Accounts
          </Button>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            // onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                className={styles.main_switch_container}
              >
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <ListItemIcon>
                    <FaFacebook
                      color="#4267B2"
                      fontSize={20}
                    />
                  </ListItemIcon>
                  Facebook
                </div>

                <div
                  className={styles.switch_container}
                // key={data.title}
                >
                  {/* <div
                                    className={styles.switch_label}
                                >
                                    {data.title}
                                </div> */}
                  <GenericSwitch
                    background="#00C57E"
                    width={108}
                    height={24}
                    thumb={19}
                    isStatus={true}
                    onText="Connected"
                    offText="Disconnected"
                    checked={Boolean(pages.filter((data) => data.isFacebook).length)}
                    onChange={handleFacebookChange}
                  />

                  {fbLoading && (
                    <CircularProgress style={{ marginLeft: 6 }} size={15} />
                  )}
                </div>
              </div>

              <div
                className={styles.accounts_container}
              >
                {pages.filter((data) => data.isFacebook).map((data) => (
                  <div>
                    <ListItemIcon>
                      <FaFacebook
                        color="#4267B2"
                        fontSize={20}
                      />
                    </ListItemIcon>
                    {data.name}
                  </div>
                ))}
              </div>

              <div
                className={styles.btn_container}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    let btn = document.getElementsByClassName(styles.facebook_btn)
                    btn[0].click()
                  }}
                >
                  Add Facebook Account
                </Button>
              </div>
            </MenuItem>
            <Divider
              style={{
                marginTop: 0
              }}
            />
            <MenuItem
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            // onClick={handleClose}
            >
              <div
                className={styles.main_switch_container}
              >
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={getImage('instagram.png-1699456353.png')}
                      height={20}
                      width={20}
                    />
                  </ListItemIcon>
                  Instagram
                </div>

                <div
                  className={styles.switch_container}
                // key={data.title}
                >
                  {/* <div
                                    className={styles.switch_label}
                                >
                                    {data.title}
                                </div> */}
                  <GenericSwitch
                    background="#00C57E"
                    width={108}
                    height={24}
                    thumb={19}
                    isStatus={true}
                    onText="Connected"
                    offText="Disconnected"
                    checked={Boolean(pages.filter((data) => data.isInstagram).length)}
                    onChange={handleInstagramChange}
                  />

                  {instaLoading && (
                    <CircularProgress style={{ marginLeft: 6 }} size={15} />
                  )}
                </div>
              </div>

              <div
                className={styles.accounts_container}
              >
                {pages.filter((data) => data.isInstagram).map((data) => (
                  <div>
                    <ListItemIcon>
                      <img
                        src={getImage('instagram.png-1699456353.png')}
                        height={20}
                        width={20}
                      />
                    </ListItemIcon>
                    {data.name}
                  </div>
                ))}
              </div>

              <div
                className={styles.btn_container}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    let btn = document.getElementsByClassName(styles.facebook_btn)
                    btn[0].click()
                  }}
                >
                  Add Instagram Account
                </Button>
              </div>
            </MenuItem>
            <Divider
              style={{
                marginTop: 0
              }}
            />
            <MenuItem
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            // onClick={handleClose}
            >
              <div
                className={styles.main_switch_container}
              >
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <ListItemIcon>
                    <FaTwitter
                      color="#4267B2"
                      fontSize={20}
                    />
                  </ListItemIcon>
                  Twitter
                </div>

                <div
                  className={styles.switch_container}
                // key={data.title}
                >
                  {/* <div
                                    className={styles.switch_label}
                                >
                                    {data.title}
                                </div> */}
                  <GenericSwitch
                    background="#00C57E"
                    width={108}
                    height={24}
                    thumb={19}
                    isStatus={true}
                    onText="Connected"
                    offText="Disconnected"
                    checked={Boolean(pages.filter((data) => data.isTwitter).length)}
                    onChange={handleTwitterChange}
                  />

                  {twitterLoading && (
                    <CircularProgress style={{ marginLeft: 6 }} size={15} />
                  )}
                </div>
              </div>

              <div
                className={styles.accounts_container}
              >
                {pages.filter((data) => data.isTwitter).map((data) => (
                  <div>
                    <ListItemIcon>
                      <FaTwitter
                        color="#4267B2"
                        fontSize={20}
                      />
                    </ListItemIcon>
                    {data.name}
                  </div>
                ))}
              </div>

              <div
                className={styles.btn_container}
              >
                <Button
                  variant="text"
                  onClick={(event) => {
                    twitterBtnRef.current.onButtonClick(event)
                  }}
                >
                  Add Twitter Account
                </Button>
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>


      {/* {showHelpPage ? helpPage : (
      )} */}
      <>
        {isLoading ? (
          <Loader />
        ) : (Boolean(pages.length) || Boolean(names.length)) ? (
          <>
            <TabsSection
              tabs={types}
              defaultTab={types[0]?.value}
              tabValue={tabValue}
              onChange={(event, newValue) => {
                setTabValue(newValue)
              }}
              onTabSelect={(data) => {
                setSelectedTab(data)
              }}
              type="secondary"
              panelHeight="unset"
              scrollButtons="auto"
              variant="scrollable"
            />

            <div
              style={{
                backgroundColor: 'var(--bg-10)',
                padding: 20
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 15
                }}
              >
                <div>
                  {selectedTab?.isFacebook && (
                    <FaFacebook
                      color="#4267B2"
                      fontSize={50}
                    />
                  )}

                  {selectedTab?.isInstagram && (
                    <img
                      src={getImage('instagram.png-1699456353.png')}
                      height={50}
                      width={50}
                    />
                  )}

                  {selectedTab?.isTwitter && (
                    <FaTwitter
                      color="#4267B2"
                      fontSize={50}
                    />
                  )}
                </div>

                <div>
                  <div
                    style={{
                      fontWeight: 600
                    }}
                  >
                    {pageData.title}
                  </div>

                  {Boolean(pageData.total?.total_follow) && (
                    <>
                      <div
                        style={{
                          fontWeight: 600
                        }}
                      >
                        {pageData.total?.total_follow}
                      </div>
                      <small
                        style={{
                          color: 'rgba(58, 58, 58, 0.75)'
                        }}
                      >
                        Total Followers
                      </small>
                    </>
                  )}
                </div>
              </div>

              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Overview" />
                  </Tabs>

                  <div>
                    <Select
                      value={dateValue}
                      onChange={handleChange2}
                      size="small"
                      sx={{
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                          border: 'none'
                        }
                      }}
                    >
                      {period.map((data) => (
                        <MenuItem
                          value={data.value}
                        >
                          {data.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Box>
                <TabPanel value={value} index={0}>
                  <div
                    className={styles.line_graph_container}
                  >
                    <div
                      className={styles.line_graph_item}
                    >
                      <div
                        style={{
                          fontWeight: 600
                        }}
                      >
                        New Followers
                      </div>

                      {graphData[graphData.length - 1]?.daily_follow && (
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 18
                          }}
                        >
                          {graphData[graphData.length - 1]?.daily_follow}
                        </div>
                      )}

                      {(Boolean(graphData[graphData.length - 1]?.daily_follow - graphData[0]?.daily_follow)) && (
                        <div
                          style={{
                            color: ((graphData[graphData.length - 1]?.daily_follow - graphData[0]?.daily_follow) > 0) ? '#107517' : '#E75C5C',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4
                          }}
                        >
                          {Math.abs(graphData[graphData.length - 1]?.daily_follow - graphData[0]?.daily_follow)}

                          {((graphData[graphData.length - 1]?.daily_follow - graphData[0]?.daily_follow) > 0) && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4
                              }}
                            >
                              <div>
                                increase
                              </div>

                              <BsFillArrowUpCircleFill />
                            </div>
                          )}

                          {((graphData[graphData.length - 1]?.daily_follow - graphData[0]?.daily_follow) < 0) && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4
                              }}
                            >
                              <div>
                                decrease
                              </div>

                              <BsFillArrowDownCircleFill />
                            </div>
                          )}
                        </div>
                      )}

                      <LineGraph
                        data={{
                          labels: graphData.map((data, index) => {
                            if (index == 0) {
                              return format(new Date(data.since), 'LLL dd')
                            } else if (index == (graphData.length - 1)) {
                              return format(new Date(data.since), 'LLL dd')
                            } else {
                              return ''
                            }
                          }),
                          datasets: [
                            {
                              data: graphData.map((data) => data.daily_follow),
                              borderColor: '#0B9DE4',
                              borderWidth: 2
                              // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                          ],
                        }}
                      />
                    </div>

                    <div
                      className={styles.line_graph_item}
                    >
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: 18
                        }}
                      >
                        Impressions
                      </div>

                      {graphData[graphData.length - 1]?.daily_impression && (
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 18
                          }}
                        >
                          {graphData[graphData.length - 1]?.daily_impression}
                        </div>
                      )}

                      {(Boolean(graphData[graphData.length - 1]?.daily_impression - graphData[0]?.daily_impression)) && (
                        <div
                          style={{
                            color: ((graphData[graphData.length - 1]?.daily_impression - graphData[0]?.daily_impression) > 0) ? '#107517' : '#E75C5C',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4
                          }}
                        >
                          {Math.abs(graphData[graphData.length - 1]?.daily_impression - graphData[0]?.daily_impression)}

                          {((graphData[graphData.length - 1]?.daily_impression - graphData[0]?.daily_impression) > 0) && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4
                              }}
                            >
                              <div>
                                increase
                              </div>

                              <BsFillArrowUpCircleFill />
                            </div>
                          )}

                          {((graphData[graphData.length - 1]?.daily_impression - graphData[0]?.daily_impression) < 0) && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4
                              }}
                            >
                              <div>
                                decrease
                              </div>

                              <BsFillArrowDownCircleFill />
                            </div>
                          )}
                        </div>
                      )}


                      <LineGraph
                        data={{
                          labels: graphData.map((data, index) => {
                            if (index == 0) {
                              return format(new Date(data.since), 'LLL dd')
                            } else if (index == (graphData.length - 1)) {
                              return format(new Date(data.since), 'LLL dd')
                            } else {
                              return ''
                            }
                          }),
                          datasets: [
                            {
                              data: graphData.map((data) => data.daily_impression),
                              borderColor: '#0B9DE4',
                              borderWidth: 2
                              // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                          ],
                        }}
                      />
                    </div>


                    <div
                      className={styles.line_graph_item}
                    >
                      <div>
                        Engagement
                      </div>

                      {graphData[graphData.length - 1]?.daily_engaged && (
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 18
                          }}
                        >
                          {graphData[graphData.length - 1]?.daily_engaged}
                        </div>
                      )}

                      {(Boolean(graphData[graphData.length - 1]?.daily_engaged - graphData[0]?.daily_engaged)) && (
                        <div
                          style={{
                            color: ((graphData[graphData.length - 1]?.daily_engaged - graphData[0]?.daily_engaged) > 0) ? '#107517' : '#E75C5C',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4
                          }}
                        >
                          {Math.abs(graphData[graphData.length - 1]?.daily_engaged - graphData[0]?.daily_engaged)}

                          {((graphData[graphData.length - 1]?.daily_engaged - graphData[0]?.daily_engaged) > 0) && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4
                              }}
                            >
                              <div>
                                increase
                              </div>

                              <BsFillArrowUpCircleFill />
                            </div>
                          )}

                          {((graphData[graphData.length - 1]?.daily_engaged - graphData[0]?.daily_engaged) < 0) && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4
                              }}
                            >
                              <div>
                                decrease
                              </div>

                              <BsFillArrowDownCircleFill />
                            </div>
                          )}
                        </div>
                      )}


                      <LineGraph
                        data={{
                          labels: graphData.map((data, index) => {
                            if (index == 0) {
                              return format(new Date(data.since), 'LLL dd')
                            } else if (index == (graphData.length - 1)) {
                              return format(new Date(data.since), 'LLL dd')
                            } else {
                              return ''
                            }
                          }),
                          datasets: [
                            {
                              data: graphData.map((data) => data.daily_engaged),
                              borderColor: '#0B9DE4',
                              borderWidth: 2
                              // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </TabPanel>
              </Box>

              <div
                style={{
                  marginTop: 20
                }}
              >
                {isPostLoading ? (
                  <Loader />
                ) : Boolean(posts.length) ? (
                  <GenericPostTable
                    headings={headings}
                    posts={posts}
                    hidePagination={true}
                  />
                ) : (
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 18,
                      textAlign: 'center',
                      paddingTop: 20
                    }}
                  >
                    No Post Available
                  </div>
                )}

              </div>
            </div>
          </>
        ) : (
          <MarketingConnections />
        )}
      </>
    </div>
  )
}

export default MarketingDashboard