import React, { useState, useEffect } from "react";
import { Typography, TextField, Select, FormControl, Autocomplete, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Const from "../../../helpers/const";
import { toast } from "react-toastify";
import routePaths from "../../../../config/route-paths";
import { useFormik } from "formik";
import axios, { nodeAxios } from '../../../../utils/axiosConfig'
import GenericTextFieldWithLabel from "../../../Library/TextFieldWithLabel/GenericTextFieldWithLabel";
import GoogleAddressInput from "../../../Library/GoogleAddressInput/GoogleAddressInput";
import GenericSelectWithLabel from "../../../Library/GenericSelectWithLabel/GenericSelectWithLabel";
import GenericLoadingButton from "../../../Library/GenericLoadingButton/GenericLoadingButton";

const Form = (props) => {

  const dispatch = useDispatch((data) => data);

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      venuetype: "Package Store",
      address1: "",
      city: "",
      state: "",
      country: "",
      latitude: 0,
      longitude: 0,
      is_claim_business: 0,
    },
    onSubmit: (values) => {

      if (
        !values.address1 ||
        !values.city ||
        !values.state ||
        !values.country ||
        !values.latitude?.toString() ||
        !values.longitude?.toString()
      ) {
        toast.error('Please enter address and choose from the suggestions')
        return;
      }
      setIsLoading(true)
      nodeAxios.post('/auth/signup', values)
        .then((response) => {
          localStorage.removeItem("isShiftedFrom")
          let res = response
          localStorage.setItem("userToken", res.data?.data?.token);
          localStorage.setItem("venue_id", res.data?.data?.user?.venueid);
          if (res.data?.data?.user?.biz_users_role == 0) {
            localStorage.setItem("superAdminToken", res.data?.data?.token);
          }
          toast.success('Signed Up Successfully')
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: res.data?.data?.user,
          });

          window.location.href = routePaths.myStoreSetting
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            toast.error(error.response.data.message)
            return
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  })


  return (
    <div
      className="new-signup-form"
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        padding: "18px 20px",
        position: "absolute",
        width: "27.5vw",
        top: "2%",
        left: "70%",
        boxShadow: "0px 15px 28px -3px rgba(0,0,0,0.25)",
      }}
    >
      <Typography
        style={{
          fontSize: "1.2rem",
          color: "black",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Tell us About Yourself
      </Typography>
      <form
        onSubmit={formik.handleSubmit}
      >
        <div

        >
          <GenericTextFieldWithLabel
            label="Your Business Name"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          />

        </div>

        <div
        >
          <GenericTextFieldWithLabel
            label="Your Business Email"
            id="email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          />
        </div>

        <div

        >
          <GenericTextFieldWithLabel
            label="Your Password"
            id="password"
            name="password"
            type="text"
            value={formik.values.password}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          />
        </div>

        <div

        >
          <GenericTextFieldWithLabel
            label="Your Business Phone Number"
            id="phone"
            name="phone"
            // containerClassname={styles.small_input_container}
            value={formik.values.phone}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          />

        </div>

        <div

        >
          <GoogleAddressInput
            label="Your Business Address"
            isLabel={true}
            placeholder="Enter Address"
            value={formik.values.address1}
            setLat={lat => formik.setFieldValue('latitude', lat)}
            setLng={lng => formik.setFieldValue('longitude', lng)}
            setAddress={address => formik.setFieldValue('address1', address)}
            setCity={city => formik.setFieldValue('city', city)}
            setState={state => formik.setFieldValue('state', state)}
            setCountry={country => formik.setFieldValue('country', country)}
            required={true}
          />

        </div>

        <div
          style={{ display: Const.isOurDesiStore ? 'none' : 'initial' }}
        >
          <GenericSelectWithLabel
            id="venuetype"
            name="venuetype"
            label="Store Type"
            // containerClassname={styles.small_input_container}
            value={formik.values.venuetype}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          >
            <MenuItem value="Package Store">Liquor Store</MenuItem>
            <MenuItem value="Restaurant">Restaurant</MenuItem>
            <MenuItem value="Grocery Store">Grocery Store</MenuItem>
            <MenuItem value="Ice Cream Truck">Ice Cream Truck</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </GenericSelectWithLabel>

        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: 'column',
            alignItems: "center",
            marginTop: "10px",
            gap: 10
          }}
        >
          <GenericLoadingButton
            style={{
              backgroundColor: '#ef2d2c'
            }}
            variant="contained"
            loading={isLoading}
            loadingPosition="end"
            type="submit"
            fullWidth={true}
          >
            Get Started
          </GenericLoadingButton>

          <div
            style={{
              display: "flex",
              gap: 6
            }}
          >
            <Typography style={{ fontSize: ".85rem" }}>
              Already have an account?
            </Typography>
            <Link
              style={{
                color: "#CF5141",
                textDecoration: 'underline',
                fontSize: ".75rem",
              }}
              to="/login"
            >
              Login
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
