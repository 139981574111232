import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import GenericButton from '../../../Library/GenericButton/GenericButton'
import Branding from './Branding/Branding'
import BusinessHours from './OpenHours/BusinessHours'
import styles from './StoreInfo.module.scss'
import StoreInformation from './StoreInformation/StoreInformation'
import axios, { nodeAxios } from '../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericLoadingButton from '../../../Library/GenericLoadingButton/GenericLoadingButton'
import Loader from '../../../Library/Loader'
import { Button, Dialog, IconButton } from '@mui/material'
import { Add, DeleteForever, Edit, LocationOn } from '@mui/icons-material'
import AddHoliday from './AddHoliday/AddHoliday'
import GoogleMapReact from "google-map-react";
import Const from '../../../helpers/const'

const StoreInfo = ({ isSmall }) => {

    const { venueDetails, pickupVenueHours, openVenueHours, fetchStoreInfo, removeImage, removeFavicon, isLoading, isActionsDisabled } = useOutletContext()
    console.log('🚀OUTPUT --> venueDetails:', venueDetails);
    const [isEditMode, setIsEditMode] = useState(false)
    const [showAddHoliday, setShowAddHoliday] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imageFile, setImageFile] = useState()
    const [faviconFile, setFaviconFile] = useState()
    const [noLogo, setNoLogo] = useState(!Boolean(venueDetails.image))
    const [holidays, setHolidays] = useState([])
    const [venueSubTypes, setVenueSubTypes] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editObj, setEditObj] = useState({})

    const fetchHoliday = () => {
        axios.get('/venue-holiday')
            .then((res) => {
                setHolidays(res.data?.data?.results)
            })
    }

    const fetchVenueSubTypes = (page) => {
        axios.get('/venue-sub-type', { params: { page } })
            .then((response) => {
                if (page) {
                    setVenueSubTypes({
                        pagination: response.data?.data.pagination,
                        data: [...venueSubTypes?.data, ...response.data?.data.results],
                    })
                } else {
                    setVenueSubTypes({
                        pagination: response.data?.data.pagination,
                        data: response.data?.data.results,
                    })
                }
            })
    }

    useEffect(() => {
        setNoLogo(!Boolean(venueDetails.image))
        fetchHoliday();
        fetchVenueSubTypes()
    }, [venueDetails])


    const formik = useFormik({
        initialValues: {
            ...venueDetails,
            venue_hours: Boolean(openVenueHours.length) ? openVenueHours : [
                {
                    venuetimings: 'open',
                    dayname: 'Sunday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Monday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Tuesday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Wednesday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Thursday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Friday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                },
                {
                    venuetimings: 'open',
                    dayname: 'Saturday',
                    opentimehours: 12,
                    opentimemins: 0,
                    closedtimehours: 18,
                    closedtimemins: 0,
                    isclosed: '1',
                    time_interval: 60
                }
            ]
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)

            nodeAxios.post('/venue/update-venue', values)
                .then(() => {
                    toast.success('Saved Successfully')
                    setIsEditMode(false)
                })
                .finally(() => {
                    setLoading(false)
                    fetchStoreInfo()
                })


            // let formData = new FormData();
            // if (imageFile) {
            //     formData.append('image', imageFile);
            // }
            // if (faviconFile) {
            //     formData.append('favicon_image', faviconFile);
            // }
            // formData.append('no_logo', noLogo);

            // axios.post('/venue/steps/branding', formData)
            //     .then(() => {
            //     }).finally(() => {
            //         fetchStoreInfo()
            //     })
        }
    })

    const handleHolidayDelete = (id) => {
        axios.delete(`/venue-holiday/delete/${id}`)
            .then(() => {
                fetchHoliday()
            })
    }

    return isLoading ? (<Loader />) : (
        <div className="tw-mt-4">
            <h2 className="tw-font-semibold tw-text-2xl tw-my-4">Store Info</h2>

            <div className="tw-bg-[#F6F6F6] tw-rounded-md tw-flex tw-justify-between tw-flex-col-reverse lg:tw-flex-row">
                <div className="tw-w-full lg:tw-w-1/2 tw-h-[300px]">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: Const.GOOGLE_MAP_KEY }}
                        center={{
                            lat: parseFloat(venueDetails?.latitude) || 0,
                            lng: parseFloat(venueDetails?.longitude) || 0,
                        }}
                        defaultZoom={13}
                        resetBoundsOnResize
                        yesIWantToUseGoogleMapApiInternals
                    >
                        <LocationOn
                            style={{
                                color: 'red'
                            }}
                        />
                    </GoogleMapReact>

                </div>
                <div className="lg:tw-w-1/2 ">
                    <StoreInformation
                        venueDetails={venueDetails}
                        formik={formik}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        loading={loading}
                        isActionsDisabled={isActionsDisabled}
                        isSmall={isSmall}
                        venueSubTypes={venueSubTypes}
                    />
                </div>
            </div>

            <BusinessHours formik={formik} isSmall={isSmall} loading={loading} />
            <AddHoliday
                formik={formik} isSmall={isSmall} loading={loading} fetchHoliday={fetchHoliday}
                editObj={editObj}
                isEdit={isEdit}
                holidays={holidays}
            // onClose={() => {
            //     setShowAddHoliday(false)
            //     setEditObj({})
            //     setIsEdit(false)
            //     fetchHoliday()
            // }}
            />
            <div className='tw-hidden'>
                <div>
                    <div
                        style={{
                            padding: 10,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            className={styles.holiday_txt}
                        >
                            Holidays
                        </div>

                        <Button
                            startIcon={<Add />}
                            onClick={() => {
                                setShowAddHoliday(true)
                            }}
                        >
                            Add Holiday
                        </Button>
                    </div>

                    <div
                        style={{
                            padding: 15
                        }}
                    >
                        {holidays?.map((data) => (
                            <div
                                className={styles.date_chip}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 10
                                    }}
                                >
                                    <div>
                                        {data.date}
                                    </div>
                                    <div>
                                        {data.title}
                                    </div>
                                </div>

                                <div>
                                    <IconButton
                                        onClick={() => {
                                            setEditObj(data)
                                            setIsEdit(true)
                                            setShowAddHoliday(true)
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleHolidayDelete(data.id)}
                                    >
                                        <DeleteForever
                                            style={{
                                                color: 'red'
                                            }}
                                        />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='tw-hidden'>
                <GenericLoadingButton
                    variant="contained"
                    type="button"
                    onClick={formik.handleSubmit}
                    size="large"
                    loading={loading}
                    loadingPosition="end"
                    disabled={isActionsDisabled}
                >
                    Save
                </GenericLoadingButton>
            </div>

            <Dialog
                open={showAddHoliday}
                onClose={() => {
                    setShowAddHoliday(false)
                    setEditObj({})
                    setIsEdit(false)
                }}
            >
                <AddHoliday
                    editObj={editObj}
                    isEdit={isEdit}
                    onClose={() => {
                        setShowAddHoliday(false)
                        setEditObj({})
                        setIsEdit(false)
                        fetchHoliday()
                    }}
                />
            </Dialog>
        </div>
    )
}

export default StoreInfo