import { IconButton,  } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import apleplay from '../../../../../assets/offferReview/apleplay.png'
import facebook from '../../../../../assets/offferReview/facebook.svg'
import gplay from '../../../../../assets/offferReview/gplay.png'
import instagram from '../../../../../assets/offferReview/instagram.svg'
import linkedin from '../../../../../assets/offferReview/linkedin.svg'
import pintrest from '../../../../../assets/offferReview/pintrest.svg'
// import snapchat from '../../../../../assets/offferReview/snapchat.svg'
// import tiktok from '../../../../../assets/offferReview/tiktok.svg'
import x from '../../../../../assets/offferReview/x.svg'
import React, { useEffect, useState } from 'react';

import axios, { nodeAxios } from '../../../../../utils/axiosConfig'


const EmailPreview = ({ selectedTab, ...props }) => {

    const [venueSettings, setVenueSettings] = useState(null);
    
    const [domain, setDomain] = useState('');
    useEffect(() => {
        fetchVenueSettings();
        fetchSubdomains()
    }, [])

    const fetchVenueSettings = () => {
        axios
            .get('/venue/setting', {
                params: {
                    // template_id: id
                },
            })
            .then((response) => {
                setVenueSettings(response.data?.data);
            })
            .catch((err) => {
                console.log('🚀OUTPUT --> err:', err);
            });
    };

    const fetchSubdomains = () => {
        nodeAxios.get('/web-builder/web-page/domains').then((response) => {
        // if (Boolean(response.data?.data?.result?.subdomain)) {}
        if (response.data?.data?.custom_domain) {
        // setIsCustomDomain(true);
        setDomain(
            `https://${response.data?.data?.custom_domain}`
        );
        } else {
        // setIsCustomDomain(false);
        setDomain(
            `https://${response.data?.data?.subdomain}.esplanda.com`
        );
        }
      });
    };
    
  const getSocialLink = (social, value) => {
    switch (social) {
      case 'facebook':
        return `https://www.facebook.com/${value}`
      case 'instagram':
        return `https://www.instagram.com/${value}`
      case 'twitter':
        return `https://twitter.com/${value}`
      case 'snapchat':
        return `https://www.snapchat.com/add/${value}`
      case 'tiktok':
        return `https://www.tiktok.com/${value}`
      case 'linkedin':
        return `https://www.linkedin.com/in/${value}`
      case 'youtube':
        return `https://www.youtube.com/${value}`
      case 'pinterest':
        return `https://www.pinterest.com/${value}`
      default:
        return ''
    }
  }

    return (
        <div style={{ backgroundColor: '#f8f8f8' }}>
            {/*[if gte mso 9]>
                <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
                <v:fill type="tile" color="#1e1e1e"/>
                </v:background>
                <![endif]*/}
            <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                <tbody><tr>
                    <td valign="top" align="center">
                        <table bgcolor="#FF0000" style={{ margin: '0 auto' }} align="center" id="brick_container" cellSpacing={0} cellPadding={0} border={0} className="email-container lg:tw-w-{680}">
                            <tbody><tr>
                                <td className='lg:tw-w-{680}'>
                                    <table cellSpacing={0} cellPadding={0} border={0}>
                                        <tbody><tr>
                                            <td className='lg:tw-w-{680}' style={{ backgroundColor: '#FF0000' }} bgcolor="#FF0000">
                                                <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                    <tbody><tr>
                                                        <td width="100%">
                                                            <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                <tbody><tr>
                                                                    <td width="100%" className="heading_text" style={{ verticalAlign: 'middle', backgroundColor: '#ffffff', borderWidth: '0px 0px 1px 0px', borderColor: '#d3d3d3', borderStyle: 'solid', paddingLeft: '30px', paddingRight: '30px' }} bgcolor="#ffffff">
                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                            <tbody><tr>
                                                                                <td height={14} style={{ height: '14px', minHeight: '14px', lineHeight: '14px' }}>
                                                                                </td>
                                                                            </tr>
                                                                                <tr>
                                                                                    <td style={{ verticalAlign: 'middle' }} width="100%">
                                                                                        <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                                            <tbody><tr>
                                                                                                <td width="100%" align="center" style={{ verticalAlign: 'middle' }}>
                                                                                                    <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                        <tbody><tr>
                                                                                                            <td style={{ verticalAlign: 'middle', width: '100%' }} align="center">
                                                                                                                <div style={{ lineHeight: 'normal', textAlign: 'center', width: '100%', minHeight: '25px' }}>
                                                                                                                    <span style={{ width: '100%', textAlign: 'center', color: '#000000', fontWeight: 500, fontFamily: 'Inter,Arial,sans-serif', fontSize: '20px', lineHeight: 'normal' }}>{venueSettings?.venue?.Name}</span>

                                                                                                                    <IconButton
                                                                                                                        aria-label="close"
                                                                                                                        onClick={() => {

                                                                                                                            props.onClose()
                                                                                                                        }}
                                                                                                                        sx={{ color: '#000', position: 'absolute', right: '10px', top: '6px' }}
                                                                                                                    >
                                                                                                                        <Cancel />
                                                                                                                    </IconButton>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        </tbody></table>
                                                                                                </td>
                                                                                            </tr>
                                                                                            </tbody></table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td height={14} style={{ height: '14px', minHeight: '14px', lineHeight: '14px' }}>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody></table>
                                                                    </td>
                                                                </tr>
                                                                </tbody></table>
                                                        </td>
                                                    </tr>
                                                        <tr>
                                                            <td width="100%">
                                                                <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                    <tbody><tr>
                                                                        <td width="100%" style={{ backgroundColor: '#ffffff', paddingLeft: '20px', paddingRight: '20px' }} bgcolor="#ffffff">
                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                <tbody><tr>
                                                                                    <td height={20} style={{ height: '20px', minHeight: '20px', lineHeight: '20px' }}>
                                                                                    </td>
                                                                                </tr>
                                                                                    <tr>
                                                                                        <td width="100%">
                                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                <tbody><tr>
                                                                                                    <td>
                                                                                                        <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
                                                                                                            <span style={{ color: '#000000', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}>Dear
                                                                                                                {' {'}Customer Name{'}'},<br /><br /></span></div>
                                                                                                        <div style={{ height: '3px', lineHeight: '3px', fontSize: '3px' }}>
                                                                                                            &nbsp;</div>
                                                                                                        <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
                                                                                                            <span style={{ color: '#000000', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}>{props.formik.values.email_body}</span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody></table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={20} style={{ height: '20px', minHeight: '20px', lineHeight: '20px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody></table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100%">
                                                                <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                    <tbody><tr>
                                                                        <td width="100%" align="center" style={{ verticalAlign: 'middle', backgroundColor: '#214530', paddingLeft: '20px', paddingRight: '20px' }} bgcolor="#214530">
                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                <tbody><tr>
                                                                                    <td height={49} style={{ height: '49px', minHeight: '49px', lineHeight: '49px' }}>
                                                                                    </td>
                                                                                </tr>
                                                                                    <tr>
                                                                                        <td style={{ verticalAlign: 'middle' }} align="center">
                                                                                            <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '40px', lineHeight: 'normal', textAlign: 'center' }}>{props.formik.values.offer_name}</span></div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={10} style={{ height: '10px', minHeight: '10px', lineHeight: '10px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        {selectedTab === 'offer' && (
                                                                                            <td style={{ verticalAlign: 'middle' }} align="center">
                                                                                                <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                    <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '18px', lineHeight: 'normal', textAlign: 'center' }}>{props.formik.values.coupon_type === 'percentage' ? props.formik.values.coupon_limit + '% ' : '$' + props.formik.values.coupon_limit + ' '}
                                                                                                        off on your next purchase</span>
                                                                                                </div>
                                                                                            </td>
                                                                                        )}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={10} style={{ height: '10px', minHeight: '10px', lineHeight: '10px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        {selectedTab === 'offer' && (
                                                                                            <td style={{ verticalAlign: 'middle' }} align="center">
                                                                                                <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                    <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: 'normal', textAlign: 'center' }}>Valid
                                                                                                        for {props.formik.values.valid_for === "7" ? 'One Week' : props.formik.values.valid_for === "14" ? 'Two Weeks' : props.formik.values.valid_for === "30" ? 'One Month' : props.formik.values.valid_for === "90" ? 'Three Months' : props.formik.values.valid_for === "180" ? '6 Months' : props.formik.values.valid_for === "365" ? '1 Year' : props.formik.values.valid_for === "-1" ? 'Forever' : ''}</span></div>
                                                                                            </td>
                                                                                        )}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={10} style={{ height: '10px', minHeight: '10px', lineHeight: '10px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ verticalAlign: 'middle' }} width="100%">
                                                                                            <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                                                <tbody><tr>
                                                                                                    <td width="100%" align="center" style={{ verticalAlign: 'middle' }}>
                                                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                            <tbody><tr>
                                                                                                                <td height={12} style={{ height: '12px', minHeight: '12px', lineHeight: '12px' }}>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                                <tr>
                                                                                                                    {selectedTab === 'offer' && (
                                                                                                                        <td style={{ verticalAlign: 'middle' }} align="center">
                                                                                                                            <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                                                <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: 'normal', textAlign: 'center' }}>Offer
                                                                                                                                    code:
                                                                                                                                    {' {'}Auto-Generated-When-Email-Is-Sent{'}'}</span>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    )}
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td height={12} style={{ height: '12px', minHeight: '12px', lineHeight: '12px' }}>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody></table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody></table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={10} style={{ height: '10px', minHeight: '10px', lineHeight: '10px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td align="center">
                                                                                            <a href={domain} target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                    <tbody><tr>
                                                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                                                            <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                <tbody><tr>
                                                                                                                    <td style={{ backgroundColor: '#ffffff', borderRadius: '49px', paddingLeft: '17px', paddingRight: '17px' }} bgcolor="#ffffff">
                                                                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td height={9} style={{ height: '9px', minHeight: '9px', lineHeight: '9px' }}>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                                <tr>
                                                                                                                                    <td width={89}>
                                                                                                                                        <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                                                            <span 
                                                                                                                                                style={{ color: '#000000', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                                                                    Order Now
                                                                                                                                                </span>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td height={9} style={{ height: '9px', minHeight: '9px', lineHeight: '9px' }}>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                </tbody></table>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    </tbody></table>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={49} style={{ height: '49px', minHeight: '49px', lineHeight: '49px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody></table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100%">
                                                                <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                    <tbody><tr>
                                                                        <td width="100%" style={{ backgroundColor: '#ffffff', paddingLeft: '20px', paddingRight: '20px' }} bgcolor="#ffffff">
                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                <tbody><tr>
                                                                                    <td height={20} style={{ height: '20px', minHeight: '20px', lineHeight: '20px' }}>
                                                                                    </td>
                                                                                </tr>
                                                                                    <tr>
                                                                                        <td width="100%">
                                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                <tbody><tr>
                                                                                                    <td>
                                                                                                        <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
                                                                                                            <span style={{ color: '#000000', fontWeight: 500, fontFamily: 'Inter,Arial,sans-serif', fontSize: '24px', lineHeight: 'normal', textAlign: 'left' }}>How
                                                                                                                to redeem this
                                                                                                                offer</span></div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody></table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={13} style={{ height: '13px', minHeight: '13px', lineHeight: '13px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td width="100%">
                                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                <tbody><tr>
                                                                                                    <td width="100%">
                                                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                            <tbody><tr>
                                                                                                                <td width={35}>
                                                                                                                    <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                        <tbody><tr>
                                                                                                                            <td width={35} align="center" style={{ verticalAlign: 'middle', height: '35px', backgroundColor: '#000000', borderRadius: '90px' }} bgcolor="#000000">
                                                                                                                                <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                                    <tbody><tr>
                                                                                                                                        <td align="center">
                                                                                                                                            <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                                                <tbody><tr>
                                                                                                                                                    <td style={{ height: '20px', verticalAlign: 'middle' }} width={19} align="center">
                                                                                                                                                        <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                                                                            <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: 'normal', textAlign: 'center' }}>1</span>
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                                </tbody></table>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                    </tbody></table>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        </tbody></table>
                                                                                                                </td>
                                                                                                                <td style={{ width: '13px', minWidth: '13px' }} width={13} />
                                                                                                                <td>
                                                                                                                    <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                        <tbody><tr>
                                                                                                                            <td height={7} style={{ height: '7px', minHeight: '7px', lineHeight: '7px' }}>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
                                                                                                                                        <span style={{ color: '#000000', fontWeight: 600, fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}>Visit
                                                                                                                                            Our
                                                                                                                                            Website
                                                                                                                                            or
                                                                                                                                            App:</span><span style={{ color: '#000000', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}> Open
                                                                                                                                                our
                                                                                                                                                website
                                                                                                                                                or
                                                                                                                                                app,
                                                                                                                                                your
                                                                                                                                                offer
                                                                                                                                                is
                                                                                                                                                already
                                                                                                                                                waiting
                                                                                                                                                for
                                                                                                                                                you.</span>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody></table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            </tbody></table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                    <tr>
                                                                                                        <td height={12} style={{ height: '12px', minHeight: '12px', lineHeight: '12px' }}>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td width="100%">
                                                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                <tbody><tr>
                                                                                                                    <td width={35}>
                                                                                                                        <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td width={35} align="center" style={{ verticalAlign: 'middle', height: '35px', backgroundColor: '#000000', borderRadius: '90px' }} bgcolor="#000000">
                                                                                                                                    <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                                        <tbody><tr>
                                                                                                                                            <td align="center">
                                                                                                                                                <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                                                    <tbody><tr>
                                                                                                                                                        <td style={{ height: '20px', verticalAlign: 'middle' }} width={19} align="center">
                                                                                                                                                            <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                                                                                <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: 'normal', textAlign: 'center' }}>2</span>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                    </tbody></table>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        </tbody></table>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                    <td style={{ width: '13px', minWidth: '13px' }} width={13} />
                                                                                                                    <td>
                                                                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td height={7} style={{ height: '7px', minHeight: '7px', lineHeight: '7px' }}>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
                                                                                                                                            <span style={{ color: '#000000', fontWeight: 600, fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}>Add
                                                                                                                                                Items
                                                                                                                                                to
                                                                                                                                                Your
                                                                                                                                                Cart:</span><span style={{ color: '#000000', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}> Select
                                                                                                                                                    the
                                                                                                                                                    items
                                                                                                                                                    you
                                                                                                                                                    want
                                                                                                                                                    and
                                                                                                                                                    add
                                                                                                                                                    them
                                                                                                                                                    to
                                                                                                                                                    your
                                                                                                                                                    cart.</span>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                </tbody></table>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td height={12} style={{ height: '12px', minHeight: '12px', lineHeight: '12px' }}>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td width="100%">
                                                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                <tbody><tr>
                                                                                                                    <td width={35}>
                                                                                                                        <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td width={35} align="center" style={{ verticalAlign: 'middle', height: '35px', backgroundColor: '#000000', borderRadius: '90px' }} bgcolor="#000000">
                                                                                                                                    <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                                        <tbody><tr>
                                                                                                                                            <td align="center">
                                                                                                                                                <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                                                    <tbody><tr>
                                                                                                                                                        <td style={{ height: '20px', verticalAlign: 'middle' }} width={19} align="center">
                                                                                                                                                            <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                                                                                <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: 'normal', textAlign: 'center' }}>3</span>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                    </tbody></table>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        </tbody></table>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                    <td style={{ width: '13px', minWidth: '13px' }} width={13} />
                                                                                                                    <td>
                                                                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td height={7} style={{ height: '7px', minHeight: '7px', lineHeight: '7px' }}>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
                                                                                                                                            <span style={{ color: '#000000', fontWeight: 600, fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}>Apply
                                                                                                                                                Coupon
                                                                                                                                                Code:</span><span style={{ color: '#000000', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}> During your Checkout process, Click on “Have a Coupon”, Enter the provided coupon code (Auto-Generated-When-Email-Is-Sent) and hit “Apply”.
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td height={7} style={{ height: '7px', minHeight: '7px', lineHeight: '7px' }}>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                </tbody></table>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td height={12} style={{ height: '12px', minHeight: '12px', lineHeight: '12px' }}>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td width="100%">
                                                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                <tbody><tr>
                                                                                                                    <td width={35}>
                                                                                                                        <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td width={35} align="center" style={{ verticalAlign: 'middle', height: '35px', backgroundColor: '#000000', borderRadius: '90px' }} bgcolor="#000000">
                                                                                                                                    <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                                        <tbody><tr>
                                                                                                                                            <td align="center">
                                                                                                                                                <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                                                    <tbody><tr>
                                                                                                                                                        <td style={{ height: '20px', verticalAlign: 'middle' }} width={19} align="center">
                                                                                                                                                            <div style={{ lineHeight: 'normal', textAlign: 'center' }}>
                                                                                                                                                                <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: 'normal', textAlign: 'center' }}>3</span>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                    </tbody></table>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        </tbody></table>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                    <td style={{ width: '13px', minWidth: '13px' }} width={13} />
                                                                                                                    <td>
                                                                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td height={7} style={{ height: '7px', minHeight: '7px', lineHeight: '7px' }}>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
                                                                                                                                            <span style={{ color: '#000000', fontWeight: 600, fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}>Complete
                                                                                                                                                Your
                                                                                                                                                Purchase:</span><span style={{ color: '#000000', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '20px', textAlign: 'left' }}> After
                                                                                                                                                    applying
                                                                                                                                                    the
                                                                                                                                                    coupon,
                                                                                                                                                    review
                                                                                                                                                    your
                                                                                                                                                    order
                                                                                                                                                    and
                                                                                                                                                    complete
                                                                                                                                                    the
                                                                                                                                                    purchase.</span>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td height={7} style={{ height: '7px', minHeight: '7px', lineHeight: '7px' }}>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                </tbody></table>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody></table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={20} style={{ height: '20px', minHeight: '20px', lineHeight: '20px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody></table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100%">
                                                                <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                    <tbody><tr>
                                                                        <td width="100%" align="center" style={{ verticalAlign: 'middle', backgroundColor: '#000000', paddingLeft: '24px', paddingRight: '24px' }} bgcolor="#000000">
                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                <tbody><tr>
                                                                                    <td height={34} style={{ height: '34px', minHeight: '34px', lineHeight: '34px' }}>
                                                                                    </td>
                                                                                </tr>
                                                                                    <tr>
                                                                                        <td style={{ verticalAlign: 'middle' }} width="100%">
                                                                                            <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                                                <tbody><tr>
                                                                                                    <td width="100%" align="center">
                                                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                            <tbody><tr>
                                                                                                                <td width="100%" align="center">
                                                                                                                    <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                        <tbody><tr>
                                                                                                                            <td align="center">
                                                                                                                                <div style={{ lineHeight: '24px', textAlign: 'center' }}>
                                                                                                                                    <a href="#" target="_blank" style={{ display: 'block', width: 'max-content', margin: 'auto', maxWidth: '270px', overflow: 'hidden', color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '24px', textAlign: 'center', textOverflow: 'ellipsis' }}>
                                                                                                                                        <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '24px', textAlign: 'center', maxWidth: '270px' }}>{''}
                                                                                                                                        </span>
                                                                                                                                    </a>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        </tbody></table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                                <tr>
                                                                                                                    <td height={24} style={{ height: '24px', minHeight: '24px', lineHeight: '24px' }}>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                {console.log('get local storeg', props.venueDetails)}
                                                                                                                {/* {(localStorage.getItem("app_ver_for_app")) && ( */}
                                                                                                                <tr>
                                                                                                                    <td width="100%" align="center">
                                                                                                                        <table border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                {venueSettings?.venue_social_links?.ios_app_link != null && venueSettings?.venue_social_links?.ios_app_link !== '' && (
                                                                                                                                    <td width={139} align="center">
                                                                                                                                        <a href={venueSettings?.venue_social_links?.ios_app_link?.includes('https://') ? venueSettings?.venue_social_links?.ios_app_link : `https://${venueSettings?.venue_social_links?.ios_app_link}`} target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                                                            <img src={apleplay} width={139} border={0} style={{ minWidth: '139px', width: '139px', height: 'auto', display: 'block' }} />
                                                                                                                                        </a>
                                                                                                                                    </td>)}
                                                                                                                                <td style={{ width: '15px', minWidth: '15px' }} width={15}>
                                                                                                                                </td>
                                                                                                                                {venueSettings?.venue_social_links?.android_app_link != null && venueSettings?.venue_social_links?.android_app_link !== '' && (
                                                                                                                                    <td width={139} align="center">
                                                                                                                                        <a href={venueSettings?.venue_social_links?.android_app_link?.includes('https://') ? venueSettings?.venue_social_links?.android_app_link : `https://${venueSettings?.venue_social_links?.android_app_link}`} target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                                                            <img src={gplay} width={139} border={0} style={{ minWidth: '139px', width: '139px', height: 'auto', display: 'block' }} />
                                                                                                                                        </a>
                                                                                                                                    </td>)}</tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                {/* )} */}
                                                                                                                <tr>
                                                                                                                    <td height={24} style={{ height: '24px', minHeight: '24px', lineHeight: '24px' }}>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td align="center">
                                                                                                                        <table cellSpacing={0} cellPadding={0} border={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td>
                                                                                                                                    <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                                        <tbody><tr>
                                                                                                                                            {venueSettings?.venue_social_links?.social_links?.facebook_url != null && venueSettings?.venue_social_links?.social_links?.facebook_url !== '' && (
                                                                                                                                                <td width={24}>
                                                                                                                                                    <a href={getSocialLink('facebook',venueSettings?.venue_social_links?.social_links.facebook_url)} target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                                                                        <img src={facebook} width={24} border={0} style={{ minWidth: '24px', width: '24px', height: 'auto', display: 'block' }} />
                                                                                                                                                    </a>
                                                                                                                                                </td>
                                                                                                                                            )}
                                                                                                                                            <td style={{ width: '16px', minWidth: '16px' }} width={16}>
                                                                                                                                            </td>

                                                                                                                                            {venueSettings?.venue_social_links?.social_links?.instagram_url != null && venueSettings?.venue_social_links?.social_links?.instagram_url !== '' && (
                                                                                                                                                <td width={24}>
                                                                                                                                                    <a href={getSocialLink('instagram',venueSettings?.venue_social_links?.social_links.instagram_url)} target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                                                                        <img src={instagram} width={24} border={0} style={{ minWidth: '24px', width: '24px', height: 'auto', display: 'block' }} />
                                                                                                                                                    </a>
                                                                                                                                                </td>
                                                                                                                                            )}
                                                                                                                                            <td style={{ width: '16px', minWidth: '16px' }} width={16}>
                                                                                                                                            </td>
                                                                                                                                            {venueSettings?.venue_social_links?.social_links?.twitter_url != null && venueSettings?.venue_social_links?.social_links?.twitter_url !== '' && (
                                                                                                                                                <td width={24}>
                                                                                                                                                    <a href={getSocialLink('twitter',venueSettings?.venue_social_links?.social_links.twitter_url)} target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                                                                        <img src={x} width={24} border={0} style={{ minWidth: '24px', width: '24px', height: 'auto', display: 'block' }} />
                                                                                                                                                    </a>
                                                                                                                                                </td>
                                                                                                                                            )}
                                                                                                                                            <td style={{ width: '16px', minWidth: '16px' }} width={16}>
                                                                                                                                            </td>
                                                                                                                                            {venueSettings?.venue_social_links?.social_links?.linkedin_url != null && venueSettings?.venue_social_links?.social_links?.linkedin_url !== '' && (
                                                                                                                                                <td width={24}>
                                                                                                                                                    <a href={getSocialLink('linkedin',venueSettings?.venue_social_links?.social_links.linkedin_url)} target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                                                                        <img src={linkedin} width={24} border={0} style={{ minWidth: '24px', width: '24px', height: 'auto', display: 'block' }} />
                                                                                                                                                    </a>
                                                                                                                                                </td>
                                                                                                                                            )}

                                                                                                                                            <td style={{ width: '16px', minWidth: '16px' }} width={16}>
                                                                                                                                            </td>
                                                                                                                                            {venueSettings?.venue_social_links?.social_links?.pinterest_url != null && venueSettings?.venue_social_links?.social_links?.pinterest_url !== '' && (
                                                                                                                                                <td width={24}>
                                                                                                                                                    <a href={getSocialLink('pinterest',venueSettings?.venue_social_links?.social_links.pinterest_url)} target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                                                                        <img src={pintrest} width={24} border={0} style={{ minWidth: '24px', width: '24px', height: 'auto', display: 'block' }} />
                                                                                                                                                    </a>
                                                                                                                                                </td>
                                                                                                                                            )}

                                                                                                                                        </tr>
                                                                                                                                        </tbody></table>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td height={24} style={{ height: '24px', minHeight: '24px', lineHeight: '24px' }}>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td width="100%" align="center">
                                                                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                                                            <tbody><tr>
                                                                                                                                <td align="center">
                                                                                                                                    <div style={{ lineHeight: '24px', textAlign: 'center' }}>
                                                                                                                                        <span style={{ color: '#b8b8b8', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '24px', textAlign: 'center' }}>{venueSettings?.venue?.full_address}</span>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                                <tr>
                                                                                                                                    <td align="center">
                                                                                                                                        <div style={{ lineHeight: '24px', textAlign: 'center' }}>
                                                                                                                                            <span style={{ color: '#b8b8b8', fontFamily: 'Inter,Arial,sans-serif', fontSize: '14px', lineHeight: '24px', textAlign: 'center' }}><a style={{ color: '#b8b8b8', textDecoration: 'none' }} href={`mailto:${venueSettings?.venue?.email}`} target="_blank">{venueSettings?.venue?.email}</a>{' '}
                                                                                                                                                {venueSettings?.venue?.contact_no}</span>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody></table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody></table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody></table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height={34} style={{ height: '34px', minHeight: '34px', lineHeight: '34px' }}>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody></table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100%">
                                                                <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                    <tbody><tr>
                                                                        <td width="100%" align="center" style={{ backgroundColor: '#000000' }} bgcolor="#000000">
                                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                                                <tbody><tr>
                                                                                    <td width="100%">
                                                                                        <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                                                            <tbody><tr>
                                                                                                <td width="100%" align="center" style={{ verticalAlign: 'middle', backgroundColor: '#000000', borderWidth: '1px 0px 0px 0px', borderColor: '#2f2f2f', borderStyle: 'solid' }} bgcolor="#000000">
                                                                                                    <table border={0} style={{ margin: 'auto' }} cellPadding={0} cellSpacing={0}>
                                                                                                        <tbody><tr>
                                                                                                            <td height={7} style={{ height: '7px', minHeight: '7px', lineHeight: '7px' }}>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                            <tr>
                                                                                                                <td style={{ verticalAlign: 'middle' }} align="center">
                                                                                                                    <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
                                                                                                                        <span style={{ color: '#ffffff', fontFamily: 'Inter,Arial,sans-serif', fontSize: '12px', lineHeight: 'normal', textAlign: 'left' }}>Powered
                                                                                                                            by</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td style={{ width: '5px', minWidth: '5px' }} width={5} />
                                                                                                                <td style={{ verticalAlign: 'middle' }} width={67} align="center">
                                                                                                                    <a href="https://esplanda.com" target="_blank" style={{ display: 'block', width: 'max-content' }}>
                                                                                                                        <img src="https://d36musakzcdau7.cloudfront.net/esplanda/WebPage/fullred-1704262423.png" width={67} border={0} style={{ minWidth: '67px', width: '67px', height: 'auto', display: 'block' }} />
                                                                                                                    </a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td height={7} style={{ height: '7px', minHeight: '7px', lineHeight: '7px' }}>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody></table>
                                                                                                </td>
                                                                                            </tr>
                                                                                            </tbody></table>
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody></table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                            </td>
                                        </tr>
                                        </tbody></table>
                                </td>
                            </tr>
                            </tbody></table>
                    </td>
                </tr>
                </tbody></table>
        </div>
    )
}
export default EmailPreview