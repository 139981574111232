import React, { useEffect, useRef } from 'react';

// import dynamic from 'next/dynamic';

import axios from '../../EditSection/Util/axiosConfig';
import draftToHtml from 'draftjs-to-html';
import styles from './DraftEditor.module.scss';
import { convertToRaw } from 'draft-js';
import getImageComponent from '../RichTextEditor/Renderer/Image';
import ImageTool from '../RichTextEditor/Component/Image/ImageTool/ImageTool';
import { en } from '../RichTextEditor/RteTranslations';
import { formatUri } from '../../EditSection/Util/helpers';

import { Editor } from 'react-draft-wysiwyg';
// const Editor = dynamic(
  //   () => import('react-draft-wysiwyg').then((mod) => mod.Editor),
  //   { ssr: false }
// );

export default function DraftEditor({
  editorState,
  setEditorState,
  setConvertedContent,
  readOnly,
  toolbarNotFixed,
  editorClassName,
  pages
}) {
  const editorStateRef = useRef()

   const customEntityTransform = (entity, text) => {
    if (entity.type !== 'IMAGE' || (entity.type === 'IMAGE' && !Boolean(entity.data?.href?.trim()))) {
      return
    }
    console.log(entity, 'eeeeee')
    // eslint-disable-next-line consistent-return
    return `<a href="${formatUri(entity.data?.href)}"><img src="${entity.data?.src}" height="${entity.data?.height}" width="${entity.data?.width}" /></a>`;
  };



  useEffect(() => {
    // let html = draftToHtml(editorContent);
    let html2 = draftToHtml(convertToRaw(editorState.getCurrentContent()),{},false,customEntityTransform);
    // console.log('🚀OUTPUT --> html2 from editorState:', html2);

    setConvertedContent(html2); 
  }, [editorState]); 

  
  const imageBlockRenderer = (contentBlock, props) => {

    const contentState = editorState.getCurrentContent();
    const type = contentBlock.getType();

    if (type === 'atomic') {
      const entity = contentBlock.getEntityAt(0);
      if (entity && contentState.getEntity(entity).getType() === 'IMAGE') {
        return {
          component: getImageComponent(props),
          editable: false,
          props: props
        };
      }
    }

    // return null;
  };
  
  useEffect(() => {
    editorStateRef.current = editorState
  }, [editorState])

  return (
    <>
      <Editor
        readOnly={readOnly || false}
        localization={{
          translations: en
        }}
        blockRendererFn={(block) => imageBlockRenderer(block, {
          contentState: editorState.getCurrentContent(),
          setEditorState,
          getEditorState: () => editorState,
          width: 'auto',
          height: 'auto'
        })}
        // toolbarOnFocus
        // initialContentState={editorContent}
        // toolbarHidden={!editorFocused} 
        onEditorStateChange={setEditorState}
        editorState={editorState}
        // onChange={setEditorContent}
        // editorContent={editorContent}
        // onEditorContentChange={setEditorContent}
        stripPastedStyles={true}
        wrapperClassName={styles.wrapper}
        editorClassName={editorClassName ? editorClassName : styles.editor}
        toolbarClassName={!toolbarNotFixed && styles.toolbar}
        toolbar={{
          options: [
            'fontSize',
            'blockType',
            'inline',
            'colorPicker',
            'image',
            'link',
            'emoji',
            'textAlign',
            'list',
            'fontFamily',
            'remove',
            'history',
          ],
          inline: {
            // inDropdown: true,
            options: [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'monospace',
            ],
          },
          fontSize: {
            // icon: fontSize,
            className: styles.toolbar_fontsize,
            options: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
              36, 48, 60, 72, 96,
            ],
            // className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          fontFamily: {
            options: [ 'Arial', 'Arial Narrow', 'Avenir', 'Book Antiqua', 'Calibri', 'Cambria', 'Century Sans', 'Constantia', 'Garamond', 'Geneva',
              'Georama', 'Georgia', 'Gill Sans', 'Helvetica', 'Karla', 'Lato', 'Merriweather', 'Montserrat', 'Open Sans', 'Oswald', 'Poppins',
              'Raleway', 'Roboto', 'Tahoma', 'Trebuchet MS', 'Ubuntu', 'Veranda', ], // prettier-ignore
            className: 'bordered-option-classname',
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          image: { 
            component: ImageTool,
            popupClassName: styles.image_popup,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: uploadCallback,
            previewImage: true,
            className: true, // externalLinkOnly : true
            // inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            getEditorState: () => editorState,
            setEditorState,
            alt: {
              present: () => editorStateRef.current,
              mandatory: setEditorState,
              pages
            },
            defaultSize: {
              height: pages,
              width: 'auto',
            },
            // urlEnabled: true,
            // uploadEnabled: true,
            // alignmentEnabled: true,
            // uploadCallback: uploadCallback,
            // previewImage: true, 
            // alt: { present: false, mandatory: false },
            // defaultSize: {
            //   height: 'auto',
            //   width: 'auto',
            // },
          },
          link: {
            inDropdown: false,
            className: 'toolbar_link',
            component: undefined,
            popupClassName: styles.toolbar_link_popup,
            // dropdownClassName: 'toolbar_link_dropdown',
            showOpenOptionOnHover: false,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
            link: { className: 'toolbarLink' },
            unlink: { className: undefined },
            linkCallback: undefined,
          },
          textAlign: {
            inDropdown: true,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
            left: { className: styles.text_left },
            center: { className: styles.text_center },
            right: { className: styles.text_right },
            justify: { className: styles.text_justify },
          },
        }}
      />
      {/* <textarea disabled value={JSON.stringify(contentState, null, 4)} /> */}
    </>
  );
}
const uploadCallback = async (file) => {
  const formData = new FormData();
  formData.append('image_file', file);
  let imgUrl = '';

  await axios.post('/web-builder/web-page/upload-image', formData).then((res) => {
    imgUrl = res.data?.data.base_image + '/' + res.data?.data.content;

    console.log('🚀OUTPUT --> imgUrl:', imgUrl);
  });
  if (imgUrl !== '') {
    return new Promise((resolve, reject) => {
      // resolve({ data: { link: URL.createObjectURL(file) } });
      resolve({ data: { link: imgUrl } });
    });
  }
};
