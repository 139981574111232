import React, { useEffect, useState } from 'react';

import InventoryItem from './InventoryItem';
import arrows from '../../../../assets/arrows.png';
import AddForm from './AddForm';
import { useSelector, useDispatch } from 'react-redux';
import { getSuggestions } from "../../../../redux/actions/inventoryActions";
import { getCategoriesMenu } from '../../../../redux/actions/searchActions';
import { toast } from 'react-toastify';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import StyledTableCell from '../../../Library/StyledTableCell/StyledTableCell';
import StyledTableRow from '../../../Library/StyledTableRow/StyledTableRow';
import { Typography } from '@mui/material';



function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const InventoryList = (props) => {
    const inventories = useSelector(state => state.inventory.inventories.data);
    const dispatch = useDispatch();
    useEffect(() => {

        (async () => {
            try {
                dispatch(getCategoriesMenu())
                dispatch(getSuggestions({
                    q: "",
                    catId: 1
                }))
            } catch (err) {
                toast.error('There was an error while fetching inventories, try again later')
                return;
            }
        })();
    }, []);
    return (

        <TableContainer component={Paper} style={{ width: '100%' }}>
            <Table aria-label="customized table">
                {props.mobile ? (<></>) : (<TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    onClick={() => {
                                        props.setSort({ ...props.sort, sort_by: 'product_name' })
                                    }}
                                    variant="h6"
                                    style={{
                                        fontSize: '1rem',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Created At
                                </Typography>
                                <div
                                    style={{
                                        width: '12px',
                                        marginLeft: '1.9vw',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (props.sort.sort_by === 'created_at') {
                                            if (props.sort.sort_by_order === 'ASC') {
                                                props.setSort({ ...props.sort, sort_by_order: 'DESC' })
                                            } else {
                                                props.setSort({ ...props.sort, sort_by_order: 'ASC' })
                                            }
                                        }
                                    }}
                                >
                                    <img src={arrows} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    onClick={() => {
                                        props.setSort({ ...props.sort, sort_by: 'product_name' })
                                    }}
                                    variant="h6"
                                    style={{
                                        fontSize: '1rem',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Name
                                </Typography>
                                <div
                                    style={{
                                        width: '12px',
                                        marginLeft: '1.9vw',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (props.sort.sort_by === 'product_name') {
                                            if (props.sort.sort_by_order === 'ASC') {
                                                props.setSort({ ...props.sort, sort_by_order: 'DESC' })
                                            } else {
                                                props.setSort({ ...props.sort, sort_by_order: 'ASC' })
                                            }
                                        }
                                    }}
                                >
                                    <img src={arrows} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography
                                    onClick={() => {
                                        props.setSort({ ...props.sort, sort_by: 'price' })
                                    }}
                                    variant="h6"
                                    style={{
                                        fontSize: '1rem',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Price
                                </Typography>
                                <div
                                    onClick={() => {
                                        if (props.sort.sort_by === 'price') {
                                            if (props.sort.sort_by_order === 'ASC') {
                                                props.setSort({ ...props.sort, sort_by_order: 'DESC' })
                                            } else {
                                                props.setSort({ ...props.sort, sort_by_order: 'ASC' })
                                            }
                                        }
                                    }}
                                    style={{
                                        width: '12px',
                                        marginLeft: '1.9vw',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <img src={arrows} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography
                                    onClick={() => {
                                        props.setSort({ ...props.sort, sort_by: 'quantity' })
                                    }}
                                    variant="h6"
                                    style={{
                                        fontSize: '1rem',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Quantity
                                </Typography>
                                <div
                                    onClick={() => {
                                        if (props.sort.sort_by === 'quantity') {
                                            if (props.sort.sort_by_order === 'ASC') {
                                                props.setSort({ ...props.sort, sort_by_order: 'DESC' })
                                            } else {
                                                props.setSort({ ...props.sort, sort_by_order: 'ASC' })
                                            }
                                        }
                                    }}
                                    style={{
                                        width: '12px',
                                        marginLeft: '1.9vw',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <img src={arrows} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                </TableHead>)}
                <TableBody >
                    {props.activeForm.name === "add" && (
                        <AddForm
                            setActiveForm={props.setActiveForm}
                            handleInventoryChange={props.handleInventoryChange}
                            fetchInventory={props.fetchInventory}
                        />
                    )}

                    {inventories.map((val, i) => {
                        return (
                            <InventoryItem isActionsDisabled={props.isActionsDisabled} setDirty={props.setDirty} setPristine={props.setPristine} setActiveForm={props.setActiveForm} activeForm={props.activeForm} setActiveModal={props.setActiveModal} data={val} handleInventoryChange={props.handleInventoryChange} fetchInventory={props.fetchInventory} mobile={props.mobile} />
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default InventoryList;
