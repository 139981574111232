import React from 'react'
import styles from './DefaultLogo.module.scss'
import DefaultLogoIcon from '../../../assets/default-logo.png'

const DefaultLogo = (props) => {
    return (
        <div
            className={styles.container}
            style={{
                backgroundImage: `url(${DefaultLogoIcon})`,
            }}
        >
            <div
                className={styles.name}
            >
                {props.name}
            </div>
        </div>
    )
}

export default DefaultLogo