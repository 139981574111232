import React, { useState, useEffect, useRef, useContext } from "react";
import { Breadcrumbs, Typography, Pagination, Box, Paper, TableContainer, Table, TableRow, TableBody, TableCell, TablePagination, Button, useMediaQuery, MenuItem, Dialog, CircularProgress, IconButton, Drawer } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import day from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Loader from "../Library/Loader";
import axios, { nodeAxios } from '../../utils/axiosConfig'
import EnhancedTableToolbar from "./EnhancedTableToolbar/EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead/EnhancedTableHead";
import styles from './Order.module.scss'
import OrderTimer from "./OrderTimer/OrderTimer";
import { AttachMoney, Close, Remove } from "@mui/icons-material";
import { toast } from "react-toastify";
import PageLoader from "../Library/PageLoader/PageLoader";
import GenericSelect from "../Library/GenericSelect/GenericSelect";
import AssignDriverDialog from "./AssignDriverDialog/AssignDriverDialog";
import generatePDF from "react-to-pdf";
import { convertToId, formatDate, getImage2 } from "../../utils/helpers";
import MarketingContext from "../Marketing2/Util/marketingContext";
import DeliveryAcceptButton from "../DeliveryAcceptButton/DeliveryAcceptButton";
import { useConfirm } from "material-ui-confirm";
import useSendToPrinter from "../../hooks/useSendToPrinter";

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Order',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Order status',
    },
    {
        id: 'orderdate',
        numeric: true,
        disablePadding: false,
        label: 'Order Date',
    },
    {
        id: 'accepted_on',
        numeric: true,
        disablePadding: false,
        label: 'Accepted Details',
    },
    {
        id: 'ready_on',
        numeric: true,
        disablePadding: false,
        label: 'Order ready',
    },
    {
        id: 'action1',
        numeric: true,
        disablePadding: false,
        label: '',
    },
    {
        id: 'action2',
        numeric: true,
        disablePadding: false,
        label: '',
    },
];

const headCells2 = [
    {
        id: 'date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    {
        id: 'totalOrder',
        numeric: true,
        disablePadding: false,
        label: 'Orders',
    },
    {
        id: 'totalRevenue',
        numeric: true,
        disablePadding: false,
        label: 'Order Total',
    },
    {
        id: 'productRevenue',
        numeric: true,
        disablePadding: false,
        label: 'Product Revenue',
    },
    {
        id: 'totalTaxAmount',
        numeric: true,
        disablePadding: false,
        label: 'Tax',
    },
    {
        id: 'totalEsplandaFee',
        numeric: true,
        disablePadding: false,
        label: 'Service charge',
    },
    {
        id: 'totalDeliveryFee',
        numeric: true,
        disablePadding: false,
        label: 'Delivery fee',
    },
    {
        id: 'totalPickupFee',
        numeric: true,
        disablePadding: false,
        label: 'Pickup fee',
    },
    {
        id: 'totalShippingFee',
        numeric: true,
        disablePadding: false,
        label: 'Shipping fee',
    },
    {
        id: 'totalDriverTip',
        numeric: true,
        disablePadding: false,
        label: 'Tip',
    },
    {
        id: 'totalCouponAmount',
        isNegative: true,
        numeric: true,
        disablePadding: false,
        label: 'Coupon Amount',
    },
    {
        id: 'totalRefundAmount',
        isNegative: true,
        numeric: true,
        disablePadding: false,
        label: 'Refund Amount',
    },
    {
        id: 'totalGiftCardAmount',
        isNegative: true,
        numeric: true,
        disablePadding: false,
        label: 'Giftcard Amount',
    },
    // {
    //     id: 'esplandaFees',
    //     isNegative: true,
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Esplanda Fees',
    // },
    {
        id: 'thirdPartyDeliveryFees',
        isNegative: true,
        numeric: true,
        disablePadding: false,
        label: '3rd Party Delivery Fees',
    },
];

const statusColor = {
    'o': '#FED813',
    'a': '#0060EF',
    'r': '#00C437',
    'can': '#E90000',
    'clo': '#00C437'
}

const getPrice = (price, isNegative) => {
    if (Boolean(parseFloat(price))) {
        return isNegative ? `(USD ${parseFloat(price).toFixed(2)})` : `USD ${parseFloat(price).toFixed(2)}`
    } else {
        return '-'
    }
}

const VenueOrderListing = (props) => {

    const { isOrderPageReset, setIsOrderPageReset } = useContext(MarketingContext)
    const targetRef = useRef();
    const targetRef2 = useRef();
    const maxIdRef = useRef();
    const user = useSelector((store) => store.user);
    console.log(user)
    const navigate = useNavigate();
    const isMobileScreen = useMediaQuery('(max-width:1000px)');
    const ordersInterval = useRef()
    const confirm = useConfirm()

    const [filters, setFilters] = useState({
        startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        endDate: new Date()
    });

    const [id, setId] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    const [isFirstLoading, setIsFirstLoading] = useState(true)
    const [activeButton, setActiveButton] = useState("open");
    const [orderState, setOrderState] = useState("none");
    const [fullFillment, setFullFillment] = useState('none')
    const [sortBy, setSortBy] = useState('')
    const [sortByOrder, setSortByOrder] = useState('asc')
    const [orders, setOrders] = useState([])
    const [reports, setReports] = useState([])
    const [pagination, setPagination] = useState({})
    const [reportsPagination, setreportsPagination] = useState({})
    const [isOrderHistory, setIsOrderHistory] = useState(false)
    const [isReports, setIsReports] = useState(false)
    const [isRevenueByDate, setIsRevenueByDate] = useState(false)
    const [showAssignDriver, setShowAssignDriver] = useState(false)
    const [row, setRow] = useState({})
    const [selectedDate, setSelectedDate] = useState('')
    const [select, setSelect] = useState('none')

    const [deliveryPartners, setDeliveryPartners] = useState([])
    const [isDeliveryLoading, setIsDeliveryLoading] = useState(true)
    const [isShippingLoading, setIsShippingLoading] = useState(true)
    const [shippingPartners, setShippingPartners] = useState([])
    const [settings, setSettings] = useState({})
    const [printOrderData, setPrintOrderData] = useState()
    const [isPrinterSaved, setIsPrinterSaved] = useState(false)
    const [showDoorDashTracking, setShowDoorDashTracking] = useState(false)
    const [trackingUrl, setTrackingUrl] = useState('')
    const { sendToPrinter } = useSendToPrinter({ user, isPrinterSaved })
    const [isDoorDashEnabled, setIsDoorDashEnabled] = useState(false)

    const checkDoorDash = () => {
        nodeAxios.get('/doordash/check-doordash')
            .then((res) => {
                setIsDoorDashEnabled(res.data.data)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    useEffect(() => {
        checkDoorDash()
    }, [])

    useEffect(() => {
        if (isOrderPageReset) {
            setOrderState("none")
            setFullFillment("none")
            setIsOrderHistory(false)
            setIsReports(false)
            setIsRevenueByDate(false)
            setShowAssignDriver(false)
            setIsOrderPageReset(false)
        }
    }, [isOrderPageReset])


    const fetchOrders = (page = 1, isUpdate) => {
        if (!isUpdate) {
            setIsLoading(true)
        }

        axios.get('/order/list-new', {
            params: {
                start_date: isRevenueByDate ? selectedDate : (isOrderHistory ? formatDate(filters.startDate, 'yyyy-MM-dd') : undefined),
                end_date: isRevenueByDate ? selectedDate : isOrderHistory ? formatDate(filters.endDate, 'yyyy-MM-dd') : undefined,
                order_history: isRevenueByDate ? 'all' : (isOrderHistory ? 1 : 0),
                page: page || 1,
                state: (user?.data?.biz_users_role == 3) ? 'r' : ((orderState === 'none') ? undefined : orderState),
                otype: (fullFillment === 'none') ? undefined : fullFillment,
                sort_by: sortBy,
                sort_by_order: sortByOrder
            }
        })
            .then((res) => {
                setOrders(res?.data?.data?.result)
                setPagination(res?.data?.data?.pagination)
                maxIdRef.current = res?.data?.data?.max_id
            })
            .finally(() => {
                setIsLoading(false)
                setIsFirstLoading(false)
            })
    }

    const fetchReports = (page) => {
        nodeAxios.get('/reports/revenue-by-day', {
            params: {
                since: formatDate(filters.startDate, 'yyyy-MM-dd'),
                until: formatDate(filters.endDate, 'yyyy-MM-dd'),
                page: page || 1,
                state: (orderState === 'none') ? undefined : orderState,
                otype: (fullFillment === 'none') ? undefined : fullFillment,
            }
        })
            .then((res) => {
                setReports(res.data?.data)
                setreportsPagination(res.data?.pagination)
            })
    }

    const fetchSetting = () => {
        axios.get('/venue/setting')
            .then((res) => {
                setSettings(res.data?.data)
            })
    }


    useEffect(() => {
        fetchSetting()
        fetchOrders(pagination?.current_page)
        fetchReports()
        clearInterval(ordersInterval.current)

        ordersInterval.current = setInterval(() => {
            axios.get(`/order/is-new-order`, {
                params: {
                    id: maxIdRef.current
                }
            })
                .then((res) => {
                    if (res?.data?.data?.orders) {
                        fetchOrders(pagination?.current_page, true)
                    }
                })
                .catch(() => {

                })
        }, 60000);

        return () => clearInterval(ordersInterval.current);
    }, [sortBy, sortByOrder, orderState, isOrderHistory, filters, fullFillment, isRevenueByDate])

    const handlePaginate = (e, val) => {
        fetchOrders(val)
        fetchReports(val)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = sortBy === property && sortByOrder === 'asc';
        setSortByOrder(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };

    const handleStatusChange = (data, isDoorDash) => {
        const id = data.Id
        const status = data.status

        setIsLoading(true)
        let nextStatus
        switch (status) {
            case 'o':
                nextStatus = 'a'
                break;
            case 'a':
                nextStatus = 'r'
                break;
            case 'r':
                nextStatus = 'clo'
                break;

            default:
                break;
        }

        nodeAxios.post('/orders/change-status', {
            orderId: id,
            nextStatus: nextStatus,
            actionTakenFrom: isMobileScreen ? 'App' : 'Web',
            isDoordash: isDoorDash
        })
            .then(() => {
                toast.success('Order status updated')
            })
            .catch((err) => {
                toast.error(err.response?.data?.message)
            })
            .finally(() => {
                fetchOrders(pagination?.current_page, true)
            })
    }

    useEffect(() => {
        if (printOrderData) {
            setTimeout(() => {
                generatePDF(targetRef2, {
                    filename: `Order_${settings?.venue?.Name}_${formatDate(new Date(), 'MMddyyy_hhmm')}`,
                    page: {
                        margin: 5,
                        orientation: 'portrait',
                        unit: 'mm',
                        format: [101.6, 152.4]
                    }
                }).then((val) => {
                    val.autoPrint()
                    val.output('dataurlnewwindow')

                    // Save the PDF - this could be to a file, a data URL, etc.
                    // val.save('filename.pdf');

                    // const pdfDataUrl = val.output('dataurlnewwindow');
                    // console.log('val', pdfDataUrl)
                    // // // Open the PDF in a new window
                    // // const pdfWindow = window.open();
                    // // pdfDataUrl.document.write('<iframe width="100%" height="100%" src="' + pdfDataUrl + '"></iframe>');

                    // // // You might need to delay the print command to ensure the PDF is fully loaded
                    // pdfDataUrl.onload = () => {
                    //     setTimeout(() => {
                    //         pdfDataUrl.focus(); // Required for IE
                    //         pdfDataUrl.print();
                    //     }, 1000);
                    // };
                    setPrintOrderData()
                })
            }, 1000);
        }
    }, [printOrderData])

    const handlePrintOrderData = (id) => {
        axios.get(`/driver/get-order-detail/${id}`)
            .then((res) => {
                setPrintOrderData({
                    ...res.data?.data?.results,
                    order_details: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))
                })
            })
    }
    useEffect(() => {
        axios.get(`/venue-printer-setting/my`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((response) => {
                //setBannersInGallery(response.data.data.result)
                if (response.data.data.result?.identifier != undefined && response.data.data.result?.identifier != '') {
                    setIsPrinterSaved(false);
                } else {
                    setIsPrinterSaved(true);
                }
                // console.log('printerSettinf', response.data.data.result.identifier)
            })
    }, [])

    useEffect(() => {

        window.addEventListener('message', (event) => {
            //  toast.success('app ver recived 02', event.data?.appVersion);
            if (event.data?.appVersion !== undefined) {
                //  toast.success('app ver recived', event.data?.appVersion);
                localStorage.setItem("app_ver_for_app 03", event.data?.appVersion);
            }

        });
        window?.ReactNativeWebView?.postMessage(JSON.stringify({ getDeviceType: 'getDeviceType' }));

    }, []);

    const handleItemClick = (event, row) => {
        event.stopPropagation()
        setRow(row)
        setShowDoorDashTracking(true)
        setTrackingUrl(row?.doordash_track_url)
    }

    return (
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className={styles.order_container} >
                    <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none' }}>
                        <EnhancedTableToolbar
                            orderState={orderState}
                            setOrderState={setOrderState}
                            fullFillment={fullFillment}
                            setFullFillment={setFullFillment}
                            filters={filters}
                            setFilters={setFilters}
                            setIsOrderHistory={setIsOrderHistory}
                            isOrderHistory={isOrderHistory}
                            isReports={isReports}
                            setIsReports={setIsReports}
                            setIsRevenueByDate={setIsRevenueByDate}
                            isRevenueByDate={isRevenueByDate}
                            setSelectedDate={setSelectedDate}
                            selectedDate={selectedDate}
                            user={user}
                            onPrint={() => {
                                generatePDF(targetRef, {
                                    filename: `OrderList_${settings?.venue?.Name}_${formatDate(new Date(), 'MMddyyy_hhmm')}`,
                                    page: {
                                        margin: 10
                                    }
                                })
                            }}
                        />
                        {isLoading && (
                            <PageLoader />
                        )}
                        {/* : ( */}
                        <Box sx={{ width: '100%' }}>
                            {!isFirstLoading && (
                                <div>
                                    {(Boolean(orders.length) && !(isReports && !isRevenueByDate)) || (Boolean(reports.length) && (isReports && !isRevenueByDate)) ? (
                                        <>
                                            {isMobileScreen ? (
                                                <div className={styles.mobile_order_container} ref={targetRef}>
                                                    {(isReports && !isRevenueByDate) ? (
                                                        reports.map((row, index) => (
                                                            <MobileReportItem {...{ report: row, setSelectedDate, setIsRevenueByDate }} key={index} />
                                                        ))
                                                    ) : orders.map((row, index) => (
                                                        <MobileOrderItem order={row} navigate={navigate} key={index} handleItemClick={handleItemClick} />
                                                    ))}
                                                </div>
                                            ) : (
                                                <TableContainer
                                                    ref={targetRef}
                                                >
                                                    <Table
                                                        sx={{ minWidth: 750 }}
                                                        aria-labelledby="tableTitle"
                                                        size={'medium'}
                                                    >
                                                        <EnhancedTableHead
                                                            isReports={isReports}
                                                            headCells={(isReports && !isRevenueByDate) ? headCells2 : headCells}
                                                            order={sortBy}
                                                            orderBy={sortByOrder}
                                                            onRequestSort={handleRequestSort}
                                                        />
                                                        {(isReports && !isRevenueByDate) ? (
                                                            <TableBody>
                                                                {reports.map((row, index) => {

                                                                    return (
                                                                        <TableRow
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            tabIndex={-1}
                                                                        >
                                                                            <TableCell
                                                                                component="th"
                                                                                scope="row"
                                                                                padding="normal"
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        color: '#0060EF',
                                                                                        cursor: 'pointer',
                                                                                        whiteSpace: 'nowrap'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setSelectedDate(row.orderdate)
                                                                                        setIsRevenueByDate(true)
                                                                                    }}
                                                                                >
                                                                                    {row.orderdate}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {row.total_order}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.order_total)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.product_revenue)}
                                                                            </TableCell>

                                                                            <TableCell
                                                                                style={{
                                                                                    whiteSpace: 'nowrap'
                                                                                }}
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.total_tax)}
                                                                            </TableCell>

                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.service_charge)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.delivery_fees)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.pickup_fee)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.shipping_fee)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                style={{
                                                                                    whiteSpace: 'nowrap'
                                                                                }}
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.tip)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.coupon_amount)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.refund_amount)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.gift_card_amount)}
                                                                            </TableCell>
                                                                            {/* <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.service_charge_to_venue, true)}
                                                                            </TableCell> */}
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                {getPrice(row.third_party_delivery_fees, true)}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        ) : (
                                                            <TableBody>
                                                                {orders.map((row, index) => {

                                                                    return (
                                                                        <TableRow
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            tabIndex={-1}
                                                                            // key={row.name}
                                                                            onClick={() => {
                                                                                navigate(`/order-details?id=${row.Id}`)
                                                                            }}
                                                                        >
                                                                            <TableCell
                                                                                component="th"
                                                                                scope="row"
                                                                                padding="normal"
                                                                            >
                                                                                <div
                                                                                    className={styles.order_data_conatiner}
                                                                                >
                                                                                    <div
                                                                                        className={styles.user_icon}
                                                                                    >
                                                                                        {row.name && row.name[0]}
                                                                                    </div>
                                                                                    <div>
                                                                                        <div
                                                                                            style={{
                                                                                                fontWeight: 600
                                                                                            }}
                                                                                        >
                                                                                            {row.name}
                                                                                        </div>
                                                                                        <div

                                                                                            style={{
                                                                                                whiteSpace: 'nowrap'
                                                                                            }}
                                                                                        >
                                                                                            Order #{row.Id} for USD {row.grand_total}
                                                                                        </div>

                                                                                        <div
                                                                                            className="tw-flex tw-gap-1"
                                                                                        >
                                                                                            <div>
                                                                                                {{
                                                                                                    'delivery': "Delivery",
                                                                                                    'shipping': "Shipping",
                                                                                                    'pickup': "Pickup"
                                                                                                }[row.otype]}
                                                                                            </div>

                                                                                            {row?.doordash_track_url && (
                                                                                                <div
                                                                                                    className="tw-text-[#0076E3] tw-underline tw-cursor-pointer"
                                                                                                    onClick={(event) => {
                                                                                                        handleItemClick(event, row)
                                                                                                    }}
                                                                                                >
                                                                                                    Check doordash status
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                <div
                                                                                    className={styles.order_status}
                                                                                    style={{
                                                                                        backgroundColor: statusColor[row.status] || '#00C437',
                                                                                        color: (row.status === 'o') ? 'black' : 'white'
                                                                                    }}
                                                                                >
                                                                                    {{
                                                                                        'o': 'Open',
                                                                                        'a': 'Accepted',
                                                                                        'r': 'Ready',
                                                                                        'can': 'Cancelled',
                                                                                        'clo': 'Closed'
                                                                                    }[row.status]}
                                                                                </div>
                                                                            </TableCell>

                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                <div>
                                                                                    <div>
                                                                                        {row.orderdate && formatDate(new Date(row.orderdate), 'MMM-dd-yyyy, hh:mm a')}
                                                                                    </div>
                                                                                    {(row.status === 'o') && (
                                                                                        <div>
                                                                                            <OrderTimer
                                                                                                user={user}
                                                                                                datetime={row.orderdate && new Date(row.orderdate)}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </TableCell>

                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                <div>
                                                                                    <div>
                                                                                        {row.order_tracking?.find((data) => data?.order_action == 'Accepted')?.updated_at ? formatDate(new Date(row.order_tracking?.find((data) => data?.order_action == 'Accepted')?.updated_at), 'MMM-dd-yyyy, hh:mm a') : <Remove />}
                                                                                    </div>

                                                                                    {(row.status === 'a') && row.order_tracking?.find((data) => data?.order_action == 'Accepted')?.updated_at && (
                                                                                        <div>
                                                                                            <OrderTimer
                                                                                                user={user}
                                                                                                datetime={new Date(row.order_tracking?.find((data) => data?.order_action == 'Accepted')?.updated_at)}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </TableCell>

                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                <div>
                                                                                    <div>
                                                                                        {row.order_tracking?.find((data) => data?.order_action == 'Ready')?.updated_at ? formatDate(new Date(row.order_tracking?.find((data) => data?.order_action == 'Ready')?.updated_at), 'MMM-dd-yyyy, hh:mm a') : <Remove />}
                                                                                    </div>

                                                                                    {(row.status === 'r') && row.order_tracking?.find((data) => data?.order_action == 'Ready')?.updated_at && (
                                                                                        <div>
                                                                                            <OrderTimer
                                                                                                user={user}
                                                                                                datetime={new Date(row.order_tracking?.find((data) => data?.order_action == 'Ready')?.updated_at)}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                    {row?.assign_to?.name && (
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                fontSize: 12,
                                                                                                gap: 5
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                Assigned to:
                                                                                            </div>
                                                                                            <div>
                                                                                                {row?.assign_to?.name}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >
                                                                                <div>
                                                                                    <Button
                                                                                        variant="outlined"
                                                                                        style={{
                                                                                            backgroundColor: 'white',
                                                                                            color: 'black',
                                                                                            borderColor: '#d3d3d3'
                                                                                        }}
                                                                                    >
                                                                                        Order Details
                                                                                    </Button>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                            >

                                                                                {/* <Button
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        backgroundColor: statusColor[row.status] || '#00C437',
                                                                                        color: (row.status === 'o') ? 'black' : 'white'
                                                                                    }}
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation()
                                                                                        setRow(row)
                                                                                        handleStatusChange(row)

                                                                                        if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                                                                            axios.get(`/driver/get-order-detail/${row.Id}`)
                                                                                                .then((res) => {
                                                                                                    const resultsData = res.data?.data?.results;
                                                                                                    resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                                                                    window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                                                                    //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                                                                                })

                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {{
                                                                                        'o': 'Accept Order',
                                                                                        'a': 'Set to Ready',
                                                                                        'r': 'Close Order',
                                                                                        'can': 'Cancelled',
                                                                                        'clo': 'Closed'
                                                                                    }[row.status]}
                                                                                </Button> */}

                                                                                {((row.otype == 'delivery') && (row.status == 'o')) ? (
                                                                                    <div
                                                                                        className="tw-cursor-pointer"
                                                                                        onClick={(event) => {
                                                                                            event?.stopPropagation()
                                                                                        }}
                                                                                    >
                                                                                        <DeliveryAcceptButton
                                                                                            isDoorDashEnabled={isDoorDashEnabled}
                                                                                            setRow={setRow}
                                                                                            row={row}
                                                                                            user={user}
                                                                                            isPrinterSaved={isPrinterSaved}
                                                                                            handleStatusChange={handleStatusChange}
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        style={{
                                                                                            backgroundColor: statusColor[row.status] || '#00C437',
                                                                                            color: (row.status === 'o') ? 'black' : 'white'
                                                                                        }}
                                                                                        disabled={(row.status == 'clo') || (row.status == 'can')}
                                                                                        onClick={(event) => {
                                                                                            event.stopPropagation()
                                                                                            setRow(row)
                                                                                            handleStatusChange(row)
                                                                                            sendToPrinter(row)
                                                                                            // if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                                                                            //     axios.get(`/driver/get-order-detail/${row.Id}`)
                                                                                            //         .then((res) => {
                                                                                            //             const resultsData = res.data?.data?.results;
                                                                                            //             resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                                                            //             window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                                                            //             //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                                                                            //         })

                                                                                            // }
                                                                                        }}
                                                                                    >
                                                                                        {{
                                                                                            'o': 'Accept Order',
                                                                                            'a': 'Set to Ready',
                                                                                            'r': 'Close Order',
                                                                                            'can': 'Cancelled',
                                                                                            'clo': 'Closed'
                                                                                        }[row.status]}
                                                                                    </Button>
                                                                                )}
                                                                                {/* {((isDeliveryLoading || isShippingLoading) && row.status == 'o') ? (
                                                                                    <div>
                                                                                        <CircularProgress />
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        {((isDeliveryLoading || isShippingLoading) && row.status == 'o') ? (
                                                                                            <CircularProgress />
                                                                                        ) : (
                                                                                            <>
                                                                                                {((deliveryPartners.length && row.otype == 'delivery' && row.status == 'o') || (shippingPartners.length && row.otype == 'shipping' && row.status == 'o')) ? (
                                                                                                    <GenericSelect
                                                                                                        value={select}
                                                                                                        onBlur={(event) => {
                                                                                                            event.stopPropagation()
                                                                                                        }}
                                                                                                        onClose={(event) => {
                                                                                                            event.stopPropagation()
                                                                                                        }}
                                                                                                        onChange={(event) => {
                                                                                                            event.stopPropagation()

                                                                                                            if (event.target.value == 'none') {
                                                                                                                setSelect(event.target.value)
                                                                                                            }

                                                                                                            if (event.target.value == 'yourself') {

                                                                                                                setRow(row)
                                                                                                                handleStatusChange(row)

                                                                                                                if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                                                                                                    axios.get(`/driver/get-order-detail/${row.Id}`)
                                                                                                                        .then((res) => {
                                                                                                                            const resultsData = res.data?.data?.results;
                                                                                                                            resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                                                                                            window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                                                                                            //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                                                                                                        })

                                                                                                                }

                                                                                                                setSelect(event.target.value)
                                                                                                            } else {
                                                                                                                let deliveryUrl;
                                                                                                                let shippingUrl;

                                                                                                                switch (event.target.value) {
                                                                                                                    case 0:
                                                                                                                        deliveryUrl = '/door-dash/create-delivery'
                                                                                                                        break;
                                                                                                                }

                                                                                                                switch (event.target.value) {
                                                                                                                    case 0:
                                                                                                                        shippingUrl = '/shipping/create'
                                                                                                                        break;
                                                                                                                }

                                                                                                                axios.post(row.otype == 'delivery' ? deliveryUrl : shippingUrl, {
                                                                                                                    order_id: row.Id
                                                                                                                })
                                                                                                                    .then((result) => {
                                                                                                                        toast.success(row.otype == 'delivery' ? 'Delivery Created' : 'Shipping Created')
                                                                                                                        setSelect(event.target.value)

                                                                                                                        event.stopPropagation()
                                                                                                                        setRow(row)
                                                                                                                        handleStatusChange(row)

                                                                                                                        if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                                                                                                            axios.get(`/driver/get-order-detail/${row.Id}`)
                                                                                                                                .then((res) => {
                                                                                                                                    const resultsData = res.data?.data?.results;
                                                                                                                                    resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                                                                                                    window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                                                                                                    //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                                                                                                                })

                                                                                                                        }
                                                                                                                    }).catch((err) => {
                                                                                                                        err.response?.data?.message?.field_errors?.map((data) => {
                                                                                                                            toast.error(data.error)
                                                                                                                        })
                                                                                                                        toast.error(err.response?.data?.message?.message)
                                                                                                                    });
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <MenuItem
                                                                                                            value='none'
                                                                                                            onClick={(event) => {
                                                                                                                event.stopPropagation()
                                                                                                            }}
                                                                                                        >
                                                                                                            Select
                                                                                                        </MenuItem>

                                                                                                        <MenuItem
                                                                                                            value='yourself'
                                                                                                            onClick={(event) => {
                                                                                                                event.stopPropagation()
                                                                                                            }}
                                                                                                        >
                                                                                                            Deliver Yourself
                                                                                                        </MenuItem>

                                                                                                        {deliveryPartners.map((data) => (
                                                                                                            <MenuItem
                                                                                                                value={data.provider_id}
                                                                                                                onClick={(event) => {
                                                                                                                    event.stopPropagation()
                                                                                                                }}
                                                                                                            >
                                                                                                                {data.provider_name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </GenericSelect>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {((row.status === 'o') || (row.status === 'a') || (row.status === 'r')) && (
                                                                                                            <div>
                                                                                                                <Button
                                                                                                                    variant="contained"
                                                                                                                    style={{
                                                                                                                        backgroundColor: statusColor[row.status] || '#00C437',
                                                                                                                        color: (row.status === 'o') ? 'black' : 'white'
                                                                                                                    }}
                                                                                                                    onClick={(event) => {
                                                                                                                        event.stopPropagation()
                                                                                                                        setRow(row)
                                                                                                                        handleStatusChange(row)

                                                                                                                        if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                                                                                                            axios.get(`/driver/get-order-detail/${row.Id}`)
                                                                                                                                .then((res) => {
                                                                                                                                    const resultsData = res.data?.data?.results;
                                                                                                                                    resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                                                                                                    window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                                                                                                    //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                                                                                                                })

                                                                                                                        }
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {{
                                                                                                                        'o': 'Accept Order',
                                                                                                                        'a': 'Set to Ready',
                                                                                                                        'r': 'Close Order',
                                                                                                                        'can': 'Cancelled',
                                                                                                                        'clo': 'Closed'
                                                                                                                    }[row.status]}
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}







                                                                                    </>
                                                                                )} */}
                                                                            </TableCell>
                                                                            {/* <TableCell
                                                                                align="center"
                                                                            >
                                                                                <div>
                                                                                    <Button
                                                                                        variant="outlined"
                                                                                        onClick={(event) => {
                                                                                            event.stopPropagation()
                                                                                            handlePrintOrderData(row.Id)
                                                                                        }}
                                                                                    >
                                                                                        Print
                                                                                    </Button>
                                                                                </div>
                                                                            </TableCell> */}
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        )}
                                                    </Table>
                                                </TableContainer>
                                            )}

                                            {(isReports && !isRevenueByDate) ? (reportsPagination?.last_page > 1) : (pagination?.last_page > 1) && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        marginTop: 20
                                                    }}
                                                >
                                                    <Pagination
                                                        count={(isReports && !isRevenueByDate) ? reportsPagination?.last_page : pagination?.last_page}
                                                        page={(isReports && !isRevenueByDate) ? reportsPagination?.current_page : pagination?.current_page}
                                                        onChange={handlePaginate}
                                                    />
                                                </div>
                                            )}
                                            {/* <TablePagination
                                                    rowsPerPageOptions={[15]}
                                                    component="div"
                                                    count={pagination?.last_page}
                                                    rowsPerPage={pagination?.per_page}
                                                    page={pagination?.current_page}
                                                    onPageChange={handlePaginate}
                                                // onRowsPerPageChange={handleChangeRowsPerPage}
                                                /> */}
                                        </>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                height: '100%',
                                                gap: 20,
                                                justifyContent: 'center',
                                                minHeight: '60vh',
                                                textAlign: 'center',
                                                marginTop: 20
                                            }}
                                        >
                                            {isReports ? (
                                                <AttachMoney
                                                    fontSize="large"
                                                />
                                            ) : (
                                                <img
                                                    src="https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/noorders.png-1695923111.png"
                                                    width={250}
                                                />
                                            )}
                                            <div
                                                style={{
                                                    fontWeight: 600
                                                }}
                                            >
                                                {(isReports && !isRevenueByDate) ? "No revenue data yet." : "No orders yet"}
                                            </div>
                                            {!(isReports && !isRevenueByDate) && (
                                                <div
                                                    style={{
                                                        width: 230,
                                                        color: '#7D7D7D',
                                                        fontSize: 14
                                                    }}
                                                >
                                                    All the orders made by your customers will appear here.
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Box>
                        {/* // )} */}
                    </Paper>
                </div>
            </div>

            {/* <Dialog
                open={showAssignDriver}
                onClose={() => {
                    setShowAssignDriver(false)
                }}
            >
                <AssignDriverDialog
                    handleStatusChange={handleStatusChange}
                    row={row}
                    deliveryPartners={deliveryPartners}
                    shippingPartners={shippingPartners}
                    onClose={() => {
                        setShowAssignDriver(false)
                    }}
                />
            </Dialog> */}

            <div
                style={{
                    display: 'none'
                }}
            >
                <div
                    ref={targetRef2}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 10,
                        width: 380
                    }}
                >
                    {printOrderData?.order_details?.map((data) => (
                        <div>
                            <div
                                style={{
                                    fontWeight: 600
                                }}
                            >
                                {data.name}
                            </div>
                            {data?.modifiers_list?.map((mdata) => {
                                console.log(mdata)
                                const opt = data.modifiers[mdata?.id]

                                return (
                                    <div
                                        style={{
                                            marginLeft: 8
                                        }}
                                    >
                                        {Boolean(opt) && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <div>
                                                    {mdata?.modifier_title}
                                                </div>

                                                {Boolean(parseFloat(mdata.modifier_price)) && (
                                                    <div>
                                                        ${parseFloat(mdata?.modifier_price)?.toFixed(2)}
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {mdata?.modifier_option?.map((mdata1) => {
                                            if (!opt?.includes(mdata1?.id)) {
                                                return
                                            }
                                            return (
                                                <div
                                                    style={{
                                                        marginLeft: 8,
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <div>
                                                        {mdata1.title}
                                                    </div>

                                                    {Boolean(parseFloat(mdata1.price)) && (
                                                        <div>
                                                            ${parseFloat(mdata1.price || 0).toFixed(2)}
                                                        </div>
                                                    )}


                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    ))}


                </div>
            </div>

            {isMobileScreen ? (
                <Drawer
                    PaperProps={{
                        style: {
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15
                        }
                    }}
                    anchor="bottom"
                    open={showDoorDashTracking}
                    onClose={() => {
                        setShowDoorDashTracking(false)
                        setTrackingUrl('')
                    }}
                >
                    <div
                        className="tw-pt-4 tw-pr-4 tw-pl-4"
                    >
                        <div
                            className="tw-flex tw-justify-between"
                        >
                            <img
                                className="tw-h-6"
                                src={getImage2('doordash_red.png-1709566784.png')}
                            />

                            <IconButton
                                onClick={() => {
                                    setShowDoorDashTracking(false)
                                    setTrackingUrl('')
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>

                        {((row?.status == 'o') || (row?.status == 'a')) && (
                            <div
                                className="tw-mt-2"
                            >
                                <Button
                                    style={{
                                        color: '#E40000'
                                    }}
                                    variant="text"
                                    onClick={() => {

                                        confirm({
                                            title: 'Are you sure you want to cancel DoorDash delivery?',
                                            description: 'Once cancelled you cannot call DoorDash for this order again.'
                                        })
                                            .then(() => {

                                                nodeAxios.post(`/doordash/cancel-delivery`, {
                                                    orderId: row.Id,
                                                    deliveryId: row.external_delivery_id
                                                })
                                                    .then((res) => {
                                                        toast.success('Doordash delivery cancelled')
                                                    })
                                                    .finally(() => {
                                                        fetchOrders()
                                                    })
                                            })
                                            .catch(() => {

                                            })
                                            .finally(() => {
                                            })
                                    }}
                                >
                                    Cancel DoorDash Delivery
                                </Button>
                            </div>
                        )}
                    </div>

                    <iframe
                        className="tw-h-[75vh]"
                        src={trackingUrl}
                    />
                </Drawer>
            ) : (
                <Dialog
                    open={showDoorDashTracking}
                    onClose={() => {
                        setShowDoorDashTracking(false)
                        setTrackingUrl('')
                    }}
                    maxWidth="xl"
                    PaperProps={{
                        style: {
                            width: '100%'
                        }
                    }}
                >
                    <div
                        className="tw-pt-4 tw-pr-4 tw-pl-4"
                    >
                        <div
                            className="tw-flex tw-justify-between"
                        >
                            <img
                                className="tw-h-6"
                                src={getImage2('doordash_red.png-1709566784.png')}
                            />

                            <IconButton
                                onClick={() => {
                                    setShowDoorDashTracking(false)
                                    setTrackingUrl('')
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>

                        {((row?.status == 'o') || (row?.status == 'a')) && (
                            <div
                                className="tw-mt-2"
                            >
                                <Button
                                    style={{
                                        color: '#E40000'
                                    }}
                                    variant="text"
                                    onClick={() => {

                                        confirm({
                                            title: 'Are you sure you want to cancel DoorDash delivery?',
                                            description: 'Once cancelled you cannot call DoorDash for this order again.'
                                        })
                                            .then(() => {

                                                nodeAxios.post(`/doordash/cancel-delivery`, {
                                                    orderId: row.Id,
                                                    deliveryId: row.external_delivery_id
                                                })
                                                    .then((res) => {
                                                        toast.success('Doordash delivery cancelled')
                                                    })
                                                    .finally(() => {
                                                        fetchOrders()
                                                    })
                                            })
                                            .catch(() => {

                                            })
                                            .finally(() => {

                                            })
                                    }}
                                >
                                    Cancel DoorDash Delivery
                                </Button>
                            </div>
                        )}
                    </div>

                    <iframe
                        className="tw-h-screen"
                        src={trackingUrl}
                    />
                </Dialog>

            )}
        </div>
    );
};

export default VenueOrderListing;


const MobileOrderItem = ({ order, navigate, handleItemClick }) => {

    return (
        <div className={styles.mobile_order_item} onClick={() => navigate(`/order-details?id=${order.Id}`)}>
            <div className={styles.mobile_order_item_container}>
                <div className={styles.mobile_order_item_header}>
                    {order.name}
                    <div
                        className={styles.order_status}
                        style={{
                            backgroundColor: statusColor[order.status] || '#00C437',
                            color: (order.status === 'o') ? 'black' : 'white'
                        }}
                    >
                        {{
                            'o': 'Open',
                            'a': 'Accepted',
                            'r': 'Ready',
                            'can': 'Cancelled',
                            'clo': 'Closed'
                        }[order.status]}
                    </div>
                </div>
                <div style={{ whiteSpace: 'nowrap' }}>
                    Order #{order.Id} for USD {order.grand_total}
                </div>
                <div>
                    <b>Order Date:</b> {order.orderdate}
                </div>
                <div>
                    {{
                        'delivery': "Delivery",
                        'shipping': "Shipping",
                        'pickup': "Pickup"
                    }[order.otype]}
                </div>
            </div>
            {order?.doordash_track_url && (
                <div
                    className="tw-text-[#0076E3] tw-underline tw-cursor-pointer"
                    onClick={(event) => {
                        handleItemClick(event, order)
                    }}
                >
                    Check doordash status
                </div>
            )}
            {/* <Button variant='outlined' fullWidth sx={{ color: 'black', borderColor: '#d3d3d3' }}
                onClick={() => navigate(`/order-details?id=${order.Id}`)}
            >
                Order Detail
            </Button> */}
        </div>

    )
}
const MobileReportItem = ({ report, setSelectedDate, setIsRevenueByDate }) => {

    return (
        <div className={styles.mobile_order_item}>
            <div className={styles.mobile_order_item_container}>
                <div
                    className={styles.mobile_order_item_header}
                    style={{
                        color: '#0060EF',
                        cursor: 'pointer',
                        whiteSpace: 'nowrap'
                    }}
                    onClick={() => {
                        setSelectedDate(report.orderdate)
                        setIsRevenueByDate(true)
                    }}
                >
                    {report.orderdate}
                </div>
                <div className={styles.mobile_report_item}>
                    <div>Orders : <span>{report.total_order}</span></div>
                    {Boolean(report.order_total) &&
                        <div>Orders Total : <span>{getPrice(report.order_total)}</span></div>}
                    {Boolean(report.product_revenue) &&
                        <div>Product Revenue : <span>{getPrice(report.product_revenue)}</span></div>}
                    {Boolean(report.total_tax) &&
                        <div>Tax : <span>{getPrice(report.total_tax)}</span></div>}
                    {Boolean(report.service_charge) &&
                        <div>Service charge : <span>{getPrice(report.service_charge)}</span></div>}
                    {Boolean(report.delivery_fees) &&
                        <div>Delivery fees : <span>{getPrice(report.delivery_fees)}</span></div>}
                    {Boolean(report.pickup_fee) &&
                        <div>Pickup fees : <span>{getPrice(report.pickup_fee)}</span></div>}
                    {Boolean(report.shipping_fee) &&
                        <div>Shipping fees : <span>{getPrice(report.shipping_fee)}</span></div>}
                    {Boolean(report.tip) &&
                        <div>Tip : <span>{getPrice(report.tip)}</span></div>}
                    {Boolean(report.coupon_amount) &&
                        <div>Coupon amount : <span>{getPrice(report.coupon_amount)}</span></div>}
                    {Boolean(report.refund_amount) &&
                        <div>Refund amount : <span>{getPrice(report.refund_amount)}</span></div>}
                    {Boolean(report.gift_card_amount) &&
                        <div>Giftcard amount : <span>{getPrice(report.gift_card_amount)}</span></div>}
                    {/* {Boolean(report.service_charge_to_venue) &&
                        <div>Esplanda Fees : <span>{getPrice(report.service_charge_to_venue, true)}</span></div>} */}
                    {Boolean(report.third_party_delivery_fees) &&
                        <div>3rd party delivery fees : <span>{getPrice(report.third_party_delivery_fees, true)}</span></div>}
                </div>
            </div>
        </div>

    )
}