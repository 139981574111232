import React, { useEffect, useState } from 'react'
import GenericTextFieldWithLabel from '../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import { Button, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { nodeAxios } from '../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { Inbox } from '@mui/icons-material'

const AdminTemplate = () => {

    const [subdomain, setSubdomain] = useState('')
    const [templateName, setTemplateName] = useState('')
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [selectedPage, setSelectedPage] = useState()
    const [templatePages, setTemplatePages] = useState([])
    const [pageComponents, setPageComponents] = useState([])
    const [selectedAttribute, setSelectedAttribute] = useState('')
    const [selectedAttributePompt, setSelectedAttributePrompt] = useState('')
    const [selectedAttributePomptID, setSelectedAttributePromptID] = useState('')
    const [selectedComponentId, setSelectedComponentId] = useState('')
    const [parsedItems, setParsedItems] = useState([])

    const handleTemplateCreation = () => {
        nodeAxios.post('/web-builder/web-templates/make-as-template', {
            subdomain: subdomain,
            title: templateName
        })
            .then(() => {
                toast.success('Template created')
            })
            .catch((err) => {
                toast.error(err?.response?.message)
            })
    }

    const fetchTemplates = () => {
        nodeAxios.get('/web-builder/web-templates/list')
            .then((res) => {
                setTemplates(res.data.data)
            })
    }

    const fetchTemplatesDetails = () => {
        nodeAxios.get(`/web-builder/web-templates/details/${selectedTemplate}`)
            .then((res) => {
                console.log(res.data.data)
                setTemplatePages(res.data.data.web_template_pages)
            })
    }

    const fetchTemplatesPageDetails = () => {
        nodeAxios.get(`/web-builder/web-templates/page-details/${selectedTemplate}/${selectedPage}`)
            .then((res) => {
                console.log("!!!!!!")
                console.log(res.data.data?.payload['Home-pages'][0]?.frames[0])
                console.log("!!!!!!")
                setPageComponents(res.data.data?.payload['Home-pages'][0]?.frames[0]?.component?.components?.map((data) => {
                    console.log("🚀 ~ setPageComponents ~ data:", data)
                    if(data?.components[0]?.attributes){
                    return {
                        id: data?.attributes?.id,
                        type: data?.type,
                        attributes: Object.entries(data.components[0]?.attributes)?.map(([key, value]) => {
                            if (key == "items") {
                                const prompt = res.data.data?.prompts?.filter((fdata) => (fdata.component_id == data?.attributes?.id) && (fdata.attribute_key.startsWith("items.")))
                                return {
                                    name: key,
                                    value: value,
                                    promptArray: prompt,
                                }
                            } else {
                                const prompt = res.data.data?.prompts?.find((fdata) => (fdata.component_id == data?.attributes?.id) && (fdata.attribute_key == key))
                                return {
                                    name: key,
                                    value: value,
                                    prompt: prompt?.prompt || '',
                                    promptID: prompt?.id || null,
                                }
                            }

                        }),
                    }
                }else {
                    return {
                        
                    }
                }
                }))
            })
    }

    useEffect(() => {
        fetchTemplates()
    }, [])

    useEffect(() => {
        if (selectedTemplate) {
            fetchTemplatesDetails()
        } else {
            setTemplatePages([])
        }
    }, [selectedTemplate])

    useEffect(() => {
        if (selectedPage) {
            fetchTemplatesPageDetails()
        }
    }, [selectedPage])

    const updatePrompt = () => {
        if (!selectedAttributePompt) return
        const params = {
            "id": selectedAttributePomptID,
            "template_id": selectedTemplate,
            "template_page_id": selectedPage,
            "component_id": selectedComponentId,
            "attribute_key": selectedAttribute,
            "prompt": selectedAttributePompt
        }

        nodeAxios.post('/web-builder/web-templates/update-prompt/',
            params
        ).then((res) => {
            console.log(res.data.data)
            toast.success('Prompt saved')
        })
    }

    const updatePageType = (pageId, isSystem) => {
        nodeAxios.post('/web-builder/web-page/update-page-status/' + pageId,
            {
                is_system: isSystem
            }
        ).then((res) => {
            console.log(res.data.data)
            toast.success('Page status updated')
        })
    }


    return (
        <div
            className="tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-2"
        >
            <div
                className="tw-flex tw-flex-col tw-gap-2"
            >
                <div
                    className="tw-flex tw-gap-2"
                >
                    <GenericTextFieldWithLabel
                        label="Name for template"
                        value={templateName}
                        onChange={(event) => {
                            setTemplateName(event.target.value)
                        }}
                    />
                    <GenericTextFieldWithLabel
                        label="Subdomain to make as template"
                        value={subdomain}
                        onChange={(event) => {
                            setSubdomain(event.target.value)
                        }}
                    />
                </div>
                <Button
                    variant="contained"
                    onClick={handleTemplateCreation}
                >
                    Create
                </Button>
            </div>

            <div
                className="tw-flex tw-w-full"
            >
                <List>
                    {templates.map((data) => (
                        <ListItem
                            style={{
                                flexDirection: 'column'
                            }}
                            onClick={() => {
                                setSelectedTemplate(data.id)
                            }} disablePadding
                        >
                            <ListItemButton>
                                <ListItemText primary={(
                                    <div>
                                        <div>
                                            {data.title}
                                        </div>
                                    </div>
                                )} />
                                {data.id == selectedTemplate && (
                                    <ListItemIcon
                                        onClick={() => {
                                            setSelectedTemplate()
                                        }}
                                    >
                                        <Inbox />
                                    </ListItemIcon>
                                )}
                            </ListItemButton>
                            <a
                                style={{
                                    width: 100,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    fontSize: 13
                                }}
                                href={data.link}
                                target="_blank"
                            >
                                {data.link}
                            </a>
                        </ListItem>
                    ))}
                </List>

                <List>
                    {templatePages.map((data) => (
                        <ListItem

                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemText primary={data.title}
                                    onClick={() => {
                                        setSelectedPage(data.id)
                                    }}
                                />
                                {
                                    data.isSystem ?
                                        <Chip label="System Page" color="success" size="small" onClick={() => updatePageType(data.id, 0)} />
                                        :
                                        <Chip label="Make System Page" color="primary" variant="outlined" size="small" onClick={() => updatePageType(data.id, 1)} />
                                }

                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                <List>
                    {pageComponents.map((data) => {
                        return (<div>
                            <div
                                className="tw-p-2 tw-my-2 tw-cursor-pointer"
                                onClick={() => {
                                    setSelectedComponentId(data.id)
                                }}>
                                {data.type} - {data.id}
                            </div>
                            {data.id == selectedComponentId && (
                                <div className="tw-pl-2" >
                                    {data.attributes?.map((data1) => (
                                        <ListItem
                                            onClick={() => {
                                                console.log(data1)
                                                if (data1.name == 'items') {
                                                    setParsedItems(JSON.parse(data1.value))
                                                } else {
                                                    setParsedItems([])
                                                    setSelectedAttribute(data1.name)
                                                    setSelectedAttributePrompt(data1.prompt)
                                                    setSelectedAttributePromptID(data1.promptID)
                                                }

                                            }}
                                            disablePadding
                                        >
                                            <ListItemButton>
                                                <ListItemText primary={"• " + data1.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                    }
                                </div>
                            )}
                        </div>)
                    })}
                </List>
                {console.log({ parsedItems })}
                <List>
                    {parsedItems.map((data, index) => {
                        const ObjMap = Object.entries(data)

                        return (
                            <div>
                                <div>{index}</div>
                                {ObjMap.map(([key, value]) => (
                                    <ListItem
                                        onClick={() => {
                                            setSelectedAttribute(`items.${index}.${key}`)
                                            const curr = pageComponents.find((pc) => pc.id == selectedComponentId).attributes.find((a) => a.name == "items")

                                            console.log({ curr })
                                            if (curr) {
                                                const ele = curr.value
                                                if (ele) {
                                                    const decoded = JSON.parse(ele)
                                                    if (decoded) {
                                                        const itemAtIndex = decoded[index]
                                                        if (itemAtIndex) {
                                                            const prompt = curr.promptArray.find((p) => p.attribute_key == `items.${index}.${key}`)
                                                            if (prompt) {
                                                                setSelectedAttributePrompt(prompt.prompt)
                                                                setSelectedAttributePromptID(prompt.id)
                                                            } else {
                                                                setSelectedAttributePrompt()
                                                                setSelectedAttributePromptID()
                                                            }

                                                        }
                                                    }

                                                }
                                            }


                                        }}
                                        disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemText primary={key} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </div>
                        )
                    })}
                </List>

                {selectedAttribute && (
                    <div className='tw-flex-grow'>
                        <GenericTextFieldWithLabel
                            label={`Prompt for ${selectedAttribute}`}
                            value={selectedAttributePompt}
                            fullWidth={true}
                            onChange={(event) => {
                                setSelectedAttributePrompt(event.target.value)
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={updatePrompt}
                        >
                            Save prompt
                        </Button>
                        <p>
                            Hint:<br />
                            Values for {'{{sub_type}} {{venue_type}}'} will be sent to GPT.<br />
                            eg: Generate an about us for a {'{{sub_type}} {{venue_type}}'} under 100 words.<br />
                        </p>
                    </div>
                )}
            </div>

        </div >
    )
}

export default AdminTemplate