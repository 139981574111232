import { Close, HelpOutlineOutlined } from '@mui/icons-material'
import { Button, Collapse, DialogContent, Divider, IconButton, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import styles from './DeliveryOption.module.scss'
import { convertTo24HourFormatWithoutSpace, getImage2 } from '../../../utils/helpers'
import GenericSwitch from '../../Library/V2/GenericSwitch/GenericSwitch'
import LightTooltip from '../../../util_comps/LightTooltip'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import useSendToPrinter from '../../../hooks/useSendToPrinter'
import moment from 'moment-timezone';
import { format } from 'date-fns'

const DeliveryOption = ({ onClose, pickupTimeEstimated, orderDetails, row, handleStatusChange, isDoorDashEnabled, user, setRow, isPrinterSaved, drivers, isDoordashDisabled, estimate }) => {

    const mobile = useMediaQuery('(max-width:1000px)');
    const { sendToPrinter } = useSendToPrinter({ user, isPrinterSaved })

    const [selectedDriver, setSelectedDriver] = useState('')
    const [showDrivers, setShowDrivers] = useState(false)
    const [showDoorDash, setShowDoorDash] = useState(false)
    const [isAlcohol, setIsAlcohol] = useState(user?.data?.venue_type == 'Package Store')
    const [pickupInstruction, setPickupInstruction] = useState('')
    const [isCallDoorDashLoading, setIsCallDoorDashLoading] = useState(false)
    const [isMoreThanHalfHour, setIsMoreThanHalfHour] = useState(false)

    useEffect(() => {
        const pickupTime = orderDetails?.order?.delivery_pickup_slot?.split(' ');

        if (pickupTimeEstimated && pickupTime?.length) {
            console.log({ pickupTime })
            const date = pickupTime[0];

            let startTime = convertTo24HourFormatWithoutSpace(pickupTime[1].split("-")[0]);
            const timezone = user?.data?.venue_timezone
            console.log('timezone', timezone, startTime)
            const dateTime = moment.tz(`${date} ${startTime}`, timezone).utc().toISOString();
            console.log('timezone', dateTime, pickupTimeEstimated)

            // Convert strings to Date objects
            const startDate = new Date(dateTime);
            const endDate = new Date(pickupTimeEstimated);

            // Calculate the difference in minutes
            const diffMinutes = (endDate - startDate) / (1000 * 60);

            // Check if the difference is more than 30 minutes
            const isMoreThan30Mins = diffMinutes > 30;

            setIsMoreThanHalfHour(isMoreThan30Mins)

        }
    }, [pickupTimeEstimated, orderDetails])


    const handleAssignDriver = (id) => {
        axios.post(`/order/assign/${row?.Id}`, {
            biz_user_id: id,
            action_taken_from: mobile ? 'App' : 'Web'
        })
            .then(() => {
                setRow(row)
                handleStatusChange(row)
                sendToPrinter(row)
                // if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                //     axios.get(`/driver/get-order-detail/${row.Id}`)
                //         .then((res) => {
                //             const resultsData = res.data?.data?.results;
                //             resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                //             window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                //             //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                //         })

                // }
                toast.success('Driver assigned')
                onClose()
            })
    }

    const handleCallDoordash = () => {
        setIsCallDoorDashLoading(true)
        nodeAxios.post(`/doordash/create-delivery`, {
            orderId: row.Id,
            pickupInstructions: pickupInstruction,
            isAlcohol: isAlcohol
        })
            .then(() => {
                toast.success('Delivery Created')
                setRow(row)
                handleStatusChange(row, true)
                sendToPrinter(row)
                // if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                //     axios.get(`/driver/get-order-detail/${row.Id}`)
                //         .then((res) => {
                //             const resultsData = res.data?.data?.results;
                //             resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                //             window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                //             //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                //         })

                // }
                toast.success('Driver assigned')
                onClose()
            })
            .catch((err) => {
                err.response?.data?.message?.field_errors?.map((data) => {
                    toast.error(data.error)
                })
                toast.error(err.response?.data?.message)
            })
            .finally(() => {
                setIsCallDoorDashLoading(false)
            })
    }

    return (
        <DialogContent
            style={{
                width: mobile ? '100%' : 460
            }}
        >
            <div
                className="tw-flex tw-justify-between"
            >
                <div
                    className="tw-font-semibold"
                >
                    Choose delivery option
                </div>

                <IconButton
                    onClick={onClose}
                >
                    <Close fontSize="small" />
                </IconButton>
            </div>

            <div
                style={{
                    border: '1px solid #d3d3d3',
                    borderRadius: 4
                }}
                className="tw-p-3 tw-my-2 tw-mx-0"
            >
                <div
                    className="tw-flex tw-justify-between tw-cursor-pointer"
                    onClick={() => {
                        setShowDrivers((prevState) => !prevState)
                    }}
                >
                    <div>
                        Choose your own delivery driver
                    </div>

                    <div>
                        <svg className="tw-mr-1 tw-h-5 tw-w-5 tw-text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>

                <Collapse
                    in={showDrivers}
                >
                    <div
                        className="tw-flex tw-flex-col tw-gap-2 tw-mt-2"
                    >
                        {drivers?.map((data) => (
                            <Button
                                style={{
                                    border: (selectedDriver == data.id) ? '1px solid black' : '1px solid #D3D3D3',
                                    justifyContent: 'flex-start',
                                    color: 'black'
                                }}
                                variant='outlined'
                                size="small"
                                onClick={() => {
                                    setSelectedDriver(data.id)
                                    handleAssignDriver(data.id)
                                }}
                                fullWidth={true}
                            >
                                {data.name}
                            </Button>
                        ))}
                    </div>
                </Collapse>
            </div>

            {!isDoordashDisabled && (
                <>
                    <div className={styles.or_divider_container}><span className={styles.or_text}>or</span></div>

                    <div
                        style={{
                            border: '1px solid #d3d3d3',
                            borderRadius: 4
                        }}
                        className="tw-p-3 tw-my-2 tw-mx-0"
                    >
                        <div
                            className="tw-flex tw-justify-between tw-cursor-pointer"
                            onClick={() => {
                                setShowDoorDash((prevState) => !prevState)
                            }}
                        >
                            <div
                                className="tw-flex tw-gap-1 tw-items-center tw-flex-wrap"
                            >
                                Request
                                <img
                                    className="tw-h-3 tw-mr-1"
                                    src={getImage2('doordash_red.png-1709566784.png')}
                                />
                                for ${estimate} + tips

                                {isMoreThanHalfHour && " (Late Delivery)"}
                            </div>

                            <div>
                                <svg className="tw-mr-1 tw-h-5 tw-w-5 tw-text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>

                        <Collapse
                            in={showDoorDash}
                        >
                            <div
                                className="tw-flex tw-flex-col tw-gap-3 tw-mt-4"
                            >
                                {isMoreThanHalfHour ? (
                                    <div
                                        className="tw-text-red-500"
                                    >
                                        Doordash is unable to pickup within your delivery window, Estimated pickup time is: {format(new Date(pickupTimeEstimated), "MMM do yyyy h:mma")}
                                        {' '}Would you still like to call Doordash?
                                    </div>
                                ) : (
                                    <div
                                        className="tw-text-green-500"
                                    >
                                        Doordash Estimated Pickup Time: {format(new Date(pickupTimeEstimated), "MMM do yyyy h:mma")}
                                    </div>
                                )}

                                <div
                                    className="tw-flex tw-items-start tw-flex-col lg:tw-flex-row lg:tw-items-center"
                                >
                                    <div
                                        className="tw-mr-2"
                                    >
                                        Does this order contain alcohol?

                                        <span
                                            className="tw-mx-[2px]"
                                        >
                                            <LightTooltip
                                                title={(
                                                    <span
                                                        style={{ display: 'inline-block', padding: '0 15px' }}
                                                    >
                                                        Before you can have Doordash delivery Alcohol, Your store will need to
                                                        <a
                                                            className="tw-underline tw-mx-1"
                                                            href="https://doordash.workflowcloud.com/forms/c10f6093-0761-4ba5-be63-22eea52f6e42"
                                                            target="_blank"
                                                        >
                                                            sign this alcohol addendum
                                                        </a>.
                                                        Each merchant delivering alcohol needs to do this.
                                                    </span>
                                                )}
                                                arrow
                                                placement="top"
                                            >
                                                <HelpOutlineOutlined
                                                    style={{
                                                        fontSize: 16
                                                    }}
                                                />
                                            </LightTooltip>
                                        </span>
                                    </div>
                                    <GenericSwitch
                                        background="#0AB221"
                                        width={35}
                                        height={18}
                                        translateX={16}
                                        thumb={13}
                                        isStatus={true}
                                        checked={isAlcohol}
                                        onText="'Yes'"
                                        offText="'No'"
                                        onChange={(event) => {
                                            setIsAlcohol(event.target.checked)
                                        }}
                                    />
                                </div>

                                <Divider />

                                <div>
                                    <div>
                                        Pick up instructions for driver
                                    </div>

                                    <textarea
                                        className="tw-bg-[#F1F1F1] tw-w-full tw-p-3 tw-rounded"
                                        placeholder="Write any special instructions here"
                                        value={pickupInstruction}
                                        onChange={(event) => {
                                            setPickupInstruction(event.target.value)
                                        }}
                                    />
                                </div>

                                <GenericLoadingButton
                                    loading={isCallDoorDashLoading}
                                    loadingPosition="end"
                                    style={{
                                        backgroundColor: '#D83521',
                                        color: 'white',
                                        flexWrap: 'wrap'
                                    }}
                                    variant="contained"
                                    onClick={handleCallDoordash}
                                    fullWidth={true}
                                >
                                    Request
                                    <img
                                        className="tw-h-5"
                                        src={getImage2('doordash_white.png-1709567709.png')}
                                    />
                                    for ${estimate} + tips

                                    {isMoreThanHalfHour && " (Late Delivery)"}
                                </GenericLoadingButton>
                            </div>
                        </Collapse>
                    </div>
                </>
            )}

        </DialogContent>
    )
}

export default DeliveryOption