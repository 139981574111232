import React from 'react'
import { styled } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Button, Tooltip, CircularProgress, Dialog, ListItemIcon, Menu, MenuItem, Pagination, Radio, Select, Typography, tooltipClasses, useMediaQuery } from '@mui/material';
import { Add, ChevronRight, Description, DragIndicator, ExpandLess, ExpandMore, HelpOutline, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, Link, LocalOfferOutlined, Search, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from '../../../../CustomPageBuilder/EditSection/Util/helpers';
import { ReactComponent as EmptyPlates } from '../../../../../assets/svgs/empty_plates.svg';
import GenericSwitch from '../../../../Library/V2/GenericSwitch/GenericSwitch';
import { TreeItem, TreeView } from '@mui/x-tree-view';




const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        //   disableFocusListener
        //   disableHoverListener
        //   disableTouchListener
        // placement="left-end"
        PopperProps={{
            disablePortal: true,
        }}
        arrow
    //   leaveDelay={5000}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 260,
        fontSize: 14,
        border: 'none',
        borderRadius: 10,
        padding: '10px 15px',
        //   textAlign: 'center',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000  ',
    },
}));



const columns = [
    {
        id: 'code',
        label: 'Coupon Code',
        width: 300,
        styles: {
            maxWidth: 300,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    {
        id: 'valid_from',
        sortid: 'product_name',
        label: 'Valid from',
        width: 250,
        styles: {
            maxWidth: 250,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    {
        id: 'valid_to',
        sortid: 'product_name',
        label: 'Valid to',
        width: 250,
        styles: {
            maxWidth: 250,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    {
        id: 'value',
        sortid: 'product_name',
        label: 'Discount',
        width: 250,
        styles: {
            maxWidth: 250,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    {
        id: 'min_purchase',
        sortid: 'product_name',
        label: 'Valid above',
        width: 250,
        styles: {
            maxWidth: 250,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    {
        id: 'valid_for',
        sortid: 'product_name',
        label: 'Valid for',
        width: 250,
        styles: {
            maxWidth: 250,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    }

];



const ItemsTable = ({
    products,
    options,
    sort,
    setSort,
    handleEdit2,
    handleDelete,
    handleChangeVisibility,
    getTreeItem,
    venueCategoryMenu,
    catId,
    setCatId,
    selectedCatName,
    setSelectedCatName,
    handleClick2,
    handleClose2,
    anchorEl2,
    open2,
    onDragEnd,
    getItemStyle
}) => {
    return (
        <TableContainer component={Paper} sx={{ boxShadow: 'none', borderBottom: '1px solid #d3d3d3', borderRadius: '0' }}>

            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, cursor: 'pointer', width: column.width }}
                                onClick={() => {
                                    if (column.sortid) {
                                        if (sort.sort_by === column.sortid) {
                                            if (sort.sort_by_order === 'ASC') {
                                                setSort({ ...sort, sort_by_order: 'DESC' })
                                            } else {
                                                setSort({ ...sort, sort_by_order: 'ASC' })
                                            }
                                        } else {
                                            setSort({ sort_by_order: 'DESC', sort_by: column.sortid })
                                        }
                                    }
                                }}
                                className='!tw-p-1'
                            >
                                {(column.id == 'category_name') ? (
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <div className='tw-w-4/5' >
                                            {/* className="tw-w-min tw-min-w-[150px] tw-overflow-auto tw-outline tw-outline-1 tw-outline-gray tw-rounded-lg tw-bg-[#f2f2f2]"> */}

                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl2}
                                                open={open2}
                                                onClose={handleClose2}
                                                slotProps={{
                                                    paper: {
                                                        elevation: 0,
                                                        sx: {
                                                            filter: 'none',
                                                            border: '1px solid #D3D3D3',
                                                            height: 250
                                                        },
                                                    }
                                                }}
                                                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                            >
                                                <TreeView
                                                    defaultCollapseIcon={<ExpandMore />}
                                                    defaultExpandIcon={<ChevronRight />}
                                                // expanded={props.isEdit}
                                                >
                                                    <TreeItem
                                                        icon={(
                                                            <Radio
                                                                checked={0 == catId}
                                                                onChange={(event) => {
                                                                    setSelectedCatName('')
                                                                    setCatId(0)
                                                                    handleClose2()
                                                                }}
                                                                value={0}
                                                            />
                                                        )}
                                                        nodeId={0}
                                                        label={(
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 8
                                                                }}
                                                            >
                                                                <div>
                                                                    All
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                    {venueCategoryMenu?.map((data) => (
                                                        <TreeItem
                                                            icon={(
                                                                <Radio
                                                                    checked={data.category_id == catId}
                                                                    onChange={(event) => {
                                                                        setSelectedCatName(data.category_name)
                                                                        setCatId(event.target.value)
                                                                        handleClose2()
                                                                    }}
                                                                    value={data.category_id}
                                                                />
                                                            )}
                                                            nodeId={data.category_id}
                                                            label={(
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: 8
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {data.category_name}
                                                                    </div>

                                                                    {Boolean(data?.child?.length) && (
                                                                        <KeyboardArrowRight />
                                                                    )}
                                                                </div>
                                                            )}
                                                        >
                                                            {getTreeItem(data?.child)}
                                                        </TreeItem>
                                                    ))}
                                                </TreeView>
                                            </Menu>
                                        </div>
                                        <HtmlTooltip title={'Easily reorder menu items within category after selecting category.'} >
                                            <HelpOutline />
                                        </HtmlTooltip>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 5,
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div>
                                            {column.label}
                                        </div>
                                    </div>
                                )}
                            </TableCell>
                        ))}
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold' >
                            <div>
                                Offer Status
                            </div>
                        </TableCell>
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold !tw-w-20' />
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold !tw-w-20' />
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold' />
                    </TableRow>
                </TableHead>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <TableBody
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            // style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {products.map((row, index) => (
                                    <Draggable
                                        key={row.id}
                                        draggableId={"q-" + row.id}
                                        index={index}
                                        isDragDisabled={!Boolean(catId)}
                                    >
                                        {(provided, snapshot) => (
                                            <TableRow
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                sx={{
                                                    '&:nth-of-type(even)': {
                                                        backgroundColor: '#F7F5F7',
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                {columns.map((column) => {
                                                    let value = row[column.id];

                                                    if (column.id === 'value' && value != null) {

                                                        value = `$ ${parseFloat(value)?.toFixed(2)}`;

                                                    }
                                                    if (column.id === 'min_purchase' && value != null) {

                                                        value = `$ ${parseFloat(value)?.toFixed(2)}`;

                                                    }
                                                    if (column.id === 'valid_from' && value != null) {
                                                        value = value?.split(" ")[0];
                                                    }
                                                    if (column.id === 'valid_to' && value != null) {
                                                        value = value?.split(" ")[0];
                                                    }

                                                    return (
                                                        <TableCell key={column.id} align={column.align} style={column.styles && { ...column.styles }} className='!tw-p-1.5 !tw-border-b-0'>
                                                            <div
                                                                className={(column?.id == 'ProductName') && ' tw-cursor-pointer tw-text-[#0071C3] hover:tw-underline '}
                                                                onClick={() => {
                                                                    if ((column?.id == 'ProductName')) {
                                                                        handleEdit2(row.id)
                                                                    }
                                                                }}
                                                                title={column.format ? column.format(value, row.track_quantity) : value}
                                                            >
                                                                {column.format ? column.format(value, row.track_quantity) : value}
                                                            </div>
                                                        </TableCell>
                                                    );
                                                })}

                                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                                    <div className='tw-w-28'>
                                                        <GenericSwitch
                                                            background="#0AB221"
                                                            width={35}
                                                            height={18}
                                                            translateX={16}
                                                            thumb={13}
                                                            isStatus={true}
                                                            checked={(row.state_id != undefined ? row.state_id : (row.state_id === 0))}
                                                            // onText="'On'"
                                                            // offText="'Off'"
                                                            onChange={(event) => {
                                                                handleChangeVisibility(index)
                                                            }}
                                                        />
                                                    </div>
                                                </TableCell>

                                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            handleEdit2(row.id)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>
                                                {/* 
                                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                                    <Button
                                                        style={{
                                                            color: 'red'
                                                        }}
                                                        variant="text"
                                                        onClick={() => {
                                                            handleDelete(row.id)
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                </TableCell> */}
                                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                                    {Boolean(catId) && (
                                                        <DragIndicator />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </DragDropContext>
                {/* </TableBody> */}
            </Table>
        </TableContainer>
    )
}

export default ItemsTable