import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getOrderDetails } from '../../redux/actions/orderDetailsActions';
import OrderDetails from '../pages/OrderDetails/OrderDetails';

const OrderDetailsRoute = () => {

    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')

    useEffect(() => {
        try {
            dispatch(getOrderDetails({ id }));
        } catch (error) {
            console.log(error.message);
        }
    }, []);

  return (
    <OrderDetails />
  )
}

export default OrderDetailsRoute