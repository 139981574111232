import React, { useEffect, useState } from "react";
import { Collapse, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { ChevronRight, KeyboardArrowUp } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import TimeSlotPicker from "../../TimeSlotPicker/TimeSlotPicker";
import { nodeAxios } from "../../../../../../utils/axiosConfig";
import { toast } from "react-toastify";
import { generateDeliveryPickupSlot } from "../../../../../../utils/helpers";
import { useConfirm } from "material-ui-confirm";

const useStyles = makeStyles(() => ({
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    infoContainerVal: {
        width: '60%',
        fontWeight: '600',
        color: 'black',
        wordBreak: 'break-word',
        fontSize: '13px',
    },
    infoContainerHead: {
        fontSize: '13px',
    }
}));

const Detail2 = (props) => {
    const classes = useStyles()
    const user = useSelector((store) => store.user);

    const isMobileScreen = useMediaQuery('(max-width:768px)');
    const [showBody, setShowBody] = useState(true)
    const [timeslot, setTimeslot] = useState({})

    return (
        <div
            style={{
                flex: props.flex ? 1 : 'initial',
                border: '1px solid rgb(232,232,232)',
                borderRadius: 4
            }}
        >
            <Typography
                onClick={() => {
                    if (isMobileScreen) {
                        setShowBody(!showBody)
                    }
                }}
                style={{
                    fontSize: 18,
                    color: 'black',
                    padding: 10, backgroundColor: '#f9f9f9',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    transition: 'all .3s ease-in-out'
                }}>
                {props.title}

                {isMobileScreen && (
                    showBody ? <KeyboardArrowUp /> : <ChevronRight />
                )}
            </Typography>
            {isMobileScreen ? (
                <Collapse in={showBody}>
                    <DetailBody timeslot={timeslot} setTimeslot={setTimeslot} classes={classes} {...props} />
                </Collapse>
            ) : (
                <DetailBody timeslot={timeslot} setTimeslot={setTimeslot} classes={classes} {...props} />
            )}

        </div>
    );
};

export default Detail2;

const DetailBody = ({ classes, timeslot, setTimeslot, ...props }) => {

    return (
        <div
            style={{
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: 12
            }}
        >
            {props.isTracking ? (
                <>
                    {props.tracking?.map((data) => (
                        <div className={classes.infoContainer}>
                            <Typography className={classes.infoContainerHead}>
                                {data.order_action?.includes('DASHER') ? '' : 'Order'} {data.order_action}
                            </Typography>
                            {' '}
                            <Typography className={classes.infoContainerVal}>
                                <Typography style={{ fontWeight: '700', fontSize: '.85rem' }} component="span">
                                    {data.created_at}
                                </Typography>
                                {' '}
                                {Boolean(data.event_details) && (
                                    <Typography style={{ fontWeight: '400', fontSize: '.85rem' }} component="span">
                                        {data.event_details}
                                    </Typography>
                                )}
                            </Typography>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props._1header.style }}>
                            {props._1header.val}
                        </Typography>
                        <Typography style={{ ...props._1value.style }} className={classes.infoContainerVal}>
                            {props._1value.val}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props._2header.style }}>
                            {props._2header.val}
                        </Typography>
                        <Typography style={{ ...props._2value.style }} className={classes.infoContainerVal}>
                            {props._2value.val}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props._3header.style }}>
                            {props._3header.val}
                        </Typography>
                        <Typography style={{ ...props._3value.style }} className={classes.infoContainerVal}>
                            {props._3value.val}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props._4header.style }}>
                            {props._4header.val}
                        </Typography>
                        <Typography style={{ ...props._4value.style }} className={classes.infoContainerVal}>
                            {props._4value.val}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props?._5header?.style }}>
                            {props?._5header?.val}
                        </Typography>
                        <Typography style={{ ...props?._5value?.style }} className={classes.infoContainerVal}>
                            {props?._5value?.val}
                        </Typography>
                    </div>


                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props?._6header?.style }}>
                            {props?._6header?.val}
                        </Typography>
                        <Typography style={{ ...props?._6value?.style }} className={classes.infoContainerVal}>
                            {props?._6value?.val}
                        </Typography>
                    </div>

                    <div className={classes.infoContainer}>
                        <Typography className={classes.infoContainerHead} style={{ ...props?._6header?.style }}>
                            {props?._7header?.val}
                        </Typography>
                        <Typography style={{ ...props?._6value?.style }} className={classes.infoContainerVal}>
                            {props?._7value?.val}
                        </Typography>
                    </div>
                </>
            )}
        </div >
    )
}