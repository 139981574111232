import React, { useEffect, useState } from 'react'
import { getImage2 } from '../../../utils/helpers'
import axios from '../../../utils/axiosConfig'
import { Button, Dialog, Drawer, IconButton, useMediaQuery } from '@mui/material'
import EnterAddressDialog from '../EnterAddressDialog/EnterAddressDialog'
import { CheckCircle, Edit, HelpOutlineOutlined, West } from '@mui/icons-material'
import LightTooltip from '../../../util_comps/LightTooltip'
import { AnimatePresence, motion } from 'framer-motion'
import LoaderGif from '../LoaderGif/LoaderGif'
import RestaurantType from '../RestaurantType/RestaurantType'

const images = {
    'Restaurant': getImage2('restaurant_thumbnail.png-1709817803.png'),
    'Package Store': getImage2('liquor_thumbnails.png-1709819472.png'),
    'Grocery Store': getImage2('grocery_thumbnail.png-1709819842.png')
}

const BusinessType = ({ onNext, formik, fetchRandomTemplate, ...props }) => {

    const [showEnterAddress, setShowEnterAddress] = useState(false)
    const [page, setPage] = useState(props.businessTypeNo || 1)
    const [geoLocation, setGeoLocation] = useState({})
    const [coords, setCoords] = useState()
    const lg = useMediaQuery('(max-width:1024px)');
    const sm = useMediaQuery('(max-width:640px)');

    return (
        <div
            className="tw-flex tw-h-full"
        >
            <div
                className="tw-w-full tw-flex tw-justify-center tw-p-3 lg:tw-w-1/2"
            >
                {{
                    1: (
                        <div
                            className="tw-flex tw-items-start tw-justify-center tw-h-full tw-flex-col tw-max-w-md tw-gap-3 lg:tw-gap-7"
                        >
                            <div
                                className="tw-flex tw-gap-1 tw-items-center tw-flex-row"
                            >
                                <div
                                    className="tw-text-light-gray tw-text-sm tw-whitespace-nowrap"
                                >
                                    Your Location

                                    <span
                                        className="tw-mx-[2px]"
                                    >
                                        <LightTooltip
                                            title={(<span
                                                style={{ display: 'inline-block', padding: '0 15px' }}
                                            >
                                                Enter your business address, so that our AI can personalized your website for local listings
                                            </span>)}
                                            arrow
                                            placement="top"
                                        >
                                            <HelpOutlineOutlined
                                                style={{
                                                    fontSize: 12
                                                }}
                                            />
                                        </LightTooltip>
                                    </span>

                                    :
                                </div>
                                <div
                                    className="tw-relative tw-flex tw-cursor-pointer"
                                    onClick={() => {
                                        setShowEnterAddress(true)
                                    }}
                                >
                                    <div
                                        className="tw-text-sm tw-underline tw-truncate tw-w-40 sm:tw-w-52"
                                    >
                                        {formik.values.Address1 || "Enter your business address"}
                                        {/* {geoLocation?.content?.city}, {geoLocation?.content?.country} */}
                                    </div>
                                    {/* <Edit
                                    className="tw-absolute -tw-right-5 -tw-top-2"
                                    fontSize="small"
                                /> */}
                                </div>
                            </div>

                            <div
                                className="tw-font-semibold tw-text-[28px] sm:tw-text-4xl"
                            >
                                What type of business are you?
                            </div>

                            <div
                                className="tw-flex tw-gap-2 tw-flex-wrap"
                            >
                                <div
                                    className="tw-relative"
                                >
                                    <Button
                                        style={{
                                            color: 'black',
                                            border: (formik.values.venuetype == 'Restaurant') ? '1px solid black' : '1px solid #d3d3d3',
                                            backgroundColor: 'white',
                                            color: (formik.values.venuetype == 'Restaurant') ? 'black' : '#d3d3d3',
                                            borderRadius: 10,
                                            width: sm ? 85 : 130,
                                            height: 35
                                        }}
                                        variant="outlined"
                                        onClick={() => {
                                            formik.setFieldValue('venuetype', 'Restaurant')
                                        }}
                                    >
                                        Restaurant
                                    </Button>

                                    {(formik.values.venuetype == 'Restaurant') && (
                                        <CheckCircle
                                            className="tw-bg-white tw-absolute -tw-top-3 tw-right-4 tw-z-[1]"
                                        />
                                    )}
                                </div>

                                <div
                                    className="tw-relative"
                                >
                                    <Button
                                        style={{
                                            color: 'black',
                                            border: (formik.values.venuetype == 'Package Store') ? '1px solid black' : '1px solid #d3d3d3',
                                            backgroundColor: 'white',
                                            color: (formik.values.venuetype == 'Package Store') ? 'black' : '#d3d3d3',
                                            borderRadius: 10,
                                            width: sm ? 110 : 130,
                                            height: 35
                                        }}
                                        variant="outlined"
                                        onClick={() => {
                                            formik.setFieldValue('venuetype', 'Package Store')
                                        }}
                                    >
                                        Liquor store
                                    </Button>

                                    {(formik.values.venuetype == 'Package Store') && (
                                        <CheckCircle
                                            className="tw-bg-white tw-absolute -tw-top-3 tw-right-4 tw-z-[1]"
                                        />
                                    )}
                                </div>

                                <div
                                    className="tw-relative"
                                >
                                    <Button
                                        style={{
                                            color: 'black',
                                            border: (formik.values.venuetype == 'Grocery Store') ? '1px solid black' : '1px solid #d3d3d3',
                                            backgroundColor: 'white',
                                            color: (formik.values.venuetype == 'Grocery Store') ? 'black' : '#d3d3d3',
                                            borderRadius: 10,
                                            width: sm ? 120 : 130,
                                            height: 35
                                        }}
                                        variant="outlined"
                                        onClick={() => {
                                            formik.setFieldValue('venuetype', 'Grocery Store')
                                        }}
                                    >
                                        Grocery Store
                                    </Button>

                                    {(formik.values.venuetype == 'Grocery Store') && (
                                        <CheckCircle
                                            className="tw-bg-white tw-absolute -tw-top-3 tw-right-4 tw-z-[1]"
                                        />
                                    )}
                                </div>
                            </div>

                            <div
                                className="tw-w-full tw-flex tw-justify-end"
                            >
                                <Button
                                    style={{
                                        color: 'white',
                                        backgroundColor: (!(formik.values.latitude && formik.values.longitude) || !formik.values.venuetype) ? '#CACACA' : 'black',
                                        borderRadius: 30,
                                        width: 120,
                                        height: 40
                                    }}
                                    variant="contained"
                                    disabled={!(formik.values.latitude && formik.values.longitude) || !formik.values.venuetype}
                                    onClick={() => {
                                        if (formik.values.venuetype == 'Package Store') {
                                            window.open('https://esplanda.com/liquor', '_self')
                                        } else if (formik.values.venuetype == 'Grocery Store') {
                                            window.open('https://esplanda.com/grocery', '_self')
                                        } else if (formik.values.venuetype == 'Restaurant') {
                                            setPage(2)
                                        }
                                    }}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    ),
                    2: (
                        <LoaderGif
                            onNext={() => {
                                setPage(3)
                            }}
                            type={1}
                            isTemplateLoading={false}
                            formik={formik}
                        />
                    ),
                    3: (
                        <RestaurantType
                            fetchRandomTemplate={fetchRandomTemplate}
                            formik={formik}
                            onPrev={()=>{
                                setPage(1)
                            }}
                            onNext={() => {
                                onNext()
                            }}
                        />
                    )
                }[page]}
            </div>

            <div
                className="tw-w-1/2 tw-hidden lg:tw-block"
            >
                <AnimatePresence mode='wait'>
                    <motion.img
                        key={images[formik.values.venuetype] || getImage2('sell_online_img.png-1709029796.png')}
                        src={images[formik.values.venuetype] || getImage2('sell_online_img.png-1709029796.png')}
                        alt="Sliding image"
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.3 }}
                        className="tw-h-full tw-w-full tw-object-cover"
                    />
                </AnimatePresence>
            </div>

            <Dialog
                open={!lg && showEnterAddress}
                onClose={() => {
                    setShowEnterAddress(false)
                }}
            >
                <EnterAddressDialog
                    sm={sm}
                    formik={formik}
                    onClose={() => {
                        setShowEnterAddress(false)
                    }}
                />
            </Dialog>

            <Drawer
                anchor="bottom"
                open={lg && showEnterAddress}
                onClose={() => {
                    setShowEnterAddress(false)
                }}
                PaperProps={{
                    style: {
                        borderTopLeftRadius: 30,
                        borderTopRightRadius: 30
                    }
                }}
            >
                <EnterAddressDialog
                    sm={sm}
                    formik={formik}
                    onClose={() => {
                        setShowEnterAddress(false)
                    }}
                />
            </Drawer>
        </div>
    )
}

export default BusinessType