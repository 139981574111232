const initialState = {
    result: []
};

function reviews(state=initialState,action){
    switch(action.type){
        case "SET_APP_REVIEWS":
            return {
                ...state,
                result: action.payload
            }
        default: return state;
    };
};

export default reviews;