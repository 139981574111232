import { Add, Remove } from '@mui/icons-material'
import { CircularProgress, DialogActions, DialogContent, DialogTitle, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import GenericAutoCompleteWithLabel from '../../Library/GenericAutoCompleteWithLabel/GenericAutoCompleteWithLabel'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import GenericTextField from '../../Library/GenericTextField/GenericTextField'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import styles from './EditOrderProductDialog.module.scss'

const EditOrderProductDialog = (props) => {

    const [openOption, setOpenOption] = useState(false)

    return (
        <>
            <DialogTitle>
                Edit Order
            </DialogTitle>

            <DialogContent
                style={{
                    width: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15
                }}
            >
                <GenericAutoCompleteWithLabel
                    containerStyle={{
                        width: '100%'
                    }}
                    label="Product Name"
                    options={props.suggestions}
                    clearOnBlur={false}
                    onBlur={() => setOpenOption(false)}
                    open={openOption}
                    filterOptions={(options) => options}
                    getOptionLabel={(option) => option.ProductName}
                    inputValue={props.editableName}
                    fullWidth={true}
                    freeSolo={true}
                    onChange={(event, val) => {
                        setOpenOption(false)
                        props.handleProductNameChange(
                            event,
                            val,
                            false
                        )
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'input') {
                            setOpenOption(true)
                            props.handleProductNameChange(
                                event,
                                newInputValue,
                                true
                            );
                        }
                    }}
                    renderInput={(params) => (
                        <GenericTextField
                            {...params}
                            style={{
                                backgroundColor: 'white'
                            }}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    root: styles.autocomplete_input
                                },
                                endAdornment: props.isLoading ? <CircularProgress style={{ marginRight: '10px' }} size={20} /> : null
                            }}
                        />
                    )}
                />

                <div
                    className={styles.format_container}
                >
                    <GenericSelectWithLabel
                        style={{
                            backgroundColor: 'white'
                        }}
                        label="Format"
                        fullWidth={true}
                        value={props.editableFormat}
                        onChange={props.handleFormatChange}
                    >
                        {props.formats.map((val) => {
                            return (
                                <MenuItem key={val.format} value={val.format}>
                                    {val.format}
                                </MenuItem>
                            );
                        })}
                    </GenericSelectWithLabel>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            className={styles.label_text}
                        >
                            Quantity
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                boxShadow: '0px 1px 4px #0000001a'
                            }}
                        >
                            <div
                                onClick={e => {
                                    if ((props.editableQuantity - 1) > 0) {
                                        props.handleQuantityChange(props.editableQuantity - 1, 'reduce')
                                    }
                                }}
                                style={{
                                    backgroundColor: "rgb(223,219,220)",
                                    color: "#767273",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <Remove
                                    color="inherit"
                                    style={{ transform: "scale(.75)" }}
                                />
                            </div>
                            <Typography
                                style={{ margin: "5px 14px" }}
                                variant="h6" /*  onClick={e => setQuantityEditable(true)} */
                            >
                                {props.quantity}
                            </Typography>
                            <div
                                onClick={e => props.handleQuantityChange(props.editableQuantity + 1, 'increment')}
                                style={{
                                    backgroundColor: "rgb(223,219,220)",
                                    color: "#767273",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <Add
                                    color="inherit"
                                    style={{ transform: "scale(.75)" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                <GenericButton
                    variant="contained"
                    onClick={() => {
                        props.handleSave()
                        props.onClose()
                    }}
                >
                    Save
                </GenericButton>

                <GenericButton
                    variant="contained"
                    btnColor="secondary"
                    onClick={props.onClose}
                >
                    Cancel
                </GenericButton>
            </DialogActions>
        </>
    )
}

export default EditOrderProductDialog