import { Autocomplete } from '@mui/material'
import React from 'react'
import styles from './GenericAutoCompleteWithLabel.module.scss'
import clsx from 'clsx'

const GenericAutoCompleteWithLabel = ({ label, renderInput, containerStyle, isSmallLabel, ...rest }) => {
    return (
        <div
            style={containerStyle}
            className={styles.container}
        >
            {label && (
                <div className={clsx(styles.label_text, isSmallLabel && styles.small_label, `${rest.required ? '' : 'tw-mb-[5px]'}`)}>
                    {label}
                    {rest.required && (
                    <span
                        className={clsx(styles.required_asterisk, isSmallLabel && styles.small_icon)}
                    >
                        *
                    </span>
                )}
                </div>
            )}

            <Autocomplete
                {...rest}
                renderInput={renderInput}
            />
        </div>
    )
}

export default GenericAutoCompleteWithLabel