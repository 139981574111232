import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel } from '@mui/material'
import { format } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import GenericButton from '../../Library/GenericButton/GenericButton'
import SelectTemplate from '../SelectTemplate/SelectTemplate'
import styles from './EmailCampaign.module.scss'
import axios from '../../../utils/axiosConfig'
import clsx from 'clsx'
import EmailDetail from '../EmailDetail/EmailDetail'
import { ArrowBack } from '@mui/icons-material'
import EmailEditor from 'react-email-editor';
import Loader from '../../Library/Loader'
import NameDialog from '../../Dialogs/NameDialog/NameDialog'
import { toast } from 'react-toastify'
import SearchProduct from '../../SearchProduct/SearchProduct'
import Const from '../../helpers/const'

const localizer = momentLocalizer(moment)

const myEventsList = [
    {
        id: 1,
        title: "Tasting",
        event_date: "28-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: true
    },
    {
        id: 1,
        title: "Tast ing",
        event_date: "29-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: true
    },
    {
        id: 1,
        title: "Tasti",
        event_date: "27-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: true
    },
    {
        id: 1,
        title: "Tasg",
        event_date: "26-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: false
    },
    {
        id: 1,
        title: "Tasg",
        event_date: "30-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: false
    },
    {
        id: 1,
        title: "Tasg",
        event_date: "1-12-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: false
    }
]

const EmailCampaign = (props) => {

    const emailEditorRef = useRef(null);

    const { externalWebsite, subDomain } = useOutletContext()

    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState('calendar')
    const [templates, setTemplates] = useState([])
    const [details, setDetails] = useState()
    const [isDetailLoading, setIsDetailLoading] = useState(false)
    const [preview, setPreview] = useState('')
    const [isEmailEditorLoading, setIsEmailEditorLoading] = useState(true)
    const [isTemplateSelected, setIsTemplateSelected] = useState(false)
    const [showTemplatePopup, setShowTemplatePopup] = useState(false)
    const [templateName, setTemplateName] = useState('')
    const [isDetailEdit, setIsDetailEdit] = useState(false)
    const [isTemplateSave, setIsTemplateSave] = useState(false)
    const [audienceId, setAudienceId] = useState('')
    const [subject, setSubject] = useState('')
    const [html, setHtml] = useState('')
    const [scheduleTime, setScheduleTime] = useState(format(new Date(), 'hh:mm'))
    const [templateId, setTemplateId] = useState()
    const [openFindProduct, setOpenFindProduct] = useState(false)
    // const [selectedProduct, setSelectedProduct] = useState(second)

    const date = searchParams.get('date')
    const id = searchParams.get('id')

    useEffect(() => {
        setSearchParams({})

        window.addEventListener('message', (event) => {
            // console.log(data)
            if (event.data == 'openProductSelctor') {
                setOpenFindProduct(true)
            }
        })
    }, [])

    useEffect(() => {
        if (date) {

            let day = date.split('-')[0]
            let month = date.split('-')[1]
            let year = date.split('-')[2]

            setScheduleTime(format(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)), 'yyyy-MM-dd HH:mm').replace(' ', 'T'))
        } else {
            setScheduleTime(format(new Date(), 'HH:mm'))
        }
    }, [date])


    const handleSaveTemplate = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            setIsTemplateSave(true)
            axios.post('/mailchimp-campaign/create-template', {
                name: templateName,
                html: html,
                design: JSON.stringify(design)
            })
                .then(() => {
                    setShowTemplatePopup(false)
                    setTemplateName('')
                    fetchTemplates()
                    toast.success('Template Saved')
                })
                .catch(() => {
                    toast.error('Something went wrong')
                })
                .finally(() => {
                    setIsTemplateSave(false)
                })
        });
    }

    const handleEditorContinue = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            axios.post('/mailchimp-campaign/create-template', {
                name: 'template',
                html: html,
                design: JSON.stringify(design),
                is_temporary_template: 1
            })
                .then((response) => {
                    setTemplateId(response.data.data.result?.id)
                    if (response.data.data.result?.id && subject && audienceId) {
                        axios.post(`/mailchimp-campaign/campaign-edit/${id}`, {
                            template_id: response.data.data.result?.id,
                            subject: subject,
                            list_id: audienceId
                        })
                            .then(() => {
                                fetchDetails(id)

                                setIsDetailEdit(true)
                                setPage('email-detail')
                                setIsTemplateSelected(false)
                                emailEditorRef.current.editor.exportHtml((data) => {
                                    const { design, html } = data;
                                    setHtml(html)
                                })
                            })
                            .catch(() => {

                            })
                    } else {
                        setIsDetailEdit(true)
                        setPage('email-detail')
                        setIsTemplateSelected(false)
                        emailEditorRef.current.editor.exportHtml((data) => {
                            const { design, html } = data;
                            setHtml(html)
                        })
                    }
                })
                .catch(() => {
                    toast.error('Something went wrong')
                })
                .finally(() => {

                })
        });
    }

    const fetchTemplates = () => {
        axios.get('/mailchimp-campaign/template-list')
            .then((response) => {
                setTemplates(response.data.data.result.templates)
            })
            .finally(() => {
            })
    }

    const fetchPreview = (id) => {
        axios.get(`/mailchimp-campaign/get-template/${id}`)
            .then((response) => {
                setPreview(response.data.data.result.thumbnail)
            })
    }

    const fetchDetails = (id = id) => {
        setIsDetailLoading(true)
        axios.get('/mailchimp-campaign/campaign-detail', {
            params: {
                campaign_id: id
            }
        })
            .then((response) => {
                setDetails(response.data.data.result)
                fetchPreview(response.data.data.result.settings.template_id)
            })
            .finally(() => {
                setIsDetailLoading(false)
            })
    }

    const eventStyleGetter = (event, start, end, isSelected) => {

        let backgroundColor = '#0070C6'
        let style = {
            backgroundColor: backgroundColor
        }
        if (start < new Date()) {
            style.backgroundColor = '#0B8043'
        }

        // var backgroundColor = '#' + event.hexColor;
        // var style = {
        //     backgroundColor: backgroundColor,
        //     borderRadius: '0px',
        //     opacity: 0.8,
        //     color: 'black',
        //     border: '0px',
        //     display: 'block'
        // };
        return {
            style: style
        }
    }

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
        console.log('loaddd')
    }

    const loadTemplateDesign = (design) => {
        emailEditorRef.current.editor.loadDesign(design);
        setIsTemplateSelected(true)
        setPage('editor')
        console.log('load')
    }

    const onReady = () => {
        emailEditorRef.current.editor.loadBlank({
            backgroundColor: "#fff",
        });
        setIsEmailEditorLoading(false)
        console.log('ready')
    };

    useEffect(() => {
        fetchTemplates()
    }, [])

    const handleSubmit = () => {

        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            console.log('exportHtml', data, values, format(new Date(values.scheduleTime), 'yyyy-MM-dd HH:MM:SS'));

            axios.post('/mailchimp-campaign/create-template', {
                name: 'template',
                html: html,
                design: JSON.stringify(design),
                is_temporary_template: 1
            })
                .then((response) => {

                    let sendParams = {
                        list_id: audienceId,
                        subject: subject,
                        template_id: response.data.data.result.id,
                    }

                    // if (schedulePost) {
                    //     sendParams.send_times = format(new Date(values.scheduleTime), 'yyyy-MM-dd HH:MM:SS')
                    // }

                    axios.post('/mailchimp-campaign/send', sendParams)
                        .then(() => {
                            // toast.success(schedulePost ? 'Campaign Scheduled' : 'Campaign Launched')
                        })
                        .finally(() => {

                        })

                })
                .catch(() => {
                    toast.error('Something went wrong')
                })
                .finally(() => {

                })

        });
    }

    const handleCampaignCreate = (date) => {
        setDetails()
        setTemplateId('')
        setAudienceId('')
        setSubject('')
        axios.post('/mailchimp-campaign/campaign-create')
            .then((response) => {
                if (date) {
                    setSearchParams({ date: date, id: response.data.data.result.id })
                } else {
                    setSearchParams({ id: response.data.data.result.id })
                }
            })
            .finally(() => {

            })
    }

    const handleCampaignEdit = (date) => {
        axios.post(`/mailchimp-campaign/campaign-edit/${id}`, {
            template_id: templateId,
            subject: subject,
            list_id: audienceId
        })
            .then(() => {

            })
    }

    return (
        <div
            className={styles.container}
        >
            {(page !== 'calendar') && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginBottom: 10
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8
                        }}
                        onClick={() => {
                            setPage('calendar')
                            setSearchParams({})
                            setPreview('')
                        }}
                    >
                        <ArrowBack />
                        Back to Calendar
                    </div>
                </div>
            )}

            {(page === 'calendar') && (
                <div>
                    <div
                        className={clsx(styles.calender_container, 'email_campaign_calendar_container')}
                    >
                        <div
                            className={styles.calendar_info_container}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            color: '#0B8043'
                                        }}
                                        checked={true}
                                        size="small"
                                    />
                                }
                                label="Sent Campaign"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            color: '#0070C6'
                                        }}
                                        checked={true}
                                        size="small"
                                    />
                                }
                                label="Scheduled Campaign"
                            />

                            <GenericButton
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    handleCampaignCreate()
                                    setPage('select-template')
                                }}
                            >
                                Add Campaign
                            </GenericButton>
                        </div>
                        <Calendar
                            localizer={localizer}
                            selectable
                            showAllEvents={true}
                            onSelectSlot={(slotInfo) => {
                                let d = new Date();
                                d.setHours(0, 0, 0, 0);
                                if (d <= slotInfo.start) {
                                    handleCampaignCreate(format(slotInfo.start, "dd-MM-yyyy"))
                                    setPage('select-template')
                                } else {
                                    toast.error('Please choose future dates to create a campaign')
                                }
                            }}
                            onSelectEvent={(event) => {
                                fetchDetails(event.id)
                                setSearchParams({ eventid: event.id })
                                setPage('email-detail')
                            }}
                            views={['month']}
                            events={props.campaignList}
                            eventPropGetter={eventStyleGetter}
                        />
                    </div>
                </div>
            )}

            {isEmailEditorLoading ? (
                <Loader />
            ) : (page === 'select-template') && (
                <SelectTemplate
                    ref={emailEditorRef}
                    onLoad={onLoad}
                    loadTemplateDesign={loadTemplateDesign}
                    onReady={onReady}
                    templates={templates}
                />
            )}

            {(page === 'email-detail') && (
                <div>
                    <EmailDetail
                        details={details}
                        setDetails={setDetails}
                        isDetailLoading={isDetailLoading}
                        preview={preview}
                        isDetailEdit={isDetailEdit}
                        audienceId={audienceId}
                        setAudienceId={setAudienceId}
                        subject={subject}
                        setSubject={setSubject}
                        html={html}
                        setIsTemplateSelected={setIsTemplateSelected}
                        setPage={setPage}
                        id={id}
                        scheduleTime={scheduleTime}
                        setScheduleTime={setScheduleTime}
                        handleCampaignEdit={handleCampaignEdit}
                        templateId={templateId}
                        date={date}
                        fetchCampaigns={props.fetchCampaigns}
                    />
                </div>
            )}

            <div
                className={styles.email_editor_container}
                style={{
                    // height: isTemplateSelected ? '100%' : 0,
                    // visibility: isTemplateSelected ? 'visible' : 'hidden'
                }}
            >
                {isEmailEditorLoading && (<Loader />)}
                <EmailEditor
                    editorId="unlayer-editor-1"
                    ref={emailEditorRef}
                    onLoad={onLoad}
                    onReady={onReady}
                    projectId={1071}
                    options={{
                        customJS: ['http://localhost:3001/commonTool.js', 'http://localhost:3001/productTool.js'],
                        customCSS: ['http://localhost:3001/productTool.css'],
                        // customJS: ['https://biz.ourdesistore.com/commonTool.js', 'https://biz.ourdesistore.com/productTool.js', 'https://liquorstores.esplanda.com/commonTool.js', 'https://liquorstores.esplanda.com/productTool.js','https://biz-frontend-stag.esplanda.com/commonTool.js', 'https://biz-frontend-stag.esplanda.com/productTool.js','http://localhost:3000/commonTool.js', 'http://localhost:3000/productTool.js'],
                        // customCSS: ['https://liquorstores.esplanda.com/productTool.css','https://biz.ourdesistore.com/productTool.css','http://localhost:3000/productTool.css'],
                    }}
                />

                <div
                    className={styles.save_continue_btn_container}
                >
                    <GenericButton
                        variant="contained"
                        btnColor="secondary"
                        onClick={() => {
                            setShowTemplatePopup(true)
                        }}
                    >
                        Save this design as a template
                    </GenericButton>

                    <GenericButton
                        variant="contained"
                        onClick={handleEditorContinue}
                    >
                        Continue
                    </GenericButton>

                </div>
            </div>

            <Dialog
                open={showTemplatePopup}
                onClose={() => {
                    setShowTemplatePopup(false)
                    setTemplateName('')
                }}
            >
                <NameDialog
                    title="Save as templates"
                    label="Template Name"
                    name={templateName}
                    isTemplateSave={isTemplateSave}
                    onChange={(event) => {
                        setTemplateName(event.target.value)
                    }}
                    onSave={handleSaveTemplate}
                    onClose={() => {
                        setShowTemplatePopup(false)
                        setTemplateName('')
                    }}
                />
            </Dialog>

            <Dialog
                open={openFindProduct}
                onClose={() => {
                    setOpenFindProduct(false)
                }}
            >
                <DialogContent>
                    <SearchProduct
                        isVenueList={true}
                        onProductChipClick={(data) => {
                            document.querySelector('#unlayer-editor-1 iframe').contentWindow.postMessage({ ...data, triggerID: 'UpdateProduct', externalWebsite: '', subDomain: (subDomain + (Const.isOurDesiStore ? '.ourdesistore.com' : '.ourliquorstore.com')) }, '*')
                            setOpenFindProduct(false)
                        }}
                    />
                </DialogContent>

                <DialogActions>
                    <GenericButton
                        variant="contained"
                        onClick={() => {
                            setOpenFindProduct(false)
                        }}
                    >
                        Save
                    </GenericButton>

                    <Button
                        onClick={(e) => {
                            setOpenFindProduct(false)
                            document.querySelector('#unlayer-editor-1 iframe').contentWindow.postMessage({ ProductId: '', Price: '', ProductName: '', format: '', triggerID: 'UpdateProduct' }, '*')
                        }}
                        style={{
                            backgroundColor: "#DDDDDD",
                            color: "#171717",
                        }}
                        variant="contained"
                    >
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>


        </div>
    )
}

export default EmailCampaign