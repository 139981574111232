import React, { useEffect, useState } from 'react'
import styles from './StripeForm.module.scss'
import StripeIcon from '../../../assets/images/stripe.png'
import { useFormik } from 'formik'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericButton from '../../Library/GenericButton/GenericButton'
import { useOutletContext } from 'react-router-dom'
import { Button, Dialog } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import StripePaymentElement from './StripePaymentElement/StripePaymentElement'
import Loader from '../../Library/Loader'
import { loadStripe } from '@stripe/stripe-js'
import { useConfirm } from 'material-ui-confirm'

const StripeForm = (props) => {

    const { venueDetails, fetchStoreInfo } = useOutletContext()
    const confirm = useConfirm()

    const [isLoading, setIsLoading] = useState(false)
    const [isStripeLoading, setIsStripeLoading] = useState(false)
    const [clientSecret, setClientSecret] = useState('')
    const [showStripePopup, setShowStripePopup] = useState(false)
    const [stripePromise, setStripePromise] = useState()
    const [stripeAccountId, setStripeAccountId] = useState('')
    // const formik = useFormik({
    //     initialValues: {
    //         stripe_account_id: venueDetails.stripe_account_id || '',
    //     },
    //     enableReinitialize: true,
    //     onSubmit: (values) => {
    //         setIsLoading(true)

    //         axios.post('/venue/setting', values)
    //             .then((response) => {
    //                 fetchStoreInfo()
    //                 toast.success('Stripe Connected')
    //             })
    //             .catch(() => {
    //                 toast.error('Something went wrong')
    //             })
    //             .finally(() => {
    //                 setIsLoading(false)
    //             })
    //     }
    // })

    const handleDisconnect = () => {
        confirm({
            title: 'Are you sure you want to disconnect',
        })
            .then(() => {
                setIsStripeLoading(true)

                axios.delete(`/stripe/disconnect`)
                    .then((res) => {
                        toast.success('Stripe disconnected')
                    })
                    .finally(() => {
                        props.fetchVenuePayment()
                        fetchStoreInfo()
                    })
            })
            .catch(() => {

            })
            .finally(() => {
            })
    }


    const handleStripeSetup = () => {
        setIsStripeLoading(true)
        axios.post('/stripe/connect', {
            return_url: `${window.location.href}?from=stripe`
        })
            .then((res) => {
                window.open(res.data?.data?.url, '_self')
            })
            .catch((err) => {
                setIsStripeLoading(false)
                toast.error(err.response?.data?.message)
            })
            .finally(() => {
                // setIsStripeLoading(false)
            })
    }
    console.log(venueDetails)

    useEffect(() => {
        if (venueDetails?.stripe_account_id) {
            setStripeAccountId(venueDetails?.stripe_account_id)
            setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY, { stripeAccount: venueDetails?.stripe_account_id }))
        }
    }, [venueDetails])



    return (
        <div
            className={styles.stripe_form}
        >
            <div
                className={styles.item_box}
            >
                {(venueDetails?.stripe_connect_status == 1) && (
                    <div
                        style={{
                            color: '#00BF36',
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            fontWeight: 600
                        }}
                    >
                        Connected
                    </div>
                )}
                <div
                    className={styles.item_box_text}
                >
                    <img
                        src={StripeIcon}
                        width={80}
                        height={50}
                    />

                    <div
                        className={styles.item_description}
                    >
                        Launch and scale embedded payments and financial services
                    </div>
                </div>
                <GenericLoadingButton
                    style={{
                        backgroundColor: !Boolean(venueDetails?.stripe_connect_status) ? '#0B9DE4' : '#FF5151',
                    }}
                    variant='contained'
                    loading={isStripeLoading}
                    loadingPosition='end'
                    size='medium'
                    // disabled={Boolean(venueDetails?.stripe_connect_status == 2) && (paymentMethod.type_id == 1)}
                    onClick={() => {
                        if (venueDetails?.stripe_connect_status == 1) {
                            handleDisconnect()
                        } else {
                            handleStripeSetup()
                        }
                    }}
                >
                    {!Boolean(venueDetails?.stripe_connect_status) ? "Click to setup" : ((venueDetails?.stripe_connect_status == 2) ? "Setup in progress" : "Click to disconnnect")}
                </GenericLoadingButton>

                {/* {!props.isStripeSucces && (router.query?.from == 'stripe') && (
                        <div
                            style={{
                                textAlign: 'center',
                                color: '#ef2d2c'
                            }}
                        >
                            There is some more information needed from you to verify your account. Please login to <a style={{ color: 'blue' }} href="https://dashboard.stripe.com" target="_blank">Stripe</a> to verify your account
                        </div>
                    )} */}
            </div>


            {/* <div
                className={styles.logo_container}
            >
                <div
                    className={styles.title}
                >
                    Your Credit Card Processor account is
                </div>
                <img className={styles.logo} src={StripeIcon} />
            </div> */}

            {/* <Dialog
                open={showStripePopup}
                onClose={() => {
                    setShowStripePopup(false)
                }}
            >
                <Elements stripe={stripePromise} options={{ clientSecret: clientSecret, appearance: { theme: 'stripe' } }}>
                    {isStripeLoading ? (
                        <Loader />
                    ) : (
                        <StripePaymentElement
                            clientSecret={clientSecret}
                            stripeAccountId={stripeAccountId}
                            onClose={() => {
                                setShowStripePopup(false)
                            }}
                        />
                    )}
                </Elements>
            </Dialog> */}
            {/* <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <GenericTextFieldWithLabel
                    label="Stripe Merchant Id"
                    id="stripe_account_id"
                    name="stripe_account_id"
                    value={formik.values.stripe_account_id}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    disabled={props.isStripeSaveDisabled}
                />

                {!props.isStripeSaveDisabled && (
                    <div
                        style={{
                            display: 'flex',
                            gap: 10
                        }}
                    >
                        <GenericLoadingButton
                            variant="contained"
                            loading={isLoading}
                            type="submit"
                            loadingPosition="end"
                            fullWidth={true}
                        >
                            Save
                        </GenericLoadingButton>

                        <GenericButton
                            variant="contained"
                            btnColor="secondary"
                            fullWidth={true}
                            onClick={props.onClose}
                        >
                            Cancel
                        </GenericButton>
                    </div>
                )}
            </form> */}


        </div>
    )
}

export default StripeForm