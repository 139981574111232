import React from 'react'
import styles from './AddHoliday.module.scss'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, inputBaseClasses, outlinedInputClasses } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useFormik } from 'formik'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

const AddHoliday = (props) => {

    const formik = useFormik({
        initialValues: props.isEdit ? props.editObj : {
            title: '',
            date: ''
        },
        onSubmit: (values) => {
            axios.post(props.isEdit ? `/venue-holiday/edit/${props.editObj?.id}` : '/venue-holiday/create', {
                ...values,
                date: format(new Date(values.date), 'yyyy-MM-dd')
            })
                .then((res) => {
                    toast.success('Holiday Added')
                    props.onClose()
                })
        }
    })

    return (
        <>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <div>
                        {props?.isEdit ? "Edit Holiday" : "Add Holiday"}
                    </div>

                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onClick={props.onClose}
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10
                    }}
                >
                    <GenericTextFieldWithLabel
                        id="title"
                        name="title"
                        label="Name of holiday"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        fullWidth={true}
                        required={true}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div
                            style={{
                                fontWeight: 600
                            }}
                        >
                            Date of Holiday
                        </div>
                        <DatePicker
                            value={new Date(formik.values.date)}
                            onChange={(date) => {
                                formik.setFieldValue('date', date)
                            }}
                            sx={{
                                [`& .${outlinedInputClasses.notchedOutline}`]: {
                                    borderColor: '#d3d3d3 !important',
                                    outline: 'none',
                                },
                                [`& .${outlinedInputClasses.input}`]: {
                                    padding: '8px',
                                }
                            }}
                            minDate={new Date()}
                        />
                    </LocalizationProvider>
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    style={{
                        backgroundColor: 'black',
                        color: 'white'
                    }}
                    variant="contained"
                    onClick={formik.handleSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    )
}

export default AddHoliday