import React from 'react'
import styles from './ItemsDrag.module.scss'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { DeleteForever, DragIndicator, EditOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { isHTML } from '../../../Util/helpers';

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '4px 0',
    margin: `0 0 8px 0`,
    borderRadius: 4,
    border: '1px solid #D3D3D3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const ItemsDrag = (props) => {
    console.log('props', props)
    return (
        <div>
            <div
                className={styles.label_text}
            >
                {props.title}
            </div>

            <DragDropContext onDragEnd={props.onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.scroll}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {props.items.map((item, index) => {
                                return (
                                    <Draggable
                                        key={index.toString()}
                                        draggableId={index.toString()}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() => {

                                                }}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                )}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        position: 'relative',
                                                        // width: 170,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    <Tooltip
                                                        // style={{
                                                        //     width: 140
                                                        // }}
                                                        title="Drag item in order to arrange"
                                                        placement="top"
                                                    >
                                                        <DragIndicator />
                                                    </Tooltip>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                            width: 160,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {item.image && (
                                                            <img
                                                                onClick={() => {
                                                                    props.onEdit(index)
                                                                }}
                                                                style={{
                                                                    height: 25,
                                                                    width: 25,
                                                                    margin: '0 6px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                src={item.image}
                                                            />
                                                        )}
                                                        {isHTML(item[props.titleKey || 'title']) ? (
                                                            <div
                                                                onClick={() => {
                                                                    props.onEdit(index)
                                                                }}
                                                                style={{
                                                                    height: 25,
                                                                    overflow: 'hidden',
                                                                    cursor: 'pointer'
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item[props.titleKey || 'title']
                                                                }}
                                                            />
                                                        ) : (
                                                            <div
                                                                onClick={() => {
                                                                    props.onEdit(index)
                                                                }}
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    width: '100%',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                {item[props.titleKey || 'title'] || `${props.titleLabel || 'Image'} ${index + 1}`}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <IconButton
                                                        size='small'
                                                        onClick={() => {
                                                            props.onDelete(index)
                                                        }}
                                                    >
                                                        <DeleteForever
                                                            style={{
                                                                color: 'var(--text-3)',
                                                            }}
                                                            fontSize='small'
                                                        />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

export default ItemsDrag