import React, { useEffect, useState } from 'react'
import { Button, Divider, Drawer, IconButton, Menu, MenuItem, Select, Toolbar, Tooltip, Typography, alpha, inputBaseClasses, outlinedInputClasses, useMediaQuery } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styles from './EnhancedTableToolbar.module.scss'
import { FilterAltOutlined as FilterIcon, ArrowBack, ArrowLeft, Close as CloseIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import FiltersDrawer from './FiltersDrawer/FiltersDrawer';
import axios from '../../../utils/axiosConfig'
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';

const daysOption = [
    {
        value: 'today',
        label: 'Today'
    },
    {
        value: 'yesterday',
        label: 'Yesterday'
    },
    {
        value: 'thisweek',
        label: 'This week'
    },
    {
        value: 'lastweek',
        label: 'Last week'
    },
    {
        value: 'thismonth',
        label: 'This month'
    },
    {
        value: 'lastmonth',
        label: 'Last month'
    },
    {
        value: 'thisyear',
        label: 'This year'
    },
    {
        value: 'lastyear',
        label: 'Last year'
    },
]

const EnhancedTableToolbar = (props) => {

    const confirm = useConfirm()
    const [showMobileFilter, setShowMobileFilter] = useState(false)
    const isMobileScreen = useMediaQuery('(max-width:1000px)');
    const [openDrawer, setOpenDrawer] = useState(false)
    const [isPaused, setIsPaused] = useState(0)

    const [days, setDays] = useState('thisweek')

    const [orderSattus, setOrderSattus] = useState([
        {
            value: 'none',
            label: 'All'
        },
        {
            value: 'o',
            label: 'Open'
        },
        {
            value: 'a',
            label: 'Accepted'
        },
        {
            value: 'r',
            label: 'Ready'
        },
        {
            value: 'can',
            label: 'Cancelled'
        },
        {
            value: 'clo',
            label: 'Closed'
        },
    ])  
    const [mobileMenuEl, setMobileMenuEl] = useState(null);
    const mobileMenuOpen = Boolean(mobileMenuEl);
    const handleOpenMobileMenu = (event) => {
      setMobileMenuEl(event.currentTarget);
    };
    const handleCloseMobileMenu = () => {
      setMobileMenuEl(null);
    };

    useEffect(() => {
        if (props.isOrderHistory) {
            setOrderSattus([
                {
                    value: 'none',
                    label: 'All'
                },
                {
                    value: 'can',
                    label: 'Cancelled'
                },
                {
                    value: 'clo',
                    label: 'Closed'
                },
            ])
        } else {
            setOrderSattus([
                {
                    value: 'none',
                    label: 'All'
                },
                {
                    value: 'o',
                    label: 'Open'
                },
                {
                    value: 'a',
                    label: 'Accepted'
                },
                {
                    value: 'r',
                    label: 'Ready'
                }
            ])
        }
    }, [props.isOrderHistory])

    const fetchOrdersStatus = () => {
        axios.get('/venue-order-setting/my')
            .then((res) => {
                setIsPaused(res.data?.data?.result?.is_order_pause)
            })
    }

    useEffect(() => {

        const today = new Date();
        const dayOfWeek = today.getDay();

        switch (days) {
            case 'today':
                props.setFilters((prevState) => ({ ...prevState, startDate: new Date(), endDate: new Date() }))
                break;
            case 'yesterday':
                props.setFilters((prevState) => ({ ...prevState, startDate: new Date(today.getTime() - 86400000), endDate: new Date(today.getTime() - 86400000) }))
                break;
            case 'thisweek':
                // Get the start of this week (Sunday)
                const startOfThisWeek = new Date(today);
                startOfThisWeek.setDate(today.getDate() - dayOfWeek);

                // Get the end of this week (Saturday)
                const endOfThisWeek = new Date(today);
                endOfThisWeek.setDate(today.getDate() + (6 - dayOfWeek));
                props.setFilters((prevState) => ({ ...prevState, startDate: startOfThisWeek, endDate: endOfThisWeek }))
                break;
            case 'lastweek':
                // Get the last Sunday
                const startOfLastWeek = new Date(today);
                startOfLastWeek.setDate(today.getDate() - dayOfWeek - 7);

                // Get the last Saturday
                const endOfLastWeek = new Date(startOfLastWeek);
                endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);

                props.setFilters((prevState) => ({ ...prevState, startDate: startOfLastWeek, endDate: endOfLastWeek }))
                break;
            case 'thismonth':
                const startOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);

                // Get the end of this month
                const endOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Setting the day to 0 of the next month will give the last day of the current month

                props.setFilters((prevState) => ({ ...prevState, startDate: startOfThisMonth, endDate: endOfThisMonth }))
                break;
            case 'lastmonth':
                const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

                // Get the end of last month
                const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Setting the day to 0 of the current month will give the last day of the previous month

                props.setFilters((prevState) => ({ ...prevState, startDate: startOfLastMonth, endDate: endOfLastMonth }))
                break;
            case 'thisyear':

                // Get the start of this year
                const startOfThisYear = new Date(today.getFullYear(), 0, 1); // 0 is January

                // Get the end of this year
                const endOfThisYear = new Date(today.getFullYear(), 11, 31); // 11 is December

                props.setFilters((prevState) => ({ ...prevState, startDate: startOfThisYear, endDate: endOfThisYear }))
                break;
            case 'lastyear':
                const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1); // 0 is January

                // Get the end of last year
                const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31); // 11 is December


                props.setFilters((prevState) => ({ ...prevState, startDate: startOfLastYear, endDate: endOfLastYear }))
                break;
            default:
                break;
        }

    }, [days])

    useEffect(() => {
        fetchOrdersStatus()
    }, [])

    const handleChangeOrderStatus = () => {

        if (Boolean(isPaused)) {
            axios.post(`/venue-order-setting/create`, {
                is_order_pause: Boolean(isPaused) ? 0 : 1
            })
                .then((res) => {
                    toast.success(Boolean(isPaused) ? 'Orders Resumed' : 'Orders Paused')
                    setIsPaused(Boolean(isPaused) ? 0 : 1)
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
        } else {

            confirm({
                title: 'Customers will not be able to place orders until you resume them again. Are you sure you want to pause taking orders now?',
            })
                .then(() => {
                    axios.post(`/venue-order-setting/create`, {
                        is_order_pause: Boolean(isPaused) ? 0 : 1
                    })
                        .then((res) => {
                            toast.success(Boolean(isPaused) ? 'Orders Resumed' : 'Orders Paused')
                            setIsPaused(Boolean(isPaused) ? 0 : 1)
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.message)
                        })
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
        }
    };

    return (
        <Toolbar
            sx={{
                p: '0 !important'
                // pl: { sm: 2 },
                // pr: { xs: 1, sm: 1 },
            }}
        >
            <div
                style={{
                    width: '100%'
                }}
            >
                {/* <div
                    style={{
                        fontSize: 25,
                        fontWeight: 600
                    }}
                >
                    Orders
                </div> */}

                <div className={styles.nav_container}>
                {!(props?.user?.data?.biz_users_role == 3) && (
                    <div className={styles.toolbar_actions_container_mobile}>
                    <Button
                        variant="text"
                        color='inherit'
                        sx={{
                            borderRadius: 0,
                            width: '33%',
                            whiteSpace: 'nowrap',
                            borderBottom: !props.isReports && !props.isOrderHistory ? '2px solid #000' : '2px solid #d3d3d3' 
                        }}
                        onClick={() => {
                            props.setIsReports(false)
                            props.setOrderState('none')
                            props.setIsOrderHistory(false)
                            props.setIsRevenueByDate(false)
                        }}
                    >
                        Open Orders
                    </Button>
                    <Button
                        variant="text"
                        color='inherit'
                        sx={{
                            borderRadius: 0,
                            width: '33%',
                            whiteSpace: 'nowrap',
                            borderBottom: props.isReports ? '2px solid #000' : '2px solid #d3d3d3' 
                        }}
                        onClick={() => {
                            props.setIsReports(true)
                            props.setSelectedDate('')
                            props.setIsRevenueByDate(false)
                        }}
                    >
                        Revenue by day
                    </Button>

                    <Button
                        variant="text"
                        color='inherit'
                        sx={{
                            borderRadius: 0,
                            width: '33%',
                            whiteSpace: 'nowrap',
                            borderBottom:  !props.isReports && props.isOrderHistory ?  '2px solid #000' : '2px solid #d3d3d3' 
                        }}
                        onClick={() => {
                            props.setIsReports(false)
                            props.setOrderState('none')
                            props.setIsOrderHistory(true)
                            props.setIsRevenueByDate(false)
                        }}
                    >
                        Orders history
                    </Button>
                    </div>
                )}
                    <div className={styles.title_container}>
                        <div className={styles.title_name}>
                            {!isMobileScreen && (props.isReports ? (
                                <IconButton
                                    sx={{ '@media(max-width: 600px)': { ml: '-8px' } }}
                                    onClick={() => {
                                        props.setIsRevenueByDate(false)
                                        props.setIsReports(false)
                                    }}
                                >
                                    <ArrowBack />
                                </IconButton>

                            ) : (
                                <>
                                    {props.isOrderHistory && (
                                        <IconButton
                                            sx={{ '@media(max-width: 600px)': { ml: '-8px' } }}
                                            onClick={() => {
                                                props.setIsReports(false)
                                                props.setIsOrderHistory((prevState) => !prevState)
                                            }}
                                        >
                                            <ArrowBack />
                                        </IconButton>
                                    )}
                                </>
                            ))}

                            {isMobileScreen && props.isRevenueByDate && (
                                <IconButton
                                    sx={{ '@media(max-width: 600px)': { ml: '-8px' } }}
                                    onClick={() => {
                                        props.setIsRevenueByDate(false)
                                        props.setIsReports(true)
                                    }}
                                >
                                    <ArrowBack />
                                </IconButton>

                            )}

                            {props.isRevenueByDate ? `Revenue (${props.selectedDate})` : (
                                <>
                                    {props.isReports ? "Revenue by day" : (
                                        <>
                                            {props.isOrderHistory ? "Order history" : "Open Orders"}
                                        </>
                                    )}
                                </>
                            )}


                        </div>
                        <div className={styles.mobile_filter_icon}>
                            <Button variant='outlined' sx={{ borderRadius: '20px', borderColor: '#d3d3d3' }} onClick={() => setOpenDrawer(openDrawer => !openDrawer)} >
                                <FilterIcon sx={{ mr: 1 }} /> {openDrawer ? "Hide Filter" : 'Filter'}
                            </Button>
                            <IconButton
                                aria-controls={mobileMenuOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={mobileMenuOpen ? 'true' : undefined}
                                onClick={handleOpenMobileMenu}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={mobileMenuEl}
                                open={mobileMenuOpen}
                                onClose={handleCloseMobileMenu}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => {props.onPrint(); handleCloseMobileMenu();}}>Print</MenuItem>
                                <MenuItem onClick={() => {handleChangeOrderStatus(); handleCloseMobileMenu();}}>{Boolean(isPaused) ? "Start taking orders" : "Pause orders"}</MenuItem>
                            </Menu>
                        </div>
                    </div>

                    {!(props?.user?.data?.biz_users_role == 3) && ((isMobileScreen && !props.isReports && !props.isOrderHistory) || !isMobileScreen) && (
                        <div className={styles.toolbar_actions_container}>
                            <Button
                                variant="contained"
                                sx={{
                                    borderRadius: 20,
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                    "@media (max-width: 768px)": {
                                        width: '48%'
                                    }
                                }}
                                onClick={() => {
                                    props.onPrint()
                                }}
                            >
                                Print
                            </Button>

                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#F2F2F2',
                                    borderRadius: 20,
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                    color: 'black',
                                    "@media (max-width: 768px)": {
                                        width: '48%'
                                    }
                                }}
                                onClick={() => {
                                    props.setIsReports(true)
                                    props.setSelectedDate('')
                                    props.setIsRevenueByDate(false)
                                }}
                            >
                                Revenue by day
                            </Button>

                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#F2F2F2',
                                    borderRadius: 20,
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                    color: 'black',
                                    "@media (max-width: 768px)": {
                                        width: '48%'
                                    }
                                }}
                                onClick={() => {
                                    props.setIsReports(false)
                                    props.setOrderState('none')
                                    props.setIsOrderHistory((prevState) => !prevState)
                                    props.setIsRevenueByDate(false)
                                }}
                            >
                                {props.isOrderHistory ? "Open Orders" : "Orders history"}
                            </Button>

                            <Button
                                style={{
                                    backgroundColor: Boolean(isPaused) ? '#00c437' : 'red'
                                }}
                                variant="contained"
                                onClick={handleChangeOrderStatus}
                                sx={{
                                    borderRadius: 20,
                                    "@media (max-width: 768px)": {
                                        width: '48%'
                                    }
                                }}
                            >
                                {Boolean(isPaused) ? "Start taking orders" : "Pause orders"}
                            </Button>
                        </div>
                    )}
                </div>


                {isMobileScreen ? (
                    <div>
                        <Drawer
                            classes={{
                                paper: styles.mobile_drawer_container
                            }}
                            anchor="right"
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                        >

                            <div className={styles.drawer_close_icon_container}>
                                <div className={styles.drawer_filters_text}>
                                    Filters
                                </div>
                                <IconButton
                                    onClick={() => setOpenDrawer(false)}
                                    sx={{ color: '#0065C2', fontSize: '16px', borderRadius: '5px' }}
                                >
                                    Close
                                </IconButton>
                            </div>
                            <FiltersDrawer {...{ days, setDays, daysOption, orderSattus, closeDrawer: () => setOpenDrawer(false), ...props }} />
                        </Drawer>
                    </div>
                ) : (!(props?.user?.data?.biz_users_role == 3) && (
                    <div className={styles.toolbar_row}>
                        {(props.isOrderHistory || props.isReports) && !props.isRevenueByDate && (
                            <>
                                <Select
                                    value={days}
                                    onChange={(event) => {
                                        setDays(event.target.value)
                                    }}
                                    size="small"
                                    sx={{
                                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                                            border: 'none !important',
                                            boxShadow: '0 0 6px lightgray',
                                            borderRadius: '10px'
                                        },

                                        "@media (max-width: 426px)": {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    {daysOption?.map((data) => (
                                        <MenuItem
                                            value={data.value}
                                        >
                                            {data.label}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <div className={styles.toolbar_date_item}>
                                    <div className={styles.toolbar_date_title}>Order date:</div>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={new Date(props.filters.startDate)}
                                            onChange={(date) => {
                                                props.setFilters({ ...props.filters, startDate: date })
                                            }}
                                            sx={{
                                                [`& .${outlinedInputClasses.notchedOutline}`]: {
                                                    border: 'none',
                                                    outline: 'none',
                                                },
                                                [`& .${outlinedInputClasses.input}`]: {
                                                    padding: '2px',
                                                },
                                                [`& .${inputBaseClasses.root}`]: {
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '25px',
                                                    width: '150px',
                                                    padding: '6px 14px',
                                                    "@media (max-width: 485px)": {
                                                        width: '100%'
                                                    }
                                                },
                                            }}
                                            maxDate={new Date(props.filters.endDate)}
                                        // shouldDisableDate={(date) => {
                                        //     console.log(date, excludedDates)
                                        //     // Here, you'd implement logic to check if date is in excludedDates
                                        //     const excluded = excludedDates
                                        //     try {
                                        //         return excluded?.includes(format(date, 'yyy-MM-dd'));
                                        //     } catch (error) {

                                        //     }

                                        // }}
                                        />

                                        <DatePicker
                                            className={styles.date_picker}
                                            value={new Date(props.filters.endDate)}
                                            onChange={(date) => {
                                                props.setFilters({ ...props.filters, endDate: date })
                                            }}
                                            sx={{
                                                [`& .${outlinedInputClasses.notchedOutline}`]: {
                                                    border: 'none',
                                                    outline: 'none',
                                                },
                                                [`& .${outlinedInputClasses.input}`]: {
                                                    padding: '2px',
                                                },
                                                [`& .${inputBaseClasses.root}`]: {
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '25px',
                                                    width: '150px',
                                                    padding: '6px 14px',
                                                    "@media (max-width: 485px)": {
                                                        width: '100%'
                                                    }
                                                },
                                            }}
                                            minDate={new Date(props.filters.startDate)}
                                            maxDate={new Date()}
                                        // shouldDisableDate={(date) => {
                                        //     console.log(date, excludedDates)
                                        //     // Here, you'd implement logic to check if date is in excludedDates
                                        //     const excluded = excludedDates
                                        //     try {
                                        //         return excluded?.includes(format(date, 'yyy-MM-dd'));
                                        //     } catch (error) {

                                        //     }

                                        // }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </>
                        )}

                        <div className={styles.toolbar_item}>
                            <div>
                                Fulfillment type:
                            </div>
                            <Select
                                sx={{
                                    backgroundColor: '#F2F2F2',
                                    border: 0,
                                    borderRadius: 30,
                                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                                        border: 'none',
                                        outline: 'none',
                                    },

                                }}
                                value={props.fullFillment}
                                onChange={(event) => {
                                    props.setFullFillment(event.target.value)
                                }}
                                size="small"
                            >
                                <MenuItem
                                    value="none"
                                >
                                    All
                                </MenuItem>
                                <MenuItem
                                    value="pickup"
                                >
                                    Pickup
                                </MenuItem>
                                <MenuItem
                                    value="delivery"
                                >
                                    Delivery
                                </MenuItem>
                                <MenuItem
                                    value="shipping"
                                >
                                    Shipping
                                </MenuItem>
                            </Select>
                        </div>
                        <div className={styles.toolbar_item}>
                            <div>
                                Order status:
                            </div>
                            <Select
                                style={{
                                    backgroundColor: '#F2F2F2',
                                    border: 0,
                                    borderRadius: 30
                                }}
                                value={props.orderState}
                                onChange={(event) => {
                                    props.setOrderState(event.target.value)
                                }}
                                size="small"
                                sx={{
                                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                                        border: 'none',
                                        outline: 'none',
                                    }
                                }}
                            >
                                {orderSattus?.map((data) => (
                                    <MenuItem
                                        value={data.value}
                                    >
                                        {data.label}
                                    </MenuItem>
                                ))}

                            </Select>
                        </div>

                        {((props.fullFillment !== 'none') || (props.orderState !== 'none')) && (
                            <Button
                                variant="text"
                                onClick={() => {
                                    props.setFullFillment('none')
                                    props.setOrderState('none')
                                }}
                            >
                                Clear Filter
                            </Button>
                        )}
                        {isMobileScreen && <Divider sx={{ width: '100%', mb: 4 }} />}

                    </div>
                ))}
            </div>
        </Toolbar>
    );
}

export default EnhancedTableToolbar