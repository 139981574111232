import { Button, CircularProgress, Dialog, DialogContent, Drawer, IconButton, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios, { nodeAxios } from '../../utils/axiosConfig'
import { Close } from '@mui/icons-material'
import DeliveryOption from './DeliveryOption/DeliveryOption'
import { toast } from 'react-toastify'
import GenericLoadingButton from '../Library/GenericLoadingButton/GenericLoadingButton'
import useSendToPrinter from '../../hooks/useSendToPrinter'
const statusColor = {
    'o': '#FED813',
    'a': '#0060EF',
    'r': '#00C437',
    'can': '#E90000',
    'clo': '#00C437'
}

const DeliveryAcceptButton = ({ row, handleStatusChange, isDoorDashEnabled, user, isPrinterSaved, setRow }) => {

    const mobile = useMediaQuery('(max-width:1000px)');
    const { sendToPrinter } = useSendToPrinter({ user, isPrinterSaved })

    const [isDeliveryLoading, setIsDeliveryLoading] = useState(false)
    const [isShippingLoading, setIsShippingLoading] = useState(false)
    const [shippingPartners, setShippingPartners] = useState([])
    const [showDeliveryOption, setShowDeliveryOption] = useState(false)
    const [estimate, setEstimate] = useState(0)
    const [drivers, setDrivers] = useState([])
    const [isDoordashDisabled, setIsDoordashDisabled] = useState(false)
    const [isEstimateLoading, setisEstimateLoading] = useState(false)
    const [pickupTimeEstimated, setPickupTimeEstimated] = useState('')
    const [orderDetails, setOrderDetails] = useState({})

    const fetchDrivers = () => {
        axios.get('/user/list')
            .then((res) => {
                setDrivers(res.data?.data?.result)
                setShowDeliveryOption(true)
                setIsDoordashDisabled(false)
            })
    }


    const fetchEstimate = () => {
        setisEstimateLoading(true)

        axios.get(`/driver/get-order-detail/${row?.Id}`)
            .then((orderDetailsRes) => {
                setOrderDetails(orderDetailsRes.data?.data?.results)
                nodeAxios.get('/doordash/estimate', {
                    params: {
                        orderId: row?.Id,
                        pickupInstructions: '',
                        isAlcohol: false
                    }
                })
                    .then((res) => {
                        setPickupTimeEstimated(res?.data?.data?.pickup_time_estimated)
                        setEstimate(res?.data?.data?.fee / 100)
                        // console.log('aaaaaaaaaaaaa', parseFloat(row?.grand_total) > ((parseFloat(res?.data?.data?.res?.fee) / 100) + parseFloat(row.venuedrivertip) + 2), parseFloat(row?.grand_total), (parseFloat(res?.data?.data?.res?.fee) / 100))
                        if (parseFloat(row?.grand_total) > ((parseFloat(res?.data?.data?.fee) / 100) + parseFloat(row.venuedrivertip) + 2)) {
                            fetchDrivers()
                        } else {
                            setIsDoordashDisabled(true)
                            axios.get('/user/list')
                                .then((res) => {
                                    setDrivers(res.data?.data?.result)

                                    if (res.data?.data?.result?.length > 1) {
                                        setShowDeliveryOption(true)
                                    } else {
                                        setRow(row)
                                        handleStatusChange(row)
                                        sendToPrinter(row)
                                        // if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                        //     axios.get(`/driver/get-order-detail/${row.Id}`)
                                        //         .then((res) => {
                                        //             const resultsData = res.data?.data?.results;
                                        //             resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                        //             window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                        //             //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                        //         })

                                        // }
                                    }
                                })
                        }
                    })
                    .catch((err) => {
                        setOrderDetails({})
                        axios.get('/user/list')
                            .then((res) => {
                                setIsDoordashDisabled(true)
                                setDrivers(res.data?.data?.result)

                                if (res.data?.data?.result?.length > 1) {
                                    setShowDeliveryOption(true)
                                } else {
                                    setRow(row)
                                    handleStatusChange(row)
                                    sendToPrinter(row)
                                    // if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                    //     axios.get(`/driver/get-order-detail/${row.Id}`)
                                    //         .then((res) => {
                                    //             const resultsData = res.data?.data?.results;
                                    //             resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                    //             window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                    //             //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                    //         })

                                    // }
                                }
                            })

                        err.response?.data?.message?.field_errors?.map((data) => {
                            toast.error(data.error)
                        })
                        toast.error(err?.response?.data?.message?.message)
                        toast.error(err?.response?.data?.message)
                    })
                    .finally(() => {
                        setisEstimateLoading(false)
                    })
            })

    }

    return (
        <div>
            {((isDeliveryLoading || isShippingLoading) && row.status == 'o') ? (
                <CircularProgress size={22} />
            ) : (
                <>
                    <GenericLoadingButton
                        loading={isEstimateLoading}
                        loadingPosition="end"
                        variant="contained"
                        style={{
                            backgroundColor: statusColor[row.status] || '#00C437',
                            color: (row.status === 'o') ? 'black' : 'white'
                        }}
                        onClick={(event) => {
                            event.stopPropagation()

                            if ((row.status == 'o') && isDoorDashEnabled) {
                                fetchEstimate()
                            } else {
                                setIsDoordashDisabled(true)
                                axios.get('/user/list')
                                    .then((res) => {
                                        setDrivers(res.data?.data?.result)

                                        if (res.data?.data?.result?.length > 1) {
                                            setShowDeliveryOption(true)
                                        } else {
                                            setRow(row)
                                            handleStatusChange(row)
                                            sendToPrinter(row)
                                            // if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                            //     axios.get(`/driver/get-order-detail/${row.Id}`)
                                            //         .then((res) => {
                                            //             const resultsData = res.data?.data?.results;
                                            //             resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                            //             window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                            //             //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                            //         })

                                            // }
                                        }
                                    })
                            }
                        }}
                        disabled={isEstimateLoading}
                    >
                        {{
                            'o': 'Accept Order',
                            'a': 'Set to Ready',
                            'r': 'Close Order',
                            'can': 'Cancelled',
                            'clo': 'Closed'
                        }[row.status]}
                    </GenericLoadingButton>


                    {/* {((deliveryPartners.length && row.otype == 'delivery' && row.status == 'o') || (shippingPartners.length && row.otype == 'shipping' && row.status == 'o')) ? (
                        <GenericSelect
                            value={select}
                            onBlur={(event) => {
                                event.stopPropagation()
                            }}
                            onClose={(event) => {
                                event.stopPropagation()
                            }}
                            onChange={(event) => {
                                event.stopPropagation()

                                if (event.target.value == 'none') {
                                    setSelect(event.target.value)
                                }

                                if (event.target.value == 'yourself') {

                                    setRow(row)
                                    handleStatusChange(row)

                                    if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                        axios.get(`/driver/get-order-detail/${row.Id}`)
                                            .then((res) => {
                                                const resultsData = res.data?.data?.results;
                                                resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                            })

                                    }

                                    setSelect(event.target.value)
                                } else {
                                    let deliveryUrl;
                                    let shippingUrl;

                                    switch (event.target.value) {
                                        case 0:
                                            deliveryUrl = '/door-dash/create-delivery'
                                            break;
                                    }

                                    switch (event.target.value) {
                                        case 0:
                                            shippingUrl = '/shipping/create'
                                            break;
                                    }

                                    axios.post(row.otype == 'delivery' ? deliveryUrl : shippingUrl, {
                                        order_id: row.Id
                                    })
                                        .then((result) => {
                                            toast.success(row.otype == 'delivery' ? 'Delivery Created' : 'Shipping Created')
                                            setSelect(event.target.value)

                                            event.stopPropagation()
                                            setRow(row)
                                            handleStatusChange(row)

                                            if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                                axios.get(`/driver/get-order-detail/${row.Id}`)
                                                    .then((res) => {
                                                        const resultsData = res.data?.data?.results;
                                                        resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                        window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                        //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                                    })

                                            }
                                        }).catch((err) => {
                                            err.response?.data?.message?.field_errors?.map((data) => {
                                                toast.error(data.error)
                                            })
                                            toast.error(err.response?.data?.message?.message)
                                        });
                                }
                            }}
                        >
                            <MenuItem
                                value='none'
                                onClick={(event) => {
                                    event.stopPropagation()
                                }}
                            >
                                Select
                            </MenuItem>

                            <MenuItem
                                value='yourself'
                                onClick={(event) => {
                                    event.stopPropagation()
                                }}
                            >
                                Deliver Yourself
                            </MenuItem>

                            {deliveryPartners.map((data) => (
                                <MenuItem
                                    value={data.provider_id}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                    }}
                                >
                                    {data.provider_name}
                                </MenuItem>
                            ))}
                        </GenericSelect>
                    ) : (
                        <>
                            {((row.status === 'o') || (row.status === 'a') || (row.status === 'r')) && (
                                <div>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: statusColor[row.status] || '#00C437',
                                            color: (row.status === 'o') ? 'black' : 'white'
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            setRow(row)
                                            handleStatusChange(row)

                                            if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

                                                axios.get(`/driver/get-order-detail/${row.Id}`)
                                                    .then((res) => {
                                                        const resultsData = res.data?.data?.results;
                                                        resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                                                        window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                                                        //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                                                    })

                                            }
                                        }}
                                    >
                                        {{
                                            'o': 'Accept Order',
                                            'a': 'Set to Ready',
                                            'r': 'Close Order',
                                            'can': 'Cancelled',
                                            'clo': 'Closed'
                                        }[row.status]}
                                    </Button>
                                </div>
                            )}
                        </>
                    )} */}
                </>
            )
            }

            {mobile ? (
                <Drawer
                    PaperProps={{
                        style: {
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15
                        }
                    }}
                    anchor="bottom"
                    open={showDeliveryOption}
                    onClose={() => {
                        setShowDeliveryOption(false)
                    }}
                >
                    <DeliveryOption
                        orderDetails={orderDetails}
                        pickupTimeEstimated={pickupTimeEstimated}
                        isPrinterSaved={isPrinterSaved}
                        isDoordashDisabled={isDoordashDisabled}
                        user={user}
                        setRow={setRow}
                        row={row}
                        drivers={drivers}
                        isDoorDashEnabled={isDoorDashEnabled}
                        handleStatusChange={handleStatusChange}
                        estimate={estimate}
                        onClose={() => {
                            setShowDeliveryOption(false)
                        }}
                    />
                </Drawer>
            ) : (
                <Dialog
                    open={showDeliveryOption}
                    onClose={() => {
                        setShowDeliveryOption(false)
                    }}
                >
                    <DeliveryOption
                        orderDetails={orderDetails}
                        pickupTimeEstimated={pickupTimeEstimated}
                        isPrinterSaved={isPrinterSaved}
                        isDoordashDisabled={isDoordashDisabled}
                        user={user}
                        setRow={setRow}
                        row={row}
                        drivers={drivers}
                        isDoorDashEnabled={isDoorDashEnabled}
                        handleStatusChange={handleStatusChange}
                        estimate={estimate}
                        onClose={() => {
                            setShowDeliveryOption(false)
                        }}
                    />
                </Dialog>
            )}
        </div>
    )
}
export default DeliveryAcceptButton