import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FormsMobileItems.module.scss';
import { useSelector } from 'react-redux';
import imageBaseUrl from '../../../../utils/imageBaseUrl';
import Loader from '../../../Library/Loader';
import { Pagination } from '@mui/material';
import no_user_available from '../../../../assets/svgs/no_user_available.svg';


const FormsMobileItems = (props) => {
  const navigate = useNavigate();

  const getImageUrl = (image, name) => {
    return `${imageBaseUrl}/${name}/${image}`;
  };
  const getInitialsAvatar = (name) => {
    return `https://ui-avatars.com/api/?background=random&length=1&name=${name}`;
  };


  return (
    <div className={styles.list_grid_container}>
      <div className={styles.studentTableContainer}>
        {!props.listings ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : props.listings?.data?.length === 0 ? (
          <div className={styles.no_data_available}>
            <img
              priority
              src={no_user_available}
              height={100}
              width={100}
              alt='No user available'
            />
            No Forms Yet
          </div>
        ) : (
          <div className={styles.listing_container}>
            <div className={styles.listing_container_title}>
              Forms & Documents
            </div>
            <div className={styles.listing_container_body}>
              {props.listings?.data?.map((data) => (
                <div
                  className={styles.studentNameContainer}
                  onClick={() => navigate(`/forms/${data.id}`)}
                >
                  <div
                    // href={`/forms/${data.id}`}
                    className={styles.studentNameContent}
                  >
                    {data?.image ? (
                      <img
                        className={styles.studentImage}
                        src={getImageUrl(data?.image, 'Forms')}
                        onError={(e) => {
                          e.currentTarget.onerror = null;
                          e.currentTarget.src = getInitialsAvatar(data?.title);
                        }}
                        height={40}
                        width={40}
                      />
                    ) : (
                      <img
                        className={styles.tile_avatar}
                        src={getInitialsAvatar(data?.title)}
                        onError={(e) => {
                          e.currentTarget.onerror = null;
                          e.currentTarget.src = getInitialsAvatar(data?.title);
                        }}
                        height={1000}
                        width={1000}
                      />
                    )}
                    <div>
                      <span style={{ textDecoration: 'underline' }}>
                        {data.title && data.title !== 'null' ? data.title : ''}
                      </span>
                      <div
                        className={styles.room_title}
                        style={{ color: '#036EE4', fontSize: '13px' }}
                      >
                        (View Responses)
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {props.totalPages > 1 && (
        <Pagination
          classes={{
            root: styles.schoolPaginateRootEl,
            ul: styles.schoolPaginateListEl,
          }}
          count={props.totalPages}
          page={props.pageNo}
          onChange={(event, value) => {
            props.handleChangePage(value);
          }}
        />
      )}
    </div>
  );
};

export default FormsMobileItems;
