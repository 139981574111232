import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './AddNewProductDialog.module.scss'
import GenericTextFieldWithLabel from '../../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import { useFormik } from 'formik'
import { Autocomplete, Button, Checkbox, Chip, ClickAwayListener, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, ListItem, ListItemText, Menu, MenuItem, Popper, Radio, Select, TextField, Tooltip, inputBaseClasses, outlinedInputClasses, tooltipClasses, useMediaQuery } from '@mui/material'
import { AttachMoney, Cancel, ChevronRight, Close, Delete, Edit, EditOutlined, ExpandMore, HelpOutline, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import { getImage, getImage2 } from '../../../../../utils/helpers'
import GenericAutoCompleteWithLabel from '../../../../Library/GenericAutoCompleteWithLabel/GenericAutoCompleteWithLabel'
import axios, { nodeAxios } from '../../../../../utils/axiosConfig'
import GenericTextField from '../../../../Library/V2/GenericTextField/GenericTextField'
import GenericSelectWithLabel from '../../../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { toast } from 'react-toastify'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { debounce } from 'lodash'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import * as yup from 'yup';
import LightTooltip from '../../../../../util_comps/LightTooltip'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorder } from '../../../../CustomPageBuilder/EditSection/Util/helpers'
import ReOrderDialog from './ReOrderDialog/ReOrderDialog'
import Loader from '../../../../Library/Loader'
import { useConfirm } from 'material-ui-confirm'
import GenericSwitch from '../../../../Library/V2/GenericSwitch/GenericSwitch'
import { styled } from '@mui/styles'
import clsx from 'clsx'



const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        //   disableFocusListener
        //   disableHoverListener
        //   disableTouchListener
        // placement="left-end"
        PopperProps={{
            disablePortal: true,
        }}
        arrow
    //   leaveDelay={5000}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 360,
        fontSize: 14,
        border: 'none',
        borderRadius: 10,
        padding: '10px 15px',
        whiteSpace: 'break-spaces',
        //   wordBreak: 'break-word',
        //   textAlign: 'center',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000  ',
    },
}));


const getListStyle = (isDraggingOver, isMobile) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: '4px 0',
    maxHeight: !isMobile && 400,
    overflowY: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: '0 5px',
    margin: `0 0 8px 0`,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // width: 300,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--bg-10)',

    // styles we need to apply on draggables
    ...draggableStyle,
});


const validationSchema = yup.object({
    ProductName: yup
        .string()
        .required('This field is required'),
    category_id: yup
        .string()
        .required('This field is required'),
    inventories: yup.array()
        .of(
            yup.object().shape({
                Price: yup.string().required('This field is required'),
                min_quantity: yup.number()
                    .min(1, 'Minimum value must be 1 or greater than 1')
                    .integer('Minimum value must be an integer')
                    .nullable(true), // Allows min to be null or undefined
                max_quantity: yup.number()
                    .min(1, 'Minimum value must be 1 or greater than 1')
                    .integer('Maximum value must be an integer')
                    .nullable(true)
                    .test(
                        'is-greater',
                        'Maximum value must be greater than or equal to minimum value',
                        function (value) {
                            const { min_quantity } = this.parent;
                            if (!isNaN(parseInt(value)) && !isNaN(parseInt(min_quantity))) {
                                return value >= min_quantity;
                            } else {
                                return true
                            }
                        }
                    ), // Allows max to be null or undefined
                // format: yup.string().when([], {
                //     is: (inventories) => inventories && inventories.length > 1,
                //     then: yup.string().required('This field is required when there are more than one inventory'),
                //     otherwise: yup.string() // No requirement when there's only one inventory
                // })
            })
        )
});

const AddNewProductDialog = ({ modifiers: options, properties, ...props }) => {

    const imageRef = useRef()
    const confirm = useConfirm()
    const isMobile = useMediaQuery('(max-width:1000px)')
    const [formats, setFormats] = useState([])
    const [showModifierReorder, setShowModifierReorder] = useState(false)
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [openBrandOption, setOpenBrandOption] = useState(false)
    const [venues, setVenues] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedPropertiesOptions, setSelectedPropertiesOptions] = useState([]);
    // const [properties, setProperties] = useState([])
    // const [options, setOptions] = useState([])
    console.log('🚀OUTPUT --> options:', options);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCat, setSelectedCat] = useState(props.isEdit ? props?.editObj?.category_name : '')
    const [options2, setOptions2] = useState({})
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    // const fetchModifiers = () => {
    //     axios.get('/modifier')
    //         .then((res) => {
    //             console.log('🚀OUTPUT --> props.isEdit /modifier:', props.isEdit);
    //             const data = res.data?.data?.results?.map((data) => ({
    //                 ...data,
    //                 modifier_id: data?.id,
    //                 [props.isEdit ? 'modifier_options' :'options']: data.options?.map((data2) => ({ ...data2, is_visible: 1 }))
    //             }))
    //             setOptions(data)
    //         })
    // }

    // const fetchProperty = () => {
    //     axios.get('/product-property')
    //         .then((res) => {
    //             setProperties(res.data?.data?.results)
    //         })
    // }


    const fetchFormats = () => {
        axios.post('/product/formats')
            .then((res) => {
                setFormats(res.data?.data?.result)
            })
    }

    const fetchVenueCatMenu = () => {
        axios.get('/venue-category-order')
            .then((res) => {
                setVenueCategoryMenu(res.data?.data?.results)
            })
    }

    useEffect(() => {
        if (props.isEdit) {
            setSelectedCat(props?.editObj?.category_name)
        }
        props.fetchModifiers()

    }, [props.isEdit])
    useEffect(() => {
        fetchFormats()
        fetchVenueCatMenu()
        props.fetchProperty()
    }, [])

    const handleBrandChange = (e, val) => {
        try {
            axios.get(`/venue/list`, {
                params: {
                    q: val
                }
            })
                .then((res) => {
                    setVenues(res?.data?.data?.result)
                })
        } catch (err) {
            toast.error('There was an error while fetching inventories, try again later')
        }
    }

    const debounceBrandSearch = useCallback(debounce(handleBrandChange, 1000), [])

    const formik = useFormik({
        initialValues: props.isEdit ? props.editObj : {
            ProductName: '',
            ProducerName: '',
            image_file: '',
            category_id: '',
            image_link: '',
            inventories: [
                {
                    Price: '',
                    format: '',
                    track_quantity: 0,
                    stockqty: '',
                    sku: '',
                    min_quantity: '',
                    max_quantity: '',
                    modifier: []
                }
            ]
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsLoading(true)
            console.log(values)
            let hasError = false;

            if (values?.inventories?.length > 0) {
                values?.inventories?.every((element, index) => {
                    if (values?.inventories?.length > 1 && element?.format == '') {
                        toast.error("Since you've more than one format, you need to provide a name for each format")
                        setIsLoading(false)
                        hasError = true;
                        return false;

                    }

                    if (values?.inventories?.length > 1 && values?.inventories?.filter((data) => data.format == element.format).length > 1) {
                        toast.error('You can not assign same name for multiple formats')
                        setIsLoading(false)
                        hasError = true;
                        return false;
                    }

                    return true;
                })
            }

            if (hasError) {
                return;
            }

            const fd = new FormData()
            Object.entries(values).forEach(([key, value]) => {

                if (key == 'inventories') {

                    if (value?.length > 0) {
                        value.forEach((element, index) => {
                            console.log(element)
                            if (element.id) {
                                fd.append(`inventories[${index}][id]`, element.id);
                            }
                            fd.append(`inventories[${index}][Price]`, element.Price === null ? '' : element.Price);
                            fd.append(`inventories[${index}][track_quantity]`, element.track_quantity === null ? '' : element.track_quantity);
                            fd.append(`inventories[${index}][min_quantity]`, element.min_quantity === null ? '' : element.min_quantity);
                            fd.append(`inventories[${index}][max_quantity]`, element.max_quantity === null ? '' : element.max_quantity);
                            fd.append(`inventories[${index}][stockqty]`, element.stockqty === null ? '' : element.stockqty);
                            fd.append(`inventories[${index}][sku]`, element.sku === null ? '' : element.sku);
                            fd.append(`inventories[${index}][format]`, element.format === null ? '' : element.format);

                            if (element.modifier?.length > 0) {
                                element.modifier.forEach((element2, index2) => {
                                    fd.append(`inventories[${index}][product_modifiers][${index2}][modifier_id]`, element2.modifier_id);
                                    fd.append(`inventories[${index}][product_modifiers][${index2}][title]`, element2.title);
                                    fd.append(`inventories[${index}][product_modifiers][${index2}][min]`, Boolean(element2.min) ? element2.min : '');
                                    fd.append(`inventories[${index}][product_modifiers][${index2}][max]`, Boolean(element2.max) ? element2.max : '');
                                    fd.append(`inventories[${index}][product_modifiers][${index2}][is_price]`, element2.is_price);
                                    fd.append(`inventories[${index}][product_modifiers][${index2}][is_expandable]`, element2.is_expandable);
                                    fd.append(`inventories[${index}][product_modifiers][${index2}][price]`, Boolean(element2.price) ? (element2.price) : '');
                                    if (element2?.[props.isEdit ? 'modifier_options' : 'options']) {
                                        element2?.[props.isEdit ? 'modifier_options' : 'options'].forEach((element3, index3) => {
                                            // fd.append(`inventories[${index}][modifier][${index2}][options][${index3}][id]`, element3.id);
                                            fd.append(`inventories[${index}][product_modifiers][${index2}][product_modifier_options][${index3}][title]`, element3.title);
                                            fd.append(`inventories[${index}][product_modifiers][${index2}][product_modifier_options][${index3}][price]`, Boolean(element3.price) ? element3.price : '');
                                            fd.append(`inventories[${index}][product_modifiers][${index2}][product_modifier_options][${index3}][order_by_id]`, element3.order_by_id);
                                            fd.append(`inventories[${index}][product_modifiers][${index2}][product_modifier_options][${index3}][is_default]`, element3.is_default);
                                            fd.append(`inventories[${index}][product_modifiers][${index2}][product_modifier_options][${index3}][is_visible]`, element3.is_visible);
                                        });
                                    }
                                });
                            }
                            // else if (props.isEdit && !Boolean(element.modifier?.length)) {
                            //     fd.append(`inventories[${index}][remove_modifier]`, '1');
                            // }
                        });
                    }

                } else {
                    if (value && value !== 'null') {
                        fd.append(key, value)
                    }
                }
            })

            // if (selectedOptions?.length) {
            //     fd.append('modifier_id', selectedOptions?.map(data => data.id))
            // }
            if (selectedPropertiesOptions?.length) {
                fd.append('property_id', selectedPropertiesOptions?.map(data => data.id))
            }
            nodeAxios.post(props.isEdit ? `/products/edit/${props.editObj?.id}` : '/products/create', fd)
                .then((res) => {
                    toast.success(props.isEdit ? 'Product updated' : 'Product added')
                    props.onClose()

                    nodeAxios.post('/change-log/create', { what: `${values.ProductName} ${props.isEdit ? 'edited' : 'added'}` })
                        .catch((error) => {
                            console.log('🚀OUTPUT --> error:', error);
                        });
                })
                .catch((err) => {
                    toast.error(err.response?.data?.message)
                })
                .finally(() => {
                    setIsLoading(false)
                })

        }
    })

    console.log('🚀OUTPUT --> formik:', formik.values);

    const getTreeItem = (child) => {
        return child?.map((data) => (
            <TreeItem icon={(
                <Radio
                    checked={data.category_id == formik.values.category_id}
                    onChange={(event) => {
                        formik.setFieldValue('category_id', event.target.value)
                        setSelectedCat(data?.category_name)
                    }}
                    value={data.category_id}
                />
            )}
                nodeId={data.category_id}
                label={(
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8
                        }}
                    >
                        <div>
                            {data.category_name}
                        </div>

                        {Boolean(data?.child?.length) && (
                            <KeyboardArrowRight />
                        )}
                    </div>
                )}
            >
                {Boolean(data?.child?.length) && getTreeItem(data?.child)}
            </TreeItem>
        ))
    }

    useEffect(() => {
        if (props.editObj) {
            setSelectedOptions(props.editObj?.modifier?.map((data) => ({ id: data.modifier_id, title: data.modifier_title })))
            setSelectedPropertiesOptions(props.editObj?.properties?.map((data) => ({ id: data.id, title: data.title })))
        }
    }, [props.editObj])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            formik.values.inventories,
            result.source.index,
            result.destination.index
        );

        formik.setFieldValue('inventories', newitems)
    };

    const handleChangeVisibility = (data) => {

        if (!(options2[data.id]?.noWebSales != undefined ? options2[data.id]?.noWebSales : (data.noWebSales === 0))) {
            axios.get(`/product/visibility/${data.id}`, {
                params: {
                    product_id: data.ProductId
                }
            })
                .then((res) => {
                    toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
                    setOptions2((prevState) => ({
                        ...prevState,
                        [data.id]: {
                            ...prevState[data.id],
                            noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
                        }
                    }))
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message)
                })
        } else {

            confirm({
                title: 'Are you sure you want to disable this product',
            })
                .then(() => {
                    axios.get(`/product/visibility/${data.id}`, {
                        params: {
                            product_id: data.ProductId
                        }
                    })
                        .then((res) => {
                            toast.warn(res?.data?.data?.result?.noWebSales === 0 ? "Inventory Item Won't Be Visible From Site & App" : 'Inventory Item Will Be Visible From Site & App')
                            setOptions2((prevState) => ({
                                ...prevState,
                                [data.id]: {
                                    ...prevState[data.id],
                                    noWebSales: (prevState[data.id]?.noWebSales != undefined) ? (prevState[data.id]?.noWebSales ? 0 : 1) : (data.noWebSales ? 1 : 0)
                                }
                            }))
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.message)
                        })

                })
                .catch(() => {

                })

        }
    };


    return (
        <>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: '#000'
                    }}
                >
                    <Cancel />
                </IconButton>
            </DialogTitle>
            <DialogContent className='!tw-px-4 sm:!tw-px-5'>
                <input
                    style={{
                        display: 'none'
                    }}
                    ref={imageRef}
                    type="file"
                    onChange={(event) => {
                        formik.setFieldValue('image_file', event.target.files[0])
                        formik.setFieldValue('image_link', URL.createObjectURL(event.target.files[0]))
                        formik.setFieldValue('image_file_remove', 0)
                        event.target.value = ''
                    }}
                />
                {props.isEdit2Loading ? (
                    <Loader />
                ) : (
                    <div
                        className={styles.form_container}
                    >
                        <div
                            className={styles.title_container}
                        >
                            <div
                                style={{
                                    cursor: 'pointer',
                                    position: 'relative'
                                }}
                                onClick={() => {
                                    imageRef.current?.click()
                                }}
                            >
                                <img
                                    className='tw-min-w-36 tw-max-w-36 tw-max-h-36 tw-min-h-36 tw-w-full tw-h-full tw-object-cover'
                                    src={formik.values.image_link || formik.values.image || getImage2('addimage.png-1699973235.png')}
                                />

                                {(Boolean(formik.values.image_file) || Boolean(formik.values.image)) && (
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            padding: 2
                                        }}
                                        onClick={(event) => {
                                            formik.setFieldValue('image_file_remove', 1)
                                            formik.setFieldValue('image_file', '')
                                            formik.setFieldValue('image_link', '')
                                            formik.setFieldValue('image', '')
                                            event.stopPropagation()
                                        }}
                                    >
                                        <Delete
                                            style={{
                                                position: 'absolute',
                                                backgroundColor: 'white',
                                                padding: 2,
                                                left: 2,
                                                bottom: 6
                                            }}
                                        />
                                    </div>
                                )}

                                <EditOutlined
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                        padding: 2,
                                        right: 4,
                                        bottom: 6,
                                        fontSize: 28
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <div className={styles.title}>
                                    {props.isEdit ? "Edit Item" : "Add New Item"}
                                    {props.isEdit &&
                                        <div className="tw-flex tw-gap-2 sm:tw-gap-4 tw-text-sm sm:tw-items-end tw-flex-col sm:tw-flex-row">
                                            <div
                                                style={{
                                                    fontWeight: 600,
                                                    marginTop: 5
                                                }}
                                            >
                                                Item visibility
                                            </div>
                                            <GenericSwitch
                                                background="#0AB221"
                                                width={35}
                                                height={18}
                                                translateX={16}
                                                thumb={13}
                                                isStatus={true}
                                                checked={(options2[props.editObj?.id]?.noWebSales != undefined ? options2[props.editObj?.id]?.noWebSales : (props.editObj?.noWebSales === 0))}
                                                // onText="''"
                                                // offText="''"
                                                onChange={(event) => {
                                                    handleChangeVisibility(props.editObj)
                                                }}
                                            />
                                        </div>}
                                    {props.isEdit && (
                                        <Button
                                            color='error'
                                            variant="text"
                                            startIcon={<Delete />}
                                            onClick={() => {
                                                props.handleDelete(props?.editObj?.id)
                                                props.onClose()
                                            }}
                                        >
                                            Remove Item
                                        </Button>
                                    )}
                                </div>

                            </div>
                        </div>

                        <div className='tw-flex tw-gap-[10px] tw-items-end tw-flex-col sm:tw-flex-row'>
                            <GenericTextFieldWithLabel
                                containerStyle={{
                                    width: '100%'
                                }}
                                id="ProductName"
                                name="ProductName"
                                label="Item name"
                                placeholder="Enter item name"
                                value={formik.values.ProductName}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                required={true}
                                error={formik.touched.ProductName && Boolean(formik.errors.ProductName)}
                                helperText={formik.touched.ProductName && formik.errors.ProductName}
                            />

                            <GenericAutoCompleteWithLabel
                                label="Item Brand"
                                containerStyle={{
                                    width: '100%'
                                }}
                                options={venues || []}
                                clearOnBlur={false}
                                onBlur={() => setOpenBrandOption(false)}
                                open={openBrandOption}
                                filterOptions={(options) => options}
                                getOptionLabel={(option) => option.Name}
                                freeSolo={true}
                                onChange={(event, val) => {
                                    setOpenBrandOption(false)
                                    formik.setFieldValue('ProducerName', val.Name)
                                    formik.setFieldValue('venue_id', val.VenueId)
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason === 'input') {
                                        if (!(newInputValue?.length < 3)) {
                                            setOpenBrandOption(true)
                                        } else {
                                            setOpenBrandOption(false)
                                        }
                                        formik.setFieldValue('ProducerName', newInputValue)
                                        formik.setFieldValue('venue_id', '')

                                        debounceBrandSearch(event, newInputValue)
                                    }
                                }}
                                inputValue={formik.values.ProducerName || ''}
                                getOptionSelected={() => true}
                                renderInput={(params) => (
                                    <GenericTextField
                                        {...params}
                                        variant="outlined"
                                        style={{
                                            backgroundColor: 'white'
                                        }}
                                        placeholder="Search or enter item name"
                                        error={formik.touched.ProducerName && Boolean(formik.errors.ProducerName)}
                                        helperText={formik.touched.ProducerName && formik.errors.ProducerName}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                                root: styles.autocomplete_input
                                            },
                                            endAdornment: null
                                        }}
                                        fullWidth={true}
                                    />
                                )}
                            />
                        </div>

                        <div className='tw-flex tw-gap-[10px] tw-items-start tw-flex-col sm:tw-flex-row'>
                            <div className='tw-w-full sm:tw-w-1/2'>
                                <div className='tw-font-bold'>
                                    Category
                                    {Boolean(formik.values.category_name) && (
                                        <span style={{ fontSize: 10 }}>({formik.values.category_name})</span>
                                    )}
                                    <sup className='tw-text-red-600 tw-text-[100%]'>*</sup>
                                </div>

                                {(formik.touched.category_id && Boolean(formik.errors.category_id)) && (
                                    <small>
                                        {formik.touched.category_id && formik.errors.category_id}
                                    </small>
                                )}

                                <div
                                    style={{
                                        width: '100%',
                                        overflow: 'auto',
                                        padding: 5,
                                        outline: (formik.touched.category_id && Boolean(formik.errors.category_id)) ? '1px solid red' : '1px solid #d3d3d3',
                                        borderRadius: 2
                                    }}
                                >
                                    <Button
                                        // style={{
                                        //     color: '#d3d3d3'
                                        // }}
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        fullWidth={true}
                                        endIcon={<KeyboardArrowDown />}
                                        className='!tw-justify-between'
                                    >
                                        {selectedCat ? selectedCat : "Select Category"}

                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        slotProps={{
                                            paper: {
                                                elevation: 0,
                                                sx: {
                                                    filter: 'none',
                                                    border: '1px solid #D3D3D3',
                                                    height: 250
                                                },
                                            }
                                        }}
                                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                    >
                                        <TreeView
                                            defaultCollapseIcon={<ExpandMore />}
                                            defaultExpandIcon={<ChevronRight />}
                                        // expanded={props.isEdit}
                                        >
                                            {venueCategoryMenu?.map((data) => (
                                                <TreeItem
                                                    icon={(
                                                        <Radio
                                                            checked={data.category_id == formik.values.category_id}
                                                            onChange={(event) => {
                                                                formik.setFieldValue('category_id', event.target.value)
                                                                setSelectedCat(data?.category_name)
                                                            }}
                                                            value={data.category_id}
                                                        />
                                                    )}
                                                    nodeId={data.category_id}
                                                    label={(
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 8
                                                            }}
                                                        >
                                                            <div>
                                                                {data.category_name}
                                                            </div>

                                                            {Boolean(data?.child?.length) && (
                                                                <KeyboardArrowRight />
                                                            )}
                                                        </div>
                                                    )}
                                                >
                                                    {getTreeItem(data?.child)}
                                                </TreeItem>
                                            ))}
                                        </TreeView>
                                    </Menu>
                                </div>
                            </div>
                            <div className='tw-w-full sm:tw-w-1/2'>
                                <div className={clsx(styles.label_text, 'tw-flex tw-gap-2')}>
                                    Assign Properties

                                    <HtmlTooltip title={'Assigning attributes to items streamlines product information, facilitating personalized customer choices, and accommodating diverse preferences, ultimately enhancing user experience and satisfaction.'}>
                                        <HelpOutline />
                                    </HtmlTooltip>
                                </div>
                                <Autocomplete
                                    multiple={true}
                                    options={properties || [{ index: 0 }]}
                                    value={selectedPropertiesOptions || []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(event, newValue, reason) => {
                                        setSelectedPropertiesOptions(newValue);
                                    }}
                                    renderTags={(value, getTagProps) => {
                                        return value.map((option, index) => (
                                            <Chip label={option?.title} {...getTagProps({ index })} />
                                        ))
                                    }}
                                    renderInput={(params) => (
                                        <GenericTextField
                                            {...params}
                                            placeholder="Select Properties"
                                            sx={{
                                                [`& .${outlinedInputClasses.root}`]: {
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px'
                                                }
                                            }}
                                        />
                                    )}

                                    renderOption={(props2, option, state) => {
                                        return (
                                            <>
                                                {state.index == 0 && (
                                                    <Button
                                                        variant="text"
                                                        onClick={props.onCreateProperty}
                                                        fullWidth={true}
                                                    >
                                                        Create new property
                                                    </Button>
                                                )}

                                                <ListItem {...props2} key={option}>
                                                    <Checkbox
                                                        checked={props2['aria-selected']}
                                                    />
                                                    <ListItemText primary={option.title} />
                                                </ListItem>
                                            </>
                                        )
                                    }}
                                />
                            </div>
                        </div>


                        <div>
                            <GenericTextFieldWithLabel
                                id="description"
                                name="description"
                                label="Item Description"
                                placeholder="Provide a clear and concise description of the product, highlighting its key features and benefits."
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                multiline={true}
                                rows={3}
                                sx={{
                                    [`& .${inputBaseClasses.root}`]: {
                                        padding: 0,
                                        // height: 33
                                    },
                                }}
                            />

                        </div>

                        <div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='droppable'>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={styles.scroll}
                                            style={getListStyle(snapshot.isDraggingOver, isMobile)}
                                        >
                                            {formik.values.inventories?.map((item, index) => (
                                                <InventoryItem key={index.toString()} {...{ index, getItemStyle, item, formik, setShowModifierReorder, options, formats, isMobile, ...props }} />
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>

                        <Button
                            style={{
                                borderColor: '#D3D3D3',
                                color: 'black'
                            }}
                            variant="outlined"
                            onClick={() => {
                                let allOptions = formik.values.inventories

                                if (Array.isArray(allOptions)) {
                                    allOptions.push({
                                        Price: '',
                                        track_quantity: 0,
                                        stockqty: '',
                                        sku: '',
                                        min_quantity: '',
                                        max_quantity: '',
                                        format: ''
                                    })
                                } else {
                                    allOptions = [{
                                        Price: '',
                                        track_quantity: 0,
                                        stockqty: '',
                                        sku: '',
                                        min_quantity: '',
                                        max_quantity: '',
                                        format: ''
                                    }]
                                }
                                formik.setFieldValue('inventories', allOptions)
                            }}
                        >
                            Add new format
                        </Button>
                    </div>
                )}
            </DialogContent>

            <div className='tw-flex tw-justify-between tw-p-4'>
                {/* {props.isEdit && (
                    <div className='tw-min-w-[200px]'>
                        <Button
                            style={{
                                color: 'red'
                            }}
                            variant="text"
                            onClick={() => {
                                props.handleDelete(props?.editObj?.id)
                                props.onClose()
                            }}
                        >
                            Remove Product
                        </Button>
                    </div>
                )} */}

                <div className='tw-flex tw-gap-4 tw-w-full tw-justify-end'>
                    <Button
                        style={{
                            backgroundColor: '#d3d3d3',
                            color: 'black'
                        }}
                        className="!tw-w-full lg:!tw-w-auto !tw-rounded-3xl"
                        variant="text"
                        onClick={() => {
                            props.onClose()
                        }}
                    >
                        Cancel
                    </Button>

                    <GenericLoadingButton
                        variant="contained"
                        loading={isLoading}
                        loadingPosition="end"
                        type="submit"
                        className="!tw-w-full lg:!tw-w-auto !tw-rounded-3xl"
                        onClick={formik.handleSubmit}
                    >
                        Save product
                    </GenericLoadingButton>
                </div>
            </div>

            <Dialog
                open={showModifierReorder}
                onClose={() => {
                    setShowModifierReorder(false)
                }}
            >
                <ReOrderDialog
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    onClose={() => setShowModifierReorder(false)}
                />
            </Dialog>
        </>
    )
}

export default AddNewProductDialog;

const InventoryItem = ({ index, getItemStyle, item, formik, setShowModifierReorder, options, formats, isMobile, ...props }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const confirm = useConfirm()
    const handleCloseCustomization = () => {
        // setValue(pendingValue);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Draggable
            key={index.toString()}
            draggableId={index.toString()}
            index={index}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={() => {

                    }}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                    )}
                >
                    <div className='tw-flex tw-flex-col tw-w-full tw-rounded-md tw-border tw-border-gray tw-p-4 tw-relative'>
                        <div className="">
                            <div className="tw-flex tw-gap-4">
                                <GenericTextFieldWithLabel
                                    id={`inventories[${index}].Price`}
                                    name={`inventories[${index}].Price`}
                                    type="number"
                                    label="Pricing"
                                    // fullWidth={true}
                                    placeholder="Item price"
                                    containerClassname="tw-w-[50%]"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                style={{
                                                    backgroundColor: '#D9D9D9',
                                                    height: '100%',
                                                    paddingLeft: 13,
                                                    paddingRight: 13,
                                                    maxHeight: 'unset',
                                                    borderTopLeftRadius: 5,
                                                    borderBottomLeftRadius: 5
                                                }}
                                                position='start'
                                            >
                                                <AttachMoney style={{ height: 42 }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        [`.${outlinedInputClasses.root}`]: {
                                            paddingLeft: 0,
                                            // height: 33
                                        },
                                        [`.${outlinedInputClasses.input}`]: {
                                            paddingLeft: 0,
                                        },
                                        width: '100%'
                                    }}
                                    value={item.Price}
                                    onChange={formik.handleChange}
                                    required={true}
                                    error={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.Price && Boolean(formik.errors.inventories[index]?.Price)}
                                    helperText={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.Price && formik.errors.inventories[index]?.Price}
                                />
                                <GenericAutoCompleteWithLabel
                                    label={`Format ${(formik.values.inventories?.length === 1) ? '(if any)' : ''}`}
                                    containerStyle={{
                                        width: '50%'
                                    }}
                                    options={formats || []}
                                    clearOnBlur={false}
                                    getOptionLabel={(option) => option.format}
                                    freeSolo={true}
                                    required={formik.values.inventories?.length > 1}
                                    inputValue={((item.format == 'Esplanda') ? '' : item.format) || ''}
                                    onChange={(e, newVal) => {
                                        formik.setFieldValue(`inventories[${index}].format`, newVal?.format)
                                    }}
                                    onInputChange={(e, newVal, reason) => {
                                        if (reason === 'input') {
                                            formik.setFieldValue(`inventories[${index}].format`, newVal)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <GenericTextField
                                            {...params}
                                            placeholder="Select or enter format"
                                            error={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.values.inventories?.length > 1}
                                            helperText={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.values.inventories?.length > 1}
                                            variant="outlined"
                                            style={{
                                                backgroundColor: 'white'
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                classes: {
                                                    root: styles.autocomplete_input
                                                },
                                                // endAdornment: (
                                                //     <React.Fragment>
                                                //         {
                                                //             params.InputProps
                                                //                 .endAdornment
                                                //         }
                                                //     </React.Fragment>
                                                // )
                                            }}
                                            fullWidth={true}
                                        />
                                    )}
                                />
                            </div>
                            {/* <div>
                                <Button
                                    variant='text'
                                    color='primary'
                                    onClick={() => {
                                        setIsOpen(!isOpen)
                                    }}
                                >
                                    {isOpen ? 'Hide' : 'See'} Other Options {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </Button>
                            </div> */}
                        </div>
                        <Collapse in={isOpen} classes={{ wrapperInner: 'tw-flex tw-flex-col tw-gap-4' }}>
                            <div className={styles.two_items}>
                                <GenericTextFieldWithLabel
                                    containerStyle={{
                                        width: '100%'
                                    }}
                                    id={`inventories[${index}].sku`}
                                    name={`inventories[${index}].sku`}
                                    label="SKU"
                                    placeholder="Barcode #"
                                    value={item.sku}
                                    onChange={formik.handleChange}
                                    fullWidth={true}
                                />
                                <div className='tw-w-full'>
                                    <FormControlLabel
                                        sx={{
                                            width: '100%', m: 0
                                        }}
                                        control={
                                            <Checkbox
                                                checked={Boolean(parseInt(item.track_quantity))}
                                                onChange={(event) => {
                                                    formik.setFieldValue(`inventories[${index}].track_quantity`, event.target.checked ? 1 : 0)

                                                    if (!event.target.checked) {
                                                        formik.setFieldValue(`inventories[${index}].stockqty`, '')
                                                    }
                                                }}
                                            />
                                        }
                                        label={(
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 10,
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}
                                            >
                                                <div className='sm:tw-whitespace-nowrap' >
                                                    Track quantity
                                                </div>

                                                <div className='tw-hidden sm:tw-block'>
                                                    <GenericTextFieldWithLabel
                                                        containerStyle={{
                                                            width: '100%'
                                                        }}
                                                        id={`inventories[${index}].stockqty`}
                                                        name={`inventories[${index}].stockqty`}
                                                        placeholder={isMobile ? 'Qty' : "Enter Quantity"}
                                                        type="number"
                                                        disabled={!Boolean(parseInt(item.track_quantity))}
                                                        value={item.stockqty}
                                                        onChange={formik.handleChange}
                                                        fullWidth={true}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />

                                    <div className='sm:tw-hidden'>
                                        <GenericTextFieldWithLabel
                                            containerStyle={{
                                                width: '100%'
                                            }}
                                            id={`inventories[${index}].stockqty`}
                                            name={`inventories[${index}].stockqty`}
                                            placeholder="Enter Quantity"
                                            type="number"
                                            disabled={!Boolean(parseInt(item.track_quantity))}
                                            value={item.stockqty}
                                            onChange={formik.handleChange}
                                            fullWidth={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    style={{
                                        fontWeight: 600
                                    }}
                                >
                                    Allowable Qty for Purchase
                                </div>
                                <div className={styles.two_items}>
                                    <GenericTextFieldWithLabel
                                        label="Min"
                                        containerStyle={{
                                            width: '100%'
                                        }}
                                        id={`inventories[${index}].min_quantity`}
                                        name={`inventories[${index}].min_quantity`}
                                        placeholder="Min"
                                        type="number"
                                        value={item.min_quantity}
                                        onChange={formik.handleChange}
                                        fullWidth={true}
                                        error={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.min_quantity && Boolean(formik.errors.inventories[index]?.min_quantity)}
                                        helperText={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.min_quantity && formik.errors.inventories[index]?.min_quantity}
                                    />

                                    <GenericTextFieldWithLabel
                                        label="Max"
                                        containerStyle={{
                                            width: '100%'
                                        }}
                                        id={`inventories[${index}].max_quantity`}
                                        name={`inventories[${index}].max_quantity`}
                                        placeholder="Max"
                                        type="number"
                                        value={item.max_quantity}
                                        onChange={formik.handleChange}
                                        fullWidth={true}
                                        error={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.max_quantity && Boolean(formik.errors.inventories[index]?.max_quantity)}
                                        helperText={Boolean(formik?.touched?.inventories) && Boolean(formik?.errors?.inventories) && Array.isArray(formik.errors.inventories) && formik.touched.inventories[index]?.max_quantity && formik.errors.inventories[index]?.max_quantity}
                                    />

                                </div>
                            </div>


                            <div>
                                <div className={clsx(styles.label_text, 'tw-flex tw-gap-2')}>
                                    Assign Customization

                                    <HtmlTooltip title={'Customization let customers customize their orders, choosing options like ingredient substitutions, portion sizes, and toppings for a personalized dining experience.'}>
                                        <HelpOutline />
                                    </HtmlTooltip>
                                </div>
                                {/* <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                    className={styles.label_text}
                                >
                                    <div>
                                    Assign modifiers <span>
                                            <LightTooltip
                                                title={<span style={{ display: 'inline-block', padding: '0 15px' }}
                                                >
                                                    Customization let customers customize their orders, choosing options like ingredient substitutions, portion sizes, and toppings for a personalized dining experience.
                                                </span>
                                                } arrow placement="top"><HelpOutline /></LightTooltip></span>
                                    </div>
                                </div> */}
                                {/* <Button disableRipple aria-describedby={item.id} fullWidth onClick={(e) => setAnchorEl(e.currentTarget)} >
                                    + Add Customizations
                                </Button> */}
                                {/* <Popper id={item.id} open={open} anchorEl={anchorEl} placement="bottom-start"
                                    sx={{ width: 300 }}
                                >
                                    <ClickAwayListener onClickAway={handleCloseCustomization}> */}
                                <Autocomplete
                                    // open
                                    multiple={true}
                                    // onClose={(event, reason) => {
                                    //     if (reason === 'escape') {
                                    //         handleCloseCustomization();
                                    //     }
                                    // }}
                                    options={options || []}
                                    value={formik.values.inventories[index].modifier || []}
                                    // disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.modifier_id === value.modifier_id}
                                    onChange={(event, newValue, reason) => {
                                        console.log('🚀OUTPUT --> :', { event, newValue, reason });
                                        // setSelectedOptions(newValue);
                                        formik.setFieldValue(`inventories[${index}].modifier`, newValue)
                                    }}
                                    renderTags={(value, getTagProps) => {
                                        return value.map((option, index) => (
                                            <Chip label={option?.title} {...getTagProps({ index })} />
                                        ))
                                    }}
                                    renderOption={(props2, option, state) => {
                                        console.log(option, props2, props2?.key?.id)
                                        return (
                                            <>
                                                {state.index == 0 && (
                                                    <Button
                                                        variant="text"
                                                        onClick={props.onCreateModifier}
                                                        fullWidth={true}
                                                    >
                                                        Create new customization
                                                    </Button>
                                                )}

                                                <ListItem {...props2} key={option}>
                                                    <Checkbox
                                                        checked={props2['aria-selected']}
                                                    />
                                                    <ListItemText primary={option.title} />
                                                </ListItem>
                                            </>
                                        )
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            ref={params.InputProps.ref}
                                            inputProps={params.inputProps}
                                            // autoFocus
                                            placeholder="Select Customization"
                                        />
                                    )}
                                />
                                {/* </ClickAwayListener>
                                </Popper> */}
                                <ReOrderDialog
                                    selectedOptions={formik.values.inventories[index].modifier || []}
                                    isEdit={props.isEdit}
                                    // setSelectedOptions={setSelectedOptions}
                                    onSave={(data) => {
                                        formik.setFieldValue(`inventories[${index}].modifier`, data)
                                    }}
                                    onEditModifier={(mod_index, data) => {
                                        formik.setFieldValue(`inventories[${index}].modifier[${mod_index}]`, data)
                                    }}
                                    onClose={() => setShowModifierReorder(false)}
                                />
                            </div>
                        </Collapse>
                        <div className='tw-flex tw-items-center tw-justify-between tw-mt-1'>
                            <Button
                                variant='text'
                                color='primary'
                                onClick={() => {
                                    setIsOpen(!isOpen)
                                }}
                            >
                                {isOpen ? 'Hide' : 'See'} Other Options {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Button>
                            {formik.values.inventories?.length > 1 &&
                                <Button
                                    style={{
                                        color: 'red', gap: '10px'
                                    }}
                                    variant="text"
                                    onClick={() => {
                                        let allOptions = formik.values.inventories
                                        allOptions.splice(index, 1)
                                        formik.setFieldValue('inventories', allOptions)

                                        if (item.id) {
                                            confirm({
                                                title: 'Are you sure you want to remove this format?',
                                            })
                                                .then(() => {
                                                    axios.delete(`/venue-product/delete/${item.id}`)
                                                        .then(() => {
                                                            nodeAxios.post('/change-log/create', { what: `${formik.values.ProductName} ${item.format} format deleted` })
                                                                .then(() => {

                                                                })
                                                                .catch((error) => {
                                                                    console.log('🚀OUTPUT --> error:', error);
                                                                });
                                                        })
                                                })
                                        }

                                    }}
                                >
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.70453 15.1139C2.25633 15.1139 1.8725 14.9542 1.55305 14.6347C1.2336 14.3153 1.07414 13.9317 1.07469 13.4841V2.89008H0.259766V1.26023H4.33438V0.445312H9.22391V1.26023H13.2985V2.89008H12.4836V13.4841C12.4836 13.9323 12.3239 14.3161 12.0044 14.6356C11.685 14.955 11.3014 15.1145 10.8538 15.1139H2.70453ZM10.8538 2.89008H2.70453V13.4841H10.8538V2.89008ZM4.33438 11.8542H5.96422V4.51992H4.33438V11.8542ZM7.59407 11.8542H9.22391V4.51992H7.59407V11.8542Z" fill="#E00000" />
                                    </svg>
                                    Remove Format
                                </Button>
                            }
                        </div>

                    </div>
                </div>
            )}
        </Draggable>
    )
}