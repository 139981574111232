import React from 'react'
import ClaimMyBusinessForm from '../ClaimMyBusiness/ClaimMyBusinessForm/ClaimMyBusinessForm'
import styles from './ManageListing.module.scss'

const ManageListing = (props) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <div
                className={styles.title_text}
            >
                Manage Listing
            </div>
            <ClaimMyBusinessForm
                showVenueHours={true}
                formBoxClassName={styles.form_box}
                isManage={true}
                details={props.details}
                id={props.id}
            />
        </div>
    )
}

export default ManageListing