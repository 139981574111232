import { Breadcrumbs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useOutletContext, useParams, useSearchParams } from 'react-router-dom'
import BankingInfo from '../BankingInfo/BankingInfo'
import BusinessInfo from '../BusinessInfo/BusinessInfo'
import Integrations from '../Integrations/Integrations'
import TabsSection from '../Library/TabsSection/TabsSection'
import MultiStoreManagement from '../MultiStoreManagement/MultiStoreManagement'
import OrderSettings from '../OrderSettings/OrderSettings'
import StoreInfo from '../StoreInfo/StoreInfo'
import Users from '../Users/Users'
import styles from './MyStoreSettingsNew.module.scss'
import axios, { nodeAxios } from '../../utils/axiosConfig'
import { toast } from 'react-toastify'

const tabs = [
    {
        label: 'Store Info'
    },
    {
        label: 'Business Info'
    },
    {
        label: 'Banking Info'
    },
    {
        label: 'Users'
    },
    {
        label: 'Integrations'
    },
    {
        label: 'Order Settings'
    },
    {
        label: 'Multi Store Management'
    }
]


const MyStoreSettingsNew = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const { fetchStoreInfo } = useOutletContext()

    // console.log(searchParams.get('token'))
    const token = searchParams.get('token')
    const authCode = searchParams.get('code')
    const merchantId = searchParams.get('merchant_id')
    const from = searchParams.get('from')
    const [isColverSaved, setIsColverSaved] = useState(false)
    console.log('from', from)

    const fetchPaymentCycle = () => {
        axios
            .post('/stripe/auth-connect', {
                code: authCode
            })
            .then((res) => {
                toast.success('Your account is successfully connected.');
                // if (res.data?.data?.charges_enabled) {
                //     //   setIsStripeSuccess(true);

                // } else {
                //     //   setIsStripeSuccess(false);
                //     toast.error(
                //         'There was a problem connecting your stripe account, please try again'
                //     );
                // }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {
                setSearchParams({})
                fetchStoreInfo()
            });
    };

    const handleCloverData = () => {
        nodeAxios
            .post('/clover/credentials', {
                code: authCode,
                merchant_id: merchantId
            })
            .then((res) => {
                setIsColverSaved(true)
                toast.success('Your account is successfully connected.');
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {
                setSearchParams({})
            });
    };

    useEffect(() => {
        if (from == 'stripe') {
            fetchPaymentCycle();
        }
        if ((from != null) && merchantId && authCode) {
            handleCloverData()
        }
    }, [searchParams]);

    return (
        <div>
            <Breadcrumbs
                style={{ fontSize: 13, marginBottom: "20px" }}
                aria-label="breadcrumb"
            >
                <Link
                    style={{
                        color: 'black'
                    }}
                    to="/dashboard"
                >
                    Home
                </Link>
                <Typography
                    style={{
                        borderBottom: "1px solid grey",
                        color: 'black'
                    }}
                >
                    My Store Setting
                </Typography>
            </Breadcrumbs>

            <TabsSection
                tabs={tabs}
                defaultTab={(from == 'stripe') ? 2 : (((from != null) && merchantId && authCode) ? 5 : (token && 6))}
                tabPanelMinWidth="fit-content"
                scrollButtons="auto"
                variant="scrollable"
                tabsBody={[
                    {
                        component: <StoreInfo />
                    },
                    {
                        component: <BusinessInfo />
                    },
                    {
                        component: <BankingInfo />
                    },
                    {
                        component: <Users />
                    },
                    {
                        component: <Integrations />
                    },
                    {
                        component: <OrderSettings isColverSaved={isColverSaved} isFromClover={((from != null) && merchantId && authCode)} />
                    },
                    {
                        component: <MultiStoreManagement token={token} />
                    },
                ]}
            />

        </div>
    )
}

export default MyStoreSettingsNew