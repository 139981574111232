import { format } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import Loader from '../../Library/Loader'
import MarketingInfoChip from '../../Library/MarketingInfoChip/MarketingInfoChip'
import styles from './EmailDetail.module.scss'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, MenuItem } from '@mui/material'
import { Close, Search, Visibility } from '@mui/icons-material'
import GenericButton from '../../Library/GenericButton/GenericButton'
import InputField from '../../Library/InputField/InputField'
import { debounce } from 'lodash'
import axios from '../../../utils/axiosConfig'
import AudienceChip from '../LaunchCampaign/AudienceChip/AudienceChip'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { toast } from 'react-toastify'
import NameDialog from '../../Dialogs/NameDialog/NameDialog'
import AddRecipientDialog from '../../Dialogs/AddRecipientDialog/AddRecipientDialog'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import GenericTextField from '../../Library/GenericTextField/GenericTextField'
import SendTestEmailDialog from '../../Dialogs/SendTestEmailDialog/SendTestEmailDialog'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'

const EmailDetail = (props) => {

    const [showPreview, setShowPreview] = useState(false)
    const [audienceQuery, setAudienceQuery] = useState('')
    const [searchIsLoading, setSearchIsLoading] = useState(false)
    const [audienceList, setAudienceList] = useState([])
    const [showNewListPopup, setShowNewListPopup] = useState(false)
    const [newListName, setNewListName] = useState('')
    const [showAddRecipient, setShowAddRecipient] = useState(false)
    const [editId, setEditId] = useState('')
    const [isToEditing, setIsToEditing] = useState(false)
    const [isSubjectEditing, setIsSubjectEditing] = useState(false)
    const [showSendTestEmail, setShowSendTestEmail] = useState(false)
    const [isScheduleOn, setIsScheduleOn] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const [isSendLoading, setIsSendLoading] = useState(false)

    const fetchAudience = (query) => {
        setSearchIsLoading(true)
        axios.get('/mailchimp-campaign/get-audience-list', {
            params: {
                q: query
            }
        })
            .then((response) => {
                setAudienceList(response.data.data.result.lists)
            })
            .finally(() => {
                setSearchIsLoading(false)
            })
    }

    const debounceSearchAudience = useCallback(debounce(fetchAudience, 1000), [])

    const fetchMailchimpUserInfo = () => {
        axios.get('/mailchimp-campaign/user-info')
            .then((response) => {
                setUserInfo(response.data.data.result)
            })
            .finally(() => {

            })
    }

    useEffect(() => {
        fetchAudience()
        fetchMailchimpUserInfo()
    }, [])

    const handleSaveNewList = () => {

        axios.post('mailchimp-campaign/create-audience-list', {
            name: newListName,
        })
            .then(() => {
                setShowNewListPopup(false)
                setNewListName('')
                fetchAudience()
                toast.success('Audience Saved')
            })
            .catch(() => {
                toast.error('Something went wrong')
            })
    }

    const handleSend = () => {
        let sendParams = {
            campaign_id: props.id
        }

        if (isScheduleOn && props.date) {
            let day = props.date.split('-')[0]
            let month = props.date.split('-')[1]
            let year = props.date.split('-')[2]

            let hrs = props.scheduleTime.split(':')[0]
            let min = props.scheduleTime.split(':')[1]

            sendParams.schedule_time = format(new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hrs), parseInt(min)), 'yyyy-MM-dd HH:mm:SS')
        }

        if (isScheduleOn && !props.date) {
            sendParams.schedule_time = format(new Date(props.scheduleTime), 'yyyy-MM-dd HH:mm:SS')
        }
        setIsSendLoading(true)
        axios.post('/mailchimp-campaign/send', sendParams)
            .then(() => {
                toast.success(isScheduleOn ? 'Campaign Scheduled' : 'Campaign Launched')
                props.fetchCampaigns()
            })
            .finally(() => {
                setIsSendLoading(false)
            })
    }

    return (
        <div>
            {props.isDetailLoading ? (
                <Loader />
            ) : (
                <>
                    <div
                        className={styles.welcome_container}
                    >
                        <div>
                            <div
                                className={styles.welcome_text}
                            >
                                Campaign
                            </div>

                            {!props.isDetailEdit && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 8
                                    }}
                                >
                                    <AccessTimeIcon />
                                    {props.details?.send_time ? format(new Date(props.details?.send_time), "dd-MM-yyyy, hh:mm a") : ''}
                                </div>
                            )}
                        </div>

                        {props.isDetailEdit ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        className={styles.publish_to_text}
                                    >
                                        Schedule
                                    </div>
                                    <GenericSwitch
                                        checked={isScheduleOn}
                                        onChange={(event) => setIsScheduleOn(event.target.checked)}
                                    />
                                </div>

                                {isScheduleOn && (
                                    <div
                                        className={styles.datetime_container}
                                    >
                                        <GenericTextField
                                            id="scheduleTime"
                                            name='scheduleTime'
                                            type={props.date ? "time" : "datetime-local"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.scheduleTime}
                                            onChange={(event) => props.setScheduleTime(event.target.value)}
                                            inputProps={{
                                                min: props.date ? format(new Date(), 'hh:mm') : format(new Date(), 'yyyy-MM-dd HH:mm').replace(' ', 'T')
                                            }}
                                        />
                                    </div>
                                )}

                                <GenericLoadingButton
                                    variant="contained"
                                    loading={isSendLoading}
                                    loadingPosition="end"
                                    onClick={handleSend}
                                    disabled={(!props.templateId || !props.audienceId || !props.subject)}
                                >

                                    Send
                                </GenericLoadingButton>
                            </div>
                        ) : (
                            <div
                                className={styles.results_container}
                            >
                                Results
                                {props.details?.report_summary && Object.entries(props.details?.report_summary).map(([key, value]) => (typeof value === 'number') && (
                                    <MarketingInfoChip
                                        containerClassName={styles.container_classname}
                                        valueClassName={styles.value_classname}
                                        descriptionClassName={styles.description_classname}
                                        description={key.replace('_', ' ')}
                                        value={value.toFixed(2)}
                                        moveValue={0}
                                        hideInfo={true}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    <div
                        className={styles.box_container}
                    >
                        <div
                            className={styles.item_container}
                        >
                            <div>
                                <CheckCircleRoundedIcon
                                    style={{
                                        color: props.details?.recipients?.list_name ? '#09CF44' : '#CDCDCD',
                                        fontSize: 16
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <div
                                    className={styles.title}
                                >
                                    To
                                </div>
                                <div
                                    className={styles.description_container}
                                >
                                    <div>
                                        {props.details?.recipients?.list_name ? (
                                            <>
                                                All Subscribed contacts in the audience {props.details?.recipients?.list_name}. <span style={{ textDecoration: 'underline' }}>{props.details?.recipients?.recipient_count} recipients</span>
                                            </>
                                        ) : (
                                            <>
                                                Who are you sending this campaign to?
                                            </>
                                        )}
                                    </div>



                                    {(props.audienceId && isToEditing) && (
                                        <GenericButton
                                            variant="contained"
                                            onClick={() => {
                                                setShowAddRecipient(true)
                                                setEditId(props.audienceId)
                                            }}
                                        >
                                            {props.details?.recipients?.list_name ? "Edit Receipients" : "Add Receipients"}
                                        </GenericButton>
                                    )}

                                    {(!isToEditing && props.isDetailEdit) && (
                                        <GenericButton
                                            variant="contained"
                                            onClick={() => {
                                                setIsToEditing(true)
                                            }}
                                        >
                                            {props.details?.recipients?.list_name ? "Edit Receipients" : "Add Receipients"}
                                        </GenericButton>
                                    )}
                                </div>

                                {isToEditing && (
                                    <>
                                        <div
                                            className={styles.search_audience_container}
                                        >
                                            {/* <div
                                        style={{
                                            maxWidth: 300
                                        }}
                                    >
                                        <InputField
                                            placeholder="Search Audience"
                                            value={audienceQuery}
                                            onChange={(event) => {
                                                setAudienceQuery(event.target.value)
                                                debounceSearchAudience(event.target.value)
                                            }}
                                            showIconButton={true}
                                            endIcon={searchIsLoading ? (
                                                <CircularProgress
                                                    style={{
                                                        color: '#ef2d2c'
                                                    }}
                                                    size={14}
                                                />
                                            ) : (
                                                <Search
                                                    style={{
                                                        color: '#ef2d2c'
                                                    }}
                                                />
                                            )}
                                        />
                                    </div> */}

                                            <div
                                                style={{
                                                    maxWidth: 300
                                                }}
                                            >
                                                <GenericSelectWithLabel
                                                    label="Audience"
                                                    value={props.audienceId}
                                                    onChange={(event) => {
                                                        if (event.target.value === 'add') {
                                                            setShowNewListPopup(true)
                                                        } else {
                                                            props.setAudienceId(event.target.value)
                                                        }
                                                    }}
                                                    fullWidth={true}
                                                    required={true}
                                                >
                                                    {audienceList.map((data) => (
                                                        <MenuItem
                                                            value={data.id}
                                                        >
                                                            <div>
                                                                <div>
                                                                    {data.name}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        fontSize: 13
                                                                    }}
                                                                >
                                                                    {data.stats?.member_count} Receipients
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                    ))}

                                                    <MenuItem
                                                        style={{
                                                            borderTop: '1px solid #DFDFDF'
                                                        }}
                                                        value="add"
                                                    >
                                                        {props.details?.settings?.subject_line ? "Edit Subject" : "Add Subject"}
                                                        Add Audience
                                                    </MenuItem>
                                                </GenericSelectWithLabel>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 10,
                                                marginTop: 10
                                            }}
                                        >
                                            {/* <GenericButton
                                                variant="contained"
                                                btnColor="secondary"
                                            >
                                                Cancel
                                            </GenericButton> */}

                                            <GenericButton
                                                variant="contained"
                                                onClick={() => {
                                                    props.setDetails((prevstate) => ({
                                                        ...prevstate,
                                                        recipients: {
                                                            list_name: audienceList.find((data) => data.id === props.audienceId).name,
                                                            recipient_count: audienceList.find((data) => data.id === props.audienceId).stats?.member_count
                                                        }
                                                    }))
                                                    setIsToEditing(false)
                                                    if (props.templateId && props.subject && props.audienceId) {
                                                        props.handleCampaignEdit()
                                                    }
                                                }}
                                            >
                                                Save
                                            </GenericButton>
                                        </div>

                                    </>
                                )}
                            </div>
                        </div>

                        <div
                            className={styles.item_container}
                        >
                            <div>
                                <CheckCircleRoundedIcon
                                    style={{
                                        color: '#09CF44',
                                        fontSize: 16
                                    }}
                                />
                            </div>
                            <div>
                                <div
                                    className={styles.title}
                                >
                                    From
                                </div>
                                <div>
                                    {props.isDetailEdit ? userInfo.email : props.details?.settings?.reply_to}
                                </div>
                            </div>
                        </div>

                        <div
                            className={styles.item_container}
                        >
                            <div>
                                <CheckCircleRoundedIcon
                                    style={{
                                        color: props.details?.settings?.subject_line ? '#09CF44' : '#CDCDCD',
                                        fontSize: 16
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <div
                                    className={styles.title}
                                >
                                    Subject
                                </div>

                                <div
                                    className={styles.description_container}
                                >
                                    <div>
                                        {props.details?.settings?.subject_line ? (
                                            <>
                                                {props.details?.settings?.subject_line}
                                            </>
                                        ) : (
                                            <>
                                                What's the subject line for this campaign?
                                            </>
                                        )}
                                    </div>

                                    {(!isSubjectEditing && props.isDetailEdit) && (
                                        <GenericButton
                                            variant="contained"
                                            onClick={() => {
                                                setIsSubjectEditing(true)
                                            }}
                                        >
                                            {props.details?.settings?.subject_line ? "Edit Subject" : "Add Subject"}
                                        </GenericButton>
                                    )}
                                </div>

                                {isSubjectEditing && (
                                    <>
                                        <GenericTextField
                                            value={props.subject}
                                            onChange={(event) => {
                                                props.setSubject(event.target.value)
                                            }}
                                        />

                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 10,
                                                marginTop: 10
                                            }}
                                        >

                                            <GenericButton
                                                variant="contained"
                                                onClick={() => {
                                                    props.setDetails((prevstate) => ({
                                                        ...prevstate,
                                                        settings: {
                                                            subject_line: props.subject,
                                                        }
                                                    }))
                                                    setIsSubjectEditing(false)
                                                    if (props.templateId && props.subject && props.audienceId) {
                                                        props.handleCampaignEdit()
                                                    }
                                                }}
                                            >
                                                Save
                                            </GenericButton>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div
                            className={styles.item_container}
                        >
                            <div>
                                <CheckCircleRoundedIcon
                                    style={{
                                        color: '#09CF44',
                                        fontSize: 16
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <div
                                    className={styles.title}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div>
                                        Content
                                    </div>

                                    {props.isDetailEdit && (
                                        <GenericButton
                                            variant="contained"
                                            onClick={() => {
                                                props.setIsTemplateSelected(true)
                                            }}
                                        >
                                            Edit Content
                                        </GenericButton>
                                    )}

                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%'
                                    }}
                                >
                                    {props.isDetailEdit ? (
                                        <div
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textDecoration: 'underline',
                                                    textAlign: 'right',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    setShowPreview(true)
                                                }}
                                            >
                                                Preview
                                            </div>
                                            <IconButton
                                                onClick={() => {
                                                    setShowPreview(true)
                                                }}
                                            >
                                                <Visibility
                                                    style={{
                                                        fontSize: 100
                                                    }}
                                                />
                                            </IconButton>
                                            <div
                                                style={{
                                                    textDecoration: 'underline',
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    setShowSendTestEmail(true)
                                                }}
                                            >
                                                Send Test Email
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {props.preview ? (
                                                <div
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        setShowPreview(true)
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            textDecoration: 'underline',
                                                            textAlign: 'right',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        Preview
                                                    </div>
                                                    <img
                                                        style={{
                                                            maxWidth: 200,
                                                            width: 200
                                                        }}
                                                        src={props.preview}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        fontSize: 20
                                                    }}
                                                >
                                                    {/* Thumbnail Not Available */}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <Dialog
                maxWidth="xl"
                open={showPreview}
                onClose={() => {
                    setShowPreview(false)
                }}
            >
                <DialogTitle
                    textAlign="center"
                >
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onClick={() => {
                            setShowPreview(false)
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {props.isDetailEdit ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: props.html
                            }}
                        />
                    ) : (
                        <img
                            style={{
                                minWidth: 200
                            }}
                            src={props.preview}
                        />
                    )}
                </DialogContent>
            </Dialog>

            <Dialog
                open={showNewListPopup}
                onClose={() => {
                    setShowNewListPopup(false)
                    setTemplateName('')
                }}
            >
                <NameDialog
                    label="Audience Name"
                    name={newListName}
                    onChange={(event) => {
                        setNewListName(event.target.value)
                    }}
                    onSave={handleSaveNewList}
                    onClose={() => {
                        setShowNewListPopup(false)
                        setNewListName('')
                    }}
                />
            </Dialog>

            <Dialog
                open={showAddRecipient}
                onClose={() => {
                    setShowAddRecipient(false)
                    setEditId('')
                }}
            >
                <AddRecipientDialog
                    id={editId}
                    onClose={() => {
                        setShowAddRecipient(false)
                        setEditId('')
                        fetchAudience()
                    }}
                />
            </Dialog>

            <Dialog
                open={showSendTestEmail}
                onClose={() => {
                    setShowSendTestEmail(false)
                }}
            >
                <SendTestEmailDialog
                    id={props.id}
                    onClose={() => {
                        setShowSendTestEmail(false)
                    }}
                />
            </Dialog>
        </div>
    )
}

export default EmailDetail