import React, { useEffect, useState } from 'react'
import { Box, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Menu, MenuItem } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import EmailVerification from './EmailVerification/EmailVerification';
import GenericSelect from '../../../../../Library/GenericSelect/GenericSelect';
import { nodeAxios } from '../../../../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import { getName } from '../../../../../../utils/helpers';
import { EMAIL_VERIFICATION_CONSTANTS } from '../../../../../../utils/helpers';
import Loader from '../../../../../Library/Loader';

const EmailPreferences = ({ onClose, venueSettings, fetchVenueSettings, fetchCCMails }) => {

    const [showAddEmailDialog, setShowAddEmailDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState(venueSettings?.cc_emails ? venueSettings?.cc_emails?.split(',') : []);
    const [isStaffLoading, setIsStaffLoading] = useState(false)
    const [staff, setStaff] = useState({
                                        data:  null,
                                        isLoading: false,
                                    });
    const open = Boolean(anchorEl);


    const fetchTeachers = (params, loadMore) => {
        if (!loadMore) {
            setStaff({
                ...staff,
                data: null,
                pagination: null,
                isLoading: true,
            });
        }
        nodeAxios
            .get('/users/list', { params }) // v2/teacher
            .then((response) => {
                if (loadMore) {
                    setStaff({
                        data: [...teachers.data, ...response.data?.data],
                        pagination: response.data?.pagination,
                        rest: response.data?.rest,
                        isLoading: false,
                    });
                } else {
                    setStaff({
                        data: response.data?.data,
                        pagination: response.data?.pagination,
                        rest: response.data?.rest,
                        isLoading: false,
                    });
                }
        })
        .catch((error) => {
            setStaff({
                data: [],
                isLoading: false,
            });
        });
    };

    const handleClick = (event) => {
        if (venueSettings?.custom_from_email_address) {
            return;
        }
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let params = {
            is_enable: 1,
            page: 1,
            'sort-by-order': 'ASC',
            'sort-by': 'first_name',
        };
        fetchTeachers(params);
    }, []);

    const [isResendLoading, setIsResendLoading] = useState(null);


    const handleResend = (email) => {

        const payload = {
            email: email,
            template: process.env.NODE_ENV !== "production" ? 'Esplanda-Template-Staging' :
                    "Esplanda-Email-Verification-Template-2"
        }


        setIsResendLoading(true);
        nodeAxios
            .post('/email-settings/request-verification', payload)
            .then((response) => {
                setIsResendLoading(false);
                toast.success('An email has been sent to your email address. Please verify your email address by clicking the link in the email we sent you.');
                fetchVenueSettings();
            })
            .catch((error) => {
                setIsResendLoading(false);
                toast.error(error?.response?.data?.message || 'An error occurred');
            });

    }

    const handleSave = (e) => {
        e.preventDefault();
        const payload = {
            cc_emails: selectedStaff,
        }
        console.log('🚀OUTPUT --> payload:', payload);
        setIsSaveLoading(true);
        nodeAxios
            .post('/email-settings/update-cc-emails', payload)
            .then((response) => {
                toast.success('Email preferences updated successfully');
                fetchVenueSettings();
                fetchCCMails();
                onClose();
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || 'An error occurred');
            }).finally(() => {
                setIsSaveLoading(false);
            });
    }

    
return (
    <>
    <DialogTitle className='tw-flex tw-justify-between tw-items-center'>
        <span className='tw-font-semibold'>Email preferences</span>
        <button className='tw-dui-btn tw-dui-btn-ghost tw-h-auto tw-min-h-0 !tw-px-1' onClick={onClose}>
            <CloseIcon />
        </button>
    </DialogTitle>
    <DialogContent>
        <p className='tw-text-xs  tw-text-[#868686]'>
            Choose the email sender (Esplanda or your own) and additionally specify recipients in the CC field for order emails.
        </p>

        <div className='tw-mt-4'>
            <div className='tw-mb-1 tw-font-semibold tw-flex tw-justify-between tw-items-center'>
                <span>From</span>
                <button
                    className='tw-dui-btn tw-dui-btn-ghost tw-dui-btn-xs'
                    type='button'
                    onClick={() => setShowAddEmailDialog(true)}
                >
                    Change from email?
                </button>
            </div>
            
            <div 
                className='tw-bg-[#EFEFEF] tw-p-2 tw-rounded tw-text-sm tw-cursor-pointer'
                id="action-btn"
                aria-controls={open ? 'action-btn-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {(venueSettings?.custom_from_email_address && 
                    venueSettings?.is_from_email_verified == EMAIL_VERIFICATION_CONSTANTS.VERIFIED) ?
                    (
                        <span>
                            {venueSettings?.custom_from_email_address}
                            <span className='tw-ml-1 tw-text-white tw-text-xs tw-bg-green-500 tw-py-0.5 tw-px-2 tw-rounded-2xl'>(Verified)</span>
                        </span>
                    ) : 'Esplanda'}

            </div>

            {(venueSettings?.custom_from_email_address && 
                    venueSettings?.is_from_email_verified == EMAIL_VERIFICATION_CONSTANTS.PENDING) && (
                        <div className='tw-text-red-600 tw-text-sm tw-mt-2'>
                            {`Email verification pending for ${venueSettings?.custom_from_email_address}`}

                            <button
                                className='tw-dui-btn tw-dui-btn-xs tw-ml-2'
                                type='button'
                                onClick={() => handleResend(venueSettings?.custom_from_email_address)}
                                disabled={isResendLoading}
                            >
                                Resend verification link
                                {isResendLoading && <span className='tw-dui-loading tw-dui-loading-spinner tw-dui-loading-xs'></span>}
                            </button>
                        </div>
                    )}
            {(venueSettings?.custom_from_email_address_secondary && 
                    venueSettings?.is_secondary_from_email_verified == EMAIL_VERIFICATION_CONSTANTS.PENDING) && (
                        <div className='tw-text-red tw-text-sm tw-mt-2'>
                            {`Email verification pending for ${venueSettings?.custom_from_email_address_secondary}`}

                            <button
                                className='tw-dui-btn tw-dui-btn-xs tw-ml-2'
                                type='button'
                                onClick={() => handleResend(venueSettings?.custom_from_email_address_secondary)}
                                disabled={isResendLoading}
                            >
                                Resend verification link
                                {isResendLoading && <span className='tw-dui-loading tw-dui-loading-spinner tw-dui-loading-xs'></span>}
                            </button>
                        </div>
                    )}

            {!(venueSettings?.custom_from_email_address && 
            venueSettings?.is_from_email_verified == EMAIL_VERIFICATION_CONSTANTS.VERIFIED) && (
                <Menu
                    id="action-btn-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'action-btn',
                    }}
                    classes={{ paper: '!tw-min-w-[300px]' }}
                >
                    
                    <MenuItem  className='!tw-justify-center !tw-text-blue !tw-text-sm' onClick={() => setShowAddEmailDialog(true)}>
                        + Add Email
                    </MenuItem>
                    <MenuItem>
                        <div className='tw-flex tw-justify-between tw-w-full tw-items-end'>
                            <span>Esplanda</span>
                            <span className='tw-text-xs tw-font-semibold'>(Current)</span>
                        </div>
                    </MenuItem>
                </Menu>
            )}
        </div>
        <div className='tw-mt-4'>
            <div className='tw-font-semibold'>CC</div>
            <FormControl
                sx={{
                    width: '100% !important',
                }}
                size='small'
            >
                <GenericSelect
                    value={selectedStaff}
                    sx={{ height: 'auto' }}
                    multiple
                    placeholder='Select Staff'
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {staff?.isLoading ? (
                            <span className='tw-animate-pulse tw-w-[150px] tw-h-5 tw-bg-slate-200 tw-rounded-3xl tw-px-2 tw-py-0.5'></span>
                        ) : 
                        selected?.map((value) => (
                            <Chip
                                key={value}
                                label={staff?.data?.find((e) => e.id == value)?.name}
                            />
                        ))}
                        </Box>
                    )}
                    onChange={(event) => setSelectedStaff(event.target.value)}
                    MenuProps={{ classes: {  paper: '!tw-max-h-[400px]' } }}
                >
                {staff?.isLoading ? (<Loader />) : staff?.data?.filter(_ => _.biz_users_role != 1)?.map((_staff) => {
                    const checked = selectedStaff?.indexOf(String(_staff.id)) > -1;
                    return (
                    <MenuItem key={_staff.id} value={String(_staff?.id)}>
                        <div className='tw-flex tw-justify-between tw-items-center tw-w-full'>
                            <span>
                                <Checkbox checked={checked} />
                                {_staff?.name}
                                <span className='tw-text-[#ACACAC] tw-text-xs tw-ml-2'>
                                    ({{
                                        0: "Super Admin",
                                        1: 'Admin',
                                        2: 'Manager',
                                        3: 'Driver'
                                    }[_staff.biz_users_role]})
                                </span>
                            </span>
                        </div>
                    </MenuItem>
                )})}
                </GenericSelect>
            </FormControl>
        </div>


    <Dialog
        open={showAddEmailDialog}
        onClose={() => setShowAddEmailDialog(false)}
    >
        <EmailVerification onClose={() => setShowAddEmailDialog(false)} venueSettings={venueSettings} closePreferencesModal={onClose} fetchVenueSettings={fetchVenueSettings} />
    </Dialog>

    </DialogContent>
    <DialogActions>
        <button 
            className='tw-dui-btn tw-bg-blue tw-text-white tw-w-full hover:tw-bg-darkBlue'
            onClick={handleSave}
            disabled={isSaveLoading}
        >
            Save Settings
            {isSaveLoading && <span className='tw-dui-loading tw-dui-loading-spinner tw-dui-loading-xs'></span>}
        </button>
    </DialogActions>
    </>
  )
}

export default EmailPreferences