import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import GoogleAddressInput from '../../Library/GoogleAddressInput/GoogleAddressInput'
import GenericTextFieldWithLabel from '../../Library//TextFieldWithLabel/GenericTextFieldWithLabel'
import GenericTextField from '../../Library/GenericTextField/GenericTextField'
import { Collapse, List, ListItem, ListItemButton, ListItemText, MenuItem, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useState } from 'react'
import styles from './ClaimMyBusinessForm.module.scss'
import axios from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { ArrowForward, ExpandLess, ExpandMore } from '@mui/icons-material'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import routePaths from '../../../config/route-paths'

const ClaimMyBusinessForm = (props) => {

  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isAddVenueHours, setIsAddVenueHours] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: props.details.Name || '',
      userName: '',
      email: props.details.email || '',
      password: '',
      phone: props.details.phone || '',
      venuetype: props.details.venuetype || '',
      address1: props.details.Address1 ? ((props.details.Address1 || '') + ' ' + (props.details.City || '') + ' ' + (props.details.State || '') + ' ' + (props.details.Country || '')) : '',
      city: props.details.City || '',
      state: props.details.State || '',
      country: props.details.Country || '',
      latitude: props.details.latitude || '',
      longitude: props.details.longitude || '',
      description: props.details.Description || '',
      image: props.details.image || '',
      image_file: null,
      venue_hours: (Boolean(props.details?.venue_hours?.length) && props?.details?.venue_hours) || [
        {
          venuetimings: 'pickup',
          dayname: 'Sunday',
          opentimehours: 12,
          opentimemins: 0,
          closedtimehours: 18,
          closedtimemins: 0,
          isclosed: '1',
          time_interval: 60
        },
        {
          venuetimings: 'pickup',
          dayname: 'Monday',
          opentimehours: 12,
          opentimemins: 0,
          closedtimehours: 18,
          closedtimemins: 0,
          isclosed: '1',
          time_interval: 60
        },
        {
          venuetimings: 'pickup',
          dayname: 'Tuesday',
          opentimehours: 12,
          opentimemins: 0,
          closedtimehours: 18,
          closedtimemins: 0,
          isclosed: '1',
          time_interval: 60
        },
        {
          venuetimings: 'pickup',
          dayname: 'Wednesday',
          opentimehours: 12,
          opentimemins: 0,
          closedtimehours: 18,
          closedtimemins: 0,
          isclosed: '1',
          time_interval: 60
        },
        {
          venuetimings: 'pickup',
          dayname: 'Thursday',
          opentimehours: 12,
          opentimemins: 0,
          closedtimehours: 18,
          closedtimemins: 0,
          isclosed: '1',
          time_interval: 60
        },
        {
          venuetimings: 'pickup',
          dayname: 'Friday',
          opentimehours: 12,
          opentimemins: 0,
          closedtimehours: 18,
          closedtimemins: 0,
          isclosed: '1',
          time_interval: 60
        },
        {
          venuetimings: 'pickup',
          dayname: 'Saturday',
          opentimehours: 12,
          opentimemins: 0,
          closedtimehours: 18,
          closedtimemins: 0,
          isclosed: '1',
          time_interval: 60
        }
      ]
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!values.address1) {
        toast.error('Please enter address')
        return
      }
      setIsLoading(true)

      let params = {
        Name: values.name,
        user_name: values.userName,
        email: values.email,
        contact_no: values.phone,
        phone: values.phone,
        venuetype: values.venuetype,
        Address1: values.address1,
        Description: values.description,
        City: values.city,
        Country: values.country,
        State: values.state,
        latitude: values.latitude,
        longitude: values.longitude,
        password: values.password,
        venue_id: props.details.VenueId,
        is_claim_business: 1,
        venue_hours: values.venue_hours
      }
      if (!isAddVenueHours) {
        params.venue_hours = []
      }
      axios.post(props.isManage ? `/venue-listing/edit/${props.details.VenueId}` : '/auth/signup', params)
        .then((res) => {
          localStorage.removeItem("isShiftedFrom")
          toast.success('Success')

          if (!props.isManage) {
            localStorage.setItem("userToken", res.data.data.token);
            localStorage.setItem("venue_id", res.data.data.user.venueid);
            if (res.data.data.user?.biz_users_role == 0) {
              localStorage.setItem("superAdminToken", res.data.data.token);
            }
            dispatch({
              type: "LOGIN_SUCCESS",
              payload: res.data.data.user,
            });
            window.location.href = routePaths.manageListing
          }
        })
        .catch((error) => {
          if (props.isManage) {
            toast.error('Something went wrong')
          } else {
            if (error.response?.data?.message) {
              toast.error(error.response.data.message)
              return
            }
            toast.error(error.response.data[Object.keys(error.response.data)[0]][0])
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  })

  const handleInputChange = (time, index, hrkey, minkey) => {
    let [hour, minute] = time.split(':');
    hour = parseInt(hour) >= 10 ? parseInt(hour) : parseInt(hour[1]);
    minute = parseInt(minute) >= 10 ? parseInt(minute) : parseInt(minute[1]);

    let newVenueHours = formik.values.venue_hours.map((val, i) => {
      if (index === i) {
        return {
          ...val,
          [hrkey]: hour,
          [minkey]: minute,
        }
      } else {
        return val;
      }
    });

    setIsAddVenueHours(true)
    formik.setFieldValue('venue_hours', newVenueHours)
  };

  const handleSwitch = (index) => {

    let newVenueHours = formik.values.venue_hours.map((val, i) => {
      if (index === i) {
        return {
          ...val,
          isclosed: Boolean(parseInt(val.isclosed)) ? '0' : '1'
        }
      } else {
        return val;
      }
    });

    setIsAddVenueHours(true)
    formik.setFieldValue('venue_hours', newVenueHours)
  }
  return (
    <div
      className={styles.add_new_store}
    >
      <form
        className={clsx(styles.form_box, props.formBoxClassName)}
        onSubmit={formik.handleSubmit}
      >
        <GenericTextFieldWithLabel
          label="Business Name"
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          fullWidth={true}
          required={true}
        />

        {!props.isManage && (
          <GenericTextFieldWithLabel
            label="Your Name"
            id="userName"
            name="userName"
            value={formik.values.userName}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          />
        )}

        <GenericTextFieldWithLabel
          label="Business Email"
          id="email"
          name="email"
          type="email"
          autoComplete="new-password"
          value={formik.values.email}
          onChange={formik.handleChange}
          fullWidth={true}
          required={true}
        />

        {!props.isManage && (
          <GenericTextFieldWithLabel
            label="Password"
            id="password"
            name="password"
            type="text"
            value={formik.values.password}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          />
        )}

        <div
          className={styles.type_number_container}
          style={{
            flexDirection: props.isManage ? 'row' : 'column'
          }}
        >
          <GenericSelectWithLabel
            id="venuetype"
            name="venuetype"
            label="Store Type"
            containerClassname={styles.small_input_container}
            value={formik.values.venuetype}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          >
            <MenuItem value="Brewery">Brewery</MenuItem>
            <MenuItem value="Winery">Winery</MenuItem>
            <MenuItem value="Distillery">Distillery</MenuItem>
            <MenuItem value="Package Store">Package Store</MenuItem>
            <MenuItem value="Grocery Store">Grocery Store</MenuItem>
            <MenuItem value="Restaurant">Restaurant</MenuItem>
          </GenericSelectWithLabel>

          <GenericTextFieldWithLabel
            label="Business Phone Number"
            id="phone"
            name="phone"
            containerClassname={styles.small_input_container}
            value={formik.values.phone}
            onChange={formik.handleChange}
            fullWidth={true}
            required={true}
          />
        </div>

        <GoogleAddressInput
          label="Business Address"
          isLabel={true}
          placeholder="Enter Address"
          value={formik.values.address1}
          setLat={lat => formik.setFieldValue('latitude', lat)}
          setLng={lng => formik.setFieldValue('longitude', lng)}
          setAddress={address => formik.setFieldValue('address1', address)}
          setCity={city => formik.setFieldValue('city', city)}
          setState={state => formik.setFieldValue('state', state)}
          setCountry={country => formik.setFieldValue('country', country)}
          required={true}
        />

        <GenericTextFieldWithLabel
          label="Description"
          id="description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          multiline={true}
          fullWidth={true}
        />

        {props.showVenueHours && (
          <List
            disablePadding
          >
            <li>
              <ListItemButton
                onClick={() => {
                  setOpen((prevState) => !prevState)
                }}
                disableGutters
              >
                <ListItemText
                  classes={{
                    primary: styles.title_text
                  }}
                  primary="Venue Hours"
                />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </li>
            <Collapse component="li" in={open} timeout="auto" unmountOnExit>
              <List disablePadding>
                {formik.values.venue_hours.map((val, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "17px 0",
                        width: '100%',
                        flexWrap: 'wrap'
                      }}
                    >
                      <Typography
                        style={{ color: "black", marginRight: 10, width: 85 }}
                      >
                        {val.dayname}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <GenericSwitch
                          checked={val.isclosed == '0'}
                          onChange={e => {
                            handleSwitch(index)
                          }}
                        />

                        <div
                          style={{
                            marginLeft: 5
                          }}
                        >
                          {val.isclosed == '0' ? "Open" : "Closed"}
                        </div>
                      </div>

                      <div
                        className={styles.hours_container}
                      >
                        {val.isclosed == '0' && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: 'center',
                              padding: '4px 10px 4px 20px',
                            }}
                          >
                            <GenericTextField
                              type="time"
                              defaultValue={`${val.opentimehours < 10 ? `0${val.opentimehours}` : val.opentimehours}:${val.opentimemins < 10 ? `0${val.opentimemins}` : val.opentimemins}`}
                              onChange={(e) => {
                                handleInputChange(e.target.value, index, 'opentimehours', 'opentimemins')
                              }}
                            />

                            <ArrowForward
                              style={{ transform: 'scale(.8)', color: 'lightgrey', margin: '0 15px' }}
                            />

                            <GenericTextField
                              type="time"
                              defaultValue={`${val.closedtimehours < 10 ? `0${val.closedtimehours}` : val.closedtimehours}:${val.closedtimemins < 10 ? `0${val.closedtimemins}` : val.closedtimemins}`}
                              onChange={(e) => handleInputChange(e.target.value, index, 'closedtimehours', 'closedtimemins')}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </List>
            </Collapse>
          </List>
        )}

        <div
          className={styles.btn_container}
        >
          <GenericLoadingButton
            type="submit"
            variant="contained"
            fullWidth={true}
            loading={isLoading}
            loadingPosition="end"
          >
            {props.isManage ? "Save" : "Claim my business"}
          </GenericLoadingButton>
        </div>
      </form>
    </div>
  )
}

export default ClaimMyBusinessForm