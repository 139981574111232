import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { ReactComponent as ChevronDown } from "../assets/icons/chevron-down.svg";

const ChevronDownStyled = styled(ChevronDown)`
  position: absolute;
  right: 12px;
  top: 56px;
`;

const baseStyles = css`
  width: 100%;
  background: transparent;
  border: 1px solid #dee2e6;
  font-size: 17px;
  font-family: "Poppins";
  padding: 11px 10px;
  border-radius: 3px;
  color: #171717;

  &:disabled {
    background: #f9f9f9;
    cursor: not-allowed;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #fff;
    padding: 10px;
  }
  :-ms-input-placeholder {
    color: #fff;
    padding: 10px;
  }

  ${(props) =>
    props.isError &&
    css`
      border: 1px solid red;
    `}
`;

const Input = styled("input")`
  ${baseStyles}
`;

const CustomTextArea = styled.textarea`
  ${baseStyles}
  resize:none;
`;

const InputSelect = styled.select`
  ${baseStyles}

  & > option {
    padding: 12px 10px 12px 10px;
    font-weight: 500;
    font-size: 1.05rem;
  }

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`;

const SymbolBox = styled.div`
  padding: 10px 16px;
  color: #fff;
  background: #707070;
  display: grid;
  place-items: center;
  font-size: 17px;
  font-family: "Poppins";
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.align === "reverse" ? "row-reverse" : "row"};
`;

export const FlexRadiusContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dee2e6;

  & > span {
    font-size: 17px;
    font-family: "Poppins";
    padding: 10px;
    border: 1px solid #dee2e6;
  }
`;

const AutoComplete = styled.div`
  width: 220px;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  list-style: none;
  background-color: white;
  overflow: auto;
  max-height: 200;
  border: 1px solid rgba(0, 0, 0, 0.25);

  & li[data-focus="true"] {
    background-color: #4a8df6;
    color: white;
    cursor: pointer;
  }

  & li:active {
    background-color: #2977f5;
    color: white;
  }
`;

const LabelText = styled.label`
  font-size: 15px;
  color: #171717;
  margin-bottom: 10px;
`;

export const RegularInput = ({
  title,
  autoCompleteOptions,
  isAutoCompleteOpen,
  autoCompleteCallback,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    <LabelText>{title}</LabelText>
    <Input {...props} />
    {isAutoCompleteOpen && (
      <AutoComplete>
        {autoCompleteOptions.map((option, index) => (
          <li key={index} onClick={() => autoCompleteCallback(option)}>
            {option}
          </li>
        ))}
      </AutoComplete>
    )}
  </div>
);

export const TextArea = ({ title, ...props }) => (
  <div>
    <LabelText>{title}</LabelText>
    <CustomTextArea {...props} />
  </div>
);

export const InputDropdown = ({
  title,
  options,
  isLabelDisabled,
  overrideStyle,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {!isLabelDisabled && <LabelText>{title}</LabelText>}
    <InputSelect {...props}>
      {options.map(({ name, value }) => (
        <option key={name} value={value}>
          {name}
        </option>
      ))}
    </InputSelect>
    {overrideStyle ? (
      <ChevronDownStyled style={{ ...overrideStyle }} />
    ) : (
      <ChevronDownStyled />
    )}
  </div>
);

export const InputWithIcon = ({ title, alignment, symbol, bg, ...props }) => (
  <div>
    <LabelText>{title}</LabelText>
    <FlexContainer align={alignment}>
      <Input {...props} />
      <SymbolBox bg={bg}>{symbol}</SymbolBox>
    </FlexContainer>
  </div>
);

export const InputWithIconText = ({ title, label, ...props }) => (
  <div>
    <LabelText>{title}</LabelText>
    <FlexRadiusContainer>
      <Input {...props} />
      <span>{label}</span>
    </FlexRadiusContainer>
  </div>
);

RegularInput.propTypes = {
  title: PropTypes.string.isRequired,
};

TextArea.propTypes = {
  title: PropTypes.string.isRequired,
};
InputDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isLabelDisabled: PropTypes.bool,
};

InputWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  alignMent: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  bg: PropTypes.string,
};
