import clsx from 'clsx'
import React, { forwardRef, useState } from 'react'
import { useId } from 'react'
import styles from './UploadImage.module.scss'
import { UploadIcon } from '../../../../assets/svgs/svgs'
import { Button, Dialog } from '@mui/material'

const UploadImage = forwardRef((props, ref) => {

    const id = useId();

    return (
        <div
            className={clsx(styles.upload_image_input_container, props.className)}
        >
            <input
                style={{
                    display: 'none'
                }}
                id={id}
                type="file"
                accept="image/*"
                onChange={(event) => {
                    props.onChange(event)
                    event.target.value = ''
                }}
                ref={ref}

            />

            <div
                // htmlFor={id}
                className={styles.image_upload_label}
            >
                {(props.image || props.imageLink) ? (
                    <div className='tw-flex tw-gap-2 tw-w-full'>
                        <Button 
                            variant='outlined' 
                            // color='primary'
                            className='!tw-text-[#0071C3]'
                            fullWidth
                            size='small'
                            onClick={props.onViewImage}
                            >
                            {props.viewImageLabel}    
                        </Button>
                        {props.removeLabel && (
                            <Button 
                                variant='outlined' 
                                color='error'
                                size='small'
                                fullWidth
                                onClick={props.onRemoveImage}
                            >
                                {props.removeLabel}
                            </Button>
                        )}
                    </div>
                ) : (
                    <Button 
                        variant='outlined' 
                        fullWidth
                        color='inherit'
                        onClick={() => ref.current?.click()}
                        className='!tw-text-black  !tw-border-gray tw-gap-2'
                    >
                        <UploadIcon />
                        {props.uploadText}
                    </Button>
                )}
            </div>

            {/* <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <div className={styles.uploaded_image}>
                    <img
                        className='tw-max-w-full sm:tw-max-w-80 tw-max-h-full'
                        src={props.image ? URL.createObjectURL(props.image) : props.imageLink}
                    />
                </div>
            </Dialog> */}

        </div>
    )
})

export default UploadImage