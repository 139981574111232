import axios from 'axios';
import axios2 from '../utils/axiosConfig'
import { baseurl } from '../env';

export const previewCategoryList = async (categoryIds) => {
    try {
        const res = await axios2.get('/category/category-list', {
            params: {
                ids: categoryIds
            }
        });
        const { data: { data: { data } } } = res;
        return data;
    } catch (err) {
        throw err;
    };
};

export const previewProductList = async (uri, searchString, homepageData) => {
    try {
        const res = await axios.get(`${baseurl}${uri}${searchString ? (homepageData ? `${searchString}&homepageData=true` : searchString) : ""}`);
        const { data: { data } } = res;
        return data;
    } catch (err) {
        throw err;
    };
};