const initialState = {
  isDashBoardLoading: false,
  isLoggedIn: false,
  dashboardlist: {},
  dashboardListError: "",
};
function dashboardList(state = initialState, action) {
  switch (action.type) {
    case "SET_DASHBOARD_LIST_LOADING":
      return { ...initialState, isDashBoardLoading: true };
    case "SET_DASHBOARD_LIST_LOADED":
      return {
        ...initialState,
        isDashBoardLoading: false,
        dashboardlist: action.payload,
      };
    case "SET_DASHBOARD_LIST_ERROR":
      return { ...initialState, dashboardListError: action.payload };
    default:
      return state;
  }
}
export default dashboardList;
