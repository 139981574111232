import { DeleteForever } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import GenericTextField from '../../../Library/GenericTextField/GenericTextField'
import styles from './AddRecipient.module.scss'

const AddRecipient = (props) => {
    return (
        <div
            className={styles.container}
        >
            {props.inputFields.map((data) => (
                <GenericTextField
                    key={data.id}
                    id={data.id}
                    name={data.id}
                    placeholder={data.label}
                    value={data.value}
                    onChange={data.onChange}
                />
            ))}

            <IconButton
                onClick={() => props.onDelete(props.index)}
            >
                <DeleteForever />
            </IconButton>
        </div>
    )
}

export default AddRecipient