import React from 'react'
import cloudbaselogo1 from "../../../assets/cloud-base-logo-1.jpg";
import cloudbaselogo11 from "../../../assets/cloud-base-logo-11.jpg";
import onsitelogo2 from "../../../assets/on-site-logo-2.jpg";
import onsitelogo3 from "../../../assets/on-site-logo-3.jpg";
import onsitelogo4 from "../../../assets/on-site-logo-4.jpg";
import onsitelogo5 from "../../../assets/on-site-logo-5.jpg";
import onsitelogo6 from "../../../assets/on-site-logo-6.jpg";
import onsitelogo7 from "../../../assets/on-site-logo-7.jpg";
import onsitelogo8 from "../../../assets/on-site-logo-8.jpg";
import onsitelogo9 from "../../../assets/on-site-logo-9.jpg";
import onsitelogo10 from "../../../assets/on-site-logo-10.jpg";
import onsitelogo11 from "../../../assets/on-site-logo-11.jpg";
import onsitelogo12 from "../../../assets/on-site-logo-12.jpg";
import onsitelogo13 from "../../../assets/on-site-logo-13.jpg";
import onsitelogo14 from "../../../assets/on-site-logo-14.jpg";
import onsitelogo15 from "../../../assets/on-site-logo-15.jpg";
import onsitelogo16 from "../../../assets/on-site-logo-16.jpg";
import onsitelogo17 from "../../../assets/on-site-logo-17.jpg";
import onsitelogo18 from "../../../assets/on-site-logo-18.jpg";
import onsitelogo19 from "../../../assets/on-site-logo-19.jpg";
import onsitelogo20 from "../../../assets/on-site-logo-20.jpg";
import onsitelogo21 from "../../../assets/on-site-logo-21.jpg";
import onsitelogo22 from "../../../assets/on-site-logo-22.jpg";
import onsitelogo23 from "../../../assets/on-site-logo-23.jpg";
import onsitelogo24 from "../../../assets/on-site-logo-24.jpg";
import onsitelogo25 from "../../../assets/on-site-logo-25.jpg";
import onsitelogo26 from "../../../assets/on-site-logo-26.jpg";
import onsitelogo27 from "../../../assets/on-site-logo-27.jpg";
import onsitelogo28 from "../../../assets/on-site-logo-28.jpg";
import onsitelogo29 from "../../../assets/on-site-logo-29.jpg";
import onsitelogo30 from "../../../assets/on-site-logo-30.jpg";
import onsitelogo31 from "../../../assets/on-site-logo-31.jpg";
import onsitelogo32 from "../../../assets/on-site-logo-32.jpg";
import onsitelogo33 from "../../../assets/on-site-logo-33.jpg";
import onsitelogo34 from "../../../assets/on-site-logo-34.jpg";
import onsitelogo35 from "../../../assets/on-site-logo-35.jpg";
import onsitelogo36 from "../../../assets/on-site-logo-36.jpg";
import onsitelogo37 from "../../../assets/on-site-logo-37.jpg";
import onsitelogo38 from "../../../assets/on-site-logo-38.jpg";
import onsitelogo39 from "../../../assets/on-site-logo-39.jpg";
import onsitelogo40 from "../../../assets/on-site-logo-40.jpg";
import onsitelogo41 from "../../../assets/on-site-logo-41.jpg";
import onsitelogo42 from "../../../assets/on-site-logo-42.jpg";
import onsitelogo43 from "../../../assets/on-site-logo-43.jpg";
import onsitelogo44 from "../../../assets/on-site-logo-44.jpg";
import onsitelogo46 from "../../../assets/on-site-logo-46.jpg";
import onsitelogo47 from "../../../assets/on-site-logo-47.jpg";
import onsitelogo48 from "../../../assets/on-site-logo-48.jpg";
import onsitelogo49 from "../../../assets/on-site-logo-49.jpg";
import onsitelogo50 from "../../../assets/on-site-logo-50.jpg";
import onsitelogo51 from "../../../assets/on-site-logo-51.jpg";
import onsitelogo52 from "../../../assets/on-site-logo-52.jpg";
import onsitelogo53 from "../../../assets/on-site-logo-53.jpg";
import onsitelogo54 from "../../../assets/on-site-logo-54.jpg";
import onsitelogo55 from "../../../assets/on-site-logo-55.jpg";
import onsitelogo56 from "../../../assets/on-site-logo-56.jpg";
import onsitelogo57 from "../../../assets/on-site-logo-57.jpg";
import styles from './OnSiteIntegration.module.scss'

const OnSiteIntegration = (props) => {
  return (
    <div
      className={styles.container}
    >
      <div
        className={styles.container}
      >
        <div
          className={styles.para}
        >
          If you're using based service, this is the way to go. You're minute away from completing the integration.
          Click on your POS from the list below to give us access to the inventory data. Rest assure - we are only reading the
          data from POS, and not writing to it. Once we get your permission(by click on the POS button), we'll start fetching the inventory
          immediately. It should take <span>about a minute</span> until you'll see your products under the inventory.
        </div>

      </div>


      <div className={styles.logo_section_tabs_container}>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Lightspeed Retail')
          }}
        >
          <img src={cloudbaselogo1} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Business POS')
          }}
        >
          <img src={onsitelogo2} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('On Sight')
          }}
        >
          <img src={onsitelogo3} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Wine POS')
          }}
        >
          <img src={onsitelogo4} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Quickbooks')
          }}
        >
          <img src={onsitelogo5} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Magento')
          }}
        >
          <img src={onsitelogo6} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Wine Soft')
          }}
        >
          <img src={onsitelogo7} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('CIS')
          }}
        >
          <img src={onsitelogo8} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Spirits')
          }}
        >
          <img src={onsitelogo9} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Posim')
          }}
        >
          <img src={onsitelogo10} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('The General Store')
          }}
        >
          <img src={onsitelogo11} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('MagStar')
          }}
        >
          <img src={onsitelogo12} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('PC America')
          }}
        >
          <img src={onsitelogo13} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Microsoft Dynamics')
          }}
        >
          <img src={onsitelogo14} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('MPower')
          }}
        >
          <img src={onsitelogo15} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('RDT')
          }}
        >
          <img src={onsitelogo16} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Advent POS')
          }}
        >
          <img src={onsitelogo17} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Cap retail')
          }}
        >
          <img src={onsitelogo18} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('EZ Retail')
          }}
        >
          <img src={onsitelogo19} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('JMSC POS')
          }}
        >
          <img src={onsitelogo20} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('TAM Retail')
          }}
        >
          <img src={onsitelogo21} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Tiger POS')
          }}
        >
          <img src={onsitelogo22} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('NCR')
          }}
        >
          <img src={cloudbaselogo11} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('POS Solution')
          }}
        >
          <img src={onsitelogo23} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Artisan')
          }}
        >
          <img src={onsitelogo24} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Biz Tracker')
          }}
        >
          <img src={onsitelogo25} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Cafe Cartel')
          }}
        >
          <img src={onsitelogo26} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Future POS')
          }}
        >
          <img src={onsitelogo27} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Corner Store')
          }}
        >
          <img src={onsitelogo28} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('POS')
          }}
        >
          <img src={onsitelogo29} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Retail POS')
          }}
        >
          <img src={onsitelogo30} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('KeyStroke POS')
          }}
        >
          <img src={onsitelogo31} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Info Touch')
          }}
        >
          <img src={onsitelogo32} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('IT retail')
          }}
        >
          <img src={onsitelogo33} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Korus')
          }}
        >
          <img src={onsitelogo34} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Nau Computer')
          }}
        >
          <img src={onsitelogo35} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Logi Vision')
          }}
        >
          <img src={onsitelogo36} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('NRS Plus')
          }}
        >
          <img src={onsitelogo56} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Micro Biz')
          }}
        >
          <img src={onsitelogo37} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('POS with logic')
          }}
        >
          <img src={onsitelogo38} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('OS Prey Retail')
          }}
        >
          <img src={onsitelogo39} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Pomodo Software')
          }}
        >
          <img src={onsitelogo40} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('POS Plus')
          }}
        >
          <img src={onsitelogo41} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Rocket POS')
          }}
        >
          <img src={onsitelogo42} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Positive Software')
          }}
        >
          <img src={onsitelogo43} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Api POS')
          }}
        >
          <img src={onsitelogo44} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Retail Pro')
          }}
        >
          <img src={onsitelogo46} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Sun Smart POS')
          }}
        >
          <img src={onsitelogo47} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Dumac')
          }}
        >
          <img src={onsitelogo48} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Saitec POS')
          }}
        >
          <img src={onsitelogo49} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Loc Software')
          }}
        >
          <img src={onsitelogo50} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Wind Ward')
          }}
        >
          <img src={onsitelogo51} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('The General Store')
          }}
        >
          <img src={onsitelogo52} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Komachine')
          }}
        >
          <img src={onsitelogo53} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Pharmacy Solutions')
          }}
        >
          <img src={onsitelogo54} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Wind Ward')
          }}
        >
          <img src={onsitelogo55} />
        </div>
        <div
          className={styles.logo_image}
          onClick={() => {
            props.showIntegrations()
            props.setPlatformName('Store Tender')
          }}
        >
          <img src={onsitelogo57} />
        </div>
      </div>

      <div
        className={styles.cant_find}
        onClick={() => {
          props.showIntegrations()
          props.setPlatformName()
        }}
      >
        Still can't find your POS system?
        click here to schedule a meeting with our integration team.
      </div>
    </div >
  )
}

export default OnSiteIntegration