import React, { useState, useContext } from 'react'
import styles from './ButtonStyleEdit.module.scss' 
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, MenuItem } from '@mui/material'
import SliderWithLabel from '../../CustomPageBuilder/Library/SliderWithLabel/SliderWithLabel'
import GenericSelectWithLabel from '../GenericSelectWithLabel/GenericSelectWithLabel'
import ColorInputWithLabel from '../../CustomPageBuilder/Library/ColorInputWithLabel/ColorInputWithLabel'

const ButtonStyleEdit = ({ 
  title,
  buttonStyles, 
  setButtonStyles, 
  ...props 
}) => {
  
  const [showButton, setShowButton] = useState(false)
  
  const handleChangeButtonStyle = (key, value) => {
    setButtonStyles({...buttonStyles, [key]: value});

  }

  return (
    <div
      className={styles.item}
      style={{
        paddingBottom: showButton ? 10 : 0
      }}
    >
      <div
        className={styles.collapse_title_conatiner}
        onClick={() => {
          setShowButton((prevState) => !prevState)
        }}
      >
        <div
          className={styles.collapse_title}
        >
          {title || 'Button'}
        </div>

        {showButton ? (
          <KeyboardArrowUp />
        ) : (
          <KeyboardArrowDown />
        )}
      </div>
      <Collapse
        in={showButton}
        unmountOnExit
      >
        <div
          className={styles.collapse_container}
        > 
        
        <GenericSelectWithLabel
          label="Style"
          colorType="secondary"
          value={buttonStyles?.button_style}
          onChange={(event) => handleChangeButtonStyle('button_style', event.target.value)}
          fullWidth={true}
        >
          <MenuItem
            value="solid"
          >
            Solid
          </MenuItem>
          <MenuItem
            value="outline"
          >
            Outline
          </MenuItem> 
        </GenericSelectWithLabel>
        
        <div
          className={styles.collapse_container}
        >
          <SliderWithLabel
            title="Border radius"
            colorType="secondary"
            value={buttonStyles?.button_border_radius}
            handleChange={(event, newValue) => handleChangeButtonStyle('button_border_radius', newValue)} 
            max={50}
          />
        </div>
        
        <div
          className={styles.collapse_container}
        >
          <SliderWithLabel
            title="Font size"
            colorType="secondary"
            value={buttonStyles?.button_font_size}
            handleChange={(event, newValue) => handleChangeButtonStyle('button_font_size', newValue)}
            min={8}
            max={50}
          />
        </div>
        

        {buttonStyles?.button_style === 'outline' && (
          <ColorInputWithLabel
              title="Outline color"
              value={buttonStyles?.button_outline_color}
              onChange={(newValue) => handleChangeButtonStyle('button_outline_color', newValue)} 
            />
        )}

        <ColorInputWithLabel
            title="Background color"
            value={buttonStyles?.button_background_color}
            onChange={(newValue) => handleChangeButtonStyle('button_background_color', newValue)} 
          />

        <ColorInputWithLabel
            title="Text color"
            value={buttonStyles?.button_text_color}
            onChange={(newValue) => handleChangeButtonStyle('button_text_color', newValue)} 
        />

        {!props.hideHorizontalPadding && (
          <div
            className={styles.collapse_container}
          >
            <SliderWithLabel
              title="Horizontal padding"
              colorType="secondary"
              value={buttonStyles?.button_horizontal_padding}
              handleChange={(event, newValue) => handleChangeButtonStyle('button_horizontal_padding', newValue)} 
              max={50}
            />
          </div>
        )}
        
        <div
          className={styles.collapse_container}
        >
          <SliderWithLabel
            title="Vertical padding"
            colorType="secondary"
            value={buttonStyles?.button_vertical_padding}
            handleChange={(event, newValue) => handleChangeButtonStyle('button_vertical_padding', newValue)}
            max={50}
          />
        </div>

        {!props.hidePosition && (
          <GenericSelectWithLabel 
            label="Button position" 
            colorType="secondary" 
            value={buttonStyles?.button_position} 
            onChange={(event) => handleChangeButtonStyle('button_position', event.target.value)} 
            fullWidth={true} >
            <MenuItem
              value="left"
            >
              Left
            </MenuItem>
            <MenuItem
              value="right"
            >
              Right
            </MenuItem> 
            <MenuItem
              value="center"
            >
              Center
            </MenuItem> 
          </GenericSelectWithLabel>
        )}

        </div>
      </Collapse>
    </div>
  )
}

export default ButtonStyleEdit