import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CreateFormDialog.module.scss';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { nodeAxios } from '../../../../../utils/axiosConfig';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
const validationSchema = yup.object({
  title: yup.string().required('Form name is required'),
});

function CreateFormDialog(props) {
  console.log('🚀OUTPUT --> props:', props);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const navigate = useNavigate();

  const handleCreateForm = (values) => {
    // const formData = new FormData();
    let payload = {};
    if (props.selectedForm) {
      payload = props.selectedForm;
    }
    payload.title = values.title;
    payload.type_id = 1;

    console.log('🚀OUTPUT --> payload:', payload);
    // formData.append('title', values.title);
    // formData.append('type_id', 1);

    setIsSaveLoading(true);
    nodeAxios
      .post('/web-builder/web-page-form/create', payload)
      .then((response) => {
        console.log('🚀OUTPUT --> response:', response);

        setIsSaveLoading(false);
        toast.success('Form created successfully!');
        navigate(`/forms/edit/${response.data.data.id}`);
        props.handleFetchListing();
        props.closeDialog();
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        setIsSaveLoading(false);
      });

    // console.log('🚀OUTPUT --> formData:', formData);
  };

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log('🚀OUTPUT --> values:', values);

      handleCreateForm(values);
    },
  });

  useEffect(() => {
    if (props.selectedForm) {
      formik.setValues({
        title: props.selectedForm?.title
      });
    }
  }, []);

  return (
    <form className={styles.add_child} onSubmit={formik.handleSubmit}>
      <div>
        <div className={styles.add_child_header}>
          <div>Form Name</div>
          <IconButton onClick={props.closeDialog}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={styles.add_child_body}>
          <div className={styles.child_info}>
            <Typography variant='caption' display='block' gutterBottom>
              Once website forms are created you can easily use them in your
              website by adding them from “Forms” Section.
            </Typography>

            <TextField
              variant='outlined'
              color='primary'
              name='title'
              label='Enter Form Name *'
              size='small'
              fullWidth
              value={formik.values.title}
              onChange={formik.handleChange}
              // required
              onBlur={formik.handleBlur}
              error={formik.touched.title && formik.values.title === ''}
              helperText={formik.touched.title && formik.errors.title}
            />
          </div>
        </div>
      </div>
      <div className={styles.btns_container}>
        <div className={styles.btns_row}>
          <Button
            variant='outlined'
            color='error'
            sx={{
              background: '#F8F8F8',
              borderColor: '#D3D3D3 !important',
            }}
            onClick={() => props.closeDialog()}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            color='primary'
            type='submit'
            disableElevation
            // fullWidth
            loading={isSaveLoading}
            loadingPosition='end'
            size='medium'
            sx={{
              px: 1,
              py: 0.5,
              background: 'linear-gradient(180deg, #2993FF 0%, #036FDE 100%)',
            }}
            endIcon={<div style={{ width: isSaveLoading ? 20 : 0 }} />}
          >
            Save and continue
          </LoadingButton>
        </div>
      </div>
    </form>
  );
}

export default CreateFormDialog;
