import React from "react";
import defaultLogo from "../assets/default-logo.png";
import { Typography } from "@mui/material";

const DefaultLogo = (props) => {
    return (
        <div
            style={{
                backgroundImage: `url(${defaultLogo})`,
                backgroundSize: "cover",
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
            }}
        >
            <Typography
                style={{
                    color: props.color,
                    fontSize: props.fontSize,
                    fontWeight: "bold",
                    textAlign: 'center',
                    marginLeft: '58px',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                }}
            >
                {props.name}
            </Typography>
        </div>
    );
};

export default DefaultLogo;
