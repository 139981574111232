import React, { useCallback, useContext, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/styles';
import { Button, Tooltip, Dialog, Pagination, Radio, tooltipClasses, useMediaQuery } from '@mui/material';
import { Add, KeyboardArrowRight } from '@mui/icons-material';
import AddNewProductDialog from './AddNewOfferDialog/AddNewOfferDialog';
import axios, { nodeAxios } from '../../../../utils/axiosConfig'
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import MarketingContext from '../../../Marketing2/Util/marketingContext';
import { debounce } from 'lodash';
import Loader from '../../../Library/Loader';
import { useNavigate } from 'react-router-dom';
import AddModifierDialog from './AddOfferDialog/AddOfferDialog';
import { TreeItem } from '@mui/x-tree-view';
import AddPropertiesDialog from '../../../Properties/AddPropertiesDialog/AddPropertiesDialog';
import { reorder } from '../../../CustomPageBuilder/EditSection/Util/helpers';
import ItemsHeader from './ItemsHeader/ItemsHeader';
import { ReactComponent as EmptyPlates } from '../../../../assets/svgs/empty_coupon.svg';
import ItemsTable from './ItemsTable/ItemsTable';
import MobileItems from './MobileItems/MobileItems';
import EmailPreview from './EmailPreview/EmailPreview';


const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle
});

const Items = ({ setActiveModal }) => {

    const { subDomain } = useContext(MarketingContext)
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:1000px)')

    const confirm = useConfirm()
    const [showAddNewProduct, setShowAddNewProduct] = useState(false)

    const [showAddModifier, setShowAddModifier] = useState(false)
    const [showAddProperties, setShowAddProperties] = useState(false)
    const [showCategoryTooltip, setShowCategoryTooltip] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState({})
    const [editObj, setEditObj] = useState({})
    const [editModifierObj, setEditModifierObj] = useState({})
    const [options, setOptions] = useState({})
    const [searchStr, setSearchStr] = useState("");
    const [sort, setSort] = useState({ sort_by: 'sequence_id', sort_by_order: 'ASC' })
    console.log('🚀OUTPUT --> sort:', sort);
    const [catId, setCatId] = useState(0)
    const [venueCategoryMenu, setVenueCategoryMenu] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isEdit2Loading, setIsEdit2Loading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const [selectedCatName, setSelectedCatName] = useState('')
    const [modifiers, setModifiers] = useState([])
    const [properties, setProperties] = useState([])
    const [modalForm, setModalForm] = useState({ couponFor: [], couponCode: '', validFrom: '', validTo: '', discountType: 'dollar', discountAmount: '', orderLimit: '', validAbove: '', firstPurchase: false, is_active: true })
    const [modalEditForm, setModalEditForm] = useState({ id: '', couponFor: [], couponCode: '', validFrom: '', validTo: '', discountType: '', discountAmount: '', orderLimit: '', validAbove: '', firstPurchase: false })
    const [withDiscount, setwithDiscount] = useState(true)


    const handleClick = (event) => {
        // setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        //  setAnchorEl(null);
    };

    const handleClick2 = (event) => {
        //setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        //setAnchorEl2(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const fetchMyList = (page) => {
        setIsListLoading(true)
        nodeAxios.get('/automated-offers/offers', {
            params: {
                page: page || 1,
                limit: 10,
            }
        })
            .then((res) => {
                console.log('automated-offers/offers', res.data?.data)
                setProducts(res.data?.data)

                setPagination(res.data?.data?.pagination)
            })
            .finally(() => {
                setIsSearchLoading(false)
                setIsListLoading(false)
            })

        // axios4.get(`/automated-offers/offers?page=${page}&limit=10`)
        //     .then((res) => {
        //         console.log('automated-offers/offers', res.data?.data)
        //         setProducts(res.data?.data)
        //         // setPagination(res.data?.data?.pagination)
        //     })
        //     .finally(() => {
        //         setIsSearchLoading(false)
        //         setIsListLoading(false)
        //     })
    }




    const handleSearch = (searchStr) => {
        try {
            setIsSearchLoading(true)
            fetchMyList(pagination.current_page, searchStr)
        } catch (err) {
            toast.error('Error occured while fetching inventories, please try again.')
        };
    };

    const debounceSearch = useCallback(debounce(handleSearch, 500), [])


    useEffect(() => {
        fetchMyList()
        //fetchVenueSettings()
    }, [])




    const handleDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete offer',
        })
            .then(() => {
                nodeAxios.delete(`/automated-offers/delete-offer/${id}`)
                    .then(() => {
                        fetchMyList()
                        toast.success('Offer deleted')
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
            })
    }



    const handleEdit2 = (id) => {
        setIsEdit2Loading(true)
        setShowAddNewProduct(true)
        const selectedCoupon = products.filter((products) => products.id === id)
        const selectedCouponObj = selectedCoupon[0]
        console.log('testst', selectedCouponObj);
        setModalEditForm({
            id: id,
            venue_id: selectedCouponObj.venue_id,
            offer_name: selectedCouponObj.offer_name,
            is_active: selectedCouponObj.is_active,
            valid_for: selectedCouponObj.valid_for,
            purchase_frequency: selectedCouponObj.purchase_frequency,
            purchase_amount: selectedCouponObj.purchase_amount,
            in_days: selectedCouponObj.in_days,
            with_discount: selectedCouponObj.with_discount,
            coupon_valid_for: selectedCouponObj.coupon_valid_for?.split(',').map((val) => (val?.[0]?.toUpperCase() + val?.slice(1))),
            coupon_type: selectedCouponObj.coupon_type,
            coupon_limit: selectedCouponObj.coupon_limit,
            email_subject: selectedCouponObj.email_subject,
            email_body: selectedCouponObj.email_body,
        })
        setwithDiscount(selectedCouponObj.with_discount);
        setIsEdit(true)
    }

    const fetchProperty = () => {
        // axios.get('/product-property')
        //     .then((res) => {
        //         setProperties(res.data?.data?.results)
        //     })
    }



    const fetchModifiers = () => {
        // axios.get('/modifier')
        //     .then((res) => {
        //         console.log('🚀OUTPUT --> props.isEdit /modifier:', isEdit);
        //         const data = res.data?.data?.results?.map((data) => ({
        //             ...data,
        //             modifier_id: data?.id,
        //             [isEdit ? 'modifier_options' : 'options']: data.options?.map((data2) => ({ ...data2, is_visible: 1 }))
        //         }))
        //         setModifiers(data)
        //     })
    }
    const handleChangeVisibility = (index) => {
        console.log('handleChangeVisibility called')
        setProducts((prevState) => {
            let newCoupons = [...prevState]
            newCoupons[index].is_active = event.target?.checked
            return newCoupons
        })

        console.log('handleChangeVisibility called>>>', products[index].id)
        let postValues = { is_active: event.target?.checked }
        nodeAxios.put(`/automated-offers/update-offer/${products[index].id}`, postValues)
            .then(() => {
                toast.success('Offer Status Changed')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    };

    const getTreeItem = (child) => {
        return child?.map((data) => (
            <TreeItem icon={(
                <Radio
                    checked={data.category_id == catId}
                    onChange={(event) => {
                        setSelectedCatName(data.category_name)
                        setCatId(event.target.value)
                    }}
                    value={data.category_id}
                />
            )}
                nodeId={data.category_id}
                label={<div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8
                    }}
                >
                    <div>
                        {data.category_name}
                    </div>

                    {Boolean(data?.child?.length) && (
                        <KeyboardArrowRight />
                    )}
                </div>}
            >
                {Boolean(data?.child?.length) && getTreeItem(data?.child)}
            </TreeItem>
        ))
    }
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            products,
            result.source.index,
            result.destination.index
        );

        setProducts(newitems);
        handleSetsequence(newitems)
    };

    return (
        <div className="tw-py-[10px] lg:tw-py-[25px] tw-flex tw-flex-col tw-gap-5">
            <ItemsHeader {...{ sort, setSort, searchStr, setSearchStr, debounceSearch, isSearchLoading, setShowAddNewProduct, isMobile }} />

            {isListLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                    {Boolean(products?.length) && (
                        <>
                            {
                                isMobile ? (
                                    <MobileItems
                                        {...{ products, options, sort, setSort, handleEdit2, handleDelete, handleChangeVisibility, getTreeItem, venueCategoryMenu, catId, setCatId, selectedCatName, setSelectedCatName, handleClick2, handleClose2, anchorEl2, open2, onDragEnd, getItemStyle }}
                                    />
                                ) : (
                                    <ItemsTable
                                        {...{ products, options, sort, setSort, handleEdit2, handleDelete, handleChangeVisibility, getTreeItem, venueCategoryMenu, catId, setCatId, selectedCatName, setSelectedCatName, handleClick2, handleClose2, anchorEl2, open2, onDragEnd, getItemStyle }}
                                    />
                                )}
                        </>
                    )}

                    {!Boolean(products?.length) && (

                        <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-p-8 tw-max-w-[360px] tw-mx-auto tw-mt-12'>
                            <span className='-tw-ml-5'><EmptyPlates /></span>
                            <p className='tw-font-bold tw-text-lg tw-text-center'>You have not created any offer yet</p>
                            <p className='tw-text-[#8B8B8B] tw-text-center tw-text-base mb-2'>Automatically send offers to your customers</p>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: 20
                                }}
                                onClick={() => {
                                    setShowAddNewProduct(true)
                                }}
                            >
                                New Offers
                            </Button>
                        </div>
                    )}

                    {(pagination?.last_page > 1) && (
                        <div
                            style={{
                                padding: '10px 0'
                            }}
                        >
                            <Pagination
                                page={pagination.current_page}
                                count={pagination.last_page}
                                onChange={(e, page) => fetchMyList(page)}
                            />
                        </div>
                    )}
                </Paper>
            )}

            <Dialog
                open={showAddNewProduct}
                onClose={() => {
                    setShowAddNewProduct(false)
                    fetchMyList()
                    setIsEdit(false)
                    setEditObj({})
                }}
                PaperProps={{
                    style: {
                        width: '100%',
                        maxWidth: '650px'
                    }
                }}
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <AddNewProductDialog
                    modalForm={isEdit ? modalEditForm : modalForm}
                    setModalForm={setModalForm}
                    handleDelete={handleDelete}
                    withDiscount={withDiscount}
                    isEdit={isEdit}
                    onClose={() => {
                        setShowAddNewProduct(false)
                        setIsEdit(false)
                        fetchMyList()
                    }}
                />
            </Dialog>

            <Dialog
                open={showAddModifier}
                onClose={() => {
                    setShowAddModifier(false)
                }}
                PaperProps={{
                    style: {
                        width: '100%'
                    }
                }}
                classes={{
                    root: 'tw-font-inter',
                    container: '!tw-items-end sm:!tw-items-center',
                    paper: '!tw-m-0 sm:!tw-m-8 !tw-rounded-t-2xl !tw-rounded-b-none sm:!tw-rounded-t-md sm:!tw-rounded-b-md'
                }}
                keepMounted={false}
            >
                <AddModifierDialog
                    editObj={editModifierObj}
                    isEdit={false}
                    onSuccess={() => {
                        fetchModifiers()
                    }}
                    // handleDelete={handleDelete}
                    onClose={() => {
                        setShowAddModifier(false)
                    }}
                />
            </Dialog>





        </div>
    );
}

export default Items