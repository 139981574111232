import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import routePaths from '../../../config/route-paths'
import Const from '../../helpers/const'
import TwitterLogin from '../../TwitterLogin/TwitterLogin'
import styles from './TwitterButton.module.scss'

const TwitterButton = forwardRef((props, ref) => {

    const navigate = useNavigate()

    const { fetchSocialList } = useOutletContext()

    const onSuccess = (response) => {
        response.json().then(body => {
            toast.success(`${body.screen_name} Connected`)
            fetchSocialList()
            navigate(routePaths.marketingDashboard)
        });
    }

    const onFailed = (error) => {
        toast.error(error)
    }

    return (
        <TwitterLogin
            ref={ref}
            className={clsx(styles.btn, props.twitterBtnClass)}
            text={props.text || "Connect"}
            loginUrl={`${Const.BASE_URL}venue-setting/verifier-twitter`}
            onFailure={onFailed}
            onSuccess={onSuccess}
            requestTokenUrl={`${Const.BASE_URL}venue-setting/reverse-twitter`}
            showIcon={props.showIcon || false}
            forceLogin={false}
        />
    )
})

export default TwitterButton