import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { nodeAxios } from '../../utils/axiosConfig';
import Loader from '../Library/Loader';
import { toast } from 'react-toastify';


const EmailVerifySuccess = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(null);

  const requestVerification = (page_no, prevData) => {
    setIsLoading(true);
    nodeAxios
        .post('/email-settings/update-verification-status') 
        .then(() => {})
        .catch((err) => {
            console.log('🚀OUTPUT --> err:', err);
            toast.error(err.response.data.message);
        })
        .finally(() => {
        setIsLoading(false);
        navigate('/order-settings?section=notifications&subSection=email_notification');
    });
  };

  useEffect(() => {
    requestVerification();
  }, []);

  return (
    <div>

        {isLoading && (
            <Loader />
        )}

    </div>
  )

};

export default EmailVerifySuccess;
