import { Checkbox, FormControlLabel, MenuItem } from '@mui/material'
import React from 'react'
import Const from '../../helpers/const'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import LightTooltip from '../../Library/LightTooltip/LightTooltip'
import ValueTextField from '../../Library/ValueTextField/ValueTextField'
import PickUpIcon from '../../../assets/images/pickup.png'
import styles from './PickupSetting.module.scss'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch'
import BusinessHours from '../../StoreInfo/BusinessHours/BusinessHours'
import PreparationTime from '../PreparationTime/PreparationTime'

const PickupSetting = (props) => {

  return (
    <div
      className={styles.container}
    >

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          padding: '10px 0'
        }}
      >
        <div>
          We offer In-Store Pickup
        </div>
        <GenericSwitch
          checked={Boolean(props.formik.values.is_offer_pickup)}
          onChange={(event) => {
            props.formik.setFieldValue('is_offer_pickup', event.target.checked ? 1 : 0)
          }}
        />

        {Boolean(props.formik.values.is_offer_pickup) && (
          <FormControlLabel
            style={{
              marginLeft: 0,
              gap: 6
            }}
            control={(
              <GenericSwitch
                checked={Boolean(parseInt(props.formik.values?.is_show_no_of_stock?.pickup))}
                onChange={(event) => {
                  props.formik.setFieldValue('is_show_no_of_stock.pickup', event.target.checked ? 1 : 0)
                }}
              />
            )}
            label="Show in-stock number"
            labelPlacement="start"
          />
        )}
      </div>

      {Boolean(props.formik.values.is_offer_pickup) && (
        <div
          className={styles.box}
        >
          <div
            className={styles.image_container}
          >
            <img
              src={PickUpIcon}
            />
          </div>
          <div
            className={styles.fields_container}
          >
            <FormControlLabel
              control={(
                <Checkbox
                  checked={props.formik.values.pickup_service_charge_to_customer}
                  onChange={(event) => {
                    props.formik.setFieldValue('pickup_service_charge_to_customer', event.target.checked ? 1 : 0)
                  }}
                  color="default"
                />
              )}
              label={
                <span
                  style={{
                    textDecoration: 'underline'
                  }}
                >
                  <LightTooltip
                    title={
                      <span
                      // style={{
                      //   display: 'inline-block',
                      //   padding: '0 15px'
                      // }}
                      >
                        If this checkbox is enabled, this fees will be added to each order to offset your store fees.
                      </span>
                    }
                    arrow={true}
                    placement="top"
                  >
                    <span>
                      Add platform Service
                    </span>
                  </LightTooltip>
                  Charge of {props.formik.values.pickup_fee} to customer order.
                </span>
              }
            />

            <div
              className={styles.input_fields_container}
            >
              <div>
                <GenericSelectWithLabel
                  id="pickupfeestype"
                  name="pickupfeestype"
                  label={
                    <LightTooltip
                      title={
                        <span
                          style={{ display: 'inline-block', padding: '0 15px' }}
                        >
                          If you charge flat fees, choose "Dollar". Choose "Percent", if you want us  to calculate the fees based on order value.
                        </span>
                      }
                      arrow={true}
                      placement="top"
                    >
                      <div>Pickup Fee Type</div>
                    </LightTooltip>
                  }
                  containerClassname={styles.small_input_container}
                  value={props.formik.values.pickupfeestype}
                  onChange={props.formik.handleChange}
                >
                  <MenuItem value="percentage">Percentage</MenuItem>
                  <MenuItem value="dollar">Dollar</MenuItem>
                </GenericSelectWithLabel>
              </div>

              <div
                className={styles.input_field}
              >
                <ValueTextField
                  id="pickupfees"
                  name="pickupfees"
                  label={
                    <LightTooltip
                      title={
                        <span
                          style={{ display: 'inline-block', padding: '0 15px' }}
                        >
                          Enter Pickup fees here
                        </span>
                      }
                      arrow={true}
                      placement="top"
                    >
                      <div>Pickup Fees</div>
                    </LightTooltip>
                  }
                  type={props.formik.values.pickupfeestype}
                  value={props.formik.values.pickupfees}
                  onChange={props.formik.handleChange}
                />
              </div>


              <div
                className={styles.input_field}
              >
                <ValueTextField
                  id="minpickupamount"
                  name="minpickupamount"
                  label={
                    <LightTooltip
                      title={
                        <span
                          style={{ display: 'inline-block', padding: '0 15px' }}
                        >
                          Only orders with value greater than this amount will be allowed to process. We recommend to have a low value here to get more pickup orders.
                        </span>
                      }
                      arrow={true}
                      placement="top"
                    >
                      <div>
                        Min. Pickup Amount
                      </div>
                    </LightTooltip>
                  }
                  type="dollar"
                  value={props.formik.values.minpickupamount}
                  onChange={props.formik.handleChange}
                />
              </div>

            </div>
          </div>
        </div>
      )}

      {Boolean(props.formik.values.is_offer_pickup) && (
        <div
          style={{
            marginTop: 15
          }}
        >
          <BusinessHours
            formik={props.formik}
          />
        </div>
      )}
    </div>
  )
}

export default PickupSetting