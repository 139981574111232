import axios from "../../utils/axiosConfig";
import { nanoid } from "nanoid";
import { bizBaseurl } from "../../env";
const token = localStorage.getItem("userToken");

export const getTagList = (params) => {
  try {
    return async (dispatch) => {
      let {
        data
      } = await axios.get(`/tag/list?${params?.query ? `q=${params.query}` : ""}`);
      dispatch({
        type: "SET_TAG_LIST",
        payload: data.data.result,
      });
    };
  } catch (err) {
    throw err;
  }
};

export const deleteTag = (params) => {
    return (dispatch) => {
      return axios.delete(`/tag/delete/${params.id}`).then(() => {
        dispatch({
          type: "DELETE_TAG",
          payload: params.id
        });
        return Promise.resolve()
      }).catch((err) => {
        return Promise.reject(err.response.data.message)
      });
    };
};


export const updateTag = (params) => {
  try {
    return async (dispatch) => {
      let {
        data
      } = await axios.post(`/tag/edit/${params.id}`, {
        title: params.title
      });
      dispatch({
        type: "UPDATE_TAG",
        payload: {
          id: params.id,
          title: params.title
        }
      });
    };
  } catch (err) {
    throw err;
  }
};

export const getProductsFromTag = (params) => {
  try {
    return async (dispatch) => {
      let {
        data
      } = await axios.get(`/tag/get-products/${params.tagId}?${params.searchStr ? `q=${params.searchStr}` : ''}`);
      console.log(data);
      dispatch({
        type: "GET_TAG_PRODUCTS",
        payload: data.data.result
      });
    };
  } catch (err) {
    throw err;
  }
};

export const deleteProductFromTag = (params) => {
  try {
    return async (dispatch) => {
      let {
        data
      } = await axios.delete(`/tag/remove-tag-from-tag-product/${params.tagId}/${params.productId}`);
      dispatch({
        type: "DELETE_PRODUCT",
        payload: params.productId
      });
    };
  } catch (err) {
    throw err;
  }
}

export const createTag = (params) => {
  try {
    return async (dispatch) => {
      let {
        data
      } = await axios.post(`/tag/create`, {
        title: params.title
      });
      dispatch({
        type: "CREATE_TAG",
        payload: data.data.result
      });
    };
  } catch (err) {
    throw err;
  };
};

export const getTagProductsToAdd = (params) => {
  try {
    return async (dispatch) => {
      let {
        data
      } = await axios.get(`/tag/get-product-to-add/${params.tagId}?q=${params.q}&sortby=${params.sortby}&page=${params.page || 1}`);
      dispatch({
        type: "GET_TAG_PRODUCTS_TO_ADD",
        payload: data.data
      });
    };
  } catch (err) {
    throw err;
  }
};

export const addProductToTag = ({ tagId, productId }) => {
  try {
    return async (dispatch) => {
      let {
        data
      } = await axios.post(`/tag/add-product-to-tag/${tagId}/${productId}`, {
        productId
      });
      dispatch({
        type: "ADD_PRODUCT_TO_TAG",
        payload: {
          newProduct: data.data.result,
          productId: productId
        }
      });
    };
  } catch (err) {
    throw err;
  };
}