import React from 'react';
import { TextField, Typography } from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    textInput: {
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: "100%",
        padding: '0 !important',
        "& > input": {
            padding: "12px 10px 12px 10px !important",
        },
    }
}))

const RadiusInput = (props) => {
    const classes = useStyles(props)
    return (
        <div style={{display: 'flex'}}>
            <TextField
                InputProps={{
                    classes: {
                        root: classes.textInput
                    }
                }}
                onChange={e => props.onChange(e.target.value)}
                value={props.value}
                variant='outlined'
                style={{ width: "100%", margin: 'auto'}}
                disabled={props.isDisabled}
            />
            <div style={{display: 'flex', alignItems: 'center',backgroundColor: 'transparent', justifyContent: 'center',border: '1px solid #969696',  marginTop: '1px', width: 60, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                <Typography style={{fontSize: '.75rem'}}>Miles</Typography>
            </div>
        </div>
    )
};


export default RadiusInput;