import React from 'react'
import { styled } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Button } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DragIndicator, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';



const columns = [
    {
        id: 'category_name',
        label: 'Name',
        width: 180,
        styles: {
            maxWidth: 180,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    {
        id: 'description',
        label: 'Description',
    },
];


function CategoriesTable({ venueCategoryMenu, handleDelete, setIsEdit, setCat, setShowAddCategory, onDragEnd, sort, setSort, getItemStyle, isActionDisabled }) {
    return (
        <TableContainer component={Paper} sx={{ boxShadow: 'none', borderBottom: '1px solid #d3d3d3', borderRadius: '0' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, width: column.width }}
                                className='!tw-p-1'
                            // onClick={() => {
                            //     if (column.id === 'category_name') {
                            //         if (sort.sort_by === column.sortid) {
                            //             if (sort.sort_by_order === 'ASC') {
                            //                 setSort({ ...sort, sort_by_order: 'DESC' })
                            //             } else {
                            //                 setSort({ ...sort, sort_by_order: 'ASC' })
                            //             }
                            //         } else {
                            //             setSort({ sort_by_order: 'DESC', sort_by: column.sortid })
                            //         }
                            //     }
                            // }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 5,
                                        alignItems: 'center'
                                    }}
                                >
                                    <div>
                                        {column.label}
                                    </div>

                                    {/* {column.id === 'category_name' && 
                                (((sort.sort_by === column.sortid) && (sort.sort_by_order === 'DESC')) ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                ))} */}
                                </div>
                            </TableCell>
                        ))}
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold !tw-w-20' />
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold !tw-w-20' />
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold !tw-w-20' />
                    </TableRow>
                </TableHead>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <TableBody
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    pointerEvents: isActionDisabled ? 'none' : 'all'
                                }}
                            >
                                {venueCategoryMenu.map((row, index) => (
                                    <Draggable
                                        key={row.id}
                                        draggableId={"q-" + row.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <TableRow
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align} style={column.styles && { ...column.styles }} className='!tw-p-1.5 !tw-border-b-0'>
                                                            <div
                                                                className={(column?.id == 'ProductName') && ' tw-cursor-pointer tw-text-[#0071C3] hover:tw-underline '}
                                                                onClick={() => {
                                                                    if ((column?.id == 'ProductName')) {
                                                                        setIsEdit(true)
                                                                        setCat(row)
                                                                        setShowAddCategory(true)
                                                                    }
                                                                }}
                                                                title={column.format ? column.format(value, row.track_quantity) : value}
                                                            >
                                                                {column.format ? column.format(value, row.track_quantity) : value}
                                                            </div>
                                                        </TableCell>
                                                    );
                                                })}

                                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            setIsEdit(true)
                                                            setCat(row)
                                                            setShowAddCategory(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>


                                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                                    <Button
                                                        style={{
                                                            color: 'red'
                                                        }}
                                                        variant="text"
                                                        onClick={() => {
                                                            handleDelete(row)
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                </TableCell>

                                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                                    <DragIndicator />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </DragDropContext>
                {/* </TableBody> */}
            </Table>
        </TableContainer>
    )
}

export default CategoriesTable