import React from 'react'
import styles from './SocialMedia.module.scss'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import FacebookIcon from '../../../assets/facebook.png'
import InstagramIcon from '../../../assets/instagram.png'
import TwitterIcon from '../../../assets/twitter.png'
import SnapchatIcon from '../../../assets/images/snapchat.jpg'
import TikTokIcon from '../../../assets/images/tiktok.png'
import LinkedInIcon from '../../../assets/images/linkedin.png'
import YoutubeIcon from '../../../assets/images/youtube.png'
import PinterestIcon from '../../../assets/images/pinterest.png'

const SocialMedia = ({ formik }) => {
    return (
        <div
            className={styles.box_container}
        >

            <div className={styles.social}>Social Media</div>

            <div className={styles.social_list}>
                <div
                    className={styles.label_text}
                >
                    Facebook
                </div>

                <div
                    className={styles.social_input_container}
                >
                    <img
                        src={FacebookIcon}
                        height={43}
                    />
                    <div
                        className={styles.fixed_text}
                    >
                        https://facebook.com/
                    </div>
                    <GenericTextFieldWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        id="social_links.facebook_url"
                        name="social_links.facebook_url"
                        value={formik.values.social_links?.facebook_url}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />
                </div>
            </div>

            <div>
                <div
                    className={styles.label_text}
                >
                    Instagram
                </div>

                <div
                    className={styles.social_input_container}
                >
                    <img
                        src={InstagramIcon}
                        height={43}
                    />
                    <div
                        className={styles.fixed_text}
                    >
                        https://instagram.com/
                    </div>
                    <GenericTextFieldWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        id="social_links.instagram_url"
                        name="social_links.instagram_url"
                        value={formik.values?.social_links?.instagram_url}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />
                </div>
            </div>


            <div>
                <div
                    className={styles.label_text}
                >
                    Twitter
                </div>

                <div
                    className={styles.social_input_container}
                >
                    <img
                        src={TwitterIcon}
                        height={43}
                    />
                    <div
                        className={styles.fixed_text}
                    >
                        https://twitter.com/
                    </div>
                    <GenericTextFieldWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        id="social_links.twitter_url"
                        name="social_links.twitter_url"
                        value={formik.values?.social_links?.twitter_url}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />
                </div>
            </div>

            <div>
                <div
                    className={styles.label_text}
                >
                    Snapchat
                </div>

                <div
                    className={styles.social_input_container}
                >
                    <img
                        src={SnapchatIcon}
                        height={43}
                    />
                    <div
                        className={styles.fixed_text}
                    >
                        https://www.snapchat.com/
                    </div>
                    <GenericTextFieldWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        id="social_links.snapchat_url"
                        name="social_links.snapchat_url"
                        value={formik.values?.social_links?.snapchat_url}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />
                </div>
            </div>

            <div>
                <div
                    className={styles.label_text}
                >
                    TikTok
                </div>

                <div
                    className={styles.social_input_container}
                >
                    <img
                        src={TikTokIcon}
                        height={43}
                    />
                    <div
                        className={styles.fixed_text}
                    >
                        https://www.tiktok.com/
                    </div>
                    <GenericTextFieldWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        id="social_links.tiktok_url"
                        name="social_links.tiktok_url"
                        value={formik.values?.social_links?.tiktok_url}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />
                </div>
            </div>

            <div>
                <div
                    className={styles.label_text}
                >
                    LinkedIn
                </div>

                <div
                    className={styles.social_input_container}
                >
                    <img
                        src={LinkedInIcon}
                        height={43}
                    />
                    <div
                        className={styles.fixed_text}
                    >
                        https://www.linkedin.com/
                    </div>
                    <GenericTextFieldWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        id="social_links.linkedin_url"
                        name="social_links.linkedin_url"
                        value={formik.values?.social_links?.linkedin_url}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />
                </div>
            </div>

            <div>
                <div
                    className={styles.label_text}
                >
                    Youtube
                </div>

                <div
                    className={styles.social_input_container}
                >
                    <img
                        src={YoutubeIcon}
                        height={43}
                    />
                    <div
                        className={styles.fixed_text}
                    >
                        https://youtube.com/
                    </div>
                    <GenericTextFieldWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        id="social_links.youtube_url"
                        name="social_links.youtube_url"
                        value={formik.values?.social_links?.youtube_url}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />
                </div>
            </div>

            <div>
                <div
                    className={styles.label_text}
                >
                    Pinterest
                </div>

                <div
                    className={styles.social_input_container}
                >
                    <img
                        src={PinterestIcon}
                        height={43}
                    />
                    <div
                        className={styles.fixed_text}
                    >
                        https://pinterest.com/
                    </div>
                    <GenericTextFieldWithLabel
                        containerStyle={{
                            width: '100%'
                        }}
                        id="social_links.pinterest_url"
                        name="social_links.pinterest_url"
                        value={formik.values?.social_links?.pinterest_url}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default SocialMedia