import React from 'react';
import { LinearProgress, Box, Typography, linearProgressClasses, styled } from '@mui/material';

function CustomProgressBar(props) {
    const { value } = props; // Value should be between 0 and 100

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            width:'100%'
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? 'black' : '#308fe8',
        },
    }));

    return (
        <BorderLinearProgress variant="determinate" value={value} />
    );
}

export default CustomProgressBar;
