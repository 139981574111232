import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  TextField,
  FormControl,
  Select,
  Button,
  Pagination
} from "@mui/material";
import { Close, SearchOutlined } from "@mui/icons-material";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getTagProductsToAdd,
  addProductToTag,
} from "../../../../redux/actions/tagListActions";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  textInput: {
    borderRadius: "0",
    width: "100%",
    padding: "0 7px !important",
    border: "none",
    "& > input": {
      padding: `11px 6px !important`,
    },
  },
  select: {
    backgroundColor: "white",
    borderRadius: 0,
    border: "1px solid #DEDAD9",
    transition: "none",
    "& > select": {
      padding: "10px 65px 10px 10px !important",
      fontSize: ".93rem",
      "&:focus": {
        backgroundColor: "white",
      },
    },
    "&:hover": {
      backgroundColor: "white",
    },
  },
  formControl: {
    flex: 1,
  },
  productsListsItem: {
    "&:nth-child(odd)": {
      background: "#FCFCFC",
    },
    borderBottom: "1px solid #F0F0F0",
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

const Modal = (props) => {
  const modalRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const tagProductsToAdd = useSelector(
    (state) => state.tagList.tagProductsToAdd
  );

  const [search, setSearch] = useState("");
  const [sortby, setSortby] = useState("");
  const handleClose = () => {
    if (props.active) {
      props.setActiveModal({ name: "none", props: {} });
    }
  };
  useEffect(() => {
    if (props.secondaryModal?.props?.tagId) {
      dispatch(
        getTagProductsToAdd({
          tagId: props.secondaryModal?.props?.tagId,
          q: "",
          sortby: "",
        })
      );
    }
  }, [props.active]);
  const addProduct = (pId) => {
    dispatch(
      addProductToTag({
        tagId: props.secondaryModal?.props?.tagId,
        productId: pId,
      })
    ).then(() => {
      toast.success('Product was updated successfully')
    });
  };
  const handleSearch = (val) => {
    if (props.secondaryModal?.props?.tagId) {
      dispatch(
        getTagProductsToAdd({
          tagId: props.secondaryModal?.props?.tagId,
          q: val,
          sortby: sortby,
        })
      );
      setSearch(val);
    }
  };

  const paginate = (e, page) => {
    dispatch(
      getTagProductsToAdd({
        tagId: props.secondaryModal?.props?.tagId,
        q: search,
        sortby: sortby,
        page: page,
      })
    );
  };

  const handleSortBy = (val) => {
    if (props.secondaryModal?.props?.tagId) {
      dispatch(
        getTagProductsToAdd({
          tagId: props.secondaryModal?.props?.tagId,
          q: search,
          sortby: val,
        })
      );
      setSortby(val);
    }
  };
  useClickOutside(modalRef, handleClose);
  return (
    <div
      ref={modalRef}
      className="inventory-modal"
      style={{
        position: "fixed",
        display: props.active === true ? "initial" : "none",
        backgroundColor: "white",
        zIndex: "999999999",
        top: "30%",
        left: "60%",
        width: "47vw",
        transform: "translate(-60%,-25%)",
        boxShadow: "5px 5px 15px -1px rgba(0,0,0,0.54)",
      }}
    >
      <div style={{ padding: "20px 28px 28px 28px" }}>
        <div
          style={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "35px",
            marginTop: "7px",
          }}
        >
          <div
            onClick={handleClose}
            style={{
              fontSize: "23px",
              cursor: "pointer",
              position: "absolute",
              left: "95%",
              transform: "translateY(-2px)",
              color: "black",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Close fontSize="inherit" color="inherit" />
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ flex: 1, marginRight: "10px" }}>
              <TextField
                onChange={(e) => handleSearch(e.target.value)}
                value={search}
                variant="outlined"
                style={{ width: "100%", margin: "auto" }}
                placeholder="Search Product"
                InputProps={{
                  endAdornment: (
                    <SearchOutlined style={{ color: "#C8C8C8" }} />
                  ),
                  classes: {
                    root: classes.textInput,
                  },
                }}
              />
            </div>
            <div>
              <FormControl
                size="small"
                variant="filled"
                className={classes.formControl}
              >
                <Select
                  onChange={(e) => handleSortBy(e.target.value)}
                  className={classes.select}
                  disableUnderline
                  native
                >
                  <option value="">Sort By</option>
                  <option value="nameaz">Name A-Z</option>
                  <option value="nameza">Name Z-A</option>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <div
              style={{
                border: "1px solid #F4F4F4",
                marginTop: "2%",
                height: "100%",
                minHeight: "30vh",
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {tagProductsToAdd.result.map((val) => {
                return (
                  <div
                    className={classes.productsListsItem}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "7px 10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1rem",
                        textAlign: "center",
                        fontWeight: 400,
                      }}
                    >
                      {val.Name}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={(e) => addProduct(val.ProductId)}
                        style={{
                          backgroundColor: "#343434",
                          color: "white",
                          padding: "7px 20px",
                          borderRadius: 0,
                          margin: "0px 10px",
                        }}
                      >
                        Add To Tag
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <Pagination
              style={{ marginTop: 15 }}
              size="small"
              page={tagProductsToAdd.pagination.current_page || 1}
              count={tagProductsToAdd.pagination.last_page || 1}
              onChange={paginate}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
