const initialState = {
    storeInfo: {
        venue: {},
        venue_hours: [],
        venue_social_links: [],
    },
    bankingInfo: {
        legal_name: "",
        dba_name: "",
        tax_id: "",
        bank_routing_no: "",
        bank_account_no: "",
        birthdate: "",
        first_name: "",
        last_name: "",
        terms_accepted: false,
    },
    licenseInfo: {
        license_no: "",
        file: {},
        license_file_name: ""
    },
    branding: {
        no_logo: false,
        image: {},
    }
};

function venueInfo(state = initialState, action) {
    switch (action.type) {
        case "SET_STORE_INFO":
            return {
                ...state,
                storeInfo: action.payload,
            };
        case "SET_BANKING_INFO":
            return {
                ...state,
                bankingInfo: {
                    ...action.payload,
                },
            };
        case "SET_LICENSE_INFO":
            return {
                ...state,
                licenseInfo: {
                    ...action.payload,
                },
            };
        case "SET_BRANDING":
            return {
                ...state,
                branding: {
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}

export default venueInfo;
