import React, { useState } from 'react'
import styles from './LinkSelector.module.scss'
import { Checkbox, FormControlLabel, InputAdornment, inputBaseClasses, MenuItem, outlinedInputClasses, Select, selectClasses, styled, TextField } from '@mui/material';
import clsx from 'clsx';

const GenericTextField = styled((props) => <TextField {...props} />)(
    ({ theme, variant }) => ({
        [`& .${outlinedInputClasses.notchedOutline}`]: {
            border: '1px solid #d3d3d3 !important',
            outline: 'none',
            color: '#2e2e2e',
        },
        [`& .${outlinedInputClasses.input}`]: {
            fontSize: 14,
            padding: '7.5px 7px 7.5px 7px',
        },
        [`& .${outlinedInputClasses.root}`]: {
            height: 38,
            backgroundColor: variant == 'outlined' ? 'transparent' : '#efefef',
        },
    })
)

const GenericSelect = styled((props) => (
    <Select
        {...props}
    />
))(({ theme }) => ({
    padding: 0,
    [`& .${outlinedInputClasses.notchedOutline}`]: {
        minHeight: 36,
        border: '1px solid #d3d3d3 !important',
        height: 42,
    },
    [`& .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input}.${selectClasses.select}`]: {
        padding: '8px 30px 6px 14px'
    }
}))

const LinkSelector = ({ label, MenuProps, containerStyle, containerClassname, pages, linkType, isSelectDisabled, isInputDisabled, pagesSelectDisabled, colorType, externalLinkOnly, tab, onTabChange, ...rest }) => {

    const [type, setType] = useState(linkType || 'pages')

    return (
        <div
            style={containerStyle}
            className={clsx(styles.container, containerClassname)}
        >
            <div
                className={clsx(styles.label_text, (colorType === 'secondary') && styles.label_text_2)}
            >
                {label}
                {rest.required && (
                    <span
                        className={styles.required_asterisk}
                    >
                        *
                    </span>
                )}
            </div>

            <div
                style={{
                    display: 'flex'
                }}
            >
                {!externalLinkOnly && (
                    <GenericSelect
                        style={{
                            backgroundColor: '#EEEEEE',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            fontSize: 12
                        }}
                        value={type}
                        onChange={(event) => {
                            setType(event.target.value)
                        }}
                        disabled={isSelectDisabled}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="pages">Pages</MenuItem>
                        <MenuItem value="external">External</MenuItem>
                    </GenericSelect>
                )}

                {(type === 'pages' && !externalLinkOnly) ? (
                    <GenericSelect
                        {...rest}
                        value={rest.value ? rest.value : 'none'}
                        style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                        }}
                        disabled={pagesSelectDisabled}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="none">Select pages</MenuItem>
                        {pages?.map((data) => (
                            <MenuItem value={data.uri}>{data.title}</MenuItem>
                        ))}
                    </GenericSelect>
                ) : (
                    <GenericTextField
                        style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                        }}
                        placeholder="Paste website address here"
                        {...rest}
                        disabled={isInputDisabled}
                    />
                )}
            </div>

            {onTabChange && (
                <FormControlLabel
                    control={(
                        <Checkbox
                            size="small"
                            checked={tab == '_blank'}
                            onChange={(event) => {
                                let val = "_self"
                                if (event.target.checked) {
                                    val = "_blank"
                                } else {
                                    val = "_self"
                                }
                                onTabChange(val)
                            }}
                        />
                    )}
                    label="Open link in a new page"
                />
            )}
        </div>
    )
}

export default LinkSelector