import React, { lazy, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { nodeAxios } from '../../utils/axiosConfig';
;

const ChangeLog = lazy(
  () => import('../ChangeLog/ChangeLog'),
);

const ChangeLogMobile = lazy(
  () => import('../Mobile/ChangeLog/ChangeLog'),
);

const ChangeLogRoute = () => {

  const [changeLogs, setChangeLogs] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const isTablet = useMediaQuery('(max-width:1024px)');

  const fetchChangelog = (page_no, prevData) => {
    if(!prevData) {
      setChangeLogs(null);
      setIsLoading(true);
    }
    nodeAxios
      .get('/change-log/list', { params : {
        page: page_no || 1,
      } }) // 
      .then((response) => {
        if (prevData) {
          setChangeLogs({
            data: [...prevData, ...response.data?.data],
            pagination: response.data?.pagination,
          })
        } else {
          setChangeLogs({
            data: response.data?.data,
            pagination: response.data?.pagination,
          });
        }
      })
      .catch((error) => {
        setChangeLogs({
          data: [],
        });
      }).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchChangelog();
  }, []);

  return isTablet ? (
    <ChangeLogMobile changeLogs={changeLogs} fetchChangelog={fetchChangelog} isLoading={isLoading} />
  ) : (
    <ChangeLog changeLogs={changeLogs} fetchChangelog={fetchChangelog} isLoading={isLoading} />
  )

};

export default ChangeLogRoute;
