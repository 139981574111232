import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Search } from '@mui/icons-material'
import ProductChip from '../Library/ProductChip/ProductChip'
import { CircularProgress, Collapse, RadioGroup } from '@mui/material'
import InputField from '../Library/InputField/InputField'
import styles from './SearchProduct.module.scss'
import axios from '../../utils/axiosConfig'
import { InView } from 'react-intersection-observer'

const SearchProduct = (props) => {

    const [productQuery, setProductQuery] = useState('')
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState()
    const [searchIsLoading, setSearchIsLoading] = useState(false)
    const [isSearchOpen, setIsSearchOpen] = useState(true)
    const [canShowNoProducts, setCanShowNoProducts] = useState(false)

    const fetchMasterList = (query, sortby, page = 1, searchChange) => {
        setSearchIsLoading(true)
        axios.get(props.isVenueList ? '/product/my-product-list' : '/product/master-list', {
            params: {
                limit: 20,
                q: query,
                sortby: sortby,
                page: page
            }
        })
            .then((response) => {
                setIsSearchOpen(true)
                if (searchChange) {
                    setProducts(props.isVenueList ? response.data.data.result : response.data.data.list)
                } else {
                    setProducts((prevState) => props.isVenueList ? [...prevState, ...response.data.data.result] : [...prevState, ...response.data.data.list])
                }

                if (props.isVenueList ? response.data.data.result.length : response.data.data.list.length) {
                    setCanShowNoProducts(false)
                } else {
                    setCanShowNoProducts(true)
                }
                setPagination(response.data.data.pagination)
            })
            .finally(() => {
                setSearchIsLoading(false)
            })
    }

    // useEffect(() => {
    //     // fetchMasterList()
    // }, [])


    const debounceSearchProduct = useCallback(debounce(fetchMasterList, 1000), [])

    return (
        <div>
            <InputField
                style={{
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 32%)'
                }}
                placeholder="Search Product"
                value={productQuery}
                onChange={(event) => {
                    setProductQuery(event.target.value)
                    debounceSearchProduct(event.target.value, undefined, 1, true)
                }}
                showIconButton={true}
                endIcon={searchIsLoading ? (
                    <CircularProgress
                        style={{
                            color: '#ef2d2c'
                        }}
                        size={14}
                    />
                ) : (
                    <Search
                        style={{
                            color: '#ef2d2c'
                        }}
                    />
                )}
            />

            <RadioGroup>

                {(!Boolean(products.length) && (Boolean(productQuery)) && (canShowNoProducts)) && (
                    <div
                        className={styles.no_products_container}
                    >
                        No Products Found
                    </div>
                )}

                {Boolean(products.length) && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <small
                            style={{
                                paddingTop: 5,
                                paddingRight: 5,
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={() => {
                                setIsSearchOpen((prevState) => !prevState)
                            }}
                        >
                            {isSearchOpen ? "-Minimize" : "+Expand"}
                        </small>
                    </div>
                )}

                <Collapse
                    in={isSearchOpen}
                >
                    <div
                        className={styles.products_container}
                    >
                        {products.map((data) => (
                            <ProductChip
                                key={data.ProductId}
                                style={{
                                    cursor: 'pointer'
                                }}
                                image={data.image_file || data.image}
                                name={data.Name || `${data.ProductName} - ${data.ProducerName}`}
                                value={data.ProductId}
                                isRadio={true}
                                wholeChipClick={true}
                                selectedProductId={props.selectedProductId}
                                onClick={() => {
                                    props.onProductChipClick(data)
                                    setIsSearchOpen(false)
                                }}
                            />
                        ))}

                        {Boolean(products.length) && (
                            <InView
                                style={{
                                    display: (pagination.last_page === pagination.current_page) ? 'none' : 'block'
                                }}
                                as="div"
                                triggerOnce={false}
                                onChange={(inView, entry) => {
                                    if (inView) {
                                        if ((pagination.current_page + 1) <= pagination.last_page)
                                            fetchMasterList(productQuery, undefined, pagination.current_page + 1)
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CircularProgress
                                        size={26}
                                    />
                                </div>
                            </InView>
                        )}
                    </div>
                </Collapse>
            </RadioGroup>
        </div>
    )
}

export default SearchProduct