import { Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, RadioGroup, Tab, Tabs, TextField } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import GenericButton from '../../Library/GenericButton/GenericButton'
import GenericRadio from '../../Library/GenericRadio/GenericRadio'
import PreviewPost from '../PreviewPost/PreviewPost'
import SchedulePostSection from '../SchedulePostSection/SchedulePostSection'
import styles from './CreatePost.module.scss'
import _without from 'lodash/without'
import axios from '../../../utils/axiosConfig'
import PublishToSection from '../PublishToSection/PublishToSection'
import { toast } from 'react-toastify'
import SearchProduct from '../../SearchProduct/SearchProduct'
import RadioSelector from '../../Library/RadioSelector/RadioSelector'
import { format } from 'date-fns'
import AddSocialAccountDialog from '../../Dialogs/AddSocialAccountDialog/AddSocialAccountDialog'
import { Close, DeleteForever, Edit, Instagram } from '@mui/icons-material'
import { FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa'
import Const from '../../helpers/const'
import ImageGalleryDialog from '../../Dialogs/ImageGalleryDialog/ImageGalleryDialog'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import clsx from 'clsx'

const createPostTypes = [
    {
        value: 1,
        label: 'Create your own Post'
    },
    {
        value: 0,
        label: 'Select a Product to Post'
    }
]

const localizer = momentLocalizer(moment)

const myEventsList = [
    {
        id: 1,
        title: "Tasting",
        event_date: "28-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: true
    },
    {
        id: 1,
        title: "Tast ing",
        event_date: "29-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: true
    },
    {
        id: 1,
        title: "Tasti",
        event_date: "27-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: true
    },
    {
        id: 1,
        title: "Tasg",
        event_date: "26-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: false
    },
    {
        id: 1,
        title: "Tasg",
        event_date: "30-11-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: false
    },
    {
        id: 1,
        title: "Tasg",
        event_date: "1-12-2022",
        start_time: "14:32",
        end_time: "15:49",
        image: "https://esplanda-static-store-development.esplanda.com/ourliquorstore/Event/1663951803-14_march_Daylight_Saving_Time_starts.jpg",
        image_type: null,
        address: "142 Pleasant Valley Street, Methuen, MA, USA",
        lat: "42.746994",
        lng: "-71.14525280000001",
        isDone: false
    }
]


const CreatePost = ({ pages, names, externalWebsite, subDomain }) => {

    const [radioValue, setRadioValue] = useState(1);
    const [file, setFile] = useState(null)
    const [link, setLink] = useState(null)

    const fileRef = useRef()
    const [selectedProductId, setSelectedProductId] = useState('')
    const [platformName, setPlatformName] = useState([]);
    const [caption, setCaption] = useState('')
    const [schedulePost, setSchedulePost] = useState(false)
    const [isLoading1, setIsLoading1] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [isLoading3, setIsLoading3] = useState(false)
    // const [scheduleTime, setScheduleTime] = useState(new Date())
    const [scheduleTime, setScheduleTime] = useState(format(new Date(), 'yyyy-MM-dd HH:mm').replace(' ', 'T'))
    const [showAddSocialDialog, setShowAddSocialDialog] = useState(false)
    const [tabValue, setTabValue] = useState(0)
    const [showFb, setShowFb] = useState(false)
    const [showInsta, setShowInsta] = useState(false)
    const [showTwitter, setShowTwitter] = useState(false)
    const [page, setPage] = useState('calendar')
    const [showImageGallery, setShowImageGallery] = useState(false)
    const [bannerCatList, setBannerCatList] = useState([])
    const [currentGallery, setCurrentGallery] = useState([])
    const [isGalleryLoading, setIsGalleryLoading] = useState(false)
    const [bannerCat, setBannerCat] = useState('undefined')

    const contentTabs = useMemo(() => [
        {
            value: 0,
            label: <FaFacebookSquare style={{ fill: 'white', fontSize: 24 }} />,
            show: showFb,
            bgstyle: styles.fb_bg
        },
        {
            value: 1,
            label: <FaTwitter style={{ fill: 'white', fontSize: 24 }} />,
            show: showTwitter,
            bgstyle: styles.tw_bg
        },
        {
            value: 2,
            label: <FaInstagram style={{ fill: 'white', fontSize: 24 }} />,
            show: showInsta,
            bgstyle: styles.in_bg
        }
    ], [showFb, showInsta, showTwitter])

    useEffect(() => {
        if (platformName.filter((data) => data.isFacebook).length) {
            setShowFb(true)
        } else {
            setShowFb(false)
        }
        if (platformName.filter((data) => data.isInstagram).length) {
            setShowInsta(true)
        } else {
            setShowInsta(false)
        }
        if (platformName.filter((data) => data.isTwitter).length) {
            setShowTwitter(true)
        } else {
            setShowTwitter(false)
        }
    }, [platformName])

    const fetchBannerCat = () => {
        axios.get('/gallery/categories')
            .then((response) => {
                setBannerCatList(response.data.data.result)
            })
    }

    const fetchBannerGallery = (id) => {
        setIsGalleryLoading(true)
        axios.get('/gallery/list', {
            params: {
                category_id: id == 'undefined' ? undefined : id
            }
        })
            .then((response) => {
                setCurrentGallery(response.data.data.result.map((data) => ({ ...data, image: response.data.data.gallery_image + data.image })))
            })
            .finally(() => {
                setIsGalleryLoading(false)
            })
    }

    useEffect(() => {
        fetchBannerCat()
        fetchBannerGallery()
    }, [])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangePlatform = (data) => {
        console.log(data)

        setPlatformName((prevState) => {
            if (prevState.filter((data1) => data1.id == data.id).length) {
                return _without(prevState, data)
            } else {

                if (data.isFacebook) {
                    setTabValue(0)
                }
                if (data.isInstagram) {
                    setTabValue(2)
                }
                if (data.isTwitter) {
                    setTabValue(1)
                }

                return [...prevState, data]
            }
        });
    };
    const handleDelete = (value) => {
        setPlatformName((current) => _without(current, value));
    };

    const handlePost = () => {
        setIsLoading1(true)
        setIsLoading2(true)
        setIsLoading3(true)
        let fbPages = platformName.filter((data) => data.isFacebook)
        let instaDetails = platformName.filter((data) => data.isInstagram)
        let twitterDetails = platformName.filter((data) => data.isTwitter)

        if (!fbPages.length) {
            setIsLoading1(false)
        }
        if (!instaDetails.length) {
            setIsLoading2(false)
        }
        if (!twitterDetails.length) {
            setIsLoading3(false)
        }
        fbPages.forEach((data) => {
            let fd = new FormData()
            fd.append('page_id', data.id)
            fd.append('access_token', data.access_token)
            fd.append('message', caption.substring(0, 63206))

            if (file) {
                fd.append('image_file', file)
                fd.append('media_type', 'photos')
            } else {
                if (link) {
                    fd.append('image_link', link)
                    fd.append('media_type', 'photos')
                } else {
                    fd.append('media_type', 'feed')
                }
            }

            if (schedulePost) {
                fd.append('scheduled_publish_time', Math.floor(new Date(scheduleTime).getTime() / 1000))
            }
            axios.post('/venue-setting/post-fb', fd)
                .then((response) => {
                    toast.success(schedulePost ? 'Post Scheduled for Facebook' : 'Successfully Posted On Facebook')
                })
                .catch(() => {
                    toast.error('Something went wrong while posting on Facebook')
                })
                .finally(() => {
                    setIsLoading1(false)
                })
        })

        if (instaDetails.length) {
            instaDetails.forEach((data) => {
                let fd2 = new FormData()

                fd2.append('page_id', data.id)
                fd2.append('media_type', 'media')
                fd2.append('caption', caption.substring(0, 2190))
                if (schedulePost) {
                    fd2.append('scheduled_publish_time', Math.floor(new Date(scheduleTime).getTime() / 1000))
                }
                if (file) {
                    fd2.append('image_file', file)
                } else {
                    if (link) {
                        fd2.append('image_link', link)
                    } else {
                        toast.error('Image is required to make instagram post')
                        setIsLoading2(false)
                        return
                    }
                }

                axios.post('/venue-setting/post-ig', fd2)
                    .then((response) => {
                        toast.success(`Successfully Posted On Instagram ${data.name}`)
                    })
                    .catch(() => {
                        toast.error('Something went wrong while posting on Instagram')
                    })
                    .finally(() => {
                        setIsLoading2(false)
                    })
            })
        }

        if (twitterDetails.length) {

            let fd2 = new FormData()

            fd2.append('text', caption.substring(0, 280))

            if (schedulePost) {
                fd2.append('scheduled_publish_time', Math.floor(new Date(scheduleTime).getTime() / 1000))
            }
            if (file) {
                fd2.append('image_file', file)
            } else {
                if (link) {
                    fd2.append('image_link', link)
                }
            }

            axios.post('/venue-setting/post-twitter', fd2)
                .then((response) => {
                    toast.success('Successfully Posted On Twitter')
                })
                .catch((err) => {
                    toast.error(err.response?.message?.message)
                })
                .finally(() => {
                    setIsLoading3(false)
                })
        }
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor = '#0070C6'
        let style = {
            backgroundColor: backgroundColor
        }
        if (event.isDone) {
            style.backgroundColor = '#0B8043'
        }

        // var backgroundColor = '#' + event.hexColor;
        // var style = {
        //     backgroundColor: backgroundColor,
        //     borderRadius: '0px',
        //     opacity: 0.8,
        //     color: 'black',
        //     border: '0px',
        //     display: 'block'
        // };
        return {
            style: style
        }
    }

    return (
        <div>

            {(page === 'calendar') && (
                <div>
                    <div
                        className={clsx(styles.calender_container, 'email_campaign_calendar_container')}
                    >
                        <div
                            className={styles.calendar_info_container}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            color: '#0B8043'
                                        }}
                                        checked={true}
                                        size="small"
                                    />
                                }
                                label="Sent Campaign"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            color: '#0070C6'
                                        }}
                                        checked={true}
                                        size="small"
                                    />
                                }
                                label="Scheduled Campaign"
                            />

                            <GenericButton
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    setPage('create-post')
                                }}
                            >
                                Create Post
                            </GenericButton>
                        </div>
                        <Calendar
                            localizer={localizer}
                            selectable
                            onSelectSlot={(slotInfo) => {
                                // setEventDate(format(slotInfo.start, "dd-MM-yyyy"))
                                // setShowCalender(false)
                                setPage('create-post')
                            }}
                            onSelectEvent={(event) => {
                                // setSearchParams({ date: format(event.start, "dd-MM-yyyy") })
                                setPage('create-post')
                            }}
                            views={['month']}
                            // events={events}
                            eventPropGetter={eventStyleGetter}
                            events={[]}
                        />
                    </div>
                </div>
            )}

            {(page === 'create-post') && (
                <div
                    className={styles.create_preview_container}
                >
                    <div
                        className={styles.marketing_container}
                    >
                        <PublishToSection
                            platformName={platformName}
                            handleChangePlatform={handleChangePlatform}
                            pages={pages}
                            names={names}
                            handleDelete={handleDelete}
                            setPlatformName={setPlatformName}
                            setShowAddSocialDialog={setShowAddSocialDialog}
                        />

                        {(platformName.length > 0) && (
                            <>
                                <div
                                    style={{
                                        margin: '10px 0'
                                    }}
                                >
                                    <RadioSelector
                                        radioValue={radioValue}
                                        onChange={(event) => {
                                            setFile()
                                            setLink()
                                            setCaption('')
                                            setRadioValue(event.target.value)
                                        }}
                                        radioData={createPostTypes}
                                    />
                                </div>

                                {radioValue == 0 && (
                                    <SearchProduct
                                        isVenueList={true}
                                        selectedProductId={selectedProductId}
                                        onProductChipClick={(data) => {
                                            setLink(data.image || data.image_file)
                                            setSelectedProductId(data.ProductId)
                                            setCaption(`${data.description || data.Description || ''}

Shop Online: https://${externalWebsite ? externalWebsite : `${subDomain}.${Const.isOurDesiStore ? 'ourdesistore.com' : 'ourliquorstore.com'}`}/product/${data.ProductName?.toLowerCase().replaceAll(' ', '-')}-${data.ProducerName?.toLowerCase().replaceAll(' ', '-')}/${data.product_id_integer}
                                        `)
                                        }}
                                    />
                                )}

                                <div
                                    className={styles.publish_to_container}
                                    style={{
                                        marginTop: 15,
                                        display: ((radioValue == 0) && !Boolean(caption) && !Boolean(link)) ? 'none' : 'block'
                                    }}
                                >

                                    <Dialog
                                        onClose={() => {
                                            setShowImageGallery(false)
                                        }}
                                        open={showImageGallery}
                                        maxWidth="xl"
                                    >
                                        <DialogContent>
                                            <div>
                                                <ImageGalleryDialog
                                                    isPost={true}
                                                    bannerCat={bannerCat}
                                                    setBannerCat={setBannerCat}
                                                    bannerCatList={bannerCatList}
                                                    currentGallery={currentGallery}
                                                    isGalleryLoading={isGalleryLoading}
                                                    fetchBannerGallery={fetchBannerGallery}
                                                    ref={{
                                                        inputRef: fileRef,
                                                    }}
                                                    setSelectedBanner={(data) => {
                                                        setLink(data.image)
                                                        setShowImageGallery(false)
                                                    }}
                                                    // file={file}
                                                    // imageLink={link}
                                                    handleFileChange={(e) => {
                                                        setFile(e.target.files[0])
                                                        setShowImageGallery(false)
                                                    }}
                                                    setFile={setFile}
                                                    setBannerdata={() => { }}
                                                />
                                            </div>
                                        </DialogContent>

                                        <DialogActions>
                                            <GenericButton
                                                variant="contained"
                                                onClick={() => {
                                                    setShowImageGallery(false)
                                                }}
                                            >
                                                Save
                                            </GenericButton>

                                            <GenericButton
                                                variant="contained"
                                                btnColor="secondary"
                                                onClick={() => {
                                                    setShowImageGallery(false)
                                                }}
                                            >
                                                Cancel
                                            </GenericButton>
                                        </DialogActions>
                                    </Dialog>

                                    <div
                                        className={styles.upload_btn_container}
                                    >
                                        {/* {radioValue == 1 && ( */}
                                        <div
                                            className={styles.upload_btn_container_msg}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    margin: '0 0 15px 0',
                                                    position: 'relative'
                                                }}
                                            >
                                                {((!file && !link && (radioValue == 1)) || (!link && (radioValue == 0))) && (
                                                    <GenericButton
                                                        style={{
                                                            maxHeight: 35
                                                        }}
                                                        onClick={e => {
                                                            setShowImageGallery(true)
                                                            // fileRef.current.click()
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Upload Photo
                                                    </GenericButton>
                                                )}
                                                <input
                                                    style={{ display: 'none', maxHeight: 50 }}
                                                    ref={fileRef}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        setFile(e.target.files[0])
                                                        e.target.value = ''
                                                    }}
                                                />

                                                {file ? (
                                                    <div
                                                        style={{
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <div
                                                            className={styles.image_edit_delete_container}
                                                        >
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowImageGallery(true)
                                                                }}
                                                            >
                                                                <Edit style={{ fontSize: 18, color: 'black' }} />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => {
                                                                    setLink()
                                                                    setFile()
                                                                }}
                                                            >
                                                                <DeleteForever style={{ fontSize: 18, color: 'black' }} />
                                                            </IconButton>
                                                        </div>
                                                        <img
                                                            className={styles.input_image}
                                                            src={file ? URL.createObjectURL(file) : null}
                                                        />
                                                        {/* <div
                                                            className={styles.close_container}
                                                            onClick={() => {
                                                                setFile()
                                                                setLink()
                                                            }}
                                                        >
                                                            <Close
                                                                style={{
                                                                    fontSize: 16
                                                                }}
                                                            />
                                                        </div> */}
                                                    </div>
                                                ) : (
                                                    <>
                                                        {link && (
                                                            <div
                                                                style={{
                                                                    position: 'relative'
                                                                }}
                                                            >
                                                                <div
                                                                    className={styles.image_edit_delete_container}
                                                                >
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setShowImageGallery(true)
                                                                        }}
                                                                    >
                                                                        <Edit style={{ fontSize: 18, color: 'black' }} />
                                                                    </IconButton>

                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setLink()
                                                                            setFile()
                                                                        }}
                                                                    >
                                                                        <DeleteForever style={{ fontSize: 18, color: 'black' }} />
                                                                    </IconButton>
                                                                </div>

                                                                <img
                                                                    className={styles.input_image}
                                                                    src={link}
                                                                />

                                                                {/* <div
                                                                    className={styles.edit_container}
                                                                    onClick={e => {
                                                                        setShowImageGallery(true)
                                                                    }}
                                                                >
                                                                    <Edit
                                                                        style={{
                                                                            fontSize: 16
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={styles.delete_container}
                                                                    onClick={e => {
                                                                        setLink()
                                                                        setFile()
                                                                    }}
                                                                >
                                                                    <Close
                                                                        style={{
                                                                            fontSize: 16
                                                                        }}
                                                                    />
                                                                </div> */}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                            <small
                                                style={{
                                                    fontWeight: 400,
                                                    cursor: 'pointer',
                                                    color: '#ef2d2c'
                                                }}
                                            >
                                                {(Boolean(platformName.filter((data) => data.isInstagram).length) && !file && !link) && "Image is required to make a Instagram Post"}
                                            </small>
                                        </div>
                                        {/* )} */}

                                        <div
                                            className={styles.publish_to_container}
                                        >
                                            <div
                                                className={styles.publish_to_text}
                                            >
                                                Content
                                            </div>
                                            <div
                                                className={styles.content_container}
                                            >
                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                        // maxWidth: { xs: 320, sm: 480 },
                                                        bgcolor: 'background.paper',
                                                    }}
                                                >
                                                    <Tabs
                                                        value={tabValue}
                                                        onChange={handleTabChange}
                                                        classes={{
                                                            indicator: styles.indicator
                                                        }}
                                                    >
                                                        {contentTabs?.map((data) => ((data.show) && (
                                                            <Tab
                                                                className={data.bgstyle}
                                                                value={data.value}
                                                                label={data.label}
                                                            />
                                                        )))}
                                                    </Tabs>
                                                </Box>

                                                <div>
                                                    {caption?.length}/{{
                                                        0: 63206,
                                                        1: 280,
                                                        2: 2190
                                                    }[tabValue]}
                                                </div>
                                            </div>

                                            <div>
                                                <FormControl
                                                    className={styles.form_control}
                                                >
                                                    <TextField
                                                        placeholder='Enter your texts and links'
                                                        multiline
                                                        rows={4}
                                                        variant="outlined"
                                                        value={caption}
                                                        inputProps={{
                                                            maxLength: {
                                                                0: 63206,
                                                                1: 280,
                                                                2: 2190
                                                            }[tabValue]
                                                        }}
                                                        onChange={(event) => setCaption(event.target.value)}
                                                    />
                                                </FormControl>

                                                {((caption?.length >= 280) && Boolean(platformName.filter((data) => data.isTwitter).length)) && (
                                                    <small
                                                        style={{
                                                            fontWeight: 400,
                                                            cursor: 'pointer',
                                                            color: '#ef2d2c',
                                                            display: 'block'
                                                        }}
                                                    >
                                                        Twitter can only take 280 characters of description rest will be truncated.
                                                    </small>
                                                )}

                                                {((caption?.length >= 2190) && Boolean(platformName.filter((data) => data.isInstagram).length)) && (

                                                    <small
                                                        style={{
                                                            fontWeight: 400,
                                                            cursor: 'pointer',
                                                            color: '#ef2d2c',
                                                            display: 'block'
                                                        }}
                                                    >
                                                        Instagram can only take 2190 characters of description rest will be truncated.
                                                    </small>
                                                )}

                                                {((caption?.length >= 63206) && Boolean(platformName.filter((data) => data.isFacebook).length)) && (

                                                    <small
                                                        style={{
                                                            fontWeight: 400,
                                                            cursor: 'pointer',
                                                            color: '#ef2d2c',
                                                            display: 'block'
                                                        }}
                                                    >
                                                        Facebook can only take 63206 characters of description rest will be truncated.
                                                    </small>
                                                )}

                                            </div>

                                        </div>

                                        <SchedulePostSection
                                            schedulePost={schedulePost}
                                            setSchedulePost={setSchedulePost}
                                            scheduleTime={scheduleTime}
                                            setScheduleTime={(event) => setScheduleTime(event.target.value)}
                                            isLoading={isLoading1 || isLoading2 || isLoading3}
                                            handlePost={handlePost}
                                            isBtnDisabled={!platformName?.length || !Boolean(caption)}
                                        />

                                        <div>
                                            {schedulePost && (
                                                <small
                                                    style={{
                                                        fontWeight: 400,
                                                        cursor: 'pointer',
                                                        color: '#ef2d2c',
                                                        display: 'block'
                                                    }}
                                                >
                                                    Schedule Post time should be after 10 minutes from current time
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <PreviewPost
                        platformName={platformName}
                        file={file}
                        link={link}
                        caption={caption}
                    />
                </div>
            )}
            <Dialog
                open={showAddSocialDialog}
                onClose={() => {
                    setShowAddSocialDialog(false)
                }}
            >
                <AddSocialAccountDialog
                    onClose={() => {
                        setShowAddSocialDialog(false)
                    }}
                />
            </Dialog>
        </div>
    )
}

export default CreatePost