import React, { useContext, useEffect, useState } from 'react'
import routePaths from '../../../config/route-paths';
import { useNavigate } from 'react-router-dom';
import WebsiteEditorContext from '../../../utils/context/websiteEditorContext';

const TopPanel = ({
    isTemplateSelected
}) => {

    const { name, id, uri, handleSave, setShowLeftDrawer, editor } = useContext(WebsiteEditorContext)

    const [isShowGrid, setIsShowGrid] = useState(false)

    useEffect(() => {
        if (editor) {
            setTimeout(() => {
                const wrapper = editor.getWrapper()
                if (wrapper) {
                    const wrapperEl = wrapper?.getEl()

                    const children = Array.from(wrapperEl?.children);

                    // Iterate over the child elements
                    for (let i = 0; i < children.length - 1; i++) {
                        // Create a new button element
                        const button = document.createElement('button');
                        button.textContent = 'Click Me';
                        // button.style = `height: 34px; padding: 5px 20px; background-color: #0B9DE4; color: white; border: none; border-radius: 4px; cursor: pointer; position: absolute;bottom:0; left: 30%; pointer-events: all; border-radius: 20px;z-index: 99999999999999999999999999; transform: translateX(-50%);`
                        button.style = `
                    padding-bottom: 8px;
                    background-color: rgb(11, 157, 228);
                    color: white;
                    border: none;
                    cursor: pointer;
                    position: absolute;
                    bottom: -14px;
                    left: 30%;
                    pointer-events: all;
                    border-radius: 5px;
                    z-index: 2147483647;
                    font-size: 38px;
                    height: 30px;       
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    `
                        button.innerHTML = '+';
                        // Attach a click event listener to the button
                        button.addEventListener('click', function () {
                            setShowLeftDrawer(true)
                        });
                        children[i].style = "position:relative;"
                        // Insert the button after the current child element
                        children[i].appendChild(button);
                    }
                }
                setIsShowGrid(true)
                editor.runCommand('sw-visibility')
            }, 4000);
        }
    }, [editor])


    return (
        <div
            className="tw-flex tw-justify-between tw-items-center tw-h-12 tw-bg-[#2e3235] tw-text-white tw-z-[1]"
        >
            <div>
                <button
                    type="button" class="tw-text-white tw-bg-transparent tw-px-5 tw-py-2.5 tw-text-center tw-inline-flex tw-items-center tw-gap-2"
                    onClick={() => {
                        setShowLeftDrawer(true)
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>


                    Add
                </button>
            </div>

            <div
                className="tw-flex tw-gap-1 tw-text-white"
            >
                <button
                    type="button"
                    className="tw-text-white tw-font-medium tw-p-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2"
                    onClick={() => {
                        window.location.href = `/website-builder/template/${id}/${name}/${uri}?device=desktop`
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                    </svg>
                </button>

                <button
                    type="button"
                    className="tw-text-white tw-font-medium tw-p-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2"
                    onClick={() => {
                        window.location.href = `/website-builder/template/${id}/${name}/${uri}?device=tablet`
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-15a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 4.5v15a2.25 2.25 0 0 0 2.25 2.25Z" />
                    </svg>
                </button>

                <button
                    type="button"
                    className="tw-text-white tw-font-medium tw-p-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2"
                    onClick={() => {
                        window.location.href = `/website-builder/template/${id}/${name}/${uri}?device=mobile`
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                    </svg>
                </button>
            </div>

            <div
                className="tw-flex"
            >
                <button
                    type="button"
                    className="tw-text-white tw-font-medium tw-p-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2"
                    onClick={() => {
                        if (isShowGrid) {
                            setIsShowGrid(false)
                            editor.stopCommand('sw-visibility')
                        } else {
                            setIsShowGrid(true)
                            editor.runCommand('sw-visibility')
                        }
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                    </svg>
                </button>

                <div
                    className="tw-flex tw-border-r tw-border-gray tw-pr-1"
                >
                    <button
                        type="button"
                        className="tw-text-white tw-font-medium tw-p-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.49 12 3.74 8.248m0 0 3.75-3.75m-3.75 3.75h16.5V19.5" />
                        </svg>
                    </button>

                    <button
                        type="button"
                        className="tw-text-white tw-font-medium tw-p-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.49 12 3.75-3.751m0 0-3.75-3.75m3.75 3.75H3.74V19.5" />
                        </svg>
                    </button>
                </div>

                <div>
                    <button
                        type="button"
                        className="tw-text-white tw-bg-transparent tw-px-5 tw-py-2.5 tw-text-center tw-inline-flex tw-items-center tw-gap-2"
                        onClick={handleSave}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-7">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                        </svg>

                        Publish
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TopPanel