import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, outlinedInputClasses } from '@mui/material'
import React, { useState } from 'react'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import { useFormik } from 'formik'
import axios, { nodeAxios } from '../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import ValueTextField from '../../Library/ValueTextField/ValueTextField'
import * as Yup from 'yup';

const validationSchemaTax = Yup.object().shape({
    tax_value: Yup.number()
        .nullable()
        .min(0, 'Number must be at least 0')
        .max(100, 'Number must be at most 100')
        .test(
            'is-decimal',
            'Number must not have more than 2 decimal points',
            value => {
                if (value) {
                    return /^\d+(\.\d{1,2})?$/.test(value)
                } else {
                    return true
                }
            }
        ),
    category_name: Yup.string()
        .required('This field is required')
});


const AddCategory = (props) => {
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: props.isEdit ? props.cat : {
            category_name: '',
            description: '',
            tax_type: 'percentage',
            tax_value: props.defaulTaxValue || '',
        },
        enableReinitialize: true,
        validationSchema: validationSchemaTax,
        onSubmit: (values) => {
            setIsLoading(true)
            if (props.isEdit) {
                nodeAxios.post(`/category/edit/${props.cat?.id}`, {
                    categoryName: values.category_name,
                    description: values.description,
                    taxType: values.tax_type,
                    taxValue: values.tax_value
                })
                    .then(() => {
                        toast.success('Category created')
                        props.onClose()
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            } else {
                nodeAxios.post('/category/create', {
                    categoryName: values.category_name,
                    description: values.description,
                    taxType: values.tax_type,
                    taxValue: values.tax_value
                })
                    .then(() => {
                        toast.success('Category created')
                        props.onClose()
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        }
    })

    return (
        <>
            <DialogTitle
                align='left'
                fontWeight={600}
                fontSize={14}
                borderBottom="1px solid #D3D3D3"
                style={{
                    padding: 10,
                    marginBottom: 15
                }}
            >
                {props.isEdit ? "Edit Category" : "Add Category"}
            </DialogTitle>
            <DialogContent
                style={{
                    minWidth: 430
                }}
            >

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20
                    }}
                >
                    <GenericTextFieldWithLabel
                        label="Category name"
                        id="category_name"
                        name="category_name"
                        value={formik.values?.category_name}
                        onChange={formik.handleChange}
                        fullWidth={true}
                        required={true}
                        error={formik.touched.category_name && Boolean(formik.errors.category_name)}
                        helperText={formik.touched.category_name && formik.errors.category_name}
                    />

                    <GenericTextFieldWithLabel
                        label="Description"
                        id="description"
                        name="description"
                        value={formik.values?.description}
                        onChange={formik.handleChange}
                        fullWidth={true}
                    />

                    <div>
                        <div
                            style={{
                                fontWeight: 600
                            }}
                        >
                            Tax
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <GenericSelectWithLabel
                                containerStyle={{
                                    width: 130
                                }}
                                sty
                                id={`tax_type`}
                                name={`tax_type`}
                                value={formik.values.tax_type}
                                onChange={formik.handleChange}
                                sx={{
                                    [`.${outlinedInputClasses.input}`]: {
                                        paddingTop: '10px !important',
                                        paddingBottom: '10px !important'
                                    },
                                    [`.${outlinedInputClasses.notchedOutline}`]: {
                                        minHeight: '40px'
                                    },
                                }}
                            >
                                <MenuItem value="percentage">Percentage</MenuItem>
                                <MenuItem value="dollar">Dollar</MenuItem>
                            </GenericSelectWithLabel>

                            <ValueTextField
                                id={`tax_value`}
                                name={`tax_value`}
                                type={formik.values.tax_type}
                                texttype="number"
                                value={formik.values.tax_value}
                                onChange={formik.handleChange}
                                error={formik.touched.tax_value && Boolean(formik.errors.tax_value)}
                                helperText={formik.touched.tax_value && formik.errors.tax_value}
                            />

                        </div>
                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                <Button
                    style={{
                        backgroundColor: '#EEEEEE',
                        color: 'black',
                        paddingLeft: 16,
                        paddingRight: 16
                    }}
                    variant='text'
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <GenericLoadingButton
                    style={{
                        paddingLeft: 25,
                        paddingRight: 25,
                        background: 'linear-gradient(180deg, #2993FF 0.83%, #036FDE 100%)'
                    }}
                    variant="contained"
                    loading={isLoading}
                    loadingPosition="end"
                    onClick={formik.handleSubmit}
                >
                    Submit
                </GenericLoadingButton>
            </DialogActions>
        </>
    )
}

export default AddCategory