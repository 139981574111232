import React, { useRef, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        border: "1px dashed black",
        borderRadius: 5,
        cursor: "pointer",
    },
}));

const UploadFile = (props) => {
    const inputRef = useRef(null);
    const classes = useStyles();
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });
    const handleBtnClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };
    const handleFileChange = (e) => {
        let file = e.target.files[0];
        if (file) {
            props.setFile(file);
        }
    };
    return (
        <div {...getRootProps()} className={classes.container}>
            <div style={{ padding: "30px 40px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Button
                        onClick={handleBtnClick}
                        style={{
                            backgroundColor: "transparent",
                            padding: "8px 30px",
                            border: "1px solid black",
                            borderRadius: "0",
                            outline: "none",
                            marginBottom: "12px",
                        }}
                    >
                        Choose File
                    </Button>

                    {props.selectedFile && (
                        <img
                            src={URL.createObjectURL(props.selectedFile)}
                        />
                    )}
                    <input
                        {...getInputProps()}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        type="file"
                        ref={inputRef}
                    />
                    <Typography style={{ color: "black" }}>
                        {props.filename ? props.filename : isDragActive ? "Drag Here" : "Drag & Drop Here"}
                    </Typography>
                    {typeof props.defaultFile === "string" && <a target="_blank" style={{ marginTop: '5px', color: 'blue' }} href={props.defaultFile}>View File</a>}
                </div>
            </div>
        </div>
    );
};

export default UploadFile;
