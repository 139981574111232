import React, { useState, useEffect } from 'react'
import CreateBlogs from '../CustomPageBuilder/EditSection/Blogs/WebsiteBuilderCreateBlogs/CreateBlogs'
import axios, { nodeAxios } from '../../utils/axiosConfig';

const CreateBlogsPage = () => {

  const [blogCategories, setBlogCategories] = useState([]);

  const fetchBlogCategories = () => {
    nodeAxios
      .get('/web-builder/blog-category')
      .then((response) => {
        setBlogCategories({
          data: response.data?.data,
          pagination: response.data?.pagination,
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // dispatch(getChildrenAction({ page: 'Children', params: { page: 1 } }));
    fetchBlogCategories();
  }, []);
  return (
    <div style={{ background: '#f6f8fa' }} >
      <CreateBlogs page='CreateBlog' {...{ blogCategories, fetchBlogCategories }} />
    </div>
  )
}

export default CreateBlogsPage