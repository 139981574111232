import { Close } from '@mui/icons-material'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import GoogleAddressInput from '../../Library/GoogleAddressInput/GoogleAddressInput'

const EnterAddressDialog = ({ onClose, formik, sm }) => {
    console.log(sm,'sm')
    return (
        <div>
            <DialogTitle>
                <div
                    className="tw-flex tw-justify-between tw-items-center tw-border-b-border-color tw-border-solid tw-border-0 tw-border-b lg:tw-border-b-0"
                >
                    <div>
                        Where is your business located?
                    </div>

                    <IconButton
                        // style={{
                        //     position: 'absolute',
                        //     top: 0,
                        //     right: 0
                        // }}
                        onClick={onClose}
                    >
                        <Close
                            className="tw-bg-black tw-text-white lg:tw-bg-white lg:tw-text-black tw-rounded-full"
                        />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent
                style={{
                    height: 300
                }}
            >
                <div
                    className="tw-text-black tw-text-sm lg:tw-text-light-gray lg:tw-text-base"
                >
                    Enter your business address, so that our AI can personalized your website for local listings
                </div>

                <GoogleAddressInput
                    variant="standard"
                    isLabel={false}
                    placeholder="Search and select your address"
                    value={formik.values.Address1}
                    setLat={lat => formik.setFieldValue('latitude', lat)}
                    setLng={lng => formik.setFieldValue('longitude', lng)}
                    setAddress={address => formik.setFieldValue('Address1', address)}
                    setCity={city => formik.setFieldValue('City', city)}
                    setState={state => formik.setFieldValue('State', state)}
                    setCountry={country => formik.setFieldValue('Country', country)}
                />
            </DialogContent>
            <DialogActions>
                {!sm && (
                    <Button
                        style={{
                            color: 'black',
                            borderRadius: 30,
                            width: 120,
                            height: 40
                        }}
                        variant="text"
                        onClick={() => {
                            formik.setFieldValue('latitude', '')
                            formik.setFieldValue('longitude', '')
                            formik.setFieldValue('Address1', '')
                            formik.setFieldValue('City', '')
                            formik.setFieldValue('State', '')
                            formik.setFieldValue('Country', '')
                            onClose()
                        }}
                    >
                        Cancel
                    </Button>
                )}

                <Button
                    style={{
                        color: 'white',
                        backgroundColor: 'black',
                        borderRadius: 30,
                        width: sm ? '100%' : 120,
                        height: 40
                    }}
                    variant="contained"
                    onClick={onClose}
                    fullWidth={sm}
                >
                    {sm ? "Save Location" : "Save"}
                </Button>
            </DialogActions>
        </div>
    )
}

export default EnterAddressDialog