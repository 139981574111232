import React from 'react'
import styles from './GoogleAddressInput.module.scss'
import { LocationOn } from '@mui/icons-material'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import Const from '../../helpers/const'
import clsx from 'clsx'

const GoogleAddressInput = (props) => {

    const getName = (results, level) => {
        let name = "";
        for (let i = 0; i < results[0].address_components.length; i++) {
            for (let j = 0; j < results[0].address_components[i].types.length; j++) {
                if (results[0].address_components[i].types[j] === level) {
                    name = results[0].address_components[i].long_name;
                }
            }
        }
        return name;
    };

    const getCountryName = (results) => {
        let country = "";
        for (let i = 0; i < results[0].address_components.length; i++) {
            for (let j = 0; j < results[0].address_components[i].types.length; j++) {
                if (results[0].address_components[i].types[j] === "country") {
                    country = results[0].address_components[i].long_name;
                }
            }
        }
        return country;
    };

    const handleGoogleAutoCompleteChange = (address) => {

        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                props.setLat(lat)
                props.setLng(lng)
            });
        geocodeByAddress(address).then((results) => {
            props.setAddress(results[0]?.formatted_address)
            props.setCity(getName(results, 'locality'))
            props.setState(getName(results, 'administrative_area_level_1'))
            props.setCountry(getName(results, 'country'))
        })
    }

    return (
        <div
            className={clsx(styles.container, props.containerClasses)}
        >
            {props.isLabel && (
                <div
                    className={clsx(styles.label_text, props.isSmallLabel && styles.small_label)}
                >
                    {props.label}
                    {props.required && (
                        <span
                            className={clsx(styles.required_asterisk, props.isSmallLabel && styles.small_icon)}
                        >
                            *
                        </span>
                    )}
                </div>
            )}

            <div
                style={{
                    width: '100%'
                }}
                className={styles.delivery_address_autocomplete_container}
            >
                <LocationOn
                    className={styles.location_google_on_icon}
                />
                <GooglePlacesAutocomplete
                    inputClassName={clsx(styles.location_google_input, (props.variant == 'standard') && styles.location_google_input_standard)}
                    apiKey={Const.GOOGLE_MAP_KEY}
                    placeholder={props.placeholder}
                    initialValue={props.value}
                    onSelect={({ description }) =>
                        handleGoogleAutoCompleteChange(description)
                    }
                />
            </div>
        </div>
    )
}

export default GoogleAddressInput