import React from 'react' 
import { Box } from '@mui/material'; 



function MobileItems({ products, setIsEdit, setCat, setShowAddCategory,isActionDisabled }) {
  return (
    <Box>
        {products.map((row, index) => (
            <Box
                key={row.id}
                style={{
                    pointerEvents: isActionDisabled ? 'none' : 'all'
                }}
                onClick={() => {
                    setIsEdit(true)
                    setCat(row)
                    setShowAddCategory(true)
                }}
                className='tw-border-b tw-border-gray tw-py-[0.8rem] tw-flex tw-items-center tw-gap-2'
            >
                <div className='tw-w-full'>
                    <div className='tw-flex tw-justify-between tw-text-sm tw-text-black tw-mb-1'>
                        <span className='tw-font-bold tw-max-w-[80%] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.category_name}</span>
                        {/* <span className='tw-text-right tw-max-w-[20%]'> {row.options?.length}</span> */}
                    </div>
                    {row.description && 
                    <div className='tw-flex tw-justify-between tw-text-xs tw-text-[#7C7C7C]'>
                        <span className='tw-max-w-[80%] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{row.description}</span>
                    </div>}
                </div>
            </Box>
        ))}
    </Box>
  )
}

export default MobileItems;