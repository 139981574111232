import clsx from 'clsx'
import React from 'react'
import GenericButton from '../../Library/GenericButton/GenericButton'
import styles from './ConnectionItems.module.scss'

const ConnectionItems = (props) => {
    return (
        <div
            className={clsx(styles.container, props.containerClassName)}
        >
            {props.icon}

            <div
                className={styles.title}
            >
                {props.title}
            </div>
            {props.isActionsDisabled ? (
                <GenericButton
                    variant="contained"
                    disabled={true}
                >
                    Connect
                </GenericButton>
            ) : props.button}
        </div>
    )
}

export default ConnectionItems