import React, { useState, useEffect } from 'react'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { nodeAxios } from '../../../../../utils/axiosConfig'
import { toast } from 'react-toastify'
import GenericLoadingButton from '../../../../Library/GenericLoadingButton/GenericLoadingButton'
import EmailCustomizationsEditor from './EmailCustomizationsEditor/EmailCustomizationsEditor'

const EmailCustomizations = () => {

    const [emailTypes, setEmailTypes] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [value, setValue] = useState('');
    const [isSaveLoading, setIsSaveLoading] = useState(false);



    const fetchEmailTypes = () => {
        nodeAxios
        .get('/venue/email-config/type-list')
        .then((response) => {
            let result = response.data.data
            var arr = []
            setValue(result[0].key)
            result.forEach(element => {
              let key = element.key
    
              let value = element.value
    
              let firstText = key.split('_')[0]
              if (firstText === 'pickup') {
                if (arr.filter((data) => data.id === 'pickup').length) {
                  let index = arr.findIndex((data) => data.id === 'pickup')
                  arr[index].child.push({
                    id: key,
                    label: value
                  })
                  return
                } else {
                  arr.push({
                    id: 'pickup',
                    label: 'Pickup',
                    child: [
                      {
                        id: key,
                        label: value
                      }
                    ]
                  })
                  return
                }
              }
              if (firstText === 'delivery') {
                if (arr.filter((data) => data.id === 'delivery').length) {
                  let index = arr.findIndex((data) => data.id === 'delivery')
                  arr[index].child.push({
                    id: key,
                    label: value
                  })
    
                  return
                } else {
                  arr.push({
                    id: 'delivery',
                    label: 'Delivery',
                    child: [
                      {
                        id: key,
                        label: value
                      }
                    ]
                  })
    
                  return
                }
              }
              if (firstText === 'shipping') {
                if (arr.filter((data) => data.id === 'shipping').length) {
                  let index = arr.findIndex((data) => data.id === 'shipping')
                  arr[index].child.push({
                    id: key,
                    label: value
                  })
    
                  return
                } else {
                  arr.push({
                    id: 'shipping',
                    label: 'Shipping',
                    child: [
                      {
                        id: key,
                        label: value
                      }
                    ]
                  })
    
                  return
                }
              }
    
              if (firstText === 'other') {
                if (arr.filter((data) => data.id === 'other').length) {
                  let index = arr.findIndex((data) => data.id === 'other')
                  arr[index].child.push({
                    id: key,
                    label: value
                  })
    
                  return
                } else {
                  arr.push({
                    id: 'other',
                    label: 'Other',
                    child: [
                      {
                        id: key,
                        label: value
                      }
                    ]
                  })
    
                  return
                }
              } else {
                arr.push({
                  id: key,
                  label: value,
                  child: []
                })
    
                return
              }
    
            });
            setEmailTypes(arr)
        })
        .catch((error) => {
            // toast.error('Something went wrong')
        })
    }

    const handleSubmit = (values) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);

        setIsSaveLoading(true);
        nodeAxios.post(`/venue/email-config/update/${value}`, {
            text: markup
        })
        .then(() => {
            toast.success('Email Configuration Updated Succesfully')
        })
        .catch(() => {
            // toast.error('Something went wrong')
        })
        .finally(() => {
            setIsSaveLoading(false);
        })
}


    useEffect(() => {
        fetchEmailTypes()
    }, [])

    return (
        <div className='tw-mt-8'>
            <div className=' tw-mb-4'>
                <h2 className='tw-text-black tw-text-lg tw-font-semibold'>Email template</h2>
                <p className='tw-text-sm tw-text-[#868686]'>Add text to inbuild system emails to personalize communication with your customers, enabling them to convey special messages, promotions, or brand stories.</p>
            </div>

            <EmailCustomizationsEditor
                emailTypes={emailTypes}
                value={value}
                setValue={setValue}
                editorState={editorState}
                setEditorState={setEditorState}
            />

            <div className='tw-flex tw-justify-end tw-mt-4'>
                <GenericLoadingButton
                    variant="contained"
                    type="button"
                    onClick={handleSubmit}
                    size="large"
                    loading={isSaveLoading}
                    loadingPosition="end"
                    disabled={isSaveLoading}
                >
                    Save  Email
                </GenericLoadingButton>
            </div>
        </div>
    )
}

export default EmailCustomizations