import React from 'react'
import styles from './TabSelector.module.scss'
import clsx from 'clsx'

const TabSelector = (props) => {
    return (
        <div>
            <div
                className={styles.title}
            >
                {props.title}
            </div>
            <div
                className={clsx(styles.tab_container, props.containerClass)}
            >
                {props.tabs?.map((data) => (
                    <div
                        className={clsx(styles.item, ((data.value === props.value) && styles.selected), props.labelClass)}
                        onClick={() => {
                            props.onChange(data.value)
                        }}
                    >
                        {data.label}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TabSelector