import { buttonBaseClasses, Radio, radioClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

const GenericRadio = styled((props) => (
    <Radio
        {...props}
        sx={{
            color: '#707070',
            '&.Mui-checked': {
                color: '#000000',
            },
        }}
    />
))(({ theme }) => ({
}))

export default GenericRadio