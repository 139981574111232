import clsx from 'clsx'
import React from 'react'
import styles from './GenericTextFieldWithLabel.module.scss'
import { Button, IconButton, outlinedInputClasses, styled, TextField } from '@mui/material';
import { isHTML } from '../../EditSection/Util/helpers';
import { SettingsBackupRestore } from '@mui/icons-material';

const GenericTextField = styled((props) => <TextField {...props} />)(
    ({ theme, variant, multiline }) => ({
        [`& .${outlinedInputClasses.notchedOutline}`]: {
            border: '1px solid #d3d3d3 !important',
            outline: 'none',
            color: '#2e2e2e',
        },
        [`& .${outlinedInputClasses.input}`]: {
            fontSize: 14,
            padding: multiline ? 0 : '7.5px 14px',
        },
        [`& .${outlinedInputClasses.root}`]: {
            minHeight: 40,
            backgroundColor: variant == 'outlined' ? 'transparent' : '#efefef',
        },
    })
)


const GenericTextFieldWithLabel = ({ label, containerStyle, containerClassname, colorType, onAdvanceClick, ...rest }) => {
console.log(rest.value, onAdvanceClick)
    const getText = (htmlString) => {
        const parser = new DOMParser();

        // parse the HTML string and create a new document object
        const doc = parser.parseFromString(htmlString, 'text/html');

        // get the document element
        const docElement = doc.documentElement;
        return docElement.textContent
    }
    return (
        <div
            style={containerStyle}
            // className={clsx(styles.container, rest.multiline && styles.multiline, containerClassname)}
            className={clsx(styles.container, containerClassname)}
        >
            <div
                className={clsx(styles.label_text, (colorType === 'secondary') && styles.label_text_2, styles.advance_container)}
            >
                {label}
                {rest.required && (
                    <span
                        className={styles.required_asterisk}
                    >
                        *
                    </span>
                )}

                {Boolean(onAdvanceClick) && (
                    <div
                        className={styles.advance_btn_container}
                    >
                        <Button
                            variant="text"
                            onClick={onAdvanceClick}
                        >
                            Editor
                        </Button>
                    </div>
                )}

                {isHTML(rest.value) && (
                    <IconButton
                        size="small"
                        onClick={() => {
                            let event = {
                                target: {
                                    value: ''
                                }
                            }
                            rest.onChange(event)
                        }}
                    >
                        <SettingsBackupRestore />
                    </IconButton>
                )}
            </div>

            {isHTML(rest.value) ? (
                <div
                    style={{
                        height: 26,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 313,
                        whiteSpace: 'nowrap'
                    }}
                    dangerouslySetInnerHTML={{
                        __html: getText(rest.value)
                    }}
                />
            ) : (
                <GenericTextField
                    {...rest}
                />
            )}
        </div>
    )
}

export default GenericTextFieldWithLabel