import { Checkbox, FormControlLabel, MenuItem } from '@mui/material'
import React from 'react'
import Const from '../../helpers/const'
import GenericSelectWithLabel from '../../Library/GenericSelectWithLabel/GenericSelectWithLabel'
import LightTooltip from '../../Library/LightTooltip/LightTooltip'
import ValueTextField from '../../Library/ValueTextField/ValueTextField'
import ShippingIcon from '../../../assets/images/shipping.png'
import styles from './ShippingSetting.module.scss'
import SelectTextField from '../../Library/SelectTextField/SelectTextField'
import GenericSwitch from '../../Library/GenericSwitch/GenericSwitch'

const ShippingSetting = (props) => {
  return (
    <div
      className={styles.container}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          padding: '10px 0'
        }}
      >
        <div>
          We Offer Shipping
        </div>
        <GenericSwitch
          checked={Boolean(props.formik.values.is_offer_shipping)}
          onChange={(event) => {
            props.formik.setFieldValue('is_offer_shipping', event.target.checked ? 1 : 0)
          }}
        />

        {Boolean(props.formik.values.is_offer_shipping) && (
          <FormControlLabel
            style={{
              marginLeft: 0,
              gap: 6
            }}
            control={(
              <GenericSwitch
                checked={Boolean(parseInt(props.formik.values?.is_show_no_of_stock?.shipping))}
                onChange={(event) => {
                  props.formik.setFieldValue('is_show_no_of_stock.shipping', event.target.checked ? 1 : 0)
                }}
              />
            )}
            label="Show in-stock number"
            labelPlacement="start"
          />
        )}
      </div>

      {Boolean(props.formik.values.is_offer_shipping) && (
        <div
          className={styles.box}
        >
          <div
            className={styles.image_container}
          >
            <img
              src={ShippingIcon}
            />
          </div>
          <div
            className={styles.fields_container}
          >
            <FormControlLabel
              control={(
                <Checkbox
                  checked={props.formik.values.shipping_service_charge_to_customer}
                  onChange={(event) => {
                    props.formik.setFieldValue('shipping_service_charge_to_customer', event.target.checked ? 1 : 0)
                  }}
                  color="default"
                />
              )}
              label={
                <span
                  style={{
                    textDecoration: 'underline'
                  }}
                >
                  <LightTooltip
                    title={
                      <span
                      // style={{
                      //   display: 'inline-block',
                      //   padding: '0 15px'
                      // }}
                      >
                        If this checkbox is enabled, this fees will be added to each order to offset your store fees.
                      </span>
                    }
                    arrow={true}
                    placement="top"
                  >
                    <span>
                      Add platform Service
                    </span>
                  </LightTooltip>
                  Charge of {props.formik.values.shipping_fee} to customer order.
                </span>
              }
            />

            <div>
              <LightTooltip
                title={
                  <span
                    style={{ display: 'inline-block', padding: '0 15px' }}
                  >
                    Irrespective of delivery radius, you will only be able to deliver to the following states. Leave it blank if you would like to get orders based on your delivery zones, from any state.
                  </span>
                }
                arrow={true}
                placement="top"
              >
                <div>
                  Ship To States
                </div>
              </LightTooltip>

              <SelectTextField
                onChange={(value) => {
                  if (value?.length > 0) {
                    props.formik.setFieldValue('shiptostates', value.map((val) => val.title).join(','))
                  } else {
                    props.formik.setFieldValue('shiptostates', null)
                  }
                }}
                value={props.formik.values.shiptostates?.split(",")?.map((val) => ({ title: val }))}
                venueStates={props.venueStates}
              />
              <small
                style={{
                  fontWeight: 400,
                  cursor: 'pointer',
                  color: '#ef2d2c',
                  display: 'block'
                }}
              >
                Please choose from the suggestions
              </small>
            </div>

            <div
              className={styles.input_fields_container}
            >
              <div>
                <GenericSelectWithLabel
                  id="shippingfeestype"
                  name="shippingfeestype"
                  label={
                    <LightTooltip
                      title={
                        <span
                          style={{ display: 'inline-block', padding: '0 15px' }}
                        >
                          If you charge flat fees, choose "Dollar". Choose "Percent", if you want us  to calculate the fees based on order value.
                        </span>
                      }
                      arrow={true}
                      placement="top"
                    >
                      <div>Shipping Fee Type</div>
                    </LightTooltip>
                  }
                  containerClassname={styles.small_input_container}
                  value={props.formik.values.shippingfeestype}
                  onChange={props.formik.handleChange}
                >
                  <MenuItem value="percentage">Percentage</MenuItem>
                  <MenuItem value="dollar">Dollar</MenuItem>
                </GenericSelectWithLabel>
              </div>

              <div
                className={styles.input_field}
              >
                <ValueTextField
                  id="shippingfees"
                  name="shippingfees"
                  label={
                    <LightTooltip
                      title={
                        <span
                          style={{ display: 'inline-block', padding: '0 15px' }}
                        >
                          Enter Shipping fees here
                        </span>
                      }
                      arrow={true}
                      placement="top"
                    >
                      <div>Shipping Fees</div>
                    </LightTooltip>
                  }
                  type={props.formik.values.shippingfeestype}
                  value={props.formik.values.shippingfees}
                  onChange={props.formik.handleChange}
                />
              </div>


              <div
                className={styles.input_field}
              >
                <ValueTextField
                  id="minshipamount"
                  name="minshipamount"
                  label={
                    <LightTooltip
                      title={
                        <span
                          style={{ display: 'inline-block', padding: '0 15px' }}
                        >
                          Only orders with value greater than this amount will be allowed to process. We recommend to have a low value here to get more shippping orders.
                        </span>
                      }
                      arrow={true}
                      placement="top"
                    >
                      <div>
                        Min. Shipping Amount
                      </div>
                    </LightTooltip>
                  }
                  type="dollar"
                  value={props.formik.values.minshipamount}
                  onChange={props.formik.handleChange}
                />
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ShippingSetting