import { Add, CameraAlt, DeleteForever, Edit } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select, Tab, Tabs, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import InputField from '../../Library/InputField/InputField'
import styles from './AddBannerDialog.module.scss'
import axios from '../../../utils/axiosConfig'
import TabPanel from '../../Library/TabPanel/TabPanel'
import A11yProps from '../../Library/A11yProps/A11yProps'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { getBanners } from '../../../redux/actions/bannerActions'
import SortBySelect from '../../Library/SortBySelect/SortBySelect'
import ImageGalleryDialog from '../ImageGalleryDialog/ImageGalleryDialog'
import FolderIcon from '@mui/icons-material/Folder';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Loader from '../../Library/Loader'
import GenericButton from '../../Library/GenericButton/GenericButton'

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const AddBannerDialog = (props) => {

    const inputRef = useRef();
    const titleRef = useRef()
    const descriptionRef = useRef()
    const buttonRef = useRef()

    const dispatch = useDispatch()

    const [bannerName, setBannerName] = useState('')
    const [showAddImage, setShowAddImage] = useState(false)
    const [showBannerDetails, setShowBannerDetails] = useState(false)
    const [showBannerAttributes, setShowBannerAttributes] = useState(false)
    const [bannerCat, setBannerCat] = useState('undefined')
    const [bannerCatList, setBannerCatList] = useState([])
    const [currentGallery, setCurrentGallery] = useState([])
    const [selectedbanner, setSelectedBanner] = useState()
    const [bannerTitle, setBannerTitle] = useState('')
    const [file, setFile] = useState()
    const [bannerdata, setBannerdata] = useState([])
    const [editImageIndex, setEditImageIndex] = useState(null)
    const [showEditBanner, setShowEditBanner] = useState(false)
    const [isGalleryLoading, setIsGalleryLoading] = useState(false)
    const [tabValue, setTabValue] = useState(0)

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0])
        setBannerTitle(e.target.files[0].name.split('.')[0])
    };

    const handleFileUpload = () => {
        if (tabValue == 0) {
            // setBannerdata((prevState) => [...prevState, { banner_id: '', image: '', type: 'contain', banner_title: '', description: '', button_text: '' }]);
        }

        if (!bannerTitle) {
            toast.error('Please enter banner title')
            return
        }

        if (file && (tabValue == 0)) {
            let fd = new FormData()

            fd.append('image', file)
            // fd.append('category_id', bannerCat)
            fd.append('title', bannerTitle)
            axios.post('/banner-group/upload', fd)
                .then((response) => {
                    setBannerdata((prevState) => {
                        let newState = [...prevState]
                        newState.push({ banner_id: '', image: '', type: 'contain', banner_title: '', description: '', button_text: '' })
                        newState[newState.length - 1]['banner_id'] = response.data.data.result.id
                        newState[newState.length - 1]['image'] = response.data.data.result.image
                        return newState
                    })
                })
        }
    }

    const fetchBanners = () => {
        axios.get('/banner-group/list', {
            params: {
                group_id: props.bannerIdForEdit
            }
        })
            .then((response) => {
                setBannerdata(response.data.data.result[0]?.banners);
                setBannerName(response.data.data.result[0]?.group_name);
            })
    }

    const fetchBannerCatList = () => {
        axios.get('/banner-category/list')
            .then((response) => {
                setBannerCatList(response.data.data.result)
            })
    }

    const fetchBannerGallery = (id) => {
        setIsGalleryLoading(true)
        axios.get('/banner-group/gallery', {
            params: {
                category_id: id == 'undefined' ? undefined : id
            }
        })
            .then((response) => {
                setCurrentGallery(response.data.data.result)
            })
            .finally(() => {
                setIsGalleryLoading(false)
            })
    }

    const postBannerCreate = () => {

        if (!bannerName) {
            toast.error('Please enter banner name')
        }

        axios.post(props.isBannerEdit ? `/banner-group/edit/${props.bannerIdForEdit}` : '/banner-group/create', {
            group_name: bannerName,
            json: bannerdata.map((data) => ({
                banner_id: data.banner_id,
                banner_title: data.banner_title ? data.banner_title : '',
                banner_name: '',
                banner_type: data.banner_type ? data.banner_type : 'contain',
                description: data.description ? data.description : '',
                button_text: data.button_text ? data.button_text : '',
                button_link: data.button_link ? data.button_link : '',
                display_order: 1,
                display_time: 3
            }))
        })
            .then((response) => {
                toast.success(props.isBannerEdit ? 'Banner Saved' : 'Banner Created')
                setFile()
                setSelectedBanner()
                setBannerdata([])
                dispatch(getBanners({ page: 1, query: '' }))
                props.onClose()
            })
    }

    useEffect(() => {
        fetchBannerCatList()
        fetchBannerGallery()

        if (props.isBannerEdit) {
            fetchBanners()
        }
    }, [props])

    return (
        <>
            <DialogTitle>
                {props.isBannerEdit ? "Edit Banner" : "Add Banner"}
            </DialogTitle>

            <DialogContent
                sx={{
                    minWidth: 750,
                    '@media (max-width: 1020px)': {
                        minWidth: 'unset'
                    }
                }}
            >
                <div
                    style={{
                        width: 220
                    }}
                >
                    <InputField
                        placeholder="Banner Name"
                        value={bannerName}
                        onChange={(e) => {
                            setBannerName(e.target.value)
                        }}
                        showIconButton={false}
                    />
                </div>

                <div
                    className={styles.add_btn_text_conatiner}
                >
                    <div>
                        Current images in banner
                    </div>

                    <Button
                        style={{
                            backgroundColor: '#676767',
                            maxWidth: 130
                        }}
                        variant="contained"
                        onClick={() => {
                            // if (!bannerName) {
                            //     toast.error('Please enter banner name before adding images')
                            //     return
                            // }
                            setShowAddImage(true)
                        }}
                    >
                        {bannerdata.length ? "Add another Image" : "Add Image"}
                    </Button>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}
                >
                    {bannerdata.map((data, index) => (
                        <div
                            key={data.image}
                            style={{
                                margin: '6px',
                                position: 'relative'
                            }}
                        >
                            <img
                                style={{
                                    borderRadius: '5px'
                                }}
                                src={data.image}
                                width={150}
                                height={65}
                            />

                            <div
                                className={styles.add_banner_options}
                            >
                                <IconButton
                                    onClick={() => {
                                        setShowEditBanner(true)
                                        setEditImageIndex(index)
                                    }}
                                >
                                    <Edit
                                        style={{
                                            color: 'black'
                                        }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => {
                                        setBannerdata((prevState) => {
                                            let newState = [...prevState]
                                            newState.splice(index, 1)
                                            return newState
                                        })

                                    }}
                                >
                                    <DeleteForever
                                        style={{
                                            color: 'black'
                                        }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    style={{
                        backgroundColor: 'black'
                    }}
                    variant="contained"
                    onClick={postBannerCreate}
                >
                    Save
                </Button>

                <Button
                    style={{
                        backgroundColor: '#DDDDDD',
                        color: 'black'
                    }}
                    variant="contained"
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
            </DialogActions>

            <Dialog
                onClose={() => {
                    setShowAddImage(false)
                    setShowEditBanner(false)
                }}
                open={showAddImage || showEditBanner}
                maxWidth="xl"
            >
                <DialogTitle>
                    {props.isBannerEdit ? "Edit Image" : "Add Image"}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <ImageGalleryDialog
                            tabValue={tabValue}
                            onChange={handleChange}

                            isEdit={showEditBanner}
                            editImageIndex={editImageIndex}
                            bannerdata={bannerdata}

                            ref={{
                                inputRef: inputRef,
                                titleRef: titleRef,
                                descriptionRef: descriptionRef,
                                buttonRef: buttonRef
                            }}
                            file={file}
                            imageLink={showEditBanner ? bannerdata[editImageIndex]?.image : selectedbanner?.image}
                            onImageClose={() => {
                                setFile()
                            }}
                            handleFileChange={handleFileChange}
                            setSelectedBanner={setSelectedBanner}
                            setFile={setFile}
                            setShowBannerDetails={setShowBannerDetails}
                            showBannerAttributes={showBannerAttributes}
                            setShowBannerAttributes={setShowBannerAttributes}
                            setBannerdata={setBannerdata}

                            bannerCat={bannerCat}
                            setBannerCat={setBannerCat}
                            fetchBannerGallery={fetchBannerGallery}
                            bannerCatList={bannerCatList}
                            isGalleryLoading={isGalleryLoading}
                            selectedbanner={selectedbanner}
                            setBannerTitle={setBannerTitle}
                            currentGallery={currentGallery}
                        />
                    </div>
                </DialogContent>

                <DialogActions>
                    <GenericButton
                        variant="contained"
                        onClick={() => {
                            setShowEditBanner(false)

                            setShowAddImage(false)
                            handleFileUpload()
                            setFile()
                            setSelectedBanner()
                            titleRef.current = null
                            descriptionRef.current = null
                            buttonRef.current = null
                        }}
                    >
                        Save
                    </GenericButton>

                    <GenericButton
                        variant="contained"
                        btnColor="secondary"
                        onClick={() => {
                            setShowAddImage(false)
                            setFile()
                            setShowEditBanner(false)
                            setSelectedBanner()
                            titleRef.current = null
                            descriptionRef.current = null
                            buttonRef.current = null
                        }}
                    >
                        Cancel
                    </GenericButton>
                </DialogActions>
            </Dialog>


            {/* <Dialog
                open={showEditBanner}
                onClose={() => {
                    showEditBanner(false)
                }}
                maxWidth="xl"
            >
                <DialogTitle>
                    Edit Image
                </DialogTitle>

                <DialogContent>
                    <>

                        {bannerdata[editImageIndex]?.image ? (
                            <div
                                style={{
                                    position: 'relative'
                                }}
                            >
                                <img
                                    src={bannerdata[editImageIndex]?.image}
                                    height={180}
                                    width={800}
                                />

                                <div
                                    className={styles.change_image}
                                    onClick={() => {
                                        setSelectedBanner()
                                        setFile()
                                        setBannerdata((prevState) => {
                                            let newState = [...prevState]
                                            newState[editImageIndex].image = null
                                            return newState
                                        })
                                    }}
                                >
                                    <CameraAlt style={{ marginRight: '8px' }} />
                                    Change Image
                                </div>

                                <div
                                    className={styles.add_image_details}
                                    onClick={() => {
                                        setShowBannerDetails(true)
                                    }}
                                >
                                    Add Image Details
                                </div>

                                <div
                                    className={styles.add_details}
                                >
                                    <div
                                        className={styles.add_details_title}
                                        onBlur={(e) => {
                                            if (e.currentTarget?.textContent) {
                                                titleRef.current = e.currentTarget?.textContent
                                                setBannerdata((prevState) => {
                                                    let newState = [...prevState]
                                                    newState[editImageIndex]['banner_title'] = titleRef.current
                                                    return newState
                                                })
                                            }
                                        }}
                                        contentEditable
                                    >
                                        {bannerdata[editImageIndex]?.banner_title ? bannerdata[editImageIndex]?.banner_title : "Add Title"}
                                        <Edit
                                            style={{
                                                position: 'absolute',
                                                top: -6
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={styles.add_details_description}
                                        onBlur={(e) => {
                                            if (e.currentTarget?.textContent) {
                                                descriptionRef.current = e.currentTarget?.textContent
                                                setBannerdata((prevState) => {
                                                    let newState = [...prevState]
                                                    newState[editImageIndex]['description'] = descriptionRef.current
                                                    return newState
                                                })
                                            }
                                        }}
                                        contentEditable
                                    >
                                        {bannerdata[editImageIndex]?.description ? bannerdata[editImageIndex]?.description : "Add Description"}
                                        <Edit
                                            style={{
                                                position: 'absolute',
                                                top: -6
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={styles.add_details_item_btn}
                                        onBlur={(e) => {
                                            if (e.currentTarget?.textContent) {
                                                buttonRef.current = e.currentTarget?.textContent
                                                setBannerdata((prevState) => {
                                                    let newState = [...prevState]
                                                    newState[editImageIndex]['button_text'] = buttonRef.current
                                                    return newState
                                                })
                                            }
                                        }}
                                        contentEditable
                                    >
                                        {bannerdata[editImageIndex]?.button_text ? bannerdata[editImageIndex]?.button_text : "Button Text"}
                                        <Edit
                                            style={{
                                                position: 'absolute',
                                                top: -6
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div
                                    className={styles.select_banner_btn_container}
                                >

                                    <div
                                        style={{
                                            margin: '0 20px'
                                        }}
                                    >
                                        OR
                                    </div>

                                    <Button
                                        style={{
                                            backgroundColor: 'black',
                                            color: 'white'
                                        }}
                                    >
                                        Select From Gallery
                                    </Button>
                                </div>

                                <Box
                                    sx={{
                                        flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 400
                                    }}
                                >
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={bannerCat}
                                        onChange={(event, newvalue) => {
                                            fetchBannerGallery(newvalue)
                                            setBannerCat(newvalue)
                                        }}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        {bannerCatList.map((data) => (
                                            <Tab label={data.title} {...a11yProps(data.id)} />
                                        ))}
                                    </Tabs>

                                    <TabPanel
                                        style={{
                                            maxWidth: 400,
                                            padding: 10
                                        }}
                                    >
                                        <RadioGroup
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                flexDirection: 'row'
                                            }}
                                            value={selectedbanner}
                                            onChange={(event) => {
                                                let parsedValue = JSON.parse(event.target.value)
                                                setBannerdata((prevState) => {
                                                    let newState = [...prevState]
                                                    newState[editImageIndex].image = parsedValue.image
                                                    newState[editImageIndex].banner_id = parsedValue.id
                                                    return newState
                                                });
                                                setSelectedBanner(parsedValue)
                                                setBannerTitle(parsedValue.title)
                                            }}
                                        >
                                            {currentGallery.map((data) => (
                                                <div
                                                    style={{
                                                        margin: '6px',
                                                        position: 'relative',
                                                        width: '101px'
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            borderRadius: '5px'
                                                        }}
                                                        src={data.image}
                                                        width={100}
                                                        height={35}
                                                    />
                                                    <div>
                                                        <FormControlLabel value={JSON.stringify(data)} control={<Radio size="small" />} label={<div className={styles.radio_name} >{data.title}</div>} />
                                                    </div>
                                                </div>
                                            ))}
                                        </RadioGroup>
                                    </TabPanel>
                                </Box>
                            </div>
                        )}
                    </>
                </DialogContent>

                <DialogActions>
                    <Button
                        style={{
                            backgroundColor: 'black',
                            color: 'white'
                        }}
                        onClick={() => {
                            setShowEditBanner(false)
                            // handleFileUpload()
                            // setFile()
                            setSelectedBanner()
                            titleRef.current = null
                            descriptionRef.current = null
                            buttonRef.current = null
                        }}
                    >
                        Save
                    </Button>

                    <Button
                        style={{
                            backgroundColor: '#DDDDDD',
                            color: 'black'
                        }}
                        onClick={() => {
                            setShowEditBanner(false)
                            setSelectedBanner()
                            titleRef.current = null
                            descriptionRef.current = null
                            buttonRef.current = null
                        }}
                    >
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog> */}


            <Dialog
                open={showBannerDetails}
                onClose={() => setShowBannerDetails(false)}
            >
                <DialogContent>

                    <div>
                        <label
                            className={styles.input_label}
                            htmlFor="title"
                        >
                            Title
                        </label>
                        <TextField
                            InputProps={{
                                classes: {
                                    notchedOutline: styles.input_box_style
                                }
                            }}
                            inputProps={{
                                style: {
                                    padding: '9px 12px'
                                }
                            }}
                            variant="outlined"
                            id="title"
                            name="title"
                            type="text"
                            fullWidth
                            value={bannerTitle}
                            onChange={(event) => {
                                setBannerTitle(event.target.value)
                            }}
                        />
                    </div>

                    <div>
                        <label
                            className={styles.input_label}
                            htmlFor="title"
                        >
                            Category
                        </label>
                        <div>
                            <SortBySelect
                                value={bannerCat}
                                onChange={(event) => setBannerCat(event.target.value)}
                                menuItems={bannerCatList.map((data) => ({
                                    value: data.id,
                                    name: data.title
                                }))}
                                fullWidth={true}
                            />
                        </div>
                    </div>

                    <div
                        className={styles.input_field_container}
                    >
                        <label
                            className={styles.input_label}
                            htmlFor="button_link"
                        >
                            Button Url
                        </label>
                        <TextField
                            InputProps={{
                                classes: {
                                    notchedOutline: styles.input_box_style
                                }
                            }}
                            variant="outlined"
                            id="button_link"
                            name="button_link"
                            type="text"
                            fullWidth
                            value={bannerdata[bannerdata.length - 1]?.button_link}
                            onChange={(event) => {
                                setBannerdata((prevState) => {
                                    let newState = [...prevState]
                                    newState[newState.length - 1]['button_link'] = event.target.value
                                    return newState
                                })
                            }}
                        />
                    </div>

                    <div
                        className={styles.input_field_container}
                    >
                        <label
                            style={{
                                display: 'block'
                            }}
                        >
                            Banner Type
                        </label>

                        <Select
                            value={bannerdata[bannerdata.length - 1]?.banner_type}
                            size="small"
                            onChange={(event) => {
                                setBannerdata((prevState) => {
                                    let newState = [...prevState]
                                    newState[newState.length - 1]['banner_type'] = event.target.value
                                    return newState
                                })
                            }}
                            fullWidth
                        >
                            <MenuItem value="contain">Contain</MenuItem>
                            <MenuItem value="cover">Cover</MenuItem>
                            <MenuItem value="fill">Fill</MenuItem>
                        </Select>
                    </div>

                    <DialogActions>
                        <Button
                            style={{
                                backgroundColor: 'black',
                                color: 'white'
                            }}
                            variant="contained"
                            onClick={() => {
                                setShowBannerDetails(false)
                            }}
                        >
                            Save
                        </Button>

                        <Button
                            style={{
                                backgroundColor: '#DDDDDD',
                                color: 'black'
                            }}
                            variant="contained"
                            onClick={() => {
                                setShowBannerDetails(false)
                            }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>

                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddBannerDialog