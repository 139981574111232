import React, { useEffect, useState } from 'react';
import styles from './FormDetails.module.scss';
import Loader from '../../Library/Loader';
import clsx from 'clsx';
import FormHeader from './FormHeader/FormHeader';
import FormFields from './FormFields/FormFields';
import { useParams } from 'react-router-dom';
import { nodeAxios } from '../../../utils/axiosConfig';

const FormDetails = (props) => {
  const { id } = useParams();

  const [form, setForm] = useState(null);
  console.log('🚀OUTPUT --> form:', form);

  const fetchForm = (params) => {
    setForm(null);
    nodeAxios
      .get(`/web-builder/web-page-form/get/${id}`, { params })
      .then((response) => {
        setForm({
          data: response.data?.data,
        });
      })
      .catch((error) => {
        setForm({
          data: null,
        });
        console.log('🚀 ~ error', error);
      });
  };

  useEffect(() => {
    if (id) {
      fetchForm();
    }
  }, [id]);

  return !form ? (
    <Loader />
  ) : !form?.data ? (
    <div className={styles.no_record_find}>No Record Found</div>
  ) : (
    <div>
      <div className={clsx(styles.children_profile)}>
        <FormHeader form={form} fetchForm={fetchForm} {...props} />
        <div className={styles.child_body}>
          <FormFields form={form} fetchForm={fetchForm} {...props} />
        </div>
      </div>
    </div>
  );
};

export default FormDetails;
