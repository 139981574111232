const initialState = {
    tags: [],
    tagProducts: [],
    tagProductsToAdd: {
        pagination: {},
        result: []
    }
};
function tagList(state = initialState, action) {
    switch (action.type) {
        case "SET_TAG_LIST": {
            return {...state,tags: action.payload}
        };
        case "DELETE_TAG": {
            let newTags = [...state.tags].filter(e => e.id !== action.payload);
            return {...state, tags: newTags};
        }
        case "GET_TAG_PRODUCTS": {
            return {...state, tagProducts: action.payload}
        }
        case "GET_TAG_PRODUCTS_TO_ADD": {
            return {...state, tagProductsToAdd: action.payload}
        }
        case "DELETE_PRODUCT":{
            let newTagProducts = [...state.tagProducts].filter(e => e.product_id !== action.payload);
            return {...state, tagProducts: newTagProducts};
        }
        case "CREATE_TAG": {
            return {...state, tags: [...state.tags,action.payload]}
        }
        case "ADD_PRODUCT_TO_TAG": {
            let newTagProductsToAdd = {result: [...state.tagProductsToAdd.result].filter(e => e.ProductId !== action.payload.productId), pagination: state.tagProductsToAdd.pagination}
            return {...state, tagProductsToAdd: newTagProductsToAdd, tagProducts: [action.payload.newProduct, ...state.tagProducts]};
        }
        default:
            return state;
    }
};
export default tagList;  