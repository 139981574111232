import { Check, Close } from '@mui/icons-material';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from '../../../utils/axiosConfig'
import styles from './IntegrationsDialog.module.scss'

const IntegrationsDialog = (props) => {

    const user = useSelector(state => state.user) || [];
    const steps = useSelector(state => state.user?.data?.profile_status?.steps) || [];
    const [integration, setIntegration] = useState({})

    useEffect(() => {
        setIntegration(steps.find(e => e.page === "Integration"))
    }, [steps])

    const save = async () => {
        axios.post('/venue/steps/integration', { is_integration: "1" })
            .then(() => {
                toast.success('Call Scheduled')
            })
            .catch(() => {
                toast.error('Something went wrong')
            })
    }

    useCalendlyEventListener({
        onEventScheduled: (e) => save(e.data.payload),
    });

    return (
        <>
            <DialogTitle
                textAlign="center"
            >
                Integration {props.platformName && `for ${props.platformName}`}

                <IconButton
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}
                    onClick={props.onClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <div>
                        You can't start selling until we integrate Your inventory. Please schedule time on calendar
                    </div>

                    <div>
                        <div
                            className={styles.intel}
                            style={{ display: integration?.saved === true ? 'flex' : 'none' }}
                        >
                            <div
                                className={styles.dialog}
                            >
                                You have already scheduled a call
                            </div>
                            <Check style={{ color: 'green' }} />
                        </div>
                        <div>
                            <InlineWidget
                                styles={{ height: 530, maxWidth: 600, margin: 'auto' }}
                                url="https://calendly.com/ourliquorstore/15min"
                                prefill={{
                                    customAnswers: {
                                        a1: props.platformName ? `${user.data?.name} is requesting a call for ${props.platformName} integration` : ''
                                    },
                                    email: user.data?.email,
                                    name: user.data?.name
                                }}
                            />
                        </div>
                    </div>

                </div>
            </DialogContent>
        </>
    )
}

export default IntegrationsDialog