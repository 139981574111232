import React, { useState, useEffect, useCallback } from 'react'
import { Button,DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, outlinedInputClasses } from '@mui/material'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import axios from '../../Util/axiosConfig'
import Loader from '../Library/Loader/Loader'
import { Close } from '@mui/icons-material'
import GenericSelectWithLabel from '../Library/GenericSelectWithLabel/GenericSelectWithLabel'

const SelectFormDialog = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [forms, setForms] = useState(null); 
    const [selectedForm, setSelectedForm] = useState(null); 

    const fetchForms = (params, prevData) => {
        if (!prevData) {
            setForms(null);
        }
      axios
        .get('/web-builder/web-page-form', { params })
        .then((response) => {
            if (prevData) {
                setForms({
                    data: [...prevData, ...response.data?.data?.results],
                    pagination: response.data?.data?.pagination,
                  });
            } else  {
                setForms({
                    data: response.data?.data?.results,
                    pagination: response.data?.data?.pagination,
                });
            }
        })
        .catch((error) => {
          setForms({
            data: [],
          });
        });
    };

    useEffect(() => {
        fetchForms();
    }, [])
    

    const formik = useFormik({
        initialValues: {
            form_id: props.id,
            title: '',
            is_email: false
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setIsLoading(true)
            axios.post('/web-builder/web-page-custom-form/create', values)
                .then(() => {
                    props.onSuccess(props.id)
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    })

    return (
        <form
            onSubmit={formik.handleSubmit}
        >
            <DialogTitle className='tw-flex tw-justify-between tw-items-start '>
                <div>
                    <div className='tw-font-bold'>Select a form to add in this section</div>
                    <div className='tw-text-sm'>You need to add a form before using this section in your website.</div>
                </div>
                <IconButton onClick={props.onCancel}>
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {!forms ? <Loader /> : forms?.data?.length === 0 ? (
                    <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-min-h-64 tw-w-full tw-max-w-[380px] tw-text-center tw-mb-2'>
                        <div>Seems like you do not have any form yet, click here to create new.</div>
                        <Button
                            LinkComponent={'a'}
                            href='/forms'
                            variant='contained'
                            color='primary'
                            className='!tw-rounded-3xl !tw-transform-none'
                        >
                            Create New
                        </Button>
                    </div>
                ) : (
                    <div>

                        <GenericSelectWithLabel
                            containerStyle={{ width: '100%' }}
                            variant="outlined"
                            // label={}
                            placeholder={'Select Form'}
                            value={selectedForm}
                            onChange={(event) => {
                                // console.log(event.target.value)
                                setSelectedForm(event.target.value)
                            }}
                            sx={{
                                [`& .${outlinedInputClasses.notchedOutline}`]:{
                                    color: '#2e2e2e !important',
                                    outline: 'none !important',
                                    boxShadow: 'none  !important',
                                    border: '1px solid #d3d3d3 !important'
                                },
                            }}
                        >

                        {forms?.data?.map(form => (
                            <MenuItem value={form.id} >{form.title}</MenuItem>
                        ))}
                        </GenericSelectWithLabel>
                        
                        {/* {forms?.data?.map(form => (
                            <div className='tw-flex tw-justify-between tw-items-center'>
                                <span>{form?.title}</span>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => props.onUseForm(form.id)}
                                >Use Form</Button>
                            </div>
                        ))} */}
                    </div>
                )}
            </DialogContent>
            <DialogActions sx={{ p: '5px 24px 10px' }}>
                {/* <Button
                    variant='outlined'
                    onClick={props.onCancel}
                    style={{
                        width: 'auto',
                        height: '30px',
                        border: 'none',
                        borderRadius: '60px',
                        color: 'black',
                    }}
                >
                    Cancel
                </Button> */}
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        if (!selectedForm) {
                            toast.error('Please select a form to use')
                            return
                        }
                        props.onUseForm(selectedForm)
                    }}
                >Use Form</Button>
            </DialogActions>
        </form>
    )
}

export default SelectFormDialog