import React, { useState } from 'react'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import { Button, CircularProgress, IconButton, InputAdornment } from '@mui/material'
import { East, HelpOutlineOutlined, Visibility, VisibilityOff, West } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import routePaths from '../../../config/route-paths'
import GoogleAddressInput from '../../Library/GoogleAddressInput/GoogleAddressInput'
import LightTooltip from '../../../util_comps/LightTooltip'
import PageLoader from '../../Library/PageLoader/PageLoader'

const Signup = ({ formik, sm, onPrev, isLoading }) => {

  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)

  return (
    <div
      className="tw-flex tw-justify-center tw-h-full"
    >
      {isLoading && (
        <PageLoader />
      )}
      <div
        className="tw-flex tw-items-center tw-justify-center tw-h-full tw-flex-col tw-max-w-md tw-gap-3 tw-p-4"
      >
        <div
          className="tw-font-semibold tw-text-[21px] tw-text-left sm:tw-relative tw-pb-[10px]"
        >
          {onPrev && (
            <div
              className="tw-absolute tw-top-2 tw-left-2 sm:tw-top-0 sm:-tw-left-14"
            >
              {sm ? (
                <Button
                  style={{
                    color: 'black'
                  }}
                  variant="text"
                  startIcon={<West fontSize="medium" />}
                  onClick={onPrev}
                >
                  Back
                </Button>
              ) : (
                <IconButton
                  onClick={onPrev}
                >
                  <West style={{ color: 'black' }} fontSize="large" />
                </IconButton>
              )}
            </div>
          )}
          Save your online portal, so that you can make any changes to it later.
        </div>

        {/* <GenericTextFieldWithLabel
          isSmallLabel={true}
          containerClassname="tw-w-full"
          variant="standard"
          label="Restaurant Name"
          placeholder="Your restaurant name"
          name="Name"
          value={formik.values.Name}
          onChange={formik.handleChange}
          error={formik.touched.Name && Boolean(formik.errors.Name)}
          helperText={formik.touched.Name && formik.errors.Name}
          fullWidth={true}
          required={true}
        /> */}

        <GenericTextFieldWithLabel
          isSmallLabel={true}
          containerClassname="tw-w-full"
          variant="standard"
          label="Email"
          placeholder="name@business.com"
          name="email"
          autoComplete="off"
          inputProps={{
            autocomplete: "off"
          }}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth={true}
          required={true}
        />

        <GenericTextFieldWithLabel
          isSmallLabel={true}
          containerClassname="tw-w-full"
          variant="standard"
          label="Password"
          type={showPass ? "text" : "password"}
          placeholder="Password"
          autoComplete="off"
          inputProps={{
            autocomplete: "off"
          }}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          fullWidth={true}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  onClick={() => setShowPass((prevState) => !prevState)}
                  edge='end'
                >
                  {showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          required={true}
        />

        {(!formik.values.Address1 || !formik.values.latitude || !formik.values.longitude) && (
          <GoogleAddressInput
            label={
              <>
                Address

                <span
                  className="tw-mx-[2px]"
                >
                  <LightTooltip
                    title={(<span
                      style={{ display: 'inline-block', padding: '0 15px' }}
                    >
                      An address is crucial for a restaurant as it serves as a geographical marker, making it easily locatable for potential customers, thereby increasing foot traffic and visibility, ultimately boosting revenue and establishing a strong presence in the community.
                    </span>)}
                    arrow
                    placement="top"
                  >
                    <HelpOutlineOutlined
                      style={{
                        fontSize: 12
                      }}
                    />
                  </LightTooltip>
                </span>
              </>
            }
            containerClasses="tw-w-full"
            isLabel={true}
            variant="standard"
            isSmallLabel={true}
            placeholder="Enter and select address from list"
            value={formik.values.Address1}
            setLat={lat => formik.setFieldValue('latitude', lat)}
            setLng={lng => formik.setFieldValue('longitude', lng)}
            setAddress={address => formik.setFieldValue('address1', address)}
            setCity={city => formik.setFieldValue('city', city)}
            setState={state => formik.setFieldValue('state', state)}
            setCountry={country => formik.setFieldValue('country', country)}
            required={true}
          />
        )}

        <div
          className="tw-mt-[10px] tw-w-full"
        >
          <Button
            style={{
              color: 'white',
              backgroundColor: 'black',
              borderRadius: 30,
              height: 40,
              fontWeight: 400
            }}
            fullWidth={true}
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress style={{ color: 'white' }} size={18} /> : "Get My Restaurant Online"}
          </Button>
        </div>

        <div
          className="tw-flex tw-flex-col tw-gap-5 tw-m-5 tw-mb-0"
        >
          <div
            className="tw-text-center tw-text-light-gray tw-text-sm"
          >
            Already have a Esplanda account?
            <span
              className="tw-ml-1 tw-text-[#0073DD] tw-cursor-pointer"
              onClick={() => {
                navigate('/login')
              }}
            >
              Login <East fontSize="small" />
            </span>
          </div>

          <div
            className="tw-text-center tw-text-light-gray tw-text-xs"
          >
            By creating an account, you agree to our

            <a
              href="https://esplanda.com/terms"
              target="_blank"
            >
              <span
                className="tw-ml-1 tw-text-light-gray tw-cursor-pointer tw-underline"
              >
                Terms of Service
              </span>
            </a>
            {' '}
            and have read and understood the
            <a
              href="https://esplanda.com/privacy"
              target="_blank"
            >
              <span
                className="tw-ml-1 tw-text-light-gray tw-cursor-pointer tw-underline"
              >
                Privacy Policy
              </span>
            </a>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Signup