import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import './Layout.scss';
import { cns, classname } from '../../utils/bem'
import clsx from 'clsx';
// import esplandaLogo from "../../assets/images/esplanda-white-logo.png";
import Const from '../helpers/const';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Library/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth, logout } from '../../redux/actions/userActions';
import { getVenueStoreAdmin, getVenueStoreList, shadowLogin, superAdminLogin } from '../../redux/actions/venueStoreAction';
import Autocomplete from "react-autocomplete";
import { getOrderCount } from '../../redux/actions/orderActions';
import Order from '../DriverOrder/Order';
import MarketingIcon from '../../assets/images/marketing.svg'
import { Add, AllInbox, Create, Description, ExitToApp, Home, Settings, List as ListIcon, Assignment, CreditCard, CardGiftcard, ShoppingCart, Dvr, DriveEta, QueryBuilder, Construction, Sync, Fullscreen, FullscreenExit, DeleteForever, ExpandMore, ChevronRight, ExpandLess, Hub, Inventory, ChangeCircle, Details, Category, Campaign, ConstructionOutlined, FolderCopy } from '@mui/icons-material';
import { Button, Collapse, useMediaQuery } from '@mui/material';
import axios, { nodeAxios } from '../../utils/axiosConfig'
import { toast } from 'react-toastify';
import routePaths from '../../config/route-paths';
import styles from './Layout.module.scss'
import PageLoader from '../Library/PageLoader/PageLoader';
import { getImage, getImage2 } from '../../utils/helpers';
import { useConfirm } from 'material-ui-confirm';
import { TreeView } from '@mui/x-tree-view';
import StyledTreeItem from '../Library/StyledTreeItem/StyledTreeItem';
import { MarketingProvider } from '../Marketing2/Util/marketingContext';
import { OrdersIcon, ProductsIcon, SettingsIcon, WebsiteIcon } from '../../assets/svgs/svgs';
import SidebarItem from './SidebarItem/SidebarItem';
import Cookies from 'js-cookie'
import { debounce } from 'lodash';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: '#000000',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    }
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: `calc(${theme.spacing(7)} + 1px)`,
    width: 0,
    backgroundColor: '#000000',
    [theme.breakpoints.up('sm')]: {
        width: 62,
        // width: `calc(${theme.spacing(8)} + 1px)`,
    },

    //         width: 62,
    //         backgroundColor: '#000000',
    //         [theme.breakpoints.up('sm')]: {
    //             width: 62,
    //         },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: 42,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, isFullScreen }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#000000',
    height: 42,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        // marginLeft: isFullScreen ? 0 : drawerWidth,
        // width: isFullScreen ? '100%' : `calc(100% - ${drawerWidth}px)`,
        marginLeft: 0,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            width: 0,
        }
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => {
        return ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        })
    },
);

const Layout = ({ open, setOpen, children }) => {

    const scopes = Cookies.get('scopes')?.split(',') || []
    const isFullScreenRef = useRef()
    const isTemplateSelectedRef = useRef()
    const dispatch = useDispatch()
    const cn = classname('layout')
    const history = useNavigate();
    const location = useLocation()
    // const classes = useStyles();
    const theme = useTheme();
    const confirm = useConfirm()


    const isSmall = useMediaQuery('(max-width:1000px)');
    const isMobileScreen = useMediaQuery('(max-width:600px)');

    const [venue_name, setVenueName] = useState("");
    const [currentTab, setCurrentTab] = useState("Delivery");
    const [isLoading, setIsLoading] = useState(false);
    const [OrderType, setOrderType] = useState("Delivery");
    const [OrderStatus, setOrderStatus] = useState("Open");
    const [isTemplateSelected, setIsTemplateSelected] = useState(false)
    const [templateId, setTemplateId] = useState()
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [expanded1, setExpanded1] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    const [expanded3, setExpanded3] = useState(false)
    const [pages, setPages] = useState([]);
    const [names, setNames] = useState([]);
    const [subDomain, setSubDomain] = useState('')
    const [IsCustomDomain, setIsCustomDomain] = useState(false)
    const [isOrderPageReset, setIsOrderPageReset] = useState(false)
    const [sidebar, setSidebar] = useState([])
    const [venueList, setVenueList] = useState([])

    const user = useSelector((store) => store.user);
    const venueAdmin = useSelector((store) => store.venueStore.venueStoreAdmin);
    const orderCount = useSelector(store => store.OrderCount?.orderCount?.data?.results);

    useEffect(() => {
        isTemplateSelectedRef.current = isTemplateSelected
    }, [isTemplateSelected])


    const handleDelete = () => {
        confirm({
            title: 'Are you Sure you would like to delete your account.',
            description: 'You will not be able to see your history or interact with our service anymore. All your account data will be deleted and cannot be recovered. '
        })
            .then(() => {
                axios.get('/delete-profile')
                    .then(() => {
                        dispatch(logout()).then((res) => {
                            if (res === "success") {
                                history("/");
                            }
                        })
                    })
            })
            .catch((err) => {
            });
    }

    const fetchSubdomains = () => {
        nodeAxios
            .get('/web-builder/web-page/domains')
            .then((response) => {
                if (Boolean(response.data?.data?.subdomain)) {
                    if (response.data?.data?.custom_domain) {
                        setIsCustomDomain(true)
                        setSubDomain(
                            `https://${response.data?.data?.custom_domain}`
                        );
                    } else {
                        setIsCustomDomain(false)
                        setSubDomain(
                            `https://${response.data?.data?.subdomain}.esplanda.com`
                        );
                    }

                    axios.get('/web-builder/web-page')
                        .then((response2) => {
                            if (response2.data?.data?.results?.length) {
                                setIsTemplateSelected(true)
                                setTemplateId(response.data?.data?.template_id)
                            } else {
                                setIsTemplateSelected(false)
                            }
                        })
                        .catch(() => {

                        })
                }
            })
            .catch(() => {
                // setSubDomain('');
            });
    };

    useEffect(() => {
        fetchSubdomains()
    }, [user])


    useEffect(() => {
        setIsLoading(false);
    }, [OrderStatus]);

    useEffect(() => {
        setIsLoading(false);
    }, [OrderType]);

    useEffect(() => {
        if (user?.data?.biz_users_role == 2) {
            dispatch(getOrderCount(`status=${getOrderStatusByFullLength(
                OrderStatus
            )}&type=${OrderType?.toLowerCase()}&page=1`));
        }
    }, [currentTab, user]);

    const getOrderStatus = (status) => {
        return status == "o"
            ? "Open"
            : status == "a"
                ? "Accepted"
                : status == "r"
                    ? "EnRoute"
                    : status == "can"
                        ? "Cancelled"
                        : "Closed";
    };

    const getOrderStatusByFullLength = (status) => {
        return status == "Open"
            ? "o"
            : status == "Accepted"
                ? "a"
                : status == "EnRoute"
                    ? "r"
                    : status == "Cancelled"
                        ? "can"
                        : "clo";
    };

    const handleTabChange = (value) => {
        setCurrentTab(value);
        setIsLoading(true);
        setOrderType(value);
        setOrderStatus("Open");
    }

    const changeOrderTab = (value) => {
        setOrderStatus(getOrderStatus(value));
        dispatch(getOrderCount(`status=${getOrderStatusByFullLength(
            OrderStatus
        )}&type=${OrderType?.toLowerCase()}&page=1`));
    }


    useEffect(() => {
        if (venueAdmin !== undefined) {
            if (venueAdmin?.data?.user?.id !== undefined) {
                dispatch(shadowLogin(venueAdmin?.data?.user?.id))
                    .then(() => {
                        history(routePaths.myStoreSetting);
                    })
            }
        }
        if (window?.ReactNativeWebView) {
            setOpen(false);
        }
    }, [venueAdmin]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleToggleDrawer = () => {
        setOpen(open => !open);
    };

    const handleDrawerClose = () => {
        if (window.innerWidth <= 600) {
            setOpen(false);
        }
    };

    const handleVenueConversion = () => {
        axios.post('/venue/claim-business', {
            is_claim_business: 0
        })
            .then(() => {
                toast.success('Congratulations now you can access all of our features.')
                dispatch(checkAuth())
                    .then((response) => {

                        if (response.roleID === 1) {
                            history(routePaths.myStoreSetting);
                        } else if (response.roleID === 0) {
                            history(routePaths.commingSoon);
                        } else if (response.roleID === 2) {
                            history(routePaths.orderDashboard);
                        }


                    })
                    .catch(() => {
                        localStorage.removeItem('userToken')
                        history('/', { replace: true })
                    })
            })
    }

    useEffect(() => {

        const fullScreenEventName = 'onfullscreenchange' in document ? 'fullscreenchange' :
            'onwebkitfullscreenchange' in document ? 'webkitfullscreenchange' :
                'onmozfullscreenchange' in document ? 'mozfullscreenchange' :
                    null;

        if (fullScreenEventName) {
            document.addEventListener(fullScreenEventName, function (event) {
                if (document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement) {
                    console.log('Entered fullscreen mode');
                    if (!isFullScreenRef.current) {
                        isFullScreenRef.current = true
                        setIsFullScreen(true)
                    }
                } else {
                    if (isFullScreenRef.current) {
                        isFullScreenRef.current = false
                        setIsFullScreen(false)
                    }
                    console.log('Exited fullscreen mode');
                }
            });
        } else {
            console.warn('Fullscreen API is not supported in this browser');
        }

    }, [])


    const handleToggle1 = () => {
        if (open) {
            setExpanded1((prevState) => !prevState);
        }
    };

    const handleToggle3 = () => {
        if (open) {
            setExpanded3((prevState) => !prevState);
        }
    };

    const handleToggle2 = () => {
        if (open) {
            setExpanded2((prevState) => !prevState);
        } else {
            setOpen(true)
            setExpanded2((prevState) => !prevState);
        }
    };


    const fetchNewSocialData = () => {
        return axios.post('/marketing/social-insignts/fb-insta-page-insights-sync');
    };

    const fetchFbList = () => {
        axios
            .get('/marketing/social-page/list')
            .then((response) => {
                setPages(
                    response.data.data.results.map((data) => ({
                        ...data,
                        name: data.title,
                        isFacebook: data.type_id == 0,
                        isInstagram: data.type_id == 1,
                        isTwitter: data.type_id == 2,
                    }))
                );
            })
            .catch(() => {
                setPages([]);
            })
            .finally(() => {

            });
    };

    const fetchSocialList = (cb) => {
        // axios.get('/social-account/my')
        //   .then((response) => {
        //     setExternalWebsite(response?.data?.data?.result?.external_website);
        //     setSubDomain(response?.data?.data?.result?.sub_domain);
        //     if (response?.data?.data?.result?.mail_chimp_api_key) {
        //       setIsEmailConnected(true);
        //     } else {
        //       setIsEmailConnected(false);
        //     }
        //     if (response?.data?.data?.result?.tw_oauth_token) {
        //       setNames((prevState) => [
        //         ...prevState,
        //         {
        //           id: response?.data?.data?.result?.tw_oauth_token,
        //           name: response?.data?.data?.result?.tw_screen_name,
        //           isTwitter: true,
        //         },
        //       ]);
        //       console.log('twiter', response?.data?.data?.result?.tw_oauth_token);
        //     } else {
        //       setNames([]);
        //     }
        //     cb();
        //   })
        //   .catch(() => {
        //     // setNames([])
        //   })
        //   .finally(() => {
        //     setisSocialListLoading(false);
        //   });
    };

    const handleFBDisconnect = (cb, setFbLoading, setInstaLoading) => {
        confirm({
            title: 'Do you wish to disconnect Facebook/Instagram',
        })
            .then(() => {
                axios
                    .post('/marketing/social-account/disconnect-fb-insta')
                    .then(() => {
                        fetchFbList();
                        toast.success('Facebook Disconnected');
                        cb();
                    })
                    .catch((err) => {
                        setFbLoading(false);
                        setInstaLoading(false);
                        toast.error(err?.response?.data?.message);
                    });
            })
            .catch(() => {
                setFbLoading(false);
                setInstaLoading(false);
            });
    };

    const handleTwitterDisconnect = (cb) => {
        confirm({
            title: 'Do you wish to disconnect Twitter',
        })
            .then(() => {
                axios
                    .post('/marketing/social-account/disconnect-twitter')
                    .then(() => {
                        fetchAll();
                        toast.success('Twitter Disconnected');
                        cb();
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message);
                    });
            })
            .catch(() => { });
    };

    const fetchAll = () => {
        fetchNewSocialData()
            .then(() => {
                fetchFbList();
                fetchSocialList();
            })
            .catch(() => {
                fetchFbList();
                fetchSocialList();
            });
    };

    useEffect(() => {
        fetchAll();
    }, []);


    useEffect(() => {
        if (!open) {
            setExpanded2(false)
        }
    }, [open])

    const adminSidebarItems = [
        {
            href: '/comingSoon',
            label: 'Dashboard',
            icon: (
                <Home
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/ProductMaster',
            label: 'Product Master',
            icon: (
                <Description
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/CategoryList',
            label: 'Category',
            icon: (
                <AllInbox
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/CreateVenue',
            label: 'Create venue',
            icon: (
                <Add
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/BannerListing',
            label: 'Banner Listing',
            icon: (
                <ListIcon
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/ComingSoon',
            label: 'Lookup report',
            icon: (
                <Assignment
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/ComingSoon',
            label: 'Payments',
            icon: (
                <CreditCard
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/GiftCardListing',
            label: 'eGiftCards',
            icon: (
                <CardGiftcard
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/GiftCardHistory',
            label: 'Gift Card Purchases',
            icon: (
                <ShoppingCart
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            href: '/marketing',
            label: 'Marketing',
            icon: (
                <Campaign
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            label: 'Websites Admin',
            icon: (
                <Sync
                    style={{
                        color: 'black'
                    }}
                />
            ),
            child: [
                {
                    href: '/sync-website',
                    label: 'Sync Website'
                },
                {
                    href: routePaths.marketingPosts,
                    label: 'Posts'
                },
            ]
        },
        {
            href: routePaths.adminTemplate,
            label: 'Template',
            icon: (
                <FolderCopy
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
    ]

    const packageStoreAdminSidebarItems = [
        {
            id: 'dashboard',
            href: '/dashboard',
            label: 'Dashboard',
            icon: (
                <Home
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            id: 'products',
            href: '/products',
            label: 'My Inventory',
            icon: (
                <Description
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        // {
        //     href: '/store',
        //     label: 'Store',
        //     icon: (
        //         <OrdersIcon />
        //     ),
        // },
        {
            id: 'venueorderlisting',
            href: '/VenueOrderListing',
            label: 'Orders',
            icon: (
                <OrdersIcon />
            ),
        },
        {
            id: 'customize-homepage',
            href: '/customize-homepage',
            label: 'Customize Homepage',
            icon: (
                <Create
                    style={{
                        color: 'black'
                    }}
                />
            ),
        },
        {
            id: 'mystoresetting',
            href: routePaths.myStoreSetting,
            label: 'My Store Settings',
            icon: (
                <SettingsIcon />
            ),
        },
        {
            label: 'Settings',
            icon: (
                <SettingsIcon />
            ),
            child: [
                {
                    href: routePaths.paymentAccount,
                    label: 'Payments',
                    icon: (
                        <div className='tw-w-20 tw-h-20' />
                    ),
                },
                {
                    href: routePaths.orderSettings,
                    label: 'Order Settings',
                    icon: (
                        <div className='tw-w-20 tw-h-20' />
                    ),
                },
                (Boolean(window?.ReactNativeWebView) && {
                    onClick: () => {
                        handleDrawerClose()
                        window?.ReactNativeWebView?.postMessage(JSON.stringify({ sidemenusetting: "yes" }));
                    },
                    label: 'Printer Settings',
                    icon: (
                        <Settings
                            style={{
                                color: 'black'
                            }}
                        />
                    )
                }),
            ]
        },
        {
            id: 'marketing',
            label: 'Marketing',
            icon: (
                <Campaign
                    style={{
                        color: 'black'
                    }}
                />
            ),
            child: [
                {
                    href: routePaths.marketingDashboard,
                    label: 'Dashboard',
                },
                {
                    href: routePaths.marketingPosts,
                    label: 'Posts',
                },
                {
                    href: routePaths.marketingPlanner,
                    label: 'Planner',
                },
            ]
        },

        {
            id: 'website',
            onClick: () => {
                handleDrawerClose()
                console.log({ isTemplateSelected: isTemplateSelectedRef.current })
                if (isTemplateSelectedRef.current) {
                    history(routePaths.manageSite2)
                } else {
                    history(routePaths.websitegetStarted);
                }
            },
            label: 'Website',
            icon: (
                <WebsiteIcon />
            ),
        },
        ((user?.data?.is_claim_business === 1) && {
            href: '/manage-listing',
            label: 'Manage Listing',
            icon: (
                <Dvr
                    style={{
                        color: 'black'
                    }}
                />
            ),
        })
    ]

    const moreAccessPackageStore = useMemo(() => {
        const filteredItems = [];

        packageStoreAdminSidebarItems?.forEach((data) => {

            if (data.id) {
                if (!scopes?.includes(data.id)) {
                    filteredItems.push(data)
                }
            } else {
                if (data.child?.length) {
                    if (data.child?.filter((data1) => data1.id && !scopes?.includes(data1.id))?.length) {
                        filteredItems.push({
                            ...data,
                            child: data.child?.filter((data1) => data1.id && !scopes?.includes(data1.id))
                        })
                    }
                }
            }
        })

        return filteredItems;
    }, [isTemplateSelected]);

    const packageStoreManagerSidebarItems = [
        ...moreAccessPackageStore,
        // {
        //     id: 'products',
        //     href: '/products',
        //     label: 'My Inventory',
        //     icon: (
        //         <Description
        //             style={{
        //                 color: 'black'
        //             }}
        //         />
        //     ),
        // },
        // {
        //     id: 'venueorderlisting',
        //     href: '/VenueOrderListing',
        //     label: 'Orders',
        //     icon: (
        //         <OrdersIcon />
        //     ),
        // },
        // (Boolean(window?.ReactNativeWebView) && {
        //     onClick: () => {
        //         handleDrawerClose()
        //         window?.ReactNativeWebView?.postMessage(JSON.stringify({ sidemenusetting: "yes" }));
        //     },
        //     label: 'Settings',
        //     icon: (
        //         <Settings
        //             style={{
        //                 color: 'black'
        //             }}
        //         />
        //     )
        // })
    ]

    const packageStoreDriverSidebarItems = [
        ...moreAccessPackageStore,
        // {
        //     id: 'venueorderlisting',
        //     href: '/VenueOrderListing',
        //     label: 'Orders',
        //     icon: (
        //         <OrdersIcon />
        //     ),
        // },
        // (Boolean(window?.ReactNativeWebView) && {
        //     onClick: () => {
        //         handleDrawerClose()
        //         window?.ReactNativeWebView?.postMessage(JSON.stringify({ sidemenusetting: "yes" }));
        //     },
        //     label: 'Settings',
        //     icon: (
        //         <Settings
        //             style={{
        //                 color: 'black'
        //             }}
        //         />
        //     )
        // })
    ]

    const restaurantStoreAdminSidebarItems = [
        {
            id: 'home',
            href: '/home',
            label: 'Home',
            icon: (
                <Home style={{ color: 'black' }} />
            ),
        },
        {
            id: 'menu',
            href: '/menu',
            label: 'Menu',
            icon: (
                <svg width="24" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H1.71429V16H0V0ZM6 3.2H13V4.8H6V3.2ZM6 6.4L13 6.5V8H6V6.4Z" fill="black" />
                    <path d="M14.2667 0H3V16H14.2667C15.2226 16 16 15.2824 16 14.4V1.6C16 0.7176 15.2226 0 14.2667 0ZM14.2667 14.4H4.73333V1.6H14.2667V14.4Z" fill="black" />
                </svg>
            ),
        },
        // {
        //     href: '/store',
        //     label: 'Store',
        //     icon: (
        //         <OrdersIcon />
        //     ),
        // },
        {
            id: 'venueorderlisting',
            href: '/VenueOrderListing',
            label: 'Orders',
            icon: (
                <OrdersIcon />
            ),
        },
        {
            id: 'mystoresetting',
            href: routePaths.myStoreSetting,
            label: 'My Store Settings',
            icon: (
                <SettingsIcon />
            ),
        },
        {
            label: 'Settings',
            icon: (
                <SettingsIcon />
            ),
            child: [
                {
                    href: routePaths.paymentAccount,
                    label: 'Payments',
                    icon: (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                        </svg>

                    ),
                },
                {
                    href: routePaths.orderSettings,
                    label: 'Order Settings',
                    icon: (
                        <div className='tw-w-20 tw-h-20' />
                    ),
                },
                (Boolean(window?.ReactNativeWebView) && {
                    onClick: () => {
                        handleDrawerClose()
                        window?.ReactNativeWebView?.postMessage(JSON.stringify({ sidemenusetting: "yes" }));
                    },
                    label: 'Printer Settings',
                    icon: (
                        <Settings
                            style={{
                                color: 'black'
                            }}
                        />
                    )
                }),
            ]
        },
        {
            id: 'website',
            onClick: () => {
                handleDrawerClose()
                if (isTemplateSelectedRef.current) {
                    history(`/website-builder/manage-site`)
                } else {
                    history(routePaths.websitegetStarted);
                }
            },
            label: 'Website',
            icon: (
                <WebsiteIcon />
            ),
        },
        ((user?.data?.is_claim_business === 1) && {
            href: '/manage-listing',
            label: 'Manage Listing',
            icon: (
                <Dvr
                    style={{
                        color: 'black'
                    }}
                />
            ),
        })
    ]
    const moreAccessRestaurant = useMemo(() => {
        const filteredItems = [];

        restaurantStoreAdminSidebarItems?.forEach((data) => {

            if (data.id) {
                if (!scopes?.includes(data.id)) {
                    filteredItems.push(data)
                }
            } else {
                if (data.child?.length) {
                    if (data.child?.filter((data1) => data1.id && !scopes?.includes(data1.id))?.length) {
                        filteredItems.push({
                            ...data,
                            child: data.child?.filter((data1) => data1.id && !scopes?.includes(data1.id))
                        })
                    }
                }
            }
        })

        return filteredItems;
    }, [isTemplateSelected]);
    const restaurantStoreManagerSidebarItems = [
        ...moreAccessRestaurant,
        // {
        //     id: 'menu',
        //     href: '/menu',
        //     label: 'Menu',
        //     icon: (
        //         <svg width="24" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        //             <path d="M0 0H1.71429V16H0V0ZM6 3.2H13V4.8H6V3.2ZM6 6.4L13 6.5V8H6V6.4Z" fill="black" />
        //             <path d="M14.2667 0H3V16H14.2667C15.2226 16 16 15.2824 16 14.4V1.6C16 0.7176 15.2226 0 14.2667 0ZM14.2667 14.4H4.73333V1.6H14.2667V14.4Z" fill="black" />
        //         </svg>
        //     ),
        // },
        // {
        //     id: 'venueorderlisting',
        //     href: '/VenueOrderListing',
        //     label: 'Orders',
        //     icon: (
        //         <OrdersIcon />
        //     ),
        // },
        // (Boolean(window?.ReactNativeWebView) && {
        //     onClick: () => {
        //         handleDrawerClose()
        //         window?.ReactNativeWebView?.postMessage(JSON.stringify({ sidemenusetting: "yes" }));
        //     },
        //     label: 'Settings',
        //     icon: (
        //         <Settings
        //             style={{
        //                 color: 'black'
        //             }}
        //         />
        //     )
        // })
    ]
    const restaurantStoreDriverSidebarItems = [
        ...moreAccessRestaurant,
        // {
        //     id: 'venueorderlisting',
        //     href: '/VenueOrderListing',
        //     label: 'Orders',
        //     icon: (
        //         <OrdersIcon />
        //     ),
        // },
        // (Boolean(window?.ReactNativeWebView) && {
        //     onClick: () => {
        //         handleDrawerClose()
        //         window?.ReactNativeWebView?.postMessage(JSON.stringify({ sidemenusetting: "yes" }));
        //     },
        //     label: 'Settings',
        //     icon: (
        //         <Settings
        //             style={{
        //                 color: 'black'
        //             }}
        //         />
        //     )
        // })
    ]

    const iceCreamTruckStoreAdminSidebarItems = [
        {
            id: 'mystoresetting',
            href: routePaths.myStoreSetting,
            label: 'My Store Settings',
            icon: (
                <SettingsIcon />
            ),
        },
        {
            id: 'website',
            onClick: () => {
                handleDrawerClose()
                if (isTemplateSelectedRef.current) {
                    history(routePaths.manageSite2)
                } else {
                    history(routePaths.websitegetStarted);
                }
            },
            label: 'Website',
            icon: (
                <WebsiteIcon />
            ),
        },
    ]
    const moreAccessiceCreamTruck = useMemo(() => {
        const filteredItems = [];

        iceCreamTruckStoreAdminSidebarItems?.forEach((data) => {

            if (data.id) {
                if (!scopes?.includes(data.id)) {
                    filteredItems.push(data)
                }
            } else {
                if (data.child?.length) {
                    if (data.child?.filter((data1) => data1.id && !scopes?.includes(data1.id))?.length) {
                        filteredItems.push({
                            ...data,
                            child: data.child?.filter((data1) => data1.id && !scopes?.includes(data1.id))
                        })
                    }
                }
            }
        })

        return filteredItems;
    }, [isTemplateSelected]);

    const iceCreamTruckStoreManagerSidebarItems = [
        ...moreAccessiceCreamTruck,
    ]
    const iceCreamTruckStoreDriverSidebarItems = [
        ...moreAccessRestaurant,
    ]

    useEffect(() => {
        if (user?.data?.biz_users_role == 0) {
            setSidebar(adminSidebarItems)
        } else if (user?.data?.biz_users_role == 1) {
            switch (user?.data?.venue_type) {
                case 'Package Store':
                    setSidebar(packageStoreAdminSidebarItems)
                    break;
                case 'Restaurant':
                    setSidebar(restaurantStoreAdminSidebarItems)
                    break;
                case 'Ice Cream Truck':
                    setSidebar(iceCreamTruckStoreAdminSidebarItems)
                    break;

                default:
                    setSidebar(packageStoreAdminSidebarItems)
                    break;
            }
        } else if (user?.data?.biz_users_role == 2) {
            switch (user?.data?.venue_type) {
                case 'Package Store':
                    setSidebar(packageStoreManagerSidebarItems)
                    break;
                case 'Restaurant':
                    setSidebar(restaurantStoreManagerSidebarItems)
                    break;
                case 'Ice Cream Truck':
                    setSidebar(iceCreamTruckStoreManagerSidebarItems)
                    break;

                default:
                    setSidebar(packageStoreManagerSidebarItems)
                    break;
            }
        } else if (user?.data?.biz_users_role == 3) {
            switch (user?.data?.venue_type) {
                case 'Package Store':
                    setSidebar(packageStoreDriverSidebarItems)
                    break;
                case 'Restaurant':
                    setSidebar(restaurantStoreDriverSidebarItems)
                    break;
                case 'Ice Cream Truck':
                    setSidebar(iceCreamTruckStoreDriverSidebarItems)
                    break;

                default:
                    setSidebar(packageStoreDriverSidebarItems)
                    break;
            }
        }

    }, [user])

    const searchVenues = (q) => {
        nodeAxios.get('/venue/list', {
            params: {
                q: q
            }
        })
            .then((res) => {
                setVenueList(res.data.data)
            })
    }

    const debounceSearchVenues = useCallback(debounce(searchVenues, 500), [])

    return (
        <Box
            sx={{
                display: 'flex'
            }}
        >
            <AppBar
                position="fixed"
                open={open}
                isFullScreen={isFullScreen}
                classes={{
                    root: '!tw-bg-white !tw-text-black !tw-shadow-none tw-border-b tw-border-b-gray !tw-h-[51px]'
                }}
            >
                <Toolbar
                    style={{
                        minHeight: 'unset',
                        height: '100%',
                    }}
                >
                    {!isFullScreen && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleToggleDrawer}
                            edge="start"
                            style={{
                                "@media (min-width: 600px)": {
                                    marginRight: 36,
                                },
                                // ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon
                                style={{
                                    color: 'black'
                                }}
                            />
                        </IconButton>
                    )}

                    <div className={cn('logo-logout-container')}>
                        <div className={cn('logo-super-admin-option-container')}>
                            <img
                                className={cn('logo')}
                                src={getImage2('black-transparent.png-1707714914.png')}
                            />
                            {/* <div className='lg:tw-hidden tw-text-lg tw-font-bold'>{getTitle()}</div> */}

                            <div
                                className={cn('super-admin-input-container')}
                            >
                                {user?.data?.biz_users_role == 0 &&
                                    localStorage.getItem("isShiftedFrom") === "false" && (
                                        <>
                                            <div
                                                className={cn('shift-to-text')}
                                            >
                                                Shift To
                                            </div>
                                            <Autocomplete
                                                inputProps={{
                                                    id: "venue-autocomplete",
                                                    className: "form-control",
                                                }}
                                                wrapperStyle={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                    left: "7px",
                                                }}
                                                value={venue_name}
                                                items={venueList}
                                                getItemValue={(item) => item.Name}
                                                onSelect={(value, item) => {
                                                    console.log(value, item)
                                                    setVenueName(value);
                                                    if (item.VenueId != "") {
                                                        localStorage.setItem("StoreName", item.Name);
                                                        dispatch(getVenueStoreAdmin(item.VenueId));
                                                        //dispatch(shadowLogin(item.VenueId))
                                                    }
                                                }}
                                                onChange={(event, value) => {
                                                    setVenueName(value);
                                                    debounceSearchVenues(value)
                                                }}
                                                renderMenu={(children) => (
                                                    <div
                                                        className="menu"
                                                        style={{ color: "black !important" }}
                                                    >
                                                        {children}
                                                    </div>
                                                )}
                                                renderItem={(item, isHighlighted) => (
                                                    <div
                                                        className={clsx(styles.item, isHighlighted && styles.item_highlight)}
                                                        key={item.id}
                                                        style={{ color: "black !important" }}
                                                    >
                                                        {item.Name}
                                                    </div>
                                                )}
                                            />
                                        </>
                                    )}
                                {user?.data?.biz_users_role !== 0 && localStorage.getItem("isShiftedFrom") &&
                                    localStorage.getItem("isShiftedFrom") !== "false" && (
                                        <>
                                            <div
                                                className={cn('shift-to-text')}
                                            >
                                                Your store : <b>{localStorage.getItem("StoreName")}</b>
                                            </div>
                                            <button
                                                className={cn('shift-to-su-btn')}
                                                type="button"
                                                onClick={(e) => {
                                                    dispatch(superAdminLogin("7"));
                                                    setVenueName("");
                                                    history("/comingSoon");
                                                }}
                                            >
                                                Shift to SuperAdmin
                                            </button>
                                        </>
                                    )}
                            </div>

                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 20
                            }}
                        >
                            {!isMobileScreen && (location?.pathname?.includes('/VenueOrderListing') || location?.pathname?.includes('/order-details')) && (
                                <Button
                                    className='!tw-text-black'
                                    startIcon={isFullScreen ? <FullscreenExit /> : <Fullscreen />}
                                    onClick={() => {
                                        if (isFullScreen) {
                                            isFullScreenRef.current = false
                                            setIsFullScreen(false)
                                            document?.exitFullscreen()
                                        } else {
                                            isFullScreenRef.current = true
                                            setIsFullScreen(true)
                                            document?.documentElement?.requestFullscreen();
                                        }
                                    }}
                                >
                                    {isFullScreen ? "Exit Full Screen" : "Full Screen"}
                                </Button>
                            )}

                            {user?.data?.venue_name && (
                                <div className='tw-flex tw-items-center'>
                                    {user?.data?.venue_name}
                                </div>
                            )}

                            {!isMobileScreen && !isFullScreen && (
                                <div
                                    className='tw-cursor-pointer tw-flex tw-items-center'
                                    onClick={(e) =>
                                        dispatch(logout()).then((res) => {
                                            // localStorage.setItem("isShiftedFrom", false);
                                            // localStorage.setItem("superAdminToken", "");
                                            // localStorage.setItem("StoreName", "");
                                            if (res === "success") {
                                                history("/");
                                            }
                                        })
                                    }
                                >
                                    <ExitToApp />
                                    Logout
                                </div>
                            )}
                        </div>
                    </div>


                </Toolbar>
            </AppBar>

            {!isFullScreen && (
                <Drawer variant="permanent" open={open}
                    classes={{
                        paper: clsx('!tw-bg-white !tw-text-black', styles.drawer_paper)
                    }}
                >
                    <DrawerHeader className='tw-h-[50px]'>
                        <IconButton onClick={() => setOpen(false)}>
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon style={{
                                    color: 'black'
                                }}
                                />
                            ) : (
                                <ChevronLeftIcon
                                    style={{
                                        color: 'black'
                                    }}
                                />
                            )}
                        </IconButton>
                    </DrawerHeader>

                    <Divider />
                    <div className='tw-h-full tw-flex tw-flex-col tw-justify-between tw-gap-8'>
                        <div>
                            <List>
                                {sidebar?.map((data, index) => (
                                    <SidebarItem
                                        id={index + 1}
                                        open={open}
                                        setOpen={setOpen}
                                        icon={data.icon}
                                        label={data.label}
                                        href={data.href}
                                        isBelow1000Px={window.innerWidth < 1000}
                                        handleDrawerClose={handleDrawerClose}
                                        child={data.child}
                                        isActive={data.isActive}
                                        onClick={data.onClick}
                                    // onNodeToggle={data.onNodeToggle}
                                    // nodeId={data.nodeId}
                                    />
                                ))}

                                {/* {user?.data?.biz_users_role == 1 && (user?.data?.venue_type != 'Restaurant') && packageStoreAdminSidebarItems?.filter(data => data)?.map((data, index) => (
                                    <SidebarItem
                                        id={index + 1}
                                        open={open}
                                        setOpen={setOpen}
                                        icon={data.icon}
                                        label={data.label}
                                        href={data.href}
                                        isBelow1000Px={window.innerWidth < 1000}
                                        handleDrawerClose={handleDrawerClose}
                                        child={data.child}
                                        isActive={data.isActive}
                                        onClick={data.onClick}
                                    // onNodeToggle={data.onNodeToggle}
                                    // nodeId={data.nodeId}
                                    />
                                ))}

                                {user?.data?.biz_users_role == 2 && (user?.data?.venue_type != 'Restaurant') && packageStoreManagerSidebarItems?.filter(data => data)?.map((data, index) => (
                                    <SidebarItem
                                        id={index + 1}
                                        open={open}
                                        setOpen={setOpen}
                                        icon={data.icon}
                                        label={data.label}
                                        href={data.href}
                                        isBelow1000Px={window.innerWidth < 1000}
                                        handleDrawerClose={handleDrawerClose}
                                        child={data.child}
                                        isActive={data.isActive}
                                        onClick={data.onClick}
                                    // onNodeToggle={data.onNodeToggle}
                                    // nodeId={data.nodeId}
                                    />
                                ))}

                                {user?.data?.biz_users_role == 3 && (user?.data?.venue_type != 'Restaurant') && packageStoreDriverSidebarItems?.filter(data => data)?.map((data, index) => (
                                    <SidebarItem
                                        id={index + 1}
                                        open={open}
                                        setOpen={setOpen}
                                        icon={data.icon}
                                        label={data.label}
                                        href={data.href}
                                        isBelow1000Px={window.innerWidth < 1000}
                                        handleDrawerClose={handleDrawerClose}
                                        child={data.child}
                                        isActive={data.isActive}
                                        onClick={data.onClick}
                                    // onNodeToggle={data.onNodeToggle}
                                    // nodeId={data.nodeId}
                                    />
                                ))}

                                {user?.data?.biz_users_role == 1 && (user?.data?.venue_type == 'Restaurant') && restaurantStoreAdminSidebarItems?.filter(data => data)?.map((data, index) => (
                                    <SidebarItem
                                        id={index + 1}
                                        open={open}
                                        setOpen={setOpen}
                                        icon={data.icon}
                                        label={data.label}
                                        href={data.href}
                                        isBelow1000Px={window.innerWidth < 1000}
                                        handleDrawerClose={handleDrawerClose}
                                        child={data.child}
                                        isActive={data.isActive}
                                        onClick={data.onClick}
                                    // onNodeToggle={data.onNodeToggle}
                                    // nodeId={data.nodeId}
                                    />
                                ))}

                                {user?.data?.biz_users_role == 2 && (user?.data?.venue_type == 'Restaurant') && restaurantStoreManagerSidebarItems?.filter(data => data)?.map((data, index) => (
                                    <SidebarItem
                                        id={index + 1}
                                        open={open}
                                        setOpen={setOpen}
                                        icon={data.icon}
                                        label={data.label}
                                        href={data.href}
                                        isBelow1000Px={window.innerWidth < 1000}
                                        handleDrawerClose={handleDrawerClose}
                                        child={data.child}
                                        isActive={data.isActive}
                                        onClick={data.onClick}
                                    // onNodeToggle={data.onNodeToggle}
                                    // nodeId={data.nodeId}
                                    />
                                ))}

                                {user?.data?.biz_users_role == 3 && (user?.data?.venue_type == 'Restaurant') && restaurantStoreDriverSidebarItems?.filter(data => data)?.map((data, index) => (
                                    <SidebarItem
                                        id={index + 1}
                                        open={open}
                                        setOpen={setOpen}
                                        icon={data.icon}
                                        label={data.label}
                                        href={data.href}
                                        isBelow1000Px={window.innerWidth < 1000}
                                        handleDrawerClose={handleDrawerClose}
                                        child={data.child}
                                        isActive={data.isActive}
                                        onClick={data.onClick}
                                    // onNodeToggle={data.onNodeToggle}
                                    // nodeId={data.nodeId}
                                    />
                                ))} */}

                            </List>
                        </div>
                        <div>

                            {(user?.data?.biz_users_role != 0) && !Boolean(window?.ReactNativeWebView) && (
                                <>
                                    <ListItemButton
                                        // sx={{
                                        //     position: 'absolute',
                                        //     bottom: isMobileScreen ? '10rem' : '7rem',
                                        //     width: '100%'
                                        // }}
                                        fullWidth
                                        onClick={() => {
                                            window.open('https://apps.apple.com/us/app/esplanda-orders/id6464066329', '_blank')
                                        }}
                                    >
                                        <ListItemIcon
                                            style={{
                                                minWidth: '45px'
                                            }}
                                        >
                                            <img
                                                className={clsx(styles.download_app, !open && styles.drawer_close_app)}
                                                src={getImage2(open ? 'get-app.png-1704798072.png' : 'appstore.png-1707913757.png')}
                                                alt="Esplanda"
                                                title="App Store"
                                            />
                                        </ListItemIcon>
                                    </ListItemButton>

                                    <ListItemButton
                                        // sx={{
                                        //     position: 'absolute',
                                        //     bottom: isMobileScreen ? '14rem' : '11rem',
                                        //     width: '100%'
                                        // }}
                                        className='!tw-mb-8'
                                        fullWidth
                                        onClick={() => {
                                            window.open('https://play.google.com/store/apps/details?id=app.esplandaorders.com', '_blank')
                                        }}
                                    >
                                        <ListItemIcon
                                            style={{
                                                minWidth: '45px'
                                            }}
                                        >
                                            <img
                                                className={clsx(styles.download_app, !open && styles.drawer_close_app)}
                                                src={getImage2(open ? 'googleplay.jpg-1704798112.jpg' : 'playstore.webp-1707913683.webp')}
                                                alt="Esplanda"
                                                title="Play Store"
                                            />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </>
                            )}

                            {isMobileScreen && (
                                <>
                                    <ListItemButton
                                        // sx={{
                                        //     position: 'absolute',
                                        //     bottom: '2rem',
                                        //     width: '100%'
                                        // }}
                                        fullWidth
                                        onClick={() => {
                                            dispatch(logout()).then((res) => {
                                                // localStorage.setItem("isShiftedFrom", false);
                                                // localStorage.setItem("superAdminToken", "");
                                                // localStorage.setItem("StoreName", "");
                                                if (res === "success") {
                                                    history("/");
                                                }
                                            })
                                        }}
                                    >
                                        <ListItemIcon
                                            style={{
                                                minWidth: '45px'
                                            }}
                                        >
                                            <ExitToApp
                                                style={{
                                                    color: 'black'
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            style={{
                                                color: 'black'
                                            }}
                                            primary="Logout"
                                        />
                                    </ListItemButton>

                                    {(localStorage.getItem("app_ver_for_app")) && (
                                        <ListItemButton
                                            // sx={{
                                            //     position: 'absolute',
                                            //     bottom: '2rem',
                                            //     width: '100%'
                                            // }}
                                            fullWidth

                                        >


                                            <ListItemText
                                                style={{
                                                    color: 'black'
                                                }}
                                                primary={`App Ver: ${(localStorage.getItem("app_ver_for_app"))}`}
                                            />
                                        </ListItemButton>

                                    )}
                                </>
                            )}
                            {((user?.data?.biz_users_role == 0) || (user?.data?.biz_users_role == 1)) && (
                                <ListItemButton
                                    // sx={{
                                    //     position: 'absolute',
                                    //     bottom: isMobileScreen ? '5rem' : '1rem',
                                    //     width: '100%'
                                    // }}
                                    className='!tw-mb-8'
                                    fullWidth
                                    onClick={handleDelete}
                                >
                                    <ListItemIcon
                                        style={{
                                            minWidth: '45px'
                                        }}
                                    >
                                        <DeleteForever
                                            style={{
                                                fontSize: 18,
                                                color: 'black'
                                            }}
                                        />
                                    </ListItemIcon>

                                    <ListItemText
                                        style={{
                                            color: 'red',
                                            fontSize: 12,
                                        }}
                                        disableTypography
                                        primary="Delete Account"
                                    />
                                </ListItemButton>
                            )}

                            <Divider />
                        </div>
                    </div>
                </Drawer>
            )
            }

            <MarketingProvider
                value={{
                    isActionsDisabled: user?.data?.is_claim_business === 1,
                    isOneConnected:
                        !(names.length === 0) || !(pages.length === 0),
                    pages,
                    names,
                    // isEmailConnected,
                    externalWebsite: IsCustomDomain ? subDomain : '',
                    subDomain,
                    fetchFbList,
                    fetchSocialList,
                    handleFBDisconnect,
                    handleTwitterDisconnect,
                    fetchNewSocialData,
                    fetchAll,
                    isOrderPageReset,
                    setIsOrderPageReset,
                    isTemplateSelected,
                    templateId
                }}
            >
                <Box component="main" sx={{ overflowX: 'auto', overflowY: 'hidden', width: '100%', minHeight: '100vh' }}>
                    <DrawerHeader className='tw-h-[50px]' />

                    {user?.data?.is_claim_business === 1 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: '#ef2d2c',
                                padding: '10px 20px'
                            }}
                        >
                            <div
                                style={{
                                    color: 'black'
                                }}
                            >
                                Click On Get Started To Manage Your Website
                            </div>

                            <Button
                                variant="contained"
                                onClick={handleVenueConversion}
                            >
                                Get Started
                            </Button>
                        </div>
                    )}

                    <Suspense
                        fallback={<Loader />}
                    >
                        <div
                            style={{
                                padding: (!location.pathname.includes('custom-page') && !location.pathname.includes('website-builder') && !location.pathname.includes('products') && !location.pathname.includes('menu') && !location.pathname.includes('store')) ? '20px 5px 85px 5px' : 0,
                                backgroundColor: (location.pathname == routePaths.marketingDashboard) ? '#F5F7FB' : 'white'
                            }}
                        >
                            {children}
                        </div>
                    </Suspense>
                </Box>
            </MarketingProvider>
        </Box >
    )
}

export default Layout