const initialState = {
    isLoading: false,
    isLoggedIn: false,
    data: {},
    loginErrors: "",
    userRedirect: "/",
    signupSuccess: {},
    signupError: ""
};
function user(state = initialState, action) {
    switch (action.type) {
        case "LOGIN_LOADING":
            return { ...initialState, isLoading: true };
        case "LOGIN_SUCCESS":
            return { ...initialState, isLoggedIn: true, data: action.payload };
        case "LOGIN_ERROR":
            return { loginErrors: action.payload };
        case "SET_SIGNUP_DETAILS_LOADING":
            return { ...initialState, isLoading: true };
        case "SET_SIGNUP_DETAILS_LOADED":
            return { ...initialState, isLoggedIn: true, signupError: action.payload };
        case "SET_SIGNUP_DETAILS_ERROR":
            return { signupError: action.payload };
        case "SET_SAVED_PAGE": 
            let index = state.data.profile_status.steps.findIndex(i => i.page === action.payload.page)
            let newSteps = state.data.profile_status.steps.map((val,i) => {
                if(val.page === action.payload.page){
                    return {
                        ...val,
                        saved: action.payload.saved
                    }
                }else{
                    return val;
                }
            });
            return {...state, data: {...state.data, profile_status: {profile_status: state.data.profile_status.steps[index].saved ? state.data.profile_status.profile_status : state.data.profile_status.profile_status + (action.payload.page === "Website" ? 25 : 15), steps: newSteps}}}
        case "NO_LOGGED_USER":
            return initialState;
        case "LOGOUT":
            return initialState;
        default:
            return state;
    }
}
export default user;  