import React from 'react'
import { styled } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Button, Tooltip, tooltipClasses } from '@mui/material';
import { getImage3 } from '../../../../../utils/helpers'


const columns = [
    {
        id: 'image',
        label: 'Image',
        format: (value) => (
            <img
                src={getImage3(value)}
                height={20}
                width={20}
            />
        ),
    },
    {
        id: 'title',
        label: 'Attribute Name',
        width: '75%'
    },
];


function DietaryAttributesTable({ products, handleEdit2, handleDelete, isActionDisabled }) {
    return (
        <TableContainer component={Paper} sx={{ boxShadow: 'none', borderBottom: '1px solid #d3d3d3', borderRadius: '0' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ width: column.width }}
                                className='!tw-p-1'
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 5,
                                        alignItems: 'center'
                                    }}
                                >
                                    <div>
                                        {column.label}
                                    </div>

                                    {/* {((sort.sort_by === column.sortid) && (sort.sort_by_order === 'DESC')) ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )} */}
                                </div>
                            </TableCell>
                        ))}
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold !tw-w-20' />
                        <TableCell className='!tw-p-1 tw-text-black tw-font-semibold !tw-w-20' />
                    </TableRow>
                </TableHead>
                {Boolean(products?.length) && (
                    <TableBody
                        style={{
                            pointerEvents: isActionDisabled ? 'none' : 'all'
                        }}
                    >
                        {products.map((row, index) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align} className='!tw-p-1.5 !tw-border-b-0'>
                                            <div
                                                className={(column?.id == 'title') && ' tw-cursor-pointer tw-text-[#0071C3] hover:tw-underline '}
                                                onClick={() => {
                                                    if ((column?.id == 'title')) {
                                                        handleEdit2(row.id)
                                                    }
                                                }}
                                            >
                                                {column.format ? column.format(value) : value}
                                            </div>
                                        </TableCell>
                                    );
                                })}

                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            handleEdit2(row.id)
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>

                                <TableCell className='!tw-p-1.5 !tw-border-b-0'>
                                    <Button
                                        style={{
                                            color: 'red'
                                        }}
                                        variant="text"
                                        onClick={() => {
                                            handleDelete(row.id, row.title)
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    )
}

export default DietaryAttributesTable