import React from 'react'
import { useSelector } from 'react-redux';
import MyStoreSettings from '../pages/MyStoreSettings/MyStoreSettings'

const MyStoreSettingsRoute = () => {
    const user = useSelector((state) => state.user);

    return (
        <MyStoreSettings isActionsDisabled={user?.data?.is_claim_business === 1} />
    )
}

export default MyStoreSettingsRoute