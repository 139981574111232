import React from 'react'
import { Bar } from 'react-chartjs-2';

const options = {
    responsive: true,
    scales: {
        max: 100
    },
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Email open rate vs Unique open rate by Campaign',
        },
    },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Total Open Rate',
//             data: labels.map(() => Math.random(100)),
//             backgroundColor: '#49ABE0',
//             margin: 0,
//             padding: 0,
//             categoryPercentage: 0.5,
//             barPercentage: 1
//         },
//         {
//             label: 'Total Unique Open Rate',
//             data: labels.map(() => Math.random(100)),
//             backgroundColor: '#171717',
//             margin: 0,
//             padding: 0,
//             categoryPercentage: 0.5,
//             barPercentage: 1
//         },
//     ],
// };


const BarGraph = (props) => {

    return (
        <div>
            <Bar options={options} data={props.data} />
        </div>
    )
}

export default BarGraph