import React from 'react'
import styles from './MarketingInfoChip.module.scss'
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import LightTooltip from '../LightTooltip/LightTooltip';
import MovementIndicatorChip from '../MovementIndicatorChip/MovementIndicatorChip';
import clsx from 'clsx';

const MarketingInfoChip = (props) => {
    return (
        <div
            className={clsx(styles.container, props.containerClassName)}
        >
            {!props.hideInfo && (
                <LightTooltip
                    title="Info"
                >
                    <HelpOutlinedIcon
                        style={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            color: '#DFDFDF'
                        }}
                    />
                </LightTooltip>
            )}

            <div
                className={clsx(styles.info_section, props.infoClassName)}
            >
                {props.label && (
                    <div
                        className={styles.label}
                    >
                        {props.label}
                    </div>
                )}

                <div
                    className={clsx(styles.value, props.valueClassName)}
                >
                    {props.value || 0}
                </div>

                {Boolean(props.moveValue) && (
                    <MovementIndicatorChip
                        isUp={props.isUp}
                        value={props.moveValue}
                    />
                )}

            </div>

            {props.description && (
                <div
                    className={clsx(styles.description, props.descriptionClassName)}
                >
                    {props.description}
                </div>
            )}

        </div>
    )
}

export default MarketingInfoChip