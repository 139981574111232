import {
    POST_CREATE_CUSTOM_PAGE_REQUEST,
    POST_CREATE_CUSTOM_PAGE_SUCCESS,
    POST_CREATE_CUSTOM_PAGE_FAILURE,
    GET_CUSTOM_PAGE_REQUEST,
    GET_CUSTOM_PAGE_SUCCESS,
    GET_CUSTOM_PAGE_FAILURE,
    GET_CUSTOM_PAGE_DATA_REQUEST,
    GET_CUSTOM_PAGE_DATA_SUCCESS,
    GET_CUSTOM_PAGE_DATA_FAILURE,
    DELETE_CUSTOM_PAGE_REQUEST,
    DELETE_CUSTOM_PAGE_SUCCESS,
    DELETE_CUSTOM_PAGE_FAILURE
} from '../types/customPageTypes'

const initialGetState = {
    isLoading: false,
    data: [],
    baseLink: ""
}

const initialGetCustomPageDataState = {
    isLoading: false,
    data: {},
}

export const getCustomPageReducer = (state = initialGetState, action) => {
    switch (action.type) {
        case GET_CUSTOM_PAGE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_CUSTOM_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                baseLink: action.baseLink
            }
        case GET_CUSTOM_PAGE_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}

export const getCustomPageDataReducer = (state = initialGetCustomPageDataState, action) => {
    switch (action.type) {
        case GET_CUSTOM_PAGE_DATA_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_CUSTOM_PAGE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        case GET_CUSTOM_PAGE_DATA_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}