import { useNavigate, useParams } from 'react-router'

import clsx from 'clsx'
import React, { forwardRef, useContext } from 'react'
import ReactFacebookLogin from 'react-facebook-login'
import { toast } from 'react-toastify'
import routePaths from '../Util/route-paths'
import axios from '../Util/axiosConfig'
import styles from './FacebookButton.module.scss'
import MarketingContext from '../Util/marketingContext'

const FacebookButton = forwardRef((props, ref) => {

    const navigate = useNavigate()

    const { fetchFbList, fetchAll } = useContext(MarketingContext)

    return (
        <ReactFacebookLogin
            ref={ref}
            cssClass={clsx(styles.btn, props.facebookBtnClass)}
            appId={"446667517180549"}
            // appId={"924365652136275"}
            version="14.0"
            fields="name,email,picture"
            scope="pages_show_list,pages_read_engagement,pages_manage_posts,instagram_basic,instagram_content_publish,instagram_manage_insights,read_insights"
            textButton={props.textButton || "Connect"}
            callback={(res) => {
                let data = {
                    token: res.accessToken,
                    user_id: res.userID,
                    // client_id: '924365652136275',
                    // client_secret: 'da4d970f07203fb7f766328bac233e64',
                    client_id: '446667517180549',
                    client_secret: 'fd6734b5ade5f9bf729e3728510938a2'
                }
                if (res.accessToken) {
                    axios.post('/marketing/social-account/connect-fb-insta', data)
                        .then(() => {
                            toast.success('Facebook Connected')
                            navigate(routePaths.marketingDashboard)
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.message);
                        })
                        .finally(() => {
                            fetchAll()
                        })
                }
            }}
            icon={props.icon}
        />
    )
})

export default FacebookButton