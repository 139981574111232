import React, { useRef, useState } from 'react'
import UploadImage from '../../Library/UploadImage/UploadImage'
import styles from './LiquorLicense.module.scss'
import { IconButton } from '@mui/material'
import { DeleteForever, Edit } from '@mui/icons-material'
import GenericTextFieldWithLabel from '../../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import Const from '../../helpers/const'

const LiquorLicense = (props) => {

  const imageRef = useRef()

  return (
    <div
      className={styles.container}
    >
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div
            className={styles.title}
          >
            Business License
          </div>
          {(props.licenseFile || props.license?.licence_file) && (
            <div
              className={styles.image_edit_delete_container}
            >
              <IconButton
                onClick={() => {
                  imageRef.current?.click()
                }}
              >
                <Edit style={{ fontSize: 18, color: 'black' }} />
              </IconButton>

              <IconButton
                onClick={() => {
                  props.removeLicenceImage()
                  props.setLicenseFile()
                }}
              >
                <DeleteForever style={{ fontSize: 18, color: 'black' }} />
              </IconButton>
            </div>
          )}
        </div>

        <div
          className={styles.upload_image_container}
        >
          <UploadImage
            uploadText="Upload License"
            isBig={true}
            ref={imageRef}
            image={props.licenseFile}
            imageLink={props.license.licence_file}
            onChange={(event) => {
              props.setLicenseFile(event.target.files[0])
              event.target.value = ''
            }}
            onImageClose={(event) => {
              event.preventDefault()
              props.setLicenseFile()
            }}
          />
        </div>

        <GenericTextFieldWithLabel
          label={`License Number`}
          value={props.licenseNumber}
          onChange={(event) => {
            props.setLicenseNumber(event.target.value)
          }}
          fullWidth={true}
        />
      </div>
    </div>
  )
}

export default LiquorLicense