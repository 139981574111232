import React, { useEffect, useRef, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import WebsitePageSettings from '../../../../ManageSite/WebsitePageSettings/WebsitePageSettings';


import axios, { nodeAxios } from '../../Util/axiosConfig';
import styles from './CreateBlogs.module.scss';
import DraftEditor from '../../../Library/DraftEditor/DraftEditor';
import { EditorState } from 'draft-js';
import CreateBlogModal from './CreateBlogModal/CreateBlogModal';
import GenericAutoSizeTextField from '../../../Library/GenericAutoSizeTextField/GenericAutoSizeTextField';
import { htmlToDraftConverter } from '../../Util/helpers';

import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { debounce } from 'lodash';
import Loader from '../../../Library/Loader/Loader';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { Add, Settings } from '@mui/icons-material';
import Input from '../../../Library/Input/Input';
import RichTextEditor2 from '../../../Library/RichTextEditor2/RichTextEditor2';
import RichTextEditor3 from '../../../Library/RichTextEditor3/RichTextEditor3';



const CreateBlogs = (props) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const editorRef = useRef()

  const blog_id = searchParams.get('blog');
  const web_page_id = searchParams.get('page_id');
  const uri = searchParams.get('uri');

  const [blog, setBlog] = useState(null);

  const [isBlogLoading, setIsBlogLoading] = useState(false);
  const [blogTitle, setBlogTitle] = useState('');
  const [editorState, setEditorState] = useState('');
  const [convertedContent, setConvertedContent] = useState(null);
  const [showPageSettings, setShowPageSettings] = useState(false)

  const [isBlogSubmitDialogOpen, setIsBlogSubmitDialogOpen] = useState(false);



  const fetchBlog = (params) => {
    setBlog(null);
    setIsBlogLoading(true);
    nodeAxios
      .get(`/web-builder/public/blog/v2?id=${blog_id}`, { // /web-builder/blog/get/${blog_id}
        params : { ...params, identifier: localStorage.getItem("venue_id") },
      })
      .then((response) => {
        // console.log(response)
        setBlog(response.data.data.blogs?.[0]);
        setBlogTitle(response.data.data.blogs?.[0]?.title);
        if (response.data.data.blogs?.[0]?.content) {
          htmlToDraftConverter(setEditorState, response.data.data.blogs?.[0]?.content, true)
        } else {
          setEditorState('');
        }
      })
      .catch((e) => {
        console.log(e);
        // setBlog(nulll);
        toast.error(e?.response?.data?.message);
      }).finally(() => {
        setIsBlogLoading(false);
      });
  };

  useEffect(() => {

    return () => {
      document.querySelector('rte-floatpanel')?.remove()
    }
  }, []);
  useEffect(() => {
    if (blog_id) {
      fetchBlog();
    }
    // dispatch(getFamilyMembersAction('Family'));
  }, [blog_id]);

  const handleOpenPublishDialog = () => {
    editorRef.current?.handleSave()
    if (blogTitle === '') {
      toast.error('Blog should contain Title');
      return;
    }

    setIsBlogSubmitDialogOpen(true);
  };

  return (
    <div className={clsx(styles.children_list)}>
      <div className={styles.children_list_header}>
        <div className={styles.children_title}>Draft</div>

        <div className={styles.heading_actions}>
          {Boolean(blog_id) && (
            <IconButton
              onClick={() => {
                setShowPageSettings(prevState => !prevState)
              }}
            >
              <Settings />
            </IconButton>
          )}
          <Button
            classes={{ root: styles.draft_btn }}
            onClick={handleOpenPublishDialog}
          >
            Save as draft
          </Button>
          <Button
            classes={{ root: styles.record_btn }}
            onClick={handleOpenPublishDialog}
          >
            Continue
          </Button>
        </div>
      </div>

      {/* {showHelpPage ? (
        helpPage
      ) : ( */}
      <>
        <div className={styles.searh_section_container}></div>

        {(blog_id && isBlogLoading) ? <Loader /> : showPageSettings ? (
          <WebsitePageSettings blogId={web_page_id} uri={uri} />
        ) : (
          <div className={styles.blog_content_wrapper}>
            <div className={styles.blog_content_container}>
              <div className={styles.title_container}>
                <label className={styles.input_label} htmlFor='title'>
                  Title
                </label>
                <Input
                  value={blogTitle}
                  onChange={(e) => setBlogTitle(e.target.value)}
                />
              </div>
              <div className={styles.title_container}>
                <label className={styles.input_label} htmlFor='title'>
                  Body
                </label>
                <div className={styles.blog_body_container}>
                  <RichTextEditor3
                    isModal={false}
                    editorRef={editorRef}
                    open={true}
                    onClose={() => {

                    }}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    setConvertedContent={setConvertedContent}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <Dialog
          open={isBlogSubmitDialogOpen}
          classes={{ paper: styles.create_blog_modal_paper }}
          onClose={() => setIsBlogSubmitDialogOpen(false)}
        >
          <DialogContent>
            <CreateBlogModal
              closeDialog={() => setIsBlogSubmitDialogOpen(false)}
              page={props.page}
              setIsAddUserDialogOpen={setIsBlogSubmitDialogOpen}
              edit={blog_id ? true : false}
              blog={
                blog_id
                  ? {
                    id: blog?.id,
                    web_page_id: blog?.web_page_id,
                    blogTitle,
                    imageFile: blog?.image,
                    status: blog?.status,
                    categories: blog?.category_ids,
                    contentHtml: convertedContent,
                  }
                  : {
                    blogTitle,
                    contentHtml: convertedContent,
                  }
              }
              {...props}
            />
          </DialogContent>
        </Dialog>
      </>
      {/* )} */}
    </div>
  );
};

export default CreateBlogs;
