import React, { useEffect, useState } from 'react'
import GenericTextFieldWithLabel from '../Library/TextFieldWithLabel/GenericTextFieldWithLabel'
import { Button, IconButton, InputAdornment } from '@mui/material'
import { East, HelpOutlineOutlined, Visibility, VisibilityOff, West } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { login, setUserRedirect } from '../../redux/actions/userActions'
import routePaths from '../../config/route-paths'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from "yup";
import axios, { nodeAxios } from '../../utils/axiosConfig'
import PageLoader from '../Library/PageLoader/PageLoader'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email!")
    .required("Email is required!"),
  password: Yup.string()
    .min(8, "Password too short!")
    .required("Password is required!"),
});

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email!")
    .required("Email is required!"),
});


const Login = ({ sm }) => {

  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [deviceToken, setDeviceToken] = useState('')
  const [deviceType, setDeviceType] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [isForgoetPassword, setIsForgoetPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: isForgoetPassword ? forgotPasswordSchema : loginSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isForgoetPassword) {
        setIsLoading(true)
        nodeAxios.get(`/auth/forgot-password`, {
          params: {
            email: values.email
          }
        })
          .then((response) => {
            toast.success('Please check your email to reset password.')
            setIsForgoetPassword(false);
          })
          .catch((error) => {
            toast.error(error.response?.data?.message)
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        setIsLoading(true)
        dispatch(login({ ...values, deviceid: deviceToken, devicetype: deviceType }))
          .then((response) => {
            if (response.type === "success") {
              dispatch(setUserRedirect("/"));

              if (Boolean(response.isClaimBusiness)) {
                history(routePaths.manageListing)
                return
              }

              if (location.state?.isMultiStore) {
                history(`${routePaths.myStoreSetting}?token=${location.state.token}`)
                return
              }

              console.log('venueType', response.venueType)
              switch (response.venueType) {
                case 'Ice Cream Truck':
                  console.log('venueType1')
                  history('/MyStoreSettings')
                  break;
                default:
                  console.log('venueType2')
                  if (response.roleID === 1) {
                    history(routePaths.venueOrderListing);
                    return
                  } else if (response.roleID === 0) {
                    history("/comingSoon");
                    return
                  } else {
                    if (response.roleID === 2) {
                      history("/VenueOrderListing");
                      return
                    }
                    if (response.roleID === 3) {
                      history("/VenueOrderListing");
                      return
                    }
                  }
                  break;
              }

            } else {
              toast.error(response.payload.data.message)
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      }

    }
  })

  useEffect(() => {

    window.addEventListener('message', (event) => {
      // console.log('messageeeeeeeeeeeeeeee', event.data, event)

      //toast.error(`Token Recived one hit ${event.data}`);
      //   alert(`Token Recived ${JSON.stringify(event.data.deviceToken)}`);
      // const checkDeviceToken = JSON.stringify(event.data);
      if (event.data?.deviceToken !== undefined) {

        // toast.error(`Token Recived two ${event.data?.deviceToken}`);
        setDeviceToken(event.data?.deviceToken);
        localStorage.setItem("device_token_app", event.data?.deviceToken);
      }
      if (event.data?.deviceType !== undefined) {

        // toast.error(`Token Recived two ${event.data?.deviceToken}`);
        setDeviceType(event.data?.deviceType);
      }

    });

    window?.ReactNativeWebView?.postMessage(JSON.stringify({ getDeviceToken: 'getDeviceToken' }));
  }, []);

  return (
    <div
      className="tw-flex tw-justify-center tw-h-full"
    >
      {isLoading && (
        <PageLoader />
      )}
      <div
        className="tw-flex tw-items-center tw-justify-center tw-h-full tw-flex-col tw-max-w-96 sm:tw-max-w-lg tw-gap-3 tw-p-4"
      >
        {isForgoetPassword ? (
          <div>
            <div
              className="tw-font-semibold tw-text-2xl sm:tw-relative"
            >
              Password Recovery
            </div>

            <div
              className="tw-text-sm"
            >
              Enter the email address associated with Esplanda account, we’ll send you an email to reset the password.
            </div>
          </div>
        ) : (
          <div
            className="tw-font-semibold tw-text-2xl tw-text-center sm:tw-relative"
          >
            {/* <div
            className="tw-absolute tw-top-2 tw-left-2 sm:tw-top-0 sm:-tw-left-14"
          >
            {sm ? (
              <Button
                style={{
                  color: 'black'
                }}
                variant="text"
                startIcon={<West fontSize="medium" />}
                onClick={onPrev}
              >
                Back
              </Button>
            ) : (
              <IconButton
                onClick={onPrev}
              >
                <West style={{ color: 'black' }} fontSize="large" />
              </IconButton>
            )}
          </div> */}
            Login
          </div>
        )}


        <GenericTextFieldWithLabel
          isSmallLabel={true}
          containerClassname="tw-w-full"
          variant="standard"
          label="Email"
          placeholder="name@business.com"
          name="email"
          autoComplete="off"
          inputProps={{
            autocomplete: "off"
          }}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth={true}
          required={true}
        />

        {!isForgoetPassword && (
          <GenericTextFieldWithLabel
            isSmallLabel={true}
            containerClassname="tw-w-full"
            variant="standard"
            label="Password"
            type={showPass ? "text" : "password"}
            placeholder="Password"
            autoComplete="off"
            inputProps={{
              autocomplete: "off"
            }}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            fullWidth={true}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => setShowPass((prevState) => !prevState)}
                    edge='end'
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required={true}
          />
        )}

        {!isForgoetPassword && (
          <div
            className="tw-flex tw-justify-end tw-w-full"
          >
            <span
              className="tw-ml-1 tw-text-[#0073DD] tw-text-sm tw-cursor-pointer tw-my-1"
              onClick={() => {
                setIsForgoetPassword(true)
              }}
            >
              Forgot Password <East fontSize="small" />
            </span>
          </div>
        )}

        <Button
          style={{
            color: 'white',
            backgroundColor: 'black',
            borderRadius: 30,
            height: 40,
            fontWeight: 400
          }}
          fullWidth={true}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Login
        </Button>

        <div
          className="tw-flex tw-flex-col tw-gap-5 tw-m-5"
        >
          {isForgoetPassword ? (
            <div
              className="tw-text-center tw-text-light-gray tw-text-sm"
            >
              Already have a Esplanda account?
              <span
                className="tw-ml-1 tw-text-[#0073DD] tw-cursor-pointer"
                onClick={() => {
                  setIsForgoetPassword(false)
                }}
              >
                Login <East fontSize="small" />
              </span>
            </div>
          ) : (
            <div
              className="tw-text-center tw-text-light-gray tw-text-sm"
            >
              Don’t have an Esplanda account?
              <span
                className="tw-ml-1 tw-text-[#0073DD] tw-cursor-pointer"
                onClick={() => {
                  history('/signup')
                }}
              >
                Create Account <East fontSize="small" />
              </span>
            </div>
          )}

          <div
            className="tw-text-center tw-text-light-gray tw-text-xs"
          >
            By creating an account, you agree to our

            <a
              href="https://esplanda.com/terms"
              target="_blank"
            >
              <span
                className="tw-ml-1 tw-text-light-gray tw-cursor-pointer tw-underline"
              >
                Terms of Service
              </span>
            </a>
            {' '}
            and have read and understood the
            <a
              href="https://esplanda.com/privacy"
              target="_blank"
            >
              <span
                className="tw-ml-1 tw-text-light-gray tw-cursor-pointer tw-underline"
              >
                Privacy Policy
              </span>
            </a>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Login