import React, { useEffect, useState, useRef } from "react";
import { Typography } from "@mui/material";
import cart from "../../../assets/cart.png";
import man from "../../../assets/man.jpg";
import ourdesistoreman from "../../../assets/brand-story-desistore.jpg";
import ourdesistorecustomize from "../../../assets/ourdesi-store-customize.png";
import ourdesistorenewcustomers from "../../../assets/new-customers.jpg";
import customerreview1 from "../../../assets/customer-review-1.jpg";
import customerreview2 from "../../../assets/customer-review-2.jpg";
import payment from '../../../assets/payment.png';
import customers from '../../../assets/customers.png';
import customize from "../../../assets/customize.png";
import women from "../../../assets/women.jpg";
import Buttons from "./Parts/Buttons";
import Form from './Parts/Form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReviews } from '../../../redux/actions/reviewActions.js';
import './style.css';
import Const from "../../helpers/const";

const Signup = (props) => {
    const history = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch(data => data);
    const { result: reviews } = useSelector(state => state.reviews);
    const handleGetStarted = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handleOurFeatures = () => {
        history('/our-features');
    }
    useEffect(() => {
        dispatch(getReviews());
    }, []);
    return (
        <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
            <div
                className="signup-form-container"
                style={{
                    height: "65vh",
                    backgroundColor: "rgb(55,53,54)",
                    width: "100%",
                    marginBottom: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: 'relative'
                }}
            >
                <div className="signup-header" style={{ width: '44%', marginRight: '30%' }}>
                    <Typography
                        variant="h3"
                        style={{ color: "white", fontWeight: "700", fontSize: "4.7rem", }}
                    >
                        Sell Your Products in Right Ways with
                        <Typography
                            variant="h3"
                            component="span"
                            style={{
                                borderBottom: "6px solid #EFE70F",
                                color: "white",
                                fontWeight: "700",
                                display: 'inline-block',
                                margin: '0 9px',
                                fontSize: "4.7rem",
                            }}
                            className="signup-header-first-ols"
                        >
                            Esplanda
                        </Typography>
                    </Typography>
                    <Typography style={{ color: "white", marginTop: '20px', fontSize: "1.2rem" }}>
                        No Matter what stage you're in,
                        <Typography
                            component="span"
                            style={{
                                fontWeight: "bold",
                                color: "white",
                                display: "inline-block",
                                margin: "0 4px",
                            }}
                            className="signup-header-second-ols"
                        >
                            Esplanda
                        </Typography>
                        makes it easy to take your business online
                    </Typography>
                </div>
                <Form search={location.search} />
            </div>
            <div style={{ marginBottom: "40px" }}>
                <Typography
                    style={{
                        fontWeight: "bold",
                        marginBottom: "30px",
                        fontSize: "2.2rem",
                        textAlign: "center",
                        color: "black",
                    }}
                >
                    The Benefits of the Platform
                </Typography>
                <div
                    className="signup-benefits"
                    style={{
                        gridTemplateColumns: "33% 33% 33%",
                        display: "grid",
                        margin: "0 5vw",
                    }}
                >
                    <div style={{ textAlign: "center" }}>
                        <img src={cart} />
                        <Typography
                            style={{
                                fontWeight: "600",
                                fontSize: "1.25rem",
                                color: "black",
                                marginBottom: "5px",
                            }}
                        >
                            Start Selling Online for free
                        </Typography>
                        <Typography
                            style={{
                                fontSize: ".9rem",
                                padding: "0 75px",
                                color: "black",
                            }}
                        >
                            Easily sell online and ship orders to customers,
                            offer curbside pickup or local delivery
                        </Typography>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img src={payment} />
                        <Typography
                            style={{
                                fontWeight: "600",
                                fontSize: "1.25rem",
                                color: "black",
                                marginBottom: "5px",
                            }}
                        >
                            Realtime Customization of your website & app
                        </Typography>
                        <Typography
                            style={{
                                fontSize: ".9rem",
                                padding: "0 75px",
                                color: "black",
                            }}
                        >
                            Get your own Personalized website make changes form our admin portal which gets reflected on your website & app realtime
                        </Typography>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img src={customers} />
                        <Typography
                            style={{
                                fontWeight: "600",
                                fontSize: "1.25rem",
                                color: "black",
                                marginBottom: "5px",
                            }}
                        >
                            Realtime inventory sync with POS
                        </Typography>
                        <Typography
                            style={{
                                fontSize: ".9rem",
                                padding: "0 75px",
                                color: "black",
                            }}
                        >
                            seamless integration with your pos system reflected up to take inventory on your website & app
                        </Typography>
                    </div>
                </div>
            </div>
            <div
                className='signup-brandstory'
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "50px",
                    background: "#f8f8f8",
                }}
            >
                <img src={Const.isOurDesiStore ? ourdesistoreman : man} style={{ marginRight: "6%", width: '45%' }} />
                <div className='signup-brandstory-texts' style={{ width: "45%" }}>
                    <Typography
                        variant="h3"
                        style={{
                            color: "black",
                            fontWeight: "bold",
                            marginBottom: "20px",
                        }}
                    >
                        Your Brand's Story Starts with your Web Address
                    </Typography>
                    <Typography style={{ color: "black", fontSize: "1.2rem" }}>
                        Extend your Brick & Mortar store to the web. We act  as online extension to your existing store by seamlessly bring your inventory online.
                        You'll receive guided support and a toolset of products to tell
                        your brand story & track performance. You can easily upload new
                        innovative products and get quick result for your products.
                    </Typography>
                </div>
            </div>
            <div
                className="signup-customize"
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "2%",
                    marginBottom: '50px'
                }}
            >
                <div>
                    <Typography
                        variant="h3"
                        style={{
                            color: "black",
                            fontWeight: "bold",
                            marginBottom: "20px",
                            fontSize: "2.5rem"
                        }}
                    >
                        Customize Your Store
                    </Typography>
                    <Typography
                        style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginBottom: "11px",
                        }}
                    >
                        Shopping Experience right to your customers fingertips.
                    </Typography>
                    <div style={{ display: "flex" }}>
                        <Buttons.GetStarted action={handleGetStarted} small style={{ marginRight: "10px" }} />
                        <Buttons.OurFeatures action={handleOurFeatures} small />
                    </div>
                </div>
                <img src={Const.isOurDesiStore ? ourdesistorecustomize : customize}
                // style={{ marginLeft: "5%" }}
                />
            </div>
            <div
                className="signup-reach-new-customers"
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "50px",
                    background: "#f8f8f8",
                }}
            >
                <div className="signup-reach-new-customers-texts" style={{ width: "45%", marginLeft: '2%' }}>
                    <Typography
                        variant="h3"
                        style={{
                            color: "black",
                            fontWeight: "bold",
                            marginBottom: "20px",
                        }}
                    >
                        Reach new customers at a lower cost
                    </Typography>
                    <Typography style={{ color: "black", fontSize: "1.2rem" }}>
                        Get your store online for free no monthly Fees or Contracts.
                    </Typography>
                </div>
                <img src={Const.isOurDesiStore ? ourdesistorenewcustomers : women} style={{ marginLeft: "6%", width: '47%', height: '450px', objectFit: 'cover' }} />
            </div>
            <div>
                <Typography className="signup-reviews-header" variant="h4" style={{ textAlign: 'center', color: 'black', fontWeight: 'bold', marginBottom: '30px' }}>
                    See What Store Owner Say About Us
                </Typography>
                <div
                    className="signup-reviews-grid"
                    style={{ gridTemplateColumns: "50% 50%", display: "grid", margin: '0 11vw' }}
                >
                    <div className="signup-reviews-grid-first" style={{ marginRight: '20px' }}>
                        <div style={{ width: '100%', height: '240px' }}>
                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Const.isOurDesiStore ? customerreview1 : reviews[0]?.image_file} />
                        </div>
                        <Typography style={{ fontSize: "1.2rem", color: 'black', fontWeight: '600' }}>
                            {reviews[0]?.name}
                        </Typography>
                        <Typography style={{ color: "rgb(185,66,60)", fontWeight: 'bold' }}>
                            Store Name: {reviews[0]?.store_name}
                        </Typography>
                        <Typography style={{ color: 'black', fontSize: '.85rem' }}>{reviews[0]?.description}</Typography>
                    </div>
                    <div className="signup-reviews-grid-second" style={{ marginRight: '20px' }}>
                        <div style={{ width: '100%', height: '240px' }}>
                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Const.isOurDesiStore ? customerreview2 : reviews[1]?.image_file} />
                        </div>
                        <Typography style={{ fontSize: "1.2rem", color: 'black', fontWeight: '600' }}>
                            {reviews[1]?.name}
                        </Typography>
                        <Typography style={{ color: "rgb(185,66,60)", fontWeight: 'bold' }}>
                            Store Name: {reviews[1]?.store_name}
                        </Typography>
                        <Typography style={{ color: 'black', fontSize: '.85rem' }}>{reviews[1]?.description}</Typography>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '20px' }}>
                <Typography className="signup-ols-purpose" style={{ fontSize: "2.5rem", color: 'black', width: '60%', margin: 'auto', textAlign: 'center' }}>
                    <Typography style={{ fontWeight: "bold", fontSize: '2.5rem', color: 'black', display: 'inline-block', marginRight: '9px' }} component="span">
                        Esplanda
                    </Typography>
                    Platform is Built to Help You Make More Money
                </Typography>
                <div style={{ display: "flex", justifyContent: 'center', marginTop: '15px' }}>
                    <Buttons.GetStarted action={handleGetStarted} small style={{ marginRight: "10px" }} />
                    <Buttons.OurFeatures action={handleOurFeatures} small />
                </div>
            </div>
        </div>
    );
};

export default Signup;
