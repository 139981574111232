import React from 'react'

const FaqIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 1024 1024" class="icon" version="1.1">
            <path d="M320 853.333333h490.666667l128 128V533.333333c0-46.933333-38.4-85.333333-85.333334-85.333333H320c-46.933333 0-85.333333 38.4-85.333333 85.333333v234.666667c0 46.933333 38.4 85.333333 85.333333 85.333333z" fill="#558B2F" />
            <path d="M614.4 699.733333h-76.8l-14.933333 44.8h-46.933334l78.933334-213.333333h40.533333l78.933333 213.333333h-46.933333l-12.8-44.8z m-66.133333-34.133333h53.333333L576 584.533333l-27.733333 81.066667z" fill="#1B5E20" /><path d="M704 533.333333H213.333333l-128 128V170.666667c0-46.933333 38.4-85.333333 85.333334-85.333334h533.333333c46.933333 0 85.333333 38.4 85.333333 85.333334v277.333333c0 46.933333-38.4 85.333333-85.333333 85.333333z" fill="#8BC34A" /><path d="M541.866667 302.933333c0 21.333333-4.266667 38.4-10.666667 53.333334s-14.933333 27.733333-27.733333 36.266666l36.266666 27.733334-27.733333 25.6-46.933333-36.266667c-4.266667 0-10.666667 2.133333-17.066667 2.133333-12.8 0-25.6-2.133333-38.4-6.4-10.666667-4.266667-21.333333-12.8-29.866667-21.333333-8.533333-8.533333-14.933333-21.333333-19.2-34.133333-4.266667-12.8-6.4-27.733333-6.4-44.8v-8.533334c0-17.066667 2.133333-32 6.4-44.8 4.266667-12.8 10.666667-25.6 19.2-34.133333 8.533333-8.533333 17.066667-17.066667 29.866667-21.333333 10.666667-4.266667 23.466667-6.4 38.4-6.4 12.8 0 25.6 2.133333 38.4 6.4 10.666667 4.266667 21.333333 12.8 29.866667 21.333333 8.533333 8.533333 14.933333 21.333333 19.2 34.133333 4.266667 12.8 6.4 27.733333 6.4 44.8v6.4z m-46.933334-10.666666c0-23.466667-4.266667-40.533333-12.8-51.2-8.533333-12.8-19.2-17.066667-34.133333-17.066667-14.933333 0-27.733333 6.4-34.133333 17.066667-8.533333 12.8-12.8 29.866667-12.8 51.2v10.666666c0 10.666667 2.133333 21.333333 4.266666 29.866667 2.133333 8.533333 4.266667 17.066667 8.533334 21.333333 4.266667 6.4 8.533333 10.666667 14.933333 12.8 6.4 2.133333 12.8 4.266667 19.2 4.266667 14.933333 0 27.733333-6.4 34.133333-17.066667 8.533333-12.8 12.8-29.866667 12.8-53.333333v-8.533333z" fill="#FFFFFF" />
        </svg>
    )
}

export default FaqIcon