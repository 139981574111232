import React from "react";
import { Typography } from "@mui/material";
// import {InlineWidget,CalendlyEventListener} from 'react-calendly'
import { useDispatch,useSelector } from "react-redux";
import {
    postIntegration
} from "../../../../../servise/venueInfo";
import { Check } from "@mui/icons-material";

const Integration = (props) => {
    const dispatch = useDispatch(data => data);
    const steps = useSelector(state => state.user?.data?.profile_status?.steps) || [];
    let integration = steps.find(e => e.page === "Integration");
    const save = async () => {
        await postIntegration();
        let dispatchPayload = {
            page: "Integration",
            saved: true,
        };
        dispatch({
            type: "SET_SAVED_PAGE",
            payload: dispatchPayload,
        });
    }
    return (
        <div>
            <div style={{marginBottom: 35}}>
                <Typography
                    style={{
                        color: "black",
                        fontSize: "1.15rem",
                        fontWeight: "bold",
                        marginBottom: "10px",
                    }}
                >
                    Integration
                </Typography>
                <Typography style={{color: 'black', width: "70%"}}>You can't start selling until we integrate Your inventory. Please schedule time on calendar</Typography>
            </div>
            <div>
                <div style={{display: integration?.saved === true ? 'flex' : 'none', marginBottom: 40, alignItems: 'center',justifyContent: 'center'}}>
                    <Typography style={{color: 'black',fontSize: '1.4rem', color: 'green', marginRight: 5}}>You have already scheduled a call</Typography>
                    <Check style={{color: 'green'}}/>
                </div>
                <div>
                    {/* <CalendlyEventListener onEventScheduled={() => save()} />
                    <InlineWidget styles={{height: 530, maxWidth: 600,margin: 'auto'}} url="https://calendly.com/ourliquorstore/15min" /> */}
                </div>
            </div>
        </div>
    );
};


export default Integration;