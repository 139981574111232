import React, { useEffect, useState } from 'react'
import GenericLoadingButton from '../../../Library/GenericLoadingButton/GenericLoadingButton'
import styles from './BusinessInfo.module.scss'
import LiquorLicense from './LiquorLicense/LiquorLicense'
import axios from '../../../../utils/axiosConfig'
import { useOutletContext } from 'react-router-dom'
import MicroWebsite from './MicroWebsite/MicroWebsite'
import SocialMedia from './SocialMedia/SocialMedia'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import AppLinks from './AppLinks/AppLinks'
import { Button, Dialog, DialogTitle, Drawer, IconButton } from '@mui/material'
import HtmlTooltip from '../../../Library/V2/HtmlToolTip/HtmlToolTip'
import { Cancel, InfoOutlined } from '@mui/icons-material'
import GenericEditDrawer from '../../../Library/V2/GenericEditDrawer/GenericEditDrawer'
import BusinessLogo from './BusinessLogo/BusinessLogo'

import FacebookIcon from '../../../../assets/facebook.png'
import InstagramIcon from '../../../../assets/instagram.png'
import TwitterIcon from '../../../../assets/twitter.png'
import SnapchatIcon from '../../../../assets/images/snapchat.jpg'
import TikTokIcon from '../../../../assets/images/tiktok.png'
import LinkedInIcon from '../../../../assets/images/linkedin.png'
import YoutubeIcon from '../../../../assets/images/youtube.png'
import PinterestIcon from '../../../../assets/images/pinterest.png'
import AppStore from '../../../../assets/app_store.png'
import PlayStore from '../../../../assets/play_store.png'
import { AppStoreIcon, AppStoreInitialsIcon, PlayStoreIcon, PlayStoreInitialsIcon } from '../../../../assets/svgs/svgs'
import GenericTextFieldWithLabel from '../../../Library/V2/TextFieldWithLabel/GenericTextFieldWithLabel'
import Loader from '../../../Library/Loader'
import { useSelector } from 'react-redux'

const BusinessInfo = (props) => {

  const { license, removeLicenceImage, removeImage, venueDetails, fetchStoreInfo, fetchLiquorLicense, isActionsDisabled } = useOutletContext()

  const user = useSelector((store) => store.user);
  const [open, setOpen] = useState(false);
  const [openAppDialog, setOpenAppDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [imageFile, setImageFile] = useState()
  const [noLogo, setNoLogo] = useState(!Boolean(venueDetails.image))
  const [noLicense, setNoLicense] = useState(!Boolean(license?.licence_file))
  const [licenseFile, setLicenseFile] = useState()
  const [licenseNumber, setLicenseNumber] = useState(license?.licence_no)
  const [externalWebsite, setExternalWebsite] = useState('')

  const [openBusinessLogo, setOpenBusinessLogo] = useState(false);
  const [openBusinessLicense, setOpenBusinessLicense] = useState(false);

  useEffect(() => {
    setLicenseNumber(license?.licence_no)
  }, [license])

  const formik = useFormik({
    initialValues: {
      ...venueDetails
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsLoading(true)
      axios.post('/venue/setting', values)
        .then(() => {
          toast.success('Saved Successfully')
          // setIsEditMode(false)
        })
        .finally(() => {
          setIsLoading(false);
          setOpen(false);
          setOpenAppDialog(false);
          fetchStoreInfo()
        })

      if (licenseNumber) {
        let formData = new FormData();
        if (licenseFile) {
          formData.append('file', licenseFile);
          // formData.append('no_license', noLicense);
        }
        if (licenseNumber) {
          formData.append('licence_no', licenseNumber);
        }
        if(noLicense) {
          formData.append('no_license', true);
        }

        axios.post('/venue/steps/licence-info', formData)
          .then(() => {
            fetchLiquorLicense()
          })
      } else if (!licenseNumber && licenseFile) {
        toast.error('Please enter license number')
      }

      let fData = new FormData();
        if (imageFile) {
            fData.append('image', imageFile);
            fData.append('no_logo', noLogo);
        }
        if(noLogo) {
          fData.append('no_logo', true);
        }
        axios.post('/venue/steps/branding', fData)
          .then(() => {
            fetchStoreInfo()
          })

      if (externalWebsite) {
        axios.post('/venue/external-url', {
          external_url: externalWebsite
        })
          .then(() => {

          })
      }
    }
  })

  const getSocialIcon = (social) => {
    switch (social) {
      case 'facebook':
        return FacebookIcon
      case 'instagram':
        return InstagramIcon
      case 'twitter':
        return TwitterIcon
      case 'snapchat':
        return SnapchatIcon
      case 'tiktok':
        return TikTokIcon
      case 'linkedin':
        return LinkedInIcon
      case 'youtube':
        return YoutubeIcon
      case 'pinterest':
        return PinterestIcon
      default:
        return ''
    }
  }

  const getSocialLink = (social, value) => {
    switch (social) {
      case 'facebook':
        return `https://www.facebook.com/${value}`
      case 'instagram':
        return `https://www.instagram.com/${value}`
      case 'twitter':
        return `https://twitter.com/${value}`
      case 'snapchat':
        return `https://www.snapchat.com/add/${value}`
      case 'tiktok':
        return `https://www.tiktok.com/${value}`
      case 'linkedin':
        return `https://www.linkedin.com/in/${value}`
      case 'youtube':
        return `https://www.youtube.com/${value}`
      case 'pinterest':
        return `https://www.pinterest.com/${value}`
      default:
        return ''
    }
  }




  return (
    <div className='tw-py-1 sm:tw-py-4 tw-flex tw-flex-col'>
      <div className='tw-font-semibold tw-text-2xl tw-w-full'>
          <div className='tw-flex tw-justify-between tw-items-center tw-w-full'>
            Business Info
          <Button
              className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs' 
              onClick={() => {setOpen(true);}}
          >Edit Business Info</Button>
          </div>
          {/* <span className='tw-text-dark-gray tw-text-sm tw-font-normal'>Choose times during which your store is open to customers or clients.</span> */}
      </div>

      <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4 tw-text-sm'>
        <div>
            <div className='tw-font-semibold tw-flex tw-items-center tw-gap-1 tw-text-black'>
              Business logo 
                <HtmlTooltip title={'Your business logo will be visible on all your marketplace including emails and website.'} >
                    <InfoOutlined sx={{ fontSize: '16px' }} />
                </HtmlTooltip>
                
            </div>
            {venueDetails.image ? (
                <span className='tw-text-blue tw-font-medium tw-underline tw-cursor-pointer' onClick={() => setOpenBusinessLogo(true)}>View logo</span>
            ) : (
              <span className='tw-text-dark-gray tw-font-medium tw-italic'>Not uploaded</span>
            )}
        </div>
        <div>
            <div className='tw-font-semibold tw-flex tw-items-center tw-gap-1 tw-text-black'>
              Business license
            </div>
            {license.licence_file ? (
                <span className='tw-text-blue tw-font-medium tw-underline tw-cursor-pointer' onClick={() => setOpenBusinessLicense(true)}>View license</span>
            ) : (
              <span className='tw-text-dark-gray tw-font-medium tw-italic'>Not uploaded</span>
            )}
        </div>
        <div>
            <div className='tw-font-semibold tw-flex tw-items-center tw-gap-1 tw-text-black'>
              License number
            </div>
            {licenseNumber ? (
                <span className='tw-text-black tw-font-medium '>{licenseNumber}</span>
            ) : (
              <span className='tw-text-dark-gray tw-font-medium tw-italic'>Not uploaded</span>
            )}
        </div>
        {/* {(venueDetails?.android_app_link || venueDetails?.ios_app_link) ? ( */}
          <div>
            <div className='tw-font-semibold tw-flex tw-items-center tw-gap-1 tw-text-black'>
            Mobile app links
            <HtmlTooltip title={'Once your app is ready, your app links will be visible on your website'} >
                <InfoOutlined sx={{ fontSize: '16px' }} />
            </HtmlTooltip>
            {user?.data?.biz_users_role !== 0 && localStorage.getItem("isShiftedFrom") && (localStorage.getItem("isShiftedFrom") !== "false") && (
              <Button
                  className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs' 
                  onClick={() => {setOpenAppDialog(true);}}
              >Edit App Links</Button>
            )}
            </div>
              {(venueDetails?.android_app_link || venueDetails?.ios_app_link) ? (
                <div className='tw-flex tw-flex-col tw-gap-2'>
                  {venueDetails?.android_app_link && (
                    <a 
                      href={venueDetails?.android_app_link?.includes('https://') ? venueDetails?.android_app_link : `https://${venueDetails?.android_app_link}`} 
                      target='_blank' 
                      rel='refferer noopener' 
                      className='tw-flex tw-gap-2 tw-items-center tw-text-blue hover:tw-text-blue tw-font-medium tw-underline tw-cursor-pointer'
                    >
                      <span className='tw-w-7 tw-h-7 tw-rounded-md border tw-border-gray tw-flex tw-items-center tw-justify-center'>
                        {/* <img src={PlayStore} className='tw-max-w-full tw-h-full tw-rounded-md' /> */}
                        <PlayStoreIcon />
                      </span>
                      <div className='tw-w-11/12 tw-overflow-hidden tw-text-ellipsis'>{venueDetails?.android_app_link}</div>
                    </a>
                  )}
                  {venueDetails?.ios_app_link && (
                    <a 
                      href={venueDetails?.ios_app_link?.includes('https://') ? venueDetails?.ios_app_link : `https://${venueDetails?.ios_app_link}`} 
                      target='_blank' 
                      rel='refferer noopener' 
                      className='tw-flex tw-gap-2 tw-items-center tw-text-blue hover:tw-text-blue tw-font-medium tw-underline tw-cursor-pointer'
                    >
                      <span className='tw-w-7 tw-h-7 tw-rounded-md border tw-border-gray tw-flex tw-items-center tw-justify-center'>
                        {/* <AppStoreIcon /> */}
                        <img src={AppStore} className='tw-max-w-full tw-h-full tw-rounded-md' />
                      </span>
                      <div className='tw-w-11/12 tw-overflow-hidden tw-text-ellipsis'>{venueDetails?.ios_app_link}</div>
                    </a>
                  )}
                </div>
              ) : (
                <div className='tw-mt-2'>
                  {/* <Button
                    className='!tw-bg-[#ebebeb] !tw-rounded-3xl !tw-py-1 !tw-px-4 !tw-text-black !tw-text-xs' 
                    // onClick={() => {setOpenAppDialog(true);}}
                  >Generate my App links</Button> */}
                </div>
              )}
            </div>
            {/* ) : '' } */}
        <div>
            <div className='tw-font-semibold tw-flex tw-items-center tw-gap-1 tw-mb-2 tw-text-black'>
              Social account links
            </div>
            <div className='tw-flex tw-flex-col tw-gap-2'>
              {formik.values.social_links && Object.entries(formik.values.social_links).map(([key, value]) => {
                  if (value) {
                      return (
                          <a href={getSocialLink(value)} target='_blank' rel='refferer noopener'  key={key} className='tw-flex tw-gap-2 tw-items-center tw-text-blue hover:tw-text-blue tw-font-medium tw-underline tw-cursor-pointer'>
                            <span className='tw-w-7 tw-h-7 tw-rounded-md border tw-border-gray tw-flex tw-items-center tw-justify-center'>
                              <img src={getSocialIcon(key?.split('_')[0])} className='tw-max-w-full tw-h-full tw-rounded-md' />
                            </span>
                            <div className='tw-w-11/12 tw-overflow-hidden tw-text-ellipsis'>{getSocialLink(key?.split('_')[0], value)}</div>
                          </a>
                      )
                  }
              })}
              {formik.values.social_links && Object.values(formik.values.social_links).filter(Boolean).length === 0 && <span className='tw-text-dark-gray tw-font-medium tw-italic'>Not added</span>}
              {/* <span className='tw-text-dark-gray tw-font-medium tw-italic'>Not added</span> */}
            </div>
        </div>

      </div>

      
      <Drawer
          anchor={props.isSmall ? "bottom" :"right"}
          open={open}
          onClose={() => {setOpen(false);  formik.resetForm(); setLicenseFile(null); setImageFile(null); setNoLogo(!Boolean(venueDetails.image)); setNoLicense(!Boolean(license?.licence_file));}}
          ModalProps={{ className: '!tw-z-[1202]'}}
          PaperProps={{ className: 'tw-min-w-[350px] lg:tw-max-w-[25%] !tw-h-auto tw-min-h-[400px] tw-max-h-screen tw-p-4' }}
      >
          <GenericEditDrawer
            {...{
              title: 'Business Info', 
              onClose : () => {setOpen(false);  formik.resetForm(); setLicenseFile(null); setImageFile(null); setNoLogo(!Boolean(venueDetails.image)); setNoLicense(!Boolean(license?.licence_file));}, 
              actions : (
                  <GenericLoadingButton
                      variant="contained"
                      type="button"
                      fullWidth
                      onClick={formik.handleSubmit}
                      size="large"
                      loading={isLoading}
                      loadingPosition="end"
                      // disabled={props.isActionsDisabled}
                  >
                      Save Info
                  </GenericLoadingButton>
              ) 
          }}
          >
            <BusinessLogo
            imageLink={venueDetails.image}
            removeImage={removeImage}
            imageFile={imageFile} 
            setImageFile={setImageFile}
            noLogo={noLogo}
            setNoLogo={setNoLogo}
          /> 
            <LiquorLicense
            license={license}
            removeLicenceImage={removeLicenceImage}
            licenseFile={licenseFile}
            licenseNumber={licenseNumber}
            setLicenseFile={setLicenseFile}
            setLicenseNumber={setLicenseNumber}
            noLicense={noLicense}
            setNoLicense={setNoLicense}
          /> 

          
        <SocialMedia formik={formik} />
          </GenericEditDrawer>
      </Drawer>
      <Drawer
          anchor={props.isSmall ? "bottom" :"right"}
          open={openAppDialog}
          onClose={() => {setOpenAppDialog(false);  formik.resetForm();}}
          ModalProps={{ className: '!tw-z-[1202]'}}
          PaperProps={{ className: 'tw-min-w-[350px] lg:tw-max-w-[25%] !tw-h-auto tw-min-h-[400px] tw-max-h-screen tw-p-4' }}
      >
          <GenericEditDrawer
            {...{
              title: (
                <>
                  Mobile App Links
                  <HtmlTooltip title={'Once your app is ready, your app links will be visible on your website'} >
                      <InfoOutlined sx={{ fontSize: '18px', ml: 1 }} />
                  </HtmlTooltip>
                </>
              ), 
              onClose : () => {setOpenAppDialog(false);  formik.resetForm();}, 
              actions : (
                  <GenericLoadingButton
                      variant="contained"
                      type="button"
                      fullWidth
                      onClick={formik.handleSubmit}
                      size="large"
                      loading={isLoading}
                      loadingPosition="end"
                      // disabled={props.isActionsDisabled}
                  >
                      Save Info
                  </GenericLoadingButton>
              ) 
          }}
          >
            <GenericTextFieldWithLabel
              containerStyle={{
                  width: '100%'
              }}
              label="Android app link"
              id="android_app_link"
              name="android_app_link"
              value={formik.values.android_app_link}
              onChange={formik.handleChange}
              isSmallLabel={true}
              fullWidth={true}
              InputProps={{ startAdornment: (
                <span className='flex tw-items-center tw-gap-1'>
                  <PlayStoreInitialsIcon />
                  https://
                </span>
              )}}
            />
            <GenericTextFieldWithLabel
              containerStyle={{
                  width: '100%'
              }}
              label="IOS app link"
              id="ios_app_link"
              name="ios_app_link"
              value={formik.values.ios_app_link}
              onChange={formik.handleChange}
              isSmallLabel={true}
              fullWidth={true}
              InputProps={{ startAdornment: (
                <span className='flex tw-items-center tw-gap-1'>
                  <AppStoreInitialsIcon />
                  https://
                </span>
              )}}
            />
          </GenericEditDrawer>
      </Drawer>

        
      <Dialog
          open={openBusinessLogo}
          onClose={() => setOpenBusinessLogo(false)}
      >
        <DialogTitle className='tw-flex tw-justify-between tw-items-center !tw-p-0 !tw-pl-2'>
            <div className='tw-font-bold tw-text-xl'>Business Logo</div>
            <IconButton onClick={() => setOpenBusinessLogo(false)}>
                <Cancel sx={{ color: '#000' }} />
            </IconButton>
        </DialogTitle>
        <div>
            <img
                className='tw-max-w-full sm:tw-max-w-80 tw-max-h-full'
                src={venueDetails.image}
                // src={imageFile ? URL.createObjectURL(imageFile) : venueDetails.image}
            />
        </div>
      </Dialog>
      <Dialog
          open={openBusinessLicense}
          onClose={() => setOpenBusinessLicense(false)}
      >
        <DialogTitle className='tw-flex tw-justify-between tw-items-center !tw-p-0 !tw-pl-2'>
            <div className='tw-font-bold tw-text-xl'>Business License</div>
            <IconButton onClick={() => setOpenBusinessLicense(false)}>
                <Cancel sx={{ color: '#000' }} />
            </IconButton>
        </DialogTitle>
        <div>
            <img
                className='tw-max-w-full sm:tw-max-w-80 tw-max-h-full'
                src={license.licence_file}
                // src={imageFile ? URL.createObjectURL(imageFile) : venueDetails.image}
            />
        </div>
      </Dialog>
      <div className='tw-gap-2 tw-hidden '>
        <div className={styles.liquor_license_container}>
          <LiquorLicense
            license={license}
            removeLicenceImage={removeLicenceImage}
            licenseFile={licenseFile}
            licenseNumber={licenseNumber}
            setLicenseFile={setLicenseFile}
            setLicenseNumber={setLicenseNumber}
          />
        </div>

        <div
          className={styles.micro_website_container}
        >
          <MicroWebsite
            venueDetails={venueDetails}
            externalWebsite={externalWebsite}
            setExternalWebsite={setExternalWebsite}
          />
          <AppLinks
            formik={formik}
          />
        </div>

        <div
          className={styles.social_media_container}
        >
          <SocialMedia
            formik={formik}
          />
        </div>
      </div>

      {/* <div className={styles.save_btn_container} >
        <GenericLoadingButton
          variant="contained"
          type="button"
          onClick={formik.handleSubmit}
          size="large"
          loading={isLoading}
          loadingPosition="end"
          disabled={isActionsDisabled}
        >
          Save
        </GenericLoadingButton>
      </div> */}
    </div>
  )
}

export default BusinessInfo