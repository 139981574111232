import React, { useEffect, useState } from 'react'
import Loader from '../Library/Loader'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import axios2, { nodeAxios } from '../../utils/axiosConfig'



const AuthorizeUnsplash = () => {
     
  const [searchParams, setSearchParams] = useSearchParams();  
  const user = useSelector((state) => state.user);
  console.log('🚀OUTPUT --> user:', user);

  const [isLoading, setIsLoading] = useState(true);

  const CODE = searchParams.get("code");
  console.log('🚀OUTPUT --> CODE:', CODE);

  const createAccessToken = () => {
    setIsLoading(true)
    nodeAxios.get('/auth/get-user')
    .then((resp) => {
        axios.post('https://unsplash.com/oauth/token', {
            client_id : process.env.REACT_APP_UNSPLASH_CLIENT_ID || "TThLIE6nK6DxWvnkcavoPFLw0SqKE5-pPn9WlzZkRug",
            client_secret : process.env.REACT_APP_UNSPLASH_SECRET_KEY || "MVErUQChr0IdB5ZzH7Qqho5tUke4CINnjMl3kRMEQlM",
            redirect_uri :  `${window?.origin}/authorize_unsplash`,
            code : CODE,
            grant_type : 'authorization_code',
        })
        .then((response) => {
            console.log('🚀OUTPUT --> response:', response?.data);
            if(response?.data?.access_token){
                axios.post('https://api.unsplash.com/clients',
                { 
                    name : resp.data.data?.name,
                    description : resp.data.data?.email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization':  `Bearer ${response?.data?.access_token}`
                        }
                }
                ).then((res) => {
                    if(res.data?.client_id){ 
                        //  Saving to DB 
                        // console.log('🚀OUTPUT --> res.data?.client_id:', res.data?.client_id);
                        axios2.post('/web-builder/web-gallery-setting/create', {
                            unsplash_key : res.data?.client_id,
                        })
                        .then((resp) => {
                            console.log('🚀OUTPUT --> resp:', resp);
                            setIsLoading(false)
                            // Close the window
                            setTimeout(() => {window.close()}, 500)
                        })
                        .catch((err) => {
                            console.log('🚀OUTPUT --> err:', err);
                            setIsLoading(false)
                        })


                        // setIsLoading(false)
                        // Close the window
                        // setTimeout(() => {window.close()}, 4000)
                    }
                })
                .catch(() => {
                    setIsLoading(false)
                })
            }
            
        })
        .catch(() => {
            setIsLoading(false)
        })
        Cookies.set('scopes', resp.data.data?.scopes?.join(','));
    })
    .catch((error) => {
      console.log('🚀OUTPUT --> error:', error);
    });
  }

  useEffect(() => {
    if(CODE){
        createAccessToken()
    }
  }, [CODE])


  return (
    <div>
        {isLoading ? <Loader /> : ""}
        {/* {clientId ? `clientId = ${clientId}` : CODE} */}
    </div>
  )
}
  console.log('🚀OUTPUT --> location:', location);

export default AuthorizeUnsplash