import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="searchmain-section content mt-5 terms">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb nocolor">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#" className="active">
                  Privacy Policy
                </a>
              </li>
            </ol>
          </nav>
          <div className="row mt-4 pb-5">
            <div className="col-md-12">
              <h1>PRIVACY POLICY</h1>
              <p>
                This Privacy Policy describes how Esplanda (“Esplanda,” “we” or
                “us”) collects, uses and shares personal information, such as
                your name, address, phone number and email address. Use of
                Esplanda’s websites and apps (the “Service”) is subject to this
                Privacy Policy and our Terms of Service. Read them carefully to
                understand our practices and your options.
              </p>

              <p>
                We will notify you by email or through the Service before making
                significant changes to this Privacy Policy. If you have
                questions, please contact us at{" "}
                <a href="mailto:info@esplanda.com">info@esplanda.com</a>.
              </p>
              <p>
                The Service is for persons 21 years of age and older. Do not use
                the Service or send us information if you are under 21. We do
                not knowingly collect information about anyone under 21, and if
                we discover that we have personal information about somebody
                under 21, we will delete it as soon as possible. If you think we
                have personal information about somebody under 21, please
                contact us as soon as possible at{" "}
                <a href="mailto:info@esplanda.com">info@esplanda.com</a>. Our
                Terms of Service have more information on age verification and
                eligibility to use our Service.
              </p>

              <h2>INFORMATION WE COLLECT ABOUT YOU</h2>
              <p>
                Information You Give Us. Esplanda collects the information you
                give us when you sign up for and use the Service, such as your
                name, email address, account password, date of birth, billing
                information, phone number, delivery address and social media
                IDs.
              </p>

              <p>
                Tracking Information and Cookies. We collect tracking
                information when you use the Service, such as your device and
                browser type, location, page views, IP address, referring/exit
                pages, and information about how you interact with the Service
                and third party links. (Third parties may also collect this
                information. Visit our page on Interest-Based Advertising to
                learn more.) This information may be collected with a cookie, a
                small file from a web server that is stored in your browser.
                Your browser can be set to block cookies, but some features of
                the Service may not work. To learn more about cookies, please
                visit the third party site All About Cookies. Your browser may
                also have a “do not track” feature that tells websites not to
                track your online activities. Our Service does not respond to
                this feature.
              </p>

              <p>
                Precise Location Data. The Service collects your precise
                geographical location on some devices, such as smartphones. You
                can opt out of this through the settings on your device or in
                the App, but it may limit some features of the Service.
              </p>

              <p>
                Information from Other Sources. Esplanda may obtain information
                about you from other sources, including retailers and other
                companies, social media, and public records. If you use Facebook
                or another third party to login to the Service, we’ll receive
                information about you from them.
              </p>

              <h2>HOW WE USE YOUR INFORMATION</h2>
              <p>
                <strong>
                  We use the information we collect from you to do things like:
                </strong>
              </p>
              <ul>
                <li>provide and improve the Service;</li>
                <li>personalize your Service experience;</li>
                <li>
                  let you rate your purchases and view your purchase history;
                </li>
                <li>
                  obtain additional information about you from third parties;
                </li>
                <li>notify you of new services and special offers;</li>
                <li>
                  help you share a product review or join a rewards program;
                </li>
                <li>run sweepstakes and contests;</li>
                <li>contact you through social media;</li>
                <li>troubleshoot problems with the Service;</li>
                <li>
                  notify you of payment, billing, maintenance, and other
                  account-related issues;
                </li>
                <li>provide customer service;</li>
                <li>investigate and address fraud; and</li>
                <li>
                  create trend and preference information based on your use of
                  the Service.
                </li>
              </ul>

              <h2>HOW WE SHARE YOUR INFORMATION</h2>
              <p>
                <strong>
                  We share information collected from or about you as follows:
                </strong>
              </p>
              <p>
                Aggregated and Anonymized Data. Esplanda may share or sell trend
                and preference information based on your use of the Service,
                individually and with the information of other users. This
                information can be used for analysis, to develop consumer
                insights, and to deliver advertising about other products and
                services. We will first remove anything that personally
                identifies you.
              </p>

              <p>
                Retailers. We share information such as your name, payment
                information, address, purchase history and delivery location
                with retailers so they can fulfill your orders through the
                Service. Your information may also be shared with shipping and
                delivery companies.
              </p>

              <p>
                Service Providers. We use other companies and individuals to
                help us run the Service and support customers. These service
                providers may use your personal information to perform their
                work.
              </p>

              <p>
                Third-Party Analytics. We use analytics service providers to
                monitor and improve the Service. For more information, including
                information about setting preferences for and opting out of data
                collection by such service providers, please visit Google
                Analytics. Opting out may limit the functionality of the
                Service.
              </p>

              <p>
                Law Enforcement. We may share information about you to protect
                legal rights and public and personal safety, to stop illegal or
                unethical activity, or as required by law.
              </p>

              <p>
                Acquisition. If all or a part of Esplanda is sold or
                transferred, or a similar event occurs, a third party may
                receive your personal information. If they will use your
                personal information contrary to this Privacy Policy, you will
                receive prior notice.
              </p>

              <h2>OTHER IMPORTANT NOTICES</h2>
              <p>
                Updating Your Personal Information and Preferences; Opting Out.
                You can update your account information and preferences by
                logging into your account. To opt out of text or email marketing
                from us, follow the instructions in the text or email. This opt
                out does not apply to account-related notifications, which are
                required in order to use the Service. To stop account-related
                notifications, send us a request to deactivate your account.
              </p>

              <p>
                Retention of Personal Information. Esplanda may keep your
                personal information as long as needed for the purposes in this
                Privacy Policy unless a longer period is required or permitted
                by law.
              </p>

              <p>
                Payment/Credit/Debit Card Transactions. The retailer fulfilling
                your order processes your payment, but we may collect the type,
                last four digits and expiration date of your credit or debit
                card number.
              </p>

              <p>
                Invitations to Esplanda. If you invite someone to use the
                Service, you will need to provide their name and email address.
                Esplanda stores this information to send an invitation to them
                and track the results of the invitation referral. You may not
                provide the name or email address of anyone under 21 years of
                age.
              </p>

              <p>
                Public Forums. Our Service includes public blogs and forums. To
                request removal of any personal information that appears in
                blogs or forums operated by Esplanda, contact us at
                info@esplanda.com. If we can reasonably remove your personal
                information, we will, but we are not responsible for personal
                information or other information you post in public blogs and
                forums. If we cannot remove your personal information, we will
                let you know why.
              </p>

              <p>
                Security. We use standard security practices such as encryption
                and firewalls to protect the information we collect from you. No
                security system is perfect, and we do not guarantee the security
                of your information. You are responsible for all actions taken
                in the name of your account, so use your discretion when
                providing information and managing your account. Use unique
                numbers, letters and special characters in your password and do
                not disclose it to anyone. Please review our Terms of Service
                for additional information. If your password is compromised
                notify us immediately at info@esplanda.com and change your
                password.
              </p>

              <h2>California Privacy Rights.</h2>
              <p>
                "Shine the Light" Law (Cal. Civ. Code § 1798.83). California
                residents may request certain information regarding the
                disclosure of their personal information to third parties for
                such third parties’ direct marketing purposes. Send these
                requests to{" "}
                <a href="mailto:info@esplanda.com">info@esplanda.com</a>, with
                the subject line "California Resident Shine the Light Request",
                and include your full name and address.
              </p>

              <strong>
                California Consumer Privacy Act (Cal. Civ. Code §
                1798.100-1798.199). A California consumer has the following
                rights:
              </strong>
              <ul>
                <li>
                  The right to request Esplanda to disclose what personal
                  information Esplanda is collecting about them and to request a
                  copy of that information;
                </li>
                <li>
                  The right to request Esplanda to disclose whether their
                  personal information is sold or disclosed and to whom;
                </li>
                <li>
                  The right to request Esplanda to opt them out of the sale of
                  their personal information (to the extent Esplanda sells
                  personal information about the consumer to third parties);
                </li>
                <li>
                  The right to request Esplanda to delete their personal
                  information; and
                </li>
                <li>
                  The right to non-discrimination in the exercise of these
                  rights.
                </li>
              </ul>

              <strong>
                California consumers are also entitled to certain periodic
                updates concerning their personal information. For more
                information about the California Consumer Privacy Act, including
                how to exercise their rights, California consumers should visit
                our California Privacy Rights page
              </strong>

              <p>
                Nevada Privacy Rights. Nevada consumers have the right to submit
                a request directing that we not sell certain covered information
                that we have collected or will collect about them. If you are a
                Nevada consumer and wish to make such a request, send your
                request to{" "}
                <a href="mailto:info@esplanda.com">info@esplanda.com</a>, with
                the subject line "Nevada Privacy Request". You must provide us
                with your full name and address. Note that Esplanda may take
                reasonable steps to verify the authenticity of the request and
                the identity of the requestor.
              </p>

              <p>
                <strong>
                  Contact. You can contact us by email at info@esplanda.com, or
                  by postal mail at: Esplanda, Inc.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
