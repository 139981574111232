import React from 'react'
import styles from './WebsitePageSettings.module.scss'
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, Switch } from '@mui/material';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import clsx from 'clsx';
import DummyOpenGraphImage from '../../../assets/images/opengraphdummy.png';
import { useRef } from 'react';
import { nodeAxios } from '../../../utils/axiosConfig'
import { toast } from 'react-toastify';
import GenericLoadingButton from '../../Library/GenericLoadingButton/GenericLoadingButton';
import { useParams } from 'react-router-dom';

const WebsitePageSettings = (props) => {

    const graphImageRef = useRef();

    const { id } = useParams();

    const [metaTitle, setMetaTitle] = useState('');
    const [metaPermalink, setMetaPermalink] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaTagLoading, setMetaTagLoading] = useState('');

    const [graphTab, setGraphTab] = useState(0);
    const [graphLoading, setGraphLoading] = useState(false);
    const [fbGraphTitle, setFbGraphTitle] = useState('');
    const [fbGraphDescription, setFbGraphDescription] = useState('');
    const [fbGraphImage, setFbGraphImage] = useState('');
    const [fbGraphImageLink, setFbGraphImageLink] = useState('');

    const [twGraphTitle, setTwGraphTitle] = useState('');
    const [twGraphDescription, setTwGraphDescription] = useState('');
    const [twGraphImage, setTwGraphImage] = useState('');
    const [twGraphImageLink, setTwGraphImageLink] = useState('');

    const [otherSettingLoading, setOtherSettingLoading] = useState(false);

    const [canonicalTxt, setCanonicalTxt] = useState('');
    const [robotsObj, setRobotsObj] = useState({
        index: true,
        no_follow: true,
        no_archive: true,
        no_image_index: true,
        no_snippet: true,
    });
    const [header, setHeader] = useState(false)
    const [footer, setFooter] = useState(false)
    console.log('🚀OUTPUT --> robotsObj:', robotsObj);


    const handleSaveOther = () => {
        setOtherSettingLoading(true);
        nodeAxios
            .post('/web-builder/web-page-setting/update', {
                web_page_id: id || props.blogId,
                robot_meta: robotsObj,
                canonical_url: canonicalTxt,
                section: 'otherSetting',
            })
            .then(() => {
                toast.success('Saved Successfully');
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {
                setOtherSettingLoading(false);
            });
    };

    const handleSaveMeta = () => {
        setMetaTagLoading(true);
        nodeAxios
            .post('/web-builder/web-page-setting/update', {
                web_page_id: id || props.blogId,
                title: metaTitle,
                permalink: metaPermalink,
                description: metaDescription,
                section: 'seoTag',
            })
            .then(() => {
                toast.success('Saved Successfully');
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {
                setMetaTagLoading(false);
            });
    };

    const handleSaveGraph = () => {
        setGraphLoading(true);

        let fd = new FormData();
        fd.append('web_page_id', id || props.blogId);
        fd.append('title', graphTab == 0 ? fbGraphTitle : twGraphTitle);
        fd.append('image_file', graphTab == 0 ? fbGraphImage : twGraphImage);
        fd.append(
            'description',
            graphTab == 0 ? fbGraphDescription : twGraphDescription
        );
        fd.append('type_id', graphTab);
        fd.append('section', 'openGraph');

        nodeAxios
            .post('/web-builder/web-page-open-graph-tag-suggestion/update', fd)
            .then(() => {
                toast.success('Saved Successfully');
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {
                setGraphLoading(false);
            });
    };

    const fetchPageSetting = () => {
        nodeAxios
            .get('/web-builder/web-page-setting/my', {
                params: {
                    web_page_id: id || props.blogId,
                },
            })
            .then((response) => {
                let data = response.data?.data;
                setMetaTitle(data.title);
                setMetaPermalink(data.permalink);
                setMetaDescription(data.description);
                setCanonicalTxt(data.canonical_url);
                setHeader(data.is_header)
                setFooter(data.is_footer)
                setRobotsObj(data.robot_meta);
            })
            .catch(() => { })
            .finally(() => {
                // setIsLoading(false)
            });
    };

    const fetchPageOpenGraphSetting = (typeId) => {
        nodeAxios
            .get('/web-builder/web-page-open-graph-tag-suggestion/my', {
                params: {
                    web_page_id: id || props.blogId,
                    type_id: typeId,
                },
            })
            .then((response) => {
                let data = response.data?.data;
                if (typeId == 0) {
                    setFbGraphTitle(data.title);
                    setFbGraphDescription(data.description);
                    if (data.image) {
                        setFbGraphImageLink(
                            `${imageBaseUrl}/WebPageOpenGraphTagSuggestion/${data.image}`
                        );
                    }
                }

                if (typeId == 1) {
                    setTwGraphTitle(data.title);
                    setTwGraphDescription(data.description);
                    if (data.image) {
                        setTwGraphImageLink(
                            `${imageBaseUrl}/WebPageOpenGraphTagSuggestion/${data.image}`
                        );
                    }
                }
            })
            .catch(() => { })
            .finally(() => {
                // setIsLoading(false)
            });
    };

    const handleHeaderFooterMap = (header, footer) => {
        nodeAxios.post('/web-builder/web-page-setting/update', {
            web_page_id: id || props.blogId,
            is_header: header ? 1 : 0,
            is_footer: footer ? 1 : 0,
            section: 'headerFooter'
        })
            .then(() => {
                toast.success('Saved Successfully')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {

            })
    }

    useEffect(() => {
        if (id || props.blogId) {
            fetchPageSetting();
            fetchPageOpenGraphSetting(0);
        }
    }, [id, props.blogId]);

    return (
        <div className={styles.setting_container}>
            <div className={styles.robotstxt_section}>
                <div className={styles.robotstxt_title_container}>
                    <div className={styles.robotstxt_title}>
                        Meta tag suggestions
                    </div>
                </div>

                <div className={styles.webmaster_container}>
                    <div className={styles.webmaster_input_container}>
                        <label className={styles.label}>Title</label>

                        <div>
                            <input
                                className={styles.webaddress_input}
                                value={metaTitle}
                                onChange={(event) => {
                                    // const val = event.target.value?.replace(/[^a-zA-Z0-9-\s]/g, '');
                                    const val = event.target.value;
                                    setMetaTitle(val);
                                }}
                            />
                            <div className={styles.help_text}>
                                This will appear in first line in search results.
                            </div>
                        </div>
                    </div>

                    <div className={styles.webmaster_input_container}>
                        <label className={styles.label}>Permalink</label>

                        <div>
                            <input
                                className={styles.webaddress_input}
                                value={metaPermalink}
                                onChange={(event) => {
                                    const val = event.target.value;
                                    // const val = event.target.value?.replace(/[^a-zA-Z0-9-\s]/g, '');
                                    setMetaPermalink(event.target.value);
                                }}
                            />
                            <div className={styles.help_text}>
                                This is unique URL of this page, displayed below page
                                title in search results.
                            </div>
                        </div>
                    </div>

                    <div className={styles.webmaster_input_container}>
                        <label className={styles.label}>Description</label>

                        <div>
                            <textarea
                                className={styles.webaddress_textarea}
                                value={metaDescription}
                                rows={4}
                                onChange={(event) => {
                                    // const val = event.target.value?.replace(/[^a-zA-Z0-9-\s]/g, '');
                                    const val = event.target.value
                                    setMetaDescription(val);
                                }}
                            />
                            <div className={styles.help_text}>
                                This will appear in description when page appear in search
                                results.
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.textarea_btn_container}>
                    <Button
                        style={{
                            backgroundColor: '#ff3f3f',
                            borderRadius: '20px',
                            height: '30px',
                        }}
                        variant='contained'
                        onClick={() => {
                            setMetaTitle('')
                            setMetaPermalink('')
                            setMetaDescription('')
                        }}
                    >
                        Clear all
                    </Button>

                    <GenericLoadingButton
                        style={{
                            backgroundColor: '#0B9DE4',
                            borderRadius: '20px',
                            height: '30px',
                        }}
                        variant='contained'
                        loading={metaTagLoading}
                        loadingPosition='end'
                        size='medium'
                        onClick={handleSaveMeta}
                    >
                        Save
                    </GenericLoadingButton>
                </div>
            </div>

            <div className={styles.robotstxt_section}>
                <div className={styles.robotstxt_title_container}>
                    <div className={styles.robotstxt_title}>
                        Header & Footer
                    </div>
                </div>

                <div className={styles.webmaster_container}>

                    <div
                        className={styles.webmaster_input_container}
                    >
                        <label
                            className={styles.sitemap_label}
                        >
                            Show header on this page
                        </label>

                        <Switch
                            classes={{
                                root: styles.switch_root,
                                checked: styles.switch_checked,
                                switchBase: styles.switch_base,
                                thumb: styles.switch_thumb,
                                track: header
                                    ? styles.checked_track
                                    : styles.un_checked_track,
                            }}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            checked={header}
                            onChange={(event) => {
                                handleHeaderFooterMap(event.target.checked, footer)
                                setHeader(event.target.checked)
                            }}
                        />
                    </div>

                    <div
                        className={styles.webmaster_input_container}
                    >
                        <label
                            className={styles.sitemap_label}
                        >
                            Show footer on this page
                        </label>

                        <Switch
                            classes={{
                                root: styles.switch_root,
                                checked: styles.switch_checked,
                                switchBase: styles.switch_base,
                                thumb: styles.switch_thumb,
                                track: footer
                                    ? styles.checked_track
                                    : styles.un_checked_track,
                            }}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            checked={footer}
                            onChange={(event) => {
                                handleHeaderFooterMap(header, event.target.checked)
                                setFooter(event.target.checked)
                            }}
                        />
                    </div>
                </div>
            </div>


            <div className={styles.robotstxt_section}>
                <div className={styles.robotstxt_title_container}>
                    <div className={styles.robotstxt_title}>
                        Open graph tag suggestions
                    </div>
                </div>

                <div className={styles.opengraph_container}>
                    <div
                        style={{
                            width: 400,
                        }}
                    >
                        <div className={styles.tab_container}>
                            <div
                                className={clsx(
                                    styles.facebook_tab,
                                    graphTab == 0 && styles.active_tab
                                )}
                                onClick={() => {
                                    setGraphTab(0);
                                    fetchPageOpenGraphSetting(0);
                                }}
                            >
                                <FaFacebook />
                                Facebook
                            </div>
                            <div
                                className={clsx(
                                    styles.twitter_tab,
                                    graphTab == 1 && styles.active_tab_tw
                                )}
                                onClick={() => {
                                    setGraphTab(1);
                                    fetchPageOpenGraphSetting(1);
                                }}
                            >
                                <FaTwitter />
                                Twitter
                            </div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {graphTab == 0 ? (
                                <img
                                    src={
                                        fbGraphImage
                                            ? URL.createObjectURL(fbGraphImage)
                                            : fbGraphImageLink || DummyOpenGraphImage
                                    }
                                    height={150}
                                    width={200}
                                />
                            ) : (
                                <img
                                    src={
                                        twGraphImage
                                            ? URL.createObjectURL(twGraphImage)
                                            : twGraphImageLink || DummyOpenGraphImage
                                    }
                                    height={150}
                                    width={200}
                                />
                            )}
                        </div>

                        {(graphTab == 0 ? fbGraphTitle : twGraphTitle) ||
                            ((graphTab == 0
                                ? fbGraphDescription
                                : twGraphDescription) && (
                                    <div className={styles.graph_title_description_container}>
                                        <div
                                            style={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            {graphTab == 0 ? fbGraphTitle : twGraphTitle}
                                        </div>
                                        <div>
                                            {graphTab == 0
                                                ? fbGraphDescription
                                                : twGraphDescription}
                                        </div>
                                    </div>
                                ))}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                        }}
                    >
                        <div className={styles.webmaster_input_container}>
                            <label className={styles.label}>Title</label>

                            <div>
                                <input
                                    className={styles.webaddress_input}
                                    value={graphTab == 0 ? fbGraphTitle : twGraphTitle}
                                    onChange={(event) => {
                                        // const val = event.target.value?.replace(/[^a-zA-Z0-9-\s]/g, '');
                                        const val = event.target.value
                                        if (graphTab == 0) {
                                            setFbGraphTitle(val);
                                        } else {
                                            setTwGraphTitle(val);
                                        }
                                    }}
                                />
                                <div className={styles.help_text}>
                                    This will appear in title as shown.
                                </div>
                            </div>
                        </div>

                        <div className={styles.webmaster_input_container}>
                            <label className={styles.label}>Image</label>

                            <div>
                                <input
                                    style={{
                                        display: 'none',
                                    }}
                                    type='file'
                                    // value={(graphTab == 0) ? fbGraphImage : twGraphImage}
                                    onChange={(event) => {
                                        if (graphTab == 0) {
                                            setFbGraphImage(event.target.files[0]);
                                        } else {
                                            setTwGraphImage(event.target.files[0]);
                                        }
                                        event.target.value = '';
                                    }}
                                    ref={graphImageRef}
                                />
                                <button
                                    style={{
                                        border: '1px solid #959595',
                                        color: '#959595',
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        height: 30,
                                        width: 150,
                                    }}
                                    onClick={() => {
                                        graphImageRef.current?.click();
                                    }}
                                >
                                    Select Image
                                </button>
                                <div className={styles.help_text}>Post Image</div>
                            </div>
                        </div>

                        <div className={styles.webmaster_input_container}>
                            <label className={styles.label}>Description</label>

                            <div>
                                <textarea
                                    className={styles.webaddress_textarea}
                                    rows={4}
                                    value={
                                        graphTab == 0
                                            ? fbGraphDescription
                                            : twGraphDescription
                                    }
                                    onChange={(event) => {
                                        const val = event.target.value
                                        // const val = event.target.value?.replace(/[^a-zA-Z0-9-\s]/g, '');
                                        if (graphTab == 0) {
                                            setFbGraphDescription(val);
                                        } else {
                                            setTwGraphDescription(val);
                                        }
                                    }}
                                />
                                <div className={styles.help_text}>Post Description</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.textarea_btn_container}>
                    <Button
                        style={{
                            backgroundColor: '#ff3f3f',
                            borderRadius: '20px',
                            height: '30px',
                        }}
                        variant='contained'
                        onClick={() => {
                            setFbGraphImage(null);
                            setFbGraphTitle('');
                            setFbGraphDescription('');
                            setTwGraphImage(null);
                            setTwGraphTitle('');
                            setTwGraphDescription('');

                        }}
                    >
                        Clear all
                    </Button>

                    <GenericLoadingButton
                        style={{
                            backgroundColor: '#0B9DE4',
                            borderRadius: '20px',
                            height: '30px',
                        }}
                        variant='contained'
                        loading={graphLoading}
                        loadingPosition='end'
                        size='medium'
                        onClick={handleSaveGraph}
                    >
                        Save
                    </GenericLoadingButton>
                </div>
            </div>

            <div className={styles.robotstxt_section}>
                <div className={styles.robotstxt_title_container}>
                    <div className={styles.robotstxt_title}>Other Settings</div>
                </div>

                <div className={styles.webmaster_container}>
                    <div>
                        <div
                            style={{
                                fontWeight: 600,
                            }}
                        >
                            Robots Meta
                        </div>
                        <FormGroup
                            style={{
                                width: 340,
                            }}
                            row={true}
                        >
                            <FormControlLabel
                                style={{
                                    width: 160,
                                }}
                                onChange={(event) => {
                                    setRobotsObj((prevState) => ({
                                        ...prevState,
                                        index: event.target.checked,
                                    }));
                                }}
                                checked={robotsObj.index}
                                control={<Checkbox />}
                                label='Index'
                            />
                            {/* <FormControlLabel
                      style={{
                        width: 160,
                      }}
                      onChange={(event) => {
                        setRobotsObj((prevState) => ({
                          ...prevState,
                          no_index: event.target.checked,
                        }));
                      }}
                      checked={robotsObj.no_index}
                      control={<Checkbox />}
                      label='No Index'
                    /> */}
                            <FormControlLabel
                                style={{
                                    width: 160,
                                }}
                                onChange={(event) => {
                                    setRobotsObj((prevState) => ({
                                        ...prevState,
                                        no_follow: event.target.checked,
                                    }));
                                }}
                                checked={robotsObj.no_follow}
                                control={<Checkbox />}
                                label='No Follow'
                            />
                            <FormControlLabel
                                style={{
                                    width: 160,
                                }}
                                onChange={(event) => {
                                    setRobotsObj((prevState) => ({
                                        ...prevState,
                                        no_archive: event.target.checked,
                                    }));
                                }}
                                checked={robotsObj.no_archive}
                                control={<Checkbox />}
                                label='No Archive'
                            />
                            <FormControlLabel
                                style={{
                                    width: 160,
                                }}
                                onChange={(event) => {
                                    setRobotsObj((prevState) => ({
                                        ...prevState,
                                        no_image_index: event.target.checked,
                                    }));
                                }}
                                checked={robotsObj.no_image_index}
                                control={<Checkbox />}
                                label='No Image Index'
                            />
                            <FormControlLabel
                                style={{
                                    width: 160,
                                }}
                                onChange={(event) => {
                                    setRobotsObj((prevState) => ({
                                        ...prevState,
                                        no_snippet: event.target.checked,
                                    }));
                                }}
                                checked={robotsObj.no_snippet}
                                control={<Checkbox />}
                                label='No Snippet'
                            />
                        </FormGroup>
                    </div>

                    <div>
                        <label className={styles.label}>Canonical Url</label>

                        <div>
                            <input
                                className={styles.webaddress_input}
                                value={canonicalTxt}
                                onChange={(event) => {
                                    setCanonicalTxt(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.btn_container}>
                    <GenericLoadingButton
                        style={{
                            backgroundColor: '#0B9DE4',
                            borderRadius: '20px',
                            height: '30px',
                        }}
                        variant='contained'
                        loading={otherSettingLoading}
                        loadingPosition='end'
                        size='medium'
                        onClick={handleSaveOther}
                    >
                        Save
                    </GenericLoadingButton>
                </div>
            </div>

        </div>
    )
}

export default WebsitePageSettings