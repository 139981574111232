import { Link } from 'react-router-dom';
import React from 'react'
import Const from "../../helpers/const";
import { classname, cns } from '../../../utils/bem';
import { Typography, Button } from "@mui/material";
import './AddNewProduct.scss'
import Customize from "../../../assets/feature.png";
import listicon from "../../../assets/list-text.png";
import dragdropbannerimg from "../../../assets/drag-drop-banner.png";
import viewproductgroupsdesistoreimg from "../../../assets/view-product-groups-desistore.png";
import routePaths from '../../../config/route-paths';
const DragandDropBanners = (props) => {
const cn = classname('add-new-product')
return (
<div
className={cns(
cn(),
props.className,
)}>
<div className={cn('help-page-title')}>
   <div className={cn('title-text')}>
      <Typography variant="h1">
         Help & Support                
      </Typography>
   </div>
   <div className={cn('help-page-title-search')}>
      Search 
   </div>
</div>
<div className={cn('help-page-body')}>
   <div className={cn('helpdesk-grid')}>
      <div className={cn('left-list')}>
         <Typography variant="h2">
            <div className={cn('iconProduct')}>
               <img src={Customize} />
            </div>
            Customize Your Website & App              
         </Typography>
         <ul className={cn('helpdeskinnerlist')}>
         <li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.AddNewProductGroups}>How to Add New Product Groups</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropProductGroupInterface}>Drag & Drop Product Group Interface</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingProductGroup}>Edit Existing Product Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.DragandDropBanners} className={cn('selected')}>Drag & Drop Banners</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateNewBannerGroup}>Create New Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.EditExistingBannerGroup}>Edit Existing Banner Group</Link></li>
<li><span className={cn('listicon')}><img src={listicon} /></span> <Link to={routePaths.CreateCustomStorePage}>Create Custom Store Page</Link></li>
</ul>
      </div>
      <div className={cn('right-body-content')}>
         <Typography variant="h3">
         Drag & Drop Banners
         </Typography>
         <p><strong>Customizing the layout of your store’s home page is done through the drag & drop interface on the ‘Customize Homepage’ page of the
admin website. You can play around with the layout of product groups and banners to tailor the shopping experience for your customer base.
</strong></p>
         <p>
            
         <ol>
            <li>Go to ‘<strong>Customize Homepage</strong>’  on the admin website.</li>

            <span className={cn('addnewproductsimg')}>    
    <img src={Const.isOurDesiStore ? viewproductgroupsdesistoreimg : dragdropbannerimg}/>
    </span>

            <li>On the left side of the page, you will see your product groups and banners. On the right side, you will see an interactive laptop which
acts as a live preview of your home page. You can scroll up and down and by selecting the ‘<strong>Mobile App View</strong>’ button, you can preview
your homepage as it would appear on the app on a mobile device. Clicking ‘Website View’ returns the preview to a laptop.</li>

           

    <li>You can change the placement and order of product groups and banners on your homepage by clicking and holding them within the
preview screen. They can then be dragged around. Simply release the click, and the group you were holding will drop into place.</li>

  
    <li>You will notice that in the top right corner of each banner, there is a small trash can icon. You can click on this to remove that section
from the front page.</li>

    <li>To add another banner to your homepage, click and drag a banner from the left side of the page onto the preview screen, and release
it where you would like. To switch between groups or banners, click on the respective Product Group or Banners tabs.</li>   

<li>You can drag & drop on both the Website View or Mobile App View.</li>

<li>When you are finished customizing, click the ‘Apply’ button in the bottom right corner. Your changes will be updated in real-time.</li>
   

</ol>
         </p>
      </div>
   </div>
</div>
<div className={cn('answersection')}>
   <Typography variant="h4">
      Still can’t find an answer?
   </Typography>
   <p>Send us a ticket and we will get back to you.</p>
   <Button className={cn('contatcbutton')}><a href="https://www.ourliquorstore.com/contactus" target='blank'>Contact Us</a></Button>
</div>
</div>
)
}
export default DragandDropBanners