import React, { useContext, useEffect, useRef, useState } from 'react'
import WebsiteEditorContext from '../../../../utils/context/websiteEditorContext'
import { getInsideModel, handleInsideAttrChange, htmlToDraftConverter, isHTML, reorder, resolveHeight } from '../../Util/helpers'
import GenericSwitchWithLabel from '../../CustomPageBuilder/Library/GenericSwitchWithLabel/GenericSwitchWithLabel'
import LinkSelector from '../../CustomPageBuilder/Library/LinkSelector/LinkSelector'
import GenericSelectWithLabel from '../../CustomPageBuilder/Library/GenericSelectWithLabel/GenericSelectWithLabel'
import { Button, Collapse, Dialog, DialogActions, MenuItem } from '@mui/material'
import GenericTextFieldWithLabel from '../../CustomPageBuilder/Library/GenericTextFieldWithLabel/GenericTextFieldWithLabel'
import RichTextEditor3 from '../../CustomPageBuilder/Library/RichTextEditor3/RichTextEditor3'
import ImageUpload from '../../CustomPageBuilder/Library/ImageUpload/ImageUpload'
import ItemsDrag from '../../CustomPageBuilder/Library/ItemsDrag/ItemsDrag'
import Input from '../../CustomPageBuilder/Library/Input/Input'
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { html } from '@codemirror/lang-html';
import axios from '../../Util/axiosConfig'
import HideCategoryOption from '../../CustomPageBuilder/Library/HideCategoryOption/HideCategoryOption'
import TabSelector from '../../CustomPageBuilder/Library/TabSelector/TabSelector'


const successMessageText = [
    "Message Sent! We'll be in touch soon.",
    "Thank You! We'll respond shortly.",
    "Success! Expect a prompt reply.",
    "Inquiry Received! We're on it.",
    "Message Received! Quick response coming.",
    "Success! You'll hear back soon.",
    "Thank You! We value your message.",
    "Contact Form Submitted! We'll get back to you.",
    "Success! Stay tuned for our reply.",
    "Thank You for contacting us."
]
const esplandaStoreType = [
    "Brewery",
    "Winery",
    "Distillery",
    "Package Store",
    "Grocery Store",
    "Ice cream truck",
    "Restaurant",
]

const TraitsResolver = ({
    setTab,
    setEditIndex,
    setItemsKey
}) => {

    const { selectedModel, pages } = useContext(WebsiteEditorContext)
    const audioRef = useRef()
    const codeMirrorRef = useRef()
    const codeMirrorRefEditor = useRef()
    const editorRef = useRef()
    const resolveHeightTimer = useRef()

    const [model, setModel] = useState()
    const [traits, setTraits] = useState([])
    const [attributes, setAttributes] = useState({})
    const [openCodeEditor, setOpenCodeEditor] = useState(false)

    const [openRte, setOpenRte] = useState(false)
    const [editorState, setEditorState] = useState('');
    const [convertedContent, setConvertedContent] = useState(null);
    const [rteInput, setRteInput] = useState('')
    const [isReact, setIsReact] = useState(false)
    const [forms, setForms] = useState(null);

    useEffect(() => {
        if (selectedModel) {
            console.log({ selectedModel })

            const attr = selectedModel?.getAttributes()

            let insideModel
            let isReactComp
            let insideAttr
            try {
                insideModel = getInsideModel(selectedModel)
                insideAttr = insideModel?.getAttributes()
                isReactComp = insideAttr?.is_react
                setIsReact(isReactComp)
            } catch (error) {

            }


            if (isReactComp) {
                const insideTraits = JSON.parse(JSON.stringify(insideModel?.getTraits() || []))
                setModel(insideModel)
                console.log({ insideAttr })
                setAttributes(insideAttr)
                setTraits(insideTraits)
            } else {
                setModel(selectedModel)
                const mainTraits = JSON.parse(JSON.stringify(selectedModel?.getTraits() || []))
                setAttributes(attr)
                setTraits(mainTraits)
            }
            setTab('content')
            console.log({ attr })
        }
    }, [selectedModel])

    console.log('button', { traits })

    useEffect(() => {
        if (rteInput) {
            if (isHTML(convertedContent)) {
                setAttributes((prevState) => ({
                    ...prevState,
                    [rteInput]: convertedContent
                }))
            }
            handleInsideAttrChange({ model, key: rteInput, value: convertedContent, isOutside: isReact })
        }
    }, [convertedContent])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newitems = reorder(
            JSON.parse(attributes?.items || '[]'),
            result.source.index,
            result.destination.index
        );

        setAttributes((prevState) => ({
            ...prevState,
            items: JSON.stringify(newitems)
        }))

    };


    const fetchForms = (params) => {
        setForms(null);
        axios
            .get('/web-builder/web-page-form', { params })
            .then((response) => {
                setForms({
                    data: response.data?.data?.results,
                    pagination: response.data?.data?.pagination,
                });
            })
            .catch((error) => {
                setForms({
                    data: [],
                });
            });
    };

    useEffect(() => {
        fetchForms();
    }, [])

    const handleAudioChange = (event, isVideo) => {
        // const file = event.target.files[0]
        // setFileName(file.name)
        // const fd = new FormData()
        // fd.append('image_file', file)
        // axios.post('/school-web-page/upload-image', fd)
        //     .then((res) => {
        //         setAudioLink(`${res.data?.data?.base_image}/${res.data?.data?.content}`)
        //         setAudioLinkembed('');
        //         handleInsideAttrChange({ model, key: 'src', value: `${res.data?.data?.base_image}/${res.data?.data?.content}` })
        //         handleInsideAttrChange({ model, key: 'srcembed', value: '' })
        //     })

        event.target.value = ''
    }


    return (
        <div
            className="tw-flex tw-flex-col tw-gap-2"
        >
            {traits?.map?.((trait) => {
                switch (trait.name) {
                    case 'iframe':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.iframe}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        iframe: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'iframe', value: event.target.value, isOutside: isReact })
                                }}
                                // onAdvanceClick={() => {
                                //     setOpenRte(true)
                                //     htmlToDraftConverter(setEditorState, title)
                                //     setRteInput('title')
                                // }}
                                fullWidth={true}
                            />
                        )
                    case 'title':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.title}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        title: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'title', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.title)
                                    setRteInput('title')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'additional-text':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.additionalText}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        additionalText: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'additionalText', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.additionalText, true)
                                    setRteInput('additionalText')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'title2':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.title2}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        title2: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'title2', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.title2, true)
                                    setRteInput('title2')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'details':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.details}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        details: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'details', value: event.target.value, isOutside: isReact })
                                }}
                                // onAdvanceClick={() => {
                                //     setOpenRte(true)
                                //     htmlToDraftConverter(setEditorState, title)
                                //     setRteInput('details')
                                // }}
                                multiline
                                rows={4}
                                fullWidth={true}
                            />
                        )
                    case 'wtitle':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.wtitle}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        wtitle: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'wtitle', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.wtitle, true)
                                    setRteInput('wtitle')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'description':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.description}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        description: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'description', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.description, true)
                                    setRteInput('description')
                                }}
                                multiline
                                rows={4}
                                fullWidth={true}
                            />
                        )
                    case 'description2':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.description2}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        description2: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'description2', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, description2, true)
                                    setRteInput('description2')
                                }}
                                multiline
                                rows={4}
                                fullWidth={true}
                            />
                        )
                    case 'titlesub':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.titlesub}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        titlesub: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'titlesub', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.titlesub, true)
                                    setRteInput('titlesub')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'nametitle':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.nametitle}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        nametitle: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'nametitle', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.nametitle, true)
                                    setRteInput('nametitle')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'titleabove':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.titleabove}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        titleabove: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'titleabove', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.titleabove, true)
                                    setRteInput('titleabove')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'descriptionabove':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.descriptionabove}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        descriptionabove: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'descriptionabove', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.descriptionabove, true)
                                    setRteInput('descriptionabove')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'position':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label={trait.label}
                                placeholder={trait.placeholder}
                                value={attributes?.position}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        position: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'position', value: event.target.value, isOutside: isReact })
                                }}
                                onAdvanceClick={() => {
                                    setOpenRte(true)
                                    htmlToDraftConverter(setEditorState, attributes?.position, true)
                                    setRteInput('position')
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'image':
                        return (
                            <ImageUpload
                                title={trait.label}
                                imageSrc={attributes?.image}
                                setImageSrc={(val) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        image: val
                                    }))
                                }}
                                onUpload={(link) => {
                                    handleInsideAttrChange({ model, key: 'image', value: link, isOutside: isReact })
                                    resolveHeight(model, resolveHeightTimer)
                                }}
                                onImageLinkChange={(event) => {
                                    handleInsideAttrChange({ model, key: 'image_link', value: event.target.value, isOutside: isReact })
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        image_link: event.target.value
                                    }))
                                }}
                                onImageTargetChange={(event) => {

                                    let val = '_self'
                                    if (event.target.checked) {
                                        val = '_blank'
                                    } else {
                                        val = '_self'
                                    }
                                    handleInsideAttrChange({ model, key: 'image_target', value: val, isOutside: isReact })
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        image_target: val
                                    }))
                                }}
                                imageLink={attributes?.image_link}
                                imageTarget={attributes?.image_target}
                                pages={pages}
                                onDelete={() => {
                                    resolveHeight(model, resolveHeightTimer)
                                    handleInsideAttrChange({ model, key: 'image', value: '', isOutside: isReact })
                                }}

                                hideImageLink={trait.hideimagelink}
                            />
                        )
                    case 'image2':
                        return (
                            <ImageUpload
                                title={trait.label}
                                imageSrc={attributes?.image2}
                                setImageSrc={(val) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        image2: val
                                    }))
                                }}
                                onUpload={(link) => {
                                    handleInsideAttrChange({ model, key: 'image2', value: link, isOutside: isReact })
                                    resolveHeight(model, resolveHeightTimer)
                                }}
                                onImageLinkChange={(event) => {
                                    handleInsideAttrChange({ model, key: 'image_link2', value: event.target.value, isOutside: isReact })
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        image_link2: event.target.value
                                    }))
                                }}
                                onImageTargetChange={(event) => {

                                    let val = '_self'
                                    if (event.target.checked) {
                                        val = '_blank'
                                    } else {
                                        val = '_self'
                                    }
                                    handleInsideAttrChange({ model, key: 'image_target2', value: val, isOutside: isReact })
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        image_target2: val
                                    }))
                                }}
                                imageLink={attributes?.image_link2}
                                imageTarget={attributes?.image_target2}
                                pages={pages}
                                onDelete={() => {
                                    resolveHeight(model, resolveHeightTimer)
                                    handleInsideAttrChange({ model, key: 'image2', value: '', isOutside: isReact })
                                }}

                                hideImageLink={trait.hideimagelink}
                            />
                        )
                    case 'logo':
                        return (
                            <ImageUpload
                                title={trait.label}
                                imageSrc={attributes?.logo}
                                setImageSrc={(val) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        logo: val
                                    }))
                                }}
                                onUpload={(link) => {
                                    handleInsideAttrChange({ model, key: 'logo', value: link, isOutside: isReact })
                                    resolveHeight(model, resolveHeightTimer)
                                }}
                                onDelete={() => {
                                    handleInsideAttrChange({ model, key: 'logo', value: '', isOutside: isReact })
                                    resolveHeight(model, resolveHeightTimer)
                                }}
                                hideImageLink={true}
                            />
                        )
                    case 'logo2':
                        return (
                            <ImageUpload
                                title={trait.label}
                                imageSrc={attributes?.logo2}
                                setImageSrc={(val) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        logo2: val
                                    }))
                                }}
                                onUpload={(link) => {
                                    handleInsideAttrChange({ model, key: 'logo2', value: link, isOutside: isReact })
                                    resolveHeight(model, resolveHeightTimer)
                                }}
                                onDelete={() => {
                                    handleInsideAttrChange({ model, key: 'logo2', value: '', isOutside: isReact })
                                    resolveHeight(model, resolveHeightTimer)
                                }}
                                hideImageLink={true}
                            />
                        )
                    case 'button':
                        return (
                            <>
                                <GenericSwitchWithLabel
                                    label={trait.label}
                                    height={18}
                                    width={36}
                                    thumb={13}
                                    translateX={18}
                                    checked={attributes?.is_button}
                                    onChange={(event) => {

                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            is_button: event.target.checked
                                        }))
                                        handleInsideAttrChange({ model, key: 'is_button', value: Boolean(event.target.checked) ? 'true' : '', isOutside: isReact })
                                    }}
                                />

                                {attributes?.is_button && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 20
                                        }}
                                    >
                                        <GenericTextFieldWithLabel
                                            variant="outlined"
                                            label="Label"
                                            colorType="secondary"
                                            value={JSON.parse(attributes?.button || '{}')?.label}
                                            onChange={(event) => {

                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    button: JSON.stringify({
                                                        ...JSON.parse(prevState?.button || '{}'),
                                                        label: event.target.value
                                                    })
                                                }))
                                                handleInsideAttrChange({
                                                    model,
                                                    key: 'button',
                                                    value: JSON.stringify({
                                                        ...JSON.parse(attributes?.button || '{}'),
                                                        label: event.target.value
                                                    }),
                                                    isOutside: isReact
                                                })

                                            }}
                                            fullWidth={true}
                                        />

                                        <LinkSelector
                                            variant="outlined"
                                            label="Link To"
                                            colorType="secondary"
                                            value={JSON.parse(attributes?.button || '{}')?.link}
                                            tab={JSON.parse(attributes?.button || '{}')?.tab}
                                            onTabChange={(checked) => {

                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    button: JSON.stringify({
                                                        ...JSON.parse(prevState?.button || '{}'),
                                                        tab: checked
                                                    })

                                                }))
                                                handleInsideAttrChange({
                                                    model,
                                                    key: 'button',
                                                    value: JSON.stringify({
                                                        ...JSON.parse(attributes?.button || '{}'),
                                                        tab: checked
                                                    }),
                                                    isOutside: isReact
                                                })
                                            }}
                                            onChange={(event) => {

                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    button: JSON.stringify({
                                                        ...JSON.parse(prevState?.button || '{}'),
                                                        link: event.target.value
                                                    })

                                                }))
                                                handleInsideAttrChange({
                                                    model,
                                                    key: 'button',
                                                    value: JSON.stringify({
                                                        ...JSON.parse(attributes?.button || '{}'),
                                                        link: event.target.value
                                                    }),
                                                    isOutside: isReact
                                                })

                                            }}
                                            fullWidth={true}
                                            pages={pages}
                                        />
                                    </div>
                                )}
                            </>
                        )
                    case 'button2':
                        return (
                            <>
                                <GenericSwitchWithLabel
                                    label={trait.label}
                                    height={18}
                                    width={36}
                                    thumb={13}
                                    translateX={18}
                                    checked={attributes?.is_button2}
                                    onChange={(event) => {

                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            is_button2: event.target.checked
                                        }))
                                        handleInsideAttrChange({ model, key: 'is_button2', value: Boolean(event.target.checked) ? 'true' : '', isOutside: isReact })
                                    }}
                                />

                                {attributes?.is_button && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 20
                                        }}
                                    >
                                        <GenericTextFieldWithLabel
                                            variant="outlined"
                                            label="Label"
                                            colorType="secondary"
                                            value={JSON.parse(attributes?.button2 || '{}')?.label}
                                            onChange={(event) => {

                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    button2: JSON.stringify({
                                                        ...JSON.parse(prevState?.button2 || '{}'),
                                                        label: event.target.value
                                                    })
                                                }))
                                                handleInsideAttrChange({
                                                    model,
                                                    key: 'button2',
                                                    value: JSON.stringify({
                                                        ...JSON.parse(attributes?.button2 || '{}'),
                                                        label: event.target.value
                                                    }),
                                                    isOutside: isReact
                                                })

                                            }}
                                            fullWidth={true}
                                        />

                                        <LinkSelector
                                            variant="outlined"
                                            label="Link To"
                                            colorType="secondary"
                                            value={JSON.parse(attributes?.button2 || '{}')?.link}
                                            tab={JSON.parse(attributes?.button2 || '{}')?.tab}
                                            onTabChange={(checked) => {

                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    button2: JSON.stringify({
                                                        ...JSON.parse(prevState?.button2 || '{}'),
                                                        tab: checked
                                                    })
                                                }))
                                                handleInsideAttrChange({
                                                    model,
                                                    key: 'button2',
                                                    value: JSON.stringify({
                                                        ...JSON.parse(attributes?.button2 || '{}'),
                                                        tab: checked
                                                    }),
                                                    isOutside: isReact
                                                })

                                            }}
                                            onChange={(event) => {

                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    button2: JSON.stringify({
                                                        ...JSON.parse(prevState?.button2 || '{}'),
                                                        link: event.target.value
                                                    })
                                                }))
                                                handleInsideAttrChange({
                                                    model,
                                                    key: 'button2',
                                                    value: JSON.stringify({
                                                        ...JSON.parse(attributes?.button2 || '{}'),
                                                        link: event.target.value
                                                    }),
                                                    isOutside: isReact
                                                })
                                            }}
                                            fullWidth={true}
                                            pages={pages}
                                        />
                                    </div>
                                )}
                            </>
                        )
                    case 'items':
                        return (
                            <>
                                <ItemsDrag
                                    title={trait.label}
                                    titleKey={trait.placeholder}
                                    items={JSON.parse(attributes?.items || '[]')}
                                    onDragEnd={onDragEnd}
                                    onDelete={(indexToRemove) => {
                                        setAttributes((prevState) => {
                                            let newState = [...JSON.parse(prevState?.items || '[]')]
                                            newState.splice(indexToRemove, 1);
                                            handleInsideAttrChange({ model: model, key: 'items', value: JSON.stringify(newState), isOutside: isReact })
                                            return ({
                                                ...prevState,
                                                items: JSON.stringify(newState)
                                            })
                                        })
                                    }}
                                    onEdit={(index) => {
                                        setEditIndex(index)
                                        setTab('itemsedit')
                                    }}
                                />

                                {Boolean(trait?.data?.itemlimit) ? (
                                    <>
                                        {(trait?.data?.itemlimit > items.length) ? (
                                            <Button
                                                variant="text"
                                                onClick={() => {

                                                    setAttributes((prevState) => {
                                                        let newState = [...JSON.parse(prevState?.items || '[]')]
                                                        newState.push(trait.data)
                                                        handleInsideAttrChange({ model: model, key: 'items', value: JSON.stringify(newState), isOutside: isReact })
                                                        return ({
                                                            ...prevState,
                                                            items: JSON.stringify(newState)
                                                        })
                                                    })
                                                }}
                                            >
                                                {trait.addlabel}
                                            </Button>
                                        ) : (
                                            <>
                                                {trait?.data?.limitExceedText && (
                                                    <div style={{ textAlign: 'center', color: '#7D7D7D' }}>{trait?.data?.limitExceedText}</div>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {trait?.data && (
                                            <Button
                                                variant="text"
                                                onClick={() => {

                                                    setAttributes((prevState) => {
                                                        let newState = [...JSON.parse(prevState?.items || '[]')]
                                                        newState.push(trait.data)
                                                        handleInsideAttrChange({ model: model, key: 'items', value: JSON.stringify(newState), isOutside: isReact })
                                                        return ({
                                                            ...prevState,
                                                            items: JSON.stringify(newState)
                                                        })
                                                    })

                                                }}
                                            >
                                                {trait.addlabel}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </>
                        )
                    case 'menus':
                        return (
                            <>
                                <ItemsDrag
                                    title={trait.label}
                                    titleKey={trait.placeholder}
                                    items={JSON.parse(attributes?.menu || '[]')}
                                    onDelete={(indexToRemove) => {
                                        setAttributes((prevState) => {
                                            let newState = [...JSON.parse(prevState?.menu || '[]')]
                                            newState.splice(indexToRemove, 1);
                                            handleInsideAttrChange({ model: model, key: 'menu', value: JSON.stringify(newState), isOutside: isReact })
                                            return ({
                                                ...prevState,
                                                items: JSON.stringify(newState)
                                            })
                                        })
                                    }}
                                    onDragEnd={onDragEnd}
                                    onEdit={(index) => {
                                        setEditIndex(index)
                                        setTab('itemsedit')
                                        setItemsKey('menu')
                                    }}
                                />

                                <Button
                                    variant="text"
                                    onClick={() => {

                                        setAttributes((prevState) => {
                                            let newState = [...JSON.parse(prevState?.menu || '[]')]
                                            newState.push(trait.data)

                                            handleInsideAttrChange({ model: model, key: 'menu', value: JSON.stringify(newState), isOutside: isReact })
                                            return ({
                                                ...prevState,
                                                menu: JSON.stringify(newState)
                                            })
                                        })
                                    }}
                                >
                                    {trait.addlabel}
                                </Button>
                            </>
                        )
                    case 'socials':
                        return (
                            <>
                                <ItemsDrag
                                    title={trait.label}
                                    titleKey={trait.placeholder}
                                    items={JSON.parse(attributes?.social || '[]')}
                                    onDelete={(indexToRemove) => {
                                        setAttributes((prevState) => {
                                            let newState = [...JSON.parse(prevState?.social || '[]')]
                                            newState.splice(indexToRemove, 1);
                                            handleInsideAttrChange({ model: model, key: 'social', value: JSON.stringify(newState), isOutside: isReact })
                                            return ({
                                                ...prevState,
                                                social: JSON.stringify(newState)
                                            })
                                        })
                                    }}
                                    onDragEnd={onDragEnd}
                                    onEdit={(index) => {
                                        setEditIndex(index)
                                        setTab('itemsedit')
                                        setItemsKey('social')
                                    }}
                                />

                                <Button
                                    variant="text"
                                    onClick={() => {

                                        setAttributes((prevState) => {
                                            let newState = [...JSON.parse(prevState?.social || '[]')]
                                            newState.push(trait.data)

                                            handleInsideAttrChange({ model: model, key: 'social', value: JSON.stringify(newState), isOutside: isReact })
                                            return ({
                                                ...prevState,
                                                social: JSON.stringify(newState)
                                            })
                                        })
                                    }}
                                >
                                    {trait.addlabel}
                                </Button>
                            </>
                        )
                    case 'is_login':
                        return (
                            <GenericSwitchWithLabel
                                label="Show Login Signup"
                                height={18}
                                width={36}
                                thumb={13}
                                translateX={18}
                                checked={attributes?.is_login}
                                onChange={(event) => {
                                    setIsLogin(Boolean(event.target.checked))
                                    handleInsideAttrChange({ model, key: 'is_login', value: Boolean(event.target.checked) ? 'true' : '', isOutside: isReact })
                                }}
                            />
                        )
                    case 'is_cart':
                        return (
                            <GenericSwitchWithLabel
                                label="Show Cart"
                                height={18}
                                width={36}
                                thumb={13}
                                translateX={18}
                                checked={attributes?.is_cart}
                                onChange={(event) => {
                                    setIsLogin(Boolean(event.target.checked))
                                    handleInsideAttrChange({ model, key: 'is_cart', value: Boolean(event.target.checked) ? 'true' : '', isOutside: isReact })
                                }}
                            />
                        )
                    case 'success-message':
                        return (
                            <GenericSelectWithLabel
                                label="Form success message"
                                value={attributes?.success_message}
                                onChange={(event) => {

                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        success_message: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'success_message', value: event.target.value, isOutside: isReact })
                                }}
                                fullWidth={true}
                            >
                                {successMessageText?.map((data) => (
                                    <MenuItem
                                        key={data}
                                        value={data}
                                    >
                                        {data}
                                    </MenuItem>
                                ))}
                            </GenericSelectWithLabel>
                        )
                    case 'audio':
                        return (
                            <div
                                className="tw-flex tw-flex-col tw-gap-4"
                            >
                                <TabSelector
                                    title="Audio"
                                    onChange={(value) => {
                                        if (value === 'color') {
                                            const attr = selectedModel?.getStyle()
                                            selectedModel.setStyle({
                                                ...attr,
                                                'background-image': ''
                                            })
                                            // imageRef.current?.imageDelete()
                                        } else {
                                            const attr = selectedModel?.getStyle()
                                            selectedModel.setStyle({
                                                ...attr,
                                                'background-color': ''
                                            })
                                        }
                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            background_type: value
                                        }))
                                    }}
                                    value={attributes?.background_type}
                                    tabs={[
                                        {
                                            label: 'Upload audio',
                                            value: 'image'
                                        },
                                        {
                                            label: 'Embed audio',
                                            value: 'color'
                                        }
                                    ]}
                                />

                                {attributes?.background_type === 'image' ? (

                                    <>
                                        <input
                                            style={{
                                                display: 'none'
                                            }}
                                            ref={audioRef}
                                            type="file"
                                            onChange={handleAudioChange}
                                        />


                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: 15
                                            }}
                                        >
                                            {attributes?.src ? (
                                                <div
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            textDecoration: 'underline',
                                                            padding: '20px 5px 5px 0',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {/* {fileName} */}
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 15,
                                                            paddingLeft: 5
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                marginTop: 5,
                                                                fontWeight: 600,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => {
                                                                audioRef.current?.click()
                                                            }}
                                                        >
                                                            Replace
                                                        </div>

                                                        <div
                                                            style={{
                                                                marginTop: 5,
                                                                fontWeight: 600,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => {
                                                                handleInsideAttrChange({ model, key: 'src', value: '', isOutside: isReact })
                                                            }}
                                                        >
                                                            Delete
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className="tw-flex tw-justify-center tw-items-center tw-h-[70px] tw-mt-2 tw-w-full"
                                                    onClick={() => {
                                                        audioRef.current?.click()
                                                    }}
                                                >
                                                    <div
                                                        className="tw-flex tw-flex-col tw-bg-[#f8f4f4] tw-h-9 tw-rounded tw-items-center tw-justify-center tw-text-[#0f83f7] tw-cursor-pointer tw-p-3"
                                                    >
                                                        Select audio file from your device
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (

                                    <>
                                        <GenericSelectWithLabel
                                            label="Enter embed url"
                                            value={attributes?.type}
                                            onChange={(event) => {
                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    type: event.target.value
                                                }))
                                                handleInsideAttrChange({ model, key: 'type', value: event.target.value, isOutside: isReact })
                                            }}
                                            fullWidth={true}
                                            required={true}
                                            MenuProps={{ disablePortal: true }}
                                        >
                                            <MenuItem value="audio">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: 10
                                                    }}
                                                >
                                                    <div>
                                                        Audio
                                                    </div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value="soundcloud">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: 10
                                                    }}
                                                >
                                                    <img
                                                        height={20}
                                                        src="https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/soundcloud.png-1687106662.png"
                                                    />
                                                    <div>
                                                        Soundcloud
                                                    </div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value="spotify">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: 10
                                                    }}
                                                >
                                                    <img
                                                        height={20}
                                                        src="https://d36musakzcdau7.cloudfront.net/ourliquorstore/PageComponent/spotify.png-1687106830.png"
                                                    />
                                                    <div>
                                                        Spotify
                                                    </div>
                                                </div>
                                            </MenuItem>

                                        </GenericSelectWithLabel>

                                        <GenericTextFieldWithLabel
                                            variant="standard"
                                            placeholder="Enter soundcloud embed link"
                                            value={attributes?.srcembed}
                                            onChange={(event) => {
                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    srcembed: event.target.value,
                                                    src: ''
                                                }))
                                                handleInsideAttrChange({ model, key: 'srcembed', value: event.target.value, isOutside: isReact })
                                                handleInsideAttrChange({ model, key: 'src', value: '', isOutside: isReact })
                                            }}
                                            fullWidth={true}
                                        />
                                    </>
                                )}
                            </div>
                        )
                    case 'hide-images':
                        return (
                            <>
                                <GenericSwitchWithLabel
                                    label={trait.label}
                                    height={18}
                                    width={36}
                                    thumb={13}
                                    translateX={18}
                                    checked={Boolean(attributes?.hide_images)}
                                    onChange={(event) => {
                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            hide_images: event.target.checked
                                        }))
                                        handleInsideAttrChange({ model, key: 'hide_images', value: Boolean(event.target.checked) ? 'true' : '', isOutside: isReact })
                                    }}
                                />

                            </>
                        )
                    case 'video':
                        return (
                            <Input
                                variant="outlined"
                                label="Video"
                                placeholder="Enter embed link"
                                value={attributes?.srcembed}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        srcembed: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'srcembed', value: event.target.value, isOutside: isReact })
                                }}
                                // onAdvanceClick={() => {
                                //     setOpenRte(true)
                                //     htmlToDraftConverter(setEditorState, wtitle, true)
                                //     setRteInput('wtitle')
                                // }}
                                fullWidth={true}
                            />
                        )
                    case 'srcembed':
                        return (
                            <div>
                                <Dialog
                                    open={openCodeEditor}
                                    onClose={() => {
                                        setOpenCodeEditor(false)
                                    }}
                                >
                                    <div
                                        className="tw-w-[700px]"
                                    >
                                        <CodeMirror
                                            ref={codeMirrorRefEditor}
                                            value={attributes?.srcembed}
                                            height="600px"
                                            extensions={[
                                                html(),
                                                // lineNumbers(),
                                                // foldGutter(),
                                                // gutter()
                                            ]}
                                            onChange={(val) => {
                                                codeMirrorRef.current = val
                                                // setCode(val)

                                                // setEmbedLink(event.target.value)

                                            }}
                                            theme={vscodeDark}
                                            basicSetup={{
                                                foldGutter: true
                                            }}
                                        />
                                    </div>
                                    <DialogActions>
                                        <Button
                                            onClick={() => {
                                                setAttributes((prevState) => ({
                                                    ...prevState,
                                                    srcembed: codeMirrorRef.current
                                                }))

                                                handleInsideAttrChange({ model, key: 'srcembed', value: codeMirrorRef.current, isOutside: isReact })
                                                setOpenCodeEditor(false)
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Input
                                    variant="outlined"
                                    label="Embed"
                                    placeholder="Enter embed link"
                                    value={attributes?.srcembed}
                                    onChange={(event) => {
                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            srcembed: event.target.value
                                        }))

                                        handleInsideAttrChange({ model, key: 'srcembed', value: event.target.value, isOutside: isReact })
                                    }}
                                    // onAdvanceClick={() => {
                                    //     setOpenRte(true)
                                    //     htmlToDraftConverter(setEditorState, wtitle, true)
                                    //     setRteInput('wtitle')
                                    // }}
                                    fullWidth={true}
                                />
                                <Button
                                    onClick={() => {
                                        setOpenCodeEditor(true)
                                    }}
                                >
                                    Open Code Editor
                                </Button>
                            </div>
                        )
                    case 'hide-tabbar':
                        return (
                            <>
                                <GenericSwitchWithLabel
                                    label={trait.label}
                                    height={18}
                                    width={36}
                                    thumb={13}
                                    translateX={18}
                                    checked={!Boolean(attributes?.hide_tabbar)}
                                    onChange={(event) => {
                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            hide_tabbar: Boolean(!event.target.checked)
                                        }))
                                        handleInsideAttrChange({ model, key: 'hide_tabbar', value: Boolean(!event.target.checked) ? 'true' : '', isOutside: isReact })
                                    }}
                                />
                            </>
                        )
                    case 'form-group-id':
                        return (
                            <>
                                <GenericSelectWithLabel
                                    label={trait.label}
                                    labelClassName='!tw-text-black !tw-text-base'
                                    value={attributes?.group_id}
                                    onChange={(event) => {
                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            group_id: event.target.value
                                        }))

                                        handleInsideAttrChange({ model: model, key: 'group_id', value: event.target.value, isOutside: isReact })
                                    }}
                                    fullWidth={true}
                                >
                                    {forms?.data?.map((data) => (
                                        <MenuItem
                                            value={data?.id}
                                        >
                                            {data?.title}
                                        </MenuItem>
                                    ))}
                                </GenericSelectWithLabel>
                            </>
                        )
                    case 'hide-categories':
                        return (
                            <HideCategoryOption
                                value={attributes?.hide_categories}
                                onChange={(value) => {
                                    handleInsideAttrChange({ model, key: 'hide_categories', value: value, isOutside: isReact })
                                }}
                            />
                        )
                    case 'esplanda-signup-type':
                        return (
                            <>
                                <TabSelector
                                    title={<span style={{ fontSize: '16px', fontWeight: 600 }}>{trait.label}</span>}
                                    onChange={(value) => {
                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            signup_type: value
                                        }))
                                        handleInsideAttrChange({ model: model, key: 'signup_type', value: value, isOutside: isReact })
                                    }}
                                    value={attributes?.signup_type}
                                    tabs={[
                                        {
                                            label: 'Generic',
                                            value: 'Generic'
                                        },
                                        {
                                            label: 'Default Selected',
                                            value: 'Default Selected'
                                        }
                                    ]}
                                />
                                <GenericSelectWithLabel
                                    label={<span style={{ fontSize: '14px', fontWeight: 500 }}>Default Store Option</span>}
                                    value={attributes?.store_default}
                                    onChange={(event) => {
                                        handleInsideAttrChange({ model: model, key: 'store_default', value: event.target.value, isOutside: isReact })
                                    }}
                                    fullWidth={true}
                                >
                                    {esplandaStoreType?.map((data) => (
                                        <MenuItem
                                            value={data}
                                        >
                                            {data}
                                        </MenuItem>
                                    ))}
                                </GenericSelectWithLabel>
                            </>
                        )
                    case 'esplanda-signup-button':
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 20
                                }}
                            >
                                <GenericTextFieldWithLabel
                                    variant="outlined"
                                    label={trait.label}
                                    colorType="secondary"
                                    value={JSON.parse(attributes?.button || '{}')?.label}
                                    onChange={(event) => {

                                        setAttributes((prevState) => ({
                                            ...prevState,
                                            button: JSON.stringify({
                                                ...JSON.parse(prevState?.button || '{}'),
                                                label: event.target.value
                                            })
                                        }))
                                        handleInsideAttrChange({
                                            model,
                                            key: 'button2',
                                            value: JSON.stringify({
                                                ...JSON.parse(attributes?.button2 || '{}'),
                                                label: event.target.value
                                            }),
                                            isOutside: isReact
                                        })
                                    }}
                                    fullWidth={true}
                                />
                            </div>
                        )
                    case 'show_button_index_in_mobile':
                        return (
                            <GenericTextFieldWithLabel
                                variant="outlined"
                                label="Show button index in mobile"
                                placeholder="0,1,2"
                                value={attributes?.show_button_index_in_mobile}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        show_button_index_in_mobile: event.target.value
                                    }))
                                    handleInsideAttrChange({ model, key: 'show_button_index_in_mobile', value: event.target.value, isOutside: isReact })
                                }}
                                fullWidth={true}
                            />
                        )
                    case 'search':
                        return (
                            <GenericSwitchWithLabel
                                label="Show Search"
                                // description="Display menu links on your website"
                                height={18}
                                width={36}
                                thumb={13}
                                translateX={18}
                                checked={attributes?.is_search}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        is_search: Boolean(event.target.checked)
                                    }))
                                    handleInsideAttrChange({ model, key: 'is_search', value: Boolean(event.target.checked) ? 'true' : '', isOutside: isReact })
                                }}
                            />
                        )
                    case 'animation':
                        return (
                            <GenericSwitchWithLabel
                                label="Animation"
                                // description="Display menu links on your website"
                                height={18}
                                width={36}
                                thumb={13}
                                translateX={18}
                                checked={attributes?.is_animation}
                                onChange={(event) => {
                                    setAttributes((prevState) => ({
                                        ...prevState,
                                        is_animation: Boolean(event.target.checked)
                                    }))
                                    handleInsideAttrChange({ model, key: 'is_animation', value: Boolean(event.target.checked) ? 'true' : '', isOutside: isReact })
                                }}
                            />
                        )

                    default:
                        break;
                }
            })}

            <RichTextEditor3
                isModal={true}
                editorRef={editorRef}
                open={openRte}
                onClose={() => {
                    setOpenRte(false)
                }}
                editorState={editorState}
                setEditorState={setEditorState}
                setConvertedContent={setConvertedContent}
            />
        </div>
    )
}

export default TraitsResolver