import React from 'react'
import axios from '../utils/axiosConfig'
import { convertToId } from '../utils/helpers'

const useSendToPrinter = ({ user, isPrinterSaved }) => {

    const sendToPrinter = (row) => {
        if (row.status == 'o' && window?.ReactNativeWebView && isPrinterSaved) {

            axios.get(`/driver/get-order-detail/${row.Id}`)
                .then((res) => {
                    const resultsData = res.data?.data?.results;
                    resultsData.order_details = resultsData.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) }))

                    window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: resultsData, userinfo: user }));
                    //   window?.ReactNativeWebView?.postMessage(JSON.stringify({ orderDetails: res.data?.data?.results?.order_details?.map((data) => ({ ...data, modifiers: convertToId(data?.selected_modifiers) })), userinfo: user }));
                })

        }
    }

    return {
        sendToPrinter
    }
}

export default useSendToPrinter